import { isEmpty } from "@q4/nimbus-ui";
import { LoginQuery } from "../../../views/public/login/login.definition";
import QueryService from "../../query/query.service";
import { Auth0Base, Auth0Model } from "../auth0.model";

export const LOCAL_STORAGE_ADMIN_AUTH0 = "auth0";
export const LOCAL_STORAGE_ADMIN_AUTH0_REDIRECT = "auth0_redirect";

export class AdminAuth0 extends Auth0Base implements Auth0Model {
  init(callbackUrl: string): void {
    const queryService = new QueryService(callbackUrl);
    const idToken = queryService.get(LoginQuery.IdToken);

    const profile = this.parseProfile(idToken);
    if (isEmpty(profile)) return;

    // TODO: remove hardcoded value when the token contains the companyId
    // const company = this.getCompany(profile);
    const company = "Q4 Inc.";
    this._idToken = idToken;
    this._profile = {
      ...profile,
      company,
    };
    this._expiresAt = this.getExpiredAt(profile.exp);
  }
}
