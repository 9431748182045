import {
  BaseComponentProps,
  CheckboxIdModel,
  ComboBoxIdModel,
  DatePickerIdModel,
  ErrorModel,
  FileUploaderIdModel,
  FormIdModel,
  IdModelBase,
  InfoIconIdModel,
  isEmpty,
  isNullOrWhiteSpace,
  MessageIdModel,
  SelectIdModel,
  TextareaIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import moment from "moment";
import type { Dispatch, SetStateAction } from "react";
import { DefaultTimeZone } from "../../const";
import type { Conference } from "../../services/conference/conference.model";
import { Presentation } from "../../services/presentation/presentation.model";
import type { Speaker } from "../../services/speaker/speaker.model";
import type { Track } from "../../services/track/track.model";
import { SessionCustomVendorFormIdModel } from "../sessionCustomVendorForm/sessionCustomVendorForm.definition";

export const CreateNewLocationSuffix = " (Create New)";
export const MeetingLabelMaxLength = 100;

export enum PresentationFormClassName {
  Base = "presentation-form",
  Description = "presentation-form_description",
  Agenda = "presentation-form_agenda",
  CustomLabel = "presentation-form_custom-label",
  CustomCheckbox = "presentation-form_custom-checkbox",
}

export enum PresentationFormErrorMessages {
  track = "Track is required",
  presentationTitle = "Presentation Title is required",
  breakTitle = "Break Name is required",
  endTime = "End Time is required",
  url = "Enter a valid URL",
}

export interface PresentationFormProps extends BaseComponentProps {
  conference: Conference;
  codes: string[];
  presentation: Presentation;
  speakers: Speaker[];
  tracks: Track[];
  roomOptions: string[];
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  onPresentationUpdate: (data: Partial<Presentation>) => void;
}

export class PresentationFormIdModel extends IdModelBase {
  sessionType: SelectIdModel;
  tracks: SelectIdModel;
  title: TextboxIdModel;
  presentation_type: TextboxIdModel;
  startDate: DatePickerIdModel;
  startTime: SelectIdModel;
  endTime: SelectIdModel;
  presentationRoom: SelectIdModel;
  description: TextareaIdModel;
  presentationLinkForm: FormIdModel;
  overrideUrl: TextboxIdModel;
  pollUrl: TextboxIdModel;
  qaUrl: TextboxIdModel;
  code: ComboBoxIdModel;
  speakers: ComboBoxIdModel;
  messageModal: MessageIdModel;
  fileUpload: FileUploaderIdModel;
  customVendorForm: SessionCustomVendorFormIdModel;
  speakerMinutes: TextboxIdModel;
  speakerNotes: TextboxIdModel;
  speakerLink: TextboxIdModel;
  addToAgenda: CheckboxIdModel;
  infoIcon: InfoIconIdModel;
  addToAllConferenceDays: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.sessionType = new SelectIdModel(`${this.id}SessionType`);
    this.tracks = new SelectIdModel(`${this.id}TracksSelect`);
    this.startDate = new DatePickerIdModel(`${this.id}StartDateDatePicker`);
    this.startTime = new SelectIdModel(`${this.id}StartTimeSelect`);
    this.endTime = new SelectIdModel(`${this.id}EndTimeSelect`);
    this.presentation_type = new TextboxIdModel(`${this.id}PresentationTypeTextbox`);
    this.presentationRoom = new SelectIdModel(`${this.id}PresentationRoomSelect`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.description = new TextboxIdModel(`${this.id}DescriptionTextbox`);
    this.presentationLinkForm = new FormIdModel(`${this.id}PresentationLinkForm`);
    this.overrideUrl = new TextboxIdModel(`${this.id}OverrideUrlTextbox`);
    this.pollUrl = new TextboxIdModel(`${this.id}PollUrlTextbox`);
    this.qaUrl = new TextboxIdModel(`${this.id}QaUrlTextbox`);
    this.code = new ComboBoxIdModel(`${this.id}CodeCombobox`);
    this.speakers = new ComboBoxIdModel(`${this.id}Speaker`);
    this.messageModal = new MessageIdModel(`${this.id}MessageModal`);
    this.fileUpload = new FileUploaderIdModel(`${this.id}ImageThumbnail`);
    this.customVendorForm = new SessionCustomVendorFormIdModel(`${this.id}CustomVendorForm`);
    this.speakerMinutes = new TextboxIdModel(`${this.id}MinutesTextbox`);
    this.speakerNotes = new TextareaIdModel(`${this.id}NotesTextbox`);
    this.speakerLink = new TextboxIdModel(`${this.id}LinkTextbox`);
    this.addToAgenda = new CheckboxIdModel(`${this.id}AddToAgendaCheckbox`);
    this.infoIcon = new InfoIconIdModel(`${this.id}DateLabelInfoIcon`);
    this.addToAllConferenceDays = new CheckboxIdModel(`${this.id}AddToAllConferenceDays`);
  }
}

export class PresentationEditState extends Presentation {
  constructor(presentation?: Presentation, conference?: Conference) {
    super(presentation);
    if (isEmpty(presentation)) {
      const timezone = conference?.time_zone ?? DefaultTimeZone;
      const startDate = moment(conference?.scheduler?.start_date ?? conference?.start_date)
        .tz(timezone)
        .startOf("day");
      const currentDate = moment().tz(timezone).startOf("day");

      const isStartDateInFuture = startDate.isAfter(currentDate, "day");

      const roundingDuration = moment.duration(5, "minutes");
      const roundedStartDate = moment(Math.ceil(+moment() / +roundingDuration) * +roundingDuration).tz(timezone);
      const roundedEndDate = moment(roundedStartDate).add(1, "hours");

      this.start_date = isStartDateInFuture ? startDate : roundedStartDate;
      this.end_date = isStartDateInFuture ? startDate?.clone().add(1, "hours") : roundedEndDate;
      this._conference = conference?._id;
    }
  }
}
