export enum InvestorType {
  HedgeFund = "Hedge Fund",
  MutualFund = "Mutual Fund",
  PrivateEquity = "Private Equity",
  VentureCapital = "Venture Capital",
  PensionPlan = "Pension Plan",
  Retail = "Retail",
  FamilyOffice = "Family Office",
  Other = "Other",
}
