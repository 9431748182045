import { Form, InputType, Textbox } from "@q4/nimbus-ui";
import { cloneDeep, set } from "lodash";
import React, { memo, useMemo } from "react";
import GridCard from "../../../../../../components/gridCard/gridCard.component";
import { ConferenceCoordinatorEditIdModel } from "./coordinator.definition";
import type { ConferenceCoordinatorEditProps } from "./coordinator.definition";

const ConferenceCoordinatorEdit = (props: ConferenceCoordinatorEditProps): JSX.Element => {
  const { id, coordinator, replyTo, onChange } = props;

  const idModel = useMemo(() => new ConferenceCoordinatorEditIdModel(id), [id]);

  function getStateChangeHandler(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      const state = { coordinator, reply_to: replyTo };
      const newState = set(cloneDeep(state), key, value);

      onChange(newState);
    };
  }

  return (
    <GridCard cardProps={{ title: "Coordinator Details" }}>
      <Form
        fields={[
          {
            key: "Coordinator Name",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Coordinator Name",
            children: (
              <Textbox
                id={idModel.coordinatorName.id}
                value={coordinator?.full_name}
                onChange={getStateChangeHandler("coordinator.full_name")}
              />
            ),
          },
          {
            key: "Coordinator Title",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Coordinator Title",
            children: (
              <Textbox
                id={idModel.coordinatorTitle.id}
                value={coordinator?.title}
                onChange={getStateChangeHandler("coordinator.title")}
              />
            ),
          },
          {
            key: "Coordinator Phone",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Coordinator Phone",
            children: (
              <Textbox
                id={idModel.coordinatorPhone.id}
                value={coordinator?.phone}
                onChange={getStateChangeHandler("coordinator.phone")}
                inputType={InputType.Telephone}
              />
            ),
          },
          {
            key: "Coordinator Email",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Coordinator Email",
            children: (
              <Textbox
                id={idModel.coordinatorEmail.id}
                value={coordinator?.email}
                inputType={InputType.Email}
                onChange={getStateChangeHandler("coordinator.email")}
              />
            ),
          },
          {
            key: "Reply To Name",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Reply To Name",
            children: (
              <Textbox id={idModel.replyToName.id} value={replyTo?.name} onChange={getStateChangeHandler("reply_to.name")} />
            ),
          },
          {
            key: "Reply To Email",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Reply To Email",
            children: (
              <Textbox
                id={idModel.replyToEmail.id}
                value={replyTo?.email}
                onChange={getStateChangeHandler("reply_to.email")}
                inputType={InputType.Email}
              />
            ),
          },
        ]}
      />
    </GridCard>
  );
};

export default memo(ConferenceCoordinatorEdit);
