import { Modal } from "@q4/nimbus-ui";
import React, { memo } from "react";
import MeetingForm from "../../../../../../../../components/meetingForm/meetingForm.component";
import { MeetingModalProps, ModalContentHeight } from "./meetingModal.definition";

const MeetingModal = (props: MeetingModalProps): JSX.Element => {
  return (
    <Modal
      id={props.id}
      badgeIcon={props.badgeIcon}
      className={props.className}
      title={props.title}
      subtitle={props.subtitle}
      visible={props.visible}
      onCloseRequest={props.onCloseRequest}
      footerActions={props.footerActions}
      fullscreen={props.fullscreen}
      contentHeight={ModalContentHeight}
      scrollable
      focusOnProps={{ autoFocus: false }}
    >
      {props.meetingFormProps ? <MeetingForm {...props.meetingFormProps} /> : null}
    </Modal>
  );
};

export default memo(MeetingModal);
