import { isEmpty } from "@q4/nimbus-ui";
import { UserRouteBase } from "../../../configurations/navigation.configuration";
import { ApiResponse, AuthType, ContentType } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import type { Conference } from "../../conference/conference.model";
import type { Presentation } from "../../presentation/presentation.model";
import { Agenda } from "./agenda.model";

export default class AgendaService {
  private readonly AGENDA_API_PATH = `${UserRouteBase}/agenda`;
  private apiService = new ApiService();

  public async getAgendaByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Agenda>> {
    const response = await this.apiService.get<Agenda[]>(`${this.AGENDA_API_PATH}/${_id}`, AuthType.Passwordless);
    return this.mapAgendaResponse(response);
  }

  public async postToAgendaByPresentationId(
    conferenceId: Conference["_id"],
    presentationId: Presentation["_id"]
  ): Promise<ApiResponse<Agenda>> {
    const response = await this.apiService.post<null, Agenda[]>(
      `${this.AGENDA_API_PATH}/${conferenceId}/${presentationId}`,
      null,
      ContentType.Json,
      AuthType.Passwordless
    );
    return this.mapAgendaResponse(response);
  }

  public async deleteFromAgendaByPresentationId(
    conferenceId: Conference["_id"],
    presentationId: Presentation["_id"]
  ): Promise<ApiResponse<Agenda>> {
    const response = await this.apiService.delete<null, Agenda[]>(
      `${this.AGENDA_API_PATH}/${conferenceId}/${presentationId}`,
      null,
      AuthType.Passwordless
    );
    return this.mapAgendaResponse(response);
  }

  private mapAgendaResponse(response: ApiResponse<Agenda[]>): ApiResponse<Agenda> {
    const agenda = isEmpty(response?.data) ? null : response.data[0];
    return new ApiResponse({
      ...response,
      data: new Agenda(agenda),
    });
  }
}
