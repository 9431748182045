import { BadgeTheme, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { RoutePath, RoutePathIdLabel } from "../../configurations/navigation.configuration";
import { DefaultTimeZone } from "../../const";
import {
  Conference,
  ConferenceStatus,
  DefaultLobbyConfig,
  LobbyConfigSection,
  LobbyDefaults,
  PresentationVendorType,
  PresentationVendorTypeDefault,
  LobbyOpenConfig,
  LobbyOpenConfigSection,
} from "../../services/conference/conference.model";
import QueryService from "../../services/query/query.service";
import { ConferenceCompanyQueryParam } from "../../views/admin/conferences/conferences.definition";
import { convertIsoToMomentUtc, formatDateRange, convertToLocal } from "../date/date.utils";
import { getUrlParam } from "../window/location.utils";

export function getConferenceStatusTheme(status: ConferenceStatus): BadgeTheme {
  const theme = {
    [ConferenceStatus.None]: BadgeTheme.Silver,
    [ConferenceStatus.Scheduled]: BadgeTheme.Surf,
    [ConferenceStatus.Running]: BadgeTheme.Teal,
    [ConferenceStatus.Ended]: BadgeTheme.Spice,
  };

  return !isNil(theme[status]) ? theme[status] : BadgeTheme.None;
}

export function getConferencePresentationVendorName(conference: Conference): PresentationVendorType {
  if (isEmpty(conference?.presentation_vendors?.[0])) return PresentationVendorTypeDefault;
  if (isNullOrWhiteSpace(conference.presentation_vendors[0].name)) return PresentationVendorTypeDefault;
  return conference?.presentation_vendors[0].name;
}

export function getLobbyConfig(key: keyof DefaultLobbyConfig, lobby: DefaultLobbyConfig): LobbyConfigSection {
  if (isNullOrWhiteSpace(key)) return null;

  const defaultConfig = {
    title: LobbyDefaults[key].title,
    label: LobbyDefaults[key].label,
    disabled: LobbyDefaults[key].disabled,
  };

  if (isEmpty(lobby)) return defaultConfig;

  return {
    disabled: !!lobby[key]?.disabled,
    title: isNullOrWhiteSpace(lobby[key]?.title) ? defaultConfig.title : lobby[key].title,
    label: isNullOrWhiteSpace(lobby[key]?.label) ? defaultConfig.label : lobby[key].label,
  };
}

export function getLobbyOpenConfig(key: keyof LobbyOpenConfig, lobby: LobbyOpenConfig): LobbyOpenConfigSection {
  if (!key || !lobby) return;
  return (
    lobby[key] && {
      open_date: convertIsoToMomentUtc(lobby[key].open_date),
    }
  );
}

export function getLobbyOpenConfigLocalTime(
  key: keyof LobbyOpenConfig,
  lobby: LobbyOpenConfig,
  sourceTimeZone: string
): LobbyOpenConfigSection {
  if (!key || !lobby || !sourceTimeZone) return;
  return (
    lobby[key] && {
      open_date: convertToLocal(lobby[key].open_date, sourceTimeZone),
    }
  );
}

export function getConferenceUrlSuffix(): string {
  const urlSuffix = getUrlParam(RoutePath.ConferenceLobby, RoutePathIdLabel.ConferencePath);

  if (isNullOrWhiteSpace(urlSuffix)) return null;

  return urlSuffix;
}

export function getConferenceUrlSuffixWithLoginRoute(): string {
  const urlSuffix =
    getUrlParam(RoutePath.ConferenceLogin, RoutePathIdLabel.ConferencePath) ||
    getUrlParam(RoutePath.ConferenceLobby, RoutePathIdLabel.ConferencePath) ||
    getUrlParam(RoutePath.ConferenceLoginWithCustomCompany, RoutePathIdLabel.ConferencePath) ||
    getUrlParam(RoutePath.ConferenceLobbyWithCustomCompany, RoutePathIdLabel.ConferencePath);

  if (isNullOrWhiteSpace(urlSuffix)) return null;

  return urlSuffix;
}

export function getConferenceUrlSuffixWithCustomCompany(): string {
  const urlSuffix = getUrlParam(RoutePath.ConferenceLobbyWithCustomCompany, RoutePathIdLabel.ConferencePath);
  if (isNullOrWhiteSpace(urlSuffix)) return null;

  return urlSuffix;
}

export function getCompanyQuery(isSystemAdmin = true): string {
  if (!isSystemAdmin) return null;

  const queryService = new QueryService();
  return queryService.get(ConferenceCompanyQueryParam);
}

export function getConferencesPageRoute(isSystemAdmin: boolean, conference: Conference): string {
  return isSystemAdmin
    ? RoutePath.ConferencesByCompany.replace(RoutePathIdLabel.Company, conference?._company?.name)
    : RoutePath.Conferences;
}

export function getConferenceDateLabel(conference: Conference, showWeekDay = true): string {
  if (isEmpty(conference)) return null;
  const { date_label, end_date, start_date, time_zone } = conference;
  const conferenceTimezone = isNullOrWhiteSpace(time_zone) ? DefaultTimeZone : time_zone;
  return isNullOrWhiteSpace(date_label)
    ? formatDateRange(start_date.clone().tz(conferenceTimezone), end_date.clone().tz(conferenceTimezone), showWeekDay)
    : date_label;
}
