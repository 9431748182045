import { BaseComponentWithChildrenProps, IdModelBase, isNullOrWhiteSpace, Theme } from "@q4/nimbus-ui";

export enum AccordionType {
  DragDrop = "dragdrop",
  SubHeader = "sub-header",
}

export enum AccordionTheme {
  LightGrey = Theme.LightGrey,
  Rain = Theme.Rain,
  Spice = Theme.Spice,
}

export enum AccordionClassName {
  Base = "accordion",
  Disabled = "accordion--disabled",
  Collapsed = "accordion--collapsed",
  Header = "accordion_header",
  Row = "accordion_header-row",
  Content = "accordion_header-content",
  Badges = "accordion_header-badges",
  Action = "accordion_header-action",
  Toggle = "accordion_toggle",
  ContentWithModifier = "accordion_header-content--medium",
}
export interface AccordionState {
  toggleRotate: number;
}

export class AccordionProps extends BaseComponentWithChildrenProps {
  badges?: JSX.Element[];
  collapsed: boolean;
  disabled?: boolean;
  header?: JSX.Element;
  mediumHeader?: boolean;
  onChange: (collapsed: boolean) => void;
  theme?: AccordionTheme;
  toggleIcon? = "q4i-caret-down-4pt";
  types?: AccordionType[] = [];

  constructor(props: Partial<AccordionProps>) {
    super(props.children, props.className, props.id, props.dataId);
    const { children, className, id, dataId, ...accordionProps } = props;
    Object.assign(this, accordionProps);
  }
}

export class AccordionIdModel extends IdModelBase {
  content: string;
  toggle: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.content = `${this.id}Content`;
    this.toggle = `${this.id}ToggleButton`;
  }
}

export const AccordionTransitionSpeed = 300;
