import { useCallback, useRef, useState } from "react";
import { RegistrantStatus } from "../../services/admin/registrant/registrant.model";
import type { ApiResponse } from "../../services/api/api.definition";
import type {
  AuthRequestAccessResponse,
  AuthRequestLinkResponse,
  AuthValidateAttendeeResponse,
} from "../../services/auth/auth.model";
import AuthService from "../../services/auth/auth.service";
import type { AuthHookModel } from "./useAuth.definition";

export const useAuth = (): AuthHookModel => {
  const [loading, setLoading] = useState(false);
  const authService = useRef(new AuthService());

  const requestLink = useCallback(
    (
      companyUrlSuffix: string,
      conferenceId: string,
      email: string,
      isPasscode: boolean
    ): Promise<ApiResponse<AuthRequestLinkResponse>> => {
      setLoading(true);
      return authService.current
        .requestLink(companyUrlSuffix, conferenceId, email, isPasscode)
        .then((response: ApiResponse<AuthRequestLinkResponse>): ApiResponse<AuthRequestLinkResponse> => {
          setLoading(false);
          return response;
        });
    },
    []
  );

  const _setResponse = (
    response: ApiResponse<AuthValidateAttendeeResponse<RegistrantStatus>> | ApiResponse<AuthValidateAttendeeResponse>
  ) => {
    if (!response.success) {
      return { openConferences: false, userExists: false, success: false, message: response?.message };
    }
    return {
      openConferences: response?.data.openConferences,
      userExists: response?.data.userExists,
      message: response?.message,
      success: response?.success,
    };
  };

  const validateAttendee = useCallback((companyUrlSuffix: string, email: string): Promise<AuthValidateAttendeeResponse> => {
    setLoading(true);
    return authService.current
      .validateAttendee(companyUrlSuffix, email)
      .then((response: ApiResponse<AuthValidateAttendeeResponse>): AuthValidateAttendeeResponse => {
        setLoading(false);
        return _setResponse(response);
      });
  }, []);

  const validateAttendeeByConferenceId = useCallback(
    (
      companyUrlSuffix: string,
      conferenceId: string,
      email: string
    ): Promise<AuthValidateAttendeeResponse<RegistrantStatus>> => {
      setLoading(true);
      return authService.current
        .validateAttendeeByConferenceId(companyUrlSuffix, email, conferenceId)
        .then((response: ApiResponse<AuthValidateAttendeeResponse<RegistrantStatus>>): AuthValidateAttendeeResponse => {
          setLoading(false);
          return _setResponse(response);
        });
    },
    []
  );

  const requestAccess = useCallback(
    (
      companyUrlSuffix: string,
      conferenceId: string,
      email: string,
      password: string,
      code: string
    ): Promise<ApiResponse<AuthRequestAccessResponse>> => {
      setLoading(true);
      return authService.current
        .requestAccess(companyUrlSuffix, conferenceId, email, password, code)
        .then((response: ApiResponse<AuthRequestAccessResponse>): ApiResponse<AuthRequestAccessResponse> => {
          setLoading(false);

          if (!response.success) {
            console.error(response.message);
            return { ...response, message: "Email or password is invalid." };
          }

          return response;
        });
    },
    []
  );

  return {
    loading,
    requestLink,
    validateAttendee,
    validateAttendeeByConferenceId,
    requestAccess,
  };
};
