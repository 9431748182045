import "./schedulerMetricBar.component.scss";
import { isEmpty } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { SchedulerMeetingStatus } from "../../meetingScheduler.definition";
import {
  getMeetingSlotRange,
  getPresentationRange,
  getSchedulerRequests,
  getSlotRange,
  getSlots,
  getTotalSlots,
} from "../../meetingScheduler.utils";
import { SchedulerMetricBarProps, SchedulerMetricBarClassName } from "./schedulerMetricBar.definition";

const SchedulerMetricBar = (props: SchedulerMetricBarProps): JSX.Element => {
  const {
    id,
    requests,
    meetingRequests,
    presentations,
    step,
    currentAttendee,
    currentCorporate,
    currentRequestType,
    timezone,
  } = props;

  const totalUnscheduledRequests = useMemo(
    () =>
      getSchedulerRequests(requests, meetingRequests).filter((x) => x.requestStatus === SchedulerMeetingStatus.Unscheduled)
        .length,
    [meetingRequests, requests]
  );

  const totalSlots = useMemo((): number => {
    if (currentRequestType) {
      return getTotalSlots(currentAttendee?.availability, step, timezone);
    }

    const corporateAttendee = currentCorporate?.attendees?.find((x) => !isEmpty(x.availability));
    return getTotalSlots(corporateAttendee?.availability, step, timezone);
  }, [currentRequestType, currentCorporate, step, timezone, currentAttendee]);

  const slotsFilled = useMemo(() => {
    const availability = currentRequestType
      ? currentAttendee?.availability
      : currentCorporate?.attendees?.find((x) => !isEmpty(x.availability))?.availability;
    const currentSlots = getSlots(availability, step, timezone);

    const currentPresentations = currentRequestType
      ? []
      : presentations.filter((p) => {
          return currentCorporate?.attendees?.some((x) => {
            const corporateSpeaker = p?._speaker?.find((z) => z._attendee === x._id);
            return !isEmpty(corporateSpeaker);
          });
        });

    return currentSlots.reduce((count, slot) => {
      const slotRange = getSlotRange(slot, step);
      const isSlotFilled = meetingRequests.some((x) => {
        const meetingRange = getMeetingSlotRange(x);
        return slotRange.intersect(meetingRange) || meetingRange.contains(slotRange);
      });

      const isPresentationSlotFilled = currentPresentations.some((x) => {
        const presentationRange = getPresentationRange(x, true);
        return slotRange.intersect(presentationRange) || presentationRange.contains(slotRange);
      });

      return isSlotFilled || isPresentationSlotFilled ? count + 1 : count;
    }, 0);
  }, [
    currentAttendee?.availability,
    currentCorporate?.attendees,
    currentRequestType,
    meetingRequests,
    presentations,
    step,
    timezone,
  ]);

  return (
    <div id={id} className={SchedulerMetricBarClassName.Base}>
      <div className={SchedulerMetricBarClassName.Unscheduled}>{totalUnscheduledRequests} Requests</div>
      <div className={SchedulerMetricBarClassName.Slots}>
        <div>{`${slotsFilled} / ${totalSlots} Slots Filled`}</div>
      </div>
    </div>
  );
};

export default memo(SchedulerMetricBar);
