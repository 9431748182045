import React, { useMemo, memo, useCallback } from "react";
import { AnswerDataType, QuestionType, Question } from "../../services/questionGroup/questionGroup.model";
import { QuestionCardWrapperProps } from "../questionCardWrapper/questionCardWrapper.definition";
import { ChoiceQuestion } from "./choiceQuestion/choiceQuestion.component";
import { ConsentQuestion } from "./consentQuestion/consentQuestion.component";
import { QuestionCardProps, QuestionDisplayLabels, QuestionCardIdModel } from "./questionCard.definition";
import { SelectQuestion } from "./selectQuestion/selectQuestion.component";
import { TextQuestion } from "./textQuestion/textQuestion.component";

export function QuestionCard(props: QuestionCardProps): JSX.Element {
  const { id, question: currentQuestion, handleQuestionUpdate: handleQuestionUpdateProp, ...wrapperProps } = props;
  const { answer_type } = currentQuestion || {};
  const { field_type } = answer_type || {};

  const idModel = useMemo(() => new QuestionCardIdModel(id), [id]);

  const currentQuestionTypeLabel = useMemo(() => {
    switch (field_type) {
      case QuestionType.ShortAnswer:
      case QuestionType.LongAnswer:
        return QuestionDisplayLabels.text;

      case QuestionType.Choice:
      case QuestionType.MultipleChoice:
        return QuestionDisplayLabels.choice;

      case QuestionType.Selection:
      case QuestionType.CustomSelection:
        return QuestionDisplayLabels.select;

      case QuestionType.Consent:
        return QuestionDisplayLabels.consent;

      default:
        return QuestionDisplayLabels.text;
    }
  }, [field_type]);

  const getDataType = useCallback((questionType: QuestionType): AnswerDataType => {
    const answerTypes: { [Q in QuestionType]?: AnswerDataType } = {
      [QuestionType.MultipleChoice]: AnswerDataType.Array,
      [QuestionType.Consent]: AnswerDataType.Boolean,
    };

    return answerTypes[questionType] ?? AnswerDataType.String;
  }, []);

  const handleQuestionUpdate = useCallback(
    (updated: Question) => {
      const data_type = getDataType(updated.answer_type?.field_type);

      handleQuestionUpdateProp(
        new Question({
          ...updated,
          answer_type: {
            ...updated.answer_type,
            data_type,
          },
        })
      );
    },
    [getDataType, handleQuestionUpdateProp]
  );

  const defaultWrapperProps: QuestionCardWrapperProps = useMemo(
    () => ({
      id: idModel.questionCardWrapper.id,
      currentQuestion,
      handleQuestionUpdate,
      currentQuestionTypeLabel,
      ...wrapperProps,
    }),
    [idModel.questionCardWrapper.id, currentQuestion, handleQuestionUpdate, currentQuestionTypeLabel, wrapperProps]
  );

  return useMemo(() => {
    switch (currentQuestionTypeLabel) {
      case QuestionDisplayLabels.choice:
        return <ChoiceQuestion {...defaultWrapperProps} />;

      case QuestionDisplayLabels.select:
        return <SelectQuestion {...defaultWrapperProps} />;

      case QuestionDisplayLabels.consent:
        return <ConsentQuestion {...defaultWrapperProps} />;

      default:
        return <TextQuestion {...defaultWrapperProps} />;
    }
  }, [currentQuestionTypeLabel, defaultWrapperProps]);
}

export default memo(QuestionCard);
