import moment, { Moment } from "moment-timezone";
import { getAttendeeCorporateNames, getMeetingLabel } from "../../utils";
import { AttendeePayload, AttendeeType, AttendeeViewModel } from "../attendee/attendee.model";
import { Conference, VendorName } from "../conference/conference.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";
import { SessionBase, SessionEventType } from "../session/session.model";

export interface MeetingVendor {
  vendor: VendorName;
  url: string;
  password: string;
}

export enum MeetingLabel {
  None = "",
  OneOnOne = "1:1",
  TwoOnOne = "2:1",
  ThreeOnOne = "3:1",
  Group = "Group",
}

export class Meeting extends SessionBase {
  readonly type? = SessionEventType.Meeting;
  label?: string;
  start_date: Moment;
  end_date: Moment;
  urlOverride?: string;
  roomLocation?: string;
  labelOverride?: string;
  _attendee?: AttendeeViewModel[];
  meeting?: {
    vendor: MeetingVendor[];
  };

  get CorporateName(): string {
    return getAttendeeCorporateNames(this._attendee, AttendeeType.Corporate);
  }

  get InvestorCompany(): string {
    return getAttendeeCorporateNames(this._attendee, AttendeeType.Investor);
  }

  constructor(meeting: Partial<Meeting> | string) {
    super(meeting);
    if (typeof meeting === "string") return;

    const { start_date, end_date, _attendee, _conference, label, ...rest } = meeting ?? {};

    this.start_date = typeof start_date === "string" ? moment.utc(start_date) : start_date;
    this.end_date = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this._attendee = (_attendee || []).map((x) => new AttendeeViewModel(x));
    this._conference = new Conference(_conference);

    Object.assign(this, rest);
    this.label = getMeetingLabel(this);
  }
}

type MeetingPayloadBase = Omit<Meeting, "ConferenceId" | "vendor" | "type" | "label" | "CorporateName" | "InvestorCompany">;
export class MeetingPayload extends PayloadBase<MeetingPayloadBase> implements MeetingPayloadBase {
  start_date: Meeting["start_date"];
  end_date: Meeting["end_date"];
  _conference: Meeting["_conference"];
  _attendee: Meeting["_attendee"];

  constructor(meeting: Meeting) {
    super();
    const {
      ConferenceId,
      meeting: meetingVendor,
      label,
      type,
      vendor,
      _conference,
      CorporateName,
      InvestorCompany,
      _attendee,
      ...rest
    } = meeting;
    const conferenceId = typeof _conference === "string" ? _conference : _conference._id;
    const payload = { ...rest, _conference: conferenceId };
    const sanitizedPayload = this.sanitize(payload);
    const newAttendees = _attendee.map((attendee) => ({ ...new AttendeePayload(attendee), _id: attendee._id }));
    Object.assign(this, sanitizedPayload, { _attendee: newAttendees });
  }
}
