import { ChipsItemProps, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { uniq, intersection } from "lodash";
import config from "../../config/config";
import { AttendeeType } from "../../services/attendee/attendee.model";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Meeting } from "../../services/meeting/meeting.model";
import { MeetingLabel } from "../../services/meeting/meeting.model";
import { getCompanies } from "../company/company.utils";

export function getDisplayNames(attendees: AttendeeViewModel[], type?: AttendeeViewModel["type"]): string {
  if (isEmpty(attendees)) return "—";

  if (!isNullOrWhiteSpace(type)) {
    const foundAttendees = attendees.filter((x) => x.type === type);
    if (isEmpty(foundAttendees)) return "—";
    return foundAttendees.map((x) => x.display_name).join(", ");
  }

  return attendees.map((x) => x.display_name).join(", ");
}

export function getFriendlyNames(attendees: AttendeeViewModel[], type?: AttendeeViewModel["type"]): string {
  if (isEmpty(attendees)) return "—";

  if (!isNullOrWhiteSpace(type)) {
    const foundAttendees = attendees.filter((x) => x.type === type);
    if (isEmpty(foundAttendees)) return "—";
    return foundAttendees.map((x) => x.friendly_name).join(", ");
  }

  return attendees.map((x) => x.friendly_name).join(", ");
}

export function getAttendeeCorporateNames(attendees: AttendeeViewModel[], type?: AttendeeViewModel["type"]): string {
  if (isEmpty(attendees)) return "—";

  if (!isNullOrWhiteSpace(type)) {
    const foundAttendees = attendees.filter((x) => x.type === type);
    if (isEmpty(foundAttendees)) return "—";
    return uniq(foundAttendees.map((x) => x.company_name)).join(", ");
  }

  return uniq(attendees.map((x) => x.company_name)).join(", ");
}

export function getMeetingLabel(meeting: Meeting): MeetingLabel {
  if (isEmpty(meeting)) return MeetingLabel.None;

  const uniqueCompanies = getCompanies(
    (meeting?._attendee || []).filter((attendee) => attendee.type !== AttendeeType.Internal)
  );

  switch (uniqueCompanies.length) {
    case 0:
    case 1:
      return MeetingLabel.None;
    case 2:
      return MeetingLabel.OneOnOne;
    case 3:
      return MeetingLabel.TwoOnOne;
    case 4:
      return MeetingLabel.ThreeOnOne;
    case 5:
    default:
      return MeetingLabel.Group;
  }
}

export function getMeetingProxyUrl(meeting: Meeting | Meeting["_id"]): string {
  const meetingId = typeof meeting === "string" ? meeting : meeting?._id;
  if (isNullOrWhiteSpace(meetingId) || isNullOrWhiteSpace(config.api.url)) return null;

  return `${config.api.publicUrl}/go/meeting/${meetingId}`;
}

export function sortMeetingFormAttendeeChips(
  a: ChipsItemProps<AttendeeViewModel>,
  b: ChipsItemProps<AttendeeViewModel>
): number {
  return a.data.meeting_display_name.localeCompare(b.data.meeting_display_name);
}

export function getMeetingsWithAttendees(meetings: Meeting[], attendeeEmails: string[]): Meeting[] {
  if (isEmpty(meetings)) return [];

  return meetings.filter((meeting) => {
    const { _attendee } = meeting;

    if (isEmpty(_attendee)) return false;

    return intersection(
      _attendee.map((attendee) => {
        return attendee.email;
      }),
      attendeeEmails
    ).length;
  });
}
