import { Theme } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ProgressBarProps extends BaseComponentProps {
  val: number;
  max: number;
  theme?: ProgressBarTheme;
}

export enum ProgressBarTheme {
  Rain = Theme.Rain,
  Teal = Theme.Teal,
  Spice = Theme.Spice,
}
