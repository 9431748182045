import { IdModelBase, isNullOrWhiteSpace, SelectIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { CorporateProfileFormIdModel } from "../../../../../../components/corporateProfileForm/corporateProfileForm.definition";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { CorporateProfileHookModel } from "../../../../../../hooks/admin/useCorporateProfile/useCorporateProfile.definition";
import type { QuestionGroupHookModel } from "../../../../../../hooks/admin/useQuestionGroups/useQuestionGroups.definition";
import { AttendeesHookModel } from "../../../../../../hooks/useAttendees/useAttendees.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";

export interface CorporateProfilesProps extends Pick<BaseComponentProps, "id"> {
  conference: Conference;
  useCorporateProfiles: CorporateProfileHookModel;
  user: AdminUserContextState["user"];
  useQuestionGroups: QuestionGroupHookModel;
  useAttendees: AttendeesHookModel;
}

export enum CorporateProfilesClassName {
  Base = "corporate-profiles",
  Content = "corporate-profiles_content",
  Table = "corporate-profiles_table",
}

export enum CorporateProfileEditClassName {
  Base = "corporate-profiles-edit",
}

export enum CorporateProfilesTableHeader {
  Name = "NAME",
  Type = "TYPE",
  HostMeetings = "HOST MEETINGS",
  Attendees = "ATTENDEES",
}

export class CorporateProfilesIdModel extends IdModelBase {
  entityTable: EntityTableIdModel;
  form: CorporateProfileFormIdModel;
  type: SelectIdModel;
  hostMeetings: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.form = new CorporateProfileFormIdModel(`${this.id}Form`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.hostMeetings = new SelectIdModel(`${this.id}HostMeetingsSelect`);
  }
}
