import { BaseComponentWithChildrenProps, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { useAuth } from "../../hooks";
import { ApiResponse, AuthType } from "../../services/api/api.definition";
import type { AuthRequestAccessResponse, AuthRequestLinkResponse } from "../../services/auth/auth.model";
import type { Auth0Token, AuthSignInOptions } from "../../services/auth0/auth0.model";
import type { Company } from "../../services/company/company.model";
import PasswordlessTokenService from "../../services/passwordlessToken/passwordlessToken.service";
import {
  checkForErrors,
  getCompanyCustomPath,
  getCompanyUrlSuffix,
  getConferenceUrlSuffixWithLoginRoute,
} from "../../utils";
import type { ErrorCheck } from "../../utils/errors/errors.definition";
import { UserValidateErrorMessage } from "../baseUser/baseUser.definition";
import { UserContextState } from "./user.definition";

export const UserContext = createContext<Partial<UserContextState>>({});

export const UserProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [authType, setAuthType] = useState<AuthType>(AuthType.Passwordless);

  const { loading: authLoading, requestAccess, requestLink, validateAttendee, validateAttendeeByConferenceId } = useAuth();

  const loading = useMemo(() => authLoading, [authLoading]);

  const isAuthenticated = useCallback(() => {
    const passwordlessTokenService = new PasswordlessTokenService();
    const allTokens = passwordlessTokenService.getSanitizedTokenCollection();

    const companyCustomPath = getCompanyCustomPath();
    const companyUrlSuffix = getCompanyUrlSuffix();
    const conferenceSuffix = getConferenceUrlSuffixWithLoginRoute();
    const targetToken = allTokens.find(
      (x) =>
        (x?._customPath === companyCustomPath || x?.Profile?.company === companyUrlSuffix) &&
        (x?._conferencePath === conferenceSuffix || x?._conferenceId === conferenceSuffix)
    );

    passwordlessTokenService.setSessionId(targetToken);

    return !isEmpty(targetToken);
  }, []);

  const getUser = useCallback((): Auth0Token => {
    const tokenService = new PasswordlessTokenService();
    return tokenService.getTokenFromSession()?.Profile;
  }, []);

  const signOut = useCallback((options?: AuthSignInOptions) => {
    const tokenService = new PasswordlessTokenService();
    tokenService.signOut(options);
  }, []);

  const handleValidateAttendee = useCallback(
    async (
      emailCheck: ErrorCheck<string>,
      conferenceId: string,
      company: Company,
      passwordCheck?: ErrorCheck<string>
    ): Promise<ApiResponse<null>> => {
      const errors = checkForErrors(emailCheck, passwordCheck);

      if (errors) {
        return new ApiResponse({
          success: false,
          message: passwordCheck?.required
            ? UserValidateErrorMessage.EmailPasswordInvalid
            : UserValidateErrorMessage.EmailInvalid,
        });
      }

      const { url_suffix } = company || {};
      if (isNullOrWhiteSpace(url_suffix)) {
        return new ApiResponse({ success: false, message: UserValidateErrorMessage.AttendeeInvalid });
      }
      const email = emailCheck.value;

      const validatedAttendee = isNullOrWhiteSpace(conferenceId)
        ? () => validateAttendee(url_suffix, email)
        : () => validateAttendeeByConferenceId(url_suffix, conferenceId, email);

      const { userExists: isAttendee, message, openConferences, success } = await validatedAttendee();

      if (!isAttendee) {
        return new ApiResponse({ success: false, message: UserValidateErrorMessage.AttendeeInvalid });
      }

      if (!openConferences) {
        return new ApiResponse({
          success: false,
          message: UserValidateErrorMessage.UnavailableConference,
        });
      }

      if (!success) {
        return new ApiResponse({ success: false, message });
      }

      return null;
    },
    [validateAttendee, validateAttendeeByConferenceId]
  );

  const handleLoginPasswordless = useCallback(
    async (
      emailCheck: ErrorCheck<string>,
      conferenceId: string,
      company: Company,
      isPasscode = false
    ): Promise<ApiResponse<AuthRequestLinkResponse>> => {
      const validationResponse = await handleValidateAttendee(emailCheck, conferenceId, company);

      if (!isEmpty(validationResponse)) {
        return validationResponse;
      }

      const { url_suffix } = company || {};
      const email = emailCheck.value;

      return requestLink(url_suffix, conferenceId, email, isPasscode);
    },
    [requestLink, handleValidateAttendee]
  );

  const handleLogin = useCallback(
    async (
      emailCheck: ErrorCheck<string>,
      conferenceId: string,
      company: Company,
      passwordCheck: ErrorCheck<string>,
      code: string
    ): Promise<ApiResponse<AuthRequestAccessResponse>> => {
      const validationResponse = await handleValidateAttendee(emailCheck, conferenceId, company, passwordCheck);

      if (!isEmpty(validationResponse)) {
        return validationResponse;
      }

      const { url_suffix } = company || {};
      const email = emailCheck.value;
      const password = passwordCheck.value;

      return requestAccess(url_suffix, conferenceId, email, password, code);
    },
    [requestAccess, handleValidateAttendee]
  );

  const userContextState: UserContextState = {
    authType,
    authLoading,
    loading,
    user: getUser(),
    isAuthenticated,
    handleLogin,
    handleLoginPasswordless,
    signOut,
    setAuthType,
  };

  return <UserContext.Provider value={userContextState}>{props.children}</UserContext.Provider>;
};
