import { isEmpty } from "@q4/nimbus-ui";
import type { ISubmitEvent, UiSchema } from "@rjsf/core";
import React, { memo, useMemo, useState } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import type { JsonFieldPropsSchema } from "../../../../../components/jsonForm/jsonForm.definition";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../../services/attendee/attendee.model";
import { GuestAttendeeType } from "../../../../../services/conference/conference.model";
import { ErrorHandlerField } from "../../../../../services/errorHandler/errorHandler.definition";
import { validateEmail, validateText, trimFormData } from "../../../../../utils";
import { emailUnavailableError } from "../../registration.definition";
import { PersonalInformationIdModel, RegistrationPersonalInformationProps } from "./personalInformation.definition";

const RegistrationPersonalInformation = (props: RegistrationPersonalInformationProps): JSX.Element => {
  const {
    id,
    isEditMode = false,
    companyName,
    registrant,
    guestOtherTypesEnabled,
    onStep,
    onChange,
    checkAttendeeEmailsAvailable,
  } = props;

  const idModel = useMemo(() => new PersonalInformationIdModel(id), [id]);
  const isInternal = useMemo(() => registrant?.attendee_type === AttendeeType.Internal, [registrant?.attendee_type]);
  const isGuest = useMemo(() => registrant?.attendee_type === AttendeeType.Guest, [registrant?.attendee_type]);
  const [extraErrors, setExtraErrors] = useState({});

  const schema: JsonFieldPropsSchema = useMemo(() => {
    const { email } = registrant || {};
    const defaultCompany = isInternal ? companyName : "";
    const defaultSchema: JsonFieldPropsSchema = {
      type: "object",
      properties: {
        first_name: { id: idModel.firstName?.id, type: "string", title: "First Name", default: "" },
        last_name: { id: idModel.lastName?.id, type: "string", title: "Last Name", default: "" },
        title: { id: idModel.title?.id, type: "string", title: "Title", default: "" },
        email: {
          id: idModel.email?.id,
          type: "string",
          title: "Email",
          default: isEditMode ? email : "",
          readOnly: isEditMode,
        },
        company: {
          id: idModel.company?.id,
          type: "string",
          title: "Company",
          default: defaultCompany,
          readOnly: isInternal,
        },
      },
    };

    if (isInternal) {
      return defaultSchema;
    }

    const externalSchema: JsonFieldPropsSchema = {
      ...defaultSchema,
      properties: {
        ...defaultSchema.properties,
        sales_representative: {
          id: idModel.referral.id,
          type: "string",
          title: "Relationship Manager/Sales Representative Name",
          default: "",
        },
      },
    };

    if (isGuest && guestOtherTypesEnabled) {
      externalSchema.properties.guest_attendee_type = {
        id: idModel.otherSubType.id,
        type: "string",
        title: "Attendee Type",
        enum: Object.values(GuestAttendeeType),
        default: "",
      };
    }

    return externalSchema;
  }, [companyName, idModel, isInternal, isGuest, guestOtherTypesEnabled, isEditMode, registrant]);

  const uiSchema: UiSchema = useMemo(() => {
    return {
      "guest_attendee_type": {
        "ui:widget": "nuiSelect",
      },
      "ui:field": "layout",
      "ui:layout": [
        {
          fields: {
            first_name: { width: "1-of-2" },
            last_name: { width: "1-of-2" },
          },
        },
        {
          fields: {
            email: { width: "1-of-3" },
            company: { width: "1-of-3" },
            title: { width: "1-of-3" },
          },
        },
        {
          fields: {
            sales_representative: { width: "2-of-5" },
            guest_attendee_type: { width: "1-of-5" },
          },
        },
      ],
    };
  }, []);

  const errorHandlerFields = useMemo(() => {
    const customErrors = [];

    if (!isInternal) {
      // Company not mandatory for internal type
      customErrors.push(new ErrorHandlerField("company", "Company is required", validateText));

      if (!isGuest) {
        // Sales representative is not mandatory for internal and guest types
        customErrors.push(
          new ErrorHandlerField(
            "sales_representative",
            "Relationship Manager/Sales Representative Name is required",
            validateText
          )
        );
      } else if (guestOtherTypesEnabled) {
        customErrors.push(new ErrorHandlerField("guest_attendee_type", "Attendee Type is required", validateText));
      }
    }

    return [
      {
        fields: [
          new ErrorHandlerField("first_name", "First name is required", validateText),
          new ErrorHandlerField("last_name", "Last name is required", validateText),
          new ErrorHandlerField("title", "Title is required", validateText),
          new ErrorHandlerField("email", "A valid email is required", validateEmail),
          ...customErrors,
        ],
      },
    ];
  }, [isInternal, isGuest, guestOtherTypesEnabled]);

  async function handleSubmit(response: ISubmitEvent<RegistrantViewModel>): Promise<void> {
    if (isEmpty(response?.formData) || isEmpty(onChange)) {
      return;
    }

    const sanitizedRegistrant = new RegistrantViewModel(
      trimFormData({ ...registrant, ...response.formData, company: isInternal ? companyName : response.formData?.company })
    );

    const { email } = sanitizedRegistrant;

    onChange(sanitizedRegistrant);

    const result = checkAttendeeEmailsAvailable && (await checkAttendeeEmailsAvailable([email]));
    const isEmailAvailable = !checkAttendeeEmailsAvailable || result?.data?.[0]?.available;

    if (isEmailAvailable) {
      setExtraErrors({});
      onStep(true);
    } else {
      setExtraErrors({
        email: {
          __errors: [emailUnavailableError],
        },
      });
    }
  }

  return (
    <div id={idModel.id}>
      <JsonForm
        id={idModel.jsonForm?.id}
        uiSchema={uiSchema}
        errorHandlerFields={errorHandlerFields}
        schema={schema}
        data={registrant}
        onSubmit={handleSubmit}
        extraErrors={extraErrors}
      />
    </div>
  );
};

export default memo(RegistrationPersonalInformation);
