import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextIdModel } from "@q4/nimbus-ui";
import type { AnchorProps } from "@q4/nimbus-ui";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../../services/presentation/presentation.model";
import { SpeakersIdModel } from "../../../components/speakers/speakers.definition";
import type { LobbyHookModel } from "../../../hooks/useLobby/useLobby.definition";

export interface PresentationTheaterProps extends BaseComponentProps, Pick<LobbyHookModel, "profile" | "speaker"> {
  backButton?: AnchorProps;
  agendaLoading: boolean;
  hasAgendaPresentation: boolean;
  presentation: Presentation;
  url: string;
  lobbyConfig: Conference["lobby"];
  timeZone: string;
  onAgendaAdd: LobbyHookModel["handleAgendaAdd"];
  onAgendaRemove: LobbyHookModel["handleAgendaRemove"];
  onSpeakerChange: LobbyHookModel["handleSpeakerChange"];
}

export enum PresentationTheaterClassName {
  Base = "presentation-theater",
  BackButton = "presentation-theater_back-button",
  Date = "presentation-theater_date",
  Frame = "presentation-theater_frame",
  Presentation = "presentation-theater_presentation",
  Title = "presentation-theater_title",
  PollContainer = "presentation-theater_poll-container",
  PollFrame = "presentation-theater_poll-frame",
  PollFrameWithHeightModifier = "presentation-theater_poll-frame--has-height",
  PollEmpty = "presentation-theater_poll-empty",
}

export enum PresentationDetailsKey {
  Title = "presentation-details-title",
  Date = "presentation-details-date",
  Description = "presentation-details-decription",
  Agenda = "presentation-details-agenda",
}

export const StreamlineEmbedChannel = "papillon-live-feed-embed";

export type StreamlinePollData = { data: Record<string, unknown> };

export class PresentationTheaterIdModel extends IdModelBase {
  speakers: SpeakersIdModel;
  pollContainer: string;
  pollIframe: string;
  pollIframePlaceholder: string;
  pollSectionHeaderText: TextIdModel;
  postQuestionHeaderText: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.speakers = new SpeakersIdModel(`${this.id}Speakers`);
    this.pollContainer = `${this.id}PollContainer`;
    this.pollIframe = `${this.id}PollIframe`;
    this.pollIframePlaceholder = `${this.id}PollIframePlaceholder`;
    this.pollSectionHeaderText = new TextIdModel(`${this.id}PollSectionHeaderText`);
    this.postQuestionHeaderText = new TextIdModel(`${this.id}PostQuestionHeaderText`);
  }
}
