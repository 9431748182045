import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { useMemo, memo } from "react";
import { RegistrantViewModel } from "../../../../services/admin/registrant/registrant.model";
import CheckboxGroup from "../../../checkboxGroup/checkboxGroup.component";
import { JsonFieldProps } from "../../jsonForm.definition";

const CheckboxGroupField = (fieldProps: JsonFieldProps<RegistrantViewModel>): JSX.Element => {
  const { schema, onChange, formData: formDataProp, idSchema } = fieldProps;
  const { options, allowCustomAnswer, allowMultipleAnswers } = schema;

  const formData = useMemo(() => {
    if (isNullOrWhiteSpace(formDataProp as unknown as string)) return [];
    if (!Array.isArray(formDataProp)) return [formDataProp as unknown as string];
    return formDataProp;
  }, [formDataProp]);

  return (
    <CheckboxGroup
      id={schema.id ?? idSchema.$id}
      value={formData}
      items={options}
      allowCustomOption={allowCustomAnswer}
      isMultiChoiceMode={allowMultipleAnswers}
      onChange={onChange}
    />
  );
};

export default memo(CheckboxGroupField);
