import { Answer } from "../services/answer/answer.model";
import { Question, QuestionType } from "../services/questionGroup/questionGroup.model";

export const getOptionLabel = (optionNumber: number): string => `Option ${optionNumber}`;
export const isFirstOption = (indexNumber: number): boolean => indexNumber === 0;

export const getNoAnswerValue = (currentQuestion: Question): string[] | string | boolean => {
  switch (currentQuestion?.answer_type?.field_type) {
    case QuestionType.MultipleChoice:
      return [];
    case QuestionType.Choice:
      // For single choice question, storing empty string creates issue with JSON form validation, instead storing null value when no option selected
      return null;
    case QuestionType.Consent:
      return false;

    default:
      return "";
  }
};

export const modifyAnswerData = (answers: Answer[], questions: Question[], allInclusive = false): Answer[] => {
  return answers?.map((answer) => {
    const targetQuestion =
      typeof answer._question !== "string" ? answer._question : questions?.find((ques) => ques._id === answer._question);

    if (allInclusive) {
      return {
        ...answer,
        answer: !answer.answer ? getNoAnswerValue(targetQuestion as Question) : answer.answer,
        _question: targetQuestion?._id,
      };
    }
    return {
      _id: answer._id,
      answer: !answer.answer ? getNoAnswerValue(targetQuestion as Question) : answer.answer,
      _question: targetQuestion?._id,
    };
  });
};
