import { isEmpty } from "@q4/nimbus-ui";
import { AdminRouteBase, UserRouteBase, PublicRouteBase } from "../../configurations/navigation.configuration";
import { Entity } from "../../definitions/entity.definition";
import { capitalizeFirstLetter } from "../../utils/string/string.utils";
import { ApiResponse, AuthType } from "../api/api.definition";
import ApiService from "../api/api.service";
import { Company } from "../company/company.model";
import { QuestionGroup } from "../questionGroup/questionGroup.model";
import { Speaker } from "../speaker/speaker.model";
import { Track } from "../track/track.model";
import { Conference, ConferencePayload, VendorStatusResponse } from "./conference.model";

export default class ConferenceService {
  private readonly COMPANIES_API_PATH = `${UserRouteBase}/companies`;
  private readonly PUBLIC_CONFERENCE_API_PATH = `${PublicRouteBase}/conferences/v1`;
  private readonly ADMIN_CONFERENCE_API_PATH = `${AdminRouteBase}/conferences/v1`;
  private readonly CONFERENCE_REPORT_API_PATH = "/exportreportsadmin";
  private readonly CONFERENCE_QUESTION_GROUP_API_PATH = `${this.PUBLIC_CONFERENCE_API_PATH}/questiongroups`;
  private readonly CONFERENCE_SCHEDULER_API_PATH = `${this.ADMIN_CONFERENCE_API_PATH}/scheduler`;
  private apiService = new ApiService();

  public async getConferences(): Promise<ApiResponse<Conference[]>> {
    const response = await this.apiService.get<Conference[]>(`${this.ADMIN_CONFERENCE_API_PATH}`);
    return this.mapConferencesResponse(response);
  }

  public async getPublicConferenceByPath(path: Conference["_id"]): Promise<ApiResponse<Conference>> {
    const response = await this.apiService.get<Conference>(
      `${this.PUBLIC_CONFERENCE_API_PATH}?conf_path=${path}`,
      AuthType.Public
    );
    return this.mapConferenceByIdResponse(response);
  }

  public async getConferenceById(_id: Conference["_id"]): Promise<ApiResponse<Conference>> {
    const response = await this.apiService.get<Conference>(`${this.ADMIN_CONFERENCE_API_PATH}/${_id}`);
    return this.mapConferenceByIdResponse(response);
  }

  public postConference = (data: Conference): Promise<ApiResponse<Conference>> => {
    const payload = new ConferencePayload(data);
    return this.apiService
      .post<ConferencePayload, Conference>(`${this.ADMIN_CONFERENCE_API_PATH}`, payload)
      .then((response) => this.mapConferenceByIdResponse(response, data));
  };

  public putConferenceById(_id: Conference["_id"], data: Conference): Promise<ApiResponse<Conference>> {
    const payload = new ConferencePayload(data);
    return this.apiService
      .put<ConferencePayload, Conference>(`${this.ADMIN_CONFERENCE_API_PATH}/${_id}`, payload)
      .then((response) => this.mapConferenceByIdResponse(response, data));
  }

  public async patchConferenceSchedulerById(
    _id: Conference["_id"],
    data: Conference["scheduler"]
  ): Promise<ApiResponse<Conference["scheduler"]>> {
    return this.apiService
      .patch<Conference["scheduler"]>(`${this.CONFERENCE_SCHEDULER_API_PATH}/${_id}`, data)
      .then((response) => new ApiResponse(response));
  }

  public async deleteConferenceById(_id: Conference["_id"]): Promise<ApiResponse<Conference>> {
    const response = await this.apiService.delete<Conference>(`${this.ADMIN_CONFERENCE_API_PATH}/${_id}`);
    return this.mapConferenceByIdResponse(response);
  }

  public async getConferenceReportById(_id: Conference["_id"]): Promise<ApiResponse<Blob>> {
    const response = await this.apiService.requestBlob(`${this.CONFERENCE_REPORT_API_PATH}/${_id}`, AuthType.Protected);
    const file = response?.data;
    saveAs(file, "conference_report");
    return response;
  }

  public async clearConferenceEntityById(
    _id: Conference["_id"],
    type: Entity,
    future = false
  ): Promise<ApiResponse<Conference>> {
    const clear = future ? "cleanFuture" : "clean";
    if (type !== Entity.Meeting && type !== Entity.Presentation) {
      throw new Error("Invalid entity type to clear from conference");
    }
    const response = await this.apiService.delete<Conference>(
      `${this.ADMIN_CONFERENCE_API_PATH}/${clear}${capitalizeFirstLetter(type)}s/${_id}`
    );
    return this.mapConferenceByIdResponse(response);
  }

  public async getConferenceCompaniesById(_id: Conference["_id"], onlyChat = false): Promise<ApiResponse<Array<string>>> {
    const path = !onlyChat ? `${_id}?chat=0` : `${_id}`;
    return this.apiService.get<Array<string>>(`${this.COMPANIES_API_PATH}/${path}`, AuthType.Passwordless);
  }

  public async checkPresentationVendorAccess(hubId: string): Promise<ApiResponse<VendorStatusResponse>> {
    const path = `vendorStatus/${hubId}`;
    return this.apiService.get<VendorStatusResponse>(`${this.ADMIN_CONFERENCE_API_PATH}/${path}`);
  }

  private mapConferenceByIdResponse(response: ApiResponse<Conference>, payload?: Conference): ApiResponse<Conference> {
    const data = new Conference(response?.data);

    const conference =
      isEmpty(payload) || !response?.success
        ? data
        : new Conference({
            ...data,
            _company: new Company({ ...payload._company, _id: data._company?._id }),
            _track: payload._track.map(
              (track) => new Track({ ...data._track.find((_track) => track?._id === _track._id), ...track })
            ),
            _speaker: payload._speaker.map(
              (speaker) => new Speaker({ ...data._speaker.find((_speaker) => speaker?._id === _speaker._id), ...speaker })
            ),
            corporate_profiles: payload.corporate_profiles,
          });
    return new ApiResponse({ ...response, data: conference });
  }

  private mapConferencesResponse(response: ApiResponse<Conference[]>): ApiResponse<Conference[]> {
    return new ApiResponse({
      ...response,
      data: (response?.data ?? []).map((x) => new Conference(x)),
    });
  }

  public getConferenceQuestionGroupsById(_id: Conference["_id"]): Promise<ApiResponse<Partial<QuestionGroup>>> {
    return this.apiService.get(`${this.CONFERENCE_QUESTION_GROUP_API_PATH}/${_id}`, AuthType.Public);
  }
}
