import "./termsAndConditions.component.scss";
import { Anchor, AnchorTarget, Grid, GridColumn, RadioButton, Text, TextPreset } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { PrivacyLink, TermOfUseLink } from "../../../../const/registration.const";
import { RegistrationSummaryClassName } from "../components/summary/summary.definition";
import { TermsAndConditionsIdModel, TermsAndConditionsProps } from "./termsAndConditions.definition";

const TermsAndConditions = (props: TermsAndConditionsProps): JSX.Element => {
  const { id, policyAgree, onAgree } = props;

  const idModel = useMemo(() => new TermsAndConditionsIdModel(id), [id]);

  function renderPolicyButtons(): JSX.Element {
    function handleRadioChange(_checked: boolean, value: string) {
      onAgree(value === "yes");
    }

    return (
      <>
        <RadioButton
          id={idModel.policyAgreementYes?.id}
          key="policyAgreement-yes"
          name="policyAgreement"
          label="Yes"
          value="yes"
          inline={true}
          onChange={handleRadioChange}
          checked={policyAgree}
        />
        <RadioButton
          id={idModel.policyAgreementNo?.id}
          key="policyAgreement-no"
          name="policyAgreement"
          label="No"
          value="no"
          inline={true}
          onChange={handleRadioChange}
          checked={!policyAgree}
        />
      </>
    );
  }

  return (
    <div className={RegistrationSummaryClassName.Policy}>
      <Grid>
        <GridColumn width="2-of-3" smallWidth="1-of-1">
          <Text preset={TextPreset.Paragraph}>
            This event is hosted by Q4 on behalf of the event organizer. By providing your information and clicking Confirm
            Registration, you agree to our{" "}
            <Anchor url={TermOfUseLink} target={AnchorTarget.Blank}>
              Terms of Use
            </Anchor>{" "}
            and{" "}
            <Anchor url={PrivacyLink} target={AnchorTarget.Blank}>
              Privacy Policy
            </Anchor>
            .
          </Text>
        </GridColumn>
      </Grid>
      {renderPolicyButtons()}
    </div>
  );
};

export default memo(TermsAndConditions);
