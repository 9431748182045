import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  PaginationIdModel,
  PaginationProps,
  PaginationSize,
} from "@q4/nimbus-ui";
import { CorporateProfile } from "../../../../../../../services/corporateProfile/corporateProfile.model";
import { InvestorFormMeetingRequests } from "../../../meetingRequestForm/meetingRequestForm.definition";

export interface MeetingIssuersProps extends BaseComponentProps {
  corporateProfiles: CorporateProfile[];
  meetingRequestsObject: InvestorFormMeetingRequests;
  onRequestSelect: (id: CorporateProfile["_id"]) => void;
  onJumpToRequests: () => void;
}

export enum MeetingIssuersClassName {
  Base = "meeting-issuers",
  RequestedMeetingsContainer = "meeting-issuers_requested-meetings-container",
  RequestedMeetingsButton = "meeting-issuers_requested-meetings-button",
  NoCorporates = "meeting-issuers_no-corporates",
  Corporate = "meeting-issuers_corporate-profile",
  CorporateHeader = "meeting-issuers_corporate-profile-header",
  CorporateRow = "meeting-issuers_corporate-profile-row",
  CorporateInfo = "meeting-issuers_corporate-profile-info",
  CorporateLogo = "meeting-issuers_corporate-profile-logo",
  CorporateName = "meeting-issuers_corporate-name",
  CorporateTicker = "meeting-issuers_corporate-ticker",
  CorporateIndustry = "meeting-issuers_corporate-industry",
  CorporateAttendees = "meeting-issuers_corporate-attendees",
  CorporateMeetingRequest = "meeting-issuers_corporate-meeting-request",
  RequestButton = "meeting-issuers_request-button",
  RequestButtonRequested = "meeting-issuers_request-button--requested",
  Pagination = "meeting-issuers_corporate-pagination",
}

export class MeetingIssuersIdModel extends IdModelBase {
  requestedMeetings: ButtonIdModel;
  pagination: PaginationIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.requestedMeetings = new ButtonIdModel(`${this.id}RequestedMeetingsButton`);
    this.pagination = new PaginationIdModel(`${this.id}Pagination`);
  }
}

export const MeetingIssuerPaginationOptions: Partial<PaginationProps> = {
  startFromZero: true,
  showPageSizeSelection: true,
  pageSizeOptions: [25, 50, 100],
  size: PaginationSize.Small,
};
