import { NotificationService } from "@q4/nimbus-ui";
import { useMemo, useCallback } from "react";
import { useAutoFetch } from "..";
import { Entity } from "../../definitions/entity.definition";
import type { Conference } from "../../services/conference/conference.model";
import { Meeting } from "../../services/meeting/meeting.model";
import MeetingService from "../../services/meeting/meeting.service";
import { mapAttendeesToMeeting, mapAttendeesToMeetings } from "../../utils/scheduler/scheduler.utils";
import { useService } from "../useService/useService.hook";
import type { MeetingHookModel, MeetingsHookProps } from "./useMeeting.definition";

export const useMeetings = (props: MeetingsHookProps): MeetingHookModel => {
  const { attendees, autoFetchData, conferenceId, conferenceTimeZone, data, showNotifications, useOffline } = props;

  const {
    current: rawMeeting,
    items: rawMeetings,
    loading,
    service: meetingService,
    fetch,
    post,
    deleteById,
    putById,
    setCurrent,
    setItems,
    setLoading,
  } = useService({
    autoFetchData: false,
    data,
    entityName: Entity.Meeting,
    showNotifications,
    useOffline,
    entityModel: Meeting,
    serviceModel: MeetingService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const meetings = useMemo(() => {
    return mapAttendeesToMeetings(rawMeetings, attendees, conferenceTimeZone);
  }, [attendees, conferenceTimeZone, rawMeetings]);

  const meeting = useMemo(() => {
    return mapAttendeesToMeeting(rawMeeting, attendees, conferenceTimeZone);
  }, [attendees, conferenceTimeZone, rawMeeting]);

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Meeting[]> => {
      setLoading(true);
      return meetingService.getByConferenceId(_id).then((response): Meeting[] => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error("Failed to load meetings.");
          return null;
        }

        const responseData = response.data ?? [];

        setItems(responseData);
        return responseData;
      });
    },
    [setLoading, meetingService, setItems, showNotifications, notificationService]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetch,
    fetchBy: getByConferenceId,
    setEntities: setItems,
  });

  return {
    current: meeting,
    loading,
    items: meetings,
    service: meetingService,
    fetch,
    post,
    putById,
    deleteById,
    setCurrent,
    setItems,
    setLoading,
    fetchConferenceMeetings: getByConferenceId,
  };
};
