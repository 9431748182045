import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import DOMPurify from "dompurify";
import htmlReactParser from "html-react-parser";
import { htmlParseDefaultOptions } from "./htmlOption.utils";

export function htmlParse(html: string, parseOptions = htmlParseDefaultOptions): string | JSX.Element | JSX.Element[] {
  if (isNullOrWhiteSpace(html)) return "";

  const sanitized = sanitizeHtml(html);

  if (isNullOrWhiteSpace(sanitized)) return "";

  return htmlReactParser(sanitized, parseOptions);
}

export function sanitizeHtml(html: string): string {
  if (isNullOrWhiteSpace(html)) return "";

  return DOMPurify.sanitize(html, { FORBID_TAGS: ["form", "button", "input", "style", "script"] });
}

export function htmlToText(html: string): string {
  if (isNullOrWhiteSpace(html)) return "";
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
}
