import { Dispatch, SetStateAction } from "react";
import { RegistrationStep } from "../registration.definition";

export interface Section {
  component: JSX.Element;
  id: string;
  label: string;
}

export interface RegistrationSectionsProps {
  sections: Section[];
  tabStep: number;
  setRegistrationStep?: Dispatch<SetStateAction<RegistrationStep>>;
}

export enum RegistrationSectionsClassName {
  Base = "registration_sections",
  Tabs = "registration_tabs",
}
