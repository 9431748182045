import "./livePresentation.component.scss";
import { Anchor, AnchorTarget, Collapsable, CollapsableDirection, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import fallbackImage from "../../../../../assets/placeholder/defaultLivePresentation.png";
import { LobbyDefaults } from "../../../../../services/conference/conference.model";
import { Presentation } from "../../../../../services/presentation/presentation.model";
import { getLobbySessionUrl } from "../../../../../utils";
import { LivePresentationClassName, LivePresentationIdModel, LivePresentationProps } from "./livePresentation.definition";

// TODO: LivePresentation should prioritize live Agenda presentations in case of intersection
function LivePresentation(props: LivePresentationProps): JSX.Element {
  const { id, className, conference, now, profile, visible: visibleProp } = props;
  const { _presentation: presentations, lobby, _company } = conference ?? {};
  const { url_suffix, custom_path } = _company ?? {};

  const idModel = useMemo(() => new LivePresentationIdModel(id), [id]);

  const backgroundImageSrc = useMemo(
    () => (isNullOrWhiteSpace(conference?.image_live) ? fallbackImage : conference.image_live),
    [conference?.image_live]
  );

  const visible = useMemo(() => (!isNil(visibleProp) ? visibleProp : true), [visibleProp]);

  const livePresentationLabels = useMemo(
    () => ({
      title: lobby?.livePresentation?.title ?? LobbyDefaults.livePresentation.title,
      label: lobby?.livePresentation?.label ?? LobbyDefaults.livePresentation.label,
    }),
    [lobby?.livePresentation?.label, lobby?.livePresentation?.title]
  );

  const livePresentation = useMemo(() => {
    function getLivePresentation(): Presentation {
      if (isEmpty(presentations) || isEmpty(now)) return null;
      return presentations.find((presentation): boolean => {
        const { start_date, end_date } = presentation;

        return start_date.isBefore(now) && end_date.isAfter(now);
      });
    }
    return getLivePresentation();
  }, [now, presentations]);
  const hasLivePresentation = useMemo(() => !isEmpty(livePresentation), [livePresentation]);

  const isExclusivePresentation = useMemo(() => {
    if (isEmpty(livePresentation?.code)) return false;
    if (!isEmpty(livePresentation?.code) && isEmpty(profile?.code)) return true;
    return !livePresentation.code.filter((code) => profile?.code?.includes(code)).length;
  }, [livePresentation?.code, profile?.code]);

  return (
    <Collapsable
      className={className}
      collapsed={!hasLivePresentation || isExclusivePresentation || !visible}
      direction={CollapsableDirection.Horizontal}
      inline
    >
      <div id={idModel.id} className={LivePresentationClassName.Base}>
        <Anchor
          id={idModel.anchor}
          className={LivePresentationClassName.Anchor}
          url={getLobbySessionUrl(
            livePresentation?.type,
            { url_suffix, custom_path },
            conference?.Path,
            livePresentation?._id
          )}
          target={AnchorTarget.Blank}
        >
          <img
            id={idModel.image}
            className={LivePresentationClassName.Image}
            src={backgroundImageSrc}
            alt={livePresentationLabels.label}
          />
          <div id={idModel.label} className={LivePresentationClassName.Label}>
            <i />
            <span>{livePresentationLabels.title}</span>
            <span className="sr-only">(opens in new tab)</span>
          </div>
        </Anchor>
      </div>
    </Collapsable>
  );
}

export default memo(LivePresentation);
