import { IdModelBase, ButtonListId, BannerIdModel, ButtonIdModel, isNullOrWhiteSpace, SelectIdModel } from "@q4/nimbus-ui";

export const CompanySelectKey = "_company.name";

export const ConferenceCompanyQueryParam = "company";

export interface ConferencesPaginationState {
  currentPage: number;
  pageSize: number;
  pageCount: number;
}

export enum ConferencesClassName {
  Base = "conferences",
  Table = "conferences_table",
  TableButton = "conferences_table-button",
  IconCell = "conferences_icon-cell",
  ImageCell = "conferences_image-cell",
  TableFilters = "conferences_table-filters",
}

class PageIdModel extends IdModelBase {
  import: ButtonListId;
  banner: BannerIdModel;
  table: string;
  schedule: ButtonIdModel;
  search: string;
  company: SelectIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.banner = new BannerIdModel(`${this.id}Banner`);
    this.import = new ButtonListId(`${this.id}Import`);
    this.schedule = new ButtonIdModel(`${this.id}ScheduleButton`);
    this.search = `${this.id}Search`;
    this.table = `${this.id}Table`;
    this.company = new SelectIdModel(`${this.id}CompanySelect`);
  }
}

const PageId = "Conference";
export const ConferenceIdModel = new PageIdModel(PageId);
