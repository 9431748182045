import "./meetingShellCreator.component.scss";
import {
  Button,
  ButtonTheme,
  DatePicker,
  DatePickerTheme,
  Form,
  Select,
  SelectTheme,
  Text,
  TimePicker,
  Origin,
  SelectPreset,
  isEmpty,
  DateRangePickerValue,
  isNil,
  ErrorModel,
} from "@q4/nimbus-ui";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DefaultTimeZone, SchedulerDefaults } from "../../../../../../../../const";
import { ConferenceScheduler } from "../../../../../../../../services/conference/conference.model";
import {
  breakOptions,
  convertHourToPickerFormat,
  convertPickerFormatToMinutes,
  durationOptions,
} from "../../../../../../../../utils/scheduler/scheduler.utils";
import { buildSchedulerSlots } from "../../../meetingScheduler/meetingScheduler.utils";
import {
  MeetingShellCreatorClassName,
  MeetingShellCreatorIdModel,
  MeetingShellCreatorProps,
} from "./meetingShellCreator.definition";

const MeetingShellCreator = (props: MeetingShellCreatorProps): JSX.Element => {
  const { id, conference, scheduler, saving, setSchedule, onSubmit } = props;
  const idModel = useMemo(() => new MeetingShellCreatorIdModel(id), [id]);

  const [showError, setShowError] = useState(false);

  const conferenceTimeZone = useMemo(() => conference?.time_zone || DefaultTimeZone, [conference?.time_zone]);
  const conferenceStartDate = useMemo(
    () => conference?.start_date.clone().tz(conferenceTimeZone) || moment(),
    [conference?.start_date, conferenceTimeZone]
  );
  const conferenceEndDate = useMemo(
    () => conference?.end_date.clone().tz(conferenceTimeZone) || moment(),
    [conference?.end_date, conferenceTimeZone]
  );

  useEffect(() => {
    if (isEmpty(scheduler?.start_date) && isEmpty(scheduler?.end_date) && isEmpty(scheduler?.slots)) {
      // If scheduler has not been created, set defaults
      const defaultStart = conferenceStartDate.clone().tz(conferenceTimeZone);
      const defaultEnd = conferenceEndDate.clone().tz(conferenceTimeZone);
      setSchedule({
        start_date: defaultStart,
        end_date: defaultEnd,
        start_time: SchedulerDefaults.startTime,
        end_time: SchedulerDefaults.endTime,
        duration: SchedulerDefaults.duration,
        break: SchedulerDefaults.break,
        slots: [],
      });
    }
  }, [scheduler, setSchedule, conferenceStartDate, conferenceEndDate, conferenceTimeZone]);

  const endTimeValues = useMemo(() => {
    if (isNil(scheduler?.start_time)) {
      return {
        time: undefined,
        startHour: 0,
      };
    }
    const startHour = (scheduler.start_time / 1440) * 24 + 0.09; // Minimum end time is at 12:05am

    // Midnight is not a valid endtime option, returning undefined so select uses placeholder value
    if (!scheduler.end_time) {
      return {
        time: undefined,
        startHour: startHour,
      };
    }

    return {
      time: scheduler.end_time,
      startHour: startHour,
    };
  }, [scheduler]);

  const handleRangeChange = useCallback(
    (value: DateRangePickerValue) => {
      if (isEmpty(value)) return;

      const { startValue: start_date, endValue: end_date } = value;
      setSchedule((current) => ({ ...current, start_date, end_date }));
    },
    [setSchedule]
  );

  const handleFormChange = useCallback(
    (data: Partial<ConferenceScheduler>) => {
      setSchedule((current) => ({ ...current, ...data }));
    },
    [setSchedule]
  );

  const handleTimeChange = useCallback(
    (data: Partial<ConferenceScheduler>) => {
      setShowError(false);
      if (data.start_time) {
        const isStartBeforeEnd = data.start_time > scheduler.end_time;

        if (isStartBeforeEnd) {
          // Invalidate end time if start time crosses end time, end time cannot be set as midnight
          setSchedule((current) => ({ ...current, ...data, end_time: undefined }));
          return;
        }
      }

      setSchedule((current) => ({ ...current, ...data }));
    },
    [scheduler, setSchedule]
  );

  const handleSubmit = useCallback(() => {
    if (isNil(endTimeValues.time)) {
      setShowError(true);
      return;
    }
    const slots = buildSchedulerSlots(scheduler, conferenceTimeZone);

    onSubmit({
      ...scheduler,
      start_date: moment(scheduler?.start_date).clone().startOf("day").tz(conferenceTimeZone, true),
      end_date: moment(scheduler?.end_date).clone().startOf("day").tz(conferenceTimeZone, true),
      slots,
    });
  }, [conferenceTimeZone, endTimeValues.time, onSubmit, scheduler]);

  const formFields = useMemo(
    () => [
      {
        key: "Date Range",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Date Range",
        children: (
          <DatePicker
            id={idModel.dateRange?.id}
            className={MeetingShellCreatorClassName.DateRange}
            theme={DatePickerTheme.White}
            range={true}
            startValue={moment(scheduler?.start_date).clone()}
            endValue={moment(scheduler?.end_date).clone()}
            disabledDays={[
              { before: new Date(conferenceStartDate.clone().startOf("day").format()) },
              { after: new Date(conferenceEndDate.clone().endOf("day").format()) },
            ]}
            disabled={saving}
            onRangeChange={handleRangeChange}
          />
        ),
      },
      {
        key: "Start Time",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Start Time",
        children: (
          <TimePicker
            id={idModel.startTime?.id}
            theme={DatePickerTheme.White}
            minuteSkip={5}
            startHour={0}
            endHour={23.9}
            noOptionsMessageText="No Options"
            value={convertHourToPickerFormat(scheduler?.start_time)}
            preset={SelectPreset.Autocomplete}
            disabled={saving}
            onChange={(value: string) => handleTimeChange({ start_time: convertPickerFormatToMinutes(value) })}
          />
        ),
      },
      {
        key: "End Time",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "End Time",
        error: new ErrorModel("End time is required", showError && isNil(endTimeValues.time)),
        children: (
          <TimePicker
            id={idModel.endTime?.id}
            theme={DatePickerTheme.White}
            minuteSkip={5}
            startHour={endTimeValues.startHour}
            noOptionsMessageText="No Options"
            placeholder={"Select"}
            value={convertHourToPickerFormat(scheduler?.end_time)}
            preset={SelectPreset.Autocomplete}
            disabled={saving}
            onChange={(value: string) => handleTimeChange({ end_time: convertPickerFormatToMinutes(value) })}
          />
        ),
      },
      {
        key: "Meeting Duration (Minutes)",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Meeting Duration (Minutes)",
        children: (
          <Select
            id={idModel.duration?.id}
            menuPlacement={Origin.Top}
            theme={SelectTheme.White}
            value={`${scheduler?.duration}`}
            options={durationOptions}
            preset={SelectPreset.Autocomplete}
            disabled={saving}
            onChange={(value) => handleFormChange({ duration: parseInt(value) })}
          />
        ),
      },
      {
        key: "Break Duration (Minutes)",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Break Duration (Minutes)",
        children: (
          <Select
            id={idModel.break?.id}
            menuPlacement={Origin.Top}
            theme={SelectTheme.White}
            value={`${scheduler?.break}`}
            options={breakOptions}
            preset={SelectPreset.Autocomplete}
            disabled={saving}
            onChange={(value) => handleFormChange({ break: parseInt(value) })}
          />
        ),
      },
    ],
    [
      idModel,
      scheduler,
      saving,
      conferenceStartDate,
      conferenceEndDate,
      endTimeValues,
      showError,
      handleRangeChange,
      handleTimeChange,
      handleFormChange,
    ]
  );

  return (
    <div className={MeetingShellCreatorClassName.Base}>
      <div id={idModel.container} className={MeetingShellCreatorClassName.Content}>
        <Text>Use the below fields to create your meeting schedule.</Text>
        <Form id={idModel.form?.id} className={MeetingShellCreatorClassName.Form} fields={formFields} />
        <Button
          id={idModel.createScheduleButton?.id}
          className={MeetingShellCreatorClassName.Button}
          theme={ButtonTheme.Rain}
          label={"CREATE SCHEDULE"}
          onClick={handleSubmit}
          loading={saving}
        />
      </div>
    </div>
  );
};

export default memo(MeetingShellCreator);
