import { isEmpty } from "@q4/nimbus-ui";
import type { ISubmitEvent, FormProps } from "@rjsf/core";
import { UiSchema } from "@rjsf/core";
import React, { memo, useMemo, useState } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { ErrorHandlerField } from "../../../../../services/errorHandler/errorHandler.definition";
import { InvestorType } from "../../../../../services/investor/investor.model";
import { trimFormData, validateEmail, validateText } from "../../../../../utils";
import { RegistrationFormProps, RegistrationFormTitle } from "../../definition/registrationForm.definition";
import { emailUnavailableError } from "../../registration.definition";
import { InvestorPersonalInformationIdModel } from "./investorPersonalInformation.definition";

const InvestorPersonalInformation = (props: RegistrationFormProps): JSX.Element => {
  const { id, registrant, onStep, onChange, checkAttendeeEmailsAvailable, isEditMode } = props;

  const idModel = useMemo(() => new InvestorPersonalInformationIdModel(id), [id]);
  const [extraErrors, setExtraErrors] = useState({});

  const schema: FormProps<RegistrantViewModel>["schema"] = useMemo(() => {
    const { email } = registrant || {};
    return {
      type: "object",
      dependencies: {
        has_secondary_contact: {
          oneOf: [
            {
              properties: {
                has_secondary_contact: { enum: [true] },
                secondary_contact: {
                  type: "object",
                  title: "This person can communicate on your behalf.",
                  properties: {
                    first_name: {
                      id: idModel.secondaryFirstName.id,
                      type: "string",
                      title: "First Name",
                    },
                    last_name: {
                      id: idModel.secondaryLastName.id,
                      type: "string",
                      title: "Last Name",
                    },
                    title: { id: idModel.secondaryTitle.id, type: "string", title: "Title", default: "" },
                    email: { id: idModel.secondaryEmail.id, type: "string", title: "Email", default: "" },
                    phone_number: { id: idModel.secondaryPhone.id, type: "string", title: "Phone", default: "" },
                    receive_emails: {
                      id: idModel.sendEmail,
                      type: "boolean",
                      title: "Send copy of all email communications to this assistant?",
                      default: false,
                    },
                  },
                },
              },
            },
            {
              properties: {
                has_secondary_contact: { enum: [false] },
              },
            },
          ],
        },
      },
      properties: {
        first_name: { id: idModel.firstName.id, type: "string", title: RegistrationFormTitle.firstName, default: "" },
        last_name: { id: idModel.lastName.id, type: "string", title: RegistrationFormTitle.lastName, default: "" },
        title: { id: idModel.title.id, type: "string", title: RegistrationFormTitle.title, default: "" },
        company: { id: idModel.company.id, type: "string", title: RegistrationFormTitle.company, default: "" },
        email: {
          id: idModel.email.id,
          type: "string",
          title: RegistrationFormTitle.email,
          default: isEditMode ? email : "",
          readOnly: isEditMode,
        },
        phone_number: { id: idModel.phone.id, type: "string", title: RegistrationFormTitle.phoneNumber, default: "" },
        sales_representative: {
          id: idModel.referral.id,
          type: "string",
          title: RegistrationFormTitle.salesRepresentative,
          default: "",
          maxLength: 140,
        },
        investor_type: {
          id: idModel.investorType.id,
          type: "string",
          enum: Object.values(InvestorType),
          title: RegistrationFormTitle.investorType,
          default: "",
        },
        has_secondary_contact: {
          id: idModel.addSecondary,
          type: "boolean",
          default: false,
          title: RegistrationFormTitle.hasSecondaryContact,
        },
      },
    };
  }, [idModel, isEditMode, registrant]);

  const uiSchema: UiSchema = useMemo(() => {
    return {
      "investor_type": {
        "ui:widget": "nuiSelect",
      },
      "has_secondary_contact": {
        "ui:widget": "nuiRadio",
      },
      "secondary_contact": {
        "receive_emails": {
          "ui:widget": "nuiRadio",
        },
        "ui:field": "layout",
        "ui:layout": [
          {
            fields: {
              first_name: { width: "1-of-2" },
              last_name: { width: "1-of-2" },
            },
          },
          {
            fields: {
              title: { width: "1-of-2" },
              phone_number: { width: "1-of-2" },
            },
          },
          {
            fields: {
              email: { width: "1-of-2" },
            },
          },
          {
            fields: {
              receive_emails: { width: "1-of-1" },
            },
          },
        ],
      },
      "ui:field": "layout",
      "ui:layout": [
        {
          fields: {
            first_name: { width: "1-of-2" },
            last_name: { width: "1-of-2" },
          },
        },
        {
          fields: {
            title: { width: "2-of-4" },
            phone_number: { width: "1-of-4" },
          },
        },
        {
          fields: {
            email: { width: "1-of-3" },
            company: { width: "1-of-3" },
            investor_type: { width: "1-of-3" },
          },
        },
        {
          fields: {
            sales_representative: { width: "1-of-1" },
          },
        },
        {
          fields: {
            has_secondary_contact: { width: "1-of-1" },
          },
        },
        {
          fields: {
            secondary_contact: {
              width: "1-of-1",
            },
          },
        },
      ],
    };
  }, []);

  const errorHandlerFields = useMemo(
    () => [
      {
        fields: [
          new ErrorHandlerField("first_name", "First name is required", validateText),
          new ErrorHandlerField("last_name", "Last name is required", validateText),
          new ErrorHandlerField("company", "Company is required", validateText),
          new ErrorHandlerField("title", "Title is required", validateText),
          new ErrorHandlerField("email", "A valid email is required", validateEmail),
          new ErrorHandlerField("sales_representative", "A valid representative is required", validateText),
          new ErrorHandlerField("investor_type", "A valid type is required", validateText),
        ],
      },
      {
        parent: ["secondary_contact"],
        dependency: ["has_secondary_contact"],
        fields: [
          new ErrorHandlerField("first_name", "First name is required", validateText),
          new ErrorHandlerField("last_name", "Last name is required", validateText),
          new ErrorHandlerField("title", "Title is required", validateText),
          new ErrorHandlerField("email", "A valid email is required", validateEmail),
        ],
      },
    ],
    []
  );

  async function handleSubmit(response: ISubmitEvent<RegistrantViewModel>): Promise<void> {
    if (isEmpty(response?.formData) || isEmpty(onChange)) {
      return;
    }

    const sanitizedRegistrant = { ...registrant, ...trimFormData(response?.formData) };
    onChange(new RegistrantViewModel(sanitizedRegistrant));

    const { email } = sanitizedRegistrant;

    const result = checkAttendeeEmailsAvailable && (await checkAttendeeEmailsAvailable([email]));
    const isEmailAvailable = !checkAttendeeEmailsAvailable || result?.data?.[0]?.available;

    if (isEmailAvailable) {
      setExtraErrors({});
      onStep(true);
    } else {
      setExtraErrors({
        email: {
          __errors: [emailUnavailableError],
        },
      });
    }
  }

  return (
    <div id={idModel.id}>
      <JsonForm
        id={idModel.jsonForm?.id}
        errorHandlerFields={errorHandlerFields}
        schema={schema}
        uiSchema={uiSchema}
        data={registrant}
        onSubmit={handleSubmit}
        extraErrors={extraErrors}
      />
    </div>
  );
};

export default memo(InvestorPersonalInformation);
