import { Form, getClassName, isNullOrWhiteSpace, Textarea } from "@q4/nimbus-ui";
import { clone, set } from "lodash";
import React, { useMemo } from "react";
import { FieldMaxLength } from "../../../../../../utils/form/form.utils";
import { LoginMessageFormClassName, LoginMessageFormIdModel, LoginMessageFormProps } from "./loginMessageForm.definition";

export const LoginMessageForm = (props: LoginMessageFormProps): JSX.Element => {
  const { conference, onChange: onChangeProps, className, id } = props;

  const { login } = conference || {};

  const { message, error_message } = login || {};

  const idModel = useMemo(() => new LoginMessageFormIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(LoginMessageFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  function getStateChangeHandler(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      const state = login;
      const newState = set(clone(state), key, value);
      onChangeProps(newState);
    };
  }

  return (
    <Form
      className={baseClassName}
      fields={[
        {
          key: "Login Message",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Login Message",
          optional: true,
          children: (
            <Textarea
              id={idModel.message.id}
              value={message}
              onChange={getStateChangeHandler("message")}
              maxLength={FieldMaxLength.LoginPageMessage}
            />
          ),
        },
        {
          key: "Login Error Message",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Login Error Message",
          optional: true,
          children: (
            <Textarea
              id={idModel.errorMessage.id}
              value={error_message}
              onChange={getStateChangeHandler("error_message")}
              maxLength={FieldMaxLength.LoginPageErrorMessage}
            />
          ),
        },
      ]}
    />
  );
};
