import { RadioButton } from "@q4/nimbus-ui";
import { camelCase } from "lodash";
import React, { useMemo } from "react";
import { JsonFormWidgetProps } from "../../jsonForm.definition";
import { BooleanRadioOptions } from "./booleanRadio.definition";

const BooleanRadioWidget = (props: JsonFormWidgetProps): JSX.Element => {
  const { value, options, disabled, readonly, onChange, id: idProp, schema } = props;
  const { enumDisabled } = options || {};
  const { id: schemaId } = schema || {};

  const radioValue = useMemo(() => value ?? Boolean(schema?.default), [schema?.default, value]);
  const id = useMemo(() => schemaId ?? camelCase(`${idProp}`), [idProp, schemaId]);

  function handleRadioChange(_checked: boolean, value: string) {
    onChange(value === "true");
  }

  return (
    <div className="field-radio-group" id={id}>
      {BooleanRadioOptions.map((option) => {
        const radioId = `${id}${option.label}`;
        const itemDisabled = new Array(enumDisabled)?.indexOf(option.value) != -1;
        return (
          <RadioButton
            key={radioId}
            id={radioId}
            name={id}
            label={option.label}
            value={option.value.toString()}
            disabled={disabled || itemDisabled || readonly}
            checked={radioValue === option.value}
            onChange={handleRadioChange}
            inline={true}
          />
        );
      })}
    </div>
  );
};

export default BooleanRadioWidget;
