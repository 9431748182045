import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import { DefaultTimeZone } from "../../const";
import { Entity } from "../../definitions/entity.definition";
import { ApiResponse } from "../api/api.definition";
import { Conference } from "../conference/conference.model";
import { Presentation } from "../presentation/presentation.model";
import PresentationService from "../presentation/presentation.service";
import { Track } from "../track/track.model";
import TrackService from "../track/track.service";
import { CsvBase, ImportOptions, ImportPresentationError, ImportResult } from "./import.definition";
import type { CsvPresentation } from "./import.definition";

export default class ImportPresentationService {
  private presentations: { entity: Presentation; csv: CsvBase }[];
  private requests: (() => Promise<ApiResponse<Presentation>>)[];
  private presentationService = new PresentationService();

  private tracks: Track[];
  private trackService = new TrackService();

  get Data(): Presentation[] {
    return this.presentations?.map((x) => x.entity) || [];
  }
  get HasSetting(): boolean {
    return true;
  }
  get Requests(): (() => Promise<ApiResponse<Presentation>>)[] {
    return this.requests || [];
  }
  get Type(): Entity {
    return Entity.Presentation;
  }

  parse = async (options: ImportOptions<CsvPresentation>): Promise<ImportResult<Presentation>[]> => {
    const { csvJson, conference: _conference, handleResults, futureOnly } = options || {};

    if (isEmpty(csvJson) || isNullOrWhiteSpace(_conference?._id)) return [];

    const results: ImportResult<Presentation>[] = [];
    const existingPresentations: string[] = [];
    const uploadTracks: Track[] = [];
    const promiseTracks: Promise<ApiResponse<Track>>[] = [];

    this.tracks = (await this.trackService.getTracksByConferenceId(_conference["_id"])).data;

    csvJson.forEach((csv) => {
      const { track_name } = csv;
      if (!this.getTrack(uploadTracks, track_name)) {
        const mappedTrack = this.getTrack(this.tracks, track_name);

        if (!mappedTrack) {
          const createTrack = new Track({
            _conference,
            name: track_name,
          });
          uploadTracks.push(createTrack);
          promiseTracks.push(this.trackService.postTrack(createTrack));
        }
      }
    });

    Promise.all(promiseTracks).then((responses: ApiResponse<Track>[]) => {
      responses.forEach((response: ApiResponse<Track>) => {
        this.tracks.push(response.data);
      });

      this.presentations = csvJson.reduce((presentations, csv): Presentation[] => {
        if (isEmpty(csv)) return presentations;

        const { presentation_id } = csv;
        if (existingPresentations.includes(presentation_id)) return presentations;
        const { presentation_date, presentation_end, presentation_start, presentation_name, track_name } = csv;

        const { time_zone } = _conference;
        const start_date = this.convertDatetoUtc(presentation_date, presentation_start, time_zone);
        const end_date = this.convertDatetoUtc(presentation_date, presentation_end, time_zone);
        const mappedTrack = this.getTrack(this.tracks, track_name);

        const presentation = new Presentation({
          title: presentation_name,
          start_date,
          end_date,
          _conference,
          _track: mappedTrack,
        });

        const presentationParseError = this.getPresentationParseError(presentation, futureOnly);
        if (!isEmpty(presentationParseError)) {
          results.push(
            new ImportResult({
              type: this.Type,
              entity: presentation,
              title: presentation.title,
              csv,
              errorMessage: presentationParseError,
            })
          );
          return presentations.concat({ entity: null, csv });
        }

        existingPresentations.push(presentation_id);
        return presentations.concat({ entity: presentation, csv });
      }, []);

      this.requests = this.presentations.reduce(
        (requests, presentationData): (() => Promise<ApiResponse<Presentation>>)[] => {
          if (isEmpty(presentationData?.entity)) return requests;
          const { entity: presentation, csv } = presentationData;

          return requests.concat(
            (): Promise<ApiResponse<Presentation>> =>
              this.presentationService.post(presentation).then((response) => {
                const { success, message } = response;

                handleResults(
                  this.Type,
                  {
                    ...response,
                    data: success ? response.data : presentation,
                    message,
                    success,
                  },
                  "title",
                  csv
                );

                return response;
              })
          );
        },
        [] as (() => Promise<ApiResponse<Presentation>>)[]
      );
    });

    return results;
  };

  private validTime(dateTime: Moment, currentTime = moment()): boolean {
    return !isEmpty(dateTime) && dateTime.isValid() && dateTime.isSameOrAfter(currentTime);
  }

  private isPast(datetime: Moment, currentTime = moment()): boolean {
    return currentTime.startOf("day").diff(datetime.startOf("day"), "days") >= 0;
  }

  private getPresentationParseError(presentation: Presentation, futureOnly = false): ImportPresentationError {
    if (isEmpty(presentation)) return ImportPresentationError.Empty;
    if (isNullOrWhiteSpace(presentation.title)) return ImportPresentationError.InvalidTitle;
    if (futureOnly && this.isPast(presentation.start_date)) return ImportPresentationError.IsPastDate;
    if (!this.validTime(presentation.start_date)) return ImportPresentationError.InvalidStartDate;
    if (!this.validTime(presentation.end_date, presentation.start_date)) return ImportPresentationError.InvalidEndDate;
    return null;
  }

  private convertDatetoUtc(date: string, time: string, timeZone: Conference["time_zone"]): moment.Moment {
    const dateTime = `${date} ${time}`;
    timeZone = isNullOrWhiteSpace(timeZone) ? DefaultTimeZone : timeZone;
    return moment(dateTime).tz(timeZone).utc();
  }

  private getTrack(tracks: Track[], trackName: string): Track {
    return tracks.find((x) => x.name === trackName);
  }
}
