import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { ConferenceBaseEntity } from "../../../definitions/entity.definition";
import { getFriendlyName, Pick2 } from "../../../utils";
import { Answer } from "../../answer/answer.model";
import {
  AttendeePayload,
  AttendeeSecondaryContact,
  AttendeeType,
  AttendeeTypeDefault,
  AttendeeTypesWithAvailability,
  AttendeeViewModel,
} from "../../attendee/attendee.model";
import type { Attendee } from "../../attendee/attendee.model";
import { Conference, ConferenceSchedulerSlot, ConferenceType, GuestAttendeeType } from "../../conference/conference.model";
import { CorporateProfile, CorporateType } from "../../corporateProfile/corporateProfile.model";
import { InvestorType } from "../../investor/investor.model";
import { MeetingLabel } from "../../meeting/meeting.model";
import { PayloadBase } from "../../serviceBase/payloadBase.model";
import { ReCaptchaBase } from "../../serviceBase/serviceBase.model";
import { Speaker } from "../../speaker/speaker.model";

export enum RegistrantStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  Pending = "Pending",
}

export enum MeetingInterestLevel {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export interface MeetingRequest {
  _corporate_profile: string;
  meeting_type: MeetingLabel;
  interest_level: MeetingInterestLevel;
}

export class RegistrationFile implements Pick<RegistrantViewModel, "logo_image" | "secondary_logo_image"> {
  logo_image: Registrant["logo_image"];
  secondary_logo_image: Registrant["secondary_logo_image"];
  corporate_attendees: Pick2<AttendeeViewModel, "speaker_info", "image_profile">[];

  constructor(registrant: RegistrantViewModel) {
    if (isEmpty(registrant)) return;

    const { logo_image, secondary_logo_image, corporate_attendees } = registrant;

    this.corporate_attendees = (corporate_attendees || []).map((x) => {
      if (isEmpty(x?.speaker_info?.image_profile)) return null;

      return {
        speaker_info: {
          image_profile: x.speaker_info.image_profile,
        },
      };
    });

    Object.assign(this, { logo_image, secondary_logo_image });
  }
}
export class Registrant extends ConferenceBaseEntity {
  _conference: Conference | string;
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  company?: string;
  phone_number?: string;
  secondary_contact?: AttendeeSecondaryContact;
  status?: RegistrantStatus;
  attendee_type: AttendeeType;
  availability?: ConferenceSchedulerSlot[];
  host_small_meetings?: CorporateProfile["host_small_meetings"];
  host_presentation?: CorporateProfile["host_presentation"];
  corporate_name?: CorporateProfile["name"];
  corporate_type?: CorporateProfile["type"];
  corporate_attendees?: AttendeeViewModel[];
  industry?: CorporateProfile["industry"];
  description?: CorporateProfile["description"];
  logo_image?: CorporateProfile["logo_image"];
  secondary_logo_image?: CorporateProfile["secondary_logo_image"];
  ticker_symbol?: CorporateProfile["ticker_symbol"];
  exchange?: CorporateProfile["exchange"];
  url?: CorporateProfile["url"];
  meeting_requests?: MeetingRequest[];
  investor_type: InvestorType;
  has_secondary_contact?: boolean;
  sales_representative?: string;
  guest_attendee_type?: GuestAttendeeType;
  custom_question_answers?: Answer[];
  time_zone?: string;

  constructor(registrant: Partial<Registrant> | string) {
    super(registrant);

    if (typeof registrant === "string") return;

    const {
      _conference,
      corporate_attendees,
      attendee_type,
      host_presentation,
      host_small_meetings,
      corporate_type,
      secondary_contact,
      has_secondary_contact,
      custom_question_answers,
      ...rest
    } = registrant || {};

    if (attendee_type === AttendeeType.Corporate) {
      this.host_small_meetings = host_small_meetings ?? true;
      this.host_presentation = host_presentation ?? false;
      this.corporate_type = corporate_type ?? CorporateType.Public;
      this.corporate_attendees = isEmpty(corporate_attendees)
        ? [new AttendeeViewModel({ type: AttendeeType.Corporate, speaker_info: {} })]
        : corporate_attendees.map((x) => new AttendeeViewModel({ ...x, type: AttendeeType.Corporate }));
    }

    if (attendee_type === AttendeeType.Investor) {
      this.has_secondary_contact = has_secondary_contact ?? !isEmpty(secondary_contact);
      this.secondary_contact = secondary_contact;
    }

    this.attendee_type = attendee_type;
    this.custom_question_answers = custom_question_answers;
    this._conference = new Conference(_conference);

    Object.assign(this, rest);
  }
}

export class RegistrantViewModel extends Registrant {
  _conference: Conference;
  files?: {
    [key: string]: File;
  };

  get display_name(): string {
    const first_name = isNullOrWhiteSpace(this.first_name) ? "" : this.first_name.trim();
    const last_name = isNullOrWhiteSpace(this.last_name) ? "" : this.last_name.trim();
    return `${first_name} ${last_name}`.trim();
  }

  get friendly_name(): string {
    return getFriendlyName(this.first_name, this.last_name, this.company, this.email);
  }

  constructor(registrant: Partial<RegistrantViewModel> | Partial<Registrant> | string) {
    super(registrant);
    if (isEmpty(registrant)) return;
    if (typeof registrant === "string") return;

    const {
      status,
      attendee_type,
      host_small_meetings,
      host_presentation,
      corporate_type,
      corporate_attendees,
      _conference,
      secondary_contact,
      has_secondary_contact,
      ...rest
    } = registrant || {};

    if (attendee_type === AttendeeType.Corporate) {
      const conferenceType: ConferenceType = (_conference as Conference)?.conference_type || ConferenceType.FullConference;

      if (isNil(host_small_meetings)) {
        this.host_small_meetings = true;
      } else {
        this.host_small_meetings = conferenceType === ConferenceType.PresentationOnly ? false : host_small_meetings;
      }

      this.host_presentation = host_presentation ?? false;
      this.corporate_type = corporate_type ?? CorporateType.Public;
      this.corporate_attendees = isEmpty(corporate_attendees)
        ? [
            new AttendeeViewModel({
              type: AttendeeType.Corporate,
              is_speaker: false,
              speaker_info: { bio: "", image_profile: "" },
            }),
          ]
        : corporate_attendees.map((corporateAttendee: AttendeeViewModel) => {
            const speaker_info =
              !isEmpty(corporateAttendee.speaker_info) && corporateAttendee.is_speaker
                ? corporateAttendee.speaker_info
                : undefined;
            return new AttendeeViewModel({ ...corporateAttendee, type: AttendeeType.Corporate, speaker_info });
          });
    }

    if (attendee_type === AttendeeType.Investor) {
      this.has_secondary_contact = has_secondary_contact ?? !isEmpty(secondary_contact);
      this.secondary_contact = this.has_secondary_contact ? new AttendeeSecondaryContact(secondary_contact) : undefined;
    }

    this._conference = new Conference(_conference);
    this.status = isEmpty(status) ? RegistrantStatus.Pending : status;
    this.attendee_type = isEmpty(attendee_type) ? AttendeeTypeDefault : attendee_type;

    Object.assign(this, rest);
  }
}

type RegistrantPayloadBase = Omit<Registrant, "ConferenceId">;
export class RegistrantPayload extends PayloadBase<RegistrantPayloadBase> {
  constructor(registrant: Registrant) {
    super();
    const { ConferenceId, corporate_attendees, ...payload } = registrant;

    const isCorporate = payload?.attendee_type === AttendeeType.Corporate;

    const sanitizedPayload = this.sanitize(payload);
    Object.assign(this, sanitizedPayload, {
      _conference: ConferenceId,
      corporate_attendees: isCorporate
        ? corporate_attendees?.map((corpAttendee) => new AttendeePayload(new AttendeeViewModel({ ...corpAttendee }))) || []
        : undefined,
    });
  }
}

export class RegistrantPostPayload extends PayloadBase<RegistrantPayloadBase> {
  constructor(registrant: Registrant) {
    super();
    const {
      ConferenceId,
      has_secondary_contact,
      status,
      corporate_attendees,
      secondary_contact,
      availability,
      host_presentation,
      ...payload
    } = registrant;

    const isCorporate = payload?.attendee_type === AttendeeType.Corporate;
    const needsAvailability = AttendeeTypesWithAvailability.some((type) => type === payload?.attendee_type);

    const sanitizedPayload = this.sanitize(payload);
    Object.assign(this, sanitizedPayload, {
      _conference: ConferenceId,
      availability: !needsAvailability ? availability : availability ?? [],
      corporate_attendees: isCorporate
        ? corporate_attendees?.map((corpAttendee) => new AttendeePayload(new AttendeeViewModel({ ...corpAttendee }))) || []
        : undefined,
      secondary_contact: !!has_secondary_contact ? secondary_contact : undefined,
      host_presentation,
    });
  }
}

export type RegistrantServicePutResponse = {
  registrationResponse: {
    attendees: Attendee[];
    corporateProfile?: CorporateProfile;
    speakers?: Speaker[];
  };
} & {
  registrationRequest: Registrant;
};

export type RegistrantPostPayloadBase = Omit<RegistrantPayload, "sanitize"> & ReCaptchaBase;
