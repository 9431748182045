import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { FormProps, ISubmitEvent, UiSchema } from "@rjsf/core";
import React, { memo, useMemo, useState } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { ConferenceType } from "../../../../../services/conference/conference.model";
import { CorporateType } from "../../../../../services/corporateProfile/corporateProfile.model";
import { ErrorHandlerField } from "../../../../../services/errorHandler/errorHandler.definition";
import { validateText } from "../../../../../utils";
import { isCorporateDeadlinePassed } from "../../../../../utils/deadline/deadline.utils";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";
import {
  corporateDetailsErrorMessages,
  CorporateDetailsIdModel,
  corporateDetailsLabels,
} from "./corporateDetails.definition";

const CorporateDetails = (props: RegistrationFormProps): JSX.Element => {
  const { id, registrant, conference, isEditMode, setFileState, onChange, onStep } = props;

  const [extraErrors, setExtraErrors] = useState({});
  const idModel = useMemo(() => new CorporateDetailsIdModel(id), [id]);

  const isMeetingDeadlinePassed = useMemo(() => isCorporateDeadlinePassed("meeting", conference), [conference]);
  const isPresentationDeadlinePassed = useMemo(() => isCorporateDeadlinePassed("presentation", conference), [conference]);

  const isPresentationOnly: boolean = registrant._conference?.conference_type === ConferenceType.PresentationOnly;
  const shouldExcludeMeeting: boolean = isPresentationOnly || isMeetingDeadlinePassed;

  type JSONSchemaDefinition = { [key: string]: boolean | number | string | Record<string, unknown> };
  const schema: FormProps<RegistrantViewModel>["schema"] = useMemo(() => {
    const host_presentation: JSONSchemaDefinition =
      !isPresentationDeadlinePassed && !isEditMode
        ? {
            id: idModel.isHostPresentations,
            type: "boolean",
            default: false,
            title: corporateDetailsLabels.companyParticipation.hostPresentations,
          }
        : {};

    const host_small_meetings: JSONSchemaDefinition = shouldExcludeMeeting
      ? {}
      : {
          id: idModel.isHostMeetings,
          type: "boolean",
          default: true,
          title: corporateDetailsLabels.companyParticipation.hostMeetings,
        };

    return {
      type: "object",
      dependencies: {
        corporate_type: {
          oneOf: [
            {
              properties: {
                corporate_type: { enum: [CorporateType.Public] },
                ticker_symbol: {
                  id: idModel.stockTicker.id,
                  type: "string",
                  title: "Stock Ticker",
                },
                exchange: {
                  id: idModel.exchange.id,
                  type: "string",
                  title: "Exchange",
                },
              },
            },
            {
              properties: {
                corporate_type: { enum: [CorporateType.Private] },
              },
            },
          ],
        },
      },
      properties: {
        corporate_name: { id: idModel.companyName.id, type: "string", title: "Company Name", default: "" },
        corporate_type: {
          id: idModel.companyType,
          type: "string",
          enum: Object.values(CorporateType),
          title: "Company Type",
          default: CorporateType.Private,
        },
        logo_image: {
          id: idModel.mainLogo.id,
          type: "string",
          title: "Main Logo",
        },
        secondary_logo_image: {
          id: idModel.whiteLogo.id,
          type: "string",
          title: "White Logo",
        },
        description: {
          id: idModel.description.id,
          type: "string",
          title: " Description",
          maxLength: 2500,
        },
        url: {
          id: idModel.websiteUrl.id,
          type: "string",
          title: "Website URL",
        },
        host_presentation,
        host_small_meetings,
      },
    };
  }, [idModel, isEditMode, isPresentationDeadlinePassed, shouldExcludeMeeting]);

  const uiSchema: UiSchema = useMemo(() => {
    return {
      "logo_image": {
        "ui:widget": "nuiImagePreviewValidated",
      },
      "secondary_logo_image": {
        "ui:widget": "nuiImagePreviewValidated",
      },
      "corporate_type": {
        "ui:widget": "nuiRadio",
      },
      "description": {
        "ui:widget": "nuiTextarea",
      },
      "host_small_meetings": {
        "ui:widget": "nuiRadio",
      },
      "host_presentation": {
        "ui:widget": "nuiRadio",
      },
      "ui:field": "layout",
      "ui:layout": [
        {
          title: "Company Information",
          fields: {
            corporate_name: {
              width: "3-of-6",
            },
          },
        },
        {
          title: "Corporate Type",
          fields: {
            corporate_type: {
              width: "1-of-6",
            },
            ticker_symbol: { width: "1-of-6" },
            exchange: { width: "1-of-6" },
          },
        },
        {
          title: "Company Logo",
          fields: {
            logo_image: { width: "1-of-2" },
            secondary_logo_image: { width: "1-of-2" },
          },
        },
        {
          title: "Company Description",
          fields: {
            description: { width: "1-of-1" },
          },
        },
        {
          title: "Website",
          fields: {
            url: { width: "1-of-2" },
          },
        },
        {
          title:
            !isPresentationDeadlinePassed && !shouldExcludeMeeting && corporateDetailsLabels.companyParticipation.header,
          fields: {
            host_small_meetings: { width: "1-of-1" },
            host_presentation: { width: "1-of-1" },
          },
        },
      ],
    };
  }, [isPresentationDeadlinePassed, shouldExcludeMeeting]);

  const errorHandlerFields = useMemo(() => {
    return [
      {
        fields: [
          new ErrorHandlerField("corporate_name", corporateDetailsErrorMessages.companyError.companyNameError, validateText),
          new ErrorHandlerField("logo_image", corporateDetailsErrorMessages.companyError.mainLogoError, validateText),
          new ErrorHandlerField("description", corporateDetailsErrorMessages.companyError.descriptionError, validateText),
        ],
      },
    ];
  }, []);

  function handleSubmit(response: ISubmitEvent<RegistrantViewModel>): void {
    if (isEmpty(response?.formData) || isEmpty(onChange)) return;

    const { host_small_meetings: hostSmallMeetingFormData, ticker_symbol: ticker, exchange } = response?.formData;
    const host_small_meetings = shouldExcludeMeeting && !isEditMode ? false : hostSmallMeetingFormData;

    const isPublic = response.formData.corporate_type === CorporateType.Public;

    onChange(new RegistrantViewModel({ ...registrant, ...response.formData, host_small_meetings }));

    if (isPublic && (isNullOrWhiteSpace(ticker) || isNullOrWhiteSpace(exchange))) {
      const errors = {
        ticker_symbol: {},
        exchange: {},
      };

      if (!validateText(response.formData.ticker_symbol)) {
        errors.ticker_symbol = {
          __errors: [corporateDetailsErrorMessages.publicCompanyError.tickerSymbolError],
        };
      }

      if (!validateText(response.formData.exchange)) {
        errors.exchange = {
          __errors: [corporateDetailsErrorMessages.publicCompanyError.excahgeError],
        };
      }

      setExtraErrors(errors);
      return;
    }

    setExtraErrors({});
    onStep(true);
  }

  return (
    <div id={idModel.id}>
      <JsonForm
        id={idModel.jsonForm.id}
        errorHandlerFields={errorHandlerFields}
        schema={schema}
        uiSchema={uiSchema}
        data={registrant}
        setFiles={setFileState}
        extraErrors={extraErrors}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default memo(CorporateDetails);
