import { getClassName, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React from "react";
import { CsvBase, ImportResult } from "../../../../services/import/import.definition";

export function convertImportResultToCsv<T>(rows: ImportResult<T>[]): CsvBase[] {
  return rows?.reduce(
    (errors, row) =>
      isNullOrWhiteSpace(row.errorMessage) ? errors : errors.concat({ ...row.csv, error: row.errorMessage }),
    []
  );
}

export function getImportErrorFileName<T>(rows: ImportResult<T>[], postfix: string, prefix = "import"): string {
  const typeLabel = isEmpty(rows) ? "" : `_${rows[0].type}`;
  return `${prefix}${typeLabel}${postfix}`;
}

export function renderStatusTableRow<T>(result: ImportResult<T>, index: number): JSX.Element {
  const success = isEmpty(result.errorMessage);
  const statusClassName = getClassName("import-table_cell import-table_cell-status", [
    {
      condition: success,
      trueClassName: "import-table_cell-status--success",
      falseClassName: "import-table_cell-status--fail",
    },
  ]);
  const statusMessage = getResultStatusMessage(success, result.errorMessage, result.actionName);

  return (
    <section className="import-table_row" key={`import-table_row-${index}`}>
      <div className="import-table_cell" title={result.title}>
        <span className="import-table_cell-title-text">{result.title}</span>
      </div>
      <div className={statusClassName} title={statusMessage}>
        {statusMessage}
      </div>
    </section>
  );
}

function getResultStatusMessage(success: boolean, errorMessage: string, actionName: string): string {
  if (success) return actionName;
  return errorMessage;
}
