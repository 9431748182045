import "./company.view.scss";
import { Banner, BannerSize, Button, ButtonTheme, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { ModalProps } from "@q4/nimbus-ui";
import { ICellRendererParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useMemo } from "react";
import { useHistory } from "react-router";
import EntityTable from "../../../components/entityTable/entityTable.component";
import { EntityTableTableProps } from "../../../components/entityTable/entityTable.definition";
import { RoutePath, RoutePathIdLabel } from "../../../configurations/navigation.configuration";
import { Entity } from "../../../definitions/entity.definition";
import { useCompanies as useCompaniesHook } from "../../../hooks/admin/useCompanies/useCompanies.hook";
import { Company } from "../../../services/admin/companies/companies.model";
import { CompanyClassName, CompanyIdModel as IdModel } from "./company.definition";
import CompanyForm from "./components/form/companyForm.component";

const Companies = (): JSX.Element => {
  const useCompanies = useCompaniesHook({
    showNotifications: true,
  });
  const history = useHistory();

  const tableProps: EntityTableTableProps = {
    columnDefs: [
      {
        field: "name",
        headerName: "Name",
        minWidth: 160,
        flex: 1,
        sort: "asc",
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      },
      {
        field: "name",
        headerName: "Conferences",
        sortable: false,
        minWidth: 125,
        maxWidth: 125,
        headerClass: CompanyClassName.IconCell,
        cellRenderer: "companyActionCellRenderer",
      },
    ],
    frameworkComponents: {
      companyActionCellRenderer: renderActionsCell,
    },
  };

  // #region Modal
  const modalProps: Partial<ModalProps> = {
    fullscreen: true,
    badgeIcon: "q4i-team-2pt",
  };

  function renderActionsCell(params: ICellRendererParams): string | JSX.Element {
    const companyName = params?.value as Company["_id"];
    if (typeof companyName === "string" ? isNullOrWhiteSpace(companyName) : isEmpty(companyName)) return "—";

    const handleViewConferencesClick = () =>
      history.push(RoutePath.ConferencesByCompany.replace(RoutePathIdLabel.Company, companyName));

    return <Button theme={ButtonTheme.LightGrey} onClick={handleViewConferencesClick} label="View" />;
  }

  const editForm = useMemo(() => {
    const { current, setCurrent } = useCompanies;
    function handleCompanyUpdate(data: Partial<Company>) {
      setCurrent(new Company({ ...current, ...data }));
    }
    return <CompanyForm id={IdModel.form.id} company={current} onCompanyUpdate={handleCompanyUpdate} />;
  }, [useCompanies]);

  return (
    <section id={IdModel.id} className={CompanyClassName.Base}>
      <Banner id={IdModel.banner.id} title="Companies" badgeIcon="q4i-conference-2pt" size={BannerSize.Medium} />
      <div className={CompanyClassName.Table}>
        <EntityTable
          id={IdModel.table.id}
          entity={Entity.Company}
          editForm={editForm}
          icon="q4i-conference-2pt"
          requiredEntityKeys={["name", "url_suffix"]}
          tableProps={tableProps}
          useService={useCompanies}
          user={null}
          modalProps={modalProps}
          entityModel={Company}
        />
      </div>
    </section>
  );
};

export default memo(Companies);
