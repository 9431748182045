import { AdminRouteBase } from "../../../configurations/navigation.configuration";
import { ApiResponse } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import { AdminApiVersion, Service } from "../../serviceBase/serviceBase.model";
import { AdminProfile } from "./adminProfile.model";

export default class AdminProfileService extends Service<AdminProfile> {
  private readonly ADMIN_USER_API_PATH = `${AdminRouteBase}/users`;
  private apiService = new ApiService();

  constructor() {
    super(AdminRouteBase, "users", AdminProfile, AdminApiVersion);
  }

  public async getByEmail(): Promise<ApiResponse<AdminProfile>> {
    const response = await this.apiService.get<AdminProfile>(`${this.ADMIN_USER_API_PATH}/${AdminApiVersion}/me`);
    return this.mapResponse(response);
  }
}
