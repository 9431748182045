import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";

export function getQuery<T>(data: T): string {
  if (isEmpty(data)) return "";

  const params = Object.keys(data).reduce((query, param): string[] => {
    const value = data[param as keyof T];

    if (isNil(value)) return query;

    if (!Array.isArray(value)) {
      if (typeof value !== "string") return query;
      return _buildQueryParam(param, value, query);
    }

    return value.reduce((groupedParam, groupedValue): string[] => {
      return _buildQueryParam(param, groupedValue, groupedParam);
    }, query);
  }, []);
  return isEmpty(params) ? "" : `?${params.join("&")}`;
}

function _buildQueryParam(param: string, value: string, query: string[]): string[] {
  if (isNullOrWhiteSpace(value)) return query;
  return query.concat(`${param}=${encodeURI(value.trim())}`);
}
