import "./compactPanel.component.scss";
import { getClassName, isEmpty, isNullOrWhiteSpace, KeyboardKey, Text } from "@q4/nimbus-ui";
import React, { memo, useMemo, MouseEvent, KeyboardEvent } from "react";
import { getBackgroundImageUrl } from "../../../../../../../utils";
import { CompactPanelClassName, CompactPanelIdModel } from "./compactPanel.definition";
import type { CompactPanelProps } from "./compactPanel.definition";

const CompactPanel = (props: CompactPanelProps): JSX.Element => {
  const { id, selected, actionLabel, accessibilityLabel, category, title, details, indicator, image, onSelect } = props;

  const idModel = useMemo(() => new CompactPanelIdModel(id), [id]);
  const baseClassName = useMemo(getBaseClassName, [selected, indicator]);

  function handleClick(event: MouseEvent): void {
    onSelect && onSelect(event);
  }

  function handleKeyPress(event: KeyboardEvent): void {
    if (event?.key === KeyboardKey.Enter || event?.key === KeyboardKey.Space) {
      event.preventDefault();
      onSelect && onSelect(event);
    }
  }

  function getBaseClassName(): string {
    return getClassName(CompactPanelClassName.Base, [
      { condition: selected, trueClassName: CompactPanelClassName.BaseWithSelectedModifier },
      {
        condition: !isNullOrWhiteSpace(indicator),
        trueClassName: CompactPanelClassName.BaseWithIndicatorModifier,
      },
    ]);
  }

  return (
    <article id={idModel.id} className={baseClassName} tabIndex={0} onKeyPress={handleKeyPress} onClick={handleClick}>
      <div className={CompactPanelClassName.Section}>
        <div className={CompactPanelClassName.Image} style={{ backgroundImage: getBackgroundImageUrl(image) }} />
        {!isNullOrWhiteSpace(accessibilityLabel) && <Text className="sr-only">{accessibilityLabel}</Text>}
        {!isNullOrWhiteSpace(actionLabel) && (
          <div id={idModel.action} className={CompactPanelClassName.Action}>
            <span className={CompactPanelClassName.ActionWrap}>
              <Text className={CompactPanelClassName.ActionLabel}>{actionLabel}</Text>
            </span>
          </div>
        )}
      </div>
      <div className={`${CompactPanelClassName.Section} ${CompactPanelClassName.SectionWithGrowModifier}`}>
        {!isNullOrWhiteSpace(category) && (
          <Text id={idModel.category} className={CompactPanelClassName.Category}>
            {category}
          </Text>
        )}
        {!isNullOrWhiteSpace(title) && (
          <Text id={idModel.title} className={CompactPanelClassName.Title}>
            {title}
          </Text>
        )}
        {details?.map((detail, index) => {
          return (
            <Text
              key={`compact-panel_detail--${isEmpty(detail) ? index : `${detail}${index}`}`}
              className={CompactPanelClassName.Detail}
            >
              {detail}
            </Text>
          );
        })}
      </div>
    </article>
  );
};

export default memo(CompactPanel);
