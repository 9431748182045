import { isEmpty, isNil } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import { AttendeeViewModel } from "../attendee/attendee.model";
import { Conference } from "../conference/conference.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";
import { SessionBase, SessionEventType } from "../session/session.model";
import { Speaker } from "../speaker/speaker.model";
import { Track } from "../track/track.model";

export enum PresentationSessionType {
  Open = "Open Session",
  Closed = "Closed Session",
  Break = "Scheduled Break",
}
export class Presentation extends SessionBase {
  readonly type? = SessionEventType.Presentation;
  title: string;
  session_type: PresentationSessionType;
  presentation_type?: string;
  start_date: Moment;
  end_date: Moment;
  file?: string;
  image_thumbnail?: string;
  is_shareable_after_event?: boolean;
  description?: string;
  urlOverride?: string;
  roomLocation?: string;
  poll_url?: string;
  qa_url?: string;
  code?: string[];
  _attendee?: AttendeeViewModel[];
  _track?: Track;
  _speaker?: Speaker[];
  speaker_minutes?: number;
  speaker_link?: string;
  speaker_notes?: string;
  add_to_all: boolean;
  is_all_days?: boolean;

  // TODO: when type is removed from the db schema
  label?: string;

  constructor(presentation: Partial<Presentation> | string) {
    super(presentation);
    if (typeof presentation === "string" || isEmpty(presentation)) return;

    const { add_to_all, start_date, end_date, _attendee, _conference, _track, _speaker, session_type, ...rest } =
      presentation;

    this.session_type = session_type ?? PresentationSessionType.Open;
    this._conference = new Conference(_conference);
    this.start_date = typeof start_date === "string" ? moment.utc(start_date) : start_date;
    this.end_date = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this._attendee = (_attendee || []).map((x) => new AttendeeViewModel(x));
    this._track = new Track(_track);
    this._speaker = (_speaker || []).map((x) => new Speaker(x));
    this.add_to_all = isNil(add_to_all) ? false : add_to_all;

    Object.assign(this, rest);
  }
}

export class BreakPresentation extends Presentation {
  title: string;
  session_type: PresentationSessionType;
  start_date: Moment;
  end_date: Moment;
  description?: string;
  roomLocation?: string;
  add_to_all: boolean;
  is_all_days?: boolean;

  constructor(presentation: Partial<BreakPresentation> | string) {
    super({});
    if (typeof presentation === "string" || isEmpty(presentation)) return;

    const { _id, title, session_type, start_date, end_date, description, roomLocation, is_all_days, _conference } =
      presentation;
    this._id = _id;
    this.title = title;
    this.session_type = session_type;
    this.start_date = typeof start_date === "string" ? moment.utc(start_date) : start_date;
    this.end_date = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this.description = description;
    this.roomLocation = roomLocation;
    this.add_to_all = true;
    this.is_all_days = is_all_days;
    this._conference = new Conference(_conference);
  }
}

export interface PresentationExport {
  html: string;
}

export interface PresentationUpload {
  csvFile: string;
}

type PresentationPayloadBase = Omit<Presentation, "ConferenceId" | "label" | "vendor" | "type">;
export class PresentationPayload extends PayloadBase<PresentationPayloadBase> implements PresentationPayloadBase {
  _conference: Presentation["_conference"];
  end_date: Presentation["end_date"];
  start_date: Presentation["start_date"];
  title: Presentation["title"];
  presentation_type: Presentation["presentation_type"];
  session_type: Presentation["session_type"];
  _attendee: Presentation["_attendee"];
  speaker_minutes: Presentation["speaker_minutes"];
  speaker_link: Presentation["speaker_link"];
  speaker_notes: Presentation["speaker_notes"];
  add_to_all: Presentation["add_to_all"];
  is_all_days: Presentation["is_all_days"];

  // TODO remove this
  get ConferenceId(): string {
    if (typeof this._conference === "string") return this._conference;
    return this._conference?._id;
  }

  constructor(presentation: Presentation) {
    super();
    const { label, type, ConferenceId, file, is_shareable_after_event, _speaker, _track, vendor, _attendee, ...payload } =
      presentation;
    const sanitizedPayload = this.sanitize(payload);

    Object.assign(this, sanitizedPayload, {
      _conference: ConferenceId,
      _track: _track?._id,
      _speaker: _speaker?.map((x) => x._id),
      _attendee: _attendee?.map((x) => ({ _id: x._id, email: x.email })),
    });
  }
}
