import {
  FileUploader,
  Form,
  getClassName,
  Grid,
  GridColumn,
  isNullOrWhiteSpace,
  Textarea,
  Textbox,
  TextboxClassName,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { useFileUpload } from "../../../../../hooks";
import { Company, CompanyBranding } from "../../../../../services/company/company.model";
import { CompanyFormClassName, CompanyFormIdModel, CompanyFormProps } from "./companyForm.definition";

const CompanyForm = (props: CompanyFormProps): JSX.Element => {
  const { id, className, company, onCompanyUpdate } = props;

  const handleFileUpload = useFileUpload();

  const IdModel = useMemo(() => new CompanyFormIdModel(id), [id]);
  const baseClassName = useMemo(
    () => getClassName(CompanyFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  // #region Handler funtions
  const getTextboxHandler = useCallback(
    (key: keyof Company) => {
      return function (value: string): void {
        onCompanyUpdate({ [key]: value });
      };
    },
    [onCompanyUpdate]
  );

  const getBrandingChangeHandler = useCallback(
    (key: keyof CompanyBranding) => {
      return function (value: string): void {
        onCompanyUpdate({ branding: { ...company?.branding, ...{ [key]: value } } });
      };
    },
    [company, onCompanyUpdate]
  );
  // #endregion

  return (
    <Grid className={baseClassName}>
      <GridColumn width="1-of-2" smallWidth="1-of-1">
        <Form
          className={baseClassName}
          id={IdModel.id}
          fields={[
            {
              key: "Company Name",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: "Company Name",
              children: <Textbox id={IdModel.name.id} value={company?.name} onChange={getTextboxHandler("name")} />,
            },
            {
              key: "Website",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: "Website",
              optional: true,
              children: <Textbox id={IdModel.website.id} value={company?.website} onChange={getTextboxHandler("website")} />,
            },
            {
              key: "Url Suffix",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: "Url Suffix",
              children: (
                <Textbox id={IdModel.url_suffx.id} value={company?.url_suffix} onChange={getTextboxHandler("url_suffix")} />
              ),
            },
            {
              key: "Login Message",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: "Login Message",
              optional: true,
              children: (
                <div id={IdModel.text_login_1.id}>
                  <Textarea
                    id={IdModel.text_login_1.input}
                    value={company?.branding?.text_login_1}
                    onChange={getBrandingChangeHandler("text_login_1")}
                  />
                  <div id={IdModel.text_login_1.focus} className={TextboxClassName.Focus} />
                </div>
              ),
            },
            {
              key: "Login Error Message",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: "Login Error Message",
              optional: true,
              children: (
                <div id={IdModel.text_login_2.id}>
                  <Textarea
                    id={IdModel.text_login_2.input}
                    value={company?.branding?.text_login_2}
                    onChange={getBrandingChangeHandler("text_login_2")}
                  />
                  <div id={IdModel.text_login_2.focus} className={TextboxClassName.Focus} />
                </div>
              ),
            },
          ]}
        />
      </GridColumn>
      <GridColumn width="1-of-2" smallWidth="1-of-1">
        <Form
          fields={[
            {
              key: "Logo URL",
              width: "1-of-3",
              smallWidth: "1-of-1",
              label: "Logo Image",
              optional: true,
              children: (
                <FileUploader
                  id={IdModel.logo}
                  dropzoneProps={{ accept: ["image/*"] }}
                  fileApi={handleFileUpload}
                  fileUrl={company?.branding?.logo}
                  onChange={getBrandingChangeHandler("logo")}
                  showPreview={true}
                />
              ),
            },
            {
              key: "Backround URL",
              width: "1-of-3",
              smallWidth: "1-of-1",
              label: "Background Image",
              children: (
                <FileUploader
                  id={IdModel.background}
                  dropzoneProps={{ accept: ["image/*"] }}
                  fileApi={handleFileUpload}
                  fileUrl={company?.branding?.background}
                  onChange={getBrandingChangeHandler("background")}
                  showPreview={true}
                />
              ),
            },
            {
              key: "Custom Domain",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: "Custom Domain",
              optional: true,
              children: (
                <Textbox
                  id={IdModel.custom_domain.id}
                  value={company?.custom_domain}
                  onChange={getTextboxHandler("custom_domain")}
                />
              ),
            },
            {
              key: "Custom Path",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: "Custom Path",
              optional: true,
              children: (
                <Textbox
                  id={IdModel.custom_path.id}
                  value={company?.custom_path}
                  onChange={getTextboxHandler("custom_path")}
                />
              ),
            },
          ]}
        />
      </GridColumn>
    </Grid>
  );
};

export default memo(CompanyForm);
