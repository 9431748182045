import {
  AnchorListId,
  ButtonIdModel,
  ButtonListId,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextListId,
} from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  ValueFormatterParams,
} from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { PresentationFormIdModel } from "../../../../../../components/presentationForm/presentationForm.definition";
import type { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { AttendeesHookModel } from "../../../../../../hooks/useAttendees/useAttendees.definition";
import type { PresentationsHookModel } from "../../../../../../hooks/usePresentations/usePresentations.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../../services/presentation/presentation.model";
import type { Speaker } from "../../../../../../services/speaker/speaker.model";
import type { Track } from "../../../../../../services/track/track.model";
import { PresentationManageAttendeesIdModel } from "./components/manageAttendees/manageAttendees.defintion";

export interface TestRef {
  renderAttendeesCell?: (params: ICellRendererParams) => string | JSX.Element;
  handlePresentationUpdate?: (data: Partial<Presentation>) => void;
}

export const SessionsLink = "Sessions Link";

export interface PresentationsProps extends Pick<BaseComponentProps, "id"> {
  conference: Conference;
  conferences: Conference[];
  codes: string[];
  speakers: Speaker[];
  tracks: Track[];
  useAttendees: AttendeesHookModel;
  usePresentations: PresentationsHookModel;
  user: AdminUserContextState["user"];
  suppressColumnVirtualisation?: boolean; // Only used for unit tests
  testRef?: TestRef; // Only used for unit tests
}

export enum PresentationTableHeader {
  Name = "NAME",
  Type = "TYPE",
  StartTime = "START TIME",
  EndTime = "END TIME",
  Speaker = "SPEAKER",
  Track = "TRACK",
  Attendees = "ATTENDEES",
  Location = "LOCATION",
}

export enum PresentationClassName {
  Base = "presentations",
}

export enum PresentationEditClassName {
  Base = "presentation-edit",
}

export class PresentationsIdModel extends IdModelBase {
  attendees: ButtonListId;
  location: TextListId;
  locationLink: AnchorListId;
  form: PresentationFormIdModel;
  typeFilter: SelectIdModel;
  entityTable: EntityTableIdModel;
  manageAttendees: PresentationManageAttendeesIdModel;
  openTab: ButtonIdModel;
  closedTab: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.attendees = new ButtonListId(`${this.id}Attendees`);
    this.location = new TextListId(`${this.id}Location`);
    this.locationLink = new AnchorListId(`${this.id}LocationLink`);
    this.form = new PresentationFormIdModel(`${this.id}Form`);
    this.typeFilter = new SelectIdModel(`${this.id}TypeSelect`);
    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.manageAttendees = new PresentationManageAttendeesIdModel(`${this.id}ManageAttendeesModal`);
    this.openTab = new ButtonIdModel(`${this.id}OpenTab`);
    this.closedTab = new ButtonIdModel(`${this.id}ClosedTab`);
  }
}

export type ColumnDefs = (ColDef | ColGroupDef) & {
  field: string;
  headerName: string;
  sortable?: boolean;
  sort?: string;
  minWidth?: number;
  maxWidth?: number;
  cellRenderer?: string;
  flex?: number;
  valueFormatter?: (params: ValueFormatterParams | GetQuickFilterTextParams) => string;
  getQuickFilterText?: (params: ValueFormatterParams | GetQuickFilterTextParams) => string;
};
