import "./panel.component.scss";
import { getClassName, isEmpty, isNullOrWhiteSpace, KeyboardKey } from "@q4/nimbus-ui";
import React, { memo, useMemo, MouseEvent, KeyboardEvent } from "react";
import { BrandingVariables } from "../../../../../components/companyBranding/companyBranding.definition";
import { getBackgroundImageUrl, getLinearGradient } from "../../../../../utils";
import { PanelProps, PanelClassName, PanelIdModel } from "./panel.definition";

function Panel(props: PanelProps): JSX.Element {
  const { id, selected, actionLabel, accessibilityLabel, category, title, details, indicator, image, branding, onSelect } =
    props;

  const secondaryBackgroundRgb = useMemo(() => new BrandingVariables(branding), [branding])?.secondaryBackgroundRgb;

  const linearGradient = getLinearGradient(secondaryBackgroundRgb);

  const idModel = useMemo(() => new PanelIdModel(id), [id]);
  const baseClassName = useMemo(getBaseClassName, [selected, indicator]);

  function handleClick(event: MouseEvent): void {
    onSelect && onSelect(event);
  }

  function handleKeyPress(event: KeyboardEvent): void {
    if (event?.key === KeyboardKey.Enter || event?.key === KeyboardKey.Space) {
      event.preventDefault();
      onSelect && onSelect(event);
    }
  }

  function getBaseClassName(): string {
    return getClassName(PanelClassName.Base, [
      { condition: selected, trueClassName: PanelClassName.BaseWithSelectedModifier },
      {
        condition: !isNullOrWhiteSpace(indicator),
        trueClassName: PanelClassName.BaseWithIndicatorModifier,
      },
    ]);
  }

  return (
    <article
      id={idModel.id}
      className={baseClassName}
      style={{
        backgroundImage: isNullOrWhiteSpace(image)
          ? `${linearGradient}`
          : `${linearGradient}, 
          ${getBackgroundImageUrl(image)}`,
      }}
      tabIndex={0}
      onKeyPress={handleKeyPress}
      onClick={handleClick}
    >
      {!!accessibilityLabel && <span className="sr-only">{accessibilityLabel}</span>}
      {!!actionLabel && (
        <div id={idModel.action} className={PanelClassName.Action}>
          <span className={PanelClassName.ActionWrap}>
            <span className={PanelClassName.ActionLabel}>{actionLabel}</span>
          </span>
        </div>
      )}
      <div id={idModel.indicator} className={PanelClassName.Indicator}>
        {indicator}
      </div>
      {!!category && (
        <span id={idModel.category} className={PanelClassName.Category}>
          {category}
        </span>
      )}
      {!!title && (
        <span id={idModel.title} className={PanelClassName.Title}>
          {title}
        </span>
      )}
      {!isEmpty(details) &&
        details.map((detail, index) => {
          return (
            <span key={`panel_detail--${isEmpty(detail) ? index : `${detail}${index}`}`} className={PanelClassName.Detail}>
              {detail}
            </span>
          );
        })}
    </article>
  );
}

export default memo(Panel);
