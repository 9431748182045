import { isEmpty, isNil, isNullOrWhiteSpace, objectSortComparer } from "@q4/nimbus-ui";
import { Dictionary, groupBy } from "lodash";
import { DefaultNullCompanyName } from "../../definitions/agenda.definition";
import { AttendeeType, AttendeeViewModel } from "../../services/attendee/attendee.model";
import { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";

export function groupAttendeesByCompany(
  conferenceCompany: string,
  userCompany: string,
  attendees: AttendeeViewModel[]
): Dictionary<AttendeeViewModel[]> {
  return groupBy(
    (attendees || []).filter((x) => {
      if (isEmpty(x) || isNil(x)) return false;
      if (x.type === AttendeeType.Internal) x.company = conferenceCompany;
      if (x.type !== AttendeeType.Corporate && isNullOrWhiteSpace(x.company)) x.company = DefaultNullCompanyName;
      return x.company && x.company !== userCompany;
    }),
    "company"
  );
}
export function formatMeetingAttendee(attendee: AttendeeViewModel): string {
  const participantName = !isNullOrWhiteSpace(attendee.first_name) ? `${attendee.display_name}` : "";
  const participantTitle = !isNullOrWhiteSpace(attendee.title) ? `, ${attendee.title}` : "";

  return `${participantName}${participantTitle}`;
}

export function getCorporateMeetingAttendees(corporateName: string, attendees: AttendeeViewModel[]): string {
  return (attendees || [])
    .sort(objectSortComparer("display_name"))
    .reduce((corporateNames: AttendeeViewModel["display_name"][], current: AttendeeViewModel) => {
      const name = `${current.display_name}`;
      const title = !isNullOrWhiteSpace(current.title) ? `, ${current.title}` : "";
      return new CorporateProfile(current._corporate_profile)?.name === corporateName
        ? corporateNames.concat(`${name}${title}`)
        : corporateNames;
    }, [] as AttendeeViewModel["display_name"][])
    .join(" | ");
}

export function getInternalMeetingAttendees(attendees: AttendeeViewModel[]): string {
  return (attendees || [])
    .sort(objectSortComparer("display_name"))
    .reduce((internalNames: AttendeeViewModel["display_name"][], current: AttendeeViewModel) => {
      const name = `${current.display_name}`;
      const title = !isNullOrWhiteSpace(current.title) ? `, ${current.title}` : "";
      return current.type === AttendeeType.Internal ? internalNames.concat(`${name}${title}`) : internalNames;
    }, [] as AttendeeViewModel["display_name"][])
    .join(" | ");
}
