import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, SelectIdModel, TextboxIdModel } from "@q4/nimbus-ui";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Speaker } from "../../services/speaker/speaker.model";
import { InfoBubbleIdModel } from "../infoBubble/infoBubble.definition";

export enum SpeakerFormClassName {
  Base = "speaker-form",
  CustomLabel = "speaker-form_custom-label",
}

export interface SpeakerFormProps extends BaseComponentProps {
  attendees: AttendeeViewModel[];
  companies: string[];
  speaker: Speaker;
  onUpdate: (data: Partial<Speaker>) => void;
}

export class SpeakerFormIdModel extends IdModelBase {
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  company: SelectIdModel;
  attendee: SelectIdModel;
  title: TextboxIdModel;
  bio: TextboxIdModel;
  order: TextboxIdModel;
  email: TextboxIdModel;
  twitter: TextboxIdModel;
  linkedin: TextboxIdModel;
  infoBubble: InfoBubbleIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.company = new SelectIdModel(`${this.id}CompanySelect`);
    this.attendee = new SelectIdModel(`${this.id}AttendeeSelect`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.bio = new TextboxIdModel(`${this.id}BioTextbox`);
    this.order = new TextboxIdModel(`${this.id}OrderTextbox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.twitter = new TextboxIdModel(`${this.id}TwitterTextbox`);
    this.linkedin = new TextboxIdModel(`${this.id}LinkedInTextbox`);
    this.infoBubble = new InfoBubbleIdModel(`${this.id}InfoBubble`);
  }
}
