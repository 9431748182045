import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, Tab } from "@q4/nimbus-ui";

export interface DayTabsProps extends BaseComponentProps {
  items: Tab[];
  limit?: number;
  disabled?: boolean;
  onChange: (index: number) => void;
}

export enum DayTabsClassName {
  Base = "day-tabs",
  Tabs = "day-tabs_tabs",
  Pager = "day-tabs_pager",
}

export class DayTabsIdModel extends IdModelBase {
  tabs: string;
  nextButton: ButtonIdModel;
  previousButton: ButtonIdModel;
  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.tabs = `${this.id}Tabs`;
    this.nextButton = new ButtonIdModel(`${this.id}NextButton`);
    this.previousButton = new ButtonIdModel(`${this.id}PreviousButton`);
  }
}
