import "./corporateSummary.component.scss";
import {
  isEmpty,
  Anchor,
  Badge,
  Button,
  ButtonTheme,
  Card,
  CardTheme,
  Grid,
  GridColumn,
  isNullOrWhiteSpace,
  Text,
  TextPreset,
  BadgeSize,
  BadgeTheme,
  AnchorTarget,
} from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import { JsonFormLabels } from "../../../../../components/jsonForm/jsonForm.definition";
import { DefaultTimeZone } from "../../../../../const";
import { TimeFormat } from "../../../../../definitions/date.definition";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { Answer } from "../../../../../services/answer/answer.model";
import { CorporateRegistrationDeadlines } from "../../../../../services/conference/conference.model";
import { CorporateType } from "../../../../../services/corporateProfile/corporateProfile.model";
import { formatTimetableDataWithTimezone, getBackgroundImageUrl, getFormattedDateForSummary } from "../../../../../utils";
import { isCorporateDeadlinePassed, isCorporateRegisterDeadlinePassed } from "../../../../../utils/deadline/deadline.utils";
import TimeZoneSelector from "../../../components/timeZoneSelector/timeZoneSelector.component";
import {
  attendeesStepBaseProps,
  availabilityStepBaseProps,
  contactInformationStepBaseProps,
  customQuestionsStepBaseProps,
} from "../../hooks/useSections.definition";
import TermsAndConditions from "../../termsAndConditions/termsAndConditions.component";
import AnswerSummary from "../answerSummary/answerSummary.component";
import { AnswerListId } from "../answerSummary/answerSummary.definition";
import RenderAnswer from "../answerSummary/renderAnswer.component";
import { RegistrationSummaryClassName } from "../summary/summary.definition";
import { CorporateSummaryIdModel, CorporateSummaryProps } from "./corporateSummary.definition";

const CorporateSummary = (props: CorporateSummaryProps): JSX.Element => {
  const {
    id,
    registrant,
    loading,
    conference,
    questionGroup,
    isEditMode,
    isFormDirty,
    isSlotsEmpty,
    setRegistrant,
    onStep,
    onSubmit,
  } = props;

  const idModel = useMemo(() => new CorporateSummaryIdModel(id), [id]);

  const [isPolicyAgree, setIsPolicyAggree] = useState(isEditMode);

  const conferenceTimeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference?.time_zone]);
  const userTimeZone = useMemo(
    () => registrant?.time_zone ?? conferenceTimeZone,
    [registrant?.time_zone, conferenceTimeZone]
  );

  const isDeadlinePassed = useMemo(() => {
    return (key: keyof CorporateRegistrationDeadlines) => isCorporateDeadlinePassed(key, conference);
  }, [conference]);

  const isMeetingDeadlinePassed = useMemo(() => {
    return isDeadlinePassed("meeting");
  }, [isDeadlinePassed]);

  const isCorporateRegistrationDeadlinePassed = useMemo(() => {
    return isCorporateRegisterDeadlinePassed(conference);
  }, [conference]);

  const {
    corporate_name,
    host_presentation,
    host_small_meetings,
    corporate_attendees,
    logo_image,
    secondary_logo_image,
    description,
    corporate_type,
    ticker_symbol,
    exchange,
    url,
    availability,
  } = registrant;
  const primaryContact = corporate_attendees.find((attendee) => attendee.is_primary_contact);

  const { first_name, last_name, email, title, phone_number } = primaryContact || {};

  function getFullName(first: string, last: string): string {
    return `${first} ${last}`;
  }

  function handleStepBack(): void {
    onStep(false);
  }

  function handlePolicyAgreeChange(policyAgree: boolean): void {
    setIsPolicyAggree(policyAgree);
  }

  function handlePost(): void {
    onSubmit();
  }

  // #region Render Methods
  function renderCompanyInformation(): JSX.Element {
    return (
      <>
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>
              <strong>Company Name:</strong>
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>{corporate_name}</Text>
          </div>
        </div>
        {!isEmpty(first_name) && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <strong>Primary Contact:</strong>
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{getFullName(first_name, last_name)}</Text>
            </div>
          </div>
        )}
        {!isNullOrWhiteSpace(title) && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <strong>Title:</strong>
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{title}</Text>
            </div>
          </div>
        )}
        {!isNullOrWhiteSpace(email) && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <strong>Email Address:</strong>
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{email}</Text>
            </div>
          </div>
        )}
        {!isNullOrWhiteSpace(phone_number) && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <strong>Phone Number:</strong>
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{phone_number}</Text>
            </div>
          </div>
        )}
        {host_small_meetings && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <span className="q4i-checkmark-4pt"></span> Will be hosting 1:1 and Group Meetings
              </Text>
            </div>
          </div>
        )}
        {host_presentation && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                <span className="q4i-checkmark-4pt"></span> Will be hosting a presentation
              </Text>
            </div>
          </div>
        )}
      </>
    );
  }

  function renderAttendees(): JSX.Element {
    const noValidAttendees = isEmpty(corporate_attendees) || corporate_attendees.every((x) => isNullOrWhiteSpace(x.email));
    if (noValidAttendees) return <Text preset={TextPreset.Paragraph}>No attendees were added.</Text>;

    return (
      <div id={idModel.id} className="registration-summary_attendees">
        <Grid>
          {corporate_attendees.map((x) => {
            const {
              receive_emails,
              participate_in_meetings,
              speaker_info,
              is_speaker,
              first_name: firstName,
              last_name: lastName,
              title: attendeeTitle,
              custom_question_answers,
            } = x;
            const fullName = getFullName(firstName, lastName);
            const answerIdModel = new AnswerListId(x.email, "answers");
            return (
              <GridColumn key={`registration-summary_attendee${x.email}`} width="1-of-3" smallWidth="1-of-1">
                <div className="registration-summary_attendee">
                  <div className="registration-summary_attendee-section">
                    {!isNullOrWhiteSpace(speaker_info?.image_profile) && (
                      <div className="registration-summary_attendee-avatar">
                        <div style={{ backgroundImage: getBackgroundImageUrl(speaker_info?.image_profile) }} />
                      </div>
                    )}
                    <div className="registration-summary_attendee-info">
                      <Text preset={TextPreset.Subheader}>{fullName}</Text>
                      {!isNullOrWhiteSpace(attendeeTitle) && <Text preset={TextPreset.Paragraph}>{attendeeTitle}</Text>}
                      {(participate_in_meetings || !isEmpty(speaker_info)) && (
                        <div className="registration-summary_attendee-badges">
                          {is_speaker && (
                            <Badge theme={BadgeTheme.Surf} size={BadgeSize.Small}>
                              SPEAKER
                            </Badge>
                          )}
                          {participate_in_meetings && (
                            <Badge theme={BadgeTheme.Surf} size={BadgeSize.Small}>
                              1:1 MEETINGS
                            </Badge>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="registration-summary_attendee-section">
                    {receive_emails && (
                      <Text preset={TextPreset.Paragraph}>
                        <span className="q4i-checkmark-4pt"></span> Included in all communications
                      </Text>
                    )}
                  </div>
                  {(custom_question_answers || []).map((answer: Answer, index) => {
                    if (!isNullOrWhiteSpace(answer.answer as string)) {
                      const id = answerIdModel.getId(index);
                      return <RenderAnswer answer={answer} individual={true} id={id} key={id} />;
                    }
                    return null;
                  })}
                </div>
              </GridColumn>
            );
          })}
        </Grid>
      </div>
    );
  }

  function renderDetails(): JSX.Element {
    const noDetails = isNullOrWhiteSpace(
      url ?? exchange ?? ticker_symbol ?? logo_image ?? secondary_logo_image ?? description
    );
    if (noDetails) return <Text preset={TextPreset.Paragraph}>No details were added.</Text>;

    const isPublic =
      corporate_type === CorporateType.Public && !isNullOrWhiteSpace(exchange) && !isNullOrWhiteSpace(ticker_symbol);
    return (
      <>
        <div className={RegistrationSummaryClassName.Details}>
          {isPublic && (
            <div className={RegistrationSummaryClassName.Block}>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  <strong>Ticker:</strong>
                </Text>
              </div>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  {exchange}:{ticker_symbol}
                </Text>
              </div>
            </div>
          )}
          {!isNullOrWhiteSpace(url) && (
            <div className={RegistrationSummaryClassName.Block}>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  <strong>Website URL:</strong>
                </Text>
              </div>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  <Anchor url={url} target={AnchorTarget.Blank}>
                    {url}
                  </Anchor>
                </Text>
              </div>
            </div>
          )}
          <div className={RegistrationSummaryClassName.Block}>
            {!isNullOrWhiteSpace(logo_image ?? secondary_logo_image) && (
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  <strong>Logo</strong>
                </Text>
                {!isNullOrWhiteSpace(logo_image) && <img src={logo_image} alt="Main Logo" />}
                {!isNullOrWhiteSpace(secondary_logo_image) && <img src={secondary_logo_image} alt="White Logo" />}
              </div>
            )}
            {!isNullOrWhiteSpace(description) && (
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>
                  <strong>Company Description</strong>
                </Text>
                <Text preset={TextPreset.Paragraph}>{description}</Text>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  function renderAvailabilityTitle(): JSX.Element {
    const handleUserTimeZoneChange = (timeZone: string) => {
      setRegistrant((current) => {
        return new RegistrantViewModel({
          ...current,
          time_zone: timeZone,
        });
      });
    };

    return (
      <>
        Availability
        <TimeZoneSelector
          id={idModel.timeZoneSelector.id}
          conferenceTimeZone={conferenceTimeZone}
          selectedTimeZone={userTimeZone}
          anchorMargin
          onSelect={handleUserTimeZoneChange}
        />
      </>
    );
  }

  function renderAvailabilities(): JSX.Element {
    if (isEmpty(availability)) return;

    return (
      <div className={RegistrationSummaryClassName.Availabilities}>
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>
              <strong>Date</strong>
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>
              <strong>Time</strong>
            </Text>
          </div>
        </div>
        {formatTimetableDataWithTimezone(availability, userTimeZone).map((day, idx, self) => {
          return (
            <div key={`availability-item-${idx}`} className={RegistrationSummaryClassName.Block}>
              <div className={RegistrationSummaryClassName.BlockSection}>
                {getFormattedDateForSummary(!idx, day, self[idx - 1], userTimeZone)}
              </div>
              <div className={RegistrationSummaryClassName.BlockSection}>
                {day.start_time.tz(userTimeZone).format(TimeFormat.Standard)} -{" "}
                {day.end_time?.clone().tz(userTimeZone).format(TimeFormat.TimezoneStandard)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  // #endregion

  return (
    <div className={RegistrationSummaryClassName.Base}>
      <Card
        className={RegistrationSummaryClassName.Section}
        title="Company Information"
        id={idModel.companyInformationSection.id}
        headerChildren={
          <Button
            id={idModel.companyInformationEdit.id}
            icon="q4i-edit-4pt"
            label="Edit"
            disabled={loading}
            theme={ButtonTheme.LightGrey}
            onClick={() => onStep(contactInformationStepBaseProps.id)}
          />
        }
        theme={CardTheme.Light}
      >
        {renderCompanyInformation()}
      </Card>
      <Card
        className={RegistrationSummaryClassName.Section}
        title="Attendees"
        id={idModel.attendeesSection.id}
        headerChildren={
          !isCorporateRegistrationDeadlinePassed && (
            <Button
              icon="q4i-edit-4pt"
              label="Edit"
              disabled={loading}
              theme={ButtonTheme.LightGrey}
              onClick={() => onStep(attendeesStepBaseProps.id)}
            />
          )
        }
        theme={CardTheme.Light}
      >
        {renderAttendees()}
      </Card>
      <Card
        className={RegistrationSummaryClassName.Section}
        title="Details"
        id={idModel.detailsSection.id}
        headerChildren={
          <Button
            icon="q4i-edit-4pt"
            label="Edit"
            disabled={loading}
            theme={ButtonTheme.LightGrey}
            onClick={() => onStep(contactInformationStepBaseProps.id)}
          />
        }
        theme={CardTheme.Light}
      >
        {renderDetails()}
      </Card>
      {!isEmpty(availability) && !isSlotsEmpty ? (
        <Card
          className={RegistrationSummaryClassName.Section}
          title={renderAvailabilityTitle()}
          id={idModel.availabilitySection.id}
          headerChildren={
            !isMeetingDeadlinePassed && (
              <Button
                icon="q4i-edit-4pt"
                label="Edit"
                disabled={loading}
                theme={ButtonTheme.LightGrey}
                onClick={() => onStep(availabilityStepBaseProps.id)}
              />
            )
          }
          theme={CardTheme.Light}
        >
          {renderAvailabilities()}
        </Card>
      ) : null}
      {questionGroup && !isEmpty(registrant.custom_question_answers) && (
        <AnswerSummary
          id={idModel.answerSummary?.id}
          loading={loading}
          questionGroup={questionGroup}
          answers={registrant.custom_question_answers}
          goToEdit={() => onStep(customQuestionsStepBaseProps.id)}
        />
      )}
      {isEditMode ? null : (
        <TermsAndConditions
          id={idModel.termsAndConditions?.id}
          onAgree={handlePolicyAgreeChange}
          policyAgree={isPolicyAgree}
        />
      )}
      <div className={RegistrationSummaryClassName.Section}>
        <Button
          id={idModel.stepBack?.id}
          className="registration_back-button"
          label={JsonFormLabels.PrevStep}
          disabled={loading}
          theme={ButtonTheme.LightGrey}
          onClick={handleStepBack}
        />
        <Button
          id={idModel.confirm?.id}
          label={isEditMode ? "UPDATE INFORMATION" : "CONFIRM REGISTRATION"}
          disabled={(!isPolicyAgree && !isEditMode) || (isEditMode && !isFormDirty)}
          loading={loading}
          theme={ButtonTheme.Rain}
          onClick={handlePost}
        />
      </div>
    </div>
  );
};

export default memo(CorporateSummary);
