import { isNil } from "@q4/nimbus-ui";
import { throttle } from "lodash";
import { MutableRefObject, useEffect, useMemo, useState } from "react";

const AspectRatio = {
  "16:9": 16 / 9,
  "4:3": 4 / 3,
};

interface AspectRatioHookProps {
  aspectRatio: number;
  ref: MutableRefObject<HTMLElement>;
}

export const useAspectRatio = (props: AspectRatioHookProps): { width: number; height: number } => {
  const { aspectRatio: aspectRatioProp, ref } = props;

  const aspectRatio = useMemo(
    () => (typeof aspectRatioProp === "number" ? aspectRatioProp : AspectRatio["16:9"]),
    [aspectRatioProp]
  );

  const [height, setHeight] = useState(null);

  useEffect((): (() => void) => {
    const calcHeight = throttle(function handleResize(): void {
      const bounds = ref?.current?.getBoundingClientRect();

      if (isNil(bounds)) return;
      const presentationHeight = bounds.width / aspectRatio;

      setHeight(presentationHeight);
    }, 100);

    calcHeight();
    window.addEventListener("resize", calcHeight);
    return (): void => {
      window.removeEventListener("resize", calcHeight);
    };
  }, [aspectRatio, ref]);

  return { width: null, height };
};
