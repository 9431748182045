import { AutomationListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { BrandingBase } from "../../../../../components/companyBranding/companyBranding.definition";
import type { LobbyConfigSection } from "../../../../../services/conference/conference.model";
import type { Speaker } from "../../../../../services/speaker/speaker.model";
import { PanelIdModel } from "../../../../public/itinerary/components/panel/panel.definition";
import { SliderIdModel } from "../../../../public/itinerary/components/slider/slider.definition";
import { SectionProps } from "../section/section.definition";
import { SpeakerDetailsIdModel } from "./components/details/speakerDetails.definition";

export interface SpeakerProps extends BaseComponentProps, Pick<SectionProps, "theme" | "keyLine"> {
  compact?: boolean;
  selectedSpeaker: Speaker;
  speakers: Speaker[];
  speakerSectionConfig: LobbyConfigSection;
  visible?: boolean;
  branding?: BrandingBase;
  onSelect: (speaker: Speaker) => void;
}

export enum SpeakersClassName {
  Base = "speakers",
  BaseWithCompactModifier = "speakers--compact",
  Content = "speakers_content",
  Slider = "speakers_slider",
  Item = "speakers_item",
}

export class SpeakersIdModel extends IdModelBase {
  slider: SliderIdModel;
  list: SpeakersItemListId;
  details: SpeakerDetailsIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.slider = new SliderIdModel(null);
      this.list = new SpeakersItemListId(null);
      return;
    }

    this.slider = new SliderIdModel(`${this.id}Slider`);
    this.list = new SpeakersItemListId(this.id);
    this.details = new SpeakerDetailsIdModel(`${this.id}Details`);
  }
}

export class SpeakersItemListId extends AutomationListId<SpeakersItemIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, SpeakersItemIdModel, postfix);
  }
}

export class SpeakersItemIdModel extends IdModelBase {
  panel: PanelIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.panel = new PanelIdModel(null);
      return;
    }

    this.panel = new PanelIdModel(`${this.id}Panel`);
  }
}
