import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { UserContextState } from "../../../../contexts/user/user.definition";
import { CompanyLobbyLayoutIdModel } from "../components/layout/companyLobby.layout.definition";
import { PresentationTheaterIdModel } from "./components/presentationTheater/presentationTheater.definition";

export interface CompanyLobbyPresentationProps {
  userContext: UserContextState;
}

export class PageIdModel extends IdModelBase {
  layout: CompanyLobbyLayoutIdModel;
  presentationTheater: PresentationTheaterIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.layout = new CompanyLobbyLayoutIdModel(`${this.id}Layout`);
    this.presentationTheater = new PresentationTheaterIdModel(`${this.id}PresentationTheater`);
  }
}

const PageId = "CompanyPresentation";
export const CompanyPresentationIdModel = new PageIdModel(PageId);
