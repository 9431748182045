import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { History } from "history";
import { HeaderIdModel } from "./components/header/header.definition";
import { NavigationIdModel } from "./components/navigation/navigation.definition";

export interface RootProps {
  history: History<unknown>;
}

export enum RootClassName {
  Base = "events-conference",
  Wrapper = "events-conference_wrapper",
  WrapperWithAdminModifier = "events-conference_wrapper--admin",
  WrapperWithPublicModifier = "events-conference_wrapper--public",
  WrapperWithFullscreenModifier = "events-conference_wrapper--fullscreen",
  Navigation = "events-conference_navigation",
  Inner = "events-conference_inner",
  View = "events-conference_view",
}

class PageIdModel extends IdModelBase {
  adminHeader: HeaderIdModel;
  adminNavigation: NavigationIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.adminHeader = new HeaderIdModel(`${this.id}AdminHeader`);
    this.adminNavigation = new NavigationIdModel(`${this.id}AdminNavigation`);
  }
}

const PageId = "Root";
export const RootIdModel = new PageIdModel(PageId);
