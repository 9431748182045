import "./meetingConfiguration.component.scss";
import { Grid, GridColumn, Tabs, TabsTheme, useVisibility, Toolbar, MessageType, Message } from "@q4/nimbus-ui";
import { camelCase, isEqual } from "lodash";
import moment from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { ConferenceScheduler } from "../../../../../../services/conference/conference.model";
import { hasConferenceScheduler } from "../../../../../../utils/scheduler/scheduler.utils";
import { buildSchedulerSlots, getCalendarDays } from "../meetingScheduler/meetingScheduler.utils";
import MeetingShellCreator from "./components/meetingShellCreator/meetingShellCreator.component";
import MeetingTimeSlotCreator from "./components/meetingTimeSlotCreator/meetingTimeSlotCreator.component";
import ScheduledTimeSlotView from "./components/scheduledTimeSlotView/scheduledTimeSlotView.component";
import {
  MeetingConfigurationClassName,
  MeetingConfigurationIdModel,
  MeetingConfigurationProps,
  MeetingConfigurationTabs,
} from "./meetingConfiguration.definition";

const MeetingConfiguration = (props: MeetingConfigurationProps): JSX.Element => {
  const { toolbarProps, conference, id, useConferences } = props;
  const idModel = useMemo(() => new MeetingConfigurationIdModel(id), [id]);

  const calendarDays = useMemo(() => {
    return getCalendarDays(conference?.scheduler, conference?.time_zone);
  }, [conference?.scheduler, conference?.time_zone]);

  const [currentCalendarDay, setCurrentCalendarDay] = useState(calendarDays?.[0]);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);

  const [schedulerState, setSchedulerState] = useState<ConferenceScheduler>({ ...conference?.scheduler });

  const [createScheduleMessageVisible, handleCreateScheduleMessageOpen, handleCreateScheduleMessageClose] = useVisibility();

  const [selectedTab, setSelectedTab] = useState(hasConferenceScheduler(conference) ? 1 : 0);

  const tabs = useMemo(() => {
    return Object.values(MeetingConfigurationTabs).map((tabName) => {
      const idModelKey = `${camelCase(tabName)}Tab`;

      const tabId = idModel[idModelKey as keyof MeetingConfigurationIdModel] as string;
      return {
        key: `meeting-configuration-toggle_${tabName}`,
        id: tabId,
        label: tabName,
        value: tabName,
        disabled: editMode,
      };
    });
  }, [idModel, editMode]);

  const { patchSchedulerById } = useConferences;

  const handleUpdateSchedule = useCallback(
    async (schedule: ConferenceScheduler) => {
      setSaving(true);

      const orderedSlots = schedule.slots.sort((slotA, slotB) => {
        return (
          moment(slotA.start_time).diff(moment(slotB.start_time)) || moment(slotA.end_time).diff(moment(slotB.end_time))
        );
      });

      const newSchedule = await patchSchedulerById(conference?._id, { ...schedule, slots: orderedSlots }, true)?.finally(
        () => setSaving(false)
      );

      setSchedulerState((previousState) => ({ ...previousState, ...newSchedule }));
    },
    [conference?._id, patchSchedulerById]
  );

  const handleBuildSchedule = useCallback(async () => {
    const slots = buildSchedulerSlots(schedulerState, conference?.time_zone);
    const formattedSchedule = {
      ...schedulerState,
      start_date: moment(schedulerState.start_date).tz(conference?.time_zone, true).startOf("day"),
      end_date: moment(schedulerState.end_date).tz(conference?.time_zone, true).startOf("day"),
      slots,
    };

    await handleUpdateSchedule(formattedSchedule);
    handleCreateScheduleMessageClose();

    setSelectedTab(1);

    // reset current calendar day if needed
    const calDaysValue = calendarDays.map((x) => x.valueOf());
    const daysValue = getCalendarDays(schedulerState, conference?.time_zone).map((x) => x.valueOf());
    const isDaysChange = !isEqual(calDaysValue, daysValue);

    if (isDaysChange) setCurrentCalendarDay(moment(schedulerState.start_date));
  }, [calendarDays, conference?.time_zone, handleCreateScheduleMessageClose, handleUpdateSchedule, schedulerState]);

  const handleCreateNewSchedule = useCallback(() => {
    if (!hasConferenceScheduler(conference)) {
      handleBuildSchedule();
    } else {
      handleCreateScheduleMessageOpen();
    }
  }, [conference, handleBuildSchedule, handleCreateScheduleMessageOpen]);

  return (
    <div className={MeetingConfigurationClassName.Base}>
      <Toolbar id={toolbarProps?.id} theme={toolbarProps?.theme} autoRow autoRowProps={{ justifyContent: "space-between" }}>
        {toolbarProps?.children}
      </Toolbar>
      <div id={idModel.container} className={MeetingConfigurationClassName.Content}>
        <Grid>
          <GridColumn margin={false} width="1-of-5">
            <div className={MeetingConfigurationClassName.ContentTabs}>
              <Tabs theme={TabsTheme.LightGrey} selected={selectedTab} items={tabs} onChange={setSelectedTab} />
            </div>
            {selectedTab ? (
              <MeetingTimeSlotCreator
                conference={conference}
                scheduler={schedulerState}
                id={idModel.timeSlotCreator.id}
                saving={saving}
                onSubmit={handleUpdateSchedule}
                currentCalendarDay={currentCalendarDay}
                hasConferenceScheduler={hasConferenceScheduler(conference)}
                editMode={editMode || saving}
              />
            ) : (
              <MeetingShellCreator
                id={idModel.shellCreator.id}
                conference={conference}
                scheduler={schedulerState}
                saving={saving}
                setSchedule={setSchedulerState}
                onSubmit={handleCreateNewSchedule}
              />
            )}
          </GridColumn>
          <GridColumn width="4-of-5">
            <ScheduledTimeSlotView
              id={idModel.scheduledTimeSlotView.id}
              conference={conference}
              scheduler={schedulerState}
              editMode={editMode || saving}
              setEditMode={setEditMode}
              saving={saving}
              onSubmit={handleUpdateSchedule}
              currentCalendarDay={currentCalendarDay}
              setCurrentCalendarDay={setCurrentCalendarDay}
              calendarDays={calendarDays}
            />
          </GridColumn>
        </Grid>
        <Message
          id={idModel.createNewScheduleMessage.id}
          visible={createScheduleMessageVisible}
          title="Create New Schedule?"
          message="Creating a new meeting schedule will remove any custom time slots added to the schedule. This will not remove any scheduled meetings."
          messageType={MessageType.Warning}
          secondaryActionProps={{
            id: idModel.createNewScheduleCancel.id,
            label: "Cancel",
            disabled: saving,
            onClick: handleCreateScheduleMessageClose,
          }}
          primaryActionProps={{
            id: idModel.createNewScheduleConfirm.id,
            label: "Create Schedule",
            loading: saving,
            onClick: handleBuildSchedule,
          }}
          onCloseRequest={handleCreateScheduleMessageClose}
        />
      </div>
    </div>
  );
};

export default memo(MeetingConfiguration);
