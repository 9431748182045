import "./conferenceEdit.view.scss";
import {
  ButtonTheme,
  convertStringToEnum,
  Form,
  Grid,
  GridColumn,
  isEmpty,
  isNullOrWhiteSpace,
  Modal,
  RadioButton,
  Textbox,
  useVisibility,
} from "@q4/nimbus-ui";
import { set } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import DeleteConfirmationMessage from "../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.component";
import { FileUploaderWithImgPreload } from "../../../../components/fileUploader/fileUploader.component";
import GridCard from "../../../../components/gridCard/gridCard.component";
import { RoutePath, RoutePathIdLabel } from "../../../../configurations/navigation.configuration";
import { ConferenceContext } from "../../../../contexts/conference/conference.context";
import contextSubscribe from "../../../../contexts/context.subscribe";
import { Entity } from "../../../../definitions/entity.definition";
import { useAttendees, useConferences, useFileUpload } from "../../../../hooks";
import { Company } from "../../../../services/admin/companies/companies.model";
import {
  Conference,
  ConferenceEmail,
  ConferenceType,
  LoginConfig,
  PresentationVendor,
  PresentationVendorType,
  VideoVendor,
} from "../../../../services/conference/conference.model";
import QueryService from "../../../../services/query/query.service";
import { ConferenceCompanyQueryParam } from "../conferences.definition";
import ConferenceAdditionalInformationEdit from "./components/additionalInformation/additionalInformation.component";
import ConferenceBranding from "./components/branding/branding.component";
import ConferenceCoordinatorEdit from "./components/coordinator/coordinator.component";
import { EmailForm } from "./components/emailForm/emailForm.component";
import { LobbyConfigForm } from "./components/lobbyConfigForm/lobbyConfigForm.component";
import { LoginMessageForm } from "./components/loginMessageForm/loginMessageForm.component";
import NavigationLinks from "./components/navigationLinks/navigationLinks.component";
import PresentationVendorsForm from "./components/presentationVendorsForm/presentationVendorsForm.component";
import ConferenceRegistrationEdit from "./components/registration/registration.component";
import SecuritySettings from "./components/securitySettingsEdit/securitySettingsEdit.component";
import ConferenceTimeline from "./components/timeline/conferenceTimelineEdit.component";
import VideoVendorsForm from "./components/videoVendorsForm/videoVendorsForm.component";
import {
  ConferenceEditClassName,
  ConferenceEditIdModel as IdModel,
  ConferenceEditMatchParams,
  ConferenceEditProps,
  ConferenceEditState,
  VendorStatus,
} from "./conferenceEdit.definition";
import { convertConferenceEditToConference, checkLobbyOpenDate } from "./conferenceEdit.utils";

const ConferenceEdit = (props: ConferenceEditProps): JSX.Element => {
  const { conferenceContext } = props;
  const history = useHistory();
  const params = useParams<ConferenceEditMatchParams>();

  const [conferenceState, setConferenceState] = useReducer(reducer, null);
  const [hasRegistrationDatesError, setHasRegistrationDatesError] = useState(false);
  const [vendorStatus, setVendorStatus] = useState<VendorStatus>(VendorStatus.Unverified);

  const {
    current: conference,
    loading,
    deleteById: deleteConferenceById,
    fetchById: fetchConferenceById,
    post: postConference,
    putById: putConferenceById,
    verifyPresentationVendor,
  } = useConferences({
    autoFetchData: false,
  });

  const currentTimeZone = useRef(conferenceContext.conference?.time_zone ?? conference?.time_zone);

  const handleFileUpload = useFileUpload();

  const [messageVisible, handleDeleteClick, handleMessageClose] = useVisibility();

  const conferenceId = useMemo(() => params?.conferenceId, [params]);
  const edit = useMemo(() => !isNullOrWhiteSpace(conferenceId), [conferenceId]);

  const { items: totalAttendees } = useAttendees({ autoFetchData: !isNullOrWhiteSpace(conferenceId), conferenceId });

  const handleEmailConfigChange = useCallback((emailConfig: ConferenceEmail) => {
    setConferenceState({ email_configuration: emailConfig });
  }, []);

  const handleLoginMessageConfigChange = useCallback((loginMessageConfig: LoginConfig) => {
    setConferenceState({ login: loginMessageConfig });
  }, []);

  useEffect(() => {
    fetchConferenceById(conferenceId, conferenceContext?.conference).then((response) => {
      if (!isEmpty(response)) {
        setConferenceState(new ConferenceEditState(response));
        return;
      }

      const queryService = new QueryService();
      const value = queryService.get(ConferenceCompanyQueryParam);
      const newConference = new ConferenceEditState(response);
      setConferenceState({ ...newConference, _company: new Company(value) });
    });
  }, [conferenceContext, conferenceId, fetchConferenceById]);

  // TODO fix deep objects not resetting on cancel/exit i.e branding and coordinator
  function handleExit(details = false, data?: Conference): void {
    conferenceContext.set(details ? data || conference : null);
    const path = details
      ? RoutePath.ConferenceDetails.replace(RoutePathIdLabel.Conference, conferenceId)
      : RoutePath.Conferences;
    history.push(path);
  }

  async function handleConferenceDelete(): Promise<void> {
    if (!edit || isNullOrWhiteSpace(conferenceId)) return;

    const success = await deleteConferenceById(conferenceId);
    if (!success) return;
    handleExit();
  }

  function handleCancelClick(): void {
    handleExit(edit);
  }

  async function handleSubmitClick(): Promise<void> {
    const error = checkForErrors();

    if (error) return;

    const data = convertConferenceEditToConference(conferenceState, currentTimeZone.current ?? conference?.time_zone);

    if (isEmpty(data)) return;

    await (edit ? putConferenceById(conferenceId, data) : postConference(data)).then((saved) => {
      if (isEmpty(saved)) return;
      handleExit(edit, saved);
    });
  }

  function handlePresentationVendorChange(vendor: PresentationVendor): void {
    setConferenceState({
      ...conferenceState,
      presentation_vendors: [vendor],
    });
    setVendorStatus(VendorStatus.Unverified);
  }

  function handleVideoVendorChange(vendor?: VideoVendor): void {
    const value = isNullOrWhiteSpace(vendor?.name) ? [] : [vendor];
    setConferenceState({
      ...conferenceState,
      video_vendors: value,
    });
  }

  function getStateChangeHandler(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      const newState = set({ ...conferenceState }, key, value);
      setConferenceState(newState);
    };
  }

  function reducer(prevState: ConferenceEditState, state: Partial<ConferenceEditState>): ConferenceEditState {
    return {
      ...prevState,
      ...state,
    };
  }

  function checkForErrors(): boolean {
    const { title, presentation_vendors, lobby } = conferenceState || {};
    const { corporate_lobby, investor_lobby, general_lobby } = lobby || {};
    const pVendor = presentation_vendors?.[0];

    const tests = [
      isNullOrWhiteSpace(title),
      pVendor?.name === PresentationVendorType.Streamlined && isNullOrWhiteSpace(pVendor?.options?.hub_id),
      !checkLobbyOpenDate(corporate_lobby?.open_date, conferenceState),
      !checkLobbyOpenDate(investor_lobby?.open_date, conferenceState),
      !checkLobbyOpenDate(general_lobby?.open_date, conferenceState),
    ];

    return tests.some((x) => !!x) || hasRegistrationDatesError;
  }

  const handleConferenceTypeChange = useCallback(
    (_checked: boolean, value: string) => {
      const changedConferenceType = convertStringToEnum(ConferenceType, value);
      setConferenceState({ ...conferenceState, conference_type: changedConferenceType });
    },
    [conferenceState]
  );

  const handleVerifyPresentationVendor = useCallback(
    async (hubId: string) => {
      const result = await verifyPresentationVendor(hubId);
      setVendorStatus(result.data?.access ? VendorStatus.Verified : VendorStatus.Inaccessible);
    },
    [verifyPresentationVendor, setVendorStatus]
  );

  return (
    <div id={IdModel.id}>
      <Modal
        id={IdModel.modal.id}
        className={ConferenceEditClassName.Base}
        visible={true}
        fullscreen
        focusOnProps={{
          autoFocus: false,
        }}
        scrollable
        title={edit ? "Update Conference" : "Schedule Conference"}
        subtitle={
          !isEmpty(conference) && !isNullOrWhiteSpace(conference.updated_by)
            ? `Last modified by ${conference.updated_by}`
            : null
        }
        badgeIcon="q4i-conference-2pt"
        footerActions={[
          {
            id: IdModel.delete.id,
            key: "delete",
            className: ConferenceEditClassName.DeleteButton,
            icon: "q4i-trashbin-4pt",
            theme: ButtonTheme.DarkSlate,
            disabled: !edit || loading,
            wide: false,
            square: true,
            onClick: handleDeleteClick,
          },
          {
            id: IdModel.cancel.id,
            key: "cancel",
            label: "Cancel",
            theme: ButtonTheme.DarkSlate,
            disabled: loading,
            onClick: handleCancelClick,
          },
          {
            id: IdModel.confirmSchedule.id,
            key: "submit",
            label: edit ? "Update Conference" : "Schedule Conference",
            theme: ButtonTheme.Citrus,
            loading: loading,
            disabled: checkForErrors(),
            onClick: handleSubmitClick,
          },
        ]}
        onCloseRequest={handleCancelClick}
      >
        <Grid className={ConferenceEditClassName.Grid}>
          <GridColumn className={ConferenceEditClassName.Column} width="1-of-2" smallWidth="1-of-1">
            <GridCard cardProps={{ title: "Conference Setup" }}>
              <Form
                fields={[
                  {
                    key: "Conference Type",
                    className: ConferenceEditClassName.Type,
                    width: "1-of-1",
                    smallWidth: "1-of-1",
                    label: "Conference Type",
                    direction: "column",
                    required: true,
                    children: (
                      <>
                        {Object.values(ConferenceType).map((option, i) => (
                          <RadioButton
                            key={option}
                            id={IdModel.conferenceType.getId(i)}
                            name={option}
                            label={option}
                            value={option}
                            checked={conferenceState?.conference_type === option}
                            alignmentPadding
                            onChange={handleConferenceTypeChange}
                          />
                        ))}
                      </>
                    ),
                  },
                  {
                    key: "Conference Title",
                    width: "1-of-1",
                    smallWidth: "1-of-1",
                    label: "Conference Name",
                    required: true,
                    children: (
                      <Textbox
                        id={IdModel.name.id}
                        autoFocus={false}
                        value={conferenceState?.title}
                        onChange={getStateChangeHandler("title")}
                      />
                    ),
                  },
                  {
                    key: "Conference Path",
                    width: "3-of-4",
                    smallWidth: "3-of-4",
                    xSmallWidth: "1-of-2",
                    label: "Conference Path",
                    children: (
                      <Textbox
                        id={IdModel.path.id}
                        autoFocus={false}
                        value={conferenceState?.path}
                        onChange={getStateChangeHandler("path")}
                      />
                    ),
                  },
                ]}
              />
            </GridCard>
            <ConferenceTimeline
              id={IdModel.timeline.id}
              state={{
                open_date: conferenceState?.open_date,
                start_date: conferenceState?.start_date,
                end_date: conferenceState?.end_date,
                close_date: conferenceState?.close_date,
                date_label: conferenceState?.date_label,
                time_zone: conferenceState?.time_zone,
              }}
              type={conferenceState?.conference_type}
              onChange={setConferenceState}
            />
            <ConferenceRegistrationEdit
              id={IdModel.registration.id}
              className={ConferenceEditClassName.Registration}
              conference={conferenceState}
              setRegistrationError={setHasRegistrationDatesError}
              onChange={setConferenceState}
            />
            <ConferenceAdditionalInformationEdit
              id={IdModel.additionalInformation.id}
              conference={conferenceState}
              onChange={setConferenceState}
            />
            <ConferenceCoordinatorEdit
              id={IdModel.coordinator.id}
              coordinator={conferenceState?.coordinator}
              replyTo={conferenceState?.reply_to}
              onChange={setConferenceState}
            />
            <GridCard cardProps={{ title: "Lobby Configuration" }}>
              <LobbyConfigForm
                id={IdModel.lobbyConfig.id}
                conference={conferenceState}
                getStateChangeHandler={getStateChangeHandler}
              />
              <LoginMessageForm
                conference={conferenceState}
                id={IdModel.loginMessage.id}
                onChange={handleLoginMessageConfigChange}
                className={ConferenceEditClassName.LoginMessage}
              />
            </GridCard>
            <GridCard cardProps={{ title: "Email Settings" }}>
              <EmailForm
                conference={conferenceState}
                id={IdModel.emailForm.id}
                onChange={handleEmailConfigChange}
                className={ConferenceEditClassName.EmailForm}
              />
            </GridCard>
          </GridColumn>
          <GridColumn
            className={`${ConferenceEditClassName.Column} ${ConferenceEditClassName.ColumnWithDesignModifier}`}
            width="1-of-2"
            smallWidth="1-of-1"
          >
            <GridCard cardProps={{ title: "Branding" }}>
              <Form
                fields={[
                  {
                    key: "Splash",
                    width: "1-of-1",
                    smallWidth: "1-of-1",
                    label: "Splash",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.splashImage}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.image_background}
                        onChange={getStateChangeHandler("image_background")}
                        showPreview={true}
                      />
                    ),
                  },
                  {
                    key: "Logo",
                    width: "1-of-2",
                    smallWidth: "1-of-1",
                    label: "Logo",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.logoImage}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.image_logo}
                        onChange={getStateChangeHandler("image_logo")}
                        showPreview={true}
                      />
                    ),
                  },
                  {
                    key: "Logo (secondary)",
                    width: "1-of-2",
                    smallWidth: "1-of-1",
                    label: "Logo (Secondary)",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.logoImageSecondary}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.image_logo_secondary}
                        onChange={getStateChangeHandler("image_logo_secondary")}
                        showPreview={true}
                      />
                    ),
                  },
                  {
                    key: "Favicon URL",
                    width: "1-of-2",
                    smallWidth: "1-of-1",
                    label: "Favicon",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.favicon}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.image_favicon}
                        onChange={getStateChangeHandler("image_favicon")}
                        showPreview={true}
                      />
                    ),
                  },
                  {
                    key: "Live URL",
                    width: "1-of-2",
                    smallWidth: "1-of-1",
                    label: "Live Presentation Image",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.liveImage}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.image_live}
                        onChange={getStateChangeHandler("image_live")}
                        showPreview={true}
                      />
                    ),
                  },
                  {
                    key: "Login Background Image",
                    width: "1-of-1",
                    smallWidth: "1-of-1",
                    label: "Login Background Image",
                    children: (
                      <FileUploaderWithImgPreload
                        id={IdModel.lobbyLoginPageBackground.id}
                        dropzoneProps={{ accept: ["image/*"] }}
                        fileApi={handleFileUpload}
                        fileUrl={conferenceState?.login?.background}
                        onChange={getStateChangeHandler("login.background")}
                        showPreview={true}
                      />
                    ),
                  },
                ]}
              />

              <ConferenceBranding
                id={IdModel.branding.id}
                branding={conferenceState?.branding}
                getStateChangeHandler={getStateChangeHandler}
              />
            </GridCard>
            <GridCard cardProps={{ title: "Navigation Links" }}>
              <NavigationLinks
                id={IdModel.navigationLinks.id}
                value={conferenceState?.navigation_links}
                onChange={(value, field, index) => getStateChangeHandler(`navigation_links[${index}].${field}`)(value)}
                onDelete={(index: number) => {
                  conferenceState.navigation_links?.splice(index, 1);
                  setConferenceState({ ...conferenceState });
                }}
                onAdd={(link, index) => getStateChangeHandler(`navigation_links[${index}]`)(link)}
              />
            </GridCard>
            <GridCard cardProps={{ title: "Video Configuration" }}>
              <VideoVendorsForm
                id={IdModel.videoVendor.id}
                vendor={conferenceState?.video_vendors?.[0]}
                onChange={handleVideoVendorChange}
              />
              <PresentationVendorsForm
                id={IdModel.presentationVendor.id}
                className={ConferenceEditClassName.PresentationVendor}
                vendor={conferenceState?.presentation_vendors?.[0]}
                previousVendorState={conference?.presentation_vendors?.[0]}
                totalAttendees={totalAttendees || []}
                onChange={handlePresentationVendorChange}
                onVerifyVendor={handleVerifyPresentationVendor}
                vendorStatus={vendorStatus}
              />
            </GridCard>
            <SecuritySettings
              id={IdModel.securitySettings.id}
              meetingAuthenticated={conferenceState?.requireAuthenticatedMeetingLink}
              onUpdate={setConferenceState}
            />
          </GridColumn>
        </Grid>
      </Modal>
      <DeleteConfirmationMessage
        id={IdModel.deleteMessage.id}
        entity={Entity.Conference}
        visible={messageVisible}
        loading={loading}
        onCancel={handleMessageClose}
        onConfirm={handleConferenceDelete}
      />
    </div>
  );
};

export default contextSubscribe([{ context: ConferenceContext, mapToProps: "conferenceContext" }], memo(ConferenceEdit));
