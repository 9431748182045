import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { MeetingRequest, RegistrantViewModel } from "../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../services/attendee/attendee.model";
import { ConferenceType } from "../../../../services/conference/conference.model";
import { trimFormData, getNonConflictingSlots } from "../../../../utils";
import RegistrationAvailability from "../components/availability/availability.component";
import { Timeslots } from "../components/availability/availability.definition";
import CorporateAttendees from "../components/corporateAttendees/corporateAttendees.component";
import CorporateDetails from "../components/corporateDetails/corporateDetails.component";
import CorporateSummary from "../components/corporateSummary/corporateSummary.component";
import CustomQuestions from "../components/customQuestions/customQuestions.component";
import InvestorPersonalInformation from "../components/investorPersonalInformation/investorPersonalInformation.component";
import InvestorSummary from "../components/investorSummary/investorSummary.component";
import MeetingRequests from "../components/meetingRequests/meetingRequests.component";
import PersonalInformation from "../components/personalInformation/personalInformation.component";
import Summary from "../components/summary/summary.component";
import { isInvestorAvailabilityChangesOpen, isInvestorMeetingRequestChangesOpen } from "../registration.utils";
import {
  EditAttendeeInformationSteps,
  SectionsHookModel,
  SectionsHookProps,
  corporateRegistrationSteps,
  guestRegistrationSteps,
  internalRegistrationSteps,
  investorRegistrationSteps,
  RegistrationAttendeeTypeSteps,
  customQuestionsStepBaseProps,
  availabilityStepBaseProps,
  meetingRequestsStepBaseProps,
  StepIndexById,
} from "./useSections.definition";

export const useSections = (props: SectionsHookProps): SectionsHookModel => {
  const {
    viewIdModel,
    showAvailability,
    showMeetings,
    isGuestOtherTypesEnabled,
    companyName,
    conference,
    registrant,
    originalRegistrant,
    loading,
    isFormDirty,
    isEditMode,
    questionGroup,
    questionGroupLoading,
    setFileState,
    setRegistrant,
    handleRegistrantChange,
    setLoading,
    checkAttendeeEmailsAvailable,
    onSubmit,
  } = props;

  const [sectionsLoading, setSectionsLoading] = useState(true);
  const [sectionStep, setSectionStep] = useState(0);
  const stepIndexById = useRef(null);
  const [meetingRequests, setMeetingRequests] = useState<MeetingRequest[]>([]);
  const [timeslots, setTimeslots] = useState<Timeslots>({});

  const isInitialRegistrantDataSet = useRef(false);

  const investorAvailabilityChangesOpen = useMemo(() => isInvestorAvailabilityChangesOpen(conference), [conference]);
  const investorMeetingRequestChangesOpen = useMemo(() => isInvestorMeetingRequestChangesOpen(conference), [conference]);
  const showCustomQuestions = useMemo(
    () => questionGroup?._questions?.some((q) => !q.is_individual_corporate),
    [questionGroup]
  );
  const isInvestorAttendee = useMemo(() => registrant?.attendee_type === AttendeeType.Investor, [registrant?.attendee_type]);
  const isCorporateAttendee = useMemo(
    () => registrant?.attendee_type === AttendeeType.Corporate,
    [registrant?.attendee_type]
  );

  const filteredSlots = useMemo(() => {
    return getNonConflictingSlots(conference?.scheduler?.slots);
  }, [conference?.scheduler?.slots]);

  const isSlotsEmpty = useMemo(() => isEmpty(filteredSlots), [filteredSlots]);

  const handleStep = useCallback(
    (forward: boolean | number | string, formData: RegistrantViewModel = null): void => {
      if (typeof forward === "string" && stepIndexById.current) {
        setSectionStep(stepIndexById.current[forward]);
        return;
      }

      if (typeof forward === "number") {
        setSectionStep(forward);
        return;
      }

      if (forward === false && !isEmpty(formData)) {
        handleRegistrantChange(
          new RegistrantViewModel({
            ...registrant,
            ...trimFormData(formData),
          })
        );
      }

      setSectionStep((currentStep) => Math.max(forward ? currentStep + 1 : currentStep - 1, 0));
    },
    [handleRegistrantChange, registrant]
  );

  const getComponentStepById = useCallback(
    (stepId: string) => {
      switch (stepId) {
        case corporateRegistrationSteps.contactInformation.id:
        case investorRegistrationSteps.personalInformation.id:
        case internalRegistrationSteps.personalInformation.id:
        case guestRegistrationSteps.personalInformation.id:
          if (isCorporateAttendee) {
            return {
              ...(isEditMode
                ? EditAttendeeInformationSteps[AttendeeType.Corporate].contactInformation
                : RegistrationAttendeeTypeSteps[AttendeeType.Corporate].contactInformation),
              component: (
                <CorporateDetails
                  id={viewIdModel.corporateDetails?.id}
                  registrant={registrant}
                  conference={conference}
                  isEditMode={isEditMode}
                  setFileState={setFileState}
                  onStep={handleStep}
                  onChange={handleRegistrantChange}
                />
              ),
            };
          } else if (isInvestorAttendee) {
            return {
              ...(isEditMode
                ? EditAttendeeInformationSteps[AttendeeType.Investor].personalInformation
                : RegistrationAttendeeTypeSteps[AttendeeType.Investor].personalInformation),
              component: (
                <InvestorPersonalInformation
                  id={viewIdModel.investorPersonalInformation.id}
                  registrant={registrant}
                  onStep={handleStep}
                  onChange={handleRegistrantChange}
                  checkAttendeeEmailsAvailable={checkAttendeeEmailsAvailable}
                  setLoading={setLoading}
                  isEditMode={isEditMode}
                />
              ),
            };
          }
          return {
            ...(isEditMode
              ? EditAttendeeInformationSteps[registrant?.attendee_type].personalInformation
              : RegistrationAttendeeTypeSteps[registrant?.attendee_type].personalInformation),
            component: (
              <PersonalInformation
                id={viewIdModel.personalInformation.id}
                guestOtherTypesEnabled={isGuestOtherTypesEnabled}
                companyName={companyName}
                registrant={registrant}
                onStep={handleStep}
                onChange={handleRegistrantChange}
                checkAttendeeEmailsAvailable={checkAttendeeEmailsAvailable}
                setLoading={setLoading}
                isEditMode={isEditMode}
              />
            ),
          };
        case corporateRegistrationSteps.attendees.id:
          return {
            ...(isEditMode
              ? EditAttendeeInformationSteps[AttendeeType.Corporate].attendees
              : RegistrationAttendeeTypeSteps[AttendeeType.Corporate].attendees),
            component: (
              <CorporateAttendees
                id={viewIdModel.corporateAttendees?.id}
                conference={conference}
                registrant={registrant}
                originalRegistrant={originalRegistrant}
                setFileState={setFileState}
                onStep={handleStep}
                onChange={handleRegistrantChange}
                checkAttendeeEmailsAvailable={checkAttendeeEmailsAvailable}
                isEditMode={isEditMode}
                questionGroup={questionGroup}
              />
            ),
          };
        case corporateRegistrationSteps.availability.id:
        case investorRegistrationSteps.availability.id:
          return {
            ...(isEditMode
              ? EditAttendeeInformationSteps[registrant?.attendee_type].availability
              : RegistrationAttendeeTypeSteps[registrant?.attendee_type].availability),
            component: (
              <RegistrationAvailability
                id={viewIdModel.availability?.id}
                conference={conference}
                timeslots={timeslots}
                registrant={registrant}
                setTimeslots={setTimeslots}
                setRegistrant={setRegistrant}
                onStep={handleStep}
                isEditMode={isEditMode}
                disabled={isInvestorAttendee && isEditMode && !investorAvailabilityChangesOpen}
              />
            ),
          };
        case investorRegistrationSteps.meetingRequests.id:
          return {
            ...(isEditMode
              ? EditAttendeeInformationSteps[AttendeeType.Investor].meetingRequests
              : RegistrationAttendeeTypeSteps[AttendeeType.Investor].meetingRequests),
            component: (
              <MeetingRequests
                id={viewIdModel.meetingRequests?.id}
                conference={conference}
                onStep={handleStep}
                loading={loading}
                meetingRequests={meetingRequests}
                setMeetingRequests={setMeetingRequests}
                registrant={registrant}
                setRegistrant={setRegistrant}
                isEditMode={isEditMode}
                disabled={isEditMode && !investorMeetingRequestChangesOpen}
              />
            ),
          };
        case corporateRegistrationSteps.customQuestions.id:
        case investorRegistrationSteps.customQuestions.id:
        case internalRegistrationSteps.customQuestions.id:
        case guestRegistrationSteps.customQuestions.id:
          const sectionProp = isEditMode
            ? EditAttendeeInformationSteps[registrant?.attendee_type].customQuestions
            : RegistrationAttendeeTypeSteps[registrant?.attendee_type].customQuestions;

          sectionProp.label = !isNullOrWhiteSpace(questionGroup?.title) ? questionGroup.title : sectionProp.label;

          return {
            ...sectionProp,
            component: (
              <CustomQuestions
                id={viewIdModel.customQuestions?.id}
                questionGroup={questionGroup}
                registrant={registrant}
                isEditMode={isEditMode}
                originalRegistrant={originalRegistrant}
                onStep={handleStep}
                onChange={handleRegistrantChange}
              />
            ),
          };
        case corporateRegistrationSteps.summary.id:
        case investorRegistrationSteps.summary.id:
        case internalRegistrationSteps.summary.id:
        case guestRegistrationSteps.summary.id:
          if (isCorporateAttendee) {
            return {
              ...(isEditMode
                ? EditAttendeeInformationSteps[AttendeeType.Corporate].summary
                : RegistrationAttendeeTypeSteps[AttendeeType.Corporate].summary),
              component: (
                <CorporateSummary
                  id={viewIdModel.corporateSummary?.id}
                  conference={conference}
                  registrant={registrant}
                  loading={loading}
                  isEditMode={isEditMode}
                  isFormDirty={isFormDirty}
                  isSlotsEmpty={isSlotsEmpty}
                  questionGroup={questionGroup}
                  setRegistrant={setRegistrant}
                  onStep={handleStep}
                  onSubmit={onSubmit}
                />
              ),
            };
          } else if (isInvestorAttendee) {
            return {
              ...(isEditMode
                ? EditAttendeeInformationSteps[AttendeeType.Investor].summary
                : RegistrationAttendeeTypeSteps[AttendeeType.Investor].summary),
              component: (
                <InvestorSummary
                  id={viewIdModel.investorSummary.id}
                  conference={conference}
                  registrant={registrant}
                  loading={loading}
                  meetingRegistrationOpen={showMeetings}
                  isEditMode={isEditMode}
                  isFormDirty={isFormDirty}
                  isSlotsEmpty={isSlotsEmpty}
                  questionGroup={questionGroup}
                  setRegistrant={setRegistrant}
                  onStep={handleStep}
                  onSubmit={onSubmit}
                />
              ),
            };
          }
          return {
            ...(isEditMode
              ? EditAttendeeInformationSteps[registrant?.attendee_type].summary
              : RegistrationAttendeeTypeSteps[registrant?.attendee_type].summary),
            component: (
              <Summary
                id={viewIdModel.summary.id}
                registrant={registrant}
                loading={loading}
                companyName={companyName}
                onStep={handleStep}
                onSubmit={onSubmit}
                isEditMode={isEditMode}
                isFormDirty={isFormDirty}
                questionGroup={questionGroup}
              />
            ),
          };
      }
    },
    [
      isCorporateAttendee,
      isInvestorAttendee,
      isEditMode,
      registrant,
      viewIdModel,
      isGuestOtherTypesEnabled,
      companyName,
      handleStep,
      handleRegistrantChange,
      checkAttendeeEmailsAvailable,
      setLoading,
      conference,
      originalRegistrant,
      setFileState,
      questionGroup,
      timeslots,
      setRegistrant,
      investorAvailabilityChangesOpen,
      loading,
      meetingRequests,
      investorMeetingRequestChangesOpen,
      onSubmit,
      isFormDirty,
      isSlotsEmpty,
      showMeetings,
    ]
  );

  const { steps } = useMemo(() => {
    if (isEmpty(registrant?.attendee_type)) return { steps: [] };

    const stepsIndex: StepIndexById = {};
    const currentSteps = RegistrationAttendeeTypeSteps[registrant?.attendee_type] || {};
    const steps = Object.values(currentSteps).reduce((acc, step, index) => {
      switch (step.id) {
        case availabilityStepBaseProps.id:
          if (showAvailability && !isSlotsEmpty) {
            stepsIndex[step.id] = index;
            acc.push(getComponentStepById(step.id));
          }
          break;
        case meetingRequestsStepBaseProps.id:
          if (showMeetings && conference?.conference_type !== ConferenceType.PresentationOnly) {
            stepsIndex[step.id] = acc.length;
            acc.push(getComponentStepById(step.id));
          }
          break;
        case customQuestionsStepBaseProps.id:
          if (showCustomQuestions) {
            stepsIndex[step.id] = acc.length;
            acc.push(getComponentStepById(step.id));
          }
          break;
        default:
          stepsIndex[step.id] = acc.length;
          acc.push(getComponentStepById(step.id));
      }

      return acc;
    }, []);

    stepIndexById.current = stepsIndex;
    return { steps, stepsIndex };
  }, [
    registrant?.attendee_type,
    showAvailability,
    isSlotsEmpty,
    showMeetings,
    conference?.conference_type,
    showCustomQuestions,
    getComponentStepById,
  ]);

  useEffect(() => {
    if (isEditMode && sectionsLoading && !questionGroupLoading && steps?.length) {
      setSectionStep(steps.length - 1);
      setSectionsLoading(false);
    } else if (!isEditMode && sectionsLoading && steps?.length) {
      setSectionsLoading(false);
    }
  }, [sectionsLoading, isEditMode, steps, questionGroup, questionGroupLoading]);

  useEffect(() => {
    if (isEditMode && !isInitialRegistrantDataSet.current && !isNil(registrant?.meeting_requests)) {
      isInitialRegistrantDataSet.current = true;
      setMeetingRequests(registrant.meeting_requests);
    }
  }, [isEditMode, registrant?.meeting_requests]);

  return {
    tabSections: sectionsLoading ? [] : steps,
    sectionStep,
  };
};
