import { Color, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { omit } from "lodash";
import moment, { Moment } from "moment";
import { PersonBase } from "../../definitions/entity.definition";
import { convertIsoToMomentUtc } from "../../utils";
import { getFriendlyName } from "../../utils/presentation/presentation.utils";
import { RegistrantViewModel } from "../admin/registrant/registrant.model";
import type { MeetingRequest } from "../admin/registrant/registrant.model";
import { Answer } from "../answer/answer.model";
import type {
  Conference,
  PresentationVendorType,
  GuestAttendeeType,
  ConferenceSchedulerSlot,
} from "../conference/conference.model";
import { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import type { InvestorType } from "../investor/investor.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";

export enum AttendeeType {
  Corporate = "Corporate",
  Investor = "Investor",
  Internal = "Internal",
  Guest = "Guest",
}

export const AttendeeTypeOptions = [
  AttendeeType.Investor,
  AttendeeType.Corporate,
  AttendeeType.Internal,
  AttendeeType.Guest,
];

export const AttendeeTypeDefault = AttendeeType.Internal;

export const AttendeeTypesWithoutMeetings = [AttendeeType.Guest];

export const AttendeeTypesWithOptionalParticipation = [AttendeeType.Corporate];

export const AttendeeTypesWithAvailability = [AttendeeType.Investor, AttendeeType.Corporate];

export const AttendeeTypesWithRepresentatives = [AttendeeType.Investor, AttendeeType.Corporate, AttendeeType.Guest];

export const AttendeeNullableFields = ["guest_attendee_type"];

export class AttendeeOptions {
  allow_chat?: boolean;
  allow_last_name?: boolean;
  allow_notifications?: boolean;
  allow_image?: boolean;
  avatar_color?: string;
}

export interface AttendeePresentationVendor {
  vendor: PresentationVendorType;
  vendor_id: string;
}

export interface AttendeeSpeaker {
  bio?: string;
  image_profile?: string;
  contact?: {
    twitter?: string;
    linkedin?: string;
  };
}

export class AttendeeSecondaryContact implements Omit<PersonBase, "ConferenceId" | "_conference"> {
  first_name?: string;
  last_name?: string;
  title: string;
  phone_number?: string;
  email: string;
  receive_emails: boolean;

  constructor(contact?: AttendeeSecondaryContact) {
    if (isEmpty(contact)) return;

    const { receive_emails, ...rest } = contact;

    this.receive_emails = receive_emails ?? false;

    Object.assign(this, rest);
  }
}

export class AttendeeOptionsViewModel extends AttendeeOptions {
  allow_chat?: boolean;
  allow_last_name?: boolean;
  allow_notifications?: boolean;
  allow_image?: boolean;
  avatar_color?: string;

  readonly _colors = [
    Color.Amber,
    Color.Apple,
    Color.Avocado,
    Color.Cherry,
    Color.Eggplant,
    Color.Ginger,
    Color.Mustard,
    Color.Plum,
    Color.Raspberry,
    Color.Sunshine,
    Color.Tangerine,
    Color.Walnut,
  ];

  readonly _defaults = {
    allow_chat: false,
    allow_last_name: true,
    allow_notifications: true,
    allow_image: true,
    avatar_color: this._getDefaultColor(),
  };

  constructor(options: Partial<AttendeeOptions>) {
    super();
    if (isEmpty(options)) {
      Object.assign(this, this._defaults);
      return;
    }

    this.allow_chat = this._setDefault(this._defaults.allow_chat, options.allow_chat);
    this.allow_last_name = this._setDefault(this._defaults.allow_last_name, options.allow_last_name);
    this.allow_notifications = this._setDefault(this._defaults.allow_notifications, options.allow_notifications);
    this.allow_image = this._setDefault(this._defaults.allow_image, options.allow_image);
    this.avatar_color = this._setDefault(this._defaults.avatar_color, options.avatar_color);
  }

  private _getDefaultColor(): Color {
    const colorIdx = Math.floor(Math.random() * this._colors.length);
    return this._colors[colorIdx];
  }

  private _setDefault<T>(defaultValue: T, value: T): T {
    if (isNil(value)) return defaultValue;
    return value;
  }
}

export abstract class Attendee extends PersonBase {
  id: string;
  _conference: Conference | Conference["_id"];
  _corporate_profile?: CorporateProfile | CorporateProfile["_id"];
  availability?: ConferenceSchedulerSlot[];
  phone_number?: string;
  email: string;
  type: AttendeeType;
  status?: string;
  code?: string[];
  options?: AttendeeOptions;
  is_speaker?: boolean;
  speaker_info?: AttendeeSpeaker;
  participate_in_meetings?: boolean;
  receive_emails?: boolean;
  investor_type?: InvestorType;
  secondary_contact?: AttendeeSecondaryContact;
  meeting_requests?: MeetingRequest[];
  presentation_vendors?: AttendeePresentationVendor[];
  sales_representative?: string;
  guest_attendee_type?: GuestAttendeeType;
  allow_lobby_preview?: boolean;
  is_primary_contact?: boolean;
  editable_email: boolean;
  custom_question_answers?: Answer[];
  form_custom_question_answers?: { [questionId: string]: string | string[] | number | boolean };
  time_zone?: string;
  last_generated_password_at?: Moment;
}

export type AttendeeCompositeKey = Pick<Attendee, "email" | "first_name" | "last_name">;
export class AttendeeComposite implements AttendeeCompositeKey {
  email: Attendee["email"];
  first_name: Attendee["first_name"];
  last_name: Attendee["last_name"];

  constructor(attendee: AttendeeViewModel) {
    this.email = attendee.email;
    this.first_name = attendee.first_name;
    this.last_name = attendee.last_name;

    Object.assign(this);
  }
}

export class AttendeePassword {
  date: Moment;
  password: string;

  constructor(attendeePassword: Partial<AttendeePassword>) {
    const { date, password } = attendeePassword ?? {};

    this.date = convertIsoToMomentUtc(date);
    this.password = password;
  }
}

export class AttendeeViewModel extends Attendee {
  _conference: Conference;
  options: AttendeeOptionsViewModel;

  get display_last_name(): string {
    if (isNullOrWhiteSpace(this.last_name)) return "";

    const allow_last_name = !!this.options?.allow_last_name;
    if (allow_last_name) return this.last_name;
    return this.last_name[0];
  }

  get display_name(): string {
    const first_name = isNullOrWhiteSpace(this.first_name) ? "" : this.first_name;
    return `${first_name} ${this.display_last_name}`;
  }

  get friendly_name(): string {
    return getFriendlyName(this.first_name, this.last_name, this.company_name, this.email);
  }

  get meeting_display_name(): string {
    if (isNullOrWhiteSpace(this.first_name) || isNullOrWhiteSpace(this.last_name)) {
      return "";
    }

    const companyName = isNullOrWhiteSpace(this.company_name) ? "" : `${this.company_name}, `;
    const displayTitle = isNullOrWhiteSpace(this.title) ? "" : `, ${this.title}`;
    const displayName = `${this.first_name} ${this.last_name}`;
    return `${companyName}${displayName}${displayTitle}`;
  }

  get company_name(): string {
    if (this.type === AttendeeType.Corporate) {
      const corpProfile = this._corporate_profile as CorporateProfile;
      return corpProfile?.name;
    }

    return this.company;
  }

  constructor(attendee: Partial<AttendeeViewModel | Attendee> | string) {
    super(attendee);
    if (typeof attendee === "string") return;

    const {
      receive_emails,
      type,
      participate_in_meetings,
      allow_lobby_preview,
      is_primary_contact,
      is_speaker,
      speaker_info,
    } = attendee ?? {};

    Object.assign(this, attendee);

    this.receive_emails = receive_emails ?? true;
    this.type = isEmpty(type) ? AttendeeTypeDefault : type;
    this.participate_in_meetings = participate_in_meetings ?? false;
    this.allow_lobby_preview = allow_lobby_preview ?? false;
    this.options = new AttendeeOptionsViewModel(this.options);
    this.editable_email = attendee?.editable_email ?? true;

    if (type === AttendeeType.Corporate) {
      this.is_primary_contact = is_primary_contact ?? false;
      this.speaker_info = speaker_info;
      this.is_speaker = !isNil(is_speaker) ? is_speaker : !isEmpty(speaker_info);
    }
  }
}

type AttendeePayloadBase = Omit<
  Attendee,
  "_id" | "ConferenceId" | "id" | "editable_email" | "form_custom_question_answers" | "last_generated_password_at"
>;
export class AttendeePayload extends PayloadBase<AttendeePayloadBase> implements AttendeePayloadBase {
  _conference: Attendee["_conference"];
  _corporate_profile?: Attendee["_corporate_profile"];
  availability?: Attendee["availability"];
  email: Attendee["email"];
  options: Attendee["options"];
  type: Attendee["type"];
  participate_in_meetings?: Attendee["participate_in_meetings"];
  receive_emails?: Attendee["receive_emails"];
  company?: Attendee["company"];
  meeting_requests?: MeetingRequest[];
  investor_type?: Attendee["investor_type"];
  is_speaker?: Attendee["is_speaker"];

  constructor(attendeeViewModel: AttendeeViewModel) {
    super();
    const {
      _id,
      ConferenceId,
      friendly_name,
      meeting_display_name,
      display_last_name,
      display_name,
      company_name,
      id,
      options,
      presentation_vendors,
      editable_email,
      form_custom_question_answers,
      last_generated_password_at,
      ...payload
    } = attendeeViewModel;

    const { _colors, _defaults, ...optionPayload } = options || {};

    const sanitizedPayload = this.sanitize(payload);

    const nonNullableFields = Object.keys(sanitizedPayload).filter(
      (el) => !AttendeeNullableFields.includes(el)
    ) as (keyof AttendeePayloadBase)[];

    nonNullableFields.forEach((k: keyof AttendeePayloadBase) => {
      sanitizedPayload[k] === null && delete sanitizedPayload[k];
    });

    Object.assign(this, sanitizedPayload, {
      _conference: ConferenceId,
      options: optionPayload,
    });
  }

  sanitize = (attendee: AttendeePayloadBase): AttendeePayloadBase => {
    if (isEmpty(attendee)) return null;

    const payload = super.sanitize(attendee);

    switch (attendee.type) {
      case AttendeeType.Corporate:
        return this._sanitizeCorporatePayload(payload);
      case AttendeeType.Investor:
        return this._sanitizeInvestorPayload(payload);
      case AttendeeType.Guest:
      case AttendeeType.Internal:
      default:
        return this._sanitizeDefaultPayload(payload);
    }
  };

  private _sanitizeCorporatePayload = (attendee: AttendeePayloadBase): AttendeePayloadBase => {
    if (isEmpty(attendee)) return null;

    const {
      _corporate_profile: profile,
      company,
      investor_type,
      meeting_requests,
      speaker_info: speakerInfo,
      is_speaker,
      ...payload
    } = attendee;
    const _corporate_profile = typeof profile === "string" ? profile : profile?._id ?? undefined;
    payload.sales_representative = payload.sales_representative ?? undefined;
    const speaker_info = !!speakerInfo
      ? {
          ...speakerInfo,
          image_profile: speakerInfo.image_profile ?? "",
        }
      : null;

    return { ...payload, is_speaker, speaker_info, _corporate_profile, type: AttendeeType.Corporate };
  };

  private _sanitizeInvestorPayload = (attendee: AttendeePayloadBase): AttendeePayloadBase => {
    if (isEmpty(attendee)) return null;
    return this._sanitizeNonCorporatePayload({ ...attendee, type: AttendeeType.Investor });
  };

  private _sanitizeDefaultPayload = (attendee: AttendeePayloadBase): AttendeePayloadBase => {
    if (isEmpty(attendee)) return null;

    const { meeting_requests, receive_emails, availability, investor_type, type: attendeeType, ...payload } = attendee;
    return this._sanitizeNonCorporatePayload({ ...payload, type: attendeeType ?? AttendeeTypeDefault });
  };

  private _sanitizeNonCorporatePayload = (attendee: AttendeePayloadBase): AttendeePayloadBase => {
    if (isEmpty(attendee)) return null;

    const { participate_in_meetings, _corporate_profile, is_primary_contact, is_speaker, ...payload } = attendee;
    return payload;
  };
}

export class AttendeePutPayload extends AttendeePayload implements Omit<AttendeePayload, "meeting_requests"> {
  constructor(attendeeViewModel: AttendeeViewModel) {
    const { _id, form_custom_question_answers, ...attendee } = attendeeViewModel;
    super(new AttendeeViewModel(attendee));
    Object.assign(this, { _id });
  }
}

export const attendeeViewModelToRegistrantViewModel = (attendee: AttendeeViewModel): RegistrantViewModel => {
  if (!attendee) return new RegistrantViewModel({});

  attendee.availability = attendee.availability?.map(({ start_time, end_time }) => ({
    start_time: moment(start_time),
    end_time: moment(end_time),
  }));

  const registrantModel = new RegistrantViewModel({
    email: attendee.email,
    title: attendee.title,
    attendee_type: attendee.type,
    last_name: attendee.last_name,
    first_name: attendee.first_name,
    phone_number: attendee.phone_number,
    availability: attendee.availability,
    investor_type: attendee.investor_type,
    meeting_requests: attendee.meeting_requests,
    secondary_contact: attendee.secondary_contact,
    custom_question_answers: attendee.custom_question_answers,
    time_zone: attendee.time_zone,
  });

  if (attendee.type === AttendeeType.Corporate) {
    const corporateProfile = attendee._corporate_profile as CorporateProfile;

    registrantModel.host_small_meetings = corporateProfile.host_small_meetings;
    registrantModel.host_presentation = corporateProfile.host_presentation;
    registrantModel.corporate_name = corporateProfile.name;
    registrantModel.corporate_type = corporateProfile.type;
    registrantModel.industry = corporateProfile.industry;
    registrantModel.corporate_attendees = corporateProfile.attendees.map(
      (a) => new AttendeeViewModel({ ...a, editable_email: false })
    );
    registrantModel.logo_image = corporateProfile.logo_image;
    registrantModel.secondary_logo_image = corporateProfile.secondary_logo_image;
    registrantModel.exchange = corporateProfile.exchange;
    registrantModel.ticker_symbol = corporateProfile.ticker_symbol;
    registrantModel.description = corporateProfile.description;
    registrantModel.url = corporateProfile.url;
    registrantModel.availability = attendee.availability?.map((slot) => {
      return {
        start_time: moment(slot.start_time),
        end_time: moment(slot.end_time),
      };
    });
  } else {
    registrantModel.company = attendee.company;
    registrantModel.sales_representative = attendee.sales_representative;
  }

  return registrantModel;
};

export const updateAttendeeViewModel = (attendee: AttendeeViewModel, update: RegistrantViewModel): AttendeeViewModel => {
  let updatedAttendee = new AttendeeViewModel({
    ...attendee,
    title: update.title,
    company: update.company,
    last_name: update.last_name,
    first_name: update.first_name,
    phone_number: update.phone_number,
    availability: update.availability,
    investor_type: update.investor_type,
    meeting_requests: update.meeting_requests,
    secondary_contact: update.has_secondary_contact ? update.secondary_contact : undefined,
    sales_representative: update.sales_representative,
    custom_question_answers: update.custom_question_answers,
  });

  if (updatedAttendee.type === AttendeeType.Corporate) {
    const corporateProfile = new CorporateProfile((attendee._corporate_profile as CorporateProfile)._id);
    const conferenceId = (attendee._corporate_profile as CorporateProfile)._conference;

    corporateProfile._conference = conferenceId;
    corporateProfile.host_small_meetings = update.host_small_meetings;
    corporateProfile.host_presentation = update.host_presentation;
    corporateProfile.name = update.corporate_name;
    corporateProfile.type = update.corporate_type;
    corporateProfile.industry = update.industry;
    corporateProfile.attendees = (update.corporate_attendees as AttendeeViewModel[])?.map((x) => {
      const { editable_email, ...attendeeData } = x;
      const newAtt = new AttendeeViewModel({
        ...attendeeData,
        _conference: conferenceId,
        _corporate_profile: corporateProfile._id,
      });
      newAtt.availability = update.availability;
      return newAtt;
    });
    corporateProfile.logo_image = update.logo_image;
    corporateProfile.secondary_logo_image = update.secondary_logo_image;
    corporateProfile.exchange = update.exchange;
    corporateProfile.ticker_symbol = update.ticker_symbol;
    corporateProfile.description = update.description;
    corporateProfile.url = update.url;
    corporateProfile.availability = [...update.availability];

    updatedAttendee._corporate_profile = corporateProfile;
  }

  if (attendee.type === AttendeeType.Guest || attendee.type === AttendeeType.Internal) {
    //remove availability and meeting request
    updatedAttendee = omit(updatedAttendee, ["availability", "meeting_requests"]);
  }

  return updatedAttendee;
};
