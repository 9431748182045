import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { useAuth } from "../../hooks";
import { useAdminProfile } from "../../hooks/admin/useAdminProfile/useAdminProfile.hook";
import { AdminProfileRoleAccess } from "../../services/admin/adminProfile/adminProfile.model";
import AdminTokenService from "../../services/adminToken/adminToken.service";
import { AuthType } from "../../services/api/api.definition";
import type { Auth0Token } from "../../services/auth0/auth0.model";
import { AdminUserContextState } from "./adminUser.definition";

export const AdminUserContext = createContext<Partial<AdminUserContextState>>({});

export const AdminUserProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [authType, setAuthType] = useState<AuthType>(AuthType.Protected);

  const { loading: authLoading } = useAuth();

  const loading = useMemo(() => authLoading, [authLoading]);

  const { current } = useAdminProfile({
    autoFetchData: true,
  });

  const role = useMemo(() => current?.Role, [current?.Role]);
  const roleAccess = useMemo(() => current?.Access ?? AdminProfileRoleAccess.Denied, [current?.Access]);

  const isAuthenticated = useCallback(() => {
    const tokenService = new AdminTokenService();
    return tokenService.isAuthenticated();
  }, []);

  const getUser = useCallback((): Auth0Token => {
    if (!isAuthenticated()) return null;
    const tokenService = new AdminTokenService();
    const token = tokenService.getToken();

    return token?.Profile;
  }, [isAuthenticated]);

  const signOut = useCallback(() => {
    const adminTokenService = new AdminTokenService();
    adminTokenService.signOut();
  }, []);

  const adminUserContextState: AdminUserContextState = {
    authType,
    authLoading,
    loading,
    user: getUser(),
    isSystemAdmin: current?.IsSystemAdmin,
    role,
    roleAccess,
    isAuthenticated,
    signOut,
    setAuthType,
  };

  return <AdminUserContext.Provider value={adminUserContextState}>{props.children}</AdminUserContext.Provider>;
};
