import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import { Conference } from "../../../../../../../../services/conference/conference.model";
import { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import { EventSlot, ResourceEvent } from "../flexibleScheduler/flexibleScheduler.definition";

export interface TimetableProps extends BaseComponentProps {
  currentDate: Moment;
  slots: EventSlot[];
  resources: (AttendeeViewModel | CorporateProfile)[];
  conference: Conference;
  meetingEvents: ResourceEvent<Meeting>[][]; // meetings per resource
  presentationEvents: ResourceEvent<Presentation>[];
  breakEvents: ResourceEvent<Presentation>[];
  openModal: () => void;
  closeModal: () => void;
  setCurrentMeeting: Dispatch<SetStateAction<Meeting>>;
}

export enum TimetableClassName {
  Base = "timetable",
  Header = "timetable-header",
  TimeHeader = "timetable-header_time",
  ResourceHeader = "timetable-header_resource",
  Content = "timetable-content",
  TimeColumn = "timetable-content_time",
  ResourceColumn = "timetable-content_resource",
  MainResourceColumn = "timetable-content_resource--main",
  AltResourceColumn = "timetable-content_resource--alt",
  Slot = "timetable-content_slot",
  PseudoSlot = "timetable-content_slot--pseudo",
  BlockedSlot = "timetable-content_slot--blocked",
  Event = "timetable-content_slot-event",
  EventTitle = "timetable-content_slot-event-title",
  PresentationEvent = "timetable-content_slot-event-presentation",
  BreakEvent = "timetable-content_slot-event-break",
  PseudoEvent = "timetable-content_slot-event--pseudo",
  Highlight = "timetable-content_slot-highlight",
}

export class TimetableIdModel extends IdModelBase {
  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
  }
}
