import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { JsonFileRecord } from "../../../../components/jsonForm/jsonForm.definition";
import type { RegistrantViewModel } from "../../../../services/admin/registrant/registrant.model";
import { ApiResponse } from "../../../../services/api/api.definition";
import { Conference } from "../../../../services/conference/conference.model";
import { RegisterCheckEmailAvailableResponse } from "../../../../services/public/register.model";

export interface RegistrationFormProps extends BaseComponentProps {
  registrant?: RegistrantViewModel;
  originalRegistrant?: RegistrantViewModel;
  onStep: (forward: boolean | number | string, formData?: RegistrantViewModel) => void;
  onChange?: (registrant: RegistrantViewModel) => void;
  setFileState?: Dispatch<SetStateAction<JsonFileRecord>>;
  checkAttendeeEmailsAvailable?: (emails: string[]) => Promise<ApiResponse<RegisterCheckEmailAvailableResponse[]>>;
  setLoading?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  isEditMode?: boolean;
  conference?: Conference;
  isFormDirty?: boolean;
}

export enum RegistrationFormTitle {
  firstName = "First Name",
  lastName = "Last Name",
  title = "Position Title",
  company = "Company",
  email = "Email",
  phoneNumber = "Phone",
  salesRepresentative = "Relationship Manager/Sales Representative Name",
  investorType = "Investor Type",
  hasSecondaryContact = "Add an assistant's contact?",
}
