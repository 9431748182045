import { arrayIndexFound, isEmpty, isNil } from "@q4/nimbus-ui";
import type { DirtyPropKey, DirtyServicePropComparer } from "./dirty.model";

export default class DirtyService<TModel, TViewModel extends TModel> {
  private properties: DirtyPropKey<TViewModel>[] = [];
  private denyList: DirtyPropKey<TViewModel>["prop"][] = [];

  constructor(
    private comparer: DirtyServicePropComparer<TViewModel> = {},
    denyList: DirtyPropKey<TViewModel>["prop"][] = []
  ) {
    this.denyList = isEmpty(denyList) ? [] : denyList;
  }

  get = (): DirtyPropKey<TViewModel>[] => {
    return this.properties;
  };

  getDirtyKeys = (): DirtyPropKey<TViewModel>["key"][] => {
    return this.properties.reduce((keys, x) => (keys.includes(x.key) ? keys : keys.concat(x.key)), []);
  };

  getDirtyPropValues = (key: DirtyPropKey<TViewModel>["key"]): DirtyPropKey<TViewModel>["prop"][] => {
    return this.properties.reduce(
      (prevProps, x): DirtyPropKey<TViewModel>["prop"][] => (x.key === key ? prevProps.concat(x.prop) : prevProps),
      []
    );
  };

  getDirtyProps = (key: DirtyPropKey<TViewModel>["key"]): DirtyPropKey<TViewModel>[] => {
    return this.properties.filter((x): boolean => x.key === key);
  };

  isDirty = (): boolean => {
    return !isEmpty(this.properties);
  };

  isItemDirty = (key: DirtyPropKey<TViewModel>["key"]): boolean => {
    return this.properties.some((x): boolean => x.key === key);
  };

  isItemPropDirty = (key: DirtyPropKey<TViewModel>["key"], prop: DirtyPropKey<TViewModel>["prop"]): boolean => {
    return this.properties.some((x): boolean => x.key === key && x.prop === prop);
  };

  setDirtyProp = (
    key: DirtyPropKey<TViewModel>["key"],
    original: TViewModel,
    current: Partial<TViewModel>,
    prop: DirtyPropKey<TViewModel>["prop"]
  ): void => {
    if (isEmpty(original)) return;

    const dirtyIndex = this.properties.findIndex((x): boolean => x.key === key && x.prop === prop);
    const dirtyPropExists = arrayIndexFound(dirtyIndex);

    const comparer = this.comparer[prop];
    const hasComparer = comparer instanceof Function;
    if (!hasComparer) {
      const isCurrentNil = isNil(current[prop]);
      const isCurrentArray = Array.isArray(current[prop]);
      const isCurrentObject = !isCurrentArray && typeof current[prop] === "object";

      if (!isCurrentNil && (isCurrentObject || isCurrentArray)) {
        const objectType = isCurrentObject ? "object" : "array";
        console.warn(
          `Warning: "${prop}" is being ignored because it is an ${objectType}.  Please use a custom comparer or add to the deny list.`
        );
        return;
      }
    }

    const isOriginalEqualToCurrent =
      (hasComparer && comparer(original[prop], current[prop])) ||
      original[prop] === current[prop] ||
      (isEmpty(original[prop]) && isEmpty(current[prop]));

    if (dirtyPropExists && isOriginalEqualToCurrent) {
      this.properties.splice(dirtyIndex, 1);
    } else if (!dirtyPropExists && !isOriginalEqualToCurrent) {
      this.properties = this.properties.concat({ key, prop });
    }
  };

  clear = (): void => {
    this.properties = [];
  };

  clearByKey = (key: DirtyPropKey<TViewModel>["key"]): void => {
    this.properties = this.properties.filter((x): boolean => x.key !== key);
  };

  checkForChanges = (key: DirtyPropKey<TViewModel>["key"], original: TViewModel, changes: Partial<TViewModel>): void => {
    const props = Object.keys(changes) as DirtyPropKey<TViewModel>["prop"][];
    const dirtyProps = props.filter(
      (dirtyProp): boolean => !this.denyList.includes(dirtyProp)
    ) as DirtyPropKey<TViewModel>["prop"][];

    dirtyProps.forEach((property): void => {
      this.setDirtyProp(key, original, changes, property);
    });
  };
}
