import { isEmpty } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import { DefaultTimeZone } from "../../const";
import { ConferenceBaseEntity } from "../../definitions/entity.definition";
import { Conference } from "../conference/conference.model";
import { Presentation } from "../presentation/presentation.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";

export class Track extends ConferenceBaseEntity {
  _conference: Conference;
  _presentation?: Presentation[];
  name: string;
  start_date: Moment;
  end_date: Moment;

  constructor(track: Partial<Track> | string, startDate?: string, endDate?: string) {
    super(track);
    if (typeof track === "string") return;

    const { start_date, end_date, updated_at, created_at, _conference, ...rest } = track || {};
    Object.assign(this, rest, { _conference });

    const timeZone = _conference?.time_zone ?? DefaultTimeZone;

    this.start_date =
      !isEmpty(startDate) || typeof start_date === "string" ? moment.utc(startDate || start_date).tz(timeZone) : start_date;
    this.end_date =
      !isEmpty(endDate) || typeof end_date === "string" ? moment.utc(endDate || end_date).tz(timeZone) : end_date;
  }
}

export interface TrackPostRequest extends Omit<Track, "_conference" | "ConferenceId"> {
  _conference: string;
}

type TrackPayloadBase = Omit<Track, "_presentation" | "ConferenceId">;
export class TrackPayload extends PayloadBase<TrackPayloadBase> implements TrackPayloadBase {
  _conference: Track["_conference"];
  end_date: Track["end_date"];
  name: Track["name"];
  start_date: Track["start_date"];

  constructor(track: Track) {
    super();
    const { _presentation, ConferenceId, ...payload } = track;
    const sanitizedPayload = this.sanitize(payload);

    Object.assign(this, sanitizedPayload, { _conference: ConferenceId });
  }
}
