import { IdModelBase, BannerIdModel, isNullOrWhiteSpace, FormIdModel } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../components/entityTable/entityTable.definition";
import type { ConferenceContextState } from "../../../contexts/conference/conference.context";

export interface ConferencesFilter {
  search?: string;
  page?: number;
  pageSize?: number;
}

export interface ConferencesProps {
  conference?: ConferenceContextState;
}

export enum CompanyClassName {
  Base = "company",
  Table = "company_table",
  IconCell = "conferences_icon-cell",
}

class PageIdModel extends IdModelBase {
  banner: BannerIdModel;
  form: FormIdModel;
  table: EntityTableIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.banner = new BannerIdModel(`${this.id}Banner`);
    this.form = new FormIdModel(`${this.id}Form`);
    this.table = new EntityTableIdModel(`${this.id}Table`);
  }
}

const PageId = "Company";
export const CompanyIdModel = new PageIdModel(PageId);
