import { ButtonProps, FileUploaderProps, FileUploaderTheme } from "@q4/nimbus-ui";
import type { DropzoneOptions } from "react-dropzone";

export enum FilePreviewerMessage {
  Upload = "Drag and drop to upload",
  Replace = "Drag and drop to replace file",
}

export class FilePreviewerProps {
  id?: string;
  dataId?: string;
  className?: string;
  fileUrl: string;
  onChange: (fileUrl: string | File) => void;
  disabled? = false;
  loading?: boolean;
  required? = false;
  theme? = FileUploaderTheme.LightGrey;
  showActions? = false;
  leftActions?: Omit<ButtonProps, "theme">[];
  rightActions?: Omit<ButtonProps, "theme">[];
  dropzoneProps?: Omit<DropzoneOptions, "disabled" | "onDrop">;
  renderValidationText?: boolean;

  constructor(props: FileUploaderProps) {
    Object.assign(this, props);
  }
}
