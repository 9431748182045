import {
  BaseComponentProps,
  CheckboxIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextareaIdModel,
  TextboxIdModel,
  FileUploaderIdModel,
  InfoIconIdModel,
} from "@q4/nimbus-ui";
import { ConferenceEmail } from "../../../../../../services/conference/conference.model";
import { ConferenceEditState } from "../../conferenceEdit.definition";

export interface EmailFormProps extends BaseComponentProps {
  conference: ConferenceEditState;
  onChange: (emailConfig: ConferenceEmail) => void;
}

export class EmailFormIdModel extends IdModelBase {
  registrationCompleteEnabled: CheckboxIdModel;
  message: TextareaIdModel;
  emailLogo: FileUploaderIdModel;
  emailHeaderColor: TextboxIdModel;
  emailNameAlias: TextboxIdModel;
  emailAddressAlias: TextboxIdModel;
  signature: TextareaIdModel;
  registrationApprovalEnabled: CheckboxIdModel;
  registrationApprovalMessage: TextareaIdModel;
  registrationApprovalTooltip: InfoIconIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.registrationCompleteEnabled = new CheckboxIdModel(`${this.id}RegistrationCompleteEnabledCheckbox`);
    this.message = new TextareaIdModel(`${this.id}EmailMessageTextarea`);
    this.emailLogo = new FileUploaderIdModel(`${this.id}EmailLogo`);
    this.emailHeaderColor = new TextboxIdModel(`${this.id}EmailHeaderColor`);
    this.emailNameAlias = new TextboxIdModel(`${this.id}EmailNameAlias`);
    this.emailAddressAlias = new TextboxIdModel(`${this.id}EmailAddressAlias`);
    this.signature = new TextareaIdModel(`${this.id}EmailSignatureTextarea`);
    this.registrationApprovalEnabled = new CheckboxIdModel(`${this.id}RegistrationApprovalEnabledCheckbox`);
    this.registrationApprovalMessage = new TextareaIdModel(`${this.id}RegistrationApprovalMessageTextarea`);
    this.registrationApprovalTooltip = new InfoIconIdModel(`${this.id}RegistrationApprovalTooltip`);
  }
}

export enum EmailFormClassName {
  Base = "email-configuration-form",
  RegistrationCompleteEnabled = "email-configuration-form_registration_complete_enabled",
  Message = "auto-register-form_message",
}
