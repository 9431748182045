import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, ModalIdModel } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { DeleteConfirmationMessageIdModel } from "../../../../../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.definition";
import { MeetingExistsMessageIdModel } from "../../../../../../../../components/meetingExistsMessage/meetingExistsMessage.definition";
import { MeetingFormIdModel } from "../../../../../../../../components/meetingForm/meetingForm.definition";
import type { ServiceHookModel } from "../../../../../../../../hooks/useService/useService.definition";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../../../../../services/conference/conference.model";
import type { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import type MeetingService from "../../../../../../../../services/meeting/meeting.service";
import type { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import { TimetableIdModel } from "../timetable/timetable.definition";

export interface FlexibleSchedulerProps extends BaseComponentProps {
  conference: Conference;
  presentations: Presentation[];
  codes: string[];
  companies: string[];
  useMeetings: ServiceHookModel<Meeting, MeetingService>;
  eventDay: EventDay;
  mainResource: CorporateProfile;
  resources: AttendeeViewModel[];
  formCorporateProfiles: CorporateProfile[];
  formAttendees: AttendeeViewModel[];
  handleRefreshMeetingsAndAttendees: () => Promise<void>;
  modalVisible: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
  modalFullscreen: boolean;
  setModalFullscreen: (isFullscreen: boolean) => void;
}

export enum FlexibleSchedulerClassName {
  Base = "flexible-scheduler",
  Modal = "flexible-scheduler_modal",
  ModalDeleteButton = "flexible-scheduler_modal-delete-button",
}

export class FlexibleSchedulerIdModel extends IdModelBase {
  timetable: TimetableIdModel;
  modal: ModalIdModel;
  modalForm: MeetingFormIdModel;
  modalDelete: ButtonIdModel;
  modalCancel: ButtonIdModel;
  modalSave: ButtonIdModel;
  meetingMessage: MeetingExistsMessageIdModel;
  deleteConfirmationMessageIdModel: DeleteConfirmationMessageIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.timetable = new TimetableIdModel(`${this.id}Timetable`);
    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.modalForm = new MeetingFormIdModel(`${this.id}ModalForm`);
    this.modalDelete = new ButtonIdModel(`${this.id}ModalDeleteButton`);
    this.modalCancel = new ButtonIdModel(`${this.id}ModalCancelButton`);
    this.modalSave = new ButtonIdModel(`${this.id}ModalSaveButton`);
    this.meetingMessage = new MeetingExistsMessageIdModel(`${this.id}MeetingExistsMessage`);
    this.deleteConfirmationMessageIdModel = new DeleteConfirmationMessageIdModel(`${this.id}DeleteMessage`);
  }
}

export enum EventType {
  Presentation,
  Meeting,
  Break,
}

export interface ResourceEvent<T = Record<string, unknown>> {
  id: string;
  title: string;
  start_time: Moment;
  end_time: Moment;
  type: EventType;
  data?: Meeting | Presentation | T;
}

export interface EventSlot<T = Record<string, unknown>> {
  resourceId?: string;
  start_time: Moment;
  end_time: Moment;
  events?: ResourceEvent<T>[];
  label?: string;
  blocked?: boolean;
  pseudo?: boolean;
}

export interface EventDay<T = Record<string, unknown>> {
  title?: string;
  date: Moment;
  slots: EventSlot<T>[];
}
