import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, ToggleButtonsIdModel } from "@q4/nimbus-ui";
import type { Entity } from "../../../../definitions/entity.definition";

export enum ImportSettingsClearType {
  None,
  All,
  Future,
}

export enum ImportSettingsImportType {
  All,
  Future,
}

export enum ImportSettingsUpdateType {
  All,
  Update,
}

export interface ImportSettingsProps extends BaseComponentProps {
  type: Entity;
  onClearTypeChange: (index: number, event: React.MouseEvent | React.KeyboardEvent) => void;
  selectedClearType: ImportSettingsClearType;
  onImportTypeChange: (index: number, event: React.MouseEvent | React.KeyboardEvent) => void;
  selectedImportType: ImportSettingsImportType;
  onImportUpdateChange: (index: number, event: React.MouseEvent | React.KeyboardEvent) => void;
  selectedImportUpdate: ImportSettingsUpdateType;
}

export class ImportSettingsIdModel extends IdModelBase {
  type: ToggleButtonsIdModel;
  clear: ToggleButtonsIdModel;
  update: ToggleButtonsIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.type = new ToggleButtonsIdModel(`${this.id}ImportTypeToggle`);
    this.clear = new ToggleButtonsIdModel(`${this.id}ImportClearToggle`);
    this.update = new ToggleButtonsIdModel(`${this.id}ImportUpdateToggle`);
  }
}
