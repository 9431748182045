import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { CalendarRecurrence, ICSAlarm, ICSPropertyValue } from "datebook";
import { AttendeeViewModel } from "../attendee/attendee.model";

export enum CalendarExportType {
  Pdf = "PDF",
  Ics = "ICS",
  Yahoo = "Yahoo",
  Google = "Google",
  Outlook = "Outlook",
}

export interface CalendarSession {
  title: string;
  description: string;
  start: Date;
  end: Date;
  location?: string;
  uid: ICSPropertyValue;
  alarm?: ICSAlarm[];
  recurrence?: CalendarRecurrence;
  attendees?: AttendeeViewModel[];
  props?: { key: string; value: string }[];
  meta?: { key: string; value: string }[];
}

export class CalendarExport {
  type: CalendarExportType;
  options: CalendarSession[];
  name: string;
  filename?: string;
  download?: boolean;

  constructor(calendar: CalendarExport) {
    const { type, filename, ...rest } = calendar;

    this.type = !isEmpty(type) ? type : CalendarExportType.Ics;
    this.download = CalendarExportType.Ics === type && !isNullOrWhiteSpace(filename) ? true : false;
    this.filename = filename;

    Object.assign(this, rest);
  }
}
