import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import Pusher from "pusher-js";
import { useEffect, useMemo, useRef } from "react";
import config from "../../config/config";
import AdminTokenService from "../../services/adminToken/adminToken.service";
import { ContentType } from "../../services/api/api.definition";
import { PusherEvent, PusherSubscribeFailedResponse } from "./usePusher.definition";
import type { UsePusherProps, PusherHookModel } from "./usePusher.definition";

export const usePusher = (props: UsePusherProps): PusherHookModel => {
  const { channelName, channelEvents, data, email, disabled, name, onChannelDestroy } = props;

  const tokenService = useRef(new AdminTokenService());
  const authToken = useMemo(() => tokenService.current.getAuthToken(), []);

  const pusher = useMemo(
    () =>
      disabled ||
      isNullOrWhiteSpace(data) ||
      isNullOrWhiteSpace(email) ||
      isNullOrWhiteSpace(channelName) ||
      isNullOrWhiteSpace(config?.pusher?.apiKey) ||
      isNullOrWhiteSpace(config?.api?.url) ||
      isNullOrWhiteSpace(authToken)
        ? null
        : new Pusher(config.pusher.apiKey, {
            cluster: "us2", // a cluster are only required for a sandbox account
            authEndpoint: `${config.api.url}/pusher/auth`,
            auth: {
              headers: {
                "Authorization": `Bearer ${authToken}`,
                "content-type": ContentType.Json,
              },
              params: {
                user_id: email,
                name,
                email,
                data,
              },
            },
          }),
    [authToken, channelName, disabled, data, email, name]
  );

  const channel = useMemo(() => {
    return isEmpty(pusher) || disabled ? null : pusher.subscribe(channelName);
  }, [channelName, disabled, pusher]);

  useEffect(() => {
    if (disabled || isEmpty(channel) || isEmpty(channelEvents)) return;

    const currentChannel = channel;
    channelEvents.forEach((event) => {
      currentChannel.bind(event.name, event.action);
    });
    currentChannel.bind(PusherEvent.SubscriptionError, (data: PusherSubscribeFailedResponse) => {
      console.log("Failed to subscribe to push notifications...", data);
    });

    return (): void => {
      currentChannel.unsubscribe();
      onChannelDestroy && onChannelDestroy();
    };
  }, [channel, channelEvents, disabled, onChannelDestroy]);

  return {
    channel,
  };
};
