import { isEmpty } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import { EntityBase } from "../../definitions/entity.definition";
import { ApiMethod } from "../../services/api/api.definition";
import { ServiceBase } from "../../services/serviceBase/serviceBase.model";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import { useOfflineService } from "../useOfflineService/useOfflineSerivce.hook";
import { UseServiceLogic } from "./logic/useService.logic";
import type { ServiceHookModel, ServiceHookProps } from "./useService.definition";

export const useService = <TEntity extends EntityBase, TService extends ServiceBase<TEntity>>(
  props: ServiceHookProps<TEntity, TService>
): ServiceHookModel<TEntity, TService> => {
  const {
    autoFetchData,
    assignDefaultEntity,
    autoFetchParam,
    data,
    entityName,
    offlineDataKey: offlineDataKeyProp,
    showNotifications,
    useOffline,
    entityModel,
    serviceModel,
    validateOffline,
  } = props;

  const logic = useRef(new UseServiceLogic(entityName, showNotifications, assignDefaultEntity));

  const [entity, setEntity] = useState<TEntity>(null);
  const [entities, setEntities] = useState<TEntity[]>([]);
  const [loading, setLoading] = useState(autoFetchData);

  const offlineDataKey = useMemo(() => (useOffline ? offlineDataKeyProp : null), [offlineDataKeyProp, useOffline]);
  const service = useMemo(() => (isEmpty(serviceModel) ? null : new serviceModel()), [serviceModel]);

  const fetch = useCallback((): Promise<TEntity[]> => {
    if (isEmpty(service)) return Promise.resolve(null);

    setLoading(true);

    return service
      .get(useOffline)
      .then((response) => {
        logic.current.handleResponseNotification({
          success: response?.success,
          entityName: logic.current.entitiesName,
          message: response?.message,
          method: ApiMethod.Get,
        });

        return logic.current.handleArrayedResponse(response?.data, { setEntity, setEntities });
      })
      .finally(() => setLoading(false));
  }, [service, useOffline]);

  const fetchById = useCallback(
    (_id: TEntity["_id"]): Promise<TEntity> => {
      if (isEmpty(service)) return Promise.resolve(null);

      setLoading(true);

      return service
        .getById(_id, useOffline)
        .then((response) => {
          logic.current.handleResponseNotification({
            success: response?.success,
            message: response?.message,
            method: ApiMethod.Get,
          });

          return logic.current.handleNewResponse({ assignEntities: false, entity: response?.data });
        })
        .finally(() => setLoading(false));
    },
    [service, useOffline]
  );

  const post = useCallback(
    (payload: TEntity, assignEntities = true): Promise<TEntity> => {
      if (isEmpty(service)) return Promise.resolve(null);

      setLoading(true);

      return service
        .post(payload)
        .then((response) => {
          logic.current.handleResponseNotification({
            success: response?.success,
            message: response?.message,
            method: ApiMethod.Post,
          });

          return logic.current.handleNewResponse({
            assignEntities,
            entity: response?.data,
            entityModel,
            setEntity,
            setEntities,
          });
        })
        .finally(() => setLoading(false));
    },
    [service, entityModel]
  );

  const putById = useCallback(
    (id: TEntity["_id"], payload: TEntity, assignEntities = true): Promise<TEntity> => {
      if (isEmpty(service)) return Promise.resolve(null);

      setLoading(true);

      return service
        .put(id, payload)
        .then((response) => {
          logic.current.handleResponseNotification({
            success: response?.success,
            message: response?.message,
            method: ApiMethod.Put,
          });

          return logic.current.handleUpdateResponse({
            assignEntities,
            entity: response?.data,
            entityModel,
            setEntity,
            setEntities,
          });
        })
        .finally(() => setLoading(false));
    },
    [service, entityModel]
  );

  const deleteById = useCallback(
    (id: TEntity["_id"]): Promise<boolean> => {
      if (isEmpty(service)) return Promise.resolve(null);

      setLoading(true);

      return service
        .delete(id)
        .then((response) => {
          const success = !!response?.success;
          logic.current.handleResponseNotification({
            success,
            method: ApiMethod.Delete,
          });
          if (!success) return false;

          return logic.current.handleDeleteResponse(id, success, { setEntity, setEntities });
        })
        .finally(() => setLoading(false));
    },
    [service]
  );

  useAutoFetch({
    autoFetchData,
    param: autoFetchParam,
    data,
    fetch,
    setEntities,
  });

  const { entities: items } = useOfflineService({
    disabled: !isEmpty(data),
    entities,
    offlineDataKey,
    getOfflineData: service?.getOfflineData,
    setEntity,
    validateOffline,
  });

  return {
    loading,
    current: entity,
    items,
    service,
    fetch,
    fetchById,
    post,
    putById,
    deleteById,
    setCurrent: setEntity,
    setItems: setEntities,
    setLoading,
  };
};
