import {
  AutomationListId,
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  MessageIdModel,
  PlaceholderContentIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";
import { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import {
  Conference,
  ConferenceScheduler,
  ConferenceSchedulerSlot,
} from "../../../../../../../../services/conference/conference.model";
import { DayTabsIdModel } from "../../../meetingScheduler/components/dayTabs/dayTabs.definition";
import { TimeSlotViewIdModel } from "../timeSlotView/timeSlotView.definition";

export interface ScheduledTimeSlotProps extends BaseComponentProps {
  conference: Conference;
  scheduler: ConferenceScheduler;
  editMode: boolean;
  saving: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  onSubmit: (schedule: ConferenceScheduler) => Promise<void>;
  setCurrentCalendarDay: Dispatch<SetStateAction<Moment>>;
  currentCalendarDay: Moment;
  calendarDays: Array<Moment>;
}

export enum ScheduledTimeSlotClassName {
  Base = "scheduled-time-slot-view",
  Header = "scheduled-time-slot-view_header",
  TimezoneHeader = "scheduled-time-slot-view_timezone-header",
  SlotsWrapper = "scheduled-time-slot-view_slots-wrapper",
  SlotsInnerWrapper = "scheduled-time-slot-view_slots-inner-wrapper",
  Footer = "scheduled-time-slot-view_footer",
}

export class ScheduledTimeSlotIdModel extends IdModelBase {
  content: string;
  currentDate: TextIdModel;
  currentTimezone: TextIdModel;
  lastUpdated: TextIdModel;

  placeholderContent: PlaceholderContentIdModel;
  dayTabs: DayTabsIdModel;
  messageModal: MessageIdModel;
  deleteButton: ButtonIdModel;
  cancelButton: ButtonIdModel;

  slotsContainer: string;
  slots: TimeSlotViewListItemListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.content = `${this.id}ScheduledTimeSlot`;
    this.currentDate = new TextIdModel(`${this.id}ScheduledTimeSlotCurrentDate`);
    this.currentTimezone = new TextIdModel(`${this.id}ScheduledTimeSlotCurrentTimezone`);
    this.lastUpdated = new TextIdModel(`${this.id}ScheduledTimeSlotLastUpdated`);

    this.placeholderContent = new PlaceholderContentIdModel(`${this.id}ScheduledTimeSlotPlaceholderContent`);
    this.dayTabs = new DayTabsIdModel(`${this.id}ScheduledTimeSlotDaysTabs`);
    this.messageModal = new MessageIdModel(`${this.id}MessageModal`);
    this.deleteButton = new ButtonIdModel(`${this.id}DeleteButton`);
    this.cancelButton = new ButtonIdModel(`${this.id}CancelButton`);
    this.slotsContainer = `${this.id}SlotsContainer`;
    this.slots = new TimeSlotViewListItemListId(id);
  }
}

export class TimeSlotViewListItemListId extends AutomationListId<TimeSlotViewIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, TimeSlotViewIdModel, postfix);
  }
}

export interface TimeSlotDataForDeletion {
  currentDaySlots: ConferenceSchedulerSlot[];
  timeSlotIndex: number;
  startTime: string;
  endTime: string;
}
