import { BaseComponentWithChildrenProps, CardProps, GridColumnProps, GridProps } from "@q4/nimbus-ui";

export interface GridCardProps extends Pick<BaseComponentWithChildrenProps, "children"> {
  cardProps?: CardProps;
  gridProps?: GridProps;
  gridColumnProps?: GridColumnProps;
}

export enum GridCardClassName {
  Base = "nui-grid-card",
  Content = "nui-grid-card_content",
}
