import { BaseComponentProps, CheckboxIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface SecuritySettingsEditProps extends BaseComponentProps {
  meetingAuthenticated: boolean;
  onUpdate: (value: Partial<ConferenceEditState>) => void;
}

export class SecuritySettingsEditIdModel extends IdModelBase {
  meetingAuthenticated: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.meetingAuthenticated = new CheckboxIdModel(`${this.id}MeetingAuthenticated`);
  }
}
