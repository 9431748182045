import { ButtonIdModel, CardIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";
import { TimeZoneSelectorIdModel } from "../../../components/timeZoneSelector/timeZoneSelector.definition";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";
import { TermsAndConditionsIdModel } from "../../termsAndConditions/termsAndConditions.definition";
import { AnswerSummaryIdModel } from "../answerSummary/answerSummary.definition";

export interface CorporateSummaryProps extends RegistrationFormProps {
  loading: boolean;
  isEditMode?: boolean;
  conference: Conference;
  questionGroup?: Partial<QuestionGroup>;
  isSlotsEmpty: boolean;
  setRegistrant: Dispatch<SetStateAction<RegistrantViewModel>>;
  onSubmit: () => void;
}

export class CorporateSummaryIdModel extends IdModelBase {
  termsAndConditions: TermsAndConditionsIdModel;
  stepBack: ButtonIdModel;
  confirm: ButtonIdModel;
  companyInformationSection: CardIdModel;
  attendeesSection: CardIdModel;
  detailsSection: CardIdModel;
  availabilitySection: CardIdModel;
  answerSummary: AnswerSummaryIdModel;
  companyInformationEdit: ButtonIdModel;
  timeZoneSelector: TimeZoneSelectorIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.termsAndConditions = new TermsAndConditionsIdModel(`${this.id}TermsAndConditions`);
    this.stepBack = new ButtonIdModel(`${this.id}StepBackButton`);
    this.confirm = new ButtonIdModel(`${this.id}ConfirmButton`);
    this.companyInformationSection = new CardIdModel(`${this.id}CompanyInformation`);
    this.attendeesSection = new CardIdModel(`${this.id}Attendees`);
    this.detailsSection = new CardIdModel(`${this.id}Details`);
    this.availabilitySection = new CardIdModel(`${this.id}Availability`);
    this.answerSummary = new AnswerSummaryIdModel(`${this.id}AnswerSummary`);
    this.companyInformationEdit = new ButtonIdModel(`${this.id}CompanyInformationEdit`);
    this.timeZoneSelector = new TimeZoneSelectorIdModel(`${this.id}TimeZoneSelector`);
  }
}
