import "./corporateAttendees.component.scss";
import { isEmpty, isNil } from "@q4/nimbus-ui";
import { FormProps, IChangeEvent, ISubmitEvent, UiSchema } from "@rjsf/core";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import { useCustomQuestionsForm } from "../../../../../hooks/useCustomQuestionsForm/useCustomQuestionsForm.hook";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import { ConferenceType } from "../../../../../services/conference/conference.model";
import { ErrorHandlerField } from "../../../../../services/errorHandler/errorHandler.definition";
import { trimFormData, validateEmail, validateText } from "../../../../../utils";
import { isCorporateDeadlinePassed, isCorporateRegisterDeadlinePassed } from "../../../../../utils/deadline/deadline.utils";
import { emailDuplicateLocalError, emailInvalidError, emailUnavailableError } from "../../registration.definition";
import { validateMeetingParticipation } from "../../registration.utils";
import { CustomQuestionsFormData } from "../customQuestions/customQuestions.definition";
import {
  convertFormAnswersToModel,
  convertFormAnswersToModelForEditMode,
  convertModelAnswersToForm,
} from "../customQuestions/customQuestions.utils";
import {
  corporateAttendeeErrorMessages,
  corporateAttendeeMessages,
  CorporateAttendeesClassName,
  CorporateAttendeesIdModel,
  CorporateAttendeesProps,
} from "./corporateAttendees.definition";

const CorporateAttendees = (props: CorporateAttendeesProps): JSX.Element => {
  const {
    id,
    registrant,
    originalRegistrant,
    setFileState,
    onChange,
    onStep,
    checkAttendeeEmailsAvailable,
    isEditMode,
    conference,
    questionGroup,
  } = props;
  const isPresentationOnly: boolean = registrant._conference?.conference_type === ConferenceType.PresentationOnly;
  const individualCorporateQuestions = useMemo(() => {
    return questionGroup?._questions?.filter((question) => question.is_individual_corporate) || [];
  }, [questionGroup]);

  const refFormData = useRef<RegistrantViewModel>(
    new RegistrantViewModel({
      ...registrant,
      corporate_attendees: registrant?.corporate_attendees?.map(
        (attendee) =>
          new AttendeeViewModel({
            ...attendee,
            form_custom_question_answers: convertModelAnswersToForm(
              attendee.custom_question_answers,
              individualCorporateQuestions
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any,
          })
      ),
    })
  );
  const [hasPrimaryContact, setHasPrimaryContact] = useState(false);
  const [globalError, setGlobalError] = useState("");

  const idModel = useMemo(() => new CorporateAttendeesIdModel(id), [id]);

  const isCorporateRegistrationDeadlinePassed = useMemo(() => isCorporateRegisterDeadlinePassed(conference), [conference]);
  const isSpeakerDeadlinePassed = useMemo(() => isCorporateDeadlinePassed("speaker", conference), [conference]);
  const isMeetingDeadlinePassed = useMemo(() => isCorporateDeadlinePassed("meeting", conference), [conference]);

  const [extraErrors, setExtraErrors] = useState({});

  const shouldExcludeMeeting: boolean = isPresentationOnly || isMeetingDeadlinePassed;

  const {
    schema: customQuestionSchema,
    uiSchema: customQuestionUiSchema,
    errorHandler: customQuestionErrorHandlers,
  } = useCustomQuestionsForm({
    registrant,
    questions: individualCorporateQuestions,
    useDefaultIds: false,
  });

  type JSONSchemaDefinition = { [key: string]: boolean | number | string | Record<string, unknown> };
  const schema: FormProps<RegistrantViewModel>["schema"] = useMemo(() => {
    const speakerSchema =
      !isSpeakerDeadlinePassed || isEditMode
        ? {
            is_speaker: {
              type: "boolean",
              default: false,
              title: corporateAttendeeMessages.attendee.isSpeaker,
              readOnly: isSpeakerDeadlinePassed,
            },
          }
        : {};

    const participationSchema = shouldExcludeMeeting
      ? {}
      : {
          participate_in_meetings: {
            type: "boolean",
            default: true,
            title: corporateAttendeeMessages.attendee.isParticipant,
          },
        };

    const corporateAttendeesDefaultProps: JSONSchemaDefinition = {
      first_name: { type: "string", title: corporateAttendeeMessages.attendee.firstName, default: "" },
      last_name: { type: "string", title: corporateAttendeeMessages.attendee.lastName, default: "" },
      title: { type: "string", title: corporateAttendeeMessages.attendee.title, default: "" },
      phone_number: { type: "string", title: corporateAttendeeMessages.attendee.phoneNumber, default: "" },
      email: {
        type: "string",
        title: corporateAttendeeMessages.attendee.email,
        default: "",
        readOnly: isCorporateRegistrationDeadlinePassed,
      },
      editable_email: { type: "boolean", default: true },
      is_primary_contact: {
        type: "boolean",
        default: false,
        title: corporateAttendeeMessages.attendee.isPrimary,
        readOnly: isEditMode && hasPrimaryContact,
      },
    };

    const customQuestions = !!customQuestionSchema
      ? {
          form_custom_question_answers: {
            type: "object",
            properties: {
              ...customQuestionSchema.properties,
            },
          },
        }
      : {};

    const corporate_attendees: JSONSchemaDefinition = {
      type: "array",
      title: " ",
      minItems: 1,
      items: {
        type: "object",
        dependencies: {
          is_speaker: {
            oneOf: [
              {
                properties: {
                  is_speaker: { enum: [true] },
                  speaker_info: {
                    type: "object",
                    title: "Speaker Info",
                    properties: {
                      bio: {
                        type: "string",
                        title: "Speaker Bio",
                        default: "",
                        readOnly: isSpeakerDeadlinePassed,
                        maxLength: 5000,
                      },
                      image_profile: {
                        type: "string",
                        title: "Upload Profile",
                        readOnly: isSpeakerDeadlinePassed,
                      },
                    },
                  },
                },
              },
              {
                properties: {
                  is_speaker: { enum: [false] },
                },
              },
            ],
          },
          editable_email: {
            oneOf: [
              {
                properties: {
                  editable_email: { enum: [true] },
                  email: { readOnly: false },
                },
              },
              {
                properties: {
                  editable_email: { enum: [false] },
                  email: { readOnly: true },
                },
              },
            ],
          },
          is_primary_contact: {
            oneOf: [
              {
                properties: {
                  is_primary_contact: { enum: [true] },
                },
              },
              {
                properties: {
                  is_primary_contact: { enum: [false] },
                  receive_emails: {
                    type: "boolean",
                    default: false,
                    title: corporateAttendeeMessages.attendee.receiveEmails,
                  },
                },
              },
            ],
          },
        },
        properties: {
          ...corporateAttendeesDefaultProps,
          ...speakerSchema,
          ...participationSchema,
          ...customQuestions,
        },
      },
    };

    return {
      type: "object",
      properties: { corporate_attendees },
    };
  }, [
    shouldExcludeMeeting,
    isSpeakerDeadlinePassed,
    isEditMode,
    isCorporateRegistrationDeadlinePassed,
    hasPrimaryContact,
    customQuestionSchema,
  ]);

  const uiSchema: UiSchema = useMemo(() => {
    const customQuestions = !!customQuestionUiSchema
      ? {
          form_custom_question_answers: {
            "useParentIdPrefix": true,
            "ui:options": {
              className: "json-form_custom-questions",
            },
            ...customQuestionUiSchema,
          },
        }
      : {};

    return {
      "corporate_attendees": {
        "ui:options": {
          orderable: false,
          addable: !isCorporateRegistrationDeadlinePassed,
        },
        "items": {
          "ui:order": ["*", "is_speaker", "speaker_info", "participate_in_meetings", "form_custom_question_answers"],
          "receive_emails": {
            "ui:widget": "nuiRadio",
          },
          "is_primary_contact": {
            "ui:widget": "nuiRadio",
          },
          "participate_in_meetings": {
            "ui:widget": "nuiRadio",
          },
          "is_speaker": {
            "ui:widget": "nuiRadio",
          },
          "speaker_info": {
            "bio": {
              "ui:widget": "nuiTextarea",
            },
            "image_profile": {
              "ui:widget": "nuiImagePreviewValidated",
            },
            "useParentIdPrefix": true,
            "ui:field": "layout",
            "ui:layout": [
              {
                fields: {
                  image_profile: { width: "1-of-3" },
                  bio: { width: "2-of-3" },
                },
              },
            ],
          },
          "editable_email": {
            "ui:widget": "hidden",
          },
          ...customQuestions,
        },
      },
      "ui:field": "layout",
      "ui:layout": [
        {
          fields: {
            corporate_attendees: { width: "1-of-1" },
          },
        },
      ],
    };
  }, [isCorporateRegistrationDeadlinePassed, customQuestionUiSchema]);

  const onFormChange = useCallback(
    (changeEvent: IChangeEvent<RegistrantViewModel>) => {
      const { formData: updatedFormData } = changeEvent;
      refFormData.current = { ...updatedFormData } as RegistrantViewModel;

      const primaryContactIndex = refFormData.current.corporate_attendees.findIndex(
        (attendee) => attendee.is_primary_contact
      );

      if (primaryContactIndex > -1 && !refFormData.current.corporate_attendees[primaryContactIndex].receive_emails) {
        refFormData.current.corporate_attendees[primaryContactIndex].receive_emails = true;
        onChange(new RegistrantViewModel({ ...refFormData.current }));
      }

      setHasPrimaryContact(primaryContactIndex > -1);
      return true;
    },
    [refFormData, onChange]
  );

  const onValidateHostMeetings = useCallback(() => {
    return shouldExcludeMeeting || validateMeetingParticipation(refFormData.current);
  }, [refFormData, shouldExcludeMeeting]);

  const errorHandlerFields = useMemo(() => {
    return [
      {
        parent: ["corporate_attendees"],
        type: "array",
        fields: [
          new ErrorHandlerField("first_name", corporateAttendeeErrorMessages.attendee.firstName, validateText),
          new ErrorHandlerField("last_name", corporateAttendeeErrorMessages.attendee.lastname, validateText),
          new ErrorHandlerField("title", corporateAttendeeErrorMessages.attendee.title, validateText),
          new ErrorHandlerField("email", corporateAttendeeErrorMessages.attendee.email, validateEmail),
        ],
      },
      {
        parent: ["corporate_attendees", "speaker_info"],
        dependency: ["is_speaker"],
        fields: [new ErrorHandlerField("bio", corporateAttendeeErrorMessages.speaker, validateText)],
      },
      {
        parent: ["corporate_attendees", "form_custom_question_answers"],
        fields: [...(!!customQuestionErrorHandlers?.fields ? customQuestionErrorHandlers.fields : [])],
      },
    ];
  }, [customQuestionErrorHandlers?.fields]);

  const convertCorporateAttendeeFormAnswers = useCallback(
    (corporateAttendees: AttendeeViewModel[]) => {
      if (isEmpty(individualCorporateQuestions)) return;

      corporateAttendees?.forEach((attendee) => {
        const targetAttendee = originalRegistrant?.corporate_attendees?.find((att) => att._id === attendee._id);
        const answers = isEditMode
          ? convertFormAnswersToModelForEditMode(
              individualCorporateQuestions,
              targetAttendee?.custom_question_answers,
              attendee.form_custom_question_answers as unknown as CustomQuestionsFormData
            )
          : convertFormAnswersToModel(
              individualCorporateQuestions,
              registrant,
              attendee.form_custom_question_answers as unknown as CustomQuestionsFormData
            );

        attendee.custom_question_answers = answers;
      });
    },
    [individualCorporateQuestions, isEditMode, originalRegistrant, registrant]
  );

  async function handleSubmit(response: ISubmitEvent<RegistrantViewModel>): Promise<void> {
    if (isEmpty(response?.formData) || isEmpty(onChange)) return;

    // Mutates corporate_attendees
    convertCorporateAttendeeFormAnswers(response?.formData?.corporate_attendees);

    const sanitizedRegistrant = new RegistrantViewModel({
      ...registrant,
      ...trimFormData(response?.formData),
    });

    onChange(new RegistrantViewModel({ ...sanitizedRegistrant }));

    const primaryContacts = (sanitizedRegistrant?.corporate_attendees || []).filter(
      (attendee) => attendee.is_primary_contact
    );

    if (!primaryContacts.length) {
      setGlobalError(corporateAttendeeErrorMessages.primaryContact.none);
      return;
    } else if (primaryContacts.length > 1) {
      setGlobalError(corporateAttendeeErrorMessages.primaryContact.many);
      return;
    }

    if (!onValidateHostMeetings()) {
      setGlobalError(corporateAttendeeErrorMessages.corporate.hostMeetings);
      return;
    }

    const emails = sanitizedRegistrant.corporate_attendees.map((attendee) => attendee.email);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {
      corporate_attendees: {},
    };

    // #region Corporate Attendee Email Validations
    // Check locally first that provided emails are valid
    emails.forEach((email, index) => {
      const validEmail = validateEmail(email);
      if (!validEmail) {
        if (isNil(errors.corporate_attendees[index])) {
          errors.corporate_attendees[index] = {
            email: {
              __errors: [emailInvalidError],
            },
          };
        } else {
          errors.corporate_attendees[index].email = {
            __errors: [emailInvalidError],
          };
        }
      }
    });

    if (!isEmpty(errors.corporate_attendees)) {
      setExtraErrors(errors);
      return;
    }

    // Check locally first that provided emails are unique
    const duplicateCorporateAttendeeEmails = [...new Set(emails.filter((item, index) => emails.indexOf(item) !== index))];

    if (duplicateCorporateAttendeeEmails.length > 0) {
      emails.forEach((email, index) => {
        if (duplicateCorporateAttendeeEmails.includes(email)) {
          errors.corporate_attendees[index] = {
            email: {
              __errors: [emailDuplicateLocalError],
            },
          };
        }
      });

      setExtraErrors(errors);
      return;
    }

    const result = checkAttendeeEmailsAvailable && (await checkAttendeeEmailsAvailable(emails));
    const unavailableEmails = result?.data?.filter((item) => !item.available);

    if (unavailableEmails?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {
        corporate_attendees: {},
      };

      unavailableEmails.forEach(
        (unavailableEmail) =>
          (errors.corporate_attendees[emails.findIndex((email) => email === unavailableEmail.email)] = {
            email: {
              __errors: [emailUnavailableError],
            },
          })
      );

      setExtraErrors(errors);

      return;
    }
    // #endregion

    setExtraErrors({});

    onStep(true);
  }

  const onNavigation = useCallback(
    (forward: boolean | number | string, formData: RegistrantViewModel) => {
      const { corporate_attendees } = formData;
      convertCorporateAttendeeFormAnswers(corporate_attendees);

      onChange(
        new RegistrantViewModel({
          ...registrant,
          corporate_attendees,
        })
      );
      onStep(forward);
    },
    [onChange, onStep, registrant, convertCorporateAttendeeFormAnswers]
  );

  return (
    <div id={idModel.id} className={CorporateAttendeesClassName.Base}>
      <JsonForm
        id={idModel.jsonForm?.id}
        errorHandlerFields={errorHandlerFields}
        schema={schema}
        uiSchema={uiSchema}
        data={refFormData.current}
        setFiles={setFileState}
        onStep={onNavigation}
        onSubmit={handleSubmit}
        globalError={globalError}
        onChange={onFormChange}
        extraErrors={extraErrors}
      />
    </div>
  );
};

export default memo(CorporateAttendees);
