import "./registration.view.scss";
import { isEmpty, isNullOrWhiteSpace, NotificationService, Swapable, Text, TextPreset } from "@q4/nimbus-ui";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { load } from "recaptcha-v3";
import BackgroundPlaceholder from "../../../assets/itinerary/background-placeholder.png";
import CompanyBranding from "../../../components/companyBranding/companyBranding.component";
import { JsonFileRecord } from "../../../components/jsonForm/jsonForm.definition";
import config from "../../../config/config";
import { usePublicCompany } from "../../../hooks/public/usePublicCompany/usePublicCompany.hook";
import { usePublicQuestionGroups } from "../../../hooks/public/usePublicQuestionGroups/usePublicQuestionGroups";
import { Registrant, RegistrantViewModel } from "../../../services/admin/registrant/registrant.model";
import { ApiResponse } from "../../../services/api/api.definition";
import { AttendeeType } from "../../../services/attendee/attendee.model";
import AuthService from "../../../services/auth/auth.service";
import { RegisterCheckEmailAvailableResponse } from "../../../services/public/register.model";
import RegisterService from "../../../services/public/register.service";
import {
  formatTimetableDataWithTimezone,
  getBackgroundImageUrl,
  getNonConflictingSlots,
  getProfileUrl,
  modifyAnswerData,
  validateEmail,
} from "../../../utils";
import LoadingState from "../components/loadingState/loadingState.component";
import Confirmation from "./components/confirmation/confirmation.component";
import { RegistrationHeader } from "./components/registrationHeader/registrationHeader.component";
import TypeInformation from "./components/registrationType/registrationType.component";
import { useSections } from "./hooks/useSections.hook";
import {
  AutoRegistrationAttendeeTypes,
  RegistrationDefaults,
  RegistrationIdModel as IdModel,
  RegistrationStep,
} from "./registration.definition";
import {
  isAttendeeRegistrationAllowed,
  isInvestorAvailabilityChangesOpen,
  isCorporateAvailabilityOpen,
  isMeetingRegistrationOpen,
  isRegistrationClosed,
  uploadRegistrantFiles,
} from "./registration.utils";
import RegistrationSections from "./sections/sections.component";

const Registration = (): JSX.Element => {
  const {
    company,
    companyLoading,
    conference,
    conferenceBackgroundImage,
    conferenceLogoImage,
    hasCompany,
    hasConference,
    companyName,
    companyUrlSuffix,
  } = usePublicCompany();

  const backgroundImage = useMemo(() => {
    return !isNullOrWhiteSpace(conferenceBackgroundImage) ? conferenceBackgroundImage : BackgroundPlaceholder;
  }, [conferenceBackgroundImage]);

  const [fileState, setFileState] = useState<JsonFileRecord>(null);

  const [step, setStep] = useState(RegistrationStep.Type);

  const [registrant, setRegistrant] = useState<RegistrantViewModel>(new RegistrantViewModel({}));

  const [loading, setLoading] = useState(false);

  const registrationExists = useRef(false);

  const notificationService = useMemo(() => new NotificationService(), []);

  const { current: questionGroup } = usePublicQuestionGroups({
    conferenceId: conference?._id,
    attendeeType: registrant.attendee_type,
  });

  const pageTitle = useMemo(() => {
    const title = conference?.title;
    if (isNullOrWhiteSpace(title)) return "Conference Registration";
    return `${title} Registration`;
  }, [conference?.title]);

  const constructAllowedAttendeeTypes = useMemo(() => {
    let currentAttendeeTypes = RegistrationDefaults.AllTypes;
    RegistrationDefaults.AllTypes.forEach((attendeeType) => {
      if (!isAttendeeRegistrationAllowed(conference, attendeeType, true)) {
        currentAttendeeTypes = currentAttendeeTypes.filter((el) => el !== attendeeType);
      }
    });

    return currentAttendeeTypes;
  }, [conference]);

  const meetingRegistrationOpen = useMemo(
    () =>
      registrant?.attendee_type === AttendeeType.Investor &&
      isMeetingRegistrationOpen(conference?.investor_deadlines?.meeting_request),
    [registrant?.attendee_type, conference?.investor_deadlines?.meeting_request]
  );

  const investorAvailabilityChangesOpen = useMemo(
    () => registrant?.attendee_type === AttendeeType.Investor && isInvestorAvailabilityChangesOpen(conference),
    [registrant?.attendee_type, conference]
  );

  const corporateAvailabilityOpen = useMemo(
    () => registrant?.attendee_type === AttendeeType.Corporate && isCorporateAvailabilityOpen(conference),
    [registrant?.attendee_type, conference]
  );

  const coordinator = useMemo(() => conference?.coordinator, [conference?.coordinator]);

  const conferencePath = useMemo(() => conference?.Path, [conference?.Path]);

  const isEmailEnabled = useMemo(
    () => conference?.email_configuration?.registration_complete_enabled,
    [conference?.email_configuration?.registration_complete_enabled]
  );

  const authService = useMemo(() => new AuthService(), []);

  const profileUrl = useMemo(() => getProfileUrl(company, conference, true), [company, conference]);

  const validateEmailRegistration = useCallback(
    (email: string, attendeeType: AttendeeType, autoApprove: boolean): Promise<boolean> => {
      if (
        !autoApprove ||
        !AutoRegistrationAttendeeTypes.includes(attendeeType) ||
        isNullOrWhiteSpace(companyUrlSuffix) ||
        isNullOrWhiteSpace(conference?._id) ||
        !validateEmail(email)
      ) {
        return Promise.resolve(true);
      }

      return authService.validateAttendeeByConferenceId(companyUrlSuffix, email, conference._id).then((response) => {
        if (!response?.success) return true;

        const { status } = response?.data || {};
        if (isNullOrWhiteSpace(status)) return true;

        setRegistrant((registrant) => new RegistrantViewModel({ ...registrant, status }));
        setStep(RegistrationStep.Confirmation);
        registrationExists.current = true;

        return false;
      });
    },
    [authService, conference, companyUrlSuffix]
  );

  const checkAttendeeEmailsAvailable = useCallback(
    (emails: string[]): Promise<ApiResponse<RegisterCheckEmailAvailableResponse[]>> => {
      return new RegisterService().validateEmails(conference?._id, emails);
    },
    [conference]
  );

  const handleRegistrantChange = useCallback(
    (updated: RegistrantViewModel): void => {
      setRegistrant(
        (current) =>
          new RegistrantViewModel({
            ...current,
            ...updated,
            _conference: conference,
          })
      );
    },
    [conference]
  );

  const handlePost = useCallback(() => {
    setLoading(true);
    validateEmailRegistration(registrant.email, registrant.attendee_type, conference?.auto_approve)
      .then((proceed) => {
        if (!proceed) {
          throw new Error("User has already registered.");
        }

        if (isNullOrWhiteSpace(config?.recaptcha?.siteKey)) {
          throw new Error("Recaptcha site key was not found.");
        }
        return load(config.recaptcha.siteKey, {
          useRecaptchaNet: true,
          autoHideBadge: true,
        });
      })
      .then((recaptcha) => recaptcha.execute("conference/register"))
      .then(async (token) => {
        if (isNullOrWhiteSpace(token)) {
          throw new Error("Invalid Recaptcha token provided.");
        }
        const isCorporate = registrant?.attendee_type === AttendeeType.Corporate;
        const isInvestor = registrant?.attendee_type === AttendeeType.Investor;

        if (isInvestor && (!meetingRegistrationOpen || !investorAvailabilityChangesOpen)) {
          registrant.availability = getNonConflictingSlots(conference?.scheduler?.slots);
        }

        // TODO: Do something with the response. i.e. handle upload errors
        // TODO: Add recaptcha token per file upload
        await uploadRegistrantFiles(registrant, fileState);

        const updatedAnswerObject = modifyAnswerData(registrant?.custom_question_answers, questionGroup?._questions);
        const corporateAttendees = registrant?.corporate_attendees;

        if (isCorporate) {
          corporateAttendees?.forEach((cA, index) => {
            corporateAttendees[index].custom_question_answers = modifyAnswerData(
              cA.custom_question_answers,
              questionGroup?._questions
            );
          });
        }

        if (isCorporate || isInvestor) {
          registrant.availability = formatTimetableDataWithTimezone(registrant?.availability, conference?.time_zone);
        }

        const postData = new Registrant({
          ...registrant,
          custom_question_answers: updatedAnswerObject,
          corporate_attendees: corporateAttendees,
        });

        return new RegisterService().post(postData, token);
      })
      .then((response) => {
        if (!response?.success) {
          const errorMessage = isNullOrWhiteSpace(response?.message) ? "." : `: ${response.message}`;
          console.error(`Failed to register for the conference${errorMessage}`);
          return;
        }
        handleTypeStep(RegistrationStep.Confirmation);
      })
      .catch((err) => {
        const message = err?.message || "Failed to register for the conference.";
        console.error(message);
        notificationService.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    validateEmailRegistration,
    registrant,
    conference?.auto_approve,
    conference?.time_zone,
    conference?.scheduler?.slots,
    meetingRegistrationOpen,
    investorAvailabilityChangesOpen,
    fileState,
    questionGroup?._questions,
    notificationService,
  ]);

  const isAutoApprove = useMemo(() => !!conference?.auto_approve, [conference?.auto_approve]);
  const isGuestOtherTypesEnabled = useMemo(
    () => conference?.guest_attendee_types?.length > 0,
    [conference?.guest_attendee_types?.length]
  );

  const displayLoginLink = useMemo(() => !!conference?.display_login_link, [conference?.display_login_link]);

  useEffect(() => {
    if (isEmpty(conference)) return;

    if (isRegistrationClosed(conference)) {
      setStep(RegistrationStep.Closed);
    }

    if (constructAllowedAttendeeTypes.length === 1) {
      setStep(RegistrationStep.Sections);
    }
  }, [conference, constructAllowedAttendeeTypes]);

  const { tabSections, sectionStep } = useSections({
    viewIdModel: IdModel,
    showAvailability: corporateAvailabilityOpen || investorAvailabilityChangesOpen,
    showMeetings: meetingRegistrationOpen,
    isGuestOtherTypesEnabled,
    companyName,
    conference,
    registrant,
    loading,
    questionGroup,
    setFileState,
    setRegistrant,
    handleRegistrantChange,
    setLoading,
    checkAttendeeEmailsAvailable,
    onSubmit: handlePost,
  });

  function handleTypeStep(forward: boolean | number | string): void {
    if (typeof forward === "number") {
      setStep(forward);
      return;
    }

    setStep((currentStep) => Math.max(forward ? currentStep + 1 : currentStep - 1, 0));
  }

  const swapableLayers = useMemo(() => {
    const layers = [
      <div key="registration_type" className={"registration_layer"}>
        <TypeInformation
          id={IdModel.registrationType.id}
          guestOtherTypesEnabled={isGuestOtherTypesEnabled}
          attendeeTypes={constructAllowedAttendeeTypes}
          registrant={registrant}
          onStep={handleTypeStep}
          onChange={handleRegistrantChange}
        />
      </div>,
      <div key="registration_sections">
        <RegistrationSections sections={tabSections} setRegistrationStep={setStep} tabStep={sectionStep} />
      </div>,
      <div key="registration_confirmation" className={"registration_layer"}>
        <Confirmation
          id={IdModel.confirmation.id}
          companyUrlSuffix={companyUrlSuffix}
          conferencePath={conferencePath}
          isEmailEnabled={isEmailEnabled}
          isAutoApprove={isAutoApprove}
          coordinator={coordinator}
          registrant={registrant}
          registrationExists={registrationExists.current}
          displayLoginLink={displayLoginLink}
          profileUrl={profileUrl}
        />
      </div>,
      <div key="registration_closed" className={"registration_layer"}>
        <Text className={"registration_closed-text"} preset={TextPreset.Paragraph}>
          Registration is currently closed for this event
        </Text>
      </div>,
    ];

    if (constructAllowedAttendeeTypes.length === 1 && registrant?.attendee_type !== constructAllowedAttendeeTypes[0]) {
      handleRegistrantChange(new RegistrantViewModel({ ...registrant, attendee_type: constructAllowedAttendeeTypes[0] }));
    }
    return layers;
  }, [
    companyUrlSuffix,
    conferencePath,
    constructAllowedAttendeeTypes,
    coordinator,
    displayLoginLink,
    handleRegistrantChange,
    isAutoApprove,
    isEmailEnabled,
    isGuestOtherTypesEnabled,
    registrant,
    sectionStep,
    tabSections,
    profileUrl,
  ]);

  return (
    <div id={IdModel.id}>
      <CompanyBranding
        branding={conference?.branding}
        haloProps={{ favicon: conference?.image_favicon, title: pageTitle }}
      />
      <LoadingState loading={companyLoading} error={!hasCompany || !hasConference}>
        <div className="registration">
          <aside className={"registration_splash"} style={{ backgroundImage: getBackgroundImageUrl(backgroundImage) }}>
            <div className={"registration_splash-gradient"} />
            <div className={"registration_splash-gradient_overlay"}></div>
          </aside>
          <div className="registration_content">
            <div className="registration_form">
              <div className="registration_scrollbar">
                <RegistrationHeader
                  id={IdModel.registrationHeader.id}
                  conference={conference}
                  logo={conferenceLogoImage}
                  details="Conference Registration"
                />
                <Swapable selected={step} layers={swapableLayers} />
              </div>
            </div>
          </div>
          <div className="registration_footer">
            <Text preset={TextPreset.Title}>{conference?.title}</Text>
            <Text preset={TextPreset.Paragraph}>
              Powered by <i className="q4i-logo" />
            </Text>
          </div>
        </div>
      </LoadingState>
    </div>
  );
};

export default memo(Registration);
