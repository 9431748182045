import { useCallback, useEffect, useState } from "react";
import { PaginatedDataHookModel, PaginatedDataHookProps } from "./usePaginatedData.definition";

export const usePaginatedData = <T>(props: PaginatedDataHookProps<T>): PaginatedDataHookModel<T> => {
  const { data, initialPaginationState } = props;
  const [paginationState, setPaginationState] = useState(initialPaginationState);
  const [pageData, setPageData] = useState<T[]>();

  const handlePageChange = useCallback(
    (page: number) => {
      setPaginationState((current) => ({ ...current, page }));
    },
    [setPaginationState]
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      const newPageCount = Math.max(Math.ceil(data.length / pageSize), 1);
      setPaginationState((current) => ({
        page: Math.min(current.page, newPageCount - 1),
        pageSize,
        pageCount: newPageCount,
      }));
    },
    [data, setPaginationState]
  );

  useEffect(() => {
    const { page, pageCount, pageSize } = paginationState;
    const properPageCount = Math.max(Math.ceil(data.length / pageSize), 1);

    // Check if updated data has left us with an incorrect number of pages (ex: when searching/filtering data)
    if (pageCount !== properPageCount) {
      setPaginationState({
        pageSize,
        pageCount: properPageCount,
        page: Math.min(page, properPageCount - 1),
      });
      return;
    }

    const startIndex = Math.min(page * pageSize, data.length);
    const currentPageData = data.slice(startIndex, startIndex + pageSize);
    setPageData(currentPageData);
  }, [data, paginationState, setPageData, handlePageChange]);

  return {
    pageData,
    paginationState,
    handlePageChange,
    handlePageSizeChange,
  };
};
