import { arrayIndexFound, isEmpty, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { useAutoFetch, useService } from "..";
import { Entity } from "../../definitions/entity.definition";
import { Conference } from "../../services/conference/conference.model";
import { Presentation, PresentationExport } from "../../services/presentation/presentation.model";
import PresentationService from "../../services/presentation/presentation.service";
import { isObjectPlural, sortPresentationsByDate } from "../../utils";
import { PresentationsHookModel, PresentationsHookProps } from "./usePresentations.definition";

export const usePresentations = (props: PresentationsHookProps): PresentationsHookModel => {
  const { autoFetchData, conferenceId, data, showNotifications, useOffline } = props;
  const {
    loading,
    current,
    items,
    service: presentationService,
    fetch,
    setCurrent,
    setItems,
    setLoading,
  } = useService({
    autoFetchData: false,
    data,
    entityName: Entity.Presentation,
    showNotifications,
    useOffline,
    entityModel: Presentation,
    serviceModel: PresentationService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const createPresentation = useCallback(
    (data: Presentation): Promise<Presentation> => {
      setLoading(true);

      return presentationService
        .createPresentation(data)
        .then((response): Presentation => {
          const isPlural = isObjectPlural(response?.data);

          if (!response.success || isEmpty(response.data)) {
            showNotifications && notificationService.error(`Failed to create session${isPlural ? "s" : ""}`);
            return null;
          }

          showNotifications && notificationService.success(`Session${isPlural ? "s were" : " was"} created successfully.`);

          if (!Array.isArray(response?.data)) {
            setItems(sortPresentationsByDate([...items, response.data]));
            return response.data;
          }

          setItems(sortPresentationsByDate([...items, ...response.data]));
          return response.data[0];
        })
        .finally(() => setLoading(false));
    },
    [notificationService, presentationService, items, setItems, setLoading, showNotifications]
  );

  const updatePresentation = useCallback(
    (id: Presentation["_id"], payload: Presentation, assignEntities = true): Promise<Presentation> => {
      if (isEmpty(presentationService)) return Promise.resolve(null);

      setLoading(true);

      return presentationService
        .put(id, payload)
        .then((response) => {
          if (!response?.success || isEmpty(response?.data)) {
            showNotifications && notificationService.error("Failed to update session");
            return null;
          }

          showNotifications && notificationService.success("Session was updated successfully.");

          if (!assignEntities) return response?.data;

          const updatedPresentation = new Presentation(response.data);

          setCurrent(updatedPresentation);
          setItems((original) =>
            original.map((x) => (x._id === updatedPresentation._id ? new Presentation({ ...x, ...updatedPresentation }) : x))
          );

          return updatedPresentation;
        })
        .finally(() => setLoading(false));
    },
    [presentationService, setLoading, showNotifications, notificationService, setCurrent, setItems]
  );

  const deletePresentation = useCallback(
    (id: Presentation["_id"]): Promise<boolean> => {
      if (isEmpty(presentationService)) return Promise.resolve(null);

      setLoading(true);

      return presentationService
        .delete(id)
        .then((response) => {
          if (!response?.success || isEmpty(response?.data)) {
            showNotifications && notificationService.error("Failed to delete session");
            return null;
          }

          showNotifications && notificationService.success("Session was deleted successfully.");

          setCurrent((original) => (original?._id === id ? null : original));
          setItems((original) => {
            if (isEmpty(original)) return original;

            const updated = [...original];
            const index = updated.findIndex((x) => x._id === id);
            if (!arrayIndexFound(index)) return updated;
            updated.splice(index, 1);
            return updated;
          });

          return response?.success;
        })
        .finally(() => setLoading(false));
    },
    [presentationService, setLoading, showNotifications, notificationService, setCurrent, setItems]
  );

  const getByEmail = useCallback(
    (email: string): Promise<Presentation[]> => {
      setLoading(true);
      return presentationService.getByEmail(email).then((response): Presentation[] => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error("Failed to load sessions.");
          return null;
        }

        const presentations = sortPresentationsByDate(response.data ?? []);

        setItems(presentations);
        return presentations;
      });
    },
    [notificationService, presentationService, showNotifications, setItems, setLoading]
  );

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Presentation[]> => {
      setLoading(true);
      return presentationService.getByConferenceId(_id).then((response): Presentation[] => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error("Failed to load sessions.");
          return null;
        }

        const presentations = sortPresentationsByDate(response.data ?? []);

        setItems(presentations);
        return presentations;
      });
    },
    [notificationService, presentationService, showNotifications, setItems, setLoading]
  );

  const getById = useCallback(
    (_id: Presentation["_id"]): Promise<Presentation> => {
      setLoading(true);

      return presentationService.getById(_id).then((response) => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error(response.message);
          return null;
        }

        const { data: presentation } = response;
        setItems((presentations) =>
          presentations.map((x) => (x._id === presentation._id ? new Presentation({ ...x, ...presentation }) : x))
        );
        setCurrent(presentation);

        return presentation;
      });
    },
    [notificationService, presentationService, showNotifications, setCurrent, setItems, setLoading]
  );

  const exportToPdf = useCallback(
    async (data: PresentationExport, fileName: string): Promise<boolean> => {
      setLoading(true);
      return presentationService
        .exportToPdf(data, fileName)
        .then((response): boolean => {
          if (!response?.success) {
            throw new Error("Failed to download your itinerary.");
          }

          showNotifications && notificationService.success("Your itinerary has been exported.");

          return !!response?.success;
        })
        .catch((error): boolean => {
          showNotifications && notificationService.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [notificationService, presentationService, showNotifications, setLoading]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetch,
    fetchBy: getByConferenceId,
    setEntities: setItems,
  });

  return {
    current,
    items,
    loading,
    service: presentationService,
    fetch,
    fetchById: getById,
    fetchByEmail: getByEmail,
    fetchByConferenceId: getByConferenceId,
    post: createPresentation,
    putById: updatePresentation,
    deleteById: deletePresentation,
    exportToPdf,
    setCurrent,
    setItems,
    setLoading,
  };
};
