import { AnchorIdModel, IdModelBase, isNullOrWhiteSpace, ModalIdModel } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";

export interface FaqModalProps extends BaseComponentWithChildrenProps {
  visible?: boolean;
  supportEmail: string;
  clientEmail: string;
  conferenceName: string;
  companyName: string;
}

export enum FaqModalClassNames {
  Base = "faq-modal",
  Link = "faq-modal_link",
  Modal = "faq-modal_modal",
}

export class FaqModalIdModel extends IdModelBase {
  anchor: AnchorIdModel;
  modal: ModalIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.anchor = new AnchorIdModel(`${this.id}Anchor`);
    this.modal = new ModalIdModel(`${this.id}Modal`);
  }
}
