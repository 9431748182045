import { Color, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import { BrandingBase } from "../../components/companyBranding/companyBranding.definition";
import { DialInInstructionsLabel } from "../../const";
import { EntityBase } from "../../definitions/entity.definition";
import { convertIsoToMomentUtc, sanitizeHtml } from "../../utils";
import { getLobbyConfig, getLobbyOpenConfig } from "../../utils/conference/conference.utils";
import type { OnDemand } from "../admin/onDemand/onDemand.model";
import type { AttendeeType } from "../attendee/attendee.model";
import { Company } from "../company/company.model";
import type { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import type { Meeting } from "../meeting/meeting.model";
import { Presentation } from "../presentation/presentation.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";
import { Speaker } from "../speaker/speaker.model";
import { Track } from "../track/track.model";

export interface Coordinator {
  full_name?: string;
  title?: string;
  phone?: string;
  email?: string;
}

export interface ReplyTo {
  name?: string;
  email?: string;
}

export interface ConferenceLink {
  label: string;
  url: string;
  order: number;
}

export enum ConferenceStatus {
  Scheduled = "Scheduled",
  Running = "Running",
  Ended = "Ended",
  None = "None",
}

export enum PresentationVendorType {
  Custom = "custom",
  Streamlined = "streamlined",
}

export const PresentationVendorTypeDefault = PresentationVendorType.Custom;

export interface LobbyConfigSection {
  title: string;
  label?: string;
  disabled: boolean;
}

export interface LobbyOpenConfigSection {
  open_date?: Moment;
}

export interface LobbyConfig {
  agenda?: LobbyConfigSection;
  dialInInstructions?: LobbyConfigSection;
  ondemand_presentations?: LobbyConfigSection;
  speakers?: LobbyConfigSection;
  tracks?: LobbyConfigSection;
  livePresentation?: LobbyConfigSection;
  corporate_lobby?: LobbyOpenConfigSection;
  investor_lobby?: LobbyOpenConfigSection;
  general_lobby?: LobbyOpenConfigSection;
}
export interface LoginConfig {
  background?: string;
  message?: string;
  error_message?: string;
  primary_color?: string;
}

export type DefaultLobbyConfig = Omit<LobbyConfig, "corporate_lobby" | "investor_lobby" | "general_lobby">;

export type LobbyOpenConfig = Omit<
  LobbyConfig,
  "agenda" | "dialInInstructions" | "ondemand_presentations" | "speakers" | "tracks" | "livePresentation"
>;

export const LobbyDefaults = {
  agenda: {
    disabled: false,
    label: "You have no agenda items added",
    title: "Agenda",
  },
  dialInInstructions: {
    disabled: false,
    label: DialInInstructionsLabel,
    title: "How to Join Your Meeting",
  },
  ondemand_presentations: {
    disabled: false,
    label: "Watch",
    title: "On Demand",
  },
  speakers: {
    disabled: false,
    label: "Read Bio",
    title: "Speakers",
  },
  tracks: {
    disabled: false,
    label: "Personal Agenda",
    title: "Tracks",
  },
  livePresentation: {
    disabled: true,
    label: "Join the live presentation",
    title: "Join live presentation",
  },
};

export enum VendorName {
  Zoom = "zoom",
  MicrosoftTeams = "msteams",
  Webex = "webex",
}

export enum ConferenceType {
  FullConference = "Presentations and Meetings",
  PresentationOnly = "Presentation Only",
}

export interface VideoVendor {
  name: VendorName;
  options?: Record<string, unknown>;
}

export interface PresentationVendor {
  name: PresentationVendorType;
  options?: {
    [key: string]: string;
    hub_id?: string;
  };
}

export interface VendorStatusResponse {
  access: boolean;
}

export interface AutoRegister {
  enabled: boolean;
  start_date: Moment;
  end_date: Moment;
}

export interface RegisterDateRange {
  start_date: Moment;
  end_date: Moment;
}

export interface CorporateRegistrationDeadlines {
  speaker?: Moment;
  presentation?: Moment;
  meeting?: Moment;
}

export interface InvestorRegistrationDeadlines {
  meeting_request?: Moment;
  availability_changes?: Moment;
}

export interface ConferenceEmail {
  registration_complete_enabled: boolean;
  message: string;
  logo: string;
  color_header: string;
  email_name_alias: string;
  email_address_alias: string;
  signature: string;
  registration_approval_enabled: boolean;
  registration_approval_message: string;
}

export interface ConferenceRegistration {
  attendee_types: AttendeeType[];
}

export type ConferenceSchedulerSlot = {
  start_time: Moment;
  end_time: Moment;
};

export interface ConferenceScheduler {
  start_date: Moment;
  end_date: Moment;
  start_time: number;
  end_time: number;
  duration: number;
  break: number;
  slots: ConferenceSchedulerSlot[];
  updated_by?: string;
  updated_at?: Moment;
}

export enum GuestAttendeeType {
  Government = "Government",
  Media = "Media",
  Other = "Other",
}
export class Conference extends EntityBase {
  preview?: boolean;
  title: string;
  description?: string;
  registration_description?: string;
  info?: string;
  path?: string;
  start_date: Moment;
  end_date: Moment;
  open_date: Moment;
  close_date: Moment;
  date_label?: string;
  time_zone: string;
  image_logo?: string;
  image_logo_secondary?: string;
  image_background?: string;
  image_favicon?: string;
  image_live?: string;
  branding?: BrandingBase;
  coordinator?: Coordinator;
  disclaimer?: string;
  _company?: Company;
  _speaker?: Speaker[];
  _track?: Track[];
  _presentation?: Presentation[];
  _meeting?: Meeting[];
  _ondemand_presentation?: OnDemand[];
  created_at?: Moment;
  updated_at?: Moment;
  video_vendors?: VideoVendor[];
  socialMedia?: { icon: string; url: string }[];
  reply_to?: ReplyTo;
  corporate_profiles?: CorporateProfile[];
  lobby: LobbyConfig;
  navigation_links?: ConferenceLink[];
  presentation_vendors?: PresentationVendor[];
  registration: ConferenceRegistration;
  auto_register?: AutoRegister;
  corporate_register?: RegisterDateRange;
  investor_register?: RegisterDateRange;
  general_register?: RegisterDateRange;
  internal_register?: RegisterDateRange;
  email_configuration?: ConferenceEmail;
  auto_approve?: boolean;
  conference_type: ConferenceType;
  guest_attendee_types?: GuestAttendeeType[];
  requireAuthenticatedMeetingLink: boolean;
  display_login_link?: boolean;
  corporate_deadlines?: CorporateRegistrationDeadlines;
  investor_deadlines?: InvestorRegistrationDeadlines;
  login?: LoginConfig;
  scheduler?: ConferenceScheduler;

  get Status(): ConferenceStatus {
    if (isNil(this.open_date) || isNil(this.close_date)) return ConferenceStatus.None;

    const now = moment();
    if (now.isAfter(this.close_date)) return ConferenceStatus.Ended;
    if (now.isBetween(moment(this.open_date), moment(this.close_date))) return ConferenceStatus.Running;
    return ConferenceStatus.Scheduled;
  }

  get Path(): string {
    if (isNullOrWhiteSpace(this._id) && isNullOrWhiteSpace(this.path)) return null;
    if (isNullOrWhiteSpace(this.path)) return this._id;
    return this.path;
  }

  constructor(conference: Partial<Conference> | string) {
    super(conference);
    if (typeof conference === "string") return;

    const {
      preview,
      path,
      start_date,
      end_date,
      open_date,
      close_date,
      lobby,
      presentation_vendors,
      auto_register,
      corporate_register,
      investor_register,
      general_register,
      internal_register,
      corporate_deadlines,
      investor_deadlines,
      _company,
      _speaker,
      _presentation,
      _track,
      login,
      created_at,
      updated_at,
      scheduler,
      ...rest
    } = conference ?? {};

    this.preview = !!preview;
    this.path = path?.trim();

    this.start_date = convertIsoToMomentUtc(start_date);
    this.end_date = convertIsoToMomentUtc(end_date);
    this.open_date = convertIsoToMomentUtc(open_date);
    this.close_date = convertIsoToMomentUtc(close_date);

    // TODO: Remove this once /allconferences/ is properly fixed
    this._company = Array.isArray(_company) ? _company.map((company) => new Company(company))[0] : new Company(_company);
    this._speaker = (_speaker ?? []).map((speaker) => new Speaker(speaker));
    this._track = (_track ?? []).map((track) => new Track(track));
    this._presentation = (_presentation ?? []).map((presentation) => new Presentation(presentation));
    this.created_at = convertIsoToMomentUtc(created_at);
    this.updated_at = convertIsoToMomentUtc(updated_at);
    this.lobby = {
      agenda: getLobbyConfig("agenda", lobby),
      dialInInstructions: getLobbyConfig("dialInInstructions", lobby),
      ondemand_presentations: getLobbyConfig("ondemand_presentations", lobby),
      speakers: getLobbyConfig("speakers", lobby),
      tracks: getLobbyConfig("tracks", lobby),
      livePresentation: getLobbyConfig("livePresentation", lobby),
      corporate_lobby: getLobbyOpenConfig("corporate_lobby", lobby),
      investor_lobby: getLobbyOpenConfig("investor_lobby", lobby),
      general_lobby: getLobbyOpenConfig("general_lobby", lobby),
    };

    this.presentation_vendors = isEmpty(presentation_vendors)
      ? [
          {
            name: PresentationVendorType.Custom,
          },
        ]
      : presentation_vendors;

    this.auto_register = {
      enabled: auto_register?.enabled ?? false,
      start_date: convertIsoToMomentUtc(auto_register?.start_date),
      end_date: convertIsoToMomentUtc(auto_register?.end_date),
    };

    this.corporate_register = {
      start_date: convertIsoToMomentUtc(corporate_register?.start_date),
      end_date: convertIsoToMomentUtc(corporate_register?.end_date),
    };

    this.investor_register = {
      start_date: convertIsoToMomentUtc(investor_register?.start_date),
      end_date: convertIsoToMomentUtc(investor_register?.end_date),
    };

    this.internal_register = {
      start_date: convertIsoToMomentUtc(internal_register?.start_date),
      end_date: convertIsoToMomentUtc(internal_register?.end_date),
    };

    this.general_register = {
      start_date: convertIsoToMomentUtc(general_register?.start_date),
      end_date: convertIsoToMomentUtc(general_register?.end_date),
    };

    this.corporate_deadlines = {
      ...corporate_deadlines,
      speaker: convertIsoToMomentUtc(corporate_deadlines?.speaker),
      presentation: convertIsoToMomentUtc(corporate_deadlines?.presentation),
      meeting: convertIsoToMomentUtc(corporate_deadlines?.meeting),
    };

    this.investor_deadlines = {
      ...investor_deadlines,
      meeting_request: convertIsoToMomentUtc(investor_deadlines?.meeting_request),
      availability_changes: convertIsoToMomentUtc(investor_deadlines?.availability_changes),
    };

    this.login = {
      ...login,
      primary_color: isNullOrWhiteSpace(login?.primary_color) ? Color.Black : login.primary_color,
    };

    this.scheduler = scheduler;

    if (scheduler?.start_date) {
      this.scheduler.start_date = convertIsoToMomentUtc(scheduler.start_date);
    }
    if (scheduler?.end_date) {
      this.scheduler.end_date = convertIsoToMomentUtc(scheduler.end_date);
    }

    if (!isEmpty(scheduler?.slots)) {
      this.scheduler.slots = scheduler.slots.map(({ start_time, end_time }) => ({
        start_time: convertIsoToMomentUtc(start_time),
        end_time: convertIsoToMomentUtc(end_time),
      }));
    }

    Object.assign(this, rest);
  }
}

type ConferencePayloadBase = Omit<
  Conference,
  "_presentation" | "_meeting" | "_speaker" | "Status" | "_track" | "video_vendors" | "auto_approve" | "Path"
>;
export class ConferencePayload extends PayloadBase<ConferencePayloadBase> implements ConferencePayloadBase {
  _id: Conference["_id"];
  close_date: Conference["close_date"];
  end_date: Conference["end_date"];
  open_date: Conference["open_date"];
  start_date: Conference["start_date"];
  date_label: Conference["date_label"];
  time_zone: Conference["time_zone"];
  title: Conference["title"];
  lobby: Conference["lobby"];
  navigation_links: Conference["navigation_links"];
  auto_approve: boolean;
  conference_type: Conference["conference_type"];
  registration: Conference["registration"];
  corporate_deadlines: CorporateRegistrationDeadlines;
  investor_deadlines: InvestorRegistrationDeadlines;
  requireAuthenticatedMeetingLink: boolean;
  login: Conference["login"];

  constructor(conference: Conference) {
    super();
    const {
      auto_approve,
      corporate_profiles,
      Path,
      Status,
      _company,
      _presentation,
      _meeting,
      _speaker,
      _track,
      ...payload
    } = conference;
    const sanitizedPayload = this.sanitize(payload);

    Object.assign(this, sanitizedPayload, { _company: conference._company?._id });
  }

  sanitize(entity: ConferencePayloadBase): ConferencePayloadBase {
    const { description, registration_description, disclaimer, info, lobby, email_configuration } = entity;

    return super.sanitize({
      ...entity,
      description: sanitizeHtml(description),
      registration_description: sanitizeHtml(registration_description),
      disclaimer: sanitizeHtml(disclaimer),
      info: sanitizeHtml(info),
      lobby: {
        ...lobby,
        dialInInstructions: {
          ...lobby?.dialInInstructions,
          label: sanitizeHtml(lobby?.dialInInstructions?.label),
        },
      },
      email_configuration: {
        ...email_configuration,
        message: sanitizeHtml(email_configuration?.message),
        registration_approval_message: sanitizeHtml(email_configuration?.registration_approval_message),
      },
    });
  }
}
