import {
  AnchorIdModel,
  AnchorListId,
  AutomationListId,
  ButtonIdModel,
  ButtonListId,
  convertStringToEnum,
  IdModelBase,
  isEmpty,
  isNullOrWhiteSpace,
  PopoverMenuIdModel,
  TextListId,
  ToggleIdModel,
} from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import moment from "moment";
import type { Moment } from "moment";
import config from "../../../../../config/config";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import type { CalendarExportType } from "../../../../../services/calendarExport/calendarExport.model";
import type { Company, CompanyRouteParams } from "../../../../../services/company/company.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { Meeting } from "../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../services/presentation/presentation.model";
import { SessionBase, SessionEventType } from "../../../../../services/session/session.model";
import { Speaker } from "../../../../../services/speaker/speaker.model";
import { CorporateProfile } from "../../../../../services/user/corporateProfile/corporateProfile.model";
import { getCompanies, getLobbySessionUrl, isAttendeeSpeaker, isCorporateType } from "../../../../../utils";
import { isLobbyPresentationVendor } from "../../../../company/lobby/companyLobby.utils";
import { TimeZoneSelectorIdModel } from "../../../components/timeZoneSelector/timeZoneSelector.definition";

export interface AgendaProps extends BaseComponentProps {
  user?: AttendeeViewModel;
  company: CompanyRouteParams;
  conference?: Conference;
  presentations?: Presentation[];
  meetings?: Meeting[];
  now: Moment;
  attendees?: AttendeeViewModel[];
  loading?: boolean;
  exporting?: boolean;
  showAgenda?: boolean;
  usePresentationTheater?: boolean;
  onTimeZoneChange: (timeZone: string) => void;
  onExport: (type: CalendarExportType) => void;
  onRemove: (_id: AgendaSession["_id"]) => void;
}

export enum AgendaClassName {
  Base = "agenda",
  Details = "agenda_details",
  Actions = "agenda_actions",
  Export = "agenda_export",
  ShowPastToggle = "agenda_show-past-toggle",
  Loader = "agenda_loader",
  Group = "agenda_group",
  GroupContainer = "agenda_group_container",
  Date = "agenda_date",
  List = "agenda_list",
  Item = "agenda_item",
  ItemWithDisabledModifier = "agenda_item--disabled",
  Cell = "agenda_cell",
  Time = "agenda_time",
  Room = "agenda_room",
  Title = "agenda_title",
  Remove = "agenda_remove",
  Session = "agenda_session",
  PopoverMenu = "agenda_popover-menu",
  ExportButton = "agenda_export-button",
  SessionType = "agenda_session-type",
  SessionTypeLong = "agenda_session-type--long",
  SessionDetail = "agenda_session-detail",
  DialInformation = "agenda_dial-in",
  DialInformationSubheader = "agenda_dial-in-subheader",
  DialInformationGroup = "agenda_dial-in-group",
  Label = "agenda_label",
  Description = "agenda_description",
  Link = "agenda_link",
  Vendor = "agenda_vendor",
  VendorOverrideDialInNumbers = "agenda_vendor_override-dial-in-numbers",
  PresentationAnchor = "agenda_presentation-anchor",
}

export enum AgendaSessionLabel {
  OneOnOne = "1:1",
  TwoOnOne = "2:1",
  ThreeOnOne = "3:1",
  Group = "Group",
  Presentation = "Presentation",
}

export interface AgendaSession
  extends Pick<
    SessionBase,
    "_id" | "end_date" | "start_date" | "labelOverride" | "vendor" | "vendor_override" | "roomLocation"
  > {
  _company?: AttendeeViewModel["company"][];
  sessionLabel: AgendaSessionLabel;
  speakers?: Speaker[];
  attendees?: AttendeeViewModel[];
  preview?: boolean;
  user: AttendeeViewModel;
  Readonly: boolean;
  Route: string;
  GoUrl: string;
  Title: string;
  isUserSpeaker?: boolean;
  _conference: Conference;
  url_suffix: Company["url_suffix"];
  custom_path: Company["custom_path"];
  description?: Presentation["description"];
  session_type?: Presentation["session_type"];
  speaker_link?: Presentation["speaker_link"];
  speaker_notes?: Presentation["speaker_notes"];
  speaker_minutes?: Presentation["speaker_minutes"];
  presentation_type?: Presentation["presentation_type"];
  typeSession: SessionEventType;
  linkId?: string;
  add_to_all?: boolean;
}

export class AgendaMeeting implements AgendaSession {
  _id: AgendaSession["_id"];
  user: AttendeeViewModel;
  sessionLabel: AgendaSession["sessionLabel"];
  attendees: AgendaSession["attendees"];
  custom_path: Company["custom_path"];
  end_date: AgendaSession["end_date"];
  start_date: AgendaSession["start_date"];
  labelOverride: AgendaSession["labelOverride"];
  preview: boolean;
  _company: AgendaSession["_company"];
  _conference: Conference;
  url_suffix: Company["url_suffix"];
  linkId?: AgendaSession["linkId"];
  roomLocation: AgendaSession["roomLocation"];

  typeSession = SessionEventType.Meeting;

  get Route(): string {
    if (isNullOrWhiteSpace(this._id) || isEmpty(this._conference) || isNullOrWhiteSpace(this.url_suffix || this.custom_path))
      return null;

    const conferencePath = this._conference?.Path;

    return getLobbySessionUrl(
      this.typeSession,
      { url_suffix: this.url_suffix, custom_path: this.custom_path },
      conferencePath,
      this._id
    );
  }

  get GoUrl(): string {
    if (this.preview) return null;

    const route = this.Route;
    if (isNullOrWhiteSpace(route)) return null;

    return `${config.app.url}${route}`;
  }

  // Title formats used mainly for agenda and add to calendar
  get Title(): string {
    const label = !isNullOrWhiteSpace(this.labelOverride) ? `${this.labelOverride}` : `${this.sessionLabel} Meeting`;

    const userType = this.user?.type;
    if (isEmpty(this.user) || isNullOrWhiteSpace(userType)) return label;

    const isCorporate = isCorporateType(userType);
    if (!isCorporate) {
      const corpName = new CorporateProfile((this.attendees || []).find((x) => isCorporateType(x.type))?._corporate_profile)
        ?.name;
      return `${label}${isNullOrWhiteSpace(corpName) ? "" : ": " + corpName}`;
    }
    return `${label}: ${this._company.filter((x) => x !== this.user?.company).join(", ")}`;
  }

  get Readonly(): boolean {
    return true;
  }

  constructor(
    meeting: Meeting,
    user: AttendeeViewModel,
    conference: Conference,
    company: CompanyRouteParams,
    preview?: boolean
  ) {
    this.preview = !!preview;

    if (isEmpty(meeting)) return;

    const { _attendee, start_date, end_date, label, ...rest } = meeting;

    this.user = user;
    this._company = getCompanies(_attendee);
    this.attendees = !isEmpty(_attendee) ? _attendee.map((x) => new AttendeeViewModel(x)) : [];
    this.start_date = typeof start_date === "string" ? moment.utc(start_date) : start_date;
    this.end_date = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this.sessionLabel = convertStringToEnum(AgendaSessionLabel, label);

    Object.assign(this, rest, {
      _conference: conference,
      url_suffix: company?.url_suffix,
      custom_path: company?.custom_path,
    });
  }
}

export class AgendaPresentation implements AgendaSession {
  private _title: Presentation["title"];
  presentation_type?: Presentation["presentation_type"];
  user: AttendeeViewModel;
  _id: SessionBase["_id"];
  sessionLabel: AgendaSessionLabel;
  speakers: AgendaSession["speakers"];
  end_date: AgendaSession["end_date"];
  start_date: AgendaSession["start_date"];
  code?: string[];
  preview: boolean;
  _conference: Conference;
  url_suffix: Company["url_suffix"];
  custom_path: Company["custom_path"];
  roomLocation: AgendaSession["roomLocation"];

  typeSession = SessionEventType.Presentation;

  speaker_notes: AgendaSession["speaker_notes"];
  speaker_minutes: AgendaSession["speaker_minutes"];

  isUserSpeaker: boolean;

  linkId?: AgendaSession["linkId"];

  add_to_all?: AgendaSession["add_to_all"];

  get Route(): string {
    if (isNullOrWhiteSpace(this._id) || isEmpty(this._conference) || isNullOrWhiteSpace(this.url_suffix || this.custom_path))
      return null;

    const conferencePath = this._conference?.Path;
    return getLobbySessionUrl(
      this.typeSession,
      { url_suffix: this.url_suffix, custom_path: this.custom_path },
      conferencePath,
      this._id
    );
  }

  get GoUrl(): string {
    if (this.isUserSpeaker) return this.getRouteUrl();
    if (this.preview && !isLobbyPresentationVendor(this._conference?.presentation_vendors)) return null;

    return this.getRouteUrl();
  }

  get Title(): string {
    return `${this._title}`;
  }

  get Readonly(): boolean {
    return false;
  }

  constructor(
    presentation: Presentation,
    user: AttendeeViewModel,
    conference: Conference,
    company: CompanyRouteParams,
    preview?: boolean
  ) {
    if (isEmpty(presentation)) return;

    this.preview = !!preview;

    const { title, start_date, end_date, _speaker, code, speaker_minutes, presentation_type, ...rest } = presentation;

    const isUserSpeaker = isAttendeeSpeaker(user, _speaker);
    const startDate = typeof start_date === "string" ? moment.utc(start_date) : start_date;

    this._title = title;
    this.start_date = isUserSpeaker ? startDate.clone().subtract(speaker_minutes, "minutes") : startDate.clone();
    this.end_date = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this.speakers = (_speaker || []).map((x) => new Speaker(x));
    this.sessionLabel = AgendaSessionLabel.Presentation;
    this.code = code ?? [];
    this.user = user;
    this.speaker_minutes = speaker_minutes;
    this.isUserSpeaker = isUserSpeaker;
    this.presentation_type = presentation_type;

    Object.assign(this, rest, {
      _conference: conference,
      url_suffix: company?.url_suffix,
      custom_path: company?.custom_path,
    });
  }

  private getRouteUrl() {
    const route = this.Route;
    if (isNullOrWhiteSpace(route)) return null;
    return `${config.app.url}${route}`;
  }
}

export class AgendaIdModel extends IdModelBase {
  indicator: string;
  spinner: string;
  actions: string;
  export: ButtonIdModel;
  dialInAnchor: AnchorIdModel;
  popoverMenu: PopoverMenuIdModel;
  exportList: ButtonListId;
  showPast: ToggleIdModel;
  message: string;
  meeting: TextListId;
  list: AgendaGroupListId;
  title: string;
  vendor: TextListId;
  vendorPasscode: TextListId;
  vendorPhonePasscode: TextListId;
  vendorMeetingCode: TextListId;
  vendorUrl: AnchorListId;
  vendorOverrideDialInNumbers: TextListId;
  roomLocation: TextListId;
  timeZoneSelector: TimeZoneSelectorIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.export = new ButtonIdModel(null);
      this.list = new AgendaGroupListId(null);
      return;
    }

    this.indicator = `${this.id}Indicator`;
    this.spinner = `${this.id}Spinner`;
    this.export = new ButtonIdModel(`${this.id}ExportButton`);
    this.dialInAnchor = new AnchorIdModel(`${this.id}DialInAnchor`);
    this.popoverMenu = new PopoverMenuIdModel(`${this.id}PopoverMenu`);
    this.exportList = new ButtonListId(this.popoverMenu.id, "Type", "Button");
    this.showPast = new ToggleIdModel(`${this.id}ShowPastToggle`);
    this.message = `${this.id}Message`;
    this.meeting = new TextListId(`${this.id}Meeting`);
    this.list = new AgendaGroupListId(id);
    this.title = `${this.id}Title`;
    this.vendor = new TextListId(`${this.id}Vendor`);
    this.vendorPasscode = new TextListId(`${this.id}VendorPasscode`);
    this.vendorPhonePasscode = new TextListId(`${this.id}VendorPhonePasscode`);
    this.vendorMeetingCode = new TextListId(`${this.id}VendorMeetingId`);
    this.vendorUrl = new AnchorListId(`${this.id}VendorUrl`);
    this.vendorOverrideDialInNumbers = new TextListId(`${this.id}VendorOverrideDialInNumbers`);
    this.roomLocation = new TextListId(`${this.id}RoomLocation`);
    this.timeZoneSelector = new TimeZoneSelectorIdModel(`${this.id}TimeZoneSelector`);
  }
}

export class AgendaGroupListId extends AutomationListId<AgendaGroupIdModel> {
  constructor(parentId: string, prefix = "Group-", postfix?: string) {
    super(parentId, prefix, AgendaGroupIdModel, postfix);
  }
}

export class AgendaGroupIdModel extends IdModelBase {
  date: string;
  list: AgendaItemListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.list = new AgendaItemListId(null);
      return;
    }

    this.date = `${this.id}Date`;
    this.list = new AgendaItemListId(id);
  }
}

export class AgendaItemListId extends AutomationListId<AgendaItemIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, AgendaItemIdModel, postfix);
  }
}

export class AgendaItemIdModel extends IdModelBase {
  details: string;
  dialInformation: string;
  time: string;
  title: string;
  remove: string;
  info: string;
  label: string;
  room: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.details = `${this.id}Details`;
    this.dialInformation = `${this.id}DialInformation`;
    this.time = `${this.id}Time`;
    this.title = `${this.id}Title`;
    this.remove = `${this.id}Remove`;
    this.info = `${this.id}Info`;
    this.label = `${this.id}SessionLabel`;
    this.room = `${this.id}Room`;
  }
}
