import {
  convertStringToEnum,
  Form,
  FormFieldProps,
  getClassName,
  isEmpty,
  isNullOrWhiteSpace,
  RadioButton,
  Textbox,
  Button,
  ButtonType,
  InfoIcon,
  TooltipTheme,
  Origin,
  useVisibility,
  NotificationService,
  ErrorModel,
} from "@q4/nimbus-ui";
import "./presentationVendorsForm.scss";
import { capitalize } from "lodash";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import SyncConfirmationMessage from "../../../../../../components/syncConfirmationMessage/syncConfirmationMessage.component";
import {
  SyncedAttendeesResponse,
  SyncStatusNames,
} from "../../../../../../components/syncConfirmationMessage/syncConfirmationMessage.definition";
import { ApiResponse } from "../../../../../../services/api/api.definition";
import { AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import AttendeeService from "../../../../../../services/attendee/attendee.service";
import {
  PresentationVendorType,
  PresentationVendorTypeDefault,
} from "../../../../../../services/conference/conference.model";
import ParallelService from "../../../../../../services/parallel.service";
import { VendorStatus } from "../../conferenceEdit.definition";
import {
  PresentationVendorsFormProps,
  PresentationVendorsFormClassName,
  PresentationVendorsFormIdModel,
} from "./presentationVendorsForm.definition";

const PresentationVendorsForm = (props: PresentationVendorsFormProps): JSX.Element => {
  const {
    className,
    id,
    vendor,
    previousVendorState,
    onChange: onChangeProps,
    totalAttendees,
    onVerifyVendor,
    vendorStatus,
  } = props;
  const syncModalVisibleRef = useRef(false);
  const attendeeService = useRef(new AttendeeService());
  const notificationService = useRef(new NotificationService());

  const [countedAttendees, setAttendeesCount] = useState<number>(0);
  const [syncedAttendees, setSyncedAttendees] = useState<SyncedAttendeesResponse[]>([]);
  const [loading, setLoading] = useState(false);

  const vendorStatusMessage = useMemo(() => {
    if (vendorStatus === VendorStatus.Inaccessible) {
      return new ErrorModel("Invalid Hub ID", true);
    }
    if (vendorStatus === VendorStatus.Verified) {
      return new ErrorModel("Connection OK", true, "ni-checkmark-4pt");
    }

    return null;
  }, [vendorStatus]);

  const vendorStatusClassName = useMemo(() => {
    return getClassName(PresentationVendorsFormClassName.VendorStatus, [
      {
        condition: vendorStatus === VendorStatus.Verified,
        trueClassName: PresentationVendorsFormClassName.VendorStatusWithOkModifier,
      },
      {
        condition: vendorStatus === VendorStatus.Inaccessible,
        trueClassName: PresentationVendorsFormClassName.VendorStatusWithErrorModifier,
      },
    ]);
  }, [vendorStatus]);

  const handleAttendeesSync = useCallback(
    (cancel: () => Error): void => {
      let count = 0;
      setLoading(true);
      const parallelService = new ParallelService();
      let requests = [] as (() => Promise<ApiResponse<AttendeeViewModel>>)[];

      const updateAttendees = async (eachAttendee: AttendeeViewModel) => {
        const response = await attendeeService.current.putAttendeeById(eachAttendee.id, eachAttendee);
        count = count + 1;
        setAttendeesCount(count);
        if (!response.success) {
          return { ...response, data: eachAttendee };
        }
        return response;
      };

      requests = (totalAttendees || []).map((eachAttendee) => () => updateAttendees(eachAttendee));

      parallelService
        .limit(requests, 5, cancel)
        .then((result) => {
          setLoading(false);
          if (!result) {
            notificationService.current.error(SyncStatusNames.Cancelled);
            setAttendeesCount(0);
            setSyncedAttendees([]);
            return new Error(SyncStatusNames.Cancelled);
          }
          const constructAttendeeData = (result || []).map((response) => {
            const { data, success, message } = response;
            return {
              entity: data,
              errorMessage: success ? null : message,
              title: `${data.first_name} ${data.last_name} (${data.email})`,
              actionName: SyncStatusNames.Synced,
            };
          });
          setSyncedAttendees(constructAttendeeData);
          return result;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    [totalAttendees]
  );

  const isSyncCancelled = useCallback((): Error => {
    return !syncModalVisibleRef.current ? new Error(SyncStatusNames.Cancelled) : null;
  }, []);
  const [syncMessageVisible, handleSyncClick, handleSyncClose] = useVisibility();

  const handleSyncAllAttendees = useCallback(() => {
    setAttendeesCount(0);
    setSyncedAttendees([]);
    syncModalVisibleRef.current = true;
    handleAttendeesSync(isSyncCancelled);
  }, [handleAttendeesSync, isSyncCancelled, setAttendeesCount, setSyncedAttendees]);

  const handleSyncCancel = () => {
    syncModalVisibleRef.current = false;
    handleSyncClose();
  };

  const handleSyncModalOpen = useCallback(() => {
    setAttendeesCount(0);
    setSyncedAttendees([]);
    handleSyncClick();
  }, [setAttendeesCount, setSyncedAttendees, handleSyncClick]);

  const baseClassName = useMemo(
    () =>
      getClassName(PresentationVendorsFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const idModel = useMemo(() => new PresentationVendorsFormIdModel(id), [id]);

  const vendorName = useMemo(() => {
    if (isEmpty(vendor) || isNullOrWhiteSpace(vendor?.name)) return PresentationVendorTypeDefault;
    return vendor.name;
  }, [vendor]);

  const streamlinedHubId = useMemo(() => vendor?.options?.hub_id, [vendor?.options?.hub_id]);
  const savedStreamlinedHubId = useMemo(() => previousVendorState?.options?.hub_id, [previousVendorState?.options?.hub_id]);

  const handlePresentationVendorChange = useCallback(
    (_checked: boolean, value: string) => {
      const changedVendorName = convertStringToEnum(PresentationVendorType, value);
      return onChangeProps({
        name: changedVendorName,
      });
    },
    [onChangeProps]
  );

  const handleStreamlinedHubIdChange = useCallback(
    (value: string) => {
      return onChangeProps({
        ...vendor,
        options: {
          hub_id: value,
        },
      });
    },
    [onChangeProps, vendor]
  );

  const handleVerifyVendorClick = useCallback(() => {
    onVerifyVendor(streamlinedHubId);
  }, [onVerifyVendor, streamlinedHubId]);

  const fields = useMemo((): FormFieldProps[] => {
    const formFields = [
      {
        key: "Video Production Vendor",
        width: "1-of-3",
        smallWidth: "1-of-1",
        label: "Video Production Vendor",
        children: (
          <>
            {Object.values(PresentationVendorType).map((option, i) => (
              <RadioButton
                key={option}
                id={idModel.radio.getId(i)}
                name={idModel.radio.parentId}
                label={capitalize(option)}
                value={option}
                checked={vendorName === option}
                alignmentPadding
                inline
                onChange={handlePresentationVendorChange}
              />
            ))}
          </>
        ),
      },
    ];

    if (vendorName === PresentationVendorType.Streamlined) {
      return [
        ...formFields,
        {
          key: "Streamlined Hub ID",
          width: "2-of-3",
          required: true,
          smallWidth: "1-of-1",
          label: "Streamlined Hub ID",
          error: vendorStatusMessage,
          className: vendorStatusClassName,
          children: (
            <>
              <Textbox id={idModel.streamlinedHubId.id} value={streamlinedHubId} onChange={handleStreamlinedHubIdChange} />
              <Button
                id={idModel.streamlinedVerifyButton.id}
                label="Validate ID"
                type={ButtonType.Submit}
                onClick={handleVerifyVendorClick}
                className={PresentationVendorsFormClassName.VendorStatusVerifyButton}
              />
            </>
          ),
        },
        {
          key: "Streamlined Sync Attendees",
          width: "1-of-2",
          smallWidth: "1-of-3",
          className: "presentation-vendor-form_custom-label",
          label: (
            <>
              Sync Attendees to Streamlined
              <InfoIcon
                tooltipProps={{
                  label: "Update all attendee IDs to Streamlined IDs",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <Button
              label="Sync Attendees"
              type={ButtonType.Submit}
              disabled={!savedStreamlinedHubId}
              onClick={handleSyncModalOpen}
            />
          ),
        },
      ];
    }
    return formFields;
  }, [
    handlePresentationVendorChange,
    handleStreamlinedHubIdChange,
    idModel.streamlinedHubId.id,
    idModel.streamlinedVerifyButton.id,
    idModel.radio,
    streamlinedHubId,
    vendorName,
    handleSyncModalOpen,
    savedStreamlinedHubId,
    handleVerifyVendorClick,
    vendorStatusMessage,
    vendorStatusClassName,
  ]);

  return (
    <>
      <Form id={idModel.id} className={baseClassName} fields={fields} />
      <div>
        <SyncConfirmationMessage
          id={idModel.syncAttendeesModal?.id}
          loading={loading}
          visible={syncMessageVisible}
          totalAttendees={totalAttendees?.length}
          currentCount={countedAttendees}
          syncedAttendees={syncedAttendees}
          onCancel={handleSyncCancel}
          onConfirm={handleSyncAllAttendees}
        />
      </div>
    </>
  );
};

export default memo(PresentationVendorsForm);
