import { Message, MessageType, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { capitalize } from "lodash";
import React, { memo, useMemo } from "react";
import { DeleteConfirmationMessageIdModel, DeleteConfirmationMessageProps } from "./deleteConfirmationMessage.definition";

const DeleteConfirmationMessage = (props: DeleteConfirmationMessageProps): JSX.Element => {
  const { id, entity, customEntityTitle, visible, loading, title, message, onCancel, onConfirm } = props;

  const idModel = useMemo(() => new DeleteConfirmationMessageIdModel(id), [id]);
  const customTitle = isNullOrWhiteSpace(customEntityTitle) ? entity : customEntityTitle;

  return (
    <Message
      id={idModel.message?.id}
      visible={visible}
      messageType={MessageType.Warning}
      title={title || `Delete ${capitalize(customTitle)}`}
      message={
        message || `Are you sure you want to delete this ${(customTitle || "").toLowerCase()}? This action cannot be undone.`
      }
      secondaryActionProps={{
        id: idModel.cancel?.id,
        label: "Cancel",
        onClick: onCancel,
      }}
      primaryActionProps={{
        id: idModel.delete?.id,
        label: "Delete",
        loading,
        onClick: onConfirm,
      }}
      onCloseRequest={onCancel}
    />
  );
};

export default memo(DeleteConfirmationMessage);
