import { convertStringToEnum, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Entity } from "../../definitions/entity.definition";
import { ApiResponse } from "../api/api.definition";
import { CorporateProfile, CorporateType } from "../corporateProfile/corporateProfile.model";
import CorporateProfileService from "../corporateProfile/corporateProfile.service";
import {
  CsvBase,
  CsvCorporateProfile,
  ImportCorporateProfileError,
  ImportOptions,
  ImportResult,
  ImportServiceBase,
} from "./import.definition";

export default class ImportCorporateProfileService implements ImportServiceBase<CorporateProfile> {
  private corporateProfiles: { entity: CorporateProfile; csv: CsvBase }[];
  private requests: (() => Promise<ApiResponse<CorporateProfile>>)[];
  private corporateProfileService = new CorporateProfileService();

  get Data(): CorporateProfile[] {
    return this.corporateProfiles?.map((x) => x.entity) || [];
  }

  get HasSetting(): boolean {
    return false;
  }
  get Requests(): (() => Promise<ApiResponse<CorporateProfile>>)[] {
    return this.requests || [];
  }
  get Type(): Entity {
    return Entity.CorporateProfile;
  }

  parse = async (options: ImportOptions<CsvCorporateProfile>): Promise<ImportResult<CorporateProfile>[]> => {
    const { csvJson, conference, handleResults } = options || {};
    if (isEmpty(csvJson) || isNullOrWhiteSpace(conference?._id)) return [];
    const results: ImportResult<CorporateProfile>[] = [];
    this.corporateProfiles = csvJson.reduce((corpProfiles, csv) => {
      if (isEmpty(csv)) return corpProfiles;

      const { company_name, private_public: privateOrPublic, meetings } = csv;

      const corporateType = convertStringToEnum(CorporateType, privateOrPublic);
      const type = isNullOrWhiteSpace(corporateType) ? CorporateType.Private : corporateType;
      const sanitizedMeetings = this.sanitize(meetings);
      const host_meetings = sanitizedMeetings.toLowerCase() === "y" || sanitizedMeetings.toLowerCase() === "yes";

      let corporateProfile: CorporateProfile = new CorporateProfile({
        _conference: conference._id,
        _primary_contact: undefined,
        name: company_name,
        type,
        host_small_meetings: host_meetings,
      });

      if (type == CorporateType.Public) {
        corporateProfile = Object.assign(corporateProfile, { ticker_symbol: "-", exchange: "-" });
      }

      const parseError = this.getParseError(corporateProfile);
      if (!isEmpty(parseError)) {
        results.push(
          new ImportResult({
            type: this.Type,
            entity: corporateProfile,
            title: corporateProfile.name,
            csv,
            errorMessage: parseError,
          })
        );
        return corpProfiles.concat({ entity: null, csv });
      }
      return corpProfiles.concat({ entity: corporateProfile, csv });
    }, []);

    this.requests = this.corporateProfiles.reduce(
      (requests, profileData): (() => Promise<ApiResponse<CorporateProfile>>)[] => {
        if (isEmpty(profileData?.entity)) return requests;
        const { entity: profile, csv } = profileData;

        return requests.concat((): Promise<ApiResponse<CorporateProfile>> => {
          return this.corporateProfileService.post(profile).then((response) => {
            const { success, message } = response;
            handleResults(
              this.Type,
              {
                ...response,
                data: success ? response.data : profile,
                message,
                success,
              },
              "name",
              csv
            );

            return response;
          });
        });
      },
      [] as (() => Promise<ApiResponse<CorporateProfile>>)[]
    );

    return results;
  };

  private sanitize(value: string): string {
    if (isNullOrWhiteSpace(value)) return "";
    return value.trim();
  }

  private getParseError(corporateProfile: CorporateProfile): ImportCorporateProfileError {
    if (isEmpty(corporateProfile)) return ImportCorporateProfileError.Empty;
    return null;
  }
}
