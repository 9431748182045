import { isEmpty } from "@q4/nimbus-ui";
import type { AsyncFunction } from "async";
import parallelLimit from "async/parallelLimit";

export default class ParallelService {
  readonly DefaultLimit = 5;

  all<T>(functions: (() => Promise<T>)[]): Promise<T[]> {
    return Promise.all(functions.map((func): Promise<T> => typeof func === "function" && func()));
  }

  limit = <T>(requests: ParallelRequest<T>[], limit = this.DefaultLimit, cancel?: () => Error): Promise<T[]> => {
    const tasks = requests.map((func): AsyncFunction<T> => this.createParallelTask<T>(func, cancel));
    return parallelLimit(tasks, limit)
      .then((responses: T[]): T[] => responses?.reduce((results, result): T[] => results.concat(result), []))
      .catch((e: Error) => console.error(e.message));
  };

  private createParallelTask =
    <T>(service: ParallelRequest<T>, cancel?: () => Error): AsyncFunction<T> =>
    (cb: (cancel: Error, data: T) => void): Promise<void> =>
      service().then((response: T): void => cb(isEmpty(cancel) || typeof cancel !== "function" ? null : cancel(), response));
}

export type ParallelRequest<T> = () => Promise<T>;
