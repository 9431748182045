import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { DateRange, extendMoment } from "moment-range";
import MomentLib, { Moment } from "moment-timezone";
import { DateFormat, TimeFormat } from "../../../../../../definitions/date.definition";
import { AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import type {
  Conference,
  ConferenceScheduler,
  ConferenceSchedulerSlot,
} from "../../../../../../services/conference/conference.model";
import { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../services/meeting/meeting.model";
import { Presentation } from "../../../../../../services/presentation/presentation.model";
import { SessionBase } from "../../../../../../services/session/session.model";
import { getAttendeeCorporateNames, getNonConflictingSlots, mapAvailabilityToTimeZone } from "../../../../../../utils";
import { getScheduleStatus } from "../../../../../../utils/scheduler/scheduler.utils";
import { EventDay, EventSlot, EventType, ResourceEvent } from "./components/flexibleScheduler/flexibleScheduler.definition";
import { SchedulerRequest, SchedulerResource, SchedulerResourceType } from "./meetingScheduler.definition";

// eslint rule needed as default moment export will not work for app and jest simultaneously, must be named import (https://github.com/rotaready/moment-range/issues/263)
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const moment = extendMoment(MomentLib as any);

type CalendarMinMaxTime = { hour: number; minutes: number };
type CalendarMinMax = { start: CalendarMinMaxTime; end: CalendarMinMaxTime };

export function getCalendarTime(timetableDay: ConferenceSchedulerSlot, timeZone: Conference["time_zone"]): CalendarMinMax {
  if (isEmpty(timetableDay))
    return {
      start: {
        hour: 8,
        minutes: 0,
      },
      end: {
        hour: 20,
        minutes: 0,
      },
    };

  const startDate = moment(timetableDay.start_time).tz(timeZone);
  const endDate = moment(timetableDay.end_time).tz(timeZone);

  return {
    start: {
      hour: startDate.get("hour"),
      minutes: startDate.get("minutes"),
    },
    end: {
      hour: endDate.get("hour"),
      minutes: endDate.get("minutes"),
    },
  };
}

export function getTitle(type: string, participants: AttendeeViewModel[], attendeeType?: AttendeeViewModel["type"]): string {
  const corporateName = getAttendeeCorporateNames(participants, attendeeType);
  return `${type} ${corporateName}`;
}

export function getSlotAvailability(date: Date, availability: ConferenceSchedulerSlot[]): boolean {
  if (isEmpty(availability)) return false;
  return availability.some((x) => {
    const range = moment.range(x.start_time, x.end_time);
    const momentDate = moment(date);
    return range.contains(momentDate, { excludeEnd: true });
  });
}

export function getSlotAvailabilityForCalendar(
  slotStart: Moment,
  slotEnd: Moment,
  availability: ConferenceSchedulerSlot[]
): boolean {
  if (isEmpty(availability)) return false;
  return availability.some((x) => {
    const slotRange = moment.range(slotStart, slotEnd);
    const availabilityRange = moment.range(x.start_time, x.end_time);
    return availabilityRange.contains(slotRange);
  });
}

export function getResourceAvailability(resource: SchedulerResource): ConferenceSchedulerSlot[] {
  if (isEmpty(resource)) return [];
  return resource.availability ?? resource.attendees?.[0]?.availability;
}

export function getCorporateRequests(
  corporateProfileId: CorporateProfile["_id"],
  attendees: AttendeeViewModel[],
  timeZone: Conference["time_zone"]
): AttendeeViewModel[] {
  if (isNullOrWhiteSpace(corporateProfileId) || isEmpty(attendees)) return [];

  return attendees.reduce((requests: AttendeeViewModel[], attendee) => {
    const hasRequest = attendee?.meeting_requests.some((x) => {
      return !isNullOrWhiteSpace(x?._corporate_profile) && x._corporate_profile === corporateProfileId;
    });
    if (!hasRequest) return requests;

    requests.push(
      new AttendeeViewModel({
        ...attendee,
        availability: mapAvailabilityToTimeZone(attendee.availability, timeZone),
      })
    );
    return requests;
  }, [] as AttendeeViewModel[]);
}

export function getInvestorRequests(attendee: AttendeeViewModel, corporateProfiles: CorporateProfile[]): CorporateProfile[] {
  if (isNullOrWhiteSpace(attendee._id) || isEmpty(corporateProfiles)) return [];

  return corporateProfiles.reduce((requests: CorporateProfile[], profile) => {
    const hasRequest = attendee?.meeting_requests.some((x) => {
      return !isNullOrWhiteSpace(x?._corporate_profile) && x._corporate_profile === profile?._id;
    });
    if (!hasRequest) return requests;

    requests.push(new CorporateProfile(profile));
    return requests;
  }, []);
}

export function attendingMeeting(id: AttendeeViewModel["_id"], meeting: Meeting): boolean {
  if (isEmpty(meeting?._attendee) || isNullOrWhiteSpace(id)) return false;
  return meeting._attendee.some((attendee) => attendee?._id === id);
}

export function getTotalSlots(days: ConferenceSchedulerSlot[], timeslot: number, timeZone: Conference["time_zone"]): number {
  return getSlots(days, timeslot, timeZone)?.length ?? 0;
}

export function getSlots(days: ConferenceSchedulerSlot[], timeslot: number, timeZone: Conference["time_zone"]): Moment[] {
  const step = timeslot ?? 0;

  return (days || []).reduce((slots, day) => {
    if (isEmpty(day)) return slots;

    const dates = moment.range(moment(day.start_time)?.tz(timeZone), moment(day.end_time)?.tz(timeZone));
    const daysByMinutes = [...dates.by("minutes", { step, excludeEnd: true })];

    slots = slots.concat(daysByMinutes);

    return slots;
  }, [] as Moment[]);
}

export function getSchedulerRequests(
  requester: AttendeeViewModel[] | CorporateProfile[],
  meetingRequests: Meeting[]
): SchedulerRequest[] {
  return requester.map((request) => {
    const meeting =
      request instanceof CorporateProfile
        ? meetingRequests?.find((mtg) => request.attendees.some((x) => attendingMeeting(x._id, mtg)))
        : meetingRequests?.find((mtg) => attendingMeeting(request._id, mtg));
    const hasMeeting = !isEmpty(meeting);
    const requestStatus = getScheduleStatus(hasMeeting, false);

    return request instanceof CorporateProfile
      ? new SchedulerRequest({
          _id: request._id,
          name: request.name,
          requestStatus,
          attendees: request.attendees,
        })
      : new SchedulerRequest({
          _id: request._id,
          name: request.display_name,
          requestStatus,
          meeting_requests: request.meeting_requests,
          title: request.title,
          company: request.company,
        });
  });
}

export function getSlotRange(slot: Moment, duration: number): DateRange {
  if (isEmpty(slot)) return null;
  return moment.range(slot, slot.clone().add(duration ?? 0, "minutes"));
}

export function getMeetingSlotRange(meeting: Meeting): DateRange {
  if (isEmpty(meeting)) return null;
  return moment.range(meeting.start_date.clone(), meeting.end_date.clone());
}

export function getPresentationRange(presentation: Presentation, includeSpeakerMinutes = false): DateRange {
  if (isEmpty(presentation)) return null;

  const start = !includeSpeakerMinutes
    ? presentation.start_date.clone()
    : presentation.start_date.clone().subtract(presentation.speaker_minutes, "minutes");
  return moment.range(start, presentation.end_date.clone());
}

export function getExistingMeetingMessage(meeting: Meeting, timeZone: Conference["time_zone"]): string {
  if (isEmpty(meeting)) return "A meeting with these attendees already exists. Do you still want to save this meeting?";

  const date = meeting.start_date.clone().tz(timeZone).format(DateFormat.FullDate);
  const time = meeting.start_date.clone().tz(timeZone).format(TimeFormat.Picker);
  return `A meeting with these attendees already exists on ${date} at ${time}. Do you still want to save this meeting?`;
}

export function getParticipatingAttendees(date: Date, resources: SchedulerResource[]): AttendeeViewModel[] {
  return resources.reduce((attendees, resource) => {
    if (resource.resourceType === SchedulerResourceType.Corporate) {
      const participating = resource.attendees.filter((value) => {
        if (value.participate_in_meetings !== true) return false;
        const availability = getResourceAvailability(new SchedulerResource(value));
        return getSlotAvailability(date, availability);
      });

      attendees.push(...participating);
    } else {
      attendees.push(new AttendeeViewModel(resource));
    }

    return attendees;
  }, [] as AttendeeViewModel[]);
}

export function formatMeetingCorporateAvailability(
  availability: ConferenceSchedulerSlot[],
  timezone: string
): ConferenceSchedulerSlot[] {
  return availability.map((slot) => ({
    start_time: moment(slot.start_time).tz(timezone),
    end_time: moment(slot.end_time).tz(timezone),
  }));
}

export function isEventContainedWithinSlot(event: ResourceEvent<SessionBase>, slot: EventSlot<SessionBase>): boolean {
  return moment.range(slot.start_time, slot.end_time).contains(moment.range(event.start_time, event.end_time));
}

export function isEventIntersectingSlot(event: ResourceEvent<SessionBase>, slot: EventSlot): boolean {
  const eventRange = moment.range(event.start_time, event.end_time);
  const slotRange = moment.range(slot.start_time, slot.end_time);

  return eventRange.overlaps(slotRange);
}

export const buildSchedulerSlots = (
  scheduler: ConferenceScheduler,
  timeZone: Conference["time_zone"]
): ConferenceSchedulerSlot[] => {
  const { start_date: startDate, end_date: endDate, start_time, end_time, duration, break: breakDuration } = scheduler;
  const days = [...moment.range(moment(startDate).clone(), moment(endDate).clone()).by("days")];

  const finalSlots = days.reduce((slotArray, day) => {
    const startRange = moment(day.clone()).startOf("day").add(start_time, "minutes");
    const endRange = moment(day.clone()).startOf("day").add(end_time, "minutes");

    const availableSlotRange = moment.range(startRange, endRange);
    const sessions = [...availableSlotRange.by("minutes", { step: duration + breakDuration, excludeEnd: true })];

    const eachSlot = sessions.reduce((acc, time) => {
      const calculatedEndRange = time.clone().add(duration, "minutes");

      // if slot duration doesn't fit withing end time, disregard the slot
      if (calculatedEndRange.isAfter(endRange)) {
        return acc;
      }

      acc.push({
        start_time: time.clone().tz(timeZone, true),
        end_time: calculatedEndRange.tz(timeZone, true),
      });

      return acc;
    }, []);

    slotArray.push(eachSlot);

    return slotArray;
  }, []);

  return finalSlots.flat();
};

export const getCalendarDays = (scheduler: ConferenceScheduler, timeZone: Conference["time_zone"]): Moment[] => {
  if (isEmpty(scheduler)) return [];
  const { start_date, end_date } = scheduler;

  if (isEmpty(start_date) || isEmpty(end_date)) return [];

  const startDate = moment(start_date).tz(timeZone).startOf("day");
  const endDate = moment(end_date).tz(timeZone).startOf("day");

  return [...moment.range(startDate, endDate).by("days")];
};

export const getEventDaysFromCalendarDays = (
  calendarDays: Moment[],
  scheduler: ConferenceScheduler,
  timeZone: Conference["time_zone"]
): EventDay[] => {
  if (isEmpty(calendarDays)) return [];

  return calendarDays.map((day) => {
    const filteredSlots = (scheduler?.slots || []).reduce((slots, slot) => {
      const start_time = slot.start_time.tz(timeZone);
      if (moment(start_time).tz(timeZone).isSame(day, "day")) {
        const end_time = slot.end_time.tz(timeZone);
        slots.push({
          start_time,
          end_time,
        });
      }
      return slots;
    }, []);

    return {
      date: day,
      slots: filteredSlots,
    };
  });
};

export const constructPseudoSlots = (
  slots: EventSlot[], // for the day
  meetingsPerResource: ResourceEvent<Meeting>[][], // an array of array of meetings per resource for the day
  presentations: ResourceEvent<Presentation>[], // presentations (only applicable to corporate resource) for the day
  timeZone: Conference["time_zone"]
): EventSlot[] => {
  const newSlots = [...getNonConflictingSlots(slots)] as EventSlot[];

  [...meetingsPerResource, presentations].forEach((meetings) =>
    meetings.forEach((meeting) => {
      let start = moment(meeting.start_time).tz(timeZone);
      const end = moment(meeting.end_time).tz(timeZone);

      // If no slots for the day, still want to show meetings/presentations for the day as pseudo events
      if (isEmpty(newSlots)) {
        newSlots.push({
          start_time: start,
          end_time: end,
          pseudo: true,
        });
      }

      // Iterating and modifying over array at the same time
      // to add in pseudo slots if a portion of a session is uncovered
      for (let i = 0; i < newSlots.length; ++i) {
        const slotStart = newSlots[i].start_time;
        const slotEnd = newSlots[i].end_time;

        // IF MEETING STARTS AFTER SLOT, SKIP SLOT
        if (start.isSameOrAfter(slotEnd) && i !== newSlots.length - 1) {
          continue;
        }

        // SCENARIO 1: MEETING HAPPENS BEFORE SLOT
        if (start.isBefore(slotStart)) {
          newSlots.splice(i, 0, {
            start_time: start,
            end_time: end.isSameOrBefore(slotStart) ? end : slotStart,
            pseudo: true,
          });
          ++i;
        }

        if (end.isSameOrBefore(slotEnd)) {
          break; // END IS COVERED IN CURRENT SLOT, NO MORE ITERATION NEEDED
        }

        // SCENARIO 2: MEETING CONTINUES AFTER LAST SLOT
        if (i === newSlots.length - 1) {
          if (start.isSameOrAfter(slotEnd)) {
            newSlots.push({
              start_time: start,
              end_time: end,
              pseudo: true,
            });
          } else if (end.isAfter(slotEnd)) {
            newSlots.push({
              start_time: slotEnd,
              end_time: end,
              pseudo: true,
            });
          }

          break;
        }

        // SHIFT START TO END OF PREVIOUS SLOT AND CONTINUE LOOP
        start = slotEnd;
      }
    })
  );

  return newSlots;
};

export const sortResourceEventsByTimeAndType = (a: ResourceEvent<SessionBase>, b: ResourceEvent<SessionBase>): number => {
  const aStart = moment(a.start_time);
  const bStart = moment(b.start_time);
  const aEnd = moment(a.end_time);
  const bEnd = moment(b.end_time);

  if (aStart.isSame(bStart)) {
    if (aEnd.isSame(bEnd)) {
      switch (a.type) {
        case EventType.Break:
          return -1;
        case EventType.Presentation:
          return b.type === EventType.Break ? 1 : -1;
        case EventType.Meeting:
          return 1;
      }
    }
    return aEnd.isBefore(bEnd) ? -1 : 1;
  }
  return aStart.isBefore(bStart) ? -1 : 1;
};
