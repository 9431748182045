import { isNil, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import type { ApiResponse } from "../../../services/api/api.definition";
import type { Conference } from "../../../services/conference/conference.model";
import type { Presentation } from "../../../services/presentation/presentation.model";
import type { Agenda } from "../../../services/user/agenda/agenda.model";
import AgendaService from "../../../services/user/agenda/agenda.service";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import type { AgendaHookModel, AgendaHookProps } from "./useAgenda.definition";

export const useAgenda = (props: AgendaHookProps): AgendaHookModel => {
  const { autoFetchData: autoFetchDataProp, data, conferenceId } = props;

  const [agenda, setAgenda] = useState<Agenda>();
  const [loading, setLoading] = useState(false);
  const agendaService = useRef(new AgendaService());
  const notificationService = useRef(new NotificationService());

  const autoFetchData = useMemo(() => (isNil(autoFetchDataProp) ? true : autoFetchDataProp), [autoFetchDataProp]);

  const _handleAgendaGetResponse = useCallback((response: ApiResponse<Agenda>): Agenda => {
    setLoading(false);

    if (!response.success) {
      notificationService.current.error("Failed to load agenda.");
      return null;
    }

    const agenda = response?.data;

    setAgenda(agenda);
    return agenda;
  }, []);

  const _handleAgendaPutPostResponse = useCallback((response): boolean => {
    setLoading(false);

    if (!response.success) {
      notificationService.current.error(response.message);
      return null;
    }

    notificationService.current.success("Agenda was updated successfully.");

    return response.success;
  }, []);

  const getAgendaByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Agenda> => {
      setLoading(true);

      return agendaService.current.getAgendaByConferenceId(_id).then(_handleAgendaGetResponse);
    },
    [_handleAgendaGetResponse]
  );

  const handleAgendaPost = useCallback(
    (conferenceId: Conference["_id"], presentationId: Presentation["_id"]): Promise<boolean> => {
      setLoading(true);

      return agendaService.current
        .postToAgendaByPresentationId(conferenceId, presentationId)
        .then(_handleAgendaPutPostResponse);
    },
    [_handleAgendaPutPostResponse]
  );

  const handleAgendaDelete = useCallback(
    (conferenceId: Conference["_id"], presentationId: Presentation["_id"]): Promise<boolean> => {
      setLoading(true);

      return agendaService.current
        .deleteFromAgendaByPresentationId(conferenceId, presentationId)
        .then(_handleAgendaPutPostResponse);
    },
    [_handleAgendaPutPostResponse]
  );

  useAutoFetch({
    autoFetchData,
    autoFetchParam: conferenceId,
    param: conferenceId,
    data,
    fetchBy: getAgendaByConferenceId,
    setEntity: setAgenda,
  });

  return {
    agenda,
    loading,
    fetchAgendaByConferenceId: getAgendaByConferenceId,
    postToAgendaByPresentationId: handleAgendaPost,
    deleteFromAgendaByPresentationId: handleAgendaDelete,
  };
};
