import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import jwt from "jwt-decode";

export class AuthSignInOptions {
  constructor(public redirect?: string) {}
}

export interface Auth0ServiceBase {
  AuthToken: Auth0Base;
  signIn: (options?: AuthSignInOptions) => void;
}

export abstract class Auth0Base {
  protected _idToken: string;
  protected _expiresAt: number;
  protected _profile: Auth0Token;

  get ExpiresAt(): number {
    return this._expiresAt;
  }
  get IdToken(): string {
    return this._idToken;
  }
  get Profile(): Auth0Token {
    return this._profile;
  }

  constructor(auth0Base?: Partial<Auth0Base>) {
    Object.assign(this, auth0Base);
  }

  protected parseProfile(idToken: Auth0Base["IdToken"]): Auth0Token {
    let profile: Auth0Token;

    try {
      profile = jwt(idToken);
    } catch (e) {
      console.error(e.message);
      return null;
    }

    if (isNullOrWhiteSpace(profile?.email)) {
      console.error("Error parsing token.");
      return null;
    }

    return profile;
  }

  protected getExpiredAt(expires: number): number {
    return expires * 1000;
  }
}

export interface Auth0Model {
  ExpiresAt: number;
  IdToken: string;
  Profile: Auth0Token;
  init: (callbackUrl: string) => void | string;
}

export interface Auth0Token {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  nonce: string;
  picture: string;
  sub: string;
  updated_at: string;
  company: string;
  conferencePath: string;
  conferenceId: string;
  // TODO: type this better
  // this type is for `${config.app.url}/q4companyId`
  [x: string]: string | number | boolean;
}
