import { Message, MessageType } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { getExistingMeetingMessage } from "../../views/admin/conferences/details/components/meetingScheduler/meetingScheduler.utils";
import { MeetingExistsMessageIdModel, MeetingExistsMessageProps } from "./meetingExistsMessage.definition";

const MeetingExistsMessage = (props: MeetingExistsMessageProps): JSX.Element => {
  const { id, visible, saving, disabled, meeting, timeZone, onCancel, onConfirm, onClose } = props;

  const idModel = useMemo(() => new MeetingExistsMessageIdModel(id), [id]);

  const message = useMemo(() => getExistingMeetingMessage(meeting, timeZone), [meeting, timeZone]);

  return (
    <Message
      id={idModel?.id}
      visible={visible}
      messageType={MessageType.Confirmation}
      title="Attention"
      message={message}
      secondaryActionProps={{
        id: idModel.secondaryButton?.id,
        disabled: disabled,
        label: "Cancel",
        onClick: onCancel,
      }}
      primaryActionProps={{
        id: idModel.primaryButton?.id,
        label: "Save Meeting",
        loading: saving,
        disabled: disabled,
        onClick: onConfirm,
      }}
      onCloseRequest={onClose}
    />
  );
};

export default memo(MeetingExistsMessage);
