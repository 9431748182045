import { ButtonIdModel, FormIdModel, IdModelBase, isNullOrWhiteSpace, ModalIdModel, ModalProps } from "@q4/nimbus-ui";
import { MeetingFormProps } from "../../../../../../../../components/meetingForm/meetingForm.definition";

export const ModalContentHeight = 380;

export interface MeetingModalProps extends ModalProps {
  meetingFormProps: MeetingFormProps;
}

export class MeetingModalIdModel extends IdModelBase {
  modal: ModalIdModel;
  modalForm: FormIdModel;
  modalDelete: ButtonIdModel;
  modalCancel: ButtonIdModel;
  modalSave: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.modalForm = new FormIdModel(`${this.id}ModalForm`);
    this.modalDelete = new ButtonIdModel(`${this.id}ModalDeleteButton`);
    this.modalCancel = new ButtonIdModel(`${this.id}ModalCancelButton`);
    this.modalSave = new ButtonIdModel(`${this.id}ModalSaveButton`);
  }
}
