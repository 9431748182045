import "./timeSlotView.component.scss";
import { Button, ButtonTheme, getClassName, isNullOrWhiteSpace, Swapable } from "@q4/nimbus-ui";
import { Moment } from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { TimeFormat } from "../../../../../../../../definitions/date.definition";
import TimeSlotEdit from "../timeSlotEdit/timeSlotEdit.component";
import { TimeSlotViewProps, TimeSlotViewIdModel, TimeSlotViewClassName } from "./timeSlotView.definition";

const TimeSlotView = (props: TimeSlotViewProps): JSX.Element => {
  const { id, className, startTime, endTime, isSaving, editMode, setEditMode, onSubmit, deleteSlot, error } = props;

  const [viewIndex, setViewIndex] = useState(0);

  const idModel = useMemo(() => new TimeSlotViewIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(TimeSlotViewClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
        {
          condition: error?.visible,
          trueClassName: TimeSlotViewClassName.BaseWithConflictModifier,
        },
      ]),
    [className, error?.visible]
  );

  const resetView = useCallback(() => {
    setViewIndex(0);
    setEditMode(false);
  }, [setEditMode]);

  const handleCancelClick = useCallback(() => {
    resetView();
  }, [resetView]);

  const handleSubmitClick = useCallback(
    async (start: Moment, end: Moment) => {
      onSubmit(start, end).finally(() => {
        resetView();
      });
    },
    [onSubmit, resetView]
  );

  const handleEditClick = useCallback(() => {
    setViewIndex(1);
    setEditMode(true);
  }, [setEditMode]);

  const renderView = useMemo(() => {
    return (
      <div className={TimeSlotViewClassName.Controls}>
        <div id={idModel.text} className={TimeSlotViewClassName.Text}>
          {`${startTime.clone().format(TimeFormat.Picker)} - ${endTime.clone().format(TimeFormat.Picker)}`}
        </div>
        {error?.visible && (
          <div className={TimeSlotViewClassName.Error}>
            <i className="nui-field_error-indicator ni-warning-4pt"></i>
            <span>{error.message}</span>
          </div>
        )}
        <div className={TimeSlotViewClassName.Buttons}>
          <Button
            id={idModel.deleteButton.id}
            icon="q4i-trashbin-4pt"
            theme={ButtonTheme.LightGrey}
            disabled={editMode}
            onClick={deleteSlot}
          />
          <Button
            id={idModel.editButton.id}
            icon="q4i-edit-4pt"
            theme={ButtonTheme.LightGrey}
            disabled={editMode}
            onClick={handleEditClick}
          />
        </div>
      </div>
    );
  }, [idModel, startTime, endTime, error?.visible, error.message, editMode, deleteSlot, handleEditClick]);

  const renderEdit = useMemo(() => {
    return (
      <TimeSlotEdit
        id={idModel.timeslotEdit?.id}
        startTime={startTime}
        endTime={endTime}
        isSaving={isSaving}
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
      />
    );
  }, [endTime, handleCancelClick, handleSubmitClick, idModel.timeslotEdit?.id, isSaving, startTime]);

  return (
    <div id={idModel.id} className={baseClassName}>
      <Swapable selected={viewIndex} layers={[renderView, renderEdit]} />
    </div>
  );
};

export default memo(TimeSlotView);
