import { CheckboxIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { QuestionsFormChildrenProps } from "../../questionsForm.definition";

export type QuestionsFormConsentProps = QuestionsFormChildrenProps;

export class QuestionsFormConsentIdModel extends IdModelBase {
  checkbox: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.checkbox = new CheckboxIdModel(id);
  }
}
