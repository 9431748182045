import React from "react";
import { Redirect } from "react-router";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { AuthType } from "../../../services/api/api.definition";
import { SessionEventType } from "../../../services/session/session.model";
import { isAdminRoute } from "../../../utils";
import CompanyLobbySession from "../../company/lobby/session/companySession.view";
import Registration from "../../public/register/registration.view";
import PrivateRoute from "./components/privateRoute/privateRoute.component";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import type { RouteProps } from "./route.definition";

const CustomRoutes = (props: RouteProps): JSX.Element[] => {
  const { userContext } = props;

  if (isAdminRoute()) return [];

  return [
    <PublicRoute
      key="custom_registration--conference-id"
      exact
      path={RoutePath.CustomRegistrationWithConferenceId}
      userContext={userContext}
      component={Registration}
    />,
    <PrivateRoute
      key="custom_lobby-meeting--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceCustomCompanyMeetingWithId}
      component={() => <CompanyLobbySession session={SessionEventType.Meeting} />}
    />,
    <PrivateRoute
      key="conference_lobby-meeting--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceMeetingWithId}
      component={() => <CompanyLobbySession session={SessionEventType.Meeting} />}
    />,
    <Redirect
      exact
      key="custom_lobby-session--redirect"
      from={RoutePath.ConferenceCustomCompanyMeeting}
      to={RoutePath.ConferenceLobbyWithCustomCompany}
    />,
    <PrivateRoute
      key="custom_lobby-ondemand--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceCustomCompanyOnDemandWithId}
      component={() => <CompanyLobbySession session={SessionEventType.OnDemandPresentation} />}
    />,
    <PrivateRoute
      key="conference_lobby-ondemand--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceOnDemandWithId}
      component={() => <CompanyLobbySession session={SessionEventType.OnDemandPresentation} />}
    />,
    <Redirect
      exact
      key="custom_lobby-session--redirect"
      from={RoutePath.ConferenceCustomCompanyOnDemand}
      to={RoutePath.ConferenceLobbyWithCustomCompany}
    />,
  ];
};

export default CustomRoutes;
