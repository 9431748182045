import {
  ButtonIdModel,
  CheckboxIdModel,
  ComboBoxIdModel,
  ErrorModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextboxIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import type { AdminUserContextState } from "../../../contexts/adminUser/adminUser.definition";
import { Answer } from "../../../services/answer/answer.model";
import type { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import type { Conference } from "../../../services/conference/conference.model";
import type { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import type { QuestionGroup } from "../../../services/questionGroup/questionGroup.model";
import { MeetingRequestFormIdModel } from "../../../views/public/register/components/meetingRequestFormV2/meetingRequestForm.definition";
import { QuestionsFormIdModel } from "../../questionsForm/questionsForm.definition";

export const CreateNewCompanySuffix = " (create new)";

export enum AttendeeFormClassName {
  Base = "attendee-form",
  QuestionsHeader = "attendee-form_questions-header",
  GeneratePassword = "attendee-form_generate-password",
}

export interface AttendeeFormProps extends BaseComponentProps {
  attendee: AttendeeViewModel;
  companies?: string[];
  codes: string[];
  corporateProfiles: CorporateProfile[];
  participateInMeetings?: boolean;
  questionGroups?: QuestionGroup[];
  onAttendeeUpdate: (data: Partial<AttendeeViewModel>) => void;
  onGeneratePassword?: () => void;
  attendeeAnswers?: Answer[];
  conference: Conference;
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  isSystemAdmin?: AdminUserContextState["isSystemAdmin"];
  loading?: boolean;
}

export class AttendeeFormIdModel extends IdModelBase {
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  company: SelectIdModel;
  title: TextboxIdModel;
  phone: TextboxIdModel;
  type: SelectIdModel;
  investorType: SelectIdModel;
  status: SelectIdModel;
  code: ComboBoxIdModel;
  email: TextboxIdModel;
  representative: TextboxIdModel;
  guestAttendeeType: SelectIdModel;
  allowLobbyPreview: CheckboxIdModel;
  participateInMeetings: CheckboxIdModel;
  attendeeForm: FormIdModel;
  questionsForm: QuestionsFormIdModel;
  questionsHeader: TextIdModel;
  generatePassword: ButtonIdModel;
  meetingRequestForm: MeetingRequestFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.attendeeForm = new FormIdModel(`${this.id}AttendeeForm`);
    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.company = new SelectIdModel(`${this.id}CompanySelect`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.phone = new TextboxIdModel(`${this.id}PhoneTextbox`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.investorType = new SelectIdModel(`${this.id}InvestorTypeSelect`);
    this.status = new SelectIdModel(`${this.id}StatusSelect`);
    this.code = new ComboBoxIdModel(`${this.id}CodeCombobox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.representative = new TextboxIdModel(`${this.id}RepresentativeTextbox`);
    this.guestAttendeeType = new SelectIdModel(`${this.id}GuestAttendeeType`);
    this.allowLobbyPreview = new CheckboxIdModel(`${this.id}AllowLobbyPreviewCheckbox`);
    this.participateInMeetings = new CheckboxIdModel(`${this.id}ParticipateInMeetingsCheckbox`);
    this.questionsForm = new QuestionsFormIdModel(`${this.id}QuestionsForm`);
    this.questionsHeader = new TextIdModel(`${this.id}QuestionsHeader`);
    this.generatePassword = new ButtonIdModel(`${this.id}GeneratePasswordButton`);
    this.meetingRequestForm = new MeetingRequestFormIdModel(`${this.id}MeetingRequestForm`);
  }
}
