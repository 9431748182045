import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { Entity } from "../../definitions/entity.definition";
import { Answer } from "../../services/answer/answer.model";
import AnswerService from "../../services/answer/answer.service";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import { useService } from "../useService/useService.hook";
import { AnswerHookModel, AnswerHookProps } from "./useAnswers.definition";

export const useAnswers = (props: AnswerHookProps): AnswerHookModel => {
  const { entity, id, autoFetchData, data, showNotifications } = props;

  const { items, loading, service, setItems, setLoading } = useService({
    autoFetchData: false,
    data,
    entityName: Entity.Answer,
    showNotifications,
    entityModel: Answer,
    serviceModel: AnswerService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const handleArrayResponse = useCallback(
    (answers: Answer[]): Answer[] => {
      const mappedAnswers = (answers || []).map((answer) => new Answer(answer));
      setItems(mappedAnswers);
      return mappedAnswers;
    },
    [setItems]
  );

  const getById = useCallback(
    (_id: AnswerHookProps["id"]): Promise<Answer[]> => {
      setLoading(true);

      const getAnswers =
        entity === Entity.CorporateProfile ? service.getByCorporateProfileId(_id) : service.getByAttendeeId(_id);

      return getAnswers
        .then((response): Answer[] => {
          if (!response.success) {
            showNotifications && notificationService.error("Failed to load answers.");
            return null;
          }

          return response?.data ?? [];
        })
        .then(handleArrayResponse)
        .finally(() => setLoading(false));
    },
    [setLoading, entity, service, handleArrayResponse, showNotifications, notificationService]
  );

  const resetFetch = useCallback(() => {
    setItems([]);
    return Promise.resolve([]);
  }, [setItems]);

  useAutoFetch({
    autoFetchData,
    data,
    param: id,
    fetch: resetFetch,
    fetchBy: getById,
    setEntities: setItems,
  });

  return {
    loading,
    items,
    getById,
  };
};
