import { isEmpty } from "@q4/nimbus-ui";
import type { BrandingBase } from "../../components/companyBranding/companyBranding.definition";
import { EntityBase } from "../../definitions/entity.definition";

export class Company extends EntityBase {
  name: string;
  website?: string;
  url_suffix: string;
  custom_domain?: string;
  custom_path?: string;
  branding: CompanyBranding;

  constructor(company: Partial<Company> | string) {
    super(company);
    if (typeof company === "string" || isEmpty(company)) return;

    Object.assign(this, company);
  }
}

export type CompanyRouteParams = Pick<Company, "url_suffix" | "custom_path">;

export interface CompanyBranding extends BrandingBase {
  logo?: string;
  background?: string;
  text_login_1?: string;
  text_login_2?: string;
}
