import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { EntityBase, EntityModel } from "../../definitions/entity.definition";
import { ApiResponse, OfflineApiServiceKey } from "../api/api.definition";
import ApiService from "../api/api.service";
import { mapApiResponse, mapArrayedApiResponse } from "./serviceBase.utils";

export const AdminApiVersion = "v1";

export interface ServiceBase<TEntity, TPutResponse = TEntity> {
  ApiPathBase: string;
  get?: (offline?: boolean) => Promise<ApiResponse<TEntity[]>>;
  getById?: (id: EntityBase["_id"], offline?: boolean) => Promise<ApiResponse<TEntity>>;
  delete?: (id: EntityBase["_id"]) => Promise<ApiResponse<TEntity>>;
  post?: (data: TEntity) => Promise<ApiResponse<TEntity>>;
  put?: (id: EntityBase["_id"], data: TEntity) => Promise<ApiResponse<TPutResponse>>;
  getOfflineData?: (key: OfflineApiServiceKey) => Promise<ApiResponse<TEntity[]>>;
}

export abstract class Service<T> implements ServiceBase<T> {
  protected readonly _apiService = new ApiService();
  protected _routeBase: string;
  protected _servicePath: string;
  protected _version?: string;
  private _entityModel: EntityModel<T>;

  get ApiPathBase(): string {
    if (isNullOrWhiteSpace(this._routeBase) || isNullOrWhiteSpace(this._servicePath)) return null;
    const version = isNullOrWhiteSpace(this._version) ? "" : `/${this._version}`;
    return `${this._routeBase}/${this._servicePath}${version}`;
  }

  constructor(routeBase: string, servicePath: string, entityModel: EntityModel<T>, version?: string) {
    this._routeBase = routeBase;
    this._servicePath = servicePath;
    this._entityModel = entityModel;
    this._version = isNullOrWhiteSpace(version) ? "" : version;
  }

  getOfflineData = (key: OfflineApiServiceKey): Promise<ApiResponse<T[]>> => {
    return this._apiService.getOfflineData<ApiResponse<T[]>>(key).then(this.mapArrayedResponse);
  };

  public get(): Promise<ApiResponse<T[]>> {
    return this._apiService.get<T[]>(this.ApiPathBase).then(this.mapArrayedResponse);
  }

  public delete(id: EntityBase["_id"]): Promise<ApiResponse<T>> {
    return this._apiService.delete<null, T>(`${this.ApiPathBase}/${id}`).then(this.mapResponse);
  }

  public post(data: T): Promise<ApiResponse<T>> {
    return this._apiService.post<T>(this.ApiPathBase, data).then(this.mapResponse);
  }

  public put(id: EntityBase["_id"], data: T): Promise<ApiResponse<T>> {
    return this._apiService.put<T>(`${this.ApiPathBase}/${id}`, data).then(this.mapResponse);
  }

  protected mapResponse = (response: ApiResponse<T>): ApiResponse<T> => {
    return mapApiResponse(response, this._entityModel);
  };

  protected mapArrayedResponse = (response: ApiResponse<T[]>): ApiResponse<T[]> => {
    return mapArrayedApiResponse(response, this._entityModel);
  };
}

export interface ReCaptchaBase {
  "g-recaptcha-response": string;
}
