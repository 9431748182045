import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import type { Company } from "../../../../../services/company/company.model";

export interface CompanyFormProps extends BaseComponentProps {
  company: Company;
  onCompanyUpdate: (data: Partial<Company>) => void;
}

export enum CompanyFormClassName {
  Base = "company-form",
}

export class CompanyFormIdModel extends IdModelBase {
  name: TextboxIdModel;
  website: TextboxIdModel;
  url_suffx: TextboxIdModel;
  custom_domain: TextboxIdModel;
  custom_path: TextboxIdModel;
  logo?: string;
  background?: string;
  text_login_1?: TextboxIdModel;
  text_login_2?: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.name = new TextboxIdModel(`${this.id}NameTextbox`);
    this.website = new TextboxIdModel(`${this.id}WebsiteTextbox`);
    this.url_suffx = new TextboxIdModel(`${this.id}UrlSuffixTextbox`);
    this.custom_domain = new TextboxIdModel(`${this.id}CustomDomainTextbox`);
    this.custom_path = new TextboxIdModel(`${this.id}CustomPathTextbox`);
    this.logo = `${this.id}LogoImage`;
    this.background = `${this.id}BackgroundImage`;
    this.text_login_1 = new TextboxIdModel(`${this.id}Login1Textbox`);
    this.text_login_2 = new TextboxIdModel(`${this.id}Login2Textbox`);
  }
}
