import "./exportAgendaModal.component.scss";
import {
  ButtonSize,
  ButtonTheme,
  convertIntToEnum,
  Form,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Message,
  MessageType,
  Modal,
  ModalProps,
  Swapable,
  ToggleButtons,
  ToggleButtonsItemProps,
  ToggleButtonsTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import RequestProgressStatus from "../../../../../../../../components/requestProgressStatus/components/progress/requestProgressStatus.component";
import { RequestProgressStatusName } from "../../../../../../../../components/requestProgressStatus/components/progress/requestProgressStatus.definition";
import { AttendeeType, AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import { getAttendeeMeetingsAndPresentations } from "../../../../../../../../utils";
import { ExportAgendaItem } from "../exportAgenda/exportAgenda.definition";
import {
  ExportAgendaModalProps,
  ExportAgendaModalIdModel,
  ExportAgendaModalType,
  ExportAgendaModalStep,
  ExportAgendaModalClassName,
} from "./exportAgendaModal.definition";

const ExportAgendaModal = (props: ExportAgendaModalProps): JSX.Element => {
  const {
    id,
    attendees,
    meetings,
    presentations,
    currentCount,
    disabled,
    exporting,
    results,
    visible,
    onCancel,
    onExport,
    onReset,
  } = props;

  const idModel = useMemo(() => new ExportAgendaModalIdModel(id), [id]);

  const [exportType, setExportType] = useState<ExportAgendaModalType>(null);
  const [currentStep, setCurrentStep] = useState<ExportAgendaModalStep>(ExportAgendaModalStep.Settings);

  const [messageVisible, handleMessageOpen, handleMessageClose] = useVisibility();

  const disable = useMemo(() => exporting || disabled, [disabled, exporting]);

  const exportTypeOptions = useMemo(
    (): ToggleButtonsItemProps[] =>
      Object.keys(ExportAgendaModalType)
        .filter((x) => isNaN(Number(x)))
        .map((y) => ({ key: y, label: y, value: y })),
    []
  );

  const getAttendeeEnumType = useCallback((): AttendeeType => {
    switch (exportType) {
      case ExportAgendaModalType.Corporate:
        return AttendeeType.Corporate;
      case ExportAgendaModalType.Investor:
        return AttendeeType.Investor;
      case ExportAgendaModalType.Internal:
        return AttendeeType.Internal;
      default:
        return null;
    }
  }, [exportType]);

  const exportableAttendees = useMemo(() => {
    const selectedType = getAttendeeEnumType();

    return (attendees || []).reduce((exportable, x) => {
      if (isEmpty(x)) return exportable;

      const { meetings: userMeetings, presentations: userPresentations } =
        getAttendeeMeetingsAndPresentations(x._id, meetings, presentations) || {};

      if (isEmpty(userPresentations) && isEmpty(userMeetings)) return exportable;

      if (isNullOrWhiteSpace(selectedType) && x.type !== AttendeeType.Guest) {
        exportable.push({
          entity: x,
          title: x.friendly_name,
          meetings: userMeetings,
          presentations: userPresentations,
        });
      }

      if (!isNullOrWhiteSpace(selectedType) && x.type === selectedType) {
        exportable.push({
          entity: x,
          title: x.friendly_name,
          meetings: userMeetings,
          presentations: userPresentations,
        });
      }

      return exportable;
    }, [] as ExportAgendaItem<AttendeeViewModel>[]);
  }, [attendees, getAttendeeEnumType, meetings, presentations]);

  // #region Handlers
  const handleCancelClick = useCallback((): void => {
    setCurrentStep(ExportAgendaModalStep.Settings);
    onReset();
  }, [onReset]);

  const handleExportClick = useCallback((): void => {
    setCurrentStep(ExportAgendaModalStep.Exporting);
    onExport(exportableAttendees);
  }, [exportableAttendees, onExport]);

  const handleExportCancelClick = useCallback((): void => {
    onCancel();
    handleMessageClose();
    setCurrentStep(ExportAgendaModalStep.Settings);
  }, [handleMessageClose, onCancel]);

  function handleExportTypeChange(value: number) {
    setExportType(convertIntToEnum(ExportAgendaModalType, value));
  }
  // #endregion

  const modalProps = useMemo((): Partial<ModalProps> => {
    if (currentStep === ExportAgendaModalStep.Settings) {
      return {
        title: "Settings",
        subtitle: <span>Please confirm which attendee type should be exported.</span>,
        footerActions: [
          {
            id: idModel.cancelButton.id,
            key: "export-modal-cancel",
            theme: ButtonTheme.DarkSlate,
            label: "CANCEL",
            onClick: !exporting ? handleCancelClick : handleMessageOpen,
          },
          {
            id: idModel.exportButton.id,
            key: "export-modal-confirm",
            theme: ButtonTheme.Citrus,
            label: "EXPORT PDFs",
            loading: disable,
            disabled: disable || isNil(exportType),
            onClick: handleExportClick,
          },
        ],
      };
    }

    return {
      title: "Export",
      subtitle: "",
      footerActions: [
        {
          id: idModel.cancelButton.id,
          key: "export-modal-cancel",
          theme: ButtonTheme.DarkSlate,
          label: "CANCEL",
          onClick: !exporting ? handleCancelClick : handleMessageOpen,
        },
        {
          id: idModel.exportButton.id,
          key: "export-modal-confirm",
          theme: ButtonTheme.Citrus,
          label: "DONE",
          loading: disable,
          disabled: disable || isNil(exportType),
          onClick: handleCancelClick,
        },
      ],
    };
  }, [currentStep, disable, exportType, exporting, handleExportClick, handleMessageOpen, handleCancelClick, idModel]);

  return (
    <div className={ExportAgendaModalClassName.Base} id={idModel?.id}>
      <Modal
        id={idModel?.modal?.id}
        title={modalProps.title}
        subtitle={modalProps.subtitle}
        footerActions={modalProps.footerActions}
        visible={visible}
        includeExit={false}
        disableMaskClickToClose={true}
      >
        <Swapable
          selected={currentStep}
          layers={[
            <Form
              key="form"
              fields={[
                {
                  key: "Attendee Type",
                  width: "1-of-1",
                  smallWidth: "1-of-1",
                  label: "Attendee Type",
                  children: (
                    <ToggleButtons
                      id={idModel.toggleButtons.id}
                      key="export-agenda_toggle"
                      theme={ToggleButtonsTheme.LightGrey}
                      size={ButtonSize.Large}
                      onChange={handleExportTypeChange}
                      selected={exportType}
                      items={exportTypeOptions}
                    />
                  ),
                },
              ]}
            />,
            <RequestProgressStatus
              id={idModel.exportAgendaProgress.id}
              key="export-agenda_progress"
              results={results}
              inProgress={exporting}
              totalCount={exportableAttendees?.length ?? 0}
              currentCount={currentCount ?? 0}
              statusText={RequestProgressStatusName.Exporting}
              showCsvExport={false}
              showStatusTable={false}
            />,
          ]}
        />
      </Modal>
      <Message
        visible={messageVisible}
        title="Cancel Export"
        message="Are you sure you want to cancel exporting?"
        messageType={MessageType.Warning}
        primaryActionProps={{
          label: "CANCEL",
          onClick: handleExportCancelClick,
        }}
        secondaryActionProps={{
          label: "RESUME",
          onClick: handleMessageClose,
        }}
        onCloseRequest={handleMessageClose}
      />
    </div>
  );
};

export default memo(ExportAgendaModal);
