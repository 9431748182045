export const ZoomDialInformationLink = "https://zoom.us/zoomconference?m=MjkxODY5NDE2NQ.6nUnDzeMYTFLdVgzEUsK0cTB4K-VmSv3";
export const MsTeamsDialInformationLink = "https://dialin.teams.microsoft.com/d4ab2276-edcc-4e7d-9d2e-ac5dbb1f2242";

export type DialInRecords = { country: string; numbers: string[] }[][];
export const ZoomDialInNumbers: DialInRecords = [
  [
    {
      country: "US",
      numbers: [
        "+1 470 250 9358 Atlanta",
        "+1 470 381 2552 Atlanta",
        "+1 312 626 6799 Chicago",
        "+1 720 928 9299 Denver",
        "+1 346 248 7799 Houston",
        "+1 213 338 8477 Los Angeles",
        "+1 786 635 1003 Miami",
        "+1 651 372 8299 Minnesota",
        "+1 646 518 9805 New York",
        "+1 929 436 2866 New York",
        "+1 267 831 0333 Philadelphia",
        "+1 602 753 0140 Phoenix",
        "+1 971 247 1195 Portland",
        "+1 669 219 2599 San Jose",
        "+1 669 900 6833 San Jose",
        "+1 206 337 9723 Seattle",
        "+1 253 215 8782 Tacoma",
        "+1 301 715 8592 Washington",
      ],
    },
  ],
  [
    {
      country: "Canada",
      numbers: [
        "+1 587 328 1099",
        "+1 613 209 3054",
        "+1 647 374 4685",
        "+1 647 558 0588",
        "+1 778 907 2071",
        "+1 204 272 7920",
        "+1 438 809 7799",
      ],
    },
    {
      country: "United Kingdom",
      numbers: [
        "+44 203 481 5240",
        "+44 203 901 7895",
        "+44 208 080 6591",
        "+44 208 080 6592",
        "+44 330 088 5830",
        "+44 131 460 1196",
        "+44 203 481 5237",
      ],
    },
  ],
  [
    {
      country: "France",
      numbers: ["+33 1 7095 0103", "+33 1 7095 0350", "+33 1 8699 5831", "+33 1 7037 2246", "+33 1 7037 9729"],
    },
    {
      country: "Ireland",
      numbers: [
        "+353 1 653 3898",
        "+353 6 163 9031",
        "+353 1 240 8941",
        "+353 1 536 9320",
        "+353 1 653 3895",
        "+353 1 653 3897",
      ],
    },
  ],
  [
    {
      country: "Germany",
      numbers: ["+49 695 050 2596", "+49 69 7104 9922", "+49 30 5679 5800", "+49 69 3807 9883"],
    },
    {
      country: "Netherlands",
      numbers: [
        "+31 20 794 7345",
        "+31 707 006 526",
        "+31 20 241 0288",
        "+31 20 794 0854",
        "+31 20 794 6519",
        "+31 20 794 6520",
      ],
    },
  ],
  [
    {
      country: "New Zealand",
      numbers: ["+64 4 886 0026", "+64 9 884 6780", "+64 3 659 0603"],
    },
    {
      country: "Switzerland",
      numbers: [
        "+41 43 210 71 08",
        "+41 44 529 92 72",
        "+41 22 591 00 05",
        "+41 22 591 01 56",
        "+41 31 528 09 88",
        "+41 43 210 70 42",
      ],
    },
  ],
];

export const MsTeamsDialInNumbers: DialInRecords = [
  [
    { country: "Argentina", numbers: ["+54 11 3989-2039"] },
    { country: "Australia", numbers: ["+61 2 7208 4916"] },
    { country: "Austria", numbers: ["+43 1 2057735510"] },
    { country: "Belgium", numbers: ["+32 2 897 92 52"] },
    { country: "Brazil", numbers: ["+55 11 3181-7069"] },
    { country: "Bulgaria", numbers: ["+359 2 495 5095"] },
    { country: "Canada", numbers: ["+1 647-794-5625"] },
    { country: "China", numbers: ["+86 400 819 0875", "+86 400 819 0881"] },
    { country: "Colombia", numbers: ["+57 1 7940902"] },
    { country: "Croatia", numbers: ["+385 1 3445 369"] },
    { country: "Cyprus", numbers: ["+357 22 055650"] },
    { country: "Czech Republic", numbers: ["+420 296 849 220"] },
    { country: "Denmark", numbers: ["+45 32 74 21 75"] },
  ],
  [
    { country: "Dominican Republic", numbers: ["+1 849-945-0186"] },
    { country: "El Salvador", numbers: ["+503 2136 7305"] },
    { country: "Estonia", numbers: ["+372 674 3642"] },
    { country: "Finland", numbers: ["+358 9 85626547"] },
    { country: "France", numbers: ["+33 1 78 96 53 87"] },
    { country: "Georgia", numbers: ["+995 706 777 808"] },
    { country: "Germany", numbers: ["+49 69 566081288"] },
    { country: "Greece", numbers: ["+30 21 1199 0516"] },
    { country: "Hong Kong", numbers: ["+852 3008 6163", "+852 3008 6166"] },
    { country: "Hungary", numbers: ["+36 1 755 5342"] },
    { country: "India", numbers: ["+91 22 6259 1206"] },
    { country: "Ireland", numbers: ["+353 1 592 3693"] },
    { country: "Israel", numbers: ["+972 3-376-7661"] },
  ],
  [
    { country: "Italy", numbers: ["+39 02 1241 22526"] },
    { country: "Japan", numbers: ["+81 3-4571-2461"] },
    { country: "Korea", numbers: ["+82 70-7488-2094"] },
    { country: "Latvia", numbers: ["+371 65 189 321"] },
    { country: "Lithuania", numbers: ["+370 5 205 0900"] },
    { country: "Luxembourg", numbers: ["+352 27 87 16 49"] },
    { country: "Malaysia", numbers: ["+60 3-9078 4161"] },
    { country: "Malta", numbers: ["+356 2792 2942"] },
    { country: "Mexico", numbers: ["+52 55 8959 1117", "+52 55 8959 1118"] },
    { country: "Netherlands", numbers: ["+31 20 399 5589"] },
    { country: "New Zealand", numbers: ["+64 4-889 8110"] },
    { country: "Norway", numbers: ["+47 21 40 24 78"] },
  ],
  [
    { country: "Pakistan", numbers: ["+92 21 38799959"] },
    { country: "Panama", numbers: ["+507 836-6800"] },
    { country: "Peru", numbers: ["+51 1 7076593"] },
    { country: "Philippines", numbers: ["+63 286396682"] },
    { country: "Poland", numbers: ["+48 22 536 41 72"] },
    { country: "Portugal", numbers: ["+351 21 123 2681"] },
    { country: "Puerto Rico", numbers: ["+1 939-293-2967"] },
    { country: "Romania", numbers: ["+40 21 589 2661"] },
    { country: "Russia", numbers: ["+7 499 961-98-74"] },
    { country: "Singapore", numbers: ["+65 3157 8807", "+65 3157 8809"] },
    { country: "Slovakia", numbers: ["+421 2/333 298 39"] },
    { country: "Slovenia", numbers: ["+386 1 828 14 88"] },
  ],
  [
    { country: "South Africa", numbers: ["+27 21 835 5184"] },
    { country: "Spain", numbers: ["+34 919 17 67 04"] },
    { country: "Sri Lanka", numbers: ["+94 78 999 8357"] },
    { country: "Sweden", numbers: ["+46 8 502 439 49"] },
    { country: "Switzerland", numbers: ["+41 43 216 38 80"] },
    { country: "Taiwan", numbers: ["+886 2 5594 0385", "+886 2 5594 0387"] },
    { country: "Thailand", numbers: ["+66 2 028 1563"] },
    { country: "Turkey", numbers: ["+90 212 900 58 56"] },
    { country: "Ukraine", numbers: ["+380 89 324 0838"] },
    { country: "United Kingdom", numbers: ["+44 20 4526 8565"] },
    {
      country: "United States",
      numbers: [
        "+1 872-242-8083 Chicago",
        "+1 469-998-7386 Dallas",
        "+1 323-676-6197 Los Angeles",
        "+1 929-352-1686 New York",
      ],
    },
  ],
];
