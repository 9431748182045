import "./profile.view.scss";
import { isEmpty, LoadingScreen, NotificationService } from "@q4/nimbus-ui";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { JsonFileRecord } from "../../../components/jsonForm/jsonForm.definition";
import contextSubscribe from "../../../contexts/context.subscribe";
import { UserContext } from "../../../contexts/user/user.context";
import { usePublicQuestionGroups } from "../../../hooks/public/usePublicQuestionGroups/usePublicQuestionGroups";
import { RegistrantViewModel } from "../../../services/admin/registrant/registrant.model";
import {
  AttendeeType,
  attendeeViewModelToRegistrantViewModel,
  updateAttendeeViewModel,
  AttendeeViewModel,
} from "../../../services/attendee/attendee.model";
import { ConferenceType } from "../../../services/conference/conference.model";
import { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import DirtyService from "../../../services/dirty/dirty.service";
import ProfileService from "../../../services/user/profile/profile.service";
import {
  attendeeEqualityComparer,
  formatTimetableDataWithTimezone,
  getLobbyUrl,
  isCorporate,
  modifyAnswerData,
} from "../../../utils";
import { getLobbyOpen } from "../../company/lobby/companyLobby.utils";
import CompanyLobbyLayout from "../../company/lobby/components/layout/companyLobby.layout";
import { useLobby } from "../../company/lobby/hooks/useLobby/useLobby.hook";
import { ItineraryProps } from "../itinerary/itinerary.definition";
import { convertAnswersToContainQuestionDetails } from "../register/components/customQuestions/customQuestions.utils";
import { RegistrationHeader } from "../register/components/registrationHeader/registrationHeader.component";
import { useSections } from "../register/hooks/useSections.hook";
import { isInvestorMeetingRequestChangesOpen, uploadRegistrantFiles } from "../register/registration.utils";
import RegistrationSections from "../register/sections/sections.component";
import { AttendeeProfileClassName, ProfileIdModel as IdModel } from "./profile.definition";

const AttendeeProfileView = (props: ItineraryProps): JSX.Element => {
  const history = useHistory();

  const { userContext } = props;
  const { params, profile, useUserConferences } = useLobby({ userContext, useOffline: true });
  const { current: conference } = useUserConferences;
  const conferenceRouteParams = useMemo(
    () => ({
      url_suffix: params.company,
      custom_path: params.custom,
      conferencePath: params.conferencePath,
    }),
    [params]
  );
  const { current: questionGroup, loading: questionGroupInitialLoading } = usePublicQuestionGroups({
    conferenceId: conference?._id,
    attendeeType: profile?.type,
  });

  const { lobbyOpen } = useMemo(() => getLobbyOpen(conference, profile || ({} as AttendeeViewModel)), [conference, profile]);

  const notificationService = useRef(new NotificationService());
  const profileService = useRef(new ProfileService());
  const dirtyService = useRef(
    new DirtyService<RegistrantViewModel, RegistrantViewModel>(attendeeEqualityComparer, ["_conference"])
  );

  const [loading, setLoading] = useState(false);
  const [questionGroupLoading, setQuestionGroupLoading] = useState(true);
  const [fileState, setFileState] = useState<JsonFileRecord>(null);
  const [originalRegistrant, setOriginalRegistrant] = useState<RegistrantViewModel>();
  const [registrant, setRegistrant] = useState<RegistrantViewModel>(new RegistrantViewModel({}));
  const isGuestOtherTypesEnabled = useMemo(
    () => conference?.guest_attendee_types?.length > 0,
    [conference?.guest_attendee_types?.length]
  );

  const isFormDirty = useMemo(() => {
    const original = new RegistrantViewModel({
      ...originalRegistrant,
      availability: formatTimetableDataWithTimezone(originalRegistrant?.availability, conference?.time_zone),
    });
    const current = new RegistrantViewModel({
      ...registrant,
      availability: formatTimetableDataWithTimezone(registrant?.availability, conference?.time_zone),
    });
    dirtyService.current.checkForChanges("registrant", original, current);
    return dirtyService.current.isDirty();
  }, [originalRegistrant, conference?.time_zone, registrant]);

  useEffect(() => {
    if (isEmpty(profile)) return;
    // convert answer object to contain question details
    const updatedAnswerObject = convertAnswersToContainQuestionDetails(
      profile?.custom_question_answers,
      questionGroup?._questions
    );

    let corporateProfile = profile?._corporate_profile;
    if (isCorporate(profile) && !isEmpty(profile?._corporate_profile) && typeof profile?._corporate_profile !== "string") {
      corporateProfile = new CorporateProfile({
        ...profile._corporate_profile,
        attendees: (profile._corporate_profile.attendees || []).map(
          (attendee) =>
            new AttendeeViewModel({
              ...attendee,
              custom_question_answers: convertAnswersToContainQuestionDetails(
                attendee?.custom_question_answers,
                questionGroup?._questions
              ),
            })
        ),
      });
    }

    const updatedProfile = new AttendeeViewModel({
      ...profile,
      custom_question_answers: updatedAnswerObject,
      _corporate_profile: corporateProfile,
    });

    const mappedRegistrant = attendeeViewModelToRegistrantViewModel(updatedProfile);

    setOriginalRegistrant(mappedRegistrant);
    setRegistrant(mappedRegistrant);
  }, [profile, questionGroup?._questions]);

  useEffect(() => {
    if (!conference?._id || !originalRegistrant || registrant?.time_zone === originalRegistrant?.time_zone) return;

    profileService.current.setTimeZonePreference(conference._id, { time_zone: registrant?.time_zone });

    setOriginalRegistrant(
      (currentOriginal) => new RegistrantViewModel({ ...currentOriginal, time_zone: registrant?.time_zone })
    );
  }, [originalRegistrant, conference?._id, registrant?.time_zone]);

  useEffect(() => {
    // stop loading when registrant and profile data is configured with all questions
    if (registrant?.email && !isEmpty(profile) && !questionGroupInitialLoading) {
      setQuestionGroupLoading(false);
    }
  }, [registrant, profile, questionGroupInitialLoading]);

  const showMeetings = useMemo(() => {
    if (conference?.conference_type === ConferenceType.PresentationOnly) return false;
    if (registrant.attendee_type === AttendeeType.Investor) {
      return !isEmpty(registrant.meeting_requests) || isInvestorMeetingRequestChangesOpen(conference);
    }
    return true;
  }, [registrant, conference]);

  const showAvailability = useMemo(
    () =>
      conference?.conference_type === ConferenceType.FullConference || registrant?.attendee_type === AttendeeType.Corporate,
    [conference?.conference_type, registrant?.attendee_type]
  );

  const goToLobby = useCallback(() => {
    if (!lobbyOpen) return;
    const { url_suffix, custom_path, conferencePath } = conferenceRouteParams;
    const lobbyRoute = getLobbyUrl(url_suffix, custom_path, conferencePath);
    history.push(`${lobbyRoute}`);
  }, [conferenceRouteParams, history, lobbyOpen]);

  const handleRegistrantChange = useCallback(
    (updated: RegistrantViewModel): void => {
      setRegistrant(
        (current) =>
          new RegistrantViewModel({
            ...current,
            ...updated,
            _conference: conference,
          })
      );
    },
    [conference]
  );

  const handlePost = useCallback(async () => {
    setLoading(true);

    // TODO: Do something with the response. i.e. handle upload errors
    // TODO: Add recaptcha token per file upload
    if (!isEmpty(fileState)) {
      await uploadRegistrantFiles(registrant, fileState);
    }

    const updatedAttendee = updateAttendeeViewModel(profile, registrant);
    const isCorporate = updatedAttendee.type === AttendeeType.Corporate;
    const isInvestor = updatedAttendee.type === AttendeeType.Investor;

    updatedAttendee.custom_question_answers = modifyAnswerData(
      updatedAttendee?.custom_question_answers,
      questionGroup?._questions
    );

    // availability should be saved according to conference timezone
    if (isCorporate || isInvestor) {
      updatedAttendee.availability = formatTimetableDataWithTimezone(updatedAttendee?.availability, conference?.time_zone);
    }

    if (isCorporate && typeof updatedAttendee._corporate_profile !== "string") {
      // for corporate profile updatedAttendee corporate profile should contain custom question answer
      updatedAttendee._corporate_profile.custom_question_answers = updatedAttendee.custom_question_answers;
      const corporateProfile = updatedAttendee?._corporate_profile as CorporateProfile;
      if (corporateProfile?.attendees)
        corporateProfile.attendees = corporateProfile.attendees.map((attendee) => {
          const isCurrentUser = attendee._id === profile._id || attendee.email === profile.email;
          return new AttendeeViewModel({
            ...attendee,
            custom_question_answers: modifyAnswerData(attendee?.custom_question_answers, questionGroup?._questions),
            time_zone: isCurrentUser ? registrant.time_zone : attendee.time_zone,
            speaker_info: !attendee.is_speaker ? null : attendee.speaker_info,
            availability: formatTimetableDataWithTimezone(attendee?.availability, conference?.time_zone),
          });
        });
    }

    try {
      const resp = isCorporate
        ? await profileService.current.putCorporate(conference?._id, updatedAttendee._corporate_profile as CorporateProfile)
        : await profileService.current.put(conference?._id, updatedAttendee);
      if (resp.success) {
        notificationService.current.success("Attendee information updated");

        // convert answer object to contain question details instead of questionId
        const corporateResponseData = resp?.data as CorporateProfile;
        const updatedRegistrant = isCorporate
          ? new RegistrantViewModel({
              ...registrant,
              custom_question_answers: convertAnswersToContainQuestionDetails(
                corporateResponseData?.custom_question_answers,
                questionGroup?._questions
              ),
              corporate_attendees: corporateResponseData?.attendees?.map((attendee) => {
                return new AttendeeViewModel({
                  ...attendee,
                  custom_question_answers: convertAnswersToContainQuestionDetails(
                    attendee?.custom_question_answers,
                    questionGroup?._questions
                  ),
                });
              }),
            })
          : new RegistrantViewModel({
              ...registrant,
              custom_question_answers: convertAnswersToContainQuestionDetails(
                resp?.data?.custom_question_answers,
                questionGroup?._questions
              ),
            });
        // set original and new registrant with updated answers
        setOriginalRegistrant(updatedRegistrant);
        setRegistrant(updatedRegistrant);
      } else {
        notificationService.current.error("Failed to update information");
      }
    } catch (err) {
      notificationService.current.error("Failed to update information");
    }

    setLoading(false);
  }, [fileState, profile, registrant, questionGroup?._questions, conference?.time_zone, conference?._id]);

  const { tabSections, sectionStep } = useSections({
    isEditMode: true,
    viewIdModel: IdModel,
    showAvailability,
    showMeetings,
    isGuestOtherTypesEnabled,
    companyName: conference?._company?.name,
    conference,
    registrant,
    loading,
    isFormDirty,
    originalRegistrant,
    questionGroup,
    questionGroupLoading,
    setFileState,
    setRegistrant,
    handleRegistrantChange,
    setLoading,
    onSubmit: handlePost,
  });

  const handleSignOut = useCallback((): void => {
    userContext.signOut();
  }, [userContext]);

  if (isEmpty(profile)) return <LoadingScreen />;
  return (
    <section id={IdModel.id} className={AttendeeProfileClassName.Base}>
      <CompanyLobbyLayout
        id={IdModel.layout?.id}
        profile={profile}
        conference={conference}
        isAttendeeProfile={true}
        onSignOut={handleSignOut}
      >
        <div className={AttendeeProfileClassName.Wrapper}>
          {lobbyOpen && (
            <div id={IdModel.backToLobby} className="registration_link" onClick={goToLobby}>
              <span>
                <i className="ni-arrow-left-4pt" />
              </span>
              <span>Go Back To Lobby</span>
            </div>
          )}
          <RegistrationHeader
            id={IdModel.registrationHeader?.id}
            conference={conference}
            details="Attendee Information"
            isAttendeeProfile={true}
          />
          <RegistrationSections sections={tabSections} tabStep={sectionStep} />
        </div>
      </CompanyLobbyLayout>
    </section>
  );
};

export default contextSubscribe<ItineraryProps>(
  [
    {
      context: UserContext,
      mapToProps: "userContext",
    },
  ],
  memo(AttendeeProfileView)
);
