import { isEmpty, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo, useState } from "react";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import { CalendarExport, CalendarExportType, CalendarSession } from "../../services/calendarExport/calendarExport.model";
import CalendarExportService from "../../services/calendarExport/calendarExport.service";
import { Conference } from "../../services/conference/conference.model";
import { PresentationSessionType } from "../../services/presentation/presentation.model";
import { removeSpecialCharacters } from "../../utils";
import { AgendaSession } from "../../views/public/itinerary/components/agenda/agenda.definition";
import type { CalendarExportHookModel, CalendarExportHookProps } from "./useCalendarExport.definition";

export const useCalendarExport = (props: CalendarExportHookProps): CalendarExportHookModel => {
  const { showNotifications } = props;
  const service = useMemo(() => new CalendarExportService(), []);
  const notificationService = useMemo(() => new NotificationService(), []);
  const [loading, setLoading] = useState(false);

  const getCalendar = useCallback(
    async (
      type: CalendarExportType,
      conference: Conference,
      sessions: AgendaSession[],
      attendee: AttendeeViewModel
    ): Promise<string> => {
      setLoading(true);

      const options = (sessions || []).map((session) => {
        const calendarSession = {
          title: session.Title,
          start: new Date(session.start_date?.format()),
          end: new Date(session.end_date?.format()),
          description: service.getDescription(conference, session, attendee),
          uid: session._id,
        } as CalendarSession;

        if (session.session_type === PresentationSessionType.Break) {
          calendarSession.props = [
            { key: "X-MICROSOFT-CDO-BUSYSTATUS", value: "FREE" },
            { key: "TRANSP", value: "TRANSPARENT" },
          ];
        } else {
          calendarSession.props = [
            { key: "X-MICROSOFT-CDO-BUSYSTATUS", value: "BUSY" },
            { key: "TRANSP", value: "OPAQUE" },
          ];
        }
        return calendarSession;
      });

      if (isEmpty(options)) {
        showNotifications && notificationService.error("No sessions can be exported");
        setLoading(false);
        return Promise.resolve(null);
      }

      const name = conference?.title;

      const calendar = new CalendarExport({
        type,
        options,
        name,
        filename: removeSpecialCharacters(`${attendee?.company_name}_${attendee?.display_name}_${name}`),
      });

      return service
        .getCalendar(calendar)
        .then((response): string => {
          if (isNullOrWhiteSpace(response)) {
            throw new Error("Failed to download the calendar.");
          }

          showNotifications && notificationService.success("The calendar has been exported.");

          return response;
        })
        .catch((error): string => {
          showNotifications && notificationService.error(error.message);
          return null;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [service, showNotifications, notificationService]
  );

  const getDescription = useCallback(
    (conference: Conference, session: AgendaSession, user: AttendeeViewModel): string =>
      service.getDescription(conference, session, user),
    [service]
  );

  return {
    loading,
    getCalendar,
    getDescription,
  };
};
