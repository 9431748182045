import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { ImportResult } from "../../../../services/import/import.definition";
import {
  RequestProgressResultsGroupIdModel,
  RequestProgressResultsIdModel,
} from "../results/requestProgressResultsTable.definition";

export enum RequestProgressClassName {
  Base = "import",
  Info = "import-info",
  Results = "import-results",
  InfoComplete = "import-info--complete",
  ResultComplete = "import-results--complete",
  InfoStatus = "import-info_status",
  ResultProgressBar = "import-results_progress-bar",
}

export enum RequestProgressStatusName {
  Importing = "Importing",
  Syncing = "Syncing",
  Exporting = "Exporting",
  Complete = "Complete",
  Failed = "Failed",
}

export interface ContentRequestProgressResultsProps<T> {
  id: string;
  inProgress: boolean;
  results: ImportResult<T>[];
  totalCount: number;
  currentCount?: number;
  statusText: RequestProgressStatusName;
  showCsvExport: boolean;
  showStatusTable: boolean;
}

export class RequestProgressStatusIdModel extends IdModelBase {
  requestProgressStatusTable: RequestProgressResultsIdModel;
  requestProgressResultsGroup: RequestProgressResultsGroupIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.requestProgressStatusTable = new RequestProgressResultsIdModel(`${this.id}RequestProgressStatusTable`);
    this.requestProgressResultsGroup = new RequestProgressResultsGroupIdModel(`${this.id}RequestProgressResultsGroup`);
  }
}
