import { Device, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { isAdminRoute, isLoginRoute } from "../../utils";
import {
  AdminSupportedBrowsers,
  PublicSupportedBrowsers,
  SupportedBrowser,
  SupportedBrowserMinVersion,
} from "./supportedBrowser.definition";

export type BrowserSupportedConfig = { browser: string; browserVersion: number; device: Device; override?: boolean };

export function isBrowserSupported(config: BrowserSupportedConfig): boolean {
  if (isEmpty(config)) return false;

  const { browser, browserVersion, device, override } = config;

  // Debugging purposes
  console.log("Browser: ", browser);
  console.log("Version: ", browserVersion);

  if (!!override) return true;

  if (!isNullOrWhiteSpace(device) && device !== Device.Desktop) return true;

  const adminBrowserSupport = _isBrowserSupported(new SupportedBrowser(browser, +browserVersion), AdminSupportedBrowsers);
  const hasLocalStorageSupport = isLocalStorageSupported();
  if (isAdminRoute()) return hasLocalStorageSupport && adminBrowserSupport;

  const publicBrowserSupport = _isBrowserSupported(new SupportedBrowser(browser, +browserVersion), PublicSupportedBrowsers);
  if (isLoginRoute()) return hasLocalStorageSupport && publicBrowserSupport;

  return publicBrowserSupport;
}

export function isLocalStorageSupported(): boolean {
  return _isStorageSupported(localStorage);
}

export function isSessionStorageSupported(): boolean {
  return _isStorageSupported(sessionStorage);
}

function _isBrowserSupported(browser: SupportedBrowser, supportedBrowsers: SupportedBrowser[]): boolean {
  if (isEmpty(browser)) return false;

  const { name, version } = browser;

  const supportedVersion = SupportedBrowserMinVersion[name as keyof typeof SupportedBrowserMinVersion];

  const isVersionSupport = isNil(supportedVersion) ? true : version >= supportedVersion;
  return supportedBrowsers.some((x) => x.name === name) && isVersionSupport;
}

function _isStorageSupported(storage: Storage): boolean {
  if (isNil(storage)) return false;

  const test = "test";
  try {
    storage.setItem(test, test);
    storage.removeItem(test);
  } catch (e) {
    return false;
  }
  return true;
}
