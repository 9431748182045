import "./meetingTimeSlotCreator.component.scss";
import {
  Text,
  Button,
  ButtonTheme,
  TimePicker,
  isNil,
  ErrorModel,
  SelectPreset,
  DatePickerTheme,
  Form,
  TimePickerProps,
} from "@q4/nimbus-ui";
import moment from "moment";
import type { Moment } from "moment";
import React, { memo, useMemo, useCallback, useState, useEffect } from "react";
import { TimeFormat } from "../../../../../../../../definitions/date.definition";
import { convertMinutesToHourWithDecimals } from "../../../../../../../../utils";
import {
  MeetingTimeSlotCreatorClassName,
  MeetingTimeSlotCreatorIdModel,
  MeetingTimeSlotCreatorProps,
} from "./meetingTimeSlotCreator.definition";

const MeetingTimeSlotCreator = (props: MeetingTimeSlotCreatorProps): JSX.Element => {
  const { id, saving, scheduler, onSubmit: updateSchedule, currentCalendarDay, hasConferenceScheduler, editMode } = props;
  const [startTime, setStartTime] = useState<Moment | undefined>();
  const [endTime, setEndTime] = useState<Moment | undefined>();
  const idModel = useMemo(() => new MeetingTimeSlotCreatorIdModel(id), [id]);
  const [showErrors, setShowErrors] = useState(false);

  const startTimeError = useMemo(() => new ErrorModel("Start time is required", isNil(startTime)), [startTime]);
  const endTimeError = useMemo(() => new ErrorModel("End time is required", isNil(endTime)), [endTime]);
  const endTimeStartHour = useMemo(() => {
    return startTime ? startTime?.hours() + convertMinutesToHourWithDecimals(startTime?.minutes()) + 0.09 : 0.09;
  }, [startTime]);

  const resetForm = () => {
    setStartTime(undefined);
    setEndTime(undefined);
    setShowErrors(false);
  };

  useEffect(() => {
    // Resets form on day change
    resetForm();
  }, [currentCalendarDay]);

  const handleTimeChange = useCallback(
    (value: string, isStartTime: boolean) => {
      const selectedTime = moment(value, TimeFormat.Picker);
      const newTime = currentCalendarDay?.clone()?.set({ hours: selectedTime.hour(), minutes: selectedTime.minutes() });

      const setState = isStartTime ? setStartTime : setEndTime;

      setState(newTime);

      if (isStartTime && newTime.isSameOrAfter(endTime)) {
        setEndTime(null);
      }
    },
    [currentCalendarDay, endTime]
  );

  const onSubmit = useCallback(async () => {
    if (startTimeError?.visible || endTimeError?.visible) {
      setShowErrors(true);
      return;
    }

    const slots = [...scheduler.slots, { start_time: startTime, end_time: endTime }];

    updateSchedule({ ...scheduler, slots });
    resetForm();
  }, [startTimeError?.visible, endTimeError?.visible, scheduler, startTime, endTime, updateSchedule]);

  const formFields = useMemo(() => {
    const pickerDefaultOptions: Partial<TimePickerProps> = {
      theme: DatePickerTheme.White,
      minuteSkip: 5,
      noOptionsMessageText: "No Options",
      preset: SelectPreset.Autocomplete,
      placeholder: "Select",
      disabled: saving || !hasConferenceScheduler || editMode,
    };

    return [
      {
        key: "Start Time",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Start Time",
        error: showErrors ? startTimeError : null,
        children: (
          <TimePicker
            id={idModel.startTime?.id}
            value={startTime ? startTime?.format(TimeFormat.Picker) : null}
            startHour={0}
            endHour={23.9}
            onChange={(value: string) => handleTimeChange(value, true)}
            {...pickerDefaultOptions}
          />
        ),
      },
      {
        key: "End Time",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "End Time",
        error: showErrors ? endTimeError : null,
        children: (
          <TimePicker
            id={idModel.endTime?.id}
            value={endTime ? endTime?.format(TimeFormat.Picker) : null}
            startHour={endTimeStartHour}
            endHour={23.94}
            onChange={(value: string) => handleTimeChange(value, false)}
            {...pickerDefaultOptions}
          />
        ),
      },
    ];
  }, [
    saving,
    hasConferenceScheduler,
    showErrors,
    startTimeError,
    idModel.startTime?.id,
    idModel.endTime?.id,
    startTime,
    endTimeError,
    endTime,
    endTimeStartHour,
    editMode,
    handleTimeChange,
  ]);

  return (
    <div className={MeetingTimeSlotCreatorClassName.Base}>
      <div id={idModel.container} className={MeetingTimeSlotCreatorClassName.Content}>
        <Text>Create new time slots below. Any conflicting time slots will not be visible in the meeting scheduler.</Text>
        <Form id={idModel.form.id} className={MeetingTimeSlotCreatorClassName.Form} fields={formFields} />
        <Button
          id={idModel.button.id}
          className={MeetingTimeSlotCreatorClassName.Button}
          theme={ButtonTheme.Rain}
          label={"ADD TIME SLOT"}
          onClick={onSubmit}
          disabled={!hasConferenceScheduler || editMode}
          loading={saving}
        />
      </div>
    </div>
  );
};

export default memo(MeetingTimeSlotCreator);
