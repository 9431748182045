import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { RoutePath } from "../../configurations/navigation.configuration";
import { AuthType } from "../api/api.definition";
import { AdminAuth0 } from "../auth0/adminAuth0/adminAuth0.model";
import { AdminAuth0Service } from "../auth0/adminAuth0/adminAuth0.service";
import { Auth0Base, AuthSignInOptions } from "../auth0/auth0.model";
import { TokenStorageKey } from "../token/token.definition";

export default class AdminTokenService {
  private readonly _tokenStorageKey: string;
  private readonly _authType: AuthType;
  private readonly _authModel: new (entity?: Auth0Base) => Auth0Base;
  private readonly _authService: AdminAuth0Service;

  constructor() {
    this._authType = AuthType.Protected;
    this._tokenStorageKey = TokenStorageKey.Admin;
    this._authModel = AdminAuth0;
    this._authService = new AdminAuth0Service();
  }

  setToken(token: Auth0Base): void {
    this._addStorage(this._tokenStorageKey, token);
  }

  getToken(): Auth0Base {
    const storageValue = this._getStorage(this._tokenStorageKey);
    if (isNullOrWhiteSpace(storageValue) || storageValue === "undefined") return null;

    let json = null;
    try {
      json = JSON.parse(storageValue);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.error("Error parsing token:", (e as any).message);
      return null;
    }
    return new this._authModel(json);
  }

  removeToken(): void {
    this._removeStorage(this._tokenStorageKey);
  }

  isAuthenticated = (): boolean => {
    const token = this.getToken();
    if (isEmpty(token)) return false;
    const expired = new Date().getTime() > token.ExpiresAt;
    return !expired;
  };

  getAuthToken(): string {
    const token = this.getToken();
    if (isEmpty(token)) return "";
    return token.IdToken;
  }

  signOut = (options?: AuthSignInOptions): void => {
    options = isEmpty(options) ? new AuthSignInOptions() : options;
    options.redirect = isNullOrWhiteSpace(options.redirect) ? RoutePath.AdminHome : options.redirect;

    this._destroy();
    this._signOut();
    this._signIn(options);
  };

  private _signIn = (options?: AuthSignInOptions): void => {
    this._authService.signIn(options);
  };

  private _signOut = (): void => {
    this._authService.signOut();
  };

  private _destroy(): void {
    this.removeToken();
  }

  private _addStorage(key: string, value: string | Auth0Base): void {
    if (isEmpty(value) || (typeof value === "string" && isNullOrWhiteSpace(value))) return;

    let storageValue = value;
    if (typeof value === "object") {
      try {
        storageValue = JSON.stringify(storageValue);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.error("Error storing token:", (e as any).message);
        return;
      }
    }

    if (typeof storageValue !== "string") return;
    localStorage.setItem(key, storageValue);
  }

  private _getStorage(key: string): string {
    return localStorage?.getItem(key);
  }

  private _removeStorage(key: string): void {
    localStorage.removeItem(key);
  }
}
