import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { GetQuickFilterTextParams, ValueFormatterParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { DateFormat } from "../../../../../../../definitions/date.definition";
import type { Conference } from "../../../../../../../services/conference/conference.model";
import {
  BreakPresentation,
  Presentation,
  PresentationSessionType,
} from "../../../../../../../services/presentation/presentation.model";
import type { Speaker } from "../../../../../../../services/speaker/speaker.model";
import type { Track } from "../../../../../../../services/track/track.model";
import { basicCompare } from "../../../../../../../utils";

export function getFormattedDate(params: ValueFormatterParams | GetQuickFilterTextParams, conference: Conference): string {
  const date = params?.value;

  if (isNullOrWhiteSpace(date)) return "—";

  return date.tz(conference?.time_zone).format(DateFormat.ShortStandard);
}

export function getFormattedSpeaker(params: ValueFormatterParams | GetQuickFilterTextParams): string {
  const speaker: Speaker[] = params?.value;
  const presentation: Presentation = params?.data;

  if (isEmpty(speaker) || presentation?.session_type === PresentationSessionType.Break) return "—";

  return speaker?.map((x) => x.display_name).join(", ");
}

export function getFormattedTrack(params: ValueFormatterParams | GetQuickFilterTextParams): string {
  const track: Track = params?.value;
  const presentation: Presentation = params?.data;

  if (isEmpty(track) || isNullOrWhiteSpace(track?.name) || presentation?.session_type === PresentationSessionType.Break)
    return "—";

  return track.name;
}

export function getComparator<T>(
  a: T,
  b: T,
  func: (params: ValueFormatterParams | GetQuickFilterTextParams) => string
): number {
  const valueA = func({ value: a } as ValueFormatterParams);
  const valueB = func({ value: b } as ValueFormatterParams);

  return basicCompare(valueA, valueB);
}

export function setPresentationPayload(presentation: Presentation): Presentation {
  switch (presentation?.session_type) {
    case PresentationSessionType.Break:
      return new BreakPresentation(presentation);
    case PresentationSessionType.Open:
      return new Presentation({ ...presentation, code: [], is_all_days: undefined });
    case PresentationSessionType.Closed:
      return new Presentation({ ...presentation, add_to_all: undefined, is_all_days: undefined });
    default:
      return presentation;
  }
}
