import { ButtonIdModel, ButtonListId, IdModelBase, isNullOrWhiteSpace, TextboxListId } from "@q4/nimbus-ui";
import { BaseComponentProps } from "@q4/nimbus-ui/dist/definitions/base.definition";
import { ChangeEvent } from "react";
import { ConferenceLink } from "../../../../../../services/conference/conference.model";

export interface NavigationLinksProps extends BaseComponentProps {
  /**
   * Conference navigation links
   */
  value?: ConferenceLink[];

  onChange: (value: string, field: string, index: number, event: ChangeEvent<HTMLInputElement>) => void;

  onDelete: (index: number) => void;

  onAdd: (link: ConferenceLink, index: number) => void;
}

export class NavigationLinksIdModel extends IdModelBase {
  addButton: ButtonIdModel;
  label: TextboxListId;
  url: TextboxListId;
  order: TextboxListId;
  deleteButton: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.addButton = new ButtonIdModel(`${id}AddButton`);
    this.label = new TextboxListId(`${this.id}LabelTextBox`);
    this.url = new TextboxListId(`${this.id}UrlTextBox`);
    this.order = new TextboxListId(`${this.id}OrderTextBox`);
    this.deleteButton = new ButtonListId(`${this.id}DeleteButton`);
  }
}
