import "./conferenceTimelineEdit.component.scss";
import { DatePicker, Form, isEmpty, TimeZonePicker, Textbox, TooltipTheme, Origin, InfoIcon, isNil } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import moment from "moment";
import React, { memo, useCallback, useMemo } from "react";
import GridCard from "../../../../../../components/gridCard/gridCard.component";
import { TimeZoneLocationMap, TimeZoneOptions } from "../../../../../../const";
import { getTimeZoneOffsetLabelInHours, sortTimezonesByOffsets } from "../../../../../../utils";
import { ConferenceTimelineEditClassName, ConferenceTimelineEditIdModel } from "./conferenceTimelineEdit.definition";
import type { ConferenceDateEditState, ConferenceTimelineEditProps } from "./conferenceTimelineEdit.definition";

const ConferenceTimelineEdit = (props: ConferenceTimelineEditProps): JSX.Element => {
  const { id, state, onChange } = props;
  const { close_date, end_date, open_date, start_date, time_zone, date_label } = state || {};

  const idModel = useMemo(() => new ConferenceTimelineEditIdModel(id), [id]);

  const formatTimeZone = useCallback(
    (timeZoneName: string, timeZoneLocation: string) => {
      const startDateTZ = moment(start_date).tz(timeZoneName);
      const utcString = `UTC ${getTimeZoneOffsetLabelInHours(timeZoneName)}`;

      return `${utcString} - ${timeZoneLocation} (${startDateTZ.zoneAbbr()})`;
    },
    [start_date]
  );

  const timeZoneDisplay = useMemo(() => {
    if (isNil(time_zone?.value)) return;
    const timeZoneLocation = TimeZoneLocationMap[time_zone.value] || "";
    return {
      ...time_zone,
      label: formatTimeZone(time_zone?.value, timeZoneLocation),
    };
  }, [time_zone, formatTimeZone]);

  const timezoneOptions: Array<string> = useMemo(() => sortTimezonesByOffsets(TimeZoneOptions), []);

  function getDateChangeHandler(key: keyof ConferenceDateEditState): (value: Moment) => void {
    if (!(onChange instanceof Function)) return;

    function handleDateChange(date: Moment): void {
      function getOpposingDate(opposing: Moment, isStarting: boolean): Moment {
        const opposingDate = opposing;
        if (isEmpty(date)) return opposingDate;
        if (isStarting && date.isAfter(opposingDate)) return date.clone().endOf("day");
        if (!isStarting && date.isBefore(opposingDate)) return date.clone().startOf("day");

        return opposingDate;
      }

      enum DateKey {
        StartDate = "start_date",
        EndDate = "end_date",
        OpenDate = "open_date",
        CloseDate = "close_date",
      }

      const isStartingDate = [DateKey.StartDate, DateKey.OpenDate].some((x) => x === key);

      const conferenceState = {
        start_date: start_date.clone(),
        end_date: end_date.clone(),
        open_date: open_date.clone(),
        close_date: close_date.clone(),
      };

      let updated = {
        start_date: start_date.clone(),
        end_date: end_date.clone(),
        open_date: open_date.clone(),
        close_date: close_date.clone(),
      };

      switch (key) {
        case DateKey.StartDate:
          updated = {
            ...updated,
            [DateKey.StartDate]: date,
            [DateKey.EndDate]: getOpposingDate(conferenceState[DateKey.EndDate], isStartingDate),
          };
          break;
        case DateKey.EndDate:
          updated = {
            ...updated,
            [DateKey.EndDate]: date,
            [DateKey.StartDate]: getOpposingDate(conferenceState[DateKey.StartDate], isStartingDate),
          };
          break;
        case DateKey.OpenDate:
          updated = {
            ...updated,
            [DateKey.OpenDate]: date,
            [DateKey.CloseDate]: getOpposingDate(conferenceState[DateKey.CloseDate], isStartingDate),
          };
          break;
        case DateKey.CloseDate:
          updated = {
            ...updated,
            [DateKey.CloseDate]: date,
            [DateKey.OpenDate]: getOpposingDate(conferenceState[DateKey.OpenDate], isStartingDate),
          };
          break;
      }

      updated = {
        start_date: updated.start_date.clone().startOf("day"),
        end_date: updated.end_date.clone().endOf("day"),
        open_date: updated.open_date.clone().startOf("day"),
        close_date: updated.close_date.clone().endOf("day"),
      };

      onChange(updated);
    }

    return handleDateChange;
  }

  function handleTimeZoneChange(value: ConferenceTimelineEditProps["state"]["time_zone"]): void {
    if (isEmpty(value) || !(onChange instanceof Function)) return;

    onChange({
      time_zone: value,
    });
  }

  function handleTextInputChange(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      if (!(onChange instanceof Function)) return;
      onChange({
        [key]: value,
      });
    };
  }

  return (
    <GridCard cardProps={{ id: idModel.id, className: ConferenceTimelineEditClassName.Base, title: "Conference Timeline" }}>
      <Form
        fields={[
          {
            key: "Open Date",
            width: "1-of-2",
            label: "Conference Open Date",
            children: (
              <DatePicker
                id={idModel.openDate?.id}
                value={open_date?.clone()}
                onChange={getDateChangeHandler("open_date")}
              />
            ),
          },
          {
            key: "Start Date",
            width: "1-of-2",
            label: "Conference Start Date",
            children: (
              <DatePicker
                id={idModel.startDate?.id}
                value={start_date?.clone()}
                onChange={getDateChangeHandler("start_date")}
              />
            ),
          },
          {
            key: "End Date",
            width: "1-of-2",
            label: "Conference End Date",
            children: (
              <DatePicker id={idModel.endDate?.id} value={end_date?.clone()} onChange={getDateChangeHandler("end_date")} />
            ),
          },
          {
            key: "Close Date",
            width: "1-of-2",
            label: "Conference Close Date",
            children: (
              <DatePicker
                id={idModel.closeDate?.id}
                value={close_date?.clone()}
                onChange={getDateChangeHandler("close_date")}
              />
            ),
          },
          {
            key: "Date Label",
            width: "2-of-2",
            className: ConferenceTimelineEditClassName.CustomLabel,
            label: (
              <>
                Custom Date Label
                <InfoIcon
                  id={idModel.infoIcon?.id}
                  tooltipProps={{
                    label: "Override conference date range with custom text",
                    position: Origin.Top,
                    theme: TooltipTheme.Slate,
                  }}
                />
              </>
            ),
            children: (
              <Textbox
                id={idModel.dateLabel?.id}
                value={date_label}
                maxLength={100}
                onChange={handleTextInputChange("date_label")}
              />
            ),
          },
        ]}
      />
      <Form
        fields={[
          {
            key: "Time Zone",
            width: "1-of-2",
            label: "Time Zone",
            children: (
              <TimeZonePicker
                id={idModel.timeZone?.id}
                timeZoneOptions={timezoneOptions}
                customFormatter={formatTimeZone}
                timeZonePickerMap={TimeZoneLocationMap}
                value={timeZoneDisplay}
                onChange={handleTimeZoneChange}
              />
            ),
          },
        ]}
      />
    </GridCard>
  );
};

export default memo(ConferenceTimelineEdit);
