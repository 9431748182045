import {
  BaseComponentProps,
  ButtonIdModel,
  CardIdModel,
  CheckboxIdModel,
  ErrorModel,
  FormIdModel,
  GridColumnIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { Question } from "../../services/questionGroup/questionGroup.model";
import { EntityTableRequiredFormKey } from "../entityTable/entityTable.definition";

export enum QuestionCardWrapperClassNames {
  base = "card-placeholder",
  rowWrapper = "card-placeholder_row-wrapper",
  columnWrapper = "card-placeholder_column-wrapper",
  optionFieldWrapper = "card-placeholder_option-field-wrapper",
  headerIcons = "card-placeholder_header-icons",
  removeIcon = "card-placeholder_remove-icon",
  linkButton = "card-placeholder_link-button",
  footer = "card-placeholder_footer",
}

export interface QuestionCardWrapperProps extends BaseComponentProps {
  currentQuestion: Question;
  handleQuestionUpdate: (updated: Question) => void;
  currentQuestionTypeLabel: string;
  typeConfigurationFields?: JSX.Element;
  textConfigurationFields?: JSX.Element;
  handleQuestionMoveDown?: () => void;
  handleQuestionMoveUp?: () => void;
  idx?: number;
  isMoveUpDisabled?: boolean;
  isMoveDownDisabled?: boolean;
  formKeys?: EntityTableRequiredFormKey[];
  formErrors?: Record<string, ErrorModel>;
  dataIndex?: number;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  handleQuestionRemove?: () => void;
  onDuplicate: (question: Question) => void;
}

export class QuestionCardWrapperIdModel extends IdModelBase {
  card: CardIdModel;
  basicForm: FormIdModel;
  gridColumnLeft: GridColumnIdModel;
  gridColumnRight: GridColumnIdModel;
  duplicate: ButtonIdModel;
  moveUp: ButtonIdModel;
  moveDown: ButtonIdModel;
  footer: string;
  title: TextboxIdModel;
  answerType: SelectIdModel;
  requiredField: CheckboxIdModel;
  isIndividual: CheckboxIdModel;
  showField: CheckboxIdModel;
  removeQuestion: string;
  utilityIcon: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.card = new CardIdModel(`${this.id}Card`);
    this.basicForm = new FormIdModel(`${this.id}BasicForm`);
    this.gridColumnLeft = new GridColumnIdModel(`${this.id}GridColumnLeft`);
    this.gridColumnRight = new GridColumnIdModel(`${this.id}GridColumnRight`);
    this.duplicate = new ButtonIdModel(`${this.id}DuplicateButton`);
    this.moveUp = new ButtonIdModel(`${this.id}MoveUpButton`);
    this.moveDown = new ButtonIdModel(`${this.id}MoveDownButton`);
    this.footer = `${this.id}Footer`;

    // all answer type fields
    this.title = new TextboxIdModel(`${this.id}Title`);
    this.answerType = new SelectIdModel(`${this.id}AnswerType`);
    this.requiredField = new CheckboxIdModel(`${this.id}RequiredField`);
    this.isIndividual = new CheckboxIdModel(`${this.id}IsIndividualField`);
    this.showField = new CheckboxIdModel(`${this.id}ShowField`);
    this.removeQuestion = `${this.id}RemoveQuestion`;
    this.utilityIcon = new ButtonIdModel(`${this.id}UtilityIcon`);
  }
}
