import { TextboxIdModel, CheckboxIdModel, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import {
  QuestionCardWrapperIdModel,
  QuestionCardWrapperProps,
} from "../../questionCardWrapper/questionCardWrapper.definition";

export type TextQuestionProps = QuestionCardWrapperProps;

export class TextQuestionIdModel extends QuestionCardWrapperIdModel {
  placeholder: TextboxIdModel;
  longAnswer: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    // text field
    this.placeholder = new TextboxIdModel(`${this.id}Placeholder`);
    this.longAnswer = new CheckboxIdModel(`${this.id}LongAnswerSelection`);
  }
}
