import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Conference } from "../../services/conference/conference.model";
import type { Meeting } from "../../services/meeting/meeting.model";

export interface MeetingExistsMessageProps extends BaseComponentProps {
  visible: boolean;
  disabled: boolean;
  saving: boolean;
  meeting: Meeting;
  timeZone: Conference["time_zone"];
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

export class MeetingExistsMessageIdModel extends IdModelBase {
  primaryButton: ButtonIdModel;
  secondaryButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.primaryButton = new ButtonIdModel(`${this.id}PrimaryButton`);
    this.secondaryButton = new ButtonIdModel(`${this.id}SecondaryButton`);
  }
}
