import "./faqModal.component.scss";
import { Anchor, AnchorTarget, AnchorTheme, isNullOrWhiteSpace, Modal, useVisibility } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { FaqModalClassNames, FaqModalIdModel, FaqModalProps } from "./faqModal.definition";

function FaqModal(props: FaqModalProps): JSX.Element {
  const { children, clientEmail, id, supportEmail, conferenceName, companyName } = props;

  const [faqModalVisible, handleFaqModalOpen, handleFaqModalClose] = useVisibility();
  const IdModel = useMemo(() => new FaqModalIdModel(id), [id]);

  const loginHelpUrl = useMemo(
    () => `mailto:${supportEmail}?subject=Login Help Needed - ${conferenceName} | ${companyName}`,
    [companyName, conferenceName, supportEmail]
  );

  const registrationHelpUrl = useMemo(
    () => `mailto:${clientEmail}?subject=Registration Help - ${conferenceName} | ${companyName}`,
    [clientEmail, companyName, conferenceName]
  );

  return (
    <div id={IdModel.id} className={FaqModalClassNames.Base}>
      <Anchor
        id={IdModel.anchor?.id}
        className={FaqModalClassNames.Link}
        theme={AnchorTheme.Rain}
        target={AnchorTarget.Self}
        url={"#faq"}
        onClick={handleFaqModalOpen}
      >
        {children}
      </Anchor>
      <Modal
        id={IdModel.modal?.id}
        title={""}
        className={FaqModalClassNames.Modal}
        visible={faqModalVisible}
        onCloseRequest={handleFaqModalClose}
      >
        <h2>FAQ</h2>
        <h3>Trouble logging in?</h3>
        <p>
          <strong>
            Q: I am registered to attend the conference, but I’m getting an error when I try to log in with my email.
          </strong>
        </p>
        <p>
          A: Please make sure the email address you are logging in with is the same email you registered with. If that email
          still doesn’t work, please contact{" "}
          <Anchor className={FaqModalClassNames.Link} target={AnchorTarget.Blank} url={loginHelpUrl}>
            {supportEmail}
          </Anchor>
          .
        </p>
        <p>
          <strong>Q: I haven’t registered for the conference but would like to.</strong>
        </p>
        {isNullOrWhiteSpace(clientEmail) ? (
          <p>A: This conference is an invite-only event. If you’d like to be invited, please contact the conference host.</p>
        ) : (
          <p>
            A: This conference is an invite-only event. If you’d like to be invited, please contact the conference host or
            email the conference coordinator at{" "}
            <Anchor className={FaqModalClassNames.Link} target={AnchorTarget.Blank} url={registrationHelpUrl}>
              {clientEmail}
            </Anchor>
            .
          </p>
        )}
        <p>
          <strong>
            Q: I see a message informing me that an email has been sent to my email address, but I don’t see an email.
          </strong>
        </p>
        <p>
          A: Please check your spam folder, or try entering your email address again. If the problem persists, please email{" "}
          <Anchor className={FaqModalClassNames.Link} target={AnchorTarget.Blank} url={loginHelpUrl}>
            {supportEmail}
          </Anchor>
          .
        </p>
        <p>
          <strong>Q: I am being logged out, why is this happening?</strong>
        </p>
        <p>
          A: Access is automatically revoked if an email address is used on more than one device, or if a user has been
          inactive for a certain period of time. You can generate a new access code by entering your email address on the log
          in page again. If you continue to have trouble logging in, please contact{" "}
          <Anchor className={FaqModalClassNames.Link} target={AnchorTarget.Blank} url={loginHelpUrl}>
            {supportEmail}
          </Anchor>
          .
        </p>
      </Modal>
    </div>
  );
}

export default memo(FaqModal);
