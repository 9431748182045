import type { Dispatch, SetStateAction } from "react";
import type { AuthType } from "../../services/api/api.definition";
import type { Auth0Token, AuthSignInOptions } from "../../services/auth0/auth0.model";

export enum UserValidateErrorMessage {
  EmailInvalid = "Email is invalid",
  EmailPasswordInvalid = "Email or password is invalid.",
  AttendeeInvalid = "User does not exist",
  NoCompanyFound = "No company found",
  UnavailableConference = "Conference expired or not public yet",
}

export interface BaseUserContextState {
  isSystemAdmin?: boolean;
  authType: AuthType;
  authLoading: boolean;
  loading: boolean;
  user: Auth0Token;
  isAuthenticated: () => boolean;
  setAuthType: Dispatch<SetStateAction<AuthType>>;
  signOut: (options?: AuthSignInOptions) => void;
}
