import React, { useMemo } from "react";
import { Redirect } from "react-router";
import { RoutePath } from "../../../configurations/navigation.configuration";
import AdminCallback from "../../admin/callback/adminCallback.view";
import Company from "../../admin/company/company.view";
import Conferences from "../../admin/conferences/conferences.view";
import { ConferenceDetails } from "../../admin/conferences/details/conferenceDetails.view";
import ConferenceEdit from "../../admin/conferences/edit/conferenceEdit.view";
import PrivateRoute from "./components/privateRoute/privateRoute.component";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import type { RouteProps } from "./route.definition";

const AdminRoutes = (props: RouteProps): JSX.Element[] => {
  const { userContext: adminUserContext } = props;
  const { isSystemAdmin } = adminUserContext;

  const adminHomeRoute = useMemo(() => (isSystemAdmin ? RoutePath.Companies : RoutePath.Conferences), [isSystemAdmin]);
  const adminHomeComponent = useMemo(() => (isSystemAdmin ? Company : Conferences), [isSystemAdmin]);

  return [
    <PublicRoute
      key="admin_callback"
      exact
      path={RoutePath.AdminCallback}
      userContext={adminUserContext}
      component={AdminCallback}
    />,
    <PrivateRoute
      key="admin_home"
      exact
      userContext={adminUserContext}
      path={adminHomeRoute}
      component={adminHomeComponent}
    />,
    <PrivateRoute
      key="admin_conferences"
      exact
      userContext={adminUserContext}
      path={RoutePath.Conferences}
      component={Conferences}
    />,
    <PrivateRoute
      key="admin_conferences-new"
      exact
      userContext={adminUserContext}
      path={RoutePath.ConferenceNew}
      component={ConferenceEdit}
    />,
    <PrivateRoute
      key="admin_conferences-edit"
      exact
      userContext={adminUserContext}
      path={RoutePath.ConferenceEdit}
      component={ConferenceEdit}
    />,
    <PrivateRoute
      key="admin_conferences-details"
      exact
      userContext={adminUserContext}
      path={RoutePath.ConferenceDetails}
      component={ConferenceDetails}
    />,
    <Redirect key="admin--redirect" from={RoutePath.AdminHome} to={adminHomeRoute} />,
    <Redirect key="admin_home--redirect" from={RoutePath.AdminHomeRedirect} to={adminHomeRoute} />,
  ];
};

export default AdminRoutes;
