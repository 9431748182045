import { AutomationListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { PanelProps } from "../../../../../../public/itinerary/components/panel/panel.definition";

export type { PanelProps as CompactPanelProps };

export enum CompactPanelClassName {
  Base = "compact-panel",
  BaseWithSelectedModifier = "compact-panel--selected",
  BaseWithIndicatorModifier = "compact-panel--with-indicator",
  Section = "compact-panel_section",
  SectionWithGrowModifier = "compact-panel_section--grow",
  Image = "compact-panel_image",
  Category = "compact-panel_category",
  Title = "compact-panel_title",
  Detail = "compact-panel_detail",
  Indicator = "compact-panel_indicator",
  Action = "compact-panel_action",
  ActionWrap = "compact-panel_action-wrap",
  ActionLabel = "compact-panel_action-label",
}

export class CompactPanelListId extends AutomationListId<CompactPanelIdModel> {
  constructor(parentId: string, prefix = "CompactPanel-", postfix?: string) {
    super(parentId, prefix, CompactPanelIdModel, postfix);
  }
}

export class CompactPanelIdModel extends IdModelBase {
  indicator: string;
  action: string;
  category: string;
  title: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.indicator = `${this.id}Indicator`;
    this.action = `${this.id}Action`;
    this.category = `${this.id}Category`;
    this.title = `${this.id}Title`;
  }
}
