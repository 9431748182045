import "./corporateProfileForm.component.scss";
import {
  Ghostable,
  Checkbox,
  convertStringToEnum,
  FileUploader,
  Form,
  getClassName,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  Textarea,
  Textbox,
  Text,
  TextPreset,
  isEmpty,
  Grid,
  SpinnerTheme,
} from "@q4/nimbus-ui";
import { set } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { useFileUpload } from "../../hooks";
import { useAvailability } from "../../hooks/useAvailability/availabilityHook.component";
import { Answer } from "../../services/answer/answer.model";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import { ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import { CorporateType } from "../../services/corporateProfile/corporateProfile.model";
import AvailabilityForm from "../availabilityForm/availabilityForm.component";
import LoadingSpinner from "../loadingSpinner/loadingSpinner.component";
import QuestionsForm from "../questionsForm/questionsForm.component";
import {
  CorporateProfileFormClassName,
  CorporateProfileAvailabilityFormClassName,
  CorporateProfileFormIdModel,
} from "./corporateProfileForm.definition";
import type { CorporateProfileFormProps } from "./corporateProfileForm.definition";

const CorporateProfileForm = (props: CorporateProfileFormProps): JSX.Element => {
  const {
    id,
    className,
    conference,
    corporateProfile,
    answers: corporateAnswers,
    questionGroup,
    loading,
    onCorporateProfileUpdate,
    setFormErrors,
    useAttendees,
  } = props;
  const idModel = useMemo(() => new CorporateProfileFormIdModel(id), [id]);

  // #region Hooks
  const handleFileUpload = useFileUpload();
  const { items: hookAttendees } = useAttendees;
  // #endregion

  const baseClassName = useMemo(
    () =>
      getClassName(CorporateProfileFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const corporateAttendees = useMemo(() => {
    if (!corporateProfile?._id) return [];
    return hookAttendees.filter((attendee) => {
      return typeof attendee._corporate_profile === "string"
        ? attendee._corporate_profile === corporateProfile._id
        : attendee._corporate_profile?._id === corporateProfile._id;
    });
  }, [corporateProfile?._id, hookAttendees]);

  const primaryContact = useMemo(
    () => (corporateAttendees || []).find((x) => x._id === corporateProfile?._primary_contact),
    [corporateAttendees, corporateProfile?._primary_contact]
  );

  const corporateQuestions = useMemo(
    () => (questionGroup?._questions || []).filter((x) => !x.is_individual_corporate),
    [questionGroup?._questions]
  );

  // #region Entity effects
  const corporateTypes = useMemo(() => Object.keys(CorporateType).map((x) => convertStringToEnum(CorporateType, x)), []);
  const isCorporatePublic = useMemo(() => corporateProfile?.type === CorporateType.Public, [corporateProfile?.type]);
  // #endregion

  // #region Handle Methods
  const getTextboxHandler = useCallback(
    (key: string) => {
      return function (value: string): void {
        onCorporateProfileUpdate(set(corporateProfile, key, value));
      };
    },
    [onCorporateProfileUpdate, corporateProfile]
  );

  const getRadioChangeHandler = useCallback(
    (key: string) => {
      return function (checked: boolean): void {
        onCorporateProfileUpdate(set(corporateProfile, key, checked));
      };
    },
    [onCorporateProfileUpdate, corporateProfile]
  );

  const handlePrimaryContactChange = useCallback(
    (attendee: AttendeeViewModel) => {
      onCorporateProfileUpdate({
        _primary_contact: attendee?._id,
      });
    },
    [onCorporateProfileUpdate]
  );

  const getSelectChangeHandler = useCallback(
    (key: string) => {
      return function (option: string): void {
        onCorporateProfileUpdate(set(corporateProfile || {}, key, option));
      };
    },
    [onCorporateProfileUpdate, corporateProfile]
  );

  const handleAnswerChange = useCallback(
    (currentAnswer: Answer) => {
      const targetAnswer = corporateAnswers?.find((ans) => ans._question === currentAnswer._question);

      if (isEmpty(targetAnswer)) {
        corporateAnswers.push(currentAnswer);
      } else {
        targetAnswer.answer = currentAnswer.answer;
      }

      onCorporateProfileUpdate({
        custom_question_answers: corporateAnswers,
      });
    },
    [corporateAnswers, onCorporateProfileUpdate]
  );
  // #endregion

  const onAvailabilityChange = (availability: ConferenceSchedulerSlot[]) => {
    onCorporateProfileUpdate({
      availability,
    });
  };

  const {
    timeslots,
    days: schedulerDays,
    handleTimeslotChange,
  } = useAvailability({
    timeZone: conference?.time_zone,
    scheduler: conference?.scheduler,
    availability: corporateProfile?.availability,
    onAvailabilityChange,
  });

  if (loading) return <LoadingSpinner theme={SpinnerTheme.Rain} />;

  return (
    <>
      <Form
        id={idModel.id}
        className={baseClassName}
        fields={[
          {
            key: "Name",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Company Name",
            required: true,
            children: <Textbox id={idModel.name?.id} value={corporateProfile?.name} onChange={getTextboxHandler("name")} />,
          },
          {
            key: "Type",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Type",
            required: true,
            children: (
              <Select
                id={idModel.type?.id}
                preset={SelectPreset.Autocomplete}
                value={corporateProfile?.type}
                options={corporateTypes}
                onChange={getSelectChangeHandler("type")}
              />
            ),
          },
          {
            key: "Exchange",
            width: "1-of-3",
            smallWidth: "1-of-1",
            required: isCorporatePublic,
            label: (
              <Ghostable ghosted={!isCorporatePublic}>
                <span>Exchange</span>
              </Ghostable>
            ),
            children: (
              <Ghostable ghosted={!isCorporatePublic}>
                <Textbox
                  id={idModel.exchange?.id}
                  value={corporateProfile?.exchange}
                  disabled={!isCorporatePublic}
                  onChange={getTextboxHandler("exchange")}
                />
              </Ghostable>
            ),
          },
          {
            key: "Ticker",
            width: "1-of-3",
            smallWidth: "1-of-1",
            required: isCorporatePublic,
            label: (
              <Ghostable ghosted={!isCorporatePublic}>
                <span>Ticker</span>
              </Ghostable>
            ),
            children: (
              <Ghostable ghosted={!isCorporatePublic}>
                <Textbox
                  id={idModel.ticker?.id}
                  value={corporateProfile?.ticker_symbol}
                  disabled={!isCorporatePublic}
                  onChange={getTextboxHandler("ticker_symbol")}
                />
              </Ghostable>
            ),
          },
          {
            key: "Main Logo",
            width: "2-of-4",
            smallWidth: "1-of-1",
            label: "Main Logo",
            children: (
              <FileUploader
                id={idModel.logo.id}
                dropzoneProps={{ accept: ["image/*"] }}
                fileApi={handleFileUpload}
                fileUrl={corporateProfile?.logo_image}
                onChange={getTextboxHandler("logo_image")}
                showPreview={true}
              />
            ),
          },
          {
            key: "Secondary Logo",
            width: "2-of-4",
            smallWidth: "1-of-1",
            label: "Secondary Logo",
            children: (
              <FileUploader
                id={idModel.secondaryLogo.id}
                dropzoneProps={{ accept: ["image/*"] }}
                fileApi={handleFileUpload}
                fileUrl={corporateProfile?.secondary_logo_image}
                onChange={getTextboxHandler("secondary_logo_image")}
                showPreview={true}
              />
            ),
          },
          {
            key: "Description",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Description",
            children: (
              <Textarea
                id={idModel.description?.id}
                value={corporateProfile?.description}
                onChange={getTextboxHandler("description")}
                rows={4}
              />
            ),
          },
          {
            key: "Website",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Website",
            children: <Textbox id={idModel.website?.id} value={corporateProfile?.url} onChange={getTextboxHandler("url")} />,
          },
          {
            key: "Host Small Meetings",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Host Small Meetings",
            children: (
              <Checkbox
                id={idModel.hostMeetings.id}
                checked={corporateProfile?.host_small_meetings}
                onChange={getRadioChangeHandler("host_small_meetings")}
              />
            ),
          },
          {
            key: "Primary Contact",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Primary Contact",
            children: (
              <Select
                id={idModel.primaryContact?.id}
                preset={SelectPreset.Autocomplete}
                isClearable
                valueKey="_id"
                labelKey="display_name"
                value={primaryContact}
                options={corporateAttendees}
                onChange={handlePrimaryContactChange}
              />
            ),
          },
        ]}
      />
      {corporateProfile?._id && !isEmpty(schedulerDays) && (
        <div className={CorporateProfileAvailabilityFormClassName.Base}>
          <Text className={CorporateProfileAvailabilityFormClassName.Subheader} preset={TextPreset.Subheader}>
            {"Select your company's available time slots. This will apply to all attendees."}
          </Text>
          <AvailabilityForm
            days={schedulerDays}
            timeZone={conference?.time_zone}
            timeslots={timeslots}
            handleTimeslotChange={handleTimeslotChange}
          />
        </div>
      )}
      {!isEmpty(questionGroup) && (
        <Grid className={baseClassName}>
          <Text
            id={idModel.questionsHeader.id}
            className={CorporateProfileFormClassName.QuestionsHeader}
            preset={TextPreset.Subheader}
          >
            {questionGroup.title}
          </Text>
          <QuestionsForm
            id={idModel?.questionsForm?.id}
            questions={corporateQuestions}
            answers={corporateAnswers}
            setFormErrors={setFormErrors}
            onChange={handleAnswerChange}
          />
        </Grid>
      )}
    </>
  );
};

export default memo(CorporateProfileForm);
