import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import { MeetingRequest } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import { MeetingIssuersIdModel } from "./components/meetingIssuers/meetingIssuers.definition";

export interface MeetingRequestFormProps extends BaseComponentProps {
  conference: Conference;
  meetingRequests: MeetingRequest[];
  setMeetingRequests: Dispatch<SetStateAction<MeetingRequest[]>> | ((v: MeetingRequest[]) => void);
  title?: string;
  disabled?: boolean;
}

export enum MeetingRequestFormClassName {
  Base = "meeting-request-form",
}

export class MeetingRequestFormIdModel extends IdModelBase {
  meetingIssuers: MeetingIssuersIdModel;
  requestMeetings: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.meetingIssuers = new MeetingIssuersIdModel(`${this.id}MeetingIssuers`);
    this.requestMeetings = `${this.id}RequestMeetings`;
  }
}

export interface InvestorFormMeetingRequests {
  [profileId: string]: {
    meeting_type?: string;
    interest_level?: string;
  };
}

export interface SelectOption {
  label: string;
  value: string;
}
