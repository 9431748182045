import {
  AutomationListId,
  BaseComponentProps,
  ButtonIdModel,
  CardIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
} from "@q4/nimbus-ui";
import { Answer } from "../../../../../services/answer/answer.model";
import { QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";
import { RenderAnswerIdModel } from "./renderAnswer.definition";

export enum AnswerSummaryLabels {
  Edit = "Edit",
}

export interface AnswerSummaryProps extends BaseComponentProps {
  loading: boolean;
  questionGroup: Partial<QuestionGroup>;
  answers: Answer[];
  goToEdit?: () => void;
}

export class AnswerListId extends AutomationListId<RenderAnswerIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, RenderAnswerIdModel, postfix);
  }
}

export class AnswerSummaryIdModel extends IdModelBase {
  answerSummarySection: CardIdModel;
  list: AnswerListId;
  answerSummaryEdit: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.answerSummarySection = new CardIdModel(`${this.id}AnswerSummaryCard`);
    this.list = new AnswerListId(`${this.id}List`);
    this.answerSummaryEdit = new ButtonIdModel(`${this.id}AnswerSummaryEditButton`);
  }
}
