import { isEmpty, isNil, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import Papa from "papaparse";
import type { EntityBase, Entity } from "../../definitions/entity.definition";
import { fuzzyCompare } from "../../utils/string/string.utils";
import type { ApiResponse } from "../api/api.definition";
import ParallelService from "../parallel.service";
import {
  CsvAttendeeBase,
  CsvCorporateProfileBase,
  CsvMeetingBase,
  CsvOnDemandPresentationBase,
  CsvPresentationBase,
  ImportOptions,
} from "./import.definition";
import type { CsvBase, ImportResult, ImportServiceBase } from "./import.definition";
import ImportAttendeeService from "./importAttendee.service";
import ImportCorporateProfileService from "./importCorporateProfile.service";
import ImportMeetingService from "./importMeeting.service";
import ImportOnDemandService from "./importOnDemand.service";
import ImportPresentationService from "./importPresentation.service";

export class ImportService {
  private readonly notificationService = new NotificationService();
  private readonly parallelService = new ParallelService();

  private importService: ImportServiceBase<EntityBase>;
  private csvJson: CsvBase[];

  get Data(): EntityBase[] {
    return this.importService?.Data ?? [];
  }
  get HasSettings(): boolean {
    return isNil(this.importService?.HasSetting) ? false : this.importService?.HasSetting;
  }
  get Type(): Entity {
    return this.importService?.Type ?? null;
  }
  get Requests(): (() => Promise<ApiResponse<EntityBase>>)[] {
    return this.importService?.Requests ?? [];
  }

  fetch = async (file: File): Promise<void> => {
    const results = await new Promise<CsvBase[]>((resolve, reject) => {
      Papa.parse<CsvBase>(file, {
        delimiter: ",",
        header: true,
        transformHeader: this.sanitizeHeader,
        complete: (response) => {
          resolve(response?.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    }).catch((error): CsvBase[] => {
      this.notificationService.error("Failed to parse CSV file.");
      console.log(error.message);
      return [];
    });

    this.csvJson = results;
    const csvSample = (this.csvJson || [])[0];
    const csvKeys = isEmpty(csvSample) ? [] : Object.keys(csvSample);
    this.importService = this.getImportService(csvKeys);

    if (isEmpty(this.importService)) {
      this.notificationService.error("Invalid CSV file.");
    }
  };

  parse = (options: ImportOptions): Promise<ImportResult<EntityBase>[]> => {
    if (isEmpty(this.importService)) return Promise.resolve([]);
    return this.importService.parse({ ...options, csvJson: this.csvJson });
  };

  post = (cancel: () => Error, limit = this.parallelService.DefaultLimit): Promise<ApiResponse<EntityBase>[]> => {
    return this.parallelService.limit<ApiResponse<EntityBase>>(this.Requests, limit, cancel);
  };

  private sanitizeHeader = (header: string, idx: number): string => {
    if (isNullOrWhiteSpace(header)) return `${idx}`;
    return header.trim().toLocaleLowerCase().replace(/\s+/g, "_");
  };

  getImportService(csvKeys: string[]): ImportServiceBase<EntityBase> {
    if (isEmpty(csvKeys)) return null;

    const csvSource = [
      { keys: Object.keys(new CsvPresentationBase()), service: ImportPresentationService },
      { keys: Object.keys(new CsvOnDemandPresentationBase()), service: ImportOnDemandService },
      { keys: Object.keys(new CsvMeetingBase()), service: ImportMeetingService },
      { keys: Object.keys(new CsvAttendeeBase()), service: ImportAttendeeService },
      { keys: Object.keys(new CsvCorporateProfileBase()), service: ImportCorporateProfileService },
    ];

    let service = null;
    csvSource.forEach((source) => {
      let csvMatchKeyCount = 0;
      csvKeys.forEach((key) => {
        csvMatchKeyCount = source.keys.some((x) => fuzzyCompare(key, x)) ? csvMatchKeyCount + 1 : csvMatchKeyCount;
      });

      if (csvMatchKeyCount === source.keys.length) service = new source.service();
    });
    return service;
  }
}
