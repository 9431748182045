import "./anchorWithPopoverMenu.component.scss";
import {
  getClassName,
  isNullOrWhiteSpace,
  useVisibility,
  PopoverMenuProps,
  Origin,
  Anchor,
  PopoverMenu,
} from "@q4/nimbus-ui";
import React, { useMemo, memo } from "react";
import {
  AnchorWithPopoverMenuProps,
  AnchorWithPopoverMenuIdModel,
  AnchorWithPopoverMenuClassName,
} from "./anchorWithPopoverMenu.definition";

const AnchorWithPopoverMenu = (props: AnchorWithPopoverMenuProps): JSX.Element => {
  const { id, className, label, options } = props;

  const idModel = useMemo(() => new AnchorWithPopoverMenuIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(AnchorWithPopoverMenuClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const [menuVisible, handleOpenMenu, handleCloseMenu] = useVisibility();

  const popoverMenuProps: PopoverMenuProps = useMemo(
    () => ({
      anchorTargetElementId: idModel.anchor?.id,
      visible: menuVisible,
      options: (options || []).map((option) => ({
        ...option,
        className: getClassName(AnchorWithPopoverMenuClassName.PopoverMenuItem, [
          { condition: isNullOrWhiteSpace(option?.className), falseClassName: option?.className },
        ]),
      })),
      masked: true,
      targetOrigin: Origin.BottomLeft,
      popoverOrigin: Origin.TopLeft,
      onCloseRequest: handleCloseMenu,
    }),
    [idModel.anchor?.id, menuVisible, options, handleCloseMenu]
  );

  return (
    <span id={idModel.id} className={baseClassName}>
      <Anchor id={idModel.anchor?.id} className={AnchorWithPopoverMenuClassName.Anchor} onClick={handleOpenMenu}>
        {label}
      </Anchor>
      <PopoverMenu
        id={idModel.popoverMenu?.id}
        className={AnchorWithPopoverMenuClassName.PopoverMenu}
        {...popoverMenuProps}
      />
    </span>
  );
};

export default memo(AnchorWithPopoverMenu);
