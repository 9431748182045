import { AnchorIdModel, BadgeIdModel, BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Key } from "react";
import { Conference } from "../../../../../../services/conference/conference.model";

export interface ConferenceBannerDetailsProps extends BaseComponentProps {
  registrationUrl: string;
  lobbyUrl: string;
  loginUrl: string;
  profileUrl: string;
  conference: Conference;
}

export class ConferenceBannerDetailsIdModel extends IdModelBase {
  status: BadgeIdModel;
  chat: BadgeIdModel;
  registration: AnchorIdModel;
  login: AnchorIdModel;
  lobby: AnchorIdModel;
  profile: AnchorIdModel;
  headTimeZoneString: string;
  tailTimeZoneString: string;
  date: string;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);
    if (isNullOrWhiteSpace(this.id)) return;

    this.status = new BadgeIdModel(`${this.id}Status`);
    this.chat = new BadgeIdModel(`${this.id}Chat`);
    this.registration = new AnchorIdModel(`${this.id}Registration`);
    this.login = new AnchorIdModel(`${this.id}Login`);
    this.lobby = new AnchorIdModel(`${this.id}Lobby`);
    this.profile = new AnchorIdModel(`${this.id}Profile`);
    this.headTimeZoneString = `${this.id}HeadTimeZoneString`;
    this.tailTimeZoneString = `${this.id}TailTimeZoneString`;
    this.date = `${this.id}Date`;
  }
}
