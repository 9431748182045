import { Button, ButtonTheme, Form, InputType, Textbox } from "@q4/nimbus-ui";
import { flatten } from "lodash";
import React, { memo, useMemo } from "react";
import { NavigationLinksProps, NavigationLinksIdModel } from "./navigationLinks.definition";

const NavigationLinks = (props: NavigationLinksProps): JSX.Element => {
  const { value: conferenceLinks, className, id, onChange, onDelete, onAdd } = props;

  const idModel = useMemo(() => new NavigationLinksIdModel(id), [id]);

  const links = flatten(
    conferenceLinks?.map((link, index) => {
      return [
        {
          key: `Label${index}`,
          width: "1-of-4",
          label: "Label",
          children: (
            <Textbox
              id={idModel.label.getId(index)}
              value={link.label}
              inputType={InputType.Text}
              onChange={(value, event) => onChange(value, "label", index, event)}
            />
          ),
        },
        {
          key: `Url${index}`,
          width: "1-of-4",
          label: "Url",
          children: (
            <Textbox
              id={idModel.url.getId(index)}
              value={link.url}
              inputType={InputType.Url}
              onChange={(value, event) => onChange(value, "url", index, event)}
            />
          ),
        },
        {
          key: `Order${index}`,
          width: "1-of-4",
          label: "Order",
          children: (
            <Textbox
              id={idModel.order.getId(index)}
              value={link.order}
              inputType={InputType.Number}
              min={1}
              onChange={(value, event) => onChange(value, "order", index, event)}
            />
          ),
        },
        {
          key: `Delete${index}`,
          width: "80px",
          label: "Delete",
          children: (
            <Button
              id={idModel.deleteButton.getId(index)}
              theme={ButtonTheme.Q4Blue}
              onClick={() => onDelete(index)}
              icon="q4i-trashbin-4pt"
            />
          ),
        },
      ];
    })
  );

  links.push({
    key: "Label",
    width: "100px",
    label: "",
    children: (
      <Button
        id={idModel.addButton.id}
        onClick={() => onAdd({ url: "", order: 1, label: "" }, conferenceLinks?.length || 0)}
        label="Add navigation link"
        icon="q4i-add-4pt"
      />
    ),
  });

  return <Form id={idModel.id} className={className} fields={links} />;
};

export default memo(NavigationLinks);
