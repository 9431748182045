import { IdModelBase, isNullOrWhiteSpace, PopoverIdModel, SelectIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { AttendeeFormIdModel } from "../../../../../../components/attendee/form/attendeeForm.definition";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import { QuestionGroupHookModel } from "../../../../../../hooks/admin/useQuestionGroups/useQuestionGroups.definition";
import type { AttendeesHookModel } from "../../../../../../hooks/useAttendees/useAttendees.definition";
import type { PresentationsHookModel } from "../../../../../../hooks/usePresentations/usePresentations.definition";
import type { ServiceHookModel } from "../../../../../../hooks/useService/useService.definition";
import type { CompanyRouteParams } from "../../../../../../services/company/company.model";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../services/meeting/meeting.model";
import type MeetingService from "../../../../../../services/meeting/meeting.service";
import { AttendeePasswordModalIdModel } from "./components/attendeePasswordModal/attendeePasswordModal.definition";
import { ExportAgendaIdModel } from "./components/exportAgenda/exportAgenda.definition";

export interface AttendeesProps extends Pick<BaseComponentProps, "id"> {
  codes: string[];
  company: CompanyRouteParams;
  conference: Conference;
  corporateProfiles: CorporateProfile[];
  useAttendees: AttendeesHookModel;
  usePresentations: PresentationsHookModel;
  useMeetings: ServiceHookModel<Meeting, MeetingService>;
  useQuestionGroups: QuestionGroupHookModel;
  user: AdminUserContextState["user"];
  isSystemAdmin: AdminUserContextState["isSystemAdmin"];
  suppressColumnVirtualisation?: boolean; // Only used for unit tests
}

export enum AttendeesClassName {
  Base = "attendees",
  Content = "attendees_content",
  Table = "attendees_table",
  PDFExportButton = "attendees_pdf_button",
  ICSExportButton = "attendees_ics_button",
}

export enum AttendeeEditClassName {
  Base = "attendee-edit",
}

export class AttendeesIdModel extends IdModelBase {
  company: SelectIdModel;
  entityTable: EntityTableIdModel;
  export: ExportAgendaIdModel;
  exportList: PopoverIdModel;
  form: AttendeeFormIdModel;
  type: SelectIdModel;
  passwordModal: AttendeePasswordModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.company = new SelectIdModel(`${this.id}CompanySelect`);
    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.export = new ExportAgendaIdModel(`${this.id}Export`);
    this.exportList = new PopoverIdModel(`${this.id}ExportList`);
    this.form = new AttendeeFormIdModel(`${this.id}Form`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.passwordModal = new AttendeePasswordModalIdModel(`${this.id}AttendeePasswordModal`);
  }
}
