import { isEmpty } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { ConferenceBaseEntity } from "../../definitions/entity.definition";
import { VendorName } from "../conference/conference.model";

export interface SessionVendorOverride {
  vendor: VendorName;
  meeting_id?: string;
  meeting_password?: string;
  phone_password?: string;
  dial_in_numbers?: string[];
}

export enum SessionVendorPassword {
  Passcode = "passcode",
}

export enum SessionVendorUrl {
  Overridden = "overridden",
}

export enum DialInNumberType {
  Toll = "toll",
}

export enum DialInCountry {
  Australia = "AU",
  Canada = "CA",
  Switzerland = "CH",
  Germany = "DE",
  France = "FR",
  UnitedKingdom = "GB",
  Ireland = "IE",
  Netherlands = "NL",
  NewZealand = "NZ",
  US = "US",
}

export enum DialInCountryName {
  Australia = "Australia",
  Canada = "Canada",
  Switzerland = "Switzerland",
  Germany = "Germany",
  France = "France",
  UnitedKingdom = "United Kingdom",
  Ireland = "Ireland",
  Netherlands = "Netherlands",
  NewZealand = "New Zealand",
  US = "US",
}

export interface DialInNumber {
  country: DialInCountry;
  country_name: DialInCountryName;
  number: string;
  type: DialInNumberType;
}

export enum SessionEventType {
  Meeting = "meeting",
  OnDemandPresentation = "ondemandpresentation",
  Presentation = "presentation",
}

export interface SessionVendorData {
  dial_in_numbers: DialInNumber[];
  sip_url: string;
  sip_url_teams: string;
}

export interface SessionVendor {
  meeting_date: Date;
  password: string;
  url: string;
  vendor: VendorName;
  vendor_id: string;
  _data: SessionVendorData;
  _host: string;
}

export abstract class SessionBase extends ConferenceBaseEntity {
  start_date: Moment;
  end_date: Moment;
  urlOverride?: string;
  roomLocation?: string;
  labelOverride?: string;
  vendor?: SessionVendor[];
  vendor_override?: SessionVendorOverride;
  linkId?: string;

  constructor(session: Partial<SessionBase> | string) {
    super(session);
    if (typeof session === "string" || isEmpty(session)) return;

    Object.assign(this, session);
  }
}

export interface SessionUserInfo {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  vendor_id?: string;
}

export interface SessionProxyResponse {
  statusCode: number;
  headers: {
    "Cache-Control": string;
    "Location": string;
  };
  body: string;
}
