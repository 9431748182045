import {
  BaseComponentProps,
  BaseComponentWithChildrenProps,
  ButtonIdModel,
  CardIdModel,
  IdModelBase,
  isEmpty,
  isNullOrWhiteSpace,
  TextIdModel,
  ToolbarProps,
} from "@q4/nimbus-ui";
import { MeetingHookModel } from "../../../../../../hooks/useMeetings/useMeeting.definition";
import { AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import type { Conference, ConferenceSchedulerSlot } from "../../../../../../services/conference/conference.model";
import type { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../../services/presentation/presentation.model";
import { SessionEventType } from "../../../../../../services/session/session.model";
import { getCorporateAvailability } from "../../../../../../utils";
import { DayTabsIdModel } from "./components/dayTabs/dayTabs.definition";
import { FlexibleSchedulerIdModel } from "./components/flexibleScheduler/flexibleScheduler.definition";
import { SchedulerFilterIdModel } from "./components/schedulerFilter/schedulerFilter.definition";

export interface MeetingSchedulerProps extends BaseComponentProps {
  conference: Conference;
  presentations: Presentation[];
  companies: string[];
  codes: string[];
  toolbarProps: ToolbarProps;
  useMeetings: MeetingHookModel;
}

export enum MeetingSchedulerClassName {
  Base = "meeting-scheduler",
  Content = "meeting-scheduler_content",
  Header = "meeting-scheduler_header",
  HeaderSection = "meeting-scheduler_header-section",
  HeaderDate = "meeting-scheduler_header-date",
  Description = "meeting-scheduler_description",
  TimeZone = "meeting-scheduler_time-zone",
  DateTabs = "meeting-scheduler_date-tabs",
}

export enum SchedulerResourceType {
  Corporate,
  Investor,
}

export declare class SlotWrapperProps extends BaseComponentWithChildrenProps {
  resource?: string;
  value?: string;
}

export class SchedulerResource {
  _id: string;
  resourceTitle: string;
  availability: ConferenceSchedulerSlot[];
  attendees: AttendeeViewModel[];
  resourceType: SchedulerResourceType;

  constructor(resource: AttendeeViewModel | CorporateProfile) {
    this.resourceTitle =
      resource instanceof AttendeeViewModel ? `${resource?.display_name} (${resource?.company_name})` : resource?.name;
    this.resourceType =
      resource instanceof AttendeeViewModel ? SchedulerResourceType.Investor : SchedulerResourceType.Corporate;
    if (this.resourceType === SchedulerResourceType.Corporate && (resource as CorporateProfile)?.attendees) {
      resource.availability = getCorporateAvailability((resource as CorporateProfile)?.attendees);
    }

    Object.assign(this, resource);
  }
}

export class SchedulerEvent {
  title: React.ReactNode;
  start_date: Date;
  end_date: Date;
  resourceId: string;
  event: Meeting | Presentation;

  constructor(resourceId: string, title: React.ReactNode, event: Meeting | Presentation) {
    if (event.type === SessionEventType.Presentation) {
      const presentationType = (event as Presentation).presentation_type;
      this.title = (presentationType ? presentationType + " " + title : title).toString().substring(0, 50);
    } else {
      this.title = title;
    }

    this.start_date = event.start_date.toDate();
    this.end_date = event.end_date.toDate();
    this.resourceId = resourceId;
    this.event = event;
  }
}
export enum SchedulerMeetingStatus {
  Pending = "Pending",
  Scheduled = "Scheduled",
  Unscheduled = "Unscheduled",
}

export class SchedulerRequest {
  _id: string;
  requestStatus?: SchedulerMeetingStatus;
  meeting_requests?: AttendeeViewModel["meeting_requests"];
  attendees?: CorporateProfile["attendees"];
  name: string;
  title?: string;
  company?: string;

  constructor(request: SchedulerRequest) {
    if (typeof request === "string" || isEmpty(request)) return;
    Object.assign(this, request);
  }
}

export class MeetingSchedulerIdModel extends IdModelBase {
  flexibleScheduler: FlexibleSchedulerIdModel;
  container: CardIdModel;
  headerDate: TextIdModel;
  schedulerFilter: SchedulerFilterIdModel;
  tabs: DayTabsIdModel;
  schedulerMetricBar: string;
  addNew: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.flexibleScheduler = new FlexibleSchedulerIdModel(`${this.id}FlexibleScheduler`);
    this.container = new CardIdModel(`${this.id}FlexibleSchedulerContainer`);
    this.headerDate = new TextIdModel(`${this.id}HeaderDate`);
    this.schedulerFilter = new SchedulerFilterIdModel(`${this.id}SchedulerFilter`);
    this.schedulerMetricBar = `${this.id}RequestMetricBar`;
    this.tabs = new DayTabsIdModel(`${this.id}Tabs`);
    this.addNew = new ButtonIdModel(`${this.id}AddNewButton`);
  }
}
