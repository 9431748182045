import type { GridApi, GridReadyEvent, RowNode } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";

export const InitialPageSize = 15;
export const DefaultPageSizeOptions = [InitialPageSize, 25, 50, 100];
export enum YesNoOptions {
  Yes = "Yes",
  No = "No",
}

export interface TableHookProps {
  rowCount: number;
}

export interface TableHookModel<T> {
  gridApi: GridApi;
  page: number;
  pageCount: number;
  pageSize: number;
  searchFilter: string;
  showPagination: boolean;
  filterValues?: Partial<T>;
  doesExternalFilterPass: (node: RowNode) => boolean;
  handleClearFilter: () => void;
  handleFilter: (key: string, value: string) => void;
  handleGridReady: (grid: GridReadyEvent) => void;
  handlePaginationChange: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSearchInputChange: (search: string) => void;
  handleSearchQueryClear: () => void;
  handleSearchQueryRequest: () => void;
  isExternalFilterPresent: () => boolean;
}

export interface TablePaginationState {
  currentPage: number;
  pageSize: number;
  pageCount: number;
}
