import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import type { Settings } from "react-slick";
import { PanelWidth } from "../panel/panel.definition";

export const SlideWidth = PanelWidth + 32;

export interface SliderComponentProps extends BaseComponentWithChildrenProps, Settings {
  compact?: boolean;
}

export enum SliderClassName {
  Base = "slick-slider",
  BaseWithCompactModifier = "slick-slider--compact",
}
export class SliderIdModel extends IdModelBase {
  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;
  }
}
