import {
  BaseComponentProps,
  CheckboxIdModel,
  ScrollbarsIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
} from "@q4/nimbus-ui";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import { SchedulerRequest } from "../../meetingScheduler.definition";

export interface SchedulerFilterTabsProps {
  labels?: string[];
  selected?: number;
  onChange?: (tabIndex: number) => void;
}

export enum SchedulerFilterClassName {
  Base = "scheduler-filter",
  Selectors = "scheduler-filter_selectors",
  Tabs = "scheduler-filter_tabs",
  RequesterSearch = "scheduler-filter_search",
  Requests = "scheduler-filter_requests",
  RequesterSearchSection = "scheduler-filter_search-section",
  RequesterList = "scheduler-filter_requester-list",
}

export enum RequestItemClassName {
  Base = "request-item",
  Block = "request-item_block",
  BlockWithSelectedModifier = "request-item_block--selected",
  Header = "request-item_header",
  Details = "request-item_details",
  HeaderName = "request-item_header-name",
  HeaderSubName = "request-item_header-subname",
}

export enum SchedulerRequestType {
  Corporate = "Corporate Requests",
  Investor = "Investor Requests",
}

export interface SchedulerFilterProps extends BaseComponentProps {
  id: string;
  currentAttendee: AttendeeViewModel;
  currentCorporate: CorporateProfile;
  currentRequestType: number;
  attendees: AttendeeViewModel[];
  corporateProfiles: CorporateProfile[];
  requests: CorporateProfile[] | AttendeeViewModel[];
  meetingRequests: Meeting[];
  onAttendeeChange: (option: AttendeeViewModel) => void;
  onCorporateChange: (option: CorporateProfile) => void;
  onTabChange: (option: number) => void;
}

export class SchedulerFilterIdModel extends IdModelBase {
  corporateAttendeeFilterSelect: SelectIdModel;
  investorAttendeeFilterSelect: SelectIdModel;
  showPending: CheckboxIdModel;
  showScheduled: CheckboxIdModel;
  scrollbars: ScrollbarsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.corporateAttendeeFilterSelect = new SelectIdModel(`${this.id}CorporateAttendeesFilterSelect`);
    this.investorAttendeeFilterSelect = new SelectIdModel(`${this.id}InvestorAttendeesFilterSelect`);
    this.showPending = new CheckboxIdModel(`${this.id}ShowPendingCheckBox`);
    this.showScheduled = new CheckboxIdModel(`${this.id}ShowScheduledCheckBox`);
    this.scrollbars = new ScrollbarsIdModel(`${this.id}Scrollbars`);
  }
}

export const schedulerRequestSorting = (request1: SchedulerRequest, request2: SchedulerRequest): number => {
  if (request1.company > request2.company) {
    return 1;
  }
  if (request1.company < request2.company) {
    return -1;
  }
  if (request1.name > request2.name) {
    return 1;
  }
  if (request1.name < request2.name) {
    return -1;
  }
  return 0;
};
