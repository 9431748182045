import { IdModelBase, ButtonIdModel, CardIdModel, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";
import { TimeZoneSelectorIdModel } from "../../../components/timeZoneSelector/timeZoneSelector.definition";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";
import { TermsAndConditionsIdModel } from "../../termsAndConditions/termsAndConditions.definition";
import { AnswerSummaryIdModel } from "../answerSummary/answerSummary.definition";

export interface InvestorSummaryProps extends RegistrationFormProps {
  conference: Conference;
  loading: boolean;
  meetingRegistrationOpen: boolean;
  isEditMode?: boolean;
  questionGroup?: Partial<QuestionGroup>;
  isSlotsEmpty: boolean;
  onSubmit: () => void;
  setRegistrant: Dispatch<SetStateAction<RegistrantViewModel>>;
}

export enum InvestorSummaryMessage {
  NoMeetingRequests = "No meetings requested.",
}

export class InvestorSummaryIdModel extends IdModelBase {
  personalEdit: ButtonIdModel;
  availabilityEdit: ButtonIdModel;
  meetingEdit: ButtonIdModel;
  stepBack: ButtonIdModel;
  confirm: ButtonIdModel;
  personalInformationSection: CardIdModel;
  salesRepSection: string;
  secondaryContactSection: CardIdModel;
  availabilitySection: CardIdModel;
  meetingSection: CardIdModel;
  termsAndConditions: TermsAndConditionsIdModel;
  answerSummary: AnswerSummaryIdModel;
  timeZoneSelector: TimeZoneSelectorIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.personalEdit = new ButtonIdModel(`${this.id}PersonalEditButton`);
    this.availabilityEdit = new ButtonIdModel(`${this.id}AvailabilityEditButton`);
    this.meetingEdit = new ButtonIdModel(`${this.id}MeetingEditButton`);
    this.stepBack = new ButtonIdModel(`${this.id}StepBackButton`);
    this.confirm = new ButtonIdModel(`${this.id}ConfirmButton`);

    this.personalInformationSection = new CardIdModel(`${this.id}PersonalInformationCard`);
    this.salesRepSection = `${this.id}SalesRep`;
    this.secondaryContactSection = new CardIdModel(`${this.id}SecondaryContactCard`);
    this.availabilitySection = new CardIdModel(`${this.id}AvailabilityCard`);
    this.meetingSection = new CardIdModel(`${this.id}MeetingCard`);
    this.answerSummary = new AnswerSummaryIdModel(`${this.id}AnswerSummary`);

    this.termsAndConditions = new TermsAndConditionsIdModel(`${this.id}TermsAndConditions`);

    this.timeZoneSelector = new TimeZoneSelectorIdModel(`${this.id}TimeZoneSelector`);
  }
}
