import { isEmpty, isNullOrWhiteSpace, Message, MessageType, useVisibility } from "@q4/nimbus-ui";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  PusherChannelEvent,
  PusherEditResponse,
  PusherEvent,
  PusherMember,
  PusherMemberInfoViewModel,
  PusherSubscribeSuccessResponse,
} from "../usePusher/usePusher.definition";
import { usePusher } from "../usePusher/usePusher.hook";
import { PresentationPusherEvent, RefreshPresentationMessage } from "./useEditNotifications.definition";
import type { EditNotificationsHookModel, EditNotificationsHookProps, MessageBase } from "./useEditNotifications.definition";

export const useEditNotifications = (props: EditNotificationsHookProps): EditNotificationsHookModel => {
  const { channelName, disabled, entity, messageProps, user, id, onRefreshRequest, onSubmitRequest, onDestroy } = props;

  const [members, setMembers] = useState<PusherMemberInfoViewModel[]>([]);
  const isDirty = useRef(false);
  const [messageVisible, handleMessageOpen, handleMessageClose] = useVisibility();

  const email = useMemo(() => user?.email, [user]);
  const name = useMemo(() => user?.name, [user]);

  const message = useRef<MessageBase>();

  // #region Pusher Effects
  const useNotifications = useMemo(() => {
    const maxMembersAllowedForEditing = 1;
    if (isEmpty(members)) return false;
    return members.length > maxMembersAllowedForEditing;
  }, [members]);

  const handleRefresh = useCallback(async (): Promise<void> => {
    const success = await onRefreshRequest();
    if (!success) return;
    handleMessageClose();
  }, [handleMessageClose, onRefreshRequest]);

  const channelEvents = useMemo(
    () => [
      new PusherChannelEvent(PresentationPusherEvent.Edit, (data: PusherEditResponse) => {
        if (isNullOrWhiteSpace(id) || isEmpty(data)) return;
        setMembers((members) =>
          members.map((x: PusherMemberInfoViewModel) => {
            return x.email === data.email && id === data.id ? new PusherMemberInfoViewModel(x, true) : x;
          })
        );
      }),
      new PusherChannelEvent(PresentationPusherEvent.Save, (data: PusherMember) => {
        if (isEmpty(data) || data.id !== id) return;
        message.current = {
          ...RefreshPresentationMessage,
          primaryActionProps: {
            ...RefreshPresentationMessage.primaryActionProps,
            onClick: handleRefresh,
          },
        };
        handleMessageOpen();
      }),
      new PusherChannelEvent(PusherEvent.SubscriptionSucceeded, (data: PusherSubscribeSuccessResponse) => {
        if (isNullOrWhiteSpace(id)) return;
        const memberData = data?.members;
        const allMembers = isEmpty(memberData) ? [] : Object.values(memberData);
        const members = allMembers.reduce(
          (members, x) => (x.data === id ? members.concat(new PusherMemberInfoViewModel(x)) : members),
          [] as PusherMemberInfoViewModel[]
        );
        setMembers(members);
      }),
      new PusherChannelEvent(PusherEvent.MemberAdded, (member: PusherMember): void => {
        if (isEmpty(member) || isNullOrWhiteSpace(id)) return;
        const { info } = member;
        if (info?.data !== id) return;
        isDirty.current = false;
        setMembers((members) => [...members, new PusherMemberInfoViewModel(info)]);
      }),
      new PusherChannelEvent(PusherEvent.MemberRemoved, (member: PusherMember) => {
        if (isEmpty(member) || isNullOrWhiteSpace(id)) return;
        const { info } = member;
        if (isEmpty(info) || info.data !== id) return;
        setMembers((members) => members.filter((x) => x.email !== info.email));
      }),
    ],
    [handleMessageOpen, handleRefresh, id]
  );

  const handleClearMembers = useCallback((): void => {
    setMembers([]);
  }, []);

  const { channel } = usePusher({
    channelEvents,
    channelName,
    disabled,
    email,
    name,
    data: id,
    onChannelDestroy: handleClearMembers,
  });

  const triggerEditNotification = useCallback((): void => {
    if (isDirty.current || isEmpty(channel) || isNullOrWhiteSpace(email) || isNullOrWhiteSpace(id)) {
      return;
    }

    isDirty.current = true;
    channel.trigger(PresentationPusherEvent.Edit, {
      id,
      email,
      event: PresentationPusherEvent.Edit,
    });
  }, [channel, email, id]);
  // #region

  // #region Message Effects
  const handleSubmitClickRequest = useCallback(() => {
    onSubmitRequest()
      .then((success) => {
        if (!useNotifications || !success || isEmpty(channel)) return success;

        channel.trigger(PresentationPusherEvent.Save, { id });
        handleMessageClose();
        return success;
      })
      .then((success) => {
        if (!success) return;
        onDestroy();
      });
  }, [channel, id, useNotifications, handleMessageClose, onDestroy, onSubmitRequest]);

  const updateMessage = useMemo(
    (): MessageBase => ({
      title: `${messageProps?.title}?`,
      message: `Other users are potentially making changes to this ${entity}. Are you sure you would like to continue?`,
      messageType: MessageType.Confirmation,
      primaryActionProps: {
        label: messageProps.title,
        onClick: handleSubmitClickRequest,
      },
    }),
    [messageProps, entity, handleSubmitClickRequest]
  );

  function handleSubmitClick(): void {
    if (useNotifications) {
      message.current = updateMessage;
      handleMessageOpen();
      return;
    }
    handleSubmitClickRequest();
  }
  // #endregion

  const getMembersList = useCallback(
    (hasModified?: boolean): PusherMemberInfoViewModel[] => {
      return (members || []).reduce((members, member): PusherMemberInfoViewModel[] => {
        return member.email === email || member.modifying === !hasModified ? members : members.concat(member);
      }, [] as PusherMemberInfoViewModel[]);
    },
    [email, members]
  );

  const modifyingMembers = useMemo(() => getMembersList(true), [getMembersList]);
  const viewingMembers = useMemo(() => getMembersList(), [getMembersList]);

  const renderMessage = useCallback(() => {
    return (
      <Message
        visible={messageVisible}
        title={message.current?.title}
        message={message.current?.message}
        primaryActionProps={{
          ...message.current?.primaryActionProps,
          loading: messageProps.loading,
        }}
        secondaryActionProps={{
          label: "CANCEL",
          disabled: messageProps.loading,
          onClick: handleMessageClose,
        }}
      />
    );
  }, [messageVisible, messageProps.loading, handleMessageClose]);

  return {
    members,
    modifyingMembers,
    viewingMembers,
    useNotifications,
    handleSubmitClick,
    renderMessage,
    triggerEditNotification,
  };
};
