export const LOCAL_STORAGE_ITINERARY_SHOW_PAST_SESSIONS = "itinerary_sessions--show-past";

export const ChatSelector = "drift-frame-controller";
export const ChatOpenSelector = "drift-open-chat";

export const ItineraryDayFormat = "dddd, MMMM D, YYYY";

export const ItineraryPadding = 80;
export const ItineraryTabletPadding = 40;
export const ItineraryMobilePadding = 16;
