import {
  ComponentSizeModifier,
  convertStringToEnum,
  Form,
  FormFieldProps,
  getClassName,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  SelectTheme,
  isNil,
} from "@q4/nimbus-ui";
import { zip } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { VendorName, VideoVendor } from "../../../../../../services/conference/conference.model";
import {
  VideoVendorsFormClassName,
  VideoVendorsFormIdModel,
  VideoVendorsFormOption,
  VideoVendorsFormProps,
} from "./videoVendorsForm.definition";

const VideoVendorsForm = (props: VideoVendorsFormProps): JSX.Element => {
  const { className, id, vendor, onChange: onChangeProps, useAllVendorName = false, disabled = false } = props;

  const baseClassName = useMemo(
    () =>
      getClassName(VideoVendorsFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const idModel = useMemo(() => new VideoVendorsFormIdModel(id), [id]);

  const options = useMemo(
    () =>
      zip(Object.keys(VendorName), Object.values(VendorName))
        .filter(([, value]) => !(!useAllVendorName && value === VendorName.Webex))
        .map(([label, value]) => ({
          label: label.replace(/([A-Z])/g, " $1"),
          value,
        })),
    [useAllVendorName]
  );

  function getValue(): VideoVendorsFormOption {
    if (isNil(vendor)) return null;

    let vendorName = (vendor as VideoVendor).name;

    if (!vendorName) {
      vendorName = vendor as VendorName;
    }
    return options.find((option) => option.value === vendorName);
  }

  const value = useMemo(getValue, [vendor, options]);

  const handleVideoVendorChange = useCallback(
    (option: VideoVendorsFormOption) => {
      const changedVendorName = convertStringToEnum(VendorName, option?.value);
      return onChangeProps({
        name: changedVendorName,
      });
    },
    [onChangeProps]
  );

  const fields = useMemo((): FormFieldProps[] => {
    return [
      {
        key: "Video Vendor",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Video Vendor",
        children: (
          <>
            <Select
              id={idModel.select.id}
              value={value}
              disabled={disabled}
              onChange={handleVideoVendorChange}
              options={options}
              valueKey="value"
              labelKey="label"
              theme={SelectTheme.LightGrey}
              size={ComponentSizeModifier.Small}
              preset={SelectPreset.Dropdown}
              isClearable={true}
              placeholder="Select Video Conference Vendor"
            />
          </>
        ),
      },
    ];
  }, [idModel.select, handleVideoVendorChange, value, options, disabled]);

  return <Form id={idModel.id} className={baseClassName} fields={fields} />;
};

export default memo(VideoVendorsForm);
