import "./login.view.scss";
import {
  isEmpty,
  isNullOrWhiteSpace,
  LayoutHeight,
  LayoutPadding,
  NotificationService,
  PlaceholderContent,
  PlaceholderContentTheme,
  Text,
  TextPreset,
} from "@q4/nimbus-ui";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import BackgroundPlaceholder from "../../../assets/itinerary/background-placeholder.png";
import CompanyBranding from "../../../components/companyBranding/companyBranding.component";
import { BrandingVariables } from "../../../components/companyBranding/companyBranding.definition";
import contextSubscribe from "../../../contexts/context.subscribe";
import { UserContext } from "../../../contexts/user/user.context";
import { usePublicConference } from "../../../hooks/public/usePublicConference/usePublicConference.hook";
import PasswordlessAuth0Service from "../../../services/auth0/passwordlessAuth0/passwordlessAuth0.service";
import PasswordlessTokenService from "../../../services/passwordlessToken/passwordlessToken.service";
import QueryService from "../../../services/query/query.service";
import { getBackgroundImageUrl, getCompanyRoute, getConferenceRoute, htmlParse, validateEmail } from "../../../utils";
import { CompanyLobbyParams } from "../../company/lobby/companyLobby.definition";
import LoadingState from "../components/loadingState/loadingState.component";
import LoginForm from "./components/loginForm/loginForm.component";
import { LoginApiResponse } from "./components/loginForm/loginForm.definition";
import { CompanyLoginIdModel as IdModel, LoginAccess, LoginClassName, LoginProps, LoginQuery } from "./login.definition";

export const Login = (props: LoginProps): JSX.Element => {
  const history = useHistory();
  const { userContext } = props;
  const { loading, handleLogin, handleLoginPasswordless, isAuthenticated } = userContext;
  const {
    company,
    loading: conferenceLoading,
    logoImage: companyImage,
    conference,
    hasConference,
  } = usePublicConference({
    autoFetchData: true,
  });

  const params = useParams<CompanyLobbyParams>();

  const [requirePassword, setRequirePassword] = useState(false);

  const [loginResponse, setLoginResponse] = useState<LoginApiResponse>(null);
  const notificationService = useRef(new NotificationService());

  const companyName = useMemo(() => company?.name, [company?.name]);
  const conferenceTitle = useMemo(() => conference?.title, [conference?.title]);

  const pageTitle = useMemo(() => {
    if (isNullOrWhiteSpace(companyName)) return "Conference Login";
    return `${companyName} Conference Login`;
  }, [companyName]);

  const loginPromptText = useMemo(() => {
    if (!isNullOrWhiteSpace(conference?.login?.message)) {
      return htmlParse(conference.login.message);
    }

    let title = !isNullOrWhiteSpace(companyName) ? companyName : "Conference Platform";

    if (!isNullOrWhiteSpace(conferenceTitle)) {
      title = conferenceTitle;
    }

    return `Login to the ${title} to continue`;
  }, [companyName, conferenceTitle, conference]);

  const disclaimerHtml = useMemo(() => htmlParse(conference?.disclaimer), [conference?.disclaimer]);

  const conferenceBranding = useMemo(() => new BrandingVariables(conference?.branding), [conference?.branding]);

  const loginBackgroundImage = useMemo(() => {
    const background = conference?.login?.background || BackgroundPlaceholder;
    const secondaryBackgroundRgb = conferenceBranding.secondaryBackgroundRgb;
    const tertiaryRgb = conferenceBranding.tertiary;
    const linearGradient = `linear-gradient(to top, ${tertiaryRgb} 0, rgba(${secondaryBackgroundRgb}, 0.5) 65%, rgba(${secondaryBackgroundRgb}, 0.85) 100%)`;
    return isNullOrWhiteSpace(secondaryBackgroundRgb)
      ? getBackgroundImageUrl(background)
      : `${linearGradient}, ${getBackgroundImageUrl(background)}`;
  }, [conference?.login?.background, conferenceBranding.secondaryBackgroundRgb, conferenceBranding.tertiary]);

  useEffect(() => {
    const queryService = new QueryService();

    const error = queryService.get(LoginQuery.Error);
    if (!isNullOrWhiteSpace(error)) {
      notificationService.current.error(error);
    }

    const requirePassword = queryService.get(LoginQuery.Login) === LoginAccess;
    setRequirePassword(requirePassword);
  }, []);

  useEffect(() => {
    if (!isAuthenticated() || isEmpty(company)) return;

    const { custom_path, url_suffix } = company;
    const { _id, path } = conference || {};
    const conferencePath = path || _id || params?.conferencePath;

    const route = !isNullOrWhiteSpace(conferencePath)
      ? getConferenceRoute(url_suffix, custom_path, conferencePath)
      : getCompanyRoute(url_suffix, custom_path);
    history.push(route);
  }, [company, conference, history, isAuthenticated, params]);

  async function handleCodeRequest(email: string): Promise<void> {
    const emailInput = email?.toLocaleLowerCase();

    const conferenceId = conference?._id;

    const response = await handleLoginPasswordless(
      {
        value: emailInput,
        test: (value) => !validateEmail(value),
      },
      conferenceId,
      company,
      true
    );

    const { data, ...loginResponse } = response;
    setLoginResponse(loginResponse);
  }

  async function handleLoginRequest(email: string, password: string, passcode: string): Promise<void> {
    const emailInput = email?.toLocaleLowerCase();

    const conferenceId = conference?._id;

    const response = await handleLogin(
      { value: emailInput, test: (value) => !validateEmail(value) },
      conferenceId,
      company,
      {
        value: password,
        required: requirePassword,
      },
      passcode
    );

    const { data, ...loginResponse } = response;
    setLoginResponse(loginResponse);

    if (!loginResponse.success) {
      setLoginResponse(loginResponse);
      return;
    }

    const passwordlessTokenService = new PasswordlessTokenService();

    const passwordlessAuth0Service = new PasswordlessAuth0Service();
    const redirectUrl = passwordlessAuth0Service.handleAuthentication({
      idToken: data.id_token,
      companySuffix: company.url_suffix,
      customSuffix: company.custom_path,
      conferencePath: conference.Path,
      conferenceId,
    });

    passwordlessTokenService.setToken(passwordlessAuth0Service.AuthToken);

    if (!passwordlessTokenService.isAuthenticatedBySessionId()) {
      passwordlessTokenService.signOut();
      return;
    }

    if (isNullOrWhiteSpace(redirectUrl)) return;
    history.replace(redirectUrl);
  }

  return (
    <>
      <CompanyBranding haloProps={{ title: pageTitle }} branding={conference?.branding} />
      <LoadingState loading={conferenceLoading} error={!hasConference}>
        <div id={IdModel.id}>
          <section
            className={LoginClassName.Base}
            style={{
              backgroundImage: loginBackgroundImage,
            }}
          >
            <PlaceholderContent
              id={IdModel.placeholder?.id}
              className={LoginClassName.PlaceholderContent}
              theme={PlaceholderContentTheme.Dark}
              image={conference?.image_logo ?? companyImage}
              title={loginPromptText}
              subtitle={
                <div className={LoginClassName.Content}>
                  <LoginForm
                    id={IdModel.form.id}
                    className={LoginClassName.Credentials}
                    conference={conference}
                    company={company}
                    loading={loading}
                    loginResponse={loginResponse}
                    showPassword={requirePassword}
                    onLogin={handleLoginRequest}
                    onRequest={handleCodeRequest}
                  />
                  {!isEmpty(disclaimerHtml) && (
                    <div className={LoginClassName.Disclaimer}>
                      <Text preset={TextPreset.Title} className={LoginClassName.DisclaimerTitle}>
                        Disclaimer
                      </Text>
                      <div className={LoginClassName.DisclaimerContent}>{disclaimerHtml}</div>
                    </div>
                  )}
                </div>
              }
              layoutProps={{
                height: LayoutHeight.Viewport,
                padding: LayoutPadding.Default,
              }}
            />
          </section>
        </div>
      </LoadingState>
    </>
  );
};

export default contextSubscribe([{ context: UserContext, mapToProps: "userContext" }], memo(Login));
