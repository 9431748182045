import { WidgetProps } from "@rjsf/core";
import React from "react";
import { FilePreviewer } from "../../filePreviewer/filePreviewer.component";

const ImagePreviewWidget = (props: WidgetProps): JSX.Element => {
  const { value, disabled, onChange, id } = props;
  return (
    <FilePreviewer
      id={id}
      disabled={disabled}
      onChange={onChange}
      fileUrl={value}
      dropzoneProps={{
        accept: ["image/*"],
      }}
    />
  );
};
export default ImagePreviewWidget;
