import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { Entity } from "../../../definitions/entity.definition";
import { AdminProfile } from "../../../services/admin/adminProfile/adminProfile.model";
import AdminProfileService from "../../../services/admin/adminProfile/adminProfile.service";
import { ApiResponse } from "../../../services/api/api.definition";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import type { ServiceHookPropsBase } from "../../useService/useService.definition";
import { useService } from "../../useService/useService.hook";
import { AdminProfileHookModel } from "./useAdminProfile.definition";

export const useAdminProfile = (props: ServiceHookPropsBase<AdminProfile>): AdminProfileHookModel => {
  const { autoFetchData, data, showNotifications, useOffline } = props;

  const notificationService = useMemo(() => new NotificationService(), []);

  const { current, loading, service, setCurrent, setLoading } = useService({
    autoFetchData: false,
    data,
    entityName: Entity.Admin,
    showNotifications,
    useOffline,
    entityModel: AdminProfile,
    serviceModel: AdminProfileService,
  });

  const _handleResponse = useCallback(
    (response: ApiResponse<AdminProfile>): AdminProfile => {
      setLoading(false);
      if (!response.success) {
        showNotifications && notificationService.error("Failed to get admin profile.");
        return null;
      }

      const entity = response?.data;

      setCurrent(entity);

      return entity;
    },
    [notificationService, setCurrent, setLoading, showNotifications]
  );

  const fetchByEmail = useCallback((): Promise<AdminProfile> => {
    setLoading(true);

    return service.getByEmail().then(_handleResponse);
  }, [_handleResponse, service, setLoading]);

  useAutoFetch<AdminProfile, null>({
    autoFetchData,
    fetch: fetchByEmail,
  });

  return {
    fetchByEmail,
    current,
    loading,
    service,
    setCurrent,
    setLoading,
  };
};
