import "./navigation.component.scss";
import {
  AnchorTarget,
  ButtonProps,
  ButtonTheme,
  getClassName,
  Ghostable,
  isEmpty,
  isNullOrWhiteSpace,
  objectSortComparer,
  Opacity,
  Origin,
  PopoverMenu,
  PopoverMenuButtonProps,
  PopoverMenuTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AttendeeProfileRoute } from "../../../../../../../configurations/navigation.configuration";
import { ChatOpenSelector, ChatSelector } from "../../../../../../../const";
import { AttendeeViewModel } from "../../../../../../../services/attendee/attendee.model";
import { LobbyDefaults } from "../../../../../../../services/conference/conference.model";
import { getAccessibilityKeyPressHandler, getLobbyUrl } from "../../../../../../../utils";
import { ItineraryAnchors, ItineraryClassName } from "../../../../../../public/itinerary/itinerary.definition";
import type { CompanyLobbyParams } from "../../../../companyLobby.definition";
import { getLobbyOpen } from "../../../../companyLobby.utils";
import type { NavigationProps } from "./navigation.definition";
import { NavigationClassName, NavigationIdModel } from "./navigation.definition";

declare global {
  interface Window {
    drift: {
      api: {
        toggleChat: () => void;
      };
    };
  }
}

const Navigation = (props: NavigationProps): JSX.Element => {
  const params = useParams<CompanyLobbyParams>();
  const history = useHistory();
  const { id, conference, onSignOut: onSignOutRequest, profile, isAttendeeProfile } = props;
  const { display_name: attendeeName } = profile || {};

  const { lobbyOpen } = useMemo(() => getLobbyOpen(conference, profile || ({} as AttendeeViewModel)), [conference, profile]);

  const toggleRef = useRef<HTMLDivElement>();
  const driftContainerRef = useRef<HTMLDivElement>();

  const [visible, handlePopoverOpen, handlePopoverClose] = useVisibility();
  const [popoverMenu, setPopoverMenu] = useState(getPopoverMenu(window.outerWidth));
  const [chatAvailable, setChatAvailable] = useState(false);

  const showAgenda = useMemo(
    () => !conference?.lobby?.agenda?.disabled && lobbyOpen && !isAttendeeProfile,
    [conference?.lobby?.agenda?.disabled, lobbyOpen, isAttendeeProfile]
  );
  const agendaTitle = useMemo(
    () =>
      !isNullOrWhiteSpace(conference?.lobby?.agenda?.title) ? conference.lobby.agenda.title : LobbyDefaults.agenda.title,
    [conference?.lobby?.agenda?.title]
  );

  const speakers = useMemo(() => conference?._speaker, [conference]);
  const showSpeakers = useMemo(
    () => !isEmpty(speakers) && !conference?.lobby?.speakers?.disabled && lobbyOpen && !isAttendeeProfile,
    [conference?.lobby?.speakers?.disabled, speakers, lobbyOpen, isAttendeeProfile]
  );
  const speakerTitle = useMemo(
    () =>
      !isNullOrWhiteSpace(conference?.lobby?.speakers?.title)
        ? conference.lobby.speakers.title
        : LobbyDefaults.speakers.title,
    [conference?.lobby?.speakers?.title]
  );

  const idModel = useMemo(() => new NavigationIdModel(id), [id]);
  const baseClassName = useMemo(getBaseClassName, [popoverMenu]);

  const sortedNavigationLinks = useMemo(
    () => (conference?.navigation_links || []).sort(objectSortComparer("order")),
    [conference]
  );

  const handleSignOut = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      onSignOutRequest && onSignOutRequest();
    },
    [onSignOutRequest]
  );

  const handleChatRequest = useCallback((event: MouseEvent): void => {
    event.preventDefault();

    if (!driftContainerRef.current || isEmpty(window?.drift)) return;

    window.drift?.api?.toggleChat();
  }, []);

  const lobbyUrl = useMemo((): string => {
    const conferencePath = params?.conferencePath ?? params?.conferenceId ?? conference?.path ?? conference?._id;
    return getLobbyUrl(params?.company, params?.custom, conferencePath);
  }, [conference, params]);

  const getAnchorLink = useCallback((anchor: string): string => `${lobbyUrl}#${anchor}`, [lobbyUrl]);

  const goToAttendeeProfile = useCallback((): void => {
    if (isAttendeeProfile) return;
    history.push(`${lobbyUrl}${AttendeeProfileRoute}`);
  }, [history, isAttendeeProfile, lobbyUrl]);

  const popoverMenuOptions = useMemo(getPopoverMenuOptions, [
    popoverMenu,
    showSpeakers,
    idModel,
    speakerTitle,
    getAnchorLink,
    showAgenda,
    lobbyOpen,
    isAttendeeProfile,
    sortedNavigationLinks,
    chatAvailable,
    goToAttendeeProfile,
    handleSignOut,
    agendaTitle,
    handleChatRequest,
  ]);

  useEffect(() => {
    const getChatButton = setTimeout(() => {
      const Chat = document.getElementById(ChatSelector) as HTMLDivElement;

      if (!Chat) return;

      driftContainerRef.current = Chat;
      setChatAvailable(true);
    }, 2500);

    return () => {
      clearTimeout(getChatButton);
    };
  }, []);

  useEffect(() => {
    function handleResize(): void {
      const newPopoverMenu = getPopoverMenu(window.innerWidth);

      if (popoverMenu !== newPopoverMenu) {
        setPopoverMenu(newPopoverMenu);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [popoverMenu]);

  function getPopoverMenu(windowWidth: number): boolean {
    return windowWidth <= 768;
  }

  function getBaseClassName(): string {
    return getClassName(NavigationClassName.Base, [
      { condition: popoverMenu, trueClassName: NavigationClassName.BaseWithPopoverMenuModifier },
    ]);
  }

  function getPopoverMenuOptions(): PopoverMenuButtonProps[] {
    if (!popoverMenu) return [];

    const buttonProps: Partial<ButtonProps> = {
      className: NavigationClassName.Anchor,
      theme: ButtonTheme.White,
    };

    const menuButtonProps: PopoverMenuButtonProps[] = showSpeakers
      ? [
          {
            ...buttonProps,
            id: idModel.mobileSpeakers,
            label: speakerTitle,
            linkTo: getAnchorLink(ItineraryAnchors.Speakers),
          },
        ]
      : [];

    if (showAgenda) {
      menuButtonProps.push({
        ...buttonProps,
        id: idModel.mobileAgenda,
        label: agendaTitle,
        linkTo: getAnchorLink(ItineraryAnchors.Agenda),
      });
    }

    if (lobbyOpen && !isAttendeeProfile && sortedNavigationLinks.length) {
      menuButtonProps.push(
        ...sortedNavigationLinks.map((link, index) => ({
          ...buttonProps,
          id: idModel.popoverMenuNavLink.getId(index),
          label: link.label,
          linkTo: link.url,
          linkTarget: AnchorTarget.Blank,
        }))
      );
    }

    if (chatAvailable) {
      menuButtonProps.push({
        ...buttonProps,
        id: idModel.mobileChat,
        className: `${NavigationClassName.Anchor} ${ChatOpenSelector}`,
        icon: "q4i-chat-4pt",
        label: "Live Support",
        linkTo: getAnchorLink(ItineraryAnchors.Chat),
        onClick: handleChatRequest,
      });
    }

    menuButtonProps.push({
      ...buttonProps,
      id: idModel.mobileAttendeeProfile,
      label: "Attendee Information",
      icon: "q4i-contact-4pt ",
      disabled: isAttendeeProfile,
      onClick: goToAttendeeProfile,
    });

    menuButtonProps.push({
      ...buttonProps,
      id: idModel.mobileSignOut,
      label: "Logout",
      icon: "q4i-export-4pt",
      linkTo: getAnchorLink(ItineraryAnchors.SignOut),
      onClick: handleSignOut,
    });

    return menuButtonProps;
  }

  return (
    <nav id={idModel.id} className={baseClassName}>
      {!popoverMenu ? (
        <ul className={NavigationClassName.List}>
          {showSpeakers && (
            <li className={NavigationClassName.Item}>
              <a
                id={idModel.speakers}
                className={NavigationClassName.Anchor}
                href={getAnchorLink(ItineraryAnchors.Speakers)}
              >
                {speakerTitle}
              </a>
            </li>
          )}
          <Ghostable ghosted={!showAgenda}>
            <li className={NavigationClassName.Item}>
              <a id={idModel.agenda} className={NavigationClassName.Anchor} href={getAnchorLink(ItineraryAnchors.Agenda)}>
                {agendaTitle}
              </a>
            </li>
          </Ghostable>
          {lobbyOpen &&
            !isAttendeeProfile &&
            sortedNavigationLinks.map((link, index) => (
              <li id={idModel.navigationLink?.getId(index)} key={link.url} className={NavigationClassName.Item}>
                <a className={NavigationClassName.Anchor} href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.label}
                </a>
              </li>
            ))}
          <Ghostable ghosted={!chatAvailable}>
            <li className={NavigationClassName.Item}>
              <a
                id={idModel.chat}
                className={`${NavigationClassName.Anchor} ${ChatOpenSelector}`}
                href={getAnchorLink(ItineraryAnchors.Chat)}
                onClick={handleChatRequest}
              >
                <span className="q4i-chat-4pt" /> Live Support
              </a>
            </li>
          </Ghostable>
          <li className={NavigationClassName.Item}>
            <div
              id={idModel.attendeeProfile}
              ref={toggleRef}
              className={NavigationClassName.Anchor}
              onClick={handlePopoverOpen}
            >
              {attendeeName}
            </div>
            <PopoverMenu
              key="navigation_attendee-profile_popover-menu"
              id={idModel.attendeeProfileMenu?.id}
              className={`${ItineraryClassName.Base} ${NavigationClassName.AttendeeProfileMenu}`}
              visible={visible}
              maskOpacity={Opacity.Low}
              anchorTargetReference={toggleRef}
              targetOrigin={Origin.BottomRight}
              popoverOrigin={Origin.TopRight}
              theme={PopoverMenuTheme.Steel}
              focusOnProps={{
                autoFocus: false,
              }}
              options={[
                {
                  id: idModel.attendeeInformation,
                  label: "Attendee Information",
                  theme: ButtonTheme.Slate,
                  icon: "q4i-contact-4pt ",
                  disabled: isAttendeeProfile,
                  onClick: goToAttendeeProfile,
                },
                {
                  id: idModel.signOut,
                  label: "Logout",
                  linkTo: getAnchorLink(ItineraryAnchors.SignOut),
                  theme: ButtonTheme.Slate,
                  icon: "q4i-export-4pt",
                  onClick: handleSignOut,
                },
              ]}
              onCloseRequest={handlePopoverClose}
            />
          </li>
        </ul>
      ) : (
        [
          <div
            key="navigation_toggle"
            ref={toggleRef}
            id={idModel.toggle}
            className={NavigationClassName.Toggle}
            tabIndex={0}
            onClick={handlePopoverOpen}
            onKeyPress={getAccessibilityKeyPressHandler(handlePopoverOpen)}
          >
            <span className="sr-only">{visible ? "Close" : "Open"} Navigation menu</span>
            <i className={visible ? "ni-close-4pt" : "q4i-menu"} />
          </div>,
          <PopoverMenu
            key="navigation_popover-menu"
            id={idModel.popoverMenu?.id}
            className={`${ItineraryClassName.Base} ${NavigationClassName.PopoverMenu}`}
            visible={visible}
            maskOpacity={Opacity.Low}
            anchorTargetReference={toggleRef}
            targetOrigin={Origin.BottomRight}
            popoverOrigin={Origin.TopRight}
            focusOnProps={{
              autoFocus: true,
            }}
            options={popoverMenuOptions}
            onCloseRequest={handlePopoverClose}
          />,
        ]
      )}
    </nav>
  );
};

Navigation.defaultProps = {
  collapseToggleIcon: "q4i-caret-left-4pt",
};

export default memo(Navigation);
