import { BaseComponentProps, ButtonIdModel, ButtonProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { ReactNode } from "react";
import type { RouteComponentProps } from "react-router";
import { VersionIdModel } from "../../../../components/version/version.definition";
import type { NavigationRoute } from "../../../../configurations/navigation.configuration";
import { AdminProfileRoleAccess } from "../../../../services/admin/adminProfile/adminProfile.model";

// #region Definitions
export interface NavigationProps extends BaseComponentProps, RouteComponentProps {
  logo: ReactNode;
  collapsedLogo?: ReactNode;
  routes: NavigationRoute[];
  collapseToggleIcon?: string;
  footerActions?: ButtonProps[];
  role: AdminProfileRoleAccess;
  onRouteChange?: (path: string) => void;
}

export enum NavigationClassName {
  Base = "nui-navigation",
  BaseWithCollapsedModifier = "nui-navigation--collapsed",
  Logo = "nui-navigation_logo",
  List = "nui-navigation_list",
  Item = "nui-navigation_item",
  Route = "nui-navigation_route",
  RouteWithActiveModifier = "nui-navigation_route--active",
  Icon = "nui-navigation_icon",
  Label = "nui-navigation_label",
  Footer = "nui-navigation_footer",
  CollapseToggle = "nui-navigation_collapse-toggle",
}

export class NavigationIdModel extends IdModelBase {
  version: VersionIdModel;
  helpCentreButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.version = new VersionIdModel(`${this.id}Version`);
    this.helpCentreButton = new ButtonIdModel(`${this.id}HelpCentreButton`);
  }
}

// #endregion
