import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import Helmet from "react-helmet";
import type { BrandingVariables } from "../companyBranding/companyBranding.definition";
import type { HaloProps } from "./halo.definition";

// TODO: Load CSS3 polyfill if CSS3 Variables are used: https://www.npmjs.com/package/ie11-custom-properties
const Halo = (props: HaloProps): JSX.Element => {
  const { cssVariables, favicon, title, link, ...helmetProps } = props;
  const cssVariablesString = useMemo(getCSSVariablesString, [cssVariables]);

  function getCSSVariablesString(): string {
    if (isEmpty(cssVariables)) return null;

    const variables = Object.keys(cssVariables);

    return variables.reduce((cssVariablesString, key, index) => {
      const variable = cssVariables[key as keyof BrandingVariables];

      if (!isNullOrWhiteSpace(variable)) {
        cssVariablesString += `--${key}: ${variable}; `;
      }

      if (index === variables.length - 1) cssVariablesString += "}";

      return cssVariablesString;
    }, ":root {");
  }

  return (
    <Helmet {...helmetProps}>
      {!!favicon && <link rel="icon" type="image/png" href={favicon} />}
      {!!title && <title>{title}</title>}
      {!!cssVariablesString && <style>{cssVariablesString}</style>}
      {(link || []).map((x, i) => (
        <link key={`custom-link-${i}`} href={x.href} rel={x.rel} />
      ))}
    </Helmet>
  );
};

export default memo(Halo);
