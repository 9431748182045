import { isEmpty } from "@q4/nimbus-ui";
import type { Entity, EntityBase } from "../../definitions/entity.definition";
import type { ApiResponse } from "../api/api.definition";
import { AttendeeViewModel } from "../attendee/attendee.model";
import type { Attendee as AttendeeModel } from "../attendee/attendee.model";
import type { Conference } from "../conference/conference.model";

export const CSVPresentationDateFormat = "MMMM D, YYYY h:mm A";

export type CsvBase = { [x: string]: string };
export class CsvPresentationBase {
  "presentation_date": string;
  "presentation_start": string;
  "presentation_end": string;
  "presentation_name": string;
  "presentation_id": string;
  "timezone": string;
  "track_name": string;
}
export type CsvPresentation = CsvPresentationBase & CsvBase;

export class CsvOnDemandPresentationBase {
  "ondemand_id": string;
  "ondemand_title": string;
  "ondemand_code": string;
  "ondemand_url": string;
  "ondemand_description": string;
  "ondemand_order": string;
}
export type CsvOnDemandPresentation = CsvOnDemandPresentationBase & CsvBase;

export class CsvMeetingBase {
  "meeting_date": string;
  "meeting_start": string;
  "meeting_end": string;
  "email_txt": string;
  "first_nm": string;
  "fullname": string;
  "last_nm": string;
  "contact_type": string;
  "contact_company_nm": string;
  "contact_title": string;
  "meeting_id": string;
  "timezone": string;
}
export type CsvMeeting = CsvMeetingBase & CsvBase;

export enum CsvAttendeePermission {
  Access = "Y",
  Denied = "",
}

export type CsvAttendeePermissions = { [x: string]: CsvAttendeePermission };
export class CsvAttendeeBase {
  first: string;
  last: string;
  email: string;
  type: string;
  company: string;
}

export interface CsvAttendeeOptional
  extends Omit<AttendeeModel, "first_name" | "last_name" | "email" | "type" | "company" | "options" | "code"> {
  title?: string;
  code?: string;
  sales_rep?: string;
}

export type CsvAttendee = CsvBase & (CsvAttendeePermissions | CsvAttendeeBase | CsvAttendeeOptional);

export class AttendeeImportModel extends AttendeeViewModel {
  session_id: string;

  constructor(entity: Partial<AttendeeImportModel>) {
    const { session_id, ...attendeeViewModel } = entity;
    super(attendeeViewModel);
    this.session_id = session_id;
  }
}

export class CsvCorporateProfileBase {
  company_name: string;
  private_public: string;
  meetings: string;
}

export type CsvCorporateProfile = CsvCorporateProfileBase & CsvBase;

export class ImportResult<T> {
  type?: Entity;
  title: string;
  entity: Partial<T>;
  csv?: CsvBase;
  errorMessage?: string;
  actionName?: string;

  constructor(result?: ImportResult<T>) {
    if (isEmpty(result)) return;

    const { csv, entity, errorMessage, title, type } = result;

    this.csv = csv;
    this.entity = entity;
    this.errorMessage = errorMessage;
    this.title = title;
    this.type = type;
    this.actionName = "Imported";
  }
}

export enum ImportPresentationError {
  Empty = "Presentation is Empty",
  InvalidEventId = "Invalid Presentation Event Id",
  InvalidStartDate = "Invalid Start Date",
  InvalidEndDate = "Invalid End Date",
  InvalidTitle = "Invalid Title",
  IsPastDate = "Presentation Start Date Is Not Future Date",
}

export enum ImportOnDemandError {
  Empty = "On Demand is Empty",
  InvalidEventId = "Invalid OnDemand Event Id",
  InvalidTitle = "Invalid Title",
  InvalidOrder = "Invalid Order",
  InvalidUrl = "Invalid Url",
}

export enum ImportMeetingError {
  Empty = "Meeting is Empty",
  InvalidEventId = "Invalid Meeting Event Id",
  InvalidStartDate = "Invalid Start Date",
  InvalidEndDate = "Invalid End Date",
  IsPastDate = "Meeting Start Date Is Not Future Date",
}

export enum ImportAttendeeError {
  Empty = "Attendee is Empty",
  Email = "Invalid Email",
  CorporateProfileEmpty = "Corporate Profile is Empty",
}

export enum ImportCorporateProfileError {
  Empty = "Corporate Profile is Empty",
  Email = "Invalid Email",
}

export interface ImportOptions<T extends CsvBase = CsvBase> {
  csvJson?: T[];
  conference: Conference;
  handleResults: <TEntity extends EntityBase>(
    type: Entity,
    response: ApiResponse<TEntity>,
    titleKey: keyof TEntity,
    cvs: CsvBase
  ) => void;
  futureOnly?: boolean;
  update?: boolean;
}

export interface ImportServiceBase<T> {
  Data: T[];
  Requests: (() => Promise<ApiResponse<T>>)[];
  HasSetting: boolean;
  Type: Entity;

  parse(options: ImportOptions): Promise<ImportResult<T>[]>;
}
