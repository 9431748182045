import "./personalAgendaButton.component.scss";
import { isNullOrWhiteSpace, Collapsable, Button, getClassName } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { LobbyDefaults } from "../../../../../services/conference/conference.model";
import {
  PersonalAgendaButtonClassName,
  PersonalAgendaButtonIdModel,
  PersonalAgendaButtonProps,
} from "./personalAgendaButton.definition";

const PersonalAgendaButton = (props: PersonalAgendaButtonProps): JSX.Element => {
  const { id, hasPresentation, lobby, loading, presentationId, onAgendaAdd, onAgendaRemove } = props;

  const idModel = useMemo(() => new PersonalAgendaButtonIdModel(id), [id]);

  const hideAgenda = useMemo(() => !!lobby?.agenda?.disabled, [lobby?.agenda?.disabled]);

  const baseClassName = getClassName(PersonalAgendaButtonClassName.Base, [
    {
      condition: hasPresentation,
      trueClassName: PersonalAgendaButtonClassName.BaseWithRemoveModifier,
    },
    {
      condition: loading,
      trueClassName: PersonalAgendaButtonClassName.BaseWithDisabledModifier,
    },
  ]);

  const tracksLabel = useMemo(
    () => (!isNullOrWhiteSpace(lobby?.tracks?.label) ? lobby.tracks.label : LobbyDefaults.tracks.label),
    [lobby?.tracks?.label]
  );

  function handleButtonClick(): void {
    hasPresentation ? handlePresentationRemove() : handlePresentationAdd();
  }

  function handlePresentationAdd(): void {
    onAgendaAdd(presentationId);
  }

  function handlePresentationRemove(): void {
    onAgendaRemove(presentationId);
  }

  const hasAddRemoveMethods = onAgendaAdd instanceof Function && onAgendaRemove instanceof Function;

  return (
    <Collapsable collapsed={hideAgenda || !hasAddRemoveMethods}>
      <Button
        id={idModel.id}
        className={baseClassName}
        icon="q4i-add-4pt"
        label={`${hasPresentation ? "Remove from" : "Add to"} ${tracksLabel}`}
        disabled={loading}
        onClick={handleButtonClick}
      />
    </Collapsable>
  );
};

export default memo(PersonalAgendaButton);
