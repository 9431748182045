import { isEmpty } from "@q4/nimbus-ui";
import type { Moment } from "moment-timezone";
import { DefaultTimeZone } from "../../../../const";
import { Conference, GuestAttendeeType } from "../../../../services/conference/conference.model";
import { parseTimeZone, convertToLocal } from "../../../../utils";
import type { ConferenceEditState } from "./conferenceEdit.definition";
import { ConferenceEndOfDay, ConferenceStartOfDay } from "./conferenceEdit.definition";

export function convertConferenceEditToConference(
  conferenceEdit: ConferenceEditState,
  previousTimeZone: Conference["time_zone"]
): Conference {
  if (isEmpty(conferenceEdit)) return null;

  const {
    open_date,
    start_date,
    end_date,
    close_date,
    time_zone: timeZoneSelect,
    auto_register,
    guest_other_types_enabled,
    corporate_deadlines,
    investor_deadlines,
    corporate_register,
    investor_register,
    general_register,
    internal_register,
    scheduler,
    ...conference
  } = conferenceEdit;
  const { value: time_zone } = timeZoneSelect;

  const parsedScheduler = scheduler;

  if (parsedScheduler?.start_date) {
    parsedScheduler.start_date = parseTimeZone(
      scheduler.start_date.clone().tz(previousTimeZone),
      time_zone,
      ConferenceStartOfDay
    ).utc();
  }
  if (parsedScheduler?.end_date) {
    parsedScheduler.end_date = parseTimeZone(
      scheduler.end_date.clone().tz(previousTimeZone),
      time_zone,
      ConferenceStartOfDay
    ).utc();
  }

  if (!isEmpty(parsedScheduler?.slots)) {
    parsedScheduler.slots = scheduler.slots.map(({ start_time, end_time }) => ({
      start_time: start_time.clone().tz(previousTimeZone).tz(time_zone, true).utc(),
      end_time: end_time.clone().tz(previousTimeZone).tz(time_zone, true).utc(),
    }));
  }

  return new Conference({
    ...conference,
    open_date: parseTimeZone(open_date, time_zone, ConferenceStartOfDay).utc(),
    start_date: parseTimeZone(start_date, time_zone, ConferenceStartOfDay).utc(),
    end_date: parseTimeZone(end_date, time_zone, ConferenceEndOfDay).utc(),
    close_date: parseTimeZone(close_date, time_zone, ConferenceEndOfDay).utc(),
    auto_register: {
      ...auto_register,
      start_date: parseTimeZone(auto_register.start_date, time_zone, ConferenceStartOfDay)?.utc(),
      end_date: parseTimeZone(auto_register.end_date, time_zone, ConferenceEndOfDay)?.utc(),
    },
    corporate_register: {
      start_date: parseTimeZone(corporate_register.start_date, time_zone, ConferenceStartOfDay)?.utc(),
      end_date: parseTimeZone(corporate_register.end_date, time_zone, ConferenceEndOfDay)?.utc(),
    },
    investor_register: {
      start_date: parseTimeZone(investor_register.start_date, time_zone, ConferenceStartOfDay)?.utc(),
      end_date: parseTimeZone(investor_register.end_date, time_zone, ConferenceEndOfDay)?.utc(),
    },
    general_register: {
      start_date: parseTimeZone(general_register.start_date, time_zone, ConferenceStartOfDay)?.utc(),
      end_date: parseTimeZone(general_register.end_date, time_zone, ConferenceEndOfDay)?.utc(),
    },
    internal_register: {
      start_date: parseTimeZone(internal_register.start_date, time_zone, ConferenceStartOfDay)?.utc(),
      end_date: parseTimeZone(internal_register.end_date, time_zone, ConferenceEndOfDay)?.utc(),
    },
    time_zone,
    guest_attendee_types: guest_other_types_enabled ? Object.values(GuestAttendeeType) : [],
    lobby: {
      ...conference.lobby,
      corporate_lobby: conference.lobby.corporate_lobby?.open_date && {
        open_date: parseTimeZone(conference.lobby.corporate_lobby.open_date, time_zone, ConferenceStartOfDay).utc(),
      },
      investor_lobby: conference.lobby.investor_lobby?.open_date && {
        open_date: parseTimeZone(conference.lobby.investor_lobby.open_date, time_zone, ConferenceStartOfDay).utc(),
      },
      general_lobby: conference.lobby.general_lobby?.open_date && {
        open_date: parseTimeZone(conference.lobby.general_lobby.open_date, time_zone, ConferenceStartOfDay).utc(),
      },
    },
    corporate_deadlines: {
      speaker: parseTimeZone(corporate_deadlines?.speaker, time_zone, ConferenceStartOfDay)?.utc(),
      presentation: parseTimeZone(corporate_deadlines?.presentation, time_zone, ConferenceStartOfDay)?.utc(),
      meeting: parseTimeZone(corporate_deadlines?.meeting, time_zone, ConferenceStartOfDay)?.utc(),
    },
    investor_deadlines: {
      meeting_request: parseTimeZone(investor_deadlines?.meeting_request, time_zone, ConferenceStartOfDay)?.utc(),
      availability_changes: parseTimeZone(investor_deadlines?.availability_changes, time_zone, ConferenceStartOfDay)?.utc(),
    },
    scheduler: parsedScheduler,
  });
}

export function checkLobbyOpenDate(newDate: Moment, conference: ConferenceEditState): boolean {
  if (!newDate || !conference?.open_date || !conference?.close_date) return true;
  const timeZone = conference?.time_zone?.value || DefaultTimeZone;
  const dateNoOffset = convertToLocal(newDate, timeZone);

  if (dateNoOffset.isBefore(conference.open_date) || dateNoOffset.isAfter(conference.close_date)) return false;
  return true;
}
