import { isNullOrWhiteSpace, LoadingScreen } from "@q4/nimbus-ui";
import React, { memo, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import AdminTokenService from "../../../services/adminToken/adminToken.service";
import { AdminAuth0Service } from "../../../services/auth0/adminAuth0/adminAuth0.service";

const AdminCallback = (): JSX.Element => {
  const history = useHistory();
  const adminAuth0Service = useMemo(() => new AdminAuth0Service(), []);

  useEffect(() => {
    const tokenService = new AdminTokenService();

    const redirectUrl = adminAuth0Service.handleAuthentication();
    tokenService.setToken(adminAuth0Service.AuthToken);

    if (!tokenService.isAuthenticated()) {
      // TODO: this shouldn't be like this
      tokenService.removeToken();
      adminAuth0Service.signOut();
      return;
    }

    if (isNullOrWhiteSpace(redirectUrl)) return;
    history.replace(redirectUrl);
  }, [adminAuth0Service, history]);

  return <LoadingScreen />;
};

export default memo(AdminCallback);
