import "./dateSelect.component.scss";
import {
  Button,
  ButtonTheme,
  getClassName,
  isEmpty,
  Origin,
  PopoverMenu,
  PopoverMenuButtonProps,
  useVisibility,
} from "@q4/nimbus-ui";
import { Moment } from "moment/moment";
import React, { memo, useMemo, useRef } from "react";
import { ItineraryDayFormat } from "../../../../../const";
import { ItineraryClassName } from "../../itinerary.definition";
import { DateSelectClassName, DateSelectIdModel, DateSelectProps } from "./dateSelect.definition";

function DateSelect(props: DateSelectProps): JSX.Element {
  const { id, selectedDate, dateOptions, onSelect } = props;
  const [visible, onOpen, onClose] = useVisibility();

  const buttonRef = useRef<HTMLButtonElement>();

  const singleDate = useMemo(() => isEmpty(dateOptions) || dateOptions.length === 1, [dateOptions]);
  const idModel = useMemo(() => new DateSelectIdModel(id), [id]);
  const baseClassName = useMemo(getBaseClassName, [singleDate, visible]);
  const options = useMemo(getOptions, [idModel, singleDate, dateOptions, onSelect]);

  function getBaseClassName(): string {
    return getClassName(DateSelectClassName.Base, [
      { condition: singleDate, trueClassName: DateSelectClassName.BaseWithEmptyModifier },
      { condition: visible, trueClassName: DateSelectClassName.BaseWithOpenModifier },
    ]);
  }

  function getOptions(): PopoverMenuButtonProps[] {
    if (singleDate) return [];

    function getSelectHandler(date: Moment): () => void {
      return function (): void {
        onSelect && onSelect(date);
      };
    }

    return dateOptions.map((date, i) => {
      const id = idModel.dates.getId(i);
      return {
        id,
        key: `date-select_option--${date.toString()}`,
        className: DateSelectClassName.Button,
        theme: ButtonTheme.Transparent,
        label: date.format(ItineraryDayFormat),
        onClick: getSelectHandler(date),
      };
    });
  }

  if (isEmpty(selectedDate)) return null;

  return (
    <div id={idModel.id} className={baseClassName}>
      {!singleDate && <span className={DateSelectClassName.Label}>Select Date:</span>}
      <Button
        ref={buttonRef}
        id={idModel.button?.id}
        className={DateSelectClassName.Button}
        label={selectedDate.format(ItineraryDayFormat)}
        disabled={singleDate}
        onClick={onOpen}
      />
      <PopoverMenu
        id={idModel.popoverMenu?.id}
        className={`${ItineraryClassName.Base} ${DateSelectClassName.PopoverMenu}`}
        visible={visible}
        anchorTargetReference={buttonRef}
        targetOrigin={Origin.BottomLeft}
        popoverOrigin={Origin.TopLeft}
        matchWidth
        options={options}
        onCloseRequest={onClose}
      />
    </div>
  );
}

export default memo(DateSelect);
