import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { DateRange } from "moment-range";
import type { Dispatch, SetStateAction } from "react";
import type { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import { TimeZoneSelectorIdModel } from "../../../components/timeZoneSelector/timeZoneSelector.definition";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";

export interface RegistrationAvailabilityProps extends RegistrationFormProps {
  conference: Conference;
  timeslots: Timeslots;
  setRegistrant: Dispatch<SetStateAction<RegistrantViewModel>>;
  setTimeslots: Dispatch<SetStateAction<Timeslots>>;
  disabled?: boolean;
}

export interface Timeslot {
  slot: DateRange;
  isSelected: boolean;
}

export interface Timeslots {
  [x: string]: Timeslot;
}

export const AvailabilityDateFormat = "dddd, MMMM D";
export const AvailabilityTimeFormat = "h:mm A";

export class RegistrationAvailabilityIdModel extends IdModelBase {
  previousStep: ButtonIdModel;
  nextStep: ButtonIdModel;
  timeZoneSelector: TimeZoneSelectorIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.previousStep = new ButtonIdModel(`${this.id}PreviousStepButton`);
    this.nextStep = new ButtonIdModel(`${this.id}NextStepButton`);
    this.timeZoneSelector = new TimeZoneSelectorIdModel(`${this.id}TimezoneSelector`);
  }
}
