import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface PoweredByProps extends BaseComponentProps {
  title: string;
}

export enum PoweredByClassName {
  Base = "powered-by",
  Title = "powered-by_title",
  Anchor = "powered-by_anchor",
}

export class PoweredByIdModel extends IdModelBase {
  title: string;
  anchor: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = `${this.id}Title`;
    this.anchor = `${this.id}Anchor`;
  }
}
