import { RoutePathIdLabel } from "../../configurations/navigation.configuration";

export function getUrlParam(routePath: string, label: RoutePathIdLabel): string {
  const pathName = window.location.pathname;
  const routeParams = routePath.split("/");
  const urlParams = pathName.split("/");
  let routeParamsMatch = true;

  return routeParams.reduce((_param, current, i) => {
    if (routeParamsMatch && urlParams[i] !== current && current !== label && current.indexOf(":") === -1) {
      routeParamsMatch = false;
    }
    return routeParamsMatch && current === label ? urlParams[i] : "";
  }, "");
}
