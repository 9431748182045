import { Form, Textbox } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import ColorTextbox from "../../../../../../components/colorTextbox/colorTextbox.component";
import { ConferenceBrandingIdModel, ConferenceBrandingProps } from "./branding.definition";

const ConferenceBranding = (props: ConferenceBrandingProps): JSX.Element => {
  const { id, branding, getStateChangeHandler } = props;

  const idModel = useMemo(() => new ConferenceBrandingIdModel(id), [id]);

  return (
    <Form
      id={idModel.id}
      fields={[
        {
          key: "Primary Color",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Primary Color",
          children: (
            <ColorTextbox
              id={idModel.primaryColor?.id}
              value={branding?.color_primary}
              onChange={getStateChangeHandler("branding.color_primary")}
            />
          ),
        },
        {
          key: "Secondary Color",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Secondary Color",
          children: (
            <ColorTextbox
              id={idModel.secondaryColor?.id}
              value={branding?.color_secondary}
              onChange={getStateChangeHandler("branding.color_secondary")}
            />
          ),
        },
        {
          key: "Tertiary Color",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Tertiary Color",
          children: (
            <ColorTextbox
              id={idModel.tertiaryColor?.id}
              value={branding?.color_tertiary}
              onChange={getStateChangeHandler("branding.color_tertiary")}
            />
          ),
        },
        {
          key: "Secondary Background Color",
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: "Secondary Background Color",
          children: (
            <ColorTextbox
              id={idModel.secondaryBackgroundColor?.id}
              value={branding?.color_secondary_background}
              onChange={getStateChangeHandler("branding.color_secondary_background")}
            />
          ),
        },
        {
          key: "Heading Font Family",
          width: "3-of-4",
          smallWidth: "1-of-1",
          label: "Heading Font Family",
          children: (
            <Textbox
              id={idModel.headingFontFamily?.id}
              value={branding?.font_header?.name}
              onChange={getStateChangeHandler("branding.font_header.name")}
            />
          ),
        },
        {
          key: "Heading Font Path",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Heading Font Path",
          children: (
            <Textbox
              id={idModel.headingFontPath?.id}
              value={branding?.font_header?.path}
              onChange={getStateChangeHandler("branding.font_header.path")}
            />
          ),
        },
        {
          key: "Heading Font Color",
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: "Heading Font Color",
          children: (
            <ColorTextbox
              id={idModel.headingFontColor?.id}
              value={branding?.font_header?.color}
              onChange={getStateChangeHandler("branding.font_header.color")}
            />
          ),
        },
        {
          key: "Body Font Family",
          width: "3-of-4",
          smallWidth: "1-of-1",
          label: "Body Font Family",
          children: (
            <Textbox
              id={idModel.bodyFontFamily?.id}
              value={branding?.font_body?.name}
              onChange={getStateChangeHandler("branding.font_body.name")}
            />
          ),
        },
        {
          key: "Body Base Font Size",
          width: "1-of-4",
          smallWidth: "1-of-1",
          label: "Body Base Font Size",
          children: (
            <Textbox
              id={idModel.bodyFontSizeBase?.id}
              value={branding?.font_body?.base_size}
              onChange={getStateChangeHandler("branding.font_body.base_size")}
            />
          ),
        },
        {
          key: "Body Font Path",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Body Font Path",
          children: (
            <Textbox
              id={idModel.bodyFontPath?.id}
              value={branding?.font_body?.path}
              onChange={getStateChangeHandler("branding.font_body.path")}
            />
          ),
        },
        {
          key: "Body Font Color",
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: "Body Font Color",
          children: (
            <ColorTextbox
              id={idModel.bodyFontColor?.id}
              value={branding?.font_body?.color}
              onChange={getStateChangeHandler("branding.font_body.color")}
            />
          ),
        },
      ]}
    />
  );
};

export default memo(ConferenceBranding);
