import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.component.scss";
import { ForwardedRef, getClassName, isEmpty } from "@q4/nimbus-ui";
import React, { createRef, forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SlickSlider from "react-slick";
import { ItineraryMobilePadding, ItineraryPadding, ItineraryTabletPadding } from "../../../../../const";
import { SlideWidth, SliderComponentProps, SliderIdModel, SliderClassName } from "./slider.definition";

function Slider(props: SliderComponentProps, ref: ForwardedRef<SlickSlider>): JSX.Element {
  const { id, compact, className, ...rest } = props;

  const sliderRef = useMemo(() => (isEmpty(ref) ? createRef<SlickSlider>() : ref), [ref]);
  const sliderContainerRef = useRef<HTMLDivElement>();
  const idModel = useMemo(() => new SliderIdModel(id), [id]);
  const baseClassName = useMemo(
    () => getClassName(className ?? "", [{ condition: !!compact, trueClassName: SliderClassName.BaseWithCompactModifier }]),
    [className, compact]
  );

  const [sliderWidth, setSliderWidth] = useState(0);
  const slidesToShow = useMemo(getSlidesToShow, [sliderWidth]);

  function getSlidesToShow(): SliderComponentProps["slidesToShow"] {
    return Math["floor"](sliderWidth / SlideWidth);
  }

  const handleResize = useCallback(() => {
    const newSliderWidth = getSliderWidth(sliderContainerRef?.current?.offsetWidth ?? 0);

    if (newSliderWidth > sliderWidth + SlideWidth / 2 || newSliderWidth < sliderWidth - SlideWidth / 2) {
      setSliderWidth(newSliderWidth);
    }
  }, [sliderWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (!isEmpty(sliderContainerRef.current)) {
      setSliderWidth(getSliderWidth(sliderContainerRef?.current?.offsetWidth ?? 0));
    }
  }, [sliderContainerRef]);

  function getSliderWidth(sliderWidth: number): number {
    if (sliderWidth <= 480) {
      return sliderWidth - ItineraryMobilePadding * 2;
    }

    if (sliderWidth <= 1024) {
      return sliderWidth - ItineraryTabletPadding * 2;
    }

    if (sliderWidth <= 1526) {
      return sliderWidth - ItineraryTabletPadding * 2;
    }

    return 1526 - ItineraryPadding * 2;
  }

  return (
    <div id={idModel.id} ref={sliderContainerRef}>
      <SlickSlider
        className={baseClassName}
        dots={false}
        speed={300}
        slidesToScroll={1}
        infinite={false}
        variableWidth
        draggable={false}
        slidesToShow={slidesToShow}
        ref={sliderRef}
        {...rest}
      />
    </div>
  );
}

export default memo(forwardRef(Slider));
