import "./loadingSpinner.component.scss";
import { getClassName, isNullOrWhiteSpace, Spinner, SpinnerProps, SpinnerTheme } from "@q4/nimbus-ui";
import React, { useMemo, memo } from "react";
import { LoadingSpinnerClassName } from "./loadingSpinner.definition";

const LoadingSpinner = (props: SpinnerProps): JSX.Element => {
  const { className, theme, ...rest } = props;

  const baseClassName = useMemo(
    () =>
      getClassName(LoadingSpinnerClassName.Base, [{ condition: isNullOrWhiteSpace(className), trueClassName: className }]),
    [className]
  );

  return <Spinner className={baseClassName} theme={theme ?? SpinnerTheme.Citrus} fixed {...rest} />;
};

export default memo(LoadingSpinner);
