import { TextboxIdModel, IdModelBase, isNullOrWhiteSpace, TextboxProps } from "@q4/nimbus-ui";

export const ModalContentHeight = 210;

export interface PasswordTextboxProps extends TextboxProps {
  initialVisibility?: boolean;
  value: string;
}

export enum PasswordTextboxClassName {
  Base = "password-textbox",
  Textbox = "password-textbox_textbox",
  Icon = "password-textbox_icon",
}

export class PasswordTextboxIdModel extends IdModelBase {
  textbox: TextboxIdModel;
  icon: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.textbox = new TextboxIdModel(`${this.id}PasswordTextbox`);
    this.icon = `${this.id}PasswordTextboxIcon`;
  }
}
