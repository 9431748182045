import "./attendeeForm.component.scss";
import {
  ComboBox,
  convertStringToEnum,
  Form,
  FormFieldProps,
  getClassName,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  Textbox,
  Checkbox,
  TextPreset,
  Text,
  Grid,
  Origin,
  TooltipTheme,
  InfoIcon,
  Button,
  ButtonType,
  TextTheme,
} from "@q4/nimbus-ui";
import moment from "moment-timezone";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DefaultTimeZone } from "../../../const";
import { DateFormat } from "../../../definitions/date.definition";
import { useComboBox } from "../../../hooks";
import { useAvailability } from "../../../hooks/useAvailability/availabilityHook.component";
import { MeetingRequest } from "../../../services/admin/registrant/registrant.model";
import { Answer } from "../../../services/answer/answer.model";
import {
  AttendeeType,
  AttendeeTypesWithAvailability,
  AttendeeTypesWithOptionalParticipation,
  AttendeeTypesWithRepresentatives,
  AttendeeViewModel,
} from "../../../services/attendee/attendee.model";
import { ConferenceSchedulerSlot, ConferenceType, GuestAttendeeType } from "../../../services/conference/conference.model";
import { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import { InvestorType } from "../../../services/investor/investor.model";
import MeetingRequestForm from "../../../views/public/register/components/meetingRequestForm/meetingRequestForm.component";
import AvailabilityForm from "../../availabilityForm/availabilityForm.component";
import QuestionsForm from "../../questionsForm/questionsForm.component";
import type { AttendeeFormProps } from "./attendeeForm.definition";
import { AttendeeFormClassName, AttendeeFormIdModel, CreateNewCompanySuffix } from "./attendeeForm.definition";

const AttendeeForm = (props: AttendeeFormProps): JSX.Element => {
  const {
    id,
    attendee,
    className,
    codes,
    companies,
    corporateProfiles,
    conference,
    questionGroups,
    attendeeAnswers,
    onAttendeeUpdate,
    isSystemAdmin,
    onGeneratePassword,
    loading,
    formErrors,
    setFormErrors,
  } = props;

  const isPresentationOnly = useMemo(
    () => conference?.conference_type === ConferenceType.PresentationOnly,
    [conference?.conference_type]
  );

  const isCorporate = useMemo(() => attendee?.type === AttendeeType.Corporate, [attendee?.type]);
  const isInvestor = useMemo(() => attendee?.type === AttendeeType.Investor, [attendee?.type]);
  const isGuest = useMemo(() => attendee?.type === AttendeeType.Guest, [attendee?.type]);
  const isInternal = useMemo(() => attendee?.type === AttendeeType.Internal, [attendee?.type]);

  const questionGroup = useMemo(
    () => (questionGroups || []).find((group) => group.attendee_types.includes(attendee?.type)),
    [attendee?.type, questionGroups]
  );

  const attendeeQuestions = useMemo(() => {
    return (questionGroup?._questions || []).filter((q) => {
      if ((isCorporate && !!q.is_individual_corporate) || !isCorporate) return true;
      return false;
    });
  }, [isCorporate, questionGroup?._questions]);

  useEffect(() => {
    if (!isInternal || !isNullOrWhiteSpace(attendee?.company)) return;

    onAttendeeUpdate({ company: conference?._company?.name });
  }, [attendee?.company, conference?._company?.name, isInternal, onAttendeeUpdate]);

  const attendeeProfile = useMemo(() => {
    const profile = new CorporateProfile(attendee?._corporate_profile);
    return (corporateProfiles || []).find((x) => x._id === profile._id);
  }, [attendee?._corporate_profile, corporateProfiles]);

  const {
    timeslots,
    days: schedulerDays,
    handleTimeslotChange,
  } = useAvailability({
    timeZone: conference?.time_zone,
    scheduler: conference?.scheduler,
    availability: attendee?.availability,
    onAvailabilityChange: handleAvailabilityChange,
  });

  const [companyInputValue, setCompanyInputValue] = useState();

  const timeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference?.time_zone]);

  const idModel = useMemo(() => new AttendeeFormIdModel(id), [id]);
  const companySelectOptions = useMemo(getCompanySelectOptions, [companies, companyInputValue]);

  const baseClassName = useMemo(
    () =>
      getClassName(AttendeeFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const attendeeTypeOptions = useMemo(
    () => Object.values(AttendeeType).map((x) => convertStringToEnum(AttendeeType, x)),
    []
  );
  const investorTypeOptions = useMemo(
    () => Object.values(InvestorType).map((x) => convertStringToEnum(InvestorType, x)),
    []
  );
  const guestAttendeeTypeOptions = useMemo(
    () => Object.values(GuestAttendeeType).map((x) => convertStringToEnum(GuestAttendeeType, x)),
    []
  );

  const {
    input: presentationCodeInput,
    handleInputChange: handlePresentationCodeInputChange,
    chipsHook: usePresentationCodeChips,
    filteredOptions: codeOptions,
  } = useComboBox({
    options: codes,
    chipsHookProps: {
      entities: attendee?.code,
    },
  });
  const [presentationCodeChips, handlePresentationCodeChipAdd, handlePresentationCodeChipRemove] = usePresentationCodeChips;

  function getCompanySelectOptions(): string[] {
    const companyOptions = companies ?? [];
    if (isNullOrWhiteSpace(companyInputValue)) return companyOptions;

    return [`${companyInputValue}${CreateNewCompanySuffix}`, ...companyOptions];
  }

  function handleAvailabilityChange(availability: ConferenceSchedulerSlot[]) {
    if (isNil(availability)) return;

    onAttendeeUpdate({
      availability,
    });
  }

  const handleAnswerChange = useCallback(
    (currentAnswer: Answer) => {
      const answers = attendeeAnswers || [];
      const targetAnswer = answers?.find((ans) => ans._question === currentAnswer._question);

      if (isEmpty(targetAnswer)) {
        answers.push(currentAnswer);
      } else {
        targetAnswer.answer = currentAnswer.answer;
      }
      onAttendeeUpdate({
        custom_question_answers: answers,
      });
    },
    [attendeeAnswers, onAttendeeUpdate]
  );

  const attendeeFields = useMemo((): FormFieldProps[] => {
    // #region Field Handlers
    function handleCompanyChange(option: string): void {
      if (isNullOrWhiteSpace(option)) return;

      let company = option;

      if (company.includes(CreateNewCompanySuffix)) {
        company = company.replace(CreateNewCompanySuffix, "");
      }

      onAttendeeUpdate({
        company,
      });
    }

    function handleCorporateProfileChange(_corporate_profile: CorporateProfile): void {
      if (isEmpty(_corporate_profile)) return;

      onAttendeeUpdate({
        _corporate_profile,
      });
    }

    function handleTypeChange(option: AttendeeType): void {
      if (isEmpty(option)) return;

      const isOptionInternal = option === AttendeeType.Internal;
      const isOptionGuest = option === AttendeeType.Guest;
      const isOptionInvestor = option === AttendeeType.Investor;
      const isOptionCorporate = option === AttendeeType.Corporate;

      const internalCompanyOption = isOptionInternal ? conference?._company?.name : attendee?.company;
      const company = isInternal ? null : internalCompanyOption;
      const sales_representative = isOptionInternal || isOptionCorporate ? undefined : attendee?.sales_representative;
      const investor_type = isOptionInvestor ? attendee?.investor_type : undefined;
      const guest_attendee_type = isOptionGuest ? attendee?.guest_attendee_type : undefined;

      onAttendeeUpdate({
        type: option,
        company,
        sales_representative,
        guest_attendee_type,
        investor_type,
      });
    }

    function handleGuestAttendeeTypeChange(option: GuestAttendeeType): void {
      onAttendeeUpdate({
        guest_attendee_type: option ?? null,
      });
    }

    function handleInvestorTypeChange(option: InvestorType): void {
      if (isEmpty(option)) return;

      onAttendeeUpdate({
        investor_type: option,
      });
    }

    function handleCodeSelect(value: string): void {
      const code = handlePresentationCodeChipAdd(value);
      onAttendeeUpdate({ code });
    }

    function handleCodeRemove(value: string): void {
      const code = handlePresentationCodeChipRemove(value);
      onAttendeeUpdate({ code });
    }

    function getTextboxHandler(key: keyof AttendeeViewModel) {
      return function (value: string): void {
        onAttendeeUpdate({ [key]: value });
      };
    }

    function handleParticipateInMeetingsChange(checked: boolean) {
      onAttendeeUpdate({ participate_in_meetings: checked });
    }

    function handleLobbyPreviewChange(checked: boolean) {
      onAttendeeUpdate({ allow_lobby_preview: checked });
    }
    // #endregion

    function renderCompanySelect(): JSX.Element {
      if (isCorporate) {
        return (
          <Select
            id={idModel.company?.id}
            preset={SelectPreset.Autocomplete}
            placeholder="Select a company profile"
            value={attendeeProfile}
            valueKey="_id"
            labelKey="name"
            options={corporateProfiles}
            onChange={handleCorporateProfileChange}
          />
        );
      }
      return (
        <Select
          id={idModel.company?.id}
          preset={SelectPreset.Autocomplete}
          placeholder="Select a company"
          disabled={attendee?.type === AttendeeType.Internal}
          value={attendee?.company}
          inputValue={companyInputValue}
          options={companySelectOptions}
          isClearable
          backspaceRemovesValue
          onInputChange={setCompanyInputValue}
          onChange={handleCompanyChange}
        />
      );
    }

    const fields: FormFieldProps[] = [
      {
        key: "First Name",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "First Name",
        required: true,
        children: (
          <Textbox id={idModel.firstName?.id} value={attendee?.first_name} onChange={getTextboxHandler("first_name")} />
        ),
      },
      {
        key: "Last Name",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Last Name",
        required: true,
        children: (
          <Textbox id={idModel.lastName?.id} value={attendee?.last_name} onChange={getTextboxHandler("last_name")} />
        ),
      },
      {
        key: "Type",
        required: true,
        width: "1-of-2",
        label: "Type",
        children: (
          <Select
            id={idModel.type?.id}
            preset={SelectPreset.Autocomplete}
            value={attendee?.type}
            options={attendeeTypeOptions}
            onChange={handleTypeChange}
          />
        ),
      },
      {
        key: "Company",
        required: attendee?.type !== AttendeeType.Internal,
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Company",
        children: renderCompanySelect(),
      },
      {
        key: "Email",
        required: true,
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Email",
        children: <Textbox id={idModel.email?.id} value={attendee?.email} onChange={getTextboxHandler("email")} />,
      },
      {
        key: "Title",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Title",
        children: <Textbox id={idModel.title?.id} value={attendee?.title} onChange={getTextboxHandler("title")} />,
      },
      {
        key: "Phone",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Phone",
        children: (
          <Textbox id={idModel.phone?.id} value={attendee?.phone_number} onChange={getTextboxHandler("phone_number")} />
        ),
      },
      {
        key: "Code Combo Box",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Presentation Codes",
        children: (
          <ComboBox
            id={idModel.code?.id}
            selectProps={{
              preset: SelectPreset.Autocomplete,
              inputValue: presentationCodeInput,
              placeholder: "Select or type a code",
              options: codeOptions,
              disabled: isEmpty(codeOptions),
              onChange: handleCodeSelect,
              onInputChange: handlePresentationCodeInputChange,
            }}
            chipsProps={{
              items: presentationCodeChips,
              onClick: handleCodeRemove,
              onRemove: handleCodeRemove,
              inline: true,
            }}
          />
        ),
      },
    ];

    if (AttendeeTypesWithRepresentatives.includes(attendee?.type)) {
      fields.push({
        key: "Relationship Manager/Sales Representative Name",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Relationship Manager/Sales Representative Name",
        children: (
          <Textbox
            id={idModel.representative?.id}
            value={attendee?.sales_representative}
            onChange={getTextboxHandler("sales_representative")}
          />
        ),
      });
    }

    if (AttendeeTypesWithOptionalParticipation.includes(attendee?.type)) {
      fields.push({
        key: "Participate in Meetings",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Participate in Meetings",
        children: (
          <Checkbox
            id={idModel.participateInMeetings?.id}
            checked={attendee?.participate_in_meetings}
            onChange={handleParticipateInMeetingsChange}
            label="Enable"
          />
        ),
      });
    }

    if (isGuest) {
      fields.push({
        key: "Guest Attendee Type",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Attendee Type",
        children: (
          <Select
            id={idModel.guestAttendeeType?.id}
            value={attendee?.guest_attendee_type}
            options={guestAttendeeTypeOptions}
            preset={SelectPreset.Autocomplete}
            isClearable
            onChange={handleGuestAttendeeTypeChange}
          />
        ),
      });
    }

    if (isInvestor) {
      fields.push({
        key: "Investor Type",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Investor Type",
        children: (
          <Select
            id={idModel.investorType?.id}
            preset={SelectPreset.Dropdown}
            value={attendee?.investor_type}
            options={investorTypeOptions}
            onChange={handleInvestorTypeChange}
          />
        ),
      });
    }

    fields.push({
      key: "Allow Lobby Preview",
      width: "1-of-2",
      smallWidth: "1-of-1",
      label: "Allow Lobby Preview",
      children: (
        <Checkbox
          id={idModel.allowLobbyPreview?.id}
          checked={attendee?.allow_lobby_preview}
          onChange={handleLobbyPreviewChange}
          label="Enable"
        />
      ),
    });

    if (isSystemAdmin && !isNullOrWhiteSpace(attendee?._id)) {
      fields.push({
        key: "Generate Password",
        width: "1-of-2",
        smallWidth: "1-of-1",
        className: AttendeeFormClassName.GeneratePassword,
        label: (
          <>
            Login Password
            <InfoIcon
              tooltipProps={{
                label: "Override existing password and generate a new password",
                position: Origin.Top,
                theme: TooltipTheme.Slate,
              }}
            />
          </>
        ),
        children: (
          <>
            <Button
              id={idModel.generatePassword?.id}
              label="Generate Password"
              type={ButtonType.Submit}
              onClick={onGeneratePassword}
              loading={loading}
            />
            <Text theme={TextTheme.Silver}>
              Last Generated:{" "}
              {attendee?.last_generated_password_at
                ? moment(attendee?.last_generated_password_at)
                    .tz(conference?.time_zone || DefaultTimeZone)
                    .format(DateFormat.TimezoneFullStandard)
                : "N/A"}
            </Text>
          </>
        ),
      });
    }

    return fields;
  }, [
    isCorporate,
    isGuest,
    isInvestor,
    isInternal,
    isSystemAdmin,
    attendee,
    idModel,
    codeOptions,
    guestAttendeeTypeOptions,
    presentationCodeChips,
    presentationCodeInput,
    attendeeProfile,
    attendeeTypeOptions,
    companyInputValue,
    companySelectOptions,
    conference,
    corporateProfiles,
    investorTypeOptions,
    loading,
    onAttendeeUpdate,
    onGeneratePassword,
    handlePresentationCodeInputChange,
    handlePresentationCodeChipAdd,
    handlePresentationCodeChipRemove,
  ]);

  return (
    <div id={idModel.id}>
      <Form id={idModel.attendeeForm.id} fields={attendeeFields} className={baseClassName} />
      {AttendeeTypesWithAvailability.includes(attendee?.type) &&
        !(isPresentationOnly && isInvestor) &&
        !isEmpty(schedulerDays) && (
          <Grid className={baseClassName}>
            <Text preset={TextPreset.Subheader}>Availabilities (Required)</Text>
            <AvailabilityForm
              days={schedulerDays}
              timeZone={timeZone}
              timeslots={timeslots}
              handleTimeslotChange={handleTimeslotChange}
            />
          </Grid>
        )}
      {isInvestor && !isPresentationOnly && (
        <Grid className={baseClassName}>
          <Text preset={TextPreset.Subheader}>Meeting Requests</Text>
          <MeetingRequestForm
            id={idModel?.meetingRequestForm.id}
            conference={conference}
            meetingRequests={attendee?.meeting_requests}
            setMeetingRequests={(value: MeetingRequest[]) => onAttendeeUpdate({ meeting_requests: value })}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </Grid>
      )}
      {!isEmpty(attendeeQuestions) && (
        <Grid className={baseClassName}>
          <Text
            id={idModel?.questionsHeader.id}
            className={AttendeeFormClassName.QuestionsHeader}
            preset={TextPreset.Subheader}
          >
            {questionGroup.title}
          </Text>
          <QuestionsForm
            id={idModel?.questionsForm?.id}
            questions={attendeeQuestions || []}
            answers={attendeeAnswers || []}
            setFormErrors={setFormErrors}
            onChange={handleAnswerChange}
          />
        </Grid>
      )}
    </div>
  );
};

export default memo(AttendeeForm);
