import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type {
  CustomJsonFieldSchemaProps,
  JsonFieldPropsSchema,
} from "../../../../../components/jsonForm/jsonForm.definition";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { Answer } from "../../../../../services/answer/answer.model";
import {
  AnswerDataType,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionType,
  QuestionTypesWithOptions,
  SortDirection,
  SortOrder,
} from "../../../../../services/questionGroup/questionGroup.model";
import { AnswerLimits, CustomQuestionsFormData, QuestionFieldUiSchemaMap } from "./customQuestions.definition";

const setQuestionOptions = (question: Question, answer: Answer, field: JsonFieldPropsSchema): boolean => {
  let isOtherSelected = !!answer?.answer;

  const mappedOptions =
    question.options?.map((option) => {
      if (option.answer === answer?.answer) isOtherSelected = false;
      if (!answer && option.default) field.default = option.answer;
      return option.answer;
    }) || [];

  if (question.options_sort?.enabled && question.options_sort?.sort_order === SortOrder.Alphabetical) {
    mappedOptions.sort((firstOption, secondOption) => {
      const comparison = firstOption.localeCompare(secondOption, undefined, { sensitivity: "accent" });
      return question.options_sort.sort_direction === SortDirection.Ascending ? comparison : comparison * -1;
    });
  }

  // enum option denotes a choice of the values for jsonForm, validation prevents submitting a new value outside of the enum
  // solution is to use an unreserved field name and pass to select within jsonForm

  // default option must be set for required questions to make them included in the validation object returned
  // by JSONForm, otherwise no validation will be performed

  const defaultValue = answer?.answer ?? (question.required ? getNoAnswerValue(question.answer_type.field_type) : undefined);
  switch (question.answer_type.field_type) {
    case QuestionType.Choice:
    case QuestionType.MultipleChoice:
      field.allowCustomAnswer = question.add_other_option ?? false;
      field.allowMultipleAnswers = question.answer_type.field_type === QuestionType.MultipleChoice;
      field.options = mappedOptions;
      field.default = defaultValue;
      break;
    case QuestionType.CustomSelection:
      field.options = mappedOptions;
      field.default = defaultValue;
      field.placeholder = "Type your response";
      break;
    case QuestionType.Selection:
      if (question.required) {
        field.enum = mappedOptions;
      } else {
        field.options = mappedOptions;
      }
      field.default = defaultValue;
      field.placeholder = "Type to search";
      break;
    default:
      field.enum = mappedOptions;
  }

  return isOtherSelected;
};

export function getCustomQuestionField(
  question: Question,
  answer: Answer,
  schema: JsonFieldPropsSchema,
  useDefaultIds: boolean,
  index = 0
): JsonFieldPropsSchema | null {
  if (isEmpty(question)) return null;

  const { answer_type, title } = question;
  const { field_type, data_type } = answer_type || {};
  const defaultFieldProps = {
    id: useDefaultIds ? `CustomQuestionField${index}` : undefined,
    title: question.required ? `${title} (Required)` : title,
    type: data_type,
    field_type,
    htmlTitle: field_type === QuestionType.Consent,
  };

  switch (data_type) {
    case AnswerDataType.Boolean:
      return {
        ...defaultFieldProps,
        default: answer?.answer ?? false,
        placeholder: question.place_holder,
      };
    case AnswerDataType.Array:
      const arrayField = {
        ...defaultFieldProps,
        items: { type: "string" },
      } as JsonFieldPropsSchema;
      setQuestionOptions(question, answer, arrayField);
      return arrayField;
    default:
      const defaultField = {
        ...defaultFieldProps,
        type: ["string", "null"],
        default: answer?.answer ?? "",
        maxLength: question.max_length || AnswerLimits[field_type],
        placeholder: question.place_holder || "",
      };

      if (QuestionTypesWithOptions.includes(field_type)) {
        return {
          ...defaultField,
          ...getSchemaOptions(question, answer, schema),
        } as JsonFieldPropsSchema;
      }

      return defaultField as JsonFieldPropsSchema;
  }
}

export function getSchemaOptions(
  question: Question,
  answer: Answer,
  schema: CustomJsonFieldSchemaProps
): JsonFieldPropsSchema | null {
  if (isEmpty(question)) return null;

  const field = {} as JsonFieldPropsSchema;

  setQuestionOptions(question, answer, field);
  if (question.answer_type.field_type === QuestionType.Consent) {
    schema.htmlTitle = true;
  }

  field.clearable = !question.required;

  return field;
}

export function getUiSchema(type: QuestionType): { [key: string]: string } {
  return QuestionFieldUiSchemaMap[type] ?? { "ui:widget": "nuiText" };
}

export function validateArray<T>(array: T[]): boolean {
  return !isEmpty(array);
}

export function validateArrayNoEmptyItems(array: string[]): boolean {
  return (
    array?.reduce?.((prev: boolean, arrayItem) => {
      return !isNullOrWhiteSpace(arrayItem);
    }, true) ?? true
  );
}

export function convertAnswersToContainQuestionDetails(answers: Answer[], questions: Question[]): Answer[] {
  // maintain the order of the questions in summary page
  return questions?.reduce((acc, question) => {
    const targetAnswer = answers?.find((answer) => answer?._question === question._id);
    if (!targetAnswer) return acc;
    acc.push({
      ...targetAnswer,
      _question: question,
    });

    return acc;
  }, [] as Answer[]);
}

export function convertDeletedAnswerOptions(answer: Answer, question: Question): Answer {
  const currentAnswer = new Answer({ ...answer });
  const questionType = question?.answer_type?.field_type;

  switch (questionType) {
    case QuestionType.Selection:
      if (!question?.options?.some((opt) => opt.answer === answer?.answer)) {
        currentAnswer.answer = "";
      }
      return currentAnswer;

    case QuestionType.Choice:
      if (!question?.add_other_option && !question?.options?.some((opt) => opt.answer === answer?.answer)) {
        currentAnswer.answer = null;
      }
      return currentAnswer;

    case QuestionType.MultipleChoice:
      const userAnswers = answer?.answer as string[];

      const { answersIncludedInOptions, answersExcludedFromOptions } = (userAnswers || []).reduce(
        (acc, ans) => {
          if (question?.options?.some((opt) => opt.answer === ans)) {
            acc.answersIncludedInOptions.push(ans);
          } else {
            acc.answersExcludedFromOptions.push(ans);
          }
          return acc;
        },
        {
          answersIncludedInOptions: [],
          answersExcludedFromOptions: [],
        }
      );

      // if multiple options are deleted and other option is NOT enabled, filter out all extra options
      if (!question?.add_other_option && !isEmpty(answersExcludedFromOptions)) {
        currentAnswer.answer = answersIncludedInOptions;
        return currentAnswer;
      }

      // if multiple options are deleted and other option is enabled, keep the last option as the other option, filter out all the ones that come before
      if (question?.add_other_option && !isEmpty(answersExcludedFromOptions)) {
        currentAnswer.answer = [
          ...answersIncludedInOptions,
          answersExcludedFromOptions[answersExcludedFromOptions.length - 1],
        ];
        return currentAnswer;
      }

      return currentAnswer;

    default:
      return currentAnswer;
  }
}

export function convertFormAnswersToModel(
  questions: Question[],
  registrant: RegistrantViewModel,
  formAnswers: CustomQuestionsFormData
): Answer[] {
  return questions.map(
    (question) =>
      new Answer({
        _question: question,
        answer: formAnswers[question._id],
      })
  );
}

export function convertFormAnswersToModelForEditMode(
  questions: Question[],
  customQuestionAnswers: Answer[],
  formAnswers: CustomQuestionsFormData
): Answer[] {
  return questions.reduce((acc, question) => {
    const targetAnswer = customQuestionAnswers?.find((el) => (el._question as Question)?._id === question._id);

    // if previous answer and new answer don't exist, send nothing in payload
    if (isEmpty(targetAnswer) && isEmpty(formAnswers[question._id])) return acc;

    // if previous answer exists, send answer id to update new answer
    if (targetAnswer) {
      acc.push(
        new Answer({
          _id: targetAnswer?._id,
          _question: question,
          // when user is editing an existing answer, only for single choice removing an answer records null value.
          answer: formAnswers[question._id] ?? null,
        })
      );
    }

    // if previous answer doesn't exist, but new answer exist, create new answer
    if (!targetAnswer && formAnswers[question._id]) {
      acc.push(
        new Answer({
          _question: question,
          answer: formAnswers[question._id],
        })
      );
    }
    return acc;
  }, []);
}

export function convertModelAnswersToForm(answers: Answer[], questions: Question[]): CustomQuestionsFormData {
  const result = {} as CustomQuestionsFormData;

  if (!Array.isArray(answers)) return result;

  answers?.forEach((answer) => {
    const targetQuestion =
      typeof answer._question === "string"
        ? questions?.find((ques) => ques._id === answer._question)
        : (answer._question as Question);
    const resultedAnswer = convertDeletedAnswerOptions(answer, targetQuestion);
    result[targetQuestion._id] = resultedAnswer.answer;
  });

  return result;
}

export function getNoAnswerValue(questionType: QuestionType): string[] | string {
  return questionType === QuestionType.MultipleChoice ? [] : null;
}

function sanitizeQuestionOptions(options: QuestionOption[]): QuestionOption[] {
  return options.reduce((acc: QuestionOption[], currentOption: QuestionOption) => {
    if (!acc.some((opt) => opt.answer.toLowerCase() === currentOption.answer.toLowerCase())) acc.push(currentOption);
    return acc;
  }, [] as QuestionOption[]);
}

export function sanitizeQuestionGroup(group: QuestionGroup): QuestionGroup {
  const sanitizedData = new QuestionGroup({ ...group });

  sanitizedData._questions = sanitizedData._questions.map((question) => {
    const sanitizedQuestion = new Question(question);

    if (QuestionTypesWithOptions.includes(question.answer_type.field_type)) {
      sanitizedQuestion.options = sanitizeQuestionOptions(question.options);
    }

    return sanitizedQuestion;
  });
  return sanitizedData;
}
