import "./header.component.scss";
import {
  Toolbar,
  ToolbarTheme,
  Button,
  ButtonTheme,
  PopoverMenu,
  PopoverMenuProps,
  Ghostable,
  isEmpty,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import { HeaderProps, HeaderClassName, HeaderIdModel } from "./header.definition";

function Header(props: HeaderProps): JSX.Element {
  const { id, adminUserContext } = props;
  const { user, signOut } = adminUserContext;

  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const idModel = useMemo(() => new HeaderIdModel(id), [id]);
  function handleOpenProfileMenu() {
    setProfileMenuOpen(true);
  }

  function handleCloseProfileMenu() {
    setProfileMenuOpen(false);
  }

  const handleSignOutClick = useCallback(() => {
    signOut();
  }, [signOut]);

  const profileMenuProps: PopoverMenuProps = useMemo(
    () => ({
      anchorTargetElementId: idModel.profile.id,
      visible: profileMenuOpen,
      options: [
        {
          id: idModel.logOut.id,
          label: "Log Out",
          onClick: handleSignOutClick,
        },
      ],
      onCloseRequest: handleCloseProfileMenu,
      masked: true,
    }),
    [idModel.logOut.id, idModel.profile.id, profileMenuOpen, handleSignOutClick]
  );

  return (
    <Toolbar
      className={HeaderClassName.Base}
      theme={ToolbarTheme.Q4Blue}
      autoRow
      autoRowProps={{ justifyContent: "flex-end" }}
    >
      <Ghostable ghosted={isEmpty(user)}>
        <Button
          id={idModel.profile.id}
          className={HeaderClassName.ProfileButton}
          theme={ButtonTheme.Q4Blue}
          icon="q4i-contact-2pt"
          label={user?.name}
          onClick={handleOpenProfileMenu}
        />
      </Ghostable>
      <PopoverMenu {...profileMenuProps} />
    </Toolbar>
  );
}

export default memo(Header);
