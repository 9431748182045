import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { createContext, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { Conference } from "../../services/conference/conference.model";

export interface ConferenceContextState {
  conference: Conference;
  set: Dispatch<SetStateAction<Conference>>;
}

export const ConferenceContext = createContext<Partial<ConferenceContextState>>({});

export const ConferenceProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [conference, setConference] = useState<Conference>(null);

  return (
    <ConferenceContext.Provider
      value={{
        conference,
        set: setConference,
      }}
    >
      {props.children}
    </ConferenceContext.Provider>
  );
};
