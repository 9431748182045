import { convertFileToBase64, isEmpty } from "@q4/nimbus-ui";
import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

interface FileUploadResponse {
  url: string;
}

interface FileRequest {
  file: string;
  fileName: string;
}

export default class FileUploadService {
  private readonly ADMIN_FILEUPLOAD_API_PATH = `${AdminRouteBase}/upload`;
  private apiService = new ApiService();

  async post(blob: File, fileName = this.sanitizeFileName(blob.name)): Promise<ApiResponse<FileUploadResponse>> {
    const base64 = await convertFileToBase64(blob);
    if (isEmpty(base64)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Failed to upload file." }));
    }
    const file = base64.toString().replace(/data:.+;base64,/, "");
    return this.apiService.post<FileRequest, FileUploadResponse>(this.ADMIN_FILEUPLOAD_API_PATH, {
      file,
      fileName,
    });
  }

  private sanitizeFileName(fileName: string): string {
    return fileName.replace(/ /g, "-").replace(/[\\/<>;=,?%*:|"{}]/g, "");
  }
}
