import { IdModelBase, isNullOrWhiteSpace, SelectIdModel } from "@q4/nimbus-ui";
import { JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";

export enum RegistrationAttendeeTypeLabel {
  Corporate = "Corporate",
  Investor = "Investor",
  Internal = "Internal",
  Guest = "Other",
  Other = "Guest",
}

export interface RegistrationTypeProps extends RegistrationFormProps {
  attendeeTypes: Conference["registration"]["attendee_types"];
  guestOtherTypesEnabled?: boolean;
}

export const RegistrationTypeErrorMessages = {
  attendeeTypeSelection: "Registration type is required",
};

export class RegistrationTypeIdModel extends IdModelBase {
  jsonForm: JsonFormIdModel;
  attendeeType: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);
    this.attendeeType = new SelectIdModel(`${this.id}AttendeeTypeSelect`);
  }
}
