import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useCompany } from "../..";
import { useBackgroundImage } from "../../useBackgroundImage/useBackroundImage.hook";
import type { CompanyHookModel } from "./usePublicCompany.definition";

interface PathParams {
  custom: string;
  company: string;
  conferenceId: string;
  conferencePath: string;
}
export const usePublicCompany = (): CompanyHookModel => {
  const param = useParams<PathParams>();

  const {
    company,
    loading: companyLoading,
    conferences,
    fetchOpenConferences,
  } = useCompany({
    autoFetchData: true,
    showNotifications: false,
    companyUrlSuffix: param?.company ?? param?.custom,
  });

  const [initialized, setInitialized] = useState(false);

  const loading = useMemo(() => !initialized || companyLoading, [companyLoading, initialized]);

  const hasCompany = useMemo(() => !isEmpty(company), [company]);
  const companyName = useMemo(() => company?.name, [company]);

  const conference = useMemo(() => {
    if (isEmpty(conferences)) return null;

    const conferencePath = param?.conferencePath;

    if (!isNullOrWhiteSpace(conferencePath)) {
      const filteredConference = conferences.find((x) => x._id === conferencePath || x.path === conferencePath);
      return !isEmpty(filteredConference) ? filteredConference : null;
    }
    return conferences?.[0] ?? null;
  }, [conferences, param]);
  const hasConference = useMemo(() => !isEmpty(conference), [conference]);

  const backgroundImageSrc = useMemo(
    () => (companyLoading || isNullOrWhiteSpace(company?.branding?.background) ? null : company.branding.background),
    [company?.branding?.background, companyLoading]
  );
  const backgroundImage = useBackgroundImage(backgroundImageSrc);

  const urlSuffix = param?.custom || param?.company;

  const conferenceBackgroundImageSrc = useMemo(
    () => (companyLoading || isNullOrWhiteSpace(conference?.image_background) ? null : conference.image_background),
    [conference?.image_background, companyLoading]
  );
  const conferenceBackgroundImage = useBackgroundImage(conferenceBackgroundImageSrc);

  const logoSrc = useMemo(() => company?.branding?.logo, [company?.branding?.logo]);
  const logoImage = useBackgroundImage(logoSrc);

  const conferenceLogoSrc = useMemo(() => conference?.image_logo, [conference?.image_logo]);
  const conferenceLogoImage = useBackgroundImage(conferenceLogoSrc);

  useEffect(() => {
    if (isEmpty(company) || initialized) return;
    fetchOpenConferences(company?._id).finally(() => {
      setInitialized(true);
    });
  }, [urlSuffix, fetchOpenConferences, company, initialized]);

  return {
    company,
    backgroundImage,
    companyLoading: loading,
    conference,
    conferenceBackgroundImage,
    conferenceLogoImage,
    hasCompany,
    hasConference,
    logoImage,
    companyName,
    companyUrlSuffix: urlSuffix,
  };
};
