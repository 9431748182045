import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Entity } from "../../definitions/entity.definition";
import { OnDemand } from "../admin/onDemand/onDemand.model";
import OnDemandService from "../admin/onDemand/onDemand.service";
import { ApiResponse } from "../api/api.definition";
import { CsvBase, ImportOnDemandError, ImportOptions, ImportResult } from "./import.definition";

export default class ImportOnDemandService {
  private onDemandPresentations: { entity: OnDemand; csv: CsvBase }[];
  private requests: (() => Promise<ApiResponse<OnDemand>>)[];
  private onDemandService = new OnDemandService();

  get Data(): OnDemand[] {
    return this.onDemandPresentations?.map((x) => x.entity) || [];
  }
  get HasSetting(): boolean {
    return false;
  }
  get Requests(): (() => Promise<ApiResponse<OnDemand>>)[] {
    return this.requests || [];
  }
  get Type(): Entity {
    return Entity.OnDemand;
  }

  parse = async (options: ImportOptions): Promise<ImportResult<OnDemand>[]> => {
    const { csvJson, conference: _conference, handleResults } = options || {};
    if (isEmpty(csvJson) || isNullOrWhiteSpace(_conference?._id)) return [];

    const results: ImportResult<OnDemand>[] = [];
    const existingOnDemandPresentations: string[] = [];

    this.onDemandPresentations = csvJson.reduce((onDemandPresentations, csv): OnDemand[] => {
      if (isEmpty(csv)) return onDemandPresentations;

      const { ondemand_id } = csv;
      if (existingOnDemandPresentations.includes(ondemand_id)) return onDemandPresentations;

      const { ondemand_code, ondemand_description, ondemand_order, ondemand_title, ondemand_url } = csv;

      const convertedOrder = parseInt(ondemand_order);
      const order = isNaN(convertedOrder) ? 9999 : convertedOrder;

      const onDemand = new OnDemand({
        _conference,
        title: ondemand_title,
        description: ondemand_description,
        urlOverride: ondemand_url,
        code: ondemand_code?.split(/[ ,]+/),
        order,
      });

      const onDemandParseError = this.getOnDemandParseError(onDemand);
      if (!isEmpty(onDemandParseError)) {
        results.push(
          new ImportResult({
            type: this.Type,
            entity: onDemand,
            title: onDemand.title,
            csv,
            errorMessage: onDemandParseError,
          })
        );
        return onDemandPresentations.concat({ entity: null, csv });
      }

      existingOnDemandPresentations.push(ondemand_id);
      return onDemandPresentations.concat({ entity: onDemand, csv });
    }, []);

    this.requests = this.onDemandPresentations.reduce((requests, onDemandData): (() => Promise<ApiResponse<OnDemand>>)[] => {
      if (isEmpty(onDemandData?.entity)) return requests;
      const { entity: onDemand, csv } = onDemandData;

      return requests.concat(
        (): Promise<ApiResponse<OnDemand>> =>
          this.onDemandService.post(onDemand).then((response) => {
            const { success, message } = response;

            handleResults(
              this.Type,
              {
                ...response,
                data: success ? response.data : onDemand,
                message,
                success,
              },
              "title",
              csv
            );

            return response;
          })
      );
    }, [] as (() => Promise<ApiResponse<OnDemand>>)[]);
    return results;
  };

  private getOnDemandParseError(onDemand: OnDemand): ImportOnDemandError {
    if (isEmpty(onDemand)) return ImportOnDemandError.Empty;
    if (isNullOrWhiteSpace(onDemand.title)) return ImportOnDemandError.InvalidTitle;
    if (isNullOrWhiteSpace(onDemand.urlOverride)) return ImportOnDemandError.InvalidUrl;
    return null;
  }
}
