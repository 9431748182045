import "./info.component.scss";
import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { htmlParse } from "../../../../../../../utils";
import Section from "../../../section/section.component";
import { SectionInnerPadding, SectionInnerWidth, SectionKeyLine } from "../../../section/section.definition";
import { InfoProps, InfoClassName, InfoIdModel } from "./info.definition";

const Info = (props: InfoProps): JSX.Element => {
  const { id, content } = props;

  const idModel = useMemo(() => new InfoIdModel(id), [id]);

  if (isNullOrWhiteSpace(content)) return null;

  return (
    <Section
      id={idModel.id}
      className={InfoClassName.Base}
      innerWidth={SectionInnerWidth.Narrow}
      innerPadding={SectionInnerPadding.Thin}
      keyLine={SectionKeyLine.Top}
    >
      <div id={idModel.content} className={InfoClassName.Content}>
        {htmlParse(content)}
      </div>
    </Section>
  );
};

export default memo(Info);
