import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { PersonBase } from "../../definitions/entity.definition";
import { getFriendlyName } from "../../utils/presentation/presentation.utils";
import type { Attendee } from "../attendee/attendee.model";
import type { Conference } from "../conference/conference.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";

export interface SpeakerContact {
  email: string;
  twitter: string;
  linkedin: string;
}

export class Speaker extends PersonBase {
  _conference: Conference;
  _attendee: Attendee["_id"];
  first_name: string;
  last_name: string;
  title: string;
  company: string;
  bio: string;
  image_profile: string;
  contact: SpeakerContact;
  order: number;

  // TODO: move to PersonBase
  get display_name(): string {
    const first_name = isNullOrWhiteSpace(this.first_name) ? "" : this.first_name;
    return `${first_name} ${this.last_name}`;
  }

  get friendly_name(): string {
    return getFriendlyName(this.first_name, this.last_name, this.company, this.contact?.email);
  }

  constructor(speaker: Partial<Speaker> | string) {
    super(speaker);
    if (typeof speaker === "string") return;

    const { order, ...rest } = speaker || {};

    this.order = order ?? 0;

    Object.assign(this, rest || {});
  }
}

type SpeakerPayloadBase = Omit<Speaker, "ConferenceId" | "display_name" | "friendly_name">;
export class SpeakerPayload extends PayloadBase<SpeakerPayloadBase> implements SpeakerPayloadBase {
  _conference: Speaker["_conference"];
  first_name: Speaker["first_name"];
  last_name: Speaker["last_name"];
  title: Speaker["title"];
  company: Speaker["company"];
  bio: Speaker["bio"];
  image_profile: Speaker["image_profile"];
  contact: Speaker["contact"];
  order: Speaker["order"];
  _attendee: Attendee["_id"];

  constructor(speaker: Speaker) {
    super();
    const { ConferenceId, ...payload } = speaker;
    const sanitizedPayload = this.sanitize(payload);

    Object.assign(this, sanitizedPayload, { _conference: ConferenceId });
    this.image_profile = this.image_profile ?? "";
  }
}
