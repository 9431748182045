import { Button, ButtonTheme, Card, CardTheme, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { isEmpty } from "lodash";
import React, { memo, useMemo } from "react";
import { Answer } from "../../../../../services/answer/answer.model";
import { CustomQuestionsLabels } from "../customQuestions/customQuestions.definition";
import { RegistrationSummaryClassName } from "../summary/summary.definition";
import { AnswerSummaryIdModel, AnswerSummaryProps, AnswerSummaryLabels } from "./answerSummary.definition";
import RenderAnswer from "./renderAnswer.component";

const AnswerSummary = (props: AnswerSummaryProps): JSX.Element => {
  const { id, loading, questionGroup, answers, goToEdit } = props;
  const idModel = useMemo(() => new AnswerSummaryIdModel(id), [id]);

  if (isEmpty(questionGroup._questions)) return null;
  return (
    <Card
      className={RegistrationSummaryClassName.Section}
      title={questionGroup?.title || CustomQuestionsLabels.Title}
      id={idModel.answerSummarySection.id}
      headerChildren={
        goToEdit && (
          <Button
            icon="q4i-edit-4pt"
            id={idModel.answerSummaryEdit.id}
            label={AnswerSummaryLabels.Edit}
            disabled={loading}
            theme={ButtonTheme.LightGrey}
            onClick={goToEdit}
          />
        )
      }
      theme={CardTheme.Light}
    >
      {answers.map((answer: Answer, index) => {
        if (!isNullOrWhiteSpace(answer.answer as string)) {
          const id = idModel.list.getId(index);
          return <RenderAnswer answer={answer} id={id} key={id} />;
        }
        return null;
      })}
    </Card>
  );
};

export default memo(AnswerSummary);
