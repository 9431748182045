import { isEmpty } from "@q4/nimbus-ui";
import { EntityBase } from "../../../definitions/entity.definition";
import { AttendeeViewModel } from "../../attendee/attendee.model";
import { Conference } from "../../conference/conference.model";
import { PayloadBase } from "../../serviceBase/payloadBase.model";

export enum CorporateType {
  Public = "Public",
  Private = "Private",
}

export class CorporatePrimaryContact {
  first_name: string;
  last_name: string;
  title: string;
  phone_number: string;
  email: string;

  constructor(contact?: CorporatePrimaryContact) {
    if (isEmpty(contact)) return;
    Object.assign(this, contact);
  }
}

export class CorporatePresentation {
  file?: string;
  title?: string;
  duration?: number;
  description?: string;
  is_shareable_after_event?: boolean;

  constructor(presentation?: CorporatePresentation) {
    if (isEmpty(presentation)) return;
    Object.assign(this, presentation);
  }
}

export class CorporateProfile extends EntityBase {
  _conference: Conference["_id"];
  _primary_contact: AttendeeViewModel["_id"];
  name: string;
  industry?: string;
  type: CorporateType;
  description?: string;
  logo_image?: string;
  secondary_logo_image?: string;
  ticker_symbol?: string;
  exchange?: string;
  url?: string;
  host_small_meetings: boolean;
  host_presentation: boolean;
  notes?: string;
  presentation?: CorporatePresentation;
  attendees?: AttendeeViewModel[];

  constructor(profile: Partial<CorporateProfile> | string) {
    super(profile);
    if (typeof profile === "string" || isEmpty(profile)) return;

    Object.assign(this, profile);
  }
}

export class CorporateProfilePayload extends PayloadBase<CorporateProfile> {
  constructor(corporateProfile: CorporateProfile) {
    super();

    const sanitizedPayload = this.sanitize(corporateProfile);

    sanitizedPayload.host_small_meetings = !!sanitizedPayload?.host_small_meetings;
    sanitizedPayload.host_presentation = !!sanitizedPayload?.host_presentation;

    Object.assign(this, sanitizedPayload);
  }
}
