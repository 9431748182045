import { BaseComponentProps } from "@q4/nimbus-ui";

export interface CsvUploadButtonProps extends Pick<BaseComponentProps, "id"> {
  loading?: boolean;
  onUpload?: (file: File) => void;
}

export enum CsvUploadButtonClassName {
  Base = "csv-upload",
  Button = "csv-upload_button",
  Input = "csv-upload_input",
}
