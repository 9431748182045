import { BaseComponentWithChildrenProps, IdModelBase } from "@q4/nimbus-ui";
import type { VideoVendor } from "../../../../../services/conference/conference.model";

export enum DialInformationClassName {
  Base = "dial-in",
  Header = "dial-in_header",
  Subheader = "dial-in_subheader",
  Inner = "dial-in_inner",
  Group = "dial-in_group",
  Link = "dial-in_link",
  Tabs = "dial-in_tabs",
  Vendor = "dial-in_vendor",
}

export interface DialInformationProp extends BaseComponentWithChildrenProps {
  video_vendors: VideoVendor[];
  showAll?: boolean;
  visible?: boolean;
}

export class DialInIdModel extends IdModelBase {
  tabs: string;
  swappable: string;

  constructor(id: string, index?: number | string) {
    super(id, index);

    this.tabs = `${this.id}Tabs`;
    this.swappable = `${this.id}Swappable`;
  }
}
