import { isNullOrWhiteSpace, isEmpty } from "@q4/nimbus-ui";
import { UserRouteBase } from "../../../configurations/navigation.configuration";
import { getQuery } from "../../../utils";
import { ApiResponse, AuthType } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import type { Service } from "../../serviceBase/serviceBase.model";
import { SessionBase, SessionEventType } from "../../session/session.model";
import { SessionLink } from "./sessionLink.definition";

export default class SessionLinkService
  implements Omit<Service<string>, "delete" | "get" | "getOfflineData" | "put" | "post">
{
  protected readonly _apiService = new ApiService();
  protected _servicePath: string;

  get ApiPathBase(): string {
    if (isNullOrWhiteSpace(this._servicePath)) return null;
    return `${this._servicePath}`;
  }

  constructor() {
    this._servicePath = `${UserRouteBase}/sessionlink`;
  }

  get = (id: SessionBase["_id"], type: SessionEventType): Promise<ApiResponse<SessionLink>> => {
    if (isNullOrWhiteSpace(id) || isEmpty(type)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Invalid session id or type was provided." }));
    }

    const query = getQuery({ id, type });
    return this._apiService.get<SessionLink>(`${this.ApiPathBase}${query}`, AuthType.Passwordless);
  };
}
