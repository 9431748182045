import { isEmpty, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { useAutoFetch } from "../..";
import { Entity } from "../../../definitions/entity.definition";
import { OnDemand } from "../../../services/admin/onDemand/onDemand.model";
import { OfflineApiServiceKey } from "../../../services/api/api.definition";
import { Conference } from "../../../services/conference/conference.model";
import { Presentation } from "../../../services/presentation/presentation.model";
import UserConferenceService from "../../../services/user/userConference/userConference.service";
import { mapTimeZone } from "../../../utils";
import { useService } from "../../useService/useService.hook";
import type { UserConferenceHookProps, UserConferenceHookModel } from "./useUserConferences.definition";
import { UserNotAttendeeOfConferenceMessage } from "./useUserConferences.definition";

export const useUserConferences = (props: UserConferenceHookProps): UserConferenceHookModel => {
  const { autoFetchData, data, path, showNotifications, useOffline, validateOffline, userContext } = props;

  const offlineDataKey = useMemo(
    () => (isNullOrWhiteSpace(path) ? OfflineApiServiceKey.GetUserConferences : OfflineApiServiceKey.GetUserLobbyConference),
    [path]
  );

  const notificationService = useMemo(() => new NotificationService(), []);

  const { current, items, loading, service, fetch, setCurrent, setItems } = useService({
    autoFetchData: false,
    data,
    entityName: Entity.Conference,
    offlineDataKey,
    showNotifications,
    useOffline,
    entityModel: Conference,
    serviceModel: UserConferenceService,
    validateOffline,
  });

  const _mapPresentations = useCallback((conference: Conference): Presentation[] => {
    return conference._presentation.map(
      (y) =>
        new Presentation({
          ...y,
          start_date: mapTimeZone(y.start_date, conference.time_zone),
          end_date: mapTimeZone(y.end_date, conference.time_zone),
          _speaker: Array.isArray(y?._speaker)
            ? y?._speaker?.map((z) => (conference?._speaker || []).find((a) => a._id === z._id))
            : [],
        })
    );
  }, []);

  const _mapOndemand = useCallback((conference: Conference): OnDemand[] => {
    return conference._ondemand_presentation.map((x) => new OnDemand(x));
  }, []);

  const handleFetchById = useCallback(
    async (id: string) => {
      return service.getById(id).then((response) => {
        const conference = response?.data;

        if (
          response?.success === false &&
          response?.message.includes(UserNotAttendeeOfConferenceMessage) &&
          !isEmpty(userContext)
        ) {
          notificationService.error(`${userContext.user?.email} email cannot be found for the conference`);

          // Timeout is set to show the user notification error
          setTimeout(() => {
            userContext.signOut();
          }, 1000);
          return null;
        }

        if (isEmpty(conference)) {
          setItems(null);
          setCurrent(null);
          return null;
        }

        const updated = new Conference({
          ...conference,
          _presentation: _mapPresentations(conference),
          _ondemand_presentation: _mapOndemand(conference),
        });

        setItems([updated]);
        setCurrent(updated);

        return updated;
      });
    },
    [_mapOndemand, _mapPresentations, service, setCurrent, setItems, userContext, notificationService]
  );

  const handleFetch = useCallback(() => {
    if (!isNullOrWhiteSpace(path)) {
      return handleFetchById(path).then((conference) => [conference]);
    }

    return fetch().then((conferences) => {
      const updated = conferences.map((x) => {
        return new Conference({
          ...x,
          _presentation: _mapPresentations(x),
          _ondemand_presentation: _mapOndemand(x),
        });
      });

      setItems(() => updated);
      setCurrent((current) => updated.find((x) => x._id === current?._id) ?? null);

      return updated;
    });
  }, [_mapOndemand, _mapPresentations, fetch, handleFetchById, path, setCurrent, setItems]);

  useAutoFetch({
    autoFetchData,
    data,
    fetch: handleFetch,
    fetchBy: handleFetchById,
    setEntity: setCurrent,
    setEntities: setItems,
  });

  return {
    current,
    loading,
    items: items,
    fetch: handleFetch,
    fetchById: handleFetchById,
    setCurrent,
    setItems,
  };
};
