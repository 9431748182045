import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { CustomSelectIdModel } from "../../../customSelect/customSelect.definition";
import type { QuestionsFormChildrenProps } from "../../questionsForm.definition";

export type QuestionsFormSelectProps = QuestionsFormChildrenProps;

export class QuestionsFormSelectIdModel extends IdModelBase {
  customSelect: CustomSelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.customSelect = new CustomSelectIdModel(`${this.id}CustomSelect`);
  }
}
