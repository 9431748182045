import { isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import {
  QuestionCardWrapperIdModel,
  QuestionCardWrapperProps,
} from "../../questionCardWrapper/questionCardWrapper.definition";

export type ConsentQuestionProps = Omit<
  QuestionCardWrapperProps,
  "currentQuestionTypeLabel" | "typeConfigurationFields" | "textConfigurationFields"
>;

export class ConsentQuestionIdModel extends QuestionCardWrapperIdModel {
  statement: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.statement = new TextboxIdModel(`${this.id}AgreeStatement`);
  }
}
