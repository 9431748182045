import { isEmpty, isNil, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { ApiResponse } from "../../../services/api/api.definition";
import { Company } from "../../../services/company/company.model";
import { Conference } from "../../../services/conference/conference.model";
import ConferenceService from "../../../services/conference/conference.service";
import { CompanyLobbyParams } from "../../../views/company/lobby/companyLobby.definition";
import { useBackgroundImage } from "../../useBackgroundImage/useBackroundImage.hook";
import { PublicConferenceHookModel, PublicConferenceHookProps } from "./usePublicConference.definition";

export const usePublicConference = (props: PublicConferenceHookProps): PublicConferenceHookModel => {
  const { showNotifications: showNotificationsProp, autoFetchData: autoFetchDataProp } = props;

  const params = useParams<CompanyLobbyParams>();

  const [loading, setLoading] = useState(autoFetchDataProp);
  const conferenceService = useMemo(() => new ConferenceService(), []);

  const [conference, setConference] = useState<Conference>();
  const [company, setCompany] = useState<Company>();
  const notificationService = useMemo(() => new NotificationService(), []);

  const autoFetchData = useMemo(() => (isNil(autoFetchDataProp) ? true : autoFetchDataProp), [autoFetchDataProp]);
  const showNotifications = useMemo(
    () => (isNil(showNotificationsProp) ? true : showNotificationsProp),
    [showNotificationsProp]
  );

  const companyName = useMemo(() => company?.name, [company]);
  const hasConference = useMemo(() => !isEmpty(conference), [conference]);

  const backgroundImageSrc = useMemo(
    () => (loading || isNullOrWhiteSpace(company?.branding?.background) ? null : company.branding.background),
    [company?.branding?.background, loading]
  );
  const backgroundImage = useBackgroundImage(backgroundImageSrc);

  const conferenceBackgroundImageSrc = useMemo(
    () => (loading || isNullOrWhiteSpace(conference?.image_background) ? null : conference.image_background),
    [conference?.image_background, loading]
  );
  const conferenceBackgroundImage = useBackgroundImage(conferenceBackgroundImageSrc);

  const logoSrc = useMemo(() => company?.branding?.logo, [company?.branding?.logo]);
  const logoImage = useBackgroundImage(logoSrc);

  const conferenceLogoSrc = useMemo(() => conference?.image_logo, [conference?.image_logo]);
  const conferenceLogoImage = useBackgroundImage(conferenceLogoSrc);

  const _handleConferenceResponse = useCallback(
    (response: ApiResponse<Conference>): Conference => {
      if (!response.success) {
        showNotifications && notificationService.error("Failed to find open conference.");
        return null;
      }

      const conference = response?.data;
      setConference(conference);
      setCompany(conference._company);
      return conference;
    },
    [notificationService, showNotifications]
  );

  const getPublicConferenceByPath = useCallback(
    (path: string): Promise<Conference> => {
      setLoading(true);

      return conferenceService
        .getPublicConferenceByPath(path)
        .then(_handleConferenceResponse)
        .finally(() => setLoading(false));
    },
    [conferenceService, _handleConferenceResponse]
  );

  useEffect(() => {
    if (!autoFetchData || !params?.conferencePath) return;

    getPublicConferenceByPath(params.conferencePath);
  }, [autoFetchData, getPublicConferenceByPath, params.conferencePath]);

  return {
    company,
    backgroundImage,
    loading,
    conference,
    conferenceBackgroundImage,
    conferenceLogoImage,
    hasConference,
    logoImage,
    companyName,
    fetchConferenceByPath: getPublicConferenceByPath,
  };
};
