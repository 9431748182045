import { isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import Cookies from "js-cookie";
import { useContext, useMemo, useRef } from "react";
import config from "../../config/config";
import { AdminUserContext } from "../../contexts/adminUser/adminUser.context";
import HelpCentreService from "../../services/helpCentre/helpCentre.service";
import { HelpCentreHookModel } from "./useHelpCentre.definition";

export const useHelpCentre = (): HelpCentreHookModel => {
  const { user: adminUser } = useContext(AdminUserContext);
  const helpCentreService = useRef(new HelpCentreService());
  const notificationService = useMemo(() => new NotificationService(), []);

  async function authenticateForHelpCentre() {
    const helpCentreToken = await helpCentreService.current.getTokenByEmail(adminUser.email);

    if (isNullOrWhiteSpace(helpCentreToken?.data?.access_token)) {
      return false;
    }

    const { tokenKey, domain, secure } = config.helpCentre;
    Cookies.set(tokenKey, helpCentreToken.data.access_token, {
      expires: 1, // expires after 1 day
      domain,
      secure,
    });
    return true;
  }

  function isAuthorizedToOpenHelpCentre() {
    return !!Cookies.get(config.helpCentre.tokenKey);
  }

  async function handleHelpCentreClick() {
    if (isAuthorizedToOpenHelpCentre()) {
      window.open(config.helpCentre.url);
      return;
    }

    const authenticated = await authenticateForHelpCentre();
    if (authenticated) {
      window.open(config.helpCentre.url);
      return;
    }

    notificationService.error("Unable to authenticate for help center");
  }

  return {
    authenticateForHelpCentre,
    isAuthorizedToOpenHelpCentre,
    handleHelpCentreClick,
  };
};

export default useHelpCentre;
