import "./meetingRequests.component.scss";
import { Button, isEmpty, Text, TextPreset } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { JsonFormLabels } from "../../../../../components/jsonForm/jsonForm.definition";
import { DefaultTimeZone } from "../../../../../const";
import { DateFormat } from "../../../../../definitions/date.definition";
import { MeetingRequest, RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import MeetingRequestForm from "../meetingRequestFormV2/meetingRequestForm.component";
import { MeetingRequestClassName, MeetingRequestsIdModel, MeetingRequestsProps } from "./meetingRequests.definition";

const MeetingRequests = (props: MeetingRequestsProps): JSX.Element => {
  const {
    id,
    onStep,
    loading,
    conference,
    registrant,
    setRegistrant,
    meetingRequests,
    setMeetingRequests,
    disabled = false,
  } = props;

  const idModel = useMemo(() => new MeetingRequestsIdModel(id), [id]);

  const updateMeetingRequestText = useMemo(() => {
    if (!conference?.investor_deadlines?.meeting_request?.isValid()) return "";

    const timeZone = registrant?.time_zone || conference?.time_zone || DefaultTimeZone;
    const date = conference.investor_deadlines.meeting_request.clone().tz(timeZone)?.format(DateFormat.FullDateNth);
    return (
      <>
        You will be able to update your requests until <b>{date}</b>.
      </>
    );
  }, [registrant?.time_zone, conference]);

  function handleNextClick() {
    setRegistrant((current) => {
      const meeting_requests = (meetingRequests || []).reduce((requests, request) => {
        if (isEmpty(request)) return requests;
        if (Object.values(request).some((x) => isEmpty(x))) return requests;

        requests.push(request);
        return requests;
      }, [] as MeetingRequest[]);

      return new RegistrantViewModel({
        ...current,
        meeting_requests,
      });
    });
    onStep(true);
  }

  return (
    <div id={idModel.id} className={MeetingRequestClassName.Base}>
      {!disabled && (
        <>
          <Text preset={TextPreset.Title} className={MeetingRequestClassName.Title}>
            Available for Meetings
          </Text>
          <Text preset={TextPreset.Subheader} className={MeetingRequestClassName.Subtitle}>
            Select the companies you would like to meet with. {updateMeetingRequestText}
          </Text>
        </>
      )}
      <MeetingRequestForm
        id={idModel.form?.id}
        meetingRequests={meetingRequests}
        setMeetingRequests={setMeetingRequests}
        conference={conference}
        disabled={disabled}
      />
      <div className={MeetingRequestClassName.Navigation}>
        <Button
          id={idModel.previousStep?.id}
          className={"registration_back-button"}
          label={JsonFormLabels.PrevStep}
          disabled={loading}
          onClick={() => onStep(false)}
        />
        <Button id={idModel.nextStep?.id} label={JsonFormLabels.NextStep} loading={loading} onClick={handleNextClick} />
      </div>
    </div>
  );
};

export default memo(MeetingRequests);
