import { isEmpty, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import type { Entity } from "../../definitions/entity.definition";
import type { Attendee } from "../../services/attendee/attendee.model";
import { Conference } from "../../services/conference/conference.model";
import ConferenceService from "../../services/conference/conference.service";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import type { ConferencesHookModel, ConferencesHookProps } from "./useConferences.definition";

export const useConferences = (props: ConferencesHookProps): ConferencesHookModel => {
  const { autoFetchData, data, id: conferenceId } = props;

  const [conferences, setConferences] = useState<Conference[]>([]);
  const [conferenceCompanies, setConferenceCompanies] = useState<Array<Attendee["company"]>>(null);
  const [currentConference, setCurrentConference] = useState<Conference>();
  const [loading, setLoading] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);

  const conferenceService = useMemo(() => new ConferenceService(), []);

  const notificationService = useRef(new NotificationService());

  const id = useMemo(() => currentConference?._id || conferenceId, [conferenceId, currentConference]);

  const getConferences = useCallback((): Promise<Conference[]> => {
    return conferenceService.getConferences().then((response): Conference[] => {
      setLoadFailed(!response.success);
      if (!response.success) {
        notificationService.current.error("Failed to load conferences.");
        return [];
      }

      const conferenceData = response.data ?? [];
      const conference = isEmpty(conferenceData) && isNullOrWhiteSpace(id) ? null : conferenceData.find((x) => x._id === id);
      setCurrentConference(conference);

      setConferences(conferenceData);
      return conferenceData;
    });
  }, [conferenceService, id]);

  const getConferenceCompaniesById = useCallback(
    (_id: Conference["_id"], onlyChat = false): Promise<Array<Attendee["company"]>> => {
      return conferenceService.getConferenceCompaniesById(_id, onlyChat).then((response): Array<Attendee["company"]> => {
        if (!response.success) {
          notificationService.current.error("Failed to load company list.");
          setLoadFailed(true);
          return [];
        }
        const conferenceData = sortCompaniesAlphabetically(response.data ?? []);
        setConferenceCompanies(conferenceData);
        return conferenceData;
      });
    },
    [conferenceService]
  );

  const getConferenceById = useCallback(
    (_id: Conference["_id"]): Promise<Conference> => {
      return conferenceService
        .getConferenceById(_id)
        .then((response): Conference => {
          if (!response?.success) {
            throw new Error("Failed to get the conference.");
          }

          const conference = response?.data;
          if (!isEmpty(conference)) {
            setCurrentConference(response.data);
          }

          return conference;
        })
        .catch((error): Conference => {
          notificationService.current.error(error.message);
          setLoadFailed(true);
          return null;
        });
    },
    [conferenceService]
  );

  const handleConferenceDeleteById = useCallback(
    (_id: Conference["_id"]): Promise<boolean> => {
      return conferenceService
        .deleteConferenceById(_id)
        .then((response): boolean => {
          if (!response?.success) {
            throw new Error("Failed to cancel the conference.");
          }

          // update conferences if the conference is part of the collection
          if (!isEmpty(conferences) && !isEmpty(response?.data)) {
            setConferences(conferences.filter((conference): boolean => conference._id !== _id));
          }

          if (!isEmpty(currentConference) && currentConference._id === _id) {
            setCurrentConference(null);
          }

          notificationService.current.success("The conference has been deleted successfully.");

          return !!response?.success;
        })
        .catch((error): boolean => {
          notificationService.current.error(error.message);
          return false;
        });
    },
    [conferenceService, conferences, currentConference]
  );

  const handleConferenceReportById = useCallback(
    (_id: Conference["_id"]): Promise<void> => {
      return conferenceService
        .getConferenceReportById(_id)
        .then((response): void => {
          if (!response?.success) {
            throw new Error("Failed to generate a report for the conference.");
          }
        })
        .catch((error): void => {
          notificationService.current.error(error.message);
        });
    },
    [conferenceService]
  );

  const fetchConferences = useCallback((): Promise<Conference[]> => {
    setLoading(true);
    setLoadFailed(false);

    return getConferences()
      .then((conferenceData) => {
        return Promise.resolve(conferenceData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getConferences]);

  const fetchConferenceCompaniesById = useCallback(
    (_id: Conference["_id"], onlyChat = false): Promise<Array<Attendee["company"]>> => {
      if (isNullOrWhiteSpace(_id)) return Promise.resolve(null);
      setLoading(true);
      setLoadFailed(false);

      return getConferenceCompaniesById(_id, onlyChat)
        .then((companies: Array<Attendee["company"]>) => {
          return companies;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getConferenceCompaniesById]
  );

  const deleteConferenceById = useCallback(
    (_id: Conference["_id"]): Promise<boolean> => {
      if (isNullOrWhiteSpace(_id)) {
        return Promise.resolve(false);
      }
      setLoading(true);

      return handleConferenceDeleteById(_id)
        .then((success) => {
          return success;
        })
        .catch(() => {
          return false;
        })
        .then((success) => {
          return success;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [handleConferenceDeleteById]
  );

  const fetchConferenceById = useCallback(
    (_id: Conference["_id"], conference?: Conference): Promise<Conference> => {
      if (isNullOrWhiteSpace(_id)) return Promise.resolve(null);

      if (conference?._id === _id) {
        setCurrentConference(conference);
        return Promise.resolve(conference);
      }
      setLoading(true);
      setLoadFailed(false);

      return getConferenceById(_id)
        .then((conferenceData) => {
          return conferenceData;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getConferenceById]
  );

  function postConference(payload: Conference): Promise<Conference> {
    setLoading(true);
    return conferenceService
      .postConference(payload)
      .then((response): Conference => {
        if (!response?.success) {
          notificationService.current.error("Failed to schedule the conference.");
          return;
        }

        notificationService.current.success("The conference has been scheduled successfully.");

        const { data: conference } = response;
        if (!isEmpty(conferences) && !isEmpty(conference)) {
          setConferences((state) => [...state, conference]);
        }

        return conference;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function putConferenceById(_id: Conference["_id"], payload: Conference): Promise<Conference> {
    setLoading(true);
    return conferenceService
      .putConferenceById(_id, payload)
      .then((response): Conference => {
        if (!response?.success) {
          notificationService.current.error("Failed to update the conference.");
          return;
        }

        notificationService.current.success("The conference has been updated successfully.");

        // update conferences if the conference is part of the collection
        const { data: conference } = response;
        if (!isEmpty(conferences) && !isEmpty(conference)) {
          setConferences((state) =>
            state.map((x): Conference => (x._id === _id ? new Conference({ ...x, ...conference }) : x))
          );
        }

        return conference;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function patchSchedulerById(
    _id: Conference["_id"],
    data: Conference["scheduler"],
    noLoader = false
  ): Promise<Conference["scheduler"]> {
    if (!noLoader) setLoading(true);

    return conferenceService
      .patchConferenceSchedulerById(_id, data)
      .then((response) => {
        if (!response?.success) {
          notificationService.current.error("Failed to update the conference scheduler.");
          return;
        }

        const { data: scheduler } = response;

        if (!isEmpty(conferences)) {
          setConferences((state) => state.map((x) => (x._id === _id ? new Conference({ ...x, scheduler }) : x)));
        }

        if (!isEmpty(currentConference) && currentConference?._id === _id) {
          const updatedConference = new Conference({ ...currentConference, scheduler });
          setCurrentConference(updatedConference);
          return updatedConference.scheduler;
        }

        return scheduler;
      })
      .finally(() => {
        if (!noLoader) setLoading(false);
      });
  }

  async function clearConferenceEntityById(_id: Conference["_id"], type: Entity, futureOnly?: boolean): Promise<boolean> {
    try {
      const response = await conferenceService.clearConferenceEntityById(_id, type, futureOnly);
      if (!response?.success) {
        throw new Error(`Failed to clear the ${type} list.`);
      }

      notificationService.current.success(`${futureOnly ? "Future" : "The"} ${type} list has been cleared successfully.`);

      return !!response?.success;
    } catch (error) {
      notificationService.current.error((error as Error).message);
      return false;
    }
  }

  function sortCompaniesAlphabetically(companies: Array<Attendee["company"]>): Array<Attendee["company"]> {
    if (isEmpty(companies)) return [];
    return companies.sort();
  }

  const verifyPresentationVendor = useCallback(
    (hubId: string) => {
      setLoading(true);
      return conferenceService.checkPresentationVendorAccess(hubId).finally(() => setLoading(false));
    },
    [conferenceService, setLoading]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: id,
    fetch: fetchConferences,
    fetchBy: fetchConferenceById,
    setEntity: setCurrentConference,
    setEntities: setConferences,
  });

  return {
    items: conferences,
    conferenceCompanies,
    current: currentConference,
    loading,
    loadFailed,
    fetch: fetchConferences,
    fetchById: fetchConferenceById,
    fetchConferenceCompaniesById,
    setCurrent: setCurrentConference,
    post: postConference,
    putById: putConferenceById,
    patchSchedulerById,
    deleteById: deleteConferenceById,
    getReportById: handleConferenceReportById,
    clearById: clearConferenceEntityById,
    setItems: setConferences,
    verifyPresentationVendor,
    setLoading,
  };
};
