import { AccordionIdModel, BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { ImportResult } from "../../../../services/import/import.definition";

export interface RequestProgressResultsTableProps<T> extends BaseComponentProps {
  rows: ImportResult<T>[];
  autoScrollToBottom?: boolean;
  showExportErrors: boolean;
  showCsvExport?: boolean;
}

export enum RequestProgressResultsGroupHeader {
  Successful = "Successful",
  Unsuccessful = "Unsuccessful",
}

export class RequestProgressResultsIdModel extends IdModelBase {
  constructor(id: string) {
    super(id);
  }
}

export class RequestProgressResultsGroupIdModel extends IdModelBase {
  successAccordion: AccordionIdModel;
  failedAccordion: AccordionIdModel;
  exportCsvButton: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.successAccordion = new AccordionIdModel(`${this.id}AccordionSuccess`);
    this.failedAccordion = new AccordionIdModel(`${this.id}AccordionFailed`);
    this.exportCsvButton = `${this.id}ExportCsvButton`;
  }
}
