import { Textbox, Checkbox, Form, Button, getClassName, FormFieldProps, ErrorModel, isEmpty } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { QuestionType } from "../../../services/questionGroup/questionGroup.model";
import { getOptionLabel, isFirstOption } from "../../../utils/customQuestionAnswer.utils";
import { QuestionCardWrapper } from "../../questionCardWrapper/questionCardWrapper.component";
import { QuestionCardWrapperClassNames } from "../../questionCardWrapper/questionCardWrapper.definition";
import { QuestionTemporaryId } from "../../registrationForm/registrationForm.definition";
import { QuestionCardFieldLimit, QuestionCardLabels } from "../questionCard.definition";
import { ChoiceQuestionIdModel, ChoiceQuestionProps } from "./choiceQuestion.definition";

export function ChoiceQuestion(props: ChoiceQuestionProps): JSX.Element {
  const { id, currentQuestion, currentQuestionTypeLabel, handleQuestionUpdate, ...cardProps } = props;
  const { _id: questionId, answer_type, options, add_other_option } = currentQuestion;

  const idModel = useMemo(() => new ChoiceQuestionIdModel(id), [id]);

  const isAllowMultiple = useMemo(() => answer_type?.field_type === QuestionType.MultipleChoice, [answer_type]);
  const isAnswerTypeEditable = useMemo(() => questionId?.includes(QuestionTemporaryId), [questionId]);

  const handleMultipleAnswerChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        answer_type: {
          ...currentQuestion?.answer_type,
          field_type: value ? QuestionType.MultipleChoice : QuestionType.Choice,
        },
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const handleAddOption = useCallback(
    (newOption): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        options: [...options, newOption],
      });
    },
    [options, currentQuestion, handleQuestionUpdate]
  );

  const handleRemoveOption = useCallback(
    (optionNumber): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        options: options?.filter((each, idx) => idx !== optionNumber),
      });
    },
    [options, currentQuestion, handleQuestionUpdate]
  );

  const handleAddOther = useCallback((): void => {
    handleQuestionUpdate({
      ...currentQuestion,
      add_other_option: true,
    });
  }, [currentQuestion, handleQuestionUpdate]);

  const handleRemoveOther = useCallback((): void => {
    handleQuestionUpdate({
      ...currentQuestion,
      add_other_option: false,
    });
  }, [currentQuestion, handleQuestionUpdate]);

  const answerTextTypeFields = useMemo(() => {
    function handleUpdateOption(optionNumber: number): (value: string) => void {
      return function (value: string): void {
        const key = `_questions[${cardProps.idx - 1}].options[${optionNumber}].answer`;
        const formKey = cardProps.formKeys?.find((form) => form.key === key);
        if (optionNumber < 1 && !isEmpty(formKey)) {
          cardProps.setFormErrors((previousState) => ({
            ...previousState,
            [key]: new ErrorModel(formKey?.message, formKey?.validation(value)),
          }));
        }

        handleQuestionUpdate({
          ...currentQuestion,
          options: options?.map((eachOption, idx) => {
            if (idx === optionNumber) {
              return { ...eachOption, answer: value };
            }
            return eachOption;
          }),
        });
      };
    }

    const renderRemoveIcon = (handleRemove: (index?: number) => void, index?: number) => (
      <div
        id={idModel.removeOption.getId(index)}
        className={QuestionCardWrapperClassNames.removeIcon}
        onClick={() => handleRemove(index)}
      >
        <span>
          <i className="q4i-close-4pt" />
        </span>
      </div>
    );

    const optionFields: FormFieldProps[] = options?.map((eachOption, idx: number) => {
      return {
        key: getOptionLabel(idx + 1),
        width: "1-of-1",
        label: getOptionLabel(idx + 1),
        required: isFirstOption(idx),
        error: isFirstOption(idx)
          ? cardProps?.formErrors?.[`_questions[${cardProps.idx - 1}].options[${idx}].answer`]
          : null,
        children: (
          <>
            <Textbox
              id={idModel.optionField.getId(idx)}
              className={getClassName(null, [
                { condition: !isFirstOption(idx), trueClassName: QuestionCardWrapperClassNames.optionFieldWrapper },
              ])}
              maxLength={QuestionCardFieldLimit.Option}
              value={eachOption.answer}
              onChange={handleUpdateOption(idx)}
            />
            {!isFirstOption(idx) && renderRemoveIcon(handleRemoveOption, idx)}
          </>
        ),
      };
    });

    add_other_option &&
      optionFields.push({
        key: getOptionLabel(options.length + 1),
        width: "1-of-1",
        label: getOptionLabel(options.length + 1),
        required: false,
        children: (
          <>
            <Textbox
              id={idModel.otherOptionField.id}
              className={QuestionCardWrapperClassNames.optionFieldWrapper}
              value="Other"
              disabled
            />
            {renderRemoveIcon(handleRemoveOther)}
          </>
        ),
      });

    const optionButtons = {
      key: "Buttons",
      width: "1-of-1",
      children: (
        <div>
          <Button
            id={idModel.addOptionButton.id}
            label={QuestionCardLabels.addOption}
            className={QuestionCardWrapperClassNames.linkButton}
            icon="q4i-add-4pt"
            onClick={() => handleAddOption({ answer: "", default: false })}
          />
          <Button
            id={idModel.addOtherOptionButton.id}
            label={QuestionCardLabels.addOtherOption}
            className={QuestionCardWrapperClassNames.linkButton}
            icon="q4i-add-4pt"
            disabled={!!add_other_option}
            onClick={handleAddOther}
          />
        </div>
      ),
    };
    return <Form id={idModel.optionForm.id} fields={[...optionFields, optionButtons]} />;
  }, [
    options,
    add_other_option,
    idModel,
    handleRemoveOther,
    handleAddOther,
    cardProps,
    handleQuestionUpdate,
    currentQuestion,
    handleRemoveOption,
    handleAddOption,
  ]);

  const answerConfigFields = useMemo(
    () => (
      <Checkbox
        id={idModel.multipleAnswer.id}
        checked={isAllowMultiple}
        label={QuestionCardLabels.multipleAnswers}
        disabled={!isAnswerTypeEditable}
        onChange={handleMultipleAnswerChange}
      />
    ),
    [isAnswerTypeEditable, idModel.multipleAnswer.id, isAllowMultiple, handleMultipleAnswerChange]
  );

  return (
    <QuestionCardWrapper
      id={idModel.id}
      typeConfigurationFields={answerConfigFields}
      textConfigurationFields={answerTextTypeFields}
      currentQuestion={currentQuestion}
      currentQuestionTypeLabel={currentQuestionTypeLabel}
      handleQuestionUpdate={handleQuestionUpdate}
      {...cardProps}
    />
  );
}

export default memo(ChoiceQuestion);
