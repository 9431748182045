import "./poweredBy.component.scss";
import { Anchor, AnchorTarget } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import Section from "../../../section/section.component";
import { SectionInnerPadding, SectionKeyLine } from "../../../section/section.definition";
import { PoweredByClassName, PoweredByIdModel, PoweredByProps } from "./poweredBy.definition";

function PoweredBy(props: PoweredByProps): JSX.Element {
  const { id, title } = props;

  const idModel = useMemo(() => new PoweredByIdModel(id), [id]);

  return (
    <Section
      id={idModel.id}
      className={PoweredByClassName.Base}
      innerPadding={SectionInnerPadding.Thin}
      keyLine={SectionKeyLine.Top}
    >
      <h6 id={idModel.title} className={PoweredByClassName.Title}>
        {title}
      </h6>
      <Anchor
        id={idModel.anchor}
        className={PoweredByClassName.Anchor}
        url="https://www.q4inc.com/Powered-by-Q4/"
        target={AnchorTarget.Blank}
      >
        Powered by <i className="q4i-logo" />
      </Anchor>
    </Section>
  );
}

export default memo(PoweredBy);
