import "./meetingRequestForm.component.scss";
import {
  ComponentSizeModifier,
  convertStringToEnum,
  Field,
  Form,
  Grid,
  GridColumn,
  isEmpty,
  isNullOrWhiteSpace,
  Pagination,
  Search,
  Select,
  SelectPreset,
  SelectTheme,
  Text,
  TextPreset,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import { usePaginatedData } from "../../../../../hooks/usePaginatedData/usePaginatedData.hook";
import { MeetingInterestLevel, MeetingRequest } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../services/corporateProfile/corporateProfile.model";
import { ErrorModel } from "../../../../../services/errorHandler/errorHandler.definition";
import { MeetingLabel } from "../../../../../services/meeting/meeting.model";
import { getBackgroundImageUrl, byCompanyName } from "../../../../../utils";
import {
  InvestorFormMeetingRequests,
  MeetingRequestFormProps,
  MeetingRequestFormClassName,
  SelectOption,
  MeetingRequestFormFilterOptions,
  MeetingRequestFormPaginationOptions,
  MeetingRequestFormIdModel,
} from "./meetingRequestForm.definition";

const MeetingRequestForm = (props: MeetingRequestFormProps): JSX.Element => {
  const {
    id,
    conference,
    meetingRequests,
    formErrors = {},
    title,
    disabled = false,
    setMeetingRequests,
    setFormErrors,
  } = props;

  const { corporate_profiles } = conference;
  const idModel = useMemo(() => new MeetingRequestFormIdModel(id), [id]);

  const [searchFilter, setSearchFilter] = useState<string>();
  const [filterValue, setFilterValue] = useState<string>(MeetingRequestFormFilterOptions.All);

  const searchedCorporateProfiles = useMemo(() => {
    const corporateProfiles = (corporate_profiles || []).sort(byCompanyName);
    return searchFilter
      ? corporateProfiles.filter((profile) => profile.name.toLowerCase().includes(searchFilter.toLowerCase()))
      : corporateProfiles;
  }, [corporate_profiles, searchFilter]);

  const visibleCorporateProfiles = useMemo(() => {
    switch (filterValue) {
      case MeetingRequestFormFilterOptions.All:
        return searchedCorporateProfiles;

      case MeetingRequestFormFilterOptions.Requested:
        if (isEmpty(meetingRequests)) return [];
        return searchedCorporateProfiles.filter((profile) =>
          meetingRequests.some((request) => request._corporate_profile === profile._id)
        );

      case MeetingRequestFormFilterOptions.Unrequested:
        if (isEmpty(meetingRequests)) return searchedCorporateProfiles;
        return searchedCorporateProfiles.filter((profile) => {
          const hasHalfRequestedMeetings = meetingRequests.some(
            (request) => request._corporate_profile === profile._id && (!request.interest_level || !request.meeting_type)
          );
          const hasNoRequest = !meetingRequests.some((request) => request._corporate_profile === profile._id);
          return hasNoRequest || hasHalfRequestedMeetings;
        });

      default:
        return searchedCorporateProfiles;
    }
  }, [filterValue, meetingRequests, searchedCorporateProfiles]);

  const { pageData, paginationState, handlePageChange, handlePageSizeChange } = usePaginatedData<CorporateProfile>({
    data: visibleCorporateProfiles,
    initialPaginationState: { page: 0, pageSize: 25, pageCount: 1 },
  });

  const formMeetingRequests = useMemo(() => {
    if (isEmpty(meetingRequests)) return {};
    return meetingRequests.reduce((acc, meeting) => {
      acc[meeting._corporate_profile] = { meeting_type: meeting.meeting_type, interest_level: meeting.interest_level };
      return acc;
    }, {} as InvestorFormMeetingRequests);
  }, [meetingRequests]);

  const meetingTypeOptions = useMemo(() => {
    return [
      {
        label: "None",
        value: "None",
      },
      {
        label: "1:1",
        value: "1:1",
      },
      {
        label: "Group",
        value: "Group",
      },
    ];
  }, []);

  const meetingInterestOptions = useMemo(() => {
    return [
      {
        label: "None",
        value: "None",
      },
      {
        label: "High",
        value: "High",
      },
      {
        label: "Medium",
        value: "Medium",
      },
      {
        label: "Low",
        value: "Low",
      },
    ];
  }, []);

  const handleFormError = useCallback(
    (key: string, message: string, isVisisble: boolean) => {
      if (setFormErrors) {
        setFormErrors((previousState) => ({
          ...previousState,
          [key]: new ErrorModel(message, isVisisble),
        }));
      }
    },
    [setFormErrors]
  );

  const handleSelectChange = useCallback(
    (id: CorporateProfile["_id"], key: keyof MeetingRequest) => {
      return (selectedOption: SelectOption) => {
        const value = !selectedOption || selectedOption?.value === "None" ? undefined : selectedOption.value;

        const updatedFormMeetingRequests: InvestorFormMeetingRequests = {
          ...formMeetingRequests,
          [id]: {
            ...formMeetingRequests[id],
            [key]: value,
          },
        };

        const updatedMeetingRequests: MeetingRequest[] = (Object.entries(updatedFormMeetingRequests) || []).reduce(
          (requests, request) => {
            if (isEmpty(request)) return requests;
            const [profileId, value] = request || [];

            if (
              isNullOrWhiteSpace(profileId) ||
              (isNullOrWhiteSpace(value?.interest_level) && isNullOrWhiteSpace(value?.meeting_type))
            ) {
              // if meeting request has been removed, remove error message
              handleFormError(`meetingType-${profileId}`, "Required", false);
              handleFormError(`interestLevel-${profileId}`, "Required", false);
              return requests;
            }

            requests.push({
              _corporate_profile: profileId,
              interest_level: convertStringToEnum(MeetingInterestLevel, value.interest_level),
              meeting_type: convertStringToEnum(MeetingLabel, value.meeting_type),
            });
            handleFormError(`meetingType-${profileId}`, "Required", isNullOrWhiteSpace(value.meeting_type));
            handleFormError(`interestLevel-${profileId}`, "Required", isNullOrWhiteSpace(value.interest_level));

            return requests;
          },
          []
        );

        setMeetingRequests(updatedMeetingRequests);
      };
    },
    [formMeetingRequests, setMeetingRequests, handleFormError]
  );

  const renderCorporateInfo = useCallback((profile: CorporateProfile): JSX.Element => {
    return (
      <GridColumn margin={false} width="3-of-12" smallWidth="3-of-12">
        <div className={MeetingRequestFormClassName.CorporateInfo}>
          {!isNullOrWhiteSpace(profile.logo_image) && (
            <div
              className={MeetingRequestFormClassName.CorporateLogo}
              style={{
                backgroundImage: isNullOrWhiteSpace(profile.logo_image) ? null : getBackgroundImageUrl(profile.logo_image),
              }}
            />
          )}
          <div>
            <h3 className={MeetingRequestFormClassName.CorporateName}>{profile.name}</h3>
            {!isNullOrWhiteSpace(profile.ticker_symbol) && (
              <h4 className={MeetingRequestFormClassName.CorporateTicker}>
                {profile.ticker_symbol} | {profile.exchange}
              </h4>
            )}
            {!isNullOrWhiteSpace(profile.industry) && (
              <h4 className={MeetingRequestFormClassName.CorporateIndustry}>
                <strong>Industry:</strong> {profile.industry}
              </h4>
            )}
          </div>
        </div>
      </GridColumn>
    );
  }, []);

  const renderCorporateAttendees = useCallback((profile: CorporateProfile): JSX.Element => {
    return (
      <GridColumn
        className={MeetingRequestFormClassName.CorporateAttendees}
        margin={false}
        width="3-of-12"
        smallWidth="3-of-12"
      >
        {!isEmpty(profile.attendees)
          ? profile.attendees.map((x) => {
              const attendee = new AttendeeViewModel(x);
              return (
                <div key={attendee.display_name}>
                  <strong>{attendee.display_name}</strong>
                  {attendee.title && `, ${attendee.title}`}
                </div>
              );
            })
          : "TBD"}
      </GridColumn>
    );
  }, []);

  const renderMeetingRequestInputs = useCallback(
    (profile: CorporateProfile): JSX.Element => {
      return (
        <GridColumn
          className={MeetingRequestFormClassName.CorporateMeetingInterest}
          margin={false}
          width="6-of-12"
          smallWidth="6-of-12"
        >
          <GridColumn margin={false} width="1-of-2" smallWidth="1-of-2">
            Meeting Type (Required)
          </GridColumn>
          <GridColumn margin={false} width="1-of-2" smallWidth="1-of-2">
            Interest Level (Required)
          </GridColumn>
          <GridColumn margin={false} width="1-of-1" smallWidth="1-of-1">
            <Form
              fields={[
                {
                  key: "meetingTypeField",
                  width: "1-of-2",
                  smallWidth: "1-of-2",
                  margin: false,
                  required: true,
                  error: formErrors?.[`meetingType-${profile._id}`],
                  children: (
                    <Select
                      className={MeetingRequestFormClassName.MeetingType}
                      value={{
                        label: formMeetingRequests[profile._id]?.meeting_type,
                        value: formMeetingRequests[profile._id]?.meeting_type,
                      }}
                      onChange={handleSelectChange(profile._id, "meeting_type")}
                      options={meetingTypeOptions}
                      valueKey="value"
                      labelKey="label"
                      theme={SelectTheme.White}
                      size={ComponentSizeModifier.Small}
                      preset={SelectPreset.Dropdown}
                      placeholder="None"
                      disabled={disabled}
                    />
                  ),
                },
                {
                  key: "interestLevel",
                  width: "1-of-2",
                  smallWidth: "1-of-2",
                  margin: false,
                  required: true,
                  error: formErrors?.[`interestLevel-${profile._id}`],
                  children: (
                    <Select
                      key={`${profile._id}_interestLevel`}
                      className={MeetingRequestFormClassName.InterestLevel}
                      value={{
                        label: formMeetingRequests[profile._id]?.interest_level,
                        value: formMeetingRequests[profile._id]?.interest_level,
                      }}
                      onChange={handleSelectChange(profile._id, "interest_level")}
                      options={meetingInterestOptions}
                      valueKey="value"
                      labelKey="label"
                      theme={SelectTheme.White}
                      size={ComponentSizeModifier.Small}
                      preset={SelectPreset.Dropdown}
                      placeholder="None"
                      disabled={disabled}
                    />
                  ),
                },
              ]}
            />
          </GridColumn>
        </GridColumn>
      );
    },
    [formErrors, formMeetingRequests, handleSelectChange, meetingTypeOptions, disabled, meetingInterestOptions]
  );

  const renderEmptyStateMessage = useCallback(() => {
    if (searchFilter)
      return (
        <div className={MeetingRequestFormClassName.EmptyState}>
          No corporates or issuers found matching &apos;{searchFilter}&apos;.
        </div>
      );
    return <div className={MeetingRequestFormClassName.EmptyState}>No corporates or issuers found.</div>;
  }, [searchFilter]);

  const renderCorporateRows = useCallback(
    (profiles: CorporateProfile[]): JSX.Element => {
      if (isEmpty(profiles)) return renderEmptyStateMessage();
      return (
        <div className={MeetingRequestFormClassName.Corporate}>
          <Grid className={MeetingRequestFormClassName.CorporateHeader}>
            <GridColumn width={"3-of-12"} smallWidth={"3-of-12"}>
              COMPANY
            </GridColumn>
            <GridColumn width={"3-of-12"} smallWidth={"3-of-12"}>
              ATTENDEES
            </GridColumn>
            <GridColumn
              width={"6-of-12"}
              smallWidth={"6-of-12"}
              className={MeetingRequestFormClassName.CorporateMeetingInterest}
            >
              <GridColumn
                width={"1-of-2"}
                smallWidth={"1-of-2"}
                className={MeetingRequestFormClassName.CorporateMeetingInterest}
              >
                MEETING TYPE
              </GridColumn>
              <GridColumn
                width={"1-of-2"}
                smallWidth={"1-of-2"}
                className={MeetingRequestFormClassName.CorporateMeetingInterest}
              >
                INTEREST LEVEL
              </GridColumn>
            </GridColumn>
          </Grid>
          {profiles.map((profile) => (
            <Grid key={profile._id} className={MeetingRequestFormClassName.CorporateRow}>
              {renderCorporateInfo(profile)}
              {renderCorporateAttendees(profile)}
              {renderMeetingRequestInputs(profile)}
            </Grid>
          ))}
        </div>
      );
    },
    [renderEmptyStateMessage, renderCorporateInfo, renderCorporateAttendees, renderMeetingRequestInputs]
  );

  return (
    <div id={id} className={MeetingRequestFormClassName.Base}>
      <Grid>
        <GridColumn width={"1-of-1"}>
          <Text preset={TextPreset.Title}>{title}</Text>
        </GridColumn>
        {visibleCorporateProfiles && (
          <>
            <GridColumn width={"1-of-3"} smallWidth={"1-of-1"}>
              <Field label="Search">
                <Search value={searchFilter} onInputChange={setSearchFilter} />
              </Field>
            </GridColumn>
            <GridColumn width={"1-of-3"} smallWidth={"1-of-1"}>
              <Field label="Filter Corporates">
                <Select
                  value={filterValue}
                  options={Object.values(MeetingRequestFormFilterOptions)}
                  onChange={setFilterValue}
                  theme={SelectTheme.White}
                  size={ComponentSizeModifier.Small}
                />
              </Field>
            </GridColumn>
          </>
        )}
      </Grid>
      {renderCorporateRows(pageData)}

      <Pagination
        id={idModel?.pagination.id}
        className={MeetingRequestFormClassName.Pagination}
        initialPage={paginationState.page}
        forcePage={paginationState.page}
        forcePageSize={paginationState.pageSize}
        pageCount={paginationState.pageCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        {...MeetingRequestFormPaginationOptions}
      />
    </div>
  );
};

export default memo(MeetingRequestForm);
