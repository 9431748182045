import "../availabilityForm/availabilityForm.component.scss";
import { Grid, GridColumn, RadioButton, RadioButtonProps, isEmpty } from "@q4/nimbus-ui";
import moment from "moment-timezone";
import React, { memo, useCallback } from "react";
import { YesNoOptions } from "../../hooks/nimbus/useTable/useTable.definition";
import {
  AvailabilityDateFormat,
  AvailabilityTimeFormat,
  Timeslot,
} from "../../views/public/register/components/availability/availability.definition";
import type { AvailabilityFormProps } from "./availabilityForm.definition";

const AvailabilityForm = (props: AvailabilityFormProps): JSX.Element => {
  const { disabled, timeslots, timeZone, handleTimeslotChange, days: schedulerDays } = props;

  const renderAvailabilityBlocks = useCallback(
    (slots: Timeslot[], dayIndex: number): JSX.Element[] => {
      return slots.map((slot, slotIndex) => {
        if (isEmpty(slot?.slot)) return;
        const start = moment(slot.slot.start).tz(timeZone).clone().format(AvailabilityTimeFormat);
        const end = moment(slot.slot.end).tz(timeZone).clone().format(AvailabilityTimeFormat);
        const timeSlotName = `day-${dayIndex}-timeslot-${slotIndex}`;
        const timezoneDisplay = moment(slot.slot.start).tz(timeZone).format("z");

        function handleRadioGroupChange(_checked: boolean, value: string, e: React.ChangeEvent<Element>) {
          const name = e.target.getAttribute("name");
          handleTimeslotChange(name, value);
        }

        const radioButtons: Partial<RadioButtonProps>[] = [
          {
            id: `${timeSlotName}-${YesNoOptions.Yes}`,
            name: timeSlotName,
            label: YesNoOptions.Yes,
            checked: timeslots[timeSlotName]?.isSelected,
            disabled,
            value: "true",
          },
          {
            id: `${timeSlotName}-${YesNoOptions.No}`,
            name: timeSlotName,
            label: YesNoOptions.No,
            checked: !timeslots[timeSlotName]?.isSelected,
            disabled,
            value: "false",
          },
        ];

        return (
          <div key={`${timeSlotName}-${start}`} className="availability-form_timeslot">
            <div className="availability-form_block">
              <div className="availability-form_block-time">
                {start} - {end} {timezoneDisplay}
              </div>
              <div className="availability-form_block-choice">
                {radioButtons.map((button) => (
                  <div
                    key={button.id}
                    className={`availability-form_block-choice-item availability-form_block-choice-item--${button.label}`}
                  >
                    <RadioButton
                      id={button.id}
                      name={button.name}
                      label={button.label}
                      value={button.value}
                      checked={button.checked}
                      disabled={button.disabled}
                      inline={true}
                      onChange={handleRadioGroupChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      });
    },
    [timeZone, timeslots, disabled, handleTimeslotChange]
  );

  return (
    <Grid>
      {schedulerDays?.map((day, dayIndex) => {
        if (!day) return null;
        const dateLabel = moment(day)?.tz(timeZone).format(AvailabilityDateFormat);

        const currentDaySlots = Object.entries(timeslots)?.reduce((acc, [slotKey, slotValue]) => {
          if (!slotKey.includes(`day-${dayIndex}-`)) return acc;
          acc = [...acc, slotValue];
          return acc;
        }, [] as Timeslot[]);

        if (isEmpty(currentDaySlots)) return null;

        return (
          <GridColumn key={`${dateLabel}${dayIndex}`} width={"1-of-2"} smallWidth={"1-of-1"}>
            <div className={"availability-form_table-block"}>
              <div className="availability-form_heading-date">
                <span>{dateLabel}</span>
              </div>
              <div className="availability-form_heading-label">
                <span>TIME</span>
                <span>AVAILABILITY</span>
              </div>
              <div className="availability-form_timeslots">{renderAvailabilityBlocks(currentDaySlots, dayIndex)}</div>
            </div>
          </GridColumn>
        );
      })}
    </Grid>
  );
};

export default memo(AvailabilityForm);
