import {
  isEmpty,
  Text,
  TextPreset,
  isNullOrWhiteSpace,
  Card,
  Button,
  ButtonTheme,
  CardTheme,
  Ghostable,
  TextTheme,
  BaseComponentWithChildrenProps,
} from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import { JsonFormLabels } from "../../../../../components/jsonForm/jsonForm.definition";
import { DefaultTimeZone, SupportEmail } from "../../../../../const";
import { TimeFormat } from "../../../../../definitions/date.definition";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { ConferenceType } from "../../../../../services/conference/conference.model";
import { formatTimetableDataWithTimezone, getFormattedDateForSummary, getCorporateName } from "../../../../../utils";
import TimeZoneSelector from "../../../components/timeZoneSelector/timeZoneSelector.component";
import {
  availabilityStepBaseProps,
  customQuestionsStepBaseProps,
  meetingRequestsStepBaseProps,
  personalInformationStepBaseProps,
} from "../../hooks/useSections.definition";
import { isInvestorAvailabilityChangesOpen, isInvestorMeetingRequestChangesOpen } from "../../registration.utils";
import TermsAndConditions from "../../termsAndConditions/termsAndConditions.component";
import AnswerSummary from "../answerSummary/answerSummary.component";
import { RegistrationSummaryClassName } from "../summary/summary.definition";
import { InvestorSummaryIdModel, InvestorSummaryMessage, InvestorSummaryProps } from "./investorSummary.definition";

const InvestorSummary = (props: InvestorSummaryProps): JSX.Element => {
  const {
    id,
    conference,
    registrant,
    loading,
    meetingRegistrationOpen,
    questionGroup,
    isEditMode,
    isFormDirty,
    isSlotsEmpty,
    setRegistrant,
    onStep,
    onSubmit,
  } = props;

  const idModel = useMemo(() => new InvestorSummaryIdModel(id), [id]);

  const [isPolicyAgree, setIsPolicyAgree] = useState(false);

  const {
    first_name,
    last_name,
    email,
    phone_number,
    title,
    company,
    sales_representative,
    has_secondary_contact,
    secondary_contact,
    availability,
    meeting_requests,
  } = registrant || {};

  const isPresentationOnly = useMemo(
    () => conference?.conference_type === ConferenceType.PresentationOnly,
    [conference?.conference_type]
  );

  const investorAvailabilityChangesOpen = useMemo(() => isInvestorAvailabilityChangesOpen(conference), [conference]);

  const investorMeetingRequestChangesOpen = useMemo(() => isInvestorMeetingRequestChangesOpen(conference), [conference]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference?.time_zone]);
  const userTimeZone = useMemo(
    () => registrant?.time_zone ?? conferenceTimeZone,
    [registrant?.time_zone, conferenceTimeZone]
  );

  function getFullName(first: string, last: string): string {
    return `${first} ${last}`;
  }

  function handleStepBack(): void {
    onStep(false);
  }

  function handlePolicyAgreeChange(policyAgree: boolean): void {
    setIsPolicyAgree(policyAgree);
  }

  function handlePost(): void {
    onSubmit();
  }

  // #region Render Methods
  function renderPersonalInformation(): JSX.Element {
    return (
      <Card
        id={idModel.personalInformationSection?.id}
        className={RegistrationSummaryClassName.Section}
        title="Personal Information"
        headerChildren={
          <Button
            id={idModel.personalEdit?.id}
            icon="q4i-edit-4pt"
            label="Edit"
            disabled={loading}
            theme={ButtonTheme.LightGrey}
            onClick={() => onStep(personalInformationStepBaseProps.id)}
          />
        }
        theme={CardTheme.Light}
      >
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
              Name:
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>{getFullName(first_name, last_name)}</Text>
          </div>
        </div>
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
              Company:
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>{company}</Text>
          </div>
        </div>
        {!isNullOrWhiteSpace(title) && (
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Title:
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{title}</Text>
            </div>
          </div>
        )}
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
              Email Address:
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>{email}</Text>
          </div>
        </div>
        <div className={RegistrationSummaryClassName.Block}>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
              Phone Number:
            </Text>
          </div>
          <div className={RegistrationSummaryClassName.BlockSection}>
            <Text preset={TextPreset.Paragraph}>{phone_number}</Text>
          </div>
        </div>
        <div id={idModel.salesRepSection} className={"registration-summary_block"}>
          <div className={"registration-summary_block-section"}>
            <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
              Sales Rep:
            </Text>
          </div>
          <div className={"registration-summary_block-section"}>
            <Text preset={TextPreset.Paragraph}>{sales_representative}</Text>
          </div>
        </div>
      </Card>
    );
  }

  function renderSecondaryContact(): JSX.Element {
    if (!has_secondary_contact) return;

    return (
      <>
        <Card
          id={idModel.secondaryContactSection?.id}
          className={RegistrationSummaryClassName.Section}
          title="Assistant Information"
          theme={CardTheme.Light}
        >
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Name:
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>
                {getFullName(secondary_contact?.first_name, secondary_contact?.last_name)}
              </Text>
            </div>
          </div>
          {!isNullOrWhiteSpace(secondary_contact?.title) && (
            <div className={RegistrationSummaryClassName.Block}>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                  Title:
                </Text>
              </div>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>{secondary_contact?.title}</Text>
              </div>
            </div>
          )}
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Email Address:
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{secondary_contact?.email}</Text>
            </div>
          </div>
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Phone Number:
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph}>{secondary_contact?.phone_number}</Text>
            </div>
          </div>
          <div className={RegistrationSummaryClassName.Block}>
            {secondary_contact?.receive_emails && (
              <Text preset={TextPreset.Paragraph}>
                <span className={"q4i-checkmark-4pt"}></span> Included in all communications
              </Text>
            )}
          </div>
        </Card>
      </>
    );
  }

  function renderAvailabilities(): JSX.Element {
    if (isEmpty(availability) || isPresentationOnly || (!isEditMode && !meetingRegistrationOpen) || isSlotsEmpty) return;

    const handleUserTimeZoneChange = (timeZone: string) => {
      setRegistrant((current) => {
        return new RegistrantViewModel({
          ...current,
          time_zone: timeZone,
        });
      });
    };

    const availabilityTitle: JSX.Element = (
      <>
        Availability
        <TimeZoneSelector
          id={idModel.timeZoneSelector.id}
          conferenceTimeZone={conferenceTimeZone}
          selectedTimeZone={userTimeZone}
          anchorMargin
          onSelect={handleUserTimeZoneChange}
        />
      </>
    );

    return (
      <Card
        className={RegistrationSummaryClassName.Section}
        title={availabilityTitle}
        id={idModel.availabilitySection?.id}
        headerChildren={
          investorAvailabilityChangesOpen ? (
            <Button
              id={idModel.availabilityEdit?.id}
              icon="q4i-edit-4pt"
              label="Edit"
              disabled={loading}
              theme={ButtonTheme.LightGrey}
              onClick={() => onStep(availabilityStepBaseProps.id)}
            />
          ) : null
        }
        theme={CardTheme.Light}
      >
        {!investorAvailabilityChangesOpen && (
          <Text preset={TextPreset.Paragraph} className={RegistrationSummaryClassName.Subheader}>
            We are no longer allowing self-edits to availability. Any changes must go through{" "}
            <span>{conference?.coordinator?.email ?? SupportEmail}</span>.
          </Text>
        )}
        <div className={RegistrationSummaryClassName.Availabilities}>
          <div className={RegistrationSummaryClassName.Block}>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Date
              </Text>
            </div>
            <div className={RegistrationSummaryClassName.BlockSection}>
              <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                Time
              </Text>
            </div>
          </div>
          {formatTimetableDataWithTimezone(availability, userTimeZone).map((day, idx, self) => {
            return (
              <div key={`availability-item-${idx}`} className={RegistrationSummaryClassName.Block}>
                <div className={RegistrationSummaryClassName.BlockSection}>
                  {getFormattedDateForSummary(!idx, day, self[idx - 1], userTimeZone)}
                </div>
                <div className={RegistrationSummaryClassName.BlockSection}>
                  {day.start_time.clone().tz(userTimeZone).format(TimeFormat.Standard)} -{" "}
                  {day.end_time?.clone().tz(userTimeZone).format(TimeFormat.TimezoneStandard)}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    );
  }

  function renderMeetingRequests(): JSX.Element {
    const isValidMeetingRequest = meeting_requests?.some(
      (request) => !isNullOrWhiteSpace(getCorporateName(request?._corporate_profile, conference?.corporate_profiles))
    );

    if (
      isPresentationOnly ||
      (isEditMode && (isEmpty(meeting_requests) || !isValidMeetingRequest)) ||
      (!isEditMode && !meetingRegistrationOpen)
    )
      return null;

    function MeetingCard(cardProps: BaseComponentWithChildrenProps & { showEdit?: boolean; email?: string }): JSX.Element {
      const { children, showEdit, email } = cardProps;

      return (
        <Card
          className={RegistrationSummaryClassName.Section}
          title="Meeting Requests"
          id={idModel.meetingSection?.id}
          headerChildren={
            <Ghostable appear={false} ghosted={!showEdit}>
              <Button
                id={idModel.meetingEdit?.id}
                icon="q4i-edit-4pt"
                label="Edit"
                disabled={loading}
                theme={ButtonTheme.LightGrey}
                onClick={() => onStep(meetingRequestsStepBaseProps.id)}
              />
            </Ghostable>
          }
          theme={CardTheme.Light}
        >
          {isEditMode && !showEdit && (
            <Text preset={TextPreset.Paragraph} className={RegistrationSummaryClassName.Subheader}>
              The conference schedule has been locked.{" "}
              {email && (
                <>
                  Any change requests must go through <span>{email}</span>.
                </>
              )}
            </Text>
          )}
          {children}
        </Card>
      );
    }

    if (isEmpty(meeting_requests)) {
      return (
        <MeetingCard showEdit={meetingRegistrationOpen || investorMeetingRequestChangesOpen}>
          <Text preset={TextPreset.Paragraph}>{InvestorSummaryMessage.NoMeetingRequests}</Text>
        </MeetingCard>
      );
    }

    return (
      <MeetingCard showEdit={investorMeetingRequestChangesOpen} email={conference?.coordinator?.email ?? SupportEmail}>
        {meeting_requests.map((request) => {
          const corporateName = getCorporateName(request._corporate_profile, conference?.corporate_profiles);
          return corporateName ? (
            <div key={request._corporate_profile} className={RegistrationSummaryClassName.Block}>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph} theme={TextTheme.Slate}>
                  {corporateName}
                </Text>
              </div>
              <div className={RegistrationSummaryClassName.BlockSection}>
                <Text preset={TextPreset.Paragraph}>{`${request.meeting_type} | ${request.interest_level}`}</Text>
              </div>
            </div>
          ) : null;
        })}
      </MeetingCard>
    );
  }

  // #endregion

  return (
    <div id={idModel.id} className={RegistrationSummaryClassName.Base}>
      {renderPersonalInformation()}
      {renderSecondaryContact()}
      {renderAvailabilities()}
      {renderMeetingRequests()}
      {questionGroup && !isEmpty(registrant.custom_question_answers) && (
        <AnswerSummary
          id={idModel.answerSummary?.id}
          loading={loading}
          questionGroup={questionGroup}
          answers={registrant.custom_question_answers}
          goToEdit={() => onStep(customQuestionsStepBaseProps.id)}
        />
      )}
      {isEditMode ? null : (
        <TermsAndConditions
          id={idModel.termsAndConditions?.id}
          onAgree={handlePolicyAgreeChange}
          policyAgree={isPolicyAgree}
        />
      )}
      <div className={RegistrationSummaryClassName.Section}>
        <Button
          id={idModel.stepBack?.id}
          className={"registration_back-button"}
          label={JsonFormLabels.PrevStep}
          disabled={loading}
          theme={ButtonTheme.LightGrey}
          onClick={handleStepBack}
        />
        <Button
          id={idModel.confirm?.id}
          label={isEditMode ? "UPDATE INFORMATION" : "CONFIRM REGISTRATION"}
          disabled={(!isPolicyAgree && !isEditMode) || (isEditMode && !isFormDirty)}
          loading={loading}
          theme={ButtonTheme.Rain}
          onClick={handlePost}
        />
      </div>
    </div>
  );
};

export default memo(InvestorSummary);
