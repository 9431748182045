import React from "react";
import { RoutePath } from "../../../configurations/navigation.configuration";
import PublicLink from "../../public/link/publicLink.view";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import type { RouteProps } from "./route.definition";

const CompanyRoutes = (props: RouteProps): JSX.Element[] => {
  const { userContext } = props;

  return [<PublicRoute key="link_path" exact path={RoutePath.LinkPath} userContext={userContext} component={PublicLink} />];
};

export default CompanyRoutes;
