import { PublicRouteBase } from "../../configurations/navigation.configuration";
import { getQuery } from "../../utils";
import { ApiResponse, AuthType } from "../api/api.definition";
import ApiService from "../api/api.service";
import { Conference } from "../conference/conference.model";
import { Company } from "./company.model";

export default class CompanyService {
  private readonly PUBLIC_COMPANY_API_PATH = `${PublicRouteBase}/company`;
  private apiService = new ApiService();

  public async getByUrlSuffix(urlSuffix: string): Promise<ApiResponse<Company>> {
    const query = getQuery({ url: urlSuffix });
    const response = await this.apiService.get<Company>(`${this.PUBLIC_COMPANY_API_PATH}${query}`, AuthType.Public);
    return this.mapResponse(response);
  }

  public async getOpenConferences(id: Company["_id"]): Promise<ApiResponse<Conference[]>> {
    const response = await this.apiService.get<Conference[]>(
      `${this.PUBLIC_COMPANY_API_PATH}/${id}/openconferences`,
      AuthType.Public
    );
    return this.mapConferencesResponse(response);
  }

  private mapResponse(response: ApiResponse<Company>): ApiResponse<Company> {
    return new ApiResponse({
      ...response,
      data: new Company(response?.data),
    });
  }

  private mapConferencesResponse(response: ApiResponse<Conference[]>): ApiResponse<Conference[]> {
    return new ApiResponse({
      ...response,
      data: (response?.data || []).map((x) => new Conference(x)),
    });
  }
}
