import { BannerIdModel, ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { DeleteConfirmationMessageIdModel } from "../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.definition";
import { DateFormat } from "../../../../definitions/date.definition";
import { AttendeesIdModel } from "./components/attendees/attendees.definition";
import { ConferenceBannerDetailsIdModel } from "./components/conferenceBannerDetails/conferenceBannerDetails.definition";
import { CorporateProfilesIdModel } from "./components/corporateProfiles/corporateProfiles.definition";
import { MeetingsIdModel } from "./components/meetings/meetings.definition";
import { OnDemandIdModel } from "./components/onDemand/onDemand.definition";
import { PresentationsIdModel } from "./components/presentations/presentations.definition";
import { RegistrantsIdModel } from "./components/registrants/registrants.definition";
import { SpeakersIdModel } from "./components/speakers/speakers.definition";
import { TracksIdModel } from "./components/tracks/tracks.definition";

export enum ConferenceDetailsTab {
  Registration = "REGISTRATION",
  CorporateProfiles = "CORPORATE PROFILES",
  Attendees = "ATTENDEES",
  OnDemand = "ON DEMAND",
  Presentations = "PRESENTATIONS",
  Meetings = "MEETINGS",
  Speakers = "SPEAKERS",
  Tracks = "TRACKS",
}

export interface ConferenceDetailsMatchParams {
  conferenceId: string;
}

export const DETAILS_DATE_FORMAT = DateFormat.FullStandard;

// #region Automation
export enum ConferenceDetailsClassName {
  Base = "conference-details",
  Title = "conference-details_title",
  Audit = "conference-details_audit",
  Content = "conference-details_content",
  Image = "conference-details_image",
  ImageContent = "conference-details_image-content",
  ImageWithLoadedModifier = "conference-details_image--loaded",
}

export type ConferenceDetailsIdModelTabs = Pick<
  PageIdModel,
  | "attendeesTab"
  | "corporateProfilesTab"
  | "meetingsTab"
  | "presentationsTab"
  | "onDemandTab"
  | "registrantsTab"
  | "registrationTab"
  | "speakersTab"
  | "tracksTab"
>;
class PageIdModel extends IdModelBase {
  banner: BannerIdModel;
  reports: ButtonIdModel;
  delete: ButtonIdModel;
  edit: ButtonIdModel;
  description: string;
  tabs: string;
  attendeesTab: string;
  presentationsTab: string;
  meetingsTab: string;
  onDemandTab: string;
  speakersTab: string;
  tracksTab: string;
  registrantsTab: string;
  registrationTab: string;
  corporateProfilesTab: string;
  attendees: AttendeesIdModel;
  meetings: MeetingsIdModel;
  presentations: PresentationsIdModel;
  onDemand: OnDemandIdModel;
  speakers: SpeakersIdModel;
  tracks: TracksIdModel;
  registrants: RegistrantsIdModel;
  corporateProfiles: CorporateProfilesIdModel;
  deleteMessage: DeleteConfirmationMessageIdModel;
  conferenceBannerDetails: ConferenceBannerDetailsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.banner = new BannerIdModel(`${this.id}Banner`);
    this.delete = new ButtonIdModel(`${this.id}DeleteButton`);
    this.reports = new ButtonIdModel(`${this.id}ReportsButton`);
    this.edit = new ButtonIdModel(`${this.id}EditButton`);
    this.description = `${this.id}Description`;
    this.tabs = `${this.id}Tabs`;
    this.attendeesTab = `${this.id}AttendeesTab`;
    this.presentationsTab = `${this.id}PresentationsTab`;
    this.meetingsTab = `${this.id}MeetingsTab`;
    this.onDemandTab = `${this.id}OnDemandTab`;
    this.speakersTab = `${this.id}SpeakersTab`;
    this.tracksTab = `${this.id}TracksTab`;
    this.registrantsTab = `${this.id}RegistrantsTab`;
    this.registrationTab = `${this.id}RegistrationTab`;
    this.corporateProfilesTab = `${this.id}CorporateProfilesTab`;
    this.attendees = new AttendeesIdModel(`${this.id}Attendees`);
    this.presentations = new PresentationsIdModel(`${this.id}Presentations`);
    this.onDemand = new OnDemandIdModel(`${this.id}onDemand`);
    this.meetings = new MeetingsIdModel(`${this.id}Meetings`);
    this.speakers = new SpeakersIdModel(`${this.id}Speakers`);
    this.tracks = new TracksIdModel(`${this.id}Tracks`);
    this.registrants = new RegistrantsIdModel(`${this.id}Registrants`);
    this.corporateProfiles = new CorporateProfilesIdModel(`${this.id}CorporateProfiles`);
    this.deleteMessage = new DeleteConfirmationMessageIdModel(`${this.id}DeleteMessage`);
    this.conferenceBannerDetails = new ConferenceBannerDetailsIdModel(`${this.id}BannerDetails`);
  }
}

const PageId = "ConferenceDetails";
export const ConferenceDetailsIdModel = new PageIdModel(PageId);
// #endregion
