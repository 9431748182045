import { VendorName } from "../../../../../services/conference/conference.model";
import { MsTeamsDialInNumbers, ZoomDialInNumbers } from "./dialInformation.utils";

export function getDialInNumbers<T>(
  vendorName: VendorName,
  numberCallback: (item: string) => T,
  countryCallback: (key: string, item: T[]) => T,
  regionCallback: (group: T[], index: number) => T
): T[] {
  if (!Object.values(VendorName).includes(vendorName)) return;

  const regions = vendorName === VendorName.Zoom ? ZoomDialInNumbers : MsTeamsDialInNumbers;
  return (regions || []).map((region, regionIndex) => {
    const groupRegion = (region || []).map((country): T => {
      const { country: countryName, numbers } = country;
      const countryNumbers = (numbers || []).map((number: string): T => {
        return numberCallback(number);
      });
      return countryCallback(countryName, countryNumbers);
    });
    return regionCallback(groupRegion, regionIndex);
  });
}
