import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import * as Providers from "./providers/providers.context";

const ContextProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const { children } = props;
  const keys = useMemo(() => Object.keys(Providers), []);

  const providerMapper = (index = 0): JSX.Element => {
    const key = keys[index] as keyof typeof Providers;
    const Provider = Providers[key];
    return <Provider key={index}>{++index < keys.length ? providerMapper(index) : children}</Provider>;
  };

  return providerMapper();
};

export default memo(ContextProvider);
