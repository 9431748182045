import "./customQuestions.component.scss";
import { Card, CardTheme } from "@q4/nimbus-ui";
import { ISubmitEvent } from "@rjsf/core";
import React, { memo, useMemo, useCallback } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import { useCustomQuestionsForm } from "../../../../../hooks/useCustomQuestionsForm/useCustomQuestionsForm.hook";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../../services/attendee/attendee.model";
import {
  CustomQuestionsIdModel,
  CustomQuestionsLabels,
  CustomQuestionsProps,
  CustomQuestionsClassName,
  CustomQuestionsFormData,
} from "./customQuestions.definition";
import { convertFormAnswersToModel, convertFormAnswersToModelForEditMode } from "./customQuestions.utils";

const CustomQuestions = (props: CustomQuestionsProps): JSX.Element => {
  const { id, questionGroup, registrant, isEditMode, originalRegistrant, onStep, onChange } = props;
  const idModel = useMemo(() => new CustomQuestionsIdModel(id), [id]);
  const questions = useMemo(() => {
    return registrant.attendee_type === AttendeeType.Corporate
      ? questionGroup._questions.filter((question) => !question.is_individual_corporate)
      : questionGroup._questions;
  }, [questionGroup, registrant]);

  const onNavigation = useCallback(
    (forward: boolean | number | string, formData: CustomQuestionsFormData): void => {
      const answers = isEditMode
        ? convertFormAnswersToModelForEditMode(questions, originalRegistrant?.custom_question_answers, formData)
        : convertFormAnswersToModel(questions, registrant, formData).filter((answer) => !!answer.answer);

      onChange(
        new RegistrantViewModel({
          ...registrant,
          custom_question_answers: answers,
        })
      );

      onStep(forward);
    },
    [isEditMode, onChange, onStep, originalRegistrant, questions, registrant]
  );

  const handleSubmit = useCallback(
    (response: ISubmitEvent<RegistrantViewModel>): void => {
      const formData = response?.formData as unknown as CustomQuestionsFormData;
      onNavigation(true, formData);
    },
    [onNavigation]
  );

  const onBackNavigation = useCallback(
    (_forward: boolean | number | string, updatedRegistrantData: RegistrantViewModel) => {
      const formData = updatedRegistrantData as unknown as CustomQuestionsFormData;
      onNavigation(false, formData);
    },
    [onNavigation]
  );

  const {
    schema,
    uiSchema,
    errorHandler: errorHandlerFields,
  } = useCustomQuestionsForm({
    questions: questions,
    registrant,
    useDefaultIds: true,
  });

  return (
    <div id={idModel.id} className={CustomQuestionsClassName.Base}>
      <Card
        id={idModel.customQuestionsSection?.id}
        className={CustomQuestionsClassName.Section}
        title={questionGroup?.title || CustomQuestionsLabels.Title}
        theme={CardTheme.Light}
      >
        {schema && (
          <JsonForm
            id={idModel.jsonForm?.id}
            errorHandlerFields={[errorHandlerFields]}
            schema={schema}
            uiSchema={uiSchema}
            data={registrant}
            onSubmit={handleSubmit}
            onStep={onBackNavigation}
          />
        )}
      </Card>
    </div>
  );
};

export default memo(CustomQuestions);
