import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { validateEmail } from "../../utils";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";
import { Conference } from "../conference/conference.model";
import {
  Attendee,
  AttendeeComposite,
  AttendeeCompositeKey,
  AttendeePayload,
  AttendeePutPayload,
  AttendeeViewModel,
  AttendeePassword,
} from "./attendee.model";

export default class AttendeeService {
  private readonly ATTENDEES_API_PATH = `${AdminRouteBase}/attendees/v1`;
  private apiService = new ApiService();

  public async getAttendees(): Promise<ApiResponse<AttendeeViewModel[]>> {
    const response = await this.apiService.get<Attendee[]>(`${this.ATTENDEES_API_PATH}`);
    return this.mapAttendeesResponse(response);
  }

  public async getAttendeeById(_id: Attendee["_id"]): Promise<ApiResponse<AttendeeViewModel>> {
    const response = await this.apiService.get<Attendee>(`${this.ATTENDEES_API_PATH}/${_id}`);
    return this.mapAttendeeByIdResponse(response);
  }

  public async getAttendeesByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<AttendeeViewModel[]>> {
    const response = await this.apiService.get<AttendeeViewModel[]>(`${this.ATTENDEES_API_PATH}/byConference/${_id}`);
    return this.mapAttendeesResponse(response);
  }

  public async postAttendee(data: AttendeeViewModel): Promise<ApiResponse<AttendeeViewModel>> {
    if (isEmpty(data)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Failed to add new Attendee." }));
    }

    const response = await this.apiService.post<AttendeePayload, Attendee>(
      `${this.ATTENDEES_API_PATH}`,
      new AttendeePayload(data)
    );
    return this.mapAttendeeByIdResponse(response);
  }

  public async putAttendeeById(_id: Attendee["_id"], data: AttendeeViewModel): Promise<ApiResponse<AttendeeViewModel>> {
    const response = await this.apiService.put<AttendeePutPayload, Attendee>(
      `${this.ATTENDEES_API_PATH}/${_id}`,
      new AttendeePutPayload(data)
    );
    return this.mapAttendeeByIdResponse(response);
  }

  public async upsertAttendee(data: AttendeeViewModel): Promise<ApiResponse<AttendeeViewModel>> {
    if (!this.validateAttendeeCompositeKey(data)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Attendee composite key is invalid" }));
    }

    const response = await this.apiService.put<AttendeePutPayload, Attendee>(
      `${this.ATTENDEES_API_PATH}`,
      new AttendeePutPayload(data)
    );
    return this.mapAttendeeByIdResponse(response);
  }

  public async deleteAttendeeById(_id: string): Promise<ApiResponse<AttendeeViewModel>> {
    const response = await this.apiService.delete<Attendee>(`${this.ATTENDEES_API_PATH}/${_id}`);
    return this.mapAttendeeByIdResponse(response);
  }

  public async generateAttendeePassword(_id: Attendee["_id"]): Promise<ApiResponse<AttendeePassword>> {
    return this.apiService.get<AttendeePassword>(`${this.ATTENDEES_API_PATH}/${_id}/generatePassword`);
  }

  private mapAttendeeByIdResponse(response: ApiResponse<Attendee>): ApiResponse<AttendeeViewModel> {
    return new ApiResponse({
      ...response,
      data: new AttendeeViewModel(response?.data),
    });
  }

  private mapAttendeesResponse(response: ApiResponse<Attendee[]>): ApiResponse<AttendeeViewModel[]> {
    return new ApiResponse({
      ...response,
      data: (response?.data ?? []).map((attendee) => new AttendeeViewModel(attendee)),
    });
  }

  private validateAttendeeCompositeKey(data: AttendeeViewModel): boolean {
    const keys = new AttendeeComposite(data);
    return Object.keys(keys).some((x) => {
      const key = x as keyof AttendeeCompositeKey;
      const value = data[key];
      if (isNullOrWhiteSpace(value)) return false;
      if (key === "email" && !validateEmail(value)) return false;
      return true;
    });
  }
}
