import {
  FileUploaderTheme,
  isNullOrWhiteSpace,
  isEmpty,
  getClassName,
  FileUploaderClassName,
  FileUploaderIdModel,
  ImageExtensions,
  getExtension,
  FileExtension,
  FileUploaderConfirmationMessage,
  ButtonTheme,
  FileType,
  SpinnerTheme,
  convertBlobToUrl,
  Text,
  TextPreset,
  TextTheme,
  Spinner,
  Swapable,
  Button,
  Collapsable,
  Ghostable,
} from "@q4/nimbus-ui";
import React, { memo, useMemo, useRef } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { getBackgroundImageUrl } from "../../utils";
import { useFileManagerController } from "./fileManagerController/fileManager.controller";
import { FilePreviewerMessage, FilePreviewerProps } from "./filePreviewer.definition";

export const FilePreviewer = (props: FilePreviewerProps): JSX.Element => {
  const {
    dataId,
    className,
    id,
    theme: themeProp,
    disabled,
    leftActions,
    rightActions,
    loading,
    required,
    showActions,
    dropzoneProps,
    renderValidationText,
    ...fileManagerControllerProps
  } = props;

  const copyTextRef = useRef<HTMLTextAreaElement>();
  const dropzoneRef = useRef<DropzoneRef>();

  const {
    fileUrl,
    currentBlob,
    currentFileType,
    removing,
    saving,
    handleDialogOpen,
    handleUpload,
    handleRemovePath,
    handleRemoveRequest,
    handleRemoveRequestCancel,
  } = useFileManagerController({
    ...fileManagerControllerProps,
    dropzoneRef,
  });

  // #region Lifecycle Methods
  const theme = useMemo(() => (isEmpty(themeProp) ? FileUploaderTheme.LightGrey : themeProp), [themeProp]);

  const helperButtonDisabled = useMemo(
    (): boolean => saving || isNullOrWhiteSpace(fileUrl) || disabled,
    [saving, disabled, fileUrl]
  );

  const baseClassName = useMemo(
    () =>
      getClassName(FileUploaderClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
        {
          condition: isEmpty(theme),
          falseClassName: `${FileUploaderClassName.Base}--${theme}`,
        },
        { condition: !disabled, falseClassName: FileUploaderClassName.BaseWithDisabledModifier },
      ]),
    [className, disabled, theme]
  );

  const hasFile = useMemo(
    () => !isNullOrWhiteSpace(currentFileType) || !isNullOrWhiteSpace(fileUrl),
    [currentFileType, fileUrl]
  );

  const fileTitle = useMemo((): string => currentBlob?.name ?? "", [currentBlob]);

  const idModel = useMemo((): FileUploaderIdModel => new FileUploaderIdModel(id), [id]);

  const dropzoneDisabled = useMemo((): boolean => disabled || removing, [disabled, removing]);

  const isImage = useMemo((): boolean => {
    if (isNullOrWhiteSpace(currentFileType) && isNullOrWhiteSpace(fileUrl)) return false;
    return currentFileType?.includes("image") || ImageExtensions.includes(getExtension(fileUrl) as FileExtension);
  }, [currentFileType, fileUrl]);

  const confirmation = useMemo((): FileUploaderConfirmationMessage => {
    if (!removing) return null;
    return new FileUploaderConfirmationMessage(
      {
        label: "REMOVE",
        theme: ButtonTheme.Spice,
        onClick: handleRemovePath,
      },
      handleRemoveRequestCancel,
      "Remove this thumbnail?",
      "Caution: This cannot be undone."
    );
  }, [handleRemovePath, handleRemoveRequestCancel, removing]);
  // #endregion

  // #region Helper Methods
  function getIconClass(currentFileType: string, url: string): string {
    if (!hasFile) return "ni-export-2pt";

    if (!isNullOrWhiteSpace(currentFileType) && !currentFileType.includes(FileType.Html)) {
      if (currentFileType.includes(FileType.Pdf)) {
        return "ni-pdf-2pt";
      }
      if (currentFileType.includes(FileType.Excel) || currentFileType.includes(FileType.Spreadsheet)) {
        return "ni-xls-2pt";
      }
      if (currentFileType.includes(FileType.Audio) || currentFileType.includes(FileType.Video)) {
        return "ni-webcast-2pt";
      }
      if (currentFileType.includes(FileType.Powerpoint) || currentFileType.includes(FileType.Presentation)) {
        return "ni-presentation-2pt";
      }
      return "ni-report-open-2pt";
    }

    const extension = getExtension(url);
    switch (extension) {
      case FileExtension.Excel:
      case FileExtension.ExcelX:
        return "ni-xls-2pt";
      case FileExtension.Pdf:
        return "ni-pdf-2pt";
      case FileExtension.Mp3:
      case FileExtension.Mp4:
        return "ni-webcast-2pt";
      case FileExtension.Powerpoint:
      case FileExtension.PowerpointX:
        return "ni-presentation-2pt";
      default:
        return "ni-report-open-2pt";
    }
  }
  // #endregion

  // #region Render Methods
  function renderPreview(url: string): JSX.Element {
    if (saving || loading) {
      const spinnerTheme = theme === FileUploaderTheme.LightGrey ? SpinnerTheme.Rain : SpinnerTheme.White;
      return (
        <div className={FileUploaderClassName.Preview}>
          <Spinner masked={false} theme={spinnerTheme} />
        </div>
      );
    }

    if (isImage && hasFile) {
      const file = currentBlob ? convertBlobToUrl(currentBlob) : url;
      return (
        <div className={`${FileUploaderClassName.Preview} ${FileUploaderClassName.PreviewWithTransparencyMask}`}>
          <div
            className={`${FileUploaderClassName.PreviewContent} ${FileUploaderClassName.PreviewContentWithImageModifier}`}
            style={{ backgroundImage: getBackgroundImageUrl(file) }}
          />
        </div>
      );
    }

    const renderTextLine = (text: string): JSX.Element => (
      <span className={`${FileUploaderClassName.PreviewContent} ${FileUploaderClassName.PreviewContentWithTextModifier}`}>
        {text}
      </span>
    );

    const previewClassName = getClassName(FileUploaderClassName.Preview, [
      { condition: hasFile, trueClassName: FileUploaderClassName.PreviewContentWithFileUploadedModifier },
      { condition: isEmpty(theme), falseClassName: `${FileUploaderClassName.Preview}--${theme}` },
    ]);
    const typeIconClass = getIconClass(currentFileType, url);
    const contentText = hasFile ? FilePreviewerMessage.Replace : FilePreviewerMessage.Upload;

    return (
      <div className={previewClassName}>
        <span
          className={`${FileUploaderClassName.PreviewContent} ${FileUploaderClassName.PreviewContentWithIconModifier} ${typeIconClass}`}
        />
        {renderTextLine(contentText)}
        {renderValidationText && (
          <>
            {renderTextLine("(Max Size: 2.5MB)")}
            {renderTextLine("(Formats: JPG, JPEG, GIF, PNG, WEBP)")}
          </>
        )}
      </div>
    );
  }

  function renderDropzoneActions(): JSX.Element {
    return (
      <div className={`${FileUploaderClassName.Splash} ${FileUploaderClassName.SplashActions}`}>
        {!required && (
          <Button
            id={idModel.remove?.id}
            theme={ButtonTheme.Gunpowder}
            onClick={handleRemoveRequest}
            disabled={helperButtonDisabled}
            stopPropagation
            icon="ni-trashbin-4pt"
            tooltip={{ label: "Remove" }}
          />
        )}
      </div>
    );
  }

  function renderConfirmation(): JSX.Element {
    if (isEmpty(confirmation)) return null;
    return (
      <div className={`${FileUploaderClassName.Splash} ${FileUploaderClassName.SplashConfirmation}`}>
        <Text preset={TextPreset.Subheader} theme={TextTheme.White}>
          {confirmation.message}
        </Text>
        <div className={FileUploaderClassName.SplashConfirmationActions}>
          <Button
            id={idModel.cancel?.id}
            label="CANCEL"
            theme={ButtonTheme.Gunpowder}
            onClick={confirmation.cancel}
            stopPropagation
          />
          <Button
            id={idModel.replace?.id}
            label={confirmation.action.label}
            theme={confirmation.action.theme}
            onClick={confirmation.action.onClick}
            stopPropagation
          />
        </div>
        <Text preset={TextPreset.Paragraph} theme={TextTheme.White}>
          {confirmation.warning}
        </Text>
      </div>
    );
  }

  function renderActions(): JSX.Element {
    const collapsed = !showActions && (!isEmpty(leftActions) || !isEmpty(rightActions)) ? false : !showActions;
    return (
      <Collapsable className={FileUploaderClassName.Actions} collapsed={collapsed}>
        {(leftActions || []).map((props, idx) => {
          const keyPrefix = isNullOrWhiteSpace(idModel.id) ? "" : `${idModel.id}-`;
          return (
            <Button
              key={`${keyPrefix}file-uploader_left-action--${idx}`}
              theme={ButtonTheme.Rain}
              disabled={disabled || removing}
              {...props}
            />
          );
        })}
        <Button
          id={idModel.upload?.id}
          theme={ButtonTheme.Rain}
          disabled={disabled || removing}
          onClick={handleDialogOpen}
          icon="ni-export-2pt"
          label="UPLOAD"
        />
        {(rightActions || []).map((props, idx) => {
          const keyPrefix = isNullOrWhiteSpace(idModel.id) ? "" : `${idModel.id}-`;
          return (
            <Button
              key={`${keyPrefix}file-uploader_left-action--${idx}`}
              theme={ButtonTheme.Rain}
              disabled={disabled || removing}
              {...props}
            />
          );
        })}
      </Collapsable>
    );
  }
  // #endregion

  return (
    <div id={idModel.id} className={baseClassName} data-id={dataId}>
      <textarea className={FileUploaderClassName.CopyText} ref={copyTextRef} value={fileUrl || ""} readOnly tabIndex={-1} />
      <Dropzone disabled={dropzoneDisabled} ref={dropzoneRef} onDrop={handleUpload} {...dropzoneProps}>
        {({ getRootProps, getInputProps }): JSX.Element => (
          <div className={FileUploaderClassName.Content}>
            <div className={FileUploaderClassName.Dropzone} title={fileTitle} {...getRootProps()}>
              <input id={idModel.dropzone} {...getInputProps()} disabled={dropzoneDisabled} />
              {renderPreview(fileUrl)}
              <Ghostable ghosted={!hasFile && !removing}>
                <Swapable selected={+removing} layers={[renderDropzoneActions(), renderConfirmation()]} />
              </Ghostable>
            </div>
            {renderActions()}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default memo(FilePreviewer);
