import moment from "moment-timezone";
import { EntityBase } from "../../definitions/entity.definition";
import { Conference } from "../../services/conference/conference.model";
import { formatTimeZoneLabel } from "../../utils";

export function getModalFormSubtitle<T extends EntityBase>(time_zone: Conference["time_zone"], current: T): string {
  return time_zone
    ? `Updated ${moment(current.updated_at).tz(time_zone).format("h:mm:ssa")} ${formatTimeZoneLabel(
        time_zone,
        current.updated_at
      )} ${moment(current.updated_at).format("MM/D/YYYY")} - ${current.updated_by ?? "Unknown"}`
    : `Last modified by ${current.updated_by ?? "Unknown"}`;
}
