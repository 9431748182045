import { IdModelBase, isNullOrWhiteSpace, SelectIdModel, TextboxIdModel } from "@q4/nimbus-ui";
import { JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import type { RegistrationFormProps } from "../../definition/registrationForm.definition";

export interface RegistrationPersonalInformationProps extends RegistrationFormProps {
  companyName: string;
  guestOtherTypesEnabled?: boolean;
  isEditMode?: boolean;
}

export class PersonalInformationIdModel extends IdModelBase {
  jsonForm: JsonFormIdModel;
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  title: TextboxIdModel;
  email: TextboxIdModel;
  company: TextboxIdModel;
  referral: TextboxIdModel;
  otherSubType: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);
    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.company = new TextboxIdModel(`${this.id}CompanyTextbox`);
    this.referral = new TextboxIdModel(`${this.id}ReferralTextbox`);
    this.otherSubType = new SelectIdModel(`${this.id}OtherSubTypeSelect`);
  }
}
