import { RegistrantStatus } from "../admin/registrant/registrant.model";
import { ApiResponse, AuthType, ContentType } from "../api/api.definition";
import ApiService from "../api/api.service";
import type { Conference } from "../conference/conference.model";
import {
  AuthRequestAccessResponse,
  AuthRequestLinkResponse,
  AuthRequestModel,
  AuthValidateAttendeeResponse,
} from "./auth.model";

export default class AuthService {
  private readonly API_PATH = "/auth";
  private readonly VERSION = "v1";
  private apiService = new ApiService();

  validateAttendee(companyUrlSuffix: string, email: string): Promise<ApiResponse<AuthValidateAttendeeResponse>> {
    const payload = new AuthRequestModel({ companyUrlSuffix, email });
    return this.apiService.post<AuthRequestModel, AuthValidateAttendeeResponse>(
      `${this.API_PATH}/validateAttendee/${this.VERSION}`,
      payload,
      ContentType.Json,
      AuthType.Public
    );
  }

  validateAttendeeByConferenceId(
    companyUrlSuffix: string,
    email: string,
    conferenceId: Conference["_id"]
  ): Promise<ApiResponse<AuthValidateAttendeeResponse<RegistrantStatus>>> {
    const payload = new AuthRequestModel({ companyUrlSuffix, email });
    return this.apiService.post<AuthRequestModel, AuthValidateAttendeeResponse<RegistrantStatus>>(
      `${this.API_PATH}/validateAttendee/${this.VERSION}/${conferenceId}`,
      payload,
      ContentType.Json,
      AuthType.Public
    );
  }

  requestLink(
    companyUrlSuffix: string,
    conferenceId: string,
    email: string,
    isPasscode = false
  ): Promise<ApiResponse<AuthRequestLinkResponse>> {
    const payload = new AuthRequestModel({ companyUrlSuffix, email, conferenceId });
    const requestURI = !isPasscode ? "requestLink" : "requestCode";
    return this.apiService.post<AuthRequestModel, AuthRequestLinkResponse>(
      `${this.API_PATH}/${requestURI}/${this.VERSION}`,
      payload,
      ContentType.Json,
      AuthType.Public
    );
  }

  requestAccess(
    companyUrlSuffix: string,
    conferenceId: string,
    email: string,
    password: string,
    code: string
  ): Promise<ApiResponse<AuthRequestAccessResponse>> {
    const payload = new AuthRequestModel({ companyUrlSuffix, conferenceId, email, password, code });

    return this.apiService.post<AuthRequestModel, AuthRequestAccessResponse>(
      `${this.API_PATH}/dblogin/${this.VERSION}`,
      payload,
      ContentType.Json,
      AuthType.Public
    );
  }
}
