import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import { mapEntities } from "..";
import { DefaultTimeZone } from "../../const";
import { DateFormat, TimeFormat } from "../../definitions/date.definition";
import { AttendeeType, AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { CompanyRouteParams } from "../../services/company/company.model";
import type { Conference } from "../../services/conference/conference.model";
import { Meeting } from "../../services/meeting/meeting.model";
import { Presentation } from "../../services/presentation/presentation.model";
import { PublicLinkData } from "../../services/public/link/publicLink.definition";
import { SessionEventType } from "../../services/session/session.model";
import type { Speaker } from "../../services/speaker/speaker.model";
import {
  AgendaMeeting,
  AgendaPresentation,
  AgendaSession,
} from "../../views/public/itinerary/components/agenda/agenda.definition";
import { getMeetingLabel } from "../meeting/meeting.utils";
import { sortEntitiesByDate } from "../object.utils";

export function formatSessionDate(date: Moment, timezone?: string): string {
  if (isEmpty(date) || !date?.isValid()) return "";
  if (timezone) return date.tz(timezone).format(DateFormat.Short);
  return date.format(DateFormat.Short);
}

export function getUTCDateTime(timeZone = DefaultTimeZone, date: Moment, time?: Moment | string): Moment {
  if (isEmpty(date) || !date?.isValid()) return date;

  const dateFormat = DateFormat.ShortStandard;
  const timeString = isEmpty(time)
    ? ""
    : typeof time === "string"
    ? time
    : moment.tz(time, timeZone).format(TimeFormat.Picker);

  const formattedDateString = isNullOrWhiteSpace(timeString)
    ? date.format(dateFormat)
    : `${formatSessionDate(date)} ${timeString}`;

  const timeZoneDate = moment.tz(formattedDateString, dateFormat, timeZone);

  return moment.utc(timeZoneDate, dateFormat, timeZone);
}

export function isCorporate(user: AttendeeViewModel): boolean {
  if (isEmpty(user)) return false;
  return isCorporateType(user.type);
}

export function isCorporateType(userType: AttendeeViewModel["type"]): boolean {
  return userType === AttendeeType.Corporate;
}

export function isInvestorType(userType: AttendeeViewModel["type"]): boolean {
  return userType === AttendeeType.Investor;
}

export function isInternalType(userType: AttendeeViewModel["type"]): boolean {
  return userType === AttendeeType.Internal;
}

export function isInvestor(user: AttendeeViewModel): boolean {
  if (isEmpty(user)) return false;
  return isInvestorType(user.type);
}

export function isInternal(user: AttendeeViewModel): boolean {
  if (isEmpty(user)) return false;
  return isInternalType(user.type);
}

export function getAgenda(
  meetings: Meeting[],
  presentations: Presentation[],
  attendees = [] as AttendeeViewModel[],
  user: AttendeeViewModel,
  conference: Conference,
  company: CompanyRouteParams
): AgendaSession[] {
  if (isEmpty(meetings) && isEmpty(presentations)) return [];

  const speakers = conference?._speaker ?? [];
  const preview = !!conference?.preview;

  const meetingSession = (meetings || []).map((x) => {
    x._attendee = !isEmpty(attendees) && mapEntities<AttendeeViewModel>(x._attendee, attendees);
    x.label = getMeetingLabel(x);
    return new AgendaMeeting(x, user, conference, company, preview);
  });

  const presentationSession = (presentations || []).map((x) => {
    x._speaker = !isEmpty(speakers) ? mapEntities<Speaker>(x._speaker, speakers) : [];
    return new AgendaPresentation(x, user, conference, company, preview);
  });

  const sessions = [...meetingSession, ...presentationSession];
  return sortEntitiesByDate(sessions, "start_date");
}

export type AttendeeMeetingsAndPresentations = { meetings: Meeting[]; presentations: Presentation[] };
export function getAttendeeMeetingsAndPresentations(
  id: AttendeeViewModel["_id"],
  mtgs: Meeting[],
  ppts: Presentation[]
): AttendeeMeetingsAndPresentations {
  if (isNil(mtgs) && isNil(ppts)) return null;
  return {
    meetings: (mtgs || []).reduce((meetings, m) => {
      return m._attendee.some((x) => x._id === id) ? meetings.concat(m) : meetings;
    }, [] as Meeting[]),
    presentations: (ppts || []).reduce((presentations, p) => {
      return p._attendee.some((x) => x._id === id) || isAttendeeSpeaker(id, p._speaker) || !!p.add_to_all
        ? presentations.concat(p)
        : presentations;
    }, [] as Presentation[]),
  };
}

export function isAttendeeSpeaker(user: AttendeeViewModel | string, speakers: Speaker[]): boolean {
  if (isEmpty(user) || isEmpty(speakers)) return false;

  const userId = typeof user === "string" ? user : user._id;
  return speakers.some((s) => s._attendee === userId);
}

export function getPublicLinkData(sessions: (Meeting | Presentation)[], user: Partial<AttendeeViewModel>): PublicLinkData[] {
  return sessions.map((x) => ({
    id: x._id,
    user: user._id,
    type: x instanceof Presentation ? SessionEventType.Presentation : SessionEventType.Meeting,
  }));
}

export function getPillText(
  isPresentation: boolean,
  isBreakSession: boolean,
  meetingType: string,
  presentationType: string
): string {
  if (!isPresentation) {
    return meetingType;
  } else if (isBreakSession) {
    return "BREAK";
  }

  return presentationType;
}
