import { convertBlobToUrl, convertFileToBlob, isEmpty, isNil, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import type { MouseEvent } from "react";
import { FileRejection } from "react-dropzone";
import {
  FileManagerController,
  FileManagerControllerProps,
  FileManagerConfirmingState as ConfirmingState,
  FileManagerRejectionErrorCodes,
} from "./fileManager.definition";

export function useFileManagerController(props: FileManagerControllerProps): FileManagerController {
  const { fileUrl: originalFileUrl, dropzoneRef, onChange } = props;

  const [currentBlob, setCurrentBlob] = useState<File>(null);
  const [currentFileUrl, setCurrentFileUrl] = useState<string>(null);
  const [currentFileType, setCurrentFileType] = useState<string>(null);
  const [currentFileTitle, setCurrentFileTitle] = useState<string>(null);

  const [saving, setSaving] = useState(false);
  const [confirmingState, setConfirmingState] = useState<ConfirmingState>(null);

  const notificationService = useRef(new NotificationService());

  const fileUrl = useMemo(
    (): string => (isNullOrWhiteSpace(currentFileUrl) ? originalFileUrl : currentFileUrl),
    [currentFileUrl, originalFileUrl]
  );
  const removing = useMemo((): boolean => confirmingState === ConfirmingState.Remove, [confirmingState]);

  // #region Handle Methods
  const handleFileChange = useCallback(
    (file: File, blobPath: string, fileTitle: string, fileType?: string): void => {
      setCurrentBlob(file);
      setCurrentFileType(fileType);
      setCurrentFileUrl(blobPath);
      setCurrentFileTitle(fileTitle);
      onChange(file);
    },
    [onChange]
  );

  const handleDialogOpen = useCallback((): void => {
    if (isEmpty(dropzoneRef.current)) return;
    dropzoneRef.current.open();
  }, [dropzoneRef]);

  const handleUpload = useCallback(
    <T extends File>(accepted: T[], rejected: FileRejection[]): void => {
      notificationService.current.dismiss();
      const rejectedFile = (rejected || [])[0]; // only take first file
      const file = (accepted || [])[0]; // only take first file

      if (rejectedFile?.errors[0]?.code === FileManagerRejectionErrorCodes.ExceedsMaxSize) {
        notificationService.current.error("Image exceeds maximum size.");
        return;
      }

      if (rejectedFile?.errors[0]?.code === FileManagerRejectionErrorCodes.InvalidType) {
        notificationService.current.error("Unsupported file format.");
        return;
      }

      if (isNil(file)) {
        notificationService.current.error("File failed to upload.");
        return;
      }

      setSaving(true);

      const { name, type } = file;
      handleFileChange(file, convertBlobToUrl(file), name, type);

      setSaving(false);
    },
    [handleFileChange]
  );

  function handleRemoveRequest(): void {
    setConfirmingState(ConfirmingState.Remove);
  }

  function handleConfirmationCancelRequest(): void {
    setConfirmingState(ConfirmingState.None);
  }

  const handleRemovePath = useCallback(
    (evt: MouseEvent): void => {
      notificationService.current.dismiss();
      setCurrentBlob(null);
      setCurrentFileType(null);
      setCurrentFileUrl(null);
      setCurrentFileTitle(null);
      setConfirmingState(ConfirmingState.None);
      onChange(null);
      (evt.target as HTMLButtonElement).focus();
    },
    [onChange]
  );
  // #endregion

  // #region LifeCycle Methods
  useEffect((): void => {
    if (isNullOrWhiteSpace(originalFileUrl)) return;

    setCurrentBlob(null);
    setSaving(true);

    convertFileToBlob(originalFileUrl)
      .catch((): Blob => null)
      .then((blob): void => {
        setSaving(false);
        if (isNil(blob)) return;

        setCurrentFileType(blob.type);
      });
  }, [originalFileUrl]);
  // #endregion

  return {
    fileUrl,
    currentBlob,
    currentFileType,
    currentFileTitle,
    removing,
    saving,
    handleDialogOpen,
    handleRemovePath,
    handleRemoveRequest,
    handleRemoveRequestCancel: handleConfirmationCancelRequest,
    handleUpload,
  };
}
