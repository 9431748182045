import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { CompanyRouteParams } from "../../../../../../../../services/company/company.model";
import type { Conference } from "../../../../../../../../services/conference/conference.model";
import { ImportResult } from "../../../../../../../../services/import/import.definition";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import { ExportAgendaModalIdModel } from "../exportAgendaModal/exportAgendaModal.definition";

export enum ExportAgendaClassName {
  Base = "export-agenda",
  Button = "export-agenda_button",
  Modal = "export-agenda_modal",
}

export enum ExportAgendaStatus {
  Exported = "Exported",
  Cancelled = "Export Cancelled",
  Failed = "Export Failed",
}

export interface ExportAgendaItem<T> extends ImportResult<T> {
  meetings: Meeting[];
  presentations: Presentation[];
}

export interface ExportAgendaProps extends BaseComponentProps {
  attendees: AttendeeViewModel[];
  conference: Conference;
  company: CompanyRouteParams;
  disabled: boolean;
  meetings: Meeting[];
  presentations: Presentation[];
}

export class ExportAgendaIdModel extends IdModelBase {
  button: ButtonIdModel;
  modal: ExportAgendaModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.button = new ButtonIdModel(`${this.id}AgendaButton`);
    this.modal = new ExportAgendaModalIdModel(`${this.id}AgendaModal`);
  }
}
