import { useEffect, useState } from "react";

export const usePreloadCanvasImg = (imgUrl: string): string => {
  const [currentImgUrl, setCurrentImgUrl] = useState(null);

  useEffect(() => {
    if (imgUrl && imgUrl !== currentImgUrl) {
      const timestamp = new Date().getTime();
      const newUrl = `${imgUrl}${imgUrl.includes("?") ? "&v=" : "?v="}${timestamp}`;
      const tempImg = new Image();
      tempImg.crossOrigin = "anonymous";
      tempImg.src = newUrl;

      setCurrentImgUrl(newUrl);

      return () => {
        tempImg.remove();
      };
    }
  }, [imgUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return currentImgUrl;
};
