import { ButtonListId, IdModelBase, isNullOrWhiteSpace, PopoverMenuIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { AttendeeViewModel } from "../../../../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../../../../services/conference/conference.model";

export interface NavigationProps extends BaseComponentProps {
  conference: Conference;
  onSignOut: () => void;
  profile?: AttendeeViewModel;
  isAttendeeProfile?: boolean;
}

export enum NavigationClassName {
  Base = "navigation",
  BaseWithPopoverMenuModifier = "navigation--with-popover-menu",
  List = "navigation_list",
  Item = "navigation_item",
  Anchor = "navigation_anchor",
  AnchorWithIconModifier = "navigation_anchor--icon",
  Toggle = "navigation_toggle",
  PopoverMenu = "navigation_popover-menu",
  AttendeeProfileMenu = "navigation_attendee-profile_popover-menu",
}

export enum NavigationIds {
  SpeakerAnchor = "SpeakersAnchor",
  AgendaAnchor = "AgendaAnchor",
  ChatAnchor = "ChatAnchor",
  SignOutAnchor = "SignOutAnchor",
  AttendeeProfile = "AttendeeProfile",
}

export class NavigationIdModel extends IdModelBase {
  speakers: string;
  agenda: string;
  chat: string;
  signOut: string;
  attendeeProfile: string;
  attendeeInformation: string;
  toggle: string;
  mobileSpeakers: string;
  mobileAgenda: string;
  mobileChat: string;
  mobileSignOut: string;
  mobileAttendeeProfile: string;
  popoverMenu: PopoverMenuIdModel;
  popoverMenuNavLink: ButtonListId;
  navigationLink: ButtonListId;
  attendeeProfileMenu: PopoverMenuIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.popoverMenu = new PopoverMenuIdModel(null);
      return;
    }

    this.speakers = `${this.id}${NavigationIds.SpeakerAnchor}`;
    this.agenda = `${this.id}${NavigationIds.AgendaAnchor}`;
    this.chat = `${this.id}${NavigationIds.ChatAnchor}`;
    this.signOut = `${this.id}${NavigationIds.SignOutAnchor}`;
    this.attendeeProfile = `${this.id}${NavigationIds.AttendeeProfile}`;
    this.attendeeProfileMenu = new PopoverMenuIdModel(`${this.id}AttendeeProfileMenu`);
    this.attendeeInformation = `${this.attendeeProfileMenu.id}${NavigationIds.AttendeeProfile}Option`;

    this.toggle = `${this.id}Toggle`;
    this.popoverMenu = new PopoverMenuIdModel(`${this.id}Menu`);
    this.popoverMenuNavLink = new ButtonListId(this.popoverMenu.id);
    this.navigationLink = new ButtonListId(this.id);
    this.mobileSpeakers = `${this.popoverMenu.id}${NavigationIds.SpeakerAnchor}Option`;
    this.mobileAgenda = `${this.popoverMenu.id}${NavigationIds.AgendaAnchor}Option`;
    this.mobileChat = `${this.popoverMenu.id}${NavigationIds.ChatAnchor}Option`;
    this.mobileSignOut = `${this.popoverMenu.id}${NavigationIds.SignOutAnchor}Option`;
    this.mobileAttendeeProfile = `${this.popoverMenu.id}${NavigationIds.AttendeeProfile}Option`;
  }
}
