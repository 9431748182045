import { IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import type { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { TracksHookModel } from "../../../../../../hooks/useTracks/useTracks.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";

export interface TrackProps extends Pick<BaseComponentProps, "id"> {
  conference: Conference;
  useTracks: TracksHookModel;
  user: AdminUserContextState["user"];
}

export enum TracksClassName {
  Base = "tracks",
}

export enum TrackEditClassName {
  Base = "track-edit",
}

export class TracksIdModel extends IdModelBase {
  name: TextboxIdModel;
  entityTable: EntityTableIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.name = new TextboxIdModel(`${this.id}NameTextbox`);
    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
  }
}
