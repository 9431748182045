import "./meetingIssuers.component.scss";
import {
  Button,
  ButtonTheme,
  Field,
  getClassName,
  Grid,
  GridColumn,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Pagination,
  Search,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import { usePaginatedData } from "../../../../../../../hooks/usePaginatedData/usePaginatedData.hook";
import { AttendeeViewModel } from "../../../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../../../services/corporateProfile/corporateProfile.model";
import { getBackgroundImageUrl, byCompanyName } from "../../../../../../../utils";
import {
  MeetingIssuerPaginationOptions,
  MeetingIssuersClassName,
  MeetingIssuersIdModel,
  MeetingIssuersProps,
} from "./meetingIssuers.definition";

const MeetingIssuers = (props: MeetingIssuersProps): JSX.Element => {
  const { id, corporateProfiles, meetingRequestsObject, onRequestSelect, onJumpToRequests } = props;

  const [searchFilter, setSearchFilter] = useState<string>();

  const idModel = useMemo(() => new MeetingIssuersIdModel(id), [id]);

  const filteredCorporateProfiles = useMemo(
    () =>
      searchFilter
        ? (corporateProfiles || [])
            .filter((profile) => profile.name.toLowerCase().includes(searchFilter.toLowerCase()))
            .sort(byCompanyName)
        : corporateProfiles?.sort(byCompanyName),
    [corporateProfiles, searchFilter]
  );
  const { pageData, paginationState, handlePageChange, handlePageSizeChange } = usePaginatedData<CorporateProfile>({
    data: filteredCorporateProfiles,
    initialPaginationState: { page: 0, pageSize: 25, pageCount: 1 },
  });

  const renderCorporateInfo = useCallback((profile: CorporateProfile): JSX.Element => {
    return (
      <GridColumn margin={false} width="4-of-12" smallWidth="4-of-12">
        <div className={MeetingIssuersClassName.CorporateInfo}>
          {!isNullOrWhiteSpace(profile.logo_image) && (
            <div
              className={MeetingIssuersClassName.CorporateLogo}
              style={{
                backgroundImage: isNullOrWhiteSpace(profile.logo_image) ? null : getBackgroundImageUrl(profile.logo_image),
              }}
            />
          )}
          <div>
            <h3 className={MeetingIssuersClassName.CorporateName}>{profile.name}</h3>
            {!isNullOrWhiteSpace(profile.ticker_symbol) && (
              <h4 className={MeetingIssuersClassName.CorporateTicker}>
                {profile.ticker_symbol} | {profile.exchange}
              </h4>
            )}
            {!isNullOrWhiteSpace(profile.industry) && (
              <h4 className={MeetingIssuersClassName.CorporateIndustry}>
                <strong>Industry:</strong> {profile.industry}
              </h4>
            )}
          </div>
        </div>
      </GridColumn>
    );
  }, []);

  const renderCorporateAttendees = useCallback((attendees: AttendeeViewModel[]): JSX.Element => {
    return (
      <GridColumn
        className={MeetingIssuersClassName.CorporateAttendees}
        margin={false}
        width={"6-of-12"}
        smallWidth={"6-of-12"}
      >
        {!isEmpty(attendees)
          ? attendees.map((x) => {
              const attendee = new AttendeeViewModel(x);
              return (
                <div key={attendee._id}>
                  <strong>{attendee.display_name}</strong>
                  {attendee.title && `, ${attendee.title}`}
                </div>
              );
            })
          : "TBD"}
      </GridColumn>
    );
  }, []);

  const renderMeetingRequestButton = useCallback(
    (profileId: CorporateProfile["_id"]): JSX.Element => {
      const className = getClassName(MeetingIssuersClassName.RequestButton, [
        {
          condition: isNil(meetingRequestsObject[profileId]),
          falseClassName: MeetingIssuersClassName.RequestButtonRequested,
        },
      ]);

      return (
        <GridColumn
          className={MeetingIssuersClassName.CorporateMeetingRequest}
          margin={false}
          width="2-of-12"
          smallWidth="2-of-12"
        >
          <Button
            theme={ButtonTheme.Citrus}
            className={className}
            label={isNil(meetingRequestsObject[profileId]) ? "REQUEST" : "REQUESTED"}
            disabled={!isNil(meetingRequestsObject[profileId])}
            onClick={() => onRequestSelect(profileId)}
          />
        </GridColumn>
      );
    },
    [meetingRequestsObject, onRequestSelect]
  );

  const renderCorporateRows = useCallback(
    (profiles: CorporateProfile[], searchText: string): JSX.Element => {
      const noIssuersMessage = isNullOrWhiteSpace(searchText) ? (
        "No corporates or issuers found."
      ) : (
        <>
          No corporates or issuers found matching &quot;<b>{searchText}</b>&quot;.
        </>
      );

      return (
        <div className={MeetingIssuersClassName.Corporate}>
          <Grid className={MeetingIssuersClassName.CorporateHeader}>
            <GridColumn width={"4-of-12"} smallWidth={"4-of-12"}>
              COMPANY
            </GridColumn>
            <GridColumn width={"6-of-12"} smallWidth={"6-of-12"}>
              ATTENDEES
            </GridColumn>
            <GridColumn width={"2-of-12"} smallWidth={"2-of-12"}>
              REQUEST MEETING
            </GridColumn>
          </Grid>
          {isEmpty(profiles) ? (
            <div className={MeetingIssuersClassName.NoCorporates}>{noIssuersMessage}</div>
          ) : (
            profiles.map((profile) =>
              isEmpty(profile) ? null : (
                <Grid key={profile._id} className={MeetingIssuersClassName.CorporateRow}>
                  {renderCorporateInfo(profile)}
                  {renderCorporateAttendees(profile.attendees)}
                  {renderMeetingRequestButton(profile._id)}
                </Grid>
              )
            )
          )}
        </div>
      );
    },
    [renderCorporateInfo, renderCorporateAttendees, renderMeetingRequestButton]
  );

  return (
    <div id={idModel.id} className={MeetingIssuersClassName.Base}>
      <Grid>
        <GridColumn width={"1-of-3"} smallWidth={"1-of-1"}>
          <Field label="Search">
            <Search value={searchFilter} onInputChange={setSearchFilter} />
          </Field>
        </GridColumn>
        <GridColumn width={"1-of-3"} smallWidth={"1-of-1"}></GridColumn>
        <GridColumn width={"1-of-3"} smallWidth={"1-of-1"} className={MeetingIssuersClassName.RequestedMeetingsContainer}>
          <Button
            id={idModel.requestedMeetings.id}
            theme={ButtonTheme.Citrus}
            className={MeetingIssuersClassName.RequestedMeetingsButton}
            disabled={Object.keys(meetingRequestsObject).length === 0}
            label={`REQUESTED MEETINGS (${Object.keys(meetingRequestsObject).length})`}
            onClick={onJumpToRequests}
          />
        </GridColumn>
      </Grid>
      {renderCorporateRows(pageData, searchFilter)}
      <Pagination
        id={idModel.pagination.id}
        className={MeetingIssuersClassName.Pagination}
        initialPage={paginationState.page}
        forcePage={paginationState.page}
        forcePageSize={paginationState.pageSize}
        pageCount={paginationState.pageCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        {...MeetingIssuerPaginationOptions}
      />
    </div>
  );
};

export default memo(MeetingIssuers);
