import { isEmpty, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { Entity } from "../../../definitions/entity.definition";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import type { Conference } from "../../../services/conference/conference.model";
import { CorporateProfile } from "../../../services/user/corporateProfile/corporateProfile.model";
import CorporateProfileService from "../../../services/user/corporateProfile/corporateProfile.service";
import { mapAvailabilityToTimeZone } from "../../../utils";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import { useService } from "../../useService/useService.hook";
import { handleSetEntity } from "../../useService/useService.utils";
import type { CorporateProfileHookModel, CorporateProfileHookProps } from "./useCorporateProfile.definition";

export const useCorporateProfile = (props: CorporateProfileHookProps): CorporateProfileHookModel => {
  const {
    attendees,
    autoFetchData,
    assignDefaultEntity: assignDefaultEntityProp,
    conferenceId,
    conferenceTimeZone,
    data,
    showNotifications,
    useOffline,
  } = props;

  const assignDefaultEntity = useMemo(() => assignDefaultEntityProp ?? true, [assignDefaultEntityProp]);

  const { current, items, loading, service, deleteById, post, putById, setCurrent, setItems, setLoading } = useService({
    autoFetchData: false,
    assignDefaultEntity,
    data,
    entityName: Entity.CorporateProfile,
    showNotifications,
    useOffline,
    entityModel: CorporateProfile,
    serviceModel: CorporateProfileService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<CorporateProfile[]> => {
      setLoading(true);

      return service.getByConferenceId(_id).then((response): CorporateProfile[] => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error("Failed to load corporate profiles.");
          return null;
        }

        const profiles = response?.data ?? [];
        setItems(profiles);
        setCurrent((current) => handleSetEntity(current, profiles, assignDefaultEntity));

        return profiles;
      });
    },
    [assignDefaultEntity, notificationService, service, showNotifications, setCurrent, setItems, setLoading]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetchBy: getByConferenceId,
    setEntities: setItems,
  });

  const mappedItems = useMemo(
    () =>
      items.map((x) => {
        return {
          ...x,
          attendees: (attendees || []).reduce((mapped: AttendeeViewModel[], attendee) => {
            if (isEmpty(attendee) || attendee._corporate_profile !== x._id) return mapped;

            mapped.push(
              new AttendeeViewModel({
                ...attendee,
                availability: mapAvailabilityToTimeZone(attendee.availability, conferenceTimeZone),
              })
            );
            return mapped;
          }, []),
        };
      }),
    [attendees, conferenceTimeZone, items]
  );

  return {
    current,
    loading,
    items: mappedItems,
    service,
    fetchByConferenceId: getByConferenceId,
    post,
    putById,
    deleteById,
    setCurrent,
    setItems,
    setLoading,
  };
};
