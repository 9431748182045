import { BaseComponentProps } from "@q4/nimbus-ui";
import { MeetingInterestLevel, MeetingRequest } from "../../../../../../../services/admin/registrant/registrant.model";
import { CorporateProfile } from "../../../../../../../services/corporateProfile/corporateProfile.model";
import { MeetingLabel } from "../../../../../../../services/meeting/meeting.model";
import { InvestorFormMeetingRequests } from "../../meetingRequestForm.definition";

export interface RequestMeetingsProps extends BaseComponentProps {
  corporateProfiles: CorporateProfile[];
  meetingRequests: MeetingRequest[];
  meetingRequestsObject: InvestorFormMeetingRequests;
  onSelect: (id: CorporateProfile["_id"], key: keyof MeetingRequest) => (selectedOption: SelectOption) => void;
  onDeleteRequest: (id: CorporateProfile["_id"]) => void;
  disabled?: boolean;
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}

export enum RequestMeetingsClassName {
  Base = "request-meetings",
  Corporate = "request-meetings_corporate-profile",
  CorporateHeader = "request-meetings_corporate-profile-header",
  CorporateRow = "request-meetings_corporate-profile-row",
  CorporateInfo = "request-meetings_corporate-profile-info",
  CorporateLogo = "request-meetings_corporate-profile-logo",
  CorporateName = "request-meetings_corporate-name",
  CorporateTicker = "request-meetings_corporate-ticker",
  CorporateIndustry = "request-meetings_corporate-industry",
  CorporateAttendees = "request-meetings_corporate-attendees",
  CorporateMeetingInterest = "request-meetings_meeting-interest",
  MeetingType = "request-meetings_meeting-type-select",
  InterestLevel = "request-meetings_interest-level-select",
  RequestsTitle = "request-meetings_requests-title",
  RequestsTitleMargin = "request-meetings_requests-title--margin",
  RequestDeleteButton = "request-meetings_request-delete-button",
}

export const meetingTypeOptions = [
  {
    label: MeetingLabel.OneOnOne,
    value: MeetingLabel.OneOnOne,
  },
  {
    label: MeetingLabel.Group,
    value: MeetingLabel.Group,
  },
];

export const meetingInterestOptions = [
  {
    label: MeetingInterestLevel.High,
    value: MeetingInterestLevel.High,
  },
  {
    label: MeetingInterestLevel.Medium,
    value: MeetingInterestLevel.Medium,
  },
  {
    label: MeetingInterestLevel.Low,
    value: MeetingInterestLevel.Low,
  },
];

export enum validMeetingTypeOptions {
  OneOnOne = "1:1",
  Group = "Group",
}

export interface SelectOption {
  label: string;
  value: string;
}
