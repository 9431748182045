import "./accordion.component.scss";
import { Collapsable, getClassName, isNullOrWhiteSpace, isEmpty } from "@q4/nimbus-ui";
import type { CollapsableGetState, CollapsableState } from "@q4/nimbus-ui";
import React, { useState, useRef, useEffect, memo, useMemo } from "react";
import { AccordionProps, AccordionIdModel, AccordionClassName } from "./accordion.definition";

const Accordion = (props: AccordionProps): JSX.Element => {
  const propsWithDefault = new AccordionProps(props);
  const { id, badges, children, className, collapsed, disabled, header, onChange, theme, types, toggleIcon, mediumHeader } =
    propsWithDefault;

  const idModel = useMemo(() => new AccordionIdModel(id), [id]);
  const { Base, Disabled, Collapsed, Header, ContentWithModifier, Row, Content, Badges, Toggle, Action } =
    AccordionClassName;

  const Rotate = 180;
  const [toggleRotate, setToggleRotate] = useState(props.collapsed ? Rotate : 0);
  const collapsableState = useRef<CollapsableState>();
  const styles = useMemo(getStyles, [toggleRotate]);

  useEffect(handleCollapsed, [collapsed]);

  function handleCollapsed(): void {
    setToggleRotate((toggleRotate) => toggleRotate + Rotate);
  }

  function handleCollapsedToggle(): void {
    if (collapsableState.current?.collapsing) return;
    onChange(!collapsed);
  }

  function setCollapsedState(getState: CollapsableGetState): void {
    collapsableState.current = getState();
  }

  function getStyles(): Record<string, Record<string, string>> {
    return {
      toggle: {
        transform: `rotate(${toggleRotate}deg)`,
      },
    };
  }

  return (
    <div
      id={idModel.id}
      className={getClassName(Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
        {
          condition: Array.isArray(types),
          trueClassName: types.map((type): string => `${Base}--${type}`).join(" "),
        },
        { condition: isEmpty(theme), falseClassName: `${Base}--${theme}` },
        { condition: disabled, trueClassName: Disabled },
        { condition: collapsed, trueClassName: Collapsed },
      ])}
    >
      <div
        className={getClassName(Header, [{ condition: mediumHeader, trueClassName: `${Header} ${ContentWithModifier}` }])}
      >
        <div className={Row} onClick={handleCollapsedToggle}>
          <div className={Content}>{header}</div>
          <div className={Badges}>
            {(badges || []).map((x: JSX.Element, i: number): JSX.Element => {
              return x && { ...x, key: i };
            })}
          </div>
          <div className={Action}>
            <span id={idModel.toggle} aria-expanded={!collapsed} className={Toggle} style={styles.toggle}>
              <span className={toggleIcon} />
            </span>
          </div>
        </div>
      </div>
      <Collapsable id={idModel.content} collapsed={collapsed} registerGetState={setCollapsedState}>
        {children}
      </Collapsable>
    </div>
  );
};

export default memo(Accordion);
