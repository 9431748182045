import { ErrorModel, TableProps, ToolbarTheme } from "@q4/nimbus-ui";
import type { ValueFormatterParams, GetQuickFilterTextParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useMemo, useState } from "react";
import CorporateProfileForm from "../../../../../../components/corporateProfileForm/corporateProfileForm.component";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { useAnswers } from "../../../../../../hooks/useAnswers/useAnswers.hook";
import { TableSelectFilter } from "../../../../../../hooks/useTableFilter/useTableFilter.definition";
import { AttendeeType, AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import { getCorporateProfileTypeRequiredKeys, getDisplayNames, getOptions, modifyAnswerData } from "../../../../../../utils";
import {
  CorporateProfilesClassName,
  CorporateProfileEditClassName,
  CorporateProfilesIdModel,
  CorporateProfilesTableHeader,
} from "./corporateProfiles.definition";
import type { CorporateProfilesProps } from "./corporateProfiles.definition";

const CorporateProfiles = (props: CorporateProfilesProps): JSX.Element => {
  const { id, conference, useCorporateProfiles, user, useAttendees, useQuestionGroups } = props;

  const idModel = useMemo(() => new CorporateProfilesIdModel(id), [id]);
  const [formErrors, setFormErrors] = useState<Record<string, ErrorModel>>({});

  const {
    current: currentCorporateProfile,
    setCurrent,
    items: corporateProfiles,
    loading: corporateProfilesLoading,
  } = useCorporateProfiles;
  const { items: questionGroups, loading: questionsLoading } = useQuestionGroups;

  const requiredEntityKeys = useMemo(
    () => getCorporateProfileTypeRequiredKeys(currentCorporateProfile?.type),
    [currentCorporateProfile?.type]
  );

  const questionGroup = useMemo(
    () => questionGroups.find((group) => group.attendee_types.includes(AttendeeType.Corporate)),
    [questionGroups]
  );

  const { items: corporateAnswers, loading: answersLoading } = useAnswers({
    entity: Entity.CorporateProfile,
    id: currentCorporateProfile?._id,
    showNotifications: false,
  });

  const modifyAnswerPayload = (corporateProfileData: CorporateProfile) => {
    const updatedAnswerObject = modifyAnswerData(corporateProfileData.custom_question_answers, questionGroup?._questions);
    return new CorporateProfile({ ...corporateProfileData, custom_question_answers: updatedAnswerObject });
  };

  const loading = useMemo(
    () => corporateProfilesLoading || questionsLoading || answersLoading,
    [answersLoading, corporateProfilesLoading, questionsLoading]
  );

  const types = useMemo<string[]>(() => getOptions("type", corporateProfiles), [corporateProfiles]);
  const hostMeetings = useMemo<string[]>(() => {
    return getOptions("host_small_meetings", corporateProfiles).map((option) => {
      return !!option ? "Yes" : "No";
    });
  }, [corporateProfiles]);
  const selectFilters: TableSelectFilter[] = [
    {
      id: idModel.type.id,
      key: "type",
      placeholder: "Filter by Type",
      options: types,
    },
    {
      id: idModel.hostMeetings.id,
      key: "host_small_meetings",
      placeholder: "Filter by Meetings",
      options: hostMeetings,
    },
  ];

  const tableProps: Partial<TableProps> = {
    columnDefs: [
      {
        field: "name",
        headerName: CorporateProfilesTableHeader.Name,
        minWidth: 280,
        flex: 1,
      },
      {
        field: "type",
        headerName: CorporateProfilesTableHeader.Type,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "host_small_meetings",
        headerName: CorporateProfilesTableHeader.HostMeetings,
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: "hostMeetingsCellRenderer",
        getQuickFilterText: renderHostMeetings,
      },
      {
        field: "attendees",
        headerName: CorporateProfilesTableHeader.Attendees,
        minWidth: 400,
        valueFormatter: getFormattedAttendees,
        getQuickFilterText: getFormattedAttendees,
      },
    ],
    frameworkComponents: {
      hostMeetingsCellRenderer: renderHostMeetings,
    },
  };

  function handleCorporateProfileUpdate(data: Partial<CorporateProfile>) {
    setCurrent(new CorporateProfile({ ...currentCorporateProfile, ...data }));
  }

  const editForm = (
    <div className={CorporateProfileEditClassName.Base}>
      <CorporateProfileForm
        id={idModel.form?.id}
        conference={conference}
        corporateProfile={currentCorporateProfile}
        questionGroup={questionGroup}
        loading={loading}
        answers={corporateAnswers}
        setFormErrors={setFormErrors}
        onCorporateProfileUpdate={handleCorporateProfileUpdate}
        useAttendees={useAttendees}
      />
    </div>
  );

  function getFormattedAttendees(params: ValueFormatterParams | GetQuickFilterTextParams): string {
    const attendees: AttendeeViewModel[] = params?.value;
    return getDisplayNames(attendees);
  }

  function renderHostMeetings(params: GetQuickFilterTextParams): string {
    const corporateProfile: CorporateProfile = params.data as CorporateProfile;
    return corporateProfile?.host_small_meetings ? "Yes" : "No";
  }

  return (
    <div id={idModel.id} className={CorporateProfilesClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.CorporateProfile}
        selectFilters={selectFilters}
        useService={useCorporateProfiles}
        tableProps={tableProps}
        editForm={editForm}
        loading={loading}
        modalProps={{ fullscreen: true, scrollable: true, focusOnProps: { autoFocus: false } }}
        entityModel={CorporateProfile}
        icon="q4i-contact-2pt"
        saveRequestParams={{ _conference: conference._id }}
        requiredEntityKeys={requiredEntityKeys}
        toolbarProps={{ theme: ToolbarTheme.Q4Blue }}
        user={user}
        newDefaultEntity={new CorporateProfile({ host_small_meetings: true })}
        requiredFormEntityKeys={[]}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        modifyData={modifyAnswerPayload}
        conference={conference}
      />
    </div>
  );
};

export default memo(CorporateProfiles);
