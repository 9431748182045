import "./timeSlotEdit.component.scss";
import {
  Button,
  ButtonTheme,
  DatePickerTheme,
  ErrorModel,
  Form,
  getClassName,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  SelectPreset,
  TimePicker,
  TimePickerProps,
} from "@q4/nimbus-ui";
import moment from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { TimeFormat } from "../../../../../../../../definitions/date.definition";
import { convertMinutesToHourWithDecimals } from "../../../../../../../../utils";
import { TimeSlotEditClassName, TimeSlotEditIdModel, TimeSlotEditProps } from "./timeSlotEdit.definition";

const TimeSlotEdit = (props: TimeSlotEditProps): JSX.Element => {
  const { id, className, isSaving, startTime: startTimeProp, endTime: endTimeProp, onSubmit, onCancel } = props;

  const [startTime, setStartTime] = useState(startTimeProp);
  const [endTime, setEndTime] = useState(endTimeProp);
  const [showError, setShowError] = useState(false);

  const idModel = useMemo(() => new TimeSlotEditIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(TimeSlotEditClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const endTimeError = useMemo(
    () => new ErrorModel("End time is required", showError && isNil(endTime)),
    [endTime, showError]
  );
  const endTimeStartHour = useMemo(() => {
    return !isEmpty(startTime) ? startTime?.hours() + convertMinutesToHourWithDecimals(startTime?.minutes()) + 0.09 : 0.09;
  }, [startTime]);

  const handleTimeChange = useCallback(
    (value: string, isStartTime: boolean) => {
      setShowError(false);
      const selectedTime = moment(value, TimeFormat.Picker);
      const originTime = isStartTime ? startTimeProp : endTimeProp;

      const newTime = originTime?.clone()?.set({ hours: selectedTime.hour(), minutes: selectedTime.minutes() });
      const setState = isStartTime ? setStartTime : setEndTime;

      setState(newTime);

      if (isStartTime && newTime.isSameOrAfter(endTime)) {
        setEndTime(null);
      }
    },
    [endTime, endTimeProp, startTimeProp]
  );

  const formFields = useMemo(() => {
    const pickerDefaultOptions: Partial<TimePickerProps> = {
      theme: DatePickerTheme.LightGrey,
      minuteSkip: 5,
      noOptionsMessageText: "No Options",
      preset: SelectPreset.Autocomplete,
      placeholder: "Select",
      disabled: isSaving,
    };

    return [
      {
        key: "Start Time",
        width: "1-of-2",
        smallWidth: "1-of-2",
        label: "Start Time",
        children: (
          <TimePicker
            id={idModel.start?.id}
            value={startTime?.format(TimeFormat.Picker)}
            startHour={0}
            endHour={23.9}
            onChange={(value: string) => handleTimeChange(value, true)}
            {...pickerDefaultOptions}
          />
        ),
      },
      {
        key: "End Time",
        width: "1-of-2",
        smallWidth: "1-of-2",
        label: "End Time",
        error: endTimeError,
        children: (
          <TimePicker
            id={idModel.end?.id}
            value={endTime?.format(TimeFormat.Picker)}
            startHour={endTimeStartHour}
            onChange={(value: string) => handleTimeChange(value, false)}
            {...pickerDefaultOptions}
          />
        ),
      },
    ];
  }, [isSaving, idModel.start?.id, idModel.end?.id, startTime, endTimeError, endTime, endTimeStartHour, handleTimeChange]);

  const handleDoneClick = useCallback(() => {
    if (isNil(endTime)) {
      setShowError(true);
      return;
    }
    onSubmit(startTime, endTime);
  }, [endTime, onSubmit, startTime]);

  const handleCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <div id={idModel.id} className={baseClassName}>
      <Form id={idModel.form?.id} fields={formFields} />
      <div className={TimeSlotEditClassName.Buttons}>
        <Button
          id={idModel.cancel?.id}
          theme={ButtonTheme.LightGrey}
          label="CANCEL"
          disabled={isSaving}
          onClick={handleCancelClick}
        />
        <Button id={idModel.done?.id} theme={ButtonTheme.Rain} label="DONE" loading={isSaving} onClick={handleDoneClick} />
      </div>
    </div>
  );
};

export default memo(TimeSlotEdit);
