import "./companyBranding.component.scss";
import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import Halo from "../halo/halo.component";
import type { BrandingProps } from "./companyBranding.definition";
import { BrandingVariables } from "./companyBranding.definition";

const CompanyBranding = (props: BrandingProps): JSX.Element => {
  const { branding, haloProps } = props;

  const customFont = useMemo(() => {
    const fonts: Pick<HTMLLinkElement, "href" | "rel">[] = [];
    !isNullOrWhiteSpace(branding?.font_body?.path) && fonts.push({ href: branding?.font_body?.path, rel: "stylesheet" });
    !isNullOrWhiteSpace(branding?.font_header?.path) && fonts.push({ href: branding?.font_header?.path, rel: "stylesheet" });

    return fonts;
  }, [branding?.font_body?.path, branding?.font_header?.path]);

  const cssVariables = useMemo(() => new BrandingVariables(branding), [branding]);

  return <Halo cssVariables={cssVariables} {...haloProps} link={customFont} />;
};

export default memo(CompanyBranding);
