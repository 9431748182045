import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AuthType } from "../api/api.definition";
import { Auth0Base, Auth0ServiceBase, AuthSignInOptions } from "../auth0/auth0.model";
import PasswordlessAuth0 from "../auth0/passwordlessAuth0/passwordlessAuth0.model";
import PasswordlessAuth0Service from "../auth0/passwordlessAuth0/passwordlessAuth0.service";
import { SessionStorageKey, TokenStorageKey } from "../token/token.definition";

export type MultiConferenceToken = { [conferenceId: string]: Auth0Base | string };

export default class PasswordlessTokenService {
  private readonly _sessionStorageKey: string;
  private readonly _tokenCollectionKey: string;
  private readonly _authType: AuthType;
  private readonly _authService: Auth0ServiceBase;

  constructor() {
    this._authType = AuthType.Passwordless;
    this._tokenCollectionKey = TokenStorageKey.Passwordless;
    this._sessionStorageKey = SessionStorageKey.Passwordless;
    this._authService = new PasswordlessAuth0Service();
  }

  setToken(token: PasswordlessAuth0): void {
    this._addStorage(this._tokenCollectionKey, token);
  }

  setSessionId(token: PasswordlessAuth0): void {
    if (isEmpty(token)) {
      sessionStorage.removeItem(this._sessionStorageKey);
      return;
    }
    sessionStorage.setItem(this._sessionStorageKey, token._conferenceId);
  }

  getTokenCollection(): PasswordlessAuth0[] {
    const parsedStorage: PasswordlessAuth0[] = JSON.parse(localStorage.getItem(this._tokenCollectionKey));

    if (!Array.isArray(parsedStorage)) return [];

    return parsedStorage.map((x) => new PasswordlessAuth0(x));
  }

  getSanitizedTokenCollection(): PasswordlessAuth0[] {
    const allTokens = this.getTokenCollection();

    return allTokens.reduce((acc, conferenceAuth) => {
      if (isEmpty(conferenceAuth)) return acc;
      if (this.isTokenExpired(conferenceAuth)) {
        this.removeTokenByConferenceId(conferenceAuth._conferenceId);
        return acc;
      }
      acc.push(conferenceAuth);

      return acc;
    }, [] as PasswordlessAuth0[]);
  }

  getTokenFromSession(): PasswordlessAuth0 {
    const storageValue = sessionStorage.getItem(this._sessionStorageKey);
    return new PasswordlessAuth0(this.getTokenByConferenceId(storageValue));
  }

  getTokenByConferenceId(conferenceId: string): PasswordlessAuth0 {
    return new PasswordlessAuth0(this.getTokenCollection().find((x) => x._conferenceId === conferenceId));
  }

  removeSessionId(): void {
    sessionStorage.removeItem(this._sessionStorageKey);
  }

  removeTokenByConferenceId(conferenceId: string): PasswordlessAuth0 {
    const allToken = this.getTokenCollection();

    const filteredToken = (allToken || []).filter((el) => el.Profile.conferenceId !== conferenceId);

    let storageValue = "";
    if (typeof allToken === "object") {
      try {
        storageValue = JSON.stringify(filteredToken);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.error("Error removing token by session id:", (e as any).message);
        return;
      }
    }
    localStorage.setItem(this._tokenCollectionKey, storageValue);
  }

  removeTokenBySessionId(): void {
    const sessionId = sessionStorage.getItem(this._sessionStorageKey);

    if (isNullOrWhiteSpace(sessionId)) return;

    const allToken = this.getTokenCollection();

    const filteredToken = (allToken || []).filter((el) => el.Profile.conferenceId !== sessionId);

    let storageValue = "";
    if (typeof allToken === "object") {
      try {
        storageValue = JSON.stringify(filteredToken);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.error("Error removing token by session id:", (e as any).message);
        return;
      }
    }
    localStorage.setItem(this._tokenCollectionKey, storageValue);
  }

  isTokenExpired = (token: PasswordlessAuth0): boolean => {
    if (isEmpty(token)) return true;
    return new Date().getTime() > token?.ExpiresAt;
  };

  isAuthenticated = (conferenceId: string): boolean => {
    const token = this.getTokenByConferenceId(conferenceId);
    return !this.isTokenExpired(token);
  };

  isAuthenticatedBySessionId = (): boolean => {
    const token = this.getTokenFromSession();
    return !this.isTokenExpired(token);
  };

  getTokenFromSessionId(): string {
    const token = this.getTokenFromSession() as Auth0Base;
    if (isEmpty(token)) return "";
    return token.IdToken;
  }

  signOut = (options?: AuthSignInOptions): void => {
    options = isEmpty(options) ? new AuthSignInOptions() : options;
    this.removeTokenBySessionId();
    this.removeSessionId();
    this._signIn(options);
  };

  private _signIn = (options?: AuthSignInOptions): void => {
    this._authService.signIn(options);
  };

  private _addStorage(key: string, value: PasswordlessAuth0): void {
    if (isEmpty(value) || (typeof value === "string" && isNullOrWhiteSpace(value))) return;

    const allToken = this.getTokenCollection();
    const index = allToken.findIndex((x) => x._conferenceId === value._conferenceId);

    const passwordlessAuth0 = new PasswordlessAuth0(value);

    if (~index) {
      allToken[index] = passwordlessAuth0;
    } else {
      allToken.push(passwordlessAuth0);
    }

    let storageValue = "";
    if (typeof allToken === "object") {
      try {
        storageValue = JSON.stringify(allToken);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.error("Error storing token:", (e as any).message);
        return;
      }
    }

    if (typeof storageValue !== "string") return;
    localStorage.setItem(key, storageValue);
    sessionStorage.setItem(this._sessionStorageKey, value._conferenceId);
  }
}
