import { isNil } from "@q4/nimbus-ui";
import type { Moment } from "moment-timezone";
import { DateTokenFormat, TimeFormat } from "../../../../../../definitions/date.definition";

export function getPresentationTheaterDate(start: Moment, end: Moment): string {
  if (isNil(start) || isNil(end)) return "";
  const startTimeZone = start.tz();
  const endTimeZone = end.tz();
  const endConverted = end.clone();

  if (endTimeZone !== startTimeZone) {
    endConverted.utc().tz(startTimeZone);
  }
  const startDate = start.format(
    `${DateTokenFormat.FullWeekDay}, ${DateTokenFormat.FullMonth} ${DateTokenFormat.DayWithSuffix} - ${TimeFormat.Picker}`
  );
  const endDate = endConverted.format(`${TimeFormat.Picker} ${DateTokenFormat.TimeZone}`);

  return `${startDate} - ${endDate}`.trim();
}
