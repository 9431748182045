import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";
import type { Conference } from "../conference/conference.model";
import { Track, TrackPayload } from "./track.model";
import type { TrackPostRequest } from "./track.model";

export default class TrackService {
  private readonly TRACKS_API_PATH = `${AdminRouteBase}/tracks/v1`;
  private apiService = new ApiService();

  public async getTracks(): Promise<ApiResponse<Track[]>> {
    const response = await this.apiService.get<Track[]>(`${this.TRACKS_API_PATH}`);
    return this.mapTracksResponse(response);
  }

  public async getTracksByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Track[]>> {
    const response = await this.apiService.get<Track[]>(`${this.TRACKS_API_PATH}/byConference/${_id}`);
    return this.mapTracksResponse(response);
  }

  public async getTrackById(_id: Track["_id"]): Promise<ApiResponse<Track>> {
    const response = await this.apiService.get<Track>(`${this.TRACKS_API_PATH}/${_id}`);
    return this.mapTrackByIdResponse(response);
  }

  public postTrack = async (data: Track): Promise<ApiResponse<Track>> => {
    const conferenceId = data?._conference?._id;
    if (isEmpty(data) || isNullOrWhiteSpace(conferenceId)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Failed to add new track." }));
    }

    const payload = {
      ...data,
      _conference: conferenceId,
    };
    const response = await this.apiService.post<TrackPostRequest, Track>(`${this.TRACKS_API_PATH}`, payload);
    return this.mapTrackByIdResponse(response);
  };

  public async putTrackById(_id: Track["_id"], data: Track): Promise<ApiResponse<Track>> {
    const response = await this.apiService.put<TrackPayload, Track>(
      `${this.TRACKS_API_PATH}/${_id}`,
      new TrackPayload(data)
    );
    return this.mapTrackByIdResponse(response);
  }

  public async deleteTrackById(_id: string): Promise<ApiResponse<Track>> {
    const response = await this.apiService.delete<Track>(`${this.TRACKS_API_PATH}/${_id}`);
    return this.mapTrackByIdResponse(response);
  }

  private mapTrackByIdResponse(response: ApiResponse<Track>): ApiResponse<Track> {
    return new ApiResponse({
      ...response,
      data: new Track(response?.data),
    });
  }

  private mapTracksResponse(response: ApiResponse<Track[]>): ApiResponse<Track[]> {
    return new ApiResponse({
      ...response,
      data: (response?.data ?? []).map((track) => new Track(track)),
    });
  }
}
