import "./itinerary.view.scss";
import { isEmpty, isNil, isNullOrWhiteSpace, LoadingScreen, usePolling } from "@q4/nimbus-ui";
import { uniqBy } from "lodash";
import moment from "moment-timezone";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AttendeeProfileRoute } from "../../../configurations/navigation.configuration";
import contextSubscribe from "../../../contexts/context.subscribe";
import { LobbyContext } from "../../../contexts/lobby/lobby.context";
import { UserContext } from "../../../contexts/user/user.context";
import { useConferences } from "../../../hooks";
import { useCalendarExport } from "../../../hooks/useCalendarExport/useCalendarExport.hook";
import { useExport } from "../../../hooks/useExport/useExport.hook";
import { useCorporateProfile } from "../../../hooks/user/useCorporateProfile/useCorporateProfile.hook";
import { AttendeeType, AttendeeViewModel } from "../../../services/attendee/attendee.model";
import { CalendarExportType } from "../../../services/calendarExport/calendarExport.model";
import { LobbyDefaults } from "../../../services/conference/conference.model";
import { Presentation } from "../../../services/presentation/presentation.model";
import { getAgenda, getLobbyUrl, getOpenAndClosedPresentations, isAttendeeSpeaker, mapEntities } from "../../../utils";
import { getLobbyOpen } from "../../company/lobby/companyLobby.utils";
import CompanyLobbyLayout from "../../company/lobby/components/layout/companyLobby.layout";
import Section from "../../company/lobby/components/section/section.component";
import { SectionKeyLine, SectionTheme } from "../../company/lobby/components/section/section.definition";
import Speakers from "../../company/lobby/components/speakers/speakers.component";
import { useLobby } from "../../company/lobby/hooks/useLobby/useLobby.hook";
import DialInInstructions from "../itinerary/components/dialInInstructions/dialInInstructions.component";
import DialInformation from "../itinerary/components/dialInformation/dialInformation.component";
import Agenda from "./components/agenda/agenda.component";
import Details from "./components/details/details.component";
import LivePresentation from "./components/livePresentation/livePresentation.component";
import Tracks from "./components/tracks/tracks.component";
import {
  ItineraryClassName,
  ItineraryProps,
  ItineraryIdModel as idModel,
  ItineraryPollingInterval,
} from "./itinerary.definition";

const Itinerary = (props: ItineraryProps): JSX.Element => {
  const lobbyContext = useContext(LobbyContext);

  const { userContext } = props;
  const history = useHistory();

  const [fetchCount, setFetchCount] = useState(0);
  const [now, setNow] = useState(moment());

  const { conferenceCompanies: companies, fetchConferenceCompaniesById } = useConferences({
    autoFetchData: false,
  });

  // #region useLobby
  const {
    params,
    profile: profileProp,
    speaker,
    useAgenda,
    usePresentationTheater,
    useUserConferences,
    setUserTimeZone,
    handleAgendaAdd,
    handleAgendaRemove,
    handleSignOut,
    handleSpeakerChange,
  } = useLobby({
    useOffline: true,
    userContext,
  });

  const conferenceRouteParams = useMemo(
    () => ({
      url_suffix: params.company,
      custom_path: params.custom,
      conferencePath: params.conferencePath,
    }),
    [params]
  );
  const { current: conference, items: conferences, fetchById: fetchConferenceById } = useUserConferences;

  const conferenceId = useMemo(() => conference?._id, [conference]);

  const { agenda, loading: agendaLoading } = useAgenda;
  // #endregion
  const { items: corporateProfiles } = useCorporateProfile({
    attendees: agenda?._attendees,
    conferenceId,
    conferenceTimeZone: conference?.time_zone,
  });

  const profile = useMemo(() => {
    const _corporate_profile =
      profileProp?.type === AttendeeType.Corporate
        ? (corporateProfiles || []).find((x) => x._id === profileProp?._corporate_profile) ?? profileProp?._corporate_profile
        : null;
    return new AttendeeViewModel({
      ...profileProp,
      _corporate_profile,
    });
  }, [corporateProfiles, profileProp]);

  const userPresentations = useMemo(() => {
    const speakerPresentations = conference?._presentation.reduce((speakerPresentations, presentation) => {
      if (isEmpty(presentation)) return speakerPresentations;
      if (isAttendeeSpeaker(profile, presentation._speaker)) speakerPresentations.push(presentation);
      return speakerPresentations;
    }, [] as Presentation[]);

    return uniqBy([...(agenda?._presentation || []), ...(speakerPresentations || [])], "_id");
  }, [conference?._presentation, agenda?._presentation, profile]);

  const userMeetings = useMemo(
    () =>
      agenda?._meeting.map((x) => {
        x.linkId = conference?._meeting.find((y) => x._id === y._id)?.linkId;
        return x;
      }),
    [agenda?._meeting, conference?._meeting]
  );

  const openAndClosedPresentations = useMemo(
    () => getOpenAndClosedPresentations(conference?._presentation),
    [conference?._presentation]
  );

  // #region useExport
  const { getCalendar, loading: exportingCalendar } = useCalendarExport({
    showNotifications: true,
  });

  const { generatePdf, loading: exportingPDF } = useExport({
    company: conferenceRouteParams,
    showNotifications: true,
  });

  const exporting = useMemo(() => exportingPDF || exportingCalendar, [exportingCalendar, exportingPDF]);
  // #endregion

  const tracksTitle = useMemo(
    () =>
      !isNullOrWhiteSpace(conference?.lobby?.tracks?.title) ? conference.lobby.tracks.title : LobbyDefaults.tracks.title,
    [conference?.lobby.tracks.title]
  );

  const onDemandTitle = useMemo(
    () =>
      !isNullOrWhiteSpace(conference?.lobby?.ondemand_presentations?.title)
        ? conference.lobby.ondemand_presentations.title
        : LobbyDefaults.ondemand_presentations.title,
    [conference?.lobby.ondemand_presentations.title]
  );

  const onDemandHoverText = useMemo(
    () =>
      !isNullOrWhiteSpace(conference?.lobby?.ondemand_presentations?.label)
        ? conference.lobby.ondemand_presentations.label
        : LobbyDefaults.ondemand_presentations.label,
    [conference?.lobby.ondemand_presentations.label]
  );

  const { lobbyOpen, lobbyOpenDate } = useMemo(
    () => getLobbyOpen(conference, profile || ({} as AttendeeViewModel)),
    [conference, profile]
  );

  const goToAttendeeProfile = useCallback(() => {
    const lobbyRoute = getLobbyUrl(
      conferenceRouteParams.url_suffix,
      conferenceRouteParams.custom_path,
      conferenceRouteParams.conferencePath
    );
    return history.push(`${lobbyRoute}${AttendeeProfileRoute}`);
  }, [conferenceRouteParams.conferencePath, conferenceRouteParams.custom_path, conferenceRouteParams.url_suffix, history]);

  const showAgenda = useMemo(() => !conference?.lobby?.agenda?.disabled, [conference?.lobby?.agenda?.disabled]);
  const showSpeakers = useMemo(() => !conference?.lobby?.speakers?.disabled, [conference?.lobby?.speakers?.disabled]);
  const showOnDemand = useMemo(
    () => !conference?.lobby?.ondemand_presentations?.disabled && !isEmpty(conference?._ondemand_presentation),
    [conference?.lobby?.ondemand_presentations?.disabled, conference?._ondemand_presentation]
  );
  const showTracks = useMemo(
    () => !conference?.lobby?.tracks?.disabled && !isEmpty(conference?._presentation),
    [conference?.lobby?.tracks?.disabled, conference?._presentation]
  );
  const showLivePresentation = useMemo(
    () => !conference?.lobby?.livePresentation?.disabled,
    [conference?.lobby?.livePresentation?.disabled]
  );

  const itineraryAttendees = useMemo(() => {
    return (agenda?._attendees || []).map((attendee) => {
      return new AttendeeViewModel({
        ...attendee,
        _corporate_profile: (corporateProfiles || []).find((x) => x._id === attendee?._corporate_profile),
      });
    });
  }, [agenda?._attendees, corporateProfiles]);

  const hasConference = useMemo(() => !isEmpty(conference), [conference]);
  const fetched = useMemo(() => hasConference || fetchCount > 0, [fetchCount, hasConference]);

  useEffect(() => {
    if (!hasConference || !isNil(companies)) return;

    fetchConferenceCompaniesById(conferenceId, true);
  }, [companies, conferenceId, fetchConferenceCompaniesById, hasConference]);

  const getNow = useCallback(() => {
    setNow(moment());
  }, []);

  usePolling({
    allowPolling: true,
    onPollingComplete: getNow,
    interval: ItineraryPollingInterval.Ui,
  });

  const getConferences = useCallback(() => {
    fetchConferenceById(conference?.Path ?? params.conferencePath).then(() => {
      setFetchCount((count) => count + 1);
      getNow();
    });
  }, [conference?.Path, fetchConferenceById, getNow, params.conferencePath]);

  usePolling({
    allowPolling: true,
    onPollingComplete: getConferences,
    interval: ItineraryPollingInterval.Api,
  });

  useEffect(() => {
    lobbyContext.setConference(conference);
    lobbyContext.setConferences(conferences);
    lobbyContext.setProfile(profile);
    lobbyContext.setAgenda(agenda);
  }, [lobbyContext, agenda, conference, conferences, profile]);

  useEffect(() => {
    if (conference?.lobby && !isNullOrWhiteSpace(profile.display_name) && !lobbyOpen) {
      goToAttendeeProfile();
    }
  }, [conference, conference?.lobby, goToAttendeeProfile, lobbyOpen, profile]);

  function handleExport(type: CalendarExportType): void {
    if (isNullOrWhiteSpace(type)) return;

    switch (type) {
      case CalendarExportType.Pdf:
        generatePdf(profile, itineraryAttendees, conference, userMeetings, userPresentations);
        break;
      case CalendarExportType.Ics:
        const icsAgenda = getAgenda(
          userMeetings,
          mapEntities(userPresentations, conference._presentation),
          itineraryAttendees,
          profile,
          conference,
          { url_suffix: params?.company, custom_path: params?.custom }
        );
        getCalendar(CalendarExportType.Ics, conference, icsAgenda, profile);
        break;
      default:
        break;
    }
  }

  function handleTimeZoneChange(value: string): void {
    setUserTimeZone(conferenceId, value);
  }

  function renderCurrentConference(): JSX.Element {
    if (!fetched || !profileProp) return <LoadingScreen />;
    if (fetched && !hasConference) return null;

    const tracksVisible =
      (showTracks && !isEmpty(conference?._presentation)) || (showOnDemand && !isEmpty(conference?._ondemand_presentation));

    return (
      <div id={idModel.conference} className={ItineraryClassName.Conference}>
        <Section className={ItineraryClassName.Hero} theme={SectionTheme.Transparent}>
          <div className={ItineraryClassName.Details}>
            <Details id={idModel.details?.id} conference={conference} lobbyOpen={lobbyOpen} lobbyOpenDate={lobbyOpenDate} />
          </div>
          {lobbyOpen && (
            <LivePresentation
              className={ItineraryClassName.LiveContainer}
              id={idModel.livePresentation?.id}
              conference={conference}
              now={now}
              profile={profile}
              visible={showLivePresentation}
            />
          )}
        </Section>
        {lobbyOpen && (
          <>
            <Tracks
              id={idModel.ondemandTracks?.id}
              title={onDemandTitle}
              hoverText={onDemandHoverText}
              conference={conference}
              agendaLoading={agendaLoading}
              now={now}
              profile={profile}
              visible={showOnDemand}
              presentations={conference?._ondemand_presentation}
              branding={conference?.branding}
            />
            <Tracks
              id={idModel.tracks?.id}
              title={tracksTitle}
              conference={conference}
              agendaLoading={agendaLoading}
              now={now}
              profile={profile}
              visible={showTracks}
              presentations={openAndClosedPresentations}
              agendaPresentations={userPresentations}
              branding={conference?.branding}
              usePresentationTheater={usePresentationTheater}
              onPresentationAdd={handleAgendaAdd}
              onPresentationRemove={handleAgendaRemove}
              onTimeZoneChange={handleTimeZoneChange}
            />
            <Speakers
              id={idModel.speakers?.id}
              speakers={conference?._speaker}
              selectedSpeaker={speaker}
              theme={tracksVisible ? SectionTheme.Dark : SectionTheme.Gradient}
              keyLine={tracksVisible ? SectionKeyLine.Both : SectionKeyLine.Bottom}
              speakerSectionConfig={conference?.lobby?.speakers}
              visible={showSpeakers}
              onSelect={handleSpeakerChange}
              branding={conference?.branding}
            />
            <Agenda
              id={idModel.agenda?.id}
              user={profile}
              company={conferenceRouteParams}
              conference={conference}
              now={now}
              presentations={userPresentations}
              meetings={userMeetings}
              attendees={itineraryAttendees}
              showAgenda={showAgenda}
              loading={agendaLoading}
              exporting={exporting}
              usePresentationTheater={usePresentationTheater}
              onTimeZoneChange={handleTimeZoneChange}
              onExport={handleExport}
              onRemove={handleAgendaRemove}
            />
            <DialInInstructions id={idModel.dialInInstructions.id} config={conference?.lobby?.dialInInstructions} />
            <DialInformation
              id={idModel.dialInfo.id}
              video_vendors={conference?.video_vendors}
              visible={!isEmpty(conference?.video_vendors) && showAgenda}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <section id={idModel.id} className={ItineraryClassName.Base}>
      <CompanyLobbyLayout
        id={idModel.layout?.id}
        profile={profile}
        conference={conference}
        conferences={conferences}
        onSignOut={handleSignOut}
      >
        {renderCurrentConference()}
      </CompanyLobbyLayout>
    </section>
  );
};

export default contextSubscribe<ItineraryProps>(
  [
    {
      context: UserContext,
      mapToProps: "userContext",
    },
  ],
  memo(Itinerary)
);
