import "./speakerDetails.component.scss";
import { Anchor, AnchorTarget, Collapsable, getClassName, isEmpty, isNullOrWhiteSpace, Swapable } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { Speaker } from "../../../../../../../services/speaker/speaker.model";
import { getAccessibilityKeyPressHandler, getBackgroundImageUrl, htmlParse } from "../../../../../../../utils";
import Section from "../../../section/section.component";
import { SectionInnerWidth, SectionTheme } from "../../../section/section.definition";
import {
  SpeakerDetailsClassName,
  SpeakerDetailsIdModel,
  SpeakerDetailsItemIdModel,
  SpeakerDetailsProps,
} from "./speakerDetails.definition";

function SpeakerDetails(props: SpeakerDetailsProps): JSX.Element {
  const { id, speakers, selectedSpeaker, onClose } = props;

  const idModel = useMemo(() => new SpeakerDetailsIdModel(id), [id]);

  const selected = useMemo(getSelectedIndex, [speakers, selectedSpeaker]);
  const collapsed = useMemo(() => isEmpty(selectedSpeaker), [selectedSpeaker]);

  const renderSpeaker = useCallback(
    (speaker: Speaker, index): JSX.Element => {
      if (isEmpty(speaker)) return null;

      const { first_name, last_name, company, title, bio, image_profile, contact } = speaker;

      const itemIdBase = idModel.list.getId(index);
      const itemIdModel = new SpeakerDetailsItemIdModel(itemIdBase);

      return (
        <div id={itemIdModel?.id} className={SpeakerDetailsClassName.Item}>
          <div className={SpeakerDetailsClassName.Column}>
            <div
              id={itemIdModel?.image}
              className={SpeakerDetailsClassName.Image}
              style={{ backgroundImage: getBackgroundImageUrl(image_profile) }}
            />
            {renderContact(itemIdModel, contact, false)}
          </div>
          <div className={SpeakerDetailsClassName.Column}>
            <h3 id={itemIdModel?.name} className={SpeakerDetailsClassName.Name}>{`${first_name} ${last_name}`}</h3>
            <h4 id={itemIdModel?.company} className={SpeakerDetailsClassName.Company}>
              {company}
            </h4>
            <h4 id={itemIdModel?.title} className={SpeakerDetailsClassName.Title}>
              {title}
            </h4>
            <p id={itemIdModel?.bio} className={SpeakerDetailsClassName.Bio}>
              {htmlParse(bio)}
            </p>
            {renderContact(itemIdModel, contact, true)}
          </div>
        </div>
      );
    },
    [idModel]
  );
  const layers = useMemo(getLayers, [speakers, renderSpeaker]);

  function getSelectedIndex(): number {
    if (isEmpty(speakers)) return null;

    return speakers.findIndex((speaker) => speaker._id === selectedSpeaker?._id);
  }

  function getLayers(): JSX.Element[] {
    if (isEmpty(speakers)) return null;

    return speakers.map(renderSpeaker);
  }

  function renderContact(idModel: SpeakerDetailsItemIdModel, contact: Speaker["contact"], tablet: boolean): JSX.Element {
    const { linkedin, twitter, email } = contact ?? {};

    if (isNullOrWhiteSpace(linkedin) && isNullOrWhiteSpace(twitter) && isNullOrWhiteSpace(email)) {
      return null;
    }

    const contactClassName = getClassName(SpeakerDetailsClassName.Contact, [
      { condition: tablet, trueClassName: SpeakerDetailsClassName.ContactWithTabletModifier },
    ]);

    return (
      <div id={idModel.contact} className={contactClassName}>
        <span className={SpeakerDetailsClassName.ContactLabel}>Contact</span>
        <div className={SpeakerDetailsClassName.ContactList}>
          {!!linkedin && (
            <Anchor
              id={idModel.linkedIn}
              className={SpeakerDetailsClassName.ContactAnchor}
              url={linkedin}
              target={AnchorTarget.Blank}
            >
              <i className="q4i-linkedin" />
              <span className="sr-only">LinkedIn (opens in new tab)</span>
            </Anchor>
          )}
          {!!twitter && (
            <Anchor
              id={idModel.twitter}
              className={SpeakerDetailsClassName.ContactAnchor}
              url={twitter}
              target={AnchorTarget.Blank}
            >
              <i className="q4i-twitter" />
              <span className="sr-only">Twitter (opens in new tab)</span>
            </Anchor>
          )}
          {!!email && (
            <Anchor id={idModel.email} className={SpeakerDetailsClassName.ContactAnchor} url={`mailto:${email}`}>
              <i className="q4i-email" />
              <span className="sr-only">Email (opens in email client)</span>
            </Anchor>
          )}
        </div>
      </div>
    );
  }

  if (isEmpty(speakers)) return null;

  return (
    <Collapsable id={idModel.id} className={SpeakerDetailsClassName.Base} collapsed={collapsed} onCollapse={onClose}>
      <Section
        id={idModel.section}
        className={SpeakerDetailsClassName.Section}
        theme={SectionTheme.Black}
        innerWidth={SectionInnerWidth.Contained}
      >
        <div id={idModel.inner} className={SpeakerDetailsClassName.Inner}>
          <Swapable selected={selected} layers={layers} />
          <div
            id={idModel.close}
            className={SpeakerDetailsClassName.Close}
            tabIndex={0}
            onKeyPress={getAccessibilityKeyPressHandler(onClose)}
            onClick={onClose}
          >
            <i className="ni-close-4pt" />
          </div>
        </div>
      </Section>
    </Collapsable>
  );
}

export default memo(SpeakerDetails);
