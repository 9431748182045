import posthog from "posthog-js";
import { useCallback, useEffect } from "react";
import config from "../../config/config";

interface AnalyticsHookModel {
  identify: (email: string, company: string) => void;
}

export const useAnalytics = (): AnalyticsHookModel => {
  useEffect(function initialize() {
    posthog?.init?.(config.analytics.posthog.apiKey, { api_host: config.analytics.posthog.hostUrl });
  }, []);

  const identify = useCallback((email, company) => {
    if (!email) return;

    posthog?.identify?.(email, {
      env: config.env,
      company,
    });
  }, []);

  return {
    identify,
  };
};
