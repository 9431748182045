import "./registrationForm.component.scss";
import {
  Button,
  Card,
  ErrorModel,
  Form,
  FormFieldProps,
  isEmpty,
  NotificationService,
  Select,
  SelectPreset,
  Text,
  Textbox,
  TextPreset,
} from "@q4/nimbus-ui";
import { get, sortBy, uniqueId } from "lodash";
import React, { memo, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { Entity } from "../../definitions/entity.definition";
import { AttendeeType, AttendeeTypeOptions } from "../../services/attendee/attendee.model";
import {
  AnswerDataType,
  Question,
  QuestionGroup,
  QuestionStatus,
  QuestionType,
} from "../../services/questionGroup/questionGroup.model";
import { getPositionByReference } from "../../utils/order/order.utils";
import DeleteConfirmationMessage from "../deleteConfirmationMessage/deleteConfirmationMessage.component";
import { QuestionCard } from "../questionCard/questionCard.component";
import {
  QuestionTemporaryId,
  RegistrationFormClassName,
  RegistrationFormIdModel,
  RegistrationFormLabels,
  RegistrationFormProps,
  UsedQuestionGroups,
} from "./registrationForm.definition";

const defaultOffset = 100;

export function RegistrationForm(props: RegistrationFormProps): JSX.Element {
  const { id, conference, current: questionGroup, questionGroups, formKeys, formErrors, setFormErrors, setCurrent } = props;
  const [originalQuestions, setOriginalQuestions] = useState<Question[]>([]);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);

  const notificationService = useMemo(() => new NotificationService(), []);
  const idModel = useMemo(() => new RegistrationFormIdModel(id), [id]);
  const usedGroups = useMemo(
    () =>
      questionGroups.reduce((acc: UsedQuestionGroups, group: QuestionGroup) => {
        acc[group.attendee_types?.[0]] = true;
        return acc;
      }, {}),
    [questionGroups]
  );
  const attendeeTypeOptions = useMemo(
    () =>
      AttendeeTypeOptions.filter(
        (option) => !usedGroups[option] || (questionGroup?._id && option === questionGroup?.attendee_types?.[0])
      ),
    [questionGroup, usedGroups]
  );
  const isCorporate = useMemo(() => questionGroup?.attendee_types?.includes(AttendeeType.Corporate), [questionGroup]);

  const mappedQuestions = useMemo(
    () =>
      (questionGroup?._questions || []).map(
        (question) => new Question({ ...question, attendee_types: questionGroup?.attendee_types || [] })
      ),
    [questionGroup?._questions, questionGroup?.attendee_types]
  );

  const generalQuestions = useMemo(
    () => mappedQuestions.filter((question) => (isCorporate && !question.is_individual_corporate) || !isCorporate),
    [mappedQuestions, isCorporate]
  );

  const questionRefs = useMemo(
    () =>
      mappedQuestions.reduce((acc, value) => {
        acc[value._id] = React.createRef();
        return acc;
      }, {} as Record<string, RefObject<HTMLDivElement>>),
    [mappedQuestions]
  );

  const individualCorporateQuestions = useMemo(
    () => mappedQuestions.filter((q) => q.is_individual_corporate),
    [mappedQuestions]
  );

  const getPositionByIndex = (idx: number, offset = 0) => idx * defaultOffset + defaultOffset + offset;

  useEffect(() => {
    if (isEmpty(questionGroup) || isEmpty(mappedQuestions) || mappedQuestions.length === originalQuestions?.length) return;

    const newQuestions = mappedQuestions?.filter((q) => !originalQuestions?.map((m) => m._id).includes(q._id));
    const targetRef = questionRefs?.[newQuestions?.[0]?._id]?.current;

    if (typeof targetRef?.scrollIntoView !== "function") return;

    targetRef.scrollIntoView({
      behavior: "smooth",
    });

    setOriginalQuestions((o) => [...o, ...newQuestions]);
  }, [questionGroup, originalQuestions, questionRefs, mappedQuestions]);

  useEffect(() => {
    if (questionGroup === null) {
      setCurrent(
        new QuestionGroup({
          _conference: conference,
          title: RegistrationFormLabels.AdditionalQuestions,
          attendee_types: [attendeeTypeOptions[0]],
          _questions: [
            new Question({
              _id: `${QuestionTemporaryId}-${uniqueId()}`,
              status: QuestionStatus.Active,
              attendee_types: [attendeeTypeOptions[0]],
              position: getPositionByIndex(0),
              answer_type: {
                field_type: QuestionType.ShortAnswer,
                data_type: AnswerDataType.String,
              },
              required: false,
            }),
          ],
        })
      );
    }
  }, [conference, questionGroup, setCurrent, attendeeTypeOptions]);

  const handleTitleChange = (value: string): void => {
    const formKey = formKeys?.find((form) => form.key === "title");
    if (!isEmpty(formKey)) {
      setFormErrors((previousState) => ({
        ...previousState,
        title: new ErrorModel(formKey.message, formKey.validation(value)),
      }));
    }
    setCurrent((curr) => new QuestionGroup({ ...curr, title: value }));
  };

  const handleTypeChange = (value: AttendeeType) => {
    if (!questionGroup && !setCurrent) return;

    setCurrent(
      (curr) =>
        new QuestionGroup({
          ...curr,
          attendee_types: [value],
          _questions: curr?._questions?.map((question) => new Question({ ...question, attendee_types: [value] })),
        })
    );
  };

  const handleAddQuestionClick = (isIndividual?: boolean): void => {
    let newQuestions = mappedQuestions ?? [];
    const newQuestion = new Question({
      _id: `${QuestionTemporaryId}-${uniqueId()}`,
      status: QuestionStatus.Active,
      attendee_types: questionGroup.attendee_types,
      position: getPositionByIndex(mappedQuestions?.length),
      answer_type: {
        field_type: QuestionType.ShortAnswer,
        data_type: AnswerDataType.String,
      },
      required: false,
    });

    if (isCorporate) {
      const { generalQuestions, individualQuestions } = orderCorporateQuestions(newQuestions);
      newQuestion.position = isIndividual
        ? getPositionByIndex(newQuestions.length)
        : getPositionByIndex(generalQuestions.length, (defaultOffset / 2) * -1);
      newQuestion.is_individual_corporate = isIndividual;

      if (isIndividual) {
        individualQuestions.push(newQuestion);
      } else {
        generalQuestions.push(newQuestion);
      }

      newQuestions = [...generalQuestions, ...individualQuestions];
    } else {
      newQuestions.push(newQuestion);
    }

    setCurrent(
      (curr) =>
        new QuestionGroup({
          ...curr,
          _questions: newQuestions,
        })
    );
  };

  function orderCorporateQuestions(currentQuestions: Question[]) {
    const generalQuestions = currentQuestions
      .filter((q) => !q.is_individual_corporate)
      .map((q, idx) => new Question({ ...q, position: getPositionByIndex(idx) }));
    const individualQuestions = currentQuestions
      .filter((q) => q.is_individual_corporate)
      .map((q, idx) => new Question({ ...q, position: getPositionByIndex(idx, generalQuestions.length * defaultOffset) }));
    return { generalQuestions, individualQuestions };
  }

  const handleQuestionUpdate = (updated: Question): void => {
    const questionToUpdate = mappedQuestions.find((q) => q._id === updated._id);
    const isIndividualToggled = isCorporate && questionToUpdate.is_individual_corporate !== updated.is_individual_corporate;

    if (isIndividualToggled) {
      const { generalQuestions: general, individualQuestions: individual } = orderCorporateQuestions(
        mappedQuestions.filter((question) => question._id !== updated._id)
      );

      if (updated.is_individual_corporate) {
        individual.push(
          new Question({
            ...updated,
            position: getPositionByIndex(general.length + individual.length),
          })
        );
      } else {
        general.push(
          new Question({
            ...updated,
            position: getPositionByIndex(general.length),
          })
        );
      }

      return setCurrent(
        (curr) =>
          new QuestionGroup({
            ...curr,
            _questions: [...general, ...individual],
          })
      );
    }

    return setCurrent(
      (curr) =>
        new QuestionGroup({
          ...curr,
          _questions: curr._questions?.map((question) => {
            if (question._id !== updated._id) return question;
            return new Question({ ...updated });
          }),
        })
    );
  };

  const handleQuestionMoveUp = (questionToMove: Question) => {
    const questionIndex = mappedQuestions.findIndex((question: Question) => question._id === questionToMove._id) || 0;
    if (questionIndex === 0) return;

    const questionBeforePosition = get(mappedQuestions, `[${questionIndex - 2}].position`) || 0;
    const questionAfterPosition = get(mappedQuestions, `[${questionIndex - 1}].position`);

    const newPosition = (questionBeforePosition + questionAfterPosition) / 2;

    const updatedQuestions = mappedQuestions.map((question) =>
      question._id === questionToMove._id ? { ...question, position: newPosition } : question
    );

    setCurrent(
      new QuestionGroup({
        ...questionGroup,
        _questions: sortBy(updatedQuestions, "position"),
      })
    );
  };

  const handleQuestionMoveDown = (questionToMove: Question) => {
    const questionIndex = mappedQuestions.findIndex((question: Question) => question._id === questionToMove._id) || 0;
    if (questionIndex === mappedQuestions.length - 1) return;

    const questionBeforePosition = get(mappedQuestions, `[${questionIndex + 1}].position`) || 0;
    const questionAfterPosition = get(mappedQuestions, `[${questionIndex + 2}].position`) || questionBeforePosition * 2;

    const newPosition = (questionBeforePosition + questionAfterPosition) / 2;

    const updatedQuestions = mappedQuestions.map((question) =>
      question._id === questionToMove._id ? { ...question, position: newPosition } : question
    );

    setCurrent(
      new QuestionGroup({
        ...questionGroup,
        _questions: sortBy(updatedQuestions, "position"),
      })
    );
  };

  const handleQuestionRemoveRequest = (questionId: string) => {
    setQuestionIdToDelete(questionId);
  };

  const handleQuestionRemove = useCallback(() => {
    const updatedQuestions = mappedQuestions.filter((question) => question._id !== questionIdToDelete);

    setCurrent(
      new QuestionGroup({
        ...questionGroup,
        _questions: updatedQuestions,
      })
    );

    handleQuestionRemoveRequest(null);
    notificationService.success("Successfully deleted question.");
  }, [mappedQuestions, setCurrent, questionGroup, notificationService, questionIdToDelete]);

  const handleDuplicateQuestion = (question: Question) => {
    const currentQuestions = mappedQuestions || [];
    const position = getPositionByReference<Question>({ key: "_id", value: question._id }, "position", currentQuestions);

    const tempId = `${QuestionTemporaryId}-${uniqueId()}`;
    const newQuestion = new Question({
      ...question,
      _id: tempId,
      position,
    });

    const newQuestions = [...currentQuestions, newQuestion];

    setCurrent(
      (curr) =>
        new QuestionGroup({
          ...curr,
          _questions: sortBy(newQuestions, "position"),
        })
    );
  };

  const fields: FormFieldProps[] = [
    {
      key: RegistrationFormLabels.FormName,
      width: "1-of-2",
      smallWidth: "1-of-2",
      label: RegistrationFormLabels.FormName,
      children: (
        <Textbox id={idModel?.title?.id} maxLength={100} value={questionGroup?.title} onChange={handleTitleChange} />
      ),
      error: formErrors?.["title"],
    },
    {
      key: RegistrationFormLabels.RegistrationType,
      width: "1-of-2",
      smallWidth: "1-of-2",
      label: RegistrationFormLabels.RegistrationType,
      children: (
        <Select
          id={idModel?.registrationType?.id}
          preset={SelectPreset.Autocomplete}
          value={questionGroup?.attendee_types?.[0] || attendeeTypeOptions[0]}
          options={attendeeTypeOptions}
          onChange={handleTypeChange}
          disabled={!!questionGroup?._id}
        />
      ),
      error: formErrors?.["attendee_types"],
    },
  ];

  const renderQuestions = (questions: Question[], isIndividual = false) => {
    return (
      <>
        {questions?.map((question, idx) => {
          const dataIndex = mappedQuestions.findIndex((q) => q._id === question._id);
          return (
            <div ref={questionRefs[question._id]} key={question._id}>
              <QuestionCard
                id={`${isIndividual ? idModel?.individualQuestionCard?.getId(idx) : idModel?.questionCard?.getId(idx)}`}
                question={question}
                idx={idx + 1}
                handleQuestionUpdate={handleQuestionUpdate}
                handleQuestionMoveUp={() => handleQuestionMoveUp(question)}
                handleQuestionMoveDown={() => handleQuestionMoveDown(question)}
                isMoveUpDisabled={idx === 0}
                isMoveDownDisabled={
                  isIndividual ? idx === individualCorporateQuestions?.length - 1 : idx === generalQuestions?.length - 1
                }
                formKeys={formKeys}
                formErrors={formErrors}
                dataIndex={dataIndex}
                setFormErrors={setFormErrors}
                onDuplicate={handleDuplicateQuestion}
                handleQuestionRemove={() => handleQuestionRemoveRequest(question._id)}
              />
            </div>
          );
        })}
        <div className={RegistrationFormClassName.Add}>
          <Button
            id={idModel?.addButton?.id}
            icon="q4i-add-4pt"
            label={RegistrationFormLabels.AddQuestion}
            onClick={() => handleAddQuestionClick(isIndividual)}
          />
        </div>
      </>
    );
  };

  return (
    <div id={idModel?.id} className={RegistrationFormClassName.Base}>
      <DeleteConfirmationMessage
        id={idModel?.deleteQuestionCard?.id}
        entity={Entity.Question}
        visible={!!questionIdToDelete}
        onCancel={() => handleQuestionRemoveRequest(null)}
        onConfirm={handleQuestionRemove}
        message="Deleting a question will remove all answers collected so far. Once saved, this action cannot be undone."
      />
      <Card>
        <Form id={idModel?.nameAndTypeForm?.id} className={RegistrationFormClassName.Form} fields={fields} />
      </Card>
      <div className={RegistrationFormClassName.GeneralQuestions}>
        {isCorporate && (
          <Text preset={TextPreset.Title} className="section-header">
            General Questions
          </Text>
        )}
        {renderQuestions(generalQuestions)}
      </div>

      {isCorporate && (
        <div className={RegistrationFormClassName.IndividualQuestions}>
          <Text preset={TextPreset.Title} className="section-header">
            Individual Questions
          </Text>
          <p>These questions will be repeated for each attendee within the corporate registration.</p>
          {renderQuestions(individualCorporateQuestions, true)}
        </div>
      )}
    </div>
  );
}

export default memo(RegistrationForm);
