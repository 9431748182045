import "./requestProgressResults.component.scss";
import { isEmpty, Ghostable, Scrollbars, ScrollbarsTheme } from "@q4/nimbus-ui";
import React, { memo, useEffect, useMemo, useRef } from "react";
import type { EntityBase } from "../../../../definitions/entity.definition";
import ExportCsvButton from "../../../../services/import/components/exportCsvButton/exportCsvButton.component";
import type { RequestProgressResultsTableProps } from "./requestProgressResultsTable.definition";
import { RequestProgressResultsIdModel } from "./requestProgressResultsTable.definition";
import {
  convertImportResultToCsv,
  getImportErrorFileName,
  renderStatusTableRow,
} from "./requestProgressStatusResults.utils";

const RequestProgressStatusTable = <T extends EntityBase>(props: RequestProgressResultsTableProps<T>): JSX.Element => {
  const { id, rows, autoScrollToBottom, showExportErrors } = props;
  const idModel = useMemo(() => new RequestProgressResultsIdModel(id), [id]);

  const tableBottomRef = useRef<HTMLDivElement>();

  const errorCsv = useMemo(() => convertImportResultToCsv(rows), [rows]);
  const fileName = useMemo(() => getImportErrorFileName(rows, "_parsing_errors"), [rows]);

  useEffect((): void => {
    if (!autoScrollToBottom || isEmpty(tableBottomRef.current)) return;
    tableBottomRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  });

  return (
    <div id={idModel.id} className="import-table">
      <div className="import-table_header">
        <div className="import-table_header-title">Title</div>
        <div className="import-table_header-status">
          <span>Status</span>
          <Ghostable appear={false} ghosted={!showExportErrors}>
            <span>
              <ExportCsvButton fileName={fileName} includeTimeStamp={true} items={errorCsv} />
            </span>
          </Ghostable>
        </div>
      </div>
      <div className="import-table_content">
        <Scrollbars theme={ScrollbarsTheme.Dark}>
          {rows.map(renderStatusTableRow)}
          <div ref={tableBottomRef}></div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default memo(RequestProgressStatusTable);
