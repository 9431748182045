import { ButtonIdModel, ButtonListId, IdModelBase, isNullOrWhiteSpace, PopoverMenuIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment-timezone";

export interface DateSelectProps extends BaseComponentProps {
  selectedDate: Moment;
  dateOptions: Moment[];
  onSelect: (date: Moment) => void;
}

export enum DateSelectClassName {
  Base = "date-select",
  BaseWithEmptyModifier = "date-select--empty",
  BaseWithOpenModifier = "date-select--open",
  Label = "date-select_label",
  Button = "date-select_button",
  PopoverMenu = "date-select_popover-menu",
}

export class DateSelectIdModel extends IdModelBase {
  button: ButtonIdModel;
  popoverMenu: PopoverMenuIdModel;
  dates: ButtonListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.button = new ButtonIdModel(null);
      this.popoverMenu = new PopoverMenuIdModel(null);
      return;
    }

    this.button = new ButtonIdModel(`${this.id}Button`);
    this.popoverMenu = new PopoverMenuIdModel(`${this.id}PopoverMenu`);
    this.dates = new ButtonListId(this.popoverMenu.id, "Date", "Button");
  }
}
