import "./emailForm.component.scss";
import {
  Checkbox,
  Form,
  getClassName,
  isNullOrWhiteSpace,
  Textarea,
  Textbox,
  InfoIcon,
  Origin,
  TooltipTheme,
} from "@q4/nimbus-ui";
import { set, clone } from "lodash";
import React, { useMemo } from "react";
import ColorTextbox from "../../../../../../components/colorTextbox/colorTextbox.component";
import { FileUploaderWithImgPreload } from "../../../../../../components/fileUploader/fileUploader.component";
import { useFileUpload } from "../../../../../../hooks";
import { FieldMaxLength } from "../../../../../../utils/form/form.utils";
import { EmailFormClassName, EmailFormIdModel, EmailFormProps } from "./emailForm.definition";

export const EmailForm = (props: EmailFormProps): JSX.Element => {
  const { conference, onChange: onChangeProps, className, id } = props;

  const { email_configuration } = conference || {};
  const {
    message,
    signature,
    logo,
    color_header,
    email_address_alias,
    email_name_alias,
    registration_approval_message,
    registration_approval_enabled,
  } = email_configuration || {};

  const registrationCompleteEnabled = useMemo(
    () => !!email_configuration?.registration_complete_enabled,
    [email_configuration?.registration_complete_enabled]
  );

  const baseClassName = useMemo(
    () => getClassName(EmailFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const idModel = useMemo(() => new EmailFormIdModel(id), [id]);

  const handleFileUpload = useFileUpload();

  function getStateChangeHandler(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      const state = email_configuration;
      const newState = set(clone(state), key, value);
      onChangeProps(newState);
    };
  }

  return (
    <Form
      id={idModel.id}
      className={baseClassName}
      fields={[
        {
          key: "Email Name Alias",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: (
            <>
              Email Name Alias
              <InfoIcon
                tooltipProps={{
                  label: "Name of sender for all email communications",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <Textbox
              id={idModel?.emailNameAlias.id}
              value={email_name_alias}
              maxLength={100}
              onChange={getStateChangeHandler("email_name_alias")}
            />
          ),
        },
        {
          key: "Email Address Alias",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: (
            <>
              Email Address Alias
              <InfoIcon
                tooltipProps={{
                  label: "Sender’s email address for all email communications",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <Textbox
              id={idModel?.emailAddressAlias.id}
              value={email_address_alias}
              maxLength={100}
              onChange={getStateChangeHandler("email_address_alias")}
            />
          ),
        },
        {
          key: "Email Header Logo",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: (
            <>
              Email Header Logo
              <InfoIcon
                tooltipProps={{
                  label: "This logo is used in the header of all emails",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <FileUploaderWithImgPreload
              id={idModel?.emailLogo.id}
              dropzoneProps={{ accept: ["image/*"] }}
              fileApi={handleFileUpload}
              fileUrl={logo}
              onChange={getStateChangeHandler("logo")}
              showPreview={true}
            />
          ),
        },
        {
          key: "Email Header Color",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: (
            <>
              Email Header Color
              <InfoIcon
                tooltipProps={{
                  label: "Set a background color for all email headers",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <ColorTextbox
              id={idModel?.emailHeaderColor.id}
              value={color_header}
              onChange={getStateChangeHandler("color_header")}
            />
          ),
        },
        {
          key: "Email Signature",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Email Signature (max. 140 characters)",
          children: (
            <Textarea
              id={idModel?.signature?.id}
              autoFocus={false}
              value={signature}
              onChange={getStateChangeHandler("signature")}
              maxLength={140}
            />
          ),
        },
        {
          key: "Email Checkbox",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Enable sending confirmation emails after registration?",
          children: (
            <Checkbox
              id={idModel?.registrationCompleteEnabled?.id}
              checked={!!registrationCompleteEnabled}
              onChange={getStateChangeHandler("registration_complete_enabled")}
              label="Enable"
            />
          ),
        },
        {
          key: "Email Message",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: (
            <>
              Registration Email Message
              <InfoIcon
                tooltipProps={{
                  label: "Personalized text added to registration emails",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <Textarea
              id={idModel?.message?.id}
              autoFocus={false}
              value={message}
              onChange={getStateChangeHandler("message")}
              maxLength={1000}
            />
          ),
        },
        {
          key: "Approval Email Checkbox",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Enable sending registration approval emails?",
          children: (
            <Checkbox
              id={idModel?.registrationApprovalEnabled?.id}
              checked={!!registration_approval_enabled}
              onChange={getStateChangeHandler("registration_approval_enabled")}
              label="Enable"
            />
          ),
        },
        {
          key: "Approval Email Message",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: (
            <>
              Registration Approval Email Message
              <InfoIcon
                id={idModel?.registrationApprovalTooltip?.id}
                tooltipProps={{
                  label: "This message will display when a registrant is approved",
                  position: Origin.Top,
                  theme: TooltipTheme.Slate,
                }}
              />
            </>
          ),
          children: (
            <Textarea
              id={idModel?.registrationApprovalMessage?.id}
              autoFocus={false}
              value={registration_approval_message}
              onChange={getStateChangeHandler("registration_approval_message")}
              maxLength={FieldMaxLength.EmailRegistrationMessage}
            />
          ),
        },
      ]}
    />
  );
};
