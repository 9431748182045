import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useEffect } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner.component";
import { usePublicLink } from "../../../hooks/public/usePublicLink/usePublicLink.hook";
import { CompanyLobbyParams } from "../../company/lobby/companyLobby.definition";

const PublicLink = (): JSX.Element => {
  const params = useParams<CompanyLobbyParams>();
  const { getLink } = usePublicLink();

  useEffect(() => {
    getLink(params?.linkId).then((url) => {
      if (isNullOrWhiteSpace(url)) return;
      window.location.href = url;
    });
  }, [params.sessionId, getLink, params?.linkId]);

  return <LoadingSpinner />;
};

export default memo(PublicLink);
