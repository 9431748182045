import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { ICalendar } from "datebook";
import { Dictionary, upperCase } from "lodash";
import { DefaultInternalCompanyName } from "../../definitions/agenda.definition";
import {
  formatMeetingAttendee,
  getCorporateMeetingAttendees,
  getInternalMeetingAttendees,
  getPublicLinkUrl,
  groupAttendeesByCompany,
  isCorporate,
  isCorporateType,
  isInternal,
} from "../../utils";
import { AgendaSession, AgendaSessionLabel } from "../../views/public/itinerary/components/agenda/agenda.definition";
import { getDialInNumbers } from "../../views/public/itinerary/components/dialInformation/dialInformation.helper";
import {
  MsTeamsDialInformationLink,
  ZoomDialInformationLink,
} from "../../views/public/itinerary/components/dialInformation/dialInformation.utils";
import { AttendeeViewModel } from "../attendee/attendee.model";
import { Conference, VendorName } from "../conference/conference.model";
import { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import { PresentationSessionType } from "../presentation/presentation.model";
import { SessionVendor } from "../session/session.model";
import { CalendarExport, CalendarExportType, CalendarSession } from "./calendarExport.model";

export default class CalendarExportService {
  public async getCalendar(calendar: CalendarExport): Promise<string> {
    if (isEmpty(calendar)) return null;

    const { type } = calendar;
    if (type === CalendarExportType.Ics) return this.getIcsCalendar(calendar);
    return null;
  }

  public getDescription(conference: Conference, session: AgendaSession, user: AttendeeViewModel): string {
    if (isEmpty(session)) return null;

    const preview = !!conference?.preview;
    const conferenceCompany = conference?._company ? conference?._company.name : DefaultInternalCompanyName;
    const isLinkProtected = conference?.requireAuthenticatedMeetingLink ?? true;
    const {
      sessionLabel,
      Title,
      speakers,
      attendees,
      GoUrl,
      vendor,
      vendor_override,
      isUserSpeaker,
      speaker_notes,
      linkId,
      roomLocation,
      presentation_type,
      session_type,
      description: breakDescription,
    } = session;
    const isMultipleVendors = vendor?.length > 1;
    const isPresentation = sessionLabel === AgendaSessionLabel.Presentation;
    const isBreakSession = session_type === PresentationSessionType.Break;
    const presentationUrlLabel = isNullOrWhiteSpace(presentation_type) ? "Url" : `${presentation_type} Url`;

    const getCorporateDescription = (): string => {
      if (!isCorporate(user) && !isInternal(user)) return null;

      const filteredAttendees = groupAttendeesByCompany(conferenceCompany, user?.company, attendees);

      let description = Object.keys(filteredAttendees).reduce((description: string, company: string) => {
        if (company === conferenceCompany) return description;
        return description.concat(`${this.formatParticipants(company, filteredAttendees)}\n`);
      }, "");

      if (!isEmpty(filteredAttendees[conferenceCompany]) && !isInternal(user)) {
        description = description.concat(`${this.formatParticipants(conferenceCompany, filteredAttendees)}\n`);
      }

      return description.trim();
    };

    const getJoinInstructions = (): string => {
      const { disabled, title, label } = conference?.lobby?.dialInInstructions || {};
      if (disabled || preview) return "";

      let instructions = "";
      const vendorInstructions = `\n\n${title}\n\n${label}\n`;

      if (!isNullOrWhiteSpace(roomLocation)) {
        instructions = `\n\nRoom Location: ${roomLocation}`;
      }
      if (!isEmpty(conference?.video_vendors)) instructions = instructions.concat(vendorInstructions);

      return instructions;
    };

    const getVendorInfo = (): string => {
      const link = isLinkProtected ? GoUrl : getPublicLinkUrl(linkId);

      if ((isEmpty(vendor) && isEmpty(vendor_override) && isNullOrWhiteSpace(link)) || preview) return "";

      if (isEmpty(vendor) && isEmpty(vendor_override) && !isNullOrWhiteSpace(link) && !isPresentation) {
        return `\n\nMeeting Link: ${encodeURI(link)}`;
      }

      if (isEmpty(vendor) && isEmpty(vendor_override) && !isNullOrWhiteSpace(link) && isPresentation) {
        return "";
      }

      if (!isEmpty(vendor_override)) {
        const label = isPresentation ? "" : `Meeting Link: ${encodeURI(link)}`;
        const dialInNumbers =
          Array.isArray(vendor_override.dial_in_numbers) && vendor_override.dial_in_numbers.length > 0
            ? `\n\nDial-in numbers: \n\n${(vendor_override.dial_in_numbers || []).join("\n\n")}`
            : "";
        return (
          "\n\n" +
          label +
          `${vendor_override.meeting_id ? `\nMeeting ID: ${vendor_override.meeting_id}` : ""}` +
          `${vendor_override.meeting_password ? `\nPasscode: ${vendor_override.meeting_password}` : ""}` +
          `${vendor_override.phone_password ? `\nPhone Password: ${vendor_override.phone_password}` : ""}` +
          dialInNumbers
        );
      }

      return (
        `${vendor.map((x: SessionVendor, i: number) => {
          const url = i === 0 ? link : x.url;
          const linkText = isMultipleVendors ? `${upperCase(x.vendor)} Meeting Link` : "Meeting Link";
          const hasMeetingId = !isNullOrWhiteSpace(x.vendor_id);
          const hasPasscode = !isNullOrWhiteSpace(x.password);

          return (
            `\n\n${linkText}: ${encodeURI(url)}` +
            `${hasMeetingId ? `\nMeeting ID: ${x.vendor_id}` : ""}` +
            `${hasPasscode ? `\nPasscode: ${x.password}` : ""}`
          );
        })}` +
        `${(vendor || []).map((x: SessionVendor) => {
          return this.getVendorDialInNumbers(x.vendor);
        })}`
      );
    };

    if (isPresentation) {
      let presentationDescription = `${Title} \n`;

      if (isBreakSession) {
        return presentationDescription + (isNullOrWhiteSpace(breakDescription) ? "" : `\nDescription: ${breakDescription}`);
      }

      presentationDescription +=
        "Speakers: " +
        (isEmpty(speakers)
          ? "TBD"
          : speakers
              .filter((x) => x?._id !== user?._id)
              .map((speaker) => {
                const { display_name, title } = speaker;
                return isNullOrWhiteSpace(title) ? display_name : `${display_name}, ${title}`;
              })
              .join(" | ")) +
        "\n" +
        (isUserSpeaker ? "Speaker Url" : presentationUrlLabel) +
        `: ${GoUrl}` +
        (!isNullOrWhiteSpace(roomLocation) ? `\nRoom Location: ${roomLocation}` : "") +
        (isNullOrWhiteSpace(speaker_notes) || !isUserSpeaker ? "" : `\nSpeaker Notes: ${speaker_notes}`);
      return presentationDescription + getVendorInfo();
    }

    const corpDescription = getCorporateDescription();
    if (!isEmpty(corpDescription) && !isInternal(user))
      return `${corpDescription}${getJoinInstructions()}${getVendorInfo()}`;

    const corporateCompany = new CorporateProfile((attendees || []).find((x) => isCorporateType(x.type))?._corporate_profile)
      .name;
    const corporateAttendees = getCorporateMeetingAttendees(corporateCompany, attendees);
    const internalAttendees = getInternalMeetingAttendees(attendees);
    const internalParticipants = `\n\n${conferenceCompany}\nParticipants: ${internalAttendees}`;

    const investorDescription =
      `Corporate: ${corporateCompany}` +
      `\nParticipants: ${corporateAttendees}` +
      (!isNullOrWhiteSpace(internalAttendees) && !isInternal(user) ? `${internalParticipants}` : "");

    if (isInternal(user)) {
      return `${corpDescription}\n\n${investorDescription}${getJoinInstructions()}${getVendorInfo()}`;
    }

    return `${investorDescription}${getJoinInstructions()}${getVendorInfo()}`;
  }

  private formatParticipants(company: string, filteredAttendees: Dictionary<AttendeeViewModel[]>): string {
    return `\n${company}\nParticipants: ${filteredAttendees[company].map((x) => formatMeetingAttendee(x)).join(" | ")}`;
  }

  private getVendorDialInNumbers(value: VendorName): string {
    const isZoom = value === VendorName.Zoom;
    const dialInNumbers = getDialInNumbers<string>(
      value,
      (item) => {
        return `\n${item}`;
      },
      (key, item) => {
        return `\n${key}${item.join(" ")}\n`;
      },
      (group) => {
        return `${group.join(" ")}`;
      }
    );

    return `\n\nDial by your location (${isZoom ? "Zoom" : "Microsoft Teams"})\n${dialInNumbers.join(
      " "
    )}\nFind your local number: ${isZoom ? ZoomDialInformationLink : MsTeamsDialInformationLink}`;
  }

  private getIcsCalendar(calendar: CalendarExport): string {
    const { download, options, filename, name } = calendar;
    if (isEmpty(options)) return null;

    const iCalendar = options.reduce((icsCalendar: ICalendar, option: CalendarSession, i: number) => {
      return i === 0
        ? this.formatToIcsCalendar(option, name).addProperty("NAME", name).addProperty("X-WR-CALNAME", name)
        : icsCalendar.addEvent(this.formatToIcsCalendar(option, name));
    }, {} as ICalendar);

    if (isEmpty(iCalendar)) return null;

    download && iCalendar.download(`${filename}.ics`);
    return iCalendar.render();
  }

  private formatToIcsCalendar(option: CalendarSession, conferenceName: Conference["title"]): ICalendar {
    if (isEmpty(option)) return null;

    const { title, description, start, end, uid, alarm, attendees, meta, props, recurrence } = option;
    const calendarEvent = new ICalendar({
      title: `${title} | ${conferenceName}`,
      description,
      start,
      end,
      recurrence,
      attendees: (attendees || []).map((x) => {
        return {
          email: x.email,
          name: x.display_name,
        };
      }),
    }).addProperty("UID", uid);

    (alarm || []).map((x) => calendarEvent.addAlarm(x));
    (meta || []).map((x) => calendarEvent.setMeta(x.key, x.value));
    (props || []).map((x) => calendarEvent.addProperty(x.key, x.value));

    return calendarEvent;
  }
}
