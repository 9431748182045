import { Select, isNullOrWhiteSpace, isNil } from "@q4/nimbus-ui";
import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { idModel, CustomSelectProps, CreateNewSuffix, SelectActions } from "./customSelect.definition";

function CustomSelect(props: CustomSelectProps): JSX.Element {
  const { value, options, onChange, addNew, addNewSuffix = CreateNewSuffix, maxLength, ...rest } = props;

  const [selectValues, setSelectValues] = useState({
    selection: value,
    input: "",
  });

  useEffect(() => {
    if ((isNil(selectValues.selection) && value) || (isNil(value) && selectValues.selection)) {
      setSelectValues((values) => ({ ...values, selection: value }));
    }
  }, [value, selectValues, setSelectValues]);

  const selectOptions = useMemo(() => {
    const originalOptions = options ?? [];
    const { input } = selectValues;

    if (!addNew || isNullOrWhiteSpace(input)) return originalOptions;

    return [`${input}${addNewSuffix}`, ...originalOptions];
  }, [options, selectValues, addNew, addNewSuffix]);

  const onInputChange = useCallback(
    (input: string) => {
      if (maxLength) {
        return setSelectValues(({ selection }) => ({ selection, input: input.substring(0, maxLength) }));
      }

      return setSelectValues(({ selection }) => ({ selection, input }));
    },
    [maxLength]
  );

  const handleChange = useCallback(
    (option: string, triggeredAction): void => {
      if (triggeredAction?.action === SelectActions.Clear) {
        onChange("");
        setSelectValues({
          selection: "",
          input: "",
        });
        return;
      }

      if (isNil(option)) return;

      let newOption = option;

      if (newOption.includes(addNewSuffix)) {
        newOption = newOption.replace(addNewSuffix, "");
      }

      onChange(newOption);
      setSelectValues((values) => ({ ...values, selection: newOption }));
    },
    [onChange, addNewSuffix]
  );

  return (
    <Select
      id={idModel.select}
      value={selectValues.selection}
      inputValue={selectValues.input}
      options={selectOptions}
      onInputChange={onInputChange}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default memo(CustomSelect);
