import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { camelCase } from "lodash";

export default class QueryService {
  private _search: string;

  constructor(search?: string) {
    this._search = isNullOrWhiteSpace(search) ? (window.location.search.split("?") || [])[1] : search;
  }

  get(key: string): string {
    if (isNullOrWhiteSpace(key) || isNullOrWhiteSpace(this._search)) return null;

    const query = new URLSearchParams(this._search);
    const insensitiveKeySearch = [...query.entries()].reduce((search, entry, i) => {
      const key = camelCase(entry[0]).toLowerCase();
      return `${search}${i === 0 ? "" : "&"}${key}=${entry[1]}`;
    }, "?");
    if (isNullOrWhiteSpace(insensitiveKeySearch)) {
      return null;
    }

    const insensitiveQuery = new URLSearchParams(insensitiveKeySearch);
    return insensitiveQuery.get(key.toLowerCase());
  }
}
