import "./registrantForm.component.scss";
import {
  Anchor,
  AnchorTheme,
  Checkbox,
  Chips,
  ChipsItemProps,
  convertStringToEnum,
  FileUploader,
  Form,
  FormFieldProps,
  getClassName,
  Grid,
  GridColumn,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  Text,
  Textarea,
  Textbox,
  TextPreset,
  useVisibility,
} from "@q4/nimbus-ui";
import { set } from "lodash";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DefaultTimeZone } from "../../const";
import { TimeFormat } from "../../definitions/date.definition";
import { useFileUpload } from "../../hooks";
import { useAvailability } from "../../hooks/useAvailability/availabilityHook.component";
import { RegistrantStatus } from "../../services/admin/registrant/registrant.model";
import { Answer } from "../../services/answer/answer.model";
import {
  AttendeeType,
  AttendeeTypeDefault,
  AttendeeTypesWithAvailability,
  AttendeeViewModel,
} from "../../services/attendee/attendee.model";
import { ConferenceType, GuestAttendeeType, ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import { CorporateType } from "../../services/corporateProfile/corporateProfile.model";
import { InvestorType } from "../../services/investor/investor.model";
import { formatDateRange, getCorporateName, getNonConflictingSlots } from "../../utils/index";
import AvailabilityForm from "../availabilityForm/availabilityForm.component";
import CustomSelect from "../customSelect/customSelect.component";
import QuestionsForm from "../questionsForm/questionsForm.component";
import CorporateAttendeeModal from "./components/corporateAttendeeModal/corporateAttendeeModal.component";
import {
  CreateNewCompanySuffix,
  RegistrantField,
  RegistrantFormClassName,
  RegistrantFormIdModel,
  RegistrantFormProps,
} from "./registrantForm.definition";

const RegistrantForm = (props: RegistrantFormProps): JSX.Element => {
  const {
    id,
    registrant,
    className,
    companies,
    conference,
    corporateProfiles,
    questionGroups,
    setFormErrors,
    onRegistrantUpdate,
  } = props;

  const [currentCorporateAttendee, setCurrentCorporateAttendee] = useState<AttendeeViewModel>();
  const [corporateAttendeeEdit, setCorporateAttendeeEdit] = useState(false);
  const [selectedAttendeeIndex, setSelectedAttendeeIndex] = useState<number>(-1);

  const currentQuestionGroup = useMemo(
    () =>
      (questionGroups || []).find((group) =>
        group.attendee_types.includes(
          isNullOrWhiteSpace(registrant?.attendee_type) ? AttendeeTypeDefault : registrant.attendee_type
        )
      ),
    [questionGroups, registrant?.attendee_type]
  );

  const nonConflictingSlots = useMemo(
    () => getNonConflictingSlots(conference?.scheduler?.slots),
    [conference?.scheduler?.slots]
  );

  // #region Effects
  useEffect(() => {
    // Create default availability when creating a registrant (saving empty array when no slots present)
    if (
      isNil(registrant?._id) &&
      isNil(registrant?.availability) &&
      AttendeeTypesWithAvailability.includes(registrant?.attendee_type)
    ) {
      onRegistrantUpdate({
        availability: nonConflictingSlots,
      });
      return;
    }

    // If there are no slots present, don't update availability for corporate and investor
    if (isEmpty(nonConflictingSlots)) return;

    // If there are slots present, but investor has no availability, update all slots to yes
    if (
      registrant?.attendee_type === AttendeeType.Investor &&
      registrant?.status === RegistrantStatus.Pending &&
      isEmpty(registrant?.availability)
    ) {
      onRegistrantUpdate({
        availability: nonConflictingSlots,
      });
    }
  }, [
    nonConflictingSlots,
    onRegistrantUpdate,
    registrant?._id,
    registrant?.attendee_type,
    registrant?.availability,
    registrant?.status,
  ]);

  // #endregion

  // #region Hooks
  const handleFileUpload = useFileUpload();
  const [attendeeModalVisible, handleAttendeeModalOpen, handleAttendeeModalClose] = useVisibility();
  // #endregion

  // #region Definitions
  const idModel = useMemo(() => new RegistrantFormIdModel(id), [id]);

  const isCorporate = useMemo(() => registrant?.attendee_type === AttendeeType.Corporate, [registrant?.attendee_type]);

  const isPresentationOnly = useMemo(
    () => conference?.conference_type === ConferenceType.PresentationOnly,
    [conference?.conference_type]
  );

  const showHostPresentations = useMemo(() => {
    return (
      !conference?.corporate_deadlines?.presentation || moment().isBefore(conference?.corporate_deadlines?.presentation)
    );
  }, [conference?.corporate_deadlines?.presentation]);

  const timeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference?.time_zone]);

  const {
    timeslots,
    days: schedulerDays,
    handleTimeslotChange,
  } = useAvailability({
    timeZone,
    scheduler: conference?.scheduler,
    availability: registrant?.availability,
    onAvailabilityChange: handleAvailabilityChange,
  });

  const baseClassName = useMemo(
    () =>
      getClassName(RegistrantFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const attendeeTypeOptions = useMemo(() => Object.keys(AttendeeType).map((x) => convertStringToEnum(AttendeeType, x)), []);
  const guestAttendeeTypeOptions = useMemo(
    () => Object.values(GuestAttendeeType).map((x) => convertStringToEnum(GuestAttendeeType, x)),
    []
  );
  const investorTypeOptions = useMemo(() => Object.values(InvestorType).map((x) => x), []);
  const statusOptions = useMemo(
    () => Object.keys(RegistrantStatus).map((x) => convertStringToEnum(RegistrantStatus, x)),
    []
  );
  const corporateTypes = useMemo(() => Object.keys(CorporateType).map((x) => convertStringToEnum(CorporateType, x)), []);

  const registrantType = useMemo(() => {
    return isNullOrWhiteSpace(registrant?.attendee_type) ? AttendeeTypeDefault : registrant.attendee_type;
  }, [registrant?.attendee_type]);
  const isApproved = useRef(registrant?.status === RegistrantStatus.Approved);

  const isCorporatePrivate = useMemo(
    () => registrant?.corporate_type === CorporateType.Private,
    [registrant?.corporate_type]
  );

  const mappedChips: ChipsItemProps<AttendeeViewModel>[] = useMemo(() => {
    return (registrant?.corporate_attendees || []).map((attendee) => {
      const { email, friendly_name } = attendee;
      return {
        value: email,
        label: isNullOrWhiteSpace(friendly_name) ? "Add first attendee's details here" : friendly_name,
        data: attendee,
        locked: isApproved.current,
      };
    });
  }, [isApproved, registrant?.corporate_attendees]);

  const primaryContact = useMemo(() => {
    if (!registrant?.attendee_type || registrant?.attendee_type !== AttendeeType.Corporate) return null;
    return (registrant?.corporate_attendees || []).find((x) => x.is_primary_contact);
  }, [registrant?.attendee_type, registrant?.corporate_attendees]);
  // #endregion

  // #region Handlers
  const handleCompanyChange = useCallback(
    (option: string): void => {
      if (isNullOrWhiteSpace(option)) return;

      onRegistrantUpdate({
        company: option,
      });
    },
    [onRegistrantUpdate]
  );

  const handleCorporateAttendeeEdit = useCallback(
    (_value: string, data: AttendeeViewModel) => {
      if (isEmpty(data)) return;

      setCorporateAttendeeEdit(true);
      setCurrentCorporateAttendee(
        new AttendeeViewModel({
          ...data,
          company: registrant?.corporate_name,
          type: AttendeeType.Corporate,
        })
      );
      const currentAttendees = registrant?.corporate_attendees ?? [];
      setSelectedAttendeeIndex(currentAttendees.indexOf(data));
      handleAttendeeModalOpen();
    },
    [handleAttendeeModalOpen, registrant?.corporate_attendees, registrant?.corporate_name]
  );

  const handleCorporateAttendeeRemove = useCallback(
    (value: string): void => {
      if (isNullOrWhiteSpace(value)) return;

      const newAttendees = (registrant?.corporate_attendees || []).filter(({ email }) => email !== value);

      onRegistrantUpdate({ corporate_attendees: newAttendees });
    },
    [onRegistrantUpdate, registrant?.corporate_attendees]
  );

  function handleAvailabilityChange(availability: ConferenceSchedulerSlot[]) {
    onRegistrantUpdate({
      availability,
    });
  }

  const handleCorporateAttendeeUpdate = useCallback(
    (attendee: AttendeeViewModel): void => {
      if (isEmpty(attendee)) return;

      const currentAttendees = registrant?.corporate_attendees ?? [];
      const newAttendees =
        selectedAttendeeIndex === -1
          ? currentAttendees.concat([attendee])
          : currentAttendees.map((currentAttendee, index) => {
              if (index === selectedAttendeeIndex) {
                return new AttendeeViewModel({
                  ...currentAttendee,
                  ...attendee,
                });
              }
              return currentAttendee;
            });

      onRegistrantUpdate({ corporate_attendees: newAttendees });
      handleAttendeeModalClose();
    },
    [handleAttendeeModalClose, onRegistrantUpdate, registrant?.corporate_attendees, selectedAttendeeIndex]
  );

  const handleCorporateAttendeeCreateNew = useCallback((): void => {
    setCorporateAttendeeEdit(false);
    setCurrentCorporateAttendee(
      new AttendeeViewModel({
        company: registrant?.corporate_name,
        type: AttendeeType.Corporate,
      })
    );

    setSelectedAttendeeIndex(-1);
    handleAttendeeModalOpen();
  }, [handleAttendeeModalOpen, registrant?.corporate_name]);

  const handleCorporateAttendeeSave = useCallback(
    (attendee: AttendeeViewModel): void => {
      if (isEmpty(attendee)) return;

      const currentAttendees = registrant?.corporate_attendees ?? [];
      const newAttendees = currentAttendees.concat([
        new AttendeeViewModel({
          ...attendee,
          company: registrant?.corporate_name,
          type: AttendeeType.Corporate,
        }),
      ]);

      onRegistrantUpdate({ corporate_attendees: newAttendees });
      handleAttendeeModalClose();
    },
    [handleAttendeeModalClose, onRegistrantUpdate, registrant?.corporate_attendees, registrant?.corporate_name]
  );

  const getTextboxHandler = useCallback(
    (key: string) => {
      return function (value: string): void {
        onRegistrantUpdate(set(registrant, key, value));
      };
    },
    [onRegistrantUpdate, registrant]
  );

  const getRadioChangeHandler = useCallback(
    (key: string) => {
      return function (checked: boolean): void {
        onRegistrantUpdate(set(registrant, key, checked));
      };
    },
    [onRegistrantUpdate, registrant]
  );

  const getSelectChangeHandler = useCallback(
    (key: string) => {
      return function (option: string): void {
        onRegistrantUpdate(set(registrant, key, option));
      };
    },
    [onRegistrantUpdate, registrant]
  );

  const handlePrimaryContactChange = useCallback(
    (attendee: AttendeeViewModel) => {
      const newAttendees = (registrant?.corporate_attendees || []).map((x) => ({
        ...x,
        is_primary_contact: x.email === attendee.email,
      })) as AttendeeViewModel[];

      onRegistrantUpdate({ corporate_attendees: newAttendees });
    },
    [onRegistrantUpdate, registrant?.corporate_attendees]
  );

  function handleTypeChange(option: AttendeeType): void {
    if (isEmpty(option)) return;

    const isOptionInternal = option === AttendeeType.Internal;
    const isOptionGuest = option === AttendeeType.Guest;
    const isOptionInvestor = option === AttendeeType.Investor;
    const isOptionCorporate = option === AttendeeType.Corporate;

    const internalCompany = isOptionInternal ? conference?._company?.name : registrant?.company;
    const company = registrant?.attendee_type === AttendeeType.Internal ? null : internalCompany;
    const sales_representative = isOptionInternal || isOptionCorporate ? undefined : registrant?.sales_representative;
    const investor_type = isOptionInvestor ? registrant?.investor_type : undefined;
    const guest_attendee_type = isOptionGuest ? registrant?.guest_attendee_type : undefined;

    onRegistrantUpdate({
      attendee_type: option,
      company,
      sales_representative,
      investor_type,
      guest_attendee_type,
    });
  }

  const handleGuestAttendeeTypeChange = useCallback(
    (option: GuestAttendeeType): void => {
      onRegistrantUpdate({
        guest_attendee_type: option ?? null,
      });
    },
    [onRegistrantUpdate]
  );

  function handleStatusChange(option: RegistrantStatus): void {
    if (isEmpty(option)) return;

    onRegistrantUpdate({
      status: option,
    });
  }

  const handleCustomAnswerChange = useCallback(
    (currentAnswer: Answer) => {
      const answers = registrant?.custom_question_answers || [];
      const targetAnswer = answers?.find((ans) => ans._question === currentAnswer._question);

      if (isEmpty(targetAnswer)) {
        onRegistrantUpdate({
          custom_question_answers: [...answers, currentAnswer],
        });
        return;
      }

      const updatedAnswers = answers?.map((ans) => {
        if (ans._question !== currentAnswer._question) return ans;
        return new Answer({ ...ans, answer: currentAnswer.answer });
      });

      onRegistrantUpdate({
        custom_question_answers: updatedAnswers,
      });
    },
    [onRegistrantUpdate, registrant]
  );

  // #endregion

  // #region Helpers
  const getFields = useCallback(
    (type: AttendeeType): FormFieldProps[] => {
      if (isEmpty(registrant)) return [];

      // #region Fields
      const fields: RegistrantField = {
        firstName: {
          key: "First Name",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "First Name",
          children: (
            <Textbox id={idModel?.firstName?.id} value={registrant?.first_name} onChange={getTextboxHandler("first_name")} />
          ),
        },
        lastName: {
          key: "Last Name",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "Last Name",
          children: (
            <Textbox id={idModel?.lastName?.id} value={registrant?.last_name} onChange={getTextboxHandler("last_name")} />
          ),
        },
        email: {
          key: "Email",
          width: "1-of-3",
          smallWidth: "1-of-2",
          label: "Email",
          children: <Textbox id={idModel?.email?.id} value={registrant?.email} onChange={getTextboxHandler("email")} />,
        },
        title: {
          key: "Title",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Title",
          children: <Textbox id={idModel?.title?.id} value={registrant?.title} onChange={getTextboxHandler("title")} />,
        },
        company: {
          key: "Company",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Company",
          children: (
            <CustomSelect
              id={idModel?.company?.id}
              preset={SelectPreset.Autocomplete}
              placeholder=""
              value={registrant?.company}
              options={companies ?? []}
              disabled={registrant?.attendee_type === AttendeeType.Internal}
              isClearable
              backspaceRemovesValue
              onChange={handleCompanyChange}
              addNew={true}
              addNewSuffix={CreateNewCompanySuffix}
            />
          ),
        },
        investorType: {
          key: "Investor Type",
          width: "1-of-4",
          smallWidth: "1-of-1",
          label: "Investor Type",
          children: (
            <Select
              id={idModel?.investorType?.id}
              preset={SelectPreset.Dropdown}
              placeholder=""
              value={registrant?.investor_type}
              options={investorTypeOptions}
              onChange={getSelectChangeHandler("investor_type")}
            />
          ),
        },
        phone: {
          key: "Phone",
          optional: true,
          width: "1-of-2",
          smallWidth: "1-of-2",
          label: "Phone",
          children: (
            <Textbox id={idModel?.phone?.id} value={registrant?.phone_number} onChange={getTextboxHandler("phone_number")} />
          ),
        },
        representative: {
          key: "Relationship Manager/Sales Representative Name",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "Relationship Manager/Sales Representative Name",
          children: (
            <Textbox
              id={idModel?.representative?.id}
              value={registrant?.sales_representative}
              onChange={getTextboxHandler("sales_representative")}
            />
          ),
        },
        secondaryContact: {
          key: "Secondary Contact",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: (
            <Checkbox
              id={idModel?.secondaryContact?.id}
              checked={registrant?.has_secondary_contact}
              onChange={getRadioChangeHandler("has_secondary_contact")}
              label="Secondary Contact"
              inline
            />
          ),
        },
        secondaryFirstName: {
          key: "Secondary First Name",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "First Name",
          children: (
            <Textbox
              id={idModel?.secondaryFirstName?.id}
              value={registrant?.secondary_contact?.first_name}
              onChange={getTextboxHandler("secondary_contact.first_name")}
            />
          ),
        },
        secondaryLastName: {
          key: "Secondary Last Name",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "Last Name",
          children: (
            <Textbox
              id={idModel?.secondaryLastName?.id}
              value={registrant?.secondary_contact?.last_name}
              onChange={getTextboxHandler("secondary_contact.last_name")}
            />
          ),
        },
        secondaryEmail: {
          key: "Secondary Email",
          width: "1-of-3",
          smallWidth: "1-of-2",
          label: "Email",
          children: (
            <Textbox
              id={idModel?.secondaryEmail?.id}
              value={registrant?.secondary_contact?.email}
              onChange={getTextboxHandler("secondary_contact.email")}
            />
          ),
        },
        secondaryTitle: {
          key: "Secondary Title",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Title",
          children: (
            <Textbox
              id={idModel?.secondaryTitle?.id}
              value={registrant?.secondary_contact?.title}
              onChange={getTextboxHandler("secondary_contact.title")}
            />
          ),
        },
        secondaryPhone: {
          key: "Secondary Phone",
          optional: true,
          width: "1-of-3",
          smallWidth: "1-of-2",
          label: "Phone",
          margin: false,
          children: (
            <Textbox
              id={idModel?.secondaryPhone?.id}
              value={registrant?.secondary_contact?.phone_number}
              onChange={getTextboxHandler("secondary_contact.phone_number")}
            />
          ),
        },
        secondaryReceiveEmails: {
          key: "Receive Email Communications",
          width: "1-of-2",
          smallWidth: "1-of-1",
          children: (
            <Checkbox
              id={idModel?.secondaryReceiveEmails?.id}
              checked={registrant?.secondary_contact?.receive_emails}
              onChange={getRadioChangeHandler("secondary_contact.receive_emails")}
              label="Receive Email Communications"
              inline
            />
          ),
        },
        corporateName: {
          key: "Company Name",
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: "Company Name",
          children: (
            <Textbox
              id={idModel?.corporateName?.id}
              value={registrant.corporate_name}
              onChange={getTextboxHandler("corporate_name")}
            />
          ),
        },
        corporateType: {
          key: "Corporate Type",
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: "Corporate Type",
          children: (
            <Select
              id={idModel?.corporateType?.id}
              preset={SelectPreset.Autocomplete}
              value={registrant.corporate_type}
              options={corporateTypes}
              onChange={getSelectChangeHandler("corporate_type")}
              disabled={isApproved.current}
            />
          ),
        },
        corporateExchange: {
          key: "Exchange",
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: "Exchange",
          children: (
            <Textbox
              id={idModel?.corporateExchange?.id}
              value={registrant?.exchange}
              disabled={isCorporatePrivate}
              onChange={getTextboxHandler("exchange")}
            />
          ),
        },
        corporateTicker: {
          key: "Ticker",
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: "Ticker",
          children: (
            <Textbox
              id={idModel?.corporateTicker?.id}
              value={registrant?.ticker_symbol}
              disabled={isCorporatePrivate}
              onChange={getTextboxHandler("ticker_symbol")}
            />
          ),
        },
        corporateLogo: {
          key: "Main Logo",
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: "Main Logo",
          children: (
            <FileUploader
              id={idModel?.corporateLogo.id}
              dropzoneProps={{ accept: ["image/*"] }}
              fileApi={handleFileUpload}
              fileUrl={registrant?.logo_image}
              onChange={getTextboxHandler("logo_image")}
              showPreview={true}
            />
          ),
        },
        secondaryLogo: {
          key: "Secondary Logo",
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: "Secondary Logo",
          children: (
            <FileUploader
              id={idModel?.corporateSecondaryLogo.id}
              dropzoneProps={{ accept: ["image/*"] }}
              fileApi={handleFileUpload}
              fileUrl={registrant?.secondary_logo_image}
              onChange={getTextboxHandler("secondary_logo_image")}
              showPreview={true}
            />
          ),
        },
        corporateDescription: {
          key: "Corporate Description",
          width: "3-of-5",
          smallWidth: "1-of-1",
          label: "Description",
          children: (
            <Textarea
              id={idModel?.corporateDescription?.id}
              value={registrant?.description}
              onChange={getTextboxHandler("description")}
              rows={8}
            />
          ),
        },
        corporateWebsite: {
          key: "Corporate Website",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "Website",
          children: (
            <Textbox id={idModel?.corporateWebsite?.id} value={registrant?.url} onChange={getTextboxHandler("url")} />
          ),
        },
        corporateAttendees: {
          key: "Attendees",
          width: "1-of-1",
          required: true,
          label: isApproved.current ? (
            "Attendees"
          ) : (
            <>
              Attendees (
              <Anchor id={idModel?.addAttendee?.id} theme={AnchorTheme.Rain} onClick={handleCorporateAttendeeCreateNew}>
                Create New
              </Anchor>
              )
            </>
          ),
          children: !isApproved.current ? (
            <Chips
              id={idModel?.corporateAttendees.id}
              items={mappedChips}
              onClick={handleCorporateAttendeeEdit}
              onRemove={handleCorporateAttendeeRemove}
            />
          ) : (
            <Chips id={idModel?.corporateAttendees.id} items={mappedChips} onRemove={null} />
          ),
        },
        corporatePrimaryContact: {
          key: "Primary Contact",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Primary Contact",
          children: (
            <Select
              id={idModel?.corporatePrimaryContact?.id}
              preset={SelectPreset.Autocomplete}
              isClearable
              valueKey="email"
              labelKey="display_name"
              value={primaryContact}
              options={registrant?.corporate_attendees}
              onChange={handlePrimaryContactChange}
            />
          ),
        },
        hostMeetings: {
          key: "Host Small Meetings",
          width: "1-of-2",
          smallWidth: "1-of-1",
          label: "Host Small Meetings",
          children: (
            <Checkbox
              id={idModel?.hostMeetings.id}
              checked={registrant?.host_small_meetings}
              onChange={getRadioChangeHandler("host_small_meetings")}
            />
          ),
        },
        hostPresentations: {
          key: "Host Presentation",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Host Presentation",
          children: (
            <Checkbox
              id={idModel?.hostPresentation.id}
              checked={registrant?.host_presentation}
              onChange={getRadioChangeHandler("host_presentation")}
            />
          ),
        },
        guestAttendeeType: {
          key: "Guest Attendee Type",
          width: "1-of-3",
          smallWidth: "1-of-1",
          label: "Attendee Type",
          children: (
            <Select
              id={idModel?.guestAttendeeType?.id}
              value={registrant?.guest_attendee_type}
              options={guestAttendeeTypeOptions}
              preset={SelectPreset.Autocomplete}
              isClearable
              onChange={handleGuestAttendeeTypeChange}
            />
          ),
        },
      };
      // #endregion

      if (showHostPresentations) {
        fields.hostPresentations = {
          key: "Host Presentation",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Host Presentation",
          children: (
            <Checkbox
              id={idModel?.hostPresentation.id}
              checked={registrant?.host_presentation}
              onChange={getRadioChangeHandler("host_presentation")}
            />
          ),
        };
      }

      const attendeeType = convertStringToEnum(AttendeeType, type);

      switch (attendeeType) {
        case AttendeeType.Investor:
          let investorFields = [
            fields.firstName,
            fields.lastName,
            fields.email,
            fields.title,
            fields.company,
            fields.phone,
            fields.representative,
            fields.investorType,
            fields.secondaryContact,
          ];
          if (!!registrant?.has_secondary_contact) {
            investorFields = investorFields.concat([
              fields.secondaryFirstName,
              fields.secondaryLastName,
              fields.secondaryEmail,
              fields.secondaryTitle,
              fields.secondaryPhone,
              fields.secondaryReceiveEmails,
            ]);
          }
          return investorFields;
        case AttendeeType.Corporate:
          let corporateFields = [
            fields.corporateName,
            fields.corporateType,
            fields.corporateExchange,
            fields.corporateTicker,
            fields.corporateLogo,
            fields.secondaryLogo,
            fields.corporateDescription,
            fields.corporateWebsite,
            fields.hostMeetings,
            fields.representative,
            fields.hostPresentations,
          ];

          corporateFields = corporateFields.concat([fields.corporateAttendees, fields.corporatePrimaryContact]);
          return corporateFields;
        case AttendeeType.Guest:
          return [
            fields.firstName,
            fields.lastName,
            fields.email,
            fields.title,
            fields.company,
            fields.representative,
            fields.phone,
            fields.guestAttendeeType,
          ];
        default:
          return [fields.firstName, fields.lastName, fields.email, fields.title, fields.phone, fields.company];
      }
    },
    [
      registrant,
      idModel,
      getTextboxHandler,
      companies,
      handleCompanyChange,
      investorTypeOptions,
      getSelectChangeHandler,
      getRadioChangeHandler,
      corporateTypes,
      isCorporatePrivate,
      handleFileUpload,
      handleCorporateAttendeeCreateNew,
      mappedChips,
      handleCorporateAttendeeEdit,
      handleCorporateAttendeeRemove,
      primaryContact,
      handlePrimaryContactChange,
      guestAttendeeTypeOptions,
      handleGuestAttendeeTypeChange,
      showHostPresentations,
    ]
  );
  // #endregion

  // #region Renders
  const renderRequests = useMemo((): JSX.Element => {
    if (isEmpty(registrant?.meeting_requests)) return;

    return (
      <GridColumn width="1-of-2" smallWidth="1-of-1">
        <Text preset={TextPreset.Paragraph}>Meeting Requests</Text>
        {registrant.meeting_requests.map((request) => {
          return (
            <div key={request._corporate_profile} className={"registrant-form_block"}>
              <div className={"registrant-form_block-section"}>
                <Text preset={TextPreset.Paragraph}>
                  <strong>{getCorporateName(request._corporate_profile, corporateProfiles)}</strong>
                </Text>
              </div>
              <div className={"registrant-form_block-section"}>
                <Text preset={TextPreset.Paragraph}>{`${request.meeting_type} | ${request.interest_level}`}</Text>
              </div>
            </div>
          );
        })}
      </GridColumn>
    );
  }, [registrant?.meeting_requests, corporateProfiles]);

  const renderAvailability = useMemo((): JSX.Element => {
    if (
      isPresentationOnly ||
      (!isCorporate && isEmpty(registrant?.availability)) ||
      isEmpty(nonConflictingSlots) ||
      !AttendeeTypesWithAvailability.includes(registrant?.attendee_type)
    )
      return;

    return isCorporate ? (
      <GridColumn width="1-of-1" margin={false}>
        <Text preset={TextPreset.Paragraph}>Availabilities</Text>
        <AvailabilityForm
          disabled={isApproved.current}
          days={schedulerDays}
          timeZone={timeZone}
          timeslots={timeslots}
          handleTimeslotChange={handleTimeslotChange}
        />
      </GridColumn>
    ) : (
      <GridColumn width="1-of-2" smallWidth="1-of-1">
        <Text preset={TextPreset.Paragraph}>Availabilities</Text>
        <div className="registrant-form_block">
          <div className="registrant-form_block-section">
            <Text preset={TextPreset.Paragraph}>
              <strong>Date</strong>
            </Text>
          </div>
          <div className="registrant-form_block-section">
            <Text preset={TextPreset.Paragraph}>
              <strong>Time</strong>
            </Text>
          </div>
        </div>
        {registrant?.availability.map((x, i) => {
          if (isEmpty(x.start_time) || isEmpty(x.end_time)) return;

          const startTime = moment(x.start_time).tz(conference?.time_zone);
          const endTime = moment(x.end_time).tz(conference?.time_zone);

          return (
            <div key={`availability-item-${i}`} className="registrant-form_block">
              <div className="registrant-form_block-section">{formatDateRange(startTime, endTime)}</div>
              <div className="registrant-form_block-section">
                {startTime.format(TimeFormat.Standard)} - {endTime.format(TimeFormat.TimezoneStandard)}
              </div>
            </div>
          );
        })}
      </GridColumn>
    );
  }, [
    isPresentationOnly,
    isCorporate,
    registrant?.availability,
    registrant?.attendee_type,
    nonConflictingSlots,
    schedulerDays,
    timeZone,
    timeslots,
    handleTimeslotChange,
    conference?.time_zone,
  ]);

  const renderCustomQuestions = useMemo((): JSX.Element => {
    if (isEmpty(currentQuestionGroup)) return null;

    let questions = currentQuestionGroup._questions;

    if (registrant?.attendee_type === AttendeeType.Corporate) {
      questions = (currentQuestionGroup._questions || []).filter((x) => !x.is_individual_corporate);
    }

    return (
      <GridColumn width="1-of-1">
        <Text
          id={idModel?.questionsHeader.id}
          className={RegistrantFormClassName.QuestionsHeader}
          preset={TextPreset.Subheader}
        >
          {currentQuestionGroup?.title}
        </Text>
        <QuestionsForm
          id={idModel?.questionsForm?.id}
          questions={questions || []}
          answers={registrant?.custom_question_answers}
          setFormErrors={setFormErrors}
          onChange={handleCustomAnswerChange}
        />
      </GridColumn>
    );
  }, [
    currentQuestionGroup,
    registrant?.attendee_type,
    registrant?.custom_question_answers,
    idModel?.questionsHeader.id,
    idModel?.questionsForm?.id,
    setFormErrors,
    handleCustomAnswerChange,
  ]);

  // #endregion

  return (
    <div id={idModel?.id} className={baseClassName}>
      <Form
        fields={[
          {
            key: "Message",
            width: "1-of-1",
            label: "",
            children: <span>Status changes will send an email to registrant.</span>,
          },
          {
            key: "Type",
            width: "1-of-2",
            label: "Type",
            children: (
              <Select
                id={idModel?.type?.id}
                preset={SelectPreset.Autocomplete}
                value={registrantType}
                options={attendeeTypeOptions}
                onChange={handleTypeChange}
              />
            ),
          },
          {
            key: "Status",
            width: "1-of-2",
            label: "Status",
            children: (
              <Select
                id={idModel?.status?.id}
                preset={SelectPreset.Autocomplete}
                value={registrant?.status}
                options={statusOptions}
                onChange={handleStatusChange}
                disabled={isApproved.current}
              />
            ),
          },
        ]}
      />
      <Form key="registrant-form" fields={getFields(registrantType)} />
      <Grid>
        {renderRequests}
        {renderAvailability}
        {renderCustomQuestions}
      </Grid>
      <CorporateAttendeeModal
        id={idModel?.attendeeModal?.id}
        conference={conference}
        edit={corporateAttendeeEdit}
        attendee={currentCorporateAttendee}
        visible={attendeeModalVisible}
        questionGroup={currentQuestionGroup}
        onAttendeeUpdate={handleCorporateAttendeeUpdate}
        onAttendeeCreate={handleCorporateAttendeeSave}
        onCloseRequest={handleAttendeeModalClose}
      />
    </div>
  );
};

export default memo(RegistrantForm);
