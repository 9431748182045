import { Badge, getClassName, isNullOrWhiteSpace, Tooltip } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { InfoBubbleClassName, InfoBubbleIdModel, InfoBubbleProps } from "./infoBubble.definition";

function InfoBubble(props: InfoBubbleProps): JSX.Element {
  const { id, children, className, badgeProps, tooltipProps } = props;

  const idModel = useMemo(() => new InfoBubbleIdModel(id), [id]);

  const baseClassName = useMemo(
    () => getClassName(InfoBubbleClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  return (
    <span id={idModel.id} className={baseClassName}>
      {children} <Badge id={idModel?.badge?.id} className={InfoBubbleClassName.Badge} {...badgeProps} />
      <Tooltip id={idModel?.tooltip?.id} className={InfoBubbleClassName.Tooltip} {...tooltipProps} />
    </span>
  );
}

export default memo(InfoBubble);
