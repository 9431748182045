import {
  Button,
  ButtonTheme,
  ErrorModel,
  isEmpty,
  isNullOrWhiteSpace,
  NotificationService,
  TableProps,
  ToolbarTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import type { GetQuickFilterTextParams, ICellRendererParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import AttendeeForm from "../../../../../../components/attendee/form/attendeeForm.component";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { useCalendarExport, useExport } from "../../../../../../hooks";
import { usePublicLink } from "../../../../../../hooks/public/usePublicLink/usePublicLink.hook";
import { useAttendeeAnswer as useAttendeeAnswerHook } from "../../../../../../hooks/useAttendeeAnswer/useAttendeeAnswer.hook";
import { TableSelectFilter } from "../../../../../../hooks/useTableFilter/useTableFilter.definition";
import { AuthType } from "../../../../../../services/api/api.definition";
import { AttendeeType, AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import { CalendarExportType } from "../../../../../../services/calendarExport/calendarExport.model";
import { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../../../../../services/meeting/meeting.model";
import { Presentation } from "../../../../../../services/presentation/presentation.model";
import {
  byString,
  getAgenda,
  getAttendeeTypeRequiredKeys,
  getOptions,
  getPublicLinkData,
  isAttendeeSpeaker,
  modifyAnswerData,
} from "../../../../../../utils";
import { AttendeesClassName, AttendeeEditClassName, AttendeesIdModel } from "./attendees.definition";
import type { AttendeesProps } from "./attendees.definition";
import AttendeePasswordModal from "./components/attendeePasswordModal/attendeePasswordModal.component";
import ExportAgendaButton from "./components/exportAgenda/exportAgenda.component";

const Attendees = (props: AttendeesProps): JSX.Element => {
  const {
    id,
    codes,
    company,
    conference,
    corporateProfiles,
    useAttendees,
    useMeetings,
    usePresentations,
    useQuestionGroups,
    user,
    isSystemAdmin,
    suppressColumnVirtualisation, // Only used for unit tests
  } = props;

  const idModel = useMemo(() => new AttendeesIdModel(id), [id]);
  const [formErrors, setFormErrors] = useState<Record<string, ErrorModel>>({});
  const [attendeePassword, setAttendeePassword] = useState<string>("");

  const notificationService = useMemo(() => new NotificationService(), []);

  const { generatePdf } = useExport({
    showNotifications: true,
    authType: AuthType.Protected,
    company,
  });

  const { getId } = usePublicLink();

  const { getCalendar } = useCalendarExport({
    showNotifications: true,
  });

  const {
    current: currentAttendee,
    loading: attendeesLoading,
    setCurrent,
    items: attendees,
    fetchConferenceAttendees,
    fetchAttendeePasswordById,
  } = useAttendees;

  const useAttendeeAnswer = useAttendeeAnswerHook({
    attendeeId: currentAttendee?._id,
  });
  const { items: presentations, loading: presentationsLoading } = usePresentations;
  const { items: meetings, loading: meetingsLoading } = useMeetings;
  const { items: questionGroups } = useQuestionGroups;

  const { loading: answersLoading, items: attendeeAnswers } = useAttendeeAnswer;

  const [modalVisible, handleModalOpen, handleModalClose] = useVisibility();

  useEffect(() => {
    fetchConferenceAttendees();
  }, [fetchConferenceAttendees]);

  const companies = useMemo<string[]>(() => getOptions("company_name", attendees, true), [attendees]);
  const types = useMemo<string[]>(() => getOptions("type", attendees), [attendees]);

  const loading = useMemo(
    () => attendeesLoading || meetingsLoading || presentationsLoading || answersLoading,
    [attendeesLoading, meetingsLoading, presentationsLoading, answersLoading]
  );

  const requiredEntityKeys = useMemo(() => getAttendeeTypeRequiredKeys(currentAttendee?.type), [currentAttendee?.type]);

  const modifyAnswerPayload = (attendeeData: AttendeeViewModel) => {
    const targetQuestionGroup = questionGroups?.find((qG) => qG?.attendee_types.includes(currentAttendee?.type));
    const updatedAnswerObject = modifyAnswerData(attendeeData?.custom_question_answers, targetQuestionGroup?._questions);
    return new AttendeeViewModel({ ...attendeeData, custom_question_answers: updatedAnswerObject });
  };

  const selectFilters: TableSelectFilter[] = [
    {
      id: idModel.company.id,
      key: "company_name",
      placeholder: "Filter by Company",
      options: companies,
    },
    {
      id: idModel.type.id,
      key: "type",
      placeholder: "Filter by Type",
      options: types,
    },
  ];

  const tableProps: Partial<TableProps> = {
    columnDefs: [
      {
        field: "first_name",
        headerName: "Name",
        minWidth: 280,
        flex: 1,
        cellRenderer: "nameCellRenderer",
        getQuickFilterText: renderName,
      },
      {
        field: "company_name",
        headerName: "Company",
        minWidth: 180,
        flex: 1,
        cellRenderer: "companyCellRenderer",
        getQuickFilterText: renderCompany,
        comparator: byString,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 160,
        flex: 1,
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 125,
        maxWidth: 125,
      },
      {
        field: "_id",
        headerName: "Export Agenda",
        sortable: false,
        minWidth: 150,
        maxWidth: 150,
        cellRenderer: "exportCellRenderer",
      },
    ],
    frameworkComponents: {
      nameCellRenderer: renderName,
      companyCellRenderer: renderCompany,
      exportCellRenderer: renderExportCell,
    },
    suppressColumnVirtualisation: !!suppressColumnVirtualisation,
  };

  const editForm = (
    <div className={AttendeeEditClassName.Base}>
      <AttendeeForm
        id={idModel.form?.id}
        conference={conference}
        attendee={currentAttendee}
        companies={companies}
        codes={codes}
        loading={attendeesLoading}
        questionGroups={questionGroups}
        corporateProfiles={corporateProfiles}
        attendeeAnswers={attendeeAnswers}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
        onAttendeeUpdate={handleAttendeeUpdate}
        onGeneratePassword={handleGeneratePassword}
        isSystemAdmin={isSystemAdmin}
      />
    </div>
  );

  const mapPublicLinkIds = useCallback(
    async (sessions: { userMeetings: Meeting[]; userPresentations: Presentation[] }, user: AttendeeViewModel) => {
      const linkIds = await getId({
        data: getPublicLinkData([...sessions.userPresentations, ...sessions.userMeetings], user),
      }).then((result) => result);

      sessions.userPresentations = (sessions.userPresentations || []).map((x) => {
        return new Presentation({ ...x, linkId: linkIds?.[user._id]?.[x._id] });
      });

      sessions.userMeetings = (sessions.userMeetings || []).map((x) => {
        return new Meeting({ ...x, linkId: linkIds?.[user._id]?.[x._id] });
      });
    },
    [getId]
  );

  const getUserMeetingsAndPresentations = useCallback(
    (id: AttendeeViewModel["_id"]): { userMeetings: Meeting[]; userPresentations: Presentation[] } => {
      const userMeetings = meetings.reduce((meetings, m) => {
        return m._attendee.some((x) => x._id === id) ? meetings.concat(m) : meetings;
      }, [] as Meeting[]);

      const userPresentations = presentations.reduce((presentations, p) => {
        return p._attendee.some((x) => x._id === id) || isAttendeeSpeaker(id, p._speaker) || !!p.add_to_all
          ? presentations.concat(p)
          : presentations;
      }, [] as Presentation[]);

      return {
        userMeetings,
        userPresentations,
      };
    },
    [meetings, presentations]
  );

  const beforeSaveCallback = useCallback(
    (attendeeData: AttendeeViewModel) => {
      const corporatesWithMissingFields = attendeeData.meeting_requests?.reduce((acc, req) => {
        if (req._corporate_profile && (!req.interest_level || !req.meeting_type)) {
          acc.push(corporateProfiles?.find((profile) => profile?._id === req._corporate_profile).name);
        }

        return acc;
      }, []);

      if (!isEmpty(corporatesWithMissingFields)) {
        notificationService.error(
          `Both meeting type and interest level are required: ${corporatesWithMissingFields.join(", ")}`
        );
      }
    },
    [corporateProfiles, notificationService]
  );

  function handleAttendeeUpdate(data: Partial<AttendeeViewModel>) {
    setCurrent(new AttendeeViewModel({ ...currentAttendee, ...data }));
  }

  async function handleGeneratePassword() {
    const newAttendeePassword = await fetchAttendeePasswordById(currentAttendee._id);

    if (newAttendeePassword) {
      setCurrent(
        new AttendeeViewModel({
          ...currentAttendee,
          last_generated_password_at: moment(newAttendeePassword.date).tz(conference?.time_zone),
        })
      );
      setAttendeePassword(newAttendeePassword.password);
      handleModalOpen();
    }
  }

  function renderName(params: GetQuickFilterTextParams): string {
    const attendee = new AttendeeViewModel(params?.data as AttendeeViewModel);
    const displayName = attendee?.display_name;
    return isNullOrWhiteSpace(displayName) ? "—" : displayName;
  }

  function renderCompany(params: GetQuickFilterTextParams): string {
    const attendee = new AttendeeViewModel(params?.data as AttendeeViewModel);
    const profileId = new CorporateProfile(attendee?._corporate_profile)?._id;
    const profile = corporateProfiles.find((x) => profileId === x._id);
    const company = attendee.type === AttendeeType.Corporate ? profile?.name : attendee?.company;

    return isNullOrWhiteSpace(company) ? "—" : company;
  }

  function renderExportCell(params: ICellRendererParams): string | JSX.Element {
    const user: AttendeeViewModel = params.data;
    const _id = params?.value as AttendeeViewModel["_id"];
    if (typeof _id === "string" ? isNullOrWhiteSpace(_id) : isEmpty(_id)) return "—";

    const sessions = getUserMeetingsAndPresentations(_id);

    async function handleExportPDFClick() {
      if (!conference?.requireAuthenticatedMeetingLink) {
        await mapPublicLinkIds(sessions, user);
      }

      generatePdf(
        user,
        attendees,
        conference,
        sessions?.userMeetings,
        sessions?.userPresentations,
        `${user.display_name}${isNullOrWhiteSpace(user.company_name) ? "" : `_${user.company_name}`}_`
      );
    }

    async function handleExportIcsClick() {
      if (!conference?.requireAuthenticatedMeetingLink) {
        await mapPublicLinkIds(sessions, user);
      }
      const agenda = getAgenda(sessions?.userMeetings, sessions?.userPresentations, attendees, user, conference, company);
      getCalendar(CalendarExportType.Ics, conference, agenda, user);
    }

    return (
      <>
        <Button
          id={idModel.export?.id}
          className={AttendeesClassName.PDFExportButton}
          theme={ButtonTheme.Rain}
          label={"PDF"}
          onClick={handleExportPDFClick}
        />
        <Button
          id={idModel.export?.id}
          className={AttendeesClassName.ICSExportButton}
          theme={ButtonTheme.Rain}
          label={"ICS"}
          onClick={handleExportIcsClick}
        />
      </>
    );
  }

  return (
    <div id={idModel.id} className={AttendeesClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.Attendee}
        selectFilters={selectFilters}
        useService={useAttendees}
        tableProps={tableProps}
        editForm={editForm}
        modalProps={{
          fullscreen: true,
          scrollable: true,
          focusOnProps: { autoFocus: false },
          loading: answersLoading && !isEmpty(attendeeAnswers),
        }}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        entityModel={AttendeeViewModel}
        icon="q4i-contact-2pt"
        saveRequestParams={{ _conference: conference }}
        requiredEntityKeys={requiredEntityKeys}
        requiredFormEntityKeys={[]}
        beforeSaveCallback={beforeSaveCallback}
        conference={conference}
        toolbarProps={{
          theme: ToolbarTheme.Q4Blue,
        }}
        exportButton={
          <ExportAgendaButton
            id={idModel?.export?.id}
            attendees={attendees}
            company={company}
            conference={conference}
            disabled={loading}
            meetings={meetings}
            presentations={presentations}
          />
        }
        user={user}
        modifyData={modifyAnswerPayload}
      />
      <AttendeePasswordModal
        id={idModel.passwordModal?.id}
        visible={modalVisible}
        onClose={handleModalClose}
        password={attendeePassword}
      />
    </div>
  );
};

export default memo(Attendees);
