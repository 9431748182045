import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Answer } from "../../../../../services/answer/answer.model";

export enum RenderAnswerClassName {
  Base = "render-answer",
  Individual = "render-answer--individual",
}

export interface RenderAnswerProps extends BaseComponentProps {
  answer: Answer;
  individual?: boolean;
}

export class RenderAnswerIdModel extends IdModelBase {
  title: string;
  answer: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = `${this.id}Title`;
    this.answer = `${this.id}Answer`;
  }
}
