import "./requestMeetings.component.scss";
import {
  ComponentSizeModifier,
  convertStringToEnum,
  Form,
  getClassName,
  Grid,
  GridColumn,
  isEmpty,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  SelectTheme,
  Text,
  TextPreset,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { MeetingInterestLevel, MeetingRequest } from "../../../../../../../services/admin/registrant/registrant.model";
import { AttendeeViewModel } from "../../../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../../../services/corporateProfile/corporateProfile.model";
import { MeetingLabel } from "../../../../../../../services/meeting/meeting.model";
import { getBackgroundImageUrl } from "../../../../../../../utils";
import {
  meetingInterestOptions,
  meetingTypeOptions,
  RequestMeetingsClassName,
  RequestMeetingsProps,
  validMeetingTypeOptions,
} from "./requestMeetings.definition";

const RequestMeetings = (props: RequestMeetingsProps): JSX.Element => {
  const { id, corporateProfiles, meetingRequests, meetingRequestsObject, onSelect, onDeleteRequest, disabled, sectionRef } =
    props;

  const renderCorporateInfo = useCallback((profile: CorporateProfile): JSX.Element => {
    return (
      <GridColumn margin={false} width="4-of-12" smallWidth="4-of-12">
        <div className={RequestMeetingsClassName.CorporateInfo}>
          {!isNullOrWhiteSpace(profile.logo_image) && (
            <div
              className={RequestMeetingsClassName.CorporateLogo}
              style={{
                backgroundImage: isNullOrWhiteSpace(profile.logo_image) ? null : getBackgroundImageUrl(profile.logo_image),
              }}
            />
          )}
          <div>
            <h3 className={RequestMeetingsClassName.CorporateName}>{profile.name}</h3>
            {!isNullOrWhiteSpace(profile.ticker_symbol) && (
              <h4 className={RequestMeetingsClassName.CorporateTicker}>
                {profile.ticker_symbol} | {profile.exchange}
              </h4>
            )}
            {!isNullOrWhiteSpace(profile.industry) && (
              <h4 className={RequestMeetingsClassName.CorporateIndustry}>
                <strong>Industry:</strong> {profile.industry}
              </h4>
            )}
          </div>
        </div>
      </GridColumn>
    );
  }, []);

  const renderCorporateAttendees = useCallback((attendees: AttendeeViewModel[]): JSX.Element => {
    return (
      <GridColumn
        className={RequestMeetingsClassName.CorporateAttendees}
        margin={false}
        width={"4-of-12"}
        smallWidth={"4-of-12"}
      >
        {!isEmpty(attendees)
          ? attendees.map((x) => {
              const attendee = new AttendeeViewModel(x);
              return (
                <div key={attendee._id}>
                  <strong>{attendee.display_name}</strong>
                  {attendee.title && `, ${attendee.title}`}
                </div>
              );
            })
          : "TBD"}
      </GridColumn>
    );
  }, []);

  const renderMeetingInterestInputs = useCallback(
    (profileId: CorporateProfile["_id"]): JSX.Element => {
      // In case none is set from admin side, check if its a valid option, otherwise set the default
      const currentMeetingType = convertStringToEnum(
        validMeetingTypeOptions,
        meetingRequestsObject?.[profileId]?.meeting_type
      );
      const currentInterestLevel = convertStringToEnum(
        MeetingInterestLevel,
        meetingRequestsObject?.[profileId]?.interest_level
      );

      const defaultType = currentMeetingType ?? MeetingLabel.OneOnOne;
      const defaultInterest = currentInterestLevel ?? MeetingInterestLevel.High;
      return (
        <GridColumn
          margin={false}
          width="4-of-12"
          smallWidth="4-of-12"
          className={RequestMeetingsClassName.CorporateMeetingInterest}
        >
          <GridColumn margin={false} width="1-of-2" smallWidth="1-of-2">
            Meeting Type (Required)
          </GridColumn>
          <GridColumn margin={false} width="1-of-2" smallWidth="1-of-2">
            Interest Level (Required)
          </GridColumn>
          <GridColumn margin={false} width="1-of-1" smallWidth="1-of-1">
            <Form
              fields={[
                {
                  key: "meetingTypeField",
                  width: "1-of-2",
                  smallWidth: "1-of-2",
                  margin: false,
                  required: true,
                  children: (
                    <Select
                      key={`${profileId}_meetingType`}
                      className={RequestMeetingsClassName.MeetingType}
                      value={{
                        label: defaultType,
                        value: defaultType,
                      }}
                      onChange={onSelect(profileId, "meeting_type")}
                      options={meetingTypeOptions}
                      valueKey="value"
                      labelKey="label"
                      theme={SelectTheme.White}
                      size={ComponentSizeModifier.Small}
                      preset={SelectPreset.Dropdown}
                      placeholder="None"
                      disabled={disabled}
                    />
                  ),
                },
                {
                  key: "interestLevel",
                  width: "1-of-2",
                  smallWidth: "1-of-2",
                  margin: false,
                  required: true,
                  children: (
                    <Select
                      key={`${profileId}_interestLevel`}
                      className={RequestMeetingsClassName.InterestLevel}
                      value={{
                        label: defaultInterest,
                        value: defaultInterest,
                      }}
                      onChange={onSelect(profileId, "interest_level")}
                      options={meetingInterestOptions}
                      valueKey="value"
                      labelKey="label"
                      theme={SelectTheme.White}
                      size={ComponentSizeModifier.Small}
                      preset={SelectPreset.Dropdown}
                      placeholder="None"
                      disabled={disabled}
                    />
                  ),
                },
              ]}
            />
          </GridColumn>
        </GridColumn>
      );
    },
    [meetingRequestsObject, onSelect, disabled]
  );

  const renderDeleteButton = useCallback(
    (profileId: CorporateProfile["_id"]): JSX.Element => {
      return disabled ? null : (
        <div className={RequestMeetingsClassName.RequestDeleteButton} onClick={() => onDeleteRequest(profileId)}>
          <i className={"q4i-close-4pt"} />
        </div>
      );
    },
    [onDeleteRequest, disabled]
  );

  const renderRequestRows = useCallback(
    (meetingRequests: MeetingRequest[]): JSX.Element => {
      return (
        <div className={RequestMeetingsClassName.Corporate}>
          <Grid className={RequestMeetingsClassName.CorporateHeader}>
            <GridColumn width={"4-of-12"} smallWidth={"4-of-12"}>
              COMPANY
            </GridColumn>
            <GridColumn width={"4-of-12"} smallWidth={"4-of-12"}>
              ATTENDEES
            </GridColumn>
            <GridColumn width={"4-of-12"} smallWidth={"4-of-12"}>
              MEETING INTEREST
            </GridColumn>
          </Grid>
          {meetingRequests?.map((meetingRequest) => {
            const profile = corporateProfiles?.find((profile) => profile._id === meetingRequest._corporate_profile);
            return isEmpty(profile) ? null : (
              <Grid key={profile._id} className={RequestMeetingsClassName.CorporateRow}>
                {renderCorporateInfo(profile)}
                {renderCorporateAttendees(profile.attendees)}
                {renderMeetingInterestInputs(profile._id)}
                {renderDeleteButton(profile._id)}
              </Grid>
            );
          })}
        </div>
      );
    },
    [corporateProfiles, renderCorporateInfo, renderCorporateAttendees, renderMeetingInterestInputs, renderDeleteButton]
  );

  const requestedCorporatesListHeaderClassName = useMemo(
    () =>
      getClassName(RequestMeetingsClassName.RequestsTitle, [
        {
          condition: disabled,
          falseClassName: RequestMeetingsClassName.RequestsTitleMargin,
        },
      ]),
    [disabled]
  );

  return (
    <>
      {!isEmpty(meetingRequests) ? (
        <div id={id} className={RequestMeetingsClassName.Base}>
          <div ref={sectionRef}>
            <Text preset={TextPreset.Title} className={requestedCorporatesListHeaderClassName}>
              Requested Meetings
            </Text>
            {renderRequestRows(meetingRequests)}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(RequestMeetings);
