import "./csvUploadButton.component.scss";
import { Button, ButtonTheme, isEmpty } from "@q4/nimbus-ui";
import React, { ChangeEvent, memo, useRef } from "react";
import { CsvUploadButtonClassName, CsvUploadButtonProps } from "./csvUploadButton.definition";

const CsvUploadButton = (props: CsvUploadButtonProps): JSX.Element => {
  const { id, loading, onUpload } = props;

  const buttonRef = useRef<HTMLButtonElement>();
  const inputRef = useRef<HTMLInputElement>();

  function handleImportClick(): void {
    handleTriggerInput();
  }

  function handleTriggerInput(): void {
    inputRef.current?.click();
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event?.target;
    const files = target?.files;

    if (isEmpty(files)) return;

    onUpload && onUpload(files[0]);

    // clear the input
    event.target.value = null;
  }

  return (
    <div className={CsvUploadButtonClassName.Base}>
      <Button
        id={id}
        ref={buttonRef}
        className={CsvUploadButtonClassName.Button}
        theme={ButtonTheme.Citrus}
        label="Import CSV"
        loading={loading}
        onClick={handleImportClick}
      />
      <input className={CsvUploadButtonClassName.Input} type="file" onChange={handleFileChange} ref={inputRef} />
    </div>
  );
};

export default memo(CsvUploadButton);
