import { isEmpty } from "@q4/nimbus-ui";
import type { EntityModel } from "../../definitions/entity.definition";
import { ApiResponse } from "../api/api.definition";

export function mapApiResponse<T>(response: ApiResponse<T>, entityModel: EntityModel<T>): ApiResponse<T> {
  if (!response?.success || isEmpty(entityModel)) return response;

  return new ApiResponse({
    ...response,
    data: new entityModel(response?.data),
  });
}

export function mapArrayedApiResponse<T>(response: ApiResponse<T[]>, entityModel: EntityModel<T>): ApiResponse<T[]> {
  if (!response?.success || isEmpty(entityModel)) return response;

  return new ApiResponse({
    ...response,
    data: (response?.data ?? []).map((x) => new entityModel(x)),
  });
}
