import { FileUploaderIdModel, IdModelBase, isNullOrWhiteSpace, TextareaIdModel, TextboxIdModel } from "@q4/nimbus-ui";
import { JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import { QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";
import { RegistrationFormProps } from "../../definition/registrationForm.definition";

export enum CorporateAttendeesClassName {
  Base = "corporate-attendees",
}
export interface CorporateAttendeesProps extends RegistrationFormProps {
  questionGroup: Partial<QuestionGroup>;
}

export const corporateAttendeeErrorMessages = {
  corporate: {
    hostMeetings: "Must have at least one attendee participating in meetings",
  },
  attendee: {
    firstName: "First name is required",
    lastname: "Last name is required",
    title: "Title is required",
    email: "A valid email is required",
  },
  speaker: "Speaker Bio is required",
  primaryContact: {
    none: "Must designate an attendee as the primary contact",
    many: "Only one attendee can be designated as the primary contact",
  },
};

export const corporateAttendeeMessages = {
  attendee: {
    title: "Position",
    email: "Attendee’s Email (used to log in)",
    lastName: "Last Name",
    firstName: "First Name",
    isSpeaker: "Will this person be a presentation speaker?",
    isPrimary: "Will this person be the primary contact? (Receives all communications and can edit registration details)",
    phoneNumber: "Phone Number",
    isParticipant: "Will this person participate in meetings?",
    receiveEmails: "Include this attendee in all conference schedule emails?",
  },
  presentation: {
    topic: "Suggested Topic",
    title: "Presentation Details",
    shareable: "Presentation stream can be shared after the event",
    description: "Description",
  },
};

export class CorporateAttendeesIdModel extends IdModelBase {
  jsonForm: JsonFormIdModel;

  firstNameField: TextboxIdModel;
  lastNameField: TextboxIdModel;
  positionField: TextboxIdModel;
  phoneNumberField: TextboxIdModel;
  emailField: TextboxIdModel;

  speakerImage: FileUploaderIdModel;
  speakerBio: TextareaIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);

    this.firstNameField = new TextboxIdModel(`${this.id}FirstNameField`);
    this.lastNameField = new TextboxIdModel(`${this.id}LastNameField`);
    this.positionField = new TextboxIdModel(`${this.id}PositionField`);
    this.phoneNumberField = new TextboxIdModel(`${this.id}PhoneNumberField`);
    this.emailField = new TextboxIdModel(`${this.id}EmailField`);

    this.speakerImage = new FileUploaderIdModel(`${this.id}SpeakerImage`);
    this.speakerBio = new TextareaIdModel(`${this.id}SpeakerBio`);
  }
}
