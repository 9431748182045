import "./agenda.component.scss";
import {
  Anchor,
  AnchorTarget,
  Button,
  ButtonTheme,
  Collapsable,
  Ghostable,
  Grid,
  GridColumn,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Origin,
  PopoverMenu,
  PopoverMenuButtonProps,
  Spinner,
  Text,
  TextPreset,
  Toggle,
  ToggleTheme,
  Tooltip,
  useVisibility,
} from "@q4/nimbus-ui";
import { Dictionary, groupBy, upperCase } from "lodash";
import moment from "moment-timezone";
import React, { KeyboardEvent, memo, MouseEvent, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { LOCAL_STORAGE_ITINERARY_SHOW_PAST_SESSIONS, ItineraryDayFormat, DefaultTimeZone } from "../../../../../const";
import { DefaultInternalCompanyName } from "../../../../../definitions/agenda.definition";
import { DateFormat, DateTokenFormat, TimeFormat } from "../../../../../definitions/date.definition";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import { CalendarExportType } from "../../../../../services/calendarExport/calendarExport.model";
import { LobbyDefaults } from "../../../../../services/conference/conference.model";
import { CorporateProfile } from "../../../../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../../../../services/meeting/meeting.model";
import { Presentation, PresentationSessionType } from "../../../../../services/presentation/presentation.model";
import { SessionEventType, SessionVendor } from "../../../../../services/session/session.model";
import {
  formatEntityDate,
  formatMeetingAttendee,
  getAccessibilityKeyPressHandler,
  getAgenda,
  getCorporateMeetingAttendees,
  getInternalMeetingAttendees,
  getMeetingLabel,
  getPillText,
  groupAttendeesByCompany,
  htmlParse,
  isCorporate,
  isCorporateType,
  isInternal,
  mapEntities,
  sortEntitiesByDate,
} from "../../../../../utils";
import Section from "../../../../company/lobby/components/section/section.component";
import {
  SectionHeaderMargin,
  SectionInnerWidth,
  SectionTheme,
} from "../../../../company/lobby/components/section/section.definition";
import TimeZoneSelector from "../../../components/timeZoneSelector/timeZoneSelector.component";
import { ItineraryAnchors, ItineraryClassName } from "../../itinerary.definition";
import {
  AgendaClassName,
  AgendaGroupIdModel,
  AgendaIdModel,
  AgendaItemIdModel,
  AgendaPresentation,
  AgendaProps,
  AgendaSession,
} from "./agenda.definition";

function Agenda(props: AgendaProps): JSX.Element {
  const history = useHistory();

  const {
    id,
    user,
    company,
    conference,
    now,
    presentations,
    meetings,
    attendees,
    loading,
    exporting,
    showAgenda: showAgendaProp,
    usePresentationTheater,
    onTimeZoneChange,
    onExport,
    onRemove,
  } = props;
  const [visible, handleOpen, handleClose] = useVisibility();
  const buttonRef = useRef<HTMLButtonElement>();

  const {
    time_zone: conferenceTimeZone,
    _track: tracks,
    _speaker,
    _presentation: conferencePresentations,
    lobby,
    video_vendors: videoVendors,
  } = conference ?? {};

  const { time_zone: profileTimeZone } = user ?? {};

  const userTimeZone = useMemo(
    () => profileTimeZone ?? conferenceTimeZone ?? DefaultTimeZone,
    [conferenceTimeZone, profileTimeZone]
  );

  const idModel = useMemo(() => new AgendaIdModel(id), [id]);
  const [showPast, setShowPast] = useState(localStorage?.getItem(LOCAL_STORAGE_ITINERARY_SHOW_PAST_SESSIONS) === "true");

  const showAgenda = useMemo(() => showAgendaProp ?? !LobbyDefaults.agenda.disabled, [showAgendaProp]);
  const hasTracks = useMemo(() => !isEmpty(tracks), [tracks]);
  const hasSpeakers = useMemo(() => !isEmpty(_speaker), [_speaker]);

  const hasAgenda = useMemo(() => !isEmpty(meetings) || !isEmpty(presentations), [meetings, presentations]);

  const agendaAttendees = useMemo(() => (attendees || []).map((attendee) => new AttendeeViewModel(attendee)), [attendees]);

  const agendaLabels = useMemo(() => {
    return { title: lobby?.agenda?.title ?? LobbyDefaults.agenda.title, noItems: lobby?.agenda?.label };
  }, [lobby?.agenda?.label, lobby?.agenda?.title]);

  const agendaSessions = useMemo(
    () =>
      getAgenda(
        meetings,
        mapEntities<Presentation>(presentations, conferencePresentations),
        agendaAttendees,
        user,
        conference,
        company
      ),
    [agendaAttendees, company, conference, conferencePresentations, meetings, user, presentations]
  );

  const filteredAgenda = useMemo(getFilteredAgendaSessions, [agendaSessions, now, showPast]);
  const agendaDateGroups = useMemo(getAgendaGroups, [userTimeZone, filteredAgenda]);
  const noFilteredAgenda = useMemo(() => isEmpty(filteredAgenda), [filteredAgenda]);

  const isUserCorporate = useMemo(() => isCorporate(user), [user]);
  const isUserInternal = useMemo(() => isInternal(user), [user]);
  const conferenceCompany = conference._company ? conference._company.name : DefaultInternalCompanyName;

  const popoverOptions = useMemo((): PopoverMenuButtonProps[] => {
    function getExportTypeHandler(type: CalendarExportType): () => void {
      return function (): void {
        onExport && onExport(type);
      };
    }
    return [
      {
        id: idModel.exportList.getId(0),
        key: `export-select_option--${CalendarExportType.Pdf}`,
        className: AgendaClassName.ExportButton,
        theme: ButtonTheme.Transparent,
        label: CalendarExportType.Pdf,
        onClick: getExportTypeHandler(CalendarExportType.Pdf),
      },
      {
        id: idModel.exportList.getId(1),
        key: `export-select_option--${CalendarExportType.Ics}`,
        className: AgendaClassName.ExportButton,
        theme: ButtonTheme.Transparent,
        label: CalendarExportType.Ics,
        onClick: getExportTypeHandler(CalendarExportType.Ics),
      },
    ];
  }, [idModel.exportList, onExport]);
  // #region Agenda Handlers

  function handleRemove(_id: AgendaSession["_id"]): void {
    if (isNullOrWhiteSpace(_id) || typeof onRemove !== "function") return;
    onRemove(_id);
  }

  function handlePastAgendaSwitchChange(value: boolean): void {
    setShowPast(value);
    localStorage.setItem(LOCAL_STORAGE_ITINERARY_SHOW_PAST_SESSIONS, value ? "true" : "false");
  }
  // #endregion

  // #region Agenda Helpers
  function getFilteredAgendaSessions(): AgendaSession[] {
    if (isEmpty(agendaSessions) || isEmpty(now)) return [];

    const sortedAgenda = sortEntitiesByDate<AgendaSession>(agendaSessions, "start_date");

    return showPast ? sortedAgenda : sortedAgenda.filter((x) => x?.end_date?.isAfter(now));
  }

  function getAgendaGroups(): Record<string, AgendaSession[]> {
    if (isEmpty(filteredAgenda)) return {};

    return groupBy(filteredAgenda, (agenda: AgendaSession) =>
      formatEntityDate(agenda?.start_date?.tz(userTimeZone), DateFormat.Short)
    );
  }

  function getPlaceholderMessage(): JSX.Element {
    let message = "No agenda found.";

    if (!isNullOrWhiteSpace(agendaLabels.noItems)) {
      message = agendaLabels.noItems;
    }

    if (!showPast && hasAgenda) {
      message = "Looks like you have no upcoming agenda";
    }

    if (loading) {
      message = "Loading your agenda...";
    }

    return (
      <p id={idModel.message} className={ItineraryClassName.Description}>
        {message}
      </p>
    );
  }
  // #endregion

  // #region Render Functions
  function renderGroupList(): JSX.Element[] {
    if (isEmpty(agendaDateGroups)) return null;

    const agendaGroupKeys = Object.keys(agendaDateGroups);

    return agendaGroupKeys.map((agendaGroupKey, index): JSX.Element => {
      const agendaList = agendaDateGroups[agendaGroupKey];
      const itemIdBase = idModel.list.getId(index);
      const itemIdModel = new AgendaGroupIdModel(itemIdBase);

      return (
        <div id={itemIdModel?.id} key={`agenda_group--${agendaGroupKey}`} className={AgendaClassName.Group}>
          <div id={itemIdModel?.date} className={`${AgendaClassName.Date} ${ItineraryClassName.Subheading}`}>
            {moment(agendaGroupKey, DateFormat.Short).format(ItineraryDayFormat)}
          </div>
          {renderAgendaList(itemIdModel, agendaList)}
        </div>
      );
    });
  }

  function renderAgendaList(agendaIdModel: AgendaGroupIdModel, sessions: AgendaSession[]): JSX.Element {
    if (isEmpty(sessions)) return null;
    return (
      <div className={AgendaClassName.List}>
        {sessions.map((session: AgendaSession, index: number): JSX.Element => {
          const { _id, start_date, end_date, labelOverride, Readonly, typeSession, isUserSpeaker, session_type } = session;

          const itemIdBase = agendaIdModel.list.getId(index);
          const itemIdModel = new AgendaItemIdModel(itemIdBase);

          const isPresentation = typeSession === SessionEventType.Presentation;
          const isBreakSession = isPresentation && session_type === PresentationSessionType.Break;
          const presentationType: Presentation["presentation_type"] =
            isPresentation && !isNullOrWhiteSpace(session.presentation_type) ? session.presentation_type : "";

          const meetingType = !isNullOrWhiteSpace(labelOverride)
            ? labelOverride
            : getMeetingLabel(
                new Meeting({
                  ...session,
                  _attendee: session?.attendees,
                })
              );

          const disabled = !end_date?.isAfter(now);
          const baseClassName =
            disabled || loading
              ? `${AgendaClassName.Item} ${AgendaClassName.ItemWithDisabledModifier}`
              : AgendaClassName.Item;

          function handleRemoveClick(event: MouseEvent | KeyboardEvent): void {
            event.stopPropagation();

            handleRemove(_id);
          }

          let allowRemovePresentation = true;
          if (isPresentation) {
            const presentation = session as AgendaPresentation;
            if (
              isBreakSession ||
              (presentation?.code || []).some((presentationCode) => (user?.code || []).includes(presentationCode)) ||
              presentation?.add_to_all
            ) {
              allowRemovePresentation = false;
            }
          }

          function renderSessionTypeLabel(): JSX.Element {
            if (isPresentation && isNullOrWhiteSpace(presentationType)) return null;
            const pillClassName =
              (!isNullOrWhiteSpace(presentationType) && presentationType.length > 15) ||
              (!isNullOrWhiteSpace(meetingType) && meetingType.length > 15)
                ? AgendaClassName.SessionTypeLong
                : AgendaClassName.SessionType;

            const pillText = getPillText(isPresentation, isBreakSession, meetingType, presentationType);

            return (
              <div id={itemIdModel?.label} className={pillClassName}>
                <span>{pillText}</span>
              </div>
            );
          }

          return (
            <div key={_id} id={itemIdModel?.id} className={baseClassName}>
              <div id={itemIdModel?.time} className={`${AgendaClassName.Cell} ${AgendaClassName.Time}`}>
                <span>{start_date?.tz(userTimeZone).format(TimeFormat.Picker)}</span>
                <span>{end_date?.tz(userTimeZone).format(TimeFormat.Picker)}</span>
                <div>{start_date?.tz(userTimeZone).format(DateTokenFormat.TimeZone)}</div>
                {!isNullOrWhiteSpace(session.roomLocation) && (
                  <div id={itemIdModel?.room} className={AgendaClassName.Room}>
                    <span>{session.roomLocation}</span>
                  </div>
                )}
                {renderSessionTypeLabel()}
              </div>
              <div id={itemIdModel?.details} className={`${AgendaClassName.Cell} ${AgendaClassName.SessionDetail}`}>
                <div id={itemIdModel?.title} className={AgendaClassName.Title}>
                  {renderTitleInfo(session)}
                </div>
                {hasAgenda && !disabled && (
                  <div id={itemIdModel?.dialInformation} className={AgendaClassName.DialInformation}>
                    {renderSessionInfo(session, isPresentation, isBreakSession, presentationType)}
                  </div>
                )}
              </div>
              {hasTracks && !disabled && !Readonly && allowRemovePresentation && !isUserSpeaker && (
                <div
                  id={itemIdModel?.remove}
                  className={AgendaClassName.Remove}
                  tabIndex={0}
                  onClick={handleRemoveClick}
                  onKeyPress={getAccessibilityKeyPressHandler(handleRemoveClick)}
                >
                  <Tooltip label="Remove presentation from Personal Agenda" position={Origin.Top} />
                  <i className="ni-close-4pt" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  function renderSpeakerLink(presentation: AgendaPresentation, goUrl: string) {
    return (
      <Text customElementType="div" className={AgendaClassName.Vendor}>
        <Text preset={TextPreset.Paragraph}>
          <Anchor url={encodeURI(goUrl)} target={AnchorTarget.Blank}>
            Join As Speaker {">"}
          </Anchor>
        </Text>
      </Text>
    );
  }

  function renderSessionInfo(
    session: AgendaSession,
    isPresentation: boolean,
    isBreakSession: boolean,
    presentationType: string
  ): JSX.Element {
    const {
      _id: id,
      Readonly: readonly,
      vendor,
      vendor_override: vendorOverride,
      GoUrl: goUrl,
      Route: route,
      isUserSpeaker,
    } = session;

    if (isNullOrWhiteSpace(goUrl) || isBreakSession) return;

    if (isUserSpeaker && isPresentation) {
      const presentation = session as AgendaPresentation;
      return renderSpeakerLink(presentation, goUrl);
    }

    const joinPresentationLabel = isNullOrWhiteSpace(presentationType) ? "Join" : `Join ${presentationType}`;

    if (!isEmpty(vendorOverride)) {
      const vendorId = idModel.vendor.getId(id);
      const vendorMeetingId = idModel.vendorMeetingCode.getId(id);
      const vendorPasscodeId = idModel.vendorPasscode.getId(id);
      const vendorPhonePasscodeId = idModel.vendorPhonePasscode.getId(id);
      const vendorUrlId = idModel.vendorUrl.getId(id);

      return (
        <Text customElementType="div" id={vendorId} className={AgendaClassName.Vendor}>
          <Text preset={TextPreset.Paragraph}>
            <Anchor id={vendorUrlId} url={encodeURI(goUrl)} target={AnchorTarget.Blank}>
              {readonly ? "Join Meeting >" : joinPresentationLabel}
            </Anchor>
          </Text>
          <>
            {!isNullOrWhiteSpace(vendorOverride.meeting_id) && (
              <div>
                <span className={AgendaClassName.Label}>Meeting ID:</span>{" "}
                <Text preset={TextPreset.Base} id={vendorMeetingId}>
                  {vendorOverride.meeting_id}
                </Text>
              </div>
            )}
            {!isNullOrWhiteSpace(vendorOverride.meeting_password) && (
              <div>
                <span className={AgendaClassName.Label}>Passcode:</span>{" "}
                <Text preset={TextPreset.Base} id={vendorPasscodeId}>
                  {vendorOverride.meeting_password}
                </Text>
              </div>
            )}
            {!isNullOrWhiteSpace(vendorOverride.phone_password) && (
              <div>
                <span className={AgendaClassName.Label}>Passcode:</span>{" "}
                <Text preset={TextPreset.Base} id={vendorPhonePasscodeId}>
                  {vendorOverride.phone_password}
                </Text>
              </div>
            )}
          </>
        </Text>
      );
    }

    if (!readonly) {
      if (isUserSpeaker) {
        return (
          <Anchor url={goUrl} target={AnchorTarget.Blank}>
            Join as Speaker {">"}
          </Anchor>
        );
      }

      const handlePresentationSelect = (): void => {
        if (!!usePresentationTheater) {
          history.push(route);
        } else {
          window.open(goUrl, "_blank");
        }
      };

      return (
        <Anchor className={AgendaClassName.PresentationAnchor} onClick={handlePresentationSelect}>
          {joinPresentationLabel}
        </Anchor>
      );
    }

    if (isEmpty(vendor) && isEmpty(vendorOverride) && isNullOrWhiteSpace(goUrl)) return;

    if (isEmpty(vendor) && isEmpty(vendorOverride) && !isNullOrWhiteSpace(goUrl)) {
      const vendorUrlId = idModel.vendorUrl.getId(id);
      const vendorId = idModel.vendor.getId(id);

      return (
        <Text customElementType="div" id={vendorId} className={AgendaClassName.Vendor}>
          <Text preset={TextPreset.Paragraph}>
            <Anchor id={vendorUrlId} url={encodeURI(goUrl)} target={AnchorTarget.Blank}>
              {"Join Meeting >"}
            </Anchor>
          </Text>
        </Text>
      );
    }

    const isMultipleVendors = vendor.length > 1;
    return (
      <>
        {vendor.map((x: SessionVendor, i: number) => {
          if (isEmpty(x)) return;

          const url = i === 0 ? goUrl : x.url;
          const linkText = isMultipleVendors ? `Join via ${upperCase(x.vendor)}` : "Join Meeting";
          const hasMeetingId = !isNullOrWhiteSpace(x.vendor_id);
          const hasPasscode = !isNullOrWhiteSpace(x.password);

          const vendorId = idModel.vendor.getId(x.vendor_id);
          const vendorMeetingId = idModel.vendorMeetingCode.getId(x.vendor_id);
          const vendorPasscodeId = idModel.vendorPasscode.getId(x.vendor_id);
          const vendorUrlId = idModel.vendorUrl.getId(x.vendor_id);

          return (
            <Text customElementType="div" id={vendorId} key={vendorId ?? x.vendor} className={AgendaClassName.Vendor}>
              <Text preset={TextPreset.Paragraph}>
                <Anchor id={vendorUrlId} url={encodeURI(url)} target={AnchorTarget.Blank}>
                  {linkText} {">"}
                </Anchor>
              </Text>
              {(hasMeetingId || hasPasscode) && (
                <>
                  {hasMeetingId && (
                    <div>
                      <span className={AgendaClassName.Label}>Meeting ID:</span>{" "}
                      <Text preset={TextPreset.Base} id={vendorMeetingId}>
                        {x.vendor_id}
                      </Text>
                    </div>
                  )}
                  {hasPasscode && (
                    <div>
                      <span className={AgendaClassName.Label}>Passcode:</span>{" "}
                      <Text preset={TextPreset.Base} id={vendorPasscodeId}>
                        {x.password}
                      </Text>
                    </div>
                  )}
                </>
              )}
            </Text>
          );
        })}
      </>
    );
  }

  function renderDialInNumbers(sessionId: AgendaSession["_id"], vendorOverride: AgendaSession["vendor_override"]) {
    const dialInNumbers = vendorOverride?.dial_in_numbers;

    if (!dialInNumbers) return;

    return (
      <Text
        customElementType="div"
        id={idModel.vendorOverrideDialInNumbers.getId(sessionId)}
        className={AgendaClassName.VendorOverrideDialInNumbers}
      >
        <Text preset={TextPreset.Paragraph} className={AgendaClassName.Label}>
          Dial-in Numbers
        </Text>

        <Grid>
          {dialInNumbers.map((dialInNumber) => (
            <GridColumn width={"1-of-2"} className="agenda_dial-in_column" key={dialInNumber}>
              <Text>{dialInNumber}</Text>
            </GridColumn>
          ))}
        </Grid>
      </Text>
    );
  }

  function renderTitleInfo(agenda: AgendaSession): JSX.Element {
    const {
      vendor_override: vendorOverride,
      Readonly: readonly,
      Title: title,
      speakers,
      isUserSpeaker,
      speaker_notes: speakerNotes,
      attendees: participants,
      session_type,
      description,
    } = agenda;

    const dialInSubheading = renderDialInNumbers(id, vendorOverride);

    if (!readonly) {
      return (
        <div>
          <div className={ItineraryClassName.Subheading}>{title}</div>
          {session_type === PresentationSessionType.Break && !isNullOrWhiteSpace(description) && (
            <div className={ItineraryClassName.Inner}>
              <span className={AgendaClassName.Label}>Description: </span>
              <span className={AgendaClassName.Description}>{description}</span>
            </div>
          )}
          {!isEmpty(speakers) && (
            <div className={ItineraryClassName.Inner}>
              <span className={AgendaClassName.Label}>Presenters: </span>
              {(speakers || [])
                .map((x) => {
                  const speakerName = !isNullOrWhiteSpace(x.display_name) ? `${x.display_name}` : "";
                  const speakerTitle = !isNullOrWhiteSpace(x.title) ? `, ${x.title}` : "";

                  return `${speakerName}${speakerTitle}`;
                })
                .join(" | ")}
            </div>
          )}
          {dialInSubheading}
          {isUserSpeaker && !isNullOrWhiteSpace(speakerNotes) && (
            <div>
              <strong>Speaker Notes:</strong> {htmlParse(speakerNotes)}
            </div>
          )}
        </div>
      );
    }

    if (isInternal(user)) {
      const internalCorporateSubheading = renderCorporateSubheading(participants, user, readonly);
      const internalIvestorSubheading = renderInvestorSubheading(participants, readonly);

      return (
        <>
          {internalCorporateSubheading}
          {internalIvestorSubheading}
          {dialInSubheading}
        </>
      );
    }

    const corporateSubheading = renderCorporateSubheading(participants, user, readonly);
    if (!isEmpty(corporateSubheading))
      return (
        <>
          {corporateSubheading}
          {dialInSubheading}
        </>
      );

    const investorSubheading = renderInvestorSubheading(participants, readonly);

    return (
      <>
        {investorSubheading}
        {dialInSubheading}
      </>
    );
  }

  function renderCorporateSubheading(
    participants: AttendeeViewModel[],
    userProfile: AttendeeViewModel,
    readonly: AgendaSession["Readonly"]
  ) {
    if ((!isUserCorporate && !isUserInternal) || !readonly) return null;

    const filteredCompanies = groupAttendeesByCompany(conferenceCompany, userProfile?.company_name, participants);

    return renderCompanies(filteredCompanies);
  }

  function renderCompany(
    companyName: string,
    meetingId: string,
    companyAttendees: AttendeeViewModel[] | string
  ): JSX.Element {
    if (!companyName || !companyAttendees) return;

    return (
      <Text customElementType="div" id={meetingId} key={meetingId} className={AgendaClassName.Session}>
        <div key={companyName} className={AgendaClassName.Session}>
          <div className={ItineraryClassName.Subheading}>{companyName}</div>
          <div className={ItineraryClassName.Inner}>
            <span className={AgendaClassName.Label}>Participants: </span>
            {typeof companyAttendees === "string"
              ? companyAttendees
              : companyAttendees.map((x) => formatMeetingAttendee(x)).join(" | ")}
          </div>
        </div>
      </Text>
    );
  }

  function renderCompanies(groupedAttendeesByCompany: Dictionary<AttendeeViewModel[]>): JSX.Element {
    const meetingInternalParticipantsId = idModel.meeting.getId(DefaultInternalCompanyName);
    const companies = Object.keys(groupedAttendeesByCompany).map((companyName: string) => {
      if (companyName !== conferenceCompany) {
        const meetingId = idModel.meeting.getId(companyName);
        return renderCompany(companyName, meetingId, groupedAttendeesByCompany[companyName]);
      }
    });
    const internalCompany =
      !isInternal(user) &&
      !isNil(groupedAttendeesByCompany) &&
      !isEmpty(groupedAttendeesByCompany[conferenceCompany]) &&
      renderCompany(conferenceCompany, meetingInternalParticipantsId, groupedAttendeesByCompany[conferenceCompany]);
    return (
      <div className={AgendaClassName.Session}>
        {companies}
        {internalCompany}
      </div>
    );
  }

  function renderInvestorSubheading(
    participants: AgendaSession["attendees"],
    readonly: AgendaSession["Readonly"]
  ): JSX.Element {
    if ((isUserCorporate && !isUserInternal) || !readonly) return null;

    const corporateName = new CorporateProfile((participants || []).find((x) => isCorporateType(x.type))?._corporate_profile)
      ?.name;
    const corporateAttendees = getCorporateMeetingAttendees(corporateName, participants);

    const internalAttendees = getInternalMeetingAttendees(participants);
    const meetingId = idModel.meeting.getId(corporateName);
    const internalMeetingId = idModel.meeting.getId(DefaultInternalCompanyName);

    const companies = renderCompany(corporateName, meetingId, corporateAttendees);
    const internalCompany =
      !isInternal(user) &&
      !isNullOrWhiteSpace(internalAttendees) &&
      renderCompany(conferenceCompany, internalMeetingId, internalAttendees);

    return (
      <div>
        {companies}
        {internalCompany}
      </div>
    );
  }
  // #endregion

  return (
    <Collapsable collapsed={!showAgenda}>
      <Section
        id={idModel.id}
        className={AgendaClassName.Base}
        theme={!hasTracks && !hasSpeakers ? SectionTheme.Gradient : SectionTheme.Dark}
        innerWidth={SectionInnerWidth.Contained}
        headerChildren={[
          <div key="agenda_details" className={AgendaClassName.Details}>
            <h2
              id={idModel.title}
              className={`${ItineraryClassName.Heading} ${ItineraryClassName.HeadingWithFancyModifier}`}
            >
              <span>{agendaLabels.title}</span>
            </h2>
            <span id={idModel.indicator}>
              <TimeZoneSelector
                id={idModel.timeZoneSelector.id}
                selectedTimeZone={userTimeZone}
                conferenceTimeZone={conferenceTimeZone}
                anchorMargin
                onSelect={onTimeZoneChange}
              />
            </span>
            {loading && (
              <div className={AgendaClassName.Loader}>
                <Spinner id={idModel.spinner} fixed={false} masked={false} />
                <span>Loading...</span>
              </div>
            )}
          </div>,
          <Ghostable key="agenda_actions" ghosted={!hasAgenda}>
            <div id={idModel.actions} className={AgendaClassName.Actions}>
              <div>
                <Button
                  id={idModel.export.id}
                  ref={buttonRef}
                  className={AgendaClassName.Export}
                  icon="q4i-export-4pt"
                  label="Export"
                  loading={exporting}
                  onClick={handleOpen}
                />
                <PopoverMenu
                  id={idModel.popoverMenu?.id}
                  className={`${AgendaClassName.Base} ${AgendaClassName.PopoverMenu}`}
                  visible={visible}
                  anchorTargetReference={buttonRef}
                  targetOrigin={Origin.BottomLeft}
                  popoverOrigin={Origin.TopLeft}
                  matchWidth
                  options={popoverOptions}
                  onCloseRequest={handleClose}
                />
              </div>
              <Toggle
                id={idModel.showPast.id}
                className={AgendaClassName.ShowPastToggle}
                theme={ToggleTheme.Citrus}
                labelRight="Show Past"
                on={showPast}
                onChange={handlePastAgendaSwitchChange}
              />
              <div>
                {hasAgenda && videoVendors?.length ? (
                  <Anchor id={idModel.dialInAnchor?.id} className={AgendaClassName.Link} url={`#${ItineraryAnchors.DialIn}`}>
                    Dial Information {">"}
                  </Anchor>
                ) : null}
              </div>
            </div>
          </Ghostable>,
        ]}
        headerMargin={SectionHeaderMargin.Thick}
        anchor={ItineraryAnchors.Agenda}
      >
        {noFilteredAgenda ? getPlaceholderMessage() : renderGroupList()}
      </Section>
    </Collapsable>
  );
}

export default memo(Agenda);
