import { convertStringToEnum, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useEffect } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner.component";
import { useSessionLink } from "../../../../hooks/user/useSessionLink/useSessionLink.hook";
import { SessionEventType } from "../../../../services/session/session.model";
import { CompanyLobbyParams } from "../companyLobby.definition";
import type { CompanySessionProps } from "./companySession.definition";

const CompanySession = (props: CompanySessionProps): JSX.Element => {
  const params = useParams<CompanyLobbyParams>();

  const { fetchBy } = useSessionLink();

  useEffect(() => {
    const session = convertStringToEnum(SessionEventType, props?.session);
    fetchBy(params?.sessionId, session).then((url) => {
      if (isNullOrWhiteSpace(url)) return;
      window.location.href = url;
    });
  }, [params?.sessionId, props?.session, fetchBy]);

  return (
    <>
      <LoadingSpinner />
      Company Session View
    </>
  );
};

export default memo(CompanySession);
