import { IdModelBase, isNullOrWhiteSpace, Theme, BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import type { Key } from "react";

export enum BadgeTheme {
  BlackSmoke = Theme.BlackSmoke,
  Citrus = Theme.Citrus,
  SoftGrey = Theme.SoftGrey,
  LightGrey = Theme.LightGrey,
  LightSlate = Theme.LightSlate,
  Mustard = Theme.Mustard,
  None = Theme.None,
  Q4Blue = Theme.Q4Blue,
  Rain = Theme.Rain,
  Silver = Theme.Silver,
  Slate = Theme.Slate,
  Spice = Theme.Spice,
  Steel = Theme.Steel,
  Surf = Theme.Surf,
  Teal = Theme.Teal,
  Tangerine = Theme.Tangerine,
}

export enum BadgeClassName {
  Base = "badge",
  Icon = "badge--icon",
  Small = "badge--small",
  Text = "badge_text",
  TextIcon = "badge--text-with-icon",
}

export interface BadgeProps extends BaseComponentWithChildrenProps {
  /**
   * Used to provide the badge with an icon
   */
  icon?: string;
  /**
   * Used to paint the component using a chosen theme
   */
  theme?: BadgeTheme;

  /**
   * Used to paint the component's text using a chosen theme
   */
  color?: BadgeTheme;

  /**
   * Used to show a smaller sized version of the component (which makes the text size small)
   */
  small?: boolean;
}

export class BadgeIdModel extends IdModelBase {
  icon: string;
  theme: BadgeTheme;
  small: boolean;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);
    if (isNullOrWhiteSpace(this.id)) return;

    this.icon = `${this.id}Icon`;
  }
}
