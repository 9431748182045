import {
  Button,
  ButtonProps,
  ButtonTheme,
  ErrorModel,
  isEmpty,
  isNullOrWhiteSpace,
  Origin,
  PlaceholderContentProps,
  TableProps,
} from "@q4/nimbus-ui";
import { GetQuickFilterTextParams, ICellRendererParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { uniqueId } from "lodash";
import React, { memo, useMemo, useState } from "react";
import EntityTable from "../../../../../../../../components/entityTable/entityTable.component";
import {
  EntityTableCellRendererProps,
  EntityTableRequiredFormKey,
} from "../../../../../../../../components/entityTable/entityTable.definition";
import { RegistrationForm } from "../../../../../../../../components/registrationForm/registrationForm.component";
import { QuestionTemporaryId } from "../../../../../../../../components/registrationForm/registrationForm.definition";
import { Entity } from "../../../../../../../../definitions/entity.definition";
import { AttendeeTypeOptions } from "../../../../../../../../services/attendee/attendee.model";
import {
  Question,
  QuestionGroup,
  QuestionTypesWithOptions,
} from "../../../../../../../../services/questionGroup/questionGroup.model";
import { sanitizeQuestionGroup } from "../../../../../../../public/register/components/customQuestions/customQuestions.utils";
import { isRegistrationClosed } from "../../../../../../../public/register/registration.utils";
import {
  RegistrantClassName,
  RegistrantEntityTableMessages,
  RegistrationCustomQuestionsTableHeader,
} from "../../registrants.definition";
import { RegistrationFormTableIdModel, RegistrationFormTableProps } from "./registrationFormTable.definition";

const RegistrationFormTable = (props: RegistrationFormTableProps): JSX.Element => {
  const { id, conference, toolbarProps, useQuestionGroups, user, test } = props;

  const [registrationFormErrors, setRegistrationFormErrors] = useState<Record<string, ErrorModel>>({});

  const idModel = useMemo(() => new RegistrationFormTableIdModel(id), [id]);

  const {
    current: currentQuestionGroup,
    items: questionGroups,
    setCurrent: setCurrentQuestionGroup,
  } = useQuestionGroups || {};

  const registrationClosed = useMemo(() => isRegistrationClosed(conference), [conference]);
  const isAddNewButtonDisabled = useMemo(
    () => registrationClosed || questionGroups?.length === AttendeeTypeOptions.length,
    [questionGroups?.length, registrationClosed]
  );

  const registrationFormEntityKeys = useMemo(() => {
    const defaultRequiredKeys: EntityTableRequiredFormKey[] = [
      { key: "title", message: "Form name is required", validation: isNullOrWhiteSpace },
      { key: "attendee_types", message: "Registration type is required", validation: isEmpty },
    ];

    const questionKeys =
      currentQuestionGroup?._questions?.reduce((acc, _question, index) => {
        acc.push({ key: `_questions[${index}].title`, message: "Question is required", validation: isNullOrWhiteSpace });

        if (QuestionTypesWithOptions.includes(_question.answer_type.field_type)) {
          acc.push({
            key: `_questions[${index}].options[0].answer`,
            message: "At least one option is required",
            validation: isNullOrWhiteSpace,
          });
        }

        return acc;
      }, [] as EntityTableRequiredFormKey[]) ?? null;

    return defaultRequiredKeys.concat(questionKeys);
  }, [currentQuestionGroup?._questions]);

  const tableProps: Partial<TableProps> = {
    columnDefs: [
      {
        field: "attendee_type",
        headerName: RegistrationCustomQuestionsTableHeader.RegistrationType,
        minWidth: 180,
        cellRenderer: "attendeeTypeCellRenderer",
        getQuickFilterText: renderAttendeeType,
      },
      {
        field: "title",
        headerName: RegistrationCustomQuestionsTableHeader.FormName,
        minWidth: 180,
      },
      {
        field: "number",
        headerName: RegistrationCustomQuestionsTableHeader.NumberOfQuestions,
        minWidth: 180,
        cellRenderer: "numberCellRenderer",
      },
    ],
    frameworkComponents: {
      attendeeTypeCellRenderer: renderAttendeeType,
      actionsCellRenderer: renderDuplicateButton,
      numberCellRenderer: renderQuestionNumber,
    },
    suppressColumnVirtualisation: !!test,
  };

  const addNewButtonProps = useMemo((): Partial<ButtonProps> => {
    const props: Partial<ButtonProps> = {
      tooltip: {
        position: Origin.Left,
        label: registrationClosed
          ? RegistrantEntityTableMessages.RegistrationClosed
          : RegistrantEntityTableMessages.AllTypesPresent,
      },
    };

    if (isAddNewButtonDisabled) props.disabled = true;

    return props;
  }, [isAddNewButtonDisabled, registrationClosed]);

  const placeHolderContentProps = useMemo((): Partial<PlaceholderContentProps> => {
    const props: Partial<PlaceholderContentProps> = {
      subtitle: registrationClosed
        ? RegistrantEntityTableMessages.RegistrationClosed
        : RegistrantEntityTableMessages.AllTypesPresent,
    };

    if (isAddNewButtonDisabled) props.actions = [];

    return props;
  }, [isAddNewButtonDisabled, registrationClosed]);

  const registrationEditForm = (
    <RegistrationForm
      id={idModel?.registrationForm?.id}
      conference={conference}
      questionGroups={questionGroups}
      formKeys={registrationFormEntityKeys}
      formErrors={registrationFormErrors}
      setFormErrors={setRegistrationFormErrors}
      current={currentQuestionGroup}
      setCurrent={setCurrentQuestionGroup}
    />
  );

  function renderAttendeeType(params: GetQuickFilterTextParams): string {
    const questionGroup = new QuestionGroup(params?.data);
    return isNullOrWhiteSpace(questionGroup.attendee_types?.[0]) ? "-" : questionGroup.attendee_types[0];
  }

  function renderQuestionNumber(params: GetQuickFilterTextParams): number {
    const questionGroup = new QuestionGroup(params?.data);
    return questionGroup._questions?.length || 0;
  }

  function renderDuplicateButton(
    params: ICellRendererParams & { agGridReact: { gridOptions: { rowData: QuestionGroup[] } } },
    props: EntityTableCellRendererProps
  ) {
    const data = params.data as QuestionGroup;

    const handleDuplicateClick = () => {
      const { _id, attendee_types, _questions, ...rest } = data;

      const duplicateQuestions = _questions?.map(
        ({ _id, attendee_types, is_individual_corporate, ...question }) =>
          new Question({
            ...question,
            _id: `${QuestionTemporaryId}-${uniqueId()}`,
          })
      );

      const questionGroup = new QuestionGroup({
        ...rest,
        _questions: duplicateQuestions,
      });

      setCurrentQuestionGroup(new QuestionGroup(questionGroup));
      props.handleModalOpen();
    };

    return (
      <Button
        id={idModel?.copyButtonItem?.getId(params.rowIndex)}
        theme={ButtonTheme.LightGrey}
        className={RegistrantClassName.CopyButton}
        onClick={handleDuplicateClick}
        disabled={params.agGridReact.gridOptions.rowData.length === AttendeeTypeOptions.length}
        icon="q4i-clone-duplicate-4pt"
      />
    );
  }

  function handleBeforePost(data: QuestionGroup): QuestionGroup {
    return sanitizeQuestionGroup(data);
  }

  return (
    <EntityTable
      key="registrationForms_table"
      id={idModel?.id}
      entity={Entity.RegistrationCustomQuestionsForm}
      useService={useQuestionGroups}
      tableProps={tableProps}
      icon={"q4i-note-4pt"}
      editForm={registrationEditForm}
      modalProps={{ fullscreen: true, scrollable: true, focusOnProps: { autoFocus: false } }}
      entityModel={QuestionGroup}
      requiredFormEntityKeys={registrationFormEntityKeys}
      toolbarProps={toolbarProps}
      user={user}
      conference={conference}
      addNewButtonProps={addNewButtonProps}
      hideSearch={true}
      placeholderContentProps={placeHolderContentProps}
      formErrors={registrationFormErrors}
      setFormErrors={setRegistrationFormErrors}
      entityDeleteConfirmationTitle={RegistrantEntityTableMessages.DeleteConfirmationTitle}
      entityDeleteConfirmationMessage={RegistrantEntityTableMessages.DeleteConfirmationMessage}
      modifyData={handleBeforePost}
    />
  );
};

export default memo(RegistrationFormTable);
