import { convertStringToEnum, isEmpty } from "@q4/nimbus-ui";
import type { ISubmitEvent, FormProps, UiSchema } from "@rjsf/core";
import React, { memo, useMemo } from "react";
import JsonForm from "../../../../../components/jsonForm/jsonForm.component";
import { Registrant, RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../../services/attendee/attendee.model";
import { ErrorHandlerField } from "../../../../../services/errorHandler/errorHandler.definition";
import { validateText } from "../../../../../utils";
import { getRegistrationAttendeeTypes } from "../../registration.utils";
import {
  RegistrationAttendeeTypeLabel,
  RegistrationTypeErrorMessages,
  RegistrationTypeIdModel,
} from "./registrationType.definition";
import type { RegistrationTypeProps } from "./registrationType.definition";

const RegistrationTypeInformation = (props: RegistrationTypeProps): JSX.Element => {
  const { id, attendeeTypes: attendeeTypesProp, guestOtherTypesEnabled, registrant, onChange, onStep } = props;

  const idModel = useMemo(() => new RegistrationTypeIdModel(id), [id]);

  const attendeeTypes = useMemo(
    () =>
      getRegistrationAttendeeTypes(attendeeTypesProp).map((x) =>
        guestOtherTypesEnabled ? RegistrationAttendeeTypeLabel[x as keyof typeof RegistrationAttendeeTypeLabel] : x
      ),
    [attendeeTypesProp, guestOtherTypesEnabled]
  );

  const schema: FormProps<Registrant>["schema"] = useMemo(() => {
    return {
      type: "object",
      properties: {
        attendee_type: {
          id: idModel.attendeeType?.id,
          title: "Registration Type",
          type: "string",
          enum: attendeeTypes,
        },
      },
    };
  }, [attendeeTypes, idModel.attendeeType?.id]);

  const uiSchema: UiSchema = useMemo(() => {
    return {
      "attendee_type": {
        "ui:widget": "nuiSelect",
      },
      "ui:field": "layout",
      "ui:layout": [
        {
          fields: {
            attendee_type: {
              width: "1-of-2",
            },
          },
        },
      ],
    };
  }, []);

  const errorHandlerFields = useMemo(() => {
    return [
      {
        fields: [new ErrorHandlerField("attendee_type", RegistrationTypeErrorMessages.attendeeTypeSelection, validateText)],
      },
    ];
  }, []);

  function handleSubmit(response: ISubmitEvent<RegistrantViewModel>): void {
    if (isEmpty(response?.formData) || isEmpty(onChange)) {
      return;
    }

    const originalAttendeeType = response.formData.attendee_type;
    const attendee_type_label =
      RegistrationAttendeeTypeLabel[originalAttendeeType as keyof typeof RegistrationAttendeeTypeLabel];
    const attendee_type = convertStringToEnum(AttendeeType, attendee_type_label) ?? originalAttendeeType;

    const formData = {
      ...response.formData,
      attendee_type,
    } as RegistrantViewModel;

    onStep(true);
    onChange(formData);
  }

  return (
    <div id={idModel.id}>
      <JsonForm
        id={idModel.jsonForm?.id}
        errorHandlerFields={errorHandlerFields}
        uiSchema={uiSchema}
        schema={schema}
        data={registrant}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default memo(RegistrationTypeInformation);
