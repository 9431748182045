import { Form, FormFieldProps, Grid, GridColumn } from "@q4/nimbus-ui";
import { isEmpty, isNil, sortBy } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Answer } from "../../services/answer/answer.model";
import { Question, QuestionType } from "../../services/questionGroup/questionGroup.model";
import { htmlParse } from "../../utils";
import QuestionsFormChoice from "./components/questionsFormChoice/questionsFormChoice.component";
import QuestionsFormConsent from "./components/questionsFormConsent/questionsFormConsent.component";
import QuestionsFormSelect from "./components/questionsFormSelect/questionsFormSelect.component";
import { QuestionsFormText } from "./components/questionsFormText/questionsFormText.component";
import {
  QuestionsFormChildrenProps,
  QuestionsFormClassName,
  QuestionsFormIdModel,
  QuestionsFormProps,
} from "./questionsForm.definition";
import "./questionsForm.component.scss";

const QuestionsForm = (props: QuestionsFormProps): JSX.Element => {
  const { id, questions: questionsProp, answers, onChange, setFormErrors } = props;

  const idModel = useMemo(() => new QuestionsFormIdModel(id), [id]);

  const questions = useMemo(() => sortBy(questionsProp, "position"), [questionsProp]);

  const handleAnswerChange = useCallback(
    (answer: Answer) => {
      onChange(answer);
    },
    [onChange]
  );

  const getFieldComponent = useCallback(
    (question: Question, questionId: string): JSX.Element => {
      const type = question?.answer_type?.field_type;
      const targetAnswerObject = answers?.find((ans) => ans._question === question._id);
      const answer = targetAnswerObject?.answer;
      const indexNumber = targetAnswerObject ? answers?.indexOf(targetAnswerObject) : (answers || []).length;

      const defaultQuestionProps: QuestionsFormChildrenProps = {
        id: questionId,
        question,
        answer,
        onChange: handleAnswerChange,
        setFormErrors,
        indexNumber,
      };

      const questionType: {
        [key: string]: JSX.Element;
      } = {
        [QuestionType.Selection]: <QuestionsFormSelect {...defaultQuestionProps} />,
        [QuestionType.CustomSelection]: <QuestionsFormSelect {...defaultQuestionProps} />,
        [QuestionType.LongAnswer]: <QuestionsFormText {...defaultQuestionProps} />,
        [QuestionType.ShortAnswer]: <QuestionsFormText {...defaultQuestionProps} />,
        [QuestionType.Consent]: <QuestionsFormConsent {...defaultQuestionProps} />,
        [QuestionType.Choice]: <QuestionsFormChoice {...defaultQuestionProps} />,
        [QuestionType.MultipleChoice]: <QuestionsFormChoice {...defaultQuestionProps} />,
      };

      const questionComponent = questionType[type];

      if (isNil(questionComponent)) return;

      return questionComponent;
    },
    [answers, handleAnswerChange, setFormErrors]
  );

  const buildQuestionFields = useCallback(() => {
    return (questions || []).reduce((acc, question, i) => {
      const questionId = `${idModel.form.fieldIdList.getId(i)}Question`;
      const isConsentQuestion = question?.answer_type?.field_type === QuestionType.Consent;
      const questionTitle = isConsentQuestion ? htmlParse(question.title) : question.title;
      const component = getFieldComponent(question, questionId);
      if (isEmpty(question) || isEmpty(component)) return acc;

      acc.push({
        key: question._id,
        label: questionTitle,
        className: QuestionsFormClassName.Label,
        children: component,
        width: "1-of-1",
      });

      return acc;
    }, [] as FormFieldProps[]);
  }, [getFieldComponent, idModel?.form?.fieldIdList, questions]);

  return (
    <Grid id={idModel?.id} className={QuestionsFormClassName.Base}>
      <GridColumn width="1-of-2">
        <Form id={idModel?.form.id} fields={buildQuestionFields()} />
      </GridColumn>
    </Grid>
  );
};

export default memo(QuestionsForm);
