import { Checkbox } from "@q4/nimbus-ui";
import React, { memo } from "react";
import { ConferencePreviewModeProps } from "./previewMode.definition";

const ConferencePreviewMode = (props: ConferencePreviewModeProps): JSX.Element => {
  const { checkboxProps } = props;

  return <Checkbox label="Preview Mode" {...checkboxProps} />;
};

export default memo(ConferencePreviewMode);
