import { isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import React, { useCallback, useMemo, useState } from "react";
import ReactDomServer from "react-dom/server";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import { Conference } from "../../services/conference/conference.model";
import ExportService from "../../services/export/export.service";
import { Meeting } from "../../services/meeting/meeting.model";
import { Presentation, PresentationExport } from "../../services/presentation/presentation.model";
import { removeSpecialCharacters } from "../../utils";
import ItineraryPdf from "../../views/public/itinerary/components/pdf/itineraryPdf.component";
import { ItineraryPdfId } from "../../views/public/itinerary/components/pdf/itineraryPdf.definition";
import { ExportHookModel, ExportHookProps } from "./useExport.definition";

export const useExport = (props: ExportHookProps): ExportHookModel => {
  const { showNotifications, authType, company } = props;
  const service = useMemo(() => new ExportService(), []);
  const notificationService = useMemo(() => new NotificationService(), []);
  const [loading, setLoading] = useState(false);

  const exportToPdf = useCallback(
    async (data: PresentationExport, fileName: string): Promise<boolean> => {
      setLoading(true);
      return service
        .exportToPdf(data, fileName, authType)
        .then((response): boolean => {
          if (!response?.success) {
            throw new Error("Failed to download your itinerary.");
          }

          showNotifications && notificationService.success("Your itinerary has been exported.");

          return !!response?.success;
        })
        .catch((error): boolean => {
          showNotifications && notificationService.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [service, showNotifications, notificationService, authType]
  );

  const generatePdf = useCallback(
    async (
      user: AttendeeViewModel,
      attendees: AttendeeViewModel[],
      conference: Conference,
      meetings: Meeting[],
      presentations: Presentation[],
      fileNamePrefix?: string
    ) => {
      setLoading(true);

      const itineraryHtml = ReactDomServer.renderToStaticMarkup(
        <ItineraryPdf
          id={ItineraryPdfId}
          user={user}
          attendees={attendees}
          conference={conference}
          meetings={meetings}
          presentations={presentations}
          company={company}
        />
      );

      if (isNullOrWhiteSpace(itineraryHtml)) return;

      const head =
        '<head><style>html { -webkit-print-color-adjust: exact; } section ol, section ul { padding-left: 16px; }</style><link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&amp;display=swap" rel="stylesheet"></head>';
      const html = `<html lang="en">${head}<body>${itineraryHtml}</body></html>`.replace(/"/g, "'");

      const conferenceTitle = conference?.title || "Conference";
      const charactersRemoved = removeSpecialCharacters(`${fileNamePrefix || ""}${conferenceTitle}`);
      const fileName = `${charactersRemoved}_Itinerary.pdf`;

      return exportToPdf({ html }, fileName).then((success) => ({
        success,
        attendee: user,
      }));
    },
    [company, exportToPdf]
  );

  return {
    loading,
    exportToPdf,
    generatePdf,
  };
};
