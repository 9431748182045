import "./checkboxGroup.component.scss";
import { Checkbox, ErrorModel, isNullOrWhiteSpace, Textbox, isNil } from "@q4/nimbus-ui";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CheckboxGroupProps,
  CheckboxGroupIdModel,
  CheckboxGroupClassName,
  ErrorAppearanceDelay,
} from "./checkboxGroup.definition";

function CheckboxGroup(props: CheckboxGroupProps): JSX.Element {
  const {
    id,
    items: itemsProp,
    value: componentValue,
    allowCustomOption,
    isMultiChoiceMode: isMultiChoiceModeProp,
    onChange,
    setFormErrors,
    indexNumber,
    showError,
    setShowError,
  } = props;

  //#region Component configuration
  const idModel = useMemo(() => new CheckboxGroupIdModel(id), [id]);
  const isMultiChoiceMode = useMemo(() => isMultiChoiceModeProp ?? false, [isMultiChoiceModeProp]);
  const items = useMemo(() => itemsProp.map((item) => item), [itemsProp]);
  const key = useMemo(() => `custom_question_answers[${indexNumber}].answer`, [indexNumber]);
  const timerRef = useRef(null);

  //#endregion

  //#region Component values and states
  // Internally treat data as array in both multi and single choice cases
  const answer = useMemo(() => {
    if (isNullOrWhiteSpace(componentValue as unknown as string)) return [];
    if (!Array.isArray(componentValue)) return [componentValue as string];
    return componentValue;
  }, [componentValue]);

  const providedOptions = useMemo(() => {
    return answer.filter((opt) => items.includes(opt));
  }, [answer, items]);

  const customOptionValue = useMemo(() => {
    const lastAnswer = answer[answer.length - 1];
    if (!items.includes(lastAnswer)) return lastAnswer;
    return "";
  }, [answer, items]);

  const [customOptionSelected, setCustomOptionSelected] = useState<boolean>(!!customOptionValue);
  //#endregion

  // If a custom value exists, force the checkbox on so user can see the field
  useEffect(() => {
    if (!!customOptionValue) {
      setCustomOptionSelected(true);
    }
  }, [customOptionSelected, customOptionValue, showError]);

  useEffect(() => {
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [timerRef]);

  const handleFormError = useCallback(
    (message: string, isVisisble: boolean) => {
      if (setFormErrors) {
        setFormErrors((previousState) => ({
          ...previousState,
          [key]: new ErrorModel(message, isVisisble),
        }));
      }
    },
    [key, setFormErrors]
  );

  const handleErrorText = useCallback(() => {
    timerRef.current = setTimeout(() => {
      if (!showError && setShowError && customOptionSelected && isNullOrWhiteSpace(customOptionValue)) {
        setShowError(true);
      }
    }, ErrorAppearanceDelay);
  }, [customOptionSelected, customOptionValue, timerRef, setShowError, showError]);

  const handleProvidedOptionChange = useCallback(
    (checked: boolean, checkboxValue: string) => {
      let selectedOptions: string[];
      if (!isMultiChoiceMode) {
        selectedOptions = checked ? [checkboxValue] : [];
        setCustomOptionSelected(false);
        // if user checks another option apart from "Other" for single choice, remove error validation
        handleFormError("Hidden Error", false);
      } else {
        selectedOptions = [...providedOptions, checkboxValue].filter((val) => val !== checkboxValue || checked);

        if (allowCustomOption && customOptionSelected) {
          selectedOptions.push(customOptionValue);
        }
      }

      onChange(isMultiChoiceMode ? selectedOptions : selectedOptions[0]);
    },
    [
      isMultiChoiceMode,
      onChange,
      handleFormError,
      providedOptions,
      allowCustomOption,
      customOptionSelected,
      customOptionValue,
    ]
  );

  const updateCustomOption = useCallback(
    (updatedValue?: string) => {
      if (isMultiChoiceMode) {
        const newAnswers = [...providedOptions];
        !isNil(updatedValue) && newAnswers.push(updatedValue);
        onChange(newAnswers);
        return;
      }

      onChange(updatedValue);
    },
    [providedOptions, isMultiChoiceMode, onChange]
  );

  const handleCustomOptionCheckboxChange = useCallback(
    (checked: boolean) => {
      setCustomOptionSelected(checked);
      updateCustomOption(checked ? "" : undefined);
      handleFormError("Visible Error", checked && isNullOrWhiteSpace(customOptionValue));
      // Don't show error on initial click
      if (setShowError) setShowError(false);
    },
    [customOptionValue, handleFormError, setShowError, updateCustomOption]
  );

  const handleCustomOptionValueChange = useCallback(
    (customValue: string) => {
      updateCustomOption(customValue);
      handleFormError("Hidden Error", isNullOrWhiteSpace(customValue));
    },
    [handleFormError, updateCustomOption]
  );

  const customOptionSelector = useMemo(() => {
    return (
      <>
        <Checkbox
          id={idModel.customOptionCheckbox.id}
          name="Other"
          value={customOptionValue}
          label="Other"
          onChange={handleCustomOptionCheckboxChange}
          checked={customOptionSelected}
          className={CheckboxGroupClassName.ListItem}
        />
        {customOptionSelected && (
          <Textbox
            id={idModel.customOptionText.id}
            value={customOptionValue}
            onChange={handleCustomOptionValueChange}
            placeholder="Other"
            maxLength={100}
            className={CheckboxGroupClassName.CustomText}
            autoFocus={customOptionSelected && isNullOrWhiteSpace(customOptionValue)}
            onBlur={handleErrorText}
          ></Textbox>
        )}
        {setFormErrors && showError && customOptionSelected && isNullOrWhiteSpace(customOptionValue) && (
          <div className={CheckboxGroupClassName.ErrorText}>This field cannot be blank</div>
        )}
      </>
    );
  }, [
    idModel.customOptionCheckbox.id,
    idModel.customOptionText.id,
    customOptionValue,
    handleCustomOptionCheckboxChange,
    customOptionSelected,
    handleCustomOptionValueChange,
    handleErrorText,
    showError,
    setFormErrors,
  ]);

  return (
    <div id={idModel.id}>
      {items.map((option, idx) => {
        return (
          <Checkbox
            key={`${idModel.id}-${idx}`}
            id={idModel.checkboxList.getId(idx)}
            name={option}
            value={option}
            label={option}
            onChange={handleProvidedOptionChange}
            checked={providedOptions?.includes(option) || false}
            className={CheckboxGroupClassName.ListItem}
          />
        );
      })}
      {allowCustomOption && customOptionSelector}
    </div>
  );
}

export default memo(CheckboxGroup);
