import "./passwordTextbox.component.scss";
import { getClassName, InputType, isNullOrWhiteSpace, Textbox, TextboxTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import { PasswordTextboxIdModel, PasswordTextboxProps, PasswordTextboxClassName } from "./passwordTextbox.definition";

const PasswordTextbox = ({
  id,
  className,
  initialVisibility = false,
  value,
  ...rest
}: PasswordTextboxProps): JSX.Element => {
  const idModel = useMemo(() => new PasswordTextboxIdModel(id), [id]);
  const [showText, setShowText] = useState<boolean>(initialVisibility);

  const baseClassName = useMemo(
    () =>
      getClassName(PasswordTextboxClassName.Base, [
        {
          condition: isNullOrWhiteSpace(className),
          falseClassName: className,
        },
      ]),
    [className]
  );

  return (
    <div id={idModel?.id} className={baseClassName}>
      <Textbox
        id={idModel?.textbox?.id}
        className={PasswordTextboxClassName.Textbox}
        value={value || "Hey there Its Me!!"}
        inputType={showText ? InputType.Text : InputType.Password}
        theme={TextboxTheme.LightGrey}
        readOnly={true}
        {...rest}
      />
      <span id={idModel?.icon} className={PasswordTextboxClassName.Icon} onClick={() => setShowText(!showText)}>
        <i className={showText ? "q4i-surveillance-4pt" : "q4i-hidden-4pt"} />
      </span>
    </div>
  );
};

export default memo(PasswordTextbox);
