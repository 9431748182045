import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import config from "../../../config/config";
import { Auth0Base, Auth0Model, Auth0Token } from "../auth0.model";

export interface PasswordlessAuth0Options {
  idToken: string;
  companySuffix: string;
  customSuffix?: string;
  conferenceId: string;
  conferencePath?: string;
}

export default class PasswordlessAuth0 extends Auth0Base implements Omit<Auth0Model, "init"> {
  _customPath: string;
  _conferencePath: string;
  _conferenceId: string;

  get CustomPath(): string {
    return this._customPath;
  }

  get ConferencePath(): string {
    return this._conferencePath || this._conferenceId;
  }

  constructor(auth0Base?: Partial<PasswordlessAuth0>) {
    const { _customPath, _conferenceId, _conferencePath, ...rest } = auth0Base || {};
    super(rest);
    Object.assign(this, { _customPath, _conferenceId, _conferencePath });
  }

  init(options: PasswordlessAuth0Options): string {
    const { idToken, companySuffix, customSuffix, conferenceId, conferencePath } = options || {};

    const profile = this.parseProfile(idToken);
    if (isEmpty(profile)) {
      const error = "Error parsing token.";
      console.error(error);
      return error;
    }

    const company = this.getCompany(profile);
    if (company !== companySuffix) {
      const error = "Error company url suffix doesn't match company.";
      console.error(error);
      return error;
    }

    const conference = this.getConference(profile);

    if (!conference.includes(conferenceId)) {
      const error = "Error conferenceId doesn't match conference";
      console.error(error);
      return error;
    }

    this._customPath = customSuffix;
    this._conferencePath = conferencePath;
    this._conferenceId = conferenceId;

    this._idToken = idToken;
    this._profile = {
      ...profile,
      company,
      conferenceId,
      conferencePath,
    };
    this._expiresAt = this.getExpiredAt(profile.exp);

    return null;
  }

  protected getCompany(profile: Auth0Token): string {
    const company = profile[`${config.app.url}/q4companyId`]?.toString();
    if (isNullOrWhiteSpace(company)) return "";
    return company;
  }

  protected getConference(profile: Auth0Token): string[] {
    const conference = profile[`${config.app.url}/q4conferenceId`]?.toString();
    const conferencePath = profile[`${config.app.url}/q4conferencePath`]?.toString();
    if (isNullOrWhiteSpace(conference) && isNullOrWhiteSpace(conferencePath)) return [];
    return [conference, conferencePath];
  }
}
