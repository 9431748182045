import { arrayIndexFound, isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { sortBy } from "lodash";
import moment, { Moment } from "moment";
import { EntityBase } from "../definitions/entity.definition";

export function getOptions<T, K extends keyof T>(key: K, array: T[], sort = false): T[K][] {
  if (isEmpty(array)) return [];
  const options = array.reduce((uniq, item) => {
    if (isEmpty(item)) return uniq;

    const value = item[key];
    if (typeof value === "string" && isNullOrWhiteSpace(value)) return uniq;
    if (typeof value === "boolean" && isNil(value)) return uniq;
    if (typeof value !== "boolean" && isEmpty(value)) return uniq;
    if (uniq.some((x) => x === value)) return uniq;
    return uniq.concat(value);
  }, []);

  if (!sort) return options;
  return sortBy(options, (o) => o.toLowerCase());
}

export function mapEntities<T extends EntityBase>(partialObject: T[], entityObject: T[]): T[] {
  if (isEmpty(partialObject) || isNil(entityObject)) return [];
  return partialObject.map((entity) => entityObject.find((x) => x._id === entity._id)).filter((entity) => !isEmpty(entity));
}

export function sortEntitiesByDate<T>(entities: T[], dateKey: keyof T): T[] {
  if (isEmpty(entities)) return [];

  return entities.sort((a: T, b: T) => moment(a[dateKey])?.valueOf() - moment(b[dateKey])?.valueOf());
}

export function formatEntityDate(date: Moment, format: string): string {
  if (isEmpty(date) || !date?.isValid()) return "";
  return date.format(format);
}

export function convertEnumByKey<T1, T2>(enumType: T1, enumType2: T2, sharedValue: T1[keyof T1]): T2[keyof T2] {
  if (isEmpty(enumType)) return null;

  const keys = Object.keys(enumType);
  const index = Object.values(enumType).findIndex((x) => x === sharedValue);

  if (!arrayIndexFound(index)) return null;

  return enumType2[keys[index] as keyof typeof enumType2];
}

export function isObjectPlural(data: unknown): boolean {
  const isArray = Array.isArray(data);
  if (!isArray) return false;
  return isArray && (data as [])?.length > 1;
}
