import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface InfoProps extends BaseComponentProps {
  content?: string;
}

export enum InfoClassName {
  Base = "info",
  Content = "info_content",
}

export class InfoIdModel extends IdModelBase {
  content: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.content = `${this.id}ContentText`;
  }
}
