/** @jsxRuntime classic */
import "./polyfills";
import "./styles/index.scss";
import { createBrowserHistory } from "history";
import React from "react";
import { render } from "react-dom";
import SupportedBrowser from "./components/supportedBrowser/supportedBrowser.component";
import ContextProvider from "./contexts/context.provider";
import * as serviceWorker from "./serviceWorker";
import Root from "./views/root/root.view";
import "@q4/nimbus-ui/dist/_styles.css";

const history = createBrowserHistory();

render(
  <SupportedBrowser>
    <ContextProvider>
      <Root history={history} />
    </ContextProvider>
  </SupportedBrowser>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
