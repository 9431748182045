import { AutomationListId, ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { BrandingBase } from "../../../../../components/companyBranding/companyBranding.definition";
import type { OnDemand } from "../../../../../services/admin/onDemand/onDemand.model";
import type { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../services/presentation/presentation.model";
import { TimeZoneSelectorIdModel } from "../../../components/timeZoneSelector/timeZoneSelector.definition";
import { DateSelectIdModel } from "../dateSelect/dateSelect.definition";
import { PanelIdModel } from "../panel/panel.definition";
import { SliderIdModel } from "../slider/slider.definition";

export type TrackPresentation = Presentation | OnDemand;

export interface TracksProps extends BaseComponentProps {
  conference?: Conference;
  agendaPresentations?: Presentation[];
  presentations?: TrackPresentation[];
  agendaLoading?: boolean;
  profile: AttendeeViewModel;
  now: Moment;
  title: string;
  hoverText?: string;
  visible?: boolean;
  usePresentationTheater?: boolean;
  branding?: BrandingBase;
  onTimeZoneChange?: (timeZone: string) => void;
  onPresentationAdd?: (_id: TrackPresentation["_id"]) => void;
  onPresentationRemove?: (_id: TrackPresentation["_id"]) => void;
}

export enum TracksClassName {
  Base = "tracks",
  Group = "tracks_group",
  Slider = "tracks_slider",
  Name = "tracks_name",
  List = "tracks_list",
  Item = "tracks_item",
}

export class TracksIdModel extends IdModelBase {
  dateSelect: DateSelectIdModel;
  indicator: string;
  list: TracksGroupListId;
  timeZoneSelector: TimeZoneSelectorIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.dateSelect = new DateSelectIdModel(null);
      this.list = new TracksGroupListId(null);
      return;
    }

    this.dateSelect = new DateSelectIdModel(`${this.id}DateSelect`);
    this.indicator = `${this.id}Indicator`;
    this.list = new TracksGroupListId(this.id);
    this.timeZoneSelector = new TimeZoneSelectorIdModel(`${this.id}TimeZoneSelector`);
  }
}

export class TracksGroupListId extends AutomationListId<TracksGroupIdModel> {
  constructor(parentId: string, prefix = "Group-", postfix?: string) {
    super(parentId, prefix, TracksGroupIdModel, postfix);
  }
}

export class TracksGroupIdModel extends IdModelBase {
  name: string;
  slider: SliderIdModel;
  list: TracksItemListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.slider = new SliderIdModel(null);
      this.list = new TracksItemListId(null);
      return;
    }

    this.name = `${this.id}Name`;
    this.slider = new SliderIdModel(this.id);
    this.list = new TracksItemListId(this.id);
  }
}

export class TracksItemListId extends AutomationListId<TracksItemIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, TracksItemIdModel, postfix);
  }
}

export class TracksItemIdModel extends IdModelBase {
  panel: PanelIdModel;
  button: ButtonIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.panel = new PanelIdModel(null);
      this.button = new ButtonIdModel(null);
      return;
    }

    this.panel = new PanelIdModel(`${this.id}Panel`);
    this.button = new ButtonIdModel(`${this.id}Button`);
  }
}
