import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { Answer } from "../../services/answer/answer.model";
import AnswerService from "../../services/answer/answer.service";
import { AttendeeAnswerHookProps, AttendeeAnswerHookModel } from "./useAttendeeAnswer.definition";

export const useAttendeeAnswer = (props: AttendeeAnswerHookProps): AttendeeAnswerHookModel => {
  const { attendeeId: newAttendeeId } = props;

  const notificationService = useRef(new NotificationService());
  const answerService = useRef(new AnswerService());
  const attendeeId = useRef(newAttendeeId);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getAnswersByAttendeeId = useCallback(async () => {
    setLoading(true);
    if (!newAttendeeId) {
      setItems([] as Answer[]);
      return setLoading(false);
    }

    try {
      const response = await answerService.current.getByAttendeeId(newAttendeeId);
      if (response?.success) {
        setItems(response?.data as Answer[]);
      } else throw new Error(response?.message);
    } catch (err) {
      console.error(err);
      notificationService.current.error("An error occured while loading answers");
    }

    setLoading(false);
  }, [newAttendeeId]);

  useEffect(() => {
    if (newAttendeeId !== attendeeId.current) {
      attendeeId.current = newAttendeeId;
      getAnswersByAttendeeId();
    }
  }, [newAttendeeId, getAnswersByAttendeeId]);

  return { loading, items };
};
