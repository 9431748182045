import { isEmpty } from "@q4/nimbus-ui";
import { get } from "lodash";
import { ErrorHandlerMessage } from "./errorHandler.definition";
import type { ErrorHandlerField } from "./errorHandler.definition";

export default class ErrorHandlerService<TKey, TEntity> {
  private _errorMessages: ErrorHandlerMessage<TKey>[];
  private readonly _errorFields: ErrorHandlerField[];

  constructor(errorFields: ErrorHandlerField[]) {
    this._errorMessages = [];
    this._errorFields = errorFields;
  }

  getAll = (): ErrorHandlerMessage<TKey>[] => this._errorMessages;

  get = (id: TKey, prop: string): ErrorHandlerMessage<TKey> => {
    const error = this._errorMessages.find((x): boolean => x.id === id && x.prop === prop);
    return { ...error, visible: !isEmpty(error) };
  };

  hasErrors = (): boolean => !isEmpty(this._errorMessages);

  checkForErrors(id: TKey, item: TEntity): void {
    this._errorFields.forEach((_ef) => {
      this.checkError(id, item, null, _ef);
    });
  }

  checkError(id: TKey, item: Partial<TEntity>, propToCheck: string, fieldToCheck: ErrorHandlerField = null): void {
    const requiredField = fieldToCheck || this._errorFields.find((r) => r.prop === propToCheck);
    if (isEmpty(requiredField)) return;

    const { errorMessage, prop, condition } = requiredField;
    const value = get(item, prop);

    if ((isEmpty(condition) && isEmpty(value)) || (!isEmpty(condition) && !condition(value))) {
      this._add(id, prop, errorMessage);
    }
  }

  private _add(id: TKey, prop: string, message: string): void {
    const containsErrorMessage = this._errorMessages.find((x): boolean => x.prop === prop && x.id === id);

    if (!containsErrorMessage) {
      this._errorMessages.push(new ErrorHandlerMessage(id, prop, message, true));
    }
  }

  delete(id: TKey, prop: string): void {
    const errIndex = this._errorMessages.findIndex((x): boolean => x.id === id && x.prop === prop);

    if (errIndex > -1) {
      this._errorMessages.splice(errIndex, 1);
    }
  }

  clear(): void {
    this._errorMessages = [];
  }
}
