import { isEmpty } from "lodash";
import moment from "moment";
import {
  Conference,
  CorporateRegistrationDeadlines,
  InvestorRegistrationDeadlines,
} from "../../services/conference/conference.model";

export const isCorporateRegisterDeadlinePassed = (conference: Conference): boolean => {
  if (isEmpty(conference)) return false;
  const { corporate_register } = conference;

  if (isEmpty(corporate_register)) return false;

  // Return true, if "end_date" is undefined
  return moment().isSameOrAfter(corporate_register.end_date);
};

export const isCorporateDeadlinePassed = (
  deadline: keyof CorporateRegistrationDeadlines,
  conference: Conference
): boolean => {
  if (isEmpty(conference)) return false;
  const now = moment();
  const { corporate_deadlines } = conference;
  if (isEmpty(corporate_deadlines)) return false;

  const requestedDeadline = corporate_deadlines[deadline];
  if (isEmpty(requestedDeadline)) return false;

  return now.isSameOrAfter(requestedDeadline);
};

export const isInvestorDeadlinePassed = (deadline: keyof InvestorRegistrationDeadlines, conference: Conference): boolean => {
  if (isEmpty(conference)) return false;

  const now = moment();
  const { investor_deadlines } = conference;

  if (isEmpty(investor_deadlines)) return false;

  const requestedDeadline = investor_deadlines[deadline];

  if (isEmpty(requestedDeadline)) return false;

  return now.isSameOrAfter(requestedDeadline);
};
