import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import ConferenceService from "../../../services/conference/conference.service";
import { QuestionGroup } from "../../../services/questionGroup/questionGroup.model";
import { PublicQuestionGroupsHookProps, PublicQuestionGroupsHookModel } from "./usePublicQuestionGroups.definition";

export const usePublicQuestionGroups = (props: PublicQuestionGroupsHookProps): PublicQuestionGroupsHookModel => {
  const { conferenceId: newConferenceId, attendeeType } = props;

  const notificationService = useRef(new NotificationService());
  const conferenceService = useRef(new ConferenceService());
  const conferenceId = useRef(newConferenceId);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [current, setCurrent] = useState(null);

  const getQuestionGroups = useCallback(async () => {
    setLoading(true);

    try {
      const response = await conferenceService.current.getConferenceQuestionGroupsById(newConferenceId);
      if (response?.success) {
        setItems(response?.data as Partial<QuestionGroup>[]);
      } else throw new Error(response?.message);
    } catch (err) {
      console.error(err);
      notificationService.current.error("An error occured while loading the page, please refresh");
    }

    setLoading(false);
  }, [newConferenceId]);

  useEffect(() => {
    if (newConferenceId !== conferenceId.current) {
      conferenceId.current = newConferenceId;
      if (newConferenceId) getQuestionGroups();
    }
  }, [newConferenceId, getQuestionGroups]);

  useEffect(() => {
    const currentQuestionGroup =
      items && attendeeType && items.find((group: Partial<QuestionGroup>) => group.attendee_types?.includes(attendeeType));
    if (currentQuestionGroup) setCurrent(currentQuestionGroup);
  }, [items, attendeeType]);

  return { loading, items, current };
};
