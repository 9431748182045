import { arrayIndexFound, isEmpty, isNil } from "@q4/nimbus-ui";
import { isEqual } from "lodash";
import moment from "moment";
import { DateRange, extendMoment } from "moment-range";
import * as MomentLib from "moment-timezone";
import { Moment } from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import { formatTimetableDataWithTimezone, getCalendarDaysFromScheduledSlots, getNonConflictingSlots } from "../../utils";
import { Timeslot, Timeslots } from "../../views/public/register/components/availability/availability.definition";
import { AvailabilityHookModel, AvailabilityHookProps } from "./useAvailability.definition";

const momentExtended = extendMoment(MomentLib);

export const useAvailability = (props: AvailabilityHookProps): AvailabilityHookModel => {
  const {
    availability: initialAvailability,
    timeZone,
    selectedByDefault: selectedByDefaultProps,
    scheduler,
    onAvailabilityChange,
  } = props;

  const newTimezone = useRef(timeZone);

  // filter all conflicting slots
  const filteredSlots = useMemo(() => {
    return getNonConflictingSlots(scheduler?.slots);
  }, [scheduler?.slots]);

  const schedulerDays = useMemo(
    () =>
      getCalendarDaysFromScheduledSlots({ ...scheduler, slots: formatTimetableDataWithTimezone(filteredSlots, timeZone) }),
    [filteredSlots, scheduler, timeZone]
  );

  const [timeslotState, setTimeslotState] = useState<Timeslots>({});
  const availability = useMemo(
    () => formatTimetableDataWithTimezone(initialAvailability, timeZone),
    [initialAvailability, timeZone]
  );

  const selectedByDefault = useMemo(() => selectedByDefaultProps ?? true, [selectedByDefaultProps]);
  const hasAvailability = useMemo(() => !isEmpty(availability), [availability]);
  // for participants with all availability set as NO
  const isNoAvailability = useMemo(() => Array.isArray(availability) && availability.length === 0, [availability]);

  const convertTimeslotsToAvailabilityRanges = useCallback((timeslots: Timeslots): ConferenceSchedulerSlot[] => {
    const timeslotValues = Object.values(timeslots);
    const availabilities = timeslotValues.reduce((ranges: DateRange[], timeslot: Timeslot) => {
      if (!timeslot?.isSelected) return ranges;

      const { slot } = timeslot;
      const updatedSlot = momentExtended.range(slot.start.clone(), slot.end.clone());

      const isContained = ranges.some((x) => x.contains(updatedSlot));
      if (isContained) return ranges;

      const adjacentIndex = ranges.findIndex((x) => x.adjacent(updatedSlot));
      if (arrayIndexFound(adjacentIndex)) {
        ranges[adjacentIndex] = momentExtended.range(ranges[adjacentIndex].start.clone(), updatedSlot.end.clone());
        return ranges;
      }

      return ranges.concat(updatedSlot);
    }, [] as DateRange[]);

    return availabilities.map((x) => {
      return {
        start_time: x.start.clone(),
        end_time: x.end.clone(),
      };
    });
  }, []);

  const checkAvailability = useCallback(
    (slot: DateRange) => {
      return availability.some((availableDate: ConferenceSchedulerSlot) => {
        if (isEmpty(availableDate)) return selectedByDefault;

        const availableRange = momentExtended.range(
          moment(availableDate.start_time).tz(timeZone),
          moment(availableDate.end_time).tz(timeZone)
        );

        return availableRange.contains(slot);
      }, selectedByDefaultProps);
    },
    [availability, selectedByDefaultProps, timeZone, selectedByDefault]
  );

  const getTimeSlots = useCallback(() => {
    const slots = formatTimetableDataWithTimezone(filteredSlots, timeZone);

    return schedulerDays?.reduce((timeslots: Timeslots, day: Moment, dayIdx: number) => {
      const currentDaySlots = (slots || []).filter((slot) => {
        return moment(slot.start_time).tz(timeZone).isSame(day, "day");
      });

      const availableSlots = currentDaySlots.reduce((slots: Timeslots, slot: ConferenceSchedulerSlot, slotIdx: number) => {
        const slotRange = momentExtended.range(slot.start_time, slot.end_time);
        const isSelected = () => {
          if (!isNil(initialAvailability) && isNoAvailability) return false;
          return hasAvailability ? checkAvailability(slotRange) : selectedByDefault;
        };

        slots[`day-${dayIdx}-timeslot-${slotIdx}`] = {
          slot: slotRange,
          isSelected: isSelected(),
        };
        return slots;
      }, {} as Timeslots);

      return { ...timeslots, ...availableSlots };
    }, {} as Timeslots);
  }, [
    checkAvailability,
    filteredSlots,
    hasAvailability,
    initialAvailability,
    isNoAvailability,
    schedulerDays,
    selectedByDefault,
    timeZone,
  ]);

  const handleTimeslotConversion = useCallback(
    (timeslots: Timeslots) => {
      const availability = convertTimeslotsToAvailabilityRanges(timeslots);
      onAvailabilityChange(formatTimetableDataWithTimezone(availability, timeZone));
      setTimeslotState(timeslots);
    },
    [convertTimeslotsToAvailabilityRanges, onAvailabilityChange, timeZone]
  );

  useEffect(() => {
    if (isEmpty(schedulerDays)) return;

    const timeslots = getTimeSlots();
    if (!isEqual(newTimezone.current, timeZone)) {
      newTimezone.current = timeZone;
      handleTimeslotConversion(timeslots);
    }

    if (isEmpty(timeslotState)) {
      if (selectedByDefault) {
        const availability = convertTimeslotsToAvailabilityRanges(timeslots);
        onAvailabilityChange(formatTimetableDataWithTimezone(availability, timeZone));
      }
      setTimeslotState(timeslots);
    }
  }, [
    convertTimeslotsToAvailabilityRanges,
    getTimeSlots,
    handleTimeslotConversion,
    onAvailabilityChange,
    schedulerDays,
    selectedByDefault,
    timeZone,
    timeslotState,
  ]);

  const handleTimeslotChange = useCallback(
    (key: keyof Timeslots, value: string) => {
      setTimeslotState((current) => {
        const updated = {
          ...current,
          [key]: {
            ...current[key],
            isSelected: JSON.parse(value),
          },
        };

        const availability = convertTimeslotsToAvailabilityRanges(updated);

        onAvailabilityChange(formatTimetableDataWithTimezone(availability, timeZone));

        return updated;
      });
    },
    [convertTimeslotsToAvailabilityRanges, onAvailabilityChange, timeZone]
  );

  return {
    availability,
    days: schedulerDays,
    timeslots: timeslotState,
    handleTimeslotChange,
    setTimeslot: setTimeslotState,
  };
};
