import { BaseComponentProps, ErrorModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { Question } from "../../services/questionGroup/questionGroup.model";
import { EntityTableRequiredFormKey } from "../entityTable/entityTable.definition";
import { QuestionCardWrapperIdModel } from "../questionCardWrapper/questionCardWrapper.definition";

export enum QuestionCardLabels {
  questionTitle = "Question",
  answerType = "Answer Type",
  placeholderText = "Placeholder Text",
  agreement = "Agreement Statement",
  longAnswer = "Long Answer",
  multipleAnswers = "Allow Multiple Answers",
  additionalOption = "Allow users to add additional options",
  sortOptions = "Sort options alphabetically",
  addOption = "Add Option",
  addOtherOption = 'Add "Other" Option',
}

export const QuestionDisplayLabels = {
  text: "Text Answer",
  choice: "Choice",
  consent: "Consent Agreement",
  select: "Select From Dropdown List",
};

export enum QuestionCardFieldLimit {
  Title = 140,
  Consent = 9001,
  Option = 50,
  Placeholder = 100,
}

export interface QuestionCardProps extends BaseComponentProps {
  question: Question;
  handleQuestionUpdate: (updated: Question) => void;
  handleQuestionMoveDown: () => void;
  handleQuestionMoveUp: () => void;
  idx: number;
  isMoveUpDisabled: boolean;
  isMoveDownDisabled: boolean;
  formKeys?: EntityTableRequiredFormKey[];
  formErrors?: Record<string, ErrorModel>;
  dataIndex?: number;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  handleQuestionRemove?: () => void;
  onDuplicate: (question: Question) => void;
}

export class QuestionCardIdModel extends IdModelBase {
  questionCardWrapper: QuestionCardWrapperIdModel;

  constructor(id: string, index?: string | number) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.questionCardWrapper = new QuestionCardWrapperIdModel(`${this.id}Wrapper`);
  }
}
