import { isEmpty, Grid, GridColumn, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import ObjectField, { FieldProps } from "@rjsf/core";
import React, { memo } from "react";
import { CustomLayoutProps } from "./layout.definition";

const JsonFormCustomLayout = <T extends ObjectField<T>>(props: FieldProps<T>): JSX.Element => {
  const { uiSchema, idSchema, required, registry, schema } = props;
  const { title: schemaTitle, description: schemaDescription } = schema || {};
  const { fields, formContext } = registry || {};
  const { SchemaField, TitleField, DescriptionField } = fields;

  const layout: CustomLayoutProps[] = uiSchema["ui:layout"];
  const useParentPrefix: boolean = uiSchema["useParentIdPrefix"] || false;

  return (
    <div className={"json-form_layout"}>
      {!isNullOrWhiteSpace(schemaTitle) && (
        <TitleField
          {...props}
          id={`${idSchema.$id}_title`}
          title={schemaTitle}
          required={required}
          formContext={formContext}
        />
      )}
      {!isNullOrWhiteSpace(schemaDescription) && (
        <DescriptionField
          {...props}
          id={`${idSchema.$id}_description`}
          description={schemaDescription}
          formContext={formContext}
        />
      )}

      {layout.map((row, i) => {
        return (
          <Grid key={`json-form_layout-row${i}`}>
            {!isNullOrWhiteSpace(row.title) && (
              <GridColumn className={"json-form_row-title"} width="1-of-1">
                <div>{row.title}</div>
              </GridColumn>
            )}
            {Object.keys(row.fields).map((col, j) => {
              const fieldName = col.toString();
              const { title, properties, description, ...restSchema } = schema || {};

              if (isEmpty(properties[fieldName])) return;

              const fieldSchema = {
                ...restSchema,
                properties: {
                  [fieldName]: properties[fieldName],
                },
              };

              const { [fieldName]: fieldUiSchemaProps } = uiSchema;
              const fieldUiSchema = { [fieldName]: fieldUiSchemaProps };

              return (
                <GridColumn width={row.fields[col].width} key={`json-form_layout-col${j}`}>
                  <SchemaField
                    {...props}
                    id={idSchema[fieldName]?.$id}
                    schema={fieldSchema}
                    uiSchema={fieldUiSchema}
                    idSchema={idSchema[fieldName]}
                    idPrefix={useParentPrefix ? idSchema?.$id : undefined}
                  />
                </GridColumn>
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
};

export default memo(JsonFormCustomLayout);
