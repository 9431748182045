import "./meetingScheduler.component.scss";
import {
  Card,
  isEmpty,
  Grid,
  GridColumn,
  isNil,
  LayoutPadding,
  Text,
  TextPreset,
  TextTheme,
  Toolbar,
  ToolbarTheme,
  Button,
  ButtonTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import { cloneDeep } from "lodash";
import type { Moment } from "moment";
import moment from "moment-timezone";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DefaultTimeZone, SchedulerDefaults } from "../../../../../../const";
import { DateTokenFormat } from "../../../../../../definitions/date.definition";
import { useAttendeeCorporateProfile } from "../../../../../../hooks";
import {
  AttendeeType,
  AttendeeViewModel,
  AttendeeTypesWithoutMeetings,
} from "../../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../../../../../services/meeting/meeting.model";
import { getTimeTableDaysDateRange } from "../../../../../../utils";
import { filterAvailableCorporateProfiles, formatPlannerDate } from "../../../../../../utils/scheduler/scheduler.utils";
import DayTabs from "./components/dayTabs/dayTabs.component";
import FlexibleScheduler from "./components/flexibleScheduler/flexibleScheduler.component";
import SchedulerFilter from "./components/schedulerFilter/schedulerFilter.component";
import SchedulerMetricBar from "./components/schedulerMetricBar/schedulerMetricBar.component";
import { MeetingSchedulerClassName, MeetingSchedulerIdModel, MeetingSchedulerProps } from "./meetingScheduler.definition";
import {
  getCalendarDays,
  attendingMeeting,
  formatMeetingCorporateAvailability,
  getCorporateRequests,
  getInvestorRequests,
  getEventDaysFromCalendarDays,
} from "./meetingScheduler.utils";

const MeetingScheduler = (props: MeetingSchedulerProps): JSX.Element => {
  const { id, codes, conference, companies, presentations, toolbarProps, useMeetings } = props;

  // #region States
  const attendeeState = useState<AttendeeViewModel>(null);
  const [selectedAttendee, setSelectedAttendee] = attendeeState;
  const corporateState = useState<CorporateProfile>(null);
  const [selectedCorporate, setSelectedCorporate] = corporateState;
  const [currentDayTabIndex, setCurrentDayTabIndex] = useState(0);
  const [currentCalendarDay, setCurrentCalendarDay] = useState<Moment>(null);
  const [selectedRequestType, setSelectedRequestType] = useState<number>(0);
  const originalMeetings = useRef<Meeting[]>([]);
  const [requestMeetings, setRequestMeetings] = useState<Meeting[]>([]);
  const [modalVisible, handleModalOpen, handleModalClose] = useVisibility();
  const [modalFullscreen, setModalFullscreen] = useState(false);
  // #endregion

  const idModel = useMemo(() => new MeetingSchedulerIdModel(id), [id]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone || DefaultTimeZone, [conference?.time_zone]);
  const { duration, break: tableBreak } = conference?.scheduler || {};

  // #region Hooks
  const { useAttendees, useCorporateProfile } = useAttendeeCorporateProfile({
    conferenceId: conference?._id,
    conferenceTimeZone,
    attendeeHookProps: {
      assignDefaultEntity: true,
    },
    corporateProfileHookProps: { assignDefaultEntity: true },
  });
  const { items: rawAttendees, fetchConferenceAttendees } = useAttendees;
  const { items: rawCorporates } = useCorporateProfile;
  const { current: currentMeeting, items: meetings, setCurrent: setCurrentMeeting, fetchConferenceMeetings } = useMeetings;

  // #endregion

  // #region Hook prop remaps
  const { calendarDays, eventDays, dayTabs } = useMemo(() => {
    const calendarDays = getCalendarDays(conference?.scheduler, conferenceTimeZone);
    const eventDays = getEventDaysFromCalendarDays(calendarDays, conference?.scheduler, conferenceTimeZone);
    const dayTabs = calendarDays.map((day) => ({
      value: `${day.valueOf()}`,
      label: formatPlannerDate(day, conferenceTimeZone),
    }));

    return {
      calendarDays,
      eventDays,
      dayTabs,
    };
  }, [conference?.scheduler, conferenceTimeZone]);

  const corporates = useMemo(() => {
    return (
      (!isEmpty(rawCorporates) &&
        filterAvailableCorporateProfiles(rawCorporates).sort((a, b) => a.name.localeCompare(b.name))) ||
      []
    );
  }, [rawCorporates]);
  const attendees = useMemo(
    () =>
      rawAttendees.filter(
        (rawAttendee: AttendeeViewModel) =>
          rawAttendee.type == (AttendeeType.Investor || AttendeeType.Internal) && !isEmpty(rawAttendee.availability)
      ),
    [rawAttendees]
  );
  const formAttendees = useMemo(
    () => rawAttendees.filter((rawAttendee: AttendeeViewModel) => !AttendeeTypesWithoutMeetings.includes(rawAttendee.type)),
    [rawAttendees]
  );
  // #endregion

  const step = useMemo(
    () => duration + tableBreak || SchedulerDefaults.duration + SchedulerDefaults.break,
    [tableBreak, duration]
  );

  // #region Effects
  useEffect(() => {
    if ((!isEmpty(conference) && !isNil(currentCalendarDay)) || isEmpty(calendarDays)) return;
    const tabDate = calendarDays[0];
    setCurrentCalendarDay(tabDate);
  }, [conference, currentCalendarDay, calendarDays]);

  // preload default selected attendee
  useEffect(() => {
    if (!isEmpty(selectedAttendee)) return;
    setSelectedAttendee(attendees.find((x) => x.type === AttendeeType.Investor && !isEmpty(x.availability)));
  }, [attendees, selectedAttendee, setSelectedAttendee]);

  // // preload default selected corporate
  useEffect(() => {
    if (!isEmpty(selectedCorporate)) return;
    setSelectedCorporate(corporates.find((x) => !isEmpty(x.attendees?.[0]?.availability)));
  }, [corporates, selectedCorporate, setSelectedCorporate]);

  useEffect(() => {
    if (isEmpty(calendarDays)) return;

    const day = calendarDays[currentDayTabIndex];
    if (isEmpty(day) || isEmpty(selectedCorporate?.attendees) || isEmpty(selectedAttendee)) return;

    let meetingRequests: { allDays?: Meeting[]; currentDay?: Meeting[] } = {};

    meetingRequests = (meetings || []).reduce(
      (allMeetings, meeting) => {
        const isAttendingMeeting = selectedRequestType
          ? attendingMeeting(selectedAttendee._id, meeting)
          : selectedCorporate.attendees.some((attendee) => attendingMeeting(attendee._id, meeting));

        // if meeting is not from selected one, skip
        if (!isAttendingMeeting) {
          return allMeetings;
        } else {
          const parsedMeeting = new Meeting(meeting);
          // if its on selected day
          if (day.isSame(meeting.start_date, "day")) {
            // append to meetings from day
            allMeetings.currentDay = allMeetings.currentDay.concat(parsedMeeting);
          }
          // append to all days meeting
          allMeetings.allDays = allMeetings.allDays.concat(parsedMeeting);
          return allMeetings;
        }
      },
      { allDays: [], currentDay: [] }
    );

    originalMeetings.current = cloneDeep(meetingRequests.currentDay);
    setRequestMeetings(cloneDeep(meetingRequests.allDays));
  }, [currentDayTabIndex, calendarDays, meetings, selectedAttendee, selectedCorporate?.attendees, selectedRequestType]);
  // #endregion

  const schedulerRequests = useMemo(() => {
    if (isEmpty(attendees) || isEmpty(corporates) || isEmpty(selectedAttendee) || isEmpty(selectedCorporate)) return [];
    if (selectedRequestType) {
      return getInvestorRequests(selectedAttendee, corporates);
    }
    return getCorporateRequests(selectedCorporate?._id, attendees, conferenceTimeZone);
  }, [attendees, conferenceTimeZone, corporates, selectedAttendee, selectedCorporate, selectedRequestType]);

  const dateRange = useMemo(() => getTimeTableDaysDateRange(calendarDays), [calendarDays]);

  // #region Handlers
  function handleRequestTypeChange(index: number) {
    setSelectedRequestType(index);
  }

  function handleSelectedAttendeeChange(option: AttendeeViewModel) {
    setSelectedAttendee(option);
  }

  function handleSelectedCorporateChange(option: CorporateProfile) {
    setSelectedCorporate(option);
  }

  function handleDayTabChange(index: number): void {
    if (isNil(index)) return;

    setCurrentDayTabIndex(index);

    const tabDate = calendarDays[index];
    setCurrentCalendarDay(tabDate);
  }

  const handleRefreshMeetingsAndAttendees = useCallback(async () => {
    // fetch conference attendees so that attendee picker dropdown in the left gets updated with new attendee information
    // fetch conference meetings so that other meetings in scheduler get updated
    await Promise.all([fetchConferenceAttendees(), fetchConferenceMeetings(conference?._id)]);

    const currentInvestor = currentMeeting?._attendee?.find((investor) => investor._id === selectedAttendee?._id);
    const currentCorporateAttendee = currentMeeting?._attendee?.find(
      (corporate) => (corporate._corporate_profile as CorporateProfile)?._id === selectedCorporate?._id
    );

    const currentCorporateAttendees = selectedCorporate?.attendees?.map((corpAttendee) => {
      if (corpAttendee._id === currentCorporateAttendee?._id)
        return new AttendeeViewModel({
          ...corpAttendee,
          availability: formatMeetingCorporateAvailability(currentCorporateAttendee?.availability, conference?.time_zone),
        });
      return corpAttendee;
    });

    setSelectedAttendee(currentInvestor);
    setSelectedCorporate({ ...selectedCorporate, attendees: currentCorporateAttendees });
  }, [
    conference?._id,
    conference?.time_zone,
    currentMeeting?._attendee,
    fetchConferenceAttendees,
    fetchConferenceMeetings,
    selectedAttendee?._id,
    selectedCorporate,
    setSelectedAttendee,
    setSelectedCorporate,
  ]);

  const handleAddNewClick = () => {
    setCurrentMeeting(null);
    setModalFullscreen(true);
    handleModalOpen();
  };

  return (
    <div id={idModel?.id} className={MeetingSchedulerClassName.Base}>
      <Toolbar id={toolbarProps?.id} theme={ToolbarTheme.Q4Blue} autoRow autoRowProps={{ justifyContent: "space-between" }}>
        {toolbarProps?.children}
        <Button id={idModel.addNew?.id} theme={ButtonTheme.Citrus} icon="q4i-add-4pt" onClick={handleAddNewClick} />
      </Toolbar>
      <div className={MeetingSchedulerClassName.Content}>
        <Grid>
          <GridColumn margin={false} width="1-of-5">
            <SchedulerFilter
              id={idModel.schedulerFilter.id}
              currentAttendee={selectedAttendee}
              currentCorporate={selectedCorporate}
              attendees={attendees}
              corporateProfiles={corporates}
              currentRequestType={selectedRequestType}
              requests={schedulerRequests}
              meetingRequests={requestMeetings}
              onTabChange={handleRequestTypeChange}
              onAttendeeChange={handleSelectedAttendeeChange}
              onCorporateChange={handleSelectedCorporateChange}
            />
            <SchedulerMetricBar
              id={idModel.schedulerMetricBar}
              currentAttendee={selectedAttendee}
              currentCorporate={selectedCorporate}
              currentRequestType={selectedRequestType}
              requests={schedulerRequests}
              step={step}
              meetingRequests={requestMeetings}
              presentations={presentations}
              timezone={conferenceTimeZone}
            />
          </GridColumn>
          <GridColumn width="4-of-5">
            <div className={MeetingSchedulerClassName.Header}>
              <div className={MeetingSchedulerClassName.HeaderSection}>
                <Text
                  id={idModel?.headerDate?.id}
                  className={MeetingSchedulerClassName.HeaderDate}
                  preset={TextPreset.Title}
                  theme={TextTheme.Slate}
                >
                  {dateRange}
                </Text>
                <Grid className={MeetingSchedulerClassName.Description}>
                  <GridColumn width="3-of-4" margin={false}>
                    <Text preset={TextPreset.Paragraph}>
                      Select a company or attendee and an available time slot to schedule meetings.
                    </Text>
                  </GridColumn>
                  <GridColumn className={MeetingSchedulerClassName.TimeZone} width="1-of-4" margin={false}>
                    <Text theme={TextTheme.LightSlate} preset={TextPreset.Base}>
                      ALL TIMES IN {moment(currentCalendarDay).tz(conferenceTimeZone).format(DateTokenFormat.TimeZone)}{" "}
                    </Text>
                  </GridColumn>
                </Grid>
              </div>
            </div>
            <Card id={idModel?.container?.id} layoutProps={{ padding: LayoutPadding.None, flex: false }}>
              <DayTabs
                id={idModel?.tabs.id}
                className={MeetingSchedulerClassName.DateTabs}
                items={dayTabs}
                onChange={handleDayTabChange}
              />
              <FlexibleScheduler
                id={idModel?.flexibleScheduler?.id}
                conference={conference}
                eventDay={eventDays[currentDayTabIndex]}
                presentations={presentations}
                useMeetings={useMeetings}
                codes={codes}
                companies={companies}
                mainResource={selectedCorporate}
                resources={[selectedAttendee]}
                formCorporateProfiles={rawCorporates}
                formAttendees={formAttendees}
                handleRefreshMeetingsAndAttendees={handleRefreshMeetingsAndAttendees}
                modalVisible={modalVisible}
                handleModalOpen={handleModalOpen}
                handleModalClose={handleModalClose}
                modalFullscreen={modalFullscreen}
                setModalFullscreen={setModalFullscreen}
              />
            </Card>
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
};
export default memo(MeetingScheduler);
