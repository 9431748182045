import {
  BaseComponentProps,
  ButtonIdModel,
  DatePickerIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextboxIdModel,
  TimePickerIdModel,
} from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import { Conference, ConferenceScheduler } from "../../../../../../../../services/conference/conference.model";

export interface MeetingShellCreatorProps extends BaseComponentProps {
  conference: Conference;
  scheduler: ConferenceScheduler;
  saving: boolean;
  setSchedule: Dispatch<SetStateAction<ConferenceScheduler>>;
  onSubmit: (schedule: ConferenceScheduler) => void;
}

export enum MeetingShellCreatorClassName {
  Base = "meeting-shell-creator",
  Content = "meeting-shell-creator-content",
  Form = "meeting-shell-creator-content_form",
  DateRange = "meeting-shell-creator-content_date-range",
  Button = "meeting-shell-creator-content_button",
}

export class MeetingShellCreatorIdModel extends IdModelBase {
  container: string;
  form: FormIdModel;
  dateRange: DatePickerIdModel;
  startTime: TimePickerIdModel;
  endTime: TimePickerIdModel;
  duration: TextboxIdModel;
  break: TextboxIdModel;
  createScheduleButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.container = `${this.id}MeetingShellCreatorContainer`;
    this.form = new FormIdModel(`${this.id}MeetingShellCreatorForm`);
    this.dateRange = new DatePickerIdModel(`${this.id}MeetingShellCreatorDateRangeDatePicker`);
    this.startTime = new TimePickerIdModel(`${this.id}MeetingShellCreatorStartTimeTimePicker`);
    this.endTime = new TimePickerIdModel(`${this.id}MeetingShellCreatorEndTimeTimePicker`);
    this.duration = new TextboxIdModel(`${this.id}MeetingShellCreatorDurationTextbox`);
    this.break = new TextboxIdModel(`${this.id}MeetingShellCreatorBreakTextbox`);
    this.createScheduleButton = new ButtonIdModel(`${this.id}MeetingShellCreatorButton`);
  }
}
