import { AutomationListId, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, TooltipIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { Conference } from "../../../../../../../services/conference/conference.model";

export interface ConferenceListProps extends BaseComponentProps {
  conferences?: Conference[];
  currentConference?: Conference;
  onConferenceSelect: (conference: Conference) => void;
}

export enum ConferenceListClassName {
  Base = "conference-list",
  Item = "conference-list_item",
  ItemWithInstructionModifier = "conference-list_item--instruction",
  Button = "conference-list_button",
  ButtonWithSelectedModifier = "conference-list_button--selected",
  Tooltip = "conference-list_tooltip",
}

export class ConferenceListIdModel extends IdModelBase {
  label: string;
  list: ConferenceListItemListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.list = new ConferenceListItemListId(null);
      return;
    }

    this.label = `${this.id}Label`;
    this.list = new ConferenceListItemListId(id);
  }
}

export class ConferenceListItemListId extends AutomationListId<ConferenceListItemIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, ConferenceListItemIdModel, postfix);
  }
}

export class ConferenceListItemIdModel extends IdModelBase {
  button: ButtonIdModel;
  tooltip: TooltipIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.button = new ButtonIdModel(null);
      this.tooltip = new TooltipIdModel(null);
      return;
    }

    this.button = new ButtonIdModel(`${this.id}Button`);
    this.tooltip = new TooltipIdModel(`${this.id}Tooltip`);
  }
}
