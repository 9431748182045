import {
  AnchorIdModel,
  BaseComponentProps,
  IdModelBase,
  isNullOrWhiteSpace,
  PopoverMenuButtonProps,
  PopoverMenuIdModel,
} from "@q4/nimbus-ui";
import React from "react";

export interface AnchorWithPopoverMenuProps extends BaseComponentProps {
  label: React.ReactNode;
  options: PopoverMenuButtonProps[];
}

export enum AnchorWithPopoverMenuClassName {
  Base = "anchor-popover-menu",
  Anchor = "anchor-popover-menu_anchor",
  PopoverMenu = "anchor-popover-menu_popover-menu",
  PopoverMenuItem = "anchor-popover-menu_popover-menu-item",
}

export class AnchorWithPopoverMenuIdModel extends IdModelBase {
  anchor: AnchorIdModel;
  popoverMenu: PopoverMenuIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.anchor = new AnchorIdModel(`${this.id}Anchor`);
    this.popoverMenu = new PopoverMenuIdModel(`${this.id}PopoverMenu`);
  }
}
