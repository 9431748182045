import { convertStringToEnum, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { DefaultInternalCompanyName } from "../../definitions/agenda.definition";
import { Entity } from "../../definitions/entity.definition";
import { fuzzyCompare, validateEmail } from "../../utils";
import { ApiResponse } from "../api/api.definition";
import { AttendeeType, AttendeeTypeDefault, AttendeeViewModel } from "../attendee/attendee.model";
import AttendeeService from "../attendee/attendee.service";
import { Conference } from "../conference/conference.model";
import { CorporateProfile, CorporateType } from "../corporateProfile/corporateProfile.model";
import CorporateProfileService from "../corporateProfile/corporateProfile.service";
import {
  CsvAttendeePermission,
  CsvBase,
  ImportAttendeeError,
  ImportOptions,
  ImportResult,
  ImportServiceBase,
} from "./import.definition";

export default class ImportAttendeeService implements ImportServiceBase<AttendeeViewModel> {
  private attendees: { entity: AttendeeViewModel; csv: CsvBase }[];
  private requests: (() => Promise<ApiResponse<AttendeeViewModel>>)[];
  private attendeeService = new AttendeeService();
  private corporateProfiles: CorporateProfile[];
  private corporateProfileService = new CorporateProfileService();

  get Data(): AttendeeViewModel[] {
    return this.attendees?.map((x) => x.entity) || [];
  }
  get HasSetting(): boolean {
    return true;
  }
  get Requests(): (() => Promise<ApiResponse<AttendeeViewModel>>)[] {
    return this.requests || [];
  }
  get Type(): Entity {
    return Entity.Attendee;
  }

  parse = async (options: ImportOptions): Promise<ImportResult<AttendeeViewModel>[]> => {
    const { csvJson, conference, handleResults, update } = options || {};
    if (isEmpty(csvJson) || isNullOrWhiteSpace(conference?._id)) return [];
    this.corporateProfiles = (await this.corporateProfileService.getByConferenceId(conference?._id)).data;
    const results: ImportResult<AttendeeViewModel>[] = [];
    this.attendees = await this.buildAttendeeData(csvJson, results, conference);
    const serviceRequest = !!update
      ? this.attendeeService.upsertAttendee.bind(this.attendeeService)
      : this.attendeeService.postAttendee.bind(this.attendeeService);
    this.requests = this.attendees.reduce((requests, attendeeData): (() => Promise<ApiResponse<AttendeeViewModel>>)[] => {
      if (isEmpty(attendeeData?.entity)) return requests;
      const { entity: attendee, csv } = attendeeData;
      return requests.concat((): Promise<ApiResponse<AttendeeViewModel>> => {
        return serviceRequest(attendee).then((response) => {
          const { success, message } = response;
          handleResults(
            this.Type,
            {
              ...response,
              data: success ? response.data : attendee,
              message,
              success,
            },
            "friendly_name",
            csv
          );

          return response;
        });
      });
    }, [] as (() => Promise<ApiResponse<AttendeeViewModel>>)[]);

    return results;
  };

  private sanitize(value: string): string {
    if (isNullOrWhiteSpace(value)) return "";
    return value.trim();
  }

  private getCodes(csv: CsvBase, codesBase?: string[]) {
    const existingCodes = isEmpty(codesBase) ? [] : codesBase;

    return Object.entries(csv).reduce((codes, entry) => {
      const [event, code] = entry;
      const sanitizedEvent = this.sanitize(event);
      if (isNullOrWhiteSpace(sanitizedEvent)) return codes;

      return fuzzyCompare(code, CsvAttendeePermission.Access) ? codes.concat(sanitizedEvent) : codes;
    }, existingCodes);
  }

  private validate(attendee: AttendeeViewModel): ImportAttendeeError {
    if (isEmpty(attendee)) return ImportAttendeeError.Empty;
    if (!validateEmail(attendee.email)) return ImportAttendeeError.Email;
    if (attendee.type === AttendeeType.Corporate && !attendee._corporate_profile)
      return ImportAttendeeError.CorporateProfileEmpty;
    if (!validateEmail(attendee.email)) return ImportAttendeeError.Email;
    return null;
  }

  private async buildAttendeeData(
    csvJson: CsvBase[],
    results: ImportResult<AttendeeViewModel>[],
    conference: Conference
  ): Promise<{ entity: AttendeeViewModel; csv: CsvBase }[]> {
    const attendees: { entity: AttendeeViewModel; csv: CsvBase }[] = [];
    for (const csv of csvJson) {
      if (isEmpty(csv)) {
        continue;
      }
      const attendee: AttendeeViewModel = this.buildDefaultAttendee(conference, csv);

      await this.setAttendeeType(conference, attendee, csv);

      const parseError = this.validate(attendee);
      if (!isEmpty(parseError)) {
        results.push(this.getParseError(parseError, attendee, csv));
        attendees.push({ entity: null, csv });
        continue;
      }
      attendees.push({ entity: attendee, csv });
    }
    return attendees;
  }

  setAttendeeType = async (conference: Conference, attendee: AttendeeViewModel, csv: CsvBase): Promise<void> => {
    if (attendee.type !== AttendeeType.Guest) {
      attendee.receive_emails = false;
    }
    attendee.participate_in_meetings = undefined;
    if (attendee.type === AttendeeType.Corporate) {
      await this.buildCorporateAttendee(conference, attendee);
    }
    if (attendee.type === AttendeeType.Internal) {
      attendee.company = isNullOrWhiteSpace(conference?._company?.name)
        ? DefaultInternalCompanyName
        : conference._company.name;
    }
    if (attendee.type !== AttendeeType.Internal) {
      attendee.sales_representative = csv.sales_rep?.trim();
    }
  };

  getParseError = (
    parseError: ImportAttendeeError,
    attendee: AttendeeViewModel,
    csv: CsvBase
  ): ImportResult<AttendeeViewModel> => {
    return new ImportResult({
      type: this.Type,
      entity: attendee,
      title: attendee.friendly_name,
      csv,
      errorMessage: parseError,
    });
  };

  createCorporateProfile = async (
    conference: Conference,
    company: string,
    attendee: AttendeeViewModel
  ): Promise<CorporateProfile> => {
    const corporateProfile = new CorporateProfile({
      _conference: conference._id,
      _primary_contact: attendee.is_primary_contact ? attendee._id : undefined,
      name: this.sanitize(company),
      type: CorporateType.Private,
      ticker_symbol: "-",
      exchange: "-",
    });
    const { data } = await this.corporateProfileService.post(corporateProfile);
    this.corporateProfiles.push(data);
    return data;
  };

  getCorporateProfile = async (
    conference: Conference,
    company: string,
    attendee: AttendeeViewModel
  ): Promise<CorporateProfile> => {
    return (
      this.corporateProfiles?.find((x) => x.name === company) ??
      (await this.createCorporateProfile(conference, company, attendee))
    );
  };

  buildDefaultAttendee = (conference: Conference, csv: CsvBase): AttendeeViewModel => {
    const {
      first: first_name,
      last: last_name,
      code: codeCsv,
      company,
      email,
      status: statusCsv,
      title,
      phone: phone_number,
      type: typeCsv,
      sales_rep,
      ...rest
    } = csv;

    const attendeeType = convertStringToEnum(AttendeeType, typeCsv);
    const type = isNullOrWhiteSpace(attendeeType) ? AttendeeTypeDefault : attendeeType;

    const codesBase = isNullOrWhiteSpace(codeCsv) ? [] : [codeCsv];
    const codes = this.getCodes(rest, codesBase);
    const attendee: AttendeeViewModel = new AttendeeViewModel({
      _conference: conference._id,
      company: this.sanitize(company),
      title,
      phone_number,
      email: this.sanitize(email),
      first_name: this.sanitize(first_name),
      last_name: this.sanitize(last_name),
      type,
      code: codes,
    });

    attendee.receive_emails = undefined;
    return attendee;
  };

  buildCorporateAttendee = async (conference: Conference, attendee: AttendeeViewModel): Promise<void> => {
    const corporateProfile: CorporateProfile = await this.getCorporateProfile(conference, attendee.company, attendee);
    attendee._corporate_profile = corporateProfile?._id;
    attendee.participate_in_meetings = true;
  };
}
