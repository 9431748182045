import { Anchor, AnchorTarget, AnchorTheme, Badge, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { isNil } from "lodash";
import moment from "moment";
import React, { memo } from "react";
import { DefaultTimeZone } from "../../../../../../const";
import { ConferenceType } from "../../../../../../services/conference/conference.model";
import { formatTimeZoneLabel, getConferenceStatusTheme } from "../../../../../../utils";
import { DETAILS_DATE_FORMAT } from "../../conferenceDetails.definition";
import { ConferenceBannerDetailsIdModel, ConferenceBannerDetailsProps } from "./conferenceBannerDetails.definition";

export const ConferenceBannerDetails = (props: ConferenceBannerDetailsProps): JSX.Element => {
  const { registrationUrl, lobbyUrl, loginUrl, profileUrl, conference, id } = props;

  const idModel = new ConferenceBannerDetailsIdModel(id);

  const timeZone = conference?.time_zone ?? DefaultTimeZone;

  const conferenceType = conference?.conference_type ?? ConferenceType.FullConference;
  const hasStatus = !isNil(conference?.Status);

  function renderDate(): JSX.Element {
    const startDate = conference?.start_date?.isValid()
      ? conference.start_date.clone().tz(timeZone)?.format(DETAILS_DATE_FORMAT)
      : "";
    const endDate = conference?.end_date?.isValid()
      ? conference.end_date.clone().tz(timeZone)?.format(DETAILS_DATE_FORMAT)
      : "";
    const headTimeZoneString = formatTimeZoneLabel(timeZone, moment(conference?.start_date));
    const tailTimeZoneString = formatTimeZoneLabel(timeZone, moment(conference?.end_date));

    let date: JSX.Element = null;

    if (!isNullOrWhiteSpace(startDate) && !isNullOrWhiteSpace(endDate)) {
      date = (
        <>
          {startDate}{" "}
          {headTimeZoneString !== tailTimeZoneString ? (
            <span id={idModel.headTimeZoneString}>{headTimeZoneString}</span>
          ) : (
            ""
          )}{" "}
          to {endDate} <span id={idModel.tailTimeZoneString}>{tailTimeZoneString}</span>
        </>
      );
    }

    if (!isNullOrWhiteSpace(startDate) && isNullOrWhiteSpace(endDate)) {
      date = (
        <>
          {startDate} <span id={idModel.headTimeZoneString}>{headTimeZoneString}</span> to unassigned
        </>
      );
    }

    if (isNullOrWhiteSpace(startDate) && !isNullOrWhiteSpace(endDate)) {
      date = (
        <>
          unassigned to {endDate} <span id={idModel.tailTimeZoneString}>{tailTimeZoneString}</span>
        </>
      );
    }

    return <span id={idModel.date}>{date}</span>;
  }

  return (
    <div id={idModel.id}>
      <p>
        {renderDate()}
        {hasStatus && (
          <Badge id={idModel.status.id} theme={getConferenceStatusTheme(conference?.Status)}>
            {conference?.Status}
          </Badge>
        )}
      </p>
      <p>Conference Type: {conferenceType}</p>
      <p>
        Registration Link:{" "}
        <Anchor id={idModel.registration.id} url={registrationUrl} theme={AnchorTheme.Citrus} target={AnchorTarget.Blank}>
          {registrationUrl}
        </Anchor>
        <br />
        Login Link:{" "}
        <Anchor id={idModel.login.id} url={loginUrl} theme={AnchorTheme.Citrus} target={AnchorTarget.Blank}>
          {loginUrl}
        </Anchor>
        <br />
        Lobby Link:{" "}
        <Anchor id={idModel.lobby.id} url={lobbyUrl} theme={AnchorTheme.Citrus} target={AnchorTarget.Blank}>
          {lobbyUrl}
        </Anchor>
        <br />
        Profile Link:{" "}
        <Anchor id={idModel.profile.id} url={profileUrl} theme={AnchorTheme.Citrus} target={AnchorTarget.Blank}>
          {profileUrl}
        </Anchor>
      </p>
    </div>
  );
};

export default memo(ConferenceBannerDetails);
