import "./additionalInformation.component.scss";
import {
  Form,
  InfoIcon,
  Origin,
  TooltipTheme,
  TextboxClassName,
  TextboxTheme,
  TextareaIdModel,
  InfoIconIdModel,
} from "@q4/nimbus-ui";
import React, { ChangeEvent, memo, useMemo, useCallback } from "react";
import GridCard from "../../../../../../components/gridCard/gridCard.component";
import { ConferenceEditState } from "../../conferenceEdit.definition";
import {
  ConferenceAdditionalInformationEditProps,
  ConferenceAdditionalInformationEditIdModel,
  additionalInformationFields,
} from "./additionalInformation.definition";

const ConferenceAdditionalInformationEdit = (props: ConferenceAdditionalInformationEditProps): JSX.Element => {
  const { id, conference, onChange } = props;

  const idModel = useMemo(() => new ConferenceAdditionalInformationEditIdModel(id), [id]);

  const getTextareaChangeHandler = useCallback(
    (key: keyof ConferenceEditState) => {
      return function (event: ChangeEvent<HTMLTextAreaElement>): void {
        event.persist();

        const value = event?.target?.value;

        onChange({ [key]: value });
      };
    },
    [onChange]
  );

  const fields = useMemo(() => {
    return additionalInformationFields.map((field) => ({
      key: field.key,
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: (
        <>
          {field.label}
          <InfoIcon
            id={(idModel[field.tooltipId] as InfoIconIdModel)?.id}
            tooltipProps={{
              label: field.tooltip,
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          />
        </>
      ),
      children: (
        <div
          id={(idModel[field.id] as TextareaIdModel).id}
          className={`${field.className} ${TextboxClassName.Base} nui-textbox--textarea ${TextboxClassName.Base}--${TextboxTheme.LightGrey}`}
        >
          <textarea
            id={(idModel[field.id] as TextareaIdModel).input}
            className={TextboxClassName.Input}
            value={conference?.[field.value] as string}
            onChange={getTextareaChangeHandler(field.value)}
            maxLength={field.maxLength}
          />
          <div id={(idModel[field.id] as TextareaIdModel).focus} className={TextboxClassName.Focus} />
        </div>
      ),
    }));
  }, [conference, idModel, getTextareaChangeHandler]);

  return (
    <div id={idModel.id}>
      <GridCard cardProps={{ title: "Additional Information" }}>
        <Form fields={fields} />
      </GridCard>
    </div>
  );
};

export default memo(ConferenceAdditionalInformationEdit);
