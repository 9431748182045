import {
  BaseComponentProps,
  ComboBoxIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextareaIdModel,
  TextboxIdModel,
  CheckboxIdModel,
  AnchorIdModel,
  TextIdModel,
  FileUploaderIdModel,
  ErrorModel,
} from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { AttendeesHookModel } from "../../hooks/useAttendees/useAttendees.definition";
import type { Answer } from "../../services/answer/answer.model";
import type { Conference } from "../../services/conference/conference.model";
import type { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import type { QuestionGroup } from "../../services/questionGroup/questionGroup.model";
import { AttendeeModalIdModel } from "../attendee/modal/attendeeModal.definition";
import { QuestionsFormIdModel } from "../questionsForm/questionsForm.definition";

export enum CorporateProfileFormClassName {
  Base = "corporate-profile-form",
  Description = "corporate-profile-form_description",
  QuestionsHeader = "corporate-profile-form_questions-header",
}

export enum CorporateProfileAvailabilityFormClassName {
  Base = "corporate-profile-availability-form",
  Subheader = "corporate-profile-availability-form_subheader",
}

export interface CorporateProfileFormProps extends BaseComponentProps {
  conference: Conference;
  corporateProfile: CorporateProfile;
  questionGroup: QuestionGroup;
  answers: Answer[];
  loading?: boolean;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  onCorporateProfileUpdate: (data: Partial<CorporateProfile>) => void;
  useAttendees: AttendeesHookModel;
}

export class CorporateProfileFormIdModel extends IdModelBase {
  name: TextboxIdModel;
  description: TextareaIdModel;
  website: TextboxIdModel;
  type: SelectIdModel;
  exchange: TextboxIdModel;
  ticker: TextboxIdModel;
  logo: FileUploaderIdModel;
  secondaryLogo: FileUploaderIdModel;

  hostMeetings: CheckboxIdModel;
  hostPresentation: CheckboxIdModel;
  presentationTitle: TextboxIdModel;
  presentationDuration: TextboxIdModel;
  presentationDescription: TextareaIdModel;
  presentationFile: string;
  presentationShareable: CheckboxIdModel;

  primaryContact: SelectIdModel;

  addAttendee: AnchorIdModel;
  attendees: ComboBoxIdModel;
  attendeeModal: AttendeeModalIdModel;

  questionsForm: QuestionsFormIdModel;
  questionsHeader: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.primaryContact = new SelectIdModel(`${this.id}PrimaryContactSelect`);

    this.name = new TextboxIdModel(`${this.id}NameTextbox`);
    this.description = new TextareaIdModel(`${this.id}DescriptionTextarea`);
    this.website = new TextareaIdModel(`${this.id}WebsiteTextarea`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.exchange = new TextareaIdModel(`${this.id}ExchangeTextarea`);
    this.ticker = new TextareaIdModel(`${this.id}TickerTextarea`);
    this.logo = new FileUploaderIdModel(`${this.id}LogoUploader`);
    this.secondaryLogo = new FileUploaderIdModel(`${this.id}SecondaryLogoUploader`);

    this.hostMeetings = new CheckboxIdModel(`${this.id}HostMeetingsCheckbox`);
    this.hostPresentation = new CheckboxIdModel(`${this.id}HostPresentationCheckbox`);
    this.presentationTitle = new TextboxIdModel(`${this.id}PresentationTitleTextbox`);
    this.presentationDuration = new TextboxIdModel(`${this.id}PresentationDurationTextbox`);
    this.presentationDescription = new TextareaIdModel(`${this.id}PresentationDescriptionTextarea`);
    this.presentationShareable = new CheckboxIdModel(`${this.id}PresentationShareableCheckbox`);

    this.addAttendee = new AnchorIdModel(`${this.id}AddAttendeeAnchor`);
    this.attendees = new ComboBoxIdModel(`${this.id}AttendeeComboBox`);
    this.attendeeModal = new AttendeeModalIdModel(`${this.id}AttendeeModal`);

    this.questionsForm = new QuestionsFormIdModel(`${this.id}QuestionsForm`);
    this.questionsHeader = new TextIdModel(`${this.id}QuestionsHeader`);
  }
}
