import type { BaseComponentProps, AnchorTarget } from "@q4/nimbus-ui";
import type { ReactNode } from "react";
import { AdminProfileRoleAccess } from "../services/admin/adminProfile/adminProfile.model";
import { ConferenceCompanyQueryParam } from "../views/admin/conferences/conferences.definition";

export interface NavigationRoute extends BaseComponentProps {
  path: string;
  children?: string[];
  label: ReactNode;
  icon: string;
  target?: AnchorTarget;
  minRequiredRole: AdminProfileRoleAccess;
}

export const AdminRouteBase = "/admin";
export const UserRouteBase = "/user";
export const PublicRouteBase = "/public";
export const LoginRoute = "/login";
export const RegistrationRoute = "/register";
export const PresentationRoute = "/presentation";
export const MeetingRoute = "/meeting";
export const OnDemandRoute = "/ondemandpresentation";
export const LinkRoute = "/link";
export const AttendeeProfileRoute = "/profile";
export const NotFoundRoute = "/pagenotfound";

export enum RoutePathIdLabel {
  Conference = ":conferenceId",
  ConferencePath = ":conferencePath",
  Company = ":company",
  CustomCompany = ":custom",
  IdToken = ":idToken",
  SessionId = ":sessionId",
  LinkId = ":linkId",
}

const callbackPath = "/callback";

// Company
const companyPath = `/company/${RoutePathIdLabel.Company}`;
const customCompanyPath = `/${RoutePathIdLabel.CustomCompany}`;

// Company Registration
const companyRegistrationPath = `${companyPath}${RegistrationRoute}`;
const customCompanyRegistrationPath = `${customCompanyPath}${RegistrationRoute}`;

// Company Presentation
const companyPresentationPath = `${companyPath}${PresentationRoute}`;
const customCompanyPresentationPath = `${customCompanyPath}${PresentationRoute}`;

// Company Meeting
const companyMeetingPath = `${companyPath}${MeetingRoute}`;
const customCompanyMeetingPath = `${customCompanyPath}${MeetingRoute}`;

// Company OnDemand
const companyOnDemandPath = `${companyPath}${OnDemandRoute}`;
const customCompanyOnDemandPath = `${customCompanyPath}${OnDemandRoute}`;

// Company Attendee Profile
const companyAttendeeProfilePath = `${companyPath}${AttendeeProfileRoute}`;
const customCompanyAttendeeProfilePath = `${customCompanyPath}${AttendeeProfileRoute}`;

// Conference
const conferencePath = `${companyPath}/${RoutePathIdLabel.ConferencePath}`;
const conferenceCustomCompanyPath = `${customCompanyPath}/${RoutePathIdLabel.ConferencePath}`;

// Conference Registration
const conferenceRegistrationPath = `${companyRegistrationPath}/${RoutePathIdLabel.ConferencePath}`;
const conferenceCustomCompanyRegistrationPath = `${customCompanyRegistrationPath}/${RoutePathIdLabel.ConferencePath}`;

// Conference Presentation
const conferencePresentationPath = `${conferencePath}${PresentationRoute}`;
const conferenceCustomCompanyPresentationPath = `${conferenceCustomCompanyPath}${PresentationRoute}`;

// Conference Meeting
const conferenceMeetingPath = `${conferencePath}${MeetingRoute}`;
const conferenceCustomCompanyMeetingPath = `${conferenceCustomCompanyPath}${MeetingRoute}`;

// Conference OnDemand
const conferenceOnDemandPath = `${conferencePath}${OnDemandRoute}`;
const conferenceCustomCompanyOnDemandPath = `${conferenceCustomCompanyPath}${OnDemandRoute}`;

// Conference Attendee Profile
const conferenceAttendeeProfilePath = `${conferencePath}${AttendeeProfileRoute}`;
const conferenceCustomAttendeeProfilePath = `${conferenceCustomCompanyPath}${AttendeeProfileRoute}`;

const linkPath = `${LinkRoute}/${RoutePathIdLabel.LinkId}`;

export const RoutePath = {
  // Public
  Home: "/",

  // Not Found
  NotFound: NotFoundRoute,

  // Callback
  AdminCallback: `${AdminRouteBase}${callbackPath}`,

  // Login
  CompanyLogin: `${companyPath}${LoginRoute}`,
  CustomLogin: `${customCompanyPath}${LoginRoute}`,
  ConferenceLogin: `${companyPath}${LoginRoute}/${RoutePathIdLabel.ConferencePath}`,
  ConferenceLoginWithCustomCompany: `${customCompanyPath}${LoginRoute}/${RoutePathIdLabel.ConferencePath}`,

  // Link
  Link: LinkRoute,
  LinkPath: linkPath,

  // Registration
  CompanyRegistration: companyRegistrationPath,
  CompanyRegistrationWithConferenceId: conferenceRegistrationPath,
  CustomRegistration: customCompanyRegistrationPath,
  CustomRegistrationWithConferenceId: conferenceCustomCompanyRegistrationPath,

  // Lobby
  Company: companyPath,
  Custom: customCompanyPath,
  ConferenceLobby: conferencePath,
  ConferenceLobbyWithCustomCompany: conferenceCustomCompanyPath,

  // Presentation Theatre
  CompanyPresentation: companyPresentationPath,
  CompanyPresentationWithId: `${companyPresentationPath}/${RoutePathIdLabel.SessionId}`,
  CustomPresentation: customCompanyPresentationPath,
  CustomPresentationWithId: `${customCompanyPresentationPath}/${RoutePathIdLabel.SessionId}`,
  ConferencePresentation: conferencePresentationPath,
  ConferencePresentationWithId: `${conferencePresentationPath}/${RoutePathIdLabel.SessionId}`,
  ConferenceCustomCompanyPresentation: conferenceCustomCompanyPresentationPath,
  ConferenceCustomCompanyPresentationWithId: `${conferenceCustomCompanyPresentationPath}/${RoutePathIdLabel.SessionId}`,

  // Attendee Profile
  CompanyAttendeeProfilePath: companyAttendeeProfilePath,
  CustomCompanyAttendeeProfilePath: customCompanyAttendeeProfilePath,
  ConferenceAttendeeProfilePath: conferenceAttendeeProfilePath,
  ConferenceCustomAttendeeProfilePath: conferenceCustomAttendeeProfilePath,

  // Meeting
  CompanyMeeting: companyMeetingPath,
  CompanyMeetingWithId: `${companyMeetingPath}/${RoutePathIdLabel.SessionId}`,
  CustomMeeting: customCompanyMeetingPath,
  CustomMeetingWithId: `${customCompanyMeetingPath}/${RoutePathIdLabel.SessionId}`,
  ConferenceMeeting: conferenceMeetingPath,
  ConferenceMeetingWithId: `${conferenceMeetingPath}/${RoutePathIdLabel.SessionId}`,
  ConferenceCustomCompanyMeeting: conferenceCustomCompanyMeetingPath,
  ConferenceCustomCompanyMeetingWithId: `${conferenceCustomCompanyMeetingPath}/${RoutePathIdLabel.SessionId}`,

  // On Demand
  CompanyOnDemand: companyOnDemandPath,
  CompanyOnDemandWithId: `${companyOnDemandPath}/${RoutePathIdLabel.SessionId}`,
  CustomOnDemand: customCompanyOnDemandPath,
  CustomOnDemandWithId: `${customCompanyOnDemandPath}/${RoutePathIdLabel.SessionId}`,
  ConferenceOnDemand: conferenceOnDemandPath,
  ConferenceOnDemandWithId: `${conferenceOnDemandPath}/${RoutePathIdLabel.SessionId}`,
  ConferenceCustomCompanyOnDemand: conferenceCustomCompanyOnDemandPath,
  ConferenceCustomCompanyOnDemandWithId: `${conferenceCustomCompanyOnDemandPath}/${RoutePathIdLabel.SessionId}`,

  // Admin
  AdminHome: AdminRouteBase,
  AdminHomeRedirect: `${AdminRouteBase}/*`,
  Companies: `${AdminRouteBase}/company`,
  Conferences: `${AdminRouteBase}/conference`,
  ConferencesByCompany: `${AdminRouteBase}/conference?${ConferenceCompanyQueryParam}=${RoutePathIdLabel.Company}`,
  ConferenceNew: `${AdminRouteBase}/conference/edit`,
  ConferenceEdit: `${AdminRouteBase}/conference/edit/${RoutePathIdLabel.Conference}`,
  ConferenceDetails: `${AdminRouteBase}/conference/details/${RoutePathIdLabel.Conference}`,
};

export const NavigationBarRoutes = [
  {
    path: RoutePath.Companies,
    label: "Companies",
    icon: "q4i-conference-4pt",
    minRequiredRole: AdminProfileRoleAccess.SystemAdmin,
  },
  {
    path: RoutePath.Conferences,
    children: [RoutePath.ConferenceDetails, RoutePath.ConferenceNew, RoutePath.ConferenceEdit],
    label: "Conferences",
    icon: "q4i-conference-4pt",
    minRequiredRole: AdminProfileRoleAccess.ClientAdmin,
  },
];
