import "./importConfirm.component.scss";
import { isEmpty } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import RequestProgressResultsTable from "../../../../components/requestProgressStatus/components/results/requestProgressResultsTable.component";
import type { EntityBase } from "../../../../definitions/entity.definition";
import type { ImportConfirmProps } from "./importConfirm.definition";

const ImportConfirm = <T extends EntityBase>(props: ImportConfirmProps<T>): JSX.Element => {
  const { totalCount, results, disabled } = props;

  const validCount = useMemo(() => totalCount - (results?.length ?? 0), [results, totalCount]);

  return (
    <div className="import-confirm">
      <div className="import-confirm_label">
        Your import feed has <span className="import-confirm_value">{totalCount}</span> items, and
        <span className="import-confirm_value"> {validCount}</span> are valid and can be imported.
      </div>
      {disabled ? (
        <div className="import-confirm_label">As no valid items could be found, please check the feed and import form.</div>
      ) : (
        <div className="import-confirm_label">Click CONFIRM to start the import of this feed.</div>
      )}
      {!isEmpty(results) && <RequestProgressResultsTable rows={results} showExportErrors={true} />}
    </div>
  );
};

export default memo(ImportConfirm);
