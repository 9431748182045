import { Textbox, Form } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { Question } from "../../../services/questionGroup/questionGroup.model";
import { QuestionCardWrapper } from "../../questionCardWrapper/questionCardWrapper.component";
import { QuestionCardFieldLimit, QuestionDisplayLabels } from "../questionCard.definition";
import { ConsentQuestionIdModel, ConsentQuestionProps } from "./consentQuestion.definition";

export function ConsentQuestion(props: ConsentQuestionProps): JSX.Element {
  const { id, currentQuestion, handleQuestionUpdate, ...cardProps } = props;

  const idModel = useMemo(() => new ConsentQuestionIdModel(id), [id]);

  const question = useMemo(
    () =>
      new Question({
        ...currentQuestion,
        place_holder: currentQuestion.place_holder ?? "I agree",
      }),
    [currentQuestion]
  );

  const handleStatementChange = useCallback(
    (value: string): void => {
      handleQuestionUpdate(
        new Question({
          ...question,
          place_holder: value,
        })
      );
    },
    [question, handleQuestionUpdate]
  );

  const configFields = useMemo(() => {
    return (
      <Form
        fields={[
          {
            key: "Agreement Statement",
            width: "1-of-1",
            label: "Agreement Statement",
            children: (
              <Textbox
                id={idModel.statement.id}
                maxLength={QuestionCardFieldLimit.Placeholder}
                placeholder="I agree"
                value={question?.place_holder}
                onChange={handleStatementChange}
              />
            ),
          },
        ]}
      />
    );
  }, [handleStatementChange, idModel.statement.id, question?.place_holder]);

  return (
    <QuestionCardWrapper
      id={idModel.id}
      currentQuestionTypeLabel={QuestionDisplayLabels.consent}
      typeConfigurationFields={null}
      textConfigurationFields={configFields}
      currentQuestion={currentQuestion}
      handleQuestionUpdate={handleQuestionUpdate}
      {...cardProps}
    />
  );
}

export default memo(ConsentQuestion);
