import { Textbox, Checkbox, Form } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { QuestionType } from "../../../services/questionGroup/questionGroup.model";
import { QuestionCardWrapper } from "../../questionCardWrapper/questionCardWrapper.component";
import { QuestionTemporaryId } from "../../registrationForm/registrationForm.definition";
import { QuestionCardFieldLimit, QuestionCardLabels } from "../questionCard.definition";
import { TextQuestionProps, TextQuestionIdModel } from "./textQuestion.definition";

export function TextQuestion(props: TextQuestionProps): JSX.Element {
  const { id, currentQuestion, currentQuestionTypeLabel, handleQuestionUpdate, ...cardProps } = props;
  const { _id: questionId, place_holder, answer_type } = currentQuestion || {};

  const idModel = useMemo(() => new TextQuestionIdModel(id), [id]);

  const isLongAnswer = useMemo(() => answer_type?.field_type === QuestionType.LongAnswer, [answer_type]);
  const isAnswerTypeEditable = useMemo(() => questionId?.includes(QuestionTemporaryId), [questionId]);

  const handleLongAnswerChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        answer_type: {
          ...currentQuestion?.answer_type,
          field_type: value ? QuestionType.LongAnswer : QuestionType.ShortAnswer,
        },
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const handlePlaceholderTextChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        place_holder: value,
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const answerConfigFields = useMemo(
    () => (
      <Checkbox
        id={idModel.longAnswer.id}
        checked={isLongAnswer}
        label={QuestionCardLabels.longAnswer}
        disabled={!isAnswerTypeEditable}
        onChange={handleLongAnswerChange}
      />
    ),
    [handleLongAnswerChange, isAnswerTypeEditable, idModel.longAnswer.id, isLongAnswer]
  );

  const answerTextTypeFields = useMemo(() => {
    return (
      <Form
        fields={[
          {
            key: "Placeholder Text",
            width: "1-of-1",
            label: "Placeholder Text",
            children: (
              <Textbox
                id={idModel.placeholder.id}
                placeholder="Hint or example (placeholder)"
                maxLength={QuestionCardFieldLimit.Placeholder}
                value={place_holder}
                onChange={handlePlaceholderTextChange}
              />
            ),
          },
        ]}
      />
    );
  }, [handlePlaceholderTextChange, idModel.placeholder.id, place_holder]);

  return (
    <QuestionCardWrapper
      id={idModel.id}
      currentQuestionTypeLabel={currentQuestionTypeLabel}
      typeConfigurationFields={answerConfigFields}
      textConfigurationFields={answerTextTypeFields}
      currentQuestion={currentQuestion}
      handleQuestionUpdate={handleQuestionUpdate}
      {...cardProps}
    />
  );
}

export default memo(TextQuestion);
