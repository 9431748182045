import { AnchorTarget, Button, ButtonTheme, isEmpty, isNullOrWhiteSpace, ToolbarTheme } from "@q4/nimbus-ui";
import type {
  GetQuickFilterTextParams,
  ICellRendererParams,
  ValueFormatterParams,
} from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useMemo, useRef } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { EntityTableRef } from "../../../../../../components/entityTable/entityTable.definition";
import OnDemandForm from "../../../../../../components/onDemandForm/onDemandForm.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { OnDemand } from "../../../../../../services/admin/onDemand/onDemand.model";
import { Speaker } from "../../../../../../services/speaker/speaker.model";
import {
  OnDemandClassName,
  OnDemandEditClassName,
  OnDemandIdModel,
  OnDemandTableHeader,
  OnDemandPusherChannel,
} from "./onDemand.definition";
import type { OnDemandProps } from "./onDemand.definition";

const OnDemandPresentations = (props: OnDemandProps): JSX.Element => {
  const { id, codes, conference, speakers, useOnDemand, user } = props;

  const idModel = useMemo(() => new OnDemandIdModel(id), [id]);

  const tableRef = useRef<EntityTableRef>(null);

  const { current: onDemand, loading: presentationsLoading, setCurrent: setOnDemand } = useOnDemand;

  const loading = useMemo(() => presentationsLoading, [presentationsLoading]);

  const tableProps = {
    columnDefs: [
      {
        field: "order",
        sort: "asc",
        headerName: "Order",
        maxWidth: 120,
        flex: 1,
      },
      {
        field: "title",
        headerName: OnDemandTableHeader.Name,
        minWidth: 180,
        flex: 1,
      },
      {
        field: "_speaker",
        headerName: OnDemandTableHeader.Speaker,
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        valueFormatter: getFormattedSpeaker,
        getQuickFilterText: getFormattedSpeaker,
      },
      {
        field: "_id",
        headerName: OnDemandTableHeader.View,
        sortable: false,
        minWidth: 110,
        maxWidth: 110,
        cellRenderer: "viewCellRenderer",
      },
    ],
    frameworkComponents: {
      viewCellRenderer: renderViewCell,
    },
    loading,
  };

  const editForm = (
    <div className={OnDemandEditClassName.Base}>
      <OnDemandForm
        id={idModel.form?.id}
        conference={conference}
        codes={codes}
        onDemand={onDemand}
        speakers={speakers}
        onUpdate={handlePresentationUpdate}
      />
    </div>
  );

  function handlePresentationUpdate(data: Partial<OnDemand>) {
    tableRef.current && tableRef.current.triggerEditNotification();
    setOnDemand(new OnDemand({ ...onDemand, ...data }));
  }

  function getFormattedSpeaker(params: ValueFormatterParams | GetQuickFilterTextParams): string {
    const speaker: Speaker[] = params?.value;

    if (isEmpty(speaker)) return "—";

    return speaker.map((x) => x.display_name).join(", ");
  }

  function renderViewCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.value)) return "—";

    const buttonId = idModel.view?.getId(params.value);

    const data = params?.data as OnDemand;
    return (
      <Button
        id={buttonId}
        theme={ButtonTheme.LightGrey}
        icon="q4i-link-4pt"
        linkTo={data?.urlOverride}
        linkTarget={AnchorTarget.Blank}
      />
    );
  }

  return (
    <div id={idModel.id} className={OnDemandClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.OnDemand}
        channelName={OnDemandPusherChannel.Edit}
        useService={useOnDemand}
        tableProps={tableProps}
        editForm={editForm}
        modalProps={{ fullscreen: true, scrollable: true }}
        entityModel={OnDemand}
        icon="q4i-contact-2pt"
        requiredEntityKeys={["title", "urlOverride", "order"]}
        saveRequestParams={{ _conference: conference }}
        user={user}
        conference={conference}
        toolbarProps={{ theme: ToolbarTheme.Q4Blue }}
        ref={tableRef}
      />
    </div>
  );
};

export default memo(OnDemandPresentations);
