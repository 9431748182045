import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import type { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../../services/conference/conference.model";

export interface LivePresentationProps extends BaseComponentProps {
  conference?: Conference;
  now: Moment;
  profile: AttendeeViewModel;
  visible?: boolean;
}

export enum LivePresentationClassName {
  Base = "live-presentation",
  Anchor = "live-presentation_anchor",
  Image = "live-presentation_image",
  Label = "live-presentation_label",
}

export class LivePresentationIdModel extends IdModelBase {
  anchor: string;
  image: string;
  label: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.anchor = `${this.id}Anchor`;
    this.image = `${this.id}Image`;
    this.label = `${this.id}Label`;
  }
}
