import { Button, ButtonTheme, isEmpty, NotificationService, typedMemo } from "@q4/nimbus-ui";
import download from "downloadjs";
import moment from "moment";
import React, { useMemo } from "react";
import { CsvExportService } from "../../../csvExport/csvExport.service";
import type { ExportCsvButtonProps } from "./exportCsvButton.definition";

const ExportCsvButton = <TEntity extends unknown>(props: ExportCsvButtonProps<TEntity>): JSX.Element => {
  const { fileName, includeTimeStamp, items, keys } = props;

  const csvExportService = useMemo(() => new CsvExportService(), []);
  const notificationService = useMemo(() => new NotificationService(), []);

  function getKeys(): ExportCsvButtonProps<TEntity>["keys"] {
    if (!isEmpty(keys)) return keys;

    if (isEmpty(items)) return [];

    return Object.keys(items[0]) as (keyof TEntity)[];
  }

  function handleExport(): void {
    if (isEmpty(items)) {
      notificationService.error("There are currently no items to export.");
      return;
    }

    const exportKeys = getKeys();
    const cvs = csvExportService.parse(items, ...exportKeys);
    const timeStamp = !!includeTimeStamp ? moment().format("-YYYYMMDD_HHmmss") : "";
    const completeFileName = `${fileName}${timeStamp}.csv`;

    download(cvs, completeFileName, "text/csv");
  }

  return (
    <Button icon="q4i-export-4pt" label="Export" theme={ButtonTheme.Citrus} onClick={handleExport} stopPropagation={true} />
  );
};
export default typedMemo(ExportCsvButton);
