import "./progressBar.component.scss";
import { getClassName, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo } from "react";
import { ProgressBarProps, ProgressBarTheme } from "./progressBar.definition";

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const { className, max, theme, val } = props;
  const baseClassName = getClassName("progress-bar", [
    { condition: !isNullOrWhiteSpace(className), trueClassName: className },
    { condition: !isEmpty(theme), trueClassName: `progress-bar--${theme}` },
  ]);

  return (
    <progress className={baseClassName} value={val} max={max}>
      {val} / {max}
    </progress>
  );
};

ProgressBar.defaultProps = {
  theme: ProgressBarTheme.Rain,
};

export default memo(ProgressBar);
