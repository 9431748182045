import { TimeZoneLocationMap as NUITimeZoneLocationMap } from "@q4/nimbus-ui";
import moment from "moment";
import { TimeFormat } from "../definitions/date.definition";

export const TimeTableDefaults = {
  Timeslot: 50,
  Break: 10,
  StartTime: moment().hour(8).minute(0).format(TimeFormat.Picker),
  EndTime: moment().hour(20).minute(0).format(TimeFormat.Picker),
  MaxTime: 120,
};

export const SchedulerDefaults = {
  duration: 50,
  break: 10,
  startTime: 7 * 60,
  endTime: 17 * 60,
  maxDuration: 480,
  minDuration: 5,
  maxBreak: 120,
  minBreak: 0,
  selectInterval: 5,
};

export enum TimeZone {
  CT = "America/Chicago",
  ET = "America/New_York",
  MT = "America/Denver",
  PT = "America/Los_Angeles",
  GMT = "Etc/GMT",
  BRT = "America/Sao_Paulo",
  CET = "Europe/Berlin",
  EET = "Europe/Helsinki",
  MSK = "Europe/Moscow",
  GST = "Asia/Dubai",
  IST = "Asia/Kolkata",
  HKT = "Asia/Hong_Kong",
  JST = "Asia/Tokyo",
  AEST = "Australia/Sydney",
  BST = "Europe/London",
}

export const TimeZoneLocationMap: { [idx: string]: string } = {
  ...NUITimeZoneLocationMap,
  [TimeZone.BRT]: "Sao Paolo",
  [TimeZone.CET]: "Berlin",
  [TimeZone.HKT]: "Hong Kong",
  [TimeZone.GST]: "Dubai",
};

export const TimeZoneOptions = Object.keys(TimeZone).map((k) => TimeZone[k as never] as string);

export const DefaultTimeZone = TimeZone.ET as string;
export const GreenwichTimeZone = TimeZone.GMT as string;

export const DialInInstructionsLabel =
  '<ol><li>Click on <strong>"Meeting Link"</strong> located in your schedule to join your meeting. A separate tab will open and you will be given the choice to join the meeting via the browser or app.</li>' +
  "<li>Once you have chosen your selected location the meeting will open to a Video and Audio options dashboard. Click on the toggle beside the video and audio icons to turn on these items, ensure you have your preferred devices selected.</li>" +
  '<li>Click <strong>"Join Now"</strong> to enter your meeting.</li>' +
  '<li>To exit the meeting click <strong>"Leave"</strong>.</li></ol>';

export const CreateNewSuffix = " (create new)";
