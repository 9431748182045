import {
  Button,
  ButtonTheme,
  Form,
  FormFieldProps,
  getClassName,
  Ghostable,
  InputType,
  isNullOrWhiteSpace,
  Swapable,
  Text,
  Textbox,
  TextPreset,
} from "@q4/nimbus-ui";
import { flatten } from "lodash";
import React, { memo, useMemo } from "react";
import "./sessionCustomVendorForm.component.scss";
import { VendorName, VideoVendor } from "../../services/conference/conference.model";
import { SessionBase, SessionVendorOverride } from "../../services/session/session.model";
import VideoVendorsForm from "../../views/admin/conferences/edit/components/videoVendorsForm/videoVendorsForm.component";
import {
  SessionCustomVendorFormClassName,
  SessionCustomVendorFormIdModel,
  SessionCustomVendorFormProps,
} from "./sessionCustomVendorForm.definition";

const SessionCustomVendorForm = (props: SessionCustomVendorFormProps): JSX.Element => {
  const { className, id, session, isExpired, onSessionUpdate } = props;

  const baseClassName = useMemo(
    () =>
      getClassName(SessionCustomVendorFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const idModel = useMemo(() => new SessionCustomVendorFormIdModel(id), [id]);
  const vendorMap = useMemo(
    () => ({
      [VendorName.Zoom]: 0,
      [VendorName.MicrosoftTeams]: 1,
      [VendorName.Webex]: 2,
    }),
    []
  );

  function handleDialInNumberChange(value: string, index: number): void {
    if (!session) {
      return;
    }

    const vendorValue = { ...session.vendor_override };

    // If somehow index is out of range, do nothing
    if (index >= vendorValue.dial_in_numbers.length) {
      return;
    }
    vendorValue.dial_in_numbers[index] = value;

    onSessionUpdate({
      vendor_override: vendorValue,
    });
  }

  function handleDialInNumberAdd(): void {
    const vendorValue = { ...session.vendor_override };
    if (!vendorValue.dial_in_numbers) {
      vendorValue.dial_in_numbers = [];
    }
    vendorValue.dial_in_numbers.push("");
    onSessionUpdate({
      vendor_override: vendorValue,
    });
  }

  function handleDialInNumberRemove(index: number): void {
    const vendorValue = { ...session.vendor_override };
    // If somehow index is out of range, do nothing
    if (index >= vendorValue.dial_in_numbers.length) {
      return;
    }

    vendorValue.dial_in_numbers.splice(index, 1);

    onSessionUpdate({
      vendor_override: vendorValue,
    });
  }

  function handleCustomVendorChange(value: VideoVendor): void {
    let vendorValue: SessionVendorOverride;

    if (session?.vendor_override?.vendor === value.name) {
      vendorValue = { ...session?.vendor_override };
    } else {
      vendorValue = !value?.name ? undefined : { vendor: value.name };
    }

    onSessionUpdate({
      vendor_override: vendorValue,
    });
    return;
  }

  function getTextboxHandler(key: keyof SessionBase) {
    return function (value: string): void {
      onSessionUpdate({ [key]: value });
    };
  }

  function getVendorOverrideTextboxHandler(key: keyof SessionVendorOverride) {
    return function (value: string): void {
      onSessionUpdate({
        vendor_override: {
          ...session?.vendor_override,
          [key]: value,
        },
      });
    };
  }

  function getCustomVendorFields(vendor?: string): JSX.Element {
    let fields: FormFieldProps[] = [];
    switch (vendor) {
      case VendorName.Zoom:
        fields = [
          {
            key: "Meeting Id",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Meeting ID",
            children: (
              <Textbox
                id={idModel.meetingId.id}
                value={session?.vendor_override?.meeting_id}
                onChange={getVendorOverrideTextboxHandler("meeting_id")}
                disabled={isExpired}
              />
            ),
          },
          {
            key: "Passcode",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Passcode",
            children: (
              <Textbox
                id={idModel.meetingPassword.id}
                value={session?.vendor_override?.meeting_password}
                onChange={getVendorOverrideTextboxHandler("meeting_password")}
                disabled={isExpired}
              />
            ),
          },
        ];
        break;

      case VendorName.MicrosoftTeams:
        fields = [
          {
            key: "Phone Conference ID",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Phone Conference ID",
            children: (
              <Textbox
                id={idModel.meetingId.id}
                value={session?.vendor_override?.meeting_id}
                onChange={getVendorOverrideTextboxHandler("meeting_id")}
                disabled={isExpired}
              />
            ),
          },
        ];
        break;

      case VendorName.Webex:
        fields = [
          {
            key: "Meeting Number",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Meeting Number",
            children: (
              <Textbox
                id={idModel.meetingId.id}
                value={session?.vendor_override?.meeting_id}
                onChange={getVendorOverrideTextboxHandler("meeting_id")}
                disabled={isExpired}
              />
            ),
          },
          {
            key: "Meeting Password (if joining by link)",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Password",
            children: (
              <Textbox
                id={idModel.meetingPassword.id}
                value={session?.vendor_override?.meeting_password}
                onChange={getVendorOverrideTextboxHandler("meeting_password")}
                disabled={isExpired}
              />
            ),
          },
          {
            key: "Phone Password",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Phone Password",
            children: (
              <Textbox
                id={idModel.phonePassword.id}
                value={session?.vendor_override?.phone_password}
                onChange={getVendorOverrideTextboxHandler("phone_password")}
                disabled={isExpired}
              />
            ),
          },
        ];
        break;
    }
    return <Form fields={fields} id={idModel.vendorOverrideForm.id} />;
  }

  function getDialInNumberFields(dialInNumbers: string[] = []): JSX.Element {
    const dialInFields: FormFieldProps[] = [
      {
        key: "DialIn Numbers",
        width: "1-of-1",
        smallWidth: "1-of-1",
        children: <Text>Dial-in Telephone Numbers</Text>,
      },
    ];

    const listFields: FormFieldProps[] = flatten(
      dialInNumbers?.map((dialInNumber, index) => {
        return [
          {
            key: `Label${index}`,
            width: "1-of-1",
            className: SessionCustomVendorFormClassName.DialInNumber,
            children: (
              <>
                <Textbox
                  id={idModel.dialInNumberTextbox.getId(index)}
                  className={SessionCustomVendorFormClassName.DialInNumberField}
                  value={dialInNumber}
                  inputType={InputType.Text}
                  onChange={(value) => handleDialInNumberChange(value, index)}
                  disabled={isExpired}
                />
                <Button
                  id={idModel.dialInDeleteButton.getId(index)}
                  className={SessionCustomVendorFormClassName.DialInNumberDelete}
                  theme={ButtonTheme.Q4Blue}
                  onClick={() => handleDialInNumberRemove(index)}
                  icon="q4i-trashbin-4pt"
                  disabled={isExpired}
                />
              </>
            ),
          },
        ];
      })
    );
    dialInFields.push(...listFields);
    dialInFields.push({
      key: "Label",
      width: "100px",
      label: "",
      children: (
        <Button
          id={idModel.dialInAddButton.id}
          label="Add New Number"
          icon="q4i-add-4pt"
          onClick={handleDialInNumberAdd}
          disabled={isExpired}
        />
      ),
    });

    return <Form fields={dialInFields} />;
  }

  return (
    <Form
      id={idModel.id}
      className={baseClassName}
      fields={[
        {
          key: "Virtual Session Overrides",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: <Text preset={TextPreset.Subheader}>Virtual Session Overrides</Text>,
          margin: false,
        },
        {
          key: "Virtual Session Overrides Description",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: (
            <Text preset={TextPreset.Paragraph}>
              A link will be automatically generated once this meeting is added. You can override the default meeting room by
              adding a custom vendor and link below.
            </Text>
          ),
        },
        {
          key: "Custom Meeting Info",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: (
            <>
              <VideoVendorsForm
                id={idModel.videoVendor.id}
                disabled={isExpired}
                useAllVendorName={true}
                vendor={session?.vendor_override?.vendor}
                onChange={handleCustomVendorChange}
              />
            </>
          ),
          margin: false,
        },
        {
          key: "Meeting Link",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: "Meeting Link",
          children: (
            <Textbox
              id={idModel.overrideUrl.id}
              value={session?.urlOverride}
              onChange={getTextboxHandler("urlOverride")}
              disabled={isExpired}
              maxLength={500}
            />
          ),
        },
        {
          key: "Custom Vendor Info",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: (
            <>
              <Ghostable ghosted={!session?.vendor_override?.vendor}>
                <Swapable
                  id={idModel.id}
                  selected={vendorMap[session?.vendor_override?.vendor]}
                  layers={Object.entries(vendorMap).map(([vendorName]) => getCustomVendorFields(vendorName))}
                />
              </Ghostable>
            </>
          ),
        },
        {
          key: "Custom Vendor DialIn Numbers",
          width: "1-of-1",
          smallWidth: "1-of-1",
          children: (
            <>
              <Ghostable ghosted={!session?.vendor_override?.vendor}>
                {getDialInNumberFields(session?.vendor_override?.dial_in_numbers)}
              </Ghostable>
            </>
          ),
        },
      ]}
    />
  );
};

export default memo(SessionCustomVendorForm);
