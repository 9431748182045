import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { MeetingRequest, RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import { RegistrationFormProps } from "../../definition/registrationForm.definition";
import { MeetingRequestFormIdModel } from "../meetingRequestFormV2/meetingRequestForm.definition";

export interface MeetingRequestsProps extends RegistrationFormProps {
  conference: Conference;
  loading: boolean;
  meetingRequests: MeetingRequest[];
  setMeetingRequests: Dispatch<SetStateAction<MeetingRequest[]>>;
  setRegistrant: Dispatch<SetStateAction<RegistrantViewModel>>;
  disabled?: boolean;
}

export enum MeetingRequestClassName {
  Base = "meeting-requests",
  Title = "meeting-requests_title",
  Subtitle = "meeting-requests_subtitle",
  Navigation = "meeting-requests_navigation",
}

export class MeetingRequestsIdModel extends IdModelBase {
  previousStep: ButtonIdModel;
  nextStep: ButtonIdModel;
  form: MeetingRequestFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.previousStep = new ButtonIdModel(`${this.id}PreviousStepButton`);
    this.nextStep = new ButtonIdModel(`${this.id}NextStepButton`);
    this.form = new MeetingRequestFormIdModel(`${this.id}MeetingRequestForm`);
  }
}
