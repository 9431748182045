import "./requestProgressStatus.component.scss";
import { getClassName } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import ProgressBar from "../../../../components/progressBar/progressBar.component";
import { ProgressBarTheme } from "../../../../components/progressBar/progressBar.definition";
import { EntityBase } from "../../../../definitions/entity.definition";
import RequestProgressResultsGroup from "../results/requestProgressResultsGroup.component";
import RequestProgressStatusTable from "../results/requestProgressResultsTable.component";
import {
  ContentRequestProgressResultsProps,
  RequestProgressClassName,
  RequestProgressStatusIdModel,
  RequestProgressStatusName,
} from "./requestProgressStatus.definition";

const RequestProgressStatus = <T extends EntityBase>(props: ContentRequestProgressResultsProps<T>): JSX.Element => {
  const { id, inProgress, results, currentCount: currCount, totalCount, statusText, showCsvExport, showStatusTable } = props;
  const currentCount = currCount || results?.length;
  const idModel = useMemo(() => new RequestProgressStatusIdModel(id), [id]);

  const { Info, Results, InfoComplete, ResultComplete, InfoStatus, ResultProgressBar } = RequestProgressClassName;

  const doneSuccess = useMemo(() => !inProgress && results.length === totalCount, [inProgress, results.length, totalCount]);
  const progressStatus = useMemo(
    () => (inProgress ? statusText : doneSuccess ? RequestProgressStatusName.Complete : RequestProgressStatusName.Failed),
    [doneSuccess, inProgress, statusText]
  );
  const progressTheme = useMemo(
    () => (inProgress ? ProgressBarTheme.Rain : doneSuccess ? ProgressBarTheme.Teal : ProgressBarTheme.Spice),
    [doneSuccess, inProgress]
  );
  const importInfoClassName = useMemo(
    () => getClassName(Info, [{ condition: inProgress, falseClassName: InfoComplete }]),
    [inProgress, Info, InfoComplete]
  );

  const renderProgressStatusView = () =>
    inProgress ? (
      showStatusTable && (
        <RequestProgressStatusTable
          id={idModel.requestProgressStatusTable.id}
          rows={results}
          autoScrollToBottom={inProgress}
          showExportErrors={false}
          showCsvExport={showCsvExport}
        />
      )
    ) : (
      <RequestProgressResultsGroup
        id={idModel.requestProgressResultsGroup.id}
        rows={results}
        showExportErrors={true}
        showCsvExport={showCsvExport}
      />
    );

  return (
    <div id={idModel.id} className={getClassName(Results, [{ condition: inProgress, falseClassName: ResultComplete }])}>
      <section className={importInfoClassName}>
        <div className={InfoStatus}>
          Status: <span className={`${InfoStatus}--${progressStatus}`}>{progressStatus}</span>
        </div>
      </section>

      <ProgressBar className={ResultProgressBar} val={currentCount} max={totalCount} theme={progressTheme} />

      {renderProgressStatusView()}
    </div>
  );
};

export default memo(RequestProgressStatus);
