import { ButtonListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { OnDemandFormIdModel } from "../../../../../../components/onDemandForm/onDemandForm.definition";
import type { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { OnDemandHookModel } from "../../../../../../hooks/admin/useOnDemand/useOnDemand.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { Speaker } from "../../../../../../services/speaker/speaker.model";

export interface OnDemandProps extends Pick<BaseComponentProps, "id"> {
  conference: Conference;
  codes: string[];
  speakers: Speaker[];
  useOnDemand: OnDemandHookModel;
  user: AdminUserContextState["user"];
}

export enum OnDemandTableHeader {
  Name = "NAME",
  EndTime = "END TIME",
  Speaker = "SPEAKER",
  View = "VIEW",
}

export enum OnDemandPusherChannel {
  Edit = "presence-edit",
}

export enum OnDemandClassName {
  Base = "onDemand",
}

export enum OnDemandEditClassName {
  Base = "onDemand-edit",
}

export class OnDemandIdModel extends IdModelBase {
  form: OnDemandFormIdModel;
  entityTable: EntityTableIdModel;
  view: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.view = new ButtonListId(`${this.id}View`);
    this.form = new OnDemandFormIdModel(`${this.id}Form`);
    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
  }
}
