import { UserRouteBase } from "../../../configurations/navigation.configuration";
import { EntityBase } from "../../../definitions/entity.definition";
import { ApiResponse, AuthType } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import type { Conference } from "../../conference/conference.model";
import { Service } from "../../serviceBase/serviceBase.model";
import { mapApiResponse, mapArrayedApiResponse } from "../../serviceBase/serviceBase.utils";
import { CorporateProfile, CorporateProfilePayload } from "./corporateProfile.model";

class CorporateProfileServiceBase extends Service<CorporateProfile> {
  constructor() {
    super(UserRouteBase, "corporateprofiles", CorporateProfile);
  }
}

export default class CorporateProfileService implements Omit<Service<CorporateProfile>, "get" | "getOfflineData"> {
  protected readonly _apiService = new ApiService();
  protected _servicePath: string;
  private service = new CorporateProfileServiceBase();
  private _entityModel: new (entity: Partial<CorporateProfile> | string) => CorporateProfile;

  get ApiPathBase(): string {
    return this.service.ApiPathBase;
  }

  getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<CorporateProfile[]>> {
    return this._apiService
      .get<CorporateProfile[]>(`${this.ApiPathBase}/byConference/${_id}`, AuthType.Passwordless)
      .then((response) => mapArrayedApiResponse(response, CorporateProfile));
  }

  delete(id: CorporateProfile["_id"]): Promise<ApiResponse<CorporateProfile>> {
    return this._apiService.delete<null, CorporateProfile>(`${this.ApiPathBase}/${id}`).then(this.mapResponse);
  }

  post(data: CorporateProfile): Promise<ApiResponse<CorporateProfile>> {
    const payload = this.getPayloadFromData(data);
    return this._apiService
      .post<CorporateProfilePayload, CorporateProfile>(this.ApiPathBase, payload)
      .then(this.mapResponse);
  }

  put(id: EntityBase["_id"], data: CorporateProfile): Promise<ApiResponse<CorporateProfile>> {
    const payload = this.getPayloadFromData(data);

    return this._apiService
      .put<CorporateProfilePayload, CorporateProfile>(`${this.ApiPathBase}/${id}`, payload)
      .then(this.mapResponse);
  }

  protected mapResponse = (response: ApiResponse<CorporateProfile>): ApiResponse<CorporateProfile> => {
    return mapApiResponse(response, this._entityModel);
  };

  protected getPayloadFromData(data: CorporateProfile): CorporateProfilePayload {
    const { attendees, ...payloadData } = data;

    return new CorporateProfilePayload(payloadData);
  }
}
