import { isEmpty } from "@q4/nimbus-ui";
import { isDateRangeConflicting } from "..";
import { ConferenceSchedulerSlot } from "../../services/conference/conference.model";

export const getNonConflictingSlots = (conferenceSchedulerSlots: ConferenceSchedulerSlot[]): ConferenceSchedulerSlot[] => {
  if (isEmpty(conferenceSchedulerSlots)) return [];

  return conferenceSchedulerSlots.filter((slot, index) => {
    const otherSlots = [...conferenceSchedulerSlots];
    otherSlots.splice(index, 1);
    return !isDateRangeConflicting(slot.start_time, slot.end_time, otherSlots);
  });
};
