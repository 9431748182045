import {
  BaseComponentProps,
  CheckboxIdModel,
  ErrorModel,
  FileUploaderIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextareaIdModel,
  TextboxIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { AttendeeViewModel } from "../../../../services/attendee/attendee.model";
import type { QuestionGroup } from "../../../../services/questionGroup/questionGroup.model";
import { QuestionsFormIdModel } from "../../../questionsForm/questionsForm.definition";

export enum CorporateAttendeeFormClassName {
  Base = "corporate-attendee-form",
  QuestionsHeader = "corporate-attendee-form_question-header",
}

export interface CorporateAttendeeFormProps extends BaseComponentProps {
  attendee: AttendeeViewModel;
  questionGroup?: QuestionGroup;
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  onAttendeeUpdate: (data: Partial<AttendeeViewModel>) => void;
}

export class CorporateAttendeeFormIdModel extends IdModelBase {
  attendeeFormId: FormIdModel;
  speakerFormId: FormIdModel;
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  title: TextboxIdModel;
  phone: TextboxIdModel;
  email: TextboxIdModel;
  participateInMeetings: CheckboxIdModel;
  isSpeaker: CheckboxIdModel;
  speakerBio: TextareaIdModel;
  speakerImage: FileUploaderIdModel;
  receiveEmails: CheckboxIdModel;
  questionsForm: QuestionsFormIdModel;
  questionsHeader: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.attendeeFormId = new FormIdModel(`${this.id}AttendeeForm`);
    this.speakerFormId = new FormIdModel(`${this.id}SpeakerInfoForm`);
    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.phone = new TextboxIdModel(`${this.id}PhoneTextbox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.participateInMeetings = new CheckboxIdModel(`${this.id}ParticipateInMeetingsCheckbox`);
    this.isSpeaker = new CheckboxIdModel(`${this.id}IsSpeakerCheckbox`);
    this.speakerBio = new TextareaIdModel(`${this.id}SpeakerBioTextArea`);
    this.speakerImage = new FileUploaderIdModel(`${this.id}SpeakerImageFileUploader`);
    this.receiveEmails = new CheckboxIdModel(`${this.id}ReceiveEmailsCheckbox`);
    this.questionsForm = new QuestionsFormIdModel(`${this.id}QuestionForm`);
    this.questionsHeader = new TextIdModel(`${this.id}QuestionsHeader`);
  }
}
