import "./confirmation.component.scss";
import { Anchor, isNil, isNullOrWhiteSpace, Text, TextPreset } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { RoutePath, RoutePathIdLabel } from "../../../../../configurations/navigation.configuration";
import { SupportEmail } from "../../../../../const";
import { RegistrantStatus } from "../../../../../services/admin/registrant/registrant.model";
import { getRoute } from "../../../../../utils";
import { ConfirmationIdModel, RegistrationConfirmationProps } from "./confirmation.definition";

const RegistrationConfirmation = (props: RegistrationConfirmationProps): JSX.Element => {
  const {
    id,
    registrant,
    companyUrlSuffix,
    conferencePath,
    coordinator,
    displayLoginLink: displayLoginLinkProp,
    registrationExists,
    isAutoApprove,
    isEmailEnabled,
    profileUrl,
  } = props;

  const idModel = useMemo(() => new ConfirmationIdModel(id), [id]);
  const displayLoginLink = useMemo(() => !!displayLoginLinkProp, [displayLoginLinkProp]);

  const renderCoordinatorInfo = useCallback((): JSX.Element => {
    const { full_name, phone, email, title } = coordinator || {};

    const hasName = !isNullOrWhiteSpace(full_name);
    const hasPhone = !isNullOrWhiteSpace(phone);
    const hasEmail = !isNullOrWhiteSpace(email);
    const hasTitle = !isNullOrWhiteSpace(title);

    if (!hasName && !hasPhone && !hasEmail) return null;

    return (
      <div id={idModel?.coordinatorSection}>
        <Text preset={TextPreset.Title}>Need More information?</Text>
        <Text preset={TextPreset.Paragraph}>If you need more information, please contact us:</Text>

        {hasName && <Text preset={TextPreset.Subheader}>{full_name}</Text>}
        {hasTitle && <Text preset={TextPreset.Paragraph}>{title}</Text>}
        {hasPhone && <Text preset={TextPreset.Paragraph}>Phone: {phone}</Text>}
        {hasEmail && (
          <Text preset={TextPreset.Paragraph}>
            Email: <Anchor url={`mailto:${email}`}>{email}</Anchor>
          </Text>
        )}
      </div>
    );
  }, [coordinator, idModel?.coordinatorSection]);

  const { title, message } = useMemo(() => {
    const profileLink = (
      <span id={idModel.profileLink}>
        You can update your registration details by using the following login link:{" "}
        <Anchor url={profileUrl}>{profileUrl}</Anchor>
      </span>
    );

    const approvedState = {
      title: "Congratulations, your registration is complete!",
      message: (
        <>
          {displayLoginLink ? (
            <span>
              You can sign into the event portal{" "}
              <Anchor
                url={getRoute(
                  RoutePath.ConferenceLoginWithCustomCompany,
                  { label: RoutePathIdLabel.CustomCompany, value: companyUrlSuffix },
                  { label: RoutePathIdLabel.ConferencePath, value: conferencePath }
                )}
              >
                here.
              </Anchor>{" "}
            </span>
          ) : null}
          {isAutoApprove && profileLink}
        </>
      ),
    };

    if (!!isAutoApprove) {
      return approvedState;
    }

    if (isNullOrWhiteSpace(registrant?.status) || !registrationExists) {
      return {
        title: "Congratulations your registration is complete!",
        message: isEmailEnabled ? "You will receive a confirmation email with all your registration details." : null,
      };
    }

    const supportMessage = (
      <span>
        Please contact <Anchor url={`mailto:${SupportEmail}`}>{SupportEmail}</Anchor> for more information.
      </span>
    );

    switch (registrant.status) {
      case RegistrantStatus.Approved:
        return approvedState;
      case RegistrantStatus.Rejected:
        return {
          title: "Your registration has been rejected.",
          message: supportMessage,
        };
      case RegistrantStatus.Pending:
        return {
          title: "Your registration is currently pending.",
          message: supportMessage,
        };
    }
  }, [
    companyUrlSuffix,
    conferencePath,
    displayLoginLink,
    registrant?.status,
    registrationExists,
    isAutoApprove,
    isEmailEnabled,
    profileUrl,
    idModel,
  ]);

  return (
    <div id={idModel.id} className="registration-confirmation">
      <Text preset={TextPreset.Title}>{title}</Text>
      {!isNil(message) && <Text preset={TextPreset.Subheader}>{message}</Text>}
      <br />
      <hr />
      {renderCoordinatorInfo()}
    </div>
  );
};

export default memo(RegistrationConfirmation);
