import { ButtonIdModel, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { groupBy } from "lodash";
import { DateFormat, DateTokenFormat, TimeFormat } from "../../../../../../../../definitions/date.definition";
import { AttendeeType } from "../../../../../../../../services/attendee/attendee.model";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import { VendorName } from "../../../../../../../../services/conference/conference.model";
import { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import { getMeetingProxyUrl, getMeetingLabel, mapTimeZone, htmlToText } from "../../../../../../../../utils";

class MeetingsExportButtonIdModel {
  button: ButtonIdModel;

  constructor(id: string) {
    this.button = new ButtonIdModel(`${id}Button`);
  }
}

export const MeetingsExportIdModel = new MeetingsExportButtonIdModel("MeetingsExport");
export class MeetingsExportButtonProps {
  meetings: Meeting[];
  timezone: string;
  vendorName: VendorName;
}

export class MeetingsExportModel {
  meetings: MeetingExportModel[];

  get keys(): (keyof MeetingExportModel)[] {
    return Object.keys(new MeetingExportModel()) as (keyof MeetingExportModel)[];
  }

  constructor(meetings: Meeting[], timezone: string, vendorName: VendorName) {
    if (isEmpty(meetings)) return;

    this.meetings = meetings
      .sort((a, b) => a.start_date?.diff(b.start_date))
      .flatMap((x) => new MeetingExportGroup(x, timezone, vendorName).getMeetingExportModels());
  }
}

export class MeetingExportModel {
  meetingId: Meeting["_id"];
  corporateCompany: CorporateProfile["name"];
  corporateAttendees: string;
  meetingType: Meeting["label"];
  meetingDate: string;
  meetingStartTime: string;
  meetingEndTime: string;
  meetingTimeZone: string;
  investorCompany: string;
  investorAttendees: string;
  internalAttendees: string;
  meetingLink: ReturnType<typeof getMeetingProxyUrl>;
  directMeetingLink: string;
  meetingLocation?: string;
}

class MeetingExportGroup {
  meetingDefault: Omit<
    MeetingExportModel,
    "corporateCompany" | "corporateAttendees" | "investorCompany" | "investorAttendees"
  >;
  corporateGroups: Record<CorporateProfile["name"], AttendeeViewModel[]>;
  investorGroups: Record<AttendeeViewModel["company"], AttendeeViewModel[]>;

  getMeetingExportModels(): MeetingExportModel[] {
    const EmptyCompanyName = "";
    const meetingRows: MeetingExportModel[] = [];

    const corporateCompanies = isEmpty(this.corporateGroups) ? [EmptyCompanyName] : Object.keys(this.corporateGroups);
    const investorCompanies = isEmpty(this.investorGroups) ? [EmptyCompanyName] : Object.keys(this.investorGroups);

    corporateCompanies.forEach((corporateCompany) => {
      const corporateAttendees = this._mapAttendees(this.corporateGroups, corporateCompany);

      investorCompanies.forEach((investorCompany) => {
        const investorAttendees = this._mapAttendees(this.investorGroups, investorCompany);

        meetingRows.push({
          ...this.meetingDefault,
          corporateCompany,
          corporateAttendees,
          investorCompany,
          investorAttendees,
        });
      });
    });

    return meetingRows;
  }

  constructor(meeting: Meeting, timezone: string, vendorName: VendorName) {
    if (isEmpty(meeting)) return;

    const internal: AttendeeViewModel[] = [];
    const corporate: AttendeeViewModel[] = [];
    const investor: AttendeeViewModel[] = [];
    (meeting._attendee || []).forEach((x) => {
      switch (x?.type) {
        case AttendeeType.Internal:
          internal.push(x);
          break;
        case AttendeeType.Corporate:
          corporate.push(x);
          break;
        case AttendeeType.Investor:
          investor.push(x);
      }
    });

    this.investorGroups = groupBy(investor, "company");
    this.corporateGroups = groupBy(corporate, "_corporate_profile.name");

    let directMeetingLink = "";

    if (!isNullOrWhiteSpace(meeting.urlOverride)) {
      directMeetingLink = meeting.urlOverride;
    } else if (!isNullOrWhiteSpace(vendorName) && vendorName === meeting.vendor?.[0]?.vendor) {
      directMeetingLink = meeting.vendor[0].url;
    }

    const meetingId = meeting._id;
    this.meetingDefault = {
      meetingId,
      meetingType: getMeetingLabel(meeting),
      meetingDate: mapTimeZone(meeting.start_date, timezone)?.format(DateFormat.FullDate),
      meetingStartTime: mapTimeZone(meeting.start_date, timezone)?.format(TimeFormat.Standard),
      meetingEndTime: mapTimeZone(meeting.end_date, timezone)?.format(TimeFormat.Standard),
      meetingTimeZone: mapTimeZone(meeting.start_date, timezone)?.format(DateTokenFormat.TimeZone),
      internalAttendees: internal.map((attendee) => attendee.display_name).join(", "),
      meetingLink: getMeetingProxyUrl(meetingId),
      directMeetingLink,
    };

    this.meetingDefault.meetingLocation = htmlToText(meeting.roomLocation).trim();
  }

  _mapAttendees(groups: Record<string, AttendeeViewModel[]>, company: string): string {
    if (!(company in groups)) return null;
    if (!Array.isArray(groups[company])) return null;

    return groups[company].map((x) => x.display_name).join(", ");
  }
}
