import {
  BaseComponentProps,
  DatePickerIdModel,
  IdModelBase,
  InfoIconIdModel,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export type ConferenceDateEditState = Required<
  Pick<ConferenceEditState, "start_date" | "end_date" | "open_date" | "close_date">
>;

export type ConferenceTimelineEditState = ConferenceDateEditState & Pick<ConferenceEditState, "time_zone" | "date_label">;

export interface ConferenceTimelineEditProps extends Pick<BaseComponentProps, "id"> {
  state: ConferenceTimelineEditState;
  type: ConferenceEditState["conference_type"];
  onChange: (state: Partial<Pick<ConferenceEditState, keyof ConferenceTimelineEditState>>) => void;
}

export enum ConferenceTimelineEditClassName {
  Base = "conference-timeline-edit",
  CustomLabel = "conference-timeline-edit_custom-label",
}

export class ConferenceTimelineEditIdModel extends IdModelBase {
  startDate: DatePickerIdModel;
  endDate: DatePickerIdModel;
  openDate: DatePickerIdModel;
  closeDate: DatePickerIdModel;
  dateLabel: TextboxIdModel;
  timeZone: SelectIdModel;
  infoIcon: InfoIconIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.startDate = new DatePickerIdModel(`${this.id}StartDateDatePicker`);
    this.endDate = new DatePickerIdModel(`${this.id}EndDateDatePicker`);
    this.openDate = new DatePickerIdModel(`${this.id}OpenDateDatePicker`);
    this.closeDate = new DatePickerIdModel(`${this.id}CloseDateDatePicker`);
    this.dateLabel = new TextboxIdModel(`${this.id}DateLabelTextbox`);
    this.timeZone = new SelectIdModel(`${this.id}TimeZoneSelect`);
    this.infoIcon = new InfoIconIdModel(`${this.id}DateLabelInfoIcon`);
  }
}
