import { isEmpty } from "@q4/nimbus-ui";
import { cloneDeep } from "lodash";
import moment from "moment";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import type { CorporateProfile, CorporateType } from "../../services/corporateProfile/corporateProfile.model";

const PublicTypeKeys = ["ticker_symbol", "exchange"];
export const CorporateTypeDefaultRequiredKeys = ["name", "type"];
export const CorporateRequiredKeys = {
  Public: [...CorporateTypeDefaultRequiredKeys, ...PublicTypeKeys],
  Private: [...CorporateTypeDefaultRequiredKeys],
};

export function getCorporateProfileTypeRequiredKeys(type: CorporateType): string[] {
  const keys = CorporateRequiredKeys[type];
  return isEmpty(keys) ? CorporateTypeDefaultRequiredKeys : keys;
}

export function getCorporateName(id: CorporateProfile["_id"], corporates: CorporateProfile[]): string {
  if (isEmpty(corporates)) return;
  return corporates.find((x) => x._id === id)?.name;
}

export const getCorporateAvailability = (attendees: AttendeeViewModel[]): ConferenceSchedulerSlot[] => {
  return cloneDeep(attendees)
    .filter(({ participate_in_meetings }) => participate_in_meetings)
    .reduce((availabilities, attendee) => availabilities.concat(attendee.availability), [])
    .reduce((intervals, currentInterval) => {
      const { start_time: startTime, end_time: endTime } = currentInterval;
      const overlapIndex = intervals.findIndex((interval: ConferenceSchedulerSlot) => {
        const { start_time: intervalStartTime, end_time: intervalEndTime } = interval;
        if (
          (startTime.isSameOrAfter(intervalStartTime) && startTime.isSameOrBefore(intervalEndTime)) ||
          (endTime.isSameOrAfter(intervalStartTime) && endTime.isSameOrBefore(intervalEndTime))
        ) {
          return true;
        }

        return false;
      });

      if (overlapIndex === -1) {
        return intervals.concat(currentInterval);
      }

      const matchInterval = intervals[overlapIndex];

      matchInterval.start_time = moment.min([matchInterval.start_time, startTime]);
      matchInterval.end_time = moment.max([matchInterval.end_time, endTime]);

      return [...intervals];
    }, []);
};
