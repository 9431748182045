import { AutomationListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Speaker } from "../../../../../../../services/speaker/speaker.model";

export interface SpeakerDetailsProps extends BaseComponentProps {
  speakers?: Speaker[];
  selectedSpeaker: Speaker;
  onClose: () => void;
}

export enum SpeakerDetailsClassName {
  Base = "speaker-details",
  Section = "speaker-details_section",
  Inner = "speaker-details_inner",
  Item = "speaker-details_item",
  Name = "speaker-details_name",
  Column = "speaker-details_column",
  Company = "speaker-details_company",
  Title = "speaker-details_title",
  Bio = "speaker-details_bio",
  Image = "speaker-details_image",
  Contact = "speaker-details_contact",
  ContactWithTabletModifier = "speaker-details_contact--tablet",
  ContactLabel = "speaker-details_contact-label",
  ContactList = "speaker-details_contact-list",
  ContactAnchor = "speaker-details_contact-anchor",
  Close = "speaker-details_close",
}

export class SpeakerDetailsIdModel extends IdModelBase {
  section: string;
  inner: string;
  close: string;
  list: SpeakerDetailsItemListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.list = new SpeakerDetailsItemListId(null);
      return;
    }

    this.section = `${this.id}Section`;
    this.inner = `${this.id}Inner`;
    this.close = `${this.id}Close`;
    this.list = new SpeakerDetailsItemListId(id);
  }
}

export class SpeakerDetailsItemListId extends AutomationListId<SpeakerDetailsItemIdModel> {
  constructor(parentId: string, prefix = "Item-", postfix?: string) {
    super(parentId, prefix, SpeakerDetailsItemIdModel, postfix);
  }
}

export class SpeakerDetailsItemIdModel extends IdModelBase {
  image: string;
  name: string;
  company: string;
  title: string;
  bio: string;
  contact: string;
  linkedIn: string;
  twitter: string;
  email: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.image = `${this.id}Image`;
    this.name = `${this.id}Name`;
    this.company = `${this.id}Company`;
    this.title = `${this.id}Title`;
    this.bio = `${this.id}Bio`;
    this.contact = `${this.id}Contact`;
    this.linkedIn = `${this.id}LinkedInAnchor`;
    this.twitter = `${this.id}TwitterAnchor`;
    this.email = `${this.id}EmailAnchor`;
  }
}
