import {
  BaseComponentProps,
  IdModelBase,
  isNullOrWhiteSpace,
  ButtonIdModel,
  TimePickerIdModel,
  FormIdModel,
} from "@q4/nimbus-ui";
import type { Moment } from "moment";
import type { Conference, ConferenceScheduler } from "../../../../../../../../services/conference/conference.model";

export interface MeetingTimeSlotCreatorProps extends BaseComponentProps {
  conference: Conference;
  scheduler: ConferenceScheduler;
  saving: boolean;
  onSubmit: (schedule: ConferenceScheduler) => void;
  currentCalendarDay: Moment;
  hasConferenceScheduler: boolean;
  editMode: boolean;
}

export enum MeetingTimeSlotCreatorClassName {
  Base = "meeting-time-slot-creator",
  Content = "meeting-time-slot-creator-content",
  Form = "meeting-time-slot-creator-content_form",
  Button = "meeting-time-slot-creator-content_button",
}

export class MeetingTimeSlotCreatorIdModel extends IdModelBase {
  container: string;
  button: ButtonIdModel;
  startTime: TimePickerIdModel;
  endTime: TimePickerIdModel;
  form: FormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.container = `${this.id}MeetingTimeSlotCreatorContainer`;
    this.button = new ButtonIdModel(`${this.id}MeetingTimeSlotCreatorButton`);
    this.startTime = new TimePickerIdModel(`${this.id}MeetingTimeSlotCreatorStartTimeTimePicker`);
    this.endTime = new TimePickerIdModel(`${this.id}MeetingTimeSlotCreatorEndTimeTimePicker`);
    this.form = new FormIdModel(`${this.id}MeetingTimeSlotCreatorForm`);
  }
}
