import { AutomationListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import type { ReactNode } from "react";

export enum SectionTheme {
  Dark = "dark",
  Black = "black",
  Transparent = "transparent",
  Gradient = "gradient",
}

export enum SectionInnerPadding {
  Default = "default",
  Thin = "thin",
}

export enum SectionHeaderMargin {
  Default = "default",
  Thick = "thick",
}

export enum SectionKeyLine {
  Top = "top",
  Bottom = "bottom",
  Both = "both",
}

export enum SectionInnerWidth {
  Uncontained = "uncontained",
  Contained = "contained",
  Narrow = "narrow",
}

export interface SectionProps extends BaseComponentWithChildrenProps {
  theme?: SectionTheme;
  headerMargin?: SectionHeaderMargin;
  innerWidth?: SectionInnerWidth;
  innerPadding?: SectionInnerPadding;
  keyLine?: SectionKeyLine;
  headerChildren?: ReactNode[];
  anchor?: string;
}

export enum SectionClassName {
  Base = "section",
  BaseWithThinModifier = "section--thin",
  Inner = "section_inner",
  Header = "section_header",
  HeaderWithThinModifier = "section_header--thin",
  Content = "section_content",
  ContentColumn = "section_content-column",
  ContentColumnWidthGrowModifier = "section_content-column--grow",
  Anchor = "section_anchor",
}

export class SectionListId extends AutomationListId<SectionIdModel> {
  constructor(parentId: string, prefix = "Section-", postfix?: string) {
    super(parentId, prefix, SectionIdModel, postfix);
  }
}

export class SectionIdModel extends IdModelBase {
  inner: string;
  header: string;
  content: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.inner = `${this.id}Inner`;
    this.header = `${this.id}Header`;
    this.content = `${this.id}Content`;
  }
}
