import "./version.component.scss";
import { Text, TextTheme, Ghostable, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { ProductionEnvironments } from "../../config/config.definition";
import { VersionClassName, VersionEnvironmentLabel, VersionIdModel, VersionProps } from "./version.definition";

const Version = (props: VersionProps): JSX.Element => {
  const { id, config, visible: visibleProp } = props;
  const { env, version } = config || {};

  const idModel = new VersionIdModel(id);

  const environment = useMemo(() => VersionEnvironmentLabel[env], [env]);
  const environmentLabel = useMemo(() => (ProductionEnvironments.includes(env) ? null : environment), [env, environment]);

  const versionLabel = useMemo(() => (isNullOrWhiteSpace(version) ? null : `v${version}`), [version]);

  const separator = useMemo(
    () => (isNullOrWhiteSpace(environmentLabel) || isNullOrWhiteSpace(versionLabel) ? null : <span> - </span>),
    [environmentLabel, versionLabel]
  );

  const visible = useMemo(
    () => (isNullOrWhiteSpace(environment) && isNullOrWhiteSpace(version) ? false : visibleProp ?? true),
    [environment, version, visibleProp]
  );

  return (
    <section id={idModel.id} className={VersionClassName.Base}>
      <Ghostable appear={false} ghosted={!visible}>
        <span>
          <Text theme={TextTheme.White}>
            {versionLabel}
            {separator}
            {environmentLabel}
          </Text>
        </span>
      </Ghostable>
    </section>
  );
};

export default memo(Version);
