import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface ConferenceCoordinatorEditProps extends BaseComponentProps {
  coordinator: ConferenceEditState["coordinator"];
  replyTo: ConferenceEditState["reply_to"];
  onChange: (state: Partial<Pick<ConferenceEditState, "coordinator" | "reply_to">>) => void;
}

export class ConferenceCoordinatorEditIdModel extends IdModelBase {
  coordinatorName: TextboxIdModel;
  coordinatorTitle: TextboxIdModel;
  coordinatorPhone: TextboxIdModel;
  coordinatorEmail: TextboxIdModel;
  replyToName: TextboxIdModel;
  replyToEmail: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.coordinatorName = new TextboxIdModel(`${this.id}CoordinatorNameTextbox`);
    this.coordinatorTitle = new TextboxIdModel(`${this.id}CoordinatorTitleTextbox`);
    this.coordinatorPhone = new TextboxIdModel(`${this.id}CoordinatorPhoneTextbox`);
    this.coordinatorEmail = new TextboxIdModel(`${this.id}CoordinatorEmailTextbox`);
    this.replyToName = new TextboxIdModel(`${this.id}ReplyToNameTextbox`);
    this.replyToEmail = new TextboxIdModel(`${this.id}ReplyToEmailTextbox`);
  }
}
