import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { useEffect, useState } from "react";

export const useBackgroundImage = (src: string): string => {
  const [backgroundImage, setBackgroundImage] = useState<string>();

  useEffect(() => {
    function fetchImage(src: string) {
      if (isNullOrWhiteSpace(src)) {
        setBackgroundImage(null);
        return;
      }

      const image = new Image();

      image.src = src;
      image.onload = () => {
        setBackgroundImage(src);
      };
    }
    fetchImage(src);
  }, [src]);

  return backgroundImage;
};
