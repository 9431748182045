import "./syncConfirmationMessage.component.scss";
import { Message, MessageType } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import RequestProgressStatus from "../requestProgressStatus/components/progress/requestProgressStatus.component";
import { RequestProgressStatusName } from "../requestProgressStatus/components/progress/requestProgressStatus.definition";
import { SyncConfirmationMessageIdModel, SyncConfirmationMessageProps } from "./syncConfirmationMessage.definition";

const SyncConfirmationMessage = (props: SyncConfirmationMessageProps): JSX.Element => {
  const { id, visible, loading, onCancel, onConfirm, totalAttendees, currentCount, syncedAttendees } = props;

  const idModel = useMemo(() => new SyncConfirmationMessageIdModel(id), [id]);

  const rendermessage = () =>
    !loading && totalAttendees !== currentCount ? (
      "Do you want to sync all attendees to Streamlined?"
    ) : (
      <>
        <RequestProgressStatus
          id={idModel.attendeeSyncProgress}
          key="attendee-sync_progress"
          results={syncedAttendees}
          inProgress={loading}
          totalCount={totalAttendees}
          currentCount={currentCount}
          statusText={RequestProgressStatusName.Syncing}
          showCsvExport={false}
          showStatusTable={false}
        />
      </>
    );

  return (
    <Message
      id={idModel.message?.id}
      visible={visible}
      className="sync-confirmation-modal"
      messageType={MessageType.Confirmation}
      title={"Confirm Sync"}
      message={rendermessage()}
      secondaryActionProps={{
        id: idModel.cancel?.id,
        label: "Cancel",
        onClick: onCancel,
      }}
      primaryActionProps={{
        id: idModel.sync?.id,
        label: totalAttendees !== currentCount ? "Sync" : "Done",
        loading,
        onClick: totalAttendees !== currentCount ? onConfirm : onCancel,
      }}
      onCloseRequest={onCancel}
    />
  );
};

export default memo(SyncConfirmationMessage);
