import { SelectIdModel, SelectProps } from "@q4/nimbus-ui";

export type CustomSelectProps = SelectProps<string> & {
  addNew?: boolean;
  addNewSuffix?: string;
  maxLength?: number;
};

export class CustomSelectIdModel extends SelectIdModel {
  select: SelectIdModel;

  constructor(id: string) {
    super(id);
    this.select = new SelectIdModel(`${this.id}CustomSelect`);
  }
}

export const idModel = new CustomSelectIdModel("CustomSelect");

export const CreateNewSuffix = " (Create New)";

export enum SelectActions {
  Clear = "clear",
}
