import { isEmpty, LoadingScreen } from "@q4/nimbus-ui";
import React, { memo, useEffect, useMemo } from "react";
import { matchPath } from "react-router";
import { Route } from "react-router-dom";
import LoadingSpinner from "../../../../../components/loadingSpinner/loadingSpinner.component";
import { RoutePath } from "../../../../../configurations/navigation.configuration";
import { AuthType } from "../../../../../services/api/api.definition";
import type { PrivateRouteProps } from "./privateRoute.definition";

const PrivateRoute = (props: PrivateRouteProps) => {
  const { authType: authTypeProp, component: Component, userContext, ...rest } = props;

  const { authType: currentAuthType, isAuthenticated, signOut, setAuthType } = userContext;
  const authType = useMemo(() => (isEmpty(authTypeProp) ? AuthType.Protected : authTypeProp), [authTypeProp]);
  const authenticated = useMemo(() => isAuthenticated(), [isAuthenticated]);
  const loadingComponent = useMemo(
    () => (authType === AuthType.Protected ? <LoadingScreen /> : <LoadingSpinner />),
    [authType]
  );
  const redirect = useMemo(() => {
    const currentPath = rest?.location?.pathname;
    const noRedirectRoutes = [RoutePath.ConferenceLobby, RoutePath.ConferenceLobbyWithCustomCompany];

    const isConferenceLobbyUrlMatch = noRedirectRoutes.some((x) => {
      return !!matchPath(currentPath, { path: x, exact: true, strict: true });
    });

    return isConferenceLobbyUrlMatch ? null : currentPath;
  }, [rest?.location?.pathname]);

  if (!authenticated) {
    signOut({ redirect });
  }

  useEffect(() => {
    if (currentAuthType === authType || !(setAuthType instanceof Function)) return;
    setAuthType(authType);
  }, [authType, currentAuthType, setAuthType]);

  return <Route render={(props) => (authenticated ? <Component {...props} /> : loadingComponent)} {...rest} />;
};

export default memo(PrivateRoute);
