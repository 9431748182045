import { isNullOrWhiteSpace, SelectPreset } from "@q4/nimbus-ui";
import { sortBy } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Answer } from "../../../../services/answer/answer.model";
import { QuestionType } from "../../../../services/questionGroup/questionGroup.model";
import { CreateNewCompanySuffix } from "../../../attendee/form/attendeeForm.definition";
import CustomSelect from "../../../customSelect/customSelect.component";
import { QuestionsFormSelectIdModel, QuestionsFormSelectProps } from "./questionsFormSelect.definition";

const QuestionsFormSelect = (props: QuestionsFormSelectProps): JSX.Element => {
  const { id, question, answer: answerProp, onChange } = props;

  const idModel = new QuestionsFormSelectIdModel(id);

  const isCustomSelection = useMemo(
    () => question.answer_type.field_type === QuestionType.CustomSelection,
    [question.answer_type.field_type]
  );

  const answer = useMemo(() => {
    const answerExists = question.options.some((x) => x.answer === answerProp);
    if (!answerExists && !isCustomSelection && !isNullOrWhiteSpace(answerProp as string)) {
      return null;
    }

    return answerProp as string;
  }, [answerProp, isCustomSelection, question?.options]);

  const getOptions = useCallback(() => {
    const options = (question.options || []).map((o) => o.answer);

    if (question.options_sort?.enabled) {
      return sortBy(options, (o) => o.toLowerCase());
    }
    return options;
  }, [question.options, question.options_sort?.enabled]);

  const handleOnSelectChange = useCallback(
    (value: string) => {
      let selectedOption = value ?? "";

      if (selectedOption.includes(CreateNewCompanySuffix)) {
        selectedOption = selectedOption.replace(CreateNewCompanySuffix, "");
      }

      onChange(
        new Answer({
          _question: question._id,
          answer: selectedOption,
        })
      );
    },
    [onChange, question._id]
  );

  return (
    <div id={idModel.id}>
      <CustomSelect
        id={idModel?.customSelect?.id}
        preset={SelectPreset.Autocomplete}
        placeholder={isCustomSelection ? "Select or Type an answer" : "Select an answer"}
        value={answer}
        options={getOptions() ?? []}
        isClearable
        backspaceRemovesValue
        onChange={handleOnSelectChange}
        addNew={isCustomSelection}
        addNewSuffix={CreateNewCompanySuffix}
        maxLength={100}
      />
    </div>
  );
};

export default memo(QuestionsFormSelect);
