import { arrayIndexFound, isEmpty, isNullOrWhiteSpace, NotificationService, SetNever } from "@q4/nimbus-ui";
import pluralize from "pluralize";
import { Dispatch, SetStateAction } from "react";
import { Entity, EntityBase } from "../../../definitions/entity.definition";
import { ApiMethod } from "../../../services/api/api.definition";
import { handleSetEntity } from "../useService.utils";

class MethodTerm {
  constructor(public fail?: string, public success?: string) {}
}

export const ServiceHookMethodTerms = {
  [ApiMethod.Put]: new MethodTerm("update", "updated"),
  [ApiMethod.Delete]: new MethodTerm("delete", "deleted"),
  [ApiMethod.Get]: new MethodTerm("load"),
  [ApiMethod.Post]: new MethodTerm("create", "created"),
  [ApiMethod.Patch]: new MethodTerm(),
};

export class UseServiceLogic {
  notificationService = new NotificationService();
  entitiesName: string;

  constructor(public entityName: Entity, public showNotifications = true, public assignDefaultEntity = true) {
    this.showNotifications = showNotifications ?? true;
    this.assignDefaultEntity = assignDefaultEntity ?? true;

    if (isNullOrWhiteSpace(entityName)) return;
    this.entitiesName = pluralize(entityName);
  }

  handleResponseNotification(options: ResponseNotificationProps): void {
    const { success, entityName: entityNameOption, method, message } = options || {};
    const { fail: failedMethodTerm, success: successMethodTerm } = ServiceHookMethodTerms[method] || {};
    const entityName = isNullOrWhiteSpace(entityNameOption) ? this.entityName : entityNameOption;

    if (!this.showNotifications || isNullOrWhiteSpace(entityName)) return;

    if (!success) {
      if (isNullOrWhiteSpace(failedMethodTerm)) return;

      const notificationMessage = isNullOrWhiteSpace(message) ? `Failed to ${failedMethodTerm} ${entityName}.` : message;

      this.notificationService.error(notificationMessage);
      return;
    }

    if (isNullOrWhiteSpace(successMethodTerm)) return;
    this.notificationService.success(`Successfully ${successMethodTerm} ${entityName}.`);
  }

  handleArrayedResponse<T extends EntityBase>(entities: T[], options: AssignmentProps<T>): T[] {
    if (isEmpty(entities)) return [];

    const { setEntity, setEntities } = options;
    setEntities(entities);
    setEntity((original) => handleSetEntity(original, entities, this.assignDefaultEntity));

    return entities;
  }

  handleNewResponse<T extends EntityBase>(options: PutResponseProps<T>): T {
    const { assignEntities, entity } = options;
    if (isEmpty(entity)) return null;

    if (!assignEntities) return entity;

    const { entityModel, setEntity, setEntities } = options;
    const model = new entityModel(entity);

    setEntity(model);
    setEntities((original) => original.concat(model));

    return model;
  }

  handleUpdateResponse<T extends EntityBase>(options: PutResponseProps<T>): T {
    const { assignEntities, entity } = options;
    if (isEmpty(entity)) return null;

    if (!assignEntities) return entity;

    const { entityModel, setEntity, setEntities } = options;
    const model = new entityModel(entity);

    setEntity(model);
    setEntities((original) => original.map((x) => (x._id === entity._id ? new entityModel({ ...x, ...entity }) : x)));

    return model;
  }

  handleDeleteResponse<T extends EntityBase>(id: EntityBase["_id"], success: boolean, options: AssignmentProps<T>): boolean {
    const { setEntity, setEntities } = options;

    setEntity((original) => (original?._id === id ? null : original));
    setEntities((original) => {
      if (isEmpty(original)) return original;

      const updated = [...original];
      const index = updated.findIndex((x) => x._id === id);
      if (!arrayIndexFound(index)) return updated;
      updated.splice(index, 1);
      return updated;
    });

    return success;
  }
}

// #region types
interface ResponseNotificationProps {
  success: boolean;
  entityName?: string;
  method?: ApiMethod;
  message?: string;
}

interface ResponseBaseProps<T extends EntityBase> {
  assignEntities?: boolean;
  entity: T;
}

interface AssignmentProps<T> {
  setEntity: Dispatch<SetStateAction<T>>;
  setEntities: Dispatch<SetStateAction<T[]>>;
}

interface ResponseAutoAssignProps<T extends EntityBase> extends Partial<AssignmentProps<T>> {
  entityModel?: new (entity: T) => T;
}

type PutResponseProps<T> =
  | ({ assignEntities: false } & ResponseBaseProps<T> & SetNever<ResponseAutoAssignProps<T>>)
  | ({ assignEntities: true } & ResponseBaseProps<T> & Required<ResponseAutoAssignProps<T>>);
// #endregion
