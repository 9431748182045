import ErrorHandlerService from "./errorHandler.service";

/* eslint-disable @typescript-eslint/ban-types */
export class ErrorModel {
  visible: boolean;
  icon: string;
  message: string;

  constructor(message: string, visible = false, icon = "ni-warning-4pt") {
    this.message = message;
    this.visible = visible;
    this.icon = icon;
  }
}

export class ErrorHandlerMessage<TKey> extends ErrorModel {
  id: TKey;
  prop: string;

  constructor(id: TKey, prop: string, message: string, visible = false) {
    super(message, visible);
    this.id = id;
    this.prop = prop;
  }
}

export class ErrorHandlerField {
  prop: string;
  errorMessage: string;
  condition: Function;

  constructor(prop: string, errorMessage: string, condition?: Function) {
    this.prop = prop;
    this.errorMessage = errorMessage;
    this.condition = condition;
  }
}

export interface ErrorHandlerFields {
  parent?: string[];
  dependency?: string[];
  type?: string;
  fields: ErrorHandlerField[];
  service?: ErrorHandlerService<number, string> | ErrorHandlerService<number, string>[];
}
