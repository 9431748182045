import "./speakers.component.scss";
import { Collapsable, getClassName, isEmpty, isNullOrWhiteSpace, objectSortComparer } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { LobbyDefaults } from "../../../../../services/conference/conference.model";
import Panel from "../../../../public/itinerary/components/panel/panel.component";
import { PanelProps } from "../../../../public/itinerary/components/panel/panel.definition";
import Slider from "../../../../public/itinerary/components/slider/slider.component";
import { ItineraryAnchors, ItineraryClassName } from "../../../../public/itinerary/itinerary.definition";
import Section from "../section/section.component";
import { SectionInnerPadding } from "../section/section.definition";
import CompactPanel from "./components/compactPanel/compactPanel.component";
import SpeakerDetails from "./components/details/speakerDetails.component";
import { SpeakerProps, SpeakersClassName, SpeakersIdModel, SpeakersItemIdModel } from "./speakers.definition";

function Speakers(props: SpeakerProps): JSX.Element {
  const {
    id,
    compact: compactProp,
    selectedSpeaker,
    speakers,
    speakerSectionConfig,
    visible: visibleProp,
    theme,
    keyLine,
    onSelect,
    branding,
  } = props;

  const visible = useMemo(() => visibleProp ?? true, [visibleProp]);
  const compact = useMemo(() => !!compactProp, [compactProp]);

  const idModel = useMemo(() => new SpeakersIdModel(id), [id]);
  const baseClassName = useMemo(
    () =>
      getClassName(SpeakersClassName.Base, [
        { condition: !!compact, trueClassName: SpeakersClassName.BaseWithCompactModifier },
      ]),
    [compact]
  );

  const hasSpeakers = useMemo(() => !isEmpty(speakers), [speakers]);

  const readLabel = useMemo(
    () => (isNullOrWhiteSpace(speakerSectionConfig?.label) ? LobbyDefaults.speakers.label : speakerSectionConfig.label),
    [speakerSectionConfig?.label]
  );

  const speakerTitle = useMemo(
    () => (isNullOrWhiteSpace(speakerSectionConfig?.title) ? LobbyDefaults.speakers.title : speakerSectionConfig.title),
    [speakerSectionConfig?.title]
  );

  const panels = useMemo<PanelProps[]>(getPanelProps, [
    hasSpeakers,
    speakers,
    selectedSpeaker?._id,
    readLabel,
    branding,
    onSelect,
  ]);

  const handleClose = useCallback((): void => {
    onSelect(null);
  }, [onSelect]);

  function getPanelProps(): PanelProps[] {
    if (!hasSpeakers) return [];
    return speakers.sort(objectSortComparer("order")).map((speaker) => {
      const { _id, display_name, company, title, image_profile } = speaker;

      function handleClick(): void {
        onSelect && onSelect(speaker);
      }

      return {
        key: _id,
        selected: _id === selectedSpeaker?._id,
        actionLabel: readLabel,
        accessibilityLabel: readLabel,
        title: display_name,
        details: [company, title],
        image: image_profile,
        onSelect: handleClick,
        branding: branding,
      };
    });
  }

  return (
    <Collapsable className={baseClassName} collapsed={!visible || !hasSpeakers}>
      <Section
        id={idModel.id}
        className={SpeakersClassName.Content}
        theme={theme}
        innerPadding={SectionInnerPadding.Thin}
        keyLine={keyLine}
        headerChildren={[
          <h2 key="speakers_heading" className={ItineraryClassName.Heading}>
            {speakerTitle}
          </h2>,
        ]}
        anchor={ItineraryAnchors.Speakers}
      >
        <Slider compact id={idModel.slider?.id} className={SpeakersClassName.Slider}>
          {panels.map((panel, index) => {
            const itemIdBase = idModel.list.getId(index);
            const itemIdModel = new SpeakersItemIdModel(itemIdBase);

            const PanelComponent = compact ? CompactPanel : Panel;
            return (
              <div key={panel.key} id={itemIdModel?.id} className={SpeakersClassName.Item}>
                <PanelComponent id={itemIdModel?.panel?.id} {...panel} />
              </div>
            );
          })}
        </Slider>
      </Section>
      <SpeakerDetails id={idModel.details?.id} speakers={speakers} selectedSpeaker={selectedSpeaker} onClose={handleClose} />
    </Collapsable>
  );
}

export default memo(Speakers);
