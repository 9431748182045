import "./onDemandForm.component.scss";
import {
  ComboBox,
  FileUploader,
  Form,
  getClassName,
  InputType,
  isEmpty,
  isNullOrWhiteSpace,
  Keyline,
  SelectPreset,
  Textarea,
  TextareaResize,
  Textbox,
} from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { CreateNewCodeSuffix } from "../../const";
import { useComboBox, useFileUpload } from "../../hooks";
import type { OnDemand } from "../../services/admin/onDemand/onDemand.model";
import { Speaker } from "../../services/speaker/speaker.model";
import { OnDemandFormClassName, OnDemandFormIdModel } from "./onDemandForm.definition";
import type { OnDemandFormProps } from "./onDemandForm.definition";

const OnDemandForm = (props: OnDemandFormProps): JSX.Element => {
  const { id, codes, className, onDemand, speakers: speakersProp, onUpdate } = props;

  const idModel = useMemo(() => new OnDemandFormIdModel(id), [id]);

  const handleFileUpload = useFileUpload();

  const baseClassName = useMemo(
    () =>
      getClassName(OnDemandFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  // #region Entity effects
  const {
    input: speakersInput,
    handleInputChange: handleSpeakersInputChange,
    chipsHook: useSpeakerChips,
    filteredOptions: speakers,
  } = useComboBox({
    options: speakersProp,
    chipsHookProps: {
      entities: onDemand?._speaker,
      labelKey: "display_name",
      valueKey: "_id",
    },
  });
  const [speakerChips, handleSpeakerChipAdd, handleSpeakerChipRemove] = useSpeakerChips;

  const {
    input: codeInput,
    handleInputChange: handleCodeInputChange,
    chipsHook: useCodeChips,
    filteredOptions: codeOptions,
  } = useComboBox({
    options: codes,
    createSuffix: CreateNewCodeSuffix,
    chipsHookProps: {
      entities: onDemand?.code,
    },
  });
  const [codeChips, handleCodeChipAdd, handleCodeChipRemove] = useCodeChips;
  // #endregion

  // #region Handle Methods
  function handleThumbnailChange(image_thumbnail: string): void {
    onUpdate({ image_thumbnail });
  }

  function handleDescriptionChange(description: string): void {
    onUpdate({ description });
  }

  function handleSpeakerSelect(speaker: Speaker): void {
    const _speaker = handleSpeakerChipAdd(speaker, Speaker);
    onUpdate({ _speaker });
  }

  function handleSpeakerRemove(value: string): void {
    const _speaker = handleSpeakerChipRemove(value);
    onUpdate({ _speaker });
  }

  function handleCodeSelect(value: string): void {
    const code = handleCodeChipAdd(value);
    onUpdate({ code });
  }

  function handleCodeRemove(value: string): void {
    const code = handleCodeChipRemove(value);
    onUpdate({ code });
  }
  // #endregion

  // #region Helper Methods
  function getTextboxHandler(key: keyof OnDemand) {
    return function (value: string): void {
      onUpdate({ [key]: value });
    };
  }

  function handleOrderChange(value: string): void {
    const order = Number(value);

    if (isNaN(order)) return;

    onUpdate({ order });
  }
  // #endregion

  return (
    <div className={baseClassName}>
      <Form
        id={idModel.id}
        fields={[
          {
            key: "Presentation Title",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Name",
            children: <Textbox id={idModel.title?.id} value={onDemand?.title} onChange={getTextboxHandler("title")} />,
          },
          {
            key: "Image",
            width: "2-of-5",
            smallWidth: "1-of-1",
            label: "Thumbnail Image",
            optional: true,
            children: (
              <FileUploader
                id={idModel.thumbnail.id}
                dropzoneProps={{ accept: ["image/*"] }}
                fileApi={handleFileUpload}
                fileUrl={onDemand?.image_thumbnail}
                onChange={handleThumbnailChange}
                showPreview={true}
              />
            ),
          },
          {
            key: "Description",
            width: "3-of-5",
            smallWidth: "1-of-1",
            label: "Description",
            optional: true,
            children: (
              <Textarea
                id={idModel.description?.id}
                className={OnDemandFormClassName.Description}
                value={onDemand?.description}
                resize={TextareaResize.None}
                onChange={handleDescriptionChange}
              />
            ),
          },
          {
            key: "Presentation Url Override",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Link",
            children: (
              <Textbox
                id={idModel.overrideUrl?.id}
                value={onDemand?.urlOverride}
                onChange={getTextboxHandler("urlOverride")}
              />
            ),
          },
          {
            key: "Presentation Codes",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Presentation Codes",
            children: (
              <ComboBox
                id={idModel.code?.id}
                selectProps={{
                  preset: SelectPreset.Autocomplete,
                  placeholder: "Select or create a code",
                  inputValue: codeInput,
                  options: codeOptions,
                  onChange: handleCodeSelect,
                  onInputChange: handleCodeInputChange,
                  menuPlacement: "top",
                }}
                chipsProps={{
                  items: codeChips,
                  inline: true,
                  onClick: handleCodeRemove,
                  onRemove: handleCodeRemove,
                }}
              />
            ),
          },
          {
            key: "Speakers",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Speakers",
            optional: true,
            margin: false,
            children: (
              <ComboBox
                id={idModel.speakers?.id}
                selectProps={{
                  preset: SelectPreset.Autocomplete,
                  placeholder: "Select an speaker",
                  inputValue: speakersInput,
                  valueKey: "_id",
                  labelKey: "display_name",
                  options: speakers,
                  onChange: handleSpeakerSelect,
                  onInputChange: handleSpeakersInputChange,
                  disabled: isEmpty(speakers),
                  menuPlacement: "top",
                }}
                chipsProps={{
                  items: speakerChips,
                  onClick: handleSpeakerRemove,
                  onRemove: handleSpeakerRemove,
                }}
              />
            ),
          },
        ]}
      />
      <Keyline margin />
      <Form
        fields={[
          {
            key: "speaker-order",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Order",
            children: (
              <Textbox
                id={idModel.order?.id}
                inputType={InputType.Number}
                min={1}
                value={onDemand?.order}
                onChange={handleOrderChange}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(OnDemandForm);
