import { isNullOrWhiteSpace, usePolling } from "@q4/nimbus-ui";
import { useEffect, useState, useCallback } from "react";
import Cookies from "universal-cookie";
import config from "../../../config/config";

const FETCH_LATEST_VERSION_INTERVAL = 60 * 1000; // 1min

/**
 * Helper hook to get the most latest app version
 */

interface AppVersionQueryState {
  loading: boolean;
  error: boolean;
  currentVersion: string;
  expectedVersion: string;
  isExpectedVersion: boolean;
}

export default function useAppVersionQuery(): AppVersionQueryState {
  const [state, setState] = useState<AppVersionQueryState>({
    loading: false,
    error: false,
    isExpectedVersion: true,
    currentVersion: null,
    expectedVersion: null,
  });

  const mockReturnValue = () => {
    const newState = {
      //hardcoded versions so that modal doesn't get triggered
      expectedVersion: config.version,
      currentVersion: config.version,
      loading: false,
      error: false,
      isExpectedVersion: true,
    };

    if (!newState?.currentVersion || !newState?.expectedVersion) {
      newState.error = true;
    } else if (newState?.currentVersion !== newState?.expectedVersion) {
      newState.isExpectedVersion = false;
      const cookies = new Cookies();
      cookies.set("x-version", newState.expectedVersion, { path: "/" });
    } else {
      newState.isExpectedVersion = true;
    }

    setState((state) => ({ ...state, ...newState }));
    return newState;
  };

  const fetchVersionByHeaders = useCallback(() => {
    // TO DO: FETCH META JSON HERE TO GET EXPECTED VERSION
    // remove the mock return value
    return mockReturnValue();
  }, []);

  usePolling({
    allowPolling: state.isExpectedVersion,
    onPollingComplete: fetchVersionByHeaders,
    interval: FETCH_LATEST_VERSION_INTERVAL,
  });

  useEffect(() => {
    if (isNullOrWhiteSpace(state.currentVersion)) {
      fetchVersionByHeaders();
    }
  }, [fetchVersionByHeaders, state.currentVersion, state.expectedVersion]);

  return state;
}
