import "./timeZoneSelector.component.scss";
import { ButtonTheme, getClassName, isNullOrWhiteSpace, PopoverMenuButtonProps } from "@q4/nimbus-ui";
import moment from "moment-timezone";
import React, { memo, useCallback, useMemo } from "react";
import AnchorWithPopoverMenu from "../../../../components/anchorWithPopoverMenu/anchorWithPopoverMenu.component";
import { formatTimeZoneLabel, getTimeZoneOffsetLabelInHours } from "../../../../utils";
import { TimeZoneSelectorClassName, TimeZoneSelectorIdModel, TimeZoneSelectorProps } from "./timeZoneSelector.definition";

const TimeZoneSelector = (props: TimeZoneSelectorProps): JSX.Element => {
  const { id, className, anchorMargin, selectedTimeZone, conferenceTimeZone, onSelect } = props;

  const idModel = useMemo(() => new TimeZoneSelectorIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(TimeZoneSelectorClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
        {
          condition: !!anchorMargin,
          trueClassName: TimeZoneSelectorClassName.BaseWithMarginModifier,
        },
      ]),
    [anchorMargin, className]
  );

  const label = useMemo(() => {
    const timeZone = selectedTimeZone ?? conferenceTimeZone;
    const timeZoneAbbr = formatTimeZoneLabel(timeZone ?? moment.tz.guess());

    return (
      <>
        <span className={`${TimeZoneSelectorClassName.Icon} q4i-time-4pt`} />
        <span className={TimeZoneSelectorClassName.Label}>{`${timeZoneAbbr} (Change Timezone)`}</span>
      </>
    );
  }, [conferenceTimeZone, selectedTimeZone]);

  const getTimeZoneLabel = useCallback((timeZone: string) => {
    if (isNullOrWhiteSpace(timeZone)) return null;
    const offsetLabel = getTimeZoneOffsetLabelInHours(timeZone);
    return `(GMT ${offsetLabel}) ${timeZone}`;
  }, []);

  const handleTimeZoneSelectChange = useCallback(
    (value: string) => {
      return () => {
        onSelect(value);
      };
    },
    [onSelect]
  );

  const browserTimeZone = useMemo(() => moment.tz.guess(), []);

  const isSelectedTimeZoneIncluded = useMemo(
    () => selectedTimeZone === conferenceTimeZone || selectedTimeZone === browserTimeZone,
    [browserTimeZone, conferenceTimeZone, selectedTimeZone]
  );

  const options: PopoverMenuButtonProps[] = useMemo(() => {
    const defaultOptions: { id: string; label: string; timeZone: string }[] = [
      {
        id: idModel.conferenceTimeZone?.id,
        label: "conference",
        timeZone: conferenceTimeZone,
      },
      {
        id: idModel.browserTimeZone?.id,
        label: "browser",
        timeZone: browserTimeZone,
      },
    ];

    if (!isSelectedTimeZoneIncluded && !isNullOrWhiteSpace(selectedTimeZone)) {
      defaultOptions.push({
        id: idModel.selectedTimeZone?.id,
        label: "chosen",
        timeZone: selectedTimeZone,
      });
    }

    return defaultOptions.map((option) => ({
      id: option.id,
      label: `Use ${option.label} timezone ${getTimeZoneLabel(option.timeZone)}`,
      theme: ButtonTheme.Transparent,
      onClick: handleTimeZoneSelectChange(option.timeZone),
    }));
  }, [
    browserTimeZone,
    conferenceTimeZone,
    getTimeZoneLabel,
    handleTimeZoneSelectChange,
    idModel,
    isSelectedTimeZoneIncluded,
    selectedTimeZone,
  ]);

  return (
    <span id={idModel.id} className={baseClassName}>
      <AnchorWithPopoverMenu id={idModel.anchorPopoverMenu?.id} label={label} options={options} />
    </span>
  );
};

export default memo(TimeZoneSelector);
