import { Anchor, AnchorTarget, isEmpty, isNil, isNullOrWhiteSpace, Text } from "@q4/nimbus-ui";
import { Dictionary, groupBy, upperCase } from "lodash";
import moment from "moment-timezone";
import React, { memo, useCallback, useMemo } from "react";
import { DefaultTimeZone } from "../../../../../const";
import { DefaultInternalCompanyName } from "../../../../../definitions/agenda.definition";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import { LobbyDefaults, VendorName } from "../../../../../services/conference/conference.model";
import { Meeting } from "../../../../../services/meeting/meeting.model";
import { Presentation, PresentationSessionType } from "../../../../../services/presentation/presentation.model";
import { SessionVendor } from "../../../../../services/session/session.model";
import { Speaker } from "../../../../../services/speaker/speaker.model";
import {
  formatMeetingAttendee,
  getAgenda,
  getConferenceDateLabel,
  getCorporateMeetingAttendees,
  getFullConferenceUrl,
  getInternalMeetingAttendees,
  getMeetingLabel,
  getPillText,
  getPublicLinkUrl,
  groupAttendeesByCompany,
  htmlParse,
  isCorporate,
  isCorporateType,
  isInternal,
  mapEntities,
} from "../../../../../utils";
import { AgendaClassName, AgendaSession, AgendaSessionLabel } from "../agenda/agenda.definition";
import { getDialInNumbers } from "../dialInformation/dialInformation.helper";
import { MsTeamsDialInformationLink, ZoomDialInformationLink } from "../dialInformation/dialInformation.utils";
import {
  ItineraryPdfDisclaimer,
  ItineraryPdfAgenda,
  ItineraryPdfProps,
  ItineraryPdfTimeZoneFormat,
  styleGroups,
  ItineraryLinkDecorator,
  ItineraryPdfIdModel,
} from "./itineraryPdf.definition";

function ItineraryPdf(props: ItineraryPdfProps): JSX.Element {
  const { id, user, attendees, conference, presentations, meetings, company } = props;

  const idModel = useMemo(() => new ItineraryPdfIdModel(id), [id]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference]);
  const userTimezone = useMemo(() => user?.time_zone ?? conferenceTimeZone, [conferenceTimeZone, user?.time_zone]);
  const conferencePresentations = useMemo(() => conference?._presentation, [conference]);
  const conferenceCompany = useMemo(
    () => (!isEmpty(conference?._company) ? conference?._company.name : DefaultInternalCompanyName),
    [conference?._company]
  );

  const isLinkProtected = useMemo(
    () => conference?.requireAuthenticatedMeetingLink ?? true,
    [conference?.requireAuthenticatedMeetingLink]
  );

  const previewMode = useMemo(() => conference?.preview ?? false, [conference?.preview]);

  const agendaAttendees = useMemo(() => (attendees || []).map((x) => new AttendeeViewModel(x)), [attendees]);

  const dialInstructions = useMemo(
    () =>
      isEmpty(conference?.lobby?.dialInInstructions)
        ? LobbyDefaults.dialInInstructions
        : conference?.lobby?.dialInInstructions,
    [conference?.lobby?.dialInInstructions]
  );

  const agenda = useMemo(() => {
    const mappedPresentations = !isEmpty(conferencePresentations)
      ? mapEntities<Presentation>(presentations, conferencePresentations)
      : presentations;
    return getAgenda(meetings, mappedPresentations, agendaAttendees, user, conference, company);
  }, [agendaAttendees, company, conference, conferencePresentations, meetings, presentations, user]);

  const getItineraryAgenda = useCallback(
    (agenda: AgendaSession): ItineraryPdfAgenda => {
      if (isEmpty(agenda)) return null;
      return new ItineraryPdfAgenda(agenda, userTimezone);
    },
    [userTimezone]
  );
  const itineraryGroups = useMemo(() => {
    if (isEmpty(agenda)) return null;

    const itineraryAgenda = agenda.map(getItineraryAgenda).filter((agenda) => !isEmpty(agenda));
    return groupBy(itineraryAgenda, "day");
  }, [agenda, getItineraryAgenda]);

  function renderDialInNumbers(vendorName: VendorName): JSX.Element {
    return (
      <div style={styleGroups.dialGroupContainer}>
        {getDialInNumbers<JSX.Element>(
          vendorName,
          (item) => {
            return (
              <span key={item} style={styleGroups.dialNumbers}>
                {item}
              </span>
            );
          },
          (key, item) => {
            return (
              <div key={key}>
                <p style={styleGroups.dialSubheading}>{key}</p>
                {item}
              </div>
            );
          },
          (group, index) => {
            return <div key={`country-group-${index}`}>{group}</div>;
          }
        )}
      </div>
    );
  }

  function renderSpeakers(speakers: Speaker[]): JSX.Element {
    if (isEmpty(speakers)) return null;

    return (
      <span
        style={{
          ...styleGroups.attendee,
          ...styleGroups.firstAttendee,
        }}
      >
        {speakers
          .filter((x) => x?._id !== user?._id)
          .map((speaker) => {
            const { display_name, title } = speaker;
            return `${display_name}${!isNullOrWhiteSpace(title) ? `, ${title}` : ""}`;
          })
          .join(" | ")}
      </span>
    );
  }

  function renderVendorOverride(
    vendor_override: AgendaSession["vendor_override"],
    _id: string,
    link: string,
    linkLabel: string
  ) {
    return (
      <div key={`session-${vendor_override.meeting_id}`} id={idModel.sessionInfo.getId(vendor_override.meeting_id)}>
        <p>
          <Anchor id={idModel.vendor.getId(_id)} url={link} target={AnchorTarget.Blank}>
            {linkLabel}
          </Anchor>
        </p>
        {!isEmpty(vendor_override.meeting_id) && (
          <>
            {!isEmpty(vendor_override.meeting_id) && (
              <div>
                <span>
                  <strong>Meeting ID:</strong>
                </span>{" "}
                {vendor_override.meeting_id}
              </div>
            )}
            {!isEmpty(vendor_override.meeting_password) && (
              <div>
                <span>
                  <strong>Passcode:</strong>
                </span>{" "}
                {vendor_override.meeting_password}
              </div>
            )}
            {!isEmpty(vendor_override.phone_password) && (
              <div>
                <span>
                  <strong>Phone password:</strong>
                </span>{" "}
                {vendor_override.phone_password}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  function renderSessionInfo(
    _id: AgendaSession["_id"],
    readonly: AgendaSession["Readonly"],
    goUrl: AgendaSession["GoUrl"],
    vendor: AgendaSession["vendor"],
    vendor_override: AgendaSession["vendor_override"],
    isUserSpeaker: AgendaSession["isUserSpeaker"],
    linkId: AgendaSession["linkId"],
    presentation_type: AgendaSession["presentation_type"]
  ): JSX.Element {
    if (isNullOrWhiteSpace(goUrl)) return;

    const link = isLinkProtected || isUserSpeaker ? encodeURI(goUrl) : getPublicLinkUrl(linkId);
    const joinLabel = !isNullOrWhiteSpace(presentation_type)
      ? `Join ${presentation_type} ${ItineraryLinkDecorator}`
      : `Join ${ItineraryLinkDecorator}`;

    if (!readonly) {
      if (isUserSpeaker) {
        return (
          <>
            <Anchor url={link} target={AnchorTarget.Blank}>
              Join as Speaker {ItineraryLinkDecorator}
            </Anchor>
          </>
        );
      }
      return (
        <>
          {isEmpty(vendor_override) && (
            <>
              <Anchor className={AgendaClassName.PresentationAnchor} url={link} target={AnchorTarget.Blank}>
                {joinLabel}
              </Anchor>
            </>
          )}
          {!isEmpty(vendor_override) && renderVendorOverride(vendor_override, _id, link, joinLabel)}
        </>
      );
    }

    if (isEmpty(vendor) && isEmpty(vendor_override) && isNullOrWhiteSpace(link)) return;

    const hasMultipleVendors = vendor.length > 1;

    return (
      <>
        {/* when vendor override */}
        {!isEmpty(vendor_override) &&
          renderVendorOverride(vendor_override, _id, link, `Meeting Link ${ItineraryLinkDecorator}`)}
        {/* when vendor */}
        {isEmpty(vendor_override) &&
          !isEmpty(vendor) &&
          vendor.map((x: SessionVendor, i: number) => {
            const url = i === 0 ? link : encodeURI(x.url);
            const linkText = `${hasMultipleVendors ? `${upperCase(x.vendor)} ` : ""}Meeting Link`;
            const hasMeetingId = !isNullOrWhiteSpace(x.vendor_id);
            const hasPasscode = !isNullOrWhiteSpace(x.password);
            const id = idModel.vendor.getId(_id);

            return (
              <div key={`meeting-${x.vendor_id}`} id={idModel.sessionInfo.getId(x.vendor_id)}>
                <p>
                  <Anchor id={id} url={url} target={AnchorTarget.Blank} className={AgendaClassName.PresentationAnchor}>
                    {linkText} {ItineraryLinkDecorator}
                  </Anchor>
                </p>
                {(hasMeetingId || hasPasscode) && (
                  <>
                    {hasMeetingId && (
                      <div>
                        <span>
                          <strong>Meeting ID:</strong>
                        </span>{" "}
                        {x.vendor_id}
                      </div>
                    )}
                    {hasPasscode && (
                      <div>
                        <span>
                          <strong>Passcode:</strong>
                        </span>{" "}
                        {x.password}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        {/* when only meeting override */}
        {isEmpty(vendor_override) && isEmpty(vendor) && !isNullOrWhiteSpace(link) && (
          <div key={link}>
            <Anchor id={id} url={link} target={AnchorTarget.Blank}>
              Meeting Link {ItineraryLinkDecorator}
            </Anchor>
          </div>
        )}
      </>
    );
  }

  function renderAgenda(itineraryAgendas: ItineraryPdfAgenda[]): JSX.Element[] {
    if (isEmpty(itineraryAgendas)) return null;

    return itineraryAgendas.map((itinerary: ItineraryPdfAgenda) => {
      const {
        _id: key,
        label,
        time,
        title,
        presentation_type,
        upcoming,
        link,
        labelOverride,
        attendees: participants,
        speakers,
        meridiem,
        timezone,
        vendor,
        vendor_override,
        isUserSpeaker,
        speakerNotes,
        linkId,
        roomLocation,
        description,
        session_type,
      } = itinerary;

      const isPresentation = label === AgendaSessionLabel.Presentation;
      const isBreakSession = session_type === PresentationSessionType.Break;
      const meetingType = !isNullOrWhiteSpace(labelOverride)
        ? labelOverride
        : getMeetingLabel(
            new Meeting({
              ...itinerary,
              _attendee: itinerary?.attendees,
            })
          );

      function renderSessionTypeLabel(): JSX.Element {
        const pillText = getPillText(isPresentation, isBreakSession, meetingType, presentation_type);
        if (isNullOrWhiteSpace(pillText)) return null;
        return (
          <span className="pill-text" style={styleGroups.meetingCellContent}>
            {pillText}
          </span>
        );
      }

      return (
        <div
          className="table-row"
          key={key}
          style={{ ...styleGroups.flexRow, ...styleGroups.tableRow, ...styleGroups.pageBreakAvoid }}
        >
          <div
            style={{ ...styleGroups.base, ...styleGroups.bodyCell, ...styleGroups.timeCell, ...styleGroups.pageBreakAvoid }}
          >
            <div style={styleGroups.timeCellContent}>{time}</div>
            <div style={styleGroups.timeCellZone}>
              {meridiem} {timezone}
            </div>
            {!isNullOrWhiteSpace(roomLocation) && (
              <div style={styleGroups.timeCellLocation}>
                <strong style={{ ...styleGroups.label }}>{roomLocation}</strong>
              </div>
            )}
          </div>
          <div
            style={{ ...styleGroups.titleCell, ...styleGroups.base, ...styleGroups.bodyCell, ...styleGroups.pageBreakAvoid }}
          >
            {renderTitleAndAttendees(
              title,
              isPresentation,
              participants,
              speakers,
              user,
              isUserSpeaker,
              speakerNotes,
              session_type,
              description
            )}
            {renderVendorOverridePhoneNumber(vendor_override?.dial_in_numbers)}
          </div>
          <div
            style={{
              ...styleGroups.base,
              ...styleGroups.bodyCell,
              ...styleGroups.meetingCell,
              ...styleGroups.pageBreakAvoid,
            }}
          >
            {renderSessionTypeLabel()}
          </div>
          <div
            style={{
              ...styleGroups.base,
              ...styleGroups.bodyCell,
              ...styleGroups.locationCell,
              ...styleGroups.pageBreakAvoid,
            }}
          >
            <div style={{ alignSelf: "center", ...styleGroups.wrapText }}>
              {upcoming && !previewMode && !isBreakSession
                ? renderSessionInfo(
                    key,
                    !isPresentation,
                    link,
                    vendor,
                    vendor_override,
                    isUserSpeaker,
                    linkId,
                    presentation_type
                  )
                : "–"}
            </div>
          </div>
        </div>
      );
    });
  }

  function renderVendorOverridePhoneNumber(dial_in_numbers: string[]) {
    if (dial_in_numbers?.length) {
      return (
        <>
          <strong style={styleGroups.label}>Dial-in Telephone Numbers:</strong>
          <div style={styleGroups.vendorOverrideDialInGrid}>
            {dial_in_numbers.map((dialIn) => (
              <div key={dialIn} style={{ ...styleGroups.base, ...styleGroups.vendorOverrideDialInCell }}>
                {dialIn}
              </div>
            ))}
          </div>
        </>
      );
    }

    return null;
  }

  function renderTitleAndAttendees(
    title: string,
    isPresentation: boolean,
    participants: AttendeeViewModel[],
    speakers: Speaker[],
    userProfile: AttendeeViewModel,
    isUserSpeaker: AgendaSession["isUserSpeaker"],
    speakerNotes: AgendaSession["speaker_notes"],
    sessionType?: AgendaSession["session_type"],
    description?: AgendaSession["description"]
  ): JSX.Element {
    if (isPresentation)
      return (
        <>
          <div style={styleGroups.titleCell}>{title}</div>
          {sessionType === PresentationSessionType.Break && !isNullOrWhiteSpace(description) && (
            <div>
              <strong style={styleGroups.label}>Description:</strong>{" "}
              {<span style={styleGroups.wrapText}>{description}</span>}
            </div>
          )}
          {!isEmpty(speakers) && (
            <div>
              <strong style={styleGroups.label}>Speakers:</strong> {renderSpeakers(speakers)}
            </div>
          )}
          {isUserSpeaker && !isNullOrWhiteSpace(speakerNotes) && (
            <div>
              <strong style={styleGroups.label}>Speaker Notes:</strong> {htmlParse(speakerNotes)}
            </div>
          )}
        </>
      );

    if (isInternal(userProfile)) {
      const corpNameAndAttendeesForInternal = renderCorporateTitleAndAttendees(participants, userProfile);
      const investorCompanyNameAndAttendeesForInternal = renderInvestorTitleAndAttendees(participants);

      return (
        <>
          {corpNameAndAttendeesForInternal}
          {investorCompanyNameAndAttendeesForInternal}
        </>
      );
    }

    const corporateTitleAndAttendee = renderCorporateTitleAndAttendees(participants, userProfile);
    if (!isEmpty(corporateTitleAndAttendee)) return corporateTitleAndAttendee;

    return renderInvestorTitleAndAttendees(participants);
  }

  function renderCorporateTitleAndAttendees(participants: AttendeeViewModel[], userProfile: AttendeeViewModel) {
    if (!isCorporate(userProfile) && !isInternal(userProfile)) return null;

    const filteredAttendees = groupAttendeesByCompany(conferenceCompany, userProfile?.company_name, participants);

    return renderCompanies(filteredAttendees);
  }

  function renderCompany(companyName: string, meetingId: string, participants: string) {
    return (
      <Text customElementType="div" id={meetingId} key={meetingId} className={AgendaClassName.Session}>
        <div key={companyName}>
          <div style={styleGroups.titleCell}>
            <strong style={styleGroups.label}>{companyName}</strong>
          </div>
          <div style={styleGroups.corporateParticipants}>
            <strong style={styleGroups.label}>Participants: </strong>
            {participants}
          </div>
        </div>
      </Text>
    );
  }

  function renderInvestorTitleAndAttendees(participants: AttendeeViewModel[]): JSX.Element {
    const corporateName = participants.find((x) => isCorporateType(x.type))?.company_name ?? "";
    const corporateAttendees = getCorporateMeetingAttendees(corporateName, participants);
    const internalAttendees = getInternalMeetingAttendees(participants);
    const meetingId = idModel.meeting.getId(corporateName);
    const meetingInternalParticpantsId = idModel.meeting.getId(DefaultInternalCompanyName);

    const corporateTitleAndAttendees =
      !isEmpty(corporateAttendees) && !isNullOrWhiteSpace(corporateName)
        ? renderCompany(corporateName, meetingId, corporateAttendees)
        : "";

    const internalTitleAndAtendees =
      !isInternal(user) &&
      !isNullOrWhiteSpace(internalAttendees) &&
      renderCompany(conferenceCompany, meetingInternalParticpantsId, internalAttendees);

    return (
      <>
        {corporateTitleAndAttendees}
        {internalTitleAndAtendees}
      </>
    );
  }

  function renderCompanies(groupedAttendeesByCompany: Dictionary<AttendeeViewModel[]>): JSX.Element {
    const meetingInternalParticpantsId = idModel.meeting.getId(DefaultInternalCompanyName);

    return (
      <>
        {Object.keys(groupedAttendeesByCompany).map((companyName) => {
          const meetingId = idModel.meeting.getId(companyName);
          if (companyName !== conferenceCompany) {
            return (
              <Text customElementType="div" id={meetingId} key={meetingId} className={AgendaClassName.Session}>
                <div key={companyName}>
                  <div style={styleGroups.titleCell}>
                    <strong style={styleGroups.label}>{companyName}</strong>
                  </div>
                  <div style={styleGroups.corporateParticipants}>
                    <strong style={styleGroups.label}>Participants: </strong>
                    {groupedAttendeesByCompany[companyName].map((x) => formatMeetingAttendee(x)).join(" | ")}
                  </div>
                </div>
              </Text>
            );
          }
        })}
        {!isNil(groupedAttendeesByCompany) && !isEmpty(groupedAttendeesByCompany[conferenceCompany]) && !isInternal(user) && (
          <Text
            customElementType="div"
            id={meetingInternalParticpantsId}
            key={meetingInternalParticpantsId}
            className={AgendaClassName.Session}
          >
            <div key={conferenceCompany}>
              <div style={styleGroups.titleCell}>
                <strong style={styleGroups.label}>{conferenceCompany}</strong>
              </div>
              <div style={styleGroups.corporateParticipants}>
                <strong style={styleGroups.label}>Participants: </strong>
                {groupedAttendeesByCompany[conferenceCompany].map((x) => formatMeetingAttendee(x)).join(" | ")}
              </div>
            </div>
          </Text>
        )}
      </>
    );
  }

  function renderAgendaGroups(): JSX.Element[] {
    if (isEmpty(itineraryGroups)) return null;

    const agendaGroupKeys = Object.keys(itineraryGroups);

    return agendaGroupKeys.map((agendaGroupKey) => {
      const agendaSessions = itineraryGroups[agendaGroupKey];
      return (
        <div key={agendaGroupKey} style={{ ...styleGroups.group }} className={AgendaClassName.GroupContainer}>
          <h3 style={{ ...styleGroups.base, ...styleGroups.date }}>
            {agendaGroupKey}{" "}
            <span style={styleGroups.allTimes}>
              All times are {moment(agendaGroupKey).tz(userTimezone).format(ItineraryPdfTimeZoneFormat)}
            </span>
          </h3>
          <div style={styleGroups.table}>
            <div style={{ ...styleGroups.tableHeader, ...styleGroups.flexRow }}>
              <div style={{ ...styleGroups.timeCellHeader, ...styleGroups.base, ...styleGroups.headerCell }}>Time</div>
              <div style={{ ...styleGroups.titleCell, ...styleGroups.base, ...styleGroups.headerCell }}>
                Event Title &amp; Attendees
              </div>
              <div
                style={{
                  ...styleGroups.base,
                  ...styleGroups.headerCell,
                  ...styleGroups.meetingCell,
                }}
              >
                Meeting Type
              </div>
              <div style={{ ...styleGroups.base, ...styleGroups.headerCell, ...styleGroups.locationCellHeader }}>Link</div>
            </div>
          </div>
          {renderAgenda(agendaSessions)}
        </div>
      );
    });
  }

  function renderConferenceCoordinator(): JSX.Element {
    if (isEmpty(conference?.coordinator)) return null;

    const { full_name, email, phone } = conference.coordinator;

    let coordinator: string;

    coordinator = `${full_name} - ${phone} ${email ? `(${email})` : ""}`;

    if (isNullOrWhiteSpace(phone)) {
      coordinator = `${full_name} ${email ? `- (${email})` : ""}`;
    }

    return (
      <section style={styleGroups.coordinators}>
        <u>Conference Coordinator(s)</u>: {coordinator}
      </section>
    );
  }

  function renderHeaderLogo(): JSX.Element {
    const logo = conference?.image_logo_secondary ?? conference?.image_logo;

    if (isNullOrWhiteSpace(logo)) return null;
    return (
      <section style={styleGroups.flexBetween}>
        <div style={styleGroups.separator}></div>
        <img style={styleGroups.logo} src={logo} alt="Company Logo" />
      </section>
    );
  }

  function renderHeader(): JSX.Element {
    return (
      <header style={styleGroups.header}>
        <div style={styleGroups.subheader}>
          <section style={styleGroups.details}>
            <h1 style={{ ...styleGroups.base, ...styleGroups.title }}>{conference?.title}</h1>
            <h2 style={{ ...styleGroups.base, ...styleGroups.conferenceDate }}>
              {`${getConferenceDateLabel(conference, false)} 
              ${conference?.start_date.clone().tz(conferenceTimeZone).format(ItineraryPdfTimeZoneFormat)}`}
            </h2>
            {!dialInstructions.disabled && <h3 style={{ fontWeight: 200 }}>{dialInstructions.title}</h3>}
            <div style={styleGroups.description}>
              {dialInstructions.disabled
                ? !isNullOrWhiteSpace(conference?.info)
                  ? htmlParse(conference?.info)
                  : htmlParse(conference?.description)
                : htmlParse(dialInstructions.label)}
            </div>
          </section>
          {renderHeaderLogo()}
        </div>
      </header>
    );
  }

  function renderAttendeeInfo(): JSX.Element {
    return (
      <section style={{ ...styleGroups.subheader, ...styleGroups.keyline }}>
        <div>
          <h3 style={{ ...styleGroups.base, ...styleGroups.attendeeInfo }}>
            Agenda for {user?.display_name}{" "}
            {!isNullOrWhiteSpace(user?.company_name) && <div style={styleGroups.attendeeCompany}>{user?.company_name}</div>}
          </h3>
        </div>
        <div>
          <a style={styleGroups.link} href={getFullConferenceUrl(conference?._company, conference)}>
            View Your Itinerary Online
          </a>
        </div>
      </section>
    );
  }

  function renderDialInformation(): JSX.Element {
    if (isEmpty(conference?.video_vendors)) return null;

    return (
      <div style={{ marginTop: "40px" }}>
        <h3>Dial In Information</h3>
        {(conference?.video_vendors || []).map((x) => {
          return (
            <div key={`dialInformation-${x.name}`}>
              <p>
                <Anchor
                  url={
                    x.name === VendorName.Zoom ? encodeURI(ZoomDialInformationLink) : encodeURI(MsTeamsDialInformationLink)
                  }
                  target={AnchorTarget.Blank}
                >
                  Click here to find your local number {">"}
                </Anchor>
              </p>
              <p>
                <strong>DIAL BY YOUR LOCATION ({upperCase(x.name)})</strong>
              </p>
              {renderDialInNumbers(x.name)}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div id={idModel.id} style={styleGroups.base}>
      {renderHeader()}
      {renderAttendeeInfo()}
      {renderAgendaGroups()}
      {renderConferenceCoordinator()}
      <p style={styleGroups.disclaimer}>
        <em>{ItineraryPdfDisclaimer}</em>
      </p>
      {!previewMode && renderDialInformation()}
    </div>
  );
}

export default memo(ItineraryPdf);
