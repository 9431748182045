import {
  Anchor,
  AnchorTarget,
  AnchorTheme,
  arrayIndexFound,
  Button,
  ButtonTheme,
  ErrorModel,
  ComponentSizeModifier,
  convertStringToEnum,
  Ghostable,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Select,
  SelectPreset,
  SelectTheme,
  TableProps,
  Text,
  TextPreset,
  ToggleButtons,
  ToolbarTheme,
  useStateRef,
  useVisibility,
} from "@q4/nimbus-ui";
import type {
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
} from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { EntityTableClassName, EntityTableRef } from "../../../../../../components/entityTable/entityTable.definition";
import PresentationForm from "../../../../../../components/presentationForm/presentationForm.component";
import {
  PresentationEditState,
  PresentationFormErrorMessages,
} from "../../../../../../components/presentationForm/presentationForm.definition";
import { Entity } from "../../../../../../definitions/entity.definition";
import { PresentationPusherChannel } from "../../../../../../hooks/useEditNotifications/useEditNotifications.definition";
import { Presentation, PresentationSessionType } from "../../../../../../services/presentation/presentation.model";
import { Track } from "../../../../../../services/track/track.model";
import { basicCompare, getOptions, mapPresentation, validateNonRequiredUrlString } from "../../../../../../utils";
import PresentationManageAttendeesModal from "./components/manageAttendees/manageAttendees.component";
import { getFormattedSpeaker, getFormattedDate, getFormattedTrack, getComparator, setPresentationPayload } from "./helpers";
import {
  ColumnDefs,
  PresentationClassName,
  PresentationEditClassName,
  PresentationsIdModel,
  PresentationTableHeader,
  SessionsLink,
} from "./presentations.definition";
import type { PresentationsProps } from "./presentations.definition";

const Presentations = (props: PresentationsProps): JSX.Element => {
  const { id, codes, conference, speakers, tracks, useAttendees, usePresentations, user, suppressColumnVirtualisation } =
    props;

  const idModel = useMemo(() => new PresentationsIdModel(id), [id]);

  const [isPrivate, isPrivateRef, setIsPrivate] = useStateRef(false);
  const [formErrors, setFormErrors] = useState<Record<string, ErrorModel>>({});
  const [selectedFilter, selectedFilterRef, setSelectedFilter] = useStateRef(null);

  const attendeesPresentation = useRef<Presentation>(null);
  const tableRef = useRef<EntityTableRef>(null);

  const [attendeesVisible, showAttendees, hideAttendees] = useVisibility();

  const {
    current: currentPresentation,
    items: presentations,
    loading: presentationsLoading,
    setCurrent: setCurrentPresentation,
    setItems: setPresentations,
  } = usePresentations || {};

  const { items: attendees, loading: attendeesLoading } = useAttendees || {};

  const loading = useMemo(() => presentationsLoading || attendeesLoading, [attendeesLoading, presentationsLoading]);
  const rooms = useMemo<string[]>(() => getOptions("roomLocation", presentations), [presentations]);
  const presentationFormRequiredKeys = useMemo(
    () => [
      { key: "title", message: PresentationFormErrorMessages.presentationTitle, validation: isNullOrWhiteSpace },
      {
        key: "_track",
        message: PresentationFormErrorMessages.track,
        validation: (val) => {
          if (currentPresentation?.session_type === PresentationSessionType.Break) return false;
          return isNil(val) || isNullOrWhiteSpace(new Track(val)._id);
        },
      },
      {
        key: "end_date",
        message: PresentationFormErrorMessages.endTime,
        validation: isNullOrWhiteSpace,
      },
      {
        key: "urlOverride",
        message: PresentationFormErrorMessages.url,
        validation: validateNonRequiredUrlString,
      },
      {
        key: "poll_url",
        message: PresentationFormErrorMessages.url,
        validation: validateNonRequiredUrlString,
      },
      {
        key: "qa_url",
        message: PresentationFormErrorMessages.url,
        validation: validateNonRequiredUrlString,
      },
      {
        key: "speaker_link",
        message: PresentationFormErrorMessages.url,
        validation: validateNonRequiredUrlString,
      },
    ],
    [currentPresentation?.session_type]
  );

  useEffect(() => {
    if (!(setPresentations instanceof Function)) return;
    setPresentations((presentations) => mapPresentation(presentations, attendees, speakers, conference));
  }, [attendees, conference, speakers, setPresentations]);

  useEffect(() => {
    if (!isEmpty(currentPresentation) || typeof setCurrentPresentation !== "function") return;
    setCurrentPresentation(new PresentationEditState(null, conference));
  }, [conference, currentPresentation, setCurrentPresentation]);

  useEffect(() => {
    if (loading) return;
    tableRef.current.gridApi?.sizeColumnsToFit();
  }, [loading]);

  const tableProps = getTableProps();

  function renderAttendeesCell(params: ICellRendererParams): string | JSX.Element {
    const presentation = params?.data as Presentation;
    const { code } = presentation;
    if (isEmpty(code)) return "—";

    const handleClick = () => {
      attendeesPresentation.current = presentation;
      showAttendees();
    };

    const buttonId = idModel.attendees?.getId(presentation._id);
    return (
      <Button
        id={buttonId}
        theme={ButtonTheme.LightGrey}
        icon="q4i-link-4pt"
        label="VIEW"
        onClick={handleClick}
        linkTarget={AnchorTarget.Blank}
      />
    );
  }

  function getTableProps(): TableProps {
    function doesExternalFilterPass(node: RowNode): boolean {
      const isCloseSession = node.data.session_type === PresentationSessionType.Closed;

      if (!isNullOrWhiteSpace(selectedFilterRef?.current) && !isPrivateRef.current) {
        return node.data.session_type === selectedFilterRef?.current;
      }

      return isPrivateRef.current ? isCloseSession : !isCloseSession;
    }

    function formatDate(params: ValueFormatterParams | GetQuickFilterTextParams): string {
      return getFormattedDate(params, conference);
    }

    if (props.testRef) {
      // Only used for unit tests
      // AG grid doesn't trigger callbacks in test environment so
      // these functions need to be tested in isolation
      props.testRef.renderAttendeesCell = renderAttendeesCell;
      props.testRef.handlePresentationUpdate = handlePresentationUpdate;
    }

    function renderLocationCell(params: ICellRendererParams): string | JSX.Element {
      const presentation = params?.data as Presentation;
      const hasRoomLocation = !isNullOrWhiteSpace(presentation.roomLocation);
      const hasUrlOverride = !isNullOrWhiteSpace(presentation.urlOverride);

      if (isEmpty(presentation) || (!hasRoomLocation && !hasUrlOverride)) return "—";

      const locationString = hasRoomLocation ? presentation.roomLocation : SessionsLink;

      const id = hasUrlOverride ? idModel.locationLink?.getId(params.value) : idModel.location.getId(params.value);

      if (hasUrlOverride && presentation.session_type !== PresentationSessionType.Break)
        return (
          <Anchor id={id} theme={AnchorTheme.Rain} target={AnchorTarget.Blank} url={presentation.urlOverride}>
            {locationString}
          </Anchor>
        );

      return (
        <Text id={id} preset={TextPreset.Base}>
          {locationString}
        </Text>
      );
    }

    const columnDefs: ColumnDefs[] = [
      {
        field: "title",
        headerName: PresentationTableHeader.Name,
        minWidth: 245,
        flex: 1,
        sort: "asc",
        comparator: basicCompare,
      },
      {
        field: "session_type",
        headerName: PresentationTableHeader.Type,
        minWidth: 128,
        maxWidth: 128,
      },
      {
        field: "start_date",
        headerName: PresentationTableHeader.StartTime,
        minWidth: 160,
        maxWidth: 160,
        valueFormatter: formatDate,
        getQuickFilterText: formatDate,
        sort: "desc",
      },
      {
        field: "end_date",
        headerName: PresentationTableHeader.EndTime,
        minWidth: 160,
        maxWidth: 160,
        valueFormatter: formatDate,
        getQuickFilterText: formatDate,
        sort: "asc",
      },
      {
        field: "_speaker",
        headerName: PresentationTableHeader.Speaker,
        minWidth: 200,
        maxWidth: 200,
        valueFormatter: getFormattedSpeaker,
        getQuickFilterText: getFormattedSpeaker,
        comparator: (a, b) => getComparator(a, b, getFormattedSpeaker),
      },
      {
        field: "_track",
        headerName: PresentationTableHeader.Track,
        minWidth: 112,
        maxWidth: 112,
        valueFormatter: getFormattedTrack,
        comparator: (a, b) => getComparator(a, b, getFormattedTrack),
      },
      {
        field: "roomLocation",
        headerName: PresentationTableHeader.Location,
        minWidth: 165,
        flex: 1,
        cellRenderer: "locationCellRenderer",
        comparator: (a, b, nodeA, nodeB) => {
          function isMeetingLink(data: Presentation) {
            return !isNullOrWhiteSpace(data.urlOverride) && isNullOrWhiteSpace(data.roomLocation);
          }
          const valueA = isMeetingLink(nodeA.data) ? SessionsLink : a;
          const valueB = isMeetingLink(nodeB.data) ? SessionsLink : b;

          return basicCompare(valueA ?? "", valueB ?? "");
        },
      },
      {
        field: "code",
        headerName: PresentationTableHeader.Attendees,
        sortable: false,
        minWidth: 110,
        maxWidth: 110,
        cellRenderer: "attendeesCellRenderer",
      },
    ];

    if (!isPrivate) {
      const position = columnDefs?.findIndex((x) => x.headerName === PresentationTableHeader.Attendees);
      if (arrayIndexFound(position)) {
        columnDefs.splice(position, 1);
      }
    }

    return {
      columnDefs,
      frameworkComponents: {
        attendeesCellRenderer: renderAttendeesCell,
        locationCellRenderer: renderLocationCell,
      },
      loading,
      doesExternalFilterPass,
      suppressColumnVirtualisation: !!suppressColumnVirtualisation,
    };
  }

  const editForm = (
    <div className={PresentationEditClassName.Base}>
      <PresentationForm
        id={idModel.form?.id}
        conference={conference}
        codes={codes}
        presentation={currentPresentation}
        speakers={speakers}
        tracks={tracks}
        roomOptions={rooms}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onPresentationUpdate={handlePresentationUpdate}
      />
    </div>
  );

  const isCurrentPresentationReadOnly = useCallback((presentation: Presentation): boolean => {
    if (isNil(presentation.end_date)) return false;

    return moment(presentation.end_date).isBefore(moment(), "minutes");
  }, []);

  function handlePresentationUpdate(data: Partial<Presentation>) {
    tableRef.current && tableRef.current.triggerEditNotification();
    setCurrentPresentation(new Presentation({ ...currentPresentation, ...data }));
  }

  function renderToggleButtons(keyPrefix: string) {
    const prefix = isNullOrWhiteSpace(keyPrefix) ? "" : keyPrefix;
    return (
      <ToggleButtons
        key="meetings-banner_toggle-buttons"
        selected={+isPrivate}
        items={[
          { id: idModel?.openTab.id, key: `${prefix}public`, label: PresentationSessionType.Open },
          { id: idModel?.closedTab.id, key: `${prefix}private`, label: PresentationSessionType.Closed },
        ]}
        onChange={(value) => {
          setIsPrivate(!!value);
        }}
      />
    );
  }

  function renderFilters(): JSX.Element {
    const handleFilterChange = (option: string) => {
      setSelectedFilter(convertStringToEnum(PresentationSessionType, option));
      tableRef.current.gridApi?.onFilterChanged();
    };

    const handleClearFilter = () => {
      setSelectedFilter(null);
      tableRef.current.gridApi?.onFilterChanged();
    };

    return (
      <Ghostable ghosted={isPrivate}>
        <div className={EntityTableClassName.Filter}>
          <Select
            id={idModel.typeFilter.id}
            key="session_type"
            value={selectedFilter}
            onChange={handleFilterChange}
            options={[PresentationSessionType.Open, PresentationSessionType.Break]}
            theme={SelectTheme.Ink}
            size={ComponentSizeModifier.Small}
            preset={SelectPreset.Autocomplete}
            isClearable={true}
            placeholder={"Filter by Type"}
          />
          <Button
            theme={ButtonTheme.Steel}
            label="Clear"
            icon="q4i-undo-4pt"
            disabled={isEmpty(selectedFilter)}
            onClick={handleClearFilter}
          />
        </div>
      </Ghostable>
    );
  }

  return (
    <div id={idModel.id} className={PresentationClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.Presentation}
        customEntityTitle={"Session"}
        channelName={PresentationPusherChannel.Edit}
        useService={usePresentations}
        tableProps={tableProps}
        editForm={editForm}
        modalProps={{ fullscreen: true, scrollable: true }}
        entityModel={Presentation}
        icon="q4i-contact-2pt"
        requiredFormEntityKeys={presentationFormRequiredKeys}
        saveRequestParams={{ _conference: conference }}
        user={user}
        conference={conference}
        toolbarProps={{
          theme: ToolbarTheme.Q4Blue,
          children: [renderToggleButtons("presentation-table_"), renderFilters()],
        }}
        entityItemReadOnly={isCurrentPresentationReadOnly}
        ref={tableRef}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        modifyData={setPresentationPayload}
      />
      <PresentationManageAttendeesModal
        id={idModel.manageAttendees?.id}
        presentation={attendeesPresentation.current}
        visible={attendeesVisible}
        useAttendees={useAttendees}
        onCloseRequest={hideAttendees}
      />
    </div>
  );
};

export default memo(Presentations);
