import { ApiResponse, AuthType, ContentType } from "../api/api.definition";
import ApiService from "../api/api.service";
import { ExportType } from "./export.model";

export default class ExportService {
  protected readonly _apiService = new ApiService();
  private readonly ExportApiPath = "/export";
  private readonly ExportAdminApiPath = "/exportadmin";

  public async exportToPdf(
    data: ExportType,
    fileName: string,
    authType: AuthType = AuthType.Passwordless
  ): Promise<ApiResponse<Blob>> {
    return await this._apiService
      .requestBlob<ExportType>(
        `${authType === AuthType.Protected ? this.ExportAdminApiPath : this.ExportApiPath}`,
        authType,
        data,
        ContentType.Json
      )
      .then((response) => {
        if (!response.success) return response;
        const file = response?.data;
        saveAs(file, fileName);

        return response;
      });
  }
}
