import { RowNode } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { sortBy } from "lodash";
import moment from "moment";
import type { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";

export const sortTimezonesByOffsets = (timezones: Array<string>): Array<string> =>
  sortBy(timezones, (timezoneOption) => moment().tz(timezoneOption).utcOffset());

export function byCompanyName(a: CorporateProfile, b: CorporateProfile): number {
  const nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
  const nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function byString(a: string, b: string): number {
  const valueA = a?.toLowerCase();
  const valueB = b?.toLowerCase();
  if (valueA > valueB) return 1;
  if (valueA < valueB) return -1;
  return 0;
}

// agGrid specific
export function byStringAndStartDate(a: string, b: string, nodeA: RowNode, nodeB: RowNode): number {
  const valueA = a?.toLowerCase();
  const valueB = b?.toLowerCase();
  if (valueA > valueB) return 1;
  if (valueA < valueB) return -1;
  return nodeA.data.start_date - nodeB.data.start_date;
}
