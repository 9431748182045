import {
  BaseComponentProps,
  IdModelBase,
  isNullOrWhiteSpace,
  PaginationIdModel,
  PaginationProps,
  PaginationSize,
  ErrorModel,
} from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import { MeetingRequest } from "../../../../../services/admin/registrant/registrant.model";
import type { Conference } from "../../../../../services/conference/conference.model";

export enum MeetingRequestFormFilterOptions {
  All = "All Corporates",
  Requested = "Requested Corporates",
  Unrequested = "Unrequested Corporates",
}
export interface MeetingRequestFormProps extends BaseComponentProps {
  conference: Conference;
  meetingRequests: MeetingRequest[];
  setMeetingRequests: Dispatch<SetStateAction<MeetingRequest[]>> | ((v: MeetingRequest[]) => void);
  title?: string;
  errors?: Map<string, string>;
  disabled?: boolean;
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
}

export enum MeetingRequestFormClassName {
  Base = "meeting-request",
  Corporate = "meeting-request_corporate-profile",
  CorporateHeader = "meeting-request_corporate-profile-header",
  CorporateRow = "meeting-request_corporate-profile-row",
  CorporateInfo = "meeting-request_corporate-profile-info",
  CorporateLogo = "meeting-request_corporate-profile-logo",
  CorporateName = "meeting-request_corporate-name",
  CorporateTicker = "meeting-request_corporate-ticker",
  CorporateIndustry = "meeting-request_corporate-industry",
  CorporateAttendees = "meeting-request_corporate-attendees",
  CorporateMeetingInterest = "meeting-request_corporate-meeting-interest",
  MeetingType = "meeting-request_meeting-type-select",
  InterestLevel = "meeting-request_interest-level-select",
  EmptyState = "meeting-request_corporate-empty-state",
  Pagination = "meeting-request_corporate-pagination",
}

export interface InvestorFormMeetingRequests {
  [profileId: string]: {
    meeting_type?: string;
    interest_level?: string;
  };
}

export interface SelectOption {
  label: string;
  value: string;
}

export const MeetingRequestFormPaginationOptions: Partial<PaginationProps> = {
  startFromZero: true,
  showPageSizeSelection: true,
  pageSizeOptions: [25, 50, 100],
  size: PaginationSize.Default,
};

export class MeetingRequestFormIdModel extends IdModelBase {
  pagination: PaginationIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.pagination = new PaginationIdModel(`${this.id}Pagination`);
  }
}
