import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";
import type { Conference } from "../conference/conference.model";
import { Speaker, SpeakerPayload } from "./speaker.model";

export default class SpeakerService {
  private readonly SPEAKERS_API_PATH = `${AdminRouteBase}/speakers/v1`;
  private apiService = new ApiService();

  public async getSpeakers(): Promise<ApiResponse<Speaker[]>> {
    const response = await this.apiService.get<Speaker[]>(`${this.SPEAKERS_API_PATH}`);
    return this.mapSpeakersResponse(response);
  }

  public async getSpeakersByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Speaker[]>> {
    const response = await this.apiService.get<Speaker[]>(`${this.SPEAKERS_API_PATH}/byConference/${_id}`);
    return this.mapSpeakersResponse(response);
  }

  public async getSpeakerById(_id: Speaker["_id"]): Promise<ApiResponse<Speaker>> {
    const response = await this.apiService.get<Speaker>(`${this.SPEAKERS_API_PATH}/${_id}`);
    return this.mapSpeakerByIdResponse(response);
  }

  public postSpeaker = async (data: Speaker): Promise<ApiResponse<Speaker>> => {
    const conferenceId = data?._conference?._id;
    if (isEmpty(data) || isNullOrWhiteSpace(conferenceId)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Failed to add new speaker." }));
    }

    const payload = new SpeakerPayload(data);
    const response = await this.apiService.post<SpeakerPayload, Speaker>(`${this.SPEAKERS_API_PATH}`, payload);
    return this.mapSpeakerByIdResponse(response);
  };

  public async putSpeakerById(_id: Speaker["_id"], data: Speaker): Promise<ApiResponse<Speaker>> {
    const payload = new SpeakerPayload(data);
    const response = await this.apiService.put<SpeakerPayload, Speaker>(`${this.SPEAKERS_API_PATH}/${_id}`, payload);
    return this.mapSpeakerByIdResponse(response);
  }

  public async deleteSpeakerById(_id: string): Promise<ApiResponse<Speaker>> {
    const response = await this.apiService.delete<Speaker>(`${this.SPEAKERS_API_PATH}/${_id}`);
    return this.mapSpeakerByIdResponse(response);
  }

  private mapSpeakerByIdResponse(response: ApiResponse<Speaker>): ApiResponse<Speaker> {
    return new ApiResponse({
      ...response,
      data: new Speaker(response?.data),
    });
  }

  private mapSpeakersResponse(response: ApiResponse<Speaker[]>): ApiResponse<Speaker[]> {
    return new ApiResponse({
      ...response,
      data: (response?.data ?? []).map((speaker) => new Speaker(speaker)),
    });
  }
}
