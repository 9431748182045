import "./scheduledTimeSlotView.component.scss";
import {
  Card,
  ErrorModel,
  Grid,
  GridColumn,
  isEmpty,
  isNil,
  Keyline,
  KeylineTheme,
  LayoutPadding,
  Message,
  MessageType,
  PlaceholderContent,
  Scrollbars,
  ScrollbarsTheme,
  Tab,
  Text,
  TextPreset,
  TextTheme,
  useVisibility,
  VerticalAlign,
} from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { EntityTableClassName } from "../../../../../../../../components/entityTable/entityTable.definition";
import { DefaultTimeZone } from "../../../../../../../../const";
import { DateFormat, DateTokenFormat, TimeFormat } from "../../../../../../../../definitions/date.definition";
import { ConferenceSchedulerSlot } from "../../../../../../../../services/conference/conference.model";
import { formatTimeZoneLabel, isDateRangeConflicting } from "../../../../../../../../utils";
import { formatPlannerDate, hasConferenceScheduler } from "../../../../../../../../utils/scheduler/scheduler.utils";
import DayTabs from "../../../meetingScheduler/components/dayTabs/dayTabs.component";
import { MeetingSchedulerClassName } from "../../../meetingScheduler/meetingScheduler.definition";
import TimeSlotView from "../timeSlotView/timeSlotView.component";
import { TimeSlotViewIdModel } from "../timeSlotView/timeSlotView.definition";
import {
  ScheduledTimeSlotClassName,
  ScheduledTimeSlotIdModel,
  ScheduledTimeSlotProps,
  TimeSlotDataForDeletion,
} from "./scheduledTimeSlotView.definition";

const ScheduledTimeSlotView = (props: ScheduledTimeSlotProps): JSX.Element => {
  const {
    id,
    conference,
    scheduler,
    editMode,
    saving,
    setEditMode,
    onSubmit,
    currentCalendarDay,
    setCurrentCalendarDay,
    calendarDays,
  } = props;
  const [dataForDeletion, setDataForDeletion] = useState<TimeSlotDataForDeletion>(null);
  const [messageVisible, handleMessageOpen, handleMessageClose] = useVisibility();
  const idModel = useMemo(() => new ScheduledTimeSlotIdModel(id), [id]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone || DefaultTimeZone, [conference?.time_zone]);

  const dayTabs: Tab[] = useMemo(() => {
    if (isEmpty(calendarDays)) return [];
    return calendarDays.map((x) => ({
      value: `${x.valueOf()}`,
      label: formatPlannerDate(x, conference?.time_zone),
    }));
  }, [calendarDays, conference?.time_zone]);

  const renderPlaceholder = useCallback(() => {
    const title = "No Schedule Created";
    return (
      <PlaceholderContent
        id={idModel.placeholderContent.id}
        className={EntityTableClassName.Placeholder}
        title={title}
        icon={"q4i-calendar-4pt"}
      />
    );
  }, [idModel]);

  const openMessageToDeleteHandler = useCallback(
    (dataForDeletion: TimeSlotDataForDeletion): (() => void) =>
      (): void => {
        setDataForDeletion(dataForDeletion);
        handleMessageOpen();
      },
    [handleMessageOpen]
  );

  const renderModalText = useMemo(() => {
    const { startTime, endTime } = dataForDeletion || {};

    return (
      <>
        Deleting the&nbsp;
        <b>
          {startTime} - {endTime}
        </b>
        &nbsp;time slot will remove it from the meeting schedule. This will not remove any scheduled meetings.
      </>
    );
  }, [dataForDeletion]);

  const deleteTimeSlot = useCallback(async (): Promise<void> => {
    const { timeSlotIndex } = dataForDeletion;
    const slotsToUpdate = [...scheduler.slots];

    const currentDayStartingIndex = slotsToUpdate.findIndex((x) =>
      x.start_time.tz(conferenceTimeZone).isSame(currentCalendarDay, "day")
    );

    slotsToUpdate.splice(timeSlotIndex + currentDayStartingIndex, 1);
    await onSubmit({
      ...scheduler,
      slots: slotsToUpdate,
    });
    handleMessageClose();
  }, [conferenceTimeZone, currentCalendarDay, dataForDeletion, handleMessageClose, onSubmit, scheduler]);

  const handleSubmitClick = useCallback(
    async (idx: number, start_time: Moment, end_time: Moment): Promise<void> => {
      const slotsToUpdate = [...scheduler.slots];
      const currentDayStartingIndex = slotsToUpdate.findIndex((x) =>
        x.start_time.tz(conferenceTimeZone).isSame(currentCalendarDay, "day")
      );

      slotsToUpdate[idx + currentDayStartingIndex] = { start_time, end_time };

      await onSubmit({
        ...scheduler,
        slots: slotsToUpdate,
      });
    },
    [conferenceTimeZone, currentCalendarDay, onSubmit, scheduler]
  );

  const handleDayTabChange = useCallback(
    (index: number): void => {
      if (isNil(index)) return;

      const tabDate = calendarDays[index];
      setCurrentCalendarDay(tabDate);
    },
    [calendarDays, setCurrentCalendarDay]
  );

  const renderSlots = useCallback(() => {
    if (isNil(scheduler.start_date) || isNil(scheduler.end_date)) return renderPlaceholder();

    const currentDaySlots = (scheduler?.slots || []).filter((slot) =>
      moment(slot.start_time).tz(conferenceTimeZone).isSame(currentCalendarDay, "day")
    );

    return (
      <Card layoutProps={{ padding: LayoutPadding.None, flex: false }}>
        {!hasConferenceScheduler(conference) ? (
          renderPlaceholder()
        ) : (
          <>
            <DayTabs
              id={idModel.dayTabs.id}
              className={MeetingSchedulerClassName.DateTabs}
              items={dayTabs}
              onChange={handleDayTabChange}
              disabled={editMode}
            />
            <Keyline theme={KeylineTheme.SoftGrey} />

            <div className={ScheduledTimeSlotClassName.SlotsWrapper}>
              <Scrollbars autoHide={true} theme={ScrollbarsTheme.Dark}>
                <div id={idModel.slotsContainer} className={ScheduledTimeSlotClassName.SlotsInnerWrapper}>
                  {currentDaySlots.map((slot, idx) => {
                    const slotStart = moment(slot.start_time).tz(conferenceTimeZone);
                    const slotEnd = moment(slot.end_time).tz(conferenceTimeZone);
                    const dataForDeletion: TimeSlotDataForDeletion = {
                      currentDaySlots,
                      timeSlotIndex: idx,
                      startTime: slotStart.clone().format(TimeFormat.Picker),
                      endTime: slotEnd.clone().format(TimeFormat.Picker),
                    };

                    const otherSlots = currentDaySlots.reduce(
                      (acc, slot, i) =>
                        idx === i
                          ? acc
                          : [
                              ...acc,
                              {
                                start_time: slot.start_time,
                                end_time: slot.end_time,
                              },
                            ],
                      [] as ConferenceSchedulerSlot[]
                    );

                    const error = new ErrorModel(
                      "Conflicts with another time slot",
                      isDateRangeConflicting(slotStart, slotEnd, otherSlots)
                    );

                    const itemIdBase = idModel.slots.getId(idx);
                    const itemIdModel = new TimeSlotViewIdModel(itemIdBase);

                    return (
                      <TimeSlotView
                        key={itemIdModel.id}
                        id={itemIdModel.id}
                        startTime={slotStart}
                        endTime={slotEnd}
                        isSaving={saving}
                        editMode={editMode}
                        error={error}
                        setEditMode={setEditMode}
                        deleteSlot={openMessageToDeleteHandler(dataForDeletion)}
                        onSubmit={(startTime, endTime) => handleSubmitClick(idx, startTime, endTime)}
                      />
                    );
                  })}
                </div>
              </Scrollbars>
            </div>
          </>
        )}
      </Card>
    );
  }, [
    scheduler.start_date,
    scheduler.end_date,
    scheduler?.slots,
    renderPlaceholder,
    conference,
    idModel,
    dayTabs,
    conferenceTimeZone,
    handleDayTabChange,
    editMode,
    currentCalendarDay,
    saving,
    setEditMode,
    openMessageToDeleteHandler,
    handleSubmitClick,
  ]);

  return (
    <div id={idModel.content}>
      <Grid className={ScheduledTimeSlotClassName.Header}>
        <GridColumn width="1-of-2" margin={false}>
          {!isNil(conference?.scheduler?.start_date) && (
            <Text id={idModel.currentDate.id} theme={TextTheme.Slate} preset={TextPreset.Title}>
              {moment(currentCalendarDay).tz(conferenceTimeZone).format(DateFormat.FullDateWithDay)}
            </Text>
          )}
        </GridColumn>
        <GridColumn
          width="1-of-2"
          verticalAlign={VerticalAlign.Middle}
          className={ScheduledTimeSlotClassName.TimezoneHeader}
        >
          <Text id={idModel.currentTimezone.id} theme={TextTheme.LightSlate} preset={TextPreset.Base}>
            ALL TIMES IN {moment(currentCalendarDay).tz(conferenceTimeZone).format(DateTokenFormat.TimeZone)}{" "}
          </Text>
        </GridColumn>
      </Grid>

      {renderSlots()}

      <Text
        id={idModel.lastUpdated.id}
        theme={TextTheme.LightSlate}
        preset={TextPreset.Base}
        className={ScheduledTimeSlotClassName.Footer}
      >
        {`Last updated ${moment(conference?.scheduler?.updated_at)
          .tz(conferenceTimeZone)
          .format(TimeFormat.FullStandard)} ${formatTimeZoneLabel(
          conferenceTimeZone,
          conference?.scheduler?.updated_at
        )} ${moment(conference?.scheduler?.updated_at).format(DateFormat.Short)} - ${
          conference?.scheduler?.updated_by ?? "Unknown"
        }`}
      </Text>
      <Message
        id={idModel.messageModal?.id}
        visible={dataForDeletion && messageVisible}
        messageType={MessageType.Warning}
        title={"Delete Time Slot?"}
        message={renderModalText}
        secondaryActionProps={{
          id: idModel.cancelButton?.id,
          label: "Cancel",
          onClick: handleMessageClose,
          disabled: saving,
        }}
        primaryActionProps={{
          id: idModel.deleteButton?.id,
          label: "Delete",
          loading: saving,
          onClick: deleteTimeSlot,
        }}
        onCloseRequest={handleMessageClose}
      />
    </div>
  );
};

export default memo(ScheduledTimeSlotView);
