import "./registrants.component.scss";
import { ErrorModel, isNil, isNullOrWhiteSpace, Swapable, TableProps, ToggleButtons, ToolbarTheme } from "@q4/nimbus-ui";
import { GetQuickFilterTextParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useMemo, useState } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import RegistrantForm from "../../../../../../components/registrantForm/registrantForm.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { TableSelectFilter } from "../../../../../../hooks/useTableFilter/useTableFilter.definition";
import { Registrant, RegistrantViewModel } from "../../../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../../../services/attendee/attendee.model";
import { CorporateType } from "../../../../../../services/corporateProfile/corporateProfile.model";
import { byString, getOptions } from "../../../../../../utils";
import { modifyAnswerData } from "../../../../../../utils/customQuestionAnswer.utils";
import RegistrationFormTable from "./components/registrationFormTable/registrationFormTable.component";
import { RegistrantClassName, RegistrantsIdModel, RegistrantsProps, RegistrantTableHeader } from "./registrants.definition";

const Registrants = (props: RegistrantsProps): JSX.Element => {
  const { id, codes, conference, useCorporateProfile, useRegistrants, useQuestionGroups, user } = props;

  const idModel = useMemo(() => new RegistrantsIdModel(id), [id]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [registrantFormErrors, setRegistrantFormErrors] = useState<Record<string, ErrorModel>>({});

  const { current: currentRegistrant, setCurrent, items: registrants } = useRegistrants;

  const { items: corporateProfiles } = useCorporateProfile;

  const { items: questionGroups, loading: questionGroupsLoading } = useQuestionGroups;

  // Compile all registrants into a single list that will populate the filter dropdown
  // Use `corporate_name` field value in place of `company` field if `company` is not available
  const registrantsWithCompanyName = useMemo<Registrant[]>(
    () =>
      registrants.map<Registrant>((entry) => {
        const registrant = entry;
        if (isNil(registrant.company)) {
          registrant.company = registrant.corporate_name;
        }
        return registrant;
      }),
    [registrants]
  );
  const companies = useMemo<string[]>(
    () => getOptions("company", registrantsWithCompanyName, true),
    [registrantsWithCompanyName]
  );
  const types = useMemo<string[]>(
    () => getOptions("attendee_type", registrantsWithCompanyName),
    [registrantsWithCompanyName]
  );
  const status = useMemo<string[]>(() => getOptions("status", registrantsWithCompanyName), [registrantsWithCompanyName]);

  const registrantEntityKeys = useMemo(() => {
    let defaultRequiredKeys: (keyof Registrant)[] = ["attendee_type", "status"];

    switch (currentRegistrant?.attendee_type) {
      case AttendeeType.Internal:
        defaultRequiredKeys = defaultRequiredKeys.concat(["first_name", "last_name", "email", "title"]);

        break;
      case AttendeeType.Investor:
        defaultRequiredKeys = defaultRequiredKeys.concat(["first_name", "last_name", "email", "company", "investor_type"]);

        if (currentRegistrant?.has_secondary_contact) {
          defaultRequiredKeys = defaultRequiredKeys.concat(["secondary_contact"]);
        }
        break;
      case AttendeeType.Corporate:
        defaultRequiredKeys = defaultRequiredKeys.concat([
          "corporate_name",
          "corporate_type",
          "logo_image",
          "description",
          "corporate_attendees",
        ]);

        if (currentRegistrant?.corporate_type === CorporateType.Public) {
          defaultRequiredKeys = defaultRequiredKeys.concat(["exchange", "ticker_symbol"]);
        }
        break;
      default:
        defaultRequiredKeys = defaultRequiredKeys.concat(["first_name", "last_name", "email", "company"]);
        break;
    }

    return defaultRequiredKeys;
  }, [currentRegistrant]);

  const modifyAnswerPayload = (registrantData: Registrant) => {
    const targetQuestionGroup = questionGroups?.find((qG) => qG?.attendee_types.includes(currentRegistrant?.attendee_type));

    const updatedAnswerObject = modifyAnswerData(
      registrantData?.custom_question_answers,
      targetQuestionGroup?._questions,
      true
    );
    if (currentRegistrant?.attendee_type === AttendeeType.Corporate) {
      const corporateAttendees = currentRegistrant?.corporate_attendees;
      corporateAttendees?.forEach((cA, index) => {
        corporateAttendees[index].custom_question_answers = modifyAnswerData(
          cA.custom_question_answers,
          targetQuestionGroup?._questions
        );
      });
      return new Registrant({
        ...registrantData,
        custom_question_answers: updatedAnswerObject,
        corporate_attendees: corporateAttendees,
      });
    }
    return new Registrant({ ...registrantData, custom_question_answers: updatedAnswerObject });
  };

  const selectFilters: TableSelectFilter[] = [
    {
      id: idModel.status.id,
      key: "status",
      placeholder: "Filter by Status",
      options: status,
    },
    {
      id: idModel.type.id,
      key: "attendee_type",
      placeholder: "Filter by Type",
      options: types,
    },
    {
      id: idModel.company.id,
      key: "company",
      placeholder: "Filter by Company",
      options: companies,
    },
  ];

  const registrantTableProps: Partial<TableProps> = {
    columnDefs: [
      {
        field: "first_name",
        headerName: RegistrantTableHeader.Name,
        minWidth: 280,
        flex: 1,
        cellRenderer: "nameCellRenderer",
        getQuickFilterText: renderName,
      },
      {
        field: "company",
        headerName: RegistrantTableHeader.Company,
        minWidth: 180,
        cellRenderer: "companyCellRenderer",
        flex: 1,
        comparator: byString,
      },
      {
        field: "email",
        headerName: RegistrantTableHeader.Email,
        minWidth: 160,
        flex: 1,
        cellRenderer: "emailCellRenderer",
      },
      {
        field: "attendee_type",
        headerName: RegistrantTableHeader.Type,
        minWidth: 125,
        maxWidth: 125,
      },
      {
        field: "status",
        headerName: RegistrantTableHeader.Status,
        minWidth: 125,
        maxWidth: 125,
      },
    ],
    frameworkComponents: {
      nameCellRenderer: renderName,
      companyCellRenderer: renderCompanyName,
      emailCellRenderer: renderEmail,
    },
  };

  const editForm = (
    <RegistrantForm
      id={idModel.form?.id}
      registrant={currentRegistrant}
      codes={codes}
      companies={companies}
      conference={conference}
      corporateProfiles={corporateProfiles}
      questionGroups={questionGroups}
      setFormErrors={setRegistrantFormErrors}
      onRegistrantUpdate={handleRegistrantUpdate}
    />
  );

  function handleRegistrantUpdate(data: Partial<Registrant>) {
    setCurrent(new Registrant({ ...currentRegistrant, ...data }));
  }

  function renderName(params: GetQuickFilterTextParams): string {
    const registrant = new RegistrantViewModel(params?.data);
    const displayName =
      registrant.attendee_type === AttendeeType.Corporate
        ? registrant?.corporate_attendees.map((attendee) => `${attendee.first_name} ${attendee.last_name}`).join(", ")
        : registrant?.display_name;
    return isNullOrWhiteSpace(displayName) ? "—" : displayName;
  }

  function renderCompanyName(params: GetQuickFilterTextParams): string {
    const registrant = new RegistrantViewModel(params?.data);
    const displayName =
      registrant.attendee_type === AttendeeType.Corporate ? registrant?.corporate_name : registrant?.company;
    return isNullOrWhiteSpace(displayName) ? "—" : displayName;
  }

  function renderEmail(params: GetQuickFilterTextParams): string {
    const registrant = new RegistrantViewModel(params?.data);
    const email =
      registrant.attendee_type === AttendeeType.Corporate
        ? registrant?.corporate_attendees?.find((attendee) => attendee.is_primary_contact)?.email
        : registrant?.email;
    return isNullOrWhiteSpace(email) ? "—" : email;
  }

  function renderToggleButtons(keyPrefix: string) {
    const prefix = isNullOrWhiteSpace(keyPrefix) ? "" : keyPrefix;
    return (
      <ToggleButtons
        id={idModel.registrantToolbarToggleButtons.id}
        key="registration-banner_toggle-buttons"
        selected={selectedTab}
        items={[
          { key: `${prefix}registrants`, label: "Registrants" },
          { key: `${prefix}registrationForms`, label: "Registration Forms" },
        ]}
        onChange={setSelectedTab}
      />
    );
  }

  return (
    <div id={idModel.id} className={RegistrantClassName.Base}>
      <Swapable
        selected={selectedTab}
        layers={[
          <EntityTable
            key="registrants_table"
            id={idModel.entityTable?.id}
            entity={Entity.Registrant}
            useService={useRegistrants}
            tableProps={registrantTableProps}
            editForm={editForm}
            modalProps={{
              fullscreen: true,
              scrollable: true,
              loading: questionGroupsLoading,
              focusOnProps: { autoFocus: false },
            }}
            entityModel={Registrant}
            icon="q4i-contact-2pt"
            saveRequestParams={{ _conference: conference }}
            requiredEntityKeys={registrantEntityKeys}
            requiredFormEntityKeys={[]}
            formErrors={registrantFormErrors}
            setFormErrors={setRegistrantFormErrors}
            toolbarProps={{
              id: idModel.registrantToolbar.id,
              theme: ToolbarTheme.Q4Blue,
              children: [renderToggleButtons("registration-table_")],
            }}
            user={user}
            conference={conference}
            selectFilters={selectFilters}
            modifyData={modifyAnswerPayload}
          />,
          <RegistrationFormTable
            id={idModel?.registrationFormTable?.id}
            key="registrationForms_table"
            conference={conference}
            useQuestionGroups={useQuestionGroups}
            user={user}
            toolbarProps={{
              id: idModel.registrantToolbar.id,
              theme: ToolbarTheme.Q4Blue,
              children: [renderToggleButtons("registrationCustomQuestionsForm-table_")],
            }}
          />,
        ]}
      />
    </div>
  );
};

export default memo(Registrants);
