import { Swapable } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import Fallback from "../../../../components/errorLog/fallback/fallback.component";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner.component";
import { LoadingStateStatus } from "./loadingState.definition";
import type { LoadingStateProps } from "./loadingState.definition";

const LoadingState = (props: LoadingStateProps): JSX.Element => {
  const { children, error, loading } = props;

  const status = useMemo(() => {
    if (!!loading) return LoadingStateStatus.Loading;
    if (!!error) return LoadingStateStatus.Failed;
    return LoadingStateStatus.Ready;
  }, [error, loading]);

  return (
    <Swapable
      className="loading-state"
      selected={status}
      layers={[
        <div className="loading-state_layer" key="loading-state--loading">
          <LoadingSpinner />
        </div>,
        <div className="loading-state_layer" key="loading-state--failed">
          <Fallback />
        </div>,
        <div className="loading-state_layer" key="loading-state--ready">
          {children}
        </div>,
      ]}
    />
  );
};

export default memo(LoadingState);
