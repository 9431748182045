import { getClassName, isNullOrWhiteSpace, ToastContainer } from "@q4/nimbus-ui";
import { compose } from "lodash/fp";
import React, { memo, useContext, useEffect } from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import Halo from "../../components/halo/halo.component";
import { NavigationBarRoutes, RoutePath } from "../../configurations/navigation.configuration";
import { AdminUserContext } from "../../contexts/adminUser/adminUser.context";
import { UserContext } from "../../contexts/user/user.context";
import withCacheBuster from "../../hoc/cacheBuster/cacheBuster.hoc";
import { useAnalytics } from "../../hooks/useAnalytics/useAnalytics.hook";
import { isAdminRoute } from "../../utils";
import Header from "./components/header/header.component";
import Navigation from "./components/navigation/navigation.component";
import type { RootProps } from "./root.definition";
import { RootIdModel as IdModel, RootClassName } from "./root.definition";
import "./root.view.scss";
import { AdminRoutes, ConferenceRoutes, CustomRoutes, LinkRoutes } from "./routes";
import ConferenceLegacyRedirect from "./routes/conference-legacy.redirect";
import NotFoundRoutes from "./routes/notFound.route";

const Root = (props: RootProps): JSX.Element => {
  const userContext = useContext(UserContext);
  const adminUserContext = useContext(AdminUserContext);
  const analytics = useAnalytics();

  const { history } = props;
  const { user } = userContext;
  const { user: adminUser, roleAccess } = adminUserContext;

  useEffect(() => {
    const currentUser = isAdminRoute() ? adminUser : user;
    const email = currentUser?.email;
    const company = currentUser?.company;

    if (isNullOrWhiteSpace(company)) return;
    analytics.identify(email, company);
  }, [analytics, adminUser, user]);

  const baseClassName = getClassName(RootClassName.Wrapper, [
    {
      condition: !isAdminRoute(),
      trueClassName: RootClassName.WrapperWithFullscreenModifier,
    },
    {
      condition: isAdminRoute(),
      trueClassName: RootClassName.WrapperWithAdminModifier,
      falseClassName: RootClassName.WrapperWithPublicModifier,
    },
  ]);

  if (isAdminRoute()) {
    return (
      <Router history={history}>
        <Halo title="Conference Admin" favicon="https://s25.q4cdn.com/982695397/files/favicon.ico" />
        <div className={baseClassName}>
          <Navigation
            id={IdModel.adminNavigation.id}
            className={RootClassName.Navigation}
            role={roleAccess}
            routes={NavigationBarRoutes}
            logo={<img alt="Q4 Inc." src="https://s25.q4cdn.com/982695397/files/design/logo-white.svg" />}
          />
          <div className={RootClassName.Inner}>
            <Header id={IdModel.adminHeader.id} adminUserContext={adminUserContext} />
            <div className={RootClassName.View}>
              <Switch>
                {AdminRoutes({ userContext: adminUserContext })}
                {NotFoundRoutes({ userContext: adminUserContext })}
                <Redirect key="public--redirect" from="*" to={RoutePath.NotFound} />
              </Switch>
            </div>
          </div>
          <ToastContainer autoClose={2000} />
        </div>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <div className={baseClassName}>
        <div className={RootClassName.Inner}>
          <div className={RootClassName.View}>
            <Switch>
              {ConferenceLegacyRedirect()}
              {NotFoundRoutes({ userContext })}
              {LinkRoutes({ userContext })}
              {CustomRoutes({ userContext })}
              {ConferenceRoutes({ userContext })}
              <Redirect key="public--redirect" from="*" to={RoutePath.NotFound} />
            </Switch>
          </div>
        </div>
        <ToastContainer autoClose={2000} />
      </div>
    </Router>
  );
};
export default compose(withCacheBuster, memo)(Root);
