import type { Attendee, AttendeeViewModel } from "../../attendee/attendee.model";
import type { Conference } from "../../conference/conference.model";
import type { Meeting } from "../../meeting/meeting.model";
import type { Presentation } from "../../presentation/presentation.model";

export class Agenda
  implements
    Omit<
      Attendee,
      "_id" | "id" | "ConferenceId" | "options" | "type" | "participate_in_meetings" | "receive_emails" | "editable_email"
    >
{
  _conference: Conference["_id"];
  _presentation?: Presentation[];
  _meeting?: Meeting[];
  _attendees?: AttendeeViewModel[];
  email: string;

  constructor(agenda: Partial<Agenda>) {
    Object.assign(this, agenda);
  }
}
