import { isNullOrWhiteSpace, Modal, ButtonTheme, isEmpty, ErrorModel } from "@q4/nimbus-ui";
import { get, has } from "lodash";
import React, { useReducer, useMemo, memo, useState } from "react";
import { validateEmail } from "../../../../utils";
import CorporateAttendeeForm from "../corporateAttendeeForm/corporateAttendeeForm.component";
import {
  CorporateAttendeeModalProps,
  CorporateAttendeeModalIdModel,
  CorporateAttendeeState,
  CorporateAttendeeModalClassName,
} from "./corporateAttendeeModal.definition";

const CorporateAttendeeModal = (props: CorporateAttendeeModalProps): JSX.Element => {
  const { id, visible, edit, attendee, questionGroup, onCloseRequest, onAttendeeCreate, onAttendeeUpdate } = props;

  const [formErrors, setFormErrors] = useState<Record<string, ErrorModel>>({});
  const [attendeeState, setAttendeeState] = useReducer(reducer, null);
  const idModel = useMemo(() => new CorporateAttendeeModalIdModel(id), [id]);
  const formRequiredKeys = useMemo(
    () => [
      { key: "first_name", message: "First Name is required", validation: isNullOrWhiteSpace },
      { key: "last_name", message: "Last Name is required", validation: isNullOrWhiteSpace },
      { key: "email", message: "Email is required", validation: (value) => !validateEmail(value) },
    ],
    []
  );

  function handleCancelClick(): void {
    onCloseRequest && onCloseRequest();
    setFormErrors({});
  }

  function handleSubmitClick(): void {
    const error = checkForErrors();

    if (error) return;

    edit ? onAttendeeUpdate && onAttendeeUpdate(attendeeState) : onAttendeeCreate && onAttendeeCreate(attendeeState);

    setFormErrors({});
  }

  function handleSync(): void {
    setAttendeeState(new CorporateAttendeeState(attendee));
  }

  function handleReset(): void {
    setAttendeeState(new CorporateAttendeeState());
  }

  function reducer(prevState: CorporateAttendeeState, state: Partial<CorporateAttendeeState>): CorporateAttendeeState {
    return new CorporateAttendeeState({
      ...prevState,
      ...state,
    });
  }

  function checkForErrors(): boolean {
    if (isEmpty(attendeeState)) return true;
    const errors = (formRequiredKeys || []).reduce((error, { key, message, validation }) => {
      if (has(attendeeState, key)) {
        const value = get(attendeeState, key);
        error[key] = new ErrorModel(message, validation(value));
      }
      return error;
    }, {} as Record<string, ErrorModel>);

    const updatedErrors = { ...formErrors, ...errors };
    if (isEmpty(updatedErrors)) return false;
    setFormErrors(updatedErrors);
    return Object.values(updatedErrors).some((x) => x.visible);
  }

  return (
    <Modal
      id={idModel?.id}
      className={CorporateAttendeeModalClassName.Base}
      visible={visible}
      title={`${edit ? "Update" : "Add"} Corporate Attendee`}
      badgeIcon="q4i-contact-2pt"
      footerActions={[
        {
          key: "cancel",
          id: idModel.cancel?.id,
          label: "Cancel",
          theme: ButtonTheme.DarkSlate,
          onClick: handleCancelClick,
        },
        {
          key: "submit",
          id: idModel.save?.id,
          label: "Save",
          theme: ButtonTheme.Citrus,
          onClick: handleSubmitClick,
        },
      ]}
      fullscreen
      scrollable
      onCloseRequest={handleCancelClick}
      ghostableProps={{
        onEntering: handleSync,
        onExited: handleReset,
      }}
      focusOnProps={{
        autoFocus: false,
      }}
    >
      <CorporateAttendeeForm
        id={idModel.form?.id}
        className={CorporateAttendeeModalClassName.Form}
        attendee={attendeeState}
        formErrors={formErrors}
        questionGroup={questionGroup}
        setFormErrors={setFormErrors}
        onAttendeeUpdate={setAttendeeState}
      />
    </Modal>
  );
};

export default memo(CorporateAttendeeModal);
