import { Button, ButtonTheme } from "@q4/nimbus-ui";
import download from "downloadjs";
import moment from "moment";
import React, { memo, useMemo } from "react";
import { CsvExportService } from "../../../../../../../../services/csvExport/csvExport.service";
import { MeetingsExportModel, MeetingsExportIdModel } from "./meetingsExportButton.definition";
import type { MeetingsExportButtonProps } from "./meetingsExportButton.definition";

const MeetingsExportButton = (props: MeetingsExportButtonProps): JSX.Element => {
  const { meetings, timezone, vendorName } = props;

  const csvExportService = useMemo(() => new CsvExportService(), []);

  function handleExport(): void {
    const meetingData = new MeetingsExportModel(meetings, timezone, vendorName);
    const csv = csvExportService.parse(meetingData.meetings, ...meetingData.keys);
    const filename = `meetingschedule_${moment().format("YYYYMMDD_HHmmss")}.csv`;

    download(csv, filename, "text/csv");
  }

  return <Button id={MeetingsExportIdModel.button.id} label="Export" theme={ButtonTheme.Citrus} onClick={handleExport} />;
};
export default memo(MeetingsExportButton);
