import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextIdModel } from "@q4/nimbus-ui";
import { Conference } from "../../../../../services/conference/conference.model";

export interface RegistrationHeaderProps extends BaseComponentProps {
  conference: Conference;
  logo?: string;
  details?: string;
  isAttendeeProfile?: boolean;
}

export enum RegistrationHeaderClassName {
  Base = "registration-header",
  Section = "registration-header_section",
  Logo = "registration-header_logo",
  TitleContainer = "registration-header_title-container",
  Title = "registration-header_title",
  Date = "registration-header_date",
  Description = "registration-header_description",
  Details = "registration-header_details",
}

export class RegistrationHeaderIdModel extends IdModelBase {
  title: TextIdModel;
  date: TextIdModel;
  description: TextIdModel;
  details: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = new TextIdModel(`${this.id}Title`);
    this.date = new TextIdModel(`${this.id}Date`);
    this.description = new TextIdModel(`${this.id}Description`);
    this.details = new TextIdModel(`${this.id}Details`);
  }
}
