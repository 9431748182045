import { IdModelBase, ButtonIdModel, CardIdModel, isNullOrWhiteSpace, TextIdModel } from "@q4/nimbus-ui";
import { QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";
import { RegistrationFormProps } from "../../definition/registrationForm.definition";
import { TermsAndConditionsIdModel } from "../../termsAndConditions/termsAndConditions.definition";
import { AnswerSummaryIdModel } from "../answerSummary/answerSummary.definition";

export interface RegistrationSummaryProps extends RegistrationFormProps {
  onSubmit: () => void;
  loading: boolean;
  companyName?: string;
  isEditMode?: boolean;
  questionGroup?: Partial<QuestionGroup>;
}

export enum RegistrationSummaryClassName {
  Base = "registration-summary",
  Subheader = "registration-summary_subheader",
  Availabilities = "registration-summary_availabilities",
  Block = "registration-summary_block",
  BlockSection = "registration-summary_block-section",
  Details = "registration-summary_details",
  Policy = "registration-summary_policy",
  Section = "registration-summary_section",
}

export class RegistrationSummaryIdModel extends IdModelBase {
  personalEdit: ButtonIdModel;
  stepBack: ButtonIdModel;
  confirm: ButtonIdModel;
  personalInformationSection: CardIdModel;
  salesRepSection: TextIdModel;
  termsAndConditions: TermsAndConditionsIdModel;
  answerSummary: AnswerSummaryIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.personalEdit = new ButtonIdModel(`${this.id}PersonalEditButton`);
    this.stepBack = new ButtonIdModel(`${this.id}StepBackButton`);
    this.confirm = new ButtonIdModel(`${this.id}ConfirmButton`);

    this.personalInformationSection = new CardIdModel(`${this.id}PersonalCard`);
    this.salesRepSection = new TextIdModel(`${this.id}SalesRep`);
    this.answerSummary = new AnswerSummaryIdModel(`${this.id}AnswerSummary`);

    this.termsAndConditions = new TermsAndConditionsIdModel(`${this.id}TermsAndConditions`);
  }
}
