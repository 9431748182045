import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse } from "../api/api.definition";
import { Attendee } from "../attendee/attendee.model";
import { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import { AdminApiVersion, Service } from "../serviceBase/serviceBase.model";
import { mapArrayedApiResponse } from "../serviceBase/serviceBase.utils";
import { Answer } from "./answer.model";

export default class AnswerService extends Service<Answer> {
  constructor() {
    super(AdminRouteBase, "answers", Answer, AdminApiVersion);
  }

  private readonly ATTENDEE_ANSWERS_API_PATH = `${AdminRouteBase}/attendees/v1`;
  private readonly CORPORATE_PROFILE_ANSWERS_API_PATH = `${AdminRouteBase}/corporateprofiles/v1`;

  public getByAttendeeId(_id: Attendee["_id"]): Promise<ApiResponse<Answer[]>> {
    return this._apiService
      .get<Answer[]>(`${this.ATTENDEE_ANSWERS_API_PATH}/${_id}/answers`)
      .then((response) => mapArrayedApiResponse(response, Answer));
  }

  public getByCorporateProfileId(_id: CorporateProfile["_id"]): Promise<ApiResponse<Answer[]>> {
    return this._apiService
      .get<Answer[]>(`${this.CORPORATE_PROFILE_ANSWERS_API_PATH}/${_id}/answers`)
      .then((response) => mapArrayedApiResponse(response, Answer));
  }
}
