import {
  BaseComponentProps,
  ButtonIdModel,
  ButtonListId,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextboxIdModel,
  TextboxListId,
  TextIdModel,
} from "@q4/nimbus-ui";
import { SessionBase } from "../../services/session/session.model";
import { VideoVendorsFormIdModel } from "../../views/admin/conferences/edit/components/videoVendorsForm/videoVendorsForm.definition";

export interface SessionCustomVendorFormProps extends BaseComponentProps {
  session: SessionBase;
  onSessionUpdate: (data: Partial<SessionBase>) => void;
  isExpired: boolean;
}

export enum SessionCustomVendorFormClassName {
  Base = "session-form-vendor",
  DialInNumber = "dial-in-number",
  DialInNumberField = "dial-in-number_field",
  DialInNumberDelete = "dial-in-number_delete",
}

export class SessionCustomVendorFormIdModel extends IdModelBase {
  header: TextIdModel;
  meetingLink: TextboxIdModel;
  videoVendor: VideoVendorsFormIdModel;
  vendorOverrideForm: FormIdModel;
  vendorOverrideDialInForm: FormIdModel;
  overrideUrl: TextboxIdModel;
  meetingId: TextboxIdModel;
  meetingPassword: TextboxIdModel;
  phonePassword: TextboxIdModel;
  dialInAddButton: ButtonIdModel;
  dialInNumberTextbox: TextboxListId;
  dialInDeleteButton: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.header = new TextIdModel(`${this.id}Header`);
    this.meetingLink = new TextboxIdModel(`${this.id}MeetingLink`);
    this.videoVendor = new VideoVendorsFormIdModel(`${this.id}VideoVendor`);
    this.overrideUrl = new TextboxIdModel(`${this.id}OverrideUrlTextbox`);
    this.vendorOverrideForm = new FormIdModel(`${this.id}VendorOverrideForm`);
    this.vendorOverrideDialInForm = new FormIdModel(`${this.id}VendorOverrideDialInForm`);
    this.meetingId = new TextboxIdModel(`${this.id}MeetingId`);
    this.meetingPassword = new TextboxIdModel(`${this.id}MeetingPassword`);
    this.phonePassword = new TextboxIdModel(`${this.id}PhonePassword`);
    this.dialInAddButton = new ButtonIdModel(`${this.id}DialInAddButton`);
    this.dialInNumberTextbox = new TextboxListId(`${this.id}DialInNumber`);
    this.dialInDeleteButton = new ButtonListId(`${this.id}DialInDeleteButton`);
  }
}
