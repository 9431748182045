import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  ToggleButtonsIdModel,
  ToolbarIdModel,
} from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { RegistrantFormIdModel } from "../../../../../../components/registrantForm/registrantForm.definition";
import { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import { CorporateProfileHookModel } from "../../../../../../hooks/admin/useCorporateProfile/useCorporateProfile.definition";
import { QuestionGroupHookModel } from "../../../../../../hooks/admin/useQuestionGroups/useQuestionGroups.definition";
import { RegistrantHookModel } from "../../../../../../hooks/admin/useRegistrant/useRegistrant.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";
import { RegistrationFormTableIdModel } from "./components/registrationFormTable/registrationFormTable.definition";

export interface RegistrantsProps extends Pick<BaseComponentProps, "id"> {
  codes: string[];
  conference: Conference;
  useRegistrants: RegistrantHookModel;
  useCorporateProfile: CorporateProfileHookModel;
  useQuestionGroups: QuestionGroupHookModel;
  user: AdminUserContextState["user"];
}

export enum RegistrantClassName {
  Base = "registrants",
  Content = "registrants_content",
  Table = "registrants_table",
  CopyButton = "registrants_copy-button",
}

export enum RegistrantEditClassName {
  Base = "registrant-edit",
}

export enum RegistrantTableHeader {
  Name = "NAME",
  Company = "COMPANY",
  Email = "EMAIL",
  Type = "TYPE",
  Status = "STATUS",
}

export enum RegistrationCustomQuestionsTableHeader {
  RegistrationType = "REGISTRATION TYPE",
  FormName = "FORM NAME",
  NumberOfQuestions = "NUMBER OF QUESTIONS",
}

export enum RegistrantEntityTableMessages {
  RegistrationClosed = "You have not enabled any registration for this conference",
  AllTypesPresent = "You can only have one form per registration type",
  DeleteConfirmationTitle = "Delete Form?",
  DeleteConfirmationMessage = "Deleting a form will remove all data collected. This action cannot be undone.",
}

export class RegistrantsIdModel extends IdModelBase {
  entityTable: EntityTableIdModel;
  form: RegistrantFormIdModel;
  status: SelectIdModel;
  type: SelectIdModel;
  company: SelectIdModel;
  registrationFormTable: RegistrationFormTableIdModel;
  copyButton: ButtonIdModel;
  registrantToolbar: ToolbarIdModel;
  registrantToolbarToggleButtons: ToggleButtonsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.form = new RegistrantFormIdModel(`${this.id}Form`);
    this.status = new SelectIdModel(`${this.id}StatusSelect`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.company = new SelectIdModel(`${this.id}CompanySelect`);
    this.registrationFormTable = new RegistrationFormTableIdModel(`${this.id}RegistrationFormTable`);
    this.copyButton = new ButtonIdModel(`${this.id}CopyButton`);
    this.registrantToolbar = new ToolbarIdModel(`${this.id}RegistrantToolbar`);
    this.registrantToolbarToggleButtons = new ToggleButtonsIdModel(`${this.id}ToolbarToggleButtons`);
  }
}
