import type { Channel, Members } from "pusher-js";
import { ResponseCode } from "../../services/api/api.definition";
import { PresentationPusherEvent } from "../useEditNotifications/useEditNotifications.definition";

export enum PusherEvent {
  SubscriptionSucceeded = "pusher:subscription_succeeded",
  SubscriptionError = "pusher:subscription_error",
  MemberAdded = "pusher:member_added",
  MemberRemoved = "pusher:member_removed",
}

export class PusherMemberInfo {
  name: string;
  email: string;
  data: string;

  constructor(member: PusherMemberInfo) {
    Object.assign(this, member);
  }
}

export class PusherMemberInfoViewModel extends PusherMemberInfo {
  modifying: boolean;

  constructor(member: PusherMemberInfo, modifying = false) {
    super(member);
    this.modifying = modifying;
  }
}

export interface PusherChannelEventResponseBase {
  id: string;
}

export interface PusherMember extends PusherChannelEventResponseBase {
  info: PusherMemberInfo;
}

export interface PusherSubscribeSuccessResponse extends Members {
  me: PusherMember;
  members: Record<string, PusherMemberInfo>;
}

export interface PusherSubscribeFailedResponse extends Members {
  type: string;
  error: string;
  status: ResponseCode;
}

export interface PusherEditResponse {
  id: string;
  email: string;
}

export interface PusherHookModel {
  channel: Channel;
}

export interface UsePusherProps {
  channelName: string;
  channelEvents: PusherChannelEvent[];
  disabled: boolean;
  email: string;
  name?: string;
  data: string;
  onChannelDestroy?: () => void;
}

export class PusherChannelEvent {
  name: PusherEvent | PresentationPusherEvent;
  action: <T extends PusherEditResponse & PusherMember & PusherSubscribeFailedResponse & PusherSubscribeSuccessResponse>(
    data: T
  ) => void;

  constructor(
    name: PusherEvent | PresentationPusherEvent,
    action: (
      data: PusherEditResponse & PusherMember & PusherSubscribeFailedResponse & PusherSubscribeSuccessResponse
    ) => void
  ) {
    this.name = name;
    this.action = action;
  }
}
