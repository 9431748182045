import { convertFileToBase64, isEmpty } from "@q4/nimbus-ui";
import { PublicRouteBase } from "../../configurations/navigation.configuration";
import { ApiMethod, ApiResponse, AuthType, ContentType } from "../api/api.definition";
import ApiService from "../api/api.service";

interface SignedResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

interface PreSignedResponse {
  fileName: string;
  signedUrl: SignedResponse;
}

interface PreSignedRequest {
  fileName: string;
}

export interface FileUploadResponse {
  url: string;
}

interface FileRequest {
  file: string;
  fileName: string;
}

export default class PublicFileUploadService {
  private readonly PUBLIC_UPLOAD_URL_API_PATH = `${PublicRouteBase}/uploadurl`;
  private readonly PUBLIC_UPLOAD_FILE_API_PATH = `${PublicRouteBase}/upload`;

  private apiService = new ApiService();

  private getUploadUrl(fileName: string): Promise<ApiResponse<PreSignedResponse>> {
    return this.apiService.post<PreSignedRequest, PreSignedResponse>(
      this.PUBLIC_UPLOAD_URL_API_PATH,
      { fileName },
      ContentType.Json,
      AuthType.Public
    );
  }

  private uploadFile(fileName: string, signedUrl: SignedResponse, file: File): Promise<ApiResponse<PreSignedResponse>> {
    const { url, fields } = signedUrl;
    const formData = new FormData();

    Object.keys(fields || {}).forEach((key) => formData.append(key, fields[key]));
    formData.append("file", file);

    return this.apiService
      .makeExternalRequest<FormData>(url, ApiMethod.Post, formData, ContentType.FormData)
      .then((response) =>
        Promise.resolve({
          ...response,
          data: {
            fileName: fileName,
            signedUrl: signedUrl,
          },
        })
      );
  }

  private postPresentation(fileName: string, blob: File): Promise<ApiResponse<FileUploadResponse>> {
    return this.getUploadUrl(fileName)
      .then((response): PreSignedResponse => {
        if (!response.success) {
          throw new Error(`${response.message}`);
        }

        const { data: uploadResponse } = response;
        return uploadResponse;
      })
      .then((uploadResponse): Promise<ApiResponse<PreSignedResponse>> => {
        const { fileName, signedUrl } = uploadResponse;
        return this.uploadFile(fileName, signedUrl, blob);
      })
      .then((response): Promise<ApiResponse<FileUploadResponse>> => {
        if (response.success)
          return Promise.resolve(new ApiResponse({ success: true, data: { url: response.data.fileName } }));

        return Promise.resolve(
          new ApiResponse({
            success: false,
            message: `Failed to upload signed presentation. Status: ${response.message}`,
          })
        );
      })
      .catch((): ApiResponse<FileUploadResponse> => {
        return new ApiResponse({ success: false, message: "Failed to upload signed presentation" });
      });
  }

  async post(
    blob: File,
    fileName = this.sanitizeFileName(blob.name),
    signed = false
  ): Promise<ApiResponse<FileUploadResponse>> {
    if (signed) return this.postPresentation(fileName, blob);

    const base64 = await convertFileToBase64(blob);

    if (isEmpty(base64)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Failed to convert file" }));
    }
    const file = base64.toString().replace(/data:.+;base64,/, "");
    return this.apiService.post<FileRequest, FileUploadResponse>(
      this.PUBLIC_UPLOAD_FILE_API_PATH,
      {
        file,
        fileName,
      },
      ContentType.Json,
      AuthType.Public
    );
  }

  private sanitizeFileName(fileName: string): string {
    return fileName.replace(/ /g, "-").replace(/[\\/<>;=,?%*:|"{}]/g, "");
  }
}
