import {
  BaseComponentProps,
  ButtonIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
} from "@q4/nimbus-ui";
import type { Moment } from "moment";

export interface TimeSlotEditProps extends BaseComponentProps {
  startTime: Moment;
  endTime: Moment;
  isSaving: boolean;
  onSubmit: (startTime: Moment, endTime: Moment) => void;
  onCancel: () => void;
}

export enum TimeSlotEditClassName {
  Base = "timeslot-edit",
  Form = "timeslot-edit_form",
  Buttons = "timeslot-edit_buttons",
}

export class TimeSlotEditIdModel extends IdModelBase {
  form: FormIdModel;
  cancel: ButtonIdModel;
  done: ButtonIdModel;
  start: SelectIdModel;
  end: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.form = new FormIdModel(`${this.id}Form`);
    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.done = new ButtonIdModel(`${this.id}DoneButton`);
    this.start = new SelectIdModel(`${this.id}StartTimeSelect`);
    this.end = new SelectIdModel(`${this.id}EndTimeSelect`);
  }
}
