import { isEmpty } from "lodash";
import { Attendee, AttendeeType, AttendeeViewModel } from "../../attendee/attendee.model";
import { CorporateProfilePayload } from "../../corporateProfile/corporateProfile.model";
import { PayloadBase } from "../../serviceBase/payloadBase.model";

type ProfilePayloadBase = Omit<Attendee, "ConferenceId" | "code" | "email" | "status">;
export class ProfilePayload extends PayloadBase<ProfilePayloadBase> {
  options: Attendee["options"];

  constructor(attendeeViewModel: AttendeeViewModel) {
    super();
    const { options } = attendeeViewModel;
    const payload = {
      type: attendeeViewModel.type,
      title: attendeeViewModel.title,
      company: attendeeViewModel.company,
      last_name: attendeeViewModel.last_name,
      first_name: attendeeViewModel.first_name,
      phone_number: attendeeViewModel.phone_number,
      availability: attendeeViewModel.availability,
      investor_type: attendeeViewModel.investor_type,
      meeting_requests: attendeeViewModel.meeting_requests,
      secondary_contact: attendeeViewModel.secondary_contact,
      sales_representative: attendeeViewModel.sales_representative,
      custom_question_answers: attendeeViewModel.custom_question_answers,
    };

    const { _colors, _defaults, ...optionPayload } = options || {};
    Object.assign(this, payload, { options: optionPayload });

    if (attendeeViewModel.type === AttendeeType.Investor || attendeeViewModel.type === AttendeeType.Corporate) {
      Object.assign(this, { receive_emails: attendeeViewModel.receive_emails });
    }

    if (!isEmpty(attendeeViewModel._corporate_profile)) {
      if (typeof attendeeViewModel._corporate_profile !== "string") {
        const corporateProfilePayload = new CorporateProfilePayload(attendeeViewModel._corporate_profile);
        Object.assign(this, { _corporate_profile: corporateProfilePayload });
      }
    } else {
      Object.assign(this, { company: attendeeViewModel.company });
    }
  }
}
