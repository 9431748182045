import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { useEffect, useState } from "react";
import type { ApiResponse } from "../../services/api/api.definition";
import type { OfflineServiceHookProps, OfflineServiceHookModel } from "./useOfflineSerivce.definition";

export const useOfflineService = <T>(props: OfflineServiceHookProps<T>): OfflineServiceHookModel<T> => {
  const { disabled, entities, offlineDataKey, getOfflineData, setEntity, validateOffline } = props;
  const [offline, setOffline] = useState<ApiResponse<T[]>>(null);

  useEffect(() => {
    (async () => {
      if (disabled || typeof getOfflineData !== "function" || isNullOrWhiteSpace(offlineDataKey)) return;

      const offline = await getOfflineData(offlineDataKey);

      if (!offline?.success) return;

      const offlineData = (offline?.data || [])[0];
      if (validateOffline instanceof Function && !validateOffline(offlineData)) return;

      setEntity((current) => {
        if (!isEmpty(current) || isEmpty(offlineData)) return current;
        return offlineData;
      });

      setOffline(offline);
    })();
  }, [disabled, offlineDataKey, getOfflineData, setEntity, validateOffline]);

  return {
    entities: entities || offline?.data,
  };
};
