import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AdminRouteBase, PublicRouteBase } from "../../../configurations/navigation.configuration";
import { ApiResponse, AuthType, ContentType } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import type { Service } from "../../serviceBase/serviceBase.model";
import {
  PublicEncryptedLink,
  PublicLink,
  PublicLinkEncryptRequest,
  PublicLinkEncryptResponse,
} from "./publicLink.definition";

export default class PublicLinkService
  implements Omit<Service<string>, "delete" | "get" | "getOfflineData" | "put" | "post">
{
  protected readonly _apiService = new ApiService();
  protected _servicePath: string;

  get ApiPathBase(): string {
    if (isNullOrWhiteSpace(this._servicePath)) return null;
    return `${this._servicePath}`;
  }

  constructor() {
    this._servicePath = "/link";
  }

  post = (data: PublicLinkEncryptRequest): Promise<ApiResponse<PublicLinkEncryptResponse>> => {
    if (isEmpty(data)) return Promise.resolve(new ApiResponse({ success: false, message: "Invalid data was provided" }));

    return this._apiService.post<PublicLinkEncryptRequest, PublicLinkEncryptResponse>(
      `${AdminRouteBase}${this.ApiPathBase}/encrypt`,
      data,
      ContentType.Json,
      AuthType.Protected
    );
  };

  get = (linkId: PublicEncryptedLink["linkId"]): Promise<ApiResponse<PublicLink>> => {
    if (isNullOrWhiteSpace(linkId)) {
      return Promise.resolve(new ApiResponse({ success: false, message: "Invalid linkId was provided." }));
    }

    return this._apiService.get<PublicLink>(`${PublicRouteBase}${this.ApiPathBase}/${linkId}`, AuthType.Public);
  };
}
