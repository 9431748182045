import type { SelectProps } from "@q4/nimbus-ui";
import type { ChipsHookProps, ChipsHookModel } from "../useChips/useChips.definition";

export interface ComboBoxHookProps<T> {
  createSuffix?: string;
  chipsHookProps: ChipsHookProps<T>;
  maxSearchResultShown?: number;
  minSearchLengthShown?: number;
  options: (T | string)[];
}

export interface ComboBoxHookModel<T> {
  allowMenuOpen: Pick<SelectProps<T>, "menuIsOpen">;
  input: string;
  filteredOptions: T[];
  chipsHook: ChipsHookModel<T>;
  handleInputChange: (value: string) => void;
}

export enum ComboBoxDefaultSearchResults {
  Max = 20,
  Length = 2,
}
