import { isEmpty } from "@q4/nimbus-ui";
import { saveAs } from "file-saver";
import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse, AuthType, ContentType } from "../api/api.definition";
import { Conference } from "../conference/conference.model";
import { AdminApiVersion, Service } from "../serviceBase/serviceBase.model";
import { Presentation, PresentationExport, PresentationPayload } from "./presentation.model";

export default class PresentationService extends Service<Presentation> {
  private readonly ItineraryPresentationsApiPath = "/mysessions";
  private readonly ExportApiPath = "/export";

  constructor() {
    super(AdminRouteBase, "presentations", Presentation, AdminApiVersion);
  }

  post(data: Presentation): Promise<ApiResponse<Presentation>> {
    const payload = new PresentationPayload(data);
    return super.post(payload).then((response) =>
      this._handleSaveResponse(response, {
        _conference: data._conference,
        _track: data._track,
        _speaker: data._speaker,
      })
    );
  }

  put(id: Presentation["_id"], data: Presentation): Promise<ApiResponse<Presentation>> {
    const payload = new PresentationPayload(data);
    return super.put(id, payload).then((response) =>
      this._handleSaveResponse(response, {
        _conference: data._conference,
        _track: data._track,
        _speaker: data._speaker,
      })
    );
  }

  public async getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Presentation[]>> {
    const response = await this._apiService.get<Presentation[]>(`${this.ApiPathBase}/byConference/${_id}`);
    return this.mapArrayedResponse(response);
  }

  public async getById(_id: Presentation["_id"]): Promise<ApiResponse<Presentation>> {
    const response = await this._apiService.get<Presentation>(`${this.ApiPathBase}/${_id}`);
    return this.mapResponse(response);
  }

  public async getByEmail(email: string): Promise<ApiResponse<Presentation[]>> {
    const response = await this._apiService.get<Presentation[]>(
      `${this.ItineraryPresentationsApiPath}/${email}`,
      AuthType.Passwordless
    );
    return this.mapArrayedResponse(response);
  }

  public async exportToPdf(data: PresentationExport, fileName: string): Promise<ApiResponse<Blob>> {
    return await this._apiService
      .requestBlob<PresentationExport>(`${this.ExportApiPath}`, AuthType.Passwordless, data, ContentType.Json)
      .then((response) => {
        if (!response.success) return response;
        const file = response?.data;
        saveAs(file, fileName);

        return response;
      });
  }

  public async createPresentation(data: Presentation): Promise<ApiResponse<Presentation[] | Presentation>> {
    const payload = new PresentationPayload(data);
    const response = await this._apiService.post<Presentation, Presentation | Presentation[]>(
      `${this.ApiPathBase}`,
      payload
    );

    const populateData = {
      _conference: data._conference,
      _speaker: data._speaker,
      _track: data._track,
    };

    if (Array.isArray(response?.data)) {
      return this.handleArrayedResponse(response as ApiResponse<Presentation[]>, populateData);
    } else {
      return this._handleSaveResponse(response as ApiResponse<Presentation>, populateData);
    }
  }

  private _handleSaveResponse(response: ApiResponse<Presentation>, data: Partial<Presentation>): ApiResponse<Presentation> {
    if (!response.success || isEmpty(response.data) || isEmpty(data)) return response;
    return {
      ...response,
      data: new Presentation({
        ...response.data,
        ...data,
      }),
    };
  }

  protected handleArrayedResponse = (
    response: ApiResponse<Presentation[]>,
    data: Partial<Presentation>
  ): ApiResponse<Presentation[]> => {
    if (!response?.success || isEmpty(response?.data)) return response;
    return new ApiResponse({
      ...response,
      data: response?.data.map((presentation) => new Presentation({ ...presentation, ...data })),
    });
  };
}
