export enum TimeFormat {
  Standard = "h:mm A",
  Military = "HH:mm",
  Picker = "h:mma",
  TimeZonePicker = "h:mma z",
  TimezoneStandard = "h:mm A z",
  FullStandard = "h:mm:ssA",
}

export enum DateFormat {
  AvailabilityHeading = "dddd, MMMM D",
  Short = "MM/DD/YYYY",
  ShortStandard = "MM/DD/YYYY h:mma",
  FullStandard = "MMM D, YYYY h:mma",
  FullDate = "MMMM D, YYYY",
  FullDateNth = "MMMM Do, YYYY",
  TimezoneShortStandard = "MM/DD/YYYY h:mma z",
  TimezoneFullStandard = "MMM D, YYYY h:mma z",
  FullDateWithDay = "dddd, MMMM D, YYYY",
}

export enum DateTokenFormat {
  Day = "D",
  DayWithSuffix = "Do",
  FullDay = "DD",
  FullWeekDay = "dddd",
  FullMonth = "MMMM",
  FullYear = "YYYY",
  TimeZone = "z",
  TimeZoneOffset = "Z",
  FullMonthDateYear = "LL",
}
