import { isNullOrWhiteSpace } from "@q4/nimbus-ui";

declare const initDrift: (data: { email: string; displayName: string; conferenceName: string }) => void;

export class ConferenceHelpService {
  private _chatInitialized = false;

  init(email: string, conferenceName: string, displayName: string): void {
    if (this._chatInitialized || isNullOrWhiteSpace(conferenceName) || isNullOrWhiteSpace(email)) return;

    initDrift({
      email,
      displayName: displayName ?? "",
      conferenceName,
    });
    this._chatInitialized = true;
  }
}
