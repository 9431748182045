import "./presentationForm.component.scss";
import {
  BadgeSize,
  BadgeTheme,
  Checkbox,
  ComboBox,
  DatePicker,
  FileUploader,
  Form,
  FormFieldProps,
  getClassName,
  InfoIcon,
  InputType,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Message,
  MessageType,
  Origin,
  Select,
  SelectPreset,
  Text,
  Textarea,
  Textbox,
  TextPreset,
  TimePicker,
  TooltipTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { CreateNewCodeSuffix, DefaultTimeZone } from "../../const";
import { TimeFormat } from "../../definitions/date.definition";
import { useComboBox, useFileUpload } from "../../hooks";
import { PresentationVendorType } from "../../services/conference/conference.model";
import { ErrorModel } from "../../services/errorHandler/errorHandler.definition";
import { Presentation, PresentationSessionType } from "../../services/presentation/presentation.model";
import { Speaker } from "../../services/speaker/speaker.model";
import { Track } from "../../services/track/track.model";
import {
  getConferenceDisabledDays,
  getConferencePresentationVendorName,
  getNextTimeInHourDecimals,
  getUTCDateTime,
  validateNonRequiredUrlString,
} from "../../utils";
import InfoBubble from "../infoBubble/infoBubble.component";
import SessionCustomVendorForm from "../sessionCustomVendorForm/sessionCustomVendorForm.component";
import {
  PresentationFormClassName,
  PresentationFormIdModel,
  PresentationEditState,
  CreateNewLocationSuffix,
  MeetingLabelMaxLength,
} from "./presentationForm.definition";
import type { PresentationFormProps } from "./presentationForm.definition";

const PresentationForm = (props: PresentationFormProps): JSX.Element => {
  const {
    id,
    className,
    conference,
    codes,
    presentation,
    speakers: speakersProp,
    tracks: tracksProp,
    roomOptions,
    formErrors,
    setFormErrors,
    onPresentationUpdate,
  } = props;
  const idModel = useMemo(() => new PresentationFormIdModel(id), [id]);

  const handleFileUpload = useFileUpload();
  const [messageVisible, handleMessageOpen, handleMessageClose] = useVisibility();

  const conferenceTimeZone = useMemo(
    () => (isNullOrWhiteSpace(conference?.time_zone) ? DefaultTimeZone : conference?.time_zone),
    [conference?.time_zone]
  );
  const isEdit = useMemo(() => !isNullOrWhiteSpace(presentation?._id), [presentation?._id]);

  const presentationVendorName = useMemo(() => getConferencePresentationVendorName(conference), [conference]);
  const sessionTypeOptions = useMemo(
    () => (isEdit ? [PresentationSessionType.Open, PresentationSessionType.Closed] : Object.values(PresentationSessionType)),
    [isEdit]
  );
  const sessionType = useMemo(
    () => (isNil(presentation?.session_type) ? PresentationSessionType.Open : presentation?.session_type),
    [presentation?.session_type]
  );
  const isBreakSession = useMemo(
    () => presentation?.session_type === PresentationSessionType.Break,
    [presentation?.session_type]
  );
  const presentationLabel = useMemo(() => (isBreakSession ? "Break" : "Presentation"), [isBreakSession]);
  const presentationSessionTitle = isBreakSession ? `${presentationLabel} Name` : `${presentationLabel} Title`;

  const disabledDays = useMemo(() => getConferenceDisabledDays(conference), [conference]);

  const baseClassName = useMemo(
    () =>
      getClassName(PresentationFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  // #region Entity effects
  const tracks = useMemo(() => tracksProp || conference?._track || [], [conference, tracksProp]);
  const hasTracks = useMemo(() => !isEmpty(tracks), [tracks]);

  const startTime = useMemo(
    (): string =>
      !isEmpty(presentation?.start_date)
        ? presentation?.start_date?.tz(conferenceTimeZone).format(TimeFormat.Military)
        : "00:00",
    [conferenceTimeZone, presentation?.start_date]
  );

  const isPresentationStarted = useMemo(() => {
    if (isEmpty(presentation?.start_date)) return false;
    return isEdit && moment.utc(presentation.start_date).isSameOrBefore(moment.utc(), "minutes");
  }, [presentation?.start_date, isEdit]);

  const isExpired = useMemo(() => {
    if (isEmpty(presentation?.end_date)) return false;

    const expired = moment.utc(presentation.end_date).isBefore(moment.utc(), "minutes");
    if (expired) {
      handleMessageOpen();
    }

    return expired;
  }, [handleMessageOpen, presentation?.end_date]);

  const startTimeStartHour = useMemo((): number => {
    if (isEmpty(presentation)) return 0;
    if (moment(presentation?.start_date).tz(conferenceTimeZone).isSame(moment().tz(conferenceTimeZone), "day")) {
      return getNextTimeInHourDecimals(moment(), conferenceTimeZone);
    }
    return 0;
  }, [presentation, conferenceTimeZone]);

  const endTimeStartHour = useMemo((): number => {
    if (isEmpty(presentation)) return 0.09;
    if (moment(presentation?.start_date).tz(conferenceTimeZone).isBefore(moment().tz(conferenceTimeZone))) {
      return getNextTimeInHourDecimals(moment(), conferenceTimeZone);
    }
    return getNextTimeInHourDecimals(presentation?.start_date, conferenceTimeZone);
  }, [presentation, conferenceTimeZone]);

  const [locationInput, setLocationInput] = useState<string>();

  const roomSelectOptions = useMemo(getRoomSelectOptions, [roomOptions, locationInput]);

  const {
    input: speakersInput,
    handleInputChange: handleSpeakersInputChange,
    chipsHook: useSpeakerChips,
    filteredOptions: speakers,
  } = useComboBox({
    options: speakersProp,
    chipsHookProps: {
      entities: presentation?._speaker,
      labelKey: "display_name",
      valueKey: "_id",
      disabled: isExpired,
    },
  });
  const [speakerChips, handleSpeakerChipAdd, handleSpeakerChipRemove] = useSpeakerChips;

  const {
    input: codeInput,
    handleInputChange: handleCodeInputChange,
    chipsHook: useCodeChips,
    filteredOptions: codeOptions,
  } = useComboBox({
    options: codes,
    createSuffix: CreateNewCodeSuffix,
    chipsHookProps: {
      entities: presentation?.code,
      disabled: isExpired,
    },
  });
  const [codeChips, handleCodeChipAdd, handleCodeChipRemove] = useCodeChips;

  // #endregion

  // #region Handle Methods
  const handleFormError = useCallback(
    (key: string, message: string, isVisisble: boolean) => {
      if (setFormErrors) {
        setFormErrors((previousState) => ({
          ...previousState,
          [key]: new ErrorModel(message, isVisisble),
        }));
      }
    },
    [setFormErrors]
  );

  const handleSessionTypeChange = useCallback(
    (option: PresentationSessionType): void => {
      if (isEmpty(option) || option === presentation?.session_type) return;

      onPresentationUpdate({ session_type: option });
    },
    [onPresentationUpdate, presentation?.session_type]
  );

  const handleTrackChange = useCallback(
    (option: Track): void => {
      if (isEmpty(option)) return;

      const trackIdString = "_track";
      if (formErrors?.[trackIdString]?.visible) {
        handleFormError(trackIdString, formErrors[trackIdString].message, isNullOrWhiteSpace(option?._id));
      }

      onPresentationUpdate({ _track: option });
    },
    [formErrors, handleFormError, onPresentationUpdate]
  );

  const handleDateChange = useCallback(
    (value: Moment): void => {
      if (isEmpty(value) || !value?.isBetween(disabledDays.before, disabledDays.after, undefined, "[]")) return;

      const updatedStartDate = moment(value).set({
        hours: moment(presentation?.start_date).tz(conferenceTimeZone).get("hours"),
        minutes: moment(presentation?.start_date).tz(conferenceTimeZone).get("minutes"),
      });

      onPresentationUpdate({
        start_date: getUTCDateTime(conferenceTimeZone, updatedStartDate, updatedStartDate),
        end_date: getUTCDateTime(conferenceTimeZone, value, presentation?.end_date),
      });
    },
    [conferenceTimeZone, disabledDays, onPresentationUpdate, presentation?.end_date, presentation?.start_date]
  );

  const handleStartTimeChange = useCallback(
    (value: string): void => {
      if (isEmpty(value)) return;

      const date = getUTCDateTime(conferenceTimeZone, presentation?.start_date, value);

      const presentationDateRange: Partial<PresentationEditState> = {
        start_date: date,
      };

      if (presentationDateRange.start_date.isSameOrAfter(presentation?.end_date)) {
        presentationDateRange.end_date = undefined;
      }

      onPresentationUpdate(presentationDateRange);
    },
    [conferenceTimeZone, onPresentationUpdate, presentation?.end_date, presentation?.start_date]
  );

  const handleEndTimeChange = useCallback(
    (value: string): void => {
      if (isEmpty(value)) return;

      const date = getUTCDateTime(conferenceTimeZone, presentation?.start_date, value);

      const presentationDateRange: Partial<PresentationEditState> = {
        end_date: date,
      };

      if (presentationDateRange.end_date.isSameOrBefore(presentation?.start_date)) {
        presentationDateRange.end_date = undefined;
      }

      if (formErrors?.["end_date"]?.visible) {
        handleFormError("end_date", formErrors["end_date"].message, isNullOrWhiteSpace(value));
      }

      onPresentationUpdate(presentationDateRange);
    },
    [conferenceTimeZone, formErrors, handleFormError, onPresentationUpdate, presentation?.start_date]
  );

  const handleThumbnailChange = useCallback(
    (image_thumbnail: string): void => {
      onPresentationUpdate({ image_thumbnail });
    },
    [onPresentationUpdate]
  );

  const handleDescriptionChange = useCallback(
    (value: string): void => {
      onPresentationUpdate({ description: value });
    },
    [onPresentationUpdate]
  );

  const handleSpeakerSelect = useCallback(
    (speaker: Speaker): void => {
      const _speaker = handleSpeakerChipAdd(speaker, Speaker);
      onPresentationUpdate({ _speaker });
    },
    [handleSpeakerChipAdd, onPresentationUpdate]
  );

  const handleSpeakerRemove = useCallback(
    (value: string): void => {
      const _speaker = handleSpeakerChipRemove(value);
      onPresentationUpdate({ _speaker });
    },
    [handleSpeakerChipRemove, onPresentationUpdate]
  );

  const handleMeetingRoomChange = useCallback(
    (option: string): void => {
      if (isNullOrWhiteSpace(option)) {
        onPresentationUpdate({ roomLocation: "" });
        return;
      }

      let presentationRoom = option;

      if (presentationRoom.includes(CreateNewLocationSuffix)) {
        presentationRoom = presentationRoom.replace(CreateNewLocationSuffix, "");
      }

      onPresentationUpdate({ roomLocation: presentationRoom });
    },
    [onPresentationUpdate]
  );

  const handleCodeSelect = useCallback(
    (value: string): void => {
      const code = handleCodeChipAdd(value);
      onPresentationUpdate({ code });
    },
    [handleCodeChipAdd, onPresentationUpdate]
  );

  const handleCodeRemove = useCallback(
    (value: string): void => {
      const code = handleCodeChipRemove(value);
      onPresentationUpdate({ code });
    },
    [handleCodeChipRemove, onPresentationUpdate]
  );

  const handleAddToAllAgendaChange = useCallback(
    (add_to_all: boolean): void => {
      onPresentationUpdate({ add_to_all });
    },
    [onPresentationUpdate]
  );

  const handleAddToAllConferenceDays = useCallback(
    (is_all_days: boolean): void => {
      onPresentationUpdate({ is_all_days });
    },
    [onPresentationUpdate]
  );
  // #endregion

  // #region Helper Methods
  function getRoomSelectOptions(): string[] {
    const options = roomOptions || [];
    if (isNullOrWhiteSpace(locationInput)) return options;

    return [`${locationInput}${CreateNewLocationSuffix}`, ...options];
  }

  const getTextboxHandler = useCallback(
    (key: keyof Presentation, validator?: (input: string) => boolean) => {
      return function (value: string): void {
        if (validator && formErrors?.[key]?.visible) {
          handleFormError(key, formErrors[key].message, validator(value));
        }

        onPresentationUpdate({ [key]: value });
      };
    },
    [formErrors, handleFormError, onPresentationUpdate]
  );

  const presentationDetailsFields: FormFieldProps[] = [
    {
      key: presentationSessionTitle,
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: presentationSessionTitle,
      required: true,
      error: {
        ...formErrors?.["title"],
        message: `${presentationSessionTitle} is required`,
      },
      children: (
        <Textbox
          id={idModel.title?.id}
          value={presentation?.title}
          onChange={getTextboxHandler("title", isNullOrWhiteSpace)}
          disabled={isExpired}
        />
      ),
    },
  ];

  if (!isBreakSession) {
    presentationDetailsFields.unshift({
      key: "Presentation Type",
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: "Presentation Type",
      children: (
        <Textbox
          id={idModel.presentation_type.id}
          placeholder="e.g. Fireside Chat"
          value={presentation?.presentation_type}
          maxLength={40}
          onChange={getTextboxHandler("presentation_type")}
          disabled={isExpired}
        />
      ),
    });
    presentationDetailsFields.push({
      key: "Image",
      width: "2-of-5",
      smallWidth: "1-of-1",
      label: "Thumbnail Image",
      children: (
        <FileUploader
          id={idModel.fileUpload.id}
          dropzoneProps={{ accept: ["image/*"] }}
          fileApi={handleFileUpload}
          fileUrl={presentation?.image_thumbnail}
          onChange={handleThumbnailChange}
          showPreview={true}
          disabled={isExpired}
        />
      ),
    });
  }

  presentationDetailsFields.push({
    key: "Description",
    width: isBreakSession ? "1-of-1" : "3-of-5",
    smallWidth: "1-of-1",
    label: isBreakSession ? `${presentationLabel} Description` : "Description",
    children: (
      <Textarea
        id={idModel.description?.id}
        className={`${PresentationFormClassName.Description}`}
        value={presentation?.description}
        onChange={handleDescriptionChange}
        disabled={isExpired}
        rows={8}
      />
    ),
  });

  const presentationDateAndTimeFields: FormFieldProps[] = [
    {
      key: "Presentation Date",
      width: isBreakSession && !isEdit ? "1-of-2" : "1-of-3",
      label: "Date",
      children: (
        <DatePicker
          id={idModel.startDate?.id}
          time={startTime}
          timeZone={conferenceTimeZone}
          value={presentation?.start_date}
          onChange={handleDateChange}
          disabledDays={disabledDays}
          disabled={isExpired || (presentation?.is_all_days && !isEdit)}
        />
      ),
    },
  ];

  isBreakSession &&
    !isEdit &&
    presentationDateAndTimeFields.push({
      key: "All Conference Days",
      width: "1-of-2",
      margin: false,
      children: (
        <div className={PresentationFormClassName.CustomCheckbox}>
          <Checkbox
            id={idModel.addToAllConferenceDays?.id}
            label="Apply to all conference days"
            checked={!!presentation?.is_all_days}
            onChange={handleAddToAllConferenceDays}
            disabled={isExpired}
          />
        </div>
      ),
    });

  presentationDateAndTimeFields.push(
    {
      key: "Start Time",
      width: isBreakSession && !isEdit ? "1-of-2" : "1-of-3",
      className: PresentationFormClassName.CustomLabel,
      label: isPresentationStarted ? (
        <>
          Start Time
          <InfoIcon
            id={idModel.infoIcon?.id}
            tooltipProps={{
              label: "Start time cannot be updated once the presentation has begun",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          />
        </>
      ) : (
        "Start Time"
      ),
      children: (
        <TimePicker
          id={idModel.startTime?.id}
          minuteSkip={5}
          startHour={startTimeStartHour}
          endHour={23.9}
          noOptionsMessageText="No Options"
          value={presentation?.start_date?.tz(conferenceTimeZone).format(TimeFormat.Picker)}
          onChange={handleStartTimeChange}
          disabled={isPresentationStarted || isExpired}
        />
      ),
    },
    {
      key: "End Time",
      width: isBreakSession && !isEdit ? "1-of-2" : "1-of-3",
      label: "End Time",
      error: formErrors?.["end_date"],
      children: (
        <TimePicker
          id={idModel.endTime?.id}
          minuteSkip={5}
          startHour={endTimeStartHour}
          noOptionsMessageText={"No Options"}
          value={presentation?.end_date?.tz(conferenceTimeZone).format(TimeFormat.Picker)}
          onChange={handleEndTimeChange}
          disabled={isExpired}
        />
      ),
    },
    {
      key: "Presentation Room",
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: `${presentationLabel} Location`,
      children: (
        <Select
          id={idModel.presentationRoom.id}
          preset={SelectPreset.Autocomplete}
          placeholder="Select a Location"
          disabled={isExpired}
          value={presentation?.roomLocation}
          inputValue={locationInput}
          options={roomSelectOptions}
          isClearable
          backspaceRemovesValue
          onInputChange={(input: string) => setLocationInput(input.substring(0, MeetingLabelMaxLength))}
          onChange={handleMeetingRoomChange}
        />
      ),
    }
  );

  const trackField = useMemo(() => {
    if (isBreakSession) return;
    return (
      <>
        <Text preset={TextPreset.Subheader}>Track</Text>
        <Form
          fields={[
            {
              key: "Presentation Track",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: "Track",
              required: true,
              error: formErrors?.["_track"],
              children: (
                <Select
                  id={idModel.tracks?.id}
                  preset={SelectPreset.Autocomplete}
                  placeholder="Select a Track"
                  value={presentation?._track}
                  labelKey="name"
                  valueKey="_id"
                  options={tracks}
                  disabled={!hasTracks || isExpired}
                  onChange={handleTrackChange}
                />
              ),
            },
          ]}
        />
      </>
    );
  }, [
    formErrors,
    handleTrackChange,
    hasTracks,
    idModel.tracks?.id,
    isBreakSession,
    isExpired,
    presentation?._track,
    tracks,
  ]);

  const getPresentationLinkFields = useMemo((): FormFieldProps[] => {
    return [
      {
        key: "Presentation Url Override",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Streamlined Player Link",
        error: formErrors?.["urlOverride"],
        children: (
          <Textbox
            id={idModel.overrideUrl?.id}
            value={presentation?.urlOverride}
            onChange={getTextboxHandler("urlOverride", validateNonRequiredUrlString)}
            disabled={isExpired}
          />
        ),
      },
      {
        key: "Presentation Poll Link",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Streamlined Poll Link",
        error: formErrors?.["poll_url"],
        children: (
          <Textbox
            id={idModel.pollUrl?.id}
            value={presentation?.poll_url}
            onChange={getTextboxHandler("poll_url", validateNonRequiredUrlString)}
            disabled={isExpired}
          />
        ),
      },
      {
        key: "Presentation QA Link",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Streamlined Q&A Link",
        error: formErrors?.["qa_url"],
        children: (
          <Textbox
            id={idModel.qaUrl?.id}
            value={presentation?.qa_url}
            onChange={getTextboxHandler("qa_url", validateNonRequiredUrlString)}
            disabled={isExpired}
          />
        ),
      },
    ];
  }, [getTextboxHandler, idModel, isExpired, presentation, formErrors]);

  const mediaFields = useMemo(() => {
    if (isBreakSession) return;
    return (
      <>
        {presentationVendorName === PresentationVendorType.Streamlined && (
          <>
            <Text preset={TextPreset.Subheader}>Presentation Link</Text>
            <Form id={idModel.presentationLinkForm.id} fields={getPresentationLinkFields} />
          </>
        )}
        <SessionCustomVendorForm
          key="Presentation Form Vendor"
          id={idModel.customVendorForm.id}
          session={presentation}
          onSessionUpdate={onPresentationUpdate}
          isExpired={isExpired}
        />
      </>
    );
  }, [
    getPresentationLinkFields,
    idModel?.customVendorForm?.id,
    idModel?.presentationLinkForm?.id,
    isBreakSession,
    isExpired,
    onPresentationUpdate,
    presentation,
    presentationVendorName,
  ]);

  const speakerFields = useMemo(() => {
    if (isBreakSession) return;
    return (
      <>
        <Text preset={TextPreset.Subheader}>Speakers</Text>
        <Form
          fields={[
            {
              key: "Speakers",
              width: "1-of-1",
              label: "Select a Speaker",
              children: (
                <ComboBox
                  id={idModel.speakers?.id}
                  selectProps={{
                    preset: SelectPreset.Autocomplete,
                    placeholder: "Find a speaker",
                    inputValue: speakersInput,
                    valueKey: "_id",
                    labelKey: "display_name",
                    options: speakers,
                    onChange: handleSpeakerSelect,
                    onInputChange: handleSpeakersInputChange,
                    disabled: isEmpty(speakers) || isExpired,
                    menuPlacement: "top",
                  }}
                  chipsProps={{
                    items: speakerChips,
                    onClick: handleSpeakerRemove,
                    onRemove: handleSpeakerRemove,
                  }}
                />
              ),
            },
            {
              key: "Minutes",
              width: "1-of-2",
              label: "Speaker Check-in Time (minutes prior)",
              children: (
                <Textbox
                  id={idModel.speakerMinutes?.id}
                  inputType={InputType.Number}
                  min={0}
                  max={1440}
                  value={presentation?.speaker_minutes}
                  onChange={getTextboxHandler("speaker_minutes")}
                  disabled={isExpired}
                  placeholder="Minutes"
                />
              ),
            },
            {
              key: "Speaker Link",
              width: "2-of-2",
              label: "Speaker Link",
              error: formErrors?.["speaker_link"],
              children: (
                <Textbox
                  id={idModel.speakerLink?.id}
                  value={presentation?.speaker_link}
                  onChange={getTextboxHandler("speaker_link", validateNonRequiredUrlString)}
                  disabled={isExpired}
                />
              ),
            },
            {
              key: "Speaker Notes",
              width: "1-of-1",
              label: "Speaker Notes",
              children: (
                <Textarea
                  id={idModel.speakerNotes?.id}
                  value={presentation?.speaker_notes}
                  onChange={getTextboxHandler("speaker_notes")}
                  disabled={isExpired}
                />
              ),
            },
          ]}
        />
      </>
    );
  }, [
    getTextboxHandler,
    handleSpeakerRemove,
    handleSpeakerSelect,
    handleSpeakersInputChange,
    idModel.speakerLink?.id,
    idModel.speakerMinutes?.id,
    idModel.speakerNotes?.id,
    idModel.speakers?.id,
    isBreakSession,
    isExpired,
    presentation?.speaker_link,
    presentation?.speaker_minutes,
    presentation?.speaker_notes,
    speakerChips,
    speakers,
    speakersInput,
    formErrors,
  ]);

  const presentationCodeField = useMemo(() => {
    if (sessionType === PresentationSessionType.Closed)
      return (
        <>
          <Text preset={TextPreset.Subheader}>Codes</Text>
          <Form
            fields={[
              {
                key: "Presentation Codes",
                width: "1-of-1",
                label: "Presentation Codes",
                children: (
                  <ComboBox
                    id={idModel.code?.id}
                    selectProps={{
                      preset: SelectPreset.Autocomplete,
                      placeholder: "Select or create a code",
                      inputValue: codeInput,
                      options: codeOptions,
                      onChange: handleCodeSelect,
                      onInputChange: handleCodeInputChange,
                      disabled: isExpired,
                      menuPlacement: "top",
                    }}
                    chipsProps={{
                      items: codeChips,
                      inline: true,
                      onClick: handleCodeRemove,
                      onRemove: handleCodeRemove,
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      );
  }, [
    codeChips,
    codeInput,
    codeOptions,
    handleCodeInputChange,
    handleCodeRemove,
    handleCodeSelect,
    idModel.code?.id,
    isExpired,
    sessionType,
  ]);

  const addToAllAgendaField = useMemo(() => {
    if (sessionType === PresentationSessionType.Closed) return;
    return (
      <>
        <Text preset={TextPreset.Subheader} className={PresentationFormClassName.Agenda}>
          <InfoBubble
            badgeProps={{
              icon: "ni-warning-4pt",
              size: BadgeSize.Small,
              theme: BadgeTheme.LightGrey,
            }}
            tooltipProps={{
              label:
                "Add presentation to all agendas of current and future attendees. Enabling this setting will override any Presentation Codes.",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          >
            Add to Agendas
          </InfoBubble>
        </Text>
        <Form
          fields={[
            {
              key: "Add to Agenda",
              width: "1-of-1",
              children: (
                <Checkbox
                  id={idModel.addToAgenda?.id}
                  label="Automatically add to all agendas"
                  checked={isBreakSession || !!presentation?.add_to_all}
                  onChange={handleAddToAllAgendaChange}
                  disabled={isExpired || isBreakSession}
                />
              ),
            },
          ]}
        />
      </>
    );
  }, [
    handleAddToAllAgendaChange,
    idModel.addToAgenda?.id,
    isExpired,
    presentation?.add_to_all,
    sessionType,
    isBreakSession,
  ]);
  // #endregion

  return (
    <div id={idModel.id} className={baseClassName}>
      <Text preset={TextPreset.Subheader}>Session Type</Text>
      <Form
        fields={[
          {
            key: "Session Type",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Session Type",
            required: true,
            children: (
              <Select
                id={idModel.sessionType?.id}
                preset={SelectPreset.Autocomplete}
                value={sessionType}
                options={sessionTypeOptions}
                disabled={isExpired || (isEdit && sessionType === PresentationSessionType.Break)}
                onChange={handleSessionTypeChange}
              />
            ),
          },
        ]}
      />
      {trackField}
      <Text preset={TextPreset.Subheader}>{`${presentationLabel} Details`}</Text>
      <Form fields={presentationDetailsFields} />
      <Text preset={TextPreset.Subheader}>Date &amp; Time</Text>
      <Form fields={presentationDateAndTimeFields} />
      {mediaFields}
      {speakerFields}
      {presentationCodeField}
      {addToAllAgendaField}
      <Message
        id={idModel.messageModal.id}
        visible={messageVisible}
        message={"This presentation has concluded. Editing has been disabled."}
        messageType={MessageType.Warning}
        title={"Presentation Expired"}
        primaryActionProps={{
          label: "CONFIRM",
          onClick: handleMessageClose,
        }}
        onCloseRequest={handleMessageClose}
      />
    </div>
  );
};

export default memo(PresentationForm);
