import { ButtonSize, Form, FormFieldProps, ToggleButtons, ToggleButtonsTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { Entity } from "../../../../definitions/entity.definition";
import { capitalizeFirstLetter } from "../../../../utils/string/string.utils";
import { ImportSettingsIdModel, ImportSettingsProps } from "./importSettings.definition";

const ImportSettings = (props: ImportSettingsProps): JSX.Element => {
  const {
    id,
    type,
    onClearTypeChange,
    selectedClearType,
    onImportTypeChange,
    selectedImportType,
    onImportUpdateChange,
    selectedImportUpdate,
  } = props;
  const formattedType = capitalizeFirstLetter(type) + "s";

  const idModel = useMemo(() => new ImportSettingsIdModel(id), [id]);

  const fields: FormFieldProps[] = useMemo(() => {
    if (type === Entity.Attendee) {
      return [
        {
          key: "attendee-import_settings-import-attendees",
          width: "1-of-1",
          smallWidth: "1-of-1",
          label: `Import ${formattedType}`,
          children: (
            <ToggleButtons
              id={idModel?.update?.id}
              theme={ToggleButtonsTheme.LightGrey}
              size={ButtonSize.Large}
              onChange={onImportUpdateChange}
              selected={+selectedImportUpdate}
              items={[
                {
                  key: "all",
                  label: "Create",
                },
                {
                  key: "update",
                  label: "Create or Update",
                },
              ]}
            />
          ),
        },
      ];
    }

    return [
      {
        key: "import_settings-remove-meetings-presentations",
        width: "2-of-3",
        smallWidth: "1-of-1",
        label: `Remove Existing ${formattedType}`,
        children: (
          <ToggleButtons
            id={idModel?.clear?.id}
            theme={ToggleButtonsTheme.LightGrey}
            size={ButtonSize.Large}
            onChange={onClearTypeChange}
            selected={+selectedClearType}
            items={[
              {
                key: "none",
                label: "None",
              },
              {
                key: "all",
                label: "All",
              },
              {
                key: "future",
                label: "Future Only",
              },
            ]}
          />
        ),
      },
      {
        key: "presentation-import_settings-import-presentations",
        width: "1-of-3",
        smallWidth: "1-of-1",
        label: `Import ${formattedType}`,
        children: (
          <ToggleButtons
            id={idModel?.type?.id}
            theme={ToggleButtonsTheme.LightGrey}
            size={ButtonSize.Large}
            onChange={onImportTypeChange}
            selected={+selectedImportType}
            items={[
              {
                key: "all",
                label: "All",
              },
              {
                key: "future",
                label: "Future Only",
              },
            ]}
          />
        ),
      },
    ];
  }, [
    idModel,
    formattedType,
    selectedClearType,
    selectedImportType,
    selectedImportUpdate,
    type,
    onClearTypeChange,
    onImportTypeChange,
    onImportUpdateChange,
  ]);

  return <Form id={idModel?.id} fields={fields} />;
};

export default memo(ImportSettings);
