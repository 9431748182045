import { Entity } from "../../../definitions/entity.definition";
import { Company } from "../../../services/admin/companies/companies.model";
import CompaniesService from "../../../services/admin/companies/companies.service";
import type { ServiceHookModel, ServiceHookPropsBase } from "../../useService/useService.definition";
import { useService } from "../../useService/useService.hook";

export const useCompanies = (props: ServiceHookPropsBase<Company>): ServiceHookModel<Company, CompaniesService> => {
  const { autoFetchData, assignDefaultEntity, data, showNotifications, useOffline } = props;

  const { current, items, loading, service, fetch, post, deleteById, putById, setCurrent, setItems, setLoading } =
    useService({
      autoFetchData,
      assignDefaultEntity,
      data,
      entityName: Entity.Company,
      showNotifications,
      useOffline,
      entityModel: Company,
      serviceModel: CompaniesService,
    });

  return {
    current,
    loading,
    items,
    service,
    fetch,
    post,
    putById,
    deleteById,
    setCurrent,
    setItems,
    setLoading,
  };
};
