import { isNil, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import moment from "moment";
import { SchedulerDefaults } from "../../const";
import { DateFormat, TimeFormat } from "../../definitions/date.definition";
import { MeetingInterestLevel } from "../../services/admin/registrant/registrant.model";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Conference } from "../../services/conference/conference.model";
import { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../services/meeting/meeting.model";
import { BadgeTheme } from "../../views/admin/conferences/details/badge/badge.definition";
import { SchedulerMeetingStatus } from "../../views/admin/conferences/details/components/meetingScheduler/meetingScheduler.definition";
import { mapTimeZone } from "../date/date.utils";

const DropZonePlannerBlockIdPrefix = "dropzone_planner-block--";

export function getPlannerBlockDropZoneId(time?: Moment): string {
  if (isEmpty(time)) return null;

  const format = `${DateFormat.Short}T${TimeFormat.Military}`;
  return `${DropZonePlannerBlockIdPrefix}${time.format(format)}`;
}

export function isPlannerBlockDropZone(id: string): boolean {
  if (isNullOrWhiteSpace(id)) return false;
  return id.includes(DropZonePlannerBlockIdPrefix);
}

export function attendingMeeting(id: AttendeeViewModel["_id"], meeting: Meeting): boolean {
  if (isEmpty(meeting?._attendee) || isNullOrWhiteSpace(id)) return false;
  return meeting._attendee.some((attendee) => attendee?._id === id);
}

export function mapAttendeesToMeeting(
  meeting: Meeting,
  attendees: AttendeeViewModel[],
  timeZone?: Conference["time_zone"]
): Meeting {
  if (isEmpty(meeting)) return null;

  const hasTimeZone = !isNullOrWhiteSpace(timeZone);
  const startEndDate = hasTimeZone
    ? {
        start_date: mapTimeZone(meeting.start_date, timeZone),
        end_date: mapTimeZone(meeting.end_date, timeZone),
      }
    : {};

  return new Meeting({
    ...meeting,
    ...startEndDate,
    _attendee: (meeting._attendee || []).map((attendee) => {
      const data = attendees.find((x) => x._id === attendee._id) ?? null;
      if (data?._corporate_profile) attendee._corporate_profile = data._corporate_profile;
      return new AttendeeViewModel({ ...data, ...attendee });
    }),
  });
}

export function mapAttendeesToMeetings(
  meetings: Meeting[],
  attendees: AttendeeViewModel[],
  timeZone?: Conference["time_zone"]
): Meeting[] {
  return (meetings || []).map((x) => mapAttendeesToMeeting(x, attendees, timeZone));
}

export function formatPlannerDate(date: Moment, timeZone?: string): string {
  const dateFormat = "DD dddd";
  if (isEmpty(date)) return null;
  if (isNullOrWhiteSpace(timeZone)) return date.format(dateFormat);
  return date.tz(timeZone).format(dateFormat);
}

export function hasCorporateProfile(attendee: AttendeeViewModel): boolean {
  if (isEmpty(attendee)) return false;

  return typeof attendee._corporate_profile === "string"
    ? !isNullOrWhiteSpace(attendee._corporate_profile)
    : !isEmpty(attendee._corporate_profile);
}

export function filterAvailableCorporateProfiles(corporateProfiles: CorporateProfile[]): CorporateProfile[] {
  return corporateProfiles.filter((corporate) => {
    if (!corporate?.host_small_meetings || isEmpty(corporate.attendees)) return false;
    return corporate.attendees.some((attendee) => !isEmpty(attendee.availability));
  });
}

export function getSchedulerStatusTheme(status: SchedulerMeetingStatus): BadgeTheme {
  switch (status) {
    case SchedulerMeetingStatus.Scheduled:
      return BadgeTheme.Teal;
    case SchedulerMeetingStatus.Pending:
      return BadgeTheme.Citrus;
    case SchedulerMeetingStatus.Unscheduled:
    default:
      return BadgeTheme.SoftGrey;
  }
}

export function getSchedulerPriorityTheme(priority: MeetingInterestLevel): BadgeTheme {
  switch (priority) {
    case MeetingInterestLevel.High:
      return BadgeTheme.Spice;
    case MeetingInterestLevel.Medium:
      return BadgeTheme.Rain;
    case MeetingInterestLevel.Low:
    default:
      return BadgeTheme.Teal;
  }
}

export function getScheduleStatus(hasMeeting: boolean, isDirty: boolean): SchedulerMeetingStatus {
  if (!hasMeeting) return SchedulerMeetingStatus.Unscheduled;
  if (isDirty) return SchedulerMeetingStatus.Pending;
  return SchedulerMeetingStatus.Scheduled;
}

export const convertHourToPickerFormat = (timeNumber: number): string => {
  if (isNil(timeNumber)) return;
  return moment().startOf("day").add(timeNumber, "minutes").format(TimeFormat.Picker);
};

export const convertPickerFormatToMinutes = (time: string): number => {
  return moment(time, TimeFormat.Picker).hour() * 60 + moment(time, TimeFormat.Picker).minutes();
};

export const hasConferenceScheduler = (conference: Conference): boolean => {
  const { start_date, end_date, slots } = conference?.scheduler || {};
  return !(isEmpty(start_date) && isEmpty(end_date) && isEmpty(slots));
};

export const range = (start: number, end: number, step: number): string[] => {
  const output = [];
  while (start <= end) {
    output.push(start.toString());
    start += step;
  }
  return output;
};

export const durationOptions = range(
  SchedulerDefaults.minDuration,
  SchedulerDefaults.maxDuration,
  SchedulerDefaults.selectInterval
);

export const breakOptions = range(SchedulerDefaults.minBreak, SchedulerDefaults.maxBreak, SchedulerDefaults.selectInterval);
