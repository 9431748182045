import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, MessageIdModel } from "@q4/nimbus-ui";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import { ImportResult } from "../../services/import/import.definition";

export type SyncedAttendeesResponse = {
  title: string;
  errorMessage: string;
  entity: AttendeeViewModel;
  actionName: string;
};

export enum SyncStatusNames {
  Cancelled = "Sync cancelled",
  Synced = "Synced",
}

export interface SyncConfirmationMessageProps extends Pick<BaseComponentProps, "id"> {
  visible: boolean;
  loading: boolean;
  totalAttendees: number;
  currentCount: number;
  syncedAttendees: ImportResult<AttendeeViewModel>[];
  onCancel: () => void;
  onConfirm: () => void;
}

export class SyncConfirmationMessageIdModel extends IdModelBase {
  message: MessageIdModel;
  cancel: ButtonIdModel;
  sync: ButtonIdModel;
  attendeeSyncProgress: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.message = new MessageIdModel(this.id);
    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.sync = new ButtonIdModel(`${this.id}SyncButton`);
    this.attendeeSyncProgress = `${this.id}AttendeeSyncProgress`;
  }
}
