import "./speakers.component.scss";
import { isEmpty, isNullOrWhiteSpace, ToolbarTheme } from "@q4/nimbus-ui";
import type { GetQuickFilterTextParams, ICellRendererParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useMemo, useRef } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { EntityTableClassName, EntityTableRef } from "../../../../../../components/entityTable/entityTable.definition";
import SpeakerForm from "../../../../../../components/speakerForm/speakerForm.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { Speaker } from "../../../../../../services/speaker/speaker.model";
import { getFriendlyName, getCompanies, getBackgroundImageUrl, byString } from "../../../../../../utils";
import { SpeakersClassName, SpeakersIdModel, SpeakersTableHeader } from "./speakers.definition";
import type { SpeakersProps } from "./speakers.definition";

const Speakers = (props: SpeakersProps): JSX.Element => {
  const { id, conference, useAttendees, useSpeakers, user } = props;

  const { current: speaker, items: speakers, setCurrent: setCurrentSpeaker } = useSpeakers;
  const { items: attendees } = useAttendees;

  const tableRef = useRef<EntityTableRef>(null);

  const idModel = useMemo(() => new SpeakersIdModel(id), [id]);

  const companies = useMemo<string[]>(() => getCompanies(speakers), [speakers]);

  const tableProps = {
    columnDefs: [
      {
        field: "order",
        sort: "asc",
        headerName: SpeakersTableHeader.Order,
        maxWidth: 120,
        flex: 1,
      },
      {
        field: "image_profile",
        headerName: SpeakersTableHeader.Photo,
        minWidth: 94,
        maxWidth: 94,
        sortable: false,
        cellRenderer: "imageCellRenderer",
      },
      {
        field: "first_name",
        headerName: SpeakersTableHeader.Name,
        minWidth: 160,
        flex: 1,
        cellRenderer: "nameCellRenderer",
        getQuickFilterText: renderName,
      },
      {
        field: "company",
        headerName: SpeakersTableHeader.Company,
        minWidth: 160,
        flex: 1,
        comparator: byString,
      },
      {
        field: "title",
        headerName: SpeakersTableHeader.Title,
        minWidth: 160,
        flex: 1,
      },
    ],
    frameworkComponents: {
      imageCellRenderer: renderImageCell,
      nameCellRenderer: renderName,
    },
  };

  const editForm = (
    <div className={SpeakersClassName.Edit}>
      <SpeakerForm
        id={idModel.form?.id}
        attendees={attendees}
        companies={companies}
        speaker={speaker}
        onUpdate={handleSpeakerUpdate}
      />
    </div>
  );

  function handleSpeakerUpdate(data: Partial<Speaker>) {
    tableRef.current && tableRef.current.triggerEditNotification();
    setCurrentSpeaker(new Speaker({ ...speaker, ...data }));
  }

  function renderName(params: GetQuickFilterTextParams): string {
    const speaker = params?.data as Speaker;

    if (isEmpty(speaker)) return "—";
    const friendlyName = getFriendlyName(speaker.first_name, speaker.last_name, speaker.company, speaker.contact?.email);

    return isNullOrWhiteSpace(friendlyName) ? "—" : friendlyName;
  }

  function renderImageCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.value)) return "—";

    return (
      <div className={EntityTableClassName.ImageCell} style={{ backgroundImage: getBackgroundImageUrl(params.value) }} />
    );
  }

  return (
    <div id={idModel.id} className={SpeakersClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.Speaker}
        useService={useSpeakers}
        tableProps={tableProps}
        editForm={editForm}
        entityModel={Speaker}
        saveRequestParams={{ _conference: conference }}
        icon="q4i-contact-2pt"
        requiredEntityKeys={["last_name"]}
        toolbarProps={{ theme: ToolbarTheme.Q4Blue }}
        user={user}
        conference={conference}
      />
    </div>
  );
};

export default memo(Speakers);
