import { Textbox, Checkbox, Form, Button, getClassName, FormFieldProps, ErrorModel, isEmpty } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { QuestionType } from "../../../services/questionGroup/questionGroup.model";
import { getOptionLabel, isFirstOption } from "../../../utils/customQuestionAnswer.utils";
import { QuestionCardWrapper } from "../../questionCardWrapper/questionCardWrapper.component";
import { QuestionCardWrapperClassNames } from "../../questionCardWrapper/questionCardWrapper.definition";
import { QuestionCardFieldLimit, QuestionCardLabels } from "../questionCard.definition";
import { SelectQuestionIdModel, SelectQuestionProps } from "./selectQuestion.definition";

export function SelectQuestion(props: SelectQuestionProps): JSX.Element {
  const { id, currentQuestion, currentQuestionTypeLabel, handleQuestionUpdate, ...cardProps } = props;
  const { answer_type, options, options_sort } = currentQuestion;

  const idModel = useMemo(() => new SelectQuestionIdModel(id), [id]);

  const addAdditionalOptionsEnabled = useMemo(() => answer_type?.field_type === QuestionType.CustomSelection, [answer_type]);
  const sortOptionsEnabled = useMemo(() => options_sort?.enabled || false, [options_sort]);

  const handleAdditionalOptionsChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        answer_type: {
          ...currentQuestion?.answer_type,
          field_type: value ? QuestionType.CustomSelection : QuestionType.Selection,
        },
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const handleSortChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        options_sort: {
          ...options_sort,
          enabled: value,
        },
      });
    },
    [currentQuestion, options_sort, handleQuestionUpdate]
  );

  const handleAddOption = useCallback(
    (newOption): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        options: [...options, newOption],
      });
    },
    [options, currentQuestion, handleQuestionUpdate]
  );

  const handleRemoveOption = useCallback(
    (optionNumber): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        options: options?.filter((each, idx) => idx !== optionNumber),
      });
    },
    [options, currentQuestion, handleQuestionUpdate]
  );

  const answerTextTypeFields = useMemo(() => {
    function handleUpdateOption(optionNumber: number): (value: string) => void {
      return function (value: string): void {
        const key = `_questions[${cardProps.idx - 1}].options[${optionNumber}].answer`;
        const formKey = cardProps.formKeys?.find((form) => form.key === key);
        if (optionNumber < 1 && !isEmpty(formKey)) {
          cardProps.setFormErrors((previousState) => ({
            ...previousState,
            [key]: new ErrorModel(formKey?.message, formKey?.validation(value)),
          }));
        }

        handleQuestionUpdate({
          ...currentQuestion,
          options: options?.map((eachOption, idx) => {
            if (idx === optionNumber) {
              return { ...eachOption, answer: value };
            }
            return eachOption;
          }),
        });
      };
    }

    const renderRemoveIcon = (handleRemove: (index?: number) => void, index?: number) => (
      <div
        id={idModel.removeOption.getId(index)}
        className={QuestionCardWrapperClassNames.removeIcon}
        onClick={() => handleRemove(index)}
      >
        <span>
          <i className="q4i-close-4pt" />
        </span>
      </div>
    );

    const optionFields: FormFieldProps[] = options?.map((eachOption, idx: number) => {
      return {
        key: getOptionLabel(idx + 1),
        width: "1-of-1",
        label: getOptionLabel(idx + 1),
        required: isFirstOption(idx),
        error: isFirstOption(idx)
          ? cardProps?.formErrors?.[`_questions[${cardProps.idx - 1}].options[${idx}].answer`]
          : null,
        children: (
          <>
            <Textbox
              id={idModel.optionField.getId(idx)}
              className={getClassName(null, [
                { condition: !isFirstOption(idx), trueClassName: QuestionCardWrapperClassNames.optionFieldWrapper },
              ])}
              maxLength={QuestionCardFieldLimit.Option}
              value={eachOption.answer}
              onChange={handleUpdateOption(idx)}
            />
            {!isFirstOption(idx) && renderRemoveIcon(handleRemoveOption, idx)}
          </>
        ),
      };
    });

    const optionButtons = {
      key: "Buttons",
      width: "1-of-1",
      children: (
        <div>
          <Button
            id={idModel.addOptionButton.id}
            label={QuestionCardLabels.addOption}
            className={QuestionCardWrapperClassNames.linkButton}
            icon="q4i-add-4pt"
            onClick={() => handleAddOption({ answer: "", default: false })}
          />
        </div>
      ),
    };
    return <Form id={idModel.optionForm.id} fields={[...optionFields, optionButtons]} />;
  }, [options, idModel, cardProps, handleQuestionUpdate, currentQuestion, handleRemoveOption, handleAddOption]);

  const answerConfigFields = useMemo(
    () => (
      <>
        <Checkbox
          id={idModel.addAdditionalOptions.id}
          checked={addAdditionalOptionsEnabled}
          label={QuestionCardLabels.additionalOption}
          onChange={handleAdditionalOptionsChange}
        />
        <Checkbox
          id={idModel.sortOptions.id}
          checked={sortOptionsEnabled}
          label={QuestionCardLabels.sortOptions}
          onChange={handleSortChange}
        />
      </>
    ),
    [idModel, addAdditionalOptionsEnabled, handleAdditionalOptionsChange, sortOptionsEnabled, handleSortChange]
  );

  return (
    <QuestionCardWrapper
      id={idModel.id}
      typeConfigurationFields={answerConfigFields}
      textConfigurationFields={answerTextTypeFields}
      currentQuestion={currentQuestion}
      currentQuestionTypeLabel={currentQuestionTypeLabel}
      handleQuestionUpdate={handleQuestionUpdate}
      {...cardProps}
    />
  );
}

export default memo(SelectQuestion);
