import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";
import { HelpCentreTokenRequest, HelpCentreTokenResponse } from "./helpCentre.model";

export default class HelpCentreService {
  private readonly ADMIN_HELPCENTRE_BASE_API_PATH = `${AdminRouteBase}/helpcentre`;
  private apiService = new ApiService();

  async getTokenByEmail(email: string): Promise<ApiResponse<HelpCentreTokenResponse>> {
    return this.apiService.post<HelpCentreTokenRequest, HelpCentreTokenResponse>(
      `${this.ADMIN_HELPCENTRE_BASE_API_PATH}/getToken/v1`,
      {
        email,
      }
    );
  }
}
