import { isNil, isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { ApiResponse } from "../../services/api/api.definition";
import type { Company } from "../../services/company/company.model";
import CompanyService from "../../services/company/company.service";
import { Conference } from "../../services/conference/conference.model";
import type { CompanyHookModel, CompanyHookProps } from "./useCompany.definition";

export const useCompany = (props: CompanyHookProps): CompanyHookModel => {
  const { autoFetchData: autoFetchDataProp, companyUrlSuffix, showNotifications: showNotificationsProp } = props;

  const [company, setCompany] = useState<Company>();
  const [conferences, setConferences] = useState<Conference[]>();
  const [loading, setLoading] = useState(false);
  const companyService = useMemo(() => new CompanyService(), []);
  const notificationService = useMemo(() => new NotificationService(), []);

  const autoFetchData = useMemo(() => (isNil(autoFetchDataProp) ? true : autoFetchDataProp), [autoFetchDataProp]);
  const showNotifications = useMemo(
    () => (isNil(showNotificationsProp) ? true : showNotificationsProp),
    [showNotificationsProp]
  );

  const _handleCompanyResponse = useCallback(
    (response: ApiResponse<Company>): Company => {
      setLoading(false);
      if (!response.success) {
        showNotifications && notificationService.error("Failed to find conference.");
        return null;
      }

      const company = response?.data;
      setCompany(company);
      return company;
    },
    [notificationService, showNotifications]
  );

  const _handleConferencesResponse = useCallback(
    (response: ApiResponse<Conference[]>): Conference[] => {
      setLoading(false);
      if (!response.success) {
        showNotifications && notificationService.error("Failed to find open conferences.");
        return null;
      }

      const conferences = response?.data;
      setConferences(conferences);
      return conferences;
    },
    [notificationService, showNotifications]
  );

  const getByUrlSuffix = useCallback(
    (urlSuffix: string): Promise<Company> => {
      setLoading(true);
      return companyService.getByUrlSuffix(urlSuffix).then(_handleCompanyResponse);
    },
    [companyService, _handleCompanyResponse]
  );

  // TODO create new public useConferences hook?
  const getOpenConferences = useCallback(
    (id: string): Promise<Conference[]> => {
      setLoading(true);

      return companyService.getOpenConferences(id).then(_handleConferencesResponse);
    },
    [companyService, _handleConferencesResponse]
  );

  useEffect(() => {
    if (!autoFetchData) return;

    if (isNullOrWhiteSpace(companyUrlSuffix)) return;

    getByUrlSuffix(companyUrlSuffix);
  }, [autoFetchData, companyUrlSuffix, getByUrlSuffix]);

  return {
    company,
    conferences,
    loading,
    fetchByUrlSuffix: getByUrlSuffix,
    fetchOpenConferences: getOpenConferences,
  };
};
