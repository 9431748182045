import { WebAuth } from "auth0-js";
import config from "../../../config/config";
import { AdminRouteBase } from "../../../configurations/navigation.configuration";
import type { Auth0ServiceBase, AuthSignInOptions } from "../auth0.model";
import { AdminAuth0 } from "./adminAuth0.model";

export const LOCAL_STORAGE_ADMIN_AUTH0 = "auth0";
export const LOCAL_STORAGE_ADMIN_AUTH0_REDIRECT = "auth0_redirect";

export class AdminAuth0Service implements Auth0ServiceBase {
  private _auth0: WebAuth;
  private _authToken: AdminAuth0;
  get AuthToken(): AdminAuth0 {
    return this._authToken;
  }

  constructor() {
    const { domain, clientId, audience } = config.auth0;
    const redirectUri = `${window.location.origin}${AdminRouteBase}/callback`;

    this._auth0 = new WebAuth({
      domain,
      audience,
      clientID: clientId,
      redirectUri,
      responseType: "id_token",
      scope: "openid profile email",
    });
  }

  signIn = (options: AuthSignInOptions): void => {
    localStorage.setItem(LOCAL_STORAGE_ADMIN_AUTH0_REDIRECT, options?.redirect);

    this._auth0.authorize();
  };

  handleAuthentication = (): string => {
    const callbackUrlSearch = ((window.location.search.split("?") || [])[1] + window.location.hash).replace("#", "&");

    this._authToken = new AdminAuth0();
    this._authToken.init(callbackUrlSearch);
    const redirectUrl = localStorage?.getItem(LOCAL_STORAGE_ADMIN_AUTH0_REDIRECT);

    return redirectUrl ?? "";
  };

  signOut = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_ADMIN_AUTH0_REDIRECT);

    // clear id token, profile, and expiration
    this._authToken = null;
    this._auth0.logout({
      returnTo: `${window.location.origin}${AdminRouteBase}`,
      clientID: config.auth0.clientId,
    });
  };
}
