import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { extendMoment } from "moment-range";
import moment from "moment-timezone";
import * as MomentLib from "moment-timezone";
import { MidnightTimeFormat } from "../../const/date.const";
import { DateFormat, DateTokenFormat, TimeFormat } from "../../definitions/date.definition";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Conference, ConferenceScheduler, ConferenceSchedulerSlot } from "../../services/conference/conference.model";

const momentExtended = extendMoment(MomentLib);

const IsoDateFormat = "YYYY-MM-DD";
const IsoTimeFormat = "HH:mm:ss";
const IsoDateTimeFormat = `${IsoDateFormat}T${IsoTimeFormat}`;
const DatePickerDefaultTime = "00:00";

export function convertIsoToMomentUtc(dateTime: string | Moment): Moment {
  return typeof dateTime === "string" ? moment.utc(dateTime) : dateTime;
}

export function formatDateRange(start: Moment, end: Moment, showWeekday = true, showEndMonth = true): string {
  if (isEmpty(start)) return "";

  const dateFormat = _addWeekdayFormat(
    `${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}, ${DateTokenFormat.FullYear}`,
    showWeekday
  );
  const startDate = start.format(dateFormat);
  if (isEmpty(end)) return startDate;

  if (!start.isSame(end, "year")) {
    return `${start.format(dateFormat)} - ${end.format(dateFormat)}`;
  }

  const year = start.format(DateTokenFormat.FullYear);
  if (!start.isSame(end, "month")) {
    const monthDayFormat = _addWeekdayFormat(`${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}`, showWeekday);
    return `${start.format(monthDayFormat)} - ${end.format(monthDayFormat)}, ${year}`;
  }

  if (start.isSame(end, "month") && !start.isSame(end, "day")) {
    const startFormat = _addWeekdayFormat(`${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}`, showWeekday);
    const endFormatString = showEndMonth ? `${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}` : DateTokenFormat.Day;
    const endFormat = _addWeekdayFormat(endFormatString, showWeekday);
    return `${start.format(startFormat)} - ${end.format(endFormat)}, ${year}`;
  }

  if (!start.isSame(end, "day")) {
    const startFormat = _addWeekdayFormat(`${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}`, showWeekday);
    const endFormat = _addWeekdayFormat(`${DateTokenFormat.FullMonth} ${DateTokenFormat.Day}`, showWeekday);
    return `${start.format(startFormat)} - ${end.format(endFormat)}, ${year}`;
  }
  return startDate;
}

export function formatTimeZoneLabel(timeZone: Conference["time_zone"], date?: Moment | Date): string {
  if (date) {
    return moment.utc(date).tz(timeZone).zoneAbbr();
  } else {
    return moment.tz(timeZone).zoneAbbr();
  }
}

export function convertToLocal(momentDate: Moment, timezone: string): Moment {
  // convert to timezone
  const date = parseTimeZone(momentDate, timezone);
  if (isEmpty(date)) return null;

  // remove timezone
  const isoDateTime = date.format(IsoDateTimeFormat);
  return moment(isoDateTime, IsoDateTimeFormat);
}

export function parseTimeZone(momentDate: Moment, timezone: string, time?: string): Moment {
  const date = _convertToDate(momentDate, timezone);
  return _convertToMomentTimeZone(date, timezone, time);
}

export function getTimeTableDaysDateRange(days: Moment[]): string {
  if (isEmpty(days)) return null;
  return formatDateRange(days[0].clone(), days[days.length - 1].clone(), false, false);
}

export function mapTimeZone(date: Moment, timeZone: Conference["time_zone"]): Moment {
  if (isEmpty(date) || isNullOrWhiteSpace(timeZone)) return null;
  return moment.utc(date).tz(timeZone);
}

export function mapTimeTableDayToTimeZone(
  timeTableDay: ConferenceSchedulerSlot,
  timeZone: Conference["time_zone"]
): ConferenceSchedulerSlot {
  if (isEmpty(timeTableDay) || isNullOrWhiteSpace(timeZone)) return null;
  return {
    start_time: mapTimeZone(timeTableDay.start_time, timeZone),
    end_time: mapTimeZone(timeTableDay.end_time, timeZone),
  };
}

export function mapAvailabilityToTimeZone(
  availability: ConferenceSchedulerSlot[],
  timeZone: Conference["time_zone"]
): AttendeeViewModel["availability"] {
  if (isEmpty(availability) || isNullOrWhiteSpace(timeZone)) return [];

  return availability.reduce((availabilities, x) => {
    const timeTableDay = mapTimeTableDayToTimeZone(x, timeZone);
    if (isEmpty(timeTableDay)) return availabilities;
    availabilities.push(timeTableDay);
    return availabilities;
  }, []);
}

export function getHoursToOffsetMeeting(timeZone: Conference["time_zone"], date: Date): number {
  // Adjust for the case of a meeting in the future/past being a different UTC offset than the conference (i.es. Daylight Savings)
  return (moment(date).tz(timeZone).utcOffset() - moment(date).utcOffset()) / 60;
}

export function getTimeZoneOffsetLabelInHours(timeZone: string): string {
  const offset = moment.tz(timeZone).utcOffset();
  return `${Math.trunc(offset / 60)}:${(offset % 60).toString().padEnd(2, "0")}`;
}

function _addWeekdayFormat(format: string, showWeekday: boolean, fullDayFormat = DateTokenFormat.FullWeekDay): string {
  if (isNil(format) || isNullOrWhiteSpace(fullDayFormat)) return;
  return showWeekday ? `${fullDayFormat} ${format}`.trim() : format;
}

function _convertToDate(value: Moment, timeZone: string): Date {
  if (isNil(value)) return null;

  const valueWithTimeZone = _convertUtcToTimeZone(value, timeZone);

  return new Date(_convertToIsoDate(valueWithTimeZone));
}

function _setDefaultTime(time: string): string {
  return _isValidTime(time) ? time : DatePickerDefaultTime;
}

function _isValidTime(time: string): boolean {
  if (isNullOrWhiteSpace(time)) return false;

  const morningTimeExpression = /^0[0-9](:[0-5]\d){1,2}$/;
  const afternoonTimeExpression = /^1[1-9](:[0-5]\d){1,2}$/;
  const eveningTimeExpression = /^2[0-3](:[0-5]\d){1,2}$/;

  return morningTimeExpression.test(time) || afternoonTimeExpression.test(time) || eveningTimeExpression.test(time);
}

function _getIsoDateTime(momentDate: Moment, time?: string): string {
  if (isEmpty(momentDate)) return null;
  const date = momentDate.format(IsoDateFormat);

  if (isNullOrWhiteSpace(date)) return null;

  time = time ?? momentDate.format(IsoTimeFormat);
  time = _setDefaultTime(time);

  return `${date}T${time}`;
}

function _getIsoDateTimeFromDate(dateTime: Date, time?: string): string {
  if (!moment.isDate(dateTime)) return null;

  const momentDate = moment(dateTime);

  return _getIsoDateTime(momentDate, time);
}

function _convertToMomentTimeZone(dateTime: Date, timeZone: string, time: string): Moment {
  const isoDateTime = _getIsoDateTimeFromDate(dateTime, time);

  if (isNullOrWhiteSpace(isoDateTime)) return null;

  return moment.tz(isoDateTime, IsoDateTimeFormat, timeZone);
}

function _convertUtcToTimeZone(date: Moment, timeZone: string): Moment {
  if (isNil(date)) return null;

  return date.isUTC() ? date.clone().tz(timeZone) : date.clone();
}

function _convertToIsoDate(momentDate: Moment): string {
  if (isEmpty(momentDate)) return null;

  return momentDate.format(IsoDateTimeFormat);
}

export const formatTimetableDataWithTimezone = (
  data: ConferenceSchedulerSlot[],
  timezone: string
): ConferenceSchedulerSlot[] => {
  return (data || []).reduce((acc, el, dayIndex, self) => {
    if (!el) return acc;
    const currentStartTime = moment(el.start_time).tz(timezone);
    const currentEndTime = moment(el.end_time).tz(timezone);

    const currentStartDate = currentStartTime.format(DateTokenFormat.FullDay);
    const currentEndDate = currentEndTime.format(DateTokenFormat.FullDay);

    const nextStartTime = moment(self[dayIndex + 1]?.start_time).tz(timezone);
    const previousStartTime = moment(self[dayIndex - 1]?.start_time).tz(timezone);
    const previousEndTime = moment(self[dayIndex - 1]?.end_time).tz(timezone);

    // Merge two slots if not carried over to next day
    const newStartTime =
      previousEndTime?.format(TimeFormat.Standard) !== MidnightTimeFormat &&
      previousEndTime?.format(TimeFormat.Standard) === currentStartTime?.format(TimeFormat.Standard)
        ? previousStartTime
        : currentStartTime;

    // Break up the available slot into two if carries over to next day
    if (!currentStartTime?.isSame(currentEndTime, "day")) {
      const startOfDay = moment(el.end_time).tz(timezone).startOf("day").format(TimeFormat.Standard);

      acc.push({ start_time: newStartTime, end_time: moment(el.end_time).tz(timezone).startOf("day") });

      if (currentEndTime?.format(TimeFormat.Standard) !== startOfDay) {
        acc.push({ start_time: moment(el.end_time).tz(timezone).startOf("day"), end_time: currentEndTime });
      }
    } else {
      if (
        currentStartDate === currentEndDate &&
        currentEndDate === nextStartTime?.clone().format(DateTokenFormat.FullDay) &&
        currentEndTime?.format(TimeFormat.Standard) === nextStartTime?.format(TimeFormat.Standard)
      ) {
        return acc;
      }

      acc.push({ start_time: newStartTime, end_time: currentEndTime });
    }
    return acc;
  }, [] as ConferenceSchedulerSlot[]);
};

export const getFormattedDateForSummary = (
  isFirstSlot: boolean,
  current: ConferenceSchedulerSlot,
  previous: ConferenceSchedulerSlot,
  userTimeZone: string
): string => {
  const currentDay = current.start_time.tz(userTimeZone);
  const fullStartDate = `${currentDay.format(DateTokenFormat.FullWeekDay)} ${currentDay.format(
    DateTokenFormat.FullMonthDateYear
  )}`;
  if (isFirstSlot) return fullStartDate;

  return previous?.start_time.tz(userTimeZone).format(DateTokenFormat.FullDay) !== currentDay.format(DateTokenFormat.FullDay)
    ? fullStartDate
    : "";
};

export const getCalendarDaysFromScheduledSlots = (scheduler: ConferenceScheduler): Moment[] => {
  if (isEmpty(scheduler?.slots)) return [];

  const { slots } = scheduler;

  const startDate = slots[0].start_time?.clone().startOf("day");
  const endDate = slots[slots.length - 1].end_time?.clone().startOf("day");

  const days = [...momentExtended.range(startDate.clone(), endDate.clone()).by("days")];
  return days.filter((day) => {
    return slots.some((slot) => moment(slot.start_time).isSame(day, "day"));
  });
};

export const convertMinutesToHourWithDecimals = (minutes: number): number => {
  if (isNaN(minutes)) return 0;
  return minutes / 60;
};

export function isDateRangeConflicting(startDate: Moment, endDate: Moment, dates: ConferenceSchedulerSlot[]): boolean {
  for (const date of dates) {
    const eventRange = momentExtended.range(date.start_time, date.end_time);
    const slotRange = momentExtended.range(moment(startDate), moment(endDate));

    if (eventRange.overlaps(slotRange)) {
      return true;
    }
  }

  return false;
}

export function logTimeZoneDate(date: Moment, title = ""): void {
  if (isEmpty(date)) return;
  const formattedDate = date.format(DateFormat.TimezoneShortStandard);
  console.log(title, formattedDate);
}

export const getNextTimeInHourDecimals = (date: Moment, timeZone: string, interval = 5): number => {
  const now = moment(date).tz(timeZone);
  const currentHours = now.get("hours");
  const currentMinutes = now.get("minutes");
  return currentHours + (currentMinutes + interval - (currentMinutes % interval)) / 60;
};

export function getConferenceDisabledDays(conference: Conference): {
  after: Date;
  before: Date;
} {
  if (isEmpty(conference)) return null;
  const conferenceTimeZone = conference?.time_zone;
  const startDate = conference?.scheduler?.start_date ?? conference?.start_date;
  const endDate = conference?.scheduler?.end_date ?? conference?.end_date;

  const isStartDateBeforeToday = moment(startDate)
    .tz(conferenceTimeZone)
    .startOf("day")
    .isBefore(moment().tz(conferenceTimeZone).startOf("day"));

  const defaultFirstDate = isStartDateBeforeToday
    ? new Date()
    : new Date(moment(startDate).tz(conferenceTimeZone).startOf("day").format());

  const isDefaultAfterEndDate = moment(defaultFirstDate)
    .tz(conferenceTimeZone)
    .startOf("day")
    .isAfter(endDate.clone().tz(conferenceTimeZone).startOf("day"));

  return {
    before: defaultFirstDate,
    after: isDefaultAfterEndDate ? defaultFirstDate : new Date(moment(endDate).tz(conferenceTimeZone).endOf("day").format()),
  };
}
