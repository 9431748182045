import { Color, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import colorConvert from "color-convert";
import type { HaloProps } from "../halo/halo.definition";

export const BrandingDefaults = {
  primaryColor: Color.Black,
  secondaryColor: Color.Citrus,
  secondaryBackgroundColor: Color.Black,
  tertiaryColor: Color.DarkSlate,
  fontFamilyHeader: "'Open Sans', sans-serif",
  fontFamilyBase: "'Open Sans', sans-serif",
  fontSizeBase: "13px",
  fontColor: Color.White,
};

class ColorDef {
  hex: string;
  rgb: string;

  constructor(hex: string) {
    if (isNullOrWhiteSpace(hex)) return;
    this.hex = hex;

    const rgb = colorConvert.hex.rgb(hex);
    if (isEmpty(rgb)) return;
    this.rgb = rgb.join(", ");
  }
}

export class BrandingVariables {
  primary: string;
  secondary: string;
  secondaryBackground: string;
  secondaryBackgroundRgb: string;
  secondaryBackgroundContrast: string;
  tertiary: string;
  primaryContrast: string;
  secondaryContrast: string;
  tertiaryContrast: string;
  fontFamilyHeader: string;
  fontColorHeader: string;
  fontFamilyBase: string;
  fontSizeBase: string;
  fontColorBase: string;

  constructor(branding: BrandingBase) {
    this.primary = BrandingDefaults.primaryColor;
    this.secondary = BrandingDefaults.secondaryColor;

    const secondaryBackgroundDefault = new ColorDef(BrandingDefaults.secondaryBackgroundColor);
    const secondaryBackgroundContrastDefault = new ColorDef(this._getContrastColor(secondaryBackgroundDefault.hex, 100));
    this.secondaryBackground = secondaryBackgroundDefault.hex;
    this.secondaryBackgroundRgb = secondaryBackgroundDefault.rgb;
    this.secondaryBackgroundContrast = secondaryBackgroundContrastDefault.rgb;

    this.tertiary = BrandingDefaults.tertiaryColor;

    this.primaryContrast = this._getContrastColor(BrandingDefaults.primaryColor);
    this.secondaryContrast = this._getContrastColor(BrandingDefaults.secondaryColor);
    this.tertiaryContrast = this._getContrastColor(BrandingDefaults.tertiaryColor);

    this.fontFamilyHeader = BrandingDefaults.fontFamilyBase;
    this.fontColorHeader = BrandingDefaults.fontColor;

    this.fontFamilyBase = BrandingDefaults.fontFamilyHeader;
    this.fontSizeBase = BrandingDefaults.fontSizeBase;
    this.fontColorBase = BrandingDefaults.fontColor;

    if (isEmpty(branding)) return;

    const { color_primary, color_secondary, color_secondary_background, color_tertiary, font_header, font_body } = branding;
    this.primary = isNullOrWhiteSpace(color_primary) ? this.primary : color_primary;
    this.secondary = isNullOrWhiteSpace(color_secondary) ? this.secondary : color_secondary;

    const secondaryBackground = isNullOrWhiteSpace(color_secondary_background)
      ? secondaryBackgroundDefault
      : new ColorDef(color_secondary_background);
    const secondaryBackgroundContrast = new ColorDef(this._getContrastColor(secondaryBackground.hex, 100));
    this.secondaryBackground = secondaryBackground.hex;
    this.secondaryBackgroundRgb = secondaryBackground.rgb;
    this.secondaryBackgroundContrast = secondaryBackgroundContrast.rgb;

    this.tertiary = isNullOrWhiteSpace(color_tertiary) ? this.tertiary : color_tertiary;

    this.primaryContrast = isNullOrWhiteSpace(color_primary) ? this.primaryContrast : this._getContrastColor(color_primary);
    this.secondaryContrast = isNullOrWhiteSpace(color_secondary)
      ? this.secondaryContrast
      : this._getContrastColor(color_secondary);
    this.tertiaryContrast = isNullOrWhiteSpace(color_tertiary)
      ? this.tertiaryContrast
      : this._getContrastColor(color_tertiary);

    this.fontFamilyHeader = isNullOrWhiteSpace(font_header?.name) ? this.fontFamilyHeader : font_header.name;
    this.fontColorHeader = isNullOrWhiteSpace(font_body?.color) ? this.fontColorHeader : font_header.color;

    this.fontFamilyBase = isNullOrWhiteSpace(font_body?.name) ? this.fontFamilyBase : font_body.name;
    this.fontSizeBase = isNullOrWhiteSpace(font_body?.base_size) ? this.fontSizeBase : font_body.base_size;
    this.fontColorBase = isNullOrWhiteSpace(font_body?.color) ? this.fontColorBase : font_body.color;
  }

  private _getContrastColor(hexColor: string, threshold = 130): string {
    const color = hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
    const isShorthand = color.length === 3;

    let hRed = color.substring(0, 2);
    let hGreen = color.substring(2, 4);
    let hBlue = color.substring(4, 6);

    if (isShorthand) {
      const sRed = color.substring(0, 1);
      const sGreen = color.substring(1, 2);
      const sBlue = color.substring(2, 3);

      hRed = sRed + sRed;
      hGreen = sGreen + sGreen;
      hBlue = sBlue + sBlue;
    }

    const colorBrightness = (parseInt(hRed, 16) * 299 + parseInt(hGreen, 16) * 587 + parseInt(hBlue, 16) * 114) / 1000;

    return colorBrightness >= threshold ? Color.Black : Color.White;
  }
}

export interface BrandingProps {
  branding?: BrandingBase;
  haloProps?: HaloProps;
}

export interface BrandingBase {
  color_primary?: string;
  color_secondary?: string;
  color_secondary_background?: string;
  color_tertiary?: string;
  text_login_1?: string;
  text_login_2?: string;
  font_header?: CustomFont;
  font_body?: CustomFont;
}

interface CustomFont {
  name: string;
  path: string;
  base_size?: string;
  color: string;
}
