import { BaseComponentProps } from "@q4/nimbus-ui";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../../../../services/presentation/presentation.model";

export enum SchedulerMetricBarClassName {
  Base = "scheduler-metric-bar",
  Unscheduled = "scheduler-metric-bar_unscheduled",
  Slots = "scheduler-metric-bar_slots",
}

export interface SchedulerMetricBarProps extends BaseComponentProps {
  currentAttendee: AttendeeViewModel;
  currentCorporate: CorporateProfile;
  currentRequestType: number;
  meetingRequests?: Meeting[];
  presentations: Presentation[];
  requests: CorporateProfile[] | AttendeeViewModel[];
  step: number;
  timezone: string;
}
