import { Entity } from "../../../definitions/entity.definition";
import type { Registrant, RegistrantServicePutResponse } from "../../../services/admin/registrant/registrant.model";
import RegistrantService from "../../../services/admin/registrant/registrant.service";
import type { Conference } from "../../../services/conference/conference.model";
import type { AttendeesHookModel } from "../../useAttendees/useAttendees.definition";
import type { ServiceHookPropsBase, ServiceHookModel } from "../../useService/useService.definition";
import { SpeakersHookModel } from "../../useSpeakers/useSpeaker.definition";
import { CorporateProfileHookModel } from "../useCorporateProfile/useCorporateProfile.definition";

export const RegistrantEntityName = Entity.Registrant;

export interface RegistrantHookProps extends ServiceHookPropsBase<Registrant> {
  conferenceId?: Conference["_id"]; // Will auto fetch by id if provided
  useAttendees?: AttendeesHookModel;
  useCorporateProfile?: CorporateProfileHookModel;
  useSpeakers?: SpeakersHookModel;
}

export interface RegistrantHookModel
  extends Omit<ServiceHookModel<Registrant, RegistrantService, RegistrantServicePutResponse>, "fetch" | "fetchById"> {
  fetchByConferenceId: (_id: Conference["_id"]) => Promise<Registrant[]>;
}
