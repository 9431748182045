/* eslint-disable @typescript-eslint/ban-ts-comment */
import "./jsonForm.component.scss";
import {
  Button,
  ButtonTheme,
  ButtonType,
  Field,
  isEmpty,
  isNullOrWhiteSpace,
  RadioButton as NuiRadio,
  Textbox as NuiTextbox,
  Textarea as NuiTextarea,
  convertBlobToUrl,
  isNil,
  SelectPreset,
  getClassName,
} from "@q4/nimbus-ui";
import Form, { IChangeEvent } from "@rjsf/core";
import type { FieldTemplateProps, FormValidation, ISubmitEvent } from "@rjsf/core";
import { camelCase, capitalize } from "lodash";
import React, { memo, useCallback, useMemo, useState, useRef } from "react";
import { RegistrantViewModel } from "../../services/admin/registrant/registrant.model";
import { ErrorHandlerFields, ErrorHandlerMessage } from "../../services/errorHandler/errorHandler.definition";
import ErrorHandlerService from "../../services/errorHandler/errorHandler.service";
import { htmlParse } from "../../utils";
import NuiCustomSelect from "../customSelect/customSelect.component";
import FilePreview from "../filePreviewer/filePreviewer.component";
import CheckboxGroup from "./fields/checkboxGroup/checkboxGroupField.component";
import {
  JsonFormStringFieldMaxLength,
  JsonFormErrorId,
  JsonFormIdModel,
  JsonFormLabels,
  CustomJsonFieldSchemaProps,
} from "./jsonForm.definition";
import type { JsonInputFieldType, JsonFieldProps, JsonFormProps } from "./jsonForm.definition";
import CustomLayoutField from "./layout/layout.component";
import BooleanCheckboxWidget from "./widgets/booleanCheckbox/booleanCheckbox.component";
import BooleanRadioWidget from "./widgets/booleanRadio/booleanRadio.component";
import ImagePreviewWidget from "./widgets/imagePreview.component";
import TextareaWidget from "./widgets/textarea.component";

/**
 * Build with https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/form-props/
 */

const JsonForm = (props: JsonFormProps<RegistrantViewModel>): JSX.Element => {
  const {
    id,
    data,
    formProps,
    globalError,
    uiSchema: jsonSchema,
    schema,
    setFiles,
    onStep,
    onSubmit,
    onChange,
    errorHandlerFields,
    extraErrors,
  } = props;

  const idModel = useMemo(() => new JsonFormIdModel(id), [id]);

  const refFormData = useRef<RegistrantViewModel>(new RegistrantViewModel({}));

  const [loading, setLoading] = useState(false);

  // #region Custom UI
  const CustomFieldTemplate = useCallback((props: FieldTemplateProps) => {
    const { children, rawErrors, uiSchema, required, schema } = props;

    const errorMessage = (rawErrors || [])[rawErrors?.length - 1];
    const error = new ErrorHandlerMessage<number>(JsonFormErrorId, null, errorMessage, !isNullOrWhiteSpace(errorMessage));

    const className = getClassName("json-form_field", [
      { trueClassName: `json-form_field-${uiSchema["ui:widget"]}`, condition: !!uiSchema["ui:widget"] },
      { trueClassName: `${uiSchema["ui:options"]?.className}`, condition: !!uiSchema["ui:options"]?.className },
    ]);

    return (
      <Field
        className={className}
        label={!!(schema as CustomJsonFieldSchemaProps).htmlTitle ? htmlParse(schema.title) : schema.title}
        error={error}
        required={required}
      >
        {children}
      </Field>
    );
  }, []);

  const StringField = useCallback(
    (fieldProps: JsonFieldProps<RegistrantViewModel>) => {
      const { disabled, formData, readonly, onChange, schema: fieldSchema, uiSchema, idSchema } = fieldProps;
      const {
        id: fieldId,
        title,
        enum: enumOptions,
        options, // used for questions that allow choice outside of specified values, i.e. CustomSelect
        default: defaultValue,
        maxLength = JsonFormStringFieldMaxLength,
        placeholder,
        clearable,
      } = fieldSchema;

      const value = !isNil(formData) ? formData.toString() : !isNil(defaultValue) ? defaultValue.toString() : null;

      const jsonEnum = (enumOptions || options || []).map((x) => x.toString());

      function handleChange(updated: string) {
        onChange(updated);
      }

      function handleFileChange(file: string | File) {
        if (isEmpty(file)) {
          onChange("");
          return;
        }

        if (typeof file === "string") {
          onChange(file);
          return;
        }

        const { name } = file;
        const url = convertBlobToUrl(file);

        if (setFiles instanceof Function) {
          setFiles((current) => {
            return {
              ...current,
              [url]: {
                data: file,
                title: name,
              },
            };
          });
        }

        onChange(url);
      }

      function handleRadioChange(_checked: boolean, value: string) {
        onChange(value);
      }

      const fieldComponent: JsonInputFieldType = {
        default: (
          <NuiTextbox
            id={fieldId || camelCase(idSchema.$id)}
            value={value}
            disabled={disabled || readonly}
            onChange={handleChange}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        ),
        nuiRadio: (
          <>
            {jsonEnum.map((radio, i) => {
              const radioId = isNullOrWhiteSpace(fieldId)
                ? camelCase(`${title}${radio}${i}`)
                : `${fieldId}${capitalize(radio)}`;
              return (
                <NuiRadio
                  key={radioId}
                  id={radioId}
                  name={title}
                  label={radio}
                  value={radio}
                  onChange={handleRadioChange}
                  checked={radio === value}
                  inline={true}
                />
              );
            })}
          </>
        ),
        nuiSelect: (
          <NuiCustomSelect
            id={fieldId}
            preset={SelectPreset.Autocomplete}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            options={jsonEnum}
            addNew={false}
            disabled={disabled || readonly}
            isClearable={clearable}
          />
        ),
        nuiCustomSelect: (
          <NuiCustomSelect
            id={fieldId}
            preset={SelectPreset.Autocomplete}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            options={jsonEnum}
            addNew={true}
            disabled={disabled || readonly}
            isClearable={clearable}
            maxLength={maxLength}
          />
        ),
        nuiTextarea: (
          <NuiTextarea
            id={fieldId || camelCase(idSchema.$id)}
            value={value}
            onChange={handleChange}
            rows={8}
            maxLength={maxLength}
            readOnly={readonly}
            placeholder={placeholder}
          />
        ),
        nuiFilePreview: (
          <FilePreview
            id={fieldId || camelCase(idSchema.$id)}
            fileUrl={value}
            onChange={handleFileChange}
            disabled={disabled || readonly}
            dropzoneProps={{
              accept: [
                "application/pdf",
                "application/vnd.oasis.opendocument.presentation",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.template",
                "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
              ],
            }}
          />
        ),
        nuiImagePreview: (
          <FilePreview
            id={fieldId || camelCase(idSchema.$id)}
            fileUrl={value}
            onChange={handleFileChange}
            dropzoneProps={{ accept: ["image/*"] }}
            disabled={disabled || readonly}
          />
        ),
        nuiImagePreviewValidated: (
          <FilePreview
            id={fieldId || camelCase(idSchema.$id)}
            className="tighten-validation-text"
            renderValidationText={true}
            disabled={disabled || readonly}
            onChange={handleFileChange}
            fileUrl={value}
            dropzoneProps={{
              maxSize: 2621440,
              accept: ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp"],
            }}
          />
        ),
      };

      return fieldComponent[uiSchema["ui:widget"] as keyof JsonInputFieldType] ?? fieldComponent.default;
    },
    [setFiles]
  );

  const nuiFields = useMemo(() => ({ StringField, checkboxGroup: CheckboxGroup, layout: CustomLayoutField }), [StringField]);

  const nuiWidgets = useMemo(
    () => ({
      nuiBooleanCheckbox: BooleanCheckboxWidget,
      nuiRadio: BooleanRadioWidget,
      nuiTextarea: TextareaWidget,
      nuiImagePreview: ImagePreviewWidget,
    }),
    []
  );
  // #endregion

  const createErrorHandlerServices = useCallback(
    (errorHandlerFields: ErrorHandlerFields[], formData: RegistrantViewModel): ErrorHandlerFields[] => {
      const errorHandlerServiceFields: ErrorHandlerFields[] = [];

      errorHandlerFields.forEach((field) => {
        if (isEmpty(field)) return;

        if (isEmpty(field.parent)) {
          const dependencyValid = (field.dependency || []).every((x) => !!formData[x as keyof RegistrantViewModel]);
          if (!dependencyValid) return;

          const service = new ErrorHandlerService<number, string>(field.fields);
          errorHandlerServiceFields.push({ ...field, service });
          return;
        }

        let currentObject: typeof formData | keyof typeof formData;
        field.parent.forEach((objectKey) => {
          // @ts-ignore
          currentObject = isEmpty(currentObject) ? formData[objectKey] : currentObject[objectKey];

          if (isEmpty(currentObject)) return;

          if (Array.isArray(currentObject)) {
            const service: ErrorHandlerService<number, string>[] = [];

            currentObject.forEach((obj) => {
              const dependencyValid = (field.dependency || []).every((x) => {
                return !!obj[x as keyof typeof obj];
              });

              if (!dependencyValid) {
                service.push(null);
                return;
              }

              service.push(new ErrorHandlerService<number, string>(field.fields));
            });
            errorHandlerServiceFields.push({ ...field, service });
            return;
          }

          if (!(field.dependency || []).every((x) => !!formData[x as keyof typeof formData])) return;
          errorHandlerServiceFields.push({ ...field, service: new ErrorHandlerService<number, string>(field.fields) });
        });
      });

      return errorHandlerServiceFields;
    },
    []
  );

  const checkValidation = useCallback(
    (serviceFields: ErrorHandlerFields[], formData: RegistrantViewModel, validation: FormValidation): FormValidation => {
      serviceFields.forEach((x) => {
        if (isEmpty(x.service)) return;

        if (isEmpty(x.parent)) {
          const object = formData ?? {};

          if (!Array.isArray(x.service)) {
            // @ts-ignore
            x.service.checkForErrors(JsonFormErrorId, object);
            const errors = x.service.getAll();

            errors.forEach((error) => {
              const { prop, message, visible } = error;
              if (!visible) return;

              const field = validation[prop];
              if (isEmpty(field)) return;
              field.addError(message);
            });

            x.service.clear();
          }

          return;
        }

        let parentObject = {};
        x.parent.forEach((objectKey, h) => {
          // @ts-ignore
          const currentObject = isEmpty(parentObject) ? formData[objectKey] : parentObject[objectKey];

          if (Array.isArray(parentObject)) {
            parentObject.forEach((arrayObj, i) => {
              if (Array.isArray(x.service)) {
                if (isEmpty(x.service[i])) return;

                x.service[i].checkForErrors(JsonFormErrorId, arrayObj[objectKey]);
                const errors = x.service[i].getAll();

                errors.forEach((error) => {
                  const { prop, message, visible } = error;
                  if (!visible) return;

                  const parentKey = x.parent[h - 1];
                  // @ts-ignore
                  const field = validation[parentKey][i][objectKey][prop];
                  if (isEmpty(field)) return;
                  field.addError(message);
                });

                x.service[i].clear();
              }
            });
          }

          if (isEmpty(currentObject)) return;

          if (Array.isArray(currentObject)) {
            // @ts-ignore
            currentObject.forEach((obj, i) => {
              if (Array.isArray(x.service)) {
                if (isEmpty(x.service[i])) return;

                x.service[i].checkForErrors(JsonFormErrorId, obj);
                const errors = x.service[i].getAll();

                errors.forEach((error) => {
                  const { prop, message, visible } = error;
                  if (!visible) return;

                  // @ts-ignore
                  const field = validation[objectKey][i][prop];
                  if (isEmpty(field)) return;
                  field.addError(message);
                });

                x.service[i].clear();
              }
            });

            parentObject = currentObject;
            return;
          }

          if (!Array.isArray(x.service)) {
            x.service.checkForErrors(JsonFormErrorId, currentObject);
            const errors = x.service.getAll();

            errors.forEach((error) => {
              const { prop, message, visible } = error;
              if (!visible) return;

              // @ts-ignore
              const field = validation[objectKey][prop];
              if (isEmpty(field)) return;
              field.addError(message);
            });

            x.service.clear();
          }
        });
      });

      return validation;
    },
    []
  );

  const validate = useCallback(
    (formData: RegistrantViewModel, validation: FormValidation): FormValidation => {
      if (isEmpty(errorHandlerFields)) return validation;

      const errorHandlerServiceFields = createErrorHandlerServices(errorHandlerFields, formData);
      return checkValidation(errorHandlerServiceFields, formData, validation);
    },
    [checkValidation, createErrorHandlerServices, errorHandlerFields]
  );

  const onFormChange = useCallback(
    (changeEvent: IChangeEvent<RegistrantViewModel>) => {
      const { formData: updatedFormData } = changeEvent;
      refFormData.current = { ...updatedFormData } as RegistrantViewModel;

      typeof onChange === "function" && onChange(changeEvent);
    },
    [refFormData, onChange]
  );

  const handleStepBack = useCallback(() => {
    onStep(false, refFormData.current);
  }, [onStep]);

  const handleSubmit = useCallback(
    async (response: ISubmitEvent<RegistrantViewModel>): Promise<void> => {
      if (!(onSubmit instanceof Function)) {
        return;
      }
      try {
        setLoading(true);
        await onSubmit(response);
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  return (
    <Form
      id={idModel.id}
      className="json-form"
      fields={nuiFields}
      extraErrors={extraErrors}
      widgets={nuiWidgets}
      FieldTemplate={CustomFieldTemplate}
      schema={schema}
      formData={data}
      uiSchema={jsonSchema}
      validate={validate}
      onSubmit={handleSubmit}
      onChange={onFormChange}
      {...formProps}
    >
      <div className="json-form_navigation">
        {!isEmpty(onStep) && onStep instanceof Function && (
          <Button
            id={idModel.previousStep?.id}
            className={"json-form_back-button"}
            label={JsonFormLabels.PrevStep}
            onClick={handleStepBack}
            theme={ButtonTheme.White}
            loading={loading}
          />
        )}
        <Button
          id={idModel.nextStep?.id}
          label={JsonFormLabels.NextStep}
          type={ButtonType.Submit}
          theme={ButtonTheme.Rain}
          loading={loading}
        />
        {!isNullOrWhiteSpace(globalError) && (
          <div className="nui-field_error">
            <i className="nui-field_error-indicator ni-warning-4pt"></i>
            <span className="nui-field_error-message">{globalError}</span>
          </div>
        )}
      </div>
    </Form>
  );
};

export default memo(JsonForm);
