import {
  AnchorIdModel,
  BaseComponentProps,
  CheckboxIdModel,
  ChipsIdModel,
  ComboBoxIdModel,
  ErrorModel,
  FileUploaderIdModel,
  FormFieldProps,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextareaIdModel,
  TextboxIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import type { Registrant } from "../../services/admin/registrant/registrant.model";
import type { Conference } from "../../services/conference/conference.model";
import { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import { QuestionGroup } from "../../services/questionGroup/questionGroup.model";
import { QuestionsFormIdModel } from "../questionsForm/questionsForm.definition";
import { CorporateAttendeeModalIdModel } from "./components/corporateAttendeeModal/corporateAttendeeModal.definition";

export const CreateNewCompanySuffix = " (create new)";

export enum RegistrantFormClassName {
  Base = "registrant-form",
  QuestionsHeader = "registrant-form_questions-header",
}

export enum AttendeeEditMode {
  New = "new",
  Edit = "edit",
}

export interface RegistrantFormProps extends BaseComponentProps {
  registrant: Registrant;
  codes: string[];
  companies?: string[];
  conference: Conference;
  corporateProfiles?: CorporateProfile[];
  questionGroups?: QuestionGroup[];
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  onRegistrantUpdate: (data: Partial<Registrant>) => void;
}

export type RegistrantField = {
  firstName: FormFieldProps;
  lastName: FormFieldProps;
  email: FormFieldProps;
  title: FormFieldProps;
  company: FormFieldProps;
  investorType: FormFieldProps;
  phone: FormFieldProps;
  secondaryContact: FormFieldProps;
  secondaryFirstName: FormFieldProps;
  secondaryLastName: FormFieldProps;
  secondaryEmail: FormFieldProps;
  secondaryTitle: FormFieldProps;
  secondaryPhone: FormFieldProps;
  secondaryReceiveEmails: FormFieldProps;
  corporateName: FormFieldProps;
  corporateType: FormFieldProps;
  corporateExchange: FormFieldProps;
  corporateTicker: FormFieldProps;
  corporateLogo: FormFieldProps;
  secondaryLogo: FormFieldProps;
  corporateDescription: FormFieldProps;
  corporateWebsite: FormFieldProps;
  corporateAttendees: FormFieldProps;
  corporatePrimaryContact: FormFieldProps;
  hostMeetings: FormFieldProps;
  hostPresentations: FormFieldProps;
  representative: FormFieldProps;
  guestAttendeeType: FormFieldProps;
};

export class RegistrantFormIdModel extends IdModelBase {
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  company: SelectIdModel;
  title: TextboxIdModel;
  phone: TextboxIdModel;
  email: TextboxIdModel;
  status: SelectIdModel;
  type: SelectIdModel;

  investorType: SelectIdModel;
  secondaryContact: CheckboxIdModel;
  secondaryFirstName: TextboxIdModel;
  secondaryLastName: TextboxIdModel;
  secondaryEmail: TextboxIdModel;
  secondaryTitle: TextboxIdModel;
  secondaryPhone: TextboxIdModel;
  secondaryReceiveEmails: CheckboxIdModel;

  corporateName: TextboxIdModel;
  corporateDescription: TextareaIdModel;
  corporateWebsite: TextboxIdModel;
  corporateType: SelectIdModel;
  corporatePrimaryContact: SelectIdModel;
  corporateExchange: TextboxIdModel;
  corporateTicker: TextboxIdModel;
  corporateLogo: FileUploaderIdModel;
  corporateSecondaryLogo: FileUploaderIdModel;
  corporateAttendees: ChipsIdModel;

  hostMeetings: CheckboxIdModel;
  hostPresentation: CheckboxIdModel;

  primaryFirstName: TextboxIdModel;
  primaryLastName: TextboxIdModel;
  primaryEmail: TextboxIdModel;
  primaryTitle: TextboxIdModel;
  primaryPhone: TextboxIdModel;

  addAttendee: AnchorIdModel;
  attendees: ComboBoxIdModel;
  attendeeModal: CorporateAttendeeModalIdModel;

  representative: TextboxIdModel;
  guestAttendeeType: SelectIdModel;

  questionsForm: QuestionsFormIdModel;
  questionsHeader: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.company = new SelectIdModel(`${this.id}CompanySelect`);

    this.corporateName = new TextboxIdModel(`${this.id}CorporateNameTextbox`);
    this.corporateType = new SelectIdModel(`${this.id}CorporateTypeSelect`);
    this.corporateDescription = new TextareaIdModel(`${this.id}CorporateDescriptionTextarea`);
    this.corporateLogo = new FileUploaderIdModel(`${this.id}CorporateLogoUploader`);
    this.corporateSecondaryLogo = new FileUploaderIdModel(`${this.id}CorporateSecondaryLogoUploader`);
    this.corporateAttendees = new ChipsIdModel(`${this.id}CorporateAttendeesChips`);

    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.phone = new TextboxIdModel(`${this.id}PhoneTextbox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.status = new SelectIdModel(`${this.id}StatusSelect`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);

    this.investorType = new SelectIdModel(`${this.id}InvestorTypeSelect`);
    this.secondaryContact = new CheckboxIdModel(`${this.id}SecondaryContactCheckbox`);
    this.secondaryFirstName = new TextboxIdModel(`${this.id}SecondaryFirstNameTextbox`);
    this.secondaryLastName = new TextboxIdModel(`${this.id}SecondaryLastNameTextbox`);
    this.secondaryEmail = new TextboxIdModel(`${this.id}SecondaryEmailTextbox`);
    this.secondaryTitle = new TextboxIdModel(`${this.id}SecondaryTitleTextbox`);
    this.secondaryPhone = new TextboxIdModel(`${this.id}SecondaryPhoneTextbox`);
    this.secondaryReceiveEmails = new CheckboxIdModel(`${this.id}SecondaryReceiveEmailsCheckbox`);

    this.primaryFirstName = new TextboxIdModel(`${this.id}PrimaryFirstNameTextbox`);
    this.primaryLastName = new TextboxIdModel(`${this.id}PrimaryLastNameTextbox`);
    this.primaryEmail = new TextboxIdModel(`${this.id}PrimaryEmailTextbox`);
    this.primaryTitle = new TextboxIdModel(`${this.id}PrimaryTitleTextbox`);
    this.primaryPhone = new TextboxIdModel(`${this.id}PrimaryPhoneTextbox`);

    this.hostMeetings = new CheckboxIdModel(`${this.id}HostMeetingsCheckbox`);
    this.hostPresentation = new CheckboxIdModel(`${this.id}HostPresentationCheckbox`);

    this.addAttendee = new AnchorIdModel(`${this.id}AddAttendeeAnchor`);
    this.attendees = new ComboBoxIdModel(`${this.id}AttendeeComboBox`);
    this.attendeeModal = new CorporateAttendeeModalIdModel(`${this.id}AttendeeModal`);

    this.representative = new TextboxIdModel(`${this.id}RepresentativeTextbox`);
    this.guestAttendeeType = new SelectIdModel(`${this.id}GuestAttendeeTypeSelect`);

    this.questionsForm = new QuestionsFormIdModel(`${this.id}QuestionForm`);
    this.questionsHeader = new TextIdModel(`${this.id}QuestionsHeader`);
  }
}
