import { Form, Textbox, ToolbarTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import { Entity } from "../../../../../../definitions/entity.definition";
import { Track } from "../../../../../../services/track/track.model";
import { TrackEditClassName, TracksClassName, TracksIdModel } from "./tracks.definition";
import type { TrackProps } from "./tracks.definition";

const Tracks = (props: TrackProps): JSX.Element => {
  const { id, conference, useTracks, user } = props;

  const idModel = useMemo(() => new TracksIdModel(id), [id]);

  const { current: currentTrack, setCurrent: setCurrentTrack } = useTracks;

  const tableProps = {
    columnDefs: [
      {
        field: "name",
        headerName: "Name",
        minWidth: 160,
        flex: 1,
      },
    ],
  };

  const editForm = (
    <div className={TrackEditClassName.Base}>
      <Form
        fields={[
          {
            key: "track-edit_name",
            width: "1-of-1",
            label: "Name",
            children: <Textbox id={idModel.name?.id} value={currentTrack?.name} onChange={handleNameChange} />,
          },
        ]}
      />
    </div>
  );

  function handleNameChange(name: string): void {
    setCurrentTrack(
      new Track({
        ...currentTrack,
        name,
      })
    );
  }

  return (
    <div id={idModel.id} className={TracksClassName.Base}>
      <EntityTable
        id={idModel.entityTable?.id}
        entity={Entity.Track}
        useService={useTracks}
        tableProps={tableProps}
        editForm={editForm}
        entityModel={Track}
        icon="q4i-activity-2pt"
        requiredEntityKeys={["name"]}
        saveRequestParams={{ _conference: conference }}
        toolbarProps={{ theme: ToolbarTheme.Q4Blue }}
        user={user}
        conference={conference}
      />
    </div>
  );
};

export default memo(Tracks);
