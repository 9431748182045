import { ButtonIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Conference } from "../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../services/presentation/presentation.model";
import { LobbyHookModel } from "../../hooks/useLobby/useLobby.definition";

export interface PersonalAgendaButtonProps extends BaseComponentProps {
  lobby?: Conference["lobby"];
  presentationId?: Presentation["_id"];
  hasPresentation?: boolean;
  loading?: boolean;
  onAgendaAdd?: LobbyHookModel["handleAgendaAdd"];
  onAgendaRemove?: LobbyHookModel["handleAgendaRemove"];
}

export enum PersonalAgendaButtonClassName {
  Base = "personal-agenda-button",
  BaseWithDisabledModifier = "personal-agenda-button--disabled",
  BaseWithRemoveModifier = "personal-agenda-button--remove",
}

export { ButtonIdModel as PersonalAgendaButtonIdModel };
