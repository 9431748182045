import { isEmpty } from "@q4/nimbus-ui";
import { isNil, uniq, set } from "lodash";
import moment, { Moment } from "moment";
import { JsonFile, JsonFileRecord } from "../../../components/jsonForm/jsonForm.definition";
import { DefaultTimeZone } from "../../../const";
import { RegistrantViewModel, RegistrationFile } from "../../../services/admin/registrant/registrant.model";
import { ApiResponse } from "../../../services/api/api.definition";
import { AttendeeType } from "../../../services/attendee/attendee.model";
import type { Conference, RegisterDateRange } from "../../../services/conference/conference.model";
import ParallelService, { ParallelRequest } from "../../../services/parallel.service";
import PublicFileUploadService, { FileUploadResponse } from "../../../services/public/fileUpload.service";
import { ConferenceEditState } from "../../admin/conferences/edit/conferenceEdit.definition";
import { RegistrationDefaults } from "./registration.definition";

export function isMeetingRegistrationOpen(meetingDeadline: Conference["investor_deadlines"]["meeting_request"]): boolean {
  if (isEmpty(meetingDeadline) || !meetingDeadline?.isValid()) return true;
  return meetingDeadline.isSameOrAfter(moment().utc());
}

export function getRegistrationAttendeeTypes(
  attendeeTypes?: Conference["registration"]["attendee_types"]
): Conference["registration"]["attendee_types"] {
  if (isEmpty(attendeeTypes)) return RegistrationDefaults.AllTypes;

  return uniq(attendeeTypes);
}

export function checkDateRange(rangeStart: Moment, rangeEnd: Moment, timezone: string, date?: Moment): boolean {
  const timeRange = "days";
  const dateMinMax = "[]";
  if (!rangeEnd || !rangeStart) return false;

  const currentDate = date ? moment(date) : moment();
  return currentDate
    .tz(timezone)
    .isBetween(moment(rangeStart).tz(timezone), moment(rangeEnd).tz(timezone), timeRange, dateMinMax);
}

export function isAttendeeRegistrationAllowed(
  conference: Conference | ConferenceEditState,
  attendeeType: AttendeeType,
  registrationPage?: boolean
): boolean {
  const timezone =
    typeof conference?.time_zone === "string" && conference?.time_zone ? conference?.time_zone : DefaultTimeZone;

  const dateRange = getAttendeeTypeDateRange(conference, attendeeType);
  if (isRangeNil(dateRange)) {
    return false;
  }

  const { open_date: conferenceOpenDate, close_date: conferenceCloseDate } = conference || {};
  const { start_date: startDate, end_date: endDate } = dateRange;

  if (registrationPage) {
    return checkDateRange(startDate, endDate, timezone);
  }
  return (
    checkDateRange(conferenceOpenDate, conferenceCloseDate, timezone, startDate) &&
    checkDateRange(conferenceOpenDate, conferenceCloseDate, timezone, endDate)
  );
}

export function isDateBetweenConferenceDates(date: Moment, conference: ConferenceEditState | Conference): boolean {
  if (isEmpty(date) || isEmpty(conference)) return false;
  return date?.isSameOrAfter(conference?.open_date) && date?.isSameOrBefore(conference?.close_date);
}

function getAttendeeTypeDateRange(
  conference: Conference | ConferenceEditState,
  attendeeType: AttendeeType
): RegisterDateRange {
  const dateRange = {
    [AttendeeType.Investor]: conference?.investor_register,
    [AttendeeType.Corporate]: conference?.corporate_register,
    [AttendeeType.Internal]: conference?.internal_register,
    [AttendeeType.Guest]: conference?.general_register,
  };

  return dateRange[attendeeType] ?? ({} as RegisterDateRange);
}

function isRangeNil(range: RegisterDateRange): boolean {
  return isNil(range?.start_date) || isNil(range?.end_date);
}

export function isInvestorAvailabilityChangesOpen(conference: Conference): boolean {
  if (!conference?.investor_deadlines?.availability_changes) return true;
  return conference?.investor_deadlines?.availability_changes.isAfter(moment());
}

export function isCorporateAvailabilityOpen(conference: Conference): boolean {
  if (!conference?.corporate_deadlines?.meeting) return true;
  return conference?.corporate_deadlines?.meeting.isAfter(moment());
}

export function isInvestorMeetingRequestChangesOpen(conference: Conference): boolean {
  if (!conference?.investor_deadlines?.meeting_request) return true;
  return conference?.investor_deadlines?.meeting_request.isAfter(moment());
}

export function isRegisterDateRangeClosed(date: RegisterDateRange): boolean {
  if (isEmpty(date) || isEmpty(date?.start_date) || isEmpty(date?.end_date)) return true;

  const now = moment().utc();
  const start = date.start_date;
  const end = date.end_date;

  return now.isBefore(start) || now.isAfter(end);
}

export function isRegistrationClosed(conference: Conference): boolean {
  const isCorporateClosed = isRegisterDateRangeClosed(conference?.corporate_register);
  const isInvestorClosed = isRegisterDateRangeClosed(conference?.investor_register);
  const isInternalClosed = isRegisterDateRangeClosed(conference?.internal_register);
  const isGeneralClosed = isRegisterDateRangeClosed(conference?.general_register);

  return isCorporateClosed && isInvestorClosed && isInternalClosed && isGeneralClosed;
}

export function validateMeetingParticipation(corporateRegistrant: RegistrantViewModel): boolean {
  const corporateAttendees = corporateRegistrant.corporate_attendees ?? [];
  return (
    (!corporateRegistrant.host_small_meetings ||
      !!corporateAttendees.find((attendee) => {
        return attendee.participate_in_meetings;
      })) ??
    false
  );
}

// TODO: Needs improvement
export async function uploadRegistrantFiles(
  registrant: RegistrantViewModel,
  fileState: JsonFileRecord
): Promise<ApiResponse<FileUploadResponse>[]> {
  const requests: ParallelRequest<ApiResponse<FileUploadResponse>>[] = [];
  const files = new RegistrationFile(registrant);
  const publicUpload = new PublicFileUploadService();

  const { secondary_logo_image, logo_image, corporate_attendees } = files;

  function pushRequest(key: string, file: JsonFile, signed = false) {
    if (isEmpty(file)) return;

    requests.push((): Promise<ApiResponse<FileUploadResponse>> => {
      return publicUpload.post(file.data, file.title, signed).then((response) => {
        set(registrant, key, response?.data?.url);
        return response;
      });
    });
  }

  if (!isEmpty(logo_image)) pushRequest("logo_image", fileState[logo_image]);
  if (!isEmpty(secondary_logo_image)) pushRequest("secondary_logo_image", fileState[secondary_logo_image]);
  corporate_attendees.forEach((attendee, i) =>
    pushRequest(`corporate_attendees[${i}].speaker_info.image_profile`, fileState[attendee?.speaker_info?.image_profile])
  );

  return new ParallelService().limit(requests);
}
