import {
  BaseComponentProps,
  CheckboxIdModel,
  DatePickerIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
} from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import { AccordionIdModel } from "../../../../../../components/accordion/accordion.definition";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface ConferenceRegistrationEditProps extends BaseComponentProps {
  conference: ConferenceEditState;
  setRegistrationError: Dispatch<SetStateAction<boolean>>;
  onChange: (
    state: Partial<
      Pick<
        ConferenceEditState,
        | "auto_register"
        | "corporate_register"
        | "guest_other_types_enabled"
        | "registration"
        | "display_login_link"
        | "investor_deadlines"
        | "general_register"
        | "internal_register"
      >
    >
  ) => void;
}

export const ERRORICON = "ni-warning-4pt";

export enum ConferenceRegistrationDateKeys {
  CorporateRegister = "corporate_register",
  SpeakerDeadline = "speaker_deadline",
  PresentationDeadline = "presentation",
  InvestorMeetingRequestDeadline = "investor_meeting_request_deadline",
  InvestorRegister = "investor_register",
  CorporateMeetingDeadline = "corporate_meeting_deadline",
  AvailabilityChangesDeadline = "availability_changes",
  GeneralRegister = "general_register",
  InternalRegister = "internal_register",
}

export enum ConferenceRegistrationEditClassName {
  Base = "conference-registration-edit",
  SectionError = "conference-registration-edit_section-error",

  AutoRegister = "conference-registration-edit_auto-register",
  AutoRegisterEnabled = "conference-registration-edit_auto-register-enabled",
  AutoRegisterRange = "conference-registration-edit_auto-register-range",

  CorporateRegister = "conference-registration-edit_corporate-register",
  CorporateRegisterRange = "conference-registration-edit_corporate-register-range",

  InvestorRegister = "conference-registration-edit_investor-register",
  InvestorRegisterRange = "conference-registration-edit_investor-register-range",

  GuestRegister = "conference-registration-edit_guest-register",
  GuestRegisterRange = "conference-registration-edit_guest-register-range",
  GuestRegisterSubtype = "conference-registration-edit_guest-register-subtype",

  InternalRegister = "conference-registration-edit_internal-register",
  InternalRegisterRange = "conference-registration-edit_internal-register-range",
}

export type ConferenceRegistrationCollapsibleSections = {
  autoRegisterCollapsed: boolean;
  corporateRegistrationCollapsed: boolean;
  investorRegistrationCollapsed: boolean;
  generalRegistrationCollapsed: boolean;
  internalRegistrationCollapsed: boolean;
};

export const ConferenceRegistrationCollapsibleDefault: ConferenceRegistrationCollapsibleSections = {
  autoRegisterCollapsed: true,
  corporateRegistrationCollapsed: true,
  investorRegistrationCollapsed: true,
  generalRegistrationCollapsed: true,
  internalRegistrationCollapsed: true,
};

export class ConferenceRegistrationEditIdModel extends IdModelBase {
  autoRegister: FormIdModel;
  autoRegisterEnabled: CheckboxIdModel;
  autoRegisterRange: DatePickerIdModel;

  corporateRegister: FormIdModel;
  corporateRegisterRange: DatePickerIdModel;
  corporateMeetingDeadline: DatePickerIdModel;
  speakerDeadline: DatePickerIdModel;
  presentationDeadline: DatePickerIdModel;

  generalRegister: FormIdModel;
  generalRegisterRange: DatePickerIdModel;
  otherSubtypes: CheckboxIdModel;
  displayLoginLink: CheckboxIdModel;

  internalRegister: FormIdModel;
  internalRegisterRange: DatePickerIdModel;

  investorRegister: FormIdModel;
  investorRegisterRange: DatePickerIdModel;
  availabilityChangesDeadline: DatePickerIdModel;
  investorMeetingDeadline: DatePickerIdModel;

  corporateAccordion: AccordionIdModel;
  investorAccordion: AccordionIdModel;
  generalAccordion: AccordionIdModel;
  autoRegisterAccordion: AccordionIdModel;
  internalAccordion: AccordionIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.otherSubtypes = new CheckboxIdModel(`${this.id}OtherTypeCheckbox`);
    this.displayLoginLink = new CheckboxIdModel(`${this.id}DisplayLoginLinkCheckbox`);

    this.autoRegisterEnabled = new CheckboxIdModel(`${this.id}AutoRegisterEnabledCheckbox`);
    this.autoRegister = new FormIdModel(`${this.id}AutoRegisterForm`);
    this.autoRegisterRange = new DatePickerIdModel(`${this.id}AutoRegisterRangeDatepicker`);

    this.corporateRegister = new FormIdModel(`${this.id}CorporateRegisterForm`);
    this.corporateRegisterRange = new DatePickerIdModel(`${this.id}CorporateRegisterRangeDatepicker`);
    this.speakerDeadline = new DatePickerIdModel(`${this.id}SpeakerDeadlineDatePicker`);
    this.presentationDeadline = new DatePickerIdModel(`${this.id}presentationDatePicker`);
    this.corporateMeetingDeadline = new DatePickerIdModel(`${this.id}CorporateMeetingDeadlineDatePicker`);

    this.investorRegister = new FormIdModel(`${this.id}InvestorRegisterForm`);
    this.investorRegisterRange = new DatePickerIdModel(`${this.id}InvestorRegisterRangeDatepicker`);
    this.investorMeetingDeadline = new DatePickerIdModel(`${this.id}InvestorMeetingDeadlineDatepicker`);
    this.availabilityChangesDeadline = new DatePickerIdModel(`${this.id}AvailabilityChangesDeadlineDatepicker`);

    this.generalRegister = new FormIdModel(`${this.id}GeneralRegisterForm`);
    this.generalRegisterRange = new DatePickerIdModel(`${this.id}GeneralRegisterRangeDatepicker`);

    this.internalRegister = new FormIdModel(`${this.id}InternalRegisterForm`);
    this.internalRegisterRange = new DatePickerIdModel(`${this.id}InternalRegisterRangeDatepicker`);

    this.corporateAccordion = new AccordionIdModel(`${this.id}CorporateRegisterAccordion`);
    this.investorAccordion = new AccordionIdModel(`${this.id}InvestorRegisterAccordion`);
    this.generalAccordion = new AccordionIdModel(`${this.id}GeneralRegisterAccordion`);
    this.autoRegisterAccordion = new AccordionIdModel(`${this.id}AutoRegisterAccordion`);
    this.internalAccordion = new AccordionIdModel(`${this.id}InternalRegisterAccordion`);
  }
}
