import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useState } from "react";
import type { SessionBase, SessionEventType } from "../../../services/session/session.model";
import type { SessionLink } from "../../../services/user/sessionLink/sessionLink.definition";
import SessionLinkService from "../../../services/user/sessionLink/sessionLink.service";
import type { SessionLinkHookModel } from "./useSessionLink.definition";

export const useSessionLink = (): SessionLinkHookModel => {
  const [loading, setLoading] = useState(false);

  const fetchBy = useCallback((id: SessionBase["_id"], type: SessionEventType): Promise<SessionLink["url"]> => {
    setLoading(true);
    const sessionLinkService = new SessionLinkService();

    return sessionLinkService
      .get(id, type)
      .then((response) => {
        if (!response?.success) {
          const message = `Failed to find ${type} link.`;
          const notificationService = new NotificationService();
          notificationService.error(message);
          return null;
        }

        return response.data.url;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, fetchBy };
};
