import { BaseComponentProps, ButtonListId, isNullOrWhiteSpace, ToolbarProps } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../../../components/entityTable/entityTable.definition";
import { RegistrationFormIdModel } from "../../../../../../../../components/registrationForm/registrationForm.definition";
import type { QuestionGroupHookModel } from "../../../../../../../../hooks/admin/useQuestionGroups/useQuestionGroups.definition";
import type { Auth0Token } from "../../../../../../../../services/auth0/auth0.model";
import type { Conference } from "../../../../../../../../services/conference/conference.model";

export interface RegistrationFormTableProps extends BaseComponentProps {
  conference: Conference;
  useQuestionGroups: QuestionGroupHookModel;
  user: Auth0Token;
  toolbarProps: Omit<ToolbarProps, "autoRowProps">;
  test?: boolean;
}

export class RegistrationFormTableIdModel extends EntityTableIdModel {
  registrationForm: RegistrationFormIdModel;
  copyButtonItem: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.registrationForm = new RegistrationFormIdModel(`${this.id}RegistrationForm`);
    this.copyButtonItem = new ButtonListId(`${this.id}CopyButtonList`);
  }
}
