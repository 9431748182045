import "./speakerForm.component.scss";
import {
  BadgeSize,
  BadgeTheme,
  FileUploader,
  Form,
  getClassName,
  InputType,
  isNullOrWhiteSpace,
  Keyline,
  Origin,
  Select,
  SelectPreset,
  Textarea,
  Textbox,
  TooltipTheme,
} from "@q4/nimbus-ui";
import { set } from "lodash";
import React, { memo, useCallback, useMemo, useState } from "react";
import { CreateNewSuffix } from "../../const";
import { useFileUpload } from "../../hooks";
import { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Speaker } from "../../services/speaker/speaker.model";
import InfoBubble from "../infoBubble/infoBubble.component";
import { SpeakerFormClassName, SpeakerFormIdModel, SpeakerFormProps } from "./speakerForm.definition";

const SpeakerForm = (props: SpeakerFormProps): JSX.Element => {
  const { id, className, attendees, companies, speaker, onUpdate } = props;

  const [companyInputValue, setCompanyInputValue] = useState();

  const handleFileUpload = useFileUpload();

  const idModel = useMemo(() => new SpeakerFormIdModel(id), [id]);

  const baseClassName = useMemo(
    () => getClassName(SpeakerFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const attendeeProfile = useMemo(() => {
    const profile = new AttendeeViewModel({ _id: speaker?._attendee });
    return (attendees || []).find((x) => x._id === profile._id);
  }, [attendees, speaker?._attendee]);

  const companySelectOptions = useMemo(getCompanySelectOptions, [companies, companyInputValue]);
  function getCompanySelectOptions(): string[] {
    const companyOptions = companies ?? [];
    if (isNullOrWhiteSpace(companyInputValue)) return companyOptions;

    return [`${companyInputValue}${CreateNewSuffix}`, ...companyOptions];
  }

  // #region Handlers
  const getTextboxHandler = useCallback(
    (key: keyof Speaker | string) => {
      return function (value: string): void {
        onUpdate(set({ ...speaker }, key, value));
      };
    },
    [onUpdate, speaker]
  );

  const handleCompanyChange = useCallback(
    (company: string): void => {
      if (company?.includes(CreateNewSuffix)) {
        company = company?.replace(CreateNewSuffix, "");
      }

      onUpdate({ company });
    },
    [onUpdate]
  );

  const handleAttendeeProfileChange = useCallback(
    (attendee: AttendeeViewModel): void => {
      onUpdate({
        _attendee: attendee?._id,
      });
    },
    [onUpdate]
  );

  const handleOrderChange = useCallback(
    (value: string): void => {
      const order = Number(value);

      if (isNaN(order)) return;

      onUpdate({ order });
    },
    [onUpdate]
  );
  // #endregion

  return (
    <div id={idModel.id} className={baseClassName}>
      <Form
        fields={[
          {
            key: "speaker-attendee",
            width: "1-of-1",
            smallWidth: "1-of-1",
            className: SpeakerFormClassName.CustomLabel,
            label: (
              <InfoBubble
                badgeProps={{
                  icon: "ni-warning-4pt",
                  size: BadgeSize.Small,
                  theme: BadgeTheme.LightGrey,
                }}
                tooltipProps={{
                  label: "Select an attendee profile to add the speaker link to their agenda",
                  position: Origin.Bottom,
                  theme: TooltipTheme.Slate,
                }}
              >
                Attendee Profile
              </InfoBubble>
            ),
            children: (
              <Select
                id={idModel.attendee?.id}
                preset={SelectPreset.Autocomplete}
                placeholder="Select an attendee profile"
                value={attendeeProfile}
                valueKey="_id"
                labelKey="friendly_name"
                options={attendees}
                isClearable
                backspaceRemovesValue
                onChange={handleAttendeeProfileChange}
              />
            ),
          },
          {
            key: "speaker-first-name",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "First Name",
            children: (
              <Textbox id={idModel.firstName?.id} value={speaker?.first_name} onChange={getTextboxHandler("first_name")} />
            ),
          },
          {
            key: "speaker-last-name",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Last Name",
            required: true,
            children: (
              <Textbox id={idModel.lastName?.id} value={speaker?.last_name} onChange={getTextboxHandler("last_name")} />
            ),
          },
          {
            key: "speaker-company",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Company",
            children: (
              <Select
                id={idModel.company?.id}
                preset={SelectPreset.Autosuggest}
                placeholder="Type a company"
                value={speaker?.company}
                inputValue={companyInputValue}
                options={companySelectOptions}
                isClearable
                backspaceRemovesValue
                onInputChange={setCompanyInputValue}
                onChange={handleCompanyChange}
              />
            ),
          },
          {
            key: "speaker-title",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Title",
            children: <Textbox id={idModel.title?.id} value={speaker?.title} onChange={getTextboxHandler("title")} />,
          },
          {
            key: "speaker-photo",
            width: "2-of-5",
            smallWidth: "1-of-1",
            label: "Photo",
            margin: false,
            children: (
              <FileUploader
                dropzoneProps={{ accept: ["image/*"] }}
                fileApi={handleFileUpload}
                fileUrl={speaker?.image_profile}
                onChange={getTextboxHandler("image_profile")}
                showPreview={true}
              />
            ),
          },
          {
            key: "speaker-bio",
            width: "3-of-5",
            smallWidth: "1-of-1",
            label: "Bio",
            children: <Textarea id={idModel.bio?.id} value={speaker?.bio} onChange={getTextboxHandler("bio")} rows={8} />,
          },
        ]}
      />
      <Form
        fields={[
          {
            key: "speaker-email",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Email",
            children: (
              <Textbox
                id={idModel.email?.id}
                value={speaker?.contact?.email}
                onChange={getTextboxHandler("contact.email")}
              />
            ),
          },
          {
            key: "speaker-twitter",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "Twitter Url",
            children: (
              <Textbox
                id={idModel.twitter?.id}
                value={speaker?.contact?.twitter}
                onChange={getTextboxHandler("contact.twitter")}
              />
            ),
          },
          {
            key: "speaker-linkedin",
            width: "1-of-3",
            smallWidth: "1-of-1",
            label: "LinkedIn Url",
            children: (
              <Textbox
                id={idModel.linkedin?.id}
                value={speaker?.contact?.linkedin}
                onChange={getTextboxHandler("contact.linkedin")}
              />
            ),
          },
        ]}
      />
      <Keyline margin />
      <Form
        fields={[
          {
            key: "speaker-order",
            width: "1-of-4",
            smallWidth: "1-of-1",
            label: "Order",
            children: (
              <Textbox
                id={idModel.order?.id}
                inputType={InputType.Number}
                min={1}
                value={speaker?.order}
                onChange={handleOrderChange}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(SpeakerForm);
