import "./fallback.component.scss";
import { Anchor, AnchorTheme, ButtonTheme, PlaceholderContent } from "@q4/nimbus-ui";
import React, { memo } from "react";
import { SupportEmail } from "../../../const";
import FallbackImage from "./fallback-error.svg";

const Fallback = (): JSX.Element => {
  return (
    <div className="fallback">
      <PlaceholderContent
        title="Oops! Something went wrong."
        image={FallbackImage}
        subtitle={
          <p>
            We can&#39;t seem to load the page you&#39;re looking for.
            <br />
            If you need further assistance, please contact{" "}
            <Anchor theme={AnchorTheme.Rain} url={`mailto: ${SupportEmail}`}>
              {SupportEmail}
            </Anchor>
            .
          </p>
        }
        actions={[
          {
            label: "GO BACK",
            theme: ButtonTheme.Rain,
            onClick: (): void => {
              window.location.href = window.document.referrer;
            },
          },
        ]}
      />
      <div className="fallback_logo q4i-logo" />
    </div>
  );
};

export default memo(Fallback);
