import "./navigation.component.scss";
import { Button, ButtonTheme, getClassName, Ghostable } from "@q4/nimbus-ui";
import React, { useState, memo, useMemo } from "react";
import type { ReactNode } from "react";
import { Link, withRouter } from "react-router-dom";
import Version from "../../../../components/version/version.component";
import config from "../../../../config/config";
import { useHelpCentre } from "../../../../hooks/useHelpCentre/useHelpCentre.hook";
import { NavigationClassName, NavigationIdModel } from "./navigation.definition";
import type { NavigationProps } from "./navigation.definition";

export const Navigation = (props: NavigationProps): JSX.Element => {
  const { id, role, className, logo, collapsedLogo, routes, collapseToggleIcon, footerActions } = props;

  const [collapsed, setCollapsed] = useState(false);

  const idModel = useMemo(() => new NavigationIdModel(id), [id]);
  const baseClassName = useMemo(getBaseClassName, [className, collapsed]);

  const { handleHelpCentreClick } = useHelpCentre();

  function handleCollapseToggle(): void {
    setCollapsed(!collapsed);
  }

  function getBaseClassName(): string {
    return getClassName(NavigationClassName.Base, [
      { condition: !!className, trueClassName: className },
      {
        condition: collapsed,
        trueClassName: NavigationClassName.BaseWithCollapsedModifier,
      },
    ]);
  }

  function routeActive(currentPath: string, path: string, children: string[]): boolean {
    const paths = [path, ...(children || [])];
    return paths.reduce((active, x) => {
      const testPattern = x.replace(/\/:\w+/gi, ".+");
      const hasParams = testPattern !== x;
      const pathMatch = hasParams ? new RegExp(testPattern, "i").test(currentPath) : x === currentPath;
      return active || pathMatch;
    }, false as boolean);
  }

  return (
    <div id={idModel.id} className={baseClassName}>
      <div className={NavigationClassName.Logo}>{collapsed ? (collapsedLogo ? collapsedLogo : logo) : logo}</div>
      <ul className={NavigationClassName.List}>
        {(routes || []).reduce((routeItems, route) => {
          const { path, icon, label, target, children, minRequiredRole } = route;
          if (role > minRequiredRole) return routeItems;

          const currentPath = window.location.pathname;

          const routeClassName = getClassName(NavigationClassName.Route, [
            {
              condition: routeActive(currentPath, path, children),
              trueClassName: NavigationClassName.RouteWithActiveModifier,
            },
          ]);

          return routeItems.concat(
            <li key={path} className={NavigationClassName.Item}>
              <Link to={path} className={routeClassName} target={target}>
                <i className={`${NavigationClassName.Icon} ${icon}`} />
                <span className={NavigationClassName.Label}>{label}</span>
              </Link>
            </li>
          );
        }, [])}
        <li key="helpcentre" className={NavigationClassName.Item}>
          <Button
            id={idModel.helpCentreButton.id}
            theme={ButtonTheme.Transparent}
            label={
              <>
                <i className={`${NavigationClassName.Icon} ${"q4i-question-mark-2pt"}`} />
                <span className={NavigationClassName.Label}>{"Help Center"}</span>
              </>
            }
            className={NavigationClassName.Route}
            onClick={handleHelpCentreClick}
          />
        </li>
      </ul>
      <Version id={idModel.version?.id} config={config} visible={!collapsed} />
      {footerActions && (
        <Ghostable ghosted={collapsed}>
          <footer className={NavigationClassName.Footer}>
            {footerActions.map(
              (buttonProps, index): ReactNode => (
                <Button key={`navigation_footer-action--${index}`} {...buttonProps} />
              )
            )}
          </footer>
        </Ghostable>
      )}
      <div className={NavigationClassName.CollapseToggle}>
        <i className={collapseToggleIcon} onClick={handleCollapseToggle} />
      </div>
    </div>
  );
};

Navigation.defaultProps = {
  collapseToggleIcon: "q4i-caret-left-4pt",
};

export default withRouter(memo(Navigation));
