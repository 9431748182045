import {
  AnchorIdModel,
  BaseComponentProps,
  ButtonIdModel,
  ButtonListId,
  ChipsIdModel,
  ChipsItemProps,
  ComboBoxIdModel,
  DatePickerIdModel,
  IdModelBase,
  isEmpty,
  isNullOrWhiteSpace,
  MessageIdModel,
  PopoverIdModel,
  SelectIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import moment from "moment";
import { DefaultTimeZone } from "../../const";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import { Conference } from "../../services/conference/conference.model";
import type { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../services/meeting/meeting.model";
import { Presentation } from "../../services/presentation/presentation.model";
import { AttendeeModalIdModel } from "../attendee/modal/attendeeModal.definition";
import { SessionCustomVendorFormIdModel } from "../sessionCustomVendorForm/sessionCustomVendorForm.definition";

export const CreateNewLocationSuffix = " (Create New)";
export const MeetingLabelMaxLength = 100;

export enum MeetingFormClassName {
  Base = "meeting-form",
  SuggestedTimes = "meeting-form_suggested-time",
  SuggestedTimesLabel = "meeting-form_suggested-time-label",
  SuggestedTimesPopoverMenu = "meeting-form_suggested-time-popover",
  CorporateAttendees = "meeting-form_chips--corporate",
  InvestorAttendees = "meeting-form_chips--investor",
  OtherAttendees = "meeting-form_chips--other",
  TooltipField = "meeting-form_tooltip-field",
}

export interface MeetingFormProps extends BaseComponentProps {
  meeting: Meeting;
  meetings: Meeting[];
  presentations: Presentation[];
  codes: string[];
  companies: AttendeeViewModel["company"][];
  conference: Conference;
  attendees: AttendeeViewModel[];
  corporateProfiles: CorporateProfile[];
  onUpdate: (data: Partial<Meeting>) => void;
  fullscreenLayout: boolean;
  fetchConferenceAttendees?: () => void;
}

export enum SuggestedTimeResetIndicator {
  MinSuggestionMinutesRange = 15,
  MaxSuggestionMinutesRange = 45,
}

export enum SuggestedTimeRange {
  SuggestionInterval = 30,
  MaxSuggestions = 10,
  MeetingGap = 10,
}

export class MeetingFormChips {
  corporate: ChipsItemProps<AttendeeViewModel>[];
  investor: ChipsItemProps<AttendeeViewModel>[];
  other: ChipsItemProps<AttendeeViewModel>[];

  constructor() {
    this.corporate = [];
    this.investor = [];
    this.other = [];
  }
}

export class MeetingFormIdModel extends IdModelBase {
  startDate: DatePickerIdModel;
  startTime: SelectIdModel;
  endTime: SelectIdModel;
  meetingRoom: SelectIdModel;
  overrideLabel: TextboxIdModel;
  code: TextboxIdModel;
  addAttendee: AnchorIdModel;
  attendees: ComboBoxIdModel;
  attendeeModal: AttendeeModalIdModel;
  suggestedTimes: ButtonIdModel;
  noSuggestedTimes: ButtonIdModel;
  suggestedTimesList: PopoverIdModel;
  messageModal: MessageIdModel;
  suggestedTimeOptions: ButtonListId;
  customVendorForm: SessionCustomVendorFormIdModel;

  otherChips: ChipsIdModel;
  corporateChips: ChipsIdModel;
  investorChips: ChipsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.startDate = new DatePickerIdModel(`${this.id}StartDateDatePicker`);
    this.startTime = new SelectIdModel(`${this.id}StartTimeSelect`);
    this.endTime = new SelectIdModel(`${this.id}EndTimeSelect`);
    this.meetingRoom = new SelectIdModel(`${this.id}MeetingLocationSelect`);
    this.overrideLabel = new TextboxIdModel(`${this.id}OverrideLabelTextbox`);
    this.code = new TextboxIdModel(`${this.id}CodeTextbox`);
    this.addAttendee = new AnchorIdModel(`${this.id}AddAttendeeAnchor`);
    this.attendees = new ComboBoxIdModel(`${this.id}AttendeeComboBox`);
    this.attendeeModal = new AttendeeModalIdModel(`${this.id}AttendeeModal`);
    this.suggestedTimes = new ButtonIdModel(`${this.id}SuggestedTimesButton`);
    this.suggestedTimesList = new PopoverIdModel(`${this.id}SuggestedTimesListPopover`);
    this.suggestedTimeOptions = new ButtonListId(this.suggestedTimesList.id, "Time-", "-Button");
    this.noSuggestedTimes = new ButtonIdModel(`${this.id}NoSuggestedTimesButton`);
    this.messageModal = new MessageIdModel(`${this.id}MessageModal`);
    this.customVendorForm = new SessionCustomVendorFormIdModel(`${this.id}CustomVendorForm`);
    this.otherChips = new ChipsIdModel(`${this.id}OtherChips`);
    this.corporateChips = new ChipsIdModel(`${this.id}CorporateChips`);
    this.investorChips = new ChipsIdModel(`${this.id}InvestorChips`);
  }
}

export class MeetingEditState extends Meeting {
  constructor(meeting?: Meeting, sourceConference?: Conference) {
    super(meeting);
    if (isEmpty(meeting)) {
      const conference = sourceConference ?? new Conference(meeting?._conference);
      const roundingDuration = +moment.duration(15, "minutes");

      const now = moment();

      const slots = conference?.scheduler?.slots;

      const startTime = +moment.min(
        moment(slots?.[slots.length - 1]?.end_time),
        moment.max(slots?.[0]?.start_time ?? now, now)
      );

      const roundedStartDate = moment(Math.ceil(startTime / roundingDuration) * roundingDuration).tz(
        conference?.time_zone ?? DefaultTimeZone
      );
      const roundedEndDate = roundedStartDate.clone().add(1, "hours");

      this.start_date = roundedStartDate;
      this.end_date = roundedEndDate;
      this._conference = conference;
    }
  }
}
