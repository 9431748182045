import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextareaIdModel } from "@q4/nimbus-ui";
import { LoginConfig } from "../../../../../../services/conference/conference.model";
import { ConferenceEditState } from "../../conferenceEdit.definition";

export interface LoginMessageFormProps extends BaseComponentProps {
  conference: ConferenceEditState;
  onChange: (loginMessageConfig: LoginConfig) => void;
}
export class LoginMessageFormIdModel extends IdModelBase {
  message: TextareaIdModel;
  errorMessage: TextareaIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.message = new TextareaIdModel(`${this.id}LoginMessageTextarea`);
    this.errorMessage = new TextareaIdModel(`${this.id}LoginMessageErrorTextarea`);
  }
}

export enum LoginMessageFormClassName {
  Base = "base",
}
