import { FileUploaderIdModel, IdModelBase, isNullOrWhiteSpace, RadioButtonIdModel, TextboxIdModel } from "@q4/nimbus-ui";
import { JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import { BooleanRadioOptions } from "../../../../../components/jsonForm/widgets/booleanRadio/booleanRadio.definition";
import { YesNoOptions } from "../../../../../hooks/nimbus/useTable/useTable.definition";

export const corporateDetailsLabels = {
  companyParticipation: {
    header: "Company Participation",
    hostMeetings: "Does the company want to participate in 1:1 & Group Meetings?",
    hostPresentations: "Does the company want to participate as speakers in a presentation?",
  },
};

export const corporateDetailsErrorMessages = {
  companyError: {
    companyNameError: "Corporate name is required",
    mainLogoError: "Logo image is required",
    descriptionError: "Description is required",
  },
  publicCompanyError: {
    tickerSymbolError: "Ticker symbol is required",
    excahgeError: "Exchange is required",
  },
};

export class CorporateDetailsIdModel extends IdModelBase {
  jsonForm: JsonFormIdModel;
  companyName: TextboxIdModel;
  mainLogo: FileUploaderIdModel;
  whiteLogo: TextboxIdModel;
  description: TextboxIdModel;
  websiteUrl: TextboxIdModel;

  companyType: string;
  publicCompany: RadioButtonIdModel;
  privateCompany: RadioButtonIdModel;

  stockTicker: TextboxIdModel;
  exchange: TextboxIdModel;

  isHostMeetings: string;
  isHostMeetingsYes: RadioButtonIdModel;
  isHostMeetingsNo: RadioButtonIdModel;

  isHostPresentations: string;
  isHostPresentationsYes: RadioButtonIdModel;
  isHostPresentationsNo: RadioButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);
    this.companyName = new TextboxIdModel(`${this.id}CompanyNameTextbox`);

    this.mainLogo = new FileUploaderIdModel(`${this.id}MainLogoTextbox`);
    this.whiteLogo = new TextboxIdModel(`${this.id}WhiteLogoTextbox`);
    this.description = new TextboxIdModel(`${this.id}DescriptionTextbox`);
    this.websiteUrl = new TextboxIdModel(`${this.id}WebsiteUrlTextbox`);

    const yesOption = BooleanRadioOptions.find((x) => x.label === YesNoOptions.Yes);
    const noOption = BooleanRadioOptions.find((x) => x.label === YesNoOptions.No);

    this.companyType = `${this.id}CompanyType`;
    this.publicCompany = new RadioButtonIdModel(`${this.id}AddSecondaryRadioButton${yesOption.label}`);
    this.privateCompany = new RadioButtonIdModel(`${this.id}AddSecondaryRadioButton${noOption.label}`);

    this.stockTicker = new TextboxIdModel(`${this.id}StockTickerTextbox`);
    this.exchange = new TextboxIdModel(`${this.id}ExchangeTextbox`);

    this.isHostMeetings = `${this.id}HostMeetings`;
    this.isHostMeetingsYes = new RadioButtonIdModel(`${this.id}HostMeetings${yesOption.label}`);
    this.isHostMeetingsNo = new RadioButtonIdModel(`${this.id}HostMeetings${noOption.label}`);

    this.isHostPresentations = `${this.id}HostPresentations`;
    this.isHostPresentationsYes = new RadioButtonIdModel(`${this.id}HostPresentations${yesOption.label}`);
    this.isHostPresentationsNo = new RadioButtonIdModel(`${this.id}HostPresentations${noOption.label}`);
  }
}
