import { KeyboardKey } from "@q4/nimbus-ui";
import type { MouseEvent, KeyboardEvent } from "react";

export function getAccessibilityKeyPressHandler(
  callback: (event: MouseEvent | KeyboardEvent) => void
): (event: KeyboardEvent) => void {
  return function (event: KeyboardEvent) {
    if (event?.key === KeyboardKey.Enter || event?.key === KeyboardKey.Space) {
      event.preventDefault();
      callback(event);
    }
  };
}
