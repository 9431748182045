import { convertStringToEnum, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AttendeeType } from "../../../services/attendee/attendee.model";
import { RegistrationAvailabilityIdModel } from "./components/availability/availability.definition";
import { ConfirmationIdModel } from "./components/confirmation/confirmation.definition";
import { CorporateAttendeesIdModel } from "./components/corporateAttendees/corporateAttendees.definition";
import { CorporateDetailsIdModel } from "./components/corporateDetails/corporateDetails.definition";
import { CorporateSummaryIdModel } from "./components/corporateSummary/corporateSummary.definition";
import { CustomQuestionsIdModel } from "./components/customQuestions/customQuestions.definition";
import { InvestorPersonalInformationIdModel } from "./components/investorPersonalInformation/investorPersonalInformation.definition";
import { InvestorSummaryIdModel } from "./components/investorSummary/investorSummary.definition";
import { MeetingRequestsIdModel } from "./components/meetingRequests/meetingRequests.definition";
import { PersonalInformationIdModel } from "./components/personalInformation/personalInformation.definition";
import { RegistrationHeaderIdModel } from "./components/registrationHeader/registrationHeader.definition";
import { RegistrationTypeIdModel } from "./components/registrationType/registrationType.definition";
import { RegistrationSummaryIdModel } from "./components/summary/summary.definition";

export const AutoRegistrationAttendeeTypes = [AttendeeType.Internal, AttendeeType.Guest];

export const emailUnavailableError =
  "This email is already in use. Please use a different email or contact conference coordinator";

export const emailDuplicateLocalError = "This email is already provided on this form. Please use a different email.";

export const emailInvalidError = "This email is invalid.";

export const RegistrationDefaults = {
  AllTypes: Object.values(AttendeeType).map((x) => convertStringToEnum(AttendeeType, x)),
  GuestOnly: [AttendeeType.Guest],
};

export enum RegistrationStep {
  Type,
  Sections,
  Confirmation,
  Closed,
}

export enum RegistrationStepLabel {
  ContactInformation = "Company Information",
  Attendees = "Attendees",
  Details = "Details",
  Availability = "Availability",
  Summary = "Summary",
  PersonalInformation = "Personal Information",
  MeetingRequests = "Meeting Requests",
  CustomQuestions = "Additional Information",
}

class ViewIdModel extends IdModelBase {
  registrationType: RegistrationTypeIdModel;
  investorPersonalInformation: InvestorPersonalInformationIdModel;
  personalInformation: PersonalInformationIdModel;
  availability: RegistrationAvailabilityIdModel;
  meetingRequests: MeetingRequestsIdModel;
  customQuestions: CustomQuestionsIdModel;
  investorSummary: InvestorSummaryIdModel;
  corporateSummary: CorporateSummaryIdModel;
  summary: RegistrationSummaryIdModel;
  confirmation: ConfirmationIdModel;
  corporateDetails: CorporateDetailsIdModel;
  corporateAttendees: CorporateAttendeesIdModel;
  registrationHeader: RegistrationHeaderIdModel;

  attendeesTab: string;
  contactInformationTab: string;
  detailsTab: string;
  personalInformationTab: string;
  availabilityTab: string;
  meetingRequestsTab: string;
  customQuestionsTab: string;
  summaryTab: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.registrationType = new RegistrationTypeIdModel(`${this.id}RegistrationType`);
    this.registrationHeader = new RegistrationHeaderIdModel(`${this.id}RegistrationHeader`);

    this.attendeesTab = `${this.id}AttendeesTab`;
    this.contactInformationTab = `${this.id}PersonalInformationTab`;
    this.personalInformationTab = `${this.id}PersonalInformationTab`;
    this.availabilityTab = `${this.id}AvailabilityTab`;
    this.meetingRequestsTab = `${this.id}MeetingRequestsTab`;
    this.customQuestionsTab = `${this.id}CustomQuestionsTab`;
    this.summaryTab = `${this.id}SummaryTab`;

    this.corporateDetails = new CorporateDetailsIdModel(`${this.id}CorporateDetails`);
    this.corporateAttendees = new CorporateAttendeesIdModel(`${this.id}CorporateAttendees`);
    this.investorPersonalInformation = new InvestorPersonalInformationIdModel(`${this.id}InvestorPersonalInformation`);
    this.personalInformation = new PersonalInformationIdModel(`${this.id}PersonalInformation`);
    this.availability = new RegistrationAvailabilityIdModel(`${this.id}Availability`);
    this.meetingRequests = new MeetingRequestsIdModel(`${this.id}MeetingRequests`);
    this.customQuestions = new CustomQuestionsIdModel(`${this.id}CustomQuestions`);
    this.investorSummary = new InvestorSummaryIdModel(`${this.id}InvestorSummary`);
    this.corporateSummary = new CorporateSummaryIdModel(`${this.id}CorporateSummary`);
    this.summary = new RegistrationSummaryIdModel(`${this.id}Summary`);
    this.confirmation = new ConfirmationIdModel(`${this.id}Confirmation`);
  }
}

const PageId = "PublicRegistration";
export const RegistrationIdModel = new ViewIdModel(PageId);
