import { IdModelBase, isNullOrWhiteSpace, RadioButtonIdModel, SelectIdModel, TextboxIdModel } from "@q4/nimbus-ui";
import { JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import { BooleanRadioOptions } from "../../../../../components/jsonForm/widgets/booleanRadio/booleanRadio.definition";
import { YesNoOptions } from "../../../../../hooks/nimbus/useTable/useTable.definition";

export class InvestorPersonalInformationIdModel extends IdModelBase {
  jsonForm: JsonFormIdModel;
  firstName: TextboxIdModel;
  lastName: TextboxIdModel;
  title: TextboxIdModel;
  phone: TextboxIdModel;
  email: TextboxIdModel;
  company: TextboxIdModel;
  investorType: SelectIdModel;
  referral: TextboxIdModel;

  addSecondary: string;
  addSecondaryYes: RadioButtonIdModel;
  addSecondaryNo: RadioButtonIdModel;

  secondaryFirstName: TextboxIdModel;
  secondaryLastName: TextboxIdModel;
  secondaryTitle: TextboxIdModel;
  secondaryPhone: TextboxIdModel;
  secondaryEmail: TextboxIdModel;

  sendEmail: string;
  sendEmailYes: RadioButtonIdModel;
  sendEmailNo: RadioButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);
    this.firstName = new TextboxIdModel(`${this.id}FirstNameTextbox`);
    this.lastName = new TextboxIdModel(`${this.id}LastNameTextbox`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.phone = new TextboxIdModel(`${this.id}PhoneTextbox`);
    this.email = new TextboxIdModel(`${this.id}EmailTextbox`);
    this.company = new TextboxIdModel(`${this.id}CompanyTextbox`);
    this.investorType = new SelectIdModel(`${this.id}InvestorTypeSelect`);
    this.referral = new TextboxIdModel(`${this.id}ReferralTextbox`);

    const yesOption = BooleanRadioOptions.find((x) => x.label === YesNoOptions.Yes);
    const noOption = BooleanRadioOptions.find((x) => x.label === YesNoOptions.No);

    this.addSecondary = `${this.id}AddSecondaryRadioButton`;
    this.addSecondaryYes = new RadioButtonIdModel(`${this.id}AddSecondaryRadioButton${yesOption.label}`);
    this.addSecondaryNo = new RadioButtonIdModel(`${this.id}AddSecondaryRadioButton${noOption.label}`);

    this.secondaryFirstName = new TextboxIdModel(`${this.id}SecondaryFirstNameTextbox`);
    this.secondaryLastName = new TextboxIdModel(`${this.id}SecondaryLastNameTextbox`);
    this.secondaryTitle = new TextboxIdModel(`${this.id}SecondaryTitleTextbox`);
    this.secondaryPhone = new TextboxIdModel(`${this.id}SecondaryPhoneTextbox`);
    this.secondaryEmail = new TextboxIdModel(`${this.id}SecondaryEmailTextbox`);

    this.sendEmail = `${this.id}SendEmailRadioButton`;
    this.sendEmailYes = new RadioButtonIdModel(`${this.id}SendEmailRadioButton${yesOption.label}`);
    this.sendEmailNo = new RadioButtonIdModel(`${this.id}SendEmailRadioButton${noOption.label}`);
  }
}
