import type { BrandingBase } from "../../../components/companyBranding/companyBranding.definition";
import { EntityBase } from "../../../definitions/entity.definition";
import { sanitizeHtml } from "../../../utils";
import { PayloadBase } from "../../serviceBase/payloadBase.model";

export class Company extends EntityBase {
  name: string;
  website?: string;
  url_suffix: string;
  custom_domain?: string;
  custom_path?: string;
  branding: CompanyBranding;

  constructor(company: Partial<Company> | string) {
    super(company);
    if (typeof company === "string") return;

    Object.assign(this, company);
  }
}

export interface CompanyBranding extends BrandingBase {
  logo?: string;
  background?: string;
  text_login_1?: string;
  text_login_2?: string;
}

type CompanyPayloadBase = Omit<Company, "_id">;
export class CompanyPayload extends PayloadBase<CompanyPayloadBase> implements CompanyPayloadBase {
  name: Company["name"];
  url_suffix: Company["url_suffix"];
  branding: Company["branding"];

  constructor(company: Company) {
    super();
    const { _id, ...payload } = company;
    const sanitizedPayload = this.sanitize({ ...payload });
    Object.assign(this, sanitizedPayload);
  }

  sanitize(entity: CompanyPayloadBase): CompanyPayloadBase {
    const { branding } = entity;
    const { text_login_1, text_login_2 } = branding || {};
    const sanitizedLogin1 = sanitizeHtml(text_login_1);
    const sanitizedLogin2 = sanitizeHtml(text_login_2);

    return super.sanitize({
      ...entity,
      branding: { ...branding, text_login_1: sanitizedLogin1, text_login_2: sanitizedLogin2 },
    });
  }
}
