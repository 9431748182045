import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { Conference } from "../../../services/conference/conference.model";
import { QuestionGroup } from "../../../services/questionGroup/questionGroup.model";
import QuestionGroupService from "../../../services/questionGroup/questionGroup.service";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import { useService } from "../../useService/useService.hook";
import { QuestionGroupEntityName, QuestionGroupHookModel, QuestionGroupHookProps } from "./useQuestionGroups.definition";

export const useQuestionGroups = (props: QuestionGroupHookProps): QuestionGroupHookModel => {
  const { conferenceId, autoFetchData, assignDefaultEntity, data, showNotifications, useOffline } = props;

  const {
    current,
    items,
    loading,
    service: questionGroupService,
    setCurrent,
    setItems,
    setLoading,
    fetch,
    post,
    putById,
    deleteById,
  } = useService({
    autoFetchData: false,
    assignDefaultEntity,
    data,
    entityName: QuestionGroupEntityName,
    showNotifications,
    useOffline,
    entityModel: QuestionGroup,
    serviceModel: QuestionGroupService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<QuestionGroup[]> => {
      setLoading(true);
      return questionGroupService.getByConferenceId(_id).then((response): QuestionGroup[] => {
        setLoading(false);
        if (!response.success) {
          showNotifications && notificationService.error("Failed to load meetings.");
          return null;
        }

        const responseData = response.data ?? [];

        setItems(responseData);
        return responseData;
      });
    },
    [setLoading, questionGroupService, setItems, showNotifications, notificationService]
  );

  useAutoFetch({
    autoFetchData: !conferenceId ? false : autoFetchData,
    data,
    param: conferenceId,
    fetch,
    fetchBy: getByConferenceId,
    setEntities: setItems,
  });

  return {
    loading,
    items,
    current,
    fetchByConferenceId: getByConferenceId,
    setLoading,
    setItems,
    setCurrent,
    post,
    putById,
    deleteById,
  };
};
