import { ButtonIdModel, CheckboxIdModel, FormIdModel, isNullOrWhiteSpace, TextboxListId, TextListId } from "@q4/nimbus-ui";
import {
  QuestionCardWrapperIdModel,
  QuestionCardWrapperProps,
} from "../../questionCardWrapper/questionCardWrapper.definition";

export type SelectQuestionProps = QuestionCardWrapperProps;

export class SelectQuestionIdModel extends QuestionCardWrapperIdModel {
  addAdditionalOptions: CheckboxIdModel;
  sortOptions: CheckboxIdModel;
  optionField: TextboxListId;
  addOptionButton: ButtonIdModel;
  removeOption: TextListId;
  optionForm: FormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.addAdditionalOptions = new CheckboxIdModel(`${this.id}AddAdditionalOptionsCheckbox`);
    this.sortOptions = new CheckboxIdModel(`${this.id}SortOptionsCheckbox`);
    this.optionField = new TextboxListId(`${this.id}OptionField`);
    this.addOptionButton = new ButtonIdModel(`${this.id}AddOptionButton`);
    this.removeOption = new TextListId(`${this.id}RemoveOption`);
    this.optionForm = new FormIdModel(`${this.id}OptionForm`);
  }
}
