import "./attendeePasswordModal.component.scss";
import { Button, Modal, NotificationService } from "@q4/nimbus-ui";
import React, { memo, useMemo, useRef } from "react";
import PasswordTextbox from "../passwordTextbox/passwordTextbox.component";
import {
  AttendeePasswordModalClassName,
  AttendeePasswordModalIdModel,
  AttendeePasswordModalProps,
  ModalContentHeight,
} from "./attendeePasswordModal.definition";

const AttendeePasswordModal = ({ id, visible, password, onClose }: AttendeePasswordModalProps): JSX.Element => {
  const idModel = useMemo(() => new AttendeePasswordModalIdModel(id), [id]);
  const notificationService = useRef(new NotificationService());

  const handleOnClick = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => notificationService.current.success("Login Password has been copied to clipboard"));
  };

  return (
    <Modal
      id={idModel?.id}
      className={AttendeePasswordModalClassName.Base}
      title={"Login Password"}
      visible={visible}
      onCloseRequest={onClose}
      fullscreen={false}
      contentHeight={ModalContentHeight}
      scrollable
    >
      <PasswordTextbox
        id={idModel?.password?.id}
        className={AttendeePasswordModalClassName.PasswordTextbox}
        value={password}
      />
      <Button
        id={idModel?.copy?.id}
        className={AttendeePasswordModalClassName.CopyButton}
        label="COPY PASSWORD"
        icon="q4i-link-chain-4pt"
        onClick={handleOnClick}
      />
    </Modal>
  );
};

export default memo(AttendeePasswordModal);
