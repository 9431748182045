import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { EntityBase } from "../../definitions/entity.definition";
import type { ApiResponse } from "../api/api.definition";
import type { Conference } from "../conference/conference.model";
import { AdminApiVersion, Service } from "../serviceBase/serviceBase.model";
import { Meeting, MeetingPayload } from "./meeting.model";

export default class MeetingService extends Service<Meeting> {
  constructor() {
    super(AdminRouteBase, "meetings", Meeting, AdminApiVersion);
  }

  put(id: EntityBase["_id"], data: Meeting): Promise<ApiResponse<Meeting>> {
    const payload = new MeetingPayload(data);
    return super.put(id, payload as unknown as Meeting);
  }

  post(data: Meeting): Promise<ApiResponse<Meeting>> {
    const payload = new MeetingPayload(data);
    return super.post(payload as unknown as Meeting);
  }

  public async getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Meeting[]>> {
    const response = await this._apiService.get<Meeting[]>(`${this.ApiPathBase}/byConference/${_id}`);
    return this.mapArrayedResponse(response);
  }
}
