import { isEmpty, isNil } from "@q4/nimbus-ui";
import { EntityBase } from "../../../definitions/entity.definition";
import { convertEnumByKey } from "../../../utils";
import { Company } from "../../company/company.model";

export enum AdminProfileRole {
  SystemAdmin = "q4-admin",
  ClientAdmin = "ep-company-admin",
}

// Lower access better cheese
export enum AdminProfileRoleAccess {
  SystemAdmin = -1,
  ClientAdmin,
  Denied = 9_001,
}

export class AdminProfile extends EntityBase {
  _company?: Company;
  first_name?: string;
  last_name?: string;
  title?: string;
  email?: string;
  type?: AdminProfileRole;

  get IsSystemAdmin(): boolean {
    return this.type === AdminProfileRole.SystemAdmin;
  }

  get Role(): AdminProfileRole {
    return this.type;
  }

  get Access(): AdminProfileRoleAccess {
    const access = convertEnumByKey(AdminProfileRole, AdminProfileRoleAccess, this.type);
    if (isNil(access)) return AdminProfileRoleAccess.Denied;
    return access;
  }

  constructor(admin: Partial<AdminProfile> | string) {
    super(admin);
    if (typeof admin === "string" || isEmpty(admin)) return;

    Object.assign(this, admin);
  }
}
