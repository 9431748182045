import { ButtonIdModel, isNullOrWhiteSpace, ModalIdModel, BaseComponentProps } from "@q4/nimbus-ui";
import { PasswordTextboxIdModel } from "../passwordTextbox/passwordTextbox.definition";

export const ModalContentHeight = 144;

export interface AttendeePasswordModalProps extends BaseComponentProps {
  visible: boolean;
  onClose: () => void;
  password: string;
}

export enum AttendeePasswordModalClassName {
  Base = "attendee-password-modal",
  PasswordTextbox = "attendee-password-modal_password-textbox",
  CopyButton = "attendee-password-modal_copy-button",
}

export class AttendeePasswordModalIdModel extends ModalIdModel {
  password: PasswordTextboxIdModel;
  copy: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.password = new PasswordTextboxIdModel(`${this.id}ModalPasswordTextbox`);
    this.copy = new ButtonIdModel(`${this.id}ModalCopyButton`);
  }
}
