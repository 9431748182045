import "./requestProgressResults.component.scss";
import { Ghostable, isNullOrWhiteSpace, Scrollbars, ScrollbarsTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import type { EntityBase } from "../../../../definitions/entity.definition";
import ExportCsvButton from "../../../../services/import/components/exportCsvButton/exportCsvButton.component";
import { ImportResult } from "../../../../services/import/import.definition";
import Accordion from "../../../accordion/accordion.component";
import {
  RequestProgressResultsGroupHeader,
  RequestProgressResultsGroupIdModel,
  RequestProgressResultsTableProps,
} from "./requestProgressResultsTable.definition";
import {
  convertImportResultToCsv,
  getImportErrorFileName,
  renderStatusTableRow,
} from "./requestProgressStatusResults.utils";

const RequestProgressResultsGroup = <T extends EntityBase>(props: RequestProgressResultsTableProps<T>): JSX.Element => {
  const { id, rows, showCsvExport, showExportErrors } = props;
  const idModel = useMemo(() => new RequestProgressResultsGroupIdModel(id), [id]);

  const [successAccordionCollapsed, setSuccessAccordionCollapsed] = useState(true);
  const [failAccordionCollapsed, setFailAccordionCollapsed] = useState(false);

  const succeeded = useMemo(() => rows.filter((x): boolean => isNullOrWhiteSpace(x.errorMessage)), [rows]);
  const failed = useMemo(() => rows.filter((x): boolean => !isNullOrWhiteSpace(x.errorMessage)), [rows]);

  const errorCsv = useMemo(() => (showCsvExport ? convertImportResultToCsv(rows) : null), [rows, showCsvExport]);
  const fileName = useMemo(
    () => (showCsvExport ? getImportErrorFileName(rows, "_results_failures") : null),
    [rows, showCsvExport]
  );

  function toggleSuccessAccordion(): void {
    setSuccessAccordionCollapsed((collapsed) => !collapsed);
  }

  function toggleFailAccordion(): void {
    setFailAccordionCollapsed((collapsed) => !collapsed);
  }

  function renderFeedAccordion(
    id: string,
    results: ImportResult<T>[],
    headerTitle: string,
    onChange: (collapsed: boolean) => void,
    isCollapsed: boolean
  ): JSX.Element {
    if (results.length) {
      return (
        <Accordion
          id={id}
          collapsed={isCollapsed}
          header={
            <div className="import-table_accordion-header">
              <span>
                {headerTitle} ({results.length})
              </span>
              <Ghostable
                appear={false}
                ghosted={!showCsvExport || !showExportErrors || headerTitle === RequestProgressResultsGroupHeader.Successful}
              >
                <span id={idModel.exportCsvButton}>
                  <ExportCsvButton fileName={fileName} includeTimeStamp={true} items={errorCsv} />
                </span>
              </Ghostable>
            </div>
          }
          onChange={onChange}
        >
          {results.map((result, index): JSX.Element => renderStatusTableRow(result, index))}
        </Accordion>
      );
    }
  }

  return (
    <section id={idModel.id} className="import-table">
      <section className="import-table_content">
        <Scrollbars autoHide={true} theme={ScrollbarsTheme.Dark}>
          <div className="import-table_inner">
            {renderFeedAccordion(
              idModel.successAccordion.id,
              succeeded,
              RequestProgressResultsGroupHeader.Successful,
              toggleSuccessAccordion,
              successAccordionCollapsed
            )}
            {renderFeedAccordion(
              idModel.failedAccordion.id,
              failed,
              RequestProgressResultsGroupHeader.Unsuccessful,
              toggleFailAccordion,
              failAccordionCollapsed
            )}
          </div>
        </Scrollbars>
      </section>
    </section>
  );
};

export default memo(RequestProgressResultsGroup);
