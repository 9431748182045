import "./entityTable.component.scss";
import {
  Button,
  ButtonProps,
  ButtonTheme,
  Collapsable,
  ComponentSizeModifier,
  convertStringToEnum,
  ErrorModel,
  Ghostable,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Modal,
  PlaceholderContent,
  Search,
  SearchTheme,
  Select,
  SelectPreset,
  SelectTheme,
  Swapable,
  Table,
  Toolbar,
  ToolbarGroup,
  ToolbarTheme,
  typedMemo,
  useVisibility,
} from "@q4/nimbus-ui";
import type { ForwardedRef } from "@q4/nimbus-ui";
import type { ICellRendererParams, RowClickedEvent, RowNode } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { startCase, get, has } from "lodash";
import React, { forwardRef, useCallback, useMemo } from "react";
import { Entity } from "../../definitions/entity.definition";
import type { EntityBase } from "../../definitions/entity.definition";
import { useEditNotifications, useTable } from "../../hooks";
import { DefaultPageSizeOptions, YesNoOptions } from "../../hooks/nimbus/useTable/useTable.definition";
import { PusherMemberInfoViewModel } from "../../hooks/usePusher/usePusher.definition";
import DeleteConfirmationMessage from "../deleteConfirmationMessage/deleteConfirmationMessage.component";
import { EntityModalClassName, EntityTableClassName, EntityTableIdModel, EntityTableProps } from "./entityTable.definition";
import type { EntityTableRef } from "./entityTable.definition";
import { getModalFormSubtitle } from "./entityTable.utils";

const EntityTable = <T extends EntityBase>(props: EntityTableProps<T>, ref: ForwardedRef<EntityTableRef>): JSX.Element => {
  const {
    id: componentId,
    channelName,
    editForm,
    entity: tableEntity,
    customEntityTitle,
    icon,
    loading: loadingProp,
    modalProps,
    requiredEntityKeys,
    requiredFormEntityKeys,
    beforeSaveCallback,
    saveRequestParams,
    selectFilters,
    searchProps,
    tableProps,
    toolbarProps,
    exportButton,
    useService,
    user,
    newDefaultEntity,
    addNewButtonProps,
    placeholderContentProps,
    formErrors,
    entityDeleteConfirmationTitle,
    entityDeleteConfirmationMessage,
    hideSearch,
    entityModel,
    entityItemReadOnly,
    setFormErrors,
    modifyData,
    conference,
  } = props;
  const { doesExternalFilterPass: customFilterPass } = tableProps || {};

  const { current, items, loading: serviceLoading, post, fetchById, setCurrent, putById, deleteById } = useService || {};

  const idModel = useMemo(() => new EntityTableIdModel(componentId), [componentId]);

  const id = useMemo(() => current?._id, [current]);

  const loading = useMemo(() => loadingProp || serviceLoading, [loadingProp, serviceLoading]);

  const entity = useMemo(() => convertStringToEnum(Entity, tableEntity?.toString()), [tableEntity]);

  const entityCapitalized = useMemo(() => startCase(entity?.toString()), [entity]);
  const entityTitle = useMemo(
    () => (isNullOrWhiteSpace(customEntityTitle) ? entityCapitalized : customEntityTitle),
    [customEntityTitle, entityCapitalized]
  );

  const showPlaceholder = useMemo(() => !loading && isEmpty(items), [loading, items]);

  const edit = useMemo(() => !isNullOrWhiteSpace(id), [id]);

  const usesFormErrors = useMemo(() => !!setFormErrors, [setFormErrors]);

  const currentReadOnly = useMemo((): boolean => {
    if (isEmpty(entityItemReadOnly) || isEmpty(current)) return false;
    return entityItemReadOnly(current);
  }, [current, entityItemReadOnly]);

  const searchTheme = useMemo(() => {
    const toolbarTheme = toolbarProps?.theme;
    const searchTheme = searchProps?.theme;
    if (!isEmpty(searchTheme)) return searchTheme;
    if (isEmpty(toolbarTheme) || toolbarTheme === ToolbarTheme.Rain) return SearchTheme.Steel;
    if (toolbarTheme === ToolbarTheme.Q4Blue) return SearchTheme.Ink;
    return null;
  }, [toolbarProps, searchProps]);

  const [messageVisible, handleDeleteClick, handleMessageClose] = useVisibility();

  // #region Modal Effects
  const modalTitle = useMemo(
    () => `${edit && !currentReadOnly ? "Edit" : currentReadOnly ? "View" : "Add"} ${entityTitle}`,
    [edit, currentReadOnly, entityTitle]
  );

  const [modalVisible, handleModalOpen, handleModalClose] = useVisibility();

  const handleNewEntityClick = useCallback((): void => {
    if (typeof setCurrent === "function") setCurrent(newDefaultEntity || null);
    if (usesFormErrors) setFormErrors({});
    handleModalOpen();
  }, [setCurrent, newDefaultEntity, usesFormErrors, setFormErrors, handleModalOpen]);
  // #endregion

  // #region Table Effects
  const defaultColDef = {
    lockPosition: true,
    sortable: true,
    suppressMovable: true,
  };
  const {
    gridApi,
    page,
    pageCount,
    pageSize,
    searchFilter,
    filterValues,
    showPagination,
    doesExternalFilterPass,
    handleClearFilter,
    handleFilter,
    handleGridReady,
    handlePaginationChange,
    handlePageChange,
    handlePageSizeChange,
    handleSearchInputChange,
    handleSearchQueryClear,
    handleSearchQueryRequest,
    isExternalFilterPresent,
  } = useTable<T>({ rowCount: items?.length });
  // #endregion

  // #region Table Filter Effects
  const useExternalFilters = useCallback((): boolean => {
    // TODO: this should work if both customFilterPass is assigned and selectFilters
    if (!isEmpty(customFilterPass)) return true;
    if (isEmpty(selectFilters)) return false;
    return isExternalFilterPresent();
  }, [selectFilters, customFilterPass, isExternalFilterPresent]);

  const useExternalFilterPass = useCallback(
    (node: RowNode): boolean => {
      if (!isEmpty(customFilterPass)) return customFilterPass(node);
      if (isEmpty(selectFilters)) return true;
      return doesExternalFilterPass(node);
    },
    [selectFilters, customFilterPass, doesExternalFilterPass]
  );
  // #endregion

  // #region Notification Effects
  const checkForErrors = useCallback((): boolean => {
    if (isEmpty(requiredEntityKeys)) return false;
    if (isEmpty(current)) return true;

    return requiredEntityKeys.reduce((error, key) => {
      if (has(current, key)) {
        const value = get(current, key);

        if (["boolean", "number"].includes(typeof value)) return error || isNil(value);
        if (typeof value === "string") return error || isNullOrWhiteSpace(value);
        if (Array.isArray(value)) return error || isEmpty(value);
        if (typeof value === "object") return error || isEmpty(value);

        const entityProp = value as EntityBase;
        return error || isNullOrWhiteSpace(entityProp?._id);
      }
      return error;
    }, false as boolean);
  }, [current, requiredEntityKeys]);

  const checkForFormErrors = useCallback(() => {
    if (isEmpty(current)) return true;
    const errors = (requiredFormEntityKeys || []).reduce((error, { key, message, validation }) => {
      if (has(current, key)) {
        const value = get(current, key);
        error[key] = new ErrorModel(message, validation(value));
      }
      return error;
    }, {} as Record<string, ErrorModel>);

    const updatedErrors = { ...formErrors, ...errors };
    if (isEmpty(updatedErrors)) return false;
    setFormErrors(updatedErrors);
    return Object.values(updatedErrors).some((x) => x.visible);
  }, [current, formErrors, requiredFormEntityKeys, setFormErrors]);

  const handleSaveRequest = useCallback((): Promise<boolean> => {
    function handlePost(): Promise<EntityBase> {
      const data = new entityModel({
        ...current,
        ...(saveRequestParams || {}),
      });

      const postData = modifyData ? modifyData(data) : data;

      return typeof post === "function" ? post(postData) : Promise.resolve(data);
    }

    if (beforeSaveCallback && typeof beforeSaveCallback === "function") {
      beforeSaveCallback(current);
    }

    if (checkForErrors() || checkForFormErrors()) return Promise.resolve(false);

    const existing = !isNullOrWhiteSpace(id);
    const putData = modifyData ? modifyData(current) : current;

    return (existing ? putById(id, putData) : handlePost()).then((x) => !isEmpty(x));
  }, [
    beforeSaveCallback,
    checkForErrors,
    checkForFormErrors,
    id,
    modifyData,
    current,
    putById,
    entityModel,
    saveRequestParams,
    post,
  ]);

  const handleRefreshRequest = useCallback(async (): Promise<boolean> => {
    if (!edit || isNullOrWhiteSpace(id)) return;

    const item = isEmpty(fetchById) ? null : await fetchById(id);
    if (isEmpty(item)) return false;

    setCurrent(item);
    return true;
  }, [edit, id, fetchById, setCurrent]);

  const {
    members,
    modifyingMembers,
    viewingMembers,
    useNotifications,
    handleSubmitClick,
    renderMessage,
    triggerEditNotification,
  } = useEditNotifications({
    channelName,
    disabled: !edit || !modalVisible,
    entity,
    id,
    user,
    messageProps: {
      loading,
      title: modalTitle,
    },
    onRefreshRequest: handleRefreshRequest,
    onSubmitRequest: handleSaveRequest,
    onDestroy: handleModalClose,
  });

  // TODO: anything but this...
  if (!isNil(ref) && "current" in ref) {
    ref.current = { gridApi, triggerEditNotification };
  }
  // #endregion

  // #region Modal Effects
  const subtitle = useMemo(() => {
    if (!edit || isEmpty(current)) return null;
    function getName(member: PusherMemberInfoViewModel): string {
      return isNullOrWhiteSpace(member?.name) ? `${member.name} (${member.email})` : member.email;
    }
    function getMembersList(members: PusherMemberInfoViewModel[]) {
      return (members || []).reduce((subtitle, member): string => {
        return `${subtitle}${isNullOrWhiteSpace(subtitle) ? "" : ", "}${getName(member)}`;
      }, "");
    }

    const modifying = getMembersList(modifyingMembers);
    const viewing = getMembersList(viewingMembers);
    const showLastModified = !useNotifications || isEmpty(user) || isEmpty(members);
    return (
      <div>
        <Collapsable collapsed={!showLastModified}>{getModalFormSubtitle(conference?.time_zone, current)}</Collapsable>
        <Collapsable collapsed={isNullOrWhiteSpace(modifying)}>
          <span className="q4i-contact-4pt" /> currently being modified by {modifying}
        </Collapsable>
        <Collapsable collapsed={isNullOrWhiteSpace(viewing)}>
          <span className="q4i-contact-4pt" /> currently being viewed by {viewing}
        </Collapsable>
      </div>
    );
  }, [conference, current, edit, members, modifyingMembers, useNotifications, user, viewingMembers]);

  const footerModalActions = useMemo((): ButtonProps[] => {
    return [
      {
        key: `${tableEntity}-edit_delete`,
        id: idModel.modalDelete?.id,
        className: EntityModalClassName.DeleteButton,
        icon: "q4i-trashbin-4pt",
        theme: ButtonTheme.DarkSlate,
        disabled: !edit || loading,
        wide: false,
        square: true,
        onClick: handleDeleteClick,
      },
      {
        key: `${tableEntity}-edit_cancel`,
        id: idModel.modalCancel?.id,
        label: "Cancel",
        disabled: loading,
        theme: ButtonTheme.DarkSlate,
        onClick: handleModalClose,
      },
      {
        key: `${tableEntity}-edit_save`,
        id: idModel.modalSave?.id,
        label: "Save",
        loading,
        disabled: checkForErrors() || currentReadOnly,
        theme: ButtonTheme.Citrus,
        onClick: handleSubmitClick,
      },
    ];
  }, [
    tableEntity,
    idModel,
    edit,
    loading,
    handleDeleteClick,
    handleModalClose,
    checkForErrors,
    currentReadOnly,
    handleSubmitClick,
  ]);
  // #endregion

  function startsWithVowel(string: string): boolean {
    if (isNullOrWhiteSpace(string)) return false;
    const firstLetter = string[0];
    return /[aeiou]/i.test(firstLetter);
  }

  function handleRowClicked(rowEvent: RowClickedEvent): void {
    if (isEmpty(rowEvent)) return;

    const { data, event } = rowEvent;

    if (isEmpty(data)) return;

    // stop if clicking any of the inputs / buttons / link
    const element = event?.target as HTMLElement;
    const tagName = element?.tagName;
    if (tagName === "BUTTON" || tagName === "INPUT" || tagName === "A") return;

    setCurrent(data);

    if (usesFormErrors) setFormErrors({});

    handleModalOpen();
  }

  function handleEditReset(): void {
    if (modalVisible) return;
    if (typeof setCurrent === "function") setCurrent(null);
  }

  function handleDelete() {
    if (isNullOrWhiteSpace(id)) return;

    handleMessageClose();
    deleteById(id).then((success) => {
      if (!success) return;

      handleModalClose();
      setCurrent(null);
    });
  }

  function renderPlaceholder(): JSX.Element {
    const key = `${tableEntity}-placeholder`;
    const title = `No ${entityTitle}s`;
    const label = `Add ${startsWithVowel(entityTitle) ? "an" : "a"} ${entityTitle}`;
    return (
      <PlaceholderContent
        id={idModel.placeholder?.id}
        className={EntityTableClassName.Placeholder}
        key={key}
        title={title}
        icon={icon}
        actions={[
          {
            id: idModel.placeholderAddNew?.id,
            label,
            onClick: handleNewEntityClick,
          },
        ]}
        {...placeholderContentProps}
      />
    );
  }

  function renderActionsCell(params: ICellRendererParams): string | JSX.Element {
    const _id = params?.value as T["_id"];
    if (typeof _id === "string" ? isNullOrWhiteSpace(_id) : isEmpty(_id)) return "—";

    function handleClick() {
      setCurrent(new entityModel(_id));
      handleDeleteClick();
    }

    const buttonId = idModel.tableDelete?.getId(_id);
    return (
      <Button
        id={buttonId}
        className={EntityTableClassName.DeleteButton}
        theme={ButtonTheme.LightGrey}
        onClick={handleClick}
        icon="q4i-trashbin-4pt"
      />
    );
  }

  function renderFilters(): JSX.Element {
    if (isEmpty(selectFilters)) return;

    return (
      <div className={EntityTableClassName.Filter}>
        {selectFilters.map((filter) => {
          const { id, key, options, placeholder } = filter;

          const handleFilterChange = (option: string) => {
            handleFilter(key, option);
          };

          let value = "";
          if (!isNil(filterValues[key as keyof T])) {
            if (typeof filterValues[key as keyof T] === "boolean") {
              value = filterValues[key as keyof T] ? YesNoOptions.Yes : YesNoOptions.No;
            } else {
              value = filterValues[key as keyof T].toString();
            }
          }

          return (
            <Select
              id={id}
              value={value}
              key={key}
              onChange={handleFilterChange}
              options={options}
              theme={SelectTheme.Ink}
              size={ComponentSizeModifier.Small}
              preset={SelectPreset.Autocomplete}
              isClearable={true}
              placeholder={placeholder}
            />
          );
        })}
        <Button
          theme={ButtonTheme.Steel}
          label="Clear"
          icon="q4i-undo-4pt"
          disabled={isEmpty(filterValues)}
          onClick={handleClearFilter}
        />
      </div>
    );
  }
  function renderTable(): JSX.Element {
    const { columnDefs, frameworkComponents, ...rest } = tableProps || {};

    return (
      <Table
        id={idModel.table}
        isExternalFilterPresent={useExternalFilters}
        doesExternalFilterPass={useExternalFilterPass}
        domLayout="autoHeight"
        className={EntityTableClassName.Table}
        loading={loading}
        defaultColDef={defaultColDef}
        onRowClicked={handleRowClicked}
        onGridReady={handleGridReady}
        rowData={items}
        cacheQuickFilter
        pagination={showPagination}
        paginationPageSize={pageSize}
        onPaginationChanged={handlePaginationChange}
        paginationProps={{
          showPageSizeSelection: true,
          startFromZero: true,
          initialPage: 0,
          forcePage: page,
          forcePageSize: pageSize,
          pageCount,
          pageSizeOptions: DefaultPageSizeOptions,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
        }}
        columnDefs={[
          ...(columnDefs || []),
          {
            field: "_id",
            headerName: "Actions",
            sortable: false,
            minWidth: 105,
            maxWidth: 105,
            headerClass: EntityTableClassName.IconCell,
            cellRenderer: "actionsCellRenderer",
          },
        ]}
        frameworkComponents={{
          ...(frameworkComponents || {}),
          actionsCellRenderer: frameworkComponents?.actionsCellRenderer
            ? (node: RowNode) => frameworkComponents.actionsCellRenderer(node, { handleModalOpen, handleModalClose })
            : renderActionsCell,
        }}
        {...rest}
      />
    );
  }

  return (
    <div id={idModel.id} className={EntityTableClassName.Base}>
      <Toolbar
        autoRowProps={{
          justifyContent: !isEmpty(selectFilters) || !isEmpty(toolbarProps?.children) ? "space-between" : "flex-end",
        }}
        {...toolbarProps}
      >
        <>
          <ToolbarGroup className={EntityTableClassName.ToolbarGroup}>
            {toolbarProps?.children}
            {renderFilters()}
          </ToolbarGroup>
          <ToolbarGroup>
            <Ghostable ghosted={showPlaceholder || hideSearch}>
              <Search
                id={idModel.search?.id}
                value={searchFilter}
                disabled={loading}
                onInputChange={handleSearchInputChange}
                onClear={handleSearchQueryClear}
                onQueryRequest={handleSearchQueryRequest}
                {...searchProps}
                theme={searchTheme}
              />
            </Ghostable>
            {exportButton}
            <Button
              id={idModel.addNew?.id}
              theme={ButtonTheme.Citrus}
              className={EntityTableClassName.AddNewButton}
              icon="q4i-add-4pt"
              disabled={loading}
              onClick={handleNewEntityClick}
              {...addNewButtonProps}
            />
          </ToolbarGroup>
        </>
      </Toolbar>
      <div className={EntityTableClassName.Content}>
        <Swapable selected={+showPlaceholder} layers={[renderTable(), renderPlaceholder()]} />
      </div>
      <Modal
        id={idModel.modal?.id}
        badgeIcon={icon}
        className={EntityModalClassName.Base}
        title={modalTitle}
        subtitle={subtitle}
        visible={modalVisible && !messageVisible}
        onCloseRequest={handleModalClose}
        ghostableProps={{ onExited: handleEditReset }}
        footerActions={footerModalActions}
        focusOnProps={{ autoFocus: false }}
        {...(modalProps || {})}
      >
        {editForm}
      </Modal>
      <DeleteConfirmationMessage
        id={idModel.deleteConfirmationMessageIdModel?.id}
        entity={entity}
        customEntityTitle={customEntityTitle}
        loading={loading}
        visible={messageVisible}
        onCancel={handleMessageClose}
        onConfirm={handleDelete}
        title={entityDeleteConfirmationTitle}
        message={entityDeleteConfirmationMessage}
      />
      {renderMessage()}
    </div>
  );
};

export default typedMemo(
  forwardRef(EntityTable) as <T extends EntityBase>(
    p: EntityTableProps<T> & { ref?: ForwardedRef<EntityTableRef> }
  ) => JSX.Element
);
