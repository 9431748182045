import React from "react";
import { Redirect } from "react-router";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { AuthType } from "../../../services/api/api.definition";
import CompanyLobbyPresentation from "../../company/lobby/presentation/companyPresentation.view";
import Itinerary from "../../public/itinerary/itinerary.view";
import Login from "../../public/login/login.view";
import AttendeeProfile from "../../public/profile/profile.view";
import Registration from "../../public/register/registration.view";
import PrivateRoute from "./components/privateRoute/privateRoute.component";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import type { RouteProps } from "./route.definition";

const ConferenceRoutes = (props: RouteProps): JSX.Element[] => {
  const { userContext } = props;

  return [
    <PublicRoute
      key="conference_login"
      exact
      path={RoutePath.ConferenceLogin}
      userContext={userContext}
      component={Login}
    />,
    <PublicRoute
      key="conference-custom-company_login"
      exact
      path={RoutePath.ConferenceLoginWithCustomCompany}
      userContext={userContext}
      component={Login}
    />,
    <PublicRoute
      key="custom_registration--conference-id"
      exact
      path={RoutePath.CompanyRegistrationWithConferenceId}
      userContext={userContext}
      component={Registration}
    />,
    // TODO move presentation routes from here. May break route switch priorities
    <PrivateRoute
      key="conference_lobby-presentation--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferencePresentationWithId}
      component={CompanyLobbyPresentation}
    />,
    <PrivateRoute
      key="conference-custom-company_lobby-presentation--id"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceCustomCompanyPresentationWithId}
      component={CompanyLobbyPresentation}
    />,
    <PrivateRoute
      key="conference_lobby"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceLobby}
      component={Itinerary}
    />,
    <PrivateRoute
      exact
      key="conference-custom-company_lobby"
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceLobbyWithCustomCompany}
      component={Itinerary}
    />,
    <PrivateRoute
      key="conference_lobby-attendee-profile"
      exact
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceAttendeeProfilePath}
      component={AttendeeProfile}
    />,
    <PrivateRoute
      exact
      key="conference-custom-company_lobby-attendee-profile"
      authType={AuthType.Passwordless}
      userContext={userContext}
      path={RoutePath.ConferenceCustomAttendeeProfilePath}
      component={AttendeeProfile}
    />,
    <Redirect
      exact
      key="conference-custom-company_lobby-presentation--redirect"
      from={RoutePath.ConferenceCustomCompanyPresentation}
      to={RoutePath.ConferenceLobbyWithCustomCompany}
    />,
    <Redirect
      exact
      key="conference_lobby-presentation--redirect"
      from={RoutePath.ConferencePresentation}
      to={RoutePath.ConferenceLobby}
    />,
    <Redirect exact key="custom_lobby--redirect" from={`${RoutePath.ConferenceLogin}/*`} to={RoutePath.ConferenceLogin} />,
    <Redirect
      exact
      key="custom_lobby--redirect"
      from={`${RoutePath.ConferenceLoginWithCustomCompany}/*`}
      to={RoutePath.ConferenceLoginWithCustomCompany}
    />,
  ];
};

export default ConferenceRoutes;
