import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AnchorWithPopoverMenuIdModel } from "../../../../components/anchorWithPopoverMenu/anchorWithPopoverMenu.definition";
import { Conference } from "../../../../services/conference/conference.model";

export interface TimeZoneSelectorProps extends BaseComponentProps {
  selectedTimeZone: string;
  conferenceTimeZone: Conference["time_zone"];
  anchorMargin?: boolean;
  onSelect: (value: string) => void;
}

export enum TimeZoneSelectorClassName {
  Base = "timezone-selector",
  BaseWithMarginModifier = "timezone-selector--margin",
  Icon = "timezone-selector_icon",
  Label = "timezone-selector_label",
}

export class TimeZoneSelectorIdModel extends IdModelBase {
  anchorPopoverMenu: AnchorWithPopoverMenuIdModel;
  selectedTimeZone: ButtonIdModel;
  conferenceTimeZone: ButtonIdModel;
  browserTimeZone: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.anchorPopoverMenu = new AnchorWithPopoverMenuIdModel(`${this.id}AnchorWithPopoverMenu`);
    this.selectedTimeZone = new ButtonIdModel(`${this.id}ChosenTimeZoneButton`);
    this.conferenceTimeZone = new ButtonIdModel(`${this.id}ConferenceTimeZoneButton`);
    this.browserTimeZone = new ButtonIdModel(`${this.id}BrowserTimeZoneButton`);
  }
}
