import "./gridCard.component.scss";
import { Card, Grid, GridColumn } from "@q4/nimbus-ui";
import React, { memo } from "react";
import { GridCardClassName, GridCardProps } from "./gridCard.definition";

const GridCard = (props: GridCardProps): JSX.Element => {
  const { children, gridProps, gridColumnProps, cardProps } = props;

  return (
    <Grid className={GridCardClassName.Base} {...gridProps}>
      <GridColumn width="1-of-1" {...gridColumnProps}>
        <Card {...cardProps}>
          <div className={GridCardClassName.Content}>{children}</div>
        </Card>
      </GridColumn>
    </Grid>
  );
};

export default memo(GridCard);
