import { isNil, isNullOrWhiteSpace, ToolbarTheme, Swapable, ToggleButtons, isEmpty, TableProps } from "@q4/nimbus-ui";
import { ValueFormatterParams, GetQuickFilterTextParams } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import EntityTable from "../../../../../../components/entityTable/entityTable.component";
import type { EntityTableRef } from "../../../../../../components/entityTable/entityTable.definition";
import MeetingForm from "../../../../../../components/meetingForm/meetingForm.component";
import { MeetingEditState } from "../../../../../../components/meetingForm/meetingForm.definition";
import { DefaultTimeZone } from "../../../../../../const";
import { DateFormat } from "../../../../../../definitions/date.definition";
import { Entity } from "../../../../../../definitions/entity.definition";
import type { TableSelectFilter } from "../../../../../../hooks/useTableFilter/useTableFilter.definition";
import {
  AttendeeType,
  AttendeeTypesWithoutMeetings,
  AttendeeViewModel,
} from "../../../../../../services/attendee/attendee.model";
import { Meeting } from "../../../../../../services/meeting/meeting.model";
import {
  byStringAndStartDate,
  getDisplayNames,
  getFriendlyNames,
  getMeetingLabel,
  getOptions,
} from "../../../../../../utils";
import MeetingConfiguration from "../meetingConfiguration/meetingConfiguration.component";
import MeetingScheduler from "../meetingScheduler/meetingScheduler.component";
import MeetingsExportButton from "./components/exportButton/meetingsExportButton.component";
import {
  MeetingEditClassName,
  MeetingPusherChannel,
  MeetingsClassName,
  MeetingsIdModel,
  MeetingsProps,
} from "./meetings.definition";

const Meetings = (props: MeetingsProps): JSX.Element => {
  const { id, codes, useCorporateProfile, useAttendees, conference, presentations, useMeetings, useConferences, user } =
    props;

  const [selected, setSelected] = useState(0);

  const idModel = useMemo(() => new MeetingsIdModel(id), [id]);
  const tableRef = useRef<EntityTableRef>(null);

  const { items: corporateProfiles } = useCorporateProfile;
  const {
    current: currentMeeting,
    items: meetings,
    loading: meetingsLoading,
    setCurrent: setCurrentMeeting,
    fetchConferenceMeetings,
  } = useMeetings;
  const { items: hookAttendees, loading: attendeesLoading } = useAttendees;

  const unscheduledAttendees = useMemo(
    () => hookAttendees.filter((x) => !AttendeeTypesWithoutMeetings.includes(x.type)),
    [hookAttendees]
  );

  const loading = useMemo(() => meetingsLoading || attendeesLoading, [attendeesLoading, meetingsLoading]);

  const companies = useMemo<string[]>(() => getOptions("company", unscheduledAttendees), [unscheduledAttendees]);
  const types = useMemo((): string[] => getOptions("type", meetings), [meetings]);

  useEffect(() => {
    if (!isEmpty(currentMeeting)) return;

    setCurrentMeeting(new MeetingEditState(null, conference));
  }, [currentMeeting, conference, setCurrentMeeting]);

  // refresh meeting when we open the tab, so that we don't need to refresh the whole page (similar behaviour as attendee tab)
  useEffect(() => {
    fetchConferenceMeetings(conference?._id);
  }, [conference?._id, fetchConferenceMeetings]);

  const selectFilters: TableSelectFilter[] = [
    {
      id: idModel.type.id,
      key: "type",
      placeholder: "Filter by Type",
      options: types,
    },
  ];

  const tableProps: TableProps = {
    columnDefs: [
      {
        field: "type",
        headerName: "Type",
        sortable: false,
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: getMeetingType,
        getQuickFilterText: getMeetingType,
      },
      {
        field: "start_date",
        headerName: "Start Time",
        minWidth: 180,
        maxWidth: 180,
        valueFormatter: getFormattedDate,
        getQuickFilterText: getFormattedDate,
        sort: "asc",
      },
      {
        field: "end_date",
        headerName: "End Time",
        minWidth: 180,
        maxWidth: 180,
        valueFormatter: getFormattedDate,
        getQuickFilterText: getFormattedDate,
      },
      {
        field: "CorporateName",
        headerName: "Corporate Name",
        maxWidth: 180,
        minWidth: 180,
        comparator: byStringAndStartDate,
      },
      {
        field: "_attendee",
        headerName: "Corporate Attendees",
        sortable: false,
        valueFormatter: getFormattedAttendees({ type: AttendeeType.Corporate, friendlyName: false }),
        getQuickFilterText: getFormattedAttendees({ type: AttendeeType.Corporate, friendlyName: true }),
      },
      {
        field: "InvestorCompany",
        headerName: "Investor Company",
        comparator: byStringAndStartDate,
      },
      {
        field: "_attendee",
        headerName: "Investor Attendees",
        sortable: false,
        valueFormatter: getFormattedAttendees({ type: AttendeeType.Investor, friendlyName: false }),
        getQuickFilterText: getFormattedAttendees({ type: AttendeeType.Investor, friendlyName: true }),
      },
      {
        field: "_attendee",
        headerName: "Internal Attendees",
        sortable: false,
        valueFormatter: getFormattedAttendees({ type: AttendeeType.Internal, friendlyName: false }),
        getQuickFilterText: getFormattedAttendees({ type: AttendeeType.Internal, friendlyName: true }),
      },
    ],
    loading,
  };

  const editForm = (
    <div className={MeetingEditClassName.Base}>
      <MeetingForm
        id={idModel.form?.id}
        codes={codes}
        conference={conference}
        companies={companies}
        meeting={currentMeeting}
        meetings={meetings}
        presentations={presentations}
        attendees={unscheduledAttendees}
        corporateProfiles={corporateProfiles}
        onUpdate={handleMeetingUpdate}
        fullscreenLayout={true}
      />
    </div>
  );

  const isCurrentMeetingReadOnly = useCallback((meeting: Meeting): boolean => {
    if (isNil(meeting.end_date)) return false;

    return moment(meeting.end_date).isBefore(moment(), "minutes");
  }, []);

  function handleMeetingUpdate(data: Partial<Meeting>) {
    tableRef.current && tableRef.current.triggerEditNotification();
    setCurrentMeeting(new Meeting({ ...currentMeeting, ...data }));
  }

  function getFormattedAttendees(options: { type?: AttendeeType; friendlyName?: boolean }) {
    return (params: ValueFormatterParams | GetQuickFilterTextParams): string => {
      const attendees: AttendeeViewModel[] = params?.value;
      if (options.friendlyName) return getFriendlyNames(attendees, options.type);
      return getDisplayNames(attendees, options.type);
    };
  }

  function getFormattedDate(params: ValueFormatterParams | GetQuickFilterTextParams): string {
    const date = params?.value;

    if (isNullOrWhiteSpace(date)) return "—";

    return moment(date).tz(conference?.time_zone).format(DateFormat.ShortStandard);
  }

  function getMeetingType(params: ValueFormatterParams | GetQuickFilterTextParams): string {
    const meeting = params?.data;

    if (isEmpty(meeting)) return "—";

    return getMeetingLabel(meeting);
  }

  function renderToggleButtons(keyPrefix: string) {
    const prefix = isNullOrWhiteSpace(keyPrefix) ? "" : keyPrefix;

    return (
      <ToggleButtons
        id={idModel.toolbarToggleButtons.id}
        key="meetings-banner_toggle-buttons"
        selected={selected}
        items={[
          { key: `${prefix}configuration`, label: "Schedule Configuration" },
          { key: `${prefix}scheduler`, label: "Meeting Scheduler" },
          { key: `${prefix}meetings`, label: "Meeting List" },
        ]}
        onChange={setSelected}
      />
    );
  }

  function renderSwapableLayers() {
    const meetingConfiguration = (
      <MeetingConfiguration
        key="meetings_configuration"
        id={idModel.configuration.id}
        conference={conference}
        toolbarProps={{
          id: idModel.toolbar.id,
          theme: ToolbarTheme.Q4Blue,
          children: renderToggleButtons("meeting-configuration_"),
        }}
        useConferences={useConferences}
      />
    );

    const meetingScheduler = (
      <MeetingScheduler
        key={"meetings_scheduler"}
        id={idModel.scheduler.id}
        codes={codes}
        companies={companies}
        conference={conference}
        presentations={presentations}
        useMeetings={useMeetings}
        toolbarProps={{
          id: idModel.toolbar.id,
          theme: ToolbarTheme.Q4Blue,
          children: renderToggleButtons("meeting-scheduler_"),
        }}
      />
    );

    const meetingList = (
      <EntityTable
        key="meetings_table"
        id={idModel.entityTable?.id}
        entity={Entity.Meeting}
        channelName={MeetingPusherChannel.Edit}
        useService={useMeetings}
        tableProps={tableProps}
        editForm={editForm}
        selectFilters={selectFilters}
        modalProps={{
          fullscreen: true,
          scrollable: true,
          focusOnProps: { autoFocus: false },
          ghostableProps: { onExiting: async () => await fetchConferenceMeetings(conference?._id) },
        }}
        entityModel={Meeting}
        icon="q4i-contact-2pt"
        requiredEntityKeys={["_attendee"]}
        saveRequestParams={{ _conference: conference }}
        user={user}
        conference={conference}
        toolbarProps={{
          id: idModel.toolbar.id,
          theme: ToolbarTheme.Q4Blue,
          children: renderToggleButtons("meeting-table_"),
        }}
        exportButton={
          <MeetingsExportButton
            key="meetings-banner_export-button"
            meetings={meetings}
            timezone={conference?.time_zone || DefaultTimeZone}
            vendorName={conference?.video_vendors?.[0]?.name}
          />
        }
        entityItemReadOnly={isCurrentMeetingReadOnly}
        ref={tableRef}
      />
    );

    return [meetingConfiguration, meetingScheduler, meetingList];
  }

  return (
    <div id={idModel.id} className={MeetingsClassName.Base}>
      <Swapable selected={selected} layers={renderSwapableLayers()} />
    </div>
  );
};

export default memo(Meetings);
