import "./conferenceDetails.view.scss";
import {
  AttributeProps,
  Attributes,
  AttributesTheme,
  Badge,
  BadgeTheme,
  Banner,
  BannerControlType,
  BannerSize,
  ButtonTheme,
  Card,
  CardClassName,
  CardTheme,
  getClassName,
  Ghostable,
  Grid,
  GridClassName,
  GridColumn,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Spinner,
  SpinnerTheme,
  Swapable,
  Tabs,
  Text,
  TextPreset,
  useVisibility,
} from "@q4/nimbus-ui";
import { camelCase } from "lodash";
import React, { memo, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import DeleteConfirmationMessage from "../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.component";
import { getModalFormSubtitle } from "../../../../components/entityTable/entityTable.utils";
import { RoutePath, RoutePathIdLabel } from "../../../../configurations/navigation.configuration";
import { AdminUserContext } from "../../../../contexts/adminUser/adminUser.context";
import { ConferenceContext } from "../../../../contexts/conference/conference.context";
import contextSubscribe from "../../../../contexts/context.subscribe";
import { UserContext } from "../../../../contexts/user/user.context";
import { Entity } from "../../../../definitions/entity.definition";
import {
  useConferences as useConferencesHook,
  useSpeakers as useSpeakersHook,
  useTracks as useTracksHook,
  useMeetings as useMeetingsHook,
  usePresentations as usePresentationsHook,
  useOnDemand as useOnDemandHook,
  useRegistrants as useRegistrantsHook,
  useAttendeeCorporateProfile as useAttendeeCorporateProfileHook,
  useQuestionGroups as useQuestionGroupsHook,
} from "../../../../hooks";
import { Conference } from "../../../../services/conference/conference.model";
import {
  getBackgroundImageUrl,
  getConferencesPageRoute,
  getFullConferenceUrl,
  getFullLoginUrl,
  getPresentationCodes,
  getProfileUrl,
  getRegisterUrl,
  htmlParse,
} from "../../../../utils";
import Attendees from "./components/attendees/attendees.component";
import { ConferenceBannerDetails } from "./components/conferenceBannerDetails/conferenceBannerDetails.component";
import CorporateProfiles from "./components/corporateProfiles/corporateProfiles.component";
import Meetings from "./components/meetings/meetings.component";
import OnDemand from "./components/onDemand/onDemand.component";
import Presentations from "./components/presentations/presentations.component";
import Registrants from "./components/registrants/registrants.component";
import Speakers from "./components/speakers/speakers.component";
import Tracks from "./components/tracks/tracks.component";
import {
  ConferenceDetailsIdModel as IdModel,
  ConferenceDetailsIdModelTabs,
  ConferenceDetailsClassName,
  ConferenceDetailsTab,
} from "./conferenceDetails.definition";
import type { ConferenceDetailsMatchParams } from "./conferenceDetails.definition";

export const ConferenceDetails = (): JSX.Element => {
  const adminUserContext = useContext(AdminUserContext);
  const conferenceContext = useContext(ConferenceContext);
  const { user: adminUser, isSystemAdmin } = adminUserContext;

  const history = useHistory();
  const params = useParams<ConferenceDetailsMatchParams>();

  const [currentTab, setCurrentTab] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);

  const contextEmpty = useMemo(() => isEmpty(conferenceContext?.conference), [conferenceContext]);

  const conferenceId = useMemo(() => params?.conferenceId, [params]);
  const useConferences = useConferencesHook({
    autoFetchData: contextEmpty,
    id: conferenceId,
    data: conferenceContext?.conference,
  });
  const {
    current: conference,
    items: conferences,
    loading,
    deleteById: deleteConferenceById,
    getReportById: getConferenceReportById,
    loadFailed,
  } = useConferences;

  useEffect(() => {
    if (loadFailed) {
      history.push(getConferencesPageRoute(isSystemAdmin, conference));
    }
  }, [conference, history, isSystemAdmin, loadFailed]);

  const defaultTracks = useMemo(() => conference?._track, [conference]);
  const useTracks = useTracksHook({
    conferenceId,
    autoFetchData: false,
    data: defaultTracks,
  });
  const { items: tracks } = useTracks;

  const defaultSpeakers = useMemo(() => conference?._speaker, [conference]);
  const useSpeakers = useSpeakersHook({ conferenceId, autoFetchData: false, data: defaultSpeakers });
  const { items: speakers } = useSpeakers;

  const { useAttendees, useCorporateProfile } = useAttendeeCorporateProfileHook({
    conferenceId,
    conferenceTimeZone: conference?.time_zone,
    attendeeHookProps: {},
    corporateProfileHookProps: { showNotifications: true },
  });
  const { items: attendees } = useAttendees;
  const { items: corporateProfiles } = useCorporateProfile;
  const usePresentations = usePresentationsHook({ conferenceId, showNotifications: true });
  const { items: presentations } = usePresentations;

  const useRegistrants = useRegistrantsHook({
    conferenceId,
    showNotifications: true,
    useAttendees,
    useCorporateProfile,
    useSpeakers,
  });

  const useMeetings = useMeetingsHook({
    attendees,
    conferenceId,
    conferenceTimeZone: conference?.time_zone,
    showNotifications: true,
  });

  const useOnDemand = useOnDemandHook({
    conferenceId,
    speakers,
    showNotifications: true,
  });

  const useQuestionGroups = useQuestionGroupsHook({
    conferenceId: conference?._id,
  });
  const { items: onDemand } = useOnDemand;

  const presentationCodes = useMemo(() => getPresentationCodes(presentations, onDemand), [onDemand, presentations]);

  const [messageVisible, handleDeleteClick, handleMessageClose] = useVisibility();

  const registrationUrl = useMemo(
    () => getRegisterUrl(conference?._company, conference?.Path, true),
    [conference?.Path, conference?._company]
  );
  const lobbyUrl = useMemo(() => getFullConferenceUrl(conference?._company, conference, true), [conference]);
  const loginUrl = useMemo(() => getFullLoginUrl(conference?._company, conference, true), [conference]);
  const profileUrl = useMemo(() => getProfileUrl(conference?._company, conference, true), [conference]);

  const tabs = useMemo(() => {
    return Object.values(ConferenceDetailsTab).map((x) => {
      const idModelKey = `${camelCase(x)}Tab`;
      const id = idModelKey in IdModel ? IdModel[idModelKey as keyof ConferenceDetailsIdModelTabs] : null;
      const label = ConferenceDetailsTab.Presentations === x ? "SESSIONS" : x;

      return {
        key: `conference-details-toggle_${x}`,
        id,
        label,
        value: x,
      };
    });
  }, []);

  function handleConferenceContextChange(data: Conference): void {
    if (isEmpty(data)) {
      conferenceContext.set(null);
      return;
    }
    conferenceContext.set(new Conference({ ...data, _track: tracks, _speaker: speakers }));
  }

  function handleBack(): void {
    history.push(getConferencesPageRoute(isSystemAdmin, conference));
    handleConferenceContextChange(null);
  }

  function handleEditClick(): void {
    handleConferenceContextChange(conference);
    const path = RoutePath.ConferenceEdit.replace(RoutePathIdLabel.Conference, conferenceId);
    history.push(path);
  }

  function handleReportClick(): void {
    setReportLoading(true);
    getConferenceReportById(conferenceId).finally(() => setReportLoading(false));
  }

  async function handleConferenceDelete(): Promise<void> {
    const success = await deleteConferenceById(conferenceId);
    if (!success) return Promise.resolve();
    handleMessageClose();
    handleBack();
  }

  function handleTabChange(index: number): void {
    setCurrentTab(index);
  }

  function getAttributeProps(title: string, width: number, children: ReactNode): AttributeProps {
    return {
      key: `conference-details_${title}`,
      title,
      width: `1-of-${width}`,
      smallWidth: "1-of-1",
      children,
    };
  }

  function renderDescription(): JSX.Element {
    // TODO: Add to Nimbus
    const GridClassNameWithMatchHeightModifier = `${GridClassName.Base}--match-height`;
    const CardClassNameWithFullWidthModifier = `${CardClassName.Base}--full-width`;

    return (
      <Grid className={`${GridClassName.Base} ${GridClassNameWithMatchHeightModifier}`}>
        <GridColumn width="1-of-2">
          <Card
            className={`${CardClassName.Base} ${CardClassNameWithFullWidthModifier}`}
            title="Description"
            theme={CardTheme.Dark}
          >
            <div id={IdModel.description}>
              {isNullOrWhiteSpace(conference?.description)
                ? "No description has been assigned to this conference."
                : htmlParse(conference.description)}
            </div>
          </Card>
        </GridColumn>
        <GridColumn width="1-of-2">
          <Card
            className={`${CardClassName.Base} ${CardClassNameWithFullWidthModifier}`}
            title="Supplementary Information"
            theme={CardTheme.Dark}
          >
            {isNullOrWhiteSpace(conference?.info)
              ? "No supplementary information has been assigned to this conference."
              : htmlParse(conference.info)}
          </Card>
        </GridColumn>
      </Grid>
    );
  }

  function renderImage(title: string, image: string): JSX.Element {
    if (isNullOrWhiteSpace(title)) return null;

    const hasImage = isNullOrWhiteSpace(image);
    const lowerCaseTitle = title.toLowerCase();

    return (
      <div
        className={getClassName(ConferenceDetailsClassName.Image, [
          {
            condition: hasImage,
            falseClassName: ConferenceDetailsClassName.ImageWithLoadedModifier,
          },
        ])}
      >
        <div className={ConferenceDetailsClassName.ImageContent} style={{ backgroundImage: getBackgroundImageUrl(image) }}>
          {!hasImage ? null : `No ${lowerCaseTitle} has been assigned.`}
        </div>
      </div>
    );
  }

  function renderImageSection(): JSX.Element {
    return (
      <Grid>
        <GridColumn width="1-of-2" smallWidth="1-of-1">
          <Card title="Logo" theme={CardTheme.Dark}>
            {renderImage("Logo", conference?.image_logo)}
          </Card>
        </GridColumn>
        <GridColumn width="1-of-2" smallWidth="1-of-1">
          <Card title="Background" theme={CardTheme.Dark}>
            {renderImage("Background", conference?.image_background)}
          </Card>
        </GridColumn>
      </Grid>
    );
  }

  function renderCoordinator(): JSX.Element {
    const hasName = !isNullOrWhiteSpace(conference?.coordinator?.full_name);
    const hasTitle = !isNullOrWhiteSpace(conference?.coordinator?.title);
    const hasEmail = !isNullOrWhiteSpace(conference?.coordinator?.email);
    const hasPhone = !isNullOrWhiteSpace(conference?.coordinator?.phone);
    const fieldCount = +hasName + +hasTitle + +hasEmail + +hasPhone;

    const attributes: AttributeProps[] = [];
    if (hasName) {
      attributes.push(getAttributeProps("Name", fieldCount, conference.coordinator.full_name));
    }

    if (hasTitle) {
      attributes.push(getAttributeProps("Title", fieldCount, conference.coordinator.title));
    }

    if (hasEmail) {
      attributes.push(getAttributeProps("Email", fieldCount, conference.coordinator.email));
    }

    if (hasPhone) {
      attributes.push(getAttributeProps("Phone", fieldCount, conference.coordinator.phone));
    }

    return (
      <Card title="Coordinator" theme={CardTheme.Dark}>
        {isEmpty(conference?.coordinator) ? (
          "No coordinator has been assigned to this conference."
        ) : (
          <Attributes items={attributes} theme={AttributesTheme.Dark} />
        )}
      </Card>
    );
  }

  function renderAudit(): JSX.Element {
    if (isEmpty(conference)) return null;
    return (
      <Text className={ConferenceDetailsClassName.Audit} preset={TextPreset.Base}>
        {getModalFormSubtitle<Conference>(conference?.time_zone, conference)}
      </Text>
    );
  }

  function renderTitle(): JSX.Element {
    return (
      <span className={ConferenceDetailsClassName.Title}>
        <span>{conference?.title}</span>
        <Ghostable ghosted={isNil(conference?.preview) || !conference?.preview}>
          <span>
            <Badge theme={BadgeTheme.Citrus}>Preview</Badge>
          </span>
        </Ghostable>
      </span>
    );
  }

  return (
    <section id={IdModel.id} className={ConferenceDetailsClassName.Base}>
      <Banner
        id={IdModel.banner.id}
        title={renderTitle()}
        details={
          <ConferenceBannerDetails
            id={IdModel.conferenceBannerDetails.id}
            registrationUrl={registrationUrl}
            lobbyUrl={lobbyUrl}
            loginUrl={loginUrl}
            profileUrl={profileUrl}
            conference={conference}
          />
        }
        badgeIcon="q4i-conference-2pt"
        size={BannerSize.Medium}
        controls={[
          {
            type: BannerControlType.Button,
            props: {
              id: IdModel.delete.id,
              theme: ButtonTheme.Spice,
              label: "Delete",
              icon: "q4i-trashbin-4pt",
              onClick: handleDeleteClick,
            },
          },
          {
            type: BannerControlType.Button,
            props: {
              id: IdModel.reports.id,
              theme: ButtonTheme.Citrus,
              label: "Reports",
              icon: "q4i-data-4pt",
              loading: reportLoading,
              onClick: handleReportClick,
            },
          },
          {
            type: BannerControlType.Button,
            props: {
              id: IdModel.edit.id,
              theme: ButtonTheme.Citrus,
              label: "Edit",
              icon: "q4i-edit-4pt",
              onClick: handleEditClick,
            },
          },
        ]}
        onBackRequest={handleBack}
      >
        <section className={ConferenceDetailsClassName.Content}>
          {renderDescription()}
          {renderImageSection()}
          {renderCoordinator()}
          {renderAudit()}
        </section>
      </Banner>
      <Tabs id={IdModel.tabs} items={tabs} selected={currentTab} onChange={handleTabChange} />
      <Swapable
        selected={currentTab}
        layers={[
          <Registrants
            id={IdModel.registrants.id}
            key="conference-details_registrants"
            codes={presentationCodes}
            conference={conference}
            useCorporateProfile={useCorporateProfile}
            useRegistrants={useRegistrants}
            useQuestionGroups={useQuestionGroups}
            user={adminUser}
          />,
          <CorporateProfiles
            id={IdModel.corporateProfiles.id}
            key="conference-details_corporate-profiles"
            conference={conference}
            useCorporateProfiles={useCorporateProfile}
            user={adminUser}
            useQuestionGroups={useQuestionGroups}
            useAttendees={useAttendees}
          />,
          <Attendees
            id={IdModel.attendees.id}
            key="conference-details_attendees"
            company={{ url_suffix: conference?._company.url_suffix, custom_path: conference?._company.custom_path }}
            conference={conference}
            corporateProfiles={corporateProfiles}
            codes={presentationCodes}
            useAttendees={useAttendees}
            usePresentations={usePresentations}
            useMeetings={useMeetings}
            useQuestionGroups={useQuestionGroups}
            user={adminUser}
            isSystemAdmin={isSystemAdmin}
          />,
          <OnDemand
            id={IdModel.onDemand.id}
            key="conference-details_on-demand"
            conference={conference}
            codes={presentationCodes}
            speakers={speakers}
            useOnDemand={useOnDemand}
            user={adminUser}
          />,
          <Presentations
            id={IdModel.presentations.id}
            key="conference-details_presentations"
            conference={conference}
            conferences={conferences}
            codes={presentationCodes}
            speakers={speakers}
            tracks={tracks}
            useAttendees={useAttendees}
            usePresentations={usePresentations}
            user={adminUser}
          />,
          <Meetings
            id={IdModel.meetings.id}
            key="conference-details_meetings"
            conference={conference}
            codes={presentationCodes}
            presentations={presentations}
            useAttendees={useAttendees}
            useCorporateProfile={useCorporateProfile}
            useMeetings={useMeetings}
            useConferences={useConferences}
            user={adminUser}
          />,
          <Speakers
            id={IdModel.speakers.id}
            key="conference-details_speakers"
            conference={conference}
            useAttendees={useAttendees}
            useSpeakers={useSpeakers}
            user={adminUser}
          />,
          <Tracks
            id={IdModel.tracks.id}
            key="conference-details_tracks"
            conference={conference}
            useTracks={useTracks}
            user={adminUser}
          />,
        ]}
      />
      <Ghostable ghosted={!loading}>
        <Spinner theme={SpinnerTheme.Citrus} />
      </Ghostable>
      <DeleteConfirmationMessage
        id={IdModel.deleteMessage.id}
        entity={Entity.Conference}
        visible={messageVisible}
        loading={loading}
        onCancel={handleMessageClose}
        onConfirm={handleConferenceDelete}
      />
      {/*<ConferenceDetailsPdf*/}
      {/*  conference={conference}*/}
      {/*  attendees={useAttendees?.items}*/}
      {/*  presentations={usePresentations?.items}*/}
      {/*/>*/}
    </section>
  );
};

export default contextSubscribe(
  [
    { context: ConferenceContext, mapToProps: "conferenceContext" },
    { context: UserContext, mapToProps: "userContext" },
  ],
  memo(ConferenceDetails)
);
