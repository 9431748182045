import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, SelectIdModel } from "@q4/nimbus-ui";
import { VendorName, VideoVendor } from "../../../../../../services/conference/conference.model";

export interface VideoVendorsFormOption {
  label: string;
  value: string;
}

export interface VideoVendorsFormProps extends BaseComponentProps {
  vendor?: VideoVendor | VendorName;
  disabled?: boolean;
  onChange: (value: VideoVendor) => void;
  useAllVendorName?: boolean;
}

export enum VideoVendorsFormClassName {
  Base = "video-vendors-form",
}

export class VideoVendorsFormIdModel extends IdModelBase {
  select: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.select = new SelectIdModel(`${this.id}Select`);
  }
}
