import { UserRouteBase } from "../../../configurations/navigation.configuration";
import { ApiResponse, AuthType } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import { AttendeeViewModel } from "../../attendee/attendee.model";
import type { Attendee } from "../../attendee/attendee.model";
import type { Conference } from "../../conference/conference.model";
import { CorporateProfile, CorporateProfilePayload } from "../../corporateProfile/corporateProfile.model";
import { ProfilePayload } from "./profile.model";

export default class ProfileService {
  private readonly PROFILE_API_PATH = `${UserRouteBase}/profile`;
  private readonly CORPORATE_PROFILE_API_PATH = `${UserRouteBase}/corporateProfile`;
  private readonly TIME_ZONE_API_PATH = `${UserRouteBase}/timezone`;
  private apiService = new ApiService();

  public async get(conferenceId: Conference["_id"]): Promise<ApiResponse<AttendeeViewModel>> {
    const response = await this.apiService.get<Attendee>(`${this.PROFILE_API_PATH}/${conferenceId}`, AuthType.Passwordless);
    return this.mapAttendeeResponse(response);
  }

  public async getFull(conferenceId: Conference["_id"]): Promise<ApiResponse<AttendeeViewModel>> {
    const response = await this.apiService.get<Attendee>(
      `${this.CORPORATE_PROFILE_API_PATH}/${conferenceId}`,
      AuthType.Passwordless
    );
    return this.mapAttendeeResponse(response);
  }

  public async put(conferenceId: Conference["_id"], data: AttendeeViewModel): Promise<ApiResponse<AttendeeViewModel>> {
    const payload = new ProfilePayload(data);
    const response = await this.apiService.put<ProfilePayload, Attendee>(
      `${this.PROFILE_API_PATH}/${conferenceId}`,
      payload,
      AuthType.Passwordless
    );
    return this.mapAttendeeResponse(response);
  }

  public async putCorporate(
    conferenceId: Conference["_id"],
    data: CorporateProfile
  ): Promise<ApiResponse<CorporateProfile>> {
    const payload = new CorporateProfilePayload(data);
    return await this.apiService.put<CorporateProfilePayload, CorporateProfile>(
      `${this.CORPORATE_PROFILE_API_PATH}/${conferenceId}`,
      payload,
      AuthType.Passwordless
    );
  }

  public async setTimeZonePreference(
    conferenceId: Conference["_id"],
    data: Pick<AttendeeViewModel, "time_zone">
  ): Promise<ApiResponse<Pick<Attendee, "time_zone">>> {
    return this.apiService.put<Pick<AttendeeViewModel, "time_zone">, Pick<Attendee, "time_zone">>(
      `${this.TIME_ZONE_API_PATH}/${conferenceId}`,
      data,
      AuthType.Passwordless
    );
  }

  private mapAttendeeResponse(response: ApiResponse<Attendee>): ApiResponse<AttendeeViewModel> {
    return new ApiResponse({
      ...response,
      data: new AttendeeViewModel(response?.data),
    });
  }
}
