import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import localForage from "localforage";
import config from "../../config/config";
import AdminTokenService from "../adminToken/adminToken.service";
import { AdminAuth0Service } from "../auth0/adminAuth0/adminAuth0.service";
import PasswordlessTokenService from "../passwordlessToken/passwordlessToken.service";
import {
  ApiMethod,
  ApiResponse,
  AuthType,
  ContentType,
  OfflineApiServiceKey,
  ResponseCode,
  ResponseCodeKey,
} from "./api.definition";

export default class ApiService {
  adminAuth0Service = new AdminAuth0Service();

  constructor() {
    localForage.config({
      driver: localForage.LOCALSTORAGE,
      name: "offline",
      storeName: "keyvaluepairs",
    });
  }

  public get<TResponse>(
    relativeUrl: string,
    authType = AuthType.Protected,
    offlineApiServiceKey?: OfflineApiServiceKey
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<void, TResponse>(
      relativeUrl,
      ApiMethod.Get,
      authType,
      null,
      ContentType.Json,
      offlineApiServiceKey
    );
  }

  public post<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    contentType = ContentType.Json,
    authType = AuthType.Protected
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Post, authType, data, contentType);
  }

  public put<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    authType = AuthType.Protected,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Put, authType, data, contentType);
  }

  public patch<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    authType = AuthType.Protected,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Patch, authType, data, contentType);
  }

  public delete<TBody, TResponse = TBody>(
    relativeUrl: string,
    data?: TBody,
    authType = AuthType.Protected
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Delete, authType, data);
  }

  public requestBlob<TBody>(
    relativeUrl: string,
    authType = AuthType.Protected,
    data?: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<Blob>> {
    const method = ApiMethod.Post;
    const authToken = this.getAuthToken(authType);

    if (!this.isAuthorized(authType, authToken)) {
      return Promise.resolve(this.getUnauthorized());
    }

    return this.request<TBody>(`${config.api.url}${relativeUrl}`, method, contentType, data, authToken, {
      headers: { Accept: ContentType.Pdf },
    })
      .then((response) => this.inspectStatusCode(response))
      .then((response) => response.blob())
      .then((blob) => {
        if ("type" in blob) {
          return new ApiResponse({ success: true, data: blob });
        }
        throw new Error("Unknown file returned.");
      })
      .catch((e: Error) => {
        const { message } = e;
        return new ApiResponse<Blob>({ success: false, message });
      });
  }

  public getOfflineData<T>(key: OfflineApiServiceKey): Promise<T> {
    if (isNullOrWhiteSpace(key)) return Promise.resolve(null);
    return localForage.getItem(key);
  }

  makeExternalRequest<TBody>(
    absolutePath: string,
    method: ApiMethod,
    data?: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<never>> {
    return this.request<TBody>(`${absolutePath}`, method, contentType, data)
      .then((response) => this.inspectStatusCode(response))
      .then((response) => {
        if (response.status === ResponseCode.OkNoContent) {
          return new ApiResponse<never>({ success: true });
        }
        throw new Error(`Failed to upload: ${response.status}`);
      })
      .catch((e: Error) => {
        const { message } = e;
        return new ApiResponse<never>({ success: false, message });
      });
  }

  private isAuthorized(authType: AuthType, authToken: string): boolean {
    return (authType !== AuthType.Public && !isNullOrWhiteSpace(authToken)) || authType === AuthType.Public;
  }

  private getUnauthorized<TResponse>(): ApiResponse<TResponse> {
    return new ApiResponse<TResponse>({ success: false, message: "Unauthorized" });
  }

  private async makeRequest<TBody, TResponse = TBody>(
    relativeUrl: string,
    method: ApiMethod,
    authType = AuthType.Protected,
    payload?: TBody,
    contentType = ContentType.Json,
    offlineApiServiceKey?: OfflineApiServiceKey
  ): Promise<ApiResponse<TResponse>> {
    const authToken = this.getAuthToken(authType);
    if (!this.isAuthorized(authType, authToken)) {
      return Promise.resolve(this.getUnauthorized());
    }

    const useOffline = !isNullOrWhiteSpace(offlineApiServiceKey);
    try {
      const response = await this.request<TBody>(`${config.api.url}${relativeUrl}`, method, contentType, payload, authToken);
      this.inspectStatusCode(response);
      const json = await response
        .json()
        .then((x) => new ApiResponse<TResponse>({ ...x, isDeleted: method === ApiMethod.Delete }));

      if ("success" in json) {
        if (useOffline) {
          localForage.setItem(offlineApiServiceKey, { ...json, offline: true });
        }
        return this.inspectForError(json);
      }
      throw new Error("Unknown data object returned.");
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { message } = error as any;
      if (useOffline) {
        const offlineResponse = await this.getOfflineData<ApiResponse<TResponse>>(offlineApiServiceKey);
        if (!isEmpty(offlineResponse)) {
          console.error(message);
          return offlineResponse;
        }
      }
      return new ApiResponse<TResponse>({ success: false, message });
    }
  }

  private request<T>(
    apiPath: string,
    method: ApiMethod,
    contentType: string,
    payload?: T,
    authToken?: string,
    overrideOptions?: RequestInit
  ): Promise<Response> {
    const defaultHeaders = {
      "Content-Type": contentType,
      "Authorization": `Bearer ${authToken}`,
    };

    if (isEmpty(authToken)) {
      delete defaultHeaders["Authorization"];
    }

    if (contentType === ContentType.FormData) {
      delete defaultHeaders["Content-Type"];
    }

    const body = !isEmpty(payload) && contentType === ContentType.Json ? JSON.stringify(payload) : payload;

    const { headers: extraHeaders, ...extraOptions } = overrideOptions || {};

    if (extraOptions.cache !== "no-store") {
      extraOptions.cache = "no-store";
    }

    const options: RequestInit = {
      method,
      body: body as BodyInit,
      ...extraOptions,
      headers: {
        ...defaultHeaders,
        ...extraHeaders,
      },
    };

    return fetch(apiPath, options);
  }

  private getAuthToken(authType: AuthType): string {
    switch (authType) {
      case AuthType.Protected:
        const adminTokenService = new AdminTokenService();
        if (adminTokenService.isAuthenticated()) {
          return adminTokenService.getAuthToken();
        }
        break;
      case AuthType.Passwordless:
        const passwordlessTokenService = new PasswordlessTokenService();
        if (passwordlessTokenService.isAuthenticatedBySessionId()) {
          return passwordlessTokenService.getTokenFromSessionId();
        }
        break;
      case AuthType.Public:
      default:
        return null;
    }
    return null;
  }

  private inspectStatusCode(response: Response): Response {
    const { status } = response;

    if ([ResponseCode.Ok, ResponseCode.OkNoContent].includes(status)) return response;
    if (Object.values(ResponseCode).includes(status)) {
      throw new Error(ResponseCodeKey[status]);
    }
    throw new Error(`An error occurred: Status Code(${status})`);
  }

  private inspectForError = <T>(response: ApiResponse<T>): ApiResponse<T> => {
    if (!isNil(response)) {
      const { message, success } = response;

      !success && console.error(`API error: ${message}`);
      return response;
    } else {
      const errorMessage = "An error occurred";
      throw new Error(errorMessage);
    }
  };
}
