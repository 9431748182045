import "./supportedBrowser.component.scss";
import {
  Anchor,
  AnchorTarget,
  AnchorTheme,
  Badge,
  BadgeTheme,
  BaseComponentWithChildrenProps,
  PlaceholderContent,
  useDeviceConfig,
} from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import EdgeLogo from "./edge.svg";
import FallbackImage from "./fallback-error.svg";
import GoogleLogo from "./google.svg";
import {
  EdgeDownloadUrl,
  SupportedBrowserClassName,
  GoogleDownloadUrl,
  SupportedBrowserWarningSessionStorage,
  SupportedBrowserIdModel as IdModel,
} from "./supportedBrowser.definition";
import { isBrowserSupported, isLocalStorageSupported, isSessionStorageSupported } from "./supportedBrowser.utils";

const SupportedBrowser = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const { children } = props;

  const { device, browser, browserVersion } = useDeviceConfig();
  const hasLocalStorageSupport = useMemo(isLocalStorageSupported, []);
  const hasSessionStorageSupport = useMemo(isSessionStorageSupported, []);

  const [override, setOverride] = useState(
    hasSessionStorageSupport &&
      sessionStorage.getItem(SupportedBrowserWarningSessionStorage.key) === SupportedBrowserWarningSessionStorage.value
  );

  const browserSupport = useMemo(
    () => isBrowserSupported({ browser, browserVersion: +browserVersion, device, override }),
    [browser, browserVersion, device, override]
  );

  function handleOverride(): void {
    if (hasSessionStorageSupport) {
      sessionStorage.setItem(SupportedBrowserWarningSessionStorage.key, SupportedBrowserWarningSessionStorage.value);
    }
    setOverride(true);
  }

  if (browserSupport) return <>{children}</>;

  return (
    <div id={IdModel.id} className={SupportedBrowserClassName.Base}>
      <PlaceholderContent
        id={IdModel.placeholderContent?.id}
        title="Browser Not Supported"
        image={FallbackImage}
        subtitle={
          <>
            <p>
              Your current browser cannot support our content. Please make sure your
              <br />
              browser is fully updated or try a different browser below.
            </p>
            <div className={SupportedBrowserClassName.Content}>
              <div className={SupportedBrowserClassName.Title}>
                {hasLocalStorageSupport ? "Recommended" : "Supported"} Browsers
              </div>
              <div className={SupportedBrowserClassName.Container}>
                <div className={SupportedBrowserClassName.ContainerItem}>
                  <img className={SupportedBrowserClassName.Logo} src={GoogleLogo} alt="Google Chrome" />
                  <Anchor
                    id={IdModel.google?.id}
                    className={SupportedBrowserClassName.Anchor}
                    theme={AnchorTheme.Rain}
                    url={GoogleDownloadUrl}
                    target={AnchorTarget.Blank}
                  >
                    Google Chrome
                  </Anchor>
                </div>
                <div className={SupportedBrowserClassName.ContainerItem}>
                  <img className={SupportedBrowserClassName.Logo} src={EdgeLogo} alt="Microsoft Edge" />
                  <Anchor
                    id={IdModel.edge?.id}
                    className={SupportedBrowserClassName.Anchor}
                    theme={AnchorTheme.Rain}
                    url={EdgeDownloadUrl}
                    target={AnchorTarget.Blank}
                  >
                    Microsoft Edge
                  </Anchor>
                </div>
              </div>
            </div>
            <div className={SupportedBrowserClassName.Required}>
              <div className={SupportedBrowserClassName.Title}>Required Feature</div>
              <div className={SupportedBrowserClassName.RequiredContent}>
                <span className={SupportedBrowserClassName.RequiredLabel}>Local Storage</span>
                <Badge id={IdModel.localStorage.id} theme={hasLocalStorageSupport ? BadgeTheme.Teal : BadgeTheme.Spice}>
                  {hasLocalStorageSupport ? "" : "Not "}Detected
                </Badge>
              </div>
            </div>
          </>
        }
      />
      {hasLocalStorageSupport && (
        <>
          <div className={SupportedBrowserClassName.ContinueFooter}>
            <Anchor
              id={IdModel.override.id}
              className={SupportedBrowserClassName.ContinueAnchor}
              theme={AnchorTheme.Rain}
              onClick={handleOverride}
            >
              Continue
            </Anchor>
            with my existing browser
          </div>
          <div className={SupportedBrowserClassName.ContinueFooter}>
            By continuing with your existing browser, you may experience degraded functionality and/or performance.
          </div>
        </>
      )}
      <div className="fallback_logo q4i-logo" />
    </div>
  );
};

export default memo(SupportedBrowser);
