import { HTMLReactParserOptions, Element, attributesToProps, domToReact } from "html-react-parser";
import React from "react";

export const htmlParseDefaultOptions = {
  replace: (domNode: Element) => {
    if (domNode.name === "a") {
      const props = attributesToProps(domNode.attribs);
      return (
        <a target="_blank" rel="noopener noreferrer" {...props}>
          {domToReact(domNode.children)}
        </a>
      );
    }
  },
} as HTMLReactParserOptions;
