import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";

export interface LoadingStateProps extends Pick<BaseComponentWithChildrenProps, "children"> {
  error: boolean;
  loading: boolean;
}

export enum LoadingStateStatus {
  Loading,
  Failed,
  Ready,
}
