import "./questionCardWrapper.component.scss";
import {
  Button,
  ButtonTheme,
  Card,
  Checkbox,
  ErrorModel,
  Form,
  Grid,
  GridColumn,
  isEmpty,
  PopoverMenu,
  PopoverMenuTheme,
  Select,
  SelectPreset,
  Textarea,
  Textbox,
  useVisibility,
} from "@q4/nimbus-ui";
import React, { useCallback, useMemo, useRef } from "react";
import { AttendeeType } from "../../services/attendee/attendee.model";
import { QuestionStatus, QuestionType } from "../../services/questionGroup/questionGroup.model";
import { QuestionCardFieldLimit, QuestionCardLabels, QuestionDisplayLabels } from "../questionCard/questionCard.definition";
import { QuestionTemporaryId } from "../registrationForm/registrationForm.definition";
import {
  QuestionCardWrapperClassNames,
  QuestionCardWrapperIdModel,
  QuestionCardWrapperProps,
} from "./questionCardWrapper.definition";

export function QuestionCardWrapper(props: QuestionCardWrapperProps): JSX.Element {
  const {
    id,
    currentQuestion,
    handleQuestionUpdate,
    typeConfigurationFields,
    textConfigurationFields,
    currentQuestionTypeLabel,
    handleQuestionMoveDown,
    handleQuestionMoveUp,
    idx,
    isMoveDownDisabled,
    isMoveUpDisabled,
    formKeys,
    formErrors,
    dataIndex,
    setFormErrors,
    handleQuestionRemove,
    onDuplicate,
  } = props;
  const { _id: questionId, answer_type, title, status, required, is_individual_corporate } = currentQuestion || {};

  const buttonRef = useRef<HTMLButtonElement>();
  const [visible, onOpen, onClose] = useVisibility();

  const idModel = useMemo(() => new QuestionCardWrapperIdModel(id), [id]);

  const questionTypes = useMemo(() => Object.values(QuestionDisplayLabels), []);
  const isQuestionEditable = useMemo(() => questionId?.includes(QuestionTemporaryId), [questionId]);

  const getQuestionType = (newDisplayLabel: string, currentQuestionType: QuestionType): QuestionType => {
    switch (newDisplayLabel) {
      case QuestionDisplayLabels.text:
        return currentQuestionType === QuestionType.LongAnswer ? currentQuestionType : QuestionType.ShortAnswer;

      case QuestionDisplayLabels.choice:
        return currentQuestionType === QuestionType.MultipleChoice ? currentQuestionType : QuestionType.Choice;

      case QuestionDisplayLabels.select:
        return currentQuestionType === QuestionType.CustomSelection ? currentQuestionType : QuestionType.Selection;

      case QuestionDisplayLabels.consent:
        return QuestionType.Consent;

      default:
        return QuestionType.ShortAnswer;
    }
  };

  const handleStatusChange = useCallback(
    (value): void => {
      const questionStatus = isQuestionEditable ? QuestionStatus.Active : QuestionStatus.Modified;
      handleQuestionUpdate({
        ...currentQuestion,
        status: value ? questionStatus : QuestionStatus.Hidden,
      });
    },
    [currentQuestion, handleQuestionUpdate, isQuestionEditable]
  );

  const handleRequiredChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        required: value,
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const handleIsIndividualChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        is_individual_corporate: value,
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const handleDuplicateClick = useCallback(() => {
    onDuplicate(currentQuestion);
  }, [currentQuestion, onDuplicate]);

  const headerIcons = useMemo(
    () => (
      <div className={QuestionCardWrapperClassNames.headerIcons}>
        <Button
          id={idModel.duplicate.id}
          icon="q4i-clone-duplicate-4pt"
          theme={ButtonTheme.Transparent}
          onClick={handleDuplicateClick}
        />
        <Button
          id={idModel.moveUp.id}
          className="rotated-icon caret-button"
          icon="q4i-caret-down-4pt"
          theme={ButtonTheme.Transparent}
          disabled={isMoveUpDisabled}
          onClick={handleQuestionMoveUp}
        />
        <Button
          id={idModel.moveDown.id}
          className="caret-button"
          icon="q4i-caret-down-4pt"
          theme={ButtonTheme.Transparent}
          disabled={isMoveDownDisabled}
          onClick={handleQuestionMoveDown}
        />
      </div>
    ),
    [
      idModel.duplicate.id,
      idModel.moveUp.id,
      idModel.moveDown.id,
      handleDuplicateClick,
      isMoveUpDisabled,
      handleQuestionMoveUp,
      isMoveDownDisabled,
      handleQuestionMoveDown,
    ]
  );

  const handleTitleChange = useCallback(
    (value): void => {
      const key = `_questions[${dataIndex || idx}].title`;
      const formKey = formKeys?.find((form) => form.key === key);

      if (!isEmpty(formKey)) {
        setFormErrors((previousState) => ({
          ...previousState,
          [key]: new ErrorModel(formKey?.message, formKey?.validation(value)),
        }));
      }

      handleQuestionUpdate({
        ...currentQuestion,
        title: value,
      });
    },
    [currentQuestion, dataIndex, formKeys, handleQuestionUpdate, idx, setFormErrors]
  );

  const handleQuestionTypeChange = useCallback(
    (value): void => {
      handleQuestionUpdate({
        ...currentQuestion,
        answer_type: {
          ...currentQuestion?.answer_type,
          field_type: getQuestionType(value, currentQuestion?.answer_type?.field_type),
        },
      });
    },
    [currentQuestion, handleQuestionUpdate]
  );

  const basicFields = useMemo(() => {
    const titleComponent =
      answer_type?.field_type === QuestionType.Consent ? (
        <Textarea
          id={idModel.title.id}
          maxLength={QuestionCardFieldLimit.Consent}
          value={title}
          onChange={handleTitleChange}
        />
      ) : (
        <Textbox id={idModel.title.id} maxLength={QuestionCardFieldLimit.Title} value={title} onChange={handleTitleChange} />
      );

    return [
      {
        key: QuestionCardLabels.questionTitle,
        width: "1-of-2",
        smallWidth: "1-of-2",
        required: true,
        error: formErrors?.[`_questions[${dataIndex || idx}].title`],
        label: QuestionCardLabels.questionTitle,
        children: titleComponent,
      },
      {
        key: QuestionCardLabels.answerType,
        width: "1-of-2",
        smallWidth: "1-of-2",
        label: QuestionCardLabels.answerType,
        children: (
          <Select
            id={idModel.answerType.id}
            value={currentQuestionTypeLabel}
            options={questionTypes}
            preset={SelectPreset.Autocomplete}
            disabled={!isQuestionEditable}
            onChange={handleQuestionTypeChange}
          />
        ),
      },
    ];
  }, [
    answer_type?.field_type,
    idModel.title.id,
    idModel.answerType.id,
    title,
    handleTitleChange,
    formErrors,
    dataIndex,
    idx,
    currentQuestionTypeLabel,
    questionTypes,
    isQuestionEditable,
    handleQuestionTypeChange,
  ]);

  return (
    <div id={idModel.id}>
      <Card
        id={idModel.card.id}
        className={QuestionCardWrapperClassNames.base}
        title={`Question ${idx ?? ""}`}
        headerChildren={headerIcons}
      >
        <Form id={idModel.basicForm.id} fields={basicFields || []} />

        <Grid className={QuestionCardWrapperClassNames.rowWrapper}>
          <GridColumn
            id={idModel.gridColumnLeft.id}
            smallWidth="1-of-2"
            width="1-of-2"
            className={QuestionCardWrapperClassNames.columnWrapper}
          >
            {textConfigurationFields}
          </GridColumn>
          <GridColumn
            id={idModel.gridColumnRight.id}
            smallWidth="1-of-2"
            width="1-of-2"
            className={QuestionCardWrapperClassNames.columnWrapper}
          >
            {typeConfigurationFields}
          </GridColumn>
        </Grid>
        <div id={idModel.footer} className={QuestionCardWrapperClassNames.footer}>
          {currentQuestion?.attendee_types?.includes(AttendeeType.Corporate) && (
            <Checkbox
              id={idModel.isIndividual.id}
              disabled={!currentQuestion?._id?.includes(QuestionTemporaryId)}
              checked={!!is_individual_corporate}
              label="Individual Responses"
              onChange={handleIsIndividualChange}
            />
          )}
          <Checkbox
            id={idModel.requiredField.id}
            checked={!!required}
            label="Required Field"
            onChange={handleRequiredChange}
          />
          <Checkbox
            id={idModel.showField.id}
            checked={status === QuestionStatus.Modified || status === QuestionStatus.Active}
            label="Show Field"
            onChange={handleStatusChange}
          />
          <div>
            <span>
              <Button
                className="menu-button"
                icon="q4i-utility-4pt"
                id={idModel.utilityIcon.id}
                ref={buttonRef}
                theme={ButtonTheme.Transparent}
                onClick={onOpen}
              />
              <PopoverMenu
                visible={visible}
                anchorTargetElementId={idModel.utilityIcon.id}
                theme={PopoverMenuTheme.LightGrey}
                options={[
                  {
                    id: idModel.removeQuestion,
                    label: "Delete Question",
                    theme: ButtonTheme.LightGrey,
                    icon: "q4i-trashbin-4pt",
                    onClick: handleQuestionRemove,
                  },
                ]}
                masked
                onCloseRequest={onClose}
              />
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
}
