import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import type { Company } from "../../../../../services/company/company.model";
import type { Conference } from "../../../../../services/conference/conference.model";

export interface RegistrationConfirmationProps extends BaseComponentProps {
  coordinator?: Conference["coordinator"];
  companyUrlSuffix: Company["url_suffix"];
  conferencePath: Conference["Path"];
  registrant: RegistrantViewModel;
  registrationExists?: boolean;
  isAutoApprove?: Conference["auto_approve"];
  isEmailEnabled?: Conference["email_configuration"]["registration_complete_enabled"];
  displayLoginLink?: boolean;
  profileUrl?: string;
}

export class ConfirmationIdModel extends IdModelBase {
  profileLink: string;
  coordinatorSection: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.profileLink = `${this.id}ProfileLink`;
    this.coordinatorSection = `${this.id}CoordinatorSection`;
  }
}
