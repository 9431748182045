import { Checkbox as NuiCheckbox } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { Answer } from "../../../../services/answer/answer.model";
import { QuestionsFormConsentIdModel, QuestionsFormConsentProps } from "./questionsFormConsent.definition";

function QuestionsFormConsent(props: QuestionsFormConsentProps): JSX.Element {
  const { id, question, answer, onChange } = props;

  const idModel = useMemo(() => new QuestionsFormConsentIdModel(id), [id]);

  const onCheckbox = useCallback(
    (checked: boolean) => {
      onChange(new Answer({ _question: question._id, answer: checked }));
    },
    [onChange, question._id]
  );

  return (
    <div id={idModel.id}>
      <NuiCheckbox id={idModel.checkbox.id} label={question.place_holder} onChange={onCheckbox} checked={!!answer} />
    </div>
  );
}

export default memo(QuestionsFormConsent);
