import "./importModal.component.scss";
import { ButtonTheme, isEmpty, Message, MessageType, Modal, Swapable } from "@q4/nimbus-ui";
import { capitalize } from "lodash";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import RequestProgressStatus from "../../../../../components/requestProgressStatus/components/progress/requestProgressStatus.component";
import { RequestProgressStatusName } from "../../../../../components/requestProgressStatus/components/progress/requestProgressStatus.definition";
import ImportConfirm from "../../../../../services/import/components/confirm/importConfirm.component";
import ImportSettings from "../../../../../services/import/components/settings/importSettings.component";
import {
  ImportSettingsClearType,
  ImportSettingsImportType,
  ImportSettingsUpdateType,
} from "../../../../../services/import/components/settings/importSettings.definition";
import { ImportModalClassName, ImportStep } from "./importModal.definition";
import type { ImportModalProps } from "./importModal.definition";

const ImportModal = (props: ImportModalProps): JSX.Element => {
  const {
    currentStep,
    loading,
    results,
    requestCount,
    totalCount,
    type,
    visible,
    onImport,
    onImportReset,
    onImportStop,
    onImportSettings,
  } = props;

  // #region State Hook
  const [messageVisible, setMessageVisible] = useState(false);
  const [selectedClearType, setSelectedClearType] = useState(ImportSettingsClearType.None);
  const [selectedImportType, setSelectedImportType] = useState(ImportSettingsImportType.All);
  const [selectedUpdateType, setSelectedUpdateType] = useState(ImportSettingsUpdateType.All);
  // #endregion

  // #region Effect Hook
  const clearType = useRef(ImportSettingsClearType.None);
  const importType = useRef(ImportSettingsImportType.All);
  const updateType = useRef(ImportSettingsUpdateType.All);
  const disabled = useMemo(() => isEmpty(requestCount), [requestCount]);

  const handleSettingConfirm = useCallback((): void => {
    onImportSettings({
      clearType: clearType.current,
      importType: importType.current,
      updateType: updateType.current,
    });
  }, [onImportSettings]);

  const modalModel = useMemo(() => {
    const cancelKey = "import-modal_cancel";
    switch (currentStep) {
      case ImportStep.Import:
        return {
          title: "Importing",
          subtitle: <span>Importing: {capitalize(type)}s</span>,
          footerActions: [
            {
              key: cancelKey,
              theme: ButtonTheme.DarkSlate,
              label: "CANCEL",
              disabled: !loading,
              onClick: handleWarningCancel,
            },
            {
              key: "import-modal_save",
              theme: ButtonTheme.Citrus,
              label: "DONE",
              loading: loading,
              onClick: onImportStop,
            },
          ],
        };
      case ImportStep.Settings:
        return {
          title: "Settings",
          subtitle: <span>Please confirm that the following settings are correct.</span>,
          footerActions: [
            {
              key: cancelKey,
              theme: ButtonTheme.DarkSlate,
              label: "CANCEL",
              disabled: loading,
              onClick: onImportStop,
            },
            {
              key: "import-modal_settings",
              theme: ButtonTheme.Citrus,
              label: "UPLOAD CSV",
              loading: loading,
              onClick: handleSettingConfirm,
            },
          ],
        };
      case ImportStep.Confirm:
      default:
        return {
          title: "Confirm",
          subtitle: "Please confirm that the following items are correct.",
          footerActions: [
            {
              key: cancelKey,
              theme: ButtonTheme.DarkSlate,
              label: "CANCEL",
              onClick: onImportStop,
            },
            {
              key: "import-modal_save",
              theme: ButtonTheme.Citrus,
              label: "CONFIRM",
              disabled: disabled,
              onClick: onImport,
            },
          ],
        };
    }
  }, [currentStep, disabled, loading, type, handleSettingConfirm, onImport, onImportStop]);
  // #endregion

  // #region Handlers
  function handleClearTypeOnChange(index: number): void {
    clearType.current = index;
    setSelectedClearType(clearType.current);
  }

  function handleImportTypeOnChange(index: number): void {
    importType.current = index;
    setSelectedImportType(importType.current);
  }

  function handleImportUpdateOnChange(index: number): void {
    updateType.current = index;
    setSelectedUpdateType(updateType.current);
  }

  function handleWarningCancel(): void {
    setMessageVisible(true);
  }

  function handleWarningCancelRequest(): void {
    setMessageVisible(false);
  }

  function handleImportingCancel(): void {
    handleWarningCancelRequest();
    onImportStop();
    onImportReset();
  }

  function handleReset(): void {
    if (visible) return;
    onImportReset();
  }
  // #endregion

  return (
    <>
      <Modal
        className={ImportModalClassName.ImportModal}
        loading={loading && currentStep !== ImportStep.Import}
        title={modalModel.title}
        subtitle={modalModel.subtitle}
        visible={!messageVisible && visible}
        includeExit={currentStep !== ImportStep.Import}
        disableMaskClickToClose={true}
        footerActions={modalModel.footerActions}
        onCloseRequest={onImportStop}
        ghostableProps={{ onExited: handleReset }}
      >
        <Swapable
          selected={currentStep}
          layers={[
            <ImportSettings
              id="ImportModalSettings"
              key="presentation-import_settings"
              type={type}
              onClearTypeChange={handleClearTypeOnChange}
              selectedClearType={selectedClearType}
              onImportTypeChange={handleImportTypeOnChange}
              selectedImportType={selectedImportType}
              onImportUpdateChange={handleImportUpdateOnChange}
              selectedImportUpdate={selectedUpdateType}
            />,
            <ImportConfirm
              key="presentation-import_confirm"
              totalCount={totalCount}
              results={results}
              disabled={disabled}
            />,
            <RequestProgressStatus
              id="RequestProgressStatus"
              key="presentation-import_progress"
              results={results}
              inProgress={loading}
              totalCount={requestCount}
              statusText={RequestProgressStatusName.Importing}
              showCsvExport={true}
              showStatusTable={true}
            />,
          ]}
        />
      </Modal>
      <Message
        visible={messageVisible}
        title="Cancel Import"
        message="Are you sure you want to cancel importing?"
        messageType={MessageType.Warning}
        primaryActionProps={{
          label: "CANCEL",
          onClick: handleImportingCancel,
        }}
        secondaryActionProps={{
          label: "RESUME",
          onClick: handleWarningCancelRequest,
        }}
        onCloseRequest={handleWarningCancelRequest}
      />
    </>
  );
};

export default memo(ImportModal);
