import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { createContext, useState } from "react";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Conference } from "../../services/conference/conference.model";
import type { Agenda } from "../../services/user/agenda/agenda.model";
import type { LobbyContextState } from "./lobby.context.definition";

export const LobbyContext = createContext<Partial<LobbyContextState>>({});

export const LobbyProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [conference, setConference] = useState<Conference>(null);
  const [conferences, setConferences] = useState<Conference[]>(null);
  const [profile, setProfile] = useState<AttendeeViewModel>(null);
  const [agenda, setAgenda] = useState<Agenda>(null);

  return (
    <LobbyContext.Provider
      value={{
        conference,
        conferences,
        profile,
        agenda,
        setConference,
        setConferences,
        setProfile,
        setAgenda,
      }}
    >
      {props.children}
    </LobbyContext.Provider>
  );
};
