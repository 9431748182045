import "./dayTabs.component.scss";
import {
  Button,
  ButtonTheme,
  ComponentSizeModifier,
  getClassName,
  isNullOrWhiteSpace,
  Tabs,
  TabsTheme,
} from "@q4/nimbus-ui";
import { isEqual } from "lodash";
import React, { memo, useMemo, useState, useEffect, useRef } from "react";
import { DayTabsProps, DayTabsClassName, DayTabsIdModel } from "./dayTabs.definition";

const DayTabs = (props: DayTabsProps): JSX.Element => {
  const { id, className, items: itemsProp, disabled, limit: limitProp, onChange } = props;

  const idModel = useMemo(() => new DayTabsIdModel(id), [id]);
  const updatedItems = useRef(itemsProp);

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(0);

  const baseClassName = useMemo(
    () => getClassName(DayTabsClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const limit = useMemo(() => limitProp ?? 5, [limitProp]);

  const items = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return itemsProp.slice(firstPageIndex, lastPageIndex).map((x) => ({ ...x, disabled: disabled }));
  }, [currentPage, disabled, itemsProp, limit]);

  const lastPage = useMemo(() => Math.ceil(itemsProp?.length / limit), [itemsProp?.length, limit]);
  const showPager = useMemo(() => lastPage > 1, [lastPage]);

  useEffect(() => {
    if (!isEqual(updatedItems.current, itemsProp)) {
      // when creating a new schedule and date range has changed, fall back to first day
      updatedItems.current = itemsProp;
      setSelected(0);
      setCurrentPage(1);
    }
  }, [itemsProp]);

  function getCurrentPage(next = true): number {
    const page = next ? currentPage + 1 : currentPage - 1;
    setCurrentPage(page);
    setSelected(0);
    return page;
  }

  function handleNextPage() {
    const page = getCurrentPage();
    const currentItem = (page - 1) * limit;
    const lastItem = itemsProp.length - 1;

    onChange(currentItem > lastItem ? lastItem : currentItem);
  }

  function handleBackPage() {
    const page = getCurrentPage(false);
    onChange((page - 1) * limit);
  }

  function handleOnTabChange(index: number): void {
    setSelected(index);
    onChange(index + (currentPage - 1) * limit);
  }

  return (
    <div id={idModel.id} className={baseClassName}>
      <Tabs
        id={idModel.tabs}
        className={DayTabsClassName.Tabs}
        selected={selected}
        size={ComponentSizeModifier.Compact}
        items={items}
        theme={TabsTheme.LightGrey}
        onChange={handleOnTabChange}
      />
      {showPager && (
        <div className={DayTabsClassName.Pager}>
          <Button
            id={idModel.previousButton.id}
            theme={ButtonTheme.LightGrey}
            disabled={currentPage === 1 || disabled}
            icon="ni-caret-left-4pt"
            onClick={handleBackPage}
          />
          <Button
            id={idModel.nextButton.id}
            theme={ButtonTheme.LightGrey}
            disabled={lastPage === currentPage || disabled}
            icon="ni-caret-right-4pt"
            onClick={handleNextPage}
          />
        </div>
      )}
    </div>
  );
};

export default memo(DayTabs);
