import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useState } from "react";
import {
  PublicLink,
  PublicLinkEncryptRequest,
  PublicLinkEncryptResponse,
} from "../../../services/public/link/publicLink.definition";
import PublicLinkService from "../../../services/public/link/publicLink.service";
import { PublicLinkHookModel } from "./usePublicLink.definition";

export const usePublicLink = (): PublicLinkHookModel => {
  const [loading, setLoading] = useState(false);

  const getId = useCallback((data: PublicLinkEncryptRequest): Promise<PublicLinkEncryptResponse> => {
    setLoading(true);
    const publicLinkService = new PublicLinkService();

    return publicLinkService
      .post(data)
      .then((response) => {
        if (!response?.success) {
          console.error(response);
        }

        return response?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getLink = useCallback((id: string): Promise<PublicLink["url"]> => {
    setLoading(true);
    const publicLinkService = new PublicLinkService();
    const notificationService = new NotificationService();

    return publicLinkService
      .get(id)
      .then((response) => {
        if (!response?.success) {
          console.error(response?.message);
          notificationService.error(`${response?.message}: Redirect could not be completed.`, {
            closeOnClick: true,
            autoClose: false,
          });
        }

        return response?.data?.url;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, getId, getLink };
};
