import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { Context, FunctionComponent, ReactType } from "react";

const ConsumerMapper = (props: ConsumerMapperProps): JSX.Element => {
  const { subscribingComponent: Component, contexts, index, ...remainingProps } = props;
  const idx = index || 0;

  if (idx >= contexts.length) {
    return <Component key={idx} {...remainingProps} />;
  }

  const { context: Context, mapToProps } = contexts[idx];
  return (
    <Context.Consumer key={idx}>
      {(contextProps: Record<string, unknown>): JSX.Element => {
        const mappedProp = { [mapToProps]: contextProps };
        return <ConsumerMapper {...props} {...mappedProp} index={idx + 1} />;
      }}
    </Context.Consumer>
  );
};

interface ConsumerMapperModel {
  context: Context<Record<string, unknown>>;
  mapToProps: string;
}

interface ConsumerMapperProps extends BaseComponentWithChildrenProps {
  subscribingComponent: ReactType;
  contexts: ConsumerMapperModel[];
  index?: number;
}

const contextSubscribe = <T extends unknown>(
  contexts: ConsumerMapperModel[],
  subscribingComponent: ReactType
): FunctionComponent<T> => {
  return function subscribeMapper(props: T): JSX.Element {
    return <ConsumerMapper {...props} subscribingComponent={subscribingComponent} contexts={contexts} />;
  };
};

export default contextSubscribe;
