import React, { memo, useEffect } from "react";
import { Route } from "react-router-dom";
import { AuthType } from "../../../../../services/api/api.definition";
import type { PublicRouteProps } from "./publicRoute.definition";

const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, userContext, ...rest } = props;
  const { authType, setAuthType } = userContext;

  useEffect(() => {
    if (authType === AuthType.Public || !(setAuthType instanceof Function)) return;
    setAuthType(AuthType.Public);
  }, [authType, setAuthType]);

  return <Route render={(props) => <Component {...props} />} {...rest} />;
};

export default memo(PublicRoute);
