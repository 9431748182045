import "./companyLobby.layout.scss";
import {
  Ghostable,
  isEmpty,
  isNullOrWhiteSpace,
  Layout,
  LayoutHeight,
  LayoutPadding,
  LayoutTheme,
  PlaceholderContent,
  PlaceholderContentTheme,
} from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import BackgroundPlaceholder from "../../../../../assets/itinerary/background-placeholder.png";
import CompanyBranding from "../../../../../components/companyBranding/companyBranding.component";
import LoadingSpinner from "../../../../../components/loadingSpinner/loadingSpinner.component";
import { useBackgroundImage } from "../../../../../hooks";
import { getBackgroundImageUrl } from "../../../../../utils";
import { CompanyLobbyLayoutClassName, CompanyLobbyLayoutIdModel } from "./companyLobby.layout.definition";
import type { CompanyLobbyLayoutProps } from "./companyLobby.layout.definition";
import Info from "./components/info/info.component";
import Navigation from "./components/navigation/navigation.component";
import PoweredBy from "./components/poweredBy/poweredBy.component";

const CompanyLobbyLayout = (props: CompanyLobbyLayoutProps): JSX.Element => {
  const { id, children, conference, onSignOut, profile, isAttendeeProfile } = props;

  const idModel = useMemo(() => new CompanyLobbyLayoutIdModel(id), [id]);

  const backgroundImageSrc = useMemo(() => (isEmpty(conference) ? null : conference.image_background), [conference]);
  const loadedBackgroundImage = useBackgroundImage(backgroundImageSrc);
  const backgroundImage = useMemo(() => {
    const image = !isNullOrWhiteSpace(loadedBackgroundImage) ? loadedBackgroundImage : BackgroundPlaceholder;
    return getBackgroundImageUrl(image);
  }, [loadedBackgroundImage]);

  function renderPlaceholder(): JSX.Element {
    return (
      <PlaceholderContent
        id={idModel.placeHolderContent?.id}
        className={CompanyLobbyLayoutClassName.Inner}
        theme={PlaceholderContentTheme.Dark}
        layoutProps={{
          theme: LayoutTheme.Transparent,
          height: LayoutHeight.Viewport,
        }}
        icon="q4i-conference-4pt"
        title="Oops!"
        subtitle="We're sorry. Something went wrong."
        actions={[
          {
            label: "Sign Out",
            onClick: onSignOut,
          },
        ]}
      />
    );
  }

  return (
    <div id={idModel.id} className={CompanyLobbyLayoutClassName.Base}>
      <aside
        id={idModel.splash}
        className={CompanyLobbyLayoutClassName.Splash}
        style={{
          backgroundImage,
        }}
      >
        <div className={CompanyLobbyLayoutClassName.SplashGradient} />
      </aside>
      <Ghostable ghosted={true}>
        <LoadingSpinner id={idModel.spinner} />
      </Ghostable>
      <Layout
        id={idModel.conference?.id}
        className={CompanyLobbyLayoutClassName.Inner}
        height={LayoutHeight.Auto}
        theme={LayoutTheme.Transparent}
        padding={LayoutPadding.None}
        justifyContent="flex-start"
        alignItems="stretch"
        direction="column"
      >
        <CompanyBranding
          branding={conference?.branding}
          haloProps={{ favicon: conference?.image_favicon, title: conference?.title }}
        />
        <header className={CompanyLobbyLayoutClassName.Header}>
          <img
            id={idModel.logo}
            className={CompanyLobbyLayoutClassName.Logo}
            alt="Company Logo"
            src={conference?.image_logo}
          />
          <Navigation
            id={idModel.navigation?.id}
            conference={conference}
            onSignOut={onSignOut}
            profile={profile}
            isAttendeeProfile={isAttendeeProfile}
          />
        </header>
        {isEmpty(children) ? renderPlaceholder() : children}
        <PoweredBy id={idModel.poweredBy?.id} title={conference?.title} />
        <Info id={idModel.info?.id} content={conference?.info} />
      </Layout>
    </div>
  );
};

export default memo(CompanyLobbyLayout);
