import "./meetingForm.component.scss";
import {
  useVisibility,
  getClassName,
  isNullOrWhiteSpace,
  isEmpty,
  Field,
  ButtonTheme,
  PopoverMenuTheme,
  PopoverMenuSize,
  Origin,
  MessageType,
  Form,
  FormFieldProps,
  DatePicker,
  Textbox,
  TextTheme,
  TimePicker,
  ComboBox,
  Ghostable,
  Select,
  SelectPreset,
  Chips,
  Button,
  PopoverMenu,
  Message,
  VerticalAlign,
  PopoverMenuButtonProps,
  BadgeSize,
  BadgeTheme,
  TooltipTheme,
  ChipsItemProps,
} from "@q4/nimbus-ui";
import { map, differenceBy } from "lodash";
import moment, { Moment } from "moment";
import React, { useState, useMemo, useRef, useCallback, memo } from "react";
import InfoBubble from "../../components/infoBubble/infoBubble.component";
import { DefaultTimeZone } from "../../const";
import { DateFormat, TimeFormat } from "../../definitions/date.definition";
import { AttendeeType, AttendeeViewModel } from "../../services/attendee/attendee.model";
import { ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import { Meeting } from "../../services/meeting/meeting.model";
import { SessionBase } from "../../services/session/session.model";
import {
  formatSessionDate,
  getMeetingsWithAttendees,
  getOptions,
  getPresentationsWithAttendees,
  getUTCDateTime,
  sortMeetingFormAttendeeChips,
} from "../../utils";
import { getSlotAvailability } from "../../views/admin/conferences/details/components/meetingScheduler/meetingScheduler.utils";
import AttendeeModal from "../attendee/modal/attendeeModal.component";
import SessionCustomVendorForm from "../sessionCustomVendorForm/sessionCustomVendorForm.component";
import {
  CreateNewLocationSuffix,
  MeetingEditState,
  MeetingFormChips,
  MeetingFormClassName,
  MeetingFormIdModel,
  MeetingLabelMaxLength,
} from "./meetingForm.definition";
import type { MeetingFormProps } from "./meetingForm.definition";
import { getExcludedMeetingDates } from "./meetingForm.utils";

const MeetingForm = (props: MeetingFormProps): JSX.Element => {
  const {
    id,
    attendees,
    codes,
    className,
    companies,
    conference,
    corporateProfiles,
    meeting,
    meetings,
    presentations,
    onUpdate,
    fullscreenLayout,
  } = props;

  const [currentAttendee, setCurrentAttendee] = useState<AttendeeViewModel>();
  const [attendeeInput, setAttendeeInput] = useState<string>();
  const [locationInput, setLocationInput] = useState<string>();
  const buttonRef = useRef<HTMLButtonElement>();

  const idModel = useMemo(() => new MeetingFormIdModel(id), [id]);

  const [attendeeModalVisible, handleAttendeeModalOpen, handleAttendeeModalClose] = useVisibility();
  const [suggestedTimesVisible, handleSuggestedTimesClick, handleSuggestedTimesClose] = useVisibility();
  const [messageVisible, handleMessageOpen, handleMessageClose] = useVisibility();

  const baseClassName = useMemo(
    () => getClassName(MeetingFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const allowAttendeesMenuOpen = useMemo(() => {
    const MAX_ATTENDEES_SHOWN = 20;
    const MIN_SEARCH_LENGTH = 2;
    if (!isEmpty(attendees) && attendees.length < MAX_ATTENDEES_SHOWN) return null;
    return {
      menuIsOpen: !isNullOrWhiteSpace(attendeeInput) && attendeeInput.length > MIN_SEARCH_LENGTH,
    };
  }, [attendeeInput, attendees]);
  // #region Entity effects
  const attendeeOptions = useMemo(getAttendeeOptions, [attendees, meeting]);
  const meetingRoomOptions = useMemo<string[]>(getMeetingRoomOptions, [meetings, locationInput]);

  const timeZone = useMemo(
    () => (!isNullOrWhiteSpace(conference?.time_zone) ? conference?.time_zone : DefaultTimeZone),
    [conference]
  );

  const startTime = useMemo(
    (): string => (!isEmpty(meeting?.start_date) ? meeting?.start_date?.tz(timeZone).format(TimeFormat.Military) : "00:00"),
    [meeting?.start_date, timeZone]
  );

  const isExpired = useMemo(() => {
    if (isEmpty(meeting?.end_date)) return false;

    const expired = moment.utc(meeting.end_date).isBefore(moment.utc(), "minutes");
    if (expired) {
      handleMessageOpen();
    }

    return expired;
  }, [handleMessageOpen, meeting]);

  const mappedMeetingFormChips: MeetingFormChips = useMemo(() => {
    const meetingFormChips = (meeting?._attendee || []).reduce((chips, attendee) => {
      const { _id, meeting_display_name, type } = attendee;

      const attendeeChipProps: ChipsItemProps<AttendeeViewModel> = {
        value: _id,
        label: meeting_display_name,
        data: attendee,
        locked: isExpired,
      };

      if (type === AttendeeType.Corporate) {
        chips.corporate.push(attendeeChipProps);
      } else if (type === AttendeeType.Investor) {
        chips.investor.push(attendeeChipProps);
      } else if (type === AttendeeType.Internal) {
        chips.other.push(attendeeChipProps);
      }
      return chips;
    }, new MeetingFormChips());

    meetingFormChips.investor.sort(sortMeetingFormAttendeeChips);
    meetingFormChips.corporate.sort(sortMeetingFormAttendeeChips);
    meetingFormChips.other.sort(sortMeetingFormAttendeeChips);

    return meetingFormChips;
  }, [isExpired, meeting?._attendee]);

  const startHour = useMemo((): number => {
    if (isEmpty(meeting)) return;

    if (moment.utc(meeting.start_date).isSame(moment.utc(), "day")) {
      return moment().tz(timeZone).add(1, "hours").get("hours");
    }
  }, [meeting, timeZone]);

  const { slots } = conference?.scheduler || {};

  const disabledMeetingDays = useMemo(() => {
    return getExcludedMeetingDates(conference);
  }, [conference]);

  const getSuggestedTimes = useCallback((): PopoverMenuButtonProps[] => {
    function getConflictingSessions<T extends SessionBase>(
      sessions: T[],
      currentSession: { start_date: Moment; end_date: Moment }
    ): T[] {
      return sessions.filter((session: SessionBase) => {
        const sessionEnd = moment(new Date(session.end_date.valueOf()));
        const sessionStart = moment(new Date(session.start_date.valueOf()));
        return (
          currentSession.start_date.isBefore(moment(new Date(sessionEnd.valueOf()))) &&
          currentSession.end_date.isAfter(moment(new Date(sessionStart.valueOf())))
        );
      });
    }

    function handleSuggestedTimeChange(start_date: Moment, end_date: Moment): void {
      const start = start_date.format(TimeFormat.Picker);
      const end = end_date.format(TimeFormat.Picker);
      const newMeetingState: Partial<MeetingEditState> = {
        start_date: moment(`${formatSessionDate(meeting?.start_date)} ${start}`, DateFormat.ShortStandard).tz(
          timeZone,
          true
        ),
        end_date: moment(`${formatSessionDate(meeting?.end_date)} ${end}`, DateFormat.ShortStandard).tz(timeZone, true),
      };

      onUpdate(newMeetingState);
    }
    // #endregion

    if (isEmpty(meeting)) return [];

    const { start_date, end_date, _attendee } = meeting;
    if (isEmpty(start_date) || isEmpty(end_date) || isEmpty(_attendee)) return [];

    const attendeeEmails = map(_attendee, "email");
    const attendeeIds = map(_attendee, "_id");
    const attendeeAvailability = map(_attendee, "availability") as ConferenceSchedulerSlot[][];

    // get meetings where attendees are present to be filtered on
    const meetingTimes = getMeetingsWithAttendees(meetings, attendeeEmails).filter((meeting) =>
      meeting.start_date.isSame(start_date, "day")
    );
    const presentationTimes = getPresentationsWithAttendees(presentations, attendeeIds).filter((presentation) =>
      presentation.start_date.isSame(start_date, "day")
    );
    const suggestedTimes: PopoverMenuButtonProps[] = [];

    const filteredSlots = (slots || [])
      .filter((slot) => slot.start_time.isSame(start_date, "day"))
      .filter((slot) =>
        attendeeAvailability.every((availability) => getSlotAvailability(slot.start_time.toDate(), availability))
      );

    for (let i = 0; i < filteredSlots.length; i++) {
      const slotStart = filteredSlots[i].start_time;
      const slotEnd = filteredSlots[i].end_time;

      const conflictingMeetings = getConflictingSessions(meetingTimes, {
        start_date: slotStart,
        end_date: slotEnd,
      });

      const conflictingPresentations = getConflictingSessions(presentationTimes, {
        start_date: slotStart,
        end_date: slotEnd,
      });

      if (isEmpty(conflictingMeetings) && isEmpty(conflictingPresentations)) {
        const idx = suggestedTimes.length + 1;
        const timeId = idModel.suggestedTimeOptions?.getId(idx);
        suggestedTimes.push({
          id: timeId,
          label: `${slotStart.tz(timeZone).format(TimeFormat.Picker)} - ${slotEnd.tz(timeZone).format(TimeFormat.Picker)}`,
          onClick: () => {
            handleSuggestedTimeChange(slotStart, slotEnd);
          },
        });
      }
    }

    if (isEmpty(suggestedTimes)) {
      suggestedTimes.push({
        id: idModel.noSuggestedTimes?.id,
        label: "No suggested times available on the selected day",
        onClick: () => {
          handleSuggestedTimesClose();
        },
      });
    }
    return suggestedTimes;
  }, [meeting, meetings, presentations, timeZone, idModel, slots, handleSuggestedTimesClose, onUpdate]);
  const suggestedTimes = useMemo(getSuggestedTimes, [meeting, getSuggestedTimes]);
  // #endregion

  // #region Handle Methods
  function handleDateChange(value: Moment): void {
    if (isExpired) return;

    onUpdate({
      start_date: value,
      end_date: getUTCDateTime(timeZone, value, meeting?.end_date),
    });
  }

  function handleStartTimeChange(value: string): void {
    if (isEmpty(value)) return;

    const date = getUTCDateTime(timeZone, meeting?.start_date, value);

    const meetingDateRange: Partial<MeetingEditState> = {
      start_date: date,
    };

    if (meetingDateRange.start_date.isAfter(meeting?.end_date)) {
      meetingDateRange.end_date = moment.min(moment.utc(date).add(1, "hours"), moment.utc(conference?.end_date));
    }

    onUpdate(meetingDateRange);
  }

  function handleEndTimeChange(value: string): void {
    if (isEmpty(value)) return;

    const date = getUTCDateTime(timeZone, meeting?.end_date, value);

    const meetingDateRange: Partial<MeetingEditState> = {
      end_date: date,
    };

    if (meetingDateRange.end_date.isBefore(meeting?.start_date)) {
      meetingDateRange.start_date = moment.utc(date).subtract(1, "hours");
    }

    onUpdate(meetingDateRange);
  }

  function handleAttendeeSelect(option: AttendeeViewModel): void {
    if (isEmpty(option)) return;

    const currentAttendees = meeting?._attendee ?? [];
    const newAttendees = currentAttendees.concat([
      new AttendeeViewModel({
        ...option,
      }),
    ]);

    onUpdate({ _attendee: newAttendees });
  }

  const handleAttendeeEdit = useMemo(() => {
    if (isExpired) return null;

    return (_value: string, data: AttendeeViewModel): void => {
      if (isEmpty(data)) return;
      setCurrentAttendee(data);
      handleAttendeeModalOpen();
    };
  }, [isExpired, setCurrentAttendee, handleAttendeeModalOpen]);

  const handleAttendeeRemove = useMemo(() => {
    if (isExpired) return null;

    return (value: string): void => {
      const newAttendees = (meeting?._attendee || []).filter(({ _id }) => _id !== value);
      onUpdate({ _attendee: newAttendees });
    };
  }, [isExpired, meeting, onUpdate]);

  function handleAttendeeUpdate(attendee: Partial<AttendeeViewModel>): void {
    if (isEmpty(attendee)) return;

    const currentAttendees = meeting?._attendee ?? [];
    const foundAttendee = currentAttendees.find(({ _id }) => _id === attendee._id);
    const newAttendees = isEmpty(foundAttendee)
      ? currentAttendees.concat([
          new AttendeeViewModel({
            ...attendee,
          }),
        ])
      : currentAttendees.map((currentAttendee) => {
          if (currentAttendee?._id === attendee._id) {
            return new AttendeeViewModel({
              ...currentAttendee,
              ...attendee,
            });
          }
          return currentAttendee;
        });

    onUpdate({ _attendee: newAttendees });
    handleAttendeeModalClose();
  }

  function handleMeetingRoomChange(option: string): void {
    if (isNullOrWhiteSpace(option)) {
      onUpdate({ roomLocation: "" });
      return;
    }

    let meetingRoom = option;

    if (meetingRoom.includes(CreateNewLocationSuffix)) {
      meetingRoom = meetingRoom.replace(CreateNewLocationSuffix, "");
    }

    onUpdate({ roomLocation: meetingRoom });
  }

  // #endregion

  // #region Helper Methods
  function getAttendeeOptions(): AttendeeViewModel[] {
    if (isEmpty(attendees)) return [];

    const attendeeOptions = attendees.map((x) => new AttendeeViewModel(x));
    if (isEmpty(meeting?._attendee)) return attendeeOptions;

    return differenceBy(attendeeOptions, meeting._attendee, "email");
  }

  function getMeetingRoomOptions(): string[] {
    const options = getOptions("roomLocation", meetings);
    if (isNullOrWhiteSpace(locationInput)) return options;

    return [`${locationInput}${CreateNewLocationSuffix}`, ...options];
  }

  function getTextboxHandler(key: keyof Meeting) {
    return function (value: string): void {
      onUpdate({ [key]: value });
    };
  }
  // #endregion

  const attendeeFieldLabel = useMemo(() => {
    if (isExpired) return "Attendees";

    return (
      <>
        <span>Add Attendees</span>
      </>
    );
  }, [isExpired]);

  const formFields: FormFieldProps[] = [];
  fullscreenLayout &&
    formFields.push({
      key: "Meeting Date",
      width: "1-of-1",
      label: "Date",
      children: (
        <DatePicker
          time={startTime}
          timeZone={timeZone}
          id={idModel.startDate?.id}
          value={meeting?.start_date}
          onChange={handleDateChange}
          disabledDays={disabledMeetingDays}
          disabled={isExpired}
        />
      ),
    });
  formFields.push(
    {
      key: "Start Time",
      width: fullscreenLayout ? "1-of-3" : "1-of-2",
      label: "Start Time",
      children: (
        <TimePicker
          id={idModel.startTime?.id}
          minuteSkip={5}
          startHour={startHour}
          noOptionsMessageText="No Options"
          value={meeting?.start_date?.tz(timeZone).format(TimeFormat.Picker)}
          onChange={handleStartTimeChange}
          disabled={isExpired}
        />
      ),
    },
    {
      key: "End Time",
      width: fullscreenLayout ? "1-of-3" : "1-of-2",
      label: "End Time",
      children: (
        <TimePicker
          id={idModel.endTime?.id}
          minuteSkip={5}
          startHour={startHour}
          noOptionsMessageText={"No Options"}
          value={meeting?.end_date?.tz(timeZone).format(TimeFormat.Picker)}
          onChange={handleEndTimeChange}
          disabled={isExpired}
        />
      ),
    }
  );
  fullscreenLayout &&
    formFields.push({
      key: "Suggested Times",
      width: "1-of-3",
      className: MeetingFormClassName.TooltipField,
      label: (
        <Ghostable
          ghosted={
            mappedMeetingFormChips.corporate.length + mappedMeetingFormChips.investor.length <= 1 ||
            isExpired ||
            !fullscreenLayout
          }
        >
          <InfoBubble
            badgeProps={{
              icon: "ni-warning-4pt",
              size: BadgeSize.Small,
              theme: BadgeTheme.LightGrey,
            }}
            tooltipProps={{
              label: "All attendees can participate",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          >
            Suggested Times
          </InfoBubble>
        </Ghostable>
      ),
      verticalAlign: VerticalAlign.Bottom,
      children: (
        <Ghostable
          ghosted={
            mappedMeetingFormChips.corporate.length + mappedMeetingFormChips.investor.length <= 1 ||
            isExpired ||
            !fullscreenLayout
          }
        >
          <div className={MeetingFormClassName.SuggestedTimes}>
            <Button
              id={idModel.suggestedTimes?.id}
              ref={buttonRef}
              theme={ButtonTheme.Rain}
              label="View Suggested Times"
              onClick={handleSuggestedTimesClick}
            />
            <PopoverMenu
              id={idModel.suggestedTimesList?.id}
              className={MeetingFormClassName.SuggestedTimesPopoverMenu}
              theme={PopoverMenuTheme.LightGrey}
              visible={suggestedTimesVisible}
              size={PopoverMenuSize.Small}
              targetOrigin={Origin.BottomRight}
              minWidth={false}
              popoverOrigin={Origin.TopRight}
              offsetMargin="0 0 0 50px"
              anchorTargetReference={buttonRef}
              options={suggestedTimes}
              onCloseRequest={handleSuggestedTimesClose}
            />
          </div>
        </Ghostable>
      ),
    });

  formFields.push(
    {
      key: "Meeting Room",
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: "Meeting Location",
      children: (
        <Select
          id={idModel.meetingRoom?.id}
          preset={SelectPreset.Autocomplete}
          placeholder="Select a Location"
          disabled={isExpired}
          value={meeting?.roomLocation}
          inputValue={locationInput}
          options={meetingRoomOptions}
          isClearable
          backspaceRemovesValue
          onInputChange={(input: string) => setLocationInput(input.substring(0, MeetingLabelMaxLength))}
          onChange={handleMeetingRoomChange}
        />
      ),
    },
    {
      key: "Horizontal Rule",
      width: "1-of-1",
      children: <hr />,
      margin: false,
    },
    {
      key: "Attendees",
      width: "1-of-1",
      label: attendeeFieldLabel,
      children: (
        <div>
          {isExpired ? null : (
            <ComboBox
              id={idModel.attendees?.id}
              selectProps={{
                preset: SelectPreset.Autocomplete,
                inputValue: attendeeInput,
                placeholder: "Select an attendee",
                options: attendeeOptions,
                valueKey: "meeting_display_name",
                onChange: handleAttendeeSelect,
                onInputChange: setAttendeeInput,
                disabled: isEmpty(attendeeOptions) || isExpired,
                ...allowAttendeesMenuOpen,
              }}
              chipsProps={{
                items: null,
                onRemove: null,
              }}
            />
          )}
          {mappedMeetingFormChips.corporate.length ? (
            <Field label="Corporate Attendees" labelProps={{ theme: TextTheme.LightSlate }}>
              <Chips
                id={idModel.corporateChips?.id}
                inline={false}
                className={MeetingFormClassName.CorporateAttendees}
                items={mappedMeetingFormChips.corporate}
                onClick={handleAttendeeEdit}
                onRemove={handleAttendeeRemove}
              />
            </Field>
          ) : null}
          {mappedMeetingFormChips.investor.length ? (
            <Field label="Investor Attendees" labelProps={{ theme: TextTheme.LightSlate }}>
              <Chips
                id={idModel.investorChips?.id}
                inline={false}
                className={MeetingFormClassName.InvestorAttendees}
                items={mappedMeetingFormChips.investor}
                onClick={handleAttendeeEdit}
                onRemove={handleAttendeeRemove}
              />
            </Field>
          ) : null}
          {mappedMeetingFormChips.other.length ? (
            <Field label="Other Attendees" labelProps={{ theme: TextTheme.LightSlate }}>
              <Chips
                id={idModel.otherChips?.id}
                inline={false}
                className={MeetingFormClassName.OtherAttendees}
                items={mappedMeetingFormChips.other}
                onClick={handleAttendeeEdit}
                onRemove={handleAttendeeRemove}
              />
            </Field>
          ) : null}
        </div>
      ),
    }
  );
  fullscreenLayout &&
    formFields.push(
      {
        key: "Horizontal Rule Fullscreen",
        width: "1-of-1",
        children: <hr />,
        margin: false,
      },
      {
        key: "Meeting Name Override",
        width: "1-of-1",
        smallWidth: "1-of-1",
        label: "Meeting Label",
        optional: true,
        children: (
          <Textbox
            id={idModel.overrideLabel?.id}
            value={meeting?.labelOverride}
            onChange={getTextboxHandler("labelOverride")}
            disabled={isExpired}
          />
        ),
      },
      {
        key: "Meeting Custom Vendor Info",
        width: "1-of-1",
        smallWidth: "1-of-1",
        children: (
          <SessionCustomVendorForm
            key="Meeting Form Vendor"
            id={idModel.customVendorForm?.id}
            session={meeting}
            onSessionUpdate={onUpdate}
            isExpired={isExpired}
          />
        ),
      }
    );

  return (
    <>
      <Form id={idModel.id} className={baseClassName} fields={formFields} />
      <AttendeeModal
        id={idModel.attendeeModal?.id}
        codes={codes}
        conference={conference}
        edit={true}
        attendee={currentAttendee}
        visible={attendeeModalVisible}
        companyOptions={companies}
        corporateProfiles={corporateProfiles}
        onAttendeeUpdate={handleAttendeeUpdate}
        onCloseRequest={handleAttendeeModalClose}
      />
      <Message
        id={idModel.messageModal?.id}
        visible={messageVisible}
        message={"This meeting has concluded. Editing has been disabled."}
        messageType={MessageType.Warning}
        title={"Meeting Expired"}
        primaryActionProps={{
          label: "CONFIRM",
          onClick: handleMessageClose,
        }}
        onCloseRequest={handleMessageClose}
      />
    </>
  );
};

export default memo(MeetingForm);
