import "./details.component.scss";
import { isEmpty, isNil, Text, TextPreset, TextTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { DateFormat } from "../../../../../definitions/date.definition";
import { htmlParse } from "../../../../../utils";
import { getConferenceDateLabel } from "../../../../../utils/conference/conference.utils";
import { ItineraryClassName } from "../../itinerary.definition";
import { DetailsProps, DetailsClassName, DetailsIdModel } from "./details.definition";

function Details(props: DetailsProps): JSX.Element {
  const { id, conference, lobbyOpen, lobbyOpenDate } = props;

  const idModel = useMemo(() => new DetailsIdModel(id), [id]);

  if (isEmpty(conference)) return null;

  return (
    <div id={idModel.id} className={DetailsClassName.Base}>
      <Text id={idModel.title} className={ItineraryClassName.Title} preset={TextPreset.DisplayLarge} theme={TextTheme.White}>
        {conference.title}
      </Text>
      {!isNil(conference.start_date) && !isNil(conference.end_date) && (
        <Text id={idModel.date} className={ItineraryClassName.Date} theme={TextTheme.White}>
          <i className="q4i-calendar-4pt" />
          {getConferenceDateLabel(conference)}
        </Text>
      )}
      <Text
        id={idModel.description}
        className={ItineraryClassName.Description}
        preset={TextPreset.Paragraph}
        theme={TextTheme.White}
      >
        {htmlParse(conference.description)}
      </Text>
      {!lobbyOpen && (
        <>
          <Text className={DetailsClassName.ClosedMessage} preset={TextPreset.DisplayLarge} theme={TextTheme.White}>
            The conference is not yet open.
          </Text>
          {lobbyOpenDate && (
            <Text className={ItineraryClassName.Description} preset={TextPreset.Paragraph} theme={TextTheme.White}>
              Return to this page on {lobbyOpenDate.format(DateFormat.FullDate)}
            </Text>
          )}
        </>
      )}
    </div>
  );
}

export default memo(Details);
