import type { FileUploaderApiPayload, FileUploaderApiResponse } from "@q4/nimbus-ui";
import { useCallback } from "react";
import FileUploadService from "../../services/fileUpload/fileUpload.service";
import type { FileUploadHook } from "./useFileUpload.definition";

export const useFileUpload = (): FileUploadHook => {
  return useCallback(<T extends File>(payload: FileUploaderApiPayload<T>): Promise<FileUploaderApiResponse> => {
    const { files } = payload;
    const [file] = files || [];

    return new FileUploadService().post(file).then((response) => {
      return {
        filePath: response.data?.url,
        success: response.success,
        duplicate: false,
        message: response.message,
      };
    });
  }, []);
};
