import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, MessageIdModel } from "@q4/nimbus-ui";
import { Entity } from "../../definitions/entity.definition";

export interface DeleteConfirmationMessageProps extends Pick<BaseComponentProps, "id"> {
  entity: Entity;
  customEntityTitle?: string;
  visible: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  title?: string;
  message?: string;
}

export class DeleteConfirmationMessageIdModel extends IdModelBase {
  message: MessageIdModel;
  cancel: ButtonIdModel;
  delete: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.message = new MessageIdModel(this.id);
    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.delete = new ButtonIdModel(`${this.id}DeleteButton`);
  }
}
