import { MessageType } from "@q4/nimbus-ui";
import type { MessageProps } from "@q4/nimbus-ui";
import { AdminUserContextState } from "../../contexts/adminUser/adminUser.definition";
import type { Entity, EntityBase } from "../../definitions/entity.definition";
import type { PusherMemberInfoViewModel } from "../usePusher/usePusher.definition";

export interface EditNotificationsHookProps {
  channelName: string;
  disabled: boolean;
  entity: Entity;
  id: EntityBase["_id"];
  user: AdminUserContextState["user"];
  messageProps: Pick<MessageProps, "title"> & { loading: boolean };
  onRefreshRequest: () => Promise<boolean>;
  onSubmitRequest: () => Promise<boolean>;
  onDestroy?: () => void;
}

export interface EditNotificationsHookModel {
  members: PusherMemberInfoViewModel[];
  modifyingMembers: PusherMemberInfoViewModel[];
  viewingMembers: PusherMemberInfoViewModel[];
  useNotifications: boolean;
  handleSubmitClick: () => void;
  renderMessage: () => JSX.Element;
  triggerEditNotification: () => void;
}

// TODO: find a new home
export enum PresentationPusherEvent {
  Edit = "client-edit-event",
  Save = "client-save-event",
}

export enum PresentationPusherChannel {
  Edit = "presence-edit",
}

export type MessageBase = Omit<MessageProps, "secondaryActionProps" | "visible">;
export const RefreshPresentationMessage: MessageBase = {
  title: "Refresh Presentation?",
  message: "This presentation has been updated by another user. Would you like to load the most recent changes?",
  messageType: MessageType.Confirmation,
  primaryActionProps: {
    label: "REFRESH",
  },
};
