import { ChipsItemProps, isEmpty } from "@q4/nimbus-ui";
import { isEqual, isEmpty as _isEmpty } from "lodash";
import { MeetingRequest } from "../../services/admin/registrant/registrant.model";
import { Answer } from "../../services/answer/answer.model";
import { AttendeeSecondaryContact, AttendeeType, AttendeeViewModel } from "../../services/attendee/attendee.model";
import { ConferenceSchedulerSlot } from "../../services/conference/conference.model";
import type { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import { QuestionGroup } from "../../services/questionGroup/questionGroup.model";
import { modifyAnswerData } from "../customQuestionAnswer.utils";

const CompanyKey = "company";
const CorporateProfileIdKey = "_corporate_profile._id";
const AttendeeTypeDefaultRequiredKeys = ["first_name", "last_name", "email", "type"];
export const AttendeeRequiredKeys = {
  Corporate: [...AttendeeTypeDefaultRequiredKeys, CorporateProfileIdKey],
  Investor: [...AttendeeTypeDefaultRequiredKeys, CompanyKey],
  Internal: [...AttendeeTypeDefaultRequiredKeys, CompanyKey],
  Guest: [...AttendeeTypeDefaultRequiredKeys, CompanyKey],
};

export function getAttendeeTypeRequiredKeys(type: AttendeeType): string[] {
  const keys = AttendeeRequiredKeys[type];
  return isEmpty(keys) ? [] : keys;
}

export function sortAttendeeChips(a: ChipsItemProps<AttendeeViewModel>, b: ChipsItemProps<AttendeeViewModel>): number {
  const attendeeCorpProfileA = a.data._corporate_profile as CorporateProfile;
  const attendeeCorpProfileB = b.data._corporate_profile as CorporateProfile;
  const attendeeA = `${a.data.company || attendeeCorpProfileA?.name || ""} ${a.data.first_name || ""} ${
    a.data.last_name || ""
  }`.toLowerCase();
  const attendeeB = `${b.data.company || attendeeCorpProfileB?.name || ""} ${b.data.first_name || ""} ${
    b.data.last_name || ""
  }`.toLowerCase();

  if (attendeeA > attendeeB) return 1;
  if (attendeeA < attendeeB) return -1;
  return 0;
}

const corporateAttendeeSkippableFields = ["options", "form_custom_question_answers"];

export const attendeeEqualityComparer = {
  corporate_attendees: (original: AttendeeViewModel[], current: AttendeeViewModel[]): boolean => {
    if (!original || !current) return false;
    if (original?.length !== current?.length) return false;

    for (let i = 0; i < original.length; i++) {
      const originalItem = original[i];
      const currentItem = current[i];
      const attendeeKeys = Object.keys(originalItem) as (keyof AttendeeViewModel)[];

      for (let j = 0; j < attendeeKeys.length; j++) {
        const originalValue = originalItem[attendeeKeys[j]];
        const currentValue = currentItem[attendeeKeys[j]];
        if (corporateAttendeeSkippableFields.includes(attendeeKeys[j])) continue;

        if (attendeeKeys[j] === "custom_question_answers") {
          const originalAnswers = originalValue as Answer[];
          const currentAnswers = currentValue as Answer[];

          if (!isEqual(originalAnswers?.length, currentAnswers?.length)) return false;
          for (let k = 0; k < originalAnswers?.length; k++) {
            if (!isEqual(originalAnswers[k]?.answer, currentAnswers[k]?.answer)) return false;
          }
        } else {
          const equality = isEqual(originalValue, currentValue);
          if (!equality) return false;
        }
      }
    }
    return true;
  },
  availability: (original: ConferenceSchedulerSlot[], current: ConferenceSchedulerSlot[]): boolean => {
    if (original?.length !== current?.length) return false;

    if (!_isEmpty(original) && !_isEmpty(current)) {
      for (let i = 0; i < original.length; i++) {
        if (!original[i].start_time.isSame(current[i].start_time)) return false;
        if (!original[i].end_time.isSame(current[i].end_time)) return false;
      }
    }

    return true;
  },
  secondary_contact: (original: AttendeeSecondaryContact, current: AttendeeSecondaryContact): boolean => {
    return isEqual(original, current);
  },
  meeting_requests: (original: MeetingRequest[], current: MeetingRequest[]): boolean => {
    return isEqual(original, current);
  },
  custom_question_answers: (original: Answer[], current: Answer[]): boolean => {
    if (original?.length !== current?.length) return false;

    if (!_isEmpty(original) && !_isEmpty(current)) {
      for (let i = 0; i < original.length; i++) {
        if (!isEqual(original[i].answer, current[i].answer)) return false;
      }
    }

    return true;
  },
};

export const modifyAnswerPayload = (
  attendeeData: Partial<AttendeeViewModel>,
  questionGroups: QuestionGroup[]
): Partial<AttendeeViewModel> => {
  const targetQuestionGroup = questionGroups?.find((qG) => qG?.attendee_types.includes(attendeeData?.type));
  const updatedAnswerObject = modifyAnswerData(attendeeData?.custom_question_answers, targetQuestionGroup?._questions);
  return new AttendeeViewModel({ ...attendeeData, custom_question_answers: updatedAnswerObject });
};
