import { EntityBase } from "../../definitions/entity.definition";

export class PayloadBase<T extends Omit<EntityBase, "_id">> {
  _id?: EntityBase["_id"];

  // TODO: change back to a constructor
  sanitize(
    entity: T
  ): Omit<T, "approved_at" | "approved_by" | "created_at" | "created_by" | "updated_at" | "updated_by" | "__t" | "__v"> {
    const { approved_at, approved_by, created_at, created_by, updated_at, updated_by, __t, __v, ...payload } = entity;
    return payload;
  }
}
