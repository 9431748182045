import {
  BaseComponentProps,
  CheckboxIdModel,
  DatePickerIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextareaIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface LobbyConfigFormProps extends BaseComponentProps {
  conference: ConferenceEditState;
  getStateChangeHandler(key: string): <T>(value: T) => void;
}

export type LobbyConfigProps = {
  [key in keyof Conference["lobby"]]: {
    name: string;
    titleText: string;
    labelText: string;
    disableId: string;
    titleId: string;
    labelId: string;
    isLabelTextArea?: boolean;
  };
};

export type LobbyConfigForms = Pick<
  LobbyConfigFormIdModel,
  "agenda" | "ondemand_presentations" | "tracks" | "dialInInstructions" | "livePresentation" | "speakers"
>;

export class LobbyConfigFormIdModel extends IdModelBase {
  preview: CheckboxIdModel;
  agenda: FormIdModel;
  dialInInstructions: FormIdModel;
  ondemand_presentations: FormIdModel;
  speakers: FormIdModel;
  tracks: FormIdModel;
  livePresentation: FormIdModel;

  agendaDisable: CheckboxIdModel;
  agendaTitle: TextboxIdModel;
  agendaLabel: TextboxIdModel;
  dialInInstructionsDisable: CheckboxIdModel;
  dialInInstructionsTitle: TextboxIdModel;
  dialInInstructionsLabel: TextareaIdModel;
  onDemandDisable: CheckboxIdModel;
  onDemandTitle: TextboxIdModel;
  onDemandLabel: TextboxIdModel;
  speakersDisable: CheckboxIdModel;
  speakersTitle: TextboxIdModel;
  speakersLabel: TextboxIdModel;
  tracksDisable: CheckboxIdModel;
  tracksTitle: TextboxIdModel;
  tracksLabel: TextboxIdModel;
  livePresentationDisable: CheckboxIdModel;
  livePresentationTitle: TextboxIdModel;
  livePresentationLabel: TextboxIdModel;
  corporateLobbyOpenDate: DatePickerIdModel;
  investorLobbyOpenDate: DatePickerIdModel;
  generalLobbyOpenDate: DatePickerIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.preview = new CheckboxIdModel(`${this.id}PreviewCheckbox`);
    this.agenda = new FormIdModel(`${this.id}AgendaForm`);
    this.dialInInstructions = new FormIdModel(`${this.id}DialInForm`);
    this.ondemand_presentations = new FormIdModel(`${this.id}OnDemandForm`);
    this.speakers = new FormIdModel(`${this.id}SpeakersForm`);
    this.tracks = new FormIdModel(`${this.id}TracksForm`);
    this.livePresentation = new FormIdModel(`${this.id}LivePresentationForm`);

    this.agendaDisable = new CheckboxIdModel(`${this.id}AgendaDisableCheckbox`);
    this.agendaTitle = new TextboxIdModel(`${this.id}AgendaTitleTextbox`);
    this.agendaLabel = new TextboxIdModel(`${this.id}AgendaLabelTextbox`);
    this.dialInInstructionsDisable = new CheckboxIdModel(`${this.id}DialInInstructionDisableCheckbox`);
    this.dialInInstructionsTitle = new TextboxIdModel(`${this.id}DialInInstructionTitleTextbox`);
    this.dialInInstructionsLabel = new TextareaIdModel(`${this.id}DialInInstructionLabelTextarea`);
    this.onDemandDisable = new CheckboxIdModel(`${this.id}OnDemandDisableCheckbox`);
    this.onDemandTitle = new TextboxIdModel(`${this.id}OnDemandTitleTextbox`);
    this.onDemandLabel = new TextboxIdModel(`${this.id}OnDemandLabelTextbox`);
    this.speakersDisable = new CheckboxIdModel(`${this.id}SpeakerDisableCheckbox`);
    this.speakersTitle = new TextboxIdModel(`${this.id}SpeakerTitleTextbox`);
    this.speakersLabel = new TextboxIdModel(`${this.id}SpeakerLabelTextbox`);
    this.tracksDisable = new CheckboxIdModel(`${this.id}TracksDisableCheckbox`);
    this.tracksTitle = new TextboxIdModel(`${this.id}TracksTitleTextbox`);
    this.tracksLabel = new TextboxIdModel(`${this.id}TracksLabelTextbox`);
    this.livePresentationDisable = new CheckboxIdModel(`${this.id}LivePresentationDisableCheckbox`);
    this.livePresentationTitle = new TextboxIdModel(`${this.id}LivePresentationTitleTextbox`);
    this.livePresentationLabel = new TextboxIdModel(`${this.id}LivePresentationLabelTextbox`);
    this.corporateLobbyOpenDate = new DatePickerIdModel(`${this.id}CorporateLobbyOpenDatePicker`);
    this.investorLobbyOpenDate = new DatePickerIdModel(`${this.id}InvestorLobbyOpenDatePicker`);
    this.generalLobbyOpenDate = new DatePickerIdModel(`${this.id}GeneralLobbyOpenDatePicker`);
  }
}
