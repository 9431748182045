import { Moment } from "moment";
import { Conference } from "../services/conference/conference.model";

export enum Entity {
  Admin = "admin",
  Company = "company",
  Conference = "conference",
  CorporateProfile = "corporate profile",
  OnDemand = "onDemand",
  Meeting = "meeting",
  Presentation = "presentation",
  Attendee = "attendee",
  Speaker = "speaker",
  Track = "track",
  Registrant = "registrant",
  RegistrationCustomQuestionsForm = "registration form",
  QuestionGroup = "question group",
  Question = "question",
  Answer = "answer",
}

export abstract class EntityBase {
  // TODO: remove optional from _id
  _id?: string;
  approved_at?: Moment;
  approved_by?: Moment;
  created_at?: Moment;
  updated_at?: Moment;
  created_by?: string;
  updated_by?: string;
  __t?: string;
  __v?: string;

  constructor(entity: Partial<EntityBase> | string) {
    if (typeof entity === "string") {
      this._id = entity;
      return;
    }

    Object.assign(this, entity);
  }
}

export abstract class ConferenceBaseEntity extends EntityBase {
  _conference: Conference | Conference["_id"];

  get ConferenceId(): string {
    if (typeof this._conference === "string") return this._conference;
    return this._conference?._id;
  }
}

export abstract class PersonBase extends ConferenceBaseEntity {
  first_name?: string;
  last_name?: string;
  title?: string;
  company?: string;
}

export type EntityModel<T> = new (entity: Partial<T> | string) => T;
