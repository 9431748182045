import moment, { Moment } from "moment";
import { AttendeeType, AttendeeViewModel } from "../../../services/attendee/attendee.model";
import { Conference, PresentationVendorType } from "../../../services/conference/conference.model";

export interface GetLobbyOpen {
  lobbyOpen: boolean;
  lobbyOpenDate?: Moment;
}

export function isLobbyPresentationVendor(vendors: Conference["presentation_vendors"]): boolean {
  return !!vendors?.some((x) => [PresentationVendorType.Streamlined].includes(x.name));
}

export function getLobbyOpen(conference: Conference, attendee: AttendeeViewModel): GetLobbyOpen {
  const { type: attendeeType, allow_lobby_preview: previewEnabled } = attendee;

  const lobbyOpenDateByType = {
    [AttendeeType.Corporate]: conference?.lobby?.corporate_lobby?.open_date,
    [AttendeeType.Investor]: conference?.lobby?.investor_lobby?.open_date,
    [AttendeeType.Internal]: conference?.lobby?.general_lobby?.open_date,
    [AttendeeType.Guest]: conference?.lobby?.general_lobby?.open_date,
  };

  const lobbyOpenDate = lobbyOpenDateByType[attendeeType];
  const lobbyOpen = previewEnabled || moment().utc().isSameOrAfter(lobbyOpenDate);

  return {
    lobbyOpenDate,
    lobbyOpen: lobbyOpenDate ? lobbyOpen : false,
  };
}
