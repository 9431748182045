import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { AdminRouteBase } from "../../../configurations/navigation.configuration";
import { EntityBase } from "../../../definitions/entity.definition";
import { ApiResponse, OfflineApiServiceKey } from "../../api/api.definition";
import ApiService from "../../api/api.service";
import AttendeeService from "../../attendee/attendee.service";
import { Conference } from "../../conference/conference.model";
import { AdminApiVersion, Service } from "../../serviceBase/serviceBase.model";
import { mapApiResponse, mapArrayedApiResponse } from "../../serviceBase/serviceBase.utils";
import { Registrant, RegistrantPayload } from "./registrant.model";
import type { RegistrantServicePutResponse } from "./registrant.model";

export default class RegistrantService implements Omit<Service<Registrant>, "get" | "put"> {
  protected readonly _apiService = new ApiService();
  protected attendeeApiService = new AttendeeService();
  protected _routeBase: string;
  protected _servicePath: string;
  protected _version?: string;
  private _entityModel: new (entity: Partial<Registrant> | string) => Registrant;

  get ApiPathBase(): string {
    if (isNullOrWhiteSpace(this._servicePath)) return null;
    const version = isNullOrWhiteSpace(this._version) ? "" : `/${this._version}`;
    return `${this._routeBase}${this._servicePath}${version}`;
  }

  constructor() {
    this._routeBase = AdminRouteBase;
    this._servicePath = "/registrationrequests";
    this._entityModel = Registrant;
    this._version = AdminApiVersion;
  }

  public async getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<Registrant[]>> {
    const response = await this._apiService.get<Registrant[]>(`${this.ApiPathBase}/byConference/${_id}`);
    return this.mapArrayedResponse(response);
  }

  getOfflineData = (key: OfflineApiServiceKey): Promise<ApiResponse<Registrant[]>> => {
    return this._apiService.getOfflineData<ApiResponse<Registrant[]>>(key).then(this.mapArrayedResponse);
  };

  delete(id: Registrant["_id"]): Promise<ApiResponse<Registrant>> {
    return this._apiService.delete<null, Registrant>(`${this.ApiPathBase}/${id}`).then(this.mapResponse);
  }

  post(data: Registrant): Promise<ApiResponse<Registrant>> {
    return this._apiService
      .post<RegistrantPayload, Registrant>(this.ApiPathBase, new RegistrantPayload(data))
      .then(this.mapResponse);
  }

  put(id: EntityBase["_id"], data: Registrant): Promise<ApiResponse<RegistrantServicePutResponse>> {
    const payload = new RegistrantPayload(data);
    return this._apiService
      .put<RegistrantPayload, RegistrantServicePutResponse>(`${this.ApiPathBase}/${id}`, payload)
      .then((response) => {
        return response;
      });
  }

  protected mapResponse = (response: ApiResponse<Registrant>): ApiResponse<Registrant> => {
    return mapApiResponse(response, this._entityModel);
  };

  protected mapArrayedResponse = (response: ApiResponse<Registrant[]>): ApiResponse<Registrant[]> => {
    return mapArrayedApiResponse(response, this._entityModel);
  };
}
