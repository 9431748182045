import "./exportAgenda.component.scss";
import { Button, ButtonTheme, isEmpty, isNullOrWhiteSpace, NotificationService, useVisibility } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { useExport } from "../../../../../../../../hooks";
import { usePublicLink } from "../../../../../../../../hooks/public/usePublicLink/usePublicLink.hook";
import { GeneratedPdfResponse } from "../../../../../../../../hooks/useExport/useExport.definition";
import { AuthType } from "../../../../../../../../services/api/api.definition";
import { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import { ImportResult } from "../../../../../../../../services/import/import.definition";
import { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import ParallelService from "../../../../../../../../services/parallel.service";
import { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import {
  PublicLinkData,
  PublicLinkEncryptResponse,
} from "../../../../../../../../services/public/link/publicLink.definition";
import { getPublicLinkData } from "../../../../../../../../utils";
import ExportAgendaModal from "../exportAgendaModal/exportAgendaModal.component";
import {
  ExportAgendaClassName,
  ExportAgendaIdModel,
  ExportAgendaProps,
  ExportAgendaStatus,
  ExportAgendaItem,
} from "./exportAgenda.definition";

const ExportAgendaButton = (props: ExportAgendaProps): JSX.Element => {
  const { id, attendees, company, conference, disabled, meetings, presentations } = props;

  const idModel = useMemo(() => new ExportAgendaIdModel(id), [id]);
  const parallelService = useMemo(() => new ParallelService(), []);
  const notificationService = useMemo(() => new NotificationService(), []);

  const { getId } = usePublicLink();

  const { generatePdf } = useExport({
    authType: AuthType.Protected,
    company,
  });

  const [exporting, setExporting] = useState(false);
  const [exportedPdfs, setExportedPdfs] = useState<ImportResult<AttendeeViewModel>[]>([]);
  const [exportedCount, setExportedCount] = useState(0);

  const exportModalVisible = useRef(false);

  const [visible, handleModalOpen, handleModalClose] = useVisibility();

  const disable = useMemo(() => exporting || disabled, [disabled, exporting]);

  const cancelExport = useCallback((): Error => {
    return !exportModalVisible.current ? new Error(ExportAgendaStatus.Cancelled) : null;
  }, []);

  const handleReset = useCallback((): void => {
    setExporting(false);
    setExportedCount(0);
    setExportedPdfs([]);
    handleModalClose();
  }, [handleModalClose]);

  function handleExportModalOpen(): void {
    exportModalVisible.current = true;
    handleModalOpen();
  }

  function handleCancel(): void {
    setExportedCount(0);
    setExportedPdfs([]);
    exportModalVisible.current = false;
  }

  const getLinkIds = useCallback(
    (attendees: ExportAgendaItem<AttendeeViewModel>[]): Promise<PublicLinkEncryptResponse> => {
      const linkIdRequests = attendees.reduce((data, item) => {
        if (isEmpty(item)) return data;

        const currentUserData = getPublicLinkData([...item.presentations, ...item.meetings], item.entity);

        if (isEmpty(currentUserData)) return data;

        data.push(...currentUserData);

        return data;
      }, [] as PublicLinkData[]);

      return getId({ data: linkIdRequests }).then((result) => result);
    },
    [getId]
  );

  const handleExport = useCallback(
    async (exportableAttendees: ExportAgendaItem<AttendeeViewModel>[]): Promise<void> => {
      setExporting(true);

      const linkIds = conference?.requireAuthenticatedMeetingLink ? {} : await getLinkIds(exportableAttendees);
      const requests: (() => Promise<GeneratedPdfResponse>)[] = [];

      for (const item of exportableAttendees) {
        const { entity, meetings: attendeeMeetings, presentations: attendeePresentations } = item;
        const fileNamePostFix = isNullOrWhiteSpace(entity.company_name) ? "" : `_${entity.company_name}`;

        const sessions = {
          presentations: attendeePresentations,
          meetings: attendeeMeetings,
        };

        if (!conference?.requireAuthenticatedMeetingLink && !isEmpty(linkIds) && !isNullOrWhiteSpace(entity?._id)) {
          sessions.presentations = (attendeePresentations || []).map((x) => {
            return new Presentation({ ...x, linkId: linkIds?.[entity._id]?.[x._id] });
          });

          sessions.meetings = (attendeeMeetings || []).map((x) => {
            return new Meeting({ ...x, linkId: linkIds?.[entity._id]?.[x._id] });
          });
        }

        requests.push(() =>
          generatePdf(
            new AttendeeViewModel(entity),
            attendees,
            conference,
            sessions.meetings,
            sessions.presentations,
            `${entity.display_name}${fileNamePostFix}_`
          ).then((value) => {
            setExportedCount((count) => count + 1);
            return value;
          })
        );
      }

      parallelService
        .limit(requests, 5, cancelExport)
        .then((result) => {
          if (!result) {
            handleReset();
            notificationService.error(ExportAgendaStatus.Cancelled);
          }
          setExportedPdfs(
            (result || []).map((response) => {
              const { success, attendee } = response;
              return {
                entity: attendee,
                errorMessage: success ? null : ExportAgendaStatus.Failed,
                title: attendee.display_name,
                actionName: ExportAgendaStatus.Exported,
              } as ImportResult<AttendeeViewModel>;
            })
          );

          return result;
        })
        .finally(() => setExporting(false))
        .catch((err) => console.warn(err));
    },
    [attendees, cancelExport, conference, generatePdf, getLinkIds, handleReset, notificationService, parallelService]
  );

  return (
    <div id={idModel?.id} className={ExportAgendaClassName.Base}>
      <Button
        id={idModel?.button?.id}
        className={ExportAgendaClassName.Button}
        label="Export Agendas"
        disabled={disable}
        loading={disable}
        theme={ButtonTheme.Citrus}
        onClick={handleExportModalOpen}
      />
      <ExportAgendaModal
        id={idModel.modal.id}
        attendees={attendees}
        currentCount={exportedCount}
        exporting={exporting}
        meetings={meetings}
        disabled={disabled}
        presentations={presentations}
        results={exportedPdfs}
        visible={visible}
        onReset={handleReset}
        onExport={handleExport}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default memo(ExportAgendaButton);
