import { isNullOrWhiteSpace, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import type { ApiResponse } from "../../services/api/api.definition";
import { AttendeeViewModel, AttendeePassword } from "../../services/attendee/attendee.model";
import AttendeeService from "../../services/attendee/attendee.service";
import type { Conference } from "../../services/conference/conference.model";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import { handleSetEntity } from "../useService/useService.utils";
import type { AttendeesHookModel, AttendeesHookProps } from "./useAttendees.definition";

export const useAttendees = (props: AttendeesHookProps): AttendeesHookModel => {
  const { autoFetchData, assignDefaultEntity: assignDefaultEntityProp, attendeeId, data, conferenceId } = props;

  const assignDefaultEntity = useMemo(() => assignDefaultEntityProp ?? false, [assignDefaultEntityProp]);
  const [currentAttendee, setCurrentAttendee] = useState<AttendeeViewModel>(null);
  const [attendees, setAttendees] = useState<AttendeeViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const attendeeService = useRef(new AttendeeService());
  const notificationService = useRef(new NotificationService());

  const autoFetchById = useMemo(() => !isNullOrWhiteSpace(attendeeId), [attendeeId]);

  const param = useMemo(() => (autoFetchById ? attendeeId : conferenceId), [attendeeId, autoFetchById, conferenceId]);

  const _handleAttendeeResponse = useCallback(
    (response: ApiResponse<AttendeeViewModel[]>): AttendeeViewModel[] => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error("Failed to load attendees.");
        return [];
      }

      const attendees = response?.data;
      setAttendees(attendees);
      setCurrentAttendee((entity) => handleSetEntity(entity, attendees, assignDefaultEntity));
      return attendees;
    },
    [assignDefaultEntity]
  );

  const getAttendees = useCallback((): Promise<AttendeeViewModel[]> => {
    setLoading(true);

    return attendeeService.current.getAttendees().then(_handleAttendeeResponse);
  }, [_handleAttendeeResponse]);

  const getAttendeeById = useCallback((_id: AttendeeViewModel["_id"]): Promise<AttendeeViewModel> => {
    setLoading(true);

    return attendeeService.current.getAttendeeById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }

      const { data: attendee } = response;
      setAttendees((attendees) =>
        attendees.map((x) => (x._id === attendee._id ? new AttendeeViewModel({ ...x, ...attendee }) : x))
      );
      setCurrentAttendee(attendee);

      return attendee;
    });
  }, []);

  const getAttendeesByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<AttendeeViewModel[]> => {
      setLoading(true);

      return attendeeService.current.getAttendeesByConferenceId(_id).then((response) => {
        setLoading(false);
        if (!response.success) {
          notificationService.current.error(response.message);
          return [];
        }

        const { data: attendees } = response;
        setAttendees(attendees);
        setCurrentAttendee((entity) => handleSetEntity(entity, attendees, assignDefaultEntity));
        return attendees;
      });
    },
    [assignDefaultEntity]
  );

  const fetchConferenceAttendees = useCallback(
    (): Promise<AttendeeViewModel[]> => getAttendeesByConferenceId(conferenceId),
    [conferenceId, getAttendeesByConferenceId]
  );

  const handleAttendeePost = useCallback((data: AttendeeViewModel): Promise<AttendeeViewModel> => {
    setLoading(true);
    return attendeeService.current.postAttendee(data).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Attendee was created successfully.");

      const { data: attendee } = response;
      setAttendees((attendees) => [...attendees, attendee]);
      return response.data;
    });
  }, []);

  const handleAttendeePut = useCallback(
    (_id: AttendeeViewModel["_id"], data: AttendeeViewModel, showNotifications = true): Promise<AttendeeViewModel> => {
      setLoading(true);
      return attendeeService.current
        .putAttendeeById(_id, data)
        .then((response) => {
          if (!response.success) {
            showNotifications && notificationService.current.error(response.message);
            return null;
          }
          showNotifications && notificationService.current.success("Attendee was updated successfully.");

          const { data: attendee } = response;
          setAttendees((attendees) =>
            attendees.map((x) => (x._id === attendee._id ? new AttendeeViewModel({ ...x, ...attendee }) : x))
          );
          return attendee;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchAttendeePasswordById = useCallback((_id: AttendeeViewModel["_id"]): Promise<AttendeePassword> => {
    setLoading(true);

    return attendeeService.current.generateAttendeePassword(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Login Password has been generated.");

      const { data: attendeePassword } = response;
      return new AttendeePassword(attendeePassword);
    });
  }, []);

  const handleAttendeeDelete = useCallback((_id: AttendeeViewModel["_id"]): Promise<boolean> => {
    setLoading(true);
    return attendeeService.current.deleteAttendeeById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return response.success;
      }
      notificationService.current.success("Attendee was deleted successfully.");

      setAttendees((attendees) =>
        attendees.reduce((updatedAttendees, x) => (x._id === _id ? updatedAttendees : updatedAttendees.concat(x)), [])
      );
      return response.success;
    });
  }, []);

  const fetchBy = useMemo(
    () => (autoFetchById ? getAttendeeById : getAttendeesByConferenceId),
    [autoFetchById, getAttendeesByConferenceId, getAttendeeById]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param,
    fetch: getAttendees,
    fetchBy,
    setEntities: setAttendees,
  });

  return {
    items: attendees,
    current: currentAttendee,
    loading,
    fetchAttendees: getAttendees,
    fetchConferenceAttendees,
    fetchById: getAttendeeById,
    fetchAttendeePasswordById,
    post: handleAttendeePost,
    putById: handleAttendeePut,
    deleteById: handleAttendeeDelete,
    setCurrent: setCurrentAttendee,
    setLoading,
    setItems: setAttendees,
  };
};
