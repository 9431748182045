import { isEmpty, isNullOrWhiteSpace, Text } from "@q4/nimbus-ui";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Halo from "../../../../components/halo/halo.component";
import { RoutePath } from "../../../../configurations/navigation.configuration";
import { DefaultTimeZone } from "../../../../const";
import contextSubscribe from "../../../../contexts/context.subscribe";
import { LobbyContext } from "../../../../contexts/lobby/lobby.context";
import { UserContext } from "../../../../contexts/user/user.context";
import { useSessionLink } from "../../../../hooks/user/useSessionLink/useSessionLink.hook";
import { SessionEventType } from "../../../../services/session/session.model";
import { getLobbyUrl, getOpenOrClosedPresentationFromId, isAttendeeSpeaker } from "../../../../utils";
import CompanyLobbyLayout from "../components/layout/companyLobby.layout";
import { useLobby } from "../hooks/useLobby/useLobby.hook";
import { CompanyPresentationIdModel as IdModel } from "./companyPresentation.definition";
import type { CompanyLobbyPresentationProps } from "./companyPresentation.definition";
import PresentationTheater from "./components/presentationTheater/presentationTheater.component";

const CompanyLobbyPresentation = (props: CompanyLobbyPresentationProps): JSX.Element => {
  const lobbyContext = useContext(LobbyContext);
  // Using component state, because add/remove agenda makes multiple requests and updates agendaLoading state multiple times which leads to flashing spinner
  const history = useHistory();

  const { userContext } = props;

  const [presentationUrl, setPresentationUrl] = useState(null);

  const autoFetchConferences = useMemo(() => isEmpty(lobbyContext?.conference), [lobbyContext?.conference]);
  const {
    params,
    profile,
    speaker,
    useAgenda,
    usePresentationTheater,
    useUserConferences,
    handleAgendaAdd,
    handleAgendaRemove,
    handleSignOut,
    handleSpeakerChange,
  } = useLobby({
    userContext,
    autoFetchAgenda: isEmpty(lobbyContext?.agenda),
    initialAgenda: lobbyContext?.agenda,
    autoFetchConferences,
    initialConference: lobbyContext?.conference,
    autoFetchProfile: isEmpty(lobbyContext?.profile),
    useOffline: false,
    initialProfile: lobbyContext?.profile,
  });

  const {
    company: companyParam,
    conferencePath: conferenceParam,
    custom: customParam,
    sessionId: presentationParam,
  } = params || {};

  const {
    current: conference,
    items: conferences,
    loading: conferenceLoading,
    setItems: setConferences,
  } = useUserConferences;

  const { agenda, loading: agendaLoading } = useAgenda;
  const hasAgendaPresentation = useMemo(
    () => !!getOpenOrClosedPresentationFromId(presentationParam, agenda?._presentation),
    [agenda?._presentation, presentationParam]
  );

  const userTimeZone = useMemo(
    () => profile?.time_zone ?? conference?.time_zone ?? DefaultTimeZone,
    [conference?.time_zone, profile?.time_zone]
  );

  useEffect(() => {
    if (autoFetchConferences) return;

    setConferences((conferences) => (isEmpty(conferences) ? lobbyContext?.conferences : conferences));
  }, [autoFetchConferences, lobbyContext, setConferences]);

  const presentation = useMemo(
    () => getOpenOrClosedPresentationFromId(presentationParam, conference?._presentation),
    [conference?._presentation, presentationParam]
  );

  const hasVendorOverride = useMemo(() => !isEmpty(presentation?.vendor_override), [presentation?.vendor_override]);

  const { loading: urlLoading, fetchBy } = useSessionLink();

  const isSpeaker = useMemo(() => isAttendeeSpeaker(profile, presentation?._speaker), [presentation?._speaker, profile]);

  useEffect(() => {
    if (isNullOrWhiteSpace(presentation?._id)) return;

    fetchBy(presentation?._id, SessionEventType.Presentation).then((url) => {
      setPresentationUrl(url);
    });
  }, [presentation?._id, fetchBy]);

  const loading = useMemo(
    () => agendaLoading || conferenceLoading || urlLoading,
    [agendaLoading, conferenceLoading, urlLoading]
  );

  useEffect(() => {
    if ((usePresentationTheater && !hasVendorOverride && !isSpeaker) || loading || isNullOrWhiteSpace(presentationUrl)) {
      return;
    }
    window.location.href = presentationUrl;
  }, [hasVendorOverride, isSpeaker, loading, presentationUrl, usePresentationTheater]);

  useEffect(() => {
    if (isEmpty(conference?._presentation) || isEmpty(params)) return;

    const hasPresentation = getOpenOrClosedPresentationFromId(presentationParam, conference._presentation);
    if (hasPresentation) return;

    const redirectUrl = getLobbyUrl(companyParam, customParam, conferenceParam) ?? RoutePath.Home;
    history.replace(redirectUrl);
  }, [companyParam, conference?._presentation, conferenceParam, customParam, history, params, presentationParam]);

  if (hasVendorOverride || !usePresentationTheater) return <div id={IdModel.id} />;

  return (
    <div id={IdModel.id}>
      <CompanyLobbyLayout
        id={IdModel.layout.id}
        conference={conference}
        conferences={conferences}
        onSignOut={handleSignOut}
        profile={profile}
      >
        <Halo title={`${conference?.title} - ${presentation?.title}`} />
        <PresentationTheater
          id={IdModel.presentationTheater.id}
          agendaLoading={agendaLoading}
          backButton={{
            children: (
              <span className="q4i-arrow-left-4pt">
                {" "}
                <Text>Go Back to Lobby</Text>
              </span>
            ),
            onClick: () => {
              history.push(getLobbyUrl(companyParam, customParam, conferenceParam));
            },
          }}
          lobbyConfig={conference?.lobby}
          presentation={presentation}
          url={presentationUrl}
          hasAgendaPresentation={hasAgendaPresentation}
          profile={profile}
          speaker={speaker}
          timeZone={userTimeZone}
          onSpeakerChange={handleSpeakerChange}
          onAgendaAdd={handleAgendaAdd}
          onAgendaRemove={handleAgendaRemove}
        />
      </CompanyLobbyLayout>
    </div>
  );
};

export default contextSubscribe<CompanyLobbyPresentationProps>(
  [
    {
      context: UserContext,
      mapToProps: "userContext",
    },
  ],
  memo(CompanyLobbyPresentation)
);
