import { isEmpty } from "@q4/nimbus-ui";
import { get } from "lodash";

export function getPositionByReference<T>(
  target: { key: keyof T; value: T[keyof T] },
  positionKey: keyof T,
  items: T[],
  increment?: number
): number {
  if (isEmpty(items) || isEmpty(target)) return null;
  const { key, value } = target;
  const inc = increment || 100;

  const targetIndex = items.findIndex((item: T) => item[key] === value) || 0;
  if (targetIndex === -1) return items.length * inc;

  const targetPosition = get(items, `[${targetIndex}].${positionKey}`) || 0;
  const adjacentTargetPosition = get(items, `[${targetIndex + 1}].${positionKey}`);

  if (isNaN(adjacentTargetPosition)) return targetPosition + inc;

  return (targetPosition + adjacentTargetPosition) / 2;
}
