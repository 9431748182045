import { IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface ConferenceBrandingProps extends Pick<BaseComponentProps, "id"> {
  branding: ConferenceEditState["branding"];
  getStateChangeHandler: (key: string) => <T>(value: T) => void;
}

export class ConferenceBrandingIdModel extends IdModelBase {
  primaryColor: TextboxIdModel;
  secondaryColor: TextboxIdModel;
  secondaryBackgroundColor: TextboxIdModel;
  tertiaryColor: TextboxIdModel;
  headingFontFamily: TextboxIdModel;
  headingFontPath: TextboxIdModel;
  headingFontColor: TextboxIdModel;
  bodyFontFamily: TextboxIdModel;
  bodyFontPath: TextboxIdModel;
  bodyFontSizeBase: TextboxIdModel;
  bodyFontColor: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.primaryColor = new TextboxIdModel(`${this.id}PrimaryColorTextbox`);
    this.secondaryColor = new TextboxIdModel(`${this.id}SecondaryColorTextbox`);
    this.secondaryBackgroundColor = new TextboxIdModel(`${this.id}SecondaryBackgroundColorTextbox`);
    this.tertiaryColor = new TextboxIdModel(`${this.id}TertiaryColorTextbox`);
    this.headingFontFamily = new TextboxIdModel(`${this.id}HeadingFontFamilyTextbox`);
    this.headingFontPath = new TextboxIdModel(`${this.id}HeadingFontPathTextbox`);
    this.headingFontColor = new TextboxIdModel(`${this.id}HeadingFontColorTextbox`);
    this.bodyFontFamily = new TextboxIdModel(`${this.id}BodyFontFamilyTextbox`);
    this.bodyFontPath = new TextboxIdModel(`${this.id}BodyFontPathTextbox`);
    this.bodyFontSizeBase = new TextboxIdModel(`${this.id}BodyFontSizeBaseTextbox`);
    this.bodyFontColor = new TextboxIdModel(`${this.id}BodyFontColorTextbox`);
  }
}
