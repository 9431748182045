import "./corporateAttendeeForm.component.scss";
import {
  isNullOrWhiteSpace,
  getClassName,
  Form,
  FormFieldProps,
  Text,
  Textbox,
  Checkbox,
  TextPreset,
  isEmpty,
  Ghostable,
  Textarea,
  ErrorModel,
} from "@q4/nimbus-ui";
import React, { useMemo, memo } from "react";
import { useFileUpload } from "../../../../hooks";
import { Answer } from "../../../../services/answer/answer.model";
import { AttendeeViewModel } from "../../../../services/attendee/attendee.model";
import { FileUploaderWithImgPreload } from "../../../fileUploader/fileUploader.component";
import QuestionsForm from "../../../questionsForm/questionsForm.component";
import {
  CorporateAttendeeFormClassName,
  CorporateAttendeeFormIdModel,
  CorporateAttendeeFormProps,
} from "./corporateAttendeeForm.definition";

const CorporateAttendeeForm = (props: CorporateAttendeeFormProps): JSX.Element => {
  const { id, attendee, className, questionGroup, formErrors, setFormErrors, onAttendeeUpdate } = props;

  const handleFileUpload = useFileUpload();

  const idModel = useMemo(() => new CorporateAttendeeFormIdModel(id), [id]);

  const baseClassName = useMemo(
    () =>
      getClassName(CorporateAttendeeFormClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const attendeeQuestions = useMemo(
    () => (questionGroup?._questions || []).filter((q) => !!q.is_individual_corporate),
    [questionGroup?._questions]
  );

  const attendeeFields = useMemo((): FormFieldProps[] => {
    function getInputHandler(key: keyof AttendeeViewModel) {
      return function (value: string | boolean): void {
        const formKey = formErrors[key];
        if (!isEmpty(formKey) && typeof value === "string" && typeof setFormErrors === "function") {
          setFormErrors((previousState) => ({
            ...previousState,
            [key]: new ErrorModel(formKey.message, isNullOrWhiteSpace(value)),
          }));
        }
        onAttendeeUpdate({ [key]: value });
      };
    }

    return [
      {
        key: "First Name",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "First Name",
        required: true,
        error: formErrors["first_name"],
        children: (
          <Textbox id={idModel?.firstName?.id} value={attendee?.first_name} onChange={getInputHandler("first_name")} />
        ),
      },
      {
        key: "Last Name",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Last Name",
        error: formErrors["last_name"],
        required: true,
        children: <Textbox id={idModel?.lastName?.id} value={attendee?.last_name} onChange={getInputHandler("last_name")} />,
      },
      {
        key: "Email",
        error: formErrors["email"],
        required: true,
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Email",
        children: <Textbox id={idModel?.email?.id} value={attendee?.email} onChange={getInputHandler("email")} />,
      },
      {
        key: "Title",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Title",
        children: <Textbox id={idModel?.title?.id} value={attendee?.title} onChange={getInputHandler("title")} />,
      },
      {
        key: "Phone",
        width: "6-of-12",
        smallWidth: "1-of-1",
        label: "Phone",
        children: (
          <Textbox id={idModel?.phone?.id} value={attendee?.phone_number} onChange={getInputHandler("phone_number")} />
        ),
      },
      {
        key: "Participate in Meetings",
        width: "3-of-12",
        smallWidth: "1-of-3",
        label: " ",
        children: (
          <Checkbox
            id={idModel?.participateInMeetings?.id}
            checked={attendee?.participate_in_meetings}
            onChange={getInputHandler("participate_in_meetings")}
            label="Participate in Meetings"
          />
        ),
      },
      {
        key: "Receive Emails",
        width: "3-of-12",
        smallWidth: "1-of-3",
        label: " ",
        children: (
          <Checkbox
            id={idModel?.receiveEmails?.id}
            checked={attendee?.receive_emails}
            onChange={getInputHandler("receive_emails")}
            label="Receive Emails"
          />
        ),
      },
      {
        key: "Presentation Speaker",
        width: "1-of-2",
        smallWidth: "1-of-3",
        label: " ",
        children: (
          <Checkbox
            id={idModel?.isSpeaker?.id}
            checked={attendee?.is_speaker}
            onChange={getInputHandler("is_speaker")}
            label="Presentation Speaker"
          />
        ),
      },
    ];
  }, [attendee, formErrors, idModel, setFormErrors, onAttendeeUpdate]);

  const speakerFields = useMemo((): FormFieldProps[] => {
    function handleSpeakerImageFileUpload(fileUrl: string): void {
      onAttendeeUpdate({
        speaker_info: {
          ...attendee?.speaker_info,
          image_profile: fileUrl,
        },
      });
    }

    function handleSpeakerBioChange(value: string): void {
      onAttendeeUpdate({
        speaker_info: {
          ...attendee?.speaker_info,
          bio: value,
        },
      });
    }

    return [
      {
        key: "Upload Profile Image",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Upload Profile Image",
        children: (
          <FileUploaderWithImgPreload
            id={idModel?.speakerImage?.id}
            dropzoneProps={{ accept: ["image/*"] }}
            fileApi={handleFileUpload}
            fileUrl={attendee?.speaker_info?.image_profile}
            onChange={handleSpeakerImageFileUpload}
            showPreview={true}
          />
        ),
      },
      {
        key: "Speaker Bio",
        width: "1-of-2",
        smallWidth: "1-of-1",
        label: "Speaker Bio",
        children: (
          <Textarea
            id={idModel?.speakerBio?.id}
            value={attendee?.speaker_info?.bio}
            onChange={handleSpeakerBioChange}
            rows={8}
          />
        ),
      },
    ];
  }, [attendee?.speaker_info, handleFileUpload, idModel.speakerBio?.id, idModel.speakerImage.id, onAttendeeUpdate]);

  const handleAnswerChange = (currentAnswer: Answer) => {
    const answers = attendee?.custom_question_answers || [];
    const targetAnswer = answers?.find((ans) => ans._question === currentAnswer._question);

    if (isEmpty(targetAnswer)) {
      onAttendeeUpdate({
        custom_question_answers: [...answers, currentAnswer],
      });
      return;
    }

    const updatedAnswers = answers.map((ans) => {
      if (ans._question !== currentAnswer._question) return ans;
      return new Answer({ ...ans, answer: currentAnswer.answer });
    });

    onAttendeeUpdate({
      custom_question_answers: updatedAnswers,
    });
  };

  return (
    <div id={idModel?.id} className={baseClassName}>
      <Form id={idModel?.attendeeFormId?.id} fields={attendeeFields} />
      <Ghostable ghosted={!attendee?.is_speaker}>
        <Form id={idModel?.speakerFormId?.id} fields={speakerFields} />
      </Ghostable>
      {!isEmpty(attendeeQuestions) && (
        <>
          <Text
            id={idModel?.questionsHeader?.id}
            className={CorporateAttendeeFormClassName.QuestionsHeader}
            preset={TextPreset.Subheader}
          >
            {questionGroup.title}
          </Text>
          <QuestionsForm
            id={idModel?.questionsForm?.id}
            questions={attendeeQuestions}
            answers={attendee?.custom_question_answers}
            setFormErrors={setFormErrors}
            onChange={handleAnswerChange}
          />
        </>
      )}
    </div>
  );
};

export default memo(CorporateAttendeeForm);
