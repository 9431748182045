import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { RoutePath, RoutePathIdLabel } from "../../../configurations/navigation.configuration";
import { getCompanyLoginUrl, getConferenceRoute, getQuery } from "../../../utils";
import { LoginQuery } from "../../../views/public/login/login.definition";
import PasswordlessTokenService from "../../passwordlessToken/passwordlessToken.service";
import QueryService from "../../query/query.service";
import type { Auth0ServiceBase, AuthSignInOptions } from "../auth0.model";
import PasswordlessAuth0, { PasswordlessAuth0Options } from "./passwordlessAuth0.model";

export default class PasswordlessAuth0Service implements Auth0ServiceBase {
  private _authToken: PasswordlessAuth0;
  get AuthToken(): PasswordlessAuth0 {
    return this._authToken;
  }

  signIn = (options?: AuthSignInOptions): void => {
    const loginUrl = getCompanyLoginUrl();
    if (isNullOrWhiteSpace(loginUrl)) return;

    // redirect to login page
    window.location.href = `${loginUrl}${getQuery({ [LoginQuery.Redirect]: options?.redirect })}`;
  };

  handleAuthentication = (options: PasswordlessAuth0Options): string => {
    const { companySuffix, conferenceId, conferencePath } = options || {};
    const callbackUrlSearch = ((window.location.search.split("?") || [])[1] + window.location.hash).replace("#", "&");
    const queryService = new QueryService(callbackUrlSearch);
    const redirect = queryService.get(LoginQuery.Redirect);

    const passwordlessTokenService = new PasswordlessTokenService();
    if (passwordlessTokenService.isAuthenticated(conferenceId)) {
      const authToken = new PasswordlessAuth0(passwordlessTokenService.getTokenByConferenceId(conferenceId));

      if (companySuffix === authToken.Profile?.company && conferenceId === authToken.Profile?.conferenceId) {
        this._authToken = authToken;
        return this.getPasswordlessDefaultRoute(conferenceId, redirect);
      }
    }

    this._authToken = new PasswordlessAuth0();
    const error = this._authToken.init(options);

    if (isNullOrWhiteSpace(error) && !isEmpty(this._authToken.Profile)) {
      return this.getPasswordlessDefaultRoute(conferencePath, redirect);
    }

    // failed to parse token, redirect back to login
    return this.getPasswordlessLoginRouteByQuery(options, redirect);
  };

  private getPasswordlessDefaultRoute(conferencePath?: string, redirect?: string): string {
    if (isEmpty(this._authToken)) return null;

    if (!isNullOrWhiteSpace(redirect)) {
      let pathname = "";
      try {
        const redirectUrl = new URL(redirect);
        pathname = redirectUrl.pathname;
      } catch (e) {
        pathname = redirect[0] === "/" ? redirect : `/${redirect}`;
      }

      return pathname;
    }

    const { CustomPath, Profile } = this._authToken;
    return getConferenceRoute(Profile?.company, CustomPath, conferencePath);
  }

  private getPasswordlessLoginRoute(options: PasswordlessAuth0Options): string {
    const { companySuffix, customSuffix, conferenceId, conferencePath } = options || {};
    const hasConferenceId = !isNullOrWhiteSpace(conferenceId);

    if (!isNullOrWhiteSpace(customSuffix)) {
      return hasConferenceId
        ? RoutePath.ConferenceLoginWithCustomCompany.replace(RoutePathIdLabel.CustomCompany, customSuffix).replace(
            RoutePathIdLabel.ConferencePath,
            conferencePath
          )
        : RoutePath.CustomLogin.replace(RoutePathIdLabel.CustomCompany, customSuffix);
    }

    if (!isNullOrWhiteSpace(companySuffix)) {
      return hasConferenceId
        ? RoutePath.ConferenceLogin.replace(RoutePathIdLabel.Company, companySuffix).replace(
            RoutePathIdLabel.ConferencePath,
            conferencePath
          )
        : RoutePath.CompanyLogin.replace(RoutePathIdLabel.Company, companySuffix);
    }

    return null;
  }

  private getPasswordlessLoginRouteByQuery(options: PasswordlessAuth0Options, redirect?: string): string {
    const loginRoute = this.getPasswordlessLoginRoute(options);
    if (isNullOrWhiteSpace(loginRoute)) return RoutePath.Home;
    return `${loginRoute}${redirect}`;
  }
}
