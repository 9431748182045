import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isEmpty,
  isNullOrWhiteSpace,
  ModalIdModel,
  ModalProps,
} from "@q4/nimbus-ui";
import shortid from "shortid";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import type { Attendee } from "../../../services/attendee/attendee.model";
import type { Conference } from "../../../services/conference/conference.model";
import type { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import { AttendeeFormIdModel } from "../form/attendeeForm.definition";

export interface AttendeeModalProps extends Pick<BaseComponentProps, "id"> {
  visible: boolean;
  edit?: boolean;
  attendee?: AttendeeViewModel;
  codes: string[];
  companyOptions?: string[];
  corporateProfiles: CorporateProfile[];
  onAttendeeUpdate: (attendee: Partial<AttendeeViewModel>) => void;
  onAttendeeCreate?: (attendee: Partial<AttendeeViewModel>) => void;
  onCloseRequest: ModalProps["onCloseRequest"];
  conference: Conference;
}

export enum AttendeeModalClassName {
  Base = "attendee-modal",
  Form = "attendee-modal_form",
}

export class AttendeeState extends AttendeeViewModel {
  constructor(attendee?: Partial<Attendee> | Partial<AttendeeViewModel>) {
    super(attendee);
    if (isEmpty(attendee)) {
      this._id = shortid.generate();
      return;
    }
  }
}

export class AttendeeModalIdModel extends IdModelBase {
  modal: ModalIdModel;
  cancel: ButtonIdModel;
  save: ButtonIdModel;
  form: AttendeeFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.modal = new ModalIdModel(this.id);
    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.save = new ButtonIdModel(`${this.id}SaveButton`);
    this.form = new AttendeeFormIdModel(`${this.id}Form`);
  }
}
