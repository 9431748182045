import "./tracks.component.scss";
import { Collapsable, Ghostable, isEmpty, isNullOrWhiteSpace, objectSortComparer } from "@q4/nimbus-ui";
import { uniqBy, groupBy } from "lodash";
import moment, { Moment } from "moment-timezone";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import SlickSlider from "react-slick";
import { ItineraryDayFormat, DefaultTimeZone } from "../../../../../const";
import { TimeFormat } from "../../../../../definitions/date.definition";
import { OnDemand } from "../../../../../services/admin/onDemand/onDemand.model";
import { CompanyRouteParams } from "../../../../../services/company/company.model";
import { Presentation } from "../../../../../services/presentation/presentation.model";
import { SessionEventType } from "../../../../../services/session/session.model";
import {
  getLobbySessionUrl,
  isAttendeeSpeaker,
  presentationToItineraryDay,
  sortPresentationsByDate,
} from "../../../../../utils";
import type { CompanyLobbyParams } from "../../../../company/lobby/companyLobby.definition";
import PersonalAgendaButton from "../../../../company/lobby/components/personalAgendaButton/personalAgendaButton.component";
import Section from "../../../../company/lobby/components/section/section.component";
import { SectionTheme } from "../../../../company/lobby/components/section/section.definition";
import TimeZoneSelector from "../../../components/timeZoneSelector/timeZoneSelector.component";
import { ItineraryClassName } from "../../itinerary.definition";
import DateSelect from "../dateSelect/dateSelect.component";
import Panel from "../panel/panel.component";
import Slider from "../slider/slider.component";
import {
  TrackPresentation,
  TracksClassName,
  TracksGroupIdModel,
  TracksIdModel,
  TracksItemIdModel,
  TracksProps,
} from "./tracks.definition";

const Tracks = (props: TracksProps): JSX.Element => {
  const history = useHistory();
  const params = useParams<CompanyLobbyParams>();

  const {
    id,
    conference,
    agendaPresentations,
    presentations,
    agendaLoading,
    now,
    profile,
    title,
    hoverText,
    visible: visibleProp,
    usePresentationTheater,
    onPresentationAdd,
    onPresentationRemove,
    onTimeZoneChange,
    branding,
  } = props;
  const { _track: tracks, time_zone: conferenceTimeZone, lobby } = conference ?? {};
  const { time_zone: profileTimeZone } = profile ?? {};

  const sliderRef = useRef<SlickSlider>();
  const hasSlidToDefaultPosition = useRef(false);

  const idModel = useMemo(() => new TracksIdModel(id), [id]);

  const userTimeZone = useMemo(
    () => profileTimeZone ?? conferenceTimeZone ?? DefaultTimeZone,
    [conferenceTimeZone, profileTimeZone]
  );
  const today = useMemo(() => moment.tz(userTimeZone).startOf("day"), [userTimeZone]);
  const [selectedDate, setSelectedDate] = useState<Moment>(today);

  const isPresentation = useMemo(() => (presentations || [])[0] instanceof Presentation, [presentations]);
  const visible = useMemo(() => visibleProp ?? true, [visibleProp]);

  const presentationDates = useMemo(getPresentationDates, [isPresentation, userTimeZone, presentations]);
  const presentationGroups = useMemo(getPresentationGroups, [isPresentation, userTimeZone, presentations, selectedDate]);
  const hasPresentations = useMemo(() => !isEmpty(presentationGroups), [presentationGroups]);

  useEffect(() => {
    function dateToItineraryDay(date: Moment): string {
      return date.format(ItineraryDayFormat);
    }
    if (isEmpty(selectedDate)) return;

    const selectedItineraryDay = dateToItineraryDay(selectedDate);
    const itineraryDayOptions = presentationDates.map(dateToItineraryDay);

    if (!itineraryDayOptions.includes(selectedItineraryDay)) {
      setSelectedDate(presentationDates[0]);
    }
  }, [selectedDate, presentationDates]);

  const handleDateSelect = useCallback((date: Moment) => {
    setSelectedDate(date);
    hasSlidToDefaultPosition.current = false;
  }, []);

  // Slide to upcoming/live track only on page load
  useEffect(() => {
    if (hasSlidToDefaultPosition.current || !isPresentation) return;

    function getUpcomingTrack(): number {
      const presentationGroupKeys = Object.keys(presentationGroups ?? {});
      const currentPresentations: TrackPresentation[] = presentationGroups[presentationGroupKeys[0]] ?? [];

      if (isEmpty(presentationGroups) || isEmpty(currentPresentations) || isEmpty(now)) return 0;

      const maxIndex = currentPresentations.length - sliderRef.current?.props.slidesToShow;

      let index = currentPresentations.findIndex(
        (x: TrackPresentation) => "start_date" in x && !!x.start_date?.isSameOrAfter(now)
      );

      index = index >= maxIndex ? maxIndex : index;
      return index;
    }

    sliderRef.current?.slickGoTo(getUpcomingTrack());

    hasSlidToDefaultPosition.current = true;
  }, [isPresentation, now, presentationGroups]);

  function getPresentationDates(): Moment[] {
    if (isEmpty(presentations) || !isPresentation) return [];

    return uniqBy(sortPresentationsByDate(presentations as Presentation[]), (presentation) =>
      presentationToItineraryDay(presentation, userTimeZone)
    ).map((presentation: Presentation) => presentation?.start_date);
  }

  function getPresentationGroups(): Record<string, TrackPresentation[]> {
    if (isEmpty(presentations)) return {};

    if (!isPresentation) {
      const ondemand = presentations as OnDemand[];
      const sorted = ondemand.sort(objectSortComparer("order"));
      return groupBy(sorted, "_track._id");
    }

    const sortedPresentations = sortPresentationsByDate(presentations as Presentation[]);

    return groupBy(
      isEmpty(selectedDate)
        ? sortedPresentations
        : sortedPresentations.filter(
            (presentation) =>
              presentation?.start_date.tz(userTimeZone).format(ItineraryDayFormat) ===
              selectedDate.tz(userTimeZone).format(ItineraryDayFormat)
          ),
      "_track._id"
    );
  }

  function renderPresentation(idModel: TracksGroupIdModel, presentation: TrackPresentation, index: number): JSX.Element {
    if (isEmpty(now)) return null;

    const { _id, end_date, title, image_thumbnail, description, code, type, presentation_type, add_to_all } = presentation;

    const itemIdBase = idModel.list.getId(index);
    const itemIdModel = new TracksItemIdModel(itemIdBase);

    const agendaPresentation = (agendaPresentations ?? []).find((x) => x._id === _id);

    const isUserSpeaker = isAttendeeSpeaker(profile, presentation?._speaker);

    const hideAgendaButton =
      (code && code.some((presentationCode) => profile?.code?.includes(presentationCode))) || isUserSpeaker || add_to_all;

    function handlePresentationSelect(): void {
      const conferencePath = params?.conferencePath || conference?.Path;
      const companyRoute: CompanyRouteParams = {
        url_suffix: params.company || conference?._company?.url_suffix,
        custom_path: params.custom || conference?._company?.custom_path,
      };

      const hasVendorOverride = presentation instanceof Presentation && !isEmpty(presentation?.vendor_override);

      const url = getLobbySessionUrl(type, companyRoute, conferencePath, presentation._id);

      if (isNullOrWhiteSpace(url)) return null;

      if (type === SessionEventType.OnDemandPresentation || !usePresentationTheater || hasVendorOverride) {
        window.open(url);
      } else {
        history.push(url);
      }
    }

    const livePresentation =
      "start_date" in presentation ? presentation.start_date?.isBefore(now) && end_date.isAfter(now) : false;
    const sessionTime =
      "start_date" in presentation
        ? `${presentation.start_date?.tz(userTimeZone).format(TimeFormat.Picker)} – ${end_date
            .tz(userTimeZone)
            .format(TimeFormat.TimeZonePicker)}`
        : null;
    const category = isPresentation && presentation_type ? presentation_type : null;

    return (
      <div key={`tracks_presentation--${_id}`} id={itemIdModel?.id} className={TracksClassName.Item}>
        <Panel
          id={itemIdModel?.panel?.id}
          image={image_thumbnail}
          actionLabel={hoverText ?? "Join"}
          accessibilityLabel="Open (in a new tab)"
          category={category}
          title={title}
          details={[sessionTime, description]}
          indicator={livePresentation ? "Live" : null}
          onSelect={handlePresentationSelect}
          branding={branding}
        />
        {!hideAgendaButton && (
          <PersonalAgendaButton
            id={itemIdModel?.button?.id}
            lobby={lobby}
            loading={agendaLoading}
            hasPresentation={!isEmpty(agendaPresentation)}
            presentationId={_id}
            onAgendaAdd={onPresentationAdd}
            onAgendaRemove={onPresentationRemove}
          />
        )}
      </div>
    );
  }

  function renderPresentationGroups(): JSX.Element[] {
    if (isEmpty(presentationGroups)) return null;

    const presentationGroupKeys = Object.keys(presentationGroups);
    return presentationGroupKeys.map((presentationGroupKey, index): JSX.Element => {
      const presentationList = presentationGroups[presentationGroupKey];
      const track = tracks.find((track) => track._id === presentationGroupKey);
      if (isEmpty(presentationList)) return null;

      const itemIdBase = idModel.list.getId(index);
      const itemIdModel = new TracksGroupIdModel(itemIdBase);

      return (
        <div key={`tracks_group--${presentationGroupKey}`} id={itemIdModel?.id} className={TracksClassName.Group}>
          {presentationGroupKeys.length > 1 && (
            <h3 id={itemIdModel?.name} className={`${TracksClassName.Name} ${ItineraryClassName.Subheading}`}>
              {track?.name ?? "General Track"}
            </h3>
          )}
          <div className={TracksClassName.List}>
            <Slider ref={sliderRef}>
              {presentationList.map((presentation, index) => renderPresentation(itemIdModel, presentation, index))}
            </Slider>
          </div>
        </div>
      );
    });
  }

  return (
    <Collapsable collapsed={!visible || !hasPresentations}>
      <Section
        id={idModel.id}
        theme={SectionTheme.Gradient}
        className={TracksClassName.Base}
        headerChildren={[
          <h2 key="tracks_heading" className={ItineraryClassName.Heading}>
            {title}
          </h2>,
          <Ghostable inline="true" key="tracks_date-select" ghosted={!isPresentation}>
            <div className={ItineraryClassName.Subheading}>
              <DateSelect
                id={idModel.dateSelect?.id}
                selectedDate={selectedDate}
                dateOptions={presentationDates}
                onSelect={handleDateSelect}
              />
            </div>
          </Ghostable>,
          <Ghostable inline="true" key="tracks_date-indicator" ghosted={!isPresentation}>
            <TimeZoneSelector
              id={idModel.timeZoneSelector.id}
              selectedTimeZone={userTimeZone}
              conferenceTimeZone={conferenceTimeZone}
              anchorMargin
              onSelect={onTimeZoneChange}
            />
          </Ghostable>,
        ]}
      >
        {renderPresentationGroups()}
      </Section>
    </Collapsable>
  );
};

export default memo(Tracks);
