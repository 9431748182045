import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { SpeakerFormIdModel } from "../../../../../../components/speakerForm/speakerForm.definition";
import type { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { AttendeesHookModel } from "../../../../../../hooks/useAttendees/useAttendees.definition";
import type { SpeakersHookModel } from "../../../../../../hooks/useSpeakers/useSpeaker.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";

export const CreateNewCompanySuffix = " (create new)";

export interface SpeakersProps extends Pick<BaseComponentProps, "id"> {
  companies?: string[];
  conference: Conference;
  useSpeakers: SpeakersHookModel;
  useAttendees: AttendeesHookModel;
  user: AdminUserContextState["user"];
}

export enum SpeakersClassName {
  Base = "speakers",
  Edit = "speaker-edit",
}

export enum SpeakersTableHeader {
  Order = "ORDER",
  Photo = "PHOTO",
  Name = "NAME",
  Company = "COMPANY",
  Title = "TITLE",
}

export class SpeakersIdModel extends IdModelBase {
  entityTable: EntityTableIdModel;
  form: SpeakerFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.form = new SpeakerFormIdModel(`${this.id}Form`);
  }
}
