import "./section.component.scss";
import { getClassName, isEmpty } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { SectionClassName, SectionIdModel, SectionProps, SectionTheme } from "./section.definition";

function Section(props: SectionProps): JSX.Element {
  const { id, className, theme, innerPadding, headerMargin, innerWidth, keyLine, headerChildren, anchor, children } = props;

  const idModel = useMemo(() => new SectionIdModel(id), [id]);
  const {
    base: baseClassName,
    inner: innerClassName,
    header: headerClassName,
  } = useMemo(getClassNames, [className, theme, innerPadding, headerMargin, innerWidth, keyLine]);

  function getClassNames(): { base: string; inner: string; header: string } {
    return {
      base: getClassName(SectionClassName.Base, [
        {
          condition: !!className,
          trueClassName: className,
        },
        {
          condition: !!theme,
          trueClassName: `${SectionClassName.Base}--${theme}`,
        },
      ]),
      header: getClassName(SectionClassName.Header, [
        {
          condition: !!headerMargin,
          trueClassName: `${SectionClassName.Header}--${headerMargin}`,
        },
      ]),
      inner: getClassName(SectionClassName.Inner, [
        {
          condition: !!innerWidth,
          trueClassName: `${SectionClassName.Inner}--${innerWidth}`,
        },
        {
          condition: !!innerPadding,
          trueClassName: `${SectionClassName.Inner}--${innerPadding}`,
        },
        {
          condition: !!keyLine,
          trueClassName: `${SectionClassName.Inner}--key-line-${keyLine}`,
        },
      ]),
    };
  }

  return (
    <section id={idModel.id} className={baseClassName}>
      {!!anchor && (
        <a id={anchor} href={anchor}>
          <span className="sr-only">{anchor}</span>
        </a>
      )}
      <div id={idModel.inner} className={innerClassName}>
        {!isEmpty(headerChildren) && (
          <header id={idModel.header} className={headerClassName}>
            {headerChildren}
          </header>
        )}
        <div id={idModel.content} className={SectionClassName.Content}>
          {children}
        </div>
      </div>
    </section>
  );
}

Section.defaultProps = {
  theme: SectionTheme.Dark,
};

export default memo(Section);
