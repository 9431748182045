import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { ErrorCheck } from "./errors.definition";

export function checkForErrors<T>(...errorChecks: ErrorCheck<T>[]): boolean {
  return (errorChecks || []).reduce((errors, check): boolean => {
    if (isEmpty(check)) return errors;

    const required = isNil(check.required) ? true : check.required;
    if (!required) return errors;

    if (errors) return true;
    if (typeof check.value === "string" && !isEmpty(check.test)) {
      return check.test(check.value);
    }

    if (typeof check.value === "string") return isNullOrWhiteSpace(check.value);

    return isEmpty(check.value);
  }, false as boolean);
}
