import "./flexibleScheduler.component.scss";
import { ButtonProps, ButtonTheme, isEmpty, isNullOrWhiteSpace, useVisibility } from "@q4/nimbus-ui";
import { extendMoment } from "moment-range";
import MomentLib from "moment-timezone";
import React, { memo, useCallback, useMemo, useState } from "react";
import DeleteConfirmationMessage from "../../../../../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.component";
import MeetingMessage from "../../../../../../../../components/meetingExistsMessage/meetingExistsMessage.component";
import { MeetingEditState } from "../../../../../../../../components/meetingForm/meetingForm.definition";
import { Entity } from "../../../../../../../../definitions/entity.definition";
import { AttendeeType } from "../../../../../../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../../../../../../services/corporateProfile/corporateProfile.model";
import { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import { PresentationSessionType } from "../../../../../../../../services/presentation/presentation.model";
import { getAttendeeCorporateNames, getMeetingLabel } from "../../../../../../../../utils";
import { SchedulerResource, SchedulerResourceType } from "../../meetingScheduler.definition";
import { constructPseudoSlots } from "../../meetingScheduler.utils";
import MeetingModal from "../meetingModal/meetingModal.component";
import Timetable from "../timetable/timetable.component";
import {
  EventType,
  FlexibleSchedulerProps,
  FlexibleSchedulerIdModel,
  FlexibleSchedulerClassName,
} from "./flexibleScheduler.definition";

// eslint rule needed as default moment export will not work for app and jest simultaneously, must be named import (https://github.com/rotaready/moment-range/issues/263)
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const moment = extendMoment(MomentLib as any);

const FlexibleScheduler = (props: FlexibleSchedulerProps): JSX.Element => {
  const {
    id,
    codes,
    companies,
    conference,
    presentations,
    mainResource,
    resources,
    useMeetings,
    eventDay,
    formAttendees,
    formCorporateProfiles,
    handleRefreshMeetingsAndAttendees,
    modalVisible,
    handleModalOpen,
    handleModalClose,
    modalFullscreen,
    setModalFullscreen,
  } = props;

  const { time_zone: conferenceTimeZone } = conference || {};

  // #region Hooks
  const {
    current: currentMeeting,
    items: meetings,
    setCurrent: setCurrentMeeting,
    setItems: setMeetings,
    service: meetingService,
    post,
    putById,
    deleteById,
  } = useMeetings || {};
  // #endregion

  // #region States
  const [meetingMessageVisible, handleMeetingMessageOpen, handleMeetingMessageClose] = useVisibility();
  const [deleteMessageVisible, handleDeleteMessageOpen, handleDeleteMessageClose] = useVisibility();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentExistingMeeting, setCurrentExistingMeeting] = useState<Meeting>(null);

  // #region Memos
  const idModel = useMemo(() => new FlexibleSchedulerIdModel(id), [id]);

  const allResources = useMemo(() => [mainResource, ...resources], [mainResource, resources]);

  const edit = useMemo(() => !isNullOrWhiteSpace(currentMeeting?._id), [currentMeeting]);

  const title = edit ? "Edit Meeting" : "Add a Meeting";

  const subtitle = useMemo(
    () =>
      edit ? `Last modified by ${currentMeeting?.updated_by ?? "Unknown"}` : `${getMeetingLabel(currentMeeting)} Meeting`,
    [edit, currentMeeting]
  );

  const { presentationResourceEvents, breakResourceEvents } = useMemo(() => {
    return (presentations || []).reduce(
      (acc, presentation) => {
        const resourceEvent = {
          start_time: presentation.start_date,
          end_time: presentation.end_date,
          resourceId: mainResource?._id,
          id: presentation._id,
          title: isNullOrWhiteSpace(presentation.presentation_type)
            ? presentation.title
            : `${presentation.presentation_type}: ${presentation.title}`,
          data: presentation,
        };

        const matchingDay = moment(presentation.start_date)?.tz(conferenceTimeZone)?.isSame(eventDay?.date, "day");
        if (presentation.session_type === PresentationSessionType.Break && matchingDay) {
          acc.breakResourceEvents.push({
            ...resourceEvent,
            type: EventType.Break,
          });
          return acc;
        }

        const matchingId = mainResource?.attendees?.some((attendee) => {
          return presentation?._speaker?.some((speaker) => speaker._attendee === attendee._id);
        });
        if (matchingId && matchingDay) {
          acc.presentationResourceEvents.push({
            ...resourceEvent,
            type: EventType.Presentation,
          });
        }

        return acc;
      },
      { presentationResourceEvents: [], breakResourceEvents: [] }
    );
  }, [mainResource, presentations, eventDay?.date, conferenceTimeZone]);

  const resourceMeetingEvents = useMemo(() => {
    return allResources?.map((resource) => {
      if (isEmpty(resource)) return [];

      const schedulerResource = new SchedulerResource(resource);

      return meetings
        .filter((meeting) => {
          const matchingId = meeting._attendee.some((attendee) =>
            schedulerResource.resourceType === SchedulerResourceType.Corporate
              ? (attendee._corporate_profile as CorporateProfile)?._id === resource?._id
              : attendee._id === resource?._id
          );
          const matchingDay = moment(meeting.start_date)?.tz(conferenceTimeZone)?.isSame(eventDay?.date, "day");
          return matchingId && matchingDay;
        })
        .map((meeting) => {
          const meetingLabel = getMeetingLabel(meeting);
          const corporateCompanies = getAttendeeCorporateNames(meeting._attendee, AttendeeType.Corporate);
          const investorCompanies = getAttendeeCorporateNames(meeting._attendee, AttendeeType.Investor);

          return {
            start_time: meeting.start_date,
            end_time: meeting.end_date,
            id: meeting._id,
            title: isNullOrWhiteSpace(meetingLabel) ? "-" : `${meetingLabel} ${corporateCompanies}, ${investorCompanies}`,
            data: meeting,
            type: EventType.Meeting,
          };
        });
    });
  }, [allResources, meetings, eventDay?.date, conferenceTimeZone]);

  const currentSlots = useMemo(() => {
    return constructPseudoSlots(
      eventDay?.slots,
      resourceMeetingEvents,
      [...presentationResourceEvents, ...breakResourceEvents],
      conferenceTimeZone
    );
  }, [eventDay?.slots, resourceMeetingEvents, presentationResourceEvents, breakResourceEvents, conferenceTimeZone]);
  // #endregion

  // #region Handlers
  const handleMeetingUpdate = useCallback(
    (data: Partial<Meeting>) => {
      setCurrentMeeting({ ...currentMeeting, ...data } as Meeting);
    },
    [setCurrentMeeting, currentMeeting]
  );

  const handleMeetingModalClose = useCallback(() => {
    handleModalClose();
    handleMeetingUpdate(new MeetingEditState(null, conference));
  }, [handleModalClose, handleMeetingUpdate, conference]);

  const handleMeetingModalActionResult = useCallback(
    async (success) => {
      if (!success) return;
      await handleRefreshMeetingsAndAttendees();
      handleMeetingModalClose();
      handleMeetingMessageClose();
    },
    [handleRefreshMeetingsAndAttendees, handleMeetingMessageClose, handleMeetingModalClose]
  );

  const handleMeetingModalFullscreen = useCallback(() => {
    if (modalVisible) {
      if (modalFullscreen) {
        handleMeetingModalClose();
      } else {
        setModalFullscreen(true);
      }
    }
  }, [modalVisible, modalFullscreen, setModalFullscreen, handleMeetingModalClose]);

  const handleMeetingModalDelete = useCallback(async () => {
    if (isNullOrWhiteSpace(currentMeeting._id)) return;
    setLoadingDelete(true);
    const response = await deleteById(currentMeeting._id);
    await handleMeetingModalActionResult(response);
    setLoadingDelete(false);
    handleDeleteMessageClose();
  }, [deleteById, currentMeeting, handleDeleteMessageClose, handleMeetingModalActionResult]);

  const handleSave = useCallback(async () => {
    setSaving(true);

    function handlePost(): Promise<Meeting> {
      return post(currentMeeting);
    }

    const hasNoInvestors = !currentMeeting._attendee.some((attendee) => attendee?.type === AttendeeType.Investor);
    const hasNoCorporates = !currentMeeting._attendee.some((attendee) => attendee?.type === AttendeeType.Corporate);
    if ((hasNoInvestors || hasNoCorporates) && !isNullOrWhiteSpace(currentMeeting._id)) {
      return deleteById(currentMeeting._id).then(handleMeetingModalActionResult);
    }

    await (isNullOrWhiteSpace(currentMeeting._id) ? handlePost() : putById(currentMeeting._id, currentMeeting)).then(
      handleMeetingModalActionResult
    );

    setSaving(false);
  }, [currentMeeting, deleteById, handleMeetingModalActionResult, post, putById]);

  const handleMeetingModalAdd = useCallback(async () => {
    const currentInvestors = currentMeeting?._attendee.filter((attendee) => attendee.type === AttendeeType.Investor);
    const currentCorporates = currentMeeting?._attendee.filter((attendee) => attendee.type === AttendeeType.Corporate);

    const apiMeetings = await meetingService.getByConferenceId(conference?._id).then((result) => result?.data);
    setMeetings(apiMeetings);

    const investorMeetings = (apiMeetings || []).filter(
      (meeting) =>
        meeting._attendee.some((meetingAttendee) =>
          currentInvestors.some((currentAttendee) => currentAttendee._id === meetingAttendee._id)
        ) && currentMeeting?._id !== meeting._id
    );

    const existingMeeting = investorMeetings.find((meeting) => {
      return meeting._attendee.some((attendee) => currentCorporates.some((corporate) => corporate._id === attendee._id));
    });

    if (!isEmpty(existingMeeting)) {
      setCurrentExistingMeeting(existingMeeting);
      handleMeetingMessageOpen();
      return;
    }

    handleSave();
  }, [
    conference?._id,
    currentMeeting?._attendee,
    currentMeeting?._id,
    handleMeetingMessageOpen,
    handleSave,
    meetingService,
    setMeetings,
  ]);

  const handleExistingMeetingModalSave = useCallback(async () => {
    handleSave();
  }, [handleSave]);
  // #endregion

  // #region Child Component Props
  const modalFooterActions = useMemo((): ButtonProps[] => {
    return [
      {
        id: idModel?.modalDelete.id,
        key: "delete",
        icon: "q4i-trashbin-4pt",
        className: FlexibleSchedulerClassName.ModalDeleteButton,
        theme: ButtonTheme.DarkSlate,
        wide: false,
        disabled: saving,
        loading: loadingDelete,
        square: true,
        onClick: handleDeleteMessageOpen,
      },
      {
        id: idModel?.modalCancel.id,
        key: "cancel",
        label: modalFullscreen ? "Cancel" : "More Details",
        disabled: saving || loadingDelete,
        theme: ButtonTheme.DarkSlate,
        onClick: handleMeetingModalFullscreen,
      },
      {
        id: idModel?.modalSave.id,
        key: "save",
        label: edit ? "Save" : "Add",
        loading: saving,
        disabled: loadingDelete || saving,
        theme: ButtonTheme.Citrus,
        onClick: handleMeetingModalAdd,
      },
    ];
  }, [
    modalFullscreen,
    edit,
    loadingDelete,
    saving,
    handleMeetingModalFullscreen,
    handleMeetingModalAdd,
    handleDeleteMessageOpen,
    idModel?.modalDelete,
    idModel?.modalCancel,
    idModel?.modalSave,
  ]);
  // #endregion

  return (
    <div id={idModel?.id} className={FlexibleSchedulerClassName.Base}>
      <Timetable
        id={idModel?.timetable.id}
        currentDate={eventDay?.date}
        slots={currentSlots}
        resources={allResources}
        conference={conference}
        meetingEvents={resourceMeetingEvents}
        presentationEvents={presentationResourceEvents}
        breakEvents={breakResourceEvents}
        openModal={handleModalOpen}
        closeModal={() => setModalFullscreen(false)}
        setCurrentMeeting={setCurrentMeeting}
      />
      <MeetingModal
        id={idModel?.modal.id}
        badgeIcon="q4i-calendar-2pt"
        className={FlexibleSchedulerClassName.Modal}
        title={title}
        subtitle={subtitle}
        visible={modalVisible}
        onCloseRequest={handleMeetingModalClose}
        footerActions={modalFooterActions}
        fullscreen={modalFullscreen}
        meetingFormProps={{
          id: idModel?.modalForm.id,
          codes: codes,
          conference: conference,
          companies: companies,
          meeting: currentMeeting,
          meetings: meetings,
          presentations: presentations,
          attendees: formAttendees,
          corporateProfiles: formCorporateProfiles,
          onUpdate: handleMeetingUpdate,
          fullscreenLayout: modalFullscreen,
        }}
      />
      <DeleteConfirmationMessage
        id={idModel?.deleteConfirmationMessageIdModel.id}
        entity={Entity.Meeting}
        loading={loadingDelete}
        visible={deleteMessageVisible}
        onCancel={handleDeleteMessageClose}
        onConfirm={handleMeetingModalDelete}
      />
      <MeetingMessage
        id={idModel?.meetingMessage.id}
        visible={meetingMessageVisible}
        saving={saving}
        meeting={currentExistingMeeting}
        timeZone={conferenceTimeZone}
        disabled={loadingDelete || saving}
        onClose={() => handleMeetingModalActionResult(true)}
        onCancel={() => handleMeetingModalActionResult(true)}
        onConfirm={handleExistingMeetingModalSave}
      />
    </div>
  );
};

export default memo(FlexibleScheduler);
