import { isNullOrWhiteSpace, isNil } from "@q4/nimbus-ui";
import Validator from "validator";

export function basicCompare(a: string, b: string): number {
  return a?.toLowerCase()?.trim()?.localeCompare(b?.toLowerCase()?.trim());
}

export function fuzzyCompare(a: string, b: string): boolean {
  if (a == b) return true;
  if (isNullOrWhiteSpace(a) || isNullOrWhiteSpace(b)) return false;
  return a.toString().trim().toLocaleLowerCase() === b.toString().trim().toLocaleLowerCase();
}

export function fuzzyIncludes(a: string, b: string): boolean {
  if (fuzzyCompare(a, b)) return true;
  if (isNullOrWhiteSpace(a) || isNullOrWhiteSpace(b)) return false;
  if (a.includes(b)) return true;
  return a.toString().toLocaleLowerCase().includes(b.toString().toLocaleLowerCase());
}

export function convertUnicodetoString(unicode: string): string {
  const Hex = 16;
  return unicode?.codePointAt(0).toString(Hex) ?? "";
}

export function validateEmptyString(value: string): boolean {
  const trimmedText = value?.trim();
  return trimmedText !== "";
}

export function validateIsNil<T>(value: T): boolean {
  return !isNil(value);
}

export function validateText(value: string): boolean {
  const trimmedText = value?.trim();
  return !isNullOrWhiteSpace(trimmedText);
}

export function validateBoolean(value: boolean): boolean {
  return !!value;
}

export function capitalizeFirstLetter(a: string): string {
  if (isNullOrWhiteSpace(a)) return "";
  return a.charAt(0).toUpperCase() + a.slice(1);
}

export function getBackgroundImageUrl(a: string): string {
  if (isNullOrWhiteSpace(a)) return null;
  return `url("${encodeURI(a.trim())}")`;
}

export function getLinearGradient(secondaryBackgroundRgb: string): string {
  if (isNullOrWhiteSpace(secondaryBackgroundRgb)) return null;
  return `linear-gradient(to bottom, rgba(${secondaryBackgroundRgb}, 0.3) 0%, rgba(${secondaryBackgroundRgb}, 0.75) 65%, rgba(${secondaryBackgroundRgb}, 0.85) 100%)`;
}

export function validateNonRequiredUrlString(input: string): boolean {
  return !isNullOrWhiteSpace(input) && !Validator.isURL(`${input}`);
}
