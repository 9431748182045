import { IdModelBase, TextboxIdModel, TextareaIdModel, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { QuestionsFormChildrenProps } from "../../questionsForm.definition";

export class QuestionsFormTextIdModel extends IdModelBase {
  shortAnswer: TextboxIdModel;
  longAnswer: TextareaIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.shortAnswer = new TextboxIdModel(`${this.id}ShortAnswer`);
    this.longAnswer = new TextareaIdModel(`${this.id}LongAnswer`);
  }
}

export type QuestionsFormTextProps = QuestionsFormChildrenProps;
