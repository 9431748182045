import "./badge.component.scss";
import { getClassName, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { BadgeClassName, BadgeIdModel, BadgeProps, BadgeTheme } from "./badge.definition";

export const Badge = (props: BadgeProps): JSX.Element => {
  const { className, id, theme: themeProp, icon: iconProp, small, children, color } = props;

  const idModel = useMemo(() => new BadgeIdModel(id), [id]);
  const theme = useMemo(() => (isEmpty(themeProp) ? BadgeTheme.None : themeProp), [themeProp]);
  const icon = useMemo(() => (isEmpty(iconProp) ? null : iconProp), [iconProp]);

  const baseClassName = useMemo((): string => {
    return getClassName(BadgeClassName.Base, [
      { condition: !isNullOrWhiteSpace(className), trueClassName: className },
      { condition: !isEmpty(theme), trueClassName: `${BadgeClassName.Base}--${theme}` },
      { condition: isEmpty(color), falseClassName: `${BadgeClassName.Text}--${color}` },
      {
        condition: !isNullOrWhiteSpace(icon) && isEmpty(children),
        trueClassName: `${BadgeClassName.Icon} ${icon}`,
        falseClassName: BadgeClassName.Text,
      },
      {
        condition: !isNullOrWhiteSpace(icon) && !isEmpty(children),
        trueClassName: `${BadgeClassName.TextIcon} ${icon}`,
      },
      { condition: small, trueClassName: BadgeClassName.Small },
    ]);
  }, [children, className, color, icon, small, theme]);

  return (
    <span id={idModel.id} className={baseClassName}>
      {children}
    </span>
  );
};

export default memo(Badge);
