import { IdModelBase, isNullOrWhiteSpace, LayoutIdModel, PlaceholderContentIdModel } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { LobbyHookModel } from "../../hooks/useLobby/useLobby.definition";
import { ConferenceListIdModel } from "./components/conferenceList/conferenceList.definition";
import { InfoIdModel } from "./components/info/info.definition";
import { NavigationIdModel } from "./components/navigation/navigation.definition";
import { PoweredByIdModel } from "./components/poweredBy/poweredBy.definition";

export interface CompanyLobbyLayoutProps extends BaseComponentWithChildrenProps {
  conference: Conference;
  conferences?: Conference[];
  onSignOut: LobbyHookModel["handleSignOut"];
  profile?: AttendeeViewModel;
  isAttendeeProfile?: boolean;
}

export enum CompanyLobbyLayoutClassName {
  Base = "lobby-layout",
  Logo = "lobby-layout_logo",
  ConferenceList = "lobby-layout_conference-list",

  // Layout
  Inner = "lobby-layout_inner",
  Splash = "lobby-layout_splash",
  SplashGradient = "lobby-layout_splash-gradient",
  Header = "lobby-layout_header",
}

export class CompanyLobbyLayoutIdModel extends IdModelBase {
  conference: LayoutIdModel;
  conferenceList: ConferenceListIdModel;
  info: InfoIdModel;
  logo: string;
  navigation: NavigationIdModel;
  placeHolderContent: PlaceholderContentIdModel;
  poweredBy: PoweredByIdModel;
  spinner: string;
  splash: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.conference = new LayoutIdModel(`${this.id}Conference`);
    this.conferenceList = new ConferenceListIdModel(`${this.id}ConferenceList`);
    this.info = new InfoIdModel(`${this.id}Info`);
    this.logo = `${this.id}Logo`;
    this.navigation = new NavigationIdModel(`${this.id}Navigation`);
    this.placeHolderContent = new PlaceholderContentIdModel(`${this.id}PlaceHolderContent`);
    this.poweredBy = new PoweredByIdModel(`${this.id}PoweredBy`);
    this.spinner = `${this.id}Spinner`;
    this.splash = `${this.id}Splash`;
  }
}
