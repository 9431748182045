import "./dialInformation.component.scss";
import { Anchor, AnchorTarget, Collapsable, isEmpty, Swapable, Tabs, TabsTheme, Text, TextPreset } from "@q4/nimbus-ui";
import { upperCase } from "lodash";
import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import { VendorName } from "../../../../../services/conference/conference.model";
import Section from "../../../../company/lobby/components/section/section.component";
import { SectionInnerWidth, SectionTheme } from "../../../../company/lobby/components/section/section.definition";
import { ItineraryAnchors } from "../../itinerary.definition";
import { DialInformationClassName, DialInformationProp, DialInIdModel } from "./dialInformation.definition";
import { getDialInNumbers } from "./dialInformation.helper";
import { ZoomDialInformationLink, MsTeamsDialInformationLink } from "./dialInformation.utils";

const DialInformation = (prop: DialInformationProp): JSX.Element => {
  const { id, video_vendors, showAll, visible: visibleProp } = prop;

  const [currentTab, setCurrentTab] = useState(0);
  const isSingleVendor = useMemo(() => video_vendors?.length === 1, [video_vendors?.length]);

  const idModel = useMemo(() => new DialInIdModel(id), [id]);
  const visible = useMemo(() => visibleProp ?? true, [visibleProp]);

  const handleDialInTabChange = useCallback((_index: number, value: string) => {
    setCurrentTab(parseInt(value));
  }, []);

  function renderDialInNumbers(vendorName: VendorName): JSX.Element {
    return (
      <div className={DialInformationClassName.Inner}>
        {getDialInNumbers<JSX.Element>(
          vendorName,
          (item) => {
            return <span key={item}>{item}</span>;
          },
          (key, item) => {
            return (
              <Fragment key={key}>
                <Text preset={TextPreset.Paragraph}>{key}</Text>
                {item}
              </Fragment>
            );
          },
          (group, index) => {
            return (
              <div key={`country-group-${index}`} className={DialInformationClassName.Group}>
                {group}
              </div>
            );
          }
        )}
      </div>
    );
  }

  function renderDialInformation(vendorName: VendorName): JSX.Element {
    return (
      <div key={`dialInformation-${vendorName}`} className={DialInformationClassName.Vendor}>
        {showAll && <Text preset={TextPreset.Title}>{upperCase(vendorName)}</Text>}
        <Text preset={TextPreset.Paragraph}>
          <Anchor
            className={DialInformationClassName.Link}
            url={vendorName === VendorName.Zoom ? ZoomDialInformationLink : MsTeamsDialInformationLink}
            target={AnchorTarget.Blank}
          >
            Click here to find your local number {">"}
          </Anchor>
        </Text>
        <Text className={DialInformationClassName.Subheader} preset={TextPreset.Paragraph}>
          DIAL BY YOUR LOCATION
        </Text>
        {renderDialInNumbers(vendorName)}
      </div>
    );
  }

  if (isEmpty(video_vendors)) return null;

  return (
    <Collapsable collapsed={!visible}>
      <Section
        id={idModel.id}
        className={DialInformationClassName.Base}
        theme={SectionTheme.Gradient}
        innerWidth={SectionInnerWidth.Contained}
      >
        <Anchor id={ItineraryAnchors.DialIn} url={`#${ItineraryAnchors.DialIn}`}>
          <span className="sr-only">dial in</span>
        </Anchor>
        <h2 key={DialInformationClassName.Header} className={`${DialInformationClassName.Header}`}>
          Audio Conferencing Information
        </h2>
        {!isSingleVendor && !showAll && (
          <Tabs
            id={idModel.tabs}
            className={DialInformationClassName.Tabs}
            theme={TabsTheme.Slate}
            items={(video_vendors || []).map((x, i) => {
              return {
                key: `dial-in-toggle_${x.name}`,
                id: `dial-in-toggle_${x.name}`,
                label: upperCase(x.name),
                value: `${i}`,
              };
            })}
            selected={+currentTab}
            onChange={handleDialInTabChange}
          />
        )}
        {!showAll ? (
          <Swapable
            id={idModel.swappable}
            selected={+currentTab}
            layers={(video_vendors || []).map((x) => renderDialInformation(x.name))}
          />
        ) : (
          (video_vendors || []).map((x) => renderDialInformation(x.name))
        )}
      </Section>
    </Collapsable>
  );
};

export default memo(DialInformation);
