import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, PlaceholderContentIdModel } from "@q4/nimbus-ui";
import { UserContextState } from "../../../contexts/user/user.definition";
import { LoginFormIdModel } from "./components/loginForm/loginForm.definition";

export const LoginAccess = "password";

export enum LoginQuery {
  CustomPath = "path",
  ConferenceId = "conferenceId",
  ConferencePath = "conferencePath",
  Id = "id",
  IdToken = "idToken",
  Error = "error",
  ErrorDescription = "errorDescription",
  Login = "login",
  Redirect = "redirect",
}

export interface LoginProps extends BaseComponentProps {
  userContext?: UserContextState;
}

export interface LoginMatchParams {
  company: string;
}

export enum LoginClassName {
  Base = "login",
  PlaceholderContent = "login_placeholder",
  Button = "login_button",

  Content = "login_content",
  Credentials = "login_credentials",
  ContentRow = "login_content-row",
  Disclaimer = "login_disclaimer",
  DisclaimerTitle = "login_disclaimer-title",
  DisclaimerContent = "login_disclaimer-content",
  Message = "login_message",
  Faq = "login_faq",
  FaqLink = "login_faq-link",
  FaqModal = "login_faq-modal",
}

class PageIdModel extends IdModelBase {
  placeholder: PlaceholderContentIdModel;
  form: LoginFormIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.placeholder = new PlaceholderContentIdModel(`${this.id}PlaceHolderContent`);
    this.form = new LoginFormIdModel(`${this.id}LoginForm`);
  }
}

const PageId = "CompanyLogin";
export const CompanyLoginIdModel = new PageIdModel(PageId);
