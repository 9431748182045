import "./summary.component.scss";
import { Button, ButtonTheme, Card, CardTheme, isEmpty, isNullOrWhiteSpace, Text, TextPreset } from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import { JsonFormLabels } from "../../../../../components/jsonForm/jsonForm.definition";
import { AttendeeType } from "../../../../../services/attendee/attendee.model";
import { customQuestionsStepBaseProps, personalInformationStepBaseProps } from "../../hooks/useSections.definition";
import TermsAndConditions from "../../termsAndConditions/termsAndConditions.component";
import AnswerSummary from "../answerSummary/answerSummary.component";
import { RegistrationSummaryClassName, RegistrationSummaryIdModel, RegistrationSummaryProps } from "./summary.definition";

const RegistrationSummary = (props: RegistrationSummaryProps): JSX.Element => {
  const { id, registrant, loading, companyName, isEditMode, questionGroup, onStep, onSubmit, isFormDirty } = props;

  const idModel = useMemo(() => new RegistrationSummaryIdModel(id), [id]);

  const [isPolicyAgree, setIsPolicyAggree] = useState(false);

  function setStepPersonalInformation(): void {
    onStep(personalInformationStepBaseProps.id);
  }

  function setStepCustomQuestions(): void {
    onStep(customQuestionsStepBaseProps.id);
  }

  function handleStepBack(): void {
    onStep(false);
  }

  function handlePost(): void {
    onSubmit();
  }

  function handlePolicyAgreeChange(policyAgree: boolean): void {
    setIsPolicyAggree(policyAgree);
  }

  function renderInfo(): JSX.Element[] {
    if (isEmpty(registrant)) return null;

    const company = registrant.attendee_type === AttendeeType.Internal ? companyName || "" : registrant.company;
    return [
      <Text key="registration-summary_name" preset={TextPreset.Paragraph}>
        Name: {registrant.first_name} {registrant.last_name}
      </Text>,
      <Text key="registration-summary_email" preset={TextPreset.Paragraph}>
        Email: {registrant.email}
      </Text>,
      <Text key="registration-summary_type" preset={TextPreset.Paragraph}>
        Company: {company}
      </Text>,
      <Text key="registration-summary_title" preset={TextPreset.Paragraph}>
        Title: {registrant.title}
      </Text>,
      !isNullOrWhiteSpace(registrant.sales_representative) && (
        <Text id={idModel.salesRepSection?.id} key="registration-summary_referral" preset={TextPreset.Paragraph}>
          Relationship Manager/Sales Representative Name: {registrant.sales_representative}
        </Text>
      ),
      !isNullOrWhiteSpace(registrant.guest_attendee_type) && (
        <Text key="registration-summary_guest_attendee_type" preset={TextPreset.Paragraph}>
          Attendee Type: {registrant.guest_attendee_type}
        </Text>
      ),
    ];
  }

  return (
    <div id={idModel.id} className={RegistrationSummaryClassName.Base}>
      <Card
        id={idModel.personalInformationSection?.id}
        className={RegistrationSummaryClassName.Section}
        title="Personal Information"
        theme={CardTheme.Light}
        headerChildren={
          <Button
            id={idModel.personalEdit?.id}
            icon="q4i-edit-4pt"
            label="Edit"
            disabled={loading}
            theme={ButtonTheme.LightGrey}
            onClick={setStepPersonalInformation}
          />
        }
      >
        {renderInfo()}
      </Card>
      {questionGroup && (
        <AnswerSummary
          id={idModel.answerSummary?.id}
          loading={loading}
          questionGroup={questionGroup}
          answers={registrant.custom_question_answers || []}
          goToEdit={setStepCustomQuestions}
        />
      )}
      {!isEditMode && (
        <TermsAndConditions
          id={idModel.termsAndConditions?.id}
          onAgree={handlePolicyAgreeChange}
          policyAgree={isPolicyAgree}
        />
      )}
      <div className={RegistrationSummaryClassName.Section}>
        <Button
          id={idModel.stepBack?.id}
          className={"registration_back-button"}
          label={JsonFormLabels.PrevStep}
          disabled={loading}
          theme={ButtonTheme.LightGrey}
          onClick={handleStepBack}
        />
        <Button
          id={idModel.confirm?.id}
          label={isEditMode ? "UPDATE INFORMATION" : "CONFIRM REGISTRATION"}
          disabled={(!isPolicyAgree && !isEditMode) || (isEditMode && !isFormDirty)}
          loading={loading}
          theme={ButtonTheme.Rain}
          onClick={handlePost}
        />
      </div>
    </div>
  );
};

export default memo(RegistrationSummary);
