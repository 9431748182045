import "./sections.component.scss";
import { isEmpty, ComponentSizeModifier, Swapable, Collapsable, Tabs } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { RegistrationSectionsClassName, RegistrationSectionsProps } from "./sections.definition";

const RegistrationSections = (props: RegistrationSectionsProps): JSX.Element => {
  const { sections, tabStep } = props;

  const tabs = useMemo(
    () =>
      (sections || []).map((x, i) => {
        return {
          key: `registration-tab_${x.label}`,
          id: x.id,
          label: x.label,
          value: x.label,
          disabled: i !== tabStep,
        };
      }),
    [sections, tabStep]
  );

  const layers = useMemo(
    () =>
      (sections || []).map((x) => {
        return (
          <div className="registration_layer" key={`registration_section-${x.label}`}>
            {x.component}
          </div>
        );
      }),
    [sections]
  );
  return (
    <div className={RegistrationSectionsClassName.Base}>
      <Collapsable collapsed={isEmpty(sections)}>
        <Tabs
          className={RegistrationSectionsClassName.Tabs}
          onChange={null}
          items={tabs}
          size={ComponentSizeModifier.Compact}
          selected={tabStep}
        />
      </Collapsable>
      <Swapable selected={tabStep} layers={layers} />
    </div>
  );
};

export default memo(RegistrationSections);
