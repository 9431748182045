import {
  BaseComponentProps,
  ComboBoxIdModel,
  DatePickerIdModel,
  FileUploaderIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  MessageIdModel,
  SelectIdModel,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import type { OnDemand } from "../../services/admin/onDemand/onDemand.model";
import type { Conference } from "../../services/conference/conference.model";
import type { Speaker } from "../../services/speaker/speaker.model";

export enum OnDemandFormClassName {
  Base = "ondemand-form",
  Description = "ondemand-form_description",
}

export interface OnDemandFormProps extends BaseComponentProps {
  codes: string[];
  conference: Conference;
  onDemand: OnDemand;
  speakers: Speaker[];
  onUpdate: (data: Partial<OnDemand>) => void;
}

export class OnDemandFormIdModel extends IdModelBase {
  title: TextboxIdModel;
  endDate: DatePickerIdModel;
  endTime: SelectIdModel;
  description: TextboxIdModel;
  overrideUrl: TextboxIdModel;
  code: ComboBoxIdModel;
  order: TextboxIdModel;
  speakers: ComboBoxIdModel;
  messageModal: MessageIdModel;
  thumbnail: FileUploaderIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.endDate = new DatePickerIdModel(`${this.id}StartDateDatePicker`);
    this.endTime = new SelectIdModel(`${this.id}EndTimeSelect`);
    this.title = new TextboxIdModel(`${this.id}TitleTextbox`);
    this.description = new TextboxIdModel(`${this.id}DescriptionTextbox`);
    this.overrideUrl = new TextboxIdModel(`${this.id}OverrideUrlTextbox`);
    this.code = new ComboBoxIdModel(`${this.id}CodeCombobox`);
    this.order = new TextboxIdModel(`${this.id}OrderTextbox`);
    this.speakers = new ComboBoxIdModel(`${this.id}Speaker`);
    this.messageModal = new MessageIdModel(`${this.id}MessageModal`);
    this.thumbnail = new FileUploaderIdModel(`${this.id}ThumbnailUpload`);
  }
}
