import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import { Answer } from "../../../../services/answer/answer.model";
import { QuestionType } from "../../../../services/questionGroup/questionGroup.model";
import CheckboxGroup from "../../../checkboxGroup/checkboxGroup.component";
import { QuestionsFormChoiceProps } from "./questionsFormChoice.definition";

function QuestionsFormChoice(props: QuestionsFormChoiceProps): JSX.Element {
  const { id, question, answer: answerData, onChange, setFormErrors, indexNumber } = props;

  const isMultiChoiceMode = useMemo(
    () => question.answer_type.field_type === QuestionType.MultipleChoice,
    [question.answer_type.field_type]
  );
  const allowCustomOption = useMemo(() => question.add_other_option, [question.add_other_option]);
  const answerOptions = useMemo(() => question.options.map((option) => option.answer), [question.options]);

  const answer = useMemo(() => {
    if (isNullOrWhiteSpace(answerData as string)) return [];
    if (!Array.isArray(answerData)) return [answerData as string];
    return answerData;
  }, [answerData]);

  const [showError, setShowError] = useState<boolean>(false);

  const onCheckboxGroupChange = useCallback(
    (value: string | string[]) => {
      onChange(new Answer({ _question: question._id, answer: value }));
    },
    [question, onChange]
  );

  return (
    <CheckboxGroup
      id={id}
      value={answer}
      items={answerOptions}
      allowCustomOption={allowCustomOption}
      isMultiChoiceMode={isMultiChoiceMode}
      setFormErrors={setFormErrors}
      indexNumber={indexNumber}
      showError={showError}
      setShowError={setShowError}
      onChange={onCheckboxGroupChange}
    />
  );
}

export default memo(QuestionsFormChoice);
