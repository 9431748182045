import {
  BaseComponentProps,
  CheckboxIdModel,
  CheckboxListId,
  ErrorModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";

export interface CheckboxGroupProps extends BaseComponentProps {
  value: string[];
  items: string[];
  allowCustomOption?: boolean;
  isMultiChoiceMode?: boolean;
  onChange: (value: string | string[]) => void;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  indexNumber?: number;
  showError?: boolean;
  setShowError?: (value: boolean) => void;
}

export enum CheckboxGroupClassName {
  ListItem = "checkbox-group_choice_list-item",
  CustomText = "checkbox-group_choice_custom-text",
  ErrorText = "checkbox-group_choice_error-text",
}

export const ErrorAppearanceDelay = 200;

export class CheckboxGroupIdModel extends IdModelBase {
  checkboxList: CheckboxListId;
  customOptionCheckbox: CheckboxIdModel;
  customOptionText: TextboxIdModel;

  constructor(id: string) {
    super(`${id}CheckboxGroup`);
    if (isNullOrWhiteSpace(this.id)) return;

    this.checkboxList = new CheckboxListId(`${this.id}List`);
    this.customOptionCheckbox = new CheckboxIdModel(`${this.id}OtherCheckbox`);
    this.customOptionText = new TextboxIdModel(`${this.id}OtherText`);
  }
}
