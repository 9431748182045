import "./registration.component.scss";
import {
  Checkbox,
  DatePicker,
  DateRangePickerValue,
  ErrorModel,
  Form,
  getClassName,
  Grid,
  GridClassName,
  GridColumn,
  InfoIcon,
  isEmpty,
  isNullOrWhiteSpace,
  Keyline,
  KeylineTheme,
  Origin,
  TooltipTheme,
} from "@q4/nimbus-ui";
import { set } from "lodash";
import { Moment } from "moment-timezone";
import React, { memo, useCallback, useMemo, useState } from "react";
import Accordion from "../../../../../../components/accordion/accordion.component";
import GridCard from "../../../../../../components/gridCard/gridCard.component";
import { DateFormat } from "../../../../../../definitions/date.definition";
import { NuiGridColumnClassName } from "../../../../../../definitions/nimbus.definition";
import { AttendeeType } from "../../../../../../services/attendee/attendee.model";
import { ConferenceType } from "../../../../../../services/conference/conference.model";
import {
  isAttendeeRegistrationAllowed,
  isDateBetweenConferenceDates,
} from "../../../../../public/register/registration.utils";
import {
  ConferenceRegistrationCollapsibleDefault,
  ConferenceRegistrationDateKeys,
  ConferenceRegistrationEditClassName,
  ConferenceRegistrationEditIdModel,
  ConferenceRegistrationEditProps,
  ERRORICON,
} from "./registration.definition";

const ConferenceRegistrationEdit = (props: ConferenceRegistrationEditProps): JSX.Element => {
  const { className, id, conference, setRegistrationError, onChange } = props;

  const {
    auto_register,
    corporate_register,
    investor_register,
    general_register,
    internal_register,
    open_date: conferenceOpenDate,
    close_date: conferenceCloseDate,
    guest_other_types_enabled,
    display_login_link,
    corporate_deadlines,
    investor_deadlines,
    conference_type: type,
  } = conference || {};

  const [collapsibleState, setCollapsibleState] = useState(ConferenceRegistrationCollapsibleDefault);

  const { enabled: enabledProps } = auto_register || {};

  const autoRegisterEnabled = useMemo(() => !!enabledProps, [enabledProps]);
  const otherSubtypes = useMemo(() => !!guest_other_types_enabled, [guest_other_types_enabled]);
  const displayLoginLink = useMemo(() => !!display_login_link, [display_login_link]);
  const isFullConference = useMemo(() => type === ConferenceType.FullConference, [type]);

  function toggleCollapsedState(key: string): <T>(value: T) => void {
    return function <T>(value: T): void {
      setCollapsibleState({ ...collapsibleState, [key]: value });
    };
  }

  const baseClassName = useMemo(
    () =>
      getClassName(ConferenceRegistrationEditClassName.Base, [
        { condition: isNullOrWhiteSpace(className), falseClassName: className },
      ]),
    [className]
  );

  const idModel = useMemo(() => new ConferenceRegistrationEditIdModel(id), [id]);

  const defaultDisabledDays = [{ before: conferenceOpenDate?.toDate() }, { after: conferenceCloseDate?.toDate() }];

  const defaultErrorMessage: ErrorModel = useMemo(
    () => ({
      visible: false,
      message: `Set a date between Open and Close dates - ${conferenceOpenDate
        ?.clone()
        .format(DateFormat.Short)} and ${conferenceCloseDate?.clone().format(DateFormat.Short)}`,
      icon: ERRORICON,
    }),
    [conferenceCloseDate, conferenceOpenDate]
  );

  const checkForErrors = useCallback(
    (key: ConferenceRegistrationDateKeys): boolean => {
      const validations = {
        [ConferenceRegistrationDateKeys.CorporateRegister]:
          corporate_register?.start_date && !isAttendeeRegistrationAllowed(conference, AttendeeType.Corporate),
        [ConferenceRegistrationDateKeys.InvestorRegister]:
          investor_register?.start_date && !isAttendeeRegistrationAllowed(conference, AttendeeType.Investor),
        [ConferenceRegistrationDateKeys.InvestorMeetingRequestDeadline]:
          investor_deadlines?.meeting_request &&
          !isDateBetweenConferenceDates(investor_deadlines?.meeting_request, conference),
        [ConferenceRegistrationDateKeys.SpeakerDeadline]:
          !isEmpty(corporate_deadlines?.speaker) && !isDateBetweenConferenceDates(corporate_deadlines?.speaker, conference),
        [ConferenceRegistrationDateKeys.PresentationDeadline]:
          !isEmpty(corporate_deadlines?.presentation) &&
          !isDateBetweenConferenceDates(corporate_deadlines?.presentation, conference),
        [ConferenceRegistrationDateKeys.CorporateMeetingDeadline]:
          !isEmpty(corporate_deadlines?.meeting) && !isDateBetweenConferenceDates(corporate_deadlines?.meeting, conference),
        [ConferenceRegistrationDateKeys.AvailabilityChangesDeadline]:
          investor_deadlines?.availability_changes &&
          !isDateBetweenConferenceDates(investor_deadlines?.availability_changes, conference),
        [ConferenceRegistrationDateKeys.GeneralRegister]:
          general_register?.start_date && !isAttendeeRegistrationAllowed(conference, AttendeeType.Guest),
        [ConferenceRegistrationDateKeys.InternalRegister]:
          internal_register?.start_date && !isAttendeeRegistrationAllowed(conference, AttendeeType.Internal),
      };

      setRegistrationError(Object.values(validations).some((x) => !!x));
      return validations[key];
    },
    [
      corporate_register?.start_date,
      conference,
      investor_register?.start_date,
      investor_deadlines?.meeting_request,
      investor_deadlines?.availability_changes,
      corporate_deadlines?.speaker,
      corporate_deadlines?.presentation,
      corporate_deadlines?.meeting,
      general_register?.start_date,
      internal_register?.start_date,
      setRegistrationError,
    ]
  );

  const rangeStartDate = useCallback(
    (registerStartDate: Moment, registerEndDate: Moment) =>
      (!isEmpty(registerStartDate) ? registerStartDate : registerEndDate)?.clone(),
    []
  );

  const rangeEndDate = useCallback(
    (registerStartDate: Moment, registerEndDate: Moment) =>
      (!isEmpty(registerEndDate) ? registerEndDate : registerStartDate)?.clone(),
    []
  );

  const handleEnabledChange = useCallback(
    (checked: boolean) => {
      const { start_date: registerStartDate, end_date: registerEndDate } = auto_register || {};
      onChange({
        auto_register: {
          enabled: checked,
          start_date: registerStartDate,
          end_date: registerEndDate,
        },
      });
    },
    [onChange, auto_register]
  );

  const handleDateChange = useCallback(
    (key: string) => {
      return (date: Moment): void => {
        onChange(set(conference, key, date));
      };
    },
    [conference, onChange]
  );

  function handleDateRangeChange(key: string): (date: DateRangePickerValue) => void {
    return function (date: DateRangePickerValue): void {
      const { startValue: start_date, endValue: end_date } = date || {};
      onChange({
        [key]: {
          ...(key === "auto_register" ? { enabled: autoRegisterEnabled } : {}),
          start_date: start_date?.clone().startOf("day"),
          end_date: end_date?.clone().endOf("day"),
        },
      });
    };
  }

  function handleUseSubTypesChange(checked: boolean) {
    onChange({
      guest_other_types_enabled: checked,
    });
  }

  function handleDisplayLoginLinkChange(checked: boolean) {
    onChange({
      display_login_link: checked,
    });
  }

  const investorRegistrationFields = [
    {
      key: "Investor Registration",
      width: "1-of-1",
      smallWidth: "1-of-1",
      label: (
        <>
          Investor Registration Dates
          <InfoIcon
            tooltipProps={{
              label: "Set dates to enable investor registration",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          />
        </>
      ),
      error: {
        ...defaultErrorMessage,
        visible: checkForErrors(ConferenceRegistrationDateKeys.InvestorRegister),
        message: `This date range must be during the conference’s  Open and Close dates - 
      ${conferenceOpenDate?.clone().format(DateFormat.Short)} and 
      ${conferenceCloseDate?.clone().format(DateFormat.Short)}`,
      },
      children: (
        <Grid>
          <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-2">
            <DatePicker
              id={idModel.investorRegisterRange?.id}
              className={ConferenceRegistrationEditClassName.InvestorRegister}
              placeholder="Select Dates"
              range={true}
              startValue={rangeStartDate(investor_register?.start_date, investor_register?.end_date)}
              endValue={rangeEndDate(investor_register?.start_date, investor_register?.end_date)}
              disabledDays={[
                { before: new Date(conferenceOpenDate?.clone().format()) },
                { after: new Date(conferenceCloseDate?.clone().format()) },
              ]}
              clearable
              onRangeChange={handleDateRangeChange("investor_register")}
            />
          </GridColumn>
        </Grid>
      ),
    },
    {
      key: "Availability Changes Deadline",
      width: "1-of-2",
      smallWidth: "1-of-1",
      label: (
        <>
          Availability Changes Deadline
          <InfoIcon
            tooltipProps={{
              label: "Last day to add or update availability",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          />
        </>
      ),
      error: {
        ...defaultErrorMessage,
        visible: checkForErrors(ConferenceRegistrationDateKeys.AvailabilityChangesDeadline),
      },
      children: (
        <Grid>
          <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-1">
            <DatePicker
              id={idModel.availabilityChangesDeadline?.id}
              placeholder="Select Date"
              disabledDays={defaultDisabledDays}
              clearable
              value={investor_deadlines?.availability_changes?.clone()}
              onChange={handleDateChange("investor_deadlines.availability_changes")}
            />
          </GridColumn>
        </Grid>
      ),
    },
  ];

  isFullConference &&
    investorRegistrationFields.push({
      key: "Investor Meeting Request Deadline",
      width: "1-of-2",
      smallWidth: "1-of-1",
      label: (
        <>
          Meeting Request Deadline
          <InfoIcon
            tooltipProps={{
              label: "Last day to add or update meeting requests",
              position: Origin.Top,
              theme: TooltipTheme.Slate,
            }}
          />
        </>
      ),
      error: {
        ...defaultErrorMessage,
        visible: checkForErrors(ConferenceRegistrationDateKeys.InvestorMeetingRequestDeadline),
        message: `Set a date between ${conferenceOpenDate?.clone().format(DateFormat.Short)} and ${conferenceCloseDate
          ?.clone()
          .format(DateFormat.Short)} or leave this field blank`,
      },
      children: (
        <Grid>
          <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-1">
            <DatePicker
              id={idModel.investorMeetingDeadline?.id}
              placeholder="Select Date"
              value={investor_deadlines?.meeting_request}
              disabledDays={[{ before: conferenceOpenDate?.toDate() }, { after: conferenceCloseDate?.toDate() }]}
              onChange={handleDateChange("investor_deadlines.meeting_request")}
              clearable
            />
          </GridColumn>
        </Grid>
      ),
    });

  const investorSectionHasError =
    checkForErrors(ConferenceRegistrationDateKeys.AvailabilityChangesDeadline) ||
    checkForErrors(ConferenceRegistrationDateKeys.InvestorRegister) ||
    checkForErrors(ConferenceRegistrationDateKeys.InvestorMeetingRequestDeadline);

  return (
    <GridCard cardProps={{ id: idModel.id, className: baseClassName, title: "Registration Configuration" }}>
      <Keyline theme={KeylineTheme.SoftGrey} />

      <Accordion
        id={idModel.autoRegisterAccordion?.id}
        header={<div>Auto Register</div>}
        collapsed={collapsibleState.autoRegisterCollapsed}
        mediumHeader
        onChange={toggleCollapsedState("autoRegisterCollapsed")}
      >
        <Form
          id={idModel.autoRegister?.id}
          className={ConferenceRegistrationEditClassName.AutoRegister}
          fields={[
            {
              key: "Auto Register Enabled",
              width: "1-of-5",
              smallWidth: "1-of-1",
              label: "Auto Register",
              children: (
                <Checkbox
                  alignmentPadding
                  id={idModel.autoRegisterEnabled?.id}
                  className={ConferenceRegistrationEditClassName.AutoRegisterEnabled}
                  checked={autoRegisterEnabled}
                  onChange={handleEnabledChange}
                  label="Enabled"
                />
              ),
            },
            {
              key: "Auto Registration Dates",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: (
                <>
                  Auto Registration Dates
                  <InfoIcon
                    tooltipProps={{
                      label: "Set dates to enable automatic registration",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              children: (
                <DatePicker
                  id={idModel.autoRegisterRange?.id}
                  className={ConferenceRegistrationEditClassName.AutoRegisterRange}
                  range={true}
                  startValue={rangeStartDate(auto_register?.start_date, auto_register?.end_date)}
                  endValue={rangeEndDate(auto_register?.start_date, auto_register?.end_date)}
                  disabledDays={defaultDisabledDays}
                  onRangeChange={handleDateRangeChange("auto_register")}
                />
              ),
            },
          ]}
        />
        <Grid>
          <GridColumn className={NuiGridColumnClassName.BaseWithRowModifier} width="1-of-1">
            <Checkbox
              id={idModel.displayLoginLink.id}
              checked={displayLoginLink}
              onChange={handleDisplayLoginLinkChange}
              label="Display Login Link"
            />
          </GridColumn>
        </Grid>
      </Accordion>

      <Keyline theme={KeylineTheme.SoftGrey} />

      <Accordion
        id={idModel.corporateAccordion.id}
        header={<div>Corporate Registration</div>}
        collapsed={collapsibleState.corporateRegistrationCollapsed}
        mediumHeader
        onChange={toggleCollapsedState("corporateRegistrationCollapsed")}
      >
        <Form
          id={idModel.corporateRegister?.id}
          className={ConferenceRegistrationEditClassName.CorporateRegister}
          fields={[
            {
              key: "Corporate Registration",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: (
                <>
                  Corporate Registration Dates
                  <InfoIcon
                    tooltipProps={{
                      label: "Set dates to enable corporate registration",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.CorporateRegister),
                message: `This date range must be during the conference’s  Open and Close dates - 
                ${conferenceOpenDate?.clone().format(DateFormat.Short)} and 
                ${conferenceCloseDate?.clone().format(DateFormat.Short)}`,
              },
              children: (
                <Grid>
                  <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-2">
                    <DatePicker
                      id={idModel.corporateRegisterRange?.id}
                      className={ConferenceRegistrationEditClassName.CorporateRegisterRange}
                      placeholder="Select Dates"
                      range={true}
                      startValue={rangeStartDate(corporate_register?.start_date, corporate_register?.end_date)}
                      endValue={rangeEndDate(corporate_register?.start_date, corporate_register?.end_date)}
                      disabledDays={defaultDisabledDays}
                      clearable
                      onRangeChange={handleDateRangeChange("corporate_register")}
                    />
                  </GridColumn>
                </Grid>
              ),
            },
            {
              key: "Meeting Availability Deadline",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: (
                <>
                  Meeting Availability Deadline
                  <InfoIcon
                    tooltipProps={{
                      label: "Last day to make changes to meeting availability",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.CorporateMeetingDeadline),
              },
              children: (
                <DatePicker
                  id={idModel.corporateMeetingDeadline?.id}
                  placeholder="Select Date"
                  value={corporate_deadlines?.meeting}
                  disabledDays={defaultDisabledDays}
                  clearable
                  onChange={handleDateChange("corporate_deadlines.meeting")}
                />
              ),
            },
            {
              key: "Presentation Deadline",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: (
                <>
                  Presentation Deadline
                  <InfoIcon
                    tooltipProps={{
                      label: "Last day to opt in or out of hosting a presentation",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.PresentationDeadline),
              },
              children: (
                <DatePicker
                  id={idModel.presentationDeadline?.id}
                  placeholder="Select Date"
                  value={corporate_deadlines?.presentation}
                  clearable
                  disabledDays={[
                    { before: new Date(conferenceOpenDate?.clone().format()) },
                    { after: new Date(conferenceCloseDate?.clone().format()) },
                  ]}
                  onChange={handleDateChange("corporate_deadlines.presentation")}
                />
              ),
            },
            {
              key: "Speaker Deadline",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: (
                <>
                  Speaker Deadline
                  <InfoIcon
                    tooltipProps={{
                      label: "Last day to self-edit presentation speakers",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.SpeakerDeadline),
              },
              children: (
                <Grid>
                  <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-2">
                    <DatePicker
                      id={idModel.speakerDeadline?.id}
                      placeholder="Select Date"
                      value={corporate_deadlines?.speaker}
                      disabledDays={defaultDisabledDays}
                      clearable
                      onChange={handleDateChange("corporate_deadlines.speaker")}
                    />
                  </GridColumn>
                </Grid>
              ),
            },
          ]}
        />
      </Accordion>

      <Keyline theme={KeylineTheme.SoftGrey} />

      <Accordion
        id={idModel.investorAccordion.id}
        header={
          <div className={investorSectionHasError ? ConferenceRegistrationEditClassName.SectionError : ""}>
            Investor Registration
          </div>
        }
        collapsed={collapsibleState.investorRegistrationCollapsed}
        mediumHeader
        onChange={toggleCollapsedState("investorRegistrationCollapsed")}
      >
        <Form
          id={idModel.investorRegister?.id}
          className={ConferenceRegistrationEditClassName.CorporateRegister}
          fields={investorRegistrationFields}
        />
      </Accordion>

      <Keyline theme={KeylineTheme.SoftGrey} />

      <Accordion
        id={idModel.generalAccordion?.id}
        header={<div>Guest Registration</div>}
        collapsed={collapsibleState.generalRegistrationCollapsed}
        mediumHeader
        onChange={toggleCollapsedState("generalRegistrationCollapsed")}
      >
        <Form
          id={idModel.generalRegister?.id}
          className={ConferenceRegistrationEditClassName.GuestRegister}
          fields={[
            {
              key: "Guest Registration",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: (
                <>
                  Guest Registration Dates
                  <InfoIcon
                    tooltipProps={{
                      label: "Set dates to enable guest registration",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.GeneralRegister),
                message: `This date range must be during the conference’s  Open and Close dates - 
                ${conferenceOpenDate?.clone().format(DateFormat.Short)} and 
                ${conferenceCloseDate?.clone().format(DateFormat.Short)}`,
              },
              children: (
                <DatePicker
                  id={idModel.generalRegisterRange?.id}
                  className={ConferenceRegistrationEditClassName.GuestRegisterRange}
                  placeholder="Select Dates"
                  range={true}
                  startValue={rangeStartDate(general_register?.start_date, general_register?.end_date)}
                  endValue={rangeEndDate(general_register?.start_date, general_register?.end_date)}
                  disabledDays={defaultDisabledDays}
                  clearable
                  onRangeChange={handleDateRangeChange("general_register")}
                />
              ),
            },
            {
              key: "Include Other Subtype",
              width: "1-of-2",
              smallWidth: "1-of-1",
              label: (
                <>
                  Include Other Subtype
                  <InfoIcon
                    tooltipProps={{
                      label: 'Capture attendee type in the "Other" registration form',
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              children: (
                <Checkbox
                  id={idModel.otherSubtypes.id}
                  checked={otherSubtypes}
                  onChange={handleUseSubTypesChange}
                  label="Enable"
                />
              ),
            },
          ]}
        />
      </Accordion>

      <Keyline theme={KeylineTheme.SoftGrey} />

      <Accordion
        id={idModel.internalAccordion?.id}
        header={<div>Internal Registration</div>}
        collapsed={collapsibleState.internalRegistrationCollapsed}
        mediumHeader
        onChange={toggleCollapsedState("internalRegistrationCollapsed")}
      >
        <Form
          id={idModel.internalRegister?.id}
          className={ConferenceRegistrationEditClassName.InternalRegister}
          fields={[
            {
              key: "Internal Registration",
              width: "1-of-1",
              smallWidth: "1-of-1",
              label: (
                <>
                  Internal Registration Dates
                  <InfoIcon
                    tooltipProps={{
                      label: "Set dates to enable internal registration",
                      position: Origin.Top,
                      theme: TooltipTheme.Slate,
                    }}
                  ></InfoIcon>
                </>
              ),
              error: {
                ...defaultErrorMessage,
                visible: checkForErrors(ConferenceRegistrationDateKeys.InternalRegister),
                message: `This date range must be during the conference’s  Open and Close dates - 
                ${conferenceOpenDate?.clone().format(DateFormat.Short)} and 
                ${conferenceCloseDate?.clone().format(DateFormat.Short)}`,
              },
              children: (
                <Grid>
                  <GridColumn className={GridClassName.ColumnWithNoMarginModifier} width="1-of-2">
                    <DatePicker
                      id={idModel.internalRegisterRange?.id}
                      className={ConferenceRegistrationEditClassName.InternalRegisterRange}
                      placeholder="Select Dates"
                      range={true}
                      startValue={rangeStartDate(internal_register?.start_date, internal_register?.end_date)}
                      endValue={rangeEndDate(internal_register?.start_date, internal_register?.end_date)}
                      disabledDays={defaultDisabledDays}
                      clearable
                      onRangeChange={handleDateRangeChange("internal_register")}
                    />
                  </GridColumn>
                </Grid>
              ),
            },
          ]}
        />
      </Accordion>

      <Keyline theme={KeylineTheme.SoftGrey} />
    </GridCard>
  );
};

export default memo(ConferenceRegistrationEdit);
