import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { RoutePath, RoutePathIdLabel } from "../../configurations/navigation.configuration";
import type { PersonBase } from "../../definitions/entity.definition";
import { Company } from "../../services/company/company.model";
import { getConferenceUrlSuffix, getConferenceUrlSuffixWithCustomCompany } from "../conference/conference.utils";
import { getRoute } from "../route/route.utils";
import { getUrlParam } from "../window/location.utils";

export function getCompanyLoginUrl(): string {
  const companySuffix = getCompanyUrlSuffix();
  const conferenceSuffix = getConferenceUrlSuffix();

  if (!isNullOrWhiteSpace(companySuffix) && !isNullOrWhiteSpace(conferenceSuffix)) {
    return getRoute(
      RoutePath.ConferenceLogin,
      { label: RoutePathIdLabel.Company, value: companySuffix },
      { label: RoutePathIdLabel.ConferencePath, value: conferenceSuffix }
    );
  }

  if (!isNullOrWhiteSpace(companySuffix)) {
    return getRoute(RoutePath.CompanyLogin, { label: RoutePathIdLabel.Company, value: companySuffix });
  }

  const customPath = getCompanyCustomPath();
  const conferenceSuffixWithCustomCompany = getConferenceUrlSuffixWithCustomCompany();

  if (!isNullOrWhiteSpace(customPath) && !isNullOrWhiteSpace(conferenceSuffixWithCustomCompany)) {
    return getRoute(
      RoutePath.ConferenceLoginWithCustomCompany,
      { label: RoutePathIdLabel.CustomCompany, value: customPath },
      { label: RoutePathIdLabel.ConferencePath, value: conferenceSuffixWithCustomCompany }
    );
  }

  if (!isNullOrWhiteSpace(customPath)) {
    return getRoute(RoutePath.CustomLogin, { label: RoutePathIdLabel.CustomCompany, value: customPath });
  }

  return null;
}

export function getCompanyUrlSuffix(): string {
  const companyUrlSuffix = getUrlParam(RoutePath.Company, RoutePathIdLabel.Company);
  if (isNullOrWhiteSpace(companyUrlSuffix)) return null;

  return companyUrlSuffix;
}

export function getCompanyCustomPath(): string {
  const customPath = getUrlParam(RoutePath.Custom, RoutePathIdLabel.CustomCompany);
  if (isNullOrWhiteSpace(customPath) || customPath === "company") return null;

  return customPath;
}

export function getCompanies(
  persons: PersonBase[],
  filterEmptyCompany = false,
  ...filterIds: PersonBase["_id"][]
): string[] {
  if (isEmpty(persons)) return [];
  return persons.reduce((companies, x) => {
    if (isEmpty(x)) return companies;
    return companies.includes(x.company) ||
      !!filterIds?.some((y) => y === x._id) ||
      (filterEmptyCompany && isNullOrWhiteSpace(x.company))
      ? companies
      : companies.concat(x.company);
  }, []);
}

export function getCompanyByName(name: Company["name"], companies: Company[]): Company {
  if (isNullOrWhiteSpace(name) || isEmpty(companies)) return new Company({ name });
  return companies.find((x) => x.name === name);
}
