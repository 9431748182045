import { BaseComponentProps, IdModelBase } from "@q4/nimbus-ui";
import type { LobbyConfigSection } from "../../../../../services/conference/conference.model";

export enum DialInInstructionsClassName {
  Base = "dial-in-instructions",
  Header = "dial-in-instructions_header",
  Content = "dial-in-instructions_content",
}

export interface DialInInstructionsProp extends BaseComponentProps {
  config: LobbyConfigSection;
}

export class DialInInstructionsIdModel extends IdModelBase {
  title: string;
  content: string;

  constructor(id: string, index?: number | string) {
    super(id, index);

    this.title = `${this.id}Title`;
    this.content = `${this.id}Content`;
  }
}
