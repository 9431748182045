import "./conferences.view.scss";
import {
  AnchorTarget,
  Badge,
  Banner,
  BannerControlType,
  BannerSize,
  Button,
  ButtonTheme,
  ComponentSizeModifier,
  Ghostable,
  isEmpty,
  isNil,
  isNullOrWhiteSpace,
  Search,
  Select,
  SelectPreset,
  SelectTheme,
  Table,
  Toolbar,
} from "@q4/nimbus-ui";
import type {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  RowClickedEvent,
  RowNode,
  ValueFormatterParams,
} from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { RoutePathIdLabel, RoutePath } from "../../../configurations/navigation.configuration";
import { AdminUserContext } from "../../../contexts/adminUser/adminUser.context";
import { Entity, EntityBase } from "../../../definitions/entity.definition";
import { useConferences, useTable } from "../../../hooks";
import { useCompanies } from "../../../hooks/admin/useCompanies/useCompanies.hook";
import { TableSelectFilter } from "../../../hooks/useTableFilter/useTableFilter.definition";
import { ApiResponse } from "../../../services/api/api.definition";
import { Conference, ConferenceStatus } from "../../../services/conference/conference.model";
import {
  ImportSettingsClearType,
  ImportSettingsImportType,
  ImportSettingsUpdateType,
} from "../../../services/import/components/settings/importSettings.definition";
import { CsvBase, ImportResult } from "../../../services/import/import.definition";
import { ImportService } from "../../../services/import/import.service";
import type { Presentation } from "../../../services/presentation/presentation.model";
import {
  formatSessionDate,
  getCompanyByName,
  getConferenceStatusTheme,
  getOptions,
  getBackgroundImageUrl,
  getCompanyQuery,
} from "../../../utils";
import CsvUploadButton from "./components/csvUploadButton/csvUploadButton.component";
import ImportModal from "./components/importModal/importModal.component";
import { ImportSettings, ImportStep } from "./components/importModal/importModal.definition";
import { ConferencesClassName, ConferenceIdModel as IdModel, CompanySelectKey } from "./conferences.definition";

const Conferences = (): JSX.Element => {
  const adminUserContext = useContext(AdminUserContext);
  const {
    current: currentConference,
    items: conferences,
    loading,
    clearById: clearEntityById,
    setCurrent: setCurrentConference,
  } = useConferences({});

  const { isSystemAdmin } = adminUserContext;
  const shouldAutoFetchCompanies = useMemo(() => !!isSystemAdmin, [isSystemAdmin]);

  const {
    current: currentCompany,
    items: companies,
    setCurrent: setCurrentCompany,
  } = useCompanies({
    assignDefaultEntity: !isSystemAdmin,
    autoFetchData: shouldAutoFetchCompanies,
  });

  const defaultColDef = {
    lockPosition: true,
    sortable: true,
    suppressMovable: true,
  };
  const columnDefs: ColDef[] = [
    {
      field: "image_logo",
      headerName: "Logo",
      minWidth: 88,
      maxWidth: 88,
      sortable: false,
      cellRenderer: "imageCellRenderer",
    },
    {
      field: "title",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "_company.name",
      headerName: "Company",
      minWidth: 100,
      flex: 1,
      hide: !isSystemAdmin,
    },
    {
      field: "_company._id",
      headerName: "Company",
      minWidth: 100,
      flex: 1,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 115,
      maxWidth: 115,
      cellRenderer: "statusCellRenderer",
      comparator: compareStatus,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      minWidth: 128,
      maxWidth: 128,
      valueFormatter: getFormattedDate,
      getQuickFilterText: getFormattedDate,
      sort: "desc",
    },
    {
      field: "end_date",
      headerName: "End Date",
      minWidth: 120,
      maxWidth: 120,
      valueFormatter: getFormattedDate,
      getQuickFilterText: getFormattedDate,
    },
    {
      field: "open_date",
      headerName: "Open Date",
      minWidth: 120,
      maxWidth: 120,
      valueFormatter: getFormattedDate,
      getQuickFilterText: getFormattedDate,
    },
    {
      field: "close_date",
      headerName: "Close Date",
      minWidth: 128,
      maxWidth: 128,
      valueFormatter: getFormattedDate,
      getQuickFilterText: getFormattedDate,
    },
    {
      field: "updated_by",
      headerName: "Last Modified by",
      minWidth: 160,
      maxWidth: 160,
    },
    {
      field: "_id",
      headerName: "Actions",
      sortable: false,
      minWidth: 150,
      maxWidth: 150,
      headerClass: ConferencesClassName.IconCell,
      cellRenderer: "actionsCellRenderer",
    },
  ];

  const {
    gridApi,
    page: currentPage,
    pageCount,
    pageSize,
    searchFilter,
    filterValues,
    showPagination,
    doesExternalFilterPass,
    handleClearFilter,
    handleFilter,
    handleGridReady,
    handlePaginationChange,
    handlePageChange,
    handlePageSizeChange,
    handleSearchInputChange,
    handleSearchQueryClear,
    handleSearchQueryRequest,
    isExternalFilterPresent,
  } = useTable<Conference>({ rowCount: conferences?.length });

  const history = useHistory();
  const importService = useRef(new ImportService());
  const currentStep = useRef(ImportStep.List);
  const importModalVisibleRef = useRef(false);

  const [importModalVisible, updateImportModalVisible] = useState(false);
  const [importLoading, setImportingLoading] = useState(false);
  const [importResults, setImportResults] = useState<ImportResult<Presentation>[]>([]);

  const selectFilters: TableSelectFilter[] = useMemo(() => {
    return [
      {
        id: IdModel.company.id,
        key: CompanySelectKey,
        placeholder: "Filter by Company",
        options: getOptions("name", companies, true),
      },
    ];
  }, [companies]);

  const hideFilter = useMemo(
    () => isEmpty(selectFilters) || !isSystemAdmin || isEmpty(conferences) || isEmpty(companies),
    [companies, conferences, isSystemAdmin, selectFilters]
  );

  useEffect(() => {
    function handleResize(): void {
      gridApi?.sizeColumnsToFit();
    }

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]);

  useEffect(() => {
    const value = getCompanyQuery(isSystemAdmin);

    if (!isNullOrWhiteSpace(value)) {
      handleFilter(CompanySelectKey, value);
      if (isEmpty(companies)) return;
      setCurrentCompany(getCompanyByName(value, companies));
    }

    history.replace(RoutePath.Conferences);
  }, [history, handleFilter, setCurrentCompany, companies, isSystemAdmin]);

  const handleResults = useCallback(
    <T extends EntityBase>(type: Entity, response: ApiResponse<T>, titleKey: keyof T, csv: CsvBase): void => {
      if (isEmpty(response)) return;

      const message = response.success ? null : response.message;
      const result = new ImportResult({
        type,
        entity: response.data,
        title: response.data[titleKey]?.toString(),
        csv,
        errorMessage: message,
      });
      setImportResults((results) => [...results, result]);
    },
    []
  );

  const isImportCancelled = useCallback((): Error => {
    return !importModalVisibleRef.current && currentStep.current !== ImportStep.Import
      ? new Error("Import cancelled")
      : null;
  }, []);

  const handleImport = useCallback((): void => {
    if (isEmpty(importService.current)) return;

    setImportResults([]);
    setImportingLoading(true);
    currentStep.current = ImportStep.Import;

    importService.current.post(isImportCancelled).then((): void => {
      setImportingLoading(false);
    });
  }, [isImportCancelled]);

  const handleClearFilterClick = useCallback(() => {
    handleClearFilter();
    setCurrentCompany(null);
  }, [handleClearFilter, setCurrentCompany]);

  function handleSchedule(): void {
    const route = !isSystemAdmin ? RoutePath.ConferenceNew : `${RoutePath.ConferenceNew}?company=${currentCompany._id}`;
    history.push(route);
  }

  async function handleClearType(_id: Conference["_id"], futureOnly = false): Promise<boolean> {
    if (isNullOrWhiteSpace(_id)) return false;
    return clearEntityById(_id, importService.current.Type, futureOnly);
  }

  async function handleUpload(conferenceId: Conference["_id"], file: File): Promise<void> {
    if (isNil(file)) return;

    importService.current = new ImportService();

    await importService.current.fetch(file);

    const { HasSettings } = importService.current;
    currentStep.current = HasSettings ? ImportStep.Settings : ImportStep.Confirm;

    const conference = new Conference(conferenceId);
    setCurrentConference(new Conference(conference));

    setImportModalVisible(true);
    if (!HasSettings) {
      handleConfirm(conference);
    }
    setImportingLoading(false);
  }

  async function handleConfirm(conference: Conference, futureOnly = false, update = false) {
    if (isNullOrWhiteSpace(conference?._id)) return;
    setImportingLoading(true);

    const results = await importService.current.parse({ conference, handleResults, futureOnly, update });
    if (isEmpty(importService.current.Requests) && isEmpty(results)) {
      handleImportReset();
      return;
    }

    setImportingLoading(false);
    setImportResults(results);
  }

  function handleRowClicked(rowEvent: RowClickedEvent): void {
    if (isEmpty(rowEvent)) return;

    const { data, event } = rowEvent;

    if (isEmpty(data)) return;
    // stop if clicking any of the inputs / buttons
    const element = event?.target as HTMLElement;
    const tagName = element?.tagName;

    if (tagName === "BUTTON" || tagName === "INPUT") return;

    const path = RoutePath.ConferenceDetails.replace(RoutePathIdLabel.Conference, data._id);
    history.push(path);
  }

  function setImportModalVisible(status: boolean): void {
    updateImportModalVisible(status);
    importModalVisibleRef.current = status;
  }

  function handleImportReset(): void {
    setCurrentConference(null);
    setImportingLoading(false);
    setImportModalVisible(false);
    setImportResults([]);
    currentStep.current = ImportStep.List;
    importService.current = new ImportService();
  }

  function handleImportStop(): void {
    setCurrentConference(null);
    setImportModalVisible(false);
  }

  async function handleImportSettings(opts: ImportSettings): Promise<void> {
    if (isEmpty(currentConference)) return;

    const { clearType, importType, updateType } = opts;

    currentStep.current = ImportStep.Confirm;

    const isFutureOnly = importType === ImportSettingsImportType.Future;
    const isCreateOrUpdate = updateType === ImportSettingsUpdateType.Update;

    if (clearType !== ImportSettingsClearType.None) {
      setImportingLoading(true);
      const futureOnlyClear = clearType === ImportSettingsClearType.Future;
      const success = await handleClearType(currentConference._id, futureOnlyClear);
      if (!success) return;
      setImportingLoading(false);
    }
    handleConfirm(currentConference, isFutureOnly, isCreateOrUpdate);
  }

  function getFormattedDate(params: ValueFormatterParams | GetQuickFilterTextParams): string {
    const date = params?.value;
    const timezone = params?.data?.time_zone;

    if (isNullOrWhiteSpace(date)) return "—";

    return formatSessionDate(date, timezone);
  }

  function compareStatus(_valueA: ConferenceStatus, _valueB: ConferenceStatus, nodeA: RowNode, nodeB: RowNode): number {
    if (nodeA.data.Status === nodeB.data.Status) return 0;
    switch (nodeA.data.Status) {
      case ConferenceStatus.Scheduled:
        return -1;
      case ConferenceStatus.Running:
        if (nodeB.data.Status === ConferenceStatus.Scheduled) return 1;
        return -1;
      case ConferenceStatus.Ended:
        if (nodeB.data.Status === ConferenceStatus.None) return -1;
        return 1;
      case ConferenceStatus.None:
      default:
        return 1;
    }
  }

  function renderImageCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.value)) return "—";

    return (
      <div className={ConferencesClassName.ImageCell} style={{ backgroundImage: getBackgroundImageUrl(params.value) }} />
    );
  }

  function renderStatusCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.data?.Status)) return "—";
    const status: ConferenceStatus = params?.data?.Status;
    return <Badge theme={getConferenceStatusTheme(status)}>{status}</Badge>;
  }

  function renderActionsCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.value)) return "—";

    function handleModalSettingsOpenClick(file: File): void {
      const currentConferenceId = params.value;
      handleUpload(currentConferenceId, file);
    }

    const buttonId = IdModel.import.getId(params.value);
    return <CsvUploadButton id={buttonId} onUpload={handleModalSettingsOpenClick} />;
  }

  function renderUploadCell(params: ICellRendererParams): string | JSX.Element {
    if (isNullOrWhiteSpace(params?.value)) return "—";

    return (
      <Button
        theme={ButtonTheme.Citrus}
        icon="q4i-link-4pt"
        label="Upload CSV"
        linkTo={params?.value}
        linkTarget={AnchorTarget.Blank}
      />
    );
  }

  function renderFilters(): JSX.Element {
    return (
      <Ghostable ghosted={hideFilter}>
        <div className={ConferencesClassName.TableFilters}>
          {selectFilters.map((filter) => {
            const { id, key, options, placeholder } = filter;

            const handleFilterSelect = (option: string) => {
              handleFilter(key, option);
              setCurrentCompany(getCompanyByName(option, companies));
            };

            const value = isNil(filterValues[key as keyof Conference])
              ? ""
              : filterValues[key as keyof Conference].toString();

            return (
              <Select
                key={key}
                id={id}
                value={value}
                onChange={handleFilterSelect}
                options={options}
                theme={SelectTheme.Ink}
                size={ComponentSizeModifier.Small}
                preset={SelectPreset.Autocomplete}
                isClearable={true}
                placeholder={placeholder}
              />
            );
          })}
          <Button
            theme={ButtonTheme.Steel}
            label="Clear"
            icon="q4i-undo-4pt"
            disabled={isEmpty(filterValues)}
            onClick={handleClearFilterClick}
          />
        </div>
      </Ghostable>
    );
  }

  return (
    <section id={IdModel.id} className={ConferencesClassName.Base}>
      <Banner
        id={IdModel.banner.id}
        title="Conferences"
        badgeIcon="q4i-conference-2pt"
        size={BannerSize.Medium}
        controls={[
          {
            type: BannerControlType.Button,
            props: {
              id: IdModel.schedule.id,
              disabled: isNil(currentCompany),
              theme: ButtonTheme.Citrus,
              label: "Schedule Conference",
              icon: "q4i-add-4pt",
              onClick: handleSchedule,
            },
          },
        ]}
      />
      <Toolbar
        autoRowProps={{
          justifyContent: hideFilter ? "flex-end" : "space-between",
        }}
      >
        {renderFilters()}
        <Search
          id={IdModel.search}
          value={searchFilter}
          disabled={loading}
          onInputChange={handleSearchInputChange}
          onClear={handleSearchQueryClear}
          onQueryRequest={handleSearchQueryRequest}
        />
      </Toolbar>
      <div className={ConferencesClassName.Table}>
        <Table
          id={IdModel.table}
          loading={loading}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          columnDefs={columnDefs}
          rowData={conferences}
          defaultColDef={defaultColDef}
          accentedSort
          cacheQuickFilter
          pagination={showPagination}
          paginationPageSize={pageSize}
          onPaginationChanged={handlePaginationChange}
          paginationProps={{
            showPageSizeSelection: true,
            startFromZero: true,
            initialPage: 0,
            forcePage: currentPage,
            forcePageSize: pageSize,
            pageCount: pageCount,
            pageSizeOptions: [15, 25, 50, 100],
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
          frameworkComponents={{
            imageCellRenderer: renderImageCell,
            actionsCellRenderer: renderActionsCell,
            webexCellRenderer: renderUploadCell,
            statusCellRenderer: renderStatusCell,
          }}
          onRowClicked={handleRowClicked}
          onGridReady={handleGridReady}
        />
      </div>
      <ImportModal
        type={importService.current?.Type}
        visible={importModalVisible}
        currentStep={currentStep.current}
        loading={importLoading}
        results={importResults}
        requestCount={importService.current?.Requests.length ?? 0}
        totalCount={importService.current?.Data.length ?? 0}
        onImport={handleImport}
        onImportReset={handleImportReset}
        onImportStop={handleImportStop}
        onImportSettings={handleImportSettings}
      />
    </section>
  );
};

export default memo(Conferences);
