import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useRef, useState } from "react";
import type { ApiResponse } from "../../services/api/api.definition";
import type { Conference } from "../../services/conference/conference.model";
import { Speaker } from "../../services/speaker/speaker.model";
import SpeakerService from "../../services/speaker/speaker.service";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import type { SpeakersHookModel, SpeakersHookProps } from "./useSpeaker.definition";

export const useSpeakers = (props: SpeakersHookProps): SpeakersHookModel => {
  const { autoFetchData, conferenceId, data } = props;

  const [currentSpeaker, setCurrentSpeaker] = useState<Speaker>(null);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [loading, setLoading] = useState(false);
  const speakerService = useRef(new SpeakerService());
  const notificationService = useRef(new NotificationService());

  const _handleSpeakersResponse = useCallback((response: ApiResponse<Speaker[]>): Speaker[] => {
    setLoading(false);
    if (!response.success) {
      notificationService.current.error("Failed to load speakers.");
      return [];
    }

    const speakers = response?.data;
    setSpeakers(speakers);
    return speakers;
  }, []);

  const getSpeakers = useCallback((): Promise<Speaker[]> => {
    setLoading(true);

    return speakerService.current.getSpeakers().then(_handleSpeakersResponse);
  }, [_handleSpeakersResponse]);

  const getSpeakersByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Speaker[]> => {
      setLoading(true);

      return speakerService.current.getSpeakersByConferenceId(_id).then(_handleSpeakersResponse);
    },
    [_handleSpeakersResponse]
  );

  const getSpeakerById = useCallback((_id: Speaker["_id"]): Promise<Speaker> => {
    setLoading(true);

    return speakerService.current.getSpeakerById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }

      const { data: speaker } = response;
      setSpeakers((speakers) => speakers.map((x) => (x._id === speaker._id ? new Speaker({ ...x, ...speaker }) : x)));
      setCurrentSpeaker(speaker);

      return speaker;
    });
  }, []);

  const handleSpeakerPost = useCallback((data: Speaker): Promise<Speaker> => {
    setLoading(true);
    return speakerService.current.postSpeaker(data).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Speaker was created successfully.");

      const { data: speaker } = response;
      setSpeakers((speakers) => [...speakers, speaker]);
      return speaker;
    });
  }, []);

  const handleSpeakerPut = useCallback((_id: Speaker["_id"], data: Speaker): Promise<Speaker> => {
    setLoading(true);
    return speakerService.current.putSpeakerById(_id, data).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Speaker was updated successfully.");

      const { data: speaker } = response;
      setSpeakers((speakers) => speakers.map((x) => (x._id === speaker._id ? new Speaker({ ...x, ...speaker }) : x)));
      return response.data;
    });
  }, []);

  const handleSpeakersDelete = useCallback((_id: Speaker["_id"]): Promise<boolean> => {
    setLoading(true);
    return speakerService.current.deleteSpeakerById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return response.success;
      }
      notificationService.current.success("Speaker was deleted successfully.");

      setSpeakers((speakers) =>
        speakers.reduce((updatedSpeakers, x) => (x._id === _id ? updatedSpeakers : updatedSpeakers.concat(x)), [])
      );
      return response.success;
    });
  }, []);

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetch: getSpeakers,
    fetchBy: getSpeakersByConferenceId,
    setEntities: setSpeakers,
  });

  return {
    current: currentSpeaker,
    items: speakers,
    loading,
    setCurrent: setCurrentSpeaker,
    fetchById: getSpeakerById,
    fetchSpeakers: getSpeakers,
    fetchSpeakersByConferenceId: getSpeakersByConferenceId,
    post: handleSpeakerPost,
    putById: handleSpeakerPut,
    deleteById: handleSpeakersDelete,
    setItems: setSpeakers,
    setLoading,
  };
};
