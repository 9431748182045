import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, ErrorModel, ButtonIdModel } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { TimeSlotEditIdModel } from "../timeSlotEdit/timeSlotEdit.definition";

export interface TimeSlotViewProps extends BaseComponentProps {
  startTime: Moment;
  endTime: Moment;
  isSaving: boolean;
  editMode: boolean;
  error?: ErrorModel;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  onSubmit: (startTime: Moment, endTime: Moment) => Promise<void>;
  deleteSlot: () => void;
}

export class TimeSlotViewIdModel extends IdModelBase {
  text: string;
  timeslotEdit: TimeSlotEditIdModel;
  editButton: ButtonIdModel;
  deleteButton: ButtonIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) {
      this.text = "Text";
      this.timeslotEdit = new TimeSlotEditIdModel(null);
      this.editButton = new ButtonIdModel(null);
      this.deleteButton = new ButtonIdModel(null);
      return;
    }

    this.text = `${this.id}Text`;
    this.timeslotEdit = new TimeSlotEditIdModel(`${this.id}TimeSlotEdit`);
    this.editButton = new ButtonIdModel(`${this.id}EditButton`);
    this.deleteButton = new ButtonIdModel(`${this.id}DeleteButton`);
  }
}

export enum TimeSlotViewClassName {
  Base = "timeslot-view",
  BaseWithConflictModifier = "timeslot-view--conflict",
  Controls = "timeslot-view_controls",
  Text = "timeslot-view_text",
  Error = "timeslot-view_error",
  Buttons = "timeslot-view_buttons",
  Card = "timeslot-view_card",
}
