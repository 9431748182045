import { BaseComponentProps } from "@q4/nimbus-ui";
import type { MouseEvent, RefObject } from "react";
import type { DropzoneRef } from "react-dropzone";
import { FileRejection } from "react-dropzone";

export enum FileManagerConfirmingState {
  None,
  Remove,
  Replace,
}

export enum FileManagerRejectionErrorCodes {
  ExceedsMaxSize = "file-too-large",
  InvalidType = "file-invalid-type",
}

export interface FileManagerController {
  fileUrl: string;
  currentBlob: File;
  currentFileType: string;
  currentFileTitle: string;
  removing: boolean;
  saving: boolean;
  handleDialogOpen: () => void;
  handleUpload: <T extends File>(accepted: T[], rejected: FileRejection[]) => void;
  handleRemoveRequest: () => void;
  handleRemoveRequestCancel: () => void;
  handleRemovePath: (evt: MouseEvent) => void;
}

export interface FileManagerControllerBase extends BaseComponentProps {
  accept?: string[];
  fileUrl: string;
  onChange: (fileUrl: string | File) => void;
}

export class FileManagerControllerProps implements FileManagerControllerBase {
  className?: string;
  id?: string;
  dataId?: string;

  accept?: string[];
  disabled? = false;
  fileUrl: string;
  onChange: (fileUrl: string | File) => void;
  dropzoneRef: RefObject<DropzoneRef>;

  constructor(props: FileManagerControllerProps) {
    Object.assign(this, props);
  }
}
