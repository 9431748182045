import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  SelectIdModel,
  TextboxIdModel,
  AutomationListId,
  ErrorModel,
  FormIdModel,
} from "@q4/nimbus-ui";
import type { Dispatch, SetStateAction } from "react";
import type { Conference } from "../../services/conference/conference.model";
import type { QuestionGroup } from "../../services/questionGroup/questionGroup.model";
import { DeleteConfirmationMessageIdModel } from "../deleteConfirmationMessage/deleteConfirmationMessage.definition";
import { EntityTableRequiredFormKey } from "../entityTable/entityTable.definition";
import { QuestionCardIdModel } from "../questionCard/questionCard.definition";

export enum RegistrationFormClassName {
  Base = "registration-forms",
  Add = "registration-forms_add-section",
  Form = "registration-forms_form",
  GeneralQuestions = "general-questions_section",
  IndividualQuestions = "individual-questions_section",
}

export enum RegistrationFormLabels {
  AdditionalQuestions = "Additional Questions",
  FormName = "Form Name",
  FormNameRequired = "Form Name is required",
  RegistrationType = "Registration Type",
  AddQuestion = "Add Question",
}

export const QuestionTemporaryId = "temporary";

export interface RegistrationFormProps extends BaseComponentProps {
  conference: Conference;
  questionGroups: QuestionGroup[];
  current: QuestionGroup;
  formKeys?: EntityTableRequiredFormKey[];
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  setCurrent: Dispatch<SetStateAction<QuestionGroup>>;
}

export class QuestionCardListId extends AutomationListId<QuestionCardIdModel> {
  constructor(parentId: string, prefix = "-", postfix?: string) {
    super(parentId, prefix, QuestionCardIdModel, postfix);
  }
}

export class RegistrationFormIdModel extends IdModelBase {
  title: TextboxIdModel;
  registrationType: SelectIdModel;
  addButton: ButtonIdModel;
  questionCard: QuestionCardListId;
  individualQuestionCard: QuestionCardListId;
  nameAndTypeForm: FormIdModel;
  deleteQuestionCard: DeleteConfirmationMessageIdModel;
  generalQuestionSection: FormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = new TextboxIdModel(`${this.id}Title`);
    this.registrationType = new SelectIdModel(`${this.id}RegistrationType`);
    this.addButton = new ButtonIdModel(`${this.id}AddButton`);
    this.questionCard = new QuestionCardListId(`${this.id}QuestionCard`);
    this.individualQuestionCard = new QuestionCardListId(`${this.id}IndividualQuestionCard`);
    this.nameAndTypeForm = new FormIdModel(`${this.id}RegistrationFormNameAndType`);
    this.deleteQuestionCard = new DeleteConfirmationMessageIdModel(`${this.id}DeleteQuestionCard`);
  }
}

export interface UsedQuestionGroups {
  [key: string]: boolean;
}
