import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import * as EmailValidator from "email-validator";

export function validateEmail(email: string): boolean {
  const trimmedEmail = email?.trim();
  if (isNullOrWhiteSpace(trimmedEmail)) return false;
  return EmailValidator.validate(trimmedEmail);
}

export function removeSpecialCharacters(fileName: string): string {
  if (isNullOrWhiteSpace(fileName)) return "";
  return fileName.replace(/[^a-zA-Z0-9-_ ]/g, "").replace(/ /g, "-");
}
