import { AutomationListId, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { MouseEvent, KeyboardEvent } from "react";
import { BrandingBase } from "../../../../../components/companyBranding/companyBranding.definition";

export const PanelWidth = 250;

export interface PanelProps extends BaseComponentProps {
  selected?: boolean;
  actionLabel?: string;
  accessibilityLabel?: string;
  category?: string;
  title?: string;
  details?: string[];
  indicator?: string;
  image?: string;
  branding?: BrandingBase;
  onSelect?: (event: MouseEvent | KeyboardEvent) => void;
}

export enum PanelClassName {
  Base = "panel",
  BaseWithSelectedModifier = "panel--selected",
  BaseWithIndicatorModifier = "panel--with-indicator",
  Category = "panel_category",
  Title = "panel_title",
  Detail = "panel_detail",
  Indicator = "panel_indicator",
  Action = "panel_action",
  ActionWrap = "panel_action-wrap",
  ActionLabel = "panel_action-label",
  Gradient = "panel_gradient",
}

export class PanelListId extends AutomationListId<PanelIdModel> {
  constructor(parentId: string, prefix = "Panel-", postfix?: string) {
    super(parentId, prefix, PanelIdModel, postfix);
  }
}

export class PanelIdModel extends IdModelBase {
  indicator: string;
  action: string;
  category: string;
  title: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.indicator = `${this.id}Indicator`;
    this.action = `${this.id}Action`;
    this.category = `${this.id}Category`;
    this.title = `${this.id}Title`;
  }
}
