import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { CompanyLobbyLayoutIdModel } from "../../company/lobby/components/layout/companyLobby.layout.definition";
import { RegistrationAvailabilityIdModel } from "../register/components/availability/availability.definition";
import { ConfirmationIdModel } from "../register/components/confirmation/confirmation.definition";
import { CorporateAttendeesIdModel } from "../register/components/corporateAttendees/corporateAttendees.definition";
import { CorporateDetailsIdModel } from "../register/components/corporateDetails/corporateDetails.definition";
import { CorporateSummaryIdModel } from "../register/components/corporateSummary/corporateSummary.definition";
import { CustomQuestionsIdModel } from "../register/components/customQuestions/customQuestions.definition";
import { InvestorPersonalInformationIdModel } from "../register/components/investorPersonalInformation/investorPersonalInformation.definition";
import { InvestorSummaryIdModel } from "../register/components/investorSummary/investorSummary.definition";
import { MeetingRequestsIdModel } from "../register/components/meetingRequests/meetingRequests.definition";
import { PersonalInformationIdModel } from "../register/components/personalInformation/personalInformation.definition";
import { RegistrationHeaderIdModel } from "../register/components/registrationHeader/registrationHeader.definition";
import { RegistrationSummaryIdModel } from "../register/components/summary/summary.definition";

export class ProfileViewIdModel extends IdModelBase {
  layout: CompanyLobbyLayoutIdModel;
  investorPersonalInformation: InvestorPersonalInformationIdModel;
  personalInformation: PersonalInformationIdModel;
  availability: RegistrationAvailabilityIdModel;
  meetingRequests: MeetingRequestsIdModel;
  customQuestions: CustomQuestionsIdModel;
  investorSummary: InvestorSummaryIdModel;
  corporateSummary: CorporateSummaryIdModel;
  summary: RegistrationSummaryIdModel;
  confirmation: ConfirmationIdModel;
  attendeesTab: string;
  contactInformationTab: string;
  detailsTab: string;
  personalInformationTab: string;
  availabilityTab: string;
  meetingRequestsTab: string;
  summaryTab: string;
  customQuestionsTab: string;
  backToLobby: string;
  corporateDetails: CorporateDetailsIdModel;
  corporateAttendees: CorporateAttendeesIdModel;
  registrationHeader: RegistrationHeaderIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.layout = new CompanyLobbyLayoutIdModel(`${this.id}Layout`);
    this.corporateDetails = new CorporateDetailsIdModel(`${this.id}CorporateDetails`);
    this.corporateAttendees = new CorporateAttendeesIdModel(`${this.id}CorporateAttendees`);
    this.investorPersonalInformation = new InvestorPersonalInformationIdModel(`${this.id}InvestorPersonalInformation`);
    this.personalInformation = new PersonalInformationIdModel(`${this.id}PersonalInformation`);
    this.availability = new RegistrationAvailabilityIdModel(`${this.id}Availability`);
    this.meetingRequests = new MeetingRequestsIdModel(`${this.id}MeetingRequests`);
    this.customQuestions = new CustomQuestionsIdModel(`${this.id}CustomQuestions`);
    this.investorSummary = new InvestorSummaryIdModel(`${this.id}InvestorSummary`);
    this.corporateSummary = new CorporateSummaryIdModel(`${this.id}CorporateSummary`);
    this.summary = new RegistrationSummaryIdModel(`${this.id}Summary`);
    this.confirmation = new ConfirmationIdModel(`${this.id}Confirmation`);
    this.attendeesTab = `${this.id}AttendeesTab`;
    this.contactInformationTab = `${this.id}PersonalInformationTab`;
    this.personalInformationTab = `${this.id}PersonalInformationTab`;
    this.availabilityTab = `${this.id}AvailabilityTab`;
    this.meetingRequestsTab = `${this.id}MeetingRequestsTab`;
    this.customQuestionsTab = `${this.id}CustomQuestionsTab`;
    this.summaryTab = `${this.id}SummaryTab`;
    this.backToLobby = `${this.id}BackToLobby`;
    this.registrationHeader = new RegistrationHeaderIdModel(`${this.id}RegistrationHeader`);
  }
}

export enum AttendeeProfileClassName {
  Base = "attendee_profile",
  Wrapper = "attendee_profile_content_wrapper",
}

const PageId = "AttendeeProfileView";
export const ProfileIdModel = new ProfileViewIdModel(PageId);
