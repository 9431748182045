import { AdminRouteBase } from "../../../configurations/navigation.configuration";
import { EntityBase } from "../../../definitions/entity.definition";
import type { ApiResponse } from "../../api/api.definition";
import { AdminApiVersion, Service } from "../../serviceBase/serviceBase.model";
import { Company, CompanyPayload } from "./companies.model";

export default class CompaniesService extends Service<Company> {
  constructor() {
    super(AdminRouteBase, "companies", Company, AdminApiVersion);
  }

  post(data: Company): Promise<ApiResponse<Company>> {
    const payload = new CompanyPayload(data);
    return super.post(payload);
  }

  put(id: EntityBase["_id"], data: Company): Promise<ApiResponse<Company>> {
    const payload = new CompanyPayload(data);
    return super.put(id, payload);
  }
}
