import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { pick } from "lodash";
import Papa, { UnparseConfig } from "papaparse";

export class CsvExportService {
  private readonly config: UnparseConfig;

  constructor(config?: UnparseConfig) {
    this.config = config;
  }

  parse<T, K extends keyof T>(entities: T[], ...keys: K[]): string {
    const sanitizedEntities = (entities || []).filter((x) => !isEmpty(x));
    const sanitizedKeys = (keys || []).filter((x) => (typeof x === "string" ? !isNullOrWhiteSpace(x) : !isNil(x)));

    if (isEmpty(sanitizedEntities) || isEmpty(sanitizedKeys)) return null;

    const mapped = sanitizedEntities.map((x) => pick(x, sanitizedKeys));
    return Papa.unparse(mapped, this.config);
  }
}
