import { isNil } from "@q4/nimbus-ui";
import { UserRouteBase } from "../../../configurations/navigation.configuration";
import { ApiResponse, AuthType, OfflineApiServiceKey } from "../../api/api.definition";
import { Conference } from "../../conference/conference.model";
import { Service } from "../../serviceBase/serviceBase.model";

export default class UserConferenceService extends Service<Conference> {
  constructor() {
    super(UserRouteBase, "lobby", Conference);
  }

  public get(offline?: boolean): Promise<ApiResponse<Conference[]>> {
    const offlineApiServiceKey = !isNil(offline) && offline ? OfflineApiServiceKey.GetUserConferences : null;
    return this._apiService
      .get<Conference[]>(this.ApiPathBase, AuthType.Passwordless, offlineApiServiceKey)
      .then(this.mapArrayedResponse);
  }

  public async getById(id: string, offline?: boolean): Promise<ApiResponse<Conference>> {
    const offlineApiServiceKey = !isNil(offline) && offline ? OfflineApiServiceKey.GetUserLobbyConference : null;
    return this._apiService
      .get<Conference>(`${this.ApiPathBase}/${id}`, AuthType.Passwordless, offlineApiServiceKey)
      .then(this.mapResponse);
  }
}
