import "./presentationTheater.component.scss";
import {
  Anchor,
  Collapsable,
  getClassName,
  Ghostable,
  isEmpty,
  isNullOrWhiteSpace,
  Spinner,
  Text,
  TextPreset,
  TextTheme,
} from "@q4/nimbus-ui";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { DefaultTimeZone } from "../../../../../../const";
import { getPresentationPollUrl, getPresentationQaUrl } from "../../../../../../utils";
import PersonalAgendaButton from "../../../components/personalAgendaButton/personalAgendaButton.component";
import Section from "../../../components/section/section.component";
import { SectionClassName, SectionTheme } from "../../../components/section/section.definition";
import Speakers from "../../../components/speakers/speakers.component";
import {
  PresentationDetailsKey,
  PresentationTheaterClassName,
  PresentationTheaterIdModel,
  PresentationTheaterProps,
  StreamlineEmbedChannel,
  StreamlinePollData,
} from "./presentationTheater.definition";
import { getPresentationTheaterDate } from "./presentationTheater.util";
import { useAspectRatio } from "./useAspectRatio.hook";

const PresentationTheater = (props: PresentationTheaterProps): JSX.Element => {
  const {
    id,
    agendaLoading,
    backButton,
    hasAgendaPresentation,
    presentation,
    url: presentationSrc,
    profile,
    speaker,
    lobbyConfig,
    timeZone: timeZoneProp,
    onAgendaAdd,
    onAgendaRemove,
    onSpeakerChange,
  } = props;

  const idModel = useMemo(() => new PresentationTheaterIdModel(id), [id]);

  const presentationId = useMemo(() => presentation?._id, [presentation?._id]);
  const presentationQaUrl = useMemo(() => getPresentationQaUrl(presentation, profile), [presentation, profile]);
  const presentationPollUrl = useMemo(() => {
    return getPresentationPollUrl(presentation, profile);
  }, [presentation, profile]);
  const presentationCode = useMemo(() => presentation?.code, [presentation?.code]);

  const frameRef = useRef<HTMLIFrameElement>();

  const [pollHeight, setPollHeight] = useState(0);

  const hasPresentation = useMemo(() => !isEmpty(presentation), [presentation]);
  const hasPresentationSrc = useMemo(() => !isNullOrWhiteSpace(presentationSrc), [presentationSrc]);

  const frameStyles = useAspectRatio({ aspectRatio: 16 / 9, ref: hasPresentationSrc ? frameRef : null });

  const timeZone = useMemo(() => timeZoneProp ?? DefaultTimeZone, [timeZoneProp]);

  const date = useMemo(
    () => getPresentationTheaterDate(presentation?.start_date.tz(timeZone), presentation?.end_date.tz(timeZone)),
    [presentation?.start_date, presentation?.end_date, timeZone]
  );

  const hideAgendaButton = !!presentationCode?.some((code) => profile?.code?.includes(code));

  useEffect(() => {
    if (isNullOrWhiteSpace(presentationPollUrl)) return;

    const handleAutoResizePollIframe = function (e: StreamlinePollData) {
      const data = typeof e.data === "object" ? e.data : JSON.parse(e.data);
      if (StreamlineEmbedChannel === data.channel) {
        setPollHeight(data.height);
      }
    };

    try {
      window.addEventListener("message", handleAutoResizePollIframe);
    } catch (e) {
      console.error(e);
    }

    return () => window.removeEventListener("message", handleAutoResizePollIframe);
  }, [presentationPollUrl]);

  function renderPresentation(): JSX.Element | JSX.Element[] {
    const details = hasPresentation
      ? [
          <Text
            key={PresentationDetailsKey.Title}
            className={PresentationTheaterClassName.Title}
            theme={TextTheme.White}
            preset={TextPreset.DisplayLarge}
          >
            {presentation?.title}
          </Text>,
          <Text
            key={PresentationDetailsKey.Date}
            className={PresentationTheaterClassName.Date}
            theme={TextTheme.White}
            preset={TextPreset.Subheader}
          >
            {date}
          </Text>,
          <Text key={PresentationDetailsKey.Description} theme={TextTheme.White} preset={TextPreset.Subheader}>
            {presentation?.description}
          </Text>,
          <div key={PresentationDetailsKey.Agenda}>
            {!hideAgendaButton && (
              <PersonalAgendaButton
                lobby={lobbyConfig}
                hasPresentation={hasAgendaPresentation}
                loading={agendaLoading}
                presentationId={presentationId}
                onAgendaAdd={onAgendaAdd}
                onAgendaRemove={onAgendaRemove}
              />
            )}
          </div>,
        ]
      : [];

    const sectionTheme = SectionTheme.Gradient;

    if (!hasPresentationSrc)
      return (
        <Section className={PresentationTheaterClassName.Presentation} theme={sectionTheme}>
          {details}
        </Section>
      );

    return (
      <Section className={PresentationTheaterClassName.Presentation} theme={sectionTheme} headerChildren={details}>
        <div
          className={`${PresentationTheaterClassName.Frame} ${SectionClassName.ContentColumn} ${SectionClassName.ContentColumnWidthGrowModifier}`}
          style={frameStyles}
          ref={frameRef}
        >
          <Spinner fixed={false} masked={false} />
          {!isEmpty(profile) && <iframe width="100%" height="100%" allowFullScreen frameBorder="0" src={presentationSrc} />}
        </div>
      </Section>
    );
  }

  return (
    <div id={idModel.id} className={PresentationTheaterClassName.Base}>
      <Ghostable ghosted={isEmpty(backButton)}>
        <Section className={PresentationTheaterClassName.BackButton} theme={SectionTheme.Transparent}>
          <Anchor {...backButton} />
        </Section>
      </Ghostable>
      {renderPresentation()}
      <Collapsable inline collapsed={isNullOrWhiteSpace(presentationPollUrl)}>
        <Section
          headerChildren={[
            <Text
              id={idModel?.pollSectionHeaderText?.id}
              key={idModel?.pollSectionHeaderText?.id}
              theme={TextTheme.White}
              preset={TextPreset.Title}
            >
              Answer the Poll
            </Text>,
          ]}
        >
          <div id={idModel?.pollContainer} className={PresentationTheaterClassName.PollContainer}>
            <Ghostable ghosted={!!pollHeight}>
              <div id={idModel.pollIframePlaceholder} className={`${PresentationTheaterClassName.PollEmpty}`}>
                <Text preset={TextPreset.Subheader}>Once a question has been asked, a poll will appear here.</Text>
              </div>
            </Ghostable>
            <iframe
              id={idModel.pollIframe}
              className={getClassName(PresentationTheaterClassName.PollFrame, [
                {
                  condition: !!pollHeight,
                  trueClassName: PresentationTheaterClassName.PollFrameWithHeightModifier,
                },
              ])}
              src={presentationPollUrl}
              width="100%"
              height={pollHeight}
              frameBorder="0"
              scrolling="no"
            />
          </div>
        </Section>
      </Collapsable>
      <Collapsable inline collapsed={isNullOrWhiteSpace(presentationQaUrl)}>
        <Section
          headerChildren={[
            <Text
              id={idModel.postQuestionHeaderText?.id}
              key={idModel.postQuestionHeaderText?.id}
              theme={TextTheme.White}
              preset={TextPreset.Title}
            >
              Post a Question or Comment
            </Text>,
          ]}
        >
          <iframe width="100%" height="100%" src={presentationQaUrl} frameBorder="0" />
        </Section>
      </Collapsable>
      <Speakers
        compact
        id={idModel.speakers?.id}
        speakers={presentation?._speaker}
        selectedSpeaker={speaker}
        speakerSectionConfig={lobbyConfig?.speakers}
        visible={!lobbyConfig?.speakers.disabled}
        onSelect={onSpeakerChange}
        keyLine={null}
        theme={SectionTheme.Dark}
      />
    </div>
  );
};

export default memo(PresentationTheater);
