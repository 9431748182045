import {
  ButtonIdModel,
  Color,
  FileUploaderIdModel,
  IdModelBase,
  isEmpty,
  isNullOrWhiteSpace,
  ModalIdModel,
  RadioButtonListId,
  TextareaIdModel,
  TextboxIdModel,
  TimeZone,
} from "@q4/nimbus-ui";
import type { Moment } from "moment";
import moment from "moment-timezone";
import { BrandingDefaults } from "../../../../components/companyBranding/companyBranding.definition";
import { DeleteConfirmationMessageIdModel } from "../../../../components/deleteConfirmationMessage/deleteConfirmationMessage.definition";
import { DefaultTimeZone } from "../../../../const";
import type { ConferenceContextState } from "../../../../contexts/conference/conference.context";
import { Company } from "../../../../services/company/company.model";
import {
  Conference,
  ConferenceType,
  LobbyDefaults,
  PresentationVendorTypeDefault,
} from "../../../../services/conference/conference.model";
import { Presentation } from "../../../../services/presentation/presentation.model";
import { Speaker } from "../../../../services/speaker/speaker.model";
import { Track } from "../../../../services/track/track.model";
import { convertToLocal, formatTimeZoneLabel, getLobbyConfig, getLobbyOpenConfigLocalTime } from "../../../../utils";
import { ConferenceAdditionalInformationEditIdModel } from "./components/additionalInformation/additionalInformation.definition";
import { ConferenceBrandingIdModel } from "./components/branding/branding.definition";
import { ConferenceCoordinatorEditIdModel } from "./components/coordinator/coordinator.definition";
import { EmailFormIdModel } from "./components/emailForm/emailForm.definition";
import { LobbyConfigFormIdModel } from "./components/lobbyConfigForm/lobbyConfigForm.definition";
import { LoginMessageFormIdModel } from "./components/loginMessageForm/loginMessageForm.definition";
import { NavigationLinksIdModel } from "./components/navigationLinks/navigationLinks.definition";
import { PresentationVendorsFormIdModel } from "./components/presentationVendorsForm/presentationVendorsForm.definition";
import { ConferenceRegistrationEditIdModel } from "./components/registration/registration.definition";
import { SecuritySettingsEditIdModel } from "./components/securitySettingsEdit/securitySettingsEdit.definition";
import { ConferenceTimelineEditIdModel } from "./components/timeline/conferenceTimelineEdit.definition";
import { VideoVendorsFormIdModel } from "./components/videoVendorsForm/videoVendorsForm.definition";

export const ConferenceStartOfDay = "00:00";
export const ConferenceEndOfDay = "23:59:59";

export enum SocialMediaIcon {
  Facebook = "q4i-facebook",
  Twitter = "q4i-twitter",
  LinkedIn = "q4i-linkedin",
}

export interface ConferenceEditProps {
  conferenceContext?: ConferenceContextState;
}

export interface ConferenceEditMatchParams {
  conferenceId: string;
}

export enum ConferenceEditClassName {
  Base = "conference-edit",
  Grid = "conference-edit_grid",
  Column = "conference-edit_column",
  ColumnWithDesignModifier = "conference-edit_column--design",
  Type = "conference-edit_type",
  DeleteButton = "conference-edit_delete-button",
  PresentationVendor = "conference-edit_presentation-vendor",
  Registration = "conference-edit_registration",
  EmailForm = "conference-edit_email-form",
  MeetingLocation = "conference-edit_meeting-location",
  LineDivider = "conference-edit_line-divider",
  FullWidthInput = "conference-edit_full-width-input",
  LoginMessage = "conference-edit_login-message",
}

export class ConferenceEditState implements Omit<Conference, "time_zone" | "Status" | "Path"> {
  preview?: Conference["preview"];
  title: Conference["title"];
  description?: Conference["description"];
  registration_description?: Conference["registration_description"];
  info?: Conference["info"];
  path?: Conference["path"];
  start_date: Conference["start_date"];
  end_date: Conference["end_date"];
  open_date: Conference["open_date"];
  close_date: Conference["close_date"];
  date_label?: Conference["date_label"];
  image_logo?: Conference["image_logo"];
  image_logo_secondary?: Conference["image_logo_secondary"];
  image_background?: Conference["image_background"];
  image_favicon?: Conference["image_favicon"];
  image_live?: Conference["image_live"];
  branding?: Conference["branding"];
  coordinator?: Conference["coordinator"];
  disclaimer?: Conference["disclaimer"];
  _company?: Conference["_company"];
  _speaker?: Conference["_speaker"];
  _track?: Conference["_track"];
  _presentation?: Conference["_presentation"];
  _ondemand_presentation?: Conference["_ondemand_presentation"];
  created_at?: Conference["created_at"];
  updated_at?: Conference["updated_at"];
  video_vendors?: Conference["video_vendors"];
  socialMedia?: { icon: string; url: string }[];
  reply_to?: Conference["reply_to"];
  corporate_profiles?: Conference["corporate_profiles"];
  lobby: Conference["lobby"];
  navigation_links?: Conference["navigation_links"];
  presentation_vendors?: Conference["presentation_vendors"];
  registration: Conference["registration"];
  auto_register?: Conference["auto_register"];
  corporate_register?: Conference["corporate_register"];
  investor_register?: Conference["investor_register"];
  general_register?: Conference["general_register"];
  internal_register?: Conference["internal_register"];
  corporate_deadlines?: Conference["corporate_deadlines"];
  investor_deadlines?: Conference["investor_deadlines"];
  email_configuration?: Conference["email_configuration"];
  auto_approve?: Conference["auto_approve"];
  conference_type: Conference["conference_type"];
  display_login_link: Conference["display_login_link"];
  guest_other_types_enabled?: boolean;
  requireAuthenticatedMeetingLink: boolean;
  scheduler?: Conference["scheduler"];
  time_zone: TimeZone;
  login?: Conference["login"];

  constructor(conference?: Conference) {
    // use current day without time_zone, it will be added on save
    if (isEmpty(conference)) {
      this.time_zone = {
        value: DefaultTimeZone,
        label: formatTimeZoneLabel(DefaultTimeZone),
      };

      this.open_date = _getDefaultStartDate();
      this.start_date = _getDefaultStartDate();
      this.end_date = _getDefaultEndDate();
      this.close_date = _getDefaultEndDate();
      this.guest_other_types_enabled = false;

      this.lobby = LobbyDefaults;

      this.socialMedia = [
        { icon: SocialMediaIcon.Facebook, url: "" },
        { icon: SocialMediaIcon.Twitter, url: "" },
        { icon: SocialMediaIcon.LinkedIn, url: "" },
      ];

      const defaultFont = {
        name: BrandingDefaults.fontFamilyBase,
        path: "",
        base_size: BrandingDefaults.fontSizeBase,
        color: BrandingDefaults.fontColor,
      };

      this.branding = {
        color_primary: BrandingDefaults.primaryColor,
        color_secondary: BrandingDefaults.secondaryColor,
        color_secondary_background: BrandingDefaults.secondaryBackgroundColor,
        color_tertiary: BrandingDefaults.tertiaryColor,
        font_body: { ...defaultFont },
        font_header: { ...defaultFont },
      };

      this.presentation_vendors = [
        {
          name: PresentationVendorTypeDefault,
        },
      ];

      this.auto_register = {
        enabled: false,
        start_date: this.start_date.clone(),
        end_date: this.end_date.clone(),
      };

      this.corporate_register = {
        start_date: this.corporate_register?.start_date.clone(),
        end_date: this.corporate_register?.end_date.clone(),
      };

      this.investor_register = {
        start_date: this.investor_register?.start_date.clone(),
        end_date: this.investor_register?.end_date.clone(),
      };

      this.internal_register = {
        start_date: this.internal_register?.start_date.clone(),
        end_date: this.internal_register?.end_date.clone(),
      };

      this.general_register = {
        start_date: this.general_register?.start_date.clone(),
        end_date: this.general_register?.end_date.clone(),
      };

      this.investor_deadlines = {
        meeting_request: this.investor_deadlines?.meeting_request.clone(),
      };

      this.email_configuration = {
        registration_complete_enabled: false,
        message: "",
        logo: "",
        color_header: "",
        email_name_alias: "",
        email_address_alias: "",
        signature: "",
        registration_approval_enabled: false,
        registration_approval_message: "",
      };

      this.login = {
        ...this.login,
        primary_color: Color.White,
      };

      this.conference_type = ConferenceType.FullConference;

      this.requireAuthenticatedMeetingLink = true;

      return;
    }

    const {
      preview,
      start_date,
      end_date,
      open_date,
      close_date,
      branding,
      _company,
      _speaker,
      _track,
      _presentation,
      socialMedia,
      lobby,
      presentation_vendors,
      auto_register,
      corporate_register,
      investor_register,
      internal_register,
      general_register,
      corporate_deadlines,
      investor_deadlines,
      email_configuration,
      time_zone,
      display_login_link,
      ...rest
    } = conference ?? {};

    this.preview = !!preview;

    const timeZone = isNullOrWhiteSpace(time_zone) ? DefaultTimeZone : time_zone;
    this.time_zone = {
      value: timeZone,
      label: formatTimeZoneLabel(timeZone),
    };

    const openDate = typeof open_date === "string" ? moment.utc(open_date) : open_date;
    this.open_date = convertToLocal(openDate, timeZone) ?? _getDefaultStartDate();

    const startDate = typeof start_date === "string" ? moment.utc(start_date) : start_date;
    this.start_date = convertToLocal(startDate, timeZone) ?? _getDefaultStartDate();

    const endDate = typeof end_date === "string" ? moment.utc(end_date) : end_date;
    this.end_date = convertToLocal(endDate, timeZone) ?? _getDefaultEndDate();

    const closeDate = typeof close_date === "string" ? moment.utc(close_date) : close_date;
    this.close_date = convertToLocal(closeDate, timeZone) ?? _getDefaultEndDate();

    this.guest_other_types_enabled = (conference?.guest_attendee_types || []).length > 0;

    this.branding = {
      ...branding,
      font_body: {
        name: branding?.font_body?.name || BrandingDefaults.fontFamilyBase,
        path: branding?.font_body?.path || null,
        base_size: branding?.font_body?.base_size || BrandingDefaults.fontSizeBase,
        color: branding?.font_body?.color || BrandingDefaults.fontColor,
      },
      font_header: {
        name: branding?.font_header?.name || BrandingDefaults.fontFamilyBase,
        path: branding?.font_header?.path || null,
        base_size: branding?.font_header?.base_size || null,
        color: branding?.font_header?.color || BrandingDefaults.fontColor,
      },
    };
    this._company = new Company(_company);
    this._presentation = (_presentation || []).map((x) => new Presentation(x));
    this._speaker = (_speaker || []).map((x) => new Speaker(x));
    this._track = (_track || []).map((x) => new Track(x));

    this.socialMedia = isEmpty(socialMedia)
      ? [
          { icon: SocialMediaIcon.Facebook, url: "" },
          { icon: SocialMediaIcon.Twitter, url: "" },
          { icon: SocialMediaIcon.LinkedIn, url: "" },
        ]
      : socialMedia;

    this.lobby = {
      agenda: getLobbyConfig("agenda", lobby),
      dialInInstructions: getLobbyConfig("dialInInstructions", lobby),
      ondemand_presentations: getLobbyConfig("ondemand_presentations", lobby),
      speakers: getLobbyConfig("speakers", lobby),
      tracks: getLobbyConfig("tracks", lobby),
      livePresentation: getLobbyConfig("livePresentation", lobby),
      corporate_lobby: getLobbyOpenConfigLocalTime("corporate_lobby", lobby, timeZone),
      investor_lobby: getLobbyOpenConfigLocalTime("investor_lobby", lobby, timeZone),
      general_lobby: getLobbyOpenConfigLocalTime("general_lobby", lobby, timeZone),
    };

    this.presentation_vendors = isEmpty(presentation_vendors)
      ? [
          {
            name: PresentationVendorTypeDefault,
          },
        ]
      : presentation_vendors;

    const registerFormattedDate = (date: string | Moment) =>
      convertToLocal(typeof date === "string" ? moment.utc(date) : date, timeZone);

    this.auto_register = {
      enabled: auto_register.enabled ?? false,
      start_date: registerFormattedDate(auto_register?.start_date),
      end_date: registerFormattedDate(auto_register?.end_date),
    };

    this.corporate_register = {
      start_date: registerFormattedDate(corporate_register?.start_date),
      end_date: registerFormattedDate(corporate_register?.end_date),
    };

    this.investor_register = {
      start_date: registerFormattedDate(investor_register?.start_date),
      end_date: registerFormattedDate(investor_register?.end_date),
    };

    this.general_register = {
      start_date: registerFormattedDate(general_register?.start_date),
      end_date: registerFormattedDate(general_register?.end_date),
    };

    this.internal_register = {
      start_date: registerFormattedDate(internal_register?.start_date),
      end_date: registerFormattedDate(internal_register?.end_date),
    };

    this.corporate_deadlines = {
      speaker: registerFormattedDate(corporate_deadlines?.speaker),
      presentation: registerFormattedDate(corporate_deadlines?.presentation),
      meeting: registerFormattedDate(corporate_deadlines?.meeting),
    };

    this.investor_deadlines = {
      meeting_request: registerFormattedDate(investor_deadlines?.meeting_request),
      availability_changes: registerFormattedDate(investor_deadlines?.availability_changes),
    };

    this.email_configuration = {
      registration_complete_enabled: email_configuration?.registration_complete_enabled ?? false,
      message: email_configuration?.message ?? "",
      logo: email_configuration?.logo,
      color_header: email_configuration?.color_header,
      email_name_alias: email_configuration?.email_name_alias,
      email_address_alias: email_configuration?.email_address_alias,
      signature: email_configuration?.signature,
      registration_approval_enabled: email_configuration?.registration_approval_enabled ?? false,
      registration_approval_message: email_configuration?.registration_approval_message ?? "",
    };

    this.display_login_link = !!display_login_link;

    Object.assign(this, rest);
    // #endregion
  }
}

function _getDefaultStartDate(): Moment {
  return moment().startOf("day");
}

function _getDefaultEndDate(): Moment {
  return moment().endOf("day");
}

class PageIdModel extends IdModelBase {
  modal: ModalIdModel;
  name: TextboxIdModel;
  path: TextboxIdModel;
  timeline: ConferenceTimelineEditIdModel;
  coordinator: ConferenceCoordinatorEditIdModel;
  additionalInformation: ConferenceAdditionalInformationEditIdModel;
  lobbyConfig: LobbyConfigFormIdModel;
  logoImage: string;
  logoImageSecondary: string;
  splashImage: string;
  favicon: string;
  liveImage: string;
  chat: string;
  delete: ButtonIdModel;
  cancel: ButtonIdModel;
  confirmSchedule: ButtonIdModel;
  deleteMessage: DeleteConfirmationMessageIdModel;
  navigationLinks: NavigationLinksIdModel;
  presentationVendor: PresentationVendorsFormIdModel;
  videoVendor: VideoVendorsFormIdModel;
  registration: ConferenceRegistrationEditIdModel;
  emailForm: EmailFormIdModel;
  loginMessage: LoginMessageFormIdModel;
  branding: ConferenceBrandingIdModel;
  conferenceType: RadioButtonListId;
  securitySettings: SecuritySettingsEditIdModel;
  lobbyLoginMessage: TextareaIdModel;
  lobbyLoginErrorMessage: TextareaIdModel;
  lobbyLoginPageBackground: FileUploaderIdModel;
  lobbyLoginPrimaryColor: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.name = new TextboxIdModel(`${this.id}NameTextbox`);
    this.path = new TextboxIdModel(`${this.id}PathTextbox`);
    this.lobbyConfig = new LobbyConfigFormIdModel(`${this.id}LobbyConfig`);
    this.logoImage = `${this.id}LogoImage`;
    this.logoImageSecondary = `${this.id}LogoImageSecondary`;
    this.splashImage = `${this.id}SplashImage`;
    this.favicon = `${this.id}FaviconImage`;
    this.liveImage = `${this.id}LiveImage`;
    this.chat = `${this.id}Chat`;
    this.confirmSchedule = new ButtonIdModel(`${this.id}ConfirmScheduleButton`);
    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.delete = new ButtonIdModel(`${this.id}DeleteButton`);
    this.timeline = new ConferenceTimelineEditIdModel(`${this.id}Timeline`);
    this.additionalInformation = new ConferenceAdditionalInformationEditIdModel(`${this.id}AdditionalInformation`);
    this.coordinator = new ConferenceCoordinatorEditIdModel(`${this.id}Coordinator`);
    this.deleteMessage = new DeleteConfirmationMessageIdModel(`${this.id}DeleteMessage`);
    this.navigationLinks = new NavigationLinksIdModel(`${this.id}NavigationLinks`);
    this.presentationVendor = new PresentationVendorsFormIdModel(`${this.id}PresentationVendor`);
    this.videoVendor = new VideoVendorsFormIdModel(`${this.id}VideoVendor`);
    this.registration = new ConferenceRegistrationEditIdModel(`${this.id}Registration`);
    this.emailForm = new EmailFormIdModel(`${this.id}EmailForm`);
    this.loginMessage = new LoginMessageFormIdModel(`${this.id}LoginMessage`);
    this.branding = new ConferenceBrandingIdModel(`${this.id}Branding`);
    this.conferenceType = new RadioButtonListId(`${this.id}ConferenceType`);
    this.securitySettings = new SecuritySettingsEditIdModel(`${this.id}SecuritySettings`);
    this.lobbyLoginMessage = new TextareaIdModel(`${this.id}LobbyLoginMessage`);
    this.lobbyLoginErrorMessage = new TextareaIdModel(`${this.id}LobbyLoginErrorMessage`);
    this.lobbyLoginPageBackground = new FileUploaderIdModel(`${this.id}LobbyLoginPageBackground`);
    this.lobbyLoginPrimaryColor = new TextboxIdModel(`${this.id}LobbyLoginPagePrimaryColor`);
  }
}

export enum VendorStatus {
  Verified = "verified",
  Inaccessible = "inaccessible",
  Unverified = "unverified",
}

const PageId = "ConferenceEdit";
export const ConferenceEditIdModel = new PageIdModel(PageId);
