import "./renderAnswer.component.scss";
import { getClassName, Text, TextPreset } from "@q4/nimbus-ui";
import React, { useCallback, memo, useMemo } from "react";
import { Answer } from "../../../../../services/answer/answer.model";
import { Question, QuestionType } from "../../../../../services/questionGroup/questionGroup.model";
import { htmlParse } from "../../../../../utils";
import { RenderAnswerClassName, RenderAnswerProps, RenderAnswerIdModel } from "./renderAnswer.definition";

const RenderAnswer = (props: RenderAnswerProps) => {
  const { answer, id, individual = false } = props;
  const answerIdModel = new RenderAnswerIdModel(id);
  const question = answer._question as Question;
  const isConsent = question?.answer_type?.field_type === QuestionType.Consent;

  const className = useMemo(
    () =>
      getClassName(RenderAnswerClassName.Base, [
        {
          condition: individual,
          trueClassName: RenderAnswerClassName.Individual,
        },
      ]),
    [individual]
  );

  const getAnswerTextElement = useCallback(
    (currentQuestion: Question, currentAnswer: Answer): JSX.Element => {
      switch (currentQuestion?.answer_type?.field_type) {
        case QuestionType.Choice:
          return (
            <>
              <span className="q4i-checkmark-4pt" /> {answer.answer}
            </>
          );
        case QuestionType.Consent:
          return (
            <>
              <span className="q4i-checkmark-4pt" /> {currentQuestion.place_holder}
            </>
          );
        case QuestionType.MultipleChoice:
          return (
            <>
              {(currentAnswer.answer as string[]).map((answerItem) => {
                return (
                  <span className="array-answer-item" key={`${currentQuestion._id}_${answerItem}`}>
                    <span className="q4i-checkmark-4pt" /> {answerItem}
                  </span>
                );
              })}
            </>
          );
        default:
          return <>{currentAnswer.answer}</>;
      }
    },
    [answer.answer]
  );

  return (
    <div key={id} className={className}>
      <Text id={answerIdModel.title} preset={individual ? TextPreset.Subheader : TextPreset.Title}>
        {isConsent ? htmlParse(question.title) : question.title}
      </Text>
      <Text id={id} preset={TextPreset.Paragraph}>
        {getAnswerTextElement(question, answer)}
      </Text>
    </div>
  );
};

export default memo(RenderAnswer);
