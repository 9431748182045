import "./colorTextbox.component.scss";
import { getClassName, Textbox, TextboxProps } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import type { CSSProperties } from "react";
import { ColorTextboxClassName } from "./colorTextbox.definition";

function ColorTextbox(props: TextboxProps): JSX.Element {
  const { className, value, ...textboxProps } = props;

  const swatchStyle = useMemo(getSwatchStyle, [value]);
  const textboxClassname = useMemo(getTextboxClassName, [className]);

  function getSwatchStyle(): CSSProperties {
    return {
      backgroundColor: value?.toString(),
    };
  }

  function getTextboxClassName(): string {
    return getClassName(ColorTextboxClassName.Textbox, [{ condition: !!className, trueClassName: className }]);
  }

  return (
    <div className={ColorTextboxClassName.Base}>
      <div className={ColorTextboxClassName.Swatch} style={swatchStyle} />
      <Textbox value={value} className={textboxClassname} {...textboxProps} />
    </div>
  );
}

export default memo(ColorTextbox);
