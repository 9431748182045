import "./timetable.component.scss";
import { isEmpty, Keyline, getClassName, KeylineTheme } from "@q4/nimbus-ui";
import { extendMoment } from "moment-range";
import MomentLib from "moment-timezone";
import React, { memo, useMemo, MouseEvent, useCallback } from "react";
import { DefaultTimeZone } from "../../../../../../../../const";
import { DateFormat, TimeFormat } from "../../../../../../../../definitions/date.definition";
import { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import {
  getSlotAvailabilityForCalendar,
  isEventContainedWithinSlot,
  getParticipatingAttendees,
  isEventIntersectingSlot,
  getResourceAvailability,
  sortResourceEventsByTimeAndType,
} from "../../../meetingScheduler/meetingScheduler.utils";
import { SchedulerResource, SchedulerResourceType } from "../../meetingScheduler.definition";
import { EventSlot, EventType, ResourceEvent } from "../flexibleScheduler/flexibleScheduler.definition";
import { TimetableClassName, TimetableIdModel, TimetableProps } from "./timetable.definition";

// eslint rule needed as default moment export will not work for app and jest simultaneously, must be named import (https://github.com/rotaready/moment-range/issues/263)
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const moment = extendMoment(MomentLib as any);

const Timetable = (props: TimetableProps): JSX.Element => {
  const {
    id,
    currentDate,
    slots,
    resources,
    conference,
    meetingEvents,
    presentationEvents,
    breakEvents,
    openModal,
    closeModal,
    setCurrentMeeting,
  } = props;

  const idModel = useMemo(() => new TimetableIdModel(id), [id]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone || DefaultTimeZone, [conference?.time_zone]);

  const schedulerResources = useMemo(() => resources.map((resource) => new SchedulerResource(resource)), [resources]);

  const resourceSlots: EventSlot<Meeting | Presentation>[][] = useMemo(
    () =>
      schedulerResources?.map((resource) => {
        const resourceEvents: ResourceEvent<Meeting | Presentation>[] = [];

        if (resource.resourceType === SchedulerResourceType.Corporate) {
          resourceEvents.push(...meetingEvents[0], ...presentationEvents, ...breakEvents);
        } else {
          resourceEvents.push(...meetingEvents[1], ...breakEvents);
        }

        const resourceAvailability = getResourceAvailability(resource);

        return slots?.map((slot) => {
          const resourceSlot = {
            ...slot,
            events: [],
          } as EventSlot<Meeting | Presentation>;

          resourceSlot.blocked = !getSlotAvailabilityForCalendar(
            resourceSlot?.start_time,
            resourceSlot?.end_time,
            resourceAvailability
          );

          resourceSlot.events = resourceEvents
            .filter((event) => isEventIntersectingSlot(event, slot))
            .sort(sortResourceEventsByTimeAndType);

          return resourceSlot;
        }) as EventSlot<Meeting | Presentation>[];
      }),
    [schedulerResources, meetingEvents, presentationEvents, breakEvents, slots]
  );

  const handleResourceClick = useCallback(
    (clickedSlot: EventSlot<Meeting | Presentation>) => {
      closeModal();
      const start_date = moment(clickedSlot.start_time).tz(conferenceTimeZone, true);
      const end_date = moment(clickedSlot.end_time).tz(conferenceTimeZone, true);

      const _attendee = getParticipatingAttendees(clickedSlot.start_time.toDate(), schedulerResources);

      setCurrentMeeting(
        new Meeting({
          _conference: conference,
          start_date,
          end_date,
          _attendee,
        })
      );

      openModal();
    },
    [schedulerResources, conference, conferenceTimeZone, openModal, closeModal, setCurrentMeeting]
  );

  const renderTimetableHeader = useMemo((): JSX.Element => {
    return (
      <div className={TimetableClassName.Header}>
        <div className={TimetableClassName.TimeHeader}></div>
        {schedulerResources.map((resource) => {
          if (isEmpty(resource)) return;
          return (
            <div key={resource._id} className={TimetableClassName.ResourceHeader}>
              {resource.resourceTitle}
            </div>
          );
        })}
      </div>
    );
  }, [schedulerResources]);

  const renderTimetableTimeColumn = useMemo((): JSX.Element => {
    return (
      <div key="TimeContentColumn" className={TimetableClassName.TimeColumn}>
        {slots?.map((slot, i) => {
          const className = getClassName(TimetableClassName.Slot, [
            { condition: slot.pseudo, trueClassName: TimetableClassName.PseudoSlot },
          ]);
          return (
            <>
              <div key={`${slot.start_time.format(DateFormat.Short)}-time-slot-${i}`} className={className}>
                {slot.start_time.format(TimeFormat.Picker)} - {slot.end_time.format(TimeFormat.Picker)}
              </div>
              <Keyline theme={KeylineTheme.SoftGrey} />
            </>
          );
        })}
      </div>
    );
  }, [slots]);

  const renderSlotEvent = useCallback(
    (event, slot) => {
      function handleSelectEvent(evt: MouseEvent) {
        evt.stopPropagation();

        closeModal();

        if (event.data instanceof Meeting) {
          setCurrentMeeting(event.data);
          openModal();
        }
      }

      const eventClassName = getClassName(TimetableClassName.Event, [
        {
          condition: event.type === EventType.Presentation,
          trueClassName: TimetableClassName.PresentationEvent,
        },
        {
          condition: event.type === EventType.Break,
          trueClassName: TimetableClassName.BreakEvent,
        },
      ]);

      const showAsterix = !isEventContainedWithinSlot(event, slot);

      return (
        <div
          key={event.id}
          onClick={event.type === EventType.Meeting ? handleSelectEvent : (e) => e.stopPropagation()}
          className={eventClassName}
        >
          <div className={TimetableClassName.EventTitle}>{event.title}</div>
          <div>
            {moment(event.start_time).clone().tz(conferenceTimeZone).format(TimeFormat.Picker)} -{" "}
            {moment(event.end_time).clone().tz(conferenceTimeZone).format(TimeFormat.Picker)}
            {showAsterix && "*"}
          </div>
        </div>
      );
    },
    [conferenceTimeZone, openModal, closeModal, setCurrentMeeting]
  );

  const renderTimetableResourceColumn = useCallback(
    (resource, index): JSX.Element => {
      if (isEmpty(resource)) return;

      const resourceClassName = getClassName(TimetableClassName.ResourceColumn, [
        {
          condition: index === 0,
          trueClassName: TimetableClassName.MainResourceColumn,
          falseClassName: TimetableClassName.AltResourceColumn,
        },
      ]);

      return (
        <div key={`table-content_${resource._id}`} className={resourceClassName}>
          {resourceSlots[index]?.map((slot, i) => {
            const slotHasSession = slot.events.some(
              (se) => se.type === EventType.Presentation || se.type === EventType.Break
            );

            const slotClassName = getClassName(TimetableClassName.Slot, [
              { condition: !!slot.pseudo, trueClassName: TimetableClassName.PseudoSlot },
              { condition: !!slot.blocked || slotHasSession, trueClassName: TimetableClassName.BlockedSlot },
            ]);

            const matchingSlotInOtherColumn = resourceSlots[(index + 1) % 2][i];

            const showHighlight =
              isEmpty(matchingSlotInOtherColumn.events) &&
              isEmpty(slot.events) &&
              !matchingSlotInOtherColumn.blocked &&
              !slot.blocked &&
              !slot.pseudo;

            const showOutsideSlotText = !!slot.pseudo && isEmpty(slot.events);

            return (
              <>
                <div key={`${currentDate?.format(DateFormat.Short)}-resource-${index}-slot-${i}`} className={slotClassName}>
                  {showOutsideSlotText && (
                    <div className={`${TimetableClassName.Event} ${TimetableClassName.PseudoEvent}`}>
                      Outside of Scheduled Time Slots
                    </div>
                  )}
                  {showHighlight ? (
                    <div
                      className={TimetableClassName.Highlight}
                      onClick={() =>
                        handleResourceClick({
                          resourceId: resource._id,
                          ...slot,
                        } as EventSlot<Meeting | Presentation>)
                      }
                    ></div>
                  ) : (
                    slot.events.map((event) => renderSlotEvent(event, slot))
                  )}
                </div>
                <Keyline theme={KeylineTheme.SoftGrey} />
              </>
            );
          })}
        </div>
      );
    },
    [currentDate, resourceSlots, renderSlotEvent, handleResourceClick]
  );

  const renderTimeTableContent = useMemo((): JSX.Element => {
    return (
      <div className={TimetableClassName.Content}>
        {renderTimetableTimeColumn}
        {resources?.map((resource, index) => renderTimetableResourceColumn(resource, index))}
      </div>
    );
  }, [resources, renderTimetableTimeColumn, renderTimetableResourceColumn]);

  return (
    <div id={idModel.id} className={TimetableClassName.Base}>
      {renderTimetableHeader}
      {renderTimeTableContent}
    </div>
  );
};

export default memo(Timetable);
