import { isNil } from "@q4/nimbus-ui";
import moment from "moment";
import type { Conference } from "../../services/conference/conference.model";

export type ExcludedDates = { before: Date; after: Date };
export function getExcludedMeetingDates(conference: Conference): ExcludedDates {
  const { start_date, end_date } = conference?.scheduler ?? {};

  if (isNil(start_date) || isNil(end_date)) {
    return {
      before: moment.max(conference?.start_date, moment()).toDate(),
      after: conference?.end_date?.toDate(),
    };
  }

  return {
    before: moment.max(start_date, moment()).toDate(),
    after: end_date.toDate(),
  };
}
