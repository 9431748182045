import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { AdminUserContextState } from "../../../../contexts/adminUser/adminUser.definition";

export interface HeaderProps extends BaseComponentProps {
  id: string;
  adminUserContext: Partial<AdminUserContextState>;
}

export enum HeaderClassName {
  Base = "header",
  ProfileButton = "header_profile-button",
}

export class HeaderIdModel extends IdModelBase {
  profile: ButtonIdModel;
  logOut: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.profile = new ButtonIdModel(`${this.id}ProfileButton`);
    this.logOut = new ButtonIdModel(`${this.id}LogOutButton`);
  }
}
