import { isEmpty } from "@q4/nimbus-ui";
import { EntityBase } from "../../definitions/entity.definition";
import type { Answer } from "../answer/answer.model";
import { AttendeePutPayload, AttendeeViewModel } from "../attendee/attendee.model";
import type { Conference, ConferenceSchedulerSlot } from "../conference/conference.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";

export enum CorporateType {
  Public = "Public",
  Private = "Private",
}

export class CorporateProfile extends EntityBase {
  _conference: Conference["_id"];
  _primary_contact: AttendeeViewModel["_id"];
  name: string;
  industry?: string;
  type: CorporateType;
  description?: string;
  logo_image?: string;
  secondary_logo_image?: string;
  ticker_symbol?: string;
  exchange?: string;
  url?: string;
  host_small_meetings: boolean;
  host_presentation: boolean;
  notes?: string;
  attendees?: AttendeeViewModel[];
  availability?: ConferenceSchedulerSlot[];
  custom_question_answers?: Answer[];

  constructor(profile: Partial<CorporateProfile> | string) {
    super(profile);
    if (typeof profile === "string" || isEmpty(profile)) return;

    const corpAttendees = profile?.attendees || [];
    const corpAvailability =
      corpAttendees.find((attendee) => attendee.is_primary_contact)?.availability || corpAttendees?.[0]?.availability;

    Object.assign(this, profile);
    if (!profile?.availability && profile?.attendees?.length) {
      this.availability = corpAvailability;
    }
  }
}

export class CorporateProfilePayload extends PayloadBase<CorporateProfile> {
  constructor(corporateProfile: CorporateProfile) {
    super();

    const { attendees, custom_question_answers, ...payload } = corporateProfile;

    const sanitizedPayload = this.sanitize(payload);

    sanitizedPayload.host_small_meetings = !!sanitizedPayload?.host_small_meetings;
    sanitizedPayload.host_presentation = !!sanitizedPayload?.host_presentation;

    if (sanitizedPayload.type === CorporateType.Private) {
      delete sanitizedPayload.exchange;
      delete sanitizedPayload.ticker_symbol;
    }
    const sanitizedAttendees = attendees?.map((attendee) => new AttendeePutPayload(attendee));

    Object.assign(this, sanitizedPayload, !!sanitizedAttendees ? { attendees: sanitizedAttendees } : null, {
      custom_question_answers: custom_question_answers,
    });
  }
}
