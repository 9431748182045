import type { UserContextState } from "../../../contexts/user/user.definition";
import type { Conference } from "../../../services/conference/conference.model";
import type { LegacyServiceHookModel, ServiceHookPropsBase } from "../../useService/useService.definition";

export interface UserConferenceHookProps extends ServiceHookPropsBase<Conference> {
  path?: Conference["Path"];
  userContext?: UserContextState;
}

export type UserConferenceHookModel = Omit<LegacyServiceHookModel<Conference>, "setLoading">;

export const UserNotAttendeeOfConferenceMessage = "is not an attendee of";
