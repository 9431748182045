import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useRef, useState } from "react";
import type { ApiResponse } from "../../services/api/api.definition";
import type { Conference } from "../../services/conference/conference.model";
import { Track } from "../../services/track/track.model";
import TrackService from "../../services/track/track.service";
import { useAutoFetch } from "../useAutoFetch/useAutoFetch.hook";
import type { TracksHookModel, TracksHookProps } from "./useTracks.definition";

export const useTracks = (props: TracksHookProps): TracksHookModel => {
  const { autoFetchData, conferenceId, data } = props;

  const [currentTrack, setCurrentTrack] = useState<Track>(null);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [loading, setLoading] = useState(false);
  const trackService = useRef(new TrackService());
  const notificationService = useRef(new NotificationService());

  const _handleTracksResponse = useCallback((response: ApiResponse<Track[]>): Track[] => {
    setLoading(false);
    if (!response.success) {
      notificationService.current.error("Failed to load tracks.");
      return [];
    }

    const tracks = response?.data;
    setTracks(tracks);
    return tracks;
  }, []);

  const getTracks = useCallback((): Promise<Track[]> => {
    setLoading(true);

    return trackService.current.getTracks().then(_handleTracksResponse);
  }, [_handleTracksResponse]);

  const getTracksByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Track[]> => {
      setLoading(true);

      return trackService.current.getTracksByConferenceId(_id).then(_handleTracksResponse);
    },
    [_handleTracksResponse]
  );

  const getTrackById = useCallback((_id: Track["_id"]): Promise<Track> => {
    setLoading(true);

    return trackService.current.getTrackById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }

      const { data: track } = response;
      setTracks((tracks) => tracks.map((x) => (x._id === track._id ? new Track({ ...x, ...track }) : x)));
      setCurrentTrack(track);

      return track;
    });
  }, []);

  const handleTrackPost = useCallback((data: Track): Promise<Track> => {
    setLoading(true);
    return trackService.current.postTrack(data).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Track was created successfully.");

      const { data: track } = response;
      setTracks((tracks) => [...tracks, track]);
      return response.data;
    });
  }, []);

  const handleTrackPut = useCallback((_id: Track["_id"], data: Track): Promise<Track> => {
    setLoading(true);
    return trackService.current.putTrackById(_id, data).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return null;
      }
      notificationService.current.success("Track was updated successfully.");

      const { data: track } = response;
      setTracks((tracks) => tracks.map((x) => (x._id === track._id ? new Track({ ...x, ...track }) : x)));
      return response.data;
    });
  }, []);

  const handleTrackDelete = useCallback((_id: Track["_id"]): Promise<boolean> => {
    setLoading(true);
    return trackService.current.deleteTrackById(_id).then((response) => {
      setLoading(false);
      if (!response.success) {
        notificationService.current.error(response.message);
        return response.success;
      }
      notificationService.current.success("Track was deleted successfully.");

      setTracks((tracks) =>
        tracks.reduce((updatedTracks, x) => (x._id === _id ? updatedTracks : updatedTracks.concat(x)), [])
      );
      return response.success;
    });
  }, []);

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetch: getTracks,
    fetchBy: getTracksByConferenceId,
    setEntities: setTracks,
  });

  return {
    current: currentTrack,
    items: tracks,
    loading,
    fetchTracks: getTracks,
    fetchById: getTrackById,
    fetchTracksByConferenceId: getTracksByConferenceId,
    post: handleTrackPost,
    putById: handleTrackPut,
    deleteById: handleTrackDelete,
    setCurrent: setCurrentTrack,
    setItems: setTracks,
    setLoading,
  };
};
