import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { FieldProps, FormProps, UiSchema, WidgetProps } from "@rjsf/core";
import type { JSONSchema7 } from "json-schema";
import type { Dispatch, SetStateAction } from "react";
import type { RegistrantViewModel } from "../../services/admin/registrant/registrant.model";
import type { ErrorHandlerFields } from "../../services/errorHandler/errorHandler.definition";

export enum JsonUiFieldType {
  Radio = "radio",
  Select = "select",
}

export interface JsonFile {
  data: File;
  title: string;
}
export type JsonFileRecord = Record<string, JsonFile>;

export interface JsonInputFieldType {
  default: JSX.Element;
  nuiRadio?: JSX.Element;
  nuiSelect?: JSX.Element;
  nuiCustomSelect?: JSX.Element;
  nuiFilePreview?: JSX.Element;
  nuiImagePreview?: JSX.Element;
  nuiImagePreviewValidated?: JSX.Element;
  nuiTextarea?: JSX.Element;
}

export interface JsonFormWidgetProps extends WidgetProps {
  schema: JsonFieldPropsSchema;
}

export interface JsonFieldPropsSchema extends JSONSchema7 {
  id?: string;
  placeholder?: string;
  options?: string[];
  allowCustomAnswer?: boolean;
  allowMultipleAnswers?: boolean;
  clearable?: boolean;
  properties?:
    | {
        [key: string]: JsonFieldPropsSchema | boolean;
      }
    | undefined;
}

export interface CustomJsonFieldSchemaProps extends JsonFieldPropsSchema {
  htmlTitle?: boolean;
}

export interface JsonFieldProps<T> extends FieldProps<T> {
  schema: JsonFieldPropsSchema;
}

export interface JsonLayoutFieldProps {
  [key: string]: {
    isVisible?: boolean;
    width?: string;
  };
}

export interface JsonLayout {
  sectionTitle?: string;
  properties: JsonLayoutFieldProps;
}

export interface JsonUISchema extends UiSchema {
  fieldType?: JsonUiFieldType;
}

export interface JsonFormProps<T> extends BaseComponentProps {
  data: JsonFieldProps<T>["formData"];
  errorHandlerFields?: ErrorHandlerFields[];
  formProps?: Omit<FormProps<T>, "schema" | "formData" | "onSubmit">;
  schema: FormProps<T>["schema"];
  uiSchema?: JsonUISchema;
  globalError?: string;
  onStep?: (forward: boolean | number, formData?: RegistrantViewModel) => void;
  onSubmit: FormProps<T>["onSubmit"];
  setFiles?: Dispatch<SetStateAction<JsonFileRecord>>;
  extraErrors?: FormProps<T>["extraErrors"];
  onChange?: FormProps<T>["onChange"];
}

export const JsonFormErrorId = 1;

export const JsonFormStringFieldMaxLength = 255;

export class JsonFormIdModel extends IdModelBase {
  nextStep: ButtonIdModel;
  previousStep: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.nextStep = new ButtonIdModel(`${this.id}NextStepButton`);
    this.previousStep = new ButtonIdModel(`${this.id}PreviousStepButton`);
  }
}

export enum JsonFormLabels {
  PrevStep = "PREVIOUS STEP",
  NextStep = "NEXT STEP",
}
