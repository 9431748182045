import {
  ButtonIdModel,
  CheckboxIdModel,
  FormIdModel,
  isNullOrWhiteSpace,
  TextboxIdModel,
  TextboxListId,
  TextListId,
} from "@q4/nimbus-ui";
import {
  QuestionCardWrapperIdModel,
  QuestionCardWrapperProps,
} from "../../questionCardWrapper/questionCardWrapper.definition";

export type ChoiceQuestionProps = QuestionCardWrapperProps;

export class ChoiceQuestionIdModel extends QuestionCardWrapperIdModel {
  multipleAnswer: CheckboxIdModel;
  optionField: TextboxListId;
  otherOptionField: TextboxIdModel;
  addOptionButton: ButtonIdModel;
  addOtherOptionButton: ButtonIdModel;
  optionForm: FormIdModel;
  removeOption: TextListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.multipleAnswer = new CheckboxIdModel(`${this.id}MultipleAnswerSelection`);
    this.optionField = new TextboxListId(`${this.id}OptionField`);
    this.otherOptionField = new TextboxIdModel(`${this.id}OtherOptionField`);
    this.addOptionButton = new ButtonIdModel(`${this.id}AddOptionButton`);
    this.addOtherOptionButton = new ButtonIdModel(`${this.id}AddOtherOptionButton`);
    this.removeOption = new TextListId(`${this.id}RemoveOption`);
    this.optionForm = new FormIdModel(`${this.id}OptionForm`);
  }
}
