import { arrayIndexFound, isEmpty, NotificationService } from "@q4/nimbus-ui";
import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";
import { Entity } from "../../../definitions/entity.definition";
import { OnDemand } from "../../../services/admin/onDemand/onDemand.model";
import OnDemandService from "../../../services/admin/onDemand/onDemand.service";
import type { Conference } from "../../../services/conference/conference.model";
import { Speaker } from "../../../services/speaker/speaker.model";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import { useService } from "../../useService/useService.hook";
import { handleSetEntity } from "../../useService/useService.utils";
import type { OnDemandHookModel, OnDemandHookProps } from "./useOnDemand.definition";

export const useOnDemand = (props: OnDemandHookProps): OnDemandHookModel => {
  const {
    autoFetchData,
    assignDefaultEntity: assignDefaultEntityProp,
    conferenceId,
    data,
    showNotifications,
    speakers,
    useOffline,
  } = props;

  const assignDefaultEntity = useMemo(() => assignDefaultEntityProp ?? true, [assignDefaultEntityProp]);

  const {
    current,
    items,
    loading,
    service,
    deleteById,
    post: postBase,
    putById: putByIdBase,
    setCurrent,
    setItems,
    setLoading,
  } = useService({
    autoFetchData: false,
    assignDefaultEntity,
    data,
    entityName: Entity.OnDemand,
    showNotifications,
    useOffline,
    entityModel: OnDemand,
    serviceModel: OnDemandService,
  });

  const notificationService = useMemo(() => new NotificationService(), []);

  const mapSpeaker = useCallback(
    (onDemand: OnDemand) => {
      if (isEmpty(onDemand?._speaker) || isEmpty(speakers)) return onDemand;

      return new OnDemand({
        ...onDemand,
        _speaker: onDemand._speaker.map((x) => speakers.find((y) => y._id === new Speaker(x)?._id)),
      });
    },
    [speakers]
  );

  const mapSpeakers = useCallback(
    (onDemand: OnDemand[]) => {
      return (onDemand || []).map(mapSpeaker);
    },
    [mapSpeaker]
  );

  const handleArrayResponse = useCallback(
    (onDemand: OnDemand[]): OnDemand[] => {
      const mapped = mapSpeakers(onDemand);
      setCurrent((current) => handleSetEntity(current, mapped, assignDefaultEntity));
      setItems(mapped);
      return mapped;
    },
    [assignDefaultEntity, mapSpeakers, setCurrent, setItems]
  );

  const handleResponse = useCallback(
    (response: OnDemand): OnDemand => {
      if (!response) {
        return;
      }

      const updated = mapSpeaker(response);

      setCurrent(updated);
      setItems((onDemand) => {
        const current = cloneDeep(onDemand);
        const index = onDemand.findIndex((x) => x._id == response._id);

        if (arrayIndexFound(index)) {
          current.splice(index, 1, updated);
        } else {
          current.push(updated);
        }
        return current;
      });

      return updated;
    },
    [mapSpeaker, setCurrent, setItems]
  );

  const post = useCallback(
    (onDemand: OnDemand): Promise<OnDemand> => {
      return postBase(onDemand, false).then(handleResponse);
    },
    [postBase, handleResponse]
  );

  const putById = useCallback(
    (_id: string, onDemand: OnDemand): Promise<OnDemand> => {
      return putByIdBase(_id, onDemand, false).then(handleResponse);
    },
    [putByIdBase, handleResponse]
  );

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<OnDemand[]> => {
      setLoading(true);

      return service
        .getByConferenceId(_id)
        .then((response): OnDemand[] => {
          if (!response.success) {
            showNotifications && notificationService.error("Failed to load on demand presentations.");
            return null;
          }

          return response?.data ?? [];
        })
        .then(handleArrayResponse)
        .finally(() => setLoading(false));
    },
    [notificationService, service, showNotifications, handleArrayResponse, setLoading]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetchBy: getByConferenceId,
    setEntities: setItems,
  });

  return {
    current,
    loading,
    items,
    service,
    fetchByConferenceId: getByConferenceId,
    post,
    putById,
    deleteById,
    setCurrent,
    setItems,
    setLoading,
  };
};
