import { Entity } from "../../../definitions/entity.definition";
import type { Conference } from "../../../services/conference/conference.model";
import type { QuestionGroup } from "../../../services/questionGroup/questionGroup.model";
import type QuestionGroupService from "../../../services/questionGroup/questionGroup.service";
import type { ServiceHookModel, ServiceHookPropsBase } from "../../useService/useService.definition";

export const QuestionGroupEntityName = Entity.QuestionGroup;

export interface QuestionGroupHookProps extends ServiceHookPropsBase<QuestionGroup> {
  conferenceId?: Conference["_id"]; // Will auto fetch Question Groups by id if provided
}

export interface QuestionGroupHookModel extends ServiceHookModel<QuestionGroup, QuestionGroupService> {
  fetchByConferenceId: (_id: Conference["_id"]) => Promise<QuestionGroup[]>;
}
