import { AdminRouteBase } from "../../configurations/navigation.configuration";
import { EntityBase } from "../../definitions/entity.definition";
import type { ApiResponse } from "../api/api.definition";
import type { Conference } from "../conference/conference.model";
import { AdminApiVersion, Service } from "../serviceBase/serviceBase.model";
import { QuestionGroup, QuestionGroupPayload } from "./questionGroup.model";

export default class QuestionGroupService extends Service<QuestionGroup> {
  constructor() {
    super(AdminRouteBase, "questiongroups", QuestionGroup, AdminApiVersion);
  }

  put(id: EntityBase["_id"], data: QuestionGroup): Promise<ApiResponse<QuestionGroup>> {
    const payload = new QuestionGroupPayload(data);
    return super.put(id, payload as unknown as QuestionGroup);
  }

  post(data: QuestionGroup): Promise<ApiResponse<QuestionGroup>> {
    const payload = new QuestionGroupPayload(data);
    return super.post(payload as unknown as QuestionGroup);
  }

  public async getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<QuestionGroup[]>> {
    const response = await this._apiService.get<QuestionGroup[]>(`${this.ApiPathBase}/byConference/${_id}`);
    return this.mapArrayedResponse(response);
  }
}
