import { isEmpty } from "@q4/nimbus-ui";
import { UiSchema } from "@rjsf/core";
import { useMemo } from "react";
import { JsonFieldPropsSchema } from "../../components/jsonForm/jsonForm.definition";
import { RequiredErrorText } from "../../const";
import { ErrorHandlerField, ErrorHandlerFields } from "../../services/errorHandler/errorHandler.definition";
import { Question, QuestionType, QuestionTypesWithOptions } from "../../services/questionGroup/questionGroup.model";
import { validateBoolean, validateEmptyString, validateIsNil, validateText } from "../../utils";
import {
  CustomQuestionFormProps,
  CustomQuestionsErrorMessages,
  SpecialOptions,
} from "../../views/public/register/components/customQuestions/customQuestions.definition";
import {
  convertDeletedAnswerOptions,
  getCustomQuestionField,
  getUiSchema,
  validateArray,
  validateArrayNoEmptyItems,
} from "../../views/public/register/components/customQuestions/customQuestions.utils";
import { CustomQuestionsFormHookModel, CustomQuestionsFormHookProps } from "./useCustomQuestions.definition";

const addErrorHandlers = (errorHandler: ErrorHandlerFields, question: Question) => {
  const fieldType = question.answer_type?.field_type;

  switch (fieldType) {
    case QuestionType.Consent:
      question.required && errorHandler.fields.push(new ErrorHandlerField(question._id, RequiredErrorText, validateBoolean));
      break;
    case QuestionType.Choice:
      errorHandler.fields.push(
        new ErrorHandlerField(question._id, CustomQuestionsErrorMessages.NoEmptyOtherField, validateEmptyString)
      );

      if (question.required) {
        errorHandler.fields.push(new ErrorHandlerField(question._id, RequiredErrorText, validateIsNil));
      }
      break;
    case QuestionType.MultipleChoice:
      errorHandler.fields.push(
        new ErrorHandlerField(question._id, CustomQuestionsErrorMessages.NoEmptyOtherField, validateArrayNoEmptyItems)
      );

      if (question.required) {
        errorHandler.fields.push(
          new ErrorHandlerField(question._id, RequiredErrorText, (value: string[]) => validateArray(value))
        );
      }
      break;
    default:
      question.required && errorHandler.fields.push(new ErrorHandlerField(question._id, RequiredErrorText, validateText));
      break;
  }
};

export const useCustomQuestionsForm = (props: CustomQuestionsFormHookProps): CustomQuestionsFormHookModel => {
  const { questions, registrant, useDefaultIds } = props;

  return useMemo(() => {
    const schema: JsonFieldPropsSchema = {
      type: "object",
      dependencies: {},
      properties: {},
    };
    const fields: CustomQuestionFormProps = {};
    const uiSchema: UiSchema = {
      "ui:field": "layout",
      "ui:layout": [],
    };

    if (isEmpty(questions)) {
      return {
        uiSchema,
      };
    }

    const errorHandler: ErrorHandlerFields = { fields: [] };
    questions.sort((a, b) => a.position - b.position);
    questions.forEach((question, i) => {
      const otherFieldId = `${question._id}${SpecialOptions.Other}`;

      const currentAnswer = registrant?.custom_question_answers?.find((answer) => {
        const questionId = typeof answer._question === "string" ? answer._question : answer._question._id;
        return questionId === question._id;
      });

      const fieldType = question.answer_type?.field_type;

      fields[question._id] = getCustomQuestionField(
        question,
        QuestionTypesWithOptions.includes(fieldType) && currentAnswer?.answer
          ? convertDeletedAnswerOptions(currentAnswer, question)
          : currentAnswer,
        schema,
        useDefaultIds,
        i
      );
      uiSchema[question._id] = getUiSchema(fieldType);

      const layoutSchema = {
        fields: {
          [question._id]: {
            width: "2-of-3",
          },
        },
      };

      if (question.add_other_option) {
        layoutSchema.fields[otherFieldId] = { width: "1-of-1" };
      }
      uiSchema["ui:layout"].push(layoutSchema);

      addErrorHandlers(errorHandler, question);
    });
    schema.properties = fields;

    return {
      schema,
      uiSchema,
      errorHandler,
    };
  }, [questions, registrant, useDefaultIds]);
};
