import React from "react";
import { Redirect } from "react-router";
import Fallback from "../../../components/errorLog/fallback/fallback.component";
import { RoutePath } from "../../../configurations/navigation.configuration";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import { RouteProps } from "./route.definition";

const NotFoundRoutes = (props: RouteProps): JSX.Element[] => {
  const { userContext } = props;
  return [
    <PublicRoute key="404_not-found" exact path={RoutePath.NotFound} userContext={userContext} component={Fallback} />,
    // Company
    <Redirect exact key="custom-company--redirect" from={RoutePath.Custom} to={RoutePath.NotFound} />,
    <Redirect exact key="company--redirect" from={RoutePath.Company} to={RoutePath.NotFound} />,
    // Company Login
    <Redirect exact key="custom-company_login--redirect" from={RoutePath.CustomLogin} to={RoutePath.NotFound} />,
    <Redirect exact key="company_login--redirect" from={RoutePath.CompanyLogin} to={RoutePath.NotFound} />,
    // Company Register
    <Redirect exact key="company_register--redirect" from={RoutePath.CompanyRegistration} to={RoutePath.NotFound} />,
    <Redirect exact key="custom-company_register--redirect" from={RoutePath.CustomRegistration} to={RoutePath.NotFound} />,
    // Company Presentation
    <Redirect exact key="custom-company_pres--redirect" from={RoutePath.CustomPresentation} to={RoutePath.NotFound} />,
    <Redirect exact key="company_pres--redirect" from={RoutePath.CompanyPresentation} to={RoutePath.NotFound} />,
    <Redirect exact key="custom-company_pres--redirect" from={RoutePath.CustomPresentationWithId} to={RoutePath.NotFound} />,
    <Redirect exact key="company_pres--redirect" from={RoutePath.CompanyPresentationWithId} to={RoutePath.NotFound} />,
    // Company Meeting
    <Redirect exact key="custom-company_meeting--redirect" from={RoutePath.CustomMeeting} to={RoutePath.NotFound} />,
    <Redirect exact key="company_meeting--redirect" from={RoutePath.CompanyMeeting} to={RoutePath.NotFound} />,
    <Redirect exact key="custom-company_meeting--redirect" from={RoutePath.CustomMeetingWithId} to={RoutePath.NotFound} />,
    <Redirect exact key="company_meeting--redirect" from={RoutePath.CompanyMeetingWithId} to={RoutePath.NotFound} />,
    // Company OnDemand
    <Redirect exact key="custom-company_ondemand--redirect" from={RoutePath.CustomOnDemand} to={RoutePath.NotFound} />,
    <Redirect exact key="company_ondemand--redirect" from={RoutePath.CompanyOnDemand} to={RoutePath.NotFound} />,
    <Redirect exact key="custom-company_ondemand--redirect" from={RoutePath.CustomOnDemandWithId} to={RoutePath.NotFound} />,
    <Redirect exact key="company_ondemand--redirect" from={RoutePath.CompanyOnDemandWithId} to={RoutePath.NotFound} />,
    // Company Profile
    <Redirect exact key="company_profile--redirect" from={RoutePath.CompanyAttendeeProfilePath} to={RoutePath.NotFound} />,
    <Redirect
      exact
      key="custom-company_profile--redirect"
      from={RoutePath.CustomCompanyAttendeeProfilePath}
      to={RoutePath.NotFound}
    />,
  ];
};

export default NotFoundRoutes;
