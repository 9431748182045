import { isEmpty } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import { mapAvailabilityToTimeZone } from "../../../utils";
import { useCorporateProfile as useCorporateProfileHook } from "../../admin/useCorporateProfile/useCorporateProfile.hook";
import { useAttendees as useAttendeesHook } from "../../useAttendees/useAttendees.hook";
import type {
  AttendeeCorporateProfileHookModel,
  AttendeeCorporateProfileHookProps,
} from "./useAttendeeCorporateProfile.definition";

export const useAttendeeCorporateProfile = (props: AttendeeCorporateProfileHookProps): AttendeeCorporateProfileHookModel => {
  const { conferenceId, conferenceTimeZone, attendeeHookProps, corporateProfileHookProps } = props;

  const useAttendees = useAttendeesHook({ ...attendeeHookProps, conferenceId });
  const { current: rawAttendee, items: rawAttendees } = useAttendees;

  const useCorporateProfile = useCorporateProfileHook({ ...corporateProfileHookProps, conferenceId });
  const { current: rawProfile, items: rawProfiles } = useCorporateProfile;

  const mapAttendeeWithCorporateProfile = useCallback(
    (att: AttendeeViewModel) => {
      if (isEmpty(att)) return;

      const corporateProfileId =
        typeof att._corporate_profile === "string" ? att._corporate_profile : att._corporate_profile?._id;

      const _corporate_profile = rawProfiles.find((x) => x._id === corporateProfileId);

      return new AttendeeViewModel({
        ...att,
        availability: mapAvailabilityToTimeZone(att.availability, conferenceTimeZone),
        _corporate_profile,
      });
    },
    [conferenceTimeZone, rawProfiles]
  );

  const attendees = useMemo(
    () => (rawAttendees || []).map((att) => mapAttendeeWithCorporateProfile(att)),
    [mapAttendeeWithCorporateProfile, rawAttendees]
  );

  const attendee = useMemo(
    () => mapAttendeeWithCorporateProfile(rawAttendee),
    [mapAttendeeWithCorporateProfile, rawAttendee]
  );

  const mapProfileWithAttendees = useCallback(
    (corporate: CorporateProfile) => {
      if (isEmpty(corporate)) return;

      return new CorporateProfile({
        ...corporate,
        attendees: (attendees || []).reduce((mapped: AttendeeViewModel[], att) => {
          if (isEmpty(att)) return mapped;

          const corporateProfileId =
            typeof att._corporate_profile === "string" ? att._corporate_profile : att._corporate_profile?._id;

          if (corporateProfileId !== corporate._id) return mapped;

          mapped.push(att);
          return mapped;
        }, []),
      });
    },
    [attendees]
  );

  const profiles = useMemo(
    () => (rawProfiles ?? []).map((x) => mapProfileWithAttendees(x)),
    [mapProfileWithAttendees, rawProfiles]
  );

  const profile = useMemo(() => mapProfileWithAttendees(rawProfile), [mapProfileWithAttendees, rawProfile]);

  return {
    useAttendees: { ...useAttendees, current: attendee, items: attendees },
    useCorporateProfile: { ...useCorporateProfile, current: profile, items: profiles },
  };
};
