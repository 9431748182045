import { ConferenceBaseEntity } from "../../../definitions/entity.definition";
import { Presentation } from "../../presentation/presentation.model";
import { PayloadBase } from "../../serviceBase/payloadBase.model";
import { SessionBase, SessionEventType } from "../../session/session.model";
import type { Speaker } from "../../speaker/speaker.model";
import type { Track } from "../../track/track.model";

interface OnDemandBase extends Omit<SessionBase, "start_date" | "vendors" | "end_date" | "_conference" | "ConferenceId"> {
  _track?: Track;
  _speaker?: Speaker[];
  end_date?: SessionBase["end_date"];
  code?: string[];
  title: string;
  presentation_type?: Presentation["presentation_type"];
  is_shareable_after_event?: boolean;
  file?: string;
  order: number;
  urlOverride?: SessionBase["urlOverride"];
  image_thumbnail?: string;
  description?: string;
  add_to_all?: boolean;
}

export class OnDemand extends ConferenceBaseEntity implements OnDemandBase {
  readonly type? = SessionEventType.OnDemandPresentation;
  _track?: OnDemandBase["_track"];
  _speaker?: OnDemandBase["_speaker"];
  code?: OnDemandBase["code"];
  title: OnDemandBase["title"];
  presentation_type?: OnDemandBase["presentation_type"];
  is_shareable_after_event?: OnDemandBase["is_shareable_after_event"];
  file?: OnDemandBase["file"];
  end_date?: OnDemandBase["end_date"];
  order: OnDemandBase["order"];
  urlOverride?: OnDemandBase["urlOverride"];
  image_thumbnail?: OnDemandBase["image_thumbnail"];
  description?: OnDemandBase["image_thumbnail"];
  add_to_all?: OnDemandBase["add_to_all"];

  constructor(onDemand: Partial<OnDemand> | string) {
    super(onDemand);
    if (typeof onDemand === "string") return;

    Object.assign(this, onDemand);
  }
}

type OnDemandPayloadBase = Omit<OnDemand, "ConferenceId" | "_id" | "type" | "add_to_all">;
export class OnDemandPayload extends PayloadBase<OnDemandPayloadBase> implements OnDemandPayloadBase {
  _conference: OnDemand["_conference"];
  _track?: OnDemand["_track"];
  _speaker?: OnDemand["_speaker"];
  code?: OnDemand["code"];
  title: OnDemand["title"];
  is_shareable_after_event?: OnDemand["is_shareable_after_event"];
  file?: OnDemand["file"];
  end_date?: OnDemand["end_date"];
  urlOverride?: OnDemand["urlOverride"];
  order: OnDemandBase["order"];
  image_thumbnail?: OnDemandBase["image_thumbnail"];
  description?: OnDemandBase["image_thumbnail"];

  // TODO remove
  get ConferenceId(): string {
    if (typeof this._conference === "string") return this._conference;
    return this._conference?._id;
  }

  constructor(onDemand: OnDemand) {
    super();
    const { _id, ConferenceId, type, ...payload } = onDemand;
    const sanitizedPayload = this.sanitize({ ...payload, _conference: ConferenceId });
    Object.assign(this, sanitizedPayload);
  }
}

export class OnDemandPutPayload extends OnDemandPayload {
  constructor(onDemand: OnDemand) {
    super(onDemand);
    const { _id } = onDemand;
    Object.assign(this, { _id });
  }
}
