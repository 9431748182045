import { Checkbox } from "@q4/nimbus-ui";
import React, { useMemo } from "react";
import { JsonFormWidgetProps } from "../../jsonForm.definition";

const BooleanCheckboxWidget = (props: JsonFormWidgetProps): JSX.Element => {
  const { id, value, disabled, schema, onChange } = props;

  const checkboxValue = useMemo(() => value ?? Boolean(schema?.default), [schema?.default, value]);

  function handleRadioChange() {
    onChange(!checkboxValue);
  }

  return (
    <Checkbox
      id={schema.id ?? `${id}Checkbox`}
      label={schema.placeholder}
      value={checkboxValue}
      checked={value}
      disabled={disabled}
      inline={true}
      onChange={handleRadioChange}
      stopPropagation={true}
    />
  );
};

export default BooleanCheckboxWidget;
