import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { useState, useMemo, useCallback } from "react";
import { ChipsHookProps } from "../useChips/useChips.definition";
import { useChips } from "../useChips/useChips.hook";
import { ComboBoxDefaultSearchResults } from "./useComboBox.definition";
import type { ComboBoxHookProps, ComboBoxHookModel } from "./useComboBox.definition";

export const useComboBox = <T>(props: ComboBoxHookProps<T>): ComboBoxHookModel<T> => {
  const { createSuffix, options: optionsProp, chipsHookProps, maxSearchResultShown, minSearchLengthShown } = props;
  const { entities, disabled, ...chipHookEntityProps } = chipsHookProps || {};

  const isStringEntity = useMemo(
    () => !isNullOrWhiteSpace(createSuffix) || typeof (optionsProp?.[0] || entities?.[0]) === "string",
    [createSuffix, entities, optionsProp]
  );

  const useChipsProps = useMemo(
    () =>
      isStringEntity
        ? { entities: entities as string[], createSuffix, disabled }
        : { ...chipHookEntityProps, createSuffix: null as never, entities: entities as T[], disabled },
    [chipHookEntityProps, createSuffix, disabled, entities, isStringEntity]
  );

  const chipsHook = useChips(useChipsProps);

  const [input, setInput] = useState<string>();

  const allowMenuOpen = useMemo(() => {
    const maxShown = maxSearchResultShown || ComboBoxDefaultSearchResults.Max;
    const searchLength = minSearchLengthShown || ComboBoxDefaultSearchResults.Length;
    if (!isEmpty(entities) && entities.length < maxShown && isNullOrWhiteSpace(createSuffix)) return null;
    return {
      menuIsOpen: !isNullOrWhiteSpace(input) && input.length > searchLength,
    };
  }, [createSuffix, entities, input, maxSearchResultShown, minSearchLengthShown]);

  const selectOptions = useMemo((): (T | string)[] => {
    const options = optionsProp ?? [];

    if (isEmpty(options) && !isNullOrWhiteSpace(input) && !isNullOrWhiteSpace(createSuffix)) {
      return [`${input}${createSuffix}`];
    }

    if (isNullOrWhiteSpace(input) || isNullOrWhiteSpace(createSuffix) || typeof options?.[0] !== "string") return options;
    if (options.some((x) => x === input)) return options;

    return [`${input}${createSuffix}`, ...options];
  }, [createSuffix, input, optionsProp]);

  const filteredOptions = useMemo((): T[] => {
    const { entities } = useChipsProps;
    if (isEmpty(entities)) return selectOptions as unknown as T[];

    if (typeof selectOptions?.[0] === "string") {
      const filtered = (selectOptions as string[]).filter(
        (x: string) => !(entities as string[]).some((y: string) => x === y)
      );
      return filtered as unknown as T[];
    }

    const { valueKey } = useChipsProps as ChipsHookProps<T>;
    if (isNullOrWhiteSpace(valueKey?.toString())) return selectOptions as unknown as T[];

    return ((selectOptions as T[]) || []).filter((x) => !(entities as []).some((y) => x[valueKey] === y[valueKey]));
  }, [selectOptions, useChipsProps]);

  const handleInputChange = useCallback((value: string): void => {
    setInput(value);
  }, []);

  return { allowMenuOpen, input, filteredOptions, chipsHook, handleInputChange };
};
