import {
  ButtonIdModel,
  ButtonListId,
  ButtonProps,
  ErrorModel,
  IdModelBase,
  isNullOrWhiteSpace,
  ModalIdModel,
  ModalProps,
  PlaceholderContentIdModel,
  PlaceholderContentProps,
  SearchIdModel,
  SearchProps,
  ToolbarProps,
} from "@q4/nimbus-ui";
import type { BaseComponentProps, TableProps } from "@q4/nimbus-ui";
import type { GridApi } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import type { Dispatch, MutableRefObject, ReactNode, SetStateAction } from "react";
import type { AdminUserContextState } from "../../contexts/adminUser/adminUser.definition";
import type { Entity, EntityBase, EntityModel } from "../../definitions/entity.definition";
import type { EditNotificationsHookModel } from "../../hooks/useEditNotifications/useEditNotifications.definition";
import type { LegacyServiceHookModel, ServiceHookModel } from "../../hooks/useService/useService.definition";
import type { TableSelectFilter } from "../../hooks/useTableFilter/useTableFilter.definition";
import { Conference } from "../../services/conference/conference.model";
import type { ServiceBase } from "../../services/serviceBase/serviceBase.model";
import { DeleteConfirmationMessageIdModel } from "../deleteConfirmationMessage/deleteConfirmationMessage.definition";

export type EntityTableRef = {
  gridApi: GridApi;
  triggerEditNotification: EditNotificationsHookModel["triggerEditNotification"];
};

export type EntityTableTableProps = Omit<TableProps, "rowData" | "className" | "loading" | "onRowClicked" | "onGridReady">;

export type EntityTableRequiredFormKey = {
  key: string;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: (value: any) => boolean;
};

export interface EntityTableProps<T extends EntityBase> extends Pick<BaseComponentProps, "id"> {
  channelName?: string;
  entity: Entity;
  customEntityTitle?: string;
  editForm: ModalProps["children"];
  icon: string;
  modalProps?: Omit<Partial<ModalProps>, "children">;
  requiredEntityKeys?: string[];
  requiredFormEntityKeys?: EntityTableRequiredFormKey[];
  tableProps: EntityTableTableProps;
  toolbarProps?: Omit<ToolbarProps, "autoRowProps">;
  exportButton?: ReactNode;
  selectFilters?: TableSelectFilter[];
  searchProps?: SearchProps;
  hideSearch?: boolean;
  saveRequestParams?: Partial<T>;
  useService: LegacyServiceHookModel<T> | ServiceHookModel<T, ServiceBase<T>>;
  user: AdminUserContextState["user"];
  entityItemReadOnly?: (current: T) => boolean;
  entityModel: EntityModel<T>;
  ref?: MutableRefObject<EntityTableRef>;
  newDefaultEntity?: T;
  loading?: boolean;
  addNewButtonProps?: Partial<ButtonProps>;
  placeholderContentProps?: Partial<PlaceholderContentProps>;
  formErrors?: Record<string, ErrorModel>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  entityDeleteConfirmationTitle?: string;
  entityDeleteConfirmationMessage?: string;
  modifyData?: (data: T) => T;
  conference?: Conference;
  beforeSaveCallback?: (data: T) => void;
}

export enum EntityTableClassName {
  Base = "entity-table",
  Toolbar = "entity-table_toolbar",
  ToolbarGroup = "entity-table_toolbar-group",
  Content = "entity-table_content",
  Filter = "entity-table_filters",
  Placeholder = "entity-table_placeholder",
  Table = "entity-table_table",
  ImageCell = "entity-table_table-image-cell",
  IconCell = "entity-table_table-icon-cell",
  DeleteButton = "entity-table_table-delete-button",
  AddNewButton = "entity-table_new-button",
}

export enum EntityModalClassName {
  Base = "entity-modal",
  DeleteButton = "entity-modal_delete-button",
}

export class EntityTableIdModel extends IdModelBase {
  addNew: ButtonIdModel;
  deleteConfirmationMessageIdModel: DeleteConfirmationMessageIdModel;
  modal: ModalIdModel;
  modalDelete: ButtonIdModel;
  modalCancel: ButtonIdModel;
  modalSave: ButtonIdModel;
  placeholder: PlaceholderContentIdModel;
  placeholderAddNew: ButtonIdModel;
  search: SearchIdModel;
  table: string;
  tableDelete: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.addNew = new ButtonIdModel(`${this.id}AddNewButton`);
    this.deleteConfirmationMessageIdModel = new DeleteConfirmationMessageIdModel(`${this.id}DeleteMessage`);
    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.modalDelete = new ButtonIdModel(`${this.modal.id}DeleteButton`);
    this.modalCancel = new ButtonIdModel(`${this.modal.id}CancelButton`);
    this.modalSave = new ButtonIdModel(`${this.modal.id}SaveButton`);
    this.placeholder = new PlaceholderContentIdModel(`${this.id}PlaceholderContent`);
    this.placeholderAddNew = new ButtonIdModel(`${this.placeholder.id}AddNewButton`);
    this.search = new SearchIdModel(`${this.id}Search`);
    this.table = `${this.id}Table`;
    this.tableDelete = new ButtonListId(this.table, "Row-", "-DeleteButton");
  }
}

export interface EntityTableCellRendererProps {
  handleModalOpen?: () => void;
  handleModalClose?: () => void;
}
