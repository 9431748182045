import { isEmpty, isNil } from "@q4/nimbus-ui";
import { useEffect, useMemo } from "react";
import type { AutoFetchHookProps } from "./useAutoFetch.definition";

export const useAutoFetch = <TEntity, TId>(props: AutoFetchHookProps<TEntity, TId>): void => {
  const { autoFetchData: autoFetchDataProp, data, param, fetch, fetchBy, setEntity, setEntities } = props;

  const autoFetchData = useMemo(() => (isNil(autoFetchDataProp) ? true : autoFetchDataProp), [autoFetchDataProp]);

  useEffect(() => {
    if (!autoFetchData) return;

    if (isEmpty(param)) {
      !isEmpty(fetch) && fetch();
      return;
    }
    !isEmpty(fetchBy) && fetchBy(param);
  }, [autoFetchData, param, fetch, fetchBy]);

  useEffect(() => {
    if (autoFetchData) return;

    if (Array.isArray(data)) {
      !isEmpty(setEntities) && setEntities(data);
      return;
    }
    !isEmpty(setEntity) && setEntity(data);
  }, [autoFetchData, data, setEntities, setEntity]);
};
