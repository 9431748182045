import { Textbox, Textarea } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import { Answer } from "../../../../services/answer/answer.model";
import { QuestionType } from "../../../../services/questionGroup/questionGroup.model";
import { QuestionsFormTextIdModel, QuestionsFormTextProps } from "./questionsFormText.definition";

export function QuestionsFormText(props: QuestionsFormTextProps): JSX.Element {
  const { id, question, answer: answerProp, onChange } = props;
  const { answer_type, place_holder, max_length } = question;
  const answer = answerProp as string;
  const idModel = useMemo(() => new QuestionsFormTextIdModel(id), [id]);

  const handleAnswerChange = useCallback(
    (value: string) => {
      onChange(
        new Answer({
          _question: question._id,
          answer: value,
        })
      );
    },
    [onChange, question]
  );

  return (
    <div id={idModel.id}>
      {answer_type.field_type === QuestionType.ShortAnswer ? (
        <Textbox
          id={idModel.shortAnswer.id}
          value={answer}
          onChange={handleAnswerChange}
          maxLength={max_length}
          placeholder={place_holder}
        />
      ) : (
        <Textarea
          id={idModel.longAnswer.id}
          value={answer}
          onChange={handleAnswerChange}
          maxLength={max_length}
          placeholder={place_holder}
        />
      )}
    </div>
  );
}

export default memo(QuestionsFormText);
