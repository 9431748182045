import {
  ButtonIdModel,
  ComboBoxIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  ModalIdModel,
  ScrollbarsIdModel,
} from "@q4/nimbus-ui";
import type { ModalProps } from "@q4/nimbus-ui";
import type { AttendeesHookModel } from "../../../../../../../../hooks/useAttendees/useAttendees.definition";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { Presentation } from "../../../../../../../../services/presentation/presentation.model";

export interface PresentationManageAttendeesModalProps extends Omit<ModalProps, "title"> {
  presentation: Presentation;
  useAttendees: AttendeesHookModel;
}

export enum PresentationManageAttendeesAction {
  Add,
  Remove,
}

export interface PresentationManageAttendeesChange {
  _id: AttendeeViewModel["_id"];
  action: PresentationManageAttendeesAction;
}

export enum PresentationManageAttendeesModalClassName {
  Base = "presentation-manage-attendees-modal",
  Scrollbar = "presentation-manage-attendees-modal_scrollbar",
  Subtitle = "presentation-manage-attendees-modal_subtitle",
}

export class PresentationManageAttendeesIdModel extends IdModelBase {
  modal: ModalIdModel;
  attendees: ComboBoxIdModel;
  cancel: ButtonIdModel;
  save: ButtonIdModel;
  scrollbar: ScrollbarsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.modal = new ModalIdModel(this.id);
    this.attendees = new ComboBoxIdModel(`${this.id}Attendees`);
    this.cancel = new ButtonIdModel(`${this.id}Cancel`);
    this.save = new ButtonIdModel(`${this.id}Save`);
    this.scrollbar = new ScrollbarsIdModel(`${this.id}Scrollbar`);
  }
}
