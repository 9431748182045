import { BaseComponentProps, ButtonIdModel, IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import type { ApiResponse } from "../../../../../services/api/api.definition";
import type { Company } from "../../../../../services/company/company.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import { FaqModalIdModel } from "../faqModal/faqModal.definition";

export interface LoginFormProps extends BaseComponentProps {
  conference: Conference;
  company: Company;
  loginResponse: LoginApiResponse;
  loading: boolean;
  showPassword: boolean;
  onLogin: (email: string, password: string, passcode: string) => Promise<void>;
  onRequest: (email: string) => Promise<void>;
}

export type LoginApiResponse = ApiResponse<null>;

export enum LoginFormClassName {
  Base = "login-form",
  Button = "login-form_button",
  ButtonWithBrandingModifier = "login-form_button--branding",
  Instructions = "login-form_instructions",
  Message = "login-form_message",
  ToastHeader = "login-form_toast-header",
  ToastContentRow = "login-form_toast-content-row",
}

export class LoginFormIdModel extends IdModelBase {
  email: TextboxIdModel;
  password: TextboxIdModel;
  passcode: TextboxIdModel;
  requestCode: ButtonIdModel;
  submitCode: ButtonIdModel;
  submitPassword: ButtonIdModel;
  faq: FaqModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.email = new TextboxIdModel(`${this.id}Email`);
    this.password = new TextboxIdModel(`${this.id}Password`);
    this.passcode = new TextboxIdModel(`${this.id}Passcode`);
    this.requestCode = new ButtonIdModel(`${this.id}RequestCode`);
    this.submitCode = new ButtonIdModel(`${this.id}SubmitCode`);
    this.submitPassword = new ButtonIdModel(`${this.id}SubmitPassword`);
    this.faq = new FaqModalIdModel(`${this.id}Faq`);
  }
}
