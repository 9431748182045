import { BaseComponentProps, ErrorModel, FormIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { Answer } from "../../services/answer/answer.model";
import { Question } from "../../services/questionGroup/questionGroup.model";

export enum QuestionsFormClassName {
  Base = "questions-form",
  Label = "questions-form_label",
}
export interface QuestionsFormProps extends BaseComponentProps {
  questions: Question[];
  answers: Answer[];
  onChange: (answer: Answer) => void;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
}

export interface QuestionsFormChildrenProps extends BaseComponentProps {
  question: Question;
  answer: Answer["answer"];
  onChange: (answer: Answer) => void;
  setFormErrors?: Dispatch<SetStateAction<Record<string, ErrorModel>>>;
  indexNumber?: number;
}

export class QuestionsFormIdModel extends IdModelBase {
  form: FormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.form = new FormIdModel(`${this.id}Form`);
  }
}
