import { BaseComponentProps, IdModelBase, InfoIconIdModel, isNullOrWhiteSpace, TextareaIdModel } from "@q4/nimbus-ui";
import { FieldMaxLength } from "../../../../../../utils/form/form.utils";
import type { ConferenceEditState } from "../../conferenceEdit.definition";

export interface ConferenceAdditionalInformationEditProps extends BaseComponentProps {
  conference: ConferenceEditState;
  onChange: (
    state: Partial<Pick<ConferenceEditState, "description" | "registration_description" | "info" | "disclaimer">>
  ) => void;
}

export enum AdditionalInformationClassName {
  Base = "additional-information",
  RegistrationDescription = "additional-information_registration-description",
  ConferenceDescription = "additional-information_conference-description",
  SupplementaryInfo = "additional-information_supplementary-info",
  LoginDisclaimer = "additional-information_login-disclaimer",
}

export class ConferenceAdditionalInformationEditIdModel extends IdModelBase {
  registrationDescription: TextareaIdModel;
  registrationDescriptionInfoIcon: InfoIconIdModel;
  conferenceDescription: TextareaIdModel;
  supplementaryInfo: TextareaIdModel;
  loginDisclaimer: TextareaIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.registrationDescription = new TextareaIdModel(`${this.id}RegistrationDescriptionTextarea`);
    this.registrationDescriptionInfoIcon = new InfoIconIdModel(`${this.id}RegistrationDescriptionInfoIcon`);
    this.conferenceDescription = new TextareaIdModel(`${this.id}ConferenceDescriptionTextarea`);
    this.supplementaryInfo = new TextareaIdModel(`${this.id}SupplementaryInfoTextarea`);
    this.loginDisclaimer = new TextareaIdModel(`${this.id}LoginDisclaimerTextarea`);
  }
}

type additionalInformationField = {
  key: string;
  label: string;
  tooltip: string;
  id: keyof ConferenceAdditionalInformationEditIdModel;
  tooltipId?: keyof ConferenceAdditionalInformationEditIdModel;
  className: AdditionalInformationClassName;
  value: keyof ConferenceEditState;
  maxLength?: number;
};

export const additionalInformationFields: additionalInformationField[] = [
  {
    key: "Registration Description",
    label: "Registration Description",
    tooltip: "Displayed beneath the Conference Registration Title",
    id: "registrationDescription",
    tooltipId: "registrationDescriptionInfoIcon",
    className: AdditionalInformationClassName.RegistrationDescription,
    value: "registration_description",
    maxLength: 1000,
  },
  {
    key: "Conference Lobby Description",
    label: "Conference Lobby Description",
    tooltip: "Displayed at top of the Lobby",
    id: "conferenceDescription",
    className: AdditionalInformationClassName.ConferenceDescription,
    value: "description",
    maxLength: FieldMaxLength.LobbyDescription,
  },
  {
    key: "Info",
    label: "Conference Lobby Supplementary Information",
    tooltip: "Displayed in the Lobby Footer",
    id: "supplementaryInfo",
    className: AdditionalInformationClassName.SupplementaryInfo,
    value: "info",
    maxLength: FieldMaxLength.LobbySupplementaryInfo,
  },
  {
    key: "Login Disclaimer",
    label: "Login Disclaimer",
    tooltip: "Displayed on the Login Page",
    id: "loginDisclaimer",
    className: AdditionalInformationClassName.LoginDisclaimer,
    value: "disclaimer",
    maxLength: FieldMaxLength.LoginPageDisclaimer,
  },
];
