import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import config from "../../config/config";
import {
  AttendeeProfileRoute,
  LoginRoute,
  RegistrationRoute,
  RoutePath,
  RoutePathIdLabel,
} from "../../configurations/navigation.configuration";
import { Company, CompanyRouteParams } from "../../services/company/company.model";
import { Conference } from "../../services/conference/conference.model";
import { Presentation } from "../../services/presentation/presentation.model";
import { SessionEventType } from "../../services/session/session.model";
import { AgendaSession } from "../../views/public/itinerary/components/agenda/agenda.definition";

export function getCompanyRoute(company: string, customPath: string): string {
  if (!isNullOrWhiteSpace(customPath)) {
    return RoutePath.Custom.replace(RoutePathIdLabel.CustomCompany, customPath);
  }

  if (!isNullOrWhiteSpace(company)) {
    return RoutePath.Company.replace(RoutePathIdLabel.Company, company);
  }

  return null;
}

export function getConferenceRoute(company: string, customPath: string, conferencePath: string): string {
  if (isNullOrWhiteSpace(conferencePath)) return null;

  const companyRoute = getCompanyRoute(company, customPath);
  if (isNullOrWhiteSpace(companyRoute)) return null;

  return `${companyRoute}/${conferencePath}`;
}

export function isAdminRoute(): boolean {
  if (isNullOrWhiteSpace(window?.location?.pathname)) return false;
  return new RegExp(`^${RoutePath.AdminHome}`, "i").test(window.location.pathname);
}

export function isLoginRoute(): boolean {
  if (isNullOrWhiteSpace(window?.location?.pathname)) return false;
  const loginRoutes = [
    RoutePath.CompanyLogin.replace(RoutePathIdLabel.Company, ".+"),
    RoutePath.CustomLogin.replace(RoutePathIdLabel.CustomCompany, ".+"),
    RoutePath.ConferenceLogin.replace(RoutePathIdLabel.Company, ".+").replace(RoutePathIdLabel.ConferencePath, ".+"),
    RoutePath.ConferenceLoginWithCustomCompany.replace(RoutePathIdLabel.CustomCompany, ".+").replace(
      RoutePathIdLabel.ConferencePath,
      ".+"
    ),
  ];
  return loginRoutes.some((x) => new RegExp(`^${x}`, "i").test(window.location.pathname));
}

export function isLocalhost(): boolean {
  const location = isNullOrWhiteSpace(window?.location?.hostname) ? new URL(config.app.url) : window.location;
  return _testLocalhost(location?.hostname);
}

export function getFullLoginUrl(company: Company, conference: Conference, showLocal = false): string {
  if (isEmpty(company)) return null;

  const domain = !isNullOrWhiteSpace(company.custom_domain) ? `//${company.custom_domain}` : config.app.url;
  const path = getLoginUrl(company.url_suffix, company.custom_path, conference?.Path);

  const fullPath = isLocalhost() && showLocal ? `${window.location.origin}${path}` : `${domain}${path}`;

  return isNullOrWhiteSpace(path) ? null : fullPath;
}

export function getFullConferenceUrl(company: Company, conference: Conference, showLocal = false): string {
  if (isEmpty(company)) return null;

  const domain = !isNullOrWhiteSpace(company.custom_domain) ? `//${company.custom_domain}` : config.app.url;
  const path = getLobbyUrl(company.url_suffix, company.custom_path, conference?.Path);

  const fullPath = isLocalhost() && showLocal ? `${window.location.origin}${path}` : `${domain}${path}`;

  return isNullOrWhiteSpace(path) ? null : fullPath;
}

export function getProfileUrl(company: Company, conference: Conference, showLocal = false): string {
  if (isEmpty(company)) return null;
  return `${getFullConferenceUrl(company, conference, showLocal)}${AttendeeProfileRoute}`;
}

export function getLobbyUrl(
  urlSuffix: Company["url_suffix"],
  customPath: Company["custom_path"],
  conferencePath: Conference["Path"]
): string {
  if (isNullOrWhiteSpace(customPath) && isNullOrWhiteSpace(urlSuffix)) return null;

  return isNullOrWhiteSpace(conferencePath)
    ? _getCompanyUrl(urlSuffix, customPath, RoutePath.Company, RoutePath.Custom)
    : _getConferenceUrl(
        urlSuffix,
        customPath,
        conferencePath,
        RoutePath.ConferenceLobby,
        RoutePath.ConferenceLobbyWithCustomCompany
      );
}

export function getLoginUrl(
  urlSuffix: Company["url_suffix"],
  customPath: Company["custom_path"],
  conferencePath: Conference["Path"]
): string {
  if (isNullOrWhiteSpace(customPath) && isNullOrWhiteSpace(urlSuffix)) return null;
  if (isNullOrWhiteSpace(conferencePath))
    return `${_getCompanyUrl(urlSuffix, customPath, RoutePath.Company, RoutePath.Custom)}${LoginRoute}`;

  return isNullOrWhiteSpace(customPath)
    ? getRoute(
        RoutePath.ConferenceLogin,
        { label: RoutePathIdLabel.Company, value: urlSuffix },
        { label: RoutePathIdLabel.ConferencePath, value: conferencePath }
      )
    : getRoute(
        RoutePath.ConferenceLoginWithCustomCompany,
        { label: RoutePathIdLabel.CustomCompany, value: customPath },
        { label: RoutePathIdLabel.ConferencePath, value: conferencePath }
      );
}

export function getPublicLinkUrl(linkId: string): string {
  if (isNullOrWhiteSpace(linkId)) return null;
  const domain = isLocalhost() ? "http://localhost:3000" : config.app.url;
  const relativeUrl = getRoute(RoutePath.LinkPath, { label: RoutePathIdLabel.LinkId, value: linkId });
  return `${domain}${relativeUrl}`;
}

export function getLobbySessionUrl(
  sessionType: SessionEventType,
  company: CompanyRouteParams,
  conferencePath: Conference["Path"],
  sessionId: AgendaSession["_id"]
): string {
  if (
    isNullOrWhiteSpace(sessionId) ||
    isNullOrWhiteSpace(sessionType) ||
    isEmpty(company) ||
    isNullOrWhiteSpace(conferencePath)
  )
    return null;

  const routes: { [key: string]: [string, string, RoutePathIdLabel] } = {
    [SessionEventType.Meeting]: [
      RoutePath.ConferenceMeetingWithId,
      RoutePath.ConferenceCustomCompanyMeetingWithId,
      RoutePathIdLabel.SessionId,
    ],
    [SessionEventType.Presentation]: [
      RoutePath.ConferencePresentationWithId,
      RoutePath.ConferenceCustomCompanyPresentationWithId,
      RoutePathIdLabel.SessionId,
    ],
    [SessionEventType.OnDemandPresentation]: [
      RoutePath.ConferenceOnDemandWithId,
      RoutePath.ConferenceCustomCompanyOnDemandWithId,
      RoutePathIdLabel.SessionId,
    ],
  };

  const sessionRoute = routes[sessionType];

  if (isEmpty(sessionRoute)) return null;

  const [regularRoute, customRoute, routeLabel] = sessionRoute;

  const route = _getConferenceUrl(company.url_suffix, company.custom_path, conferencePath, regularRoute, customRoute);
  if (isNullOrWhiteSpace(route)) return null;

  return getRoute(route, { label: routeLabel, value: sessionId });
}

export function getLobbyPresentationUrl(
  urlSuffix: Company["url_suffix"],
  customPath: Company["custom_path"],
  id: Presentation["_id"],
  conferenceId: Conference["path"]
): string {
  if (isNullOrWhiteSpace(id)) return null;

  const route = isNullOrWhiteSpace(conferenceId)
    ? _getCompanyUrl(urlSuffix, customPath, RoutePath.CompanyPresentationWithId, RoutePath.CustomPresentationWithId)
    : _getConferenceUrl(
        urlSuffix,
        customPath,
        conferenceId,
        RoutePath.ConferencePresentationWithId,
        RoutePath.ConferenceCustomCompanyPresentationWithId
      );
  if (isNullOrWhiteSpace(route)) return null;

  return getRoute(route, { label: RoutePathIdLabel.SessionId, value: id });
}

export function getRegisterUrl(company: Company, conferencePath: Conference["Path"], showLocal = false): string {
  if (isEmpty(company)) return null;

  return `${getFullConferenceUrl(company, null, showLocal)}${RegistrationRoute}/${conferencePath}`;
}

export function getRoute(route: string, ...params: { label: RoutePathIdLabel; value: string }[]): string {
  if (isEmpty(params)) return null;

  return params.reduce((route, param) => {
    return isEmpty(param) ? route : route.replace(param.label, param.value);
  }, route ?? "");
}

function _getCompanyUrl(urlSuffix: string, customPath: string, companyRoute: string, customRoute: string): string {
  if (
    (isNullOrWhiteSpace(companyRoute) || isNullOrWhiteSpace(urlSuffix)) &&
    (isNullOrWhiteSpace(customRoute) || isNullOrWhiteSpace(customPath))
  ) {
    return null;
  }

  return isNullOrWhiteSpace(customPath)
    ? companyRoute.replace(RoutePathIdLabel.Company, urlSuffix)
    : customRoute.replace(RoutePathIdLabel.CustomCompany, customPath);
}

function _getConferenceUrl(
  urlSuffix: string,
  customPath: string,
  conferenceId: string,
  companyRoute: string,
  customRoute: string
): string {
  if (
    isNullOrWhiteSpace(conferenceId) &&
    (isNullOrWhiteSpace(companyRoute) || isNullOrWhiteSpace(urlSuffix)) &&
    (isNullOrWhiteSpace(customRoute) || isNullOrWhiteSpace(customPath))
  ) {
    return null;
  }

  return isNullOrWhiteSpace(customPath)
    ? getRoute(
        companyRoute,
        { label: RoutePathIdLabel.Company, value: urlSuffix },
        { label: RoutePathIdLabel.ConferencePath, value: conferenceId }
      )
    : getRoute(
        customRoute,
        { label: RoutePathIdLabel.CustomCompany, value: customPath },
        { label: RoutePathIdLabel.ConferencePath, value: conferenceId }
      );
}

function _testLocalhost(hostname: string): boolean {
  return (
    !isNullOrWhiteSpace(hostname) &&
    (hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(hostname))
  );
}
