import "./registrationHeader.component.scss";
import { Text, TextPreset } from "@q4/nimbus-ui";
import React, { useMemo } from "react";
import { getConferenceDateLabel, htmlParse } from "../../../../../utils";
import {
  RegistrationHeaderClassName,
  RegistrationHeaderIdModel,
  RegistrationHeaderProps,
} from "./registrationHeader.definition";

export const RegistrationHeader = ({
  id,
  conference,
  logo,
  details,
  isAttendeeProfile,
}: RegistrationHeaderProps): JSX.Element => {
  const idModel = useMemo(() => new RegistrationHeaderIdModel(id), [id]);

  return (
    <div id={idModel.id} className={RegistrationHeaderClassName.Base}>
      <div className={RegistrationHeaderClassName.Section}>
        {logo && (
          <div className={RegistrationHeaderClassName.Logo}>
            <img src={logo} alt={"Company Logo"} />
          </div>
        )}
        <div className={RegistrationHeaderClassName.TitleContainer}>
          <Text id={idModel.title.id} className={RegistrationHeaderClassName.Title} preset={TextPreset.DisplayLarge}>
            {conference?.title}
          </Text>
          <Text id={idModel.date.id} className={RegistrationHeaderClassName.Date} preset={TextPreset.Paragraph}>
            {getConferenceDateLabel(conference)}
          </Text>
          <Text
            id={idModel.description.id}
            className={RegistrationHeaderClassName.Description}
            preset={TextPreset.Paragraph}
          >
            {htmlParse(isAttendeeProfile ? conference?.description : conference?.registration_description)}
          </Text>
        </div>
        <div className={RegistrationHeaderClassName.Details}>
          <Text id={idModel.details.id} preset={TextPreset.Title}>
            {details}
          </Text>
        </div>
      </div>
    </div>
  );
};
