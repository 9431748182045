import { AnchorListId, BaseComponentProps, gux, IdModelBase, isNullOrWhiteSpace, TextListId } from "@q4/nimbus-ui";
import moment from "moment";
import type { CSSProperties } from "react";
import { ItineraryDayFormat } from "../../../../../const";
import type { AttendeeViewModel } from "../../../../../services/attendee/attendee.model";
import type { CompanyRouteParams } from "../../../../../services/company/company.model";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { Meeting } from "../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../services/presentation/presentation.model";
import { Speaker } from "../../../../../services/speaker/speaker.model";
import type { AgendaSession, AgendaSessionLabel } from "../agenda/agenda.definition";

export const ItineraryPdfTimeRangeFormat = "h:mm";
export const ItineraryPdfTimeMeridiemFormat = "A";
export const ItineraryPdfTimeZoneFormat = "z";
export const ItineraryPdfDisclaimer =
  "Please note: This schedule is subject to change. All participants must check-in every day at the One-on-One Desk.";

export class ItineraryPdfAgenda {
  _id: AgendaSession["_id"];
  title: AgendaSession["Title"];
  link: AgendaSession["GoUrl"];
  presentation_type?: AgendaSession["presentation_type"];
  upcoming: boolean;
  label: AgendaSessionLabel;
  day: string;
  time: string;
  meridiem: string;
  timezone: string;
  speakers: Speaker[];
  labelOverride?: AgendaSession["labelOverride"];
  attendees: AttendeeViewModel[];
  vendor: AgendaSession["vendor"];
  vendor_override: Meeting["vendor_override"];
  speakerLink: AgendaSession["speaker_link"];
  speakerNotes: AgendaSession["speaker_notes"];
  speakerTime: AgendaSession["speaker_minutes"];
  roomLocation: AgendaSession["roomLocation"];
  isUserSpeaker: boolean;
  linkId?: AgendaSession["linkId"];
  description?: AgendaSession["description"];
  session_type?: AgendaSession["session_type"];

  constructor(agenda: AgendaSession, timeZone: Conference["time_zone"]) {
    const {
      _id,
      attendees,
      start_date,
      end_date,
      GoUrl,
      sessionLabel,
      speakers,
      Title,
      vendor,
      labelOverride,
      vendor_override,
      speaker_link: speakerLink,
      speaker_notes: speakerNotes,
      speaker_minutes: speakerTime,
      isUserSpeaker,
      presentation_type,
      linkId,
      roomLocation,
      session_type,
      description,
    } = agenda;

    this._id = _id;
    this.label = sessionLabel;
    this.title = Title;
    this.day = start_date?.tz(timeZone).format(ItineraryDayFormat);
    this.time = `${start_date?.tz(timeZone).format(ItineraryPdfTimeRangeFormat)} - ${end_date
      ?.tz(timeZone)
      .format(ItineraryPdfTimeRangeFormat)}`;
    this.meridiem = start_date?.tz(timeZone).format(ItineraryPdfTimeMeridiemFormat);
    this.timezone = start_date?.tz(timeZone).format(ItineraryPdfTimeZoneFormat);
    this.upcoming = end_date?.isAfter(moment());
    this.link = GoUrl;
    this.speakers = speakers;
    this.attendees = attendees;
    this.labelOverride = labelOverride;
    this.vendor = vendor;
    this.vendor_override = vendor_override;
    this.speakerLink = speakerLink;
    this.speakerNotes = speakerNotes;
    this.speakerTime = speakerTime;
    this.isUserSpeaker = isUserSpeaker;
    this.presentation_type = presentation_type;
    this.linkId = linkId;
    this.roomLocation = roomLocation;
    this.session_type = session_type;
    this.description = description;
  }
}

export interface ItineraryPdfProps extends BaseComponentProps {
  user?: AttendeeViewModel;
  conference?: Conference;
  presentations?: Presentation[];
  meetings?: Meeting[];
  attendees?: AttendeeViewModel[];
  company: CompanyRouteParams;
}

interface ItineraryPdfStyleGroups {
  base: CSSProperties;
  header: CSSProperties;
  subheader: CSSProperties;
  logo: CSSProperties;
  separator: CSSProperties;
  details: CSSProperties;
  title: CSSProperties;
  description: CSSProperties;
  conferenceDate: CSSProperties;
  address: CSSProperties;
  link: CSSProperties;
  attendee: CSSProperties;
  attendeeInfo: CSSProperties;
  attendeeCompany: CSSProperties;
  firstAttendee: CSSProperties;
  group: CSSProperties;
  date: CSSProperties;
  allTimes: CSSProperties;
  table: CSSProperties;
  tableHeader: CSSProperties;
  tableRow: CSSProperties;
  headerCell: CSSProperties;
  bodyCell: CSSProperties;
  timeCell: CSSProperties;
  timeCellHeader: CSSProperties;
  timeCellContent: CSSProperties;
  timeCellZone: CSSProperties;
  timeCellLocation: CSSProperties;
  titleCell: CSSProperties;
  corporateParticipants: CSSProperties;
  meetingCell: CSSProperties;
  vendorOverrideDialInGrid: CSSProperties;
  vendorOverrideDialInCell: CSSProperties;
  meetingCellContent: CSSProperties;
  locationCellHeader: CSSProperties;
  locationCell: CSSProperties;
  attendeeCell: CSSProperties;
  typeCell: CSSProperties;
  coordinators: CSSProperties;
  disclaimer: CSSProperties;
  flexBetween: CSSProperties;
  keyline: CSSProperties;
  label: CSSProperties;
  dialGroupContainer: CSSProperties;
  dialNumbers: CSSProperties;
  dialSubheading: CSSProperties;
  flexRow: CSSProperties;
  pageBreakAvoid: CSSProperties;
  wrapText: CSSProperties;
}

const BaseFontSize = 13;
const FontFamily = "'Open Sans', sans-serif";
const SeparatorColor = "#E5E5E5";
const HighlightColor = "#0F5CA3";
const TableRowColor = "#ECECEC";
const TimeColumnBackgroundColor = "#E3E3E3";
const MeetingCellBackgroundColor = "#D8D8D4";
const LinkLeftBorderColor = "#DCDCDC";
const spaceBetween = "space-between";
const whiteBorder = "5px solid #fff";

export const ItineraryLinkDecorator = "»";

export const styleGroups: ItineraryPdfStyleGroups = {
  base: {
    fontFamily: FontFamily,
    fontWeight: 300,
    fontSize: BaseFontSize,
  },
  header: {
    marginBottom: gux(3),
  },
  subheader: {
    display: "flex",
    justifyContent: spaceBetween,
    alignItems: "center",
    marginBottom: gux(3),
  },
  logo: {
    maxWidth: 160,
  },
  separator: {
    background: SeparatorColor,
    width: 2,
    display: "inline-block",
    marginRight: gux(3),
  },
  details: {},
  title: {
    fontWeight: 400,
    fontSize: "1.75em",
    marginBottom: gux(),
  },
  description: {
    marginTop: gux(),
    marginBottom: gux(3),
    lineHeight: 1.5,
    paddingRight: gux(),
  },
  attendeeInfo: {
    fontSize: "1.4em",
    lineHeight: "25px",
    paddingBottom: gux(),
    marginBottom: 0,
    marginTop: 0,
    borderBottom: `2px solid ${HighlightColor}`,
  },
  attendeeCompany: {
    fontSize: "0.85em",
    lineHeight: "20px",
  },
  conferenceDate: {
    marginTop: gux(0.5),
    marginBottom: gux(0.5),
    fontWeight: 400,
  },
  address: {
    marginTop: gux(0.5),
    marginBottom: gux(2),
  },
  link: {
    color: HighlightColor,
  },
  group: {
    marginBottom: gux(3),
  },
  date: {
    marginTop: 0,
    marginBottom: gux(2),
  },
  allTimes: {
    fontSize: "0.7em",
    lineHeight: 2.5,
  },
  table: {
    fontSize: BaseFontSize,
    borderCollapse: "collapse",
  },
  tableHeader: {
    borderBottom: whiteBorder,
  },
  tableRow: {
    backgroundColor: TableRowColor,
    borderBottom: whiteBorder,
  },
  headerCell: {
    padding: gux(),
    fontWeight: 400,
    fontSize: "0.85em",
    textAlign: "left",
    borderBottom: "2px solid #fff",
    textTransform: "uppercase",
  },
  bodyCell: {
    padding: gux(),
    verticalAlign: "top",
  },
  timeCell: {
    width: 100,
    borderRight: whiteBorder,
    background: TimeColumnBackgroundColor,
    flexShrink: 0,
  },
  timeCellHeader: {
    width: 100,
  },
  timeCellContent: {
    fontSize: "1.1em",
    letterSpacing: 1,
  },
  timeCellZone: {
    fontSize: "0.75em",
  },
  timeCellLocation: {
    marginTop: gux(),
    marginBottom: gux(0.5),
    fontSize: "0.75em",
  },
  titleCell: {
    fontSize: "1.125em",
    flexGrow: 1,
  },
  corporateParticipants: {
    marginBottom: gux(),
  },
  meetingCell: {
    display: "flex",
    justifyContent: "flex-end",
    width: 80,
  },
  meetingCellContent: {
    textTransform: "uppercase",
    borderRadius: 25,
    background: MeetingCellBackgroundColor,
    padding: "3px 9px",
    fontSize: "0.75em",
    alignSelf: "center",
    textAlign: "center",
  },
  vendorOverrideDialInCell: {
    textAlign: "left",
    verticalAlign: "middle",
    marginBottom: gux(),
  },
  vendorOverrideDialInGrid: {
    display: "grid",
    marginTop: gux(),
    gridTemplateColumns: "minmax(100px, 400px) minmax(100px, 400px)",
  },
  locationCellHeader: {
    width: 170,
  },
  locationCell: {
    borderLeft: `1px solid ${LinkLeftBorderColor}`,
    verticalAlign: "middle",
    width: 170,
    flexShrink: 0,
    display: "flex",
  },
  attendeeCell: {
    width: 250,
  },
  typeCell: {
    width: 110,
  },
  attendee: {
    marginTop: gux(),
  },
  firstAttendee: {
    marginTop: 0,
  },
  coordinators: {
    marginTop: gux(4),
  },
  disclaimer: {
    fontSize: "0.75em",
    marginTop: gux(9),
  },
  flexBetween: {
    display: "flex",
    justifyContent: spaceBetween,
  },
  keyline: {
    borderBottom: `1px solid ${SeparatorColor}`,
  },
  label: {
    fontWeight: 400,
  },
  dialGroupContainer: {
    display: "flex",
    marginBottom: "32px",
    justifyContent: spaceBetween,
  },
  dialSubheading: {
    fontWeight: "bold",
  },
  dialNumbers: {
    display: "block",
  },
  flexRow: {
    display: "flex",
    justifyContent: spaceBetween,
  },
  pageBreakAvoid: {
    breakInside: "avoid",
    pageBreakInside: "avoid",
  },
  wrapText: {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    overflow: "hidden",
    whiteSpace: "break-spaces",
  },
};

export const ItineraryPdfId = "ItineraryPdf";

export class ItineraryPdfIdModel extends IdModelBase {
  meeting: TextListId;
  sessionInfo: TextListId;
  vendor: AnchorListId;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.meeting = new TextListId(`${this.id}Meeting`);
    this.sessionInfo = new TextListId(`${this.id}SessionInfo`);
    this.vendor = new AnchorListId(`${this.id}Vendor`);
  }
}
