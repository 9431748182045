import { convertStringToEnum } from "@q4/nimbus-ui";
import { Environment } from "./config.definition";

const base = {
  auth0: {
    domain: process.env.REACT_APP_EVENTS_CONFERENCE_Q4P_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_EVENTS_CONFERENCE_Q4P_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_EVENTS_CONFERENCE_Q4P_AUTH0_AUDIENCE,
  },
  helpCentre: {
    tokenKey: `${convertStringToEnum(Environment, process.env.REACT_APP_ENV)}.helpCentreToken`, // lambda edge looking for "${env}.helpCentreToken" cookie
    domain: process.env.REACT_APP_EVENTS_CONFERENCE_APP_URL.replace(/^https?:\/\//, ""),
    secure: process.env.NODE_ENV === "production",
    url: process.env.REACT_APP_EVENTS_CONFERENCE_HELPCENTRE_APP_URL,
  },
  env: convertStringToEnum(Environment, process.env.REACT_APP_ENV),
  pusher: {
    apiKey: process.env.REACT_APP_PUSHER_API_KEY,
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
  version: process.env.REACT_APP_VERSION,
  s3StaticFiles: "https://q4-touch.s3.amazonaws.com",
};

const apiUrl = process.env.REACT_APP_EVENTS_CONFERENCE_API_URL || "https://api.dev.conference.q4inc.com";

const options = {
  ...base,
  app: {
    url: process.env.REACT_APP_EVENTS_CONFERENCE_APP_URL || "https://dev.conference.q4inc.com",
  },
  api: {
    url: apiUrl,
    publicUrl: `${apiUrl}/public`,
    adminUrl: `${apiUrl}/admin`,
  },
  analytics: {
    posthog: {
      apiKey: "phc_ajYnn5HFwh7DVX8mRqUc9mUdl75tp9AFMX1d99bsI9D",
      hostUrl: "https://app.posthog.com",
    },
  },
};

const config = {
  ...options,
};
export default config;
