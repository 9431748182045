import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  MessageIdModel,
  ToolbarProps,
} from "@q4/nimbus-ui";
import type { ConferencesHookModel } from "../../../../../../hooks/useConferences/useConferences.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";
import { MeetingShellCreatorIdModel } from "./components/meetingShellCreator/meetingShellCreator.definition";
import { MeetingTimeSlotCreatorIdModel } from "./components/meetingTimeSlotCreator/meetingTimeSlotCreator.definition";
import { ScheduledTimeSlotIdModel } from "./components/scheduledTimeSlotView/scheduledTimeSlotView.definition";

export interface MeetingConfigurationProps extends BaseComponentProps {
  conference: Conference;
  toolbarProps: ToolbarProps;
  useConferences: ConferencesHookModel;
}

export enum MeetingConfigurationClassName {
  Base = "meeting-configuration",
  Content = "meeting-configuration-content",
  ContentTabs = "meeting-configuration-content_tabs",
}

export enum MeetingConfigurationTabs {
  ScheduleSetup = "Schedule Setup",
  TimeSlots = "Time Slots",
}

export class MeetingConfigurationIdModel extends IdModelBase {
  container: string;
  shellCreator: MeetingShellCreatorIdModel;
  timeSlotCreator: MeetingTimeSlotCreatorIdModel;
  scheduledTimeSlotView: ScheduledTimeSlotIdModel;
  createNewScheduleMessage: MessageIdModel;
  createNewScheduleCancel: ButtonIdModel;
  createNewScheduleConfirm: ButtonIdModel;
  scheduleSetupTab: string;
  timeSlotsTab: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.container = `${this.id}container`;
    this.shellCreator = new MeetingShellCreatorIdModel(`${this.id}MeetingConfigurationMeetingShellCreator`);
    this.timeSlotCreator = new MeetingTimeSlotCreatorIdModel(`${this.id}MeetingConfigurationMeetingTimeSlotCreator`);
    this.scheduledTimeSlotView = new ScheduledTimeSlotIdModel(`${this.id}MeetingConfigurationScheduledTimeSlotView`);

    this.createNewScheduleMessage = new MessageIdModel(`${this.id}MeetingConfigurationCreateNewScheduleMessage`);
    this.createNewScheduleCancel = new ButtonIdModel(`${this.id}MeetingConfigurationCreateNewScheduleCancel`);
    this.createNewScheduleConfirm = new ButtonIdModel(`${this.id}MeetingConfigurationCreateNewScheduleConfirm`);

    this.scheduleSetupTab = `${this.id}ScheduleTab`;
    this.timeSlotsTab = `${this.id}SlotTab`;
  }
}
