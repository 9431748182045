import { AttendeeViewModel } from "../attendee/attendee.model";
import { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import { Question } from "../questionGroup/questionGroup.model";

export class Answer {
  _id?: string;
  _attendee?: string | AttendeeViewModel;
  _corporate_profile?: string | CorporateProfile;
  _question?: string | Question | Partial<Question>;
  answer: boolean | string | number | string[];
  title?: string;

  constructor(answer: Partial<Answer> | string) {
    if (typeof answer === "string") return;

    Object.assign(this, answer);
  }
}
