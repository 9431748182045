import "./dialInInstructions.component.scss";
import { isEmpty, Collapsable, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useMemo } from "react";
import { LobbyDefaults } from "../../../../../services/conference/conference.model";
import { htmlParse } from "../../../../../utils";
import Section from "../../../../company/lobby/components/section/section.component";
import {
  SectionInnerWidth,
  SectionKeyLine,
  SectionTheme,
} from "../../../../company/lobby/components/section/section.definition";
import { ItineraryClassName } from "../../itinerary.definition";
import {
  DialInInstructionsProp,
  DialInInstructionsIdModel,
  DialInInstructionsClassName,
} from "./dialInInstructions.definition";

const DialInInstructions = (prop: DialInInstructionsProp): JSX.Element => {
  const { config, id } = prop;
  const { disabled, title: titleProp, label: labelProp } = config || {};

  const idModel = useMemo(() => new DialInInstructionsIdModel(id), [id]);
  const showDialInstructions = useMemo(() => !isEmpty(config) && !disabled, [config, disabled]);
  const title = useMemo(
    () => (isNullOrWhiteSpace(titleProp) ? LobbyDefaults.dialInInstructions.title : titleProp),
    [titleProp]
  );
  const label = useMemo(
    () => htmlParse(isNullOrWhiteSpace(labelProp) ? LobbyDefaults.dialInInstructions.label : labelProp),
    [labelProp]
  );

  return (
    <Collapsable collapsed={!showDialInstructions}>
      <Section
        id={idModel.id}
        className={DialInInstructionsClassName.Base}
        theme={SectionTheme.Gradient}
        innerWidth={SectionInnerWidth.Contained}
        keyLine={SectionKeyLine.Both}
      >
        <div className={DialInInstructionsClassName.Header}>
          <div
            id={idModel.title}
            className={`${ItineraryClassName.Subheading} ${ItineraryClassName.HeadingWithFancyModifier}`}
          >
            {title}
          </div>
        </div>
        <div id={idModel.content} className={DialInInstructionsClassName.Content}>
          {label}
        </div>
      </Section>
    </Collapsable>
  );
};

export default memo(DialInInstructions);
