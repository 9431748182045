import "./loginForm.component.scss";
import {
  Anchor,
  AnchorTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  Collapsable,
  Field,
  getClassName,
  InputType,
  isEmpty,
  isNullOrWhiteSpace,
  Text,
  Textbox,
  TextboxTheme,
  TextPreset,
  Toast,
  ToastStatus,
  KeyboardKey,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState, KeyboardEvent } from "react";
import { SupportEmail } from "../../../../../const";
import { UserValidateErrorMessage } from "../../../../../contexts/baseUser/baseUser.definition";
import { htmlParse } from "../../../../../utils";
import FaqModal from "../faqModal/faqModal.component";
import { LoginFormClassName, LoginFormIdModel, LoginFormProps } from "./loginForm.definition";

const LoginForm = (props: LoginFormProps): JSX.Element => {
  const { className, conference, company, id, loading, loginResponse, showPassword, onLogin, onRequest } = props;

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passcode, setPasscode] = useState<string>();

  const idModel = useMemo(() => new LoginFormIdModel(id), [id]);

  const baseClassName = useMemo(
    () => getClassName(LoginFormClassName.Base, [{ condition: isNullOrWhiteSpace(className), falseClassName: className }]),
    [className]
  );

  const buttonClassName = useMemo(
    () =>
      getClassName(LoginFormClassName.Button, [
        {
          condition: !isNullOrWhiteSpace(company?.name),
          trueClassName: LoginFormClassName.ButtonWithBrandingModifier,
        },
      ]),
    [company?.name]
  );

  const emailSent = useMemo(() => !isEmpty(loginResponse) && loginResponse.success, [loginResponse]);

  const invalidConference = useMemo(
    () => loginResponse?.message === UserValidateErrorMessage.UnavailableConference,
    [loginResponse?.message]
  );
  const invalidEmail = useMemo(
    () => loginResponse?.message === UserValidateErrorMessage.EmailInvalid,
    [loginResponse?.message]
  );
  const invalidAttendee = useMemo(
    () => loginResponse?.message === UserValidateErrorMessage.AttendeeInvalid,
    [loginResponse?.message]
  );

  const invalidPassword = useMemo(
    () => loginResponse?.message === UserValidateErrorMessage.EmailPasswordInvalid,
    [loginResponse?.message]
  );

  const collapseRequestElements = useMemo(
    () =>
      (emailSent && invalidPassword) || (emailSent && !invalidPassword) || (!emailSent && invalidPassword) || showPassword,
    [emailSent, invalidPassword, showPassword]
  );

  const collapseSubmitElements = useMemo(() => {
    if (showPassword) return false;
    return !emailSent && !invalidPassword;
  }, [emailSent, invalidPassword, showPassword]);

  const coordinatorEmail = useMemo(() => conference?.coordinator?.email ?? "", [conference?.coordinator?.email]);
  const conferenceName = useMemo(() => conference?.title ?? "", [conference?.title]);

  const renderToastMessage = useMemo((): JSX.Element => {
    const collapseToast = !(invalidConference || invalidAttendee);

    const toastMessage = invalidAttendee ? (
      <>
        {isNullOrWhiteSpace(conference?.login?.error_message) ? (
          <>
            <Text className={LoginFormClassName.ToastHeader} preset={TextPreset.Base}>
              Invalid Email
            </Text>
            <Text preset={TextPreset.Base} className={LoginFormClassName.ToastContentRow}>
              You are currently not part of an open conference. Please contact{" "}
              <Anchor url={`mailto:${SupportEmail}`}>{SupportEmail}</Anchor> for assistance.
            </Text>
          </>
        ) : (
          <Text preset={TextPreset.Base} className={LoginFormClassName.ToastContentRow}>
            {htmlParse(conference?.login?.error_message)}
          </Text>
        )}
      </>
    ) : (
      <>
        <Text className={LoginFormClassName.ToastHeader} preset={TextPreset.Base}>
          No Scheduled Conference
        </Text>
        <Text preset={TextPreset.Base} className={LoginFormClassName.ToastContentRow}>
          The conference(s) you’re accessing is either not open for public yet or has expired. Please contact
          <Anchor url={`mailto:${SupportEmail}`}>{SupportEmail}</Anchor> for assistance.
        </Text>
      </>
    );

    return (
      <Collapsable collapsed={collapseToast}>
        <div className={LoginFormClassName.Message}>
          <Toast type={ToastStatus.Warning} message={toastMessage} closeIcon={""} closeToast={null} />
        </div>
      </Collapsable>
    );
  }, [conference?.login?.error_message, invalidAttendee, invalidConference]);

  const handleLoginClick = useCallback(() => {
    return onLogin(email, password, passcode);
  }, [email, onLogin, passcode, password]);

  const handleRequestClick = useCallback(() => {
    setPasscode("");
    return onRequest(email);
  }, [email, onRequest]);

  const handleOnKeyDown = useCallback(
    (keyEvent: KeyboardEvent) => {
      keyEvent?.key === KeyboardKey.Enter && (collapseRequestElements ? handleLoginClick() : handleRequestClick());
    },
    [collapseRequestElements, handleLoginClick, handleRequestClick]
  );

  const renderInstructions = useMemo((): JSX.Element => {
    return (
      <>
        <Collapsable collapsed={emailSent || invalidConference || showPassword}>
          Please enter your email address to <span>receive</span> your access code to the conference login via{" "}
          <span>your email</span>.
        </Collapsable>
        <Collapsable collapsed={!emailSent}>
          Your access code has been sent to <Anchor url={`mailto:${email}`}>{email}</Anchor>. Please allow up to 5 minutes
          for the email to arrive and then enter the code below.
        </Collapsable>
        <Collapsable collapsed={!showPassword}>Please enter your email and password</Collapsable>
      </>
    );
  }, [email, emailSent, invalidConference, showPassword]);

  const renderExtraFields = useMemo((): JSX.Element => {
    return (
      <>
        <Collapsable collapsed={!showPassword}>
          <Field
            label="Password"
            error={{ visible: invalidPassword, icon: null, message: "Email or password is not valid" }}
          >
            <Textbox
              id={idModel.password?.id}
              value={password}
              inputType={InputType.Password}
              theme={invalidPassword ? TextboxTheme.Spice : null}
              onChange={setPassword}
              onKeyDown={handleOnKeyDown}
            />
          </Field>
        </Collapsable>
        <Collapsable collapsed={(!emailSent && !invalidPassword) || showPassword}>
          <Field label="Access Code" error={{ visible: invalidPassword, icon: null, message: "Access code is not valid" }}>
            <Textbox
              id={idModel.passcode?.id}
              placeholder="Access code here"
              value={passcode}
              inputType={InputType.Text}
              theme={invalidPassword ? TextboxTheme.Spice : null}
              onChange={setPasscode}
              onKeyDown={handleOnKeyDown}
            />
          </Field>
        </Collapsable>
      </>
    );
  }, [emailSent, idModel, passcode, password, showPassword, invalidPassword, handleOnKeyDown]);

  const renderedSubmitButtons = useMemo((): JSX.Element[] => {
    const submitButtonProps = showPassword
      ? {
          id: idModel.submitPassword.id,
          label: "LOGIN",
        }
      : {
          id: idModel.submitCode.id,
          label: "SUBMIT CODE",
        };

    return [
      <Collapsable key="login-form_request-code" collapsed={collapseRequestElements}>
        <Button
          id={idModel.requestCode?.id}
          className={buttonClassName}
          label="REQUEST CODE"
          theme={ButtonTheme.Citrus}
          size={ButtonSize.Large}
          loading={loading}
          onClick={handleRequestClick}
        />
      </Collapsable>,
      <Collapsable key="login-form_submit-code" collapsed={collapseSubmitElements}>
        <Button
          className={buttonClassName}
          theme={ButtonTheme.Citrus}
          size={ButtonSize.Large}
          loading={loading}
          onClick={handleLoginClick}
          {...submitButtonProps}
        />
      </Collapsable>,
    ];
  }, [
    collapseRequestElements,
    collapseSubmitElements,
    showPassword,
    idModel,
    buttonClassName,
    loading,
    handleLoginClick,
    handleRequestClick,
  ]);

  return (
    <div id={idModel.id} className={baseClassName}>
      <div className={LoginFormClassName.Instructions}>{renderInstructions}</div>
      <Field
        label="Email Address"
        error={{ visible: invalidEmail, icon: null, message: "Please enter a valid email address" }}
      >
        <Textbox
          id={idModel.email?.id}
          placeholder="Enter Email"
          value={email}
          theme={invalidEmail || invalidPassword ? TextboxTheme.Spice : null}
          inputType={InputType.Email}
          onChange={setEmail}
          onKeyDown={handleOnKeyDown}
        />
      </Field>
      {renderExtraFields}
      {renderToastMessage}
      {renderedSubmitButtons}
      <Collapsable collapsed={(!emailSent && !invalidPassword) || showPassword}>
        <Anchor onClick={handleRequestClick} theme={AnchorTheme.Rain}>
          Request a New Code
        </Anchor>
      </Collapsable>
      <Collapsable collapsed={!invalidEmail && !invalidPassword && !invalidAttendee && !invalidConference}>
        <FaqModal
          id={idModel.faq?.id}
          clientEmail={coordinatorEmail}
          supportEmail={SupportEmail}
          conferenceName={conferenceName}
          companyName={company?.name}
        >
          Need Help?
        </FaqModal>
      </Collapsable>
    </div>
  );
};

export default memo(LoginForm);
