import { isEmpty } from "@q4/nimbus-ui";
import type { GridApi, GridReadyEvent } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EntityBase } from "../../../definitions/entity.definition";
import { useTableFilter } from "../../useTableFilter/useTableFilter.hook";
import { InitialPageSize } from "./useTable.definition";
import type { TableHookModel, TableHookProps, TablePaginationState } from "./useTable.definition";

export const useTable = <T extends EntityBase>(props: TableHookProps): TableHookModel<T> => {
  const { rowCount } = props;

  const [searchFilter, setSearchFilter] = useState("");

  const [paginationState, setPaginationState] = useState<TablePaginationState>({
    currentPage: 0,
    pageSize: InitialPageSize,
    pageCount: 0,
  });

  const gridApi = useRef<GridApi>();
  const { filterValues, handleFilter, handleClearFilter, doesExternalFilterPass, isExternalFilterPresent } =
    useTableFilter<T>({
      gridApi,
    });

  const showPagination = useMemo(() => rowCount > InitialPageSize, [rowCount]);

  useEffect(() => {
    function handleResize(): void {
      gridApi.current && gridApi.current.sizeColumnsToFit();
    }

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePaginationChange = useCallback(() => {
    if (isEmpty(gridApi?.current)) return;
    const currentPage = gridApi.current.paginationGetCurrentPage();
    const pageSize = gridApi.current.paginationGetPageSize();
    const total = gridApi.current.paginationGetRowCount();

    setPaginationState({
      currentPage,
      pageSize,
      pageCount: Math.ceil(total / pageSize),
    });
  }, []);

  const handleGridReady = useCallback(
    (grid: GridReadyEvent): void => {
      gridApi.current = grid?.api;
      handlePaginationChange();
    },
    [handlePaginationChange]
  );

  const handleSearchQueryClear = useCallback((): void => {
    gridApi.current && gridApi.current.setQuickFilter(null);
  }, []);

  const handleSearchQueryRequest = useCallback((): void => {
    gridApi.current && gridApi.current.setQuickFilter(searchFilter);
  }, [searchFilter]);

  const handleSearchInputChange = useCallback((search: string): void => {
    setSearchFilter(search);
  }, []);

  const handlePageChange = useCallback((page: number): void => {
    gridApi.current && gridApi.current.paginationGoToPage(page);
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number): void => {
    gridApi.current && gridApi.current.paginationSetPageSize(pageSize);
  }, []);

  return {
    gridApi: gridApi.current,
    page: paginationState.currentPage,
    pageCount: paginationState.pageCount,
    pageSize: paginationState.pageSize,
    searchFilter,
    filterValues,
    showPagination,
    doesExternalFilterPass,
    handleClearFilter,
    handleFilter,
    handleGridReady,
    handlePaginationChange,
    handlePageChange,
    handlePageSizeChange,
    handleSearchInputChange,
    handleSearchQueryClear,
    handleSearchQueryRequest,
    isExternalFilterPresent,
  };
};
