import "./availability.component.scss";
import {
  isEmpty,
  Grid,
  GridColumn,
  Text,
  TextPreset,
  ButtonType,
  ButtonTheme,
  Button,
  isNullOrWhiteSpace,
} from "@q4/nimbus-ui";
import type { Moment } from "moment-timezone";
import React, { memo, useMemo } from "react";
import AvailabilityForm from "../../../../../components/availabilityForm/availabilityForm.component";
import { JsonFormLabels } from "../../../../../components/jsonForm/jsonForm.definition";
import { DefaultTimeZone } from "../../../../../const";
import { DateFormat } from "../../../../../definitions/date.definition";
import { useAvailability } from "../../../../../hooks/useAvailability/availabilityHook.component";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { AttendeeType } from "../../../../../services/attendee/attendee.model";
import { ConferenceSchedulerSlot } from "../../../../../services/conference/conference.model";
import { getBackgroundImageUrl } from "../../../../../utils";
import { isCorporateDeadlinePassed, isInvestorDeadlinePassed } from "../../../../../utils/deadline/deadline.utils";
import TimeZoneSelector from "../../../components/timeZoneSelector/timeZoneSelector.component";
import { RegistrationAvailabilityIdModel, RegistrationAvailabilityProps } from "./availability.definition";

const RegistrationAvailability = (props: RegistrationAvailabilityProps): JSX.Element => {
  const { id, conference, registrant, setRegistrant, onStep, isEditMode, disabled } = props;
  const idModel = useMemo(() => new RegistrationAvailabilityIdModel(id), [id]);
  const isCorporate = useMemo(() => registrant?.attendee_type === AttendeeType.Corporate, [registrant?.attendee_type]);
  const isInvestor = useMemo(() => registrant?.attendee_type === AttendeeType.Investor, [registrant?.attendee_type]);

  const isMeetingDeadlinePassed = useMemo(() => {
    if (isInvestor) {
      return isInvestorDeadlinePassed("availability_changes", conference);
    }

    return isCorporateDeadlinePassed("meeting", conference);
  }, [conference, isInvestor]);

  const conferenceTimeZone = useMemo(() => conference?.time_zone ?? DefaultTimeZone, [conference?.time_zone]);
  const userTimeZone = useMemo(
    () => registrant?.time_zone ?? conferenceTimeZone,
    [registrant?.time_zone, conferenceTimeZone]
  );
  const {
    availability,
    days: schedulerDays,
    timeslots,
    handleTimeslotChange,
  } = useAvailability({
    selectedByDefault: true,
    timeZone: userTimeZone,
    availability: registrant?.availability,
    scheduler: conference?.scheduler,
    onAvailabilityChange: handleAvailabilityChange,
  });

  const updateAvailabilityText = useMemo(() => {
    const deadlines = {
      [AttendeeType.Investor]: conference?.investor_deadlines?.availability_changes,
      [AttendeeType.Corporate]: conference?.corporate_deadlines?.meeting,
      [AttendeeType.Internal]: null as Moment,
      [AttendeeType.Guest]: null as Moment,
    };
    if (!isEditMode || !deadlines[registrant.attendee_type]?.isValid()) return "";

    const date = deadlines[registrant.attendee_type].clone().tz(conferenceTimeZone)?.format(DateFormat.FullDateNth);
    return `You will be able to update your availability until ${date}.`;
  }, [conference, registrant, conferenceTimeZone, isEditMode]);

  function handleAvailabilityChange(availability: ConferenceSchedulerSlot[]) {
    setRegistrant((current) => {
      return new RegistrantViewModel({
        ...current,
        availability,
      });
    });
  }

  function handleUserTimeZoneChange(timeZone: string) {
    setRegistrant((current) => {
      return new RegistrantViewModel({
        ...current,
        time_zone: timeZone,
      });
    });
  }

  function handleNextClick() {
    handleAvailabilityChange(availability);
    onStep(true);
  }

  function handleBackClick() {
    onStep(false);
  }

  return (
    <div id={idModel.id} className={"registration-availability"}>
      <Grid>
        <GridColumn className={"registration_column"} width="1-of-1" smallWidth="1-of-1">
          <Text preset={TextPreset.Subheader}>
            {isCorporate
              ? "Select your company's available time slots. This will apply to all attendees."
              : "Select your available time slots."}
            {isEditMode && ` ${updateAvailabilityText}`}
          </Text>
        </GridColumn>
        {isCorporate && !isEmpty(registrant?.corporate_attendees) && (
          <GridColumn className={"registration_column"} width="1-of-2" smallWidth="1-of-1">
            <Text className={"registration-availability_attendee-header"} preset={TextPreset.Subheader}>
              Attendees
            </Text>
            <Grid>
              {registrant?.corporate_attendees.map((attendee) => {
                const { speaker_info, title } = attendee;
                return (
                  <GridColumn
                    key={`registration-availability_attendee-${attendee.email}`}
                    width={"1-of-2"}
                    smallWidth={"1-of-1"}
                  >
                    <div className={"registration-availability_attendee"}>
                      <div className="registration-availability_attendee-section">
                        {!isNullOrWhiteSpace(speaker_info?.image_profile) && (
                          <div className="registration-availability_attendee-avatar">
                            <div style={{ backgroundImage: getBackgroundImageUrl(speaker_info?.image_profile) }} />
                          </div>
                        )}
                        <div className="registration-availability_attendee-info">
                          <Text preset={TextPreset.Subheader}>{attendee.display_name}</Text>
                          {!isNullOrWhiteSpace(title) && <Text preset={TextPreset.Paragraph}>{title}</Text>}
                        </div>
                      </div>
                    </div>
                  </GridColumn>
                );
              })}
            </Grid>
          </GridColumn>
        )}
        <GridColumn className={"registration_column"} width="1-of-1" smallWidth="1-of-1">
          <Text preset={TextPreset.Subheader}>
            Availability
            <TimeZoneSelector
              id={idModel.timeZoneSelector.id}
              selectedTimeZone={userTimeZone}
              conferenceTimeZone={conference?.time_zone}
              anchorMargin
              onSelect={handleUserTimeZoneChange}
            />
          </Text>
        </GridColumn>
      </Grid>
      <div className="registration-availability_table">
        <AvailabilityForm
          timeZone={userTimeZone}
          days={schedulerDays}
          timeslots={timeslots}
          handleTimeslotChange={handleTimeslotChange}
          disabled={disabled || isMeetingDeadlinePassed}
        />
      </div>
      <div className={"registration-availability_navigation"}>
        <Button
          id={idModel.previousStep?.id}
          className={"registration-availability_back"}
          label={JsonFormLabels.PrevStep}
          type={ButtonType.Button}
          theme={ButtonTheme.White}
          onClick={handleBackClick}
        />
        <Button
          id={idModel.nextStep?.id}
          className={"registration-availability_next"}
          label={JsonFormLabels.NextStep}
          type={ButtonType.Submit}
          theme={ButtonTheme.Rain}
          onClick={handleNextClick}
        />
      </div>
    </div>
  );
};

export default memo(RegistrationAvailability);
