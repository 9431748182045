import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import type { Moment } from "moment";
import type { Conference } from "../../../../../services/conference/conference.model";

export interface DetailsProps extends BaseComponentProps {
  conference?: Conference;
  lobbyOpen?: boolean;
  lobbyOpenDate?: Moment;
}

export enum DetailsClassName {
  Base = "details",
  ClosedMessage = "details_closed-message",
}

export class DetailsIdModel extends IdModelBase {
  title: string;
  date: string;
  description: string;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = `${this.id}Title`;
    this.date = `${this.id}Date`;
    this.description = `${this.id}Description`;
  }
}
