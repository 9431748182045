import { isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { PublicRouteBase } from "../../configurations/navigation.configuration";
import { Registrant, RegistrantPostPayload, RegistrantPostPayloadBase } from "../admin/registrant/registrant.model";
import { ApiResponse, AuthType, ContentType } from "../api/api.definition";
import ApiService from "../api/api.service";
import type { ReCaptchaBase, Service } from "../serviceBase/serviceBase.model";
import { mapApiResponse } from "../serviceBase/serviceBase.utils";
import { RegisterCheckEmailsAvailableRequest, RegisterCheckEmailAvailableResponse } from "./register.model";

export default class RegisterService
  implements Omit<Service<Registrant>, "delete" | "get" | "getOfflineData" | "put" | "post">
{
  protected readonly _apiService = new ApiService();
  protected _servicePath: string;
  private _entityModel: new (entity: Partial<Registrant> | string) => Registrant;

  get ApiPathBase(): string {
    if (isNullOrWhiteSpace(this._servicePath)) return null;
    return `${this._servicePath}`;
  }

  constructor() {
    this._servicePath = `${PublicRouteBase}/register`;
    this._entityModel = Registrant;
  }

  post = (data: Registrant, token: ReCaptchaBase["g-recaptcha-response"]): Promise<ApiResponse<Registrant>> => {
    if (isNullOrWhiteSpace(token)) {
      return Promise.resolve(new ApiResponse<Registrant>({ success: false, message: "Invalid Recaptcha token provided." }));
    }

    const payload = { ...new RegistrantPostPayload(data), "g-recaptcha-response": token };
    return this._apiService
      .post<RegistrantPostPayloadBase, Registrant>(`${this.ApiPathBase}/request`, payload, ContentType.Json, AuthType.Public)
      .then(this.mapResponse);
  };

  validateEmails(conferenceId: string, emails: string[]): Promise<ApiResponse<RegisterCheckEmailAvailableResponse[]>> {
    const payload = { conferenceId, emails };
    return this._apiService.post<RegisterCheckEmailsAvailableRequest, RegisterCheckEmailAvailableResponse[]>(
      `${this.ApiPathBase}/checkEmailsAvailable/v1`,
      payload,
      ContentType.Json,
      AuthType.Public
    );
  }

  private mapResponse = (response: ApiResponse<Registrant>): ApiResponse<Registrant> => {
    return mapApiResponse(response, this._entityModel);
  };
}
