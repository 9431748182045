import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { sortedUniq, intersection } from "lodash";
import { Moment } from "moment";
import config from "../../config/config";
import { ItineraryDayFormat, DefaultTimeZone } from "../../const";
import { DateFormat } from "../../definitions/date.definition";
import type { OnDemand } from "../../services/admin/onDemand/onDemand.model";
import type { AttendeeViewModel } from "../../services/attendee/attendee.model";
import type { Conference } from "../../services/conference/conference.model";
import { CorporateProfile } from "../../services/corporateProfile/corporateProfile.model";
import { Presentation, PresentationSessionType } from "../../services/presentation/presentation.model";
import type { Speaker } from "../../services/speaker/speaker.model";
import { getQuery } from "../query/query.utils";

// TODO: Replace this two values when Circular Dependency issue is resolved
enum PresentationVendorType {
  Custom = "custom",
  Streamlined = "streamlined",
}
const LobbyPresentationTheaterVendors = [PresentationVendorType.Streamlined];

const userIdQueryNameByVendors: Record<PresentationVendorType, string> = {
  [PresentationVendorType.Streamlined]: "_luser",
  [PresentationVendorType.Custom]: null,
};

export function sortPresentationsByDate(presentations: Presentation[]): Presentation[] {
  if (isEmpty(presentations)) return [];

  return presentations.sort((a, b) => a.start_date?.valueOf() - b.start_date?.valueOf());
}

export function getFriendlyName(first_name: string, last_name: string, company: string, email: string): string {
  if (
    isNullOrWhiteSpace(first_name) &&
    isNullOrWhiteSpace(last_name) &&
    isNullOrWhiteSpace(company) &&
    isNullOrWhiteSpace(email)
  ) {
    return "";
  }

  let friendly_name = "";

  if (!isNullOrWhiteSpace(first_name)) {
    friendly_name = first_name;
  }

  if (!isNullOrWhiteSpace(last_name)) {
    friendly_name = `${friendly_name} ${last_name}`;
  }

  if (!isNullOrWhiteSpace(company)) {
    friendly_name = `${friendly_name}, ${company}`;
  }

  if (!isNullOrWhiteSpace(email)) {
    friendly_name = isNullOrWhiteSpace(friendly_name) ? email : `${friendly_name} (${email})`;
  }

  return friendly_name.trim();
}

export function mapPresentation(
  presentations: Presentation[],
  attendees: AttendeeViewModel[],
  speakers: Speaker[],
  conference?: Conference
): Presentation[] {
  return [...(presentations || [])].map((presentation) => {
    const _conference = isEmpty(conference) ? presentation._conference : conference;

    const _speaker = isEmpty(speakers)
      ? []
      : presentation?._speaker?.reduce((presentationSpeaker, speaker) => {
          const data = speakers.find((x) => x._id === speaker._id);
          return isEmpty(data) ? presentationSpeaker : presentationSpeaker.concat(data);
        }, [] as Speaker[]);

    const _attendee = isEmpty(attendees)
      ? []
      : presentation?._attendee?.reduce((presentationAttendees, attendee) => {
          const data = attendees.find((x) => x._id === attendee._id);
          return isEmpty(data) ? presentationAttendees : presentationAttendees.concat(data);
        }, [] as AttendeeViewModel[]);

    return new Presentation({ ...presentation, _conference, _attendee, _speaker });
  });
}

export function getPresentationUrl(presentation: Presentation | OnDemand, profile?: AttendeeViewModel): string {
  if (isNullOrWhiteSpace(presentation?._id)) return null;

  const isPresentation = presentation instanceof Presentation;
  const onDemandRoute = "ondemandpresentation";
  const presentationRoute = "presentation";

  const route = isPresentation ? presentationRoute : onDemandRoute;
  const url = `${config.api.publicUrl}/go/${route}/${presentation._id}`;

  if (isEmpty(profile)) return url;

  const presentationVendor = profile?.presentation_vendors?.find((v) => LobbyPresentationTheaterVendors.includes(v.vendor));
  const { first_name, last_name, email, company: userCompany, _corporate_profile: corporateProfile } = profile;
  const company = !isNullOrWhiteSpace(userCompany) ? userCompany : (corporateProfile as CorporateProfile)?.name;
  const queryParams = getQuery({ first_name, last_name, email, company, vendor_id: presentationVendor?.vendor_id });

  return url + queryParams;
}

export function getPresentationQaUrl(presentation: Presentation, profile?: AttendeeViewModel): string {
  return getStreamlineLUserUrl(presentation, presentation?.qa_url, profile);
}

export function getPresentationPollUrl(presentation: Presentation, profile?: AttendeeViewModel): string {
  return getStreamlineLUserUrl(presentation, presentation?.poll_url, profile);
}

export function getStreamlineLUserUrl(presentation: Presentation, baseUrl: string, profile?: AttendeeViewModel): string {
  if (isEmpty(presentation) || isNullOrWhiteSpace(baseUrl)) return null;

  const presentationVendor = profile?.presentation_vendors?.find((v) => LobbyPresentationTheaterVendors.includes(v.vendor));
  const queryParamName = userIdQueryNameByVendors[presentationVendor?.vendor];

  if (isEmpty(presentationVendor) || isNullOrWhiteSpace(queryParamName)) return baseUrl;

  const queryParams = getQuery({ [queryParamName]: presentationVendor.vendor_id });

  return `${baseUrl.split("?")[0]}${queryParams}`;
}

export function presentationToItineraryDay(presentation: Presentation, timeZone = DefaultTimeZone): string {
  return presentation?.start_date.tz(timeZone).format(ItineraryDayFormat);
}

export function convertCodesToString(codes: string[]): string {
  if (isEmpty(codes)) return "";
  return codes.join(", ");
}

export function convertCodesToArray(codes: string): string[] {
  if (isNullOrWhiteSpace(codes)) return [];

  return codes.split(", ");
}

export function formatPresentationDate(date: Moment): string {
  if (isEmpty(date) || !date?.isValid()) return "";
  return date.format(DateFormat.Short);
}

export function getPresentationCodes(presentations: Presentation[], ondemand?: OnDemand[]): string[] {
  const presentationCodes = (presentations || []).flatMap((p) => p.code).filter((el) => !isNullOrWhiteSpace(el));
  const onDemandCodes = (ondemand || []).flatMap((o) => o.code).filter((el) => !isNullOrWhiteSpace(el));

  return sortedUniq([...presentationCodes, ...onDemandCodes].sort());
}

export function getPresentationsWithAttendees(presentations: Presentation[], attendeeIds: string[]): Presentation[] {
  if (isEmpty(presentations)) return [];

  return presentations.filter((presentation) => {
    const { _speaker: speakers } = presentation;

    if (isEmpty(speakers)) return false;

    return intersection(
      speakers.map((speaker) => {
        return speaker._attendee;
      }),
      attendeeIds
    ).length;
  });
}

export function getOpenOrClosedPresentationFromId(presentationId: string, presentations: Presentation[]): Presentation {
  if (isEmpty(presentations)) return null;

  return presentations.find(
    (presentation) => presentation.session_type !== PresentationSessionType.Break && presentation._id === presentationId
  );
}

export function getOpenAndClosedPresentations(presentations: Presentation[]): Presentation[] {
  return presentations?.filter((presentation) => presentation.session_type !== PresentationSessionType.Break);
}
