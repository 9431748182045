import { Modal, ButtonTheme, LoadingScreen } from "@q4/nimbus-ui";
import React, { FunctionComponent, memo } from "react";
import config from "../../config/config";
import { Environment } from "../../config/config.definition";
import { RootProps } from "../../views/root/root.definition";
import useAppVersionQuery from "./hooks/versionQuery.hook";
import "./cacheBuster.scss";

export enum CacheBusterClassNames {
  Base = "cache-buster",
}

const withCacheBuster = (WrappedComponent: FunctionComponent<RootProps>): unknown => {
  const memoedCache = memo((props: RootProps) => {
    const { loading, isExpectedVersion } = useAppVersionQuery();

    async function refreshCacheAndReload() {
      if (caches) {
        try {
          const names = await caches.keys();
          for (const name of names) await caches.delete(name);
        } catch (e) {
          console.error(e);
        }
      }

      /**** No need to append the timestamp in URL at this point. Keeping the comment for future  ****/
      // location.reload() has no parameter
      // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
      //   const timestamp = moment().utc().valueOf();
      //   const url = new URL(window.location.href);
      //   url.searchParams.set("t", `${timestamp}`);
      //   window.location.replace(url.toString());
      /**** ****/

      window.location.reload();
    }

    if (![Environment.Local, Environment.Develop].includes(config.env)) {
      return <WrappedComponent {...props} />;
    }

    if (loading) {
      return <LoadingScreen q4Icon="ni-q4-logo" />;
    }

    return (
      <>
        <WrappedComponent {...props} />
        <Modal
          title="A new version is available"
          className={CacheBusterClassNames.Base}
          visible={!isExpectedVersion}
          includeExit={false}
          footerActions={[
            {
              key: "refresh",
              label: "Refresh",
              theme: ButtonTheme.Citrus,
              onClick: refreshCacheAndReload,
            },
          ]}
        >
          The version you are using is out-of-date. Refresh your browser to get the latest version of the Q4 Capital Market
          Events Platform.
        </Modal>
      </>
    );
  });

  memoedCache.displayName = "memoedCache";
  return memoedCache;
};

export default withCacheBuster;
