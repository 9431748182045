/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from "@q4/nimbus-ui";

export const trimFormData = (formData: any): any => {
  const isArray = Array.isArray(formData);
  const isObject = typeof formData === "object";
  const isFunction = typeof formData === "function";
  const isString = typeof formData === "string";
  const isBoolean = typeof formData === "boolean";
  const isNumber = typeof formData === "number";

  if (isFunction || isBoolean || isNumber || isNil(formData)) return formData;
  if (isObject && typeof formData?.isValid === "function" && formData.isValid()) return formData;
  if (isString) return formData.trim();
  if (isObject) Object.keys(formData).forEach((key) => (formData[key] = trimFormData(formData[key])));
  if (isArray) formData.forEach((data: unknown) => trimFormData(data));
  return formData;
};
