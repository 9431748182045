import { BaseComponentProps, IdModelBase } from "@q4/nimbus-ui";
import config from "../../config/config";
import { Environment } from "../../config/config.definition";

export const VersionEnvironmentLabel = {
  [Environment.Develop]: "Develop",
  [Environment.Local]: "Local",
  [Environment.Production]: "",
  [Environment.Staging]: "Staging",
};

export enum VersionClassName {
  Base = "version",
}

export interface VersionProps extends Pick<BaseComponentProps, "id"> {
  config: typeof config;
  visible?: boolean;
}

export class VersionIdModel extends IdModelBase {
  constructor(id: string) {
    super(id);
  }
}
