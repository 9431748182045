import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { UserContextState } from "../../../contexts/user/user.definition";
import { CompanyLobbyLayoutIdModel } from "../../company/lobby/components/layout/companyLobby.layout.definition";
import { SpeakersIdModel } from "../../company/lobby/components/speakers/speakers.definition";
import { AgendaIdModel } from "./components/agenda/agenda.definition";
import { DetailsIdModel } from "./components/details/details.definition";
import { DialInInstructionsIdModel } from "./components/dialInInstructions/dialInInstructions.definition";
import { DialInIdModel } from "./components/dialInformation/dialInformation.definition";
import { LivePresentationIdModel } from "./components/livePresentation/livePresentation.definition";
import { TracksIdModel } from "./components/tracks/tracks.definition";

export enum ItineraryPollingInterval {
  Api = 60_000,
  Ui = 15_000,
}

export enum ItineraryAnchors {
  Speakers = "speakers",
  Agenda = "agenda",
  SignOut = "sign-out",
  DialIn = "dial-in",
  Chat = "chat",
}

export interface ItineraryProps {
  userContext?: UserContextState;
}

export enum ItineraryClassName {
  Base = "itinerary",
  Conference = "itinerary_conference",

  // Layout
  Inner = "itinerary_inner",
  Hero = "itinerary_hero",
  Details = "itinerary_details",
  LiveContainer = "itinerary_live-container",
  Section = "itinerary_section",

  // Typography
  Title = "itinerary_title",
  Date = "itinerary_date",
  Description = "itinerary_description",
  Heading = "itinerary_heading",
  HeadingWithFancyModifier = "itinerary_heading--fancy",
  Subheading = "itinerary_subheading",

  // Nimbus
  Toast = "itinerary_toast",
}

export class PageIdModel extends IdModelBase {
  layout: CompanyLobbyLayoutIdModel;
  conference: string;
  details: DetailsIdModel;
  livePresentation: LivePresentationIdModel;
  ondemandTracks: TracksIdModel;
  tracks: TracksIdModel;
  speakers: SpeakersIdModel;
  agenda: AgendaIdModel;
  dialInfo: DialInIdModel;
  dialInInstructions: DialInInstructionsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.layout = new CompanyLobbyLayoutIdModel(`${this.id}Layout`);
    this.conference = `${this.id}Conference`;
    this.details = new DetailsIdModel(`${this.id}Details`);
    this.livePresentation = new LivePresentationIdModel(`${this.id}LivePresentation`);
    this.ondemandTracks = new TracksIdModel(`${this.id}OnDemandTracks`);
    this.tracks = new TracksIdModel(`${this.id}Tracks`);
    this.speakers = new SpeakersIdModel(`${this.id}Speakers`);
    this.agenda = new AgendaIdModel(`${this.id}Agenda`);
    this.dialInfo = new DialInIdModel(`${this.id}DialInformation`);
    this.dialInInstructions = new DialInInstructionsIdModel(`${this.id}DialInInstructions`);
  }
}

const PageId = "Itinerary";
export const ItineraryIdModel = new PageIdModel(PageId);
