import { Dispatch, SetStateAction } from "react";
import { JsonFileRecord } from "../../../../components/jsonForm/jsonForm.definition";
import { RegistrantViewModel } from "../../../../services/admin/registrant/registrant.model";
import { ApiResponse } from "../../../../services/api/api.definition";
import { AttendeeType } from "../../../../services/attendee/attendee.model";
import { Conference } from "../../../../services/conference/conference.model";
import { RegisterCheckEmailAvailableResponse } from "../../../../services/public/register.model";
import { QuestionGroup } from "../../../../services/questionGroup/questionGroup.model";
import { ProfileIdModel } from "../../profile/profile.definition";
import { RegistrationIdModel, RegistrationStepLabel } from "../registration.definition";
import { Section } from "../sections/sections.definition";

export interface SectionsHookProps {
  viewIdModel: typeof RegistrationIdModel | typeof ProfileIdModel;
  showAvailability: boolean;
  showMeetings: boolean;
  isGuestOtherTypesEnabled: boolean;
  companyName: string;
  conference: Conference;
  registrant: RegistrantViewModel;
  originalRegistrant?: RegistrantViewModel;
  loading: boolean;
  isFormDirty?: boolean;
  setRegistrant: Dispatch<SetStateAction<RegistrantViewModel>>;
  handleRegistrantChange: (registrant: RegistrantViewModel) => void;
  setFileState?: Dispatch<SetStateAction<JsonFileRecord>>;
  setLoading?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  checkAttendeeEmailsAvailable?: (emails: string[]) => Promise<ApiResponse<RegisterCheckEmailAvailableResponse[]>>;
  onSubmit?: () => void;
  isEditMode?: boolean;
  questionGroup?: Partial<QuestionGroup>;
  questionGroupLoading?: boolean;
}

export interface SectionsHookModel {
  tabSections: Section[];
  sectionStep: number;
}

const editContactInformationStepBaseProps = {
  id: ProfileIdModel.contactInformationTab,
  label: RegistrationStepLabel.ContactInformation,
};
const editAttendeesStepBaseProps = {
  id: ProfileIdModel.attendeesTab,
  label: RegistrationStepLabel.Attendees,
};
const editDetailsStepBaseProps = {
  id: ProfileIdModel.detailsTab,
  label: RegistrationStepLabel.Details,
};
const editAvailabilityStepBaseProps = {
  id: ProfileIdModel.availabilityTab,
  label: RegistrationStepLabel.Availability,
};
const editSummaryStepBaseProps = {
  id: ProfileIdModel.summaryTab,
  label: RegistrationStepLabel.Summary,
};
const editPersonalInformationStepBaseProps = {
  id: ProfileIdModel.personalInformationTab,
  label: RegistrationStepLabel.PersonalInformation,
};
const editMeetingRequestsStepBaseProps = {
  id: ProfileIdModel.meetingRequestsTab,
  label: RegistrationStepLabel.MeetingRequests,
};
const editCustomQuestionsStepBaseProps = {
  id: ProfileIdModel.customQuestionsTab,
  label: RegistrationStepLabel.CustomQuestions,
};

export const EditAttendeeInformationSteps: Record<
  AttendeeType,
  Record<string, { id: string; label: RegistrationStepLabel | string }>
> = {
  [AttendeeType.Corporate]: {
    contactInformation: editContactInformationStepBaseProps,
    attendees: editAttendeesStepBaseProps,
    details: editDetailsStepBaseProps,
    availability: editAvailabilityStepBaseProps,
    customQuestions: editCustomQuestionsStepBaseProps,
    summary: editSummaryStepBaseProps,
  },
  [AttendeeType.Investor]: {
    personalInformation: editPersonalInformationStepBaseProps,
    availability: editAvailabilityStepBaseProps,
    meetingRequests: editMeetingRequestsStepBaseProps,
    customQuestions: editCustomQuestionsStepBaseProps,
    summary: editSummaryStepBaseProps,
  },
  [AttendeeType.Internal]: {
    personalInformation: editPersonalInformationStepBaseProps,
    customQuestions: editCustomQuestionsStepBaseProps,
    summary: editSummaryStepBaseProps,
  },
  [AttendeeType.Guest]: {
    personalInformation: editPersonalInformationStepBaseProps,
    customQuestions: editCustomQuestionsStepBaseProps,
    summary: editSummaryStepBaseProps,
  },
};

export const contactInformationStepBaseProps = {
  id: RegistrationIdModel.contactInformationTab,
  label: RegistrationStepLabel.ContactInformation,
};
export const attendeesStepBaseProps = {
  id: RegistrationIdModel.attendeesTab,
  label: RegistrationStepLabel.Attendees,
};
export const availabilityStepBaseProps = {
  id: RegistrationIdModel.availabilityTab,
  label: RegistrationStepLabel.Availability,
};
export const summaryStepBaseProps = {
  id: RegistrationIdModel.summaryTab,
  label: RegistrationStepLabel.Summary,
};
export const personalInformationStepBaseProps = {
  id: RegistrationIdModel.personalInformationTab,
  label: RegistrationStepLabel.PersonalInformation,
};
export const meetingRequestsStepBaseProps = {
  id: RegistrationIdModel.meetingRequestsTab,
  label: RegistrationStepLabel.MeetingRequests,
};
export const customQuestionsStepBaseProps = {
  id: RegistrationIdModel.customQuestionsTab,
  label: RegistrationStepLabel.CustomQuestions,
};

export interface StepIndexById {
  [key: string]: number;
}

export const corporateRegistrationSteps = {
  contactInformation: contactInformationStepBaseProps,
  attendees: attendeesStepBaseProps,
  availability: availabilityStepBaseProps,
  customQuestions: customQuestionsStepBaseProps,
  summary: summaryStepBaseProps,
};
export const investorRegistrationSteps = {
  personalInformation: personalInformationStepBaseProps,
  availability: availabilityStepBaseProps,
  meetingRequests: meetingRequestsStepBaseProps,
  customQuestions: customQuestionsStepBaseProps,
  summary: summaryStepBaseProps,
};
export const internalRegistrationSteps = {
  personalInformation: personalInformationStepBaseProps,
  customQuestions: customQuestionsStepBaseProps,
  summary: summaryStepBaseProps,
};
export const guestRegistrationSteps = {
  personalInformation: personalInformationStepBaseProps,
  customQuestions: customQuestionsStepBaseProps,
  summary: summaryStepBaseProps,
};

export const RegistrationAttendeeTypeSteps: Record<
  AttendeeType,
  Record<string, { id: string; label: RegistrationStepLabel }>
> = {
  [AttendeeType.Corporate]: corporateRegistrationSteps,
  [AttendeeType.Investor]: investorRegistrationSteps,
  [AttendeeType.Internal]: internalRegistrationSteps,
  [AttendeeType.Guest]: guestRegistrationSteps,
};
