import { ChipsItemProps, isEmpty } from "@q4/nimbus-ui";
import { useCallback, useMemo } from "react";
import type { EntityModel } from "../../../definitions/entity.definition";
import type { ChipsHookProps, ChipsHookModel } from "./useChips.definition";

export const useChips = <T>(props: ChipsHookProps<T>): ChipsHookModel<T> => {
  const { createSuffix, disabled, entities, labelKey, lockedKey, valueKey } = props;

  // chips
  const chips: ChipsItemProps<T>[] = useMemo(() => {
    if (isEmpty(entities)) return [];

    if (typeof entities?.[0] === "string") {
      return (entities as string[]).reduce((chips, entity) => {
        if (isEmpty(entity)) return chips;

        chips.push({
          value: entity,
          label: entity,
          locked: !!disabled,
        });
        return chips;
      }, []);
    }

    return (entities as T[]).reduce((chips, entity) => {
      if (isEmpty(entity)) return chips;

      chips.push({
        value: entity[valueKey]?.toString(),
        label: entity[labelKey]?.toString(),
        data: entity,
        locked: !!entity[lockedKey] || !!disabled,
      });
      return chips;
    }, []);
  }, [disabled, entities, labelKey, lockedKey, valueKey]);

  const handleRemove = useCallback(
    (value: string): T[] => {
      if (isEmpty(entities)) return [] as T[];
      if (typeof entities?.[0] === "string") {
        const filtered = (entities as string[]).filter((x) => x !== value);
        return filtered as unknown as T[];
      }
      return (entities as T[]).filter(({ [valueKey]: x }) => x?.toString() !== value);
    },
    [entities, valueKey]
  );

  // ComboBox
  function handleAdd(option: T, entityModel?: EntityModel<T>): T[] {
    if (isEmpty(option)) return;

    const entity = isEmpty(entityModel) ? option : new entityModel(option);

    if (typeof entity === "string") {
      const value = entity.indexOf(createSuffix) > -1 ? entity.slice(0, entity.indexOf(createSuffix)) : entity;
      const added = isEmpty(entities) ? [value] : (entities as string[])?.concat(value);
      return added as unknown as T[];
    }

    if (isEmpty(entities)) return [entity];
    return (entities as T[]).concat(entity);
  }

  return [chips, handleAdd, handleRemove];
};
