import { isEmpty } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef } from "react";
import { useAutoFetch, useService } from "../..";
import { Registrant } from "../../../services/admin/registrant/registrant.model";
import RegistrantService from "../../../services/admin/registrant/registrant.service";
import { ApiMethod } from "../../../services/api/api.definition";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import { Conference } from "../../../services/conference/conference.model";
import { CorporateProfile } from "../../../services/corporateProfile/corporateProfile.model";
import { Speaker } from "../../../services/speaker/speaker.model";
import { UseServiceLogic } from "../../useService/logic/useService.logic";
import { RegistrantEntityName, RegistrantHookModel, RegistrantHookProps } from "./useRegistrant.definition";

export const useRegistrants = (props: RegistrantHookProps): RegistrantHookModel => {
  const {
    autoFetchData,
    conferenceId,
    assignDefaultEntity,
    data,
    showNotifications,
    useAttendees,
    useCorporateProfile,
    useSpeakers,
    useOffline,
  } = props;
  const { setItems: setAttendees } = useAttendees || {};
  const { setItems: setCorporateProfiles } = useCorporateProfile || {};
  const { setItems: setSpeakers } = useSpeakers || {};

  const {
    current,
    items,
    loading,
    setCurrent: setRegistrant,
    setItems: setRegistrants,
    setLoading,
  } = useService({
    autoFetchData: false,
    assignDefaultEntity,
    data,
    entityName: RegistrantEntityName,
    showNotifications,
    useOffline,
    entityModel: Registrant,
  });

  const service = useMemo(() => new RegistrantService(), []);
  const logic = useRef(new UseServiceLogic(RegistrantEntityName, showNotifications, assignDefaultEntity));

  const getByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<Registrant[]> => {
      setLoading(true);

      return service
        .getByConferenceId(_id)
        .then((response): Registrant[] => {
          logic.current.handleResponseNotification({
            success: response?.success,
            method: ApiMethod.Get,
          });

          return logic.current.handleArrayedResponse(response?.data, {
            setEntity: setRegistrant,
            setEntities: setRegistrants,
          });
        })
        .finally(() => setLoading(false));
    },
    [service, setLoading, setRegistrant, setRegistrants]
  );

  const post = useCallback(
    (payload: Registrant, assignEntities = true): Promise<Registrant> => {
      setLoading(true);

      return service
        .post(payload)
        .then((response) => {
          logic.current.handleResponseNotification({
            success: response?.success,
            method: ApiMethod.Post,
            message: response?.message,
          });

          return logic.current.handleNewResponse({
            assignEntities,
            entity: response?.data,
            entityModel: Registrant,
            setEntity: setRegistrant,
            setEntities: setRegistrants,
          });
        })
        .finally(() => setLoading(false));
    },
    [service, setLoading, setRegistrant, setRegistrants]
  );

  const putById = useCallback(
    (_id: string, payload: Registrant, assignEntities = true): Promise<Registrant> => {
      return service.put(_id, payload).then((response) => {
        logic.current.handleResponseNotification({
          success: response?.success,
          method: ApiMethod.Put,
          message: response?.message,
        });

        const { registrationRequest: registrant, registrationResponse: attendeeResponse } = response?.data || {};

        if (setAttendees instanceof Function && !isEmpty(attendeeResponse)) {
          const { attendees } = attendeeResponse;
          setAttendees((original) => original.concat(attendees.map((x) => new AttendeeViewModel(x))));
        }

        if (setCorporateProfiles instanceof Function && !isEmpty(attendeeResponse?.corporateProfile)) {
          const { corporateProfile } = attendeeResponse;
          setCorporateProfiles((original) => original.concat(new CorporateProfile(corporateProfile)));
        }

        if (setSpeakers instanceof Function && !isEmpty(attendeeResponse?.speakers)) {
          const { speakers } = attendeeResponse;
          setSpeakers((original) => original.concat(speakers.map((x) => new Speaker(x))));
        }

        return logic.current.handleUpdateResponse({
          assignEntities,
          entity: registrant,
          entityModel: Registrant,
          setEntity: setRegistrant,
          setEntities: setRegistrants,
        });
      });
    },
    [service, setAttendees, setCorporateProfiles, setRegistrant, setRegistrants, setSpeakers]
  );

  const deleteById = useCallback(
    (id: Registrant["_id"]): Promise<boolean> => {
      setLoading(true);

      return service
        .delete(id)
        .then((response) => {
          const success = !!response?.success;
          logic.current.handleResponseNotification({
            success,
            method: ApiMethod.Delete,
          });
          if (!success) return false;

          return logic.current.handleDeleteResponse(id, success, { setEntity: setRegistrant, setEntities: setRegistrants });
        })
        .finally(() => setLoading(false));
    },
    [service, setLoading, setRegistrant, setRegistrants]
  );

  useAutoFetch({
    autoFetchData,
    data,
    param: conferenceId,
    fetchBy: getByConferenceId,
    setEntities: setRegistrants,
  });

  return {
    current,
    loading,
    items,
    service,
    post,
    fetchByConferenceId: getByConferenceId,
    putById,
    deleteById,
    setCurrent: setRegistrant,
    setItems: setRegistrants,
    setLoading,
  };
};
