import { Checkbox, Form, FormFieldProps, isEmpty, Textarea, Textbox, DatePicker, DateFormat, Keyline } from "@q4/nimbus-ui";
import { Moment } from "moment";
import React, { useCallback, useMemo } from "react";
import { DefaultLobbyConfig } from "../../../../../../services/conference/conference.model";
import { ErrorModel } from "../../../../../../services/errorHandler/errorHandler.definition";
import { ConferenceEditClassName } from "../../conferenceEdit.definition";
import { checkLobbyOpenDate } from "../../conferenceEdit.utils";
import ConferencePreviewMode from "../previewMode/previewMode.component";
import {
  LobbyConfigFormIdModel,
  LobbyConfigFormProps,
  LobbyConfigProps,
  LobbyConfigForms,
} from "./lobbyConfigForm.definition";

export const LobbyConfigForm = (props: LobbyConfigFormProps): JSX.Element => {
  const { id, conference, getStateChangeHandler } = props;

  const idModel = useMemo(() => new LobbyConfigFormIdModel(id), [id]);

  const config: LobbyConfigProps = useMemo(
    () => ({
      tracks: {
        name: "Tracks",
        disableId: idModel?.tracksDisable?.id,
        titleId: idModel?.tracksTitle?.id,
        labelId: idModel?.tracksLabel?.id,
        labelText: "Add To Label",
        titleText: "Title",
      },
      livePresentation: {
        name: "Join Live Presentation",
        disableId: idModel?.livePresentationDisable?.id,
        titleId: idModel?.livePresentationTitle?.id,
        labelId: idModel?.livePresentationLabel?.id,
        labelText: "Image Alt",
        titleText: "Link Text",
      },
      ondemand_presentations: {
        name: "On Demand",
        disableId: idModel?.onDemandDisable?.id,
        titleId: idModel?.onDemandTitle?.id,
        labelId: idModel?.onDemandLabel?.id,
        labelText: "Hover Label",
        titleText: "Title",
      },
      speakers: {
        name: "Speaker",
        disableId: idModel?.speakersDisable?.id,
        titleId: idModel?.speakersTitle?.id,
        labelId: idModel?.speakersLabel?.id,
        labelText: "Hover Label",
        titleText: "Title",
      },
      agenda: {
        name: "Agenda",
        disableId: idModel?.agendaDisable?.id,
        titleId: idModel?.agendaTitle?.id,
        labelId: idModel?.agendaLabel?.id,
        labelText: "No Item Label",
        titleText: "Title",
      },
      dialInInstructions: {
        name: "Dial Instructions",
        disableId: idModel?.dialInInstructionsDisable?.id,
        titleId: idModel?.dialInInstructionsTitle?.id,
        labelId: idModel?.dialInInstructionsLabel?.id,
        labelText: "Instructions",
        titleText: "Title",
        isLabelTextArea: true,
      },
    }),
    [idModel]
  );

  const getLobbyConfigProps = useCallback(
    (lobbyKey: keyof DefaultLobbyConfig): FormFieldProps[] => {
      if (isEmpty(config?.[lobbyKey])) return null;
      const name = config[lobbyKey].name;
      const isLabelTextArea = !!config[lobbyKey].isLabelTextArea;

      return [
        {
          key: `Hide ${name}`,
          width: "1-of-5",
          smallWidth: "1-of-1",
          label: name,
          children: (
            <Checkbox
              id={config?.[lobbyKey]?.disableId}
              checked={!!conference?.lobby?.[lobbyKey]?.disabled}
              onChange={getStateChangeHandler(`lobby.${lobbyKey}.disabled`)}
              label="Disable"
            />
          ),
        },
        {
          key: `${name} Title`,
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: config[lobbyKey].titleText,
          children: (
            <Textbox
              id={config?.[lobbyKey]?.titleId}
              value={conference?.lobby?.[lobbyKey]?.title}
              onChange={getStateChangeHandler(`lobby.${lobbyKey}.title`)}
            />
          ),
        },
        {
          key: `${name} Label`,
          width: "2-of-5",
          smallWidth: "1-of-1",
          label: config[lobbyKey].labelText,
          children: isLabelTextArea ? (
            <Textarea
              id={config?.[lobbyKey]?.labelId}
              value={conference?.lobby?.[lobbyKey]?.label}
              onChange={getStateChangeHandler(`lobby.${lobbyKey}.label`)}
            />
          ) : (
            <Textbox
              id={config?.[lobbyKey]?.labelId}
              value={conference?.lobby?.[lobbyKey]?.label}
              onChange={getStateChangeHandler(`lobby.${lobbyKey}.label`)}
            />
          ),
        },
      ];
    },
    [config, conference?.lobby, getStateChangeHandler]
  );

  const isValidDateOption = useCallback(
    (selectedDate: Moment) => checkLobbyOpenDate(selectedDate, conference),
    [conference]
  );

  const dateError = useMemo(
    () =>
      conference?.open_date &&
      conference?.close_date &&
      new ErrorModel(
        `Choose date between ${conference?.open_date.format(DateFormat)} and ${conference?.close_date.format(DateFormat)}`,
        true
      ),
    [conference?.open_date, conference?.close_date]
  );

  const disabledDays = useMemo(
    () => [{ before: conference?.open_date?.toDate() }, { after: conference?.close_date.toDate() }],
    [conference?.open_date, conference?.close_date]
  );

  return (
    <div id={idModel.id}>
      <Form
        fields={[
          {
            key: idModel.corporateLobbyOpenDate.id,
            label: "Corporate Lobby Open Date",
            width: "1-of-3",
            error: !isValidDateOption(conference?.lobby?.corporate_lobby?.open_date) ? dateError : null,
            children: (
              <DatePicker
                id={idModel.corporateLobbyOpenDate.id}
                className={ConferenceEditClassName.FullWidthInput}
                value={conference?.lobby?.corporate_lobby?.open_date}
                disabledDays={disabledDays}
                onChange={getStateChangeHandler("lobby.corporate_lobby.open_date")}
              />
            ),
          },
          {
            key: idModel.investorLobbyOpenDate.id,
            label: "Investor Lobby Open Dates",
            width: "1-of-3",
            error: !isValidDateOption(conference?.lobby?.investor_lobby?.open_date) ? dateError : null,
            children: (
              <DatePicker
                id={idModel.investorLobbyOpenDate.id}
                className={ConferenceEditClassName.FullWidthInput}
                value={conference?.lobby?.investor_lobby?.open_date}
                disabledDays={disabledDays}
                onChange={getStateChangeHandler("lobby.investor_lobby.open_date")}
              />
            ),
          },
          {
            key: idModel.generalLobbyOpenDate.id,
            label: "Guest and Internal Lobby Open Date",
            width: "1-of-3",
            error: !isValidDateOption(conference?.lobby?.general_lobby?.open_date) ? dateError : null,
            children: (
              <DatePicker
                id={idModel.generalLobbyOpenDate.id}
                className={ConferenceEditClassName.FullWidthInput}
                value={conference?.lobby?.general_lobby?.open_date}
                disabledDays={disabledDays}
                onChange={getStateChangeHandler("lobby.general_lobby.open_date")}
              />
            ),
          },
        ]}
      />
      <Keyline className={ConferenceEditClassName.LineDivider} />
      <ConferencePreviewMode
        checkboxProps={{
          id: idModel.preview.id,
          alignmentPadding: true,
          checked: !!conference?.preview,
          onChange: getStateChangeHandler("preview"),
        }}
      />
      {(Object.keys(config) || []).map(
        (key): JSX.Element => (
          <Form
            id={idModel?.[key as keyof LobbyConfigForms]?.id}
            key={key}
            fields={getLobbyConfigProps(key as keyof DefaultLobbyConfig)}
          />
        )
      )}
    </div>
  );
};
