import "./schedulerFilter.component.scss";
import {
  Checkbox,
  Collapsable,
  Field,
  getClassName,
  Grid,
  GridColumn,
  isEmpty,
  isNullOrWhiteSpace,
  Scrollbars,
  ScrollbarsTheme,
  Select,
  SelectPreset,
  SelectTheme,
  Tabs,
  TabsTheme,
  Text,
  TextTheme,
} from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useState } from "react";
import { MeetingInterestLevel, MeetingRequest } from "../../../../../../../../services/admin/registrant/registrant.model";
import { MeetingLabel } from "../../../../../../../../services/meeting/meeting.model";
import { getSchedulerPriorityTheme, getSchedulerStatusTheme } from "../../../../../../../../utils/scheduler/scheduler.utils";
import { Badge } from "../../../../badge/badge.component";
import { BadgeTheme } from "../../../../badge/badge.definition";
import { SchedulerRequest, SchedulerMeetingStatus } from "../../meetingScheduler.definition";
import { getSchedulerRequests } from "../../meetingScheduler.utils";
import {
  SchedulerFilterClassName,
  SchedulerFilterIdModel,
  SchedulerFilterProps,
  RequestItemClassName,
  SchedulerRequestType,
  schedulerRequestSorting,
} from "./schedulerFilter.definition";

const SchedulerFilter = (props: SchedulerFilterProps): JSX.Element => {
  const {
    id,
    currentAttendee,
    currentCorporate,
    attendees,
    corporateProfiles,
    currentRequestType,
    requests,
    meetingRequests,
    onTabChange,
    onAttendeeChange,
    onCorporateChange,
  } = props;

  const idModel = useMemo(() => new SchedulerFilterIdModel(id), [id]);

  // #region States
  const [meetingLabel, setMeetingLabel] = useState<MeetingLabel>(null);
  const [priority, setPriority] = useState<MeetingInterestLevel>(null);
  const [showScheduled, setShowScheduled] = useState(false);
  const [showPending, setShowPending] = useState(false);
  // #endregion

  // #region Memoized Variables
  const requester = useMemo(
    () => (!!currentRequestType ? currentCorporate : currentAttendee),
    [currentAttendee, currentCorporate, currentRequestType]
  );

  const isCorporateRequester = useMemo(() => !currentRequestType, [currentRequestType]);

  const updated = useMemo(() => getSchedulerRequests(requests, meetingRequests), [requests, meetingRequests]);

  const meetingTypes = useMemo(() => Object.values(MeetingLabel), []);
  const priorities = useMemo(() => Object.values(MeetingInterestLevel), []);
  // #endregion

  // #region Helper Methods
  const getMeetingRequest = useCallback(
    (request: SchedulerRequest): MeetingRequest => {
      const isAttendeeRequest = !!request.meeting_requests;

      if (isAttendeeRequest) {
        if (isEmpty(request?.meeting_requests)) return null;
        return request.meeting_requests.find((x) => x._corporate_profile === currentCorporate?._id);
      }

      if (isEmpty(currentAttendee?.meeting_requests)) return null;
      return currentAttendee?.meeting_requests.find((x) => x._corporate_profile === request._id);
    },
    [currentAttendee?.meeting_requests, currentCorporate?._id]
  );

  const showRequest = useCallback(
    (request: SchedulerRequest): boolean => {
      if (isEmpty(request)) return false;
      const { requestStatus } = request;
      const meetingRequest = getMeetingRequest(request);

      const unscheduled = requestStatus === SchedulerMeetingStatus.Unscheduled;
      const filterPending = showPending && requestStatus === SchedulerMeetingStatus.Pending;
      const filterScheduled = showScheduled && requestStatus === SchedulerMeetingStatus.Scheduled;
      const filterMeetingType = isNullOrWhiteSpace(meetingLabel) || meetingRequest.meeting_type === meetingLabel;
      const filterPriority = isNullOrWhiteSpace(priority) || meetingRequest.interest_level === priority;

      return (unscheduled || filterPending || filterScheduled) && filterMeetingType && filterPriority;
    },
    [meetingLabel, priority, showPending, showScheduled, getMeetingRequest]
  );
  // #endregion

  // #region Render Methods
  const renderRequestItemTemplate = useCallback(
    (request: SchedulerRequest): JSX.Element => {
      function handleRequestClick(): void {
        isCorporateRequester
          ? onAttendeeChange(attendees.find((x) => x._id === request._id))
          : onCorporateChange(corporateProfiles.find((x) => x._id === request._id));
      }

      const meetingRequest = getMeetingRequest(request);
      const selected = request._id === requester?._id;
      const requestStatus = request?.requestStatus;
      const statusTheme = getSchedulerStatusTheme(requestStatus);
      const priorityLabel = meetingRequest?.interest_level;
      const priorityTheme = getSchedulerPriorityTheme(priorityLabel);

      return (
        <Collapsable
          key={request._id}
          ghostableProps={{ mountOnEnter: false, unmountOnExit: false }}
          collapsed={!showRequest(request)}
          className={RequestItemClassName.Base}
        >
          <div
            className={getClassName(RequestItemClassName.Block, [
              {
                condition: selected,
                trueClassName: RequestItemClassName.BlockWithSelectedModifier,
              },
            ])}
            onClick={handleRequestClick}
          >
            <div className={RequestItemClassName.Header}>
              <div className={RequestItemClassName.HeaderName}>
                <Text theme={TextTheme.Slate}>{request?.name}</Text>
              </div>
              <div className={RequestItemClassName.HeaderSubName}>
                <Text theme={TextTheme.LightSlate}>
                  <strong>{request?.company}</strong> {request?.title}
                </Text>
              </div>
            </div>
            <div className={RequestItemClassName.Details}>
              <Badge theme={statusTheme}>{requestStatus}</Badge>
              <Badge theme={BadgeTheme.SoftGrey}>{meetingRequest?.meeting_type}</Badge>
              <Badge theme={BadgeTheme.SoftGrey} color={priorityTheme}>
                {priorityLabel}
              </Badge>
            </div>
          </div>
        </Collapsable>
      );
    },
    [
      getMeetingRequest,
      requester?._id,
      showRequest,
      isCorporateRequester,
      onAttendeeChange,
      attendees,
      onCorporateChange,
      corporateProfiles,
    ]
  );
  // #endregion

  return (
    <div id={idModel?.id} className={SchedulerFilterClassName.Base}>
      <div className={SchedulerFilterClassName.Selectors}>
        <Field label="Corporate Attendee" labelProps={{ theme: TextTheme.LightSlate }}>
          <Select
            id={idModel.corporateAttendeeFilterSelect.id}
            theme={SelectTheme.White}
            preset={SelectPreset.Autocomplete}
            value={currentCorporate}
            valueKey={"_id"}
            labelKey={"name"}
            options={corporateProfiles}
            onChange={onCorporateChange}
            noOptionsMessageText={"No Available Corporates"}
          />
        </Field>
        <Field label="Investor Attendee" labelProps={{ theme: TextTheme.LightSlate }}>
          <Select
            id={idModel.investorAttendeeFilterSelect.id}
            theme={SelectTheme.White}
            preset={SelectPreset.Autocomplete}
            value={currentAttendee}
            valueKey={"_id"}
            labelKey={"display_name"}
            options={attendees}
            onChange={onAttendeeChange}
            groupedBy={"company"}
            groupedByUnit={"Members"}
            noOptionsMessageText={"No Available Investors"}
          />
        </Field>
      </div>
      <div className={SchedulerFilterClassName.Tabs}>
        <Tabs
          theme={TabsTheme.LightGrey}
          selected={currentRequestType}
          items={Object.values(SchedulerRequestType).map((x) => {
            return { label: x, value: x };
          })}
          onChange={onTabChange}
        />
      </div>
      <div className={SchedulerFilterClassName.Requests}>
        <div className={SchedulerFilterClassName.RequesterSearch}>
          <div className={SchedulerFilterClassName.RequesterSearchSection}>
            <Grid>
              <GridColumn margin={false} width="3-of-5">
                <Select
                  isClearable
                  preset={SelectPreset.Autocomplete}
                  placeholder="Meeting Type"
                  options={meetingTypes}
                  theme={SelectTheme.White}
                  value={meetingLabel}
                  onChange={setMeetingLabel}
                />
              </GridColumn>
              <GridColumn margin={false} width="2-of-5">
                <Select
                  isClearable
                  preset={SelectPreset.Autocomplete}
                  placeholder="Priority"
                  options={priorities}
                  theme={SelectTheme.White}
                  value={priority}
                  onChange={setPriority}
                />
              </GridColumn>
            </Grid>
          </div>
          <div className={SchedulerFilterClassName.RequesterSearchSection}>
            <Checkbox
              id={idModel.showPending?.id}
              className="nui-toggle-input-base--background-white"
              checked={showPending}
              label="Pending Requests"
              inline
              onChange={setShowPending}
            />
            <Checkbox
              className="nui-toggle-input-base--background-white"
              checked={showScheduled}
              id={idModel.showScheduled?.id}
              label="Scheduled"
              inline
              onChange={setShowScheduled}
            />
          </div>
        </div>
        <div className={SchedulerFilterClassName.RequesterList}>
          <Scrollbars id={idModel.scrollbars?.id} autoHide theme={ScrollbarsTheme.Dark}>
            {isEmpty(updated) ? (
              <div className={RequestItemClassName.Base}>No requests</div>
            ) : !currentRequestType ? (
              updated.sort(schedulerRequestSorting).map(renderRequestItemTemplate)
            ) : (
              updated.map(renderRequestItemTemplate)
            )}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default memo(SchedulerFilter);
