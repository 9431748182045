import { RegistrantStatus } from "../admin/registrant/registrant.model";

export class AuthRequestModel {
  email: string;
  companyUrlSuffix: string;
  conferenceId?: string;
  password?: string;
  code?: string;

  constructor(authRequestModel: AuthRequestModel) {
    Object.assign(this, authRequestModel);
  }
}

export class AuthValidateAttendeeResponse<T extends RegistrantStatus | never = never> {
  constructor(
    public success: boolean,
    public openConferences: boolean,
    public userExists: boolean,
    public message?: string,
    public status?: T
  ) {}
}

export interface AuthRequestLinkResponse {
  _id: string;
  email: string;
  email_verified: boolean;
}

export interface AuthRequestAccessResponse {
  access_token: string;
  id_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}
