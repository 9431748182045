import { ButtonTheme, ErrorModel, isNullOrWhiteSpace, Modal, NotificationService } from "@q4/nimbus-ui";
import { isEmpty } from "lodash";
import React, { memo, useMemo, useState } from "react";
import { useQuestionGroups as useQuestionGroupsHook } from "../../../hooks";
import { useAttendeeAnswer as useAttendeeAnswerHook } from "../../../hooks/useAttendeeAnswer/useAttendeeAnswer.hook";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import { modifyAnswerPayload } from "../../../utils";
import AttendeeForm from "../form/attendeeForm.component";
import { AttendeeModalClassName, AttendeeModalIdModel, AttendeeModalProps, AttendeeState } from "./attendeeModal.definition";

const AttendeeModal = (props: AttendeeModalProps): JSX.Element => {
  const {
    id,
    visible,
    edit,
    attendee,
    codes,
    companyOptions,
    conference,
    corporateProfiles,
    onCloseRequest,
    onAttendeeCreate,
    onAttendeeUpdate,
  } = props;

  const notificationService = useMemo(() => new NotificationService(), []);

  const useAttendeeAnswer = useAttendeeAnswerHook({
    attendeeId: attendee?._id,
  });

  const { items: attendeeAnswers, loading: answerLoading } = useAttendeeAnswer;
  const { items: questionGroups } = useQuestionGroupsHook({
    conferenceId: conference?._id,
  });

  const [formErrors, setFormErrors] = useState<Record<string, ErrorModel>>({});
  const [attendeeState, setAttendeeState] = useState(attendee);
  const [attendeeSynced, setSyncedAttendee] = useState(false);

  const idModel = useMemo(() => new AttendeeModalIdModel(id), [id]);
  const submitDisabled = useMemo(checkForErrors, [attendeeState]);

  function handleCancelClick(): void {
    onCloseRequest && onCloseRequest();
  }

  function handleSubmitClick(): void {
    const error = checkForErrors();

    if (error) return;

    const corporateNamesWithMissingFields = attendeeState.meeting_requests?.reduce((corporateNames, req) => {
      if (req._corporate_profile && (!req.interest_level || !req.meeting_type)) {
        const profile = corporateProfiles?.find((profile) => profile?._id === req._corporate_profile);
        return [...corporateNames, profile.name];
      }

      return corporateNames;
    }, []);

    if (!isEmpty(corporateNamesWithMissingFields)) {
      notificationService.error(
        `Both meeting type and interest level are required: ${corporateNamesWithMissingFields.join(", ")}`
      );
      return;
    }
    const modifiedAttendeeData = modifyAnswerPayload(attendeeState, questionGroups);

    edit ? onAttendeeUpdate(modifiedAttendeeData) : onAttendeeCreate(modifiedAttendeeData);
  }

  function handleSync(): void {
    setAttendeeState(new AttendeeState(attendee));
    setSyncedAttendee(true);
  }

  function handleReset(): void {
    setAttendeeState(new AttendeeState());
    setSyncedAttendee(false);
  }

  function checkForErrors(): boolean {
    const { email } = attendeeState || {};
    let error = false;

    if (isNullOrWhiteSpace(email)) {
      error = true;
    }

    return error;
  }

  function handleAttendeeUpdate(state: Partial<AttendeeViewModel>): void {
    setAttendeeState(new AttendeeState({ ...attendeeState, ...state }));
  }

  return (
    <Modal
      id={idModel.modal?.id}
      className={AttendeeModalClassName.Base}
      visible={visible}
      title={edit ? "Edit Attendee" : "Add Attendee"}
      badgeIcon="q4i-contact-2pt"
      footerActions={[
        {
          key: "cancel",
          id: idModel.cancel?.id,
          label: "Cancel",
          theme: ButtonTheme.DarkSlate,
          onClick: handleCancelClick,
        },
        {
          key: "submit",
          id: idModel.save?.id,
          label: "Save",
          disabled: submitDisabled,
          theme: ButtonTheme.Citrus,
          onClick: handleSubmitClick,
        },
      ]}
      fullscreen
      scrollable
      loading={answerLoading}
      onCloseRequest={onCloseRequest}
      ghostableProps={{
        onEntering: handleSync,
        onExited: handleReset,
      }}
    >
      {attendeeSynced && (
        <AttendeeForm
          id={idModel.form?.id}
          className={AttendeeModalClassName.Form}
          attendee={attendeeState}
          codes={codes}
          companies={companyOptions}
          corporateProfiles={corporateProfiles}
          conference={conference}
          attendeeAnswers={attendeeState?.custom_question_answers ?? attendeeAnswers}
          onAttendeeUpdate={handleAttendeeUpdate}
          loading={answerLoading}
          questionGroups={questionGroups}
          setFormErrors={setFormErrors}
          formErrors={formErrors}
        />
      )}
    </Modal>
  );
};

export default memo(AttendeeModal);
