import { isEmpty, isNil } from "@q4/nimbus-ui";
import type { RowNode } from "@q4/nimbus-ui/dist/dependencies/agGrid/community";
import { get } from "lodash";
import { useCallback, useRef, useState } from "react";
import { EntityBase } from "../../definitions/entity.definition";
import { YesNoOptions } from "../nimbus/useTable/useTable.definition";
import { TableFilterHookModel, TableFilterHookProps } from "./useTableFilter.definition";

export const useTableFilter = <T extends EntityBase>(props: TableFilterHookProps): TableFilterHookModel<T> => {
  const { gridApi } = props;
  const [filterValues, updateFilterValues] = useState<Partial<T>>({});
  const filterValueRefs = useRef<Partial<T>>({});

  // TODO: use useStateRef custom hook
  const setFilterValues = useCallback((state: Partial<T> | ((state: Partial<T>) => Partial<T>)) => {
    if (typeof state === "function") {
      updateFilterValues((currentState) => {
        const updatedState = state(currentState);
        filterValueRefs.current = updatedState;
        return updatedState;
      });

      return;
    }

    updateFilterValues(state);
    filterValueRefs.current = state;
  }, []);

  const handleOnFilterChanged = useCallback(() => {
    gridApi.current && gridApi.current.onFilterChanged();
  }, [gridApi]);

  const handleFilter = useCallback(
    (key: string, value: string): void => {
      setFilterValues((filterValues: Partial<T>): Partial<T> => {
        if (value === YesNoOptions.Yes) {
          return { ...filterValues, [key]: true };
        } else if (value === YesNoOptions.No) {
          return { ...filterValues, [key]: false };
        }

        const newFilterValues = { ...filterValues, [key]: value };

        if (isNil(newFilterValues[key])) delete newFilterValues[key];

        return newFilterValues;
      });
      handleOnFilterChanged();
    },
    [handleOnFilterChanged, setFilterValues]
  );

  const handleClearFilter = useCallback((): void => {
    setFilterValues({});
    handleOnFilterChanged();
  }, [handleOnFilterChanged, setFilterValues]);

  const isExternalFilterPresent = useCallback(() => {
    if (isNil(filterValueRefs.current) || isEmpty(filterValueRefs.current)) return false;

    if (!Object.keys(filterValueRefs.current).length) return false;
    return !!Object.values(filterValueRefs.current).length;
  }, []);

  const doesExternalFilterPass = useCallback((node: RowNode) => {
    if (isEmpty(filterValueRefs.current)) return true;
    const filterKeys = Object.keys(filterValueRefs.current);
    return filterKeys.every((x) => get(node.data, x) === filterValueRefs.current[x as keyof Partial<T>]);
  }, []);

  return {
    filterValues,
    doesExternalFilterPass,
    handleClearFilter,
    handleFilter,
    isExternalFilterPresent,
  };
};
