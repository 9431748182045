import "./manageAttendees.component.scss";
import {
  arrayIndexFound,
  ButtonTheme,
  ComboBox,
  isEmpty,
  Modal,
  NotificationService,
  SelectPreset,
  Scrollbars,
  ScrollbarsTheme,
  Text,
  TextPreset,
  Field,
} from "@q4/nimbus-ui";
import { uniq } from "lodash";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useComboBox } from "../../../../../../../../hooks";
import { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import ParallelService from "../../../../../../../../services/parallel.service";
import { sortAttendeeChips } from "../../../../../../../../utils";
import {
  PresentationManageAttendeesAction,
  PresentationManageAttendeesIdModel,
  PresentationManageAttendeesModalClassName,
} from "./manageAttendees.defintion";
import type { PresentationManageAttendeesChange, PresentationManageAttendeesModalProps } from "./manageAttendees.defintion";

const PresentationManageAttendeesModal = (props: PresentationManageAttendeesModalProps): JSX.Element => {
  const { id, presentation, useAttendees, onCloseRequest, ...modalProps } = props;
  const { visible } = modalProps || {};
  const { code: codes, title } = presentation || {};

  const [presentationAttendees, setPresentationAttendees] = useState<AttendeeViewModel[]>([]);
  const [saving, setSaving] = useState(false);

  const original = useRef<AttendeeViewModel[]>([]);
  const codeChanges = useRef<PresentationManageAttendeesChange[]>([]);

  const { items: attendees, putById } = useAttendees || {};

  const idModel = useMemo(() => new PresentationManageAttendeesIdModel(id), [id]);

  useEffect(() => {
    if (saving || !visible) return;

    if (isEmpty(attendees) || isEmpty(codes)) {
      const empty = [] as AttendeeViewModel[];
      setPresentationAttendees(empty);
      original.current = empty;
      return;
    }

    const presentationAttendees = attendees.filter((x) => x.code?.some((code) => codes.some((y) => y === code)));
    setPresentationAttendees(presentationAttendees);
    original.current = presentationAttendees;
  }, [attendees, codes, saving, visible]);

  const {
    input: attendeeInput,
    handleInputChange: handleAttendeeInputChange,
    chipsHook: useAttendeeChips,
    filteredOptions: availableAttendees,
  } = useComboBox({
    options: attendees,
    chipsHookProps: {
      entities: presentationAttendees,
      labelKey: "friendly_name",
      valueKey: "_id",
    },
  });
  const [attendeeChips, handleAddAttendee, handleAttendeeRemoveRequest] = useAttendeeChips;
  attendeeChips.sort(sortAttendeeChips);

  function handleAttendeeChange(attendee: AttendeeViewModel): void {
    const attendees = handleAddAttendee(attendee, AttendeeViewModel);
    setPresentationAttendees(attendees);

    handleCodeChange({ _id: attendee?._id, action: PresentationManageAttendeesAction.Add });
  }

  function handleAttendeeRemove(_id: AttendeeViewModel["_id"]): void {
    const _attendee = handleAttendeeRemoveRequest(_id);
    setPresentationAttendees(_attendee);

    handleCodeChange({ _id, action: PresentationManageAttendeesAction.Remove });
  }

  function handleCodesClear() {
    codeChanges.current = [];
  }

  function handleCodeChange(change: PresentationManageAttendeesChange): void {
    if (isEmpty(change)) return;

    const { _id, action } = change;
    const existingChangeIdx = codeChanges.current?.findIndex((x) => _id === x._id);

    if (!arrayIndexFound(existingChangeIdx)) {
      codeChanges.current?.push(change);
      return;
    }

    // Remove duplicate entry
    const existingChange = codeChanges.current[existingChangeIdx];
    const isOriginal = original.current.some((x) => x._id === existingChange._id);

    if (
      (!isOriginal && action === PresentationManageAttendeesAction.Add) ||
      (isOriginal && action === PresentationManageAttendeesAction.Remove)
    ) {
      codeChanges.current.splice(existingChangeIdx, 1, change);
    } else {
      codeChanges.current.splice(existingChangeIdx, 1);
    }
  }

  function handleSave(): void {
    const notificationService = new NotificationService();

    if (isEmpty(codeChanges.current)) {
      notificationService.error("Failed to save because no changes were made.");
      onCloseRequest();
      return;
    }
    const requests = [] as (() => Promise<AttendeeViewModel>)[];
    codeChanges.current.forEach((change) => {
      const { _id, action } = change;

      const attendee = attendees?.find((x) => change._id === x._id);
      if (isEmpty(attendee)) return;

      const code =
        action === PresentationManageAttendeesAction.Add
          ? uniq([...attendee?.code, ...codes])
          : attendee?.code?.filter((x) => codes?.every((y) => y !== x)) ?? [];
      requests.push(() => putById(_id, new AttendeeViewModel({ ...attendee, code }), false));
    });

    if (isEmpty(requests)) {
      notificationService.error("Unable to generate requests to save.");
      return;
    }

    const parallelService = new ParallelService();
    setSaving(false);
    parallelService
      .limit(requests)
      .then((results) => {
        results.forEach((attendee) => {
          if (isEmpty(attendee)) return;

          const codeChangeIdx = codeChanges.current.findIndex((x) => x._id === attendee._id);
          if (!arrayIndexFound(codeChangeIdx)) return;

          codeChanges.current.splice(codeChangeIdx, 1);
        });

        if (!isEmpty(codeChanges.current)) {
          notificationService.error("Unable to save please try again.");
          return;
        }

        notificationService.success(`The attendee${results.length > 1 ? "s were" : " was"} updated successfully.`);
        onCloseRequest();
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <Modal
      id={idModel.modal?.id}
      className={PresentationManageAttendeesModalClassName.Base}
      title="Presentation Attendees"
      onCloseRequest={onCloseRequest}
      disableEscToClose={!saving}
      disableMaskClickToClose={!saving}
      ghostableProps={{
        onExited: handleCodesClear,
      }}
      footerActions={[
        {
          id: idModel.cancel?.id,
          key: "cancel",
          label: "Cancel",
          theme: ButtonTheme.DarkSlate,
          disabled: saving,
          onClick: onCloseRequest,
        },
        {
          id: idModel.save?.id,
          key: "save",
          label: "SAVE",
          theme: ButtonTheme.Citrus,
          loading: saving,
          onClick: handleSave,
        },
      ]}
      {...modalProps}
    >
      <Text className={PresentationManageAttendeesModalClassName.Subtitle} preset={TextPreset.Subheader}>
        {title}
      </Text>
      <Scrollbars
        autoHide
        id={idModel.scrollbar?.id}
        className={PresentationManageAttendeesModalClassName.Scrollbar}
        theme={ScrollbarsTheme.Dark}
      >
        <Field label="Add Attendee">
          <ComboBox
            id={idModel.attendees?.id}
            selectProps={{
              preset: SelectPreset.Autocomplete,
              placeholder: "Add an attendee",
              inputValue: attendeeInput,
              valueKey: "_id",
              labelKey: "friendly_name",
              options: availableAttendees,
              onChange: handleAttendeeChange,
              onInputChange: handleAttendeeInputChange,
              disabled: isEmpty(availableAttendees),
            }}
            chipsProps={{
              items: attendeeChips,
              onClick: handleAttendeeRemove,
              onRemove: handleAttendeeRemove,
            }}
          />
        </Field>
      </Scrollbars>
    </Modal>
  );
};

export default memo(PresentationManageAttendeesModal);
