import { isNil, NotificationService } from "@q4/nimbus-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import type { ApiResponse } from "../../../services/api/api.definition";
import { AttendeeViewModel } from "../../../services/attendee/attendee.model";
import type { Conference } from "../../../services/conference/conference.model";
import ProfileService from "../../../services/user/profile/profile.service";
import { useAutoFetch } from "../../useAutoFetch/useAutoFetch.hook";
import type { ProfileHookModel, ProfileHookProps } from "./useProfile.definition";

export const useProfile = (props: ProfileHookProps): ProfileHookModel => {
  const { autoFetchData, conferenceId, data, fetchFullProfiles = false, showNotifications: showNotificationsProp } = props;

  const [profile, setProfile] = useState<AttendeeViewModel>(null);
  const [loading, setLoading] = useState(false);
  const profileService = useRef(new ProfileService());
  const notificationService = useRef(new NotificationService());

  // TODO: add to useService Hook when implemented
  const showNotifications = useMemo(
    () => (isNil(showNotificationsProp) ? true : showNotificationsProp),
    [showNotificationsProp]
  );

  const _handleNotification = useCallback((type: keyof NotificationService, message: string, showNotifications): void => {
    if (!showNotifications) return;

    notificationService.current[type](message);
  }, []);

  const _handleResponse = useCallback(
    (response: ApiResponse<AttendeeViewModel>): AttendeeViewModel => {
      setLoading(false);
      if (!response.success) {
        _handleNotification("error", response.message, showNotifications);
        return null;
      }
      _handleNotification("success", "Profile was updated successfully.", showNotifications);

      const { data: profile } = response;
      setProfile(profile);
      return profile;
    },
    [showNotifications, _handleNotification]
  );

  const getProfileByConferenceId = useCallback(
    (_id: Conference["_id"]): Promise<AttendeeViewModel> => {
      setLoading(true);

      return fetchFullProfiles
        ? profileService.current.getFull(_id).then(_handleResponse)
        : profileService.current.get(_id).then(_handleResponse);
    },
    [_handleResponse, fetchFullProfiles]
  );

  const handleTimeZoneChange = useCallback((_id: Conference["_id"], time_zone: AttendeeViewModel["time_zone"]) => {
    // Exposing outside service call so UI updates quickly and regardless of API response
    setProfile(
      (current) =>
        new AttendeeViewModel({
          ...current,
          time_zone,
        })
    );

    return profileService.current.setTimeZonePreference(_id, { time_zone }).then((response) => {
      setLoading(false);
      if (!response.success) return null;

      const { time_zone: responseTimeZone } = response?.data || {};

      return responseTimeZone;
    });
  }, []);

  const handleProfilePut = useCallback(
    (_id: Conference["_id"], data: AttendeeViewModel): Promise<AttendeeViewModel> => {
      setLoading(true);
      return profileService.current.put(_id, data).then(_handleResponse);
    },
    [_handleResponse]
  );

  useAutoFetch({
    autoFetchData,
    param: conferenceId,
    data,
    fetchBy: getProfileByConferenceId,
    setEntity: setProfile,
  });

  return {
    current: profile,
    loading,
    fetchById: getProfileByConferenceId,
    fetchProfileByConferenceId: getProfileByConferenceId,
    putById: handleProfilePut,
    setCurrent: setProfile,
    setTimeZone: handleTimeZoneChange,
  };
};
