import { BaseComponentProps, isEmpty, ModalProps, ModalIdModel, ButtonIdModel, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import shortid from "shortid";
import { AttendeeViewModel, Attendee } from "../../../../services/attendee/attendee.model";
import type { Conference } from "../../../../services/conference/conference.model";
import { QuestionGroup } from "../../../../services/questionGroup/questionGroup.model";
import { AttendeeFormIdModel } from "../../../attendee/form/attendeeForm.definition";

export interface CorporateAttendeeModalProps extends BaseComponentProps {
  visible: boolean;
  edit?: boolean;
  attendee?: AttendeeViewModel;
  conference: Conference;
  questionGroup: QuestionGroup;
  onAttendeeUpdate?: (attendee: AttendeeViewModel) => void;
  onAttendeeCreate?: (attendee: AttendeeViewModel) => void;
  onCloseRequest: ModalProps["onCloseRequest"];
}

export enum CorporateAttendeeModalClassName {
  Base = "corporate-attendee-modal",
  Form = "corporate-attendee-modal_form",
}

export class CorporateAttendeeState extends AttendeeViewModel {
  constructor(attendee?: Partial<Attendee> | Partial<AttendeeViewModel>) {
    super(attendee);
    if (isEmpty(attendee)) {
      this._id = shortid.generate();
    }
  }
}

export class CorporateAttendeeModalIdModel extends ModalIdModel {
  cancel: ButtonIdModel;
  save: ButtonIdModel;
  form: AttendeeFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.cancel = new ButtonIdModel(`${this.id}CancelButton`);
    this.save = new ButtonIdModel(`${this.id}SaveButton`);
    this.form = new AttendeeFormIdModel(`${this.id}Form`);
  }
}
