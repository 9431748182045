import { convertStringToEnum, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import { DefaultTimeZone } from "../../const";
import { Entity } from "../../definitions/entity.definition";
import { ApiResponse } from "../api/api.definition";
import { Attendee, AttendeeType, AttendeeTypesWithAvailability } from "../attendee/attendee.model";
import { Conference } from "../conference/conference.model";
import { CorporateProfile } from "../corporateProfile/corporateProfile.model";
import CorporateProfileService from "../corporateProfile/corporateProfile.service";
import { InvestorType } from "../investor/investor.model";
import { Meeting } from "../meeting/meeting.model";
import MeetingService from "../meeting/meeting.service";
import { ImportMeetingError, ImportResult, AttendeeImportModel, ImportOptions, CsvBase } from "./import.definition";

export default class ImportMeetingService {
  private meetings: { entity: Meeting; csv: CsvBase }[];
  private requests: (() => Promise<ApiResponse<Meeting>>)[];
  private meetingService = new MeetingService();
  private corporateProfiles: CorporateProfile[];
  private corporateProfileService = new CorporateProfileService();

  get Data(): Meeting[] {
    return this.meetings?.map((x) => x.entity) || [];
  }
  get HasSetting(): boolean {
    return true;
  }
  get Requests(): (() => Promise<ApiResponse<Meeting>>)[] {
    return this.requests || [];
  }
  get Type(): Entity {
    return Entity.Meeting;
  }

  parse = async (options: ImportOptions): Promise<ImportResult<Meeting>[]> => {
    const { csvJson, conference: _conference, handleResults, futureOnly } = options || {};
    if (isEmpty(csvJson) || isNullOrWhiteSpace(_conference?._id)) return [];

    this.corporateProfiles = (await this.corporateProfileService.getByConferenceId(_conference?._id)).data;

    const attendees = csvJson.reduce((attendees, csv) => {
      if (isEmpty(csv)) return attendees;

      const { meeting_id } = csv;
      const first_name = csv.first_nm?.trim();
      const last_name = csv.last_nm?.trim();
      const email = csv.email_txt?.trim();
      const company = csv.contact_company_nm?.trim();
      const title = csv.contact_title?.trim();
      const attendee: AttendeeImportModel = new AttendeeImportModel({
        session_id: meeting_id,
        company,
        email,
        first_name,
        last_name,
        title,
        type: convertStringToEnum(AttendeeType, csv.contact_type) || AttendeeType.Investor,
      });

      if (!this.validAttendee(attendee)) return attendees;

      return attendees.concat(attendee);
    }, [] as AttendeeImportModel[]);

    const results: ImportResult<Meeting>[] = [];
    const existingMeetings: string[] = [];

    this.meetings = csvJson.reduce((meetings, csv): Meeting[] => {
      if (isEmpty(csv)) return meetings;

      const { meeting_id } = csv;
      if (existingMeetings.includes(meeting_id)) return meetings;
      const { meeting_date, meeting_end, meeting_start } = csv;
      const { time_zone } = _conference;
      const start_date = this.convertDatetoUtc(meeting_date, meeting_start, time_zone);
      const end_date = this.convertDatetoUtc(meeting_date, meeting_end, time_zone);

      const meetingAttendees = attendees.filter((x) => {
        if (x.session_id === meeting_id) {
          x.receive_emails = undefined;
          x.participate_in_meetings = undefined;
          if (AttendeeTypesWithAvailability.includes(x.type)) {
            x.availability = [
              {
                start_time: start_date,
                end_time: end_date,
              },
            ];
            x.receive_emails = false;
          }
          if (x.type === AttendeeType.Corporate) {
            x.participate_in_meetings = true;
            const corpProfile = this.getCorporateProfile(this.corporateProfiles, x.company);
            x._corporate_profile = corpProfile?._id;
            x.company = undefined;
          }
          if (x.type === AttendeeType.Investor) {
            x.investor_type = InvestorType.Other;
          }
        }
        return x.session_id === meeting_id;
      });

      const meeting = new Meeting({
        start_date,
        end_date,
        _attendee: meetingAttendees,
        _conference,
      });

      const meetingParseError = this.getMeetingParseError(meeting, futureOnly);
      if (!isEmpty(meetingParseError)) {
        results.push(
          new ImportResult({
            type: this.Type,
            entity: meeting,
            title: "",
            csv,
            errorMessage: meetingParseError,
          })
        );
        return meetings.concat({ entity: null, csv });
      }

      existingMeetings.push(meeting_id);
      return meetings.concat({ entity: meeting, csv });
    }, []);

    this.requests = this.meetings.reduce((requests, meetingData): (() => Promise<ApiResponse<Meeting>>)[] => {
      if (isEmpty(meetingData?.entity)) return requests;
      const { entity: meeting, csv } = meetingData;

      return requests.concat((): Promise<ApiResponse<Meeting>> => {
        return this.meetingService.post(meeting).then((response) => {
          const { success, message } = response;

          handleResults(
            this.Type,
            {
              ...response,
              data: success ? response.data : meeting,
              message,
              success,
            },
            "start_date",
            csv
          );

          return response;
        });
      });
    }, [] as (() => Promise<ApiResponse<Meeting>>)[]);

    return results;
  };

  private validAttendee(attendee: Attendee): boolean {
    if (isEmpty(attendee) || isNullOrWhiteSpace(attendee.email) || isNullOrWhiteSpace(attendee.company)) return false;
    return true;
  }

  private validTime(dateTime: Moment, currentTime = moment()): boolean {
    return !isEmpty(dateTime) && dateTime.isValid() && dateTime.isSameOrAfter(currentTime);
  }

  private isPast(datetime: Moment, currentTime = moment()): boolean {
    return currentTime.startOf("day").diff(datetime.startOf("day"), "days") >= 0;
  }

  private getMeetingParseError(meeting: Meeting, futureOnly = false): ImportMeetingError {
    if (isEmpty(meeting)) return ImportMeetingError.Empty;
    if (futureOnly && this.isPast(meeting.start_date)) return ImportMeetingError.IsPastDate;
    if (!this.validTime(meeting.start_date)) return ImportMeetingError.InvalidStartDate;
    if (!this.validTime(meeting.end_date, meeting.start_date)) return ImportMeetingError.InvalidEndDate;
    return null;
  }

  private convertDatetoUtc(date: string, time: string, timeZone: Conference["time_zone"]): moment.Moment {
    const dateTime = `${date} ${time}`;
    timeZone = isNullOrWhiteSpace(timeZone) ? DefaultTimeZone : timeZone;
    return moment(dateTime).tz(timeZone).utc();
  }

  private getCorporateProfile(corporateProfiles: CorporateProfile[], companyName: string): CorporateProfile {
    return corporateProfiles.find((x) => x.name === companyName);
  }
}
