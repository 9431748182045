import React from "react";
import { Redirect } from "react-router";

const ConferenceLegacyRedirect = (): JSX.Element[] => {
  const redirects: { from: string; to: string }[] = [
    {
      from: "/company/tdevents/register",
      to: "/company/tdevents/register/614a2fdcb821d30009866f32",
    },
    {
      from: "/company/tdevents",
      to: "/company/tdevents/614a2fdcb821d30009866f32",
    },
    {
      from: "/company/tdsecurities/register",
      to: "/company/tdsecurities/register/6138eb51af70900009c70819",
    },
    {
      from: "/company/tdsecurities",
      to: "/company/tdsecurities/6138eb51af70900009c70819",
    },
    {
      from: "/company/nationalbankfasken",
      to: "/company/nationalbankfasken/6109bcf092293c0008458c92",
    },
    {
      from: "/company/nationalbankenergy",
      to: "/company/nationalbankenergy/61098c07049943000893a48f",
    },
  ];

  return redirects.map((redirect) => <Redirect exact key={redirect.from} from={redirect.from} to={redirect.to} />);
};

export default ConferenceLegacyRedirect;
