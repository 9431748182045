import {
  BaseComponentProps,
  IdModelBase,
  RadioButtonListId,
  isNullOrWhiteSpace,
  TextboxIdModel,
  ButtonIdModel,
} from "@q4/nimbus-ui";
import { SyncConfirmationMessageIdModel } from "../../../../../../components/syncConfirmationMessage/syncConfirmationMessage.definition";
import { AttendeeViewModel } from "../../../../../../services/attendee/attendee.model";
import { PresentationVendor } from "../../../../../../services/conference/conference.model";
import { VendorStatus } from "../../conferenceEdit.definition";

export interface PresentationVendorsFormProps extends BaseComponentProps {
  vendor: PresentationVendor;
  previousVendorState?: PresentationVendor;
  totalAttendees?: AttendeeViewModel[];
  onChange: (value: PresentationVendor) => void;
  handleSyncClick?: () => void;
  onVerifyVendor?: (hubId: string) => void;
  vendorStatus?: VendorStatus;
  verifying?: boolean;
}

export enum PresentationVendorsFormClassName {
  Base = "presentation-vendors-form",
  VendorStatus = "presentation-vendor-form_vendor-status",
  VendorStatusWithOkModifier = "presentation-vendor-form_vendor-status--ok",
  VendorStatusWithErrorModifier = "presentation-vendor-form_vendor-status--error",
  VendorStatusVerifyButton = "presentation-vendor-form_verify-button",
}

export class PresentationVendorsFormIdModel extends IdModelBase {
  radio: RadioButtonListId;
  streamlinedHubId: TextboxIdModel;
  streamlinedVerifyButton: ButtonIdModel;
  syncAttendeesModal: SyncConfirmationMessageIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.radio = new RadioButtonListId(`${this.id}Radio`);
    this.streamlinedHubId = new TextboxIdModel(`${this.id}StreamlinedHubIdTextbox`);
    this.streamlinedVerifyButton = new ButtonIdModel(`${this.id}StreamlinedVerifyButton`);
    this.syncAttendeesModal = new SyncConfirmationMessageIdModel(`${this.id}SyncMessage`);
  }
}
