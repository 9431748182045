import {
  AnchorIdModel,
  BaseComponentWithChildrenProps,
  Browser,
  IdModelBase,
  isNullOrWhiteSpace,
  PlaceholderContentIdModel,
} from "@q4/nimbus-ui";
import { BadgeIdModel } from "../../views/admin/conferences/details/badge/badge.definition";

export interface SupportedBrowserProps extends BaseComponentWithChildrenProps {
  hasLocalStorageSupport: boolean;
  handleOverride: () => void;
}

export const SupportedBrowserWarningSessionStorage = { key: "q4-browser-warning--override", value: "true" };

export class SupportedBrowser {
  constructor(public name: string, public version?: number) {}
}

export const AdminSupportedBrowsers = [
  new SupportedBrowser(Browser.Chrome),
  new SupportedBrowser(Browser.Firefox),
  new SupportedBrowser(Browser.Safari),
  new SupportedBrowser("Edge"),
  new SupportedBrowser("Edge Chromium"),
  new SupportedBrowser("Chrome Headless"),
  new SupportedBrowser("Chromium"),
];

export const SupportedBrowserMinVersion = {
  [Browser.IE]: 11,
  IE: 11,
};

export const InternetExplorerBrowsers = [
  new SupportedBrowser(Browser.IE, SupportedBrowserMinVersion[Browser.IE]),
  new SupportedBrowser("IE", SupportedBrowserMinVersion["IE"]),
];

export const PublicSupportedBrowsers = [...InternetExplorerBrowsers, ...AdminSupportedBrowsers];

export enum SupportedBrowserClassName {
  Base = "supported-browser",
  Content = "supported-browser_content",
  Title = "supported-browser_title",
  Container = "supported-browser_container",
  ContainerItem = "supported-browser_container-item",
  Logo = "supported-browser_logo",
  Anchor = "supported-browser_anchor",
  ContinueFooter = "supported-browser_continue-footer",
  ContinueAnchor = "supported-browser_continue-footer-anchor",
  Required = "supported-browser_required",
  RequiredContent = "supported-browser_required-content",
  RequiredLabel = "supported-browser_required-label",
}

export const GoogleDownloadUrl = "https://www.google.com/intl/en_ca/chrome/";
export const EdgeDownloadUrl = "https://www.microsoft.com/en-us/edge";

class PageIdModel extends IdModelBase {
  placeholderContent: PlaceholderContentIdModel;
  edge: AnchorIdModel;
  google: AnchorIdModel;
  localStorage: BadgeIdModel;
  override: AnchorIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.placeholderContent = new PlaceholderContentIdModel(`${this.id}PlaceholderContent`);
    this.google = new AnchorIdModel(`${this.id}GoogleAnchor`);
    this.edge = new AnchorIdModel(`${this.id}EdgeAnchor`);
    this.localStorage = new BadgeIdModel(`${this.id}LocalStorageBadge`);
    this.override = new AnchorIdModel(`${this.id}OverrideAnchor`);
  }
}

export const SupportedBrowserIdModel = new PageIdModel("SupportedBrowser");
