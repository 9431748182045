import {
  BadgeIdModel,
  BadgeProps,
  BaseComponentWithChildrenProps,
  IdModelBase,
  isNullOrWhiteSpace,
  TooltipIdModel,
  TooltipProps,
} from "@q4/nimbus-ui";

export enum InfoBubbleClassName {
  Base = "info-bubble",
  Badge = "info-bubble_badge",
  Tooltip = "info-bubble_tooltip",
}

export interface InfoBubbleProps extends BaseComponentWithChildrenProps {
  tooltipProps: Omit<TooltipProps, "id" | "className">;
  badgeProps: Omit<BadgeProps, "id" | "className">;
}

export class InfoBubbleIdModel extends IdModelBase {
  tooltip: TooltipIdModel;
  badge: BadgeIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.tooltip = new TooltipIdModel(`${this.id}Tooltip`);
    this.badge = new BadgeIdModel(`${this.id}Badge`);
  }
}
