import "./meetingRequestForm.component.scss";
import { convertStringToEnum, isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo, useRef, useEffect } from "react";
import { MeetingInterestLevel, MeetingRequest } from "../../../../../services/admin/registrant/registrant.model";
import { CorporateProfile } from "../../../../../services/corporateProfile/corporateProfile.model";
import { MeetingLabel } from "../../../../../services/meeting/meeting.model";
import MeetingIssuers from "./components/meetingIssuers/meetingIssuers.component";
import RequestMeetings from "./components/requestMeetings/requestMeetings.component";
import {
  InvestorFormMeetingRequests,
  MeetingRequestFormProps,
  MeetingRequestFormClassName,
  SelectOption,
  MeetingRequestFormIdModel,
} from "./meetingRequestForm.definition";

const MeetingRequestForm = (props: MeetingRequestFormProps): JSX.Element => {
  const { id, conference, meetingRequests, setMeetingRequests, disabled } = props;

  const idModel = new MeetingRequestFormIdModel(id);

  const { corporate_profiles } = conference;

  const requestedMeetingSectionRef = useRef<HTMLDivElement>();

  const filteredMeetingRequests = useMemo(
    () =>
      meetingRequests.filter((request) => corporate_profiles.some((profile) => profile._id === request._corporate_profile)),
    [corporate_profiles, meetingRequests]
  );

  const meetingRequestsObject = useMemo(() => {
    if (isEmpty(meetingRequests)) return {};
    return meetingRequests.reduce((acc, meeting) => {
      acc[meeting._corporate_profile] = { meeting_type: meeting.meeting_type, interest_level: meeting.interest_level };
      return acc;
    }, {} as InvestorFormMeetingRequests);
  }, [meetingRequests]);

  const filteredMeetingRequestsObject = useMemo(() => {
    if (isEmpty(filteredMeetingRequests)) return {};
    return filteredMeetingRequests.reduce((acc, meeting) => {
      acc[meeting._corporate_profile] = { meeting_type: meeting.meeting_type, interest_level: meeting.interest_level };
      return acc;
    }, {} as InvestorFormMeetingRequests);
  }, [filteredMeetingRequests]);

  const handleSelectChange = useCallback(
    (id: CorporateProfile["_id"], key: keyof MeetingRequest) => {
      return (selectedOption: SelectOption) => {
        const value = !selectedOption || selectedOption?.value === "None" ? undefined : selectedOption.value;

        const updatedFormMeetingRequests: InvestorFormMeetingRequests = {
          ...meetingRequestsObject,
          [id]: {
            ...meetingRequestsObject[id],
            [key]: value,
          },
        };

        const updatedMeetingRequests: MeetingRequest[] = (Object.entries(updatedFormMeetingRequests) || []).reduce(
          (requests, request) => {
            if (isEmpty(request)) return requests;
            const [profileId, value] = request || [];

            if (
              isNullOrWhiteSpace(profileId) ||
              (isNullOrWhiteSpace(value?.interest_level) && isNullOrWhiteSpace(value?.meeting_type))
            ) {
              return requests;
            }

            requests.push({
              _corporate_profile: profileId,
              interest_level: convertStringToEnum(MeetingInterestLevel, value.interest_level),
              meeting_type: convertStringToEnum(MeetingLabel, value.meeting_type),
            });
            return requests;
          },
          []
        );

        setMeetingRequests(updatedMeetingRequests);
      };
    },
    [setMeetingRequests, meetingRequestsObject]
  );

  const handleAddToRequestList = useCallback(
    (profileId: CorporateProfile["_id"]) =>
      setMeetingRequests([
        ...meetingRequests,
        {
          _corporate_profile: profileId,
          interest_level: MeetingInterestLevel.High,
          meeting_type: MeetingLabel.OneOnOne,
        } as MeetingRequest,
      ]),
    [meetingRequests, setMeetingRequests]
  );

  const handleDeleteFromRequestList = useCallback(
    (profileId: CorporateProfile["_id"]) =>
      setMeetingRequests(meetingRequests.filter((request) => request._corporate_profile !== profileId)),
    [meetingRequests, setMeetingRequests]
  );

  const handleJumpToRequests = useCallback(
    () => requestedMeetingSectionRef.current?.scrollIntoView?.({ block: "start", behavior: "smooth" }),
    [requestedMeetingSectionRef]
  );

  useEffect(() => {
    requestedMeetingSectionRef.current && handleJumpToRequests(); // scroll to requested section on first load
  }, [requestedMeetingSectionRef, handleJumpToRequests]);

  return (
    <div id={id} className={MeetingRequestFormClassName.Base}>
      {!disabled && (
        <MeetingIssuers
          id={idModel.meetingIssuers.id}
          corporateProfiles={corporate_profiles}
          meetingRequestsObject={filteredMeetingRequestsObject}
          onRequestSelect={handleAddToRequestList}
          onJumpToRequests={handleJumpToRequests}
        />
      )}
      <RequestMeetings
        id={idModel.requestMeetings}
        corporateProfiles={corporate_profiles}
        meetingRequests={filteredMeetingRequests}
        meetingRequestsObject={filteredMeetingRequestsObject}
        onSelect={handleSelectChange}
        onDeleteRequest={handleDeleteFromRequestList}
        disabled={disabled}
        sectionRef={requestedMeetingSectionRef}
      />
    </div>
  );
};

export default memo(MeetingRequestForm);
