import { IdModelBase, isNullOrWhiteSpace, SelectIdModel, ToggleButtonsIdModel, ToolbarIdModel } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import { EntityTableIdModel } from "../../../../../../components/entityTable/entityTable.definition";
import { MeetingFormIdModel } from "../../../../../../components/meetingForm/meetingForm.definition";
import type { AdminUserContextState } from "../../../../../../contexts/adminUser/adminUser.definition";
import type { CorporateProfileHookModel } from "../../../../../../hooks/admin/useCorporateProfile/useCorporateProfile.definition";
import type { AttendeesHookModel } from "../../../../../../hooks/useAttendees/useAttendees.definition";
import type { ConferencesHookModel } from "../../../../../../hooks/useConferences/useConferences.definition";
import type { MeetingHookModel } from "../../../../../../hooks/useMeetings/useMeeting.definition";
import type { Conference } from "../../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../../services/presentation/presentation.model";
import { MeetingConfigurationIdModel } from "../meetingConfiguration/meetingConfiguration.definition";
import { MeetingSchedulerIdModel } from "../meetingScheduler/meetingScheduler.definition";

export interface MeetingsProps extends Pick<BaseComponentProps, "id"> {
  codes: string[];
  conference: Conference;
  useCorporateProfile: CorporateProfileHookModel;
  useAttendees: AttendeesHookModel;
  useMeetings: MeetingHookModel;
  useConferences: ConferencesHookModel;
  presentations: Presentation[];
  user: AdminUserContextState["user"];
}

export enum MeetingTableHeader {
  Attendees = "ATTENDEES",
  UrlOverride = "URL OVERRIDE",
  InternalAttendees = "INTERNAL ATTENDEES",
}

export enum MeetingsClassName {
  Base = "meetings",
}

export enum MeetingEditClassName {
  Base = "meeting-edit",
}

export enum MeetingPusherChannel {
  Edit = "presence-edit",
}

export class MeetingsIdModel extends IdModelBase {
  form: MeetingFormIdModel;
  entityTable: EntityTableIdModel;
  type: SelectIdModel;
  attendees: SelectIdModel;
  scheduler: MeetingSchedulerIdModel;
  configuration: MeetingConfigurationIdModel;
  toolbar: ToolbarIdModel;
  toolbarToggleButtons: ToggleButtonsIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.form = new MeetingFormIdModel(`${this.id}Form`);
    this.entityTable = new EntityTableIdModel(`${this.id}EntityTable`);
    this.type = new SelectIdModel(`${this.id}TypeSelect`);
    this.attendees = new SelectIdModel(`${this.id}AttendeesSelect`);
    this.scheduler = new MeetingSchedulerIdModel(`${this.id}Scheduler`);
    this.configuration = new MeetingConfigurationIdModel(`${this.id}Configuration`);
    this.toolbar = new ToolbarIdModel(`${this.id}Toolbar`);
    this.toolbarToggleButtons = new ToggleButtonsIdModel(`${this.id}ToggleButtons`);
  }
}
