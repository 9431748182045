import {
  IdModelBase,
  ButtonIdModel,
  MessageIdModel,
  ModalIdModel,
  ToggleButtonsIdModel,
  isNullOrWhiteSpace,
  BaseComponentProps,
} from "@q4/nimbus-ui";
import { RequestProgressStatusIdModel } from "../../../../../../../../components/requestProgressStatus/components/progress/requestProgressStatus.definition";
import type { EntityBase } from "../../../../../../../../definitions/entity.definition";
import type { AttendeeViewModel } from "../../../../../../../../services/attendee/attendee.model";
import type { ImportResult } from "../../../../../../../../services/import/import.definition";
import type { Meeting } from "../../../../../../../../services/meeting/meeting.model";
import type { Presentation } from "../../../../../../../../services/presentation/presentation.model";
import type { ExportAgendaItem } from "../exportAgenda/exportAgenda.definition";

export interface ExportAgendaModalProps extends BaseComponentProps {
  attendees: AttendeeViewModel[];
  currentCount: number;
  disabled: boolean;
  exporting: boolean;
  meetings: Meeting[];
  presentations: Presentation[];
  results: ImportResult<EntityBase>[];
  visible: boolean;
  onCancel: () => void;
  onExport: (attendees: ExportAgendaItem<AttendeeViewModel>[]) => void;
  onReset: () => void;
}

export enum ExportAgendaModalClassName {
  Base = "export-agenda-modal",
}

export enum ExportAgendaModalType {
  All,
  Corporate,
  Investor,
  Internal,
}

export enum ExportAgendaModalStep {
  Settings,
  Exporting,
}

export class ExportAgendaModalIdModel extends IdModelBase {
  cancelButton: ButtonIdModel;
  exportButton: ButtonIdModel;
  message: MessageIdModel;
  modal: ModalIdModel;
  toggleButtons: ToggleButtonsIdModel;
  exportAgendaProgress: RequestProgressStatusIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.cancelButton = new ButtonIdModel(`${this.id}CancelButton`);
    this.exportButton = new ButtonIdModel(`${this.id}PdfButton`);
    this.message = new MessageIdModel(`${this.id}Message`);
    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.toggleButtons = new ToggleButtonsIdModel(`${this.id}ToggleButtons`);
    this.exportAgendaProgress = new RequestProgressStatusIdModel(`${this.id}ExportAgendaProgress`);
  }
}
