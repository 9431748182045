import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { QuestionTemporaryId } from "../../components/registrationForm/registrationForm.definition";
import { ConferenceBaseEntity } from "../../definitions/entity.definition";
import { AttendeeType } from "../attendee/attendee.model";
import { Conference } from "../conference/conference.model";
import { PayloadBase } from "../serviceBase/payloadBase.model";

export enum QuestionGroupStatus {
  Active = "active",
  Hidden = "hidden",
  Deleted = "deleted",
}

export enum QuestionStatus {
  Active = "active",
  Hidden = "hidden",
  Modified = "modified",
  Deleted = "deleted",
}

export enum QuestionType {
  Choice = "choice",
  MultipleChoice = "multiple choice",
  ShortAnswer = "short answer",
  LongAnswer = "long answer",
  Selection = "selection",
  CustomSelection = "custom selection",
  Consent = "consent",
}

export enum AnswerDataType {
  String = "string",
  Number = "number",
  Array = "array",
  Boolean = "boolean",
}

export type QuestionOption = {
  answer: string;
  default: boolean;
};

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum SortOrder {
  Alphabetical = "alphabetical",
}

export type QuestionOptionSort = {
  enabled: boolean;
  sort_direction: SortDirection;
  sort_order: SortOrder;
};

export type AnswerType = {
  field_type: QuestionType;
  data_type: AnswerDataType;
};

export const QuestionTypesWithOptions = [
  QuestionType.Choice,
  QuestionType.MultipleChoice,
  QuestionType.Selection,
  QuestionType.CustomSelection,
];

export class Question {
  _id?: string;
  _conference?: string;
  status: QuestionStatus;
  attendee_types: AttendeeType[];
  title: string;
  position: number;
  options?: QuestionOption[];
  options_sort?: QuestionOptionSort;
  answer_type: AnswerType;
  required: boolean;
  error_message?: string;
  max_length?: number;
  place_holder?: string;
  add_other_option?: boolean;
  is_individual_corporate?: boolean;

  constructor(question?: Partial<Question>) {
    const { _id, max_length, answer_type, status, position, options, options_sort, ...rest } = question || {};

    if (answer_type?.field_type === QuestionType.ShortAnswer) {
      this.max_length = 100;
    } else if (answer_type?.field_type === QuestionType.LongAnswer) {
      this.max_length = 500;
    } else {
      this.max_length = null;
    }

    if (!_id?.includes(QuestionTemporaryId) && status === QuestionStatus.Active) {
      this.status = QuestionStatus.Modified;
    } else {
      this.status = status;
    }

    if ([QuestionType.Selection, QuestionType.CustomSelection].includes(answer_type?.field_type)) {
      if (isEmpty(options_sort)) {
        this.options_sort = {
          enabled: false,
          sort_direction: SortDirection.Ascending,
          sort_order: SortOrder.Alphabetical,
        };
      } else {
        this.options_sort = options_sort;
      }
    }

    if (QuestionTypesWithOptions.includes(answer_type?.field_type)) {
      if (isEmpty(options)) {
        this.options = [{ answer: "", default: true }] as QuestionOption[];
      } else {
        this.options = options;
      }
    }

    this._id = _id;
    this.answer_type = answer_type;
    this.position = position;
    Object.assign(this, rest);
  }
}

export class QuestionGroup extends ConferenceBaseEntity {
  _conference: Conference | string;
  _questions: Question[];
  title: string;
  attendee_types: AttendeeType[];
  status: QuestionGroupStatus;

  constructor(registrationCustomQuestionsForm: Partial<QuestionGroup> | string) {
    super(registrationCustomQuestionsForm);

    if (typeof registrationCustomQuestionsForm === "string") return;

    const { _conference, status, ...rest } = registrationCustomQuestionsForm || {};

    this.status = status || QuestionGroupStatus.Active;
    this._conference = new Conference(_conference);

    Object.assign(this, rest);
  }
}

type QuestionGroupPayloadBase = Omit<QuestionGroup, "ConferenceId">;

export class QuestionGroupPayload extends PayloadBase<QuestionGroupPayloadBase> {
  _conference: QuestionGroup["_conference"];
  title: QuestionGroup["title"];
  attendee_types: QuestionGroup["attendee_types"];
  status: QuestionGroup["status"];
  _questions: QuestionGroup["_questions"];

  constructQuestionPayload(question: Partial<Question>, conferenceId: string): Question {
    const {
      status,
      attendee_types,
      title,
      position,
      options,
      options_sort,
      answer_type,
      required,
      is_individual_corporate,
      error_message,
      max_length,
      place_holder,
      add_other_option,
      _id,
    } = question;
    const questionId = _id?.includes(QuestionTemporaryId) ? {} : { _id };
    return {
      status,
      attendee_types,
      title,
      position,
      // Reduce to remove FE generated ids and remove blank options
      options: options?.reduce((acc, option) => {
        if (isNullOrWhiteSpace(option.answer)) return acc;
        acc.push({ default: option.default, answer: option.answer });
        return acc;
      }, []),
      options_sort,
      answer_type,
      required,
      is_individual_corporate: attendee_types.includes(AttendeeType.Corporate) ? !!is_individual_corporate : undefined,
      error_message,
      max_length,
      place_holder,
      add_other_option,
      _conference: conferenceId,
      ...questionId,
    };
  }

  constructor(questionGroup: QuestionGroup) {
    super();
    const { _conference, _questions: questionList, ...rest } = questionGroup;
    const conferenceId = typeof _conference === "string" ? _conference : _conference._id;
    const _questions = questionList.map((each) => {
      return this.constructQuestionPayload(each, conferenceId);
    });
    const payload = { ...rest, _conference: conferenceId, _questions };
    const sanitizedPayload = this.sanitize(payload);
    Object.assign(this, sanitizedPayload);
  }
}
