import { Entity } from "../../../../../definitions/entity.definition";
import {
  ImportSettingsClearType,
  ImportSettingsImportType,
  ImportSettingsUpdateType,
} from "../../../../../services/import/components/settings/importSettings.definition";
import type { ImportResult } from "../../../../../services/import/import.definition";
import type { Presentation } from "../../../../../services/presentation/presentation.model";

export interface ImportSettings {
  clearType: ImportSettingsClearType;
  importType: ImportSettingsImportType;
  updateType: ImportSettingsUpdateType;
}

export interface ImportModalProps {
  type: Entity;
  currentStep: ImportStep;
  loading: boolean;
  results: ImportResult<Presentation>[];
  requestCount: number;
  totalCount: number;
  visible: boolean;
  onImport: () => void;
  onImportReset: () => void;
  onImportStop: () => void;
  onImportSettings: (options: ImportSettings) => void;
}

export enum ImportStep {
  List = -1,
  Settings,
  Confirm,
  Import,
}

export enum ImportModalClassName {
  ImportModal = "import-modal",
}
