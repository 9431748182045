import { Checkbox } from "@q4/nimbus-ui";
import React, { memo, useCallback, useMemo } from "react";
import GridCard from "../../../../../../components/gridCard/gridCard.component";
import { SecuritySettingsEditIdModel, SecuritySettingsEditProps } from "./securitySettingsEdit.definition";

export const SecuritySettingsEdit = (props: SecuritySettingsEditProps): JSX.Element => {
  const { id, meetingAuthenticated, onUpdate } = props;

  const idModel = useMemo(() => new SecuritySettingsEditIdModel(id), [id]);

  const handleEnabledChange = useCallback(
    (checked: boolean) => {
      onUpdate({
        requireAuthenticatedMeetingLink: checked,
      });
    },
    [onUpdate]
  );

  return (
    <GridCard cardProps={{ title: "Security Settings" }}>
      <div id={idModel?.id}>
        <Checkbox
          id={idModel?.meetingAuthenticated?.id}
          checked={!!meetingAuthenticated}
          onChange={handleEnabledChange}
          label="Require user authentication to access meeting links"
          inline
        />
      </div>
    </GridCard>
  );
};

export default memo(SecuritySettingsEdit);
