import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAgenda as useAgendaHook, useProfile, useUserConferences as useUserConferenceHook } from "../../../../../hooks";
import type { Conference } from "../../../../../services/conference/conference.model";
import type { Presentation } from "../../../../../services/presentation/presentation.model";
import type { Speaker } from "../../../../../services/speaker/speaker.model";
import { ConferenceHelpService } from "../../../../public/itinerary/service/conferenceHelp/conferenceHelp.service";
import type { CompanyLobbyParams } from "../../companyLobby.definition";
import { isLobbyPresentationVendor } from "../../companyLobby.utils";
import type { LobbyHookModel, LobbyHookProps } from "./useLobby.definition";

export const useLobby = (props: LobbyHookProps): LobbyHookModel => {
  const {
    autoFetchAgenda: autoFetchAgendaProp,
    initialAgenda,
    autoFetchConferences,
    initialConference,
    autoFetchProfile,
    initialProfile,
    useOffline: useOfflineProp,
    userContext,
  } = props;
  const { user, signOut } = userContext;

  const params = useParams<CompanyLobbyParams>();

  const helpService = useMemo(() => new ConferenceHelpService(), []);

  const [speaker, setSelectedSpeaker] = useState<Speaker>();

  const useOffline = useMemo(() => useOfflineProp ?? true, [useOfflineProp]);

  const validateOffline = useCallback(
    (value: Conference) => {
      if (isEmpty(value?._company) || isNullOrWhiteSpace(user?.company)) return false;
      return value._company.url_suffix === user.company;
    },
    [user?.company]
  );

  const useUserConferences = useUserConferenceHook({
    path: params?.conferencePath,
    autoFetchData: autoFetchConferences,
    data: initialConference,
    useOffline,
    validateOffline,
    userContext,
  });
  const { current: conference, setCurrent: setConference } = useUserConferences;

  const conferenceId = useMemo(() => conference?._id, [conference?._id]);
  const hasConference = useMemo(() => !isEmpty(conference), [conference]);

  const usePresentationTheater = useMemo(
    () => isLobbyPresentationVendor(conference?.presentation_vendors),
    [conference?.presentation_vendors]
  );

  const { current: profile, setTimeZone: setUserTimeZone } = useProfile({
    autoFetchData: autoFetchProfile,
    data: initialProfile,
    conferenceId: conference?._id,
    showNotifications: false,
    fetchFullProfiles: true,
  });

  useEffect(() => {
    if (isEmpty(profile) || isEmpty(conference)) return;

    helpService.init(profile.email, conference.title, profile.display_name);
  }, [conference, helpService, profile]);

  const showAgenda = useMemo(() => !conference?.lobby?.agenda?.disabled, [conference?.lobby?.agenda?.disabled]);
  const autoFetchAgenda = useMemo(() => (isNil(autoFetchAgendaProp) ? true : autoFetchAgendaProp), [autoFetchAgendaProp]);
  const useAgenda = useAgendaHook({ autoFetchData: autoFetchAgenda && showAgenda, data: initialAgenda, conferenceId });
  const { fetchAgendaByConferenceId, postToAgendaByPresentationId, deleteFromAgendaByPresentationId } = useAgenda;

  const handleAgendaAdd = useCallback(
    (_id: Presentation["_id"]): void => {
      if (!hasConference || isNullOrWhiteSpace(_id)) return;

      postToAgendaByPresentationId(conferenceId, _id).then((success) => {
        success && fetchAgendaByConferenceId(conferenceId);
      });
    },
    [conferenceId, hasConference, fetchAgendaByConferenceId, postToAgendaByPresentationId]
  );

  const handleAgendaRemove = useCallback(
    (_id: Presentation["_id"]): void => {
      if (!hasConference || isNullOrWhiteSpace(_id)) return;

      deleteFromAgendaByPresentationId(conferenceId, _id).then((success) => {
        success && fetchAgendaByConferenceId(conferenceId);
      });
    },
    [conferenceId, hasConference, deleteFromAgendaByPresentationId, fetchAgendaByConferenceId]
  );

  const handleConferenceChange = useCallback(
    (conference: Conference): void => {
      setConference(conference);
      setSelectedSpeaker(null);
    },
    [setConference]
  );

  const handleSignOut = useCallback((): void => {
    signOut();
  }, [signOut]);

  const handleSpeakerChange = useCallback((speaker?: Speaker): void => {
    isEmpty(speaker) ? setSelectedSpeaker(null) : setSelectedSpeaker(speaker);
  }, []);

  return {
    params,
    profile,
    speaker,
    useAgenda,
    usePresentationTheater,
    useUserConferences,
    setUserTimeZone,
    handleConferenceChange,
    handleAgendaAdd,
    handleAgendaRemove,
    handleSignOut,
    handleSpeakerChange,
  };
};
