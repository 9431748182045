import { AdminRouteBase } from "../../../configurations/navigation.configuration";
import { EntityBase } from "../../../definitions/entity.definition";
import type { ApiResponse } from "../../api/api.definition";
import { Conference } from "../../conference/conference.model";
import { AdminApiVersion, Service } from "../../serviceBase/serviceBase.model";
import { mapArrayedApiResponse } from "../../serviceBase/serviceBase.utils";
import { OnDemand, OnDemandPayload, OnDemandPutPayload } from "./onDemand.model";

export default class OnDemandService extends Service<OnDemand> {
  constructor() {
    super(AdminRouteBase, "presentations/ondemand", OnDemand, AdminApiVersion);
  }

  public getByConferenceId(_id: Conference["_id"]): Promise<ApiResponse<OnDemand[]>> {
    return this._apiService
      .get<OnDemand[]>(`${this.ApiPathBase}/byConference/${_id}`)
      .then((response) => mapArrayedApiResponse(response, OnDemand));
  }

  put(id: EntityBase["_id"], data: OnDemand): Promise<ApiResponse<OnDemand>> {
    const payload = new OnDemandPutPayload(data);
    return super.put(id, payload);
  }

  post(data: OnDemand): Promise<ApiResponse<OnDemand>> {
    const payload = new OnDemandPayload(data);
    return super.post(payload);
  }
}
