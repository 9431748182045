import { BaseComponentProps, CardIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { JsonFieldPropsSchema, JsonFormIdModel } from "../../../../../components/jsonForm/jsonForm.definition";
import { RegistrantViewModel } from "../../../../../services/admin/registrant/registrant.model";
import { Answer } from "../../../../../services/answer/answer.model";
import { QuestionType, QuestionGroup } from "../../../../../services/questionGroup/questionGroup.model";

export enum CustomQuestionsLabels {
  Title = "Additional Information",
}

export enum CustomQuestionsClassName {
  Base = "custom-questions",
  Section = "custom-questions_section",
  Footer = "custom-questions_footer",
}

export interface CustomQuestionsProps extends BaseComponentProps {
  questionGroup: Partial<QuestionGroup>;
  registrant: RegistrantViewModel;
  originalRegistrant?: RegistrantViewModel;
  isEditMode: boolean;
  onStep: (forward: boolean | number | string, formData?: RegistrantViewModel) => void;
  onChange: (registrant: RegistrantViewModel) => void;
}

export class CustomQuestionsIdModel extends IdModelBase {
  customQuestionsSection: CardIdModel;
  jsonForm: JsonFormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.customQuestionsSection = new CardIdModel(`${this.id}CustomQuestionsCard`);
    this.jsonForm = new JsonFormIdModel(`${this.id}JsonForm`);
  }
}

export interface CustomQuestionFormProps {
  [key: string]: JsonFieldPropsSchema;
}

export interface CustomQuestionsFormData {
  [key: string]: Answer["answer"];
}

export const QuestionFieldUiSchemaMap = {
  [QuestionType.ShortAnswer]: { "ui:widget": "nuiText" },
  [QuestionType.LongAnswer]: { "ui:widget": "nuiTextarea" },
  [QuestionType.Choice]: { "ui:field": "checkboxGroup" },
  [QuestionType.MultipleChoice]: { "ui:field": "checkboxGroup" },
  [QuestionType.Selection]: { "ui:widget": "nuiSelect" },
  [QuestionType.CustomSelection]: { "ui:widget": "nuiCustomSelect" },
  [QuestionType.Consent]: { "ui:widget": "nuiBooleanCheckbox" },
};

export const AnswerLimits = {
  [QuestionType.ShortAnswer]: 100,
  [QuestionType.LongAnswer]: 500,
  [QuestionType.Choice]: 10000,
  [QuestionType.MultipleChoice]: 10000,
  [QuestionType.Selection]: 10000,
  [QuestionType.CustomSelection]: 100,
  [QuestionType.Consent]: 10,
};

export enum SpecialOptions {
  Other = "Other",
}

export enum CustomQuestionsErrorMessages {
  NoEmptyOtherField = "This field cannot be blank.",
}
