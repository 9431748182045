import { IdModelBase, isNullOrWhiteSpace, RadioButtonIdModel } from "@q4/nimbus-ui";

export interface TermsAndConditionsProps {
  id?: string;
  policyAgree: boolean;
  onAgree: (agree: boolean) => void;
}

export class TermsAndConditionsIdModel extends IdModelBase {
  policyAgreementYes: RadioButtonIdModel;
  policyAgreementNo: RadioButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.policyAgreementYes = new RadioButtonIdModel(`${this.id}PolicyAgreementYesRadioButton`);
    this.policyAgreementNo = new RadioButtonIdModel(`${this.id}PolicyAgreementNoRadioButton`);
  }
}
